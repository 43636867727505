import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './Badge.module.sass'

export type BadgeProps = {
	count?: ReactNode
	variant?: 'plain' | 'primary'
	parentShape?: 'unspecified' | 'rectangular' | 'circular' // @TODO: get rid of unspecified shape - it is here for legacy reasons only
}

export const Badge: FunctionComponent<BadgeProps> = ({ count, variant = 'plain', parentShape = 'unspecified' }) => {
	return (
		<span className={clsx(styles.count, styles[`is_variant_${variant}`], styles[`is_parentShape_${parentShape}`])}>
			{count}
		</span>
	)
}
