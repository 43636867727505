import clsx from 'clsx'
import type { FunctionComponent, ReactNode } from 'react'
import styles from './StateLabel.module.sass'

export type StateLabelProps = {
	variant: 'success' | 'error' | 'warning' | 'neutral' | 'info'
	highlighted?: boolean
	startIcon?: ReactNode
	children: ReactNode
}

export const StateLabel: FunctionComponent<StateLabelProps> = ({
	variant,
	highlighted = false,
	startIcon,
	children,
}) => {
	return (
		<div className={clsx(styles.wrapper, styles[`is_variant_${variant}`], highlighted && styles.is_highlighted)}>
			{startIcon && <div className={styles.icon}>{startIcon}</div>}
			<div className={styles.content}>{children}</div>
		</div>
	)
}
