import { workspaceName } from '@goodlok/meta/workspaceName'
import { getStage } from '../../../../meta/getStage'
import { App, appsConfiguration } from './appsConfiguration'
import { getAppUrl } from './getAppUrl'

export const getEnabledApps = (): Array<
	Pick<App, 'name' | 'short' | 'handle' | 'pathname' | 'icon'> & { url: string }
> => {
	const stage = getStage()
	const apps = appsConfiguration.filter(app => stage !== 'production' || !app.hideInProduction)

	const projectApps = {
		goodlok: apps.map(app => ({
			name: app.name,
			short: app.short,
			handle: app.handle,
			pathname: app.pathname,
			icon: app.icon,
			url: getAppUrl(app, app.handle, stage === 'beta' ? 'gdlk.cz/' : 'goodlok.cz'),
		})),
		doubleshot: apps
			.filter(app =>
				['orders', 'panel', 'pos', 'products', 'sales', 'stats', 'timetables', 'finances'].includes(app.handle),
			)
			.map(app => ({
				name: app.name,
				short: app.short,
				handle: app.handle,
				pathname: app.pathname,
				icon: app.icon,
				url: getAppUrl(
					app,
					app.handle === 'stats'
						? 'statistiky'
						: app.handle === 'orders'
						  ? 'objednavky'
						  : app.handle === 'products'
						    ? 'produkty'
						    : app.handle === 'timetables'
						      ? 'smeny'
						      : app.handle === 'pos'
						        ? 'pokladna'
						        : app.handle === 'finances'
						          ? 'finance'
						          : app.handle,
					'doubleshot.cz',
				),
			})),
		sliceslicebaby: apps
			.filter(app => ['orders', 'panel', 'pos', 'products', 'stats'].includes(app.handle))
			.map(app => ({
				name: app.name,
				short: app.short,
				handle: app.handle,
				pathname: app.pathname,
				icon: app.icon,
				url: getAppUrl(app, app.handle, 'sliceslicebaby.cz'),
			})),
	}

	return projectApps[workspaceName]
}
