/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		getInfo:{
			customerId:"UUID",
			channel:"ChannelCode"
		},
		getCart:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode"
		},
		getCartIssues:{
			cartId:"UUID"
		},
		getCartDeliveryOptions:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode",
			time:"Date"
		},
		getCartPaymentOptions:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode"
		},
		getPayment:{
			paymentId:"UUID"
		},
		getVoucher:{

		},
		getActiveOrder:{
			orderId:"UUID"
		},
		listActiveOrder:{
			customerId:"UUID"
		},
		listCustomerToast:{
			customerId:"UUID"
		},
		getCustomer:{
			by:"Contember_CustomerUniqueWhere",
			filter:"Contember_CustomerWhere"
		}
	},
	Mutation:{
		createCart:{
			customerId:"UUID",
			channel:"ChannelCode",
			items:"CartItemInput"
		},
		copyCartItems:{
			sourceCartId:"UUID",
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode"
		},
		setItems:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode",
			items:"CartItemInput"
		},
		calculatePosCart:{
			cart:"PosCartInput"
		},
		deleteCart:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode"
		},
		addItemToCart:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode",
			productId:"UUID"
		},
		setItemQuantityInCart:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode",
			productId:"UUID"
		},
		setCartDeliveryOption:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode"
		},
		setCartPaymentOption:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode",
			billingAddressId:"UUID"
		},
		confirmOrder:{
			cartId:"UUID",
			customerId:"UUID",
			channel:"ChannelCode",
			pos:"PosFulfillment"
		},
		updateCustomerInfo:{
			customerId:"UUID",
			relevantCartId:"UUID"
		},
		addAddress:{
			customerId:"UUID",
			cartId:"UUID",
			data:"AddressData"
		},
		editAddress:{
			addressId:"UUID",
			cartId:"UUID",
			data:"AddressData"
		},
		action:{

		},
		redeemVoucher:{

		},
		updateCustomer:{
			by:"Contember_CustomerUniqueWhere",
			filter:"Contember_CustomerWhere",
			data:"Contember_CustomerUpdateInput"
		},
		transaction:{
			options:"Contember_MutationTransactionOptions"
		}
	},
	ChannelDelivery:{
		channel:{
			filter:"Contember_ChannelWhere"
		},
		method:{
			filter:"Contember_DeliveryMethodWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		}
	},
	UUID: "String",
	Address:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		country:{
			filter:"Contember_CountryWhere"
		},
		deliveryZone:{
			filter:"Contember_DeliveryZoneWhere"
		},
		meta:{
			filter:"Contember_AddressMetadataWhere"
		},
		gps:{
			filter:"Contember_GpsWhere"
		},
		invoices:{
			filter:"Contember_InvoiceWhere",
			orderBy:"Contember_InvoiceOrderBy"
		},
		billingAddressOfOrders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		deliveryAddressOfOrderDeliveries:{
			filter:"Contember_OrderDeliveryWhere",
			orderBy:"Contember_OrderDeliveryOrderBy"
		},
		defaultDeliveryAddressOfCustomer:{
			filter:"Contember_CustomerWhere"
		},
		invoicesByPublicKey:{
			by:"Contember_AddressInvoicesByPublicKeyUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		invoicesByFakturoidId:{
			by:"Contember_AddressInvoicesByFakturoidIdUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		invoicesByOrderPayments:{
			by:"Contember_AddressInvoicesByOrderPaymentsUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		billingAddressOfOrdersBySeq:{
			by:"Contember_AddressBillingAddressOfOrdersBySeqUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByDoubleshotLegacyId:{
			by:"Contember_AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByDoubleShotLegacyNumber:{
			by:"Contember_AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByDiscounts:{
			by:"Contember_AddressBillingAddressOfOrdersByDiscountsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByDelivery:{
			by:"Contember_AddressBillingAddressOfOrdersByDeliveryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByItems:{
			by:"Contember_AddressBillingAddressOfOrdersByItemsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByCart:{
			by:"Contember_AddressBillingAddressOfOrdersByCartUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByEvents:{
			by:"Contember_AddressBillingAddressOfOrdersByEventsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByPriceLines:{
			by:"Contember_AddressBillingAddressOfOrdersByPriceLinesUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByPayments:{
			by:"Contember_AddressBillingAddressOfOrdersByPaymentsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersBySummary:{
			by:"Contember_AddressBillingAddressOfOrdersBySummaryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		billingAddressOfOrdersByGratuity:{
			by:"Contember_AddressBillingAddressOfOrdersByGratuityUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		deliveryAddressOfOrderDeliveriesByTrackingCode:{
			by:"Contember_AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere",
			filter:"Contember_OrderDeliveryWhere"
		},
		deliveryAddressOfOrderDeliveriesByOrder:{
			by:"Contember_AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere",
			filter:"Contember_OrderDeliveryWhere"
		},
		deliveryAddressOfOrderDeliveriesByDeliveryWaypoint:{
			by:"Contember_AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere",
			filter:"Contember_OrderDeliveryWhere"
		},
		deliveryAddressOfOrderDeliveriesByEvents:{
			by:"Contember_AddressDeliveryAddressOfOrderDeliveriesByEventsUniqueWhere",
			filter:"Contember_OrderDeliveryWhere"
		},
		deliveryAddressOfOrderDeliveriesByLiftagoRide:{
			by:"Contember_AddressDeliveryAddressOfOrderDeliveriesByLiftagoRideUniqueWhere",
			filter:"Contember_OrderDeliveryWhere"
		},
		paginateInvoices:{
			filter:"Contember_InvoiceWhere",
			orderBy:"Contember_InvoiceOrderBy"
		},
		paginateBillingAddressOfOrders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		paginateDeliveryAddressOfOrderDeliveries:{
			filter:"Contember_OrderDeliveryWhere",
			orderBy:"Contember_OrderDeliveryOrderBy"
		}
	},
	DeliveryTimelineType: true,
	VatRate:{
		channelDeliveries:{
			filter:"Contember_ChannelDeliveryWhere",
			orderBy:"Contember_ChannelDeliveryOrderBy"
		},
		channelPayments:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		},
		paginateChannelDeliveries:{
			filter:"Contember_ChannelDeliveryWhere",
			orderBy:"Contember_ChannelDeliveryOrderBy"
		},
		paginateChannelPayments:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		}
	},
	ChannelCode: true,
	DateTime: "String",
	Product:{
		packing:{
			filter:"Contember_ProductPackingWhere"
		},
		recipe:{
			filter:"Contember_ProductRecipeWhere"
		},
		tags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		categories:{
			filter:"Contember_ProductCategoryWhere",
			orderBy:"Contember_ProductCategoryOrderBy"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		stocks:{
			filter:"Contember_ProductStockWhere",
			orderBy:"Contember_ProductStockOrderBy"
		},
		groupPrices:{
			filter:"Contember_ProductGroupPriceWhere",
			orderBy:"Contember_ProductGroupPriceOrderBy"
		},
		image:{
			filter:"Contember_ImageWhere"
		},
		cartItems:{
			filter:"Contember_CartItemWhere",
			orderBy:"Contember_CartItemOrderBy"
		},
		manager:{
			filter:"Contember_StocksManagerWhere"
		},
		orderItems:{
			filter:"Contember_OrderItemWhere",
			orderBy:"Contember_OrderItemOrderBy"
		},
		inheritedCategories:{
			filter:"Contember_NestedProductCategoryProductWhere",
			orderBy:"Contember_NestedProductCategoryProductOrderBy"
		},
		deliveryZones:{
			filter:"Contember_DeliveryZoneWhere",
			orderBy:"Contember_DeliveryZoneOrderBy"
		},
		locales:{
			filter:"Contember_ProductLocaleWhere",
			orderBy:"Contember_ProductLocaleOrderBy"
		},
		customerPrices:{
			filter:"Contember_ProductCustomerPriceWhere",
			orderBy:"Contember_ProductCustomerPriceOrderBy"
		},
		virtualProduct:{
			filter:"Contember_VirtualProductWhere"
		},
		meta:{
			filter:"Contember_ProductMetadataWhere"
		},
		page:{
			filter:"Contember_PageWhere"
		},
		salesDays:{
			filter:"Contember_ProductSalesDayWhere",
			orderBy:"Contember_ProductSalesDayOrderBy"
		},
		salesWeeks:{
			filter:"Contember_ProductSalesWeekWhere",
			orderBy:"Contember_ProductSalesWeekOrderBy"
		},
		salesMonths:{
			filter:"Contember_ProductSalesMonthWhere",
			orderBy:"Contember_ProductSalesMonthOrderBy"
		},
		fulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		},
		stocksByStore:{
			by:"Contember_ProductStocksByStoreUniqueWhere",
			filter:"Contember_ProductStockWhere"
		},
		groupPricesByGroup:{
			by:"Contember_ProductGroupPricesByGroupUniqueWhere",
			filter:"Contember_ProductGroupPriceWhere"
		},
		cartItemsByCart:{
			by:"Contember_ProductCartItemsByCartUniqueWhere",
			filter:"Contember_CartItemWhere"
		},
		orderItemsByVirtualProductEffects:{
			by:"Contember_ProductOrderItemsByVirtualProductEffectsUniqueWhere",
			filter:"Contember_OrderItemWhere"
		},
		localesByLocale:{
			by:"Contember_ProductLocalesByLocaleUniqueWhere",
			filter:"Contember_ProductLocaleWhere"
		},
		localesByFeatureList:{
			by:"Contember_ProductLocalesByFeatureListUniqueWhere",
			filter:"Contember_ProductLocaleWhere"
		},
		customerPricesByCustomer:{
			by:"Contember_ProductCustomerPricesByCustomerUniqueWhere",
			filter:"Contember_ProductCustomerPriceWhere"
		},
		paginateTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		paginateCategories:{
			filter:"Contember_ProductCategoryWhere",
			orderBy:"Contember_ProductCategoryOrderBy"
		},
		paginateStocks:{
			filter:"Contember_ProductStockWhere",
			orderBy:"Contember_ProductStockOrderBy"
		},
		paginateGroupPrices:{
			filter:"Contember_ProductGroupPriceWhere",
			orderBy:"Contember_ProductGroupPriceOrderBy"
		},
		paginateCartItems:{
			filter:"Contember_CartItemWhere",
			orderBy:"Contember_CartItemOrderBy"
		},
		paginateOrderItems:{
			filter:"Contember_OrderItemWhere",
			orderBy:"Contember_OrderItemOrderBy"
		},
		paginateInheritedCategories:{
			filter:"Contember_NestedProductCategoryProductWhere",
			orderBy:"Contember_NestedProductCategoryProductOrderBy"
		},
		paginateDeliveryZones:{
			filter:"Contember_DeliveryZoneWhere",
			orderBy:"Contember_DeliveryZoneOrderBy"
		},
		paginateLocales:{
			filter:"Contember_ProductLocaleWhere",
			orderBy:"Contember_ProductLocaleOrderBy"
		},
		paginateCustomerPrices:{
			filter:"Contember_ProductCustomerPriceWhere",
			orderBy:"Contember_ProductCustomerPriceOrderBy"
		},
		paginateSalesDays:{
			filter:"Contember_ProductSalesDayWhere",
			orderBy:"Contember_ProductSalesDayOrderBy"
		},
		paginateSalesWeeks:{
			filter:"Contember_ProductSalesWeekWhere",
			orderBy:"Contember_ProductSalesWeekOrderBy"
		},
		paginateSalesMonths:{
			filter:"Contember_ProductSalesMonthWhere",
			orderBy:"Contember_ProductSalesMonthOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		}
	},
	Order:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		channel:{
			filter:"Contember_ChannelWhere"
		},
		discounts:{
			filter:"Contember_OrderDiscountWhere",
			orderBy:"Contember_OrderDiscountOrderBy"
		},
		delivery:{
			filter:"Contember_OrderDeliveryWhere"
		},
		items:{
			filter:"Contember_OrderItemWhere",
			orderBy:"Contember_OrderItemOrderBy"
		},
		cart:{
			filter:"Contember_CartWhere"
		},
		events:{
			filter:"Contember_OrderEventWhere",
			orderBy:"Contember_OrderEventOrderBy"
		},
		billingAddress:{
			filter:"Contember_AddressWhere"
		},
		priceLines:{
			filter:"Contember_OrderPriceLineWhere",
			orderBy:"Contember_OrderPriceLineOrderBy"
		},
		payments:{
			filter:"Contember_OrderPaymentWhere",
			orderBy:"Contember_OrderPaymentOrderBy"
		},
		billingSubject:{
			filter:"Contember_BillingSubjectWhere"
		},
		summary:{
			filter:"Contember_OrderSummaryWhere"
		},
		gratuity:{
			filter:"Contember_GratuityWhere"
		},
		venue:{
			filter:"Contember_VenueWhere"
		},
		fulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		},
		discountsByCustomerCreditTransactions:{
			by:"Contember_OrderDiscountsByCustomerCreditTransactionsUniqueWhere",
			filter:"Contember_OrderDiscountWhere"
		},
		discountsByVoucherRedemption:{
			by:"Contember_OrderDiscountsByVoucherRedemptionUniqueWhere",
			filter:"Contember_OrderDiscountWhere"
		},
		itemsByVirtualProductEffects:{
			by:"Contember_OrderItemsByVirtualProductEffectsUniqueWhere",
			filter:"Contember_OrderItemWhere"
		},
		priceLinesByOrderItem:{
			by:"Contember_OrderPriceLinesByOrderItemUniqueWhere",
			filter:"Contember_OrderPriceLineWhere"
		},
		priceLinesByOrderDiscount:{
			by:"Contember_OrderPriceLinesByOrderDiscountUniqueWhere",
			filter:"Contember_OrderPriceLineWhere"
		},
		priceLinesByOrderPayment:{
			by:"Contember_OrderPriceLinesByOrderPaymentUniqueWhere",
			filter:"Contember_OrderPriceLineWhere"
		},
		priceLinesByOrderDelivery:{
			by:"Contember_OrderPriceLinesByOrderDeliveryUniqueWhere",
			filter:"Contember_OrderPriceLineWhere"
		},
		paymentsByEvents:{
			by:"Contember_OrderPaymentsByEventsUniqueWhere",
			filter:"Contember_OrderPaymentWhere"
		},
		paymentsByMeta:{
			by:"Contember_OrderPaymentsByMetaUniqueWhere",
			filter:"Contember_OrderPaymentWhere"
		},
		paginateDiscounts:{
			filter:"Contember_OrderDiscountWhere",
			orderBy:"Contember_OrderDiscountOrderBy"
		},
		paginateItems:{
			filter:"Contember_OrderItemWhere",
			orderBy:"Contember_OrderItemOrderBy"
		},
		paginateEvents:{
			filter:"Contember_OrderEventWhere",
			orderBy:"Contember_OrderEventOrderBy"
		},
		paginatePriceLines:{
			filter:"Contember_OrderPriceLineWhere",
			orderBy:"Contember_OrderPriceLineOrderBy"
		},
		paginatePayments:{
			filter:"Contember_OrderPaymentWhere",
			orderBy:"Contember_OrderPaymentOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		}
	},
	ChannelPayment:{
		channel:{
			filter:"Contember_ChannelWhere"
		},
		method:{
			filter:"Contember_PaymentMethodWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		allowOnlyForTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		paginateAllowOnlyForTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		}
	},
	Date: "String",
	CustomerCredit:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		transactions:{
			filter:"Contember_CustomerCreditTransactionWhere",
			orderBy:"Contember_CustomerCreditTransactionOrderBy"
		},
		voucherRedemption:{
			filter:"Contember_VoucherRedemptionWhere"
		},
		transactionsByVirtualProductEffect:{
			by:"Contember_CustomerCreditTransactionsByVirtualProductEffectUniqueWhere",
			filter:"Contember_CustomerCreditTransactionWhere"
		},
		paginateTransactions:{
			filter:"Contember_CustomerCreditTransactionWhere",
			orderBy:"Contember_CustomerCreditTransactionOrderBy"
		}
	},
	ToastRatingValue: true,
	CartItemInput:{
		productId:"UUID"
	},
	PosCartInput:{
		customerId:"UUID",
		items:"PosItemInput"
	},
	PosItemInput:{
		productId:"UUID"
	},
	OrderPayment:{
		method:{
			filter:"Contember_PaymentMethodWhere"
		},
		events:{
			filter:"Contember_OrderEventWhere",
			orderBy:"Contember_OrderEventOrderBy"
		},
		order:{
			filter:"Contember_OrderWhere"
		},
		invoice:{
			filter:"Contember_InvoiceWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		meta:{
			filter:"Contember_OrderPaymentMetadataWhere"
		},
		paginateEvents:{
			filter:"Contember_OrderEventWhere",
			orderBy:"Contember_OrderEventOrderBy"
		}
	},
	PosFulfillment:{
		paidAt:"DateTime",
		items:"PosItemInput"
	},
	AddressData:{

	},
	Contember_Customer:{
		addresses:{
			filter:"Contember_AddressWhere",
			orderBy:"Contember_AddressOrderBy"
		},
		defaultBillingAddress:{
			filter:"Contember_AddressWhere"
		},
		defaultPaymentMethod:{
			filter:"Contember_CustomerPaymentMethodWhere"
		},
		tags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		credits:{
			filter:"Contember_CustomerCreditWhere",
			orderBy:"Contember_CustomerCreditOrderBy"
		},
		paymentMethods:{
			filter:"Contember_CustomerPaymentMethodWhere",
			orderBy:"Contember_CustomerPaymentMethodOrderBy"
		},
		orders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		permissions:{
			filter:"Contember_CustomerPermissionsWhere"
		},
		group:{
			filter:"Contember_CustomerGroupWhere"
		},
		photo:{
			filter:"Contember_ImageWhere"
		},
		name:{
			filter:"Contember_CustomerNameWhere"
		},
		ingredientRatings:{
			filter:"Contember_CustomerIngredientRatingWhere",
			orderBy:"Contember_CustomerIngredientRatingOrderBy"
		},
		business:{
			filter:"Contember_BusinessCustomerWhere"
		},
		ownerOfBusinesses:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		},
		memberOfBusinesses:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		},
		carts:{
			filter:"Contember_CartWhere",
			orderBy:"Contember_CartOrderBy"
		},
		shortcutList:{
			filter:"Contember_ApplicationShortcutListWhere"
		},
		user:{
			filter:"Contember_UserWhere"
		},
		channels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		invoices:{
			filter:"Contember_InvoiceWhere",
			orderBy:"Contember_InvoiceOrderBy"
		},
		invoicingPlan:{
			filter:"Contember_InvoicingPlanWhere"
		},
		billingSubjects:{
			filter:"Contember_BillingSubjectWhere",
			orderBy:"Contember_BillingSubjectOrderBy"
		},
		offeredProducts:{
			filter:"Contember_ProductCustomerPriceWhere",
			orderBy:"Contember_ProductCustomerPriceOrderBy"
		},
		defaultDeliveryAddress:{
			filter:"Contember_AddressWhere"
		},
		defaultBillingSubject:{
			filter:"Contember_BillingSubjectWhere"
		},
		anonymousSession:{
			filter:"Contember_AnonymousSessionWhere"
		},
		boughtVouchers:{
			filter:"Contember_VoucherWhere",
			orderBy:"Contember_VoucherOrderBy"
		},
		ownsVouchers:{
			filter:"Contember_VoucherWhere",
			orderBy:"Contember_VoucherOrderBy"
		},
		redeemedVouchers:{
			filter:"Contember_VoucherRedemptionWhere",
			orderBy:"Contember_VoucherRedemptionOrderBy"
		},
		spending:{
			filter:"Contember_CustomerSpendingWhere"
		},
		customerTabs:{
			filter:"Contember_CustomerTabWhere",
			orderBy:"Contember_CustomerTabOrderBy"
		},
		lead:{
			filter:"Contember_PipelineLeadWhere",
			orderBy:"Contember_PipelineLeadOrderBy"
		},
		meta:{
			filter:"Contember_CustomerMetadataWhere"
		},
		addressesByMeta:{
			by:"Contember_CustomerAddressesByMetaUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByGps:{
			by:"Contember_CustomerAddressesByGpsUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByInvoices:{
			by:"Contember_CustomerAddressesByInvoicesUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByBillingAddressOfOrders:{
			by:"Contember_CustomerAddressesByBillingAddressOfOrdersUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByDeliveryAddressOfOrderDeliveries:{
			by:"Contember_CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByDefaultDeliveryAddressOfCustomer:{
			by:"Contember_CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		creditsByTransactions:{
			by:"Contember_CustomerCreditsByTransactionsUniqueWhere",
			filter:"Contember_CustomerCreditWhere"
		},
		creditsByVoucherRedemption:{
			by:"Contember_CustomerCreditsByVoucherRedemptionUniqueWhere",
			filter:"Contember_CustomerCreditWhere"
		},
		ordersBySeq:{
			by:"Contember_CustomerOrdersBySeqUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDoubleshotLegacyId:{
			by:"Contember_CustomerOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDoubleShotLegacyNumber:{
			by:"Contember_CustomerOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDiscounts:{
			by:"Contember_CustomerOrdersByDiscountsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDelivery:{
			by:"Contember_CustomerOrdersByDeliveryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByItems:{
			by:"Contember_CustomerOrdersByItemsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByCart:{
			by:"Contember_CustomerOrdersByCartUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByEvents:{
			by:"Contember_CustomerOrdersByEventsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByPriceLines:{
			by:"Contember_CustomerOrdersByPriceLinesUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByPayments:{
			by:"Contember_CustomerOrdersByPaymentsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersBySummary:{
			by:"Contember_CustomerOrdersBySummaryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByGratuity:{
			by:"Contember_CustomerOrdersByGratuityUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ingredientRatingsByIngredient:{
			by:"Contember_CustomerIngredientRatingsByIngredientUniqueWhere",
			filter:"Contember_CustomerIngredientRatingWhere"
		},
		cartsByItems:{
			by:"Contember_CustomerCartsByItemsUniqueWhere",
			filter:"Contember_CartWhere"
		},
		cartsByOrder:{
			by:"Contember_CustomerCartsByOrderUniqueWhere",
			filter:"Contember_CartWhere"
		},
		cartsByPriceLines:{
			by:"Contember_CustomerCartsByPriceLinesUniqueWhere",
			filter:"Contember_CartWhere"
		},
		cartsBySummary:{
			by:"Contember_CustomerCartsBySummaryUniqueWhere",
			filter:"Contember_CartWhere"
		},
		invoicesByPublicKey:{
			by:"Contember_CustomerInvoicesByPublicKeyUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		invoicesByFakturoidId:{
			by:"Contember_CustomerInvoicesByFakturoidIdUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		invoicesByOrderPayments:{
			by:"Contember_CustomerInvoicesByOrderPaymentsUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		billingSubjectsByInvoices:{
			by:"Contember_CustomerBillingSubjectsByInvoicesUniqueWhere",
			filter:"Contember_BillingSubjectWhere"
		},
		billingSubjectsByDefaultBillingSubjectOfCustomer:{
			by:"Contember_CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere",
			filter:"Contember_BillingSubjectWhere"
		},
		billingSubjectsByOrders:{
			by:"Contember_CustomerBillingSubjectsByOrdersUniqueWhere",
			filter:"Contember_BillingSubjectWhere"
		},
		offeredProductsByProduct:{
			by:"Contember_CustomerOfferedProductsByProductUniqueWhere",
			filter:"Contember_ProductCustomerPriceWhere"
		},
		boughtVouchersByCode:{
			by:"Contember_CustomerBoughtVouchersByCodeUniqueWhere",
			filter:"Contember_VoucherWhere"
		},
		boughtVouchersByRedemption:{
			by:"Contember_CustomerBoughtVouchersByRedemptionUniqueWhere",
			filter:"Contember_VoucherWhere"
		},
		ownsVouchersByCode:{
			by:"Contember_CustomerOwnsVouchersByCodeUniqueWhere",
			filter:"Contember_VoucherWhere"
		},
		ownsVouchersByRedemption:{
			by:"Contember_CustomerOwnsVouchersByRedemptionUniqueWhere",
			filter:"Contember_VoucherWhere"
		},
		redeemedVouchersByCustomerCredit:{
			by:"Contember_CustomerRedeemedVouchersByCustomerCreditUniqueWhere",
			filter:"Contember_VoucherRedemptionWhere"
		},
		redeemedVouchersByOrderDiscount:{
			by:"Contember_CustomerRedeemedVouchersByOrderDiscountUniqueWhere",
			filter:"Contember_VoucherRedemptionWhere"
		},
		redeemedVouchersByVoucher:{
			by:"Contember_CustomerRedeemedVouchersByVoucherUniqueWhere",
			filter:"Contember_VoucherRedemptionWhere"
		},
		customerTabsByItems:{
			by:"Contember_CustomerCustomerTabsByItemsUniqueWhere",
			filter:"Contember_CustomerTabWhere"
		},
		customerTabsByMeta:{
			by:"Contember_CustomerCustomerTabsByMetaUniqueWhere",
			filter:"Contember_CustomerTabWhere"
		},
		leadByStageItem:{
			by:"Contember_CustomerLeadByStageItemUniqueWhere",
			filter:"Contember_PipelineLeadWhere"
		},
		paginateAddresses:{
			filter:"Contember_AddressWhere",
			orderBy:"Contember_AddressOrderBy"
		},
		paginateTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		paginateCredits:{
			filter:"Contember_CustomerCreditWhere",
			orderBy:"Contember_CustomerCreditOrderBy"
		},
		paginatePaymentMethods:{
			filter:"Contember_CustomerPaymentMethodWhere",
			orderBy:"Contember_CustomerPaymentMethodOrderBy"
		},
		paginateOrders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		paginateIngredientRatings:{
			filter:"Contember_CustomerIngredientRatingWhere",
			orderBy:"Contember_CustomerIngredientRatingOrderBy"
		},
		paginateOwnerOfBusinesses:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		},
		paginateMemberOfBusinesses:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		},
		paginateCarts:{
			filter:"Contember_CartWhere",
			orderBy:"Contember_CartOrderBy"
		},
		paginateChannels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		paginateInvoices:{
			filter:"Contember_InvoiceWhere",
			orderBy:"Contember_InvoiceOrderBy"
		},
		paginateBillingSubjects:{
			filter:"Contember_BillingSubjectWhere",
			orderBy:"Contember_BillingSubjectOrderBy"
		},
		paginateOfferedProducts:{
			filter:"Contember_ProductCustomerPriceWhere",
			orderBy:"Contember_ProductCustomerPriceOrderBy"
		},
		paginateBoughtVouchers:{
			filter:"Contember_VoucherWhere",
			orderBy:"Contember_VoucherOrderBy"
		},
		paginateOwnsVouchers:{
			filter:"Contember_VoucherWhere",
			orderBy:"Contember_VoucherOrderBy"
		},
		paginateRedeemedVouchers:{
			filter:"Contember_VoucherRedemptionWhere",
			orderBy:"Contember_VoucherRedemptionOrderBy"
		},
		paginateCustomerTabs:{
			filter:"Contember_CustomerTabWhere",
			orderBy:"Contember_CustomerTabOrderBy"
		},
		paginateLead:{
			filter:"Contember_PipelineLeadWhere",
			orderBy:"Contember_PipelineLeadOrderBy"
		}
	},
	Contember_AddressWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		firstName:"Contember_StringCondition",
		lastName:"Contember_StringCondition",
		addressLine2:"Contember_StringCondition",
		email:"Contember_StringCondition",
		phone:"Contember_StringCondition",
		companyName:"Contember_StringCondition",
		companyIdentifier:"Contember_StringCondition",
		vatIdentifier:"Contember_StringCondition",
		externalIdentifier:"Contember_StringCondition",
		hidden:"Contember_BooleanCondition",
		customer:"Contember_CustomerWhere",
		internalNote:"Contember_StringCondition",
		country:"Contember_CountryWhere",
		deletedAt:"Contember_DateTimeCondition",
		note:"Contember_StringCondition",
		googlePlaceId:"Contember_StringCondition",
		raw:"Contember_StringCondition",
		formatted:"Contember_StringCondition",
		geocodeGeneratedAt:"Contember_DateTimeCondition",
		geocodeResponse:"Contember_StringCondition",
		deliveryZone:"Contember_DeliveryZoneWhere",
		meta:"Contember_AddressMetadataWhere",
		gps:"Contember_GpsWhere",
		geocodeValid:"Contember_BooleanCondition",
		fullName:"Contember_StringCondition",
		fakturoidSubjectId:"Contember_StringCondition",
		invoices:"Contember_InvoiceWhere",
		addressLine1:"Contember_StringCondition",
		city:"Contember_StringCondition",
		postalCode:"Contember_StringCondition",
		createdAt:"Contember_DateTimeCondition",
		billingAddressOfOrders:"Contember_OrderWhere",
		deliveryAddressOfOrderDeliveries:"Contember_OrderDeliveryWhere",
		defaultDeliveryAddressOfCustomer:"Contember_CustomerWhere",
		and:"Contember_AddressWhere",
		or:"Contember_AddressWhere",
		not:"Contember_AddressWhere"
	},
	Contember_UUIDCondition:{
		and:"Contember_UUIDCondition",
		or:"Contember_UUIDCondition",
		not:"Contember_UUIDCondition",
		eq:"UUID",
		notEq:"UUID",
		in:"UUID",
		notIn:"UUID",
		lt:"UUID",
		lte:"UUID",
		gt:"UUID",
		gte:"UUID"
	},
	Contember_StringCondition:{
		and:"Contember_StringCondition",
		or:"Contember_StringCondition",
		not:"Contember_StringCondition"
	},
	Contember_BooleanCondition:{
		and:"Contember_BooleanCondition",
		or:"Contember_BooleanCondition",
		not:"Contember_BooleanCondition"
	},
	Contember_CustomerWhere:{
		id:"Contember_UUIDCondition",
		addresses:"Contember_AddressWhere",
		defaultBillingAddress:"Contember_AddressWhere",
		defaultPaymentMethod:"Contember_CustomerPaymentMethodWhere",
		tags:"Contember_TagWhere",
		credits:"Contember_CustomerCreditWhere",
		paymentMethods:"Contember_CustomerPaymentMethodWhere",
		orders:"Contember_OrderWhere",
		permissions:"Contember_CustomerPermissionsWhere",
		group:"Contember_CustomerGroupWhere",
		photo:"Contember_ImageWhere",
		phone:"Contember_StringCondition",
		name:"Contember_CustomerNameWhere",
		internalNote:"Contember_StringCondition",
		welcomeMessage:"Contember_StringCondition",
		familiarity:"Contember_CustomerFamiliarityCondition",
		ingredientRatings:"Contember_CustomerIngredientRatingWhere",
		business:"Contember_BusinessCustomerWhere",
		ownerOfBusinesses:"Contember_BusinessCustomerWhere",
		memberOfBusinesses:"Contember_BusinessCustomerWhere",
		carts:"Contember_CartWhere",
		shortcutList:"Contember_ApplicationShortcutListWhere",
		user:"Contember_UserWhere",
		channels:"Contember_ChannelWhere",
		disabledAt:"Contember_DateTimeCondition",
		invoices:"Contember_InvoiceWhere",
		invoicingPlan:"Contember_InvoicingPlanWhere",
		email:"Contember_StringCondition",
		createdAt:"Contember_DateTimeCondition",
		billingSubjects:"Contember_BillingSubjectWhere",
		offeredProducts:"Contember_ProductCustomerPriceWhere",
		defaultDeliveryAddress:"Contember_AddressWhere",
		defaultBillingSubject:"Contember_BillingSubjectWhere",
		prefersPackingsWithoutCardboard:"Contember_BooleanCondition",
		anonymousSession:"Contember_AnonymousSessionWhere",
		boughtVouchers:"Contember_VoucherWhere",
		ownsVouchers:"Contember_VoucherWhere",
		redeemedVouchers:"Contember_VoucherRedemptionWhere",
		spending:"Contember_CustomerSpendingWhere",
		customerTabs:"Contember_CustomerTabWhere",
		lead:"Contember_PipelineLeadWhere",
		doubleshotLegacyId:"Contember_StringCondition",
		meta:"Contember_CustomerMetadataWhere",
		doubleshotLastUpdatedAt:"Contember_DateTimeCondition",
		and:"Contember_CustomerWhere",
		or:"Contember_CustomerWhere",
		not:"Contember_CustomerWhere"
	},
	Contember_CustomerPaymentMethodWhere:{
		id:"Contember_UUIDCondition",
		caption:"Contember_StringCondition",
		data:"Contember_JsonCondition",
		customer:"Contember_CustomerWhere",
		paymentMethod:"Contember_PaymentMethodWhere",
		and:"Contember_CustomerPaymentMethodWhere",
		or:"Contember_CustomerPaymentMethodWhere",
		not:"Contember_CustomerPaymentMethodWhere"
	},
	Contember_JsonCondition:{
		and:"Contember_JsonCondition",
		or:"Contember_JsonCondition",
		not:"Contember_JsonCondition"
	},
	Contember_PaymentMethodWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		enabled:"Contember_BooleanCondition",
		deliveryMethods:"Contember_DeliveryMethodWhere",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		blockingDispatch:"Contember_BooleanCondition",
		type:"Contember_PaymentMethodTypeCondition",
		channels:"Contember_ChannelPaymentWhere",
		reduceGratuityByPercentage:"Contember_IntCondition",
		and:"Contember_PaymentMethodWhere",
		or:"Contember_PaymentMethodWhere",
		not:"Contember_PaymentMethodWhere"
	},
	Contember_DeliveryMethodWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		enabled:"Contember_BooleanCondition",
		paymentMethods:"Contember_PaymentMethodWhere",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		requireDeliveryAddress:"Contember_BooleanCondition",
		suitableForDeliveryZones:"Contember_DeliveryZoneWhere",
		channels:"Contember_ChannelDeliveryWhere",
		courierHandlers:"Contember_CourierWhere",
		enableDeliveryTracking:"Contember_BooleanCondition",
		locales:"Contember_DeliveryMethodLocaleWhere",
		timelinePreset:"Contember_DeliveryTimelinePresetWhere",
		and:"Contember_DeliveryMethodWhere",
		or:"Contember_DeliveryMethodWhere",
		not:"Contember_DeliveryMethodWhere"
	},
	Contember_DeliveryZoneWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		type:"Contember_DeliveryZoneTypeCondition",
		addresses:"Contember_AddressWhere",
		suitableDeliveryMethods:"Contember_DeliveryMethodWhere",
		deliveryZonesOfTags:"Contember_TagWhere",
		deliveryZonesOfProducts:"Contember_ProductWhere",
		addressesMetadata:"Contember_AddressMetadataWhere",
		and:"Contember_DeliveryZoneWhere",
		or:"Contember_DeliveryZoneWhere",
		not:"Contember_DeliveryZoneWhere"
	},
	Contember_DeliveryZoneTypeCondition:{
		and:"Contember_DeliveryZoneTypeCondition",
		or:"Contember_DeliveryZoneTypeCondition",
		not:"Contember_DeliveryZoneTypeCondition",
		eq:"Contember_DeliveryZoneType",
		notEq:"Contember_DeliveryZoneType",
		in:"Contember_DeliveryZoneType",
		notIn:"Contember_DeliveryZoneType",
		lt:"Contember_DeliveryZoneType",
		lte:"Contember_DeliveryZoneType",
		gt:"Contember_DeliveryZoneType",
		gte:"Contember_DeliveryZoneType"
	},
	Contember_DeliveryZoneType: true,
	Contember_TagWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		customers:"Contember_CustomerWhere",
		products:"Contember_ProductWhere",
		categories:"Contember_ProductCategoryWhere",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		addTagsOnRegistration:"Contember_ChannelWhere",
		nestedCategories:"Contember_InheritedProductCategoryTagWhere",
		deliveryZones:"Contember_DeliveryZoneWhere",
		publicInChannels:"Contember_ChannelWhere",
		allowChannelPayments:"Contember_ChannelPaymentWhere",
		fulfillmentNotes:"Contember_FulfillmentNoteWhere",
		and:"Contember_TagWhere",
		or:"Contember_TagWhere",
		not:"Contember_TagWhere"
	},
	Contember_ProductWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		priceCents:"Contember_IntCondition",
		notDiscountable:"Contember_BooleanCondition",
		packing:"Contember_ProductPackingWhere",
		recipe:"Contember_ProductRecipeWhere",
		tags:"Contember_TagWhere",
		categories:"Contember_ProductCategoryWhere",
		vatRate:"Contember_VatRateWhere",
		stocks:"Contember_ProductStockWhere",
		groupPrices:"Contember_ProductGroupPriceWhere",
		image:"Contember_ImageWhere",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		cartItems:"Contember_CartItemWhere",
		availability:"Contember_ProductAvailabilityCondition",
		order:"Contember_FloatCondition",
		manager:"Contember_StocksManagerWhere",
		orderItems:"Contember_OrderItemWhere",
		maximumQuantityPerOrder:"Contember_IntCondition",
		inheritedCategories:"Contember_NestedProductCategoryProductWhere",
		deliveryZones:"Contember_DeliveryZoneWhere",
		locales:"Contember_ProductLocaleWhere",
		archivedAt:"Contember_DateTimeCondition",
		customerPrices:"Contember_ProductCustomerPriceWhere",
		virtualProduct:"Contember_VirtualProductWhere",
		meta:"Contember_ProductMetadataWhere",
		page:"Contember_PageWhere",
		salesDays:"Contember_ProductSalesDayWhere",
		salesWeeks:"Contember_ProductSalesWeekWhere",
		salesMonths:"Contember_ProductSalesMonthWhere",
		fulfillmentNotes:"Contember_FulfillmentNoteWhere",
		hasSubscription:"Contember_BooleanCondition",
		pointOfSaleTileColor:"Contember_StringCondition",
		and:"Contember_ProductWhere",
		or:"Contember_ProductWhere",
		not:"Contember_ProductWhere"
	},
	Contember_IntCondition:{
		and:"Contember_IntCondition",
		or:"Contember_IntCondition",
		not:"Contember_IntCondition"
	},
	Contember_ProductPackingWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		image:"Contember_ImageWhere",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		volumeMl:"Contember_IntCondition",
		icon:"Contember_ImageWhere",
		shortName:"Contember_StringCondition",
		locales:"Contember_ProductPackingLocaleWhere",
		preparedPackingQuantities:"Contember_PreparedPackingQuantityWhere",
		isPackedInCardboard:"Contember_BooleanCondition",
		and:"Contember_ProductPackingWhere",
		or:"Contember_ProductPackingWhere",
		not:"Contember_ProductPackingWhere"
	},
	Contember_ImageWhere:{
		id:"Contember_UUIDCondition",
		url:"Contember_StringCondition",
		width:"Contember_IntCondition",
		height:"Contember_IntCondition",
		size:"Contember_IntCondition",
		type:"Contember_StringCondition",
		alt:"Contember_StringCondition",
		and:"Contember_ImageWhere",
		or:"Contember_ImageWhere",
		not:"Contember_ImageWhere"
	},
	Contember_ProductPackingLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		shortName:"Contember_StringCondition",
		description:"Contember_StringCondition",
		root:"Contember_ProductPackingWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_ProductPackingLocaleWhere",
		or:"Contember_ProductPackingLocaleWhere",
		not:"Contember_ProductPackingLocaleWhere"
	},
	Contember_LocaleWhere:{
		id:"Contember_UUIDCondition",
		businessCategories:"Contember_BusinessCategoryLocaleWhere",
		productCategoryLists:"Contember_ProductCategoryListLocaleWhere",
		productIngredients:"Contember_ProductIngredientLocaleWhere",
		orderRecurrences:"Contember_OrderRecurrenceLocaleWhere",
		blogs:"Contember_BlogLocaleWhere",
		blogPosts:"Contember_BlogPostLocaleWhere",
		identifier:"Contember_TranslationCataloguesIdentifierWhere",
		menus:"Contember_MenuLocaleWhere",
		pages:"Contember_PageLocaleWhere",
		productIngredientCategories:"Contember_ProductIngredientCategoryLocaleWhere",
		allergens:"Contember_AllergenLocaleWhere",
		productPackings:"Contember_ProductPackingLocaleWhere",
		products:"Contember_ProductLocaleWhere",
		productRecipes:"Contember_ProductRecipeLocaleWhere",
		pointOfSaleCategories:"Contember_PointOfSaleCategoryLocaleWhere",
		pointOfSaleCategoryProducts:"Contember_PointOfSaleCategoryProductLocaleWhere",
		deliveryMethods:"Contember_DeliveryMethodLocaleWhere",
		ingredientSuppliers:"Contember_IngredientSupplierLocaleWhere",
		identificationCodes:"Contember_IdentificationCodeLocaleWhere",
		pipelineStages:"Contember_PipelineStageLocaleWhere",
		subscriptionBoxes:"Contember_SubscriptionBoxLocaleWhere",
		businessServices:"Contember_BusinessServiceLocaleWhere",
		and:"Contember_LocaleWhere",
		or:"Contember_LocaleWhere",
		not:"Contember_LocaleWhere"
	},
	Contember_BusinessCategoryLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_BusinessCategoryWhere",
		locale:"Contember_LocaleWhere",
		title:"Contember_StringCondition",
		and:"Contember_BusinessCategoryLocaleWhere",
		or:"Contember_BusinessCategoryLocaleWhere",
		not:"Contember_BusinessCategoryLocaleWhere"
	},
	Contember_BusinessCategoryWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		visibleToNewCustomers:"Contember_BooleanCondition",
		locales:"Contember_BusinessCategoryLocaleWhere",
		internalName:"Contember_StringCondition",
		businessCustomers:"Contember_BusinessCustomerWhere",
		recurrences:"Contember_OrderRecurrenceWhere",
		specificProducts:"Contember_ProductListWhere",
		seasonalProductCategories:"Contember_ProductCategoryListWhere",
		specificProductsProductCategories:"Contember_ProductCategoryListWhere",
		slug:"Contember_StringCondition",
		and:"Contember_BusinessCategoryWhere",
		or:"Contember_BusinessCategoryWhere",
		not:"Contember_BusinessCategoryWhere"
	},
	Contember_BusinessCustomerWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		owners:"Contember_CustomerWhere",
		members:"Contember_CustomerWhere",
		category:"Contember_BusinessCategoryWhere",
		accountManager:"Contember_BusinessToBusinessManagerWhere",
		logo:"Contember_ImageWhere",
		note:"Contember_StringCondition",
		businessServiceEvents:"Contember_BusinessServiceEventWhere",
		doubleshotCoffeeEquipment:"Contember_DoubleshotCoffeeEquipmentWhere",
		and:"Contember_BusinessCustomerWhere",
		or:"Contember_BusinessCustomerWhere",
		not:"Contember_BusinessCustomerWhere"
	},
	Contember_BusinessToBusinessManagerWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		managedBusinesses:"Contember_BusinessCustomerWhere",
		createdAt:"Contember_DateTimeCondition",
		and:"Contember_BusinessToBusinessManagerWhere",
		or:"Contember_BusinessToBusinessManagerWhere",
		not:"Contember_BusinessToBusinessManagerWhere"
	},
	Contember_StaffWhere:{
		id:"Contember_UUIDCondition",
		bartender:"Contember_BartenderWhere",
		courier:"Contember_CourierWhere",
		cleaner:"Contember_CleanerWhere",
		shiftsManager:"Contember_ShiftsManagerWhere",
		businessToBusinessManager:"Contember_BusinessToBusinessManagerWhere",
		user:"Contember_UserWhere",
		shiftsProfile:"Contember_ShiftsProfileWhere",
		stocksManager:"Contember_StocksManagerWhere",
		photo:"Contember_ImageWhere",
		courierManager:"Contember_CourierManagerWhere",
		picker:"Contember_PickerWhere",
		createdAt:"Contember_DateTimeCondition",
		remunerationProfile:"Contember_RemunerationProfileWhere",
		cashier:"Contember_CashierWhere",
		venues:"Contember_VenueWhere",
		internalBuyer:"Contember_InternalBuyerWhere",
		salesManager:"Contember_SalesManagerWhere",
		cashierManager:"Contember_CashierManagerWhere",
		and:"Contember_StaffWhere",
		or:"Contember_StaffWhere",
		not:"Contember_StaffWhere"
	},
	Contember_BartenderWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		createdAt:"Contember_DateTimeCondition",
		and:"Contember_BartenderWhere",
		or:"Contember_BartenderWhere",
		not:"Contember_BartenderWhere"
	},
	Contember_DateTimeCondition:{
		and:"Contember_DateTimeCondition",
		or:"Contember_DateTimeCondition",
		not:"Contember_DateTimeCondition",
		eq:"DateTime",
		notEq:"DateTime",
		in:"DateTime",
		notIn:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		gt:"DateTime",
		gte:"DateTime"
	},
	Contember_CourierWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		routes:"Contember_DeliveryRouteWhere",
		createdAt:"Contember_DateTimeCondition",
		deliveryMethods:"Contember_DeliveryMethodWhere",
		showInPicker:"Contember_BooleanCondition",
		and:"Contember_CourierWhere",
		or:"Contember_CourierWhere",
		not:"Contember_CourierWhere"
	},
	Contember_DeliveryRouteWhere:{
		id:"Contember_UUIDCondition",
		courier:"Contember_CourierWhere",
		createdAt:"Contember_DateTimeCondition",
		waypoints:"Contember_DeliveryWaypointWhere",
		dispatchAt:"Contember_StringCondition",
		and:"Contember_DeliveryRouteWhere",
		or:"Contember_DeliveryRouteWhere",
		not:"Contember_DeliveryRouteWhere"
	},
	Contember_DeliveryWaypointWhere:{
		id:"Contember_UUIDCondition",
		estimatedArrival:"Contember_DateTimeCondition",
		note:"Contember_StringCondition",
		route:"Contember_DeliveryRouteWhere",
		sortingOrder:"Contember_IntCondition",
		delivery:"Contember_OrderDeliveryWhere",
		estimatedDistance:"Contember_IntCondition",
		estimatedDuration:"Contember_IntCondition",
		originDeparture:"Contember_DateTimeCondition",
		originAddress:"Contember_StringCondition",
		transitMode:"Contember_StringCondition",
		and:"Contember_DeliveryWaypointWhere",
		or:"Contember_DeliveryWaypointWhere",
		not:"Contember_DeliveryWaypointWhere"
	},
	Contember_OrderDeliveryWhere:{
		id:"Contember_UUIDCondition",
		priceCents:"Contember_IntCondition",
		deliveredAt:"Contember_DateTimeCondition",
		method:"Contember_DeliveryMethodWhere",
		vatRate:"Contember_VatRateWhere",
		order:"Contember_OrderWhere",
		expectedAt:"Contember_DateTimeCondition",
		state:"Contember_OrderDeliveryStateCondition",
		loadingDurationHours:"Contember_IntCondition",
		dispatchAt:"Contember_DateTimeCondition",
		duration:"Contember_StringCondition",
		window:"Contember_StringCondition",
		expectedEndAt:"Contember_DateTimeCondition",
		processingAt:"Contember_DateTimeCondition",
		deliveringAt:"Contember_DateTimeCondition",
		failedAt:"Contember_DateTimeCondition",
		internalNote:"Contember_StringCondition",
		deliveryWaypoint:"Contember_DeliveryWaypointWhere",
		events:"Contember_OrderEventWhere",
		prepareAt:"Contember_DateTimeCondition",
		liftagoRide:"Contember_LiftagoRideWhere",
		pickedAt:"Contember_DateTimeCondition",
		address:"Contember_AddressWhere",
		trackingCode:"Contember_StringCondition",
		and:"Contember_OrderDeliveryWhere",
		or:"Contember_OrderDeliveryWhere",
		not:"Contember_OrderDeliveryWhere"
	},
	Contember_VatRateWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		ratePermille:"Contember_IntCondition",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		channelDeliveries:"Contember_ChannelDeliveryWhere",
		channelPayments:"Contember_ChannelPaymentWhere",
		preset:"Contember_VatRatePresetCondition",
		and:"Contember_VatRateWhere",
		or:"Contember_VatRateWhere",
		not:"Contember_VatRateWhere"
	},
	Contember_ChannelDeliveryWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		priceCents:"Contember_IntCondition",
		channel:"Contember_ChannelWhere",
		method:"Contember_DeliveryMethodWhere",
		vatRate:"Contember_VatRateWhere",
		defaultVisible:"Contember_BooleanCondition",
		and:"Contember_ChannelDeliveryWhere",
		or:"Contember_ChannelDeliveryWhere",
		not:"Contember_ChannelDeliveryWhere"
	},
	Contember_ChannelWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		disabled:"Contember_BooleanCondition",
		payments:"Contember_ChannelPaymentWhere",
		deliveries:"Contember_ChannelDeliveryWhere",
		pointOfSales:"Contember_PointOfSaleWhere",
		carts:"Contember_CartWhere",
		customers:"Contember_CustomerWhere",
		code:"Contember_ChannelCodeCondition",
		addTagsOnRegistration:"Contember_TagWhere",
		publicTags:"Contember_TagWhere",
		venue:"Contember_VenueWhere",
		and:"Contember_ChannelWhere",
		or:"Contember_ChannelWhere",
		not:"Contember_ChannelWhere"
	},
	Contember_ChannelPaymentWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		channel:"Contember_ChannelWhere",
		method:"Contember_PaymentMethodWhere",
		vatRate:"Contember_VatRateWhere",
		requireBillingAddress:"Contember_BooleanCondition",
		provideReceipt:"Contember_BooleanCondition",
		requireBillingSubject:"Contember_BooleanCondition",
		allowOnlyForTags:"Contember_TagWhere",
		and:"Contember_ChannelPaymentWhere",
		or:"Contember_ChannelPaymentWhere",
		not:"Contember_ChannelPaymentWhere"
	},
	Contember_PointOfSaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		channels:"Contember_ChannelWhere",
		permissions:"Contember_PointOfSalePermissionsWhere",
		tenantPersonId:"Contember_UUIDCondition",
		and:"Contember_PointOfSaleWhere",
		or:"Contember_PointOfSaleWhere",
		not:"Contember_PointOfSaleWhere"
	},
	Contember_PointOfSalePermissionsWhere:{
		id:"Contember_UUIDCondition",
		canPlaceOrder:"Contember_BooleanCondition",
		pointOfSale:"Contember_PointOfSaleWhere",
		and:"Contember_PointOfSalePermissionsWhere",
		or:"Contember_PointOfSalePermissionsWhere",
		not:"Contember_PointOfSalePermissionsWhere"
	},
	Contember_CartWhere:{
		id:"Contember_UUIDCondition",
		confirmedAt:"Contember_DateTimeCondition",
		deletedAt:"Contember_DateTimeCondition",
		note:"Contember_StringCondition",
		items:"Contember_CartItemWhere",
		handle:"Contember_StringCondition",
		state:"Contember_CartStateCondition",
		createdAt:"Contember_DateTimeCondition",
		channel:"Contember_ChannelWhere",
		order:"Contember_OrderWhere",
		customer:"Contember_CustomerWhere",
		deliveryAddress:"Contember_AddressWhere",
		billingAddress:"Contember_AddressWhere",
		deliveryOption:"Contember_StringCondition",
		paymentOption:"Contember_StringCondition",
		delivery:"Contember_ChannelDeliveryWhere",
		payment:"Contember_ChannelPaymentWhere",
		billingSubject:"Contember_BillingSubjectWhere",
		priceLines:"Contember_CartPriceLineWhere",
		summary:"Contember_CartSummaryWhere",
		and:"Contember_CartWhere",
		or:"Contember_CartWhere",
		not:"Contember_CartWhere"
	},
	Contember_CartItemWhere:{
		id:"Contember_UUIDCondition",
		quantity:"Contember_IntCondition",
		cart:"Contember_CartWhere",
		product:"Contember_ProductWhere",
		createdAt:"Contember_DateTimeCondition",
		updatedAt:"Contember_DateTimeCondition",
		fulfillmentNote:"Contember_StringCondition",
		fulfillmentNotes:"Contember_FulfillmentNoteWhere",
		and:"Contember_CartItemWhere",
		or:"Contember_CartItemWhere",
		not:"Contember_CartItemWhere"
	},
	Contember_FulfillmentNoteWhere:{
		id:"Contember_UUIDCondition",
		note:"Contember_StringCondition",
		products:"Contember_ProductWhere",
		tags:"Contember_TagWhere",
		venues:"Contember_VenueWhere",
		cartItems:"Contember_CartItemWhere",
		orderItems:"Contember_OrderItemWhere",
		orders:"Contember_OrderWhere",
		highlight:"Contember_BooleanCondition",
		createdAt:"Contember_DateTimeCondition",
		emoji:"Contember_StringCondition",
		and:"Contember_FulfillmentNoteWhere",
		or:"Contember_FulfillmentNoteWhere",
		not:"Contember_FulfillmentNoteWhere"
	},
	Contember_VenueWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		deadlineTemplate:"Contember_DeadlineTemplateWhere",
		timetableDays:"Contember_TimetableDayWhere",
		timetableTemplates:"Contember_TimetableTemplateWhere",
		staffMembers:"Contember_StaffWhere",
		employeeRoles:"Contember_VenueEmployeeRoleWhere",
		pointOfSaleApplicationDevices:"Contember_PointOfSaleApplicationDeviceWhere",
		pointOfSaleTerminals:"Contember_PointOfSaleTerminalWhere",
		pointOfSalePrinters:"Contember_PointOfSalePrinterWhere",
		orders:"Contember_OrderWhere",
		fulfillmentNotes:"Contember_FulfillmentNoteWhere",
		areas:"Contember_AreaWhere",
		enabledUnassignReasons:"Contember_UnassignReasonWhere",
		categories:"Contember_PointOfSaleCategoryWhere",
		channels:"Contember_ChannelWhere",
		address:"Contember_StringCondition",
		pointOfSalePngBase64ReceiptLogo:"Contember_StringCondition",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialViewCondition",
		pointOfSalePreferPrintReceipt:"Contember_BooleanCondition",
		and:"Contember_VenueWhere",
		or:"Contember_VenueWhere",
		not:"Contember_VenueWhere"
	},
	Contember_DeadlineTemplateWhere:{
		id:"Contember_UUIDCondition",
		startDate:"Contember_DateCondition",
		period:"Contember_IntCondition",
		cutoff:"Contember_IntCondition",
		closed:"Contember_BooleanCondition",
		venue:"Contember_VenueWhere",
		and:"Contember_DeadlineTemplateWhere",
		or:"Contember_DeadlineTemplateWhere",
		not:"Contember_DeadlineTemplateWhere"
	},
	Contember_DateCondition:{
		and:"Contember_DateCondition",
		or:"Contember_DateCondition",
		not:"Contember_DateCondition",
		eq:"Date",
		notEq:"Date",
		in:"Date",
		notIn:"Date",
		lt:"Date",
		lte:"Date",
		gt:"Date",
		gte:"Date"
	},
	Contember_TimetableDayWhere:{
		id:"Contember_UUIDCondition",
		date:"Contember_DateCondition",
		type:"Contember_TimetableDayTypeCondition",
		shiftGroups:"Contember_TimetableShiftGroupWhere",
		tipsCents:"Contember_IntCondition",
		notes:"Contember_TimetableDayNoteWhere",
		venue:"Contember_VenueWhere",
		meta:"Contember_TimetableDayMetadataWhere",
		and:"Contember_TimetableDayWhere",
		or:"Contember_TimetableDayWhere",
		not:"Contember_TimetableDayWhere"
	},
	Contember_TimetableDayTypeCondition:{
		and:"Contember_TimetableDayTypeCondition",
		or:"Contember_TimetableDayTypeCondition",
		not:"Contember_TimetableDayTypeCondition",
		eq:"Contember_TimetableDayType",
		notEq:"Contember_TimetableDayType",
		in:"Contember_TimetableDayType",
		notIn:"Contember_TimetableDayType",
		lt:"Contember_TimetableDayType",
		lte:"Contember_TimetableDayType",
		gt:"Contember_TimetableDayType",
		gte:"Contember_TimetableDayType"
	},
	Contember_TimetableDayType: true,
	Contember_TimetableShiftGroupWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		shifts:"Contember_TimetableShiftWhere",
		day:"Contember_TimetableDayWhere",
		type:"Contember_TimetableEmployeeRoleWhere",
		position:"Contember_TimetableShiftPositionWhere",
		queue:"Contember_EmployeeQueueWhere",
		generatedByTemplate:"Contember_TimetableTemplateWhere",
		and:"Contember_TimetableShiftGroupWhere",
		or:"Contember_TimetableShiftGroupWhere",
		not:"Contember_TimetableShiftGroupWhere"
	},
	Contember_TimetableShiftWhere:{
		id:"Contember_UUIDCondition",
		locked:"Contember_BooleanCondition",
		order:"Contember_IntCondition",
		assigned:"Contember_DateCondition",
		group:"Contember_TimetableShiftGroupWhere",
		shiftsProfile:"Contember_ShiftsProfileWhere",
		queueItem:"Contember_EmployeeQueueItemWhere",
		swapInfo:"Contember_TimetableShiftSwapInfoWhere",
		isOvertime:"Contember_BooleanCondition",
		and:"Contember_TimetableShiftWhere",
		or:"Contember_TimetableShiftWhere",
		not:"Contember_TimetableShiftWhere"
	},
	Contember_ShiftsProfileWhere:{
		id:"Contember_UUIDCondition",
		queueItems:"Contember_EmployeeQueueItemWhere",
		staff:"Contember_StaffWhere",
		roles:"Contember_TimetableEmployeeRoleWhere",
		shifts:"Contember_TimetableShiftWhere",
		allowSelfUnassigning:"Contember_BooleanCondition",
		and:"Contember_ShiftsProfileWhere",
		or:"Contember_ShiftsProfileWhere",
		not:"Contember_ShiftsProfileWhere"
	},
	Contember_EmployeeQueueItemWhere:{
		id:"Contember_UUIDCondition",
		start:"Contember_StringCondition",
		end:"Contember_StringCondition",
		queue:"Contember_EmployeeQueueWhere",
		shiftsProfile:"Contember_ShiftsProfileWhere",
		shift:"Contember_TimetableShiftWhere",
		unassignInfo:"Contember_UnassignedShiftInfoWhere",
		meta:"Contember_EmployeeQueueItemMetadataWhere",
		and:"Contember_EmployeeQueueItemWhere",
		or:"Contember_EmployeeQueueItemWhere",
		not:"Contember_EmployeeQueueItemWhere"
	},
	Contember_EmployeeQueueWhere:{
		id:"Contember_UUIDCondition",
		shiftGroup:"Contember_TimetableShiftGroupWhere",
		items:"Contember_EmployeeQueueItemWhere",
		contemberBugFix:"Contember_BooleanCondition",
		and:"Contember_EmployeeQueueWhere",
		or:"Contember_EmployeeQueueWhere",
		not:"Contember_EmployeeQueueWhere"
	},
	Contember_UnassignedShiftInfoWhere:{
		id:"Contember_UUIDCondition",
		hoursMissed:"Contember_IntCondition",
		queueItem:"Contember_EmployeeQueueItemWhere",
		legacyReason:"Contember_UnassignedInfoReasonCondition",
		reason:"Contember_UnassignReasonWhere",
		and:"Contember_UnassignedShiftInfoWhere",
		or:"Contember_UnassignedShiftInfoWhere",
		not:"Contember_UnassignedShiftInfoWhere"
	},
	Contember_UnassignedInfoReasonCondition:{
		and:"Contember_UnassignedInfoReasonCondition",
		or:"Contember_UnassignedInfoReasonCondition",
		not:"Contember_UnassignedInfoReasonCondition",
		eq:"Contember_UnassignedInfoReason",
		notEq:"Contember_UnassignedInfoReason",
		in:"Contember_UnassignedInfoReason",
		notIn:"Contember_UnassignedInfoReason",
		lt:"Contember_UnassignedInfoReason",
		lte:"Contember_UnassignedInfoReason",
		gt:"Contember_UnassignedInfoReason",
		gte:"Contember_UnassignedInfoReason"
	},
	Contember_UnassignedInfoReason: true,
	Contember_UnassignReasonWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		name:"Contember_StringCondition",
		venues:"Contember_VenueWhere",
		and:"Contember_UnassignReasonWhere",
		or:"Contember_UnassignReasonWhere",
		not:"Contember_UnassignReasonWhere"
	},
	Contember_EmployeeQueueItemMetadataWhere:{
		id:"Contember_UUIDCondition",
		totalMinutes:"Contember_IntCondition",
		queueItem:"Contember_EmployeeQueueItemWhere",
		includeInTipsCalculation:"Contember_BooleanCondition",
		and:"Contember_EmployeeQueueItemMetadataWhere",
		or:"Contember_EmployeeQueueItemMetadataWhere",
		not:"Contember_EmployeeQueueItemMetadataWhere"
	},
	Contember_TimetableEmployeeRoleWhere:{
		id:"Contember_UUIDCondition",
		shiftGroups:"Contember_TimetableShiftGroupWhere",
		shiftsProfiles:"Contember_ShiftsProfileWhere",
		shiftsManagers:"Contember_ShiftsManagerWhere",
		name:"Contember_StringCondition",
		timetableTemplates:"Contember_TimetableTemplateWhere",
		color:"Contember_StringCondition",
		and:"Contember_TimetableEmployeeRoleWhere",
		or:"Contember_TimetableEmployeeRoleWhere",
		not:"Contember_TimetableEmployeeRoleWhere"
	},
	Contember_ShiftsManagerWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		managedRoles:"Contember_TimetableEmployeeRoleWhere",
		createdAt:"Contember_DateTimeCondition",
		and:"Contember_ShiftsManagerWhere",
		or:"Contember_ShiftsManagerWhere",
		not:"Contember_ShiftsManagerWhere"
	},
	Contember_TimetableTemplateWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		startDate:"Contember_DateCondition",
		period:"Contember_IntCondition",
		slots:"Contember_IntCondition",
		position:"Contember_TimetableShiftPositionWhere",
		staffOptions:"Contember_TimetableTemplateStaffOptionsWhere",
		internalName:"Contember_StringCondition",
		venue:"Contember_VenueWhere",
		employeeRole:"Contember_TimetableEmployeeRoleWhere",
		workdaysOnly:"Contember_BooleanCondition",
		monday:"Contember_BooleanCondition",
		tuesday:"Contember_BooleanCondition",
		wednesday:"Contember_BooleanCondition",
		thursday:"Contember_BooleanCondition",
		friday:"Contember_BooleanCondition",
		saturday:"Contember_BooleanCondition",
		sunday:"Contember_BooleanCondition",
		type:"Contember_TemplateTypeCondition",
		and:"Contember_TimetableTemplateWhere",
		or:"Contember_TimetableTemplateWhere",
		not:"Contember_TimetableTemplateWhere"
	},
	Contember_TimetableShiftPositionWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		start:"Contember_StringCondition",
		end:"Contember_StringCondition",
		shiftGroups:"Contember_TimetableShiftGroupWhere",
		timetableTemplates:"Contember_TimetableTemplateWhere",
		isDynamic:"Contember_BooleanCondition",
		and:"Contember_TimetableShiftPositionWhere",
		or:"Contember_TimetableShiftPositionWhere",
		not:"Contember_TimetableShiftPositionWhere"
	},
	Contember_TimetableTemplateStaffOptionsWhere:{
		id:"Contember_UUIDCondition",
		startTime:"Contember_StringCondition",
		endTime:"Contember_StringCondition",
		timetableTemplate:"Contember_TimetableTemplateWhere",
		autoAssignedEmployee:"Contember_ShiftsProfileWhere",
		and:"Contember_TimetableTemplateStaffOptionsWhere",
		or:"Contember_TimetableTemplateStaffOptionsWhere",
		not:"Contember_TimetableTemplateStaffOptionsWhere"
	},
	Contember_TemplateTypeCondition:{
		and:"Contember_TemplateTypeCondition",
		or:"Contember_TemplateTypeCondition",
		not:"Contember_TemplateTypeCondition",
		eq:"Contember_TemplateType",
		notEq:"Contember_TemplateType",
		in:"Contember_TemplateType",
		notIn:"Contember_TemplateType",
		lt:"Contember_TemplateType",
		lte:"Contember_TemplateType",
		gt:"Contember_TemplateType",
		gte:"Contember_TemplateType"
	},
	Contember_TemplateType: true,
	Contember_TimetableShiftSwapInfoWhere:{
		id:"Contember_UUIDCondition",
		assigned:"Contember_DateCondition",
		requested:"Contember_BooleanCondition",
		confirmed:"Contember_BooleanCondition",
		swappedShiftsProfile:"Contember_ShiftsProfileWhere",
		shift:"Contember_TimetableShiftWhere",
		and:"Contember_TimetableShiftSwapInfoWhere",
		or:"Contember_TimetableShiftSwapInfoWhere",
		not:"Contember_TimetableShiftSwapInfoWhere"
	},
	Contember_TimetableDayNoteWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		note:"Contember_StringCondition",
		day:"Contember_TimetableDayWhere",
		role:"Contember_TimetableEmployeeRoleWhere",
		and:"Contember_TimetableDayNoteWhere",
		or:"Contember_TimetableDayNoteWhere",
		not:"Contember_TimetableDayNoteWhere"
	},
	Contember_TimetableDayMetadataWhere:{
		id:"Contember_UUIDCondition",
		numberOfWorkers:"Contember_IntCondition",
		tipsTotalCents:"Contember_IntCondition",
		totalMinutes:"Contember_IntCondition",
		tipsCentsPerMinute:"Contember_FloatCondition",
		day:"Contember_TimetableDayWhere",
		and:"Contember_TimetableDayMetadataWhere",
		or:"Contember_TimetableDayMetadataWhere",
		not:"Contember_TimetableDayMetadataWhere"
	},
	Contember_FloatCondition:{
		and:"Contember_FloatCondition",
		or:"Contember_FloatCondition",
		not:"Contember_FloatCondition"
	},
	Contember_VenueEmployeeRoleWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		employeePrivileges:"Contember_TimetablesEmployeePrivilegesWhere",
		venue:"Contember_VenueWhere",
		role:"Contember_TimetableEmployeeRoleWhere",
		getsTipsShare:"Contember_BooleanCondition",
		notesEnabled:"Contember_BooleanCondition",
		and:"Contember_VenueEmployeeRoleWhere",
		or:"Contember_VenueEmployeeRoleWhere",
		not:"Contember_VenueEmployeeRoleWhere"
	},
	Contember_TimetablesEmployeePrivilegesWhere:{
		id:"Contember_UUIDCondition",
		venueEmployeeRole:"Contember_VenueEmployeeRoleWhere",
		read:"Contember_BooleanCondition",
		unassign:"Contember_BooleanCondition",
		assign:"Contember_BooleanCondition",
		and:"Contember_TimetablesEmployeePrivilegesWhere",
		or:"Contember_TimetablesEmployeePrivilegesWhere",
		not:"Contember_TimetablesEmployeePrivilegesWhere"
	},
	Contember_PointOfSaleApplicationDeviceWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		note:"Contember_StringCondition",
		venue:"Contember_VenueWhere",
		identificationCode:"Contember_IdentificationCodeWhere",
		identifier:"Contember_DeviceIdentifierWhere",
		terminal:"Contember_PointOfSaleTerminalWhere",
		receiptPrinter:"Contember_PointOfSalePrinterWhere",
		ticketPrinters:"Contember_PointOfSalePrinterWhere",
		and:"Contember_PointOfSaleApplicationDeviceWhere",
		or:"Contember_PointOfSaleApplicationDeviceWhere",
		not:"Contember_PointOfSaleApplicationDeviceWhere"
	},
	Contember_IdentificationCodeWhere:{
		id:"Contember_UUIDCondition",
		code:"Contember_StringCondition",
		createdAt:"Contember_DateTimeCondition",
		attempts:"Contember_IdentificationAttemptWhere",
		locales:"Contember_IdentificationCodeLocaleWhere",
		internalName:"Contember_StringCondition",
		applicationDevices:"Contember_PointOfSaleApplicationDeviceWhere",
		and:"Contember_IdentificationCodeWhere",
		or:"Contember_IdentificationCodeWhere",
		not:"Contember_IdentificationCodeWhere"
	},
	Contember_IdentificationAttemptWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		code:"Contember_IdentificationCodeWhere",
		user:"Contember_UserWhere",
		and:"Contember_IdentificationAttemptWhere",
		or:"Contember_IdentificationAttemptWhere",
		not:"Contember_IdentificationAttemptWhere"
	},
	Contember_UserWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		staff:"Contember_StaffWhere",
		customer:"Contember_CustomerWhere",
		tenantPersonId:"Contember_UUIDCondition",
		disabledAt:"Contember_DateTimeCondition",
		devices:"Contember_UserDeviceWhere",
		verifiedAt:"Contember_DateTimeCondition",
		email:"Contember_StringCondition",
		createdAt:"Contember_DateTimeCondition",
		deletionRequestedAt:"Contember_DateTimeCondition",
		deletionExpectedAt:"Contember_DateTimeCondition",
		deletedAt:"Contember_DateTimeCondition",
		meta:"Contember_UserMetadataWhere",
		identificationAttempts:"Contember_IdentificationAttemptWhere",
		roles:"Contember_UserRolesWhere",
		and:"Contember_UserWhere",
		or:"Contember_UserWhere",
		not:"Contember_UserWhere"
	},
	Contember_UserDeviceWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		lastActiveAt:"Contember_DateTimeCondition",
		platform:"Contember_StringCondition",
		deviceId:"Contember_StringCondition",
		notificationsEnabled:"Contember_BooleanCondition",
		user:"Contember_UserWhere",
		appVersion:"Contember_StringCondition",
		osVersion:"Contember_StringCondition",
		apiVersion:"Contember_StringCondition",
		anonymousSession:"Contember_AnonymousSessionWhere",
		and:"Contember_UserDeviceWhere",
		or:"Contember_UserDeviceWhere",
		not:"Contember_UserDeviceWhere"
	},
	Contember_AnonymousSessionWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		lastActiveAt:"Contember_DateTimeCondition",
		anonymousSessionKey:"Contember_StringCondition",
		email:"Contember_StringCondition",
		name:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		devices:"Contember_UserDeviceWhere",
		and:"Contember_AnonymousSessionWhere",
		or:"Contember_AnonymousSessionWhere",
		not:"Contember_AnonymousSessionWhere"
	},
	Contember_UserMetadataWhere:{
		id:"Contember_UUIDCondition",
		userProfileUrl:"Contember_StringCondition",
		tenantEmail:"Contember_StringCondition",
		tenantPersonId:"Contember_UUIDCondition",
		tenantIdentityId:"Contember_UUIDCondition",
		globalRoles:"Contember_JsonCondition",
		projectRoles:"Contember_JsonCondition",
		projectRolesString:"Contember_StringCondition",
		externalIdentifiers:"Contember_JsonCondition",
		externalIdentifiersString:"Contember_StringCondition",
		idpSlugs:"Contember_JsonCondition",
		idpSlugsString:"Contember_StringCondition",
		user:"Contember_UserWhere",
		and:"Contember_UserMetadataWhere",
		or:"Contember_UserMetadataWhere",
		not:"Contember_UserMetadataWhere"
	},
	Contember_UserRolesWhere:{
		id:"Contember_UUIDCondition",
		isCustomer:"Contember_BooleanCondition",
		isStaff:"Contember_BooleanCondition",
		isBartender:"Contember_BooleanCondition",
		isCashier:"Contember_BooleanCondition",
		isCourier:"Contember_BooleanCondition",
		isCleaner:"Contember_BooleanCondition",
		isShiftsManager:"Contember_BooleanCondition",
		isInternalBuyer:"Contember_BooleanCondition",
		isStocksManager:"Contember_BooleanCondition",
		isCourierManager:"Contember_BooleanCondition",
		isCashierManager:"Contember_BooleanCondition",
		isBusinessToBusinessManager:"Contember_BooleanCondition",
		isPicker:"Contember_BooleanCondition",
		user:"Contember_UserWhere",
		and:"Contember_UserRolesWhere",
		or:"Contember_UserRolesWhere",
		not:"Contember_UserRolesWhere"
	},
	Contember_IdentificationCodeLocaleWhere:{
		id:"Contember_UUIDCondition",
		successMessage:"Contember_StringCondition",
		root:"Contember_IdentificationCodeWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_IdentificationCodeLocaleWhere",
		or:"Contember_IdentificationCodeLocaleWhere",
		not:"Contember_IdentificationCodeLocaleWhere"
	},
	Contember_DeviceIdentifierWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		device:"Contember_PointOfSaleApplicationDeviceWhere",
		updatedAt:"Contember_DateTimeCondition",
		platform:"Contember_StringCondition",
		resolution:"Contember_StringCondition",
		devicePixelRatio:"Contember_FloatCondition",
		updatedByUser:"Contember_UserWhere",
		identifier:"Contember_StringCondition",
		meta:"Contember_DeviceIdentifierMetadataWhere",
		and:"Contember_DeviceIdentifierWhere",
		or:"Contember_DeviceIdentifierWhere",
		not:"Contember_DeviceIdentifierWhere"
	},
	Contember_DeviceIdentifierMetadataWhere:{
		id:"Contember_UUIDCondition",
		prettyIdentifier:"Contember_StringCondition",
		deviceIdentifier:"Contember_DeviceIdentifierWhere",
		and:"Contember_DeviceIdentifierMetadataWhere",
		or:"Contember_DeviceIdentifierMetadataWhere",
		not:"Contember_DeviceIdentifierMetadataWhere"
	},
	Contember_PointOfSaleTerminalWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		note:"Contember_StringCondition",
		venue:"Contember_VenueWhere",
		applicationDevices:"Contember_PointOfSaleApplicationDeviceWhere",
		ipAddress:"Contember_StringCondition",
		port:"Contember_IntCondition",
		type:"Contember_PointOfSaleTerminalTypeCondition",
		and:"Contember_PointOfSaleTerminalWhere",
		or:"Contember_PointOfSaleTerminalWhere",
		not:"Contember_PointOfSaleTerminalWhere"
	},
	Contember_PointOfSaleTerminalTypeCondition:{
		and:"Contember_PointOfSaleTerminalTypeCondition",
		or:"Contember_PointOfSaleTerminalTypeCondition",
		not:"Contember_PointOfSaleTerminalTypeCondition",
		eq:"Contember_PointOfSaleTerminalType",
		notEq:"Contember_PointOfSaleTerminalType",
		in:"Contember_PointOfSaleTerminalType",
		notIn:"Contember_PointOfSaleTerminalType",
		lt:"Contember_PointOfSaleTerminalType",
		lte:"Contember_PointOfSaleTerminalType",
		gt:"Contember_PointOfSaleTerminalType",
		gte:"Contember_PointOfSaleTerminalType"
	},
	Contember_PointOfSaleTerminalType: true,
	Contember_PointOfSalePrinterWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		note:"Contember_StringCondition",
		ipAddress:"Contember_StringCondition",
		applicationDevicesReceipt:"Contember_PointOfSaleApplicationDeviceWhere",
		applicationDevicesTicket:"Contember_PointOfSaleApplicationDeviceWhere",
		venue:"Contember_VenueWhere",
		ticketCategories:"Contember_PointOfSaleCategoryWhere",
		and:"Contember_PointOfSalePrinterWhere",
		or:"Contember_PointOfSalePrinterWhere",
		not:"Contember_PointOfSalePrinterWhere"
	},
	Contember_PointOfSaleCategoryWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		slug:"Contember_StringCondition",
		locales:"Contember_PointOfSaleCategoryLocaleWhere",
		products:"Contember_PointOfSaleCategoryProductWhere",
		category:"Contember_ProductCategoryWhere",
		venue:"Contember_VenueWhere",
		ticketPrinters:"Contember_PointOfSalePrinterWhere",
		and:"Contember_PointOfSaleCategoryWhere",
		or:"Contember_PointOfSaleCategoryWhere",
		not:"Contember_PointOfSaleCategoryWhere"
	},
	Contember_PointOfSaleCategoryLocaleWhere:{
		id:"Contember_UUIDCondition",
		title:"Contember_StringCondition",
		root:"Contember_PointOfSaleCategoryWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_PointOfSaleCategoryLocaleWhere",
		or:"Contember_PointOfSaleCategoryLocaleWhere",
		not:"Contember_PointOfSaleCategoryLocaleWhere"
	},
	Contember_PointOfSaleCategoryProductWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		category:"Contember_PointOfSaleCategoryWhere",
		product:"Contember_ProductWhere",
		locales:"Contember_PointOfSaleCategoryProductLocaleWhere",
		and:"Contember_PointOfSaleCategoryProductWhere",
		or:"Contember_PointOfSaleCategoryProductWhere",
		not:"Contember_PointOfSaleCategoryProductWhere"
	},
	Contember_PointOfSaleCategoryProductLocaleWhere:{
		id:"Contember_UUIDCondition",
		title:"Contember_StringCondition",
		root:"Contember_PointOfSaleCategoryProductWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_PointOfSaleCategoryProductLocaleWhere",
		or:"Contember_PointOfSaleCategoryProductLocaleWhere",
		not:"Contember_PointOfSaleCategoryProductLocaleWhere"
	},
	Contember_ProductCategoryWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		products:"Contember_ProductWhere",
		tags:"Contember_TagWhere",
		parent:"Contember_ProductCategoryWhere",
		children:"Contember_ProductCategoryWhere",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		color:"Contember_StringCondition",
		image:"Contember_ImageWhere",
		order:"Contember_FloatCondition",
		descendants:"Contember_ProductCategoryTreeWhere",
		ancestors:"Contember_ProductCategoryTreeWhere",
		inheritedTags:"Contember_InheritedProductCategoryTagWhere",
		nestedProducts:"Contember_NestedProductCategoryProductWhere",
		and:"Contember_ProductCategoryWhere",
		or:"Contember_ProductCategoryWhere",
		not:"Contember_ProductCategoryWhere"
	},
	Contember_ProductCategoryTreeWhere:{
		id:"Contember_UUIDCondition",
		depth:"Contember_IntCondition",
		ancestor:"Contember_ProductCategoryWhere",
		descendant:"Contember_ProductCategoryWhere",
		and:"Contember_ProductCategoryTreeWhere",
		or:"Contember_ProductCategoryTreeWhere",
		not:"Contember_ProductCategoryTreeWhere"
	},
	Contember_InheritedProductCategoryTagWhere:{
		id:"Contember_UUIDCondition",
		depth:"Contember_IntCondition",
		productCategory:"Contember_ProductCategoryWhere",
		tag:"Contember_TagWhere",
		and:"Contember_InheritedProductCategoryTagWhere",
		or:"Contember_InheritedProductCategoryTagWhere",
		not:"Contember_InheritedProductCategoryTagWhere"
	},
	Contember_NestedProductCategoryProductWhere:{
		id:"Contember_UUIDCondition",
		depth:"Contember_IntCondition",
		product:"Contember_ProductWhere",
		productCategory:"Contember_ProductCategoryWhere",
		and:"Contember_NestedProductCategoryProductWhere",
		or:"Contember_NestedProductCategoryProductWhere",
		not:"Contember_NestedProductCategoryProductWhere"
	},
	Contember_OrderWhere:{
		id:"Contember_UUIDCondition",
		state:"Contember_OrderStateCondition",
		fulfilledAt:"Contember_DateTimeCondition",
		canceledAt:"Contember_DateTimeCondition",
		note:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		channel:"Contember_ChannelWhere",
		discounts:"Contember_OrderDiscountWhere",
		delivery:"Contember_OrderDeliveryWhere",
		items:"Contember_OrderItemWhere",
		internalNote:"Contember_StringCondition",
		cart:"Contember_CartWhere",
		events:"Contember_OrderEventWhere",
		createdAt:"Contember_DateTimeCondition",
		billingAddress:"Contember_AddressWhere",
		priceLines:"Contember_OrderPriceLineWhere",
		payments:"Contember_OrderPaymentWhere",
		seq:"Contember_IntCondition",
		billingSubject:"Contember_BillingSubjectWhere",
		summary:"Contember_OrderSummaryWhere",
		doubleshotLegacyId:"Contember_StringCondition",
		gratuity:"Contember_GratuityWhere",
		venue:"Contember_VenueWhere",
		fulfillmentNote:"Contember_StringCondition",
		fulfillmentNotes:"Contember_FulfillmentNoteWhere",
		doubleshotTotalWeightGrams:"Contember_IntCondition",
		doubleShotLegacyNumber:"Contember_StringCondition",
		doubleshotLastUpdatedAt:"Contember_DateTimeCondition",
		doubleshotAdminUrl:"Contember_StringCondition",
		and:"Contember_OrderWhere",
		or:"Contember_OrderWhere",
		not:"Contember_OrderWhere"
	},
	Contember_OrderStateCondition:{
		and:"Contember_OrderStateCondition",
		or:"Contember_OrderStateCondition",
		not:"Contember_OrderStateCondition",
		eq:"Contember_OrderState",
		notEq:"Contember_OrderState",
		in:"Contember_OrderState",
		notIn:"Contember_OrderState",
		lt:"Contember_OrderState",
		lte:"Contember_OrderState",
		gt:"Contember_OrderState",
		gte:"Contember_OrderState"
	},
	Contember_OrderState: true,
	Contember_OrderDiscountWhere:{
		id:"Contember_UUIDCondition",
		discountCents:"Contember_IntCondition",
		vatRate:"Contember_VatRateWhere",
		order:"Contember_OrderWhere",
		description:"Contember_StringCondition",
		type:"Contember_OrderDiscountTypeCondition",
		customerCreditTransactions:"Contember_CustomerCreditTransactionWhere",
		voucherRedemption:"Contember_VoucherRedemptionWhere",
		and:"Contember_OrderDiscountWhere",
		or:"Contember_OrderDiscountWhere",
		not:"Contember_OrderDiscountWhere"
	},
	Contember_OrderDiscountTypeCondition:{
		and:"Contember_OrderDiscountTypeCondition",
		or:"Contember_OrderDiscountTypeCondition",
		not:"Contember_OrderDiscountTypeCondition",
		eq:"Contember_OrderDiscountType",
		notEq:"Contember_OrderDiscountType",
		in:"Contember_OrderDiscountType",
		notIn:"Contember_OrderDiscountType",
		lt:"Contember_OrderDiscountType",
		lte:"Contember_OrderDiscountType",
		gt:"Contember_OrderDiscountType",
		gte:"Contember_OrderDiscountType"
	},
	Contember_OrderDiscountType: true,
	Contember_CustomerCreditTransactionWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		amountCents:"Contember_IntCondition",
		customerCredit:"Contember_CustomerCreditWhere",
		orderDiscount:"Contember_OrderDiscountWhere",
		virtualProductEffect:"Contember_VirtualProductEffectWhere",
		and:"Contember_CustomerCreditTransactionWhere",
		or:"Contember_CustomerCreditTransactionWhere",
		not:"Contember_CustomerCreditTransactionWhere"
	},
	Contember_CustomerCreditWhere:{
		id:"Contember_UUIDCondition",
		initialCreditCents:"Contember_IntCondition",
		remainingCreditsCents:"Contember_IntCondition",
		expiresAt:"Contember_DateTimeCondition",
		reason:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		vatRate:"Contember_VatRateWhere",
		createdAt:"Contember_DateTimeCondition",
		transactions:"Contember_CustomerCreditTransactionWhere",
		voucherRedemption:"Contember_VoucherRedemptionWhere",
		invoiceUrl:"Contember_StringCondition",
		and:"Contember_CustomerCreditWhere",
		or:"Contember_CustomerCreditWhere",
		not:"Contember_CustomerCreditWhere"
	},
	Contember_VoucherRedemptionWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		note:"Contember_StringCondition",
		manualRedemptionNote:"Contember_StringCondition",
		customerCredit:"Contember_CustomerCreditWhere",
		orderDiscount:"Contember_OrderDiscountWhere",
		voucher:"Contember_VoucherWhere",
		customer:"Contember_CustomerWhere",
		and:"Contember_VoucherRedemptionWhere",
		or:"Contember_VoucherRedemptionWhere",
		not:"Contember_VoucherRedemptionWhere"
	},
	Contember_VoucherWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		activatedAt:"Contember_DateTimeCondition",
		expiresAt:"Contember_DateTimeCondition",
		internalNote:"Contember_StringCondition",
		note:"Contember_StringCondition",
		code:"Contember_StringCondition",
		type:"Contember_VoucherTypeCondition",
		creditCents:"Contember_IntCondition",
		buyer:"Contember_CustomerWhere",
		owner:"Contember_CustomerWhere",
		vatRate:"Contember_VatRateWhere",
		virtualProductEffect:"Contember_VirtualProductEffectWhere",
		redemption:"Contember_VoucherRedemptionWhere",
		and:"Contember_VoucherWhere",
		or:"Contember_VoucherWhere",
		not:"Contember_VoucherWhere"
	},
	Contember_VoucherTypeCondition:{
		and:"Contember_VoucherTypeCondition",
		or:"Contember_VoucherTypeCondition",
		not:"Contember_VoucherTypeCondition",
		eq:"Contember_VoucherType",
		notEq:"Contember_VoucherType",
		in:"Contember_VoucherType",
		notIn:"Contember_VoucherType",
		lt:"Contember_VoucherType",
		lte:"Contember_VoucherType",
		gt:"Contember_VoucherType",
		gte:"Contember_VoucherType"
	},
	Contember_VoucherType: true,
	Contember_VirtualProductEffectWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		succeededAt:"Contember_DateTimeCondition",
		orderItem:"Contember_OrderItemWhere",
		failedAt:"Contember_DateTimeCondition",
		revertedAt:"Contember_DateTimeCondition",
		resultCustomerCreditTransaction:"Contember_CustomerCreditTransactionWhere",
		resultVouchers:"Contember_VoucherWhere",
		and:"Contember_VirtualProductEffectWhere",
		or:"Contember_VirtualProductEffectWhere",
		not:"Contember_VirtualProductEffectWhere"
	},
	Contember_OrderItemWhere:{
		id:"Contember_UUIDCondition",
		unitPriceCents:"Contember_IntCondition",
		quantity:"Contember_IntCondition",
		vatRate:"Contember_VatRateWhere",
		order:"Contember_OrderWhere",
		virtualProductEffects:"Contember_VirtualProductEffectWhere",
		pickedAt:"Contember_DateTimeCondition",
		note:"Contember_StringCondition",
		fulfillmentNotes:"Contember_FulfillmentNoteWhere",
		product:"Contember_ProductWhere",
		and:"Contember_OrderItemWhere",
		or:"Contember_OrderItemWhere",
		not:"Contember_OrderItemWhere"
	},
	Contember_OrderEventWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_StringCondition",
		data:"Contember_StringCondition",
		createdAt:"Contember_DateTimeCondition",
		order:"Contember_OrderWhere",
		payment:"Contember_OrderPaymentWhere",
		delivery:"Contember_OrderDeliveryWhere",
		and:"Contember_OrderEventWhere",
		or:"Contember_OrderEventWhere",
		not:"Contember_OrderEventWhere"
	},
	Contember_OrderPaymentWhere:{
		id:"Contember_UUIDCondition",
		data:"Contember_JsonCondition",
		paymentCents:"Contember_IntCondition",
		method:"Contember_PaymentMethodWhere",
		failedAt:"Contember_DateTimeCondition",
		state:"Contember_OrderPaymentStateCondition",
		approvedAt:"Contember_DateTimeCondition",
		events:"Contember_OrderEventWhere",
		order:"Contember_OrderWhere",
		invoice:"Contember_InvoiceWhere",
		walletType:"Contember_StringCondition",
		createdAt:"Contember_DateTimeCondition",
		publicReceiptUrl:"Contember_StringCondition",
		priceCents:"Contember_IntCondition",
		vatRate:"Contember_VatRateWhere",
		meta:"Contember_OrderPaymentMetadataWhere",
		otherCurrencyPriceCents:"Contember_StringCondition",
		and:"Contember_OrderPaymentWhere",
		or:"Contember_OrderPaymentWhere",
		not:"Contember_OrderPaymentWhere"
	},
	Contember_OrderPaymentStateCondition:{
		and:"Contember_OrderPaymentStateCondition",
		or:"Contember_OrderPaymentStateCondition",
		not:"Contember_OrderPaymentStateCondition",
		eq:"Contember_OrderPaymentState",
		notEq:"Contember_OrderPaymentState",
		in:"Contember_OrderPaymentState",
		notIn:"Contember_OrderPaymentState",
		lt:"Contember_OrderPaymentState",
		lte:"Contember_OrderPaymentState",
		gt:"Contember_OrderPaymentState",
		gte:"Contember_OrderPaymentState"
	},
	Contember_OrderPaymentState: true,
	Contember_InvoiceWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		publicKey:"Contember_StringCondition",
		fakturoidId:"Contember_StringCondition",
		fakturoidData:"Contember_StringCondition",
		legacyUrl:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		orderPayments:"Contember_OrderPaymentWhere",
		address:"Contember_AddressWhere",
		publicUrl:"Contember_StringCondition",
		billingSubject:"Contember_BillingSubjectWhere",
		and:"Contember_InvoiceWhere",
		or:"Contember_InvoiceWhere",
		not:"Contember_InvoiceWhere"
	},
	Contember_BillingSubjectWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		fullName:"Contember_StringCondition",
		addressLine1:"Contember_StringCondition",
		addressLine2:"Contember_StringCondition",
		city:"Contember_StringCondition",
		postalCode:"Contember_StringCondition",
		email:"Contember_StringCondition",
		phone:"Contember_StringCondition",
		companyName:"Contember_StringCondition",
		companyIdentifier:"Contember_StringCondition",
		vatIdentifier:"Contember_StringCondition",
		externalIdentifier:"Contember_StringCondition",
		hidden:"Contember_BooleanCondition",
		createdAt:"Contember_DateTimeCondition",
		deletedAt:"Contember_DateTimeCondition",
		note:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		fakturoidSubjectId:"Contember_StringCondition",
		country:"Contember_CountryWhere",
		customer:"Contember_CustomerWhere",
		invoices:"Contember_InvoiceWhere",
		defaultBillingSubjectOfCustomer:"Contember_CustomerWhere",
		orders:"Contember_OrderWhere",
		and:"Contember_BillingSubjectWhere",
		or:"Contember_BillingSubjectWhere",
		not:"Contember_BillingSubjectWhere"
	},
	Contember_CountryWhere:{
		id:"Contember_UUIDCondition",
		code:"Contember_StringCondition",
		order:"Contember_IntCondition",
		and:"Contember_CountryWhere",
		or:"Contember_CountryWhere",
		not:"Contember_CountryWhere"
	},
	Contember_OrderPaymentMetadataWhere:{
		id:"Contember_UUIDCondition",
		paymentUrl:"Contember_StringCondition",
		orderPayment:"Contember_OrderPaymentWhere",
		and:"Contember_OrderPaymentMetadataWhere",
		or:"Contember_OrderPaymentMetadataWhere",
		not:"Contember_OrderPaymentMetadataWhere"
	},
	Contember_OrderPriceLineWhere:{
		id:"Contember_UUIDCondition",
		unitPriceCents:"Contember_IntCondition",
		quantity:"Contember_IntCondition",
		type:"Contember_OrderPriceLineTypeCondition",
		orderItem:"Contember_OrderItemWhere",
		orderDiscount:"Contember_OrderDiscountWhere",
		orderPayment:"Contember_OrderPaymentWhere",
		orderDelivery:"Contember_OrderDeliveryWhere",
		vatRatePermille:"Contember_IntCondition",
		vatRate:"Contember_VatRateWhere",
		order:"Contember_OrderWhere",
		and:"Contember_OrderPriceLineWhere",
		or:"Contember_OrderPriceLineWhere",
		not:"Contember_OrderPriceLineWhere"
	},
	Contember_OrderPriceLineTypeCondition:{
		and:"Contember_OrderPriceLineTypeCondition",
		or:"Contember_OrderPriceLineTypeCondition",
		not:"Contember_OrderPriceLineTypeCondition",
		eq:"Contember_OrderPriceLineType",
		notEq:"Contember_OrderPriceLineType",
		in:"Contember_OrderPriceLineType",
		notIn:"Contember_OrderPriceLineType",
		lt:"Contember_OrderPriceLineType",
		lte:"Contember_OrderPriceLineType",
		gt:"Contember_OrderPriceLineType",
		gte:"Contember_OrderPriceLineType"
	},
	Contember_OrderPriceLineType: true,
	Contember_OrderSummaryWhere:{
		id:"Contember_UUIDCondition",
		totalPriceCents:"Contember_IntCondition",
		discountPriceCents:"Contember_IntCondition",
		undiscountedPriceCents:"Contember_IntCondition",
		itemsQuantity:"Contember_IntCondition",
		orderUrl:"Contember_StringCondition",
		orderNumber:"Contember_StringCondition",
		order:"Contember_OrderWhere",
		and:"Contember_OrderSummaryWhere",
		or:"Contember_OrderSummaryWhere",
		not:"Contember_OrderSummaryWhere"
	},
	Contember_GratuityWhere:{
		id:"Contember_UUIDCondition",
		amountCents:"Contember_IntCondition",
		order:"Contember_OrderWhere",
		and:"Contember_GratuityWhere",
		or:"Contember_GratuityWhere",
		not:"Contember_GratuityWhere"
	},
	Contember_AreaWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		name:"Contember_StringCondition",
		venue:"Contember_VenueWhere",
		tables:"Contember_TableWhere",
		lines:"Contember_LineWhere",
		and:"Contember_AreaWhere",
		or:"Contember_AreaWhere",
		not:"Contember_AreaWhere"
	},
	Contember_TableWhere:{
		id:"Contember_UUIDCondition",
		shape:"Contember_TableShapeCondition",
		width:"Contember_IntCondition",
		height:"Contember_IntCondition",
		positionX:"Contember_IntCondition",
		positionY:"Contember_IntCondition",
		name:"Contember_StringCondition",
		color:"Contember_StringCondition",
		customerTabs:"Contember_CustomerTabWhere",
		area:"Contember_AreaWhere",
		seats:"Contember_SeatWhere",
		and:"Contember_TableWhere",
		or:"Contember_TableWhere",
		not:"Contember_TableWhere"
	},
	Contember_TableShapeCondition:{
		and:"Contember_TableShapeCondition",
		or:"Contember_TableShapeCondition",
		not:"Contember_TableShapeCondition",
		eq:"Contember_TableShape",
		notEq:"Contember_TableShape",
		in:"Contember_TableShape",
		notIn:"Contember_TableShape",
		lt:"Contember_TableShape",
		lte:"Contember_TableShape",
		gt:"Contember_TableShape",
		gte:"Contember_TableShape"
	},
	Contember_TableShape: true,
	Contember_CustomerTabWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		color:"Contember_StringCondition",
		items:"Contember_CustomerTabItemWhere",
		name:"Contember_StringCondition",
		meta:"Contember_CustomerTabMetadataWhere",
		customer:"Contember_CustomerWhere",
		table:"Contember_TableWhere",
		seat:"Contember_SeatWhere",
		and:"Contember_CustomerTabWhere",
		or:"Contember_CustomerTabWhere",
		not:"Contember_CustomerTabWhere"
	},
	Contember_CustomerTabItemWhere:{
		id:"Contember_UUIDCondition",
		addedAt:"Contember_DateTimeCondition",
		tab:"Contember_CustomerTabWhere",
		product:"Contember_ProductWhere",
		paidAt:"Contember_DateTimeCondition",
		and:"Contember_CustomerTabItemWhere",
		or:"Contember_CustomerTabItemWhere",
		not:"Contember_CustomerTabItemWhere"
	},
	Contember_CustomerTabMetadataWhere:{
		id:"Contember_UUIDCondition",
		totalPriceCents:"Contember_IntCondition",
		totalSpentCents:"Contember_IntCondition",
		unpaidCents:"Contember_IntCondition",
		customerTab:"Contember_CustomerTabWhere",
		and:"Contember_CustomerTabMetadataWhere",
		or:"Contember_CustomerTabMetadataWhere",
		not:"Contember_CustomerTabMetadataWhere"
	},
	Contember_SeatWhere:{
		id:"Contember_UUIDCondition",
		positionX:"Contember_IntCondition",
		positionY:"Contember_IntCondition",
		name:"Contember_StringCondition",
		color:"Contember_StringCondition",
		customerTabs:"Contember_CustomerTabWhere",
		table:"Contember_TableWhere",
		and:"Contember_SeatWhere",
		or:"Contember_SeatWhere",
		not:"Contember_SeatWhere"
	},
	Contember_LineWhere:{
		id:"Contember_UUIDCondition",
		positionStartX:"Contember_IntCondition",
		positionStartY:"Contember_IntCondition",
		positionEndX:"Contember_IntCondition",
		positionEndY:"Contember_IntCondition",
		area:"Contember_AreaWhere",
		and:"Contember_LineWhere",
		or:"Contember_LineWhere",
		not:"Contember_LineWhere"
	},
	Contember_PointOfSaleInitialViewCondition:{
		and:"Contember_PointOfSaleInitialViewCondition",
		or:"Contember_PointOfSaleInitialViewCondition",
		not:"Contember_PointOfSaleInitialViewCondition",
		eq:"Contember_PointOfSaleInitialView",
		notEq:"Contember_PointOfSaleInitialView",
		in:"Contember_PointOfSaleInitialView",
		notIn:"Contember_PointOfSaleInitialView",
		lt:"Contember_PointOfSaleInitialView",
		lte:"Contember_PointOfSaleInitialView",
		gt:"Contember_PointOfSaleInitialView",
		gte:"Contember_PointOfSaleInitialView"
	},
	Contember_PointOfSaleInitialView: true,
	Contember_CartStateCondition:{
		and:"Contember_CartStateCondition",
		or:"Contember_CartStateCondition",
		not:"Contember_CartStateCondition",
		eq:"Contember_CartState",
		notEq:"Contember_CartState",
		in:"Contember_CartState",
		notIn:"Contember_CartState",
		lt:"Contember_CartState",
		lte:"Contember_CartState",
		gt:"Contember_CartState",
		gte:"Contember_CartState"
	},
	Contember_CartState: true,
	Contember_CartPriceLineWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_CartPriceLineTypeCondition",
		item:"Contember_CartItemWhere",
		product:"Contember_ProductWhere",
		unitPriceCents:"Contember_IntCondition",
		priceCents:"Contember_IntCondition",
		quantity:"Contember_IntCondition",
		vatRatePermille:"Contember_IntCondition",
		vatRate:"Contember_VatRateWhere",
		cart:"Contember_CartWhere",
		and:"Contember_CartPriceLineWhere",
		or:"Contember_CartPriceLineWhere",
		not:"Contember_CartPriceLineWhere"
	},
	Contember_CartPriceLineTypeCondition:{
		and:"Contember_CartPriceLineTypeCondition",
		or:"Contember_CartPriceLineTypeCondition",
		not:"Contember_CartPriceLineTypeCondition",
		eq:"Contember_CartPriceLineType",
		notEq:"Contember_CartPriceLineType",
		in:"Contember_CartPriceLineType",
		notIn:"Contember_CartPriceLineType",
		lt:"Contember_CartPriceLineType",
		lte:"Contember_CartPriceLineType",
		gt:"Contember_CartPriceLineType",
		gte:"Contember_CartPriceLineType"
	},
	Contember_CartPriceLineType: true,
	Contember_CartSummaryWhere:{
		id:"Contember_UUIDCondition",
		itemsPriceCents:"Contember_IntCondition",
		deliveryPriceCents:"Contember_IntCondition",
		totalPriceCents:"Contember_IntCondition",
		itemsCount:"Contember_IntCondition",
		itemsQuantity:"Contember_IntCondition",
		checkoutUrl:"Contember_StringCondition",
		cart:"Contember_CartWhere",
		and:"Contember_CartSummaryWhere",
		or:"Contember_CartSummaryWhere",
		not:"Contember_CartSummaryWhere"
	},
	Contember_ChannelCodeCondition:{
		and:"Contember_ChannelCodeCondition",
		or:"Contember_ChannelCodeCondition",
		not:"Contember_ChannelCodeCondition",
		eq:"Contember_ChannelCode",
		notEq:"Contember_ChannelCode",
		in:"Contember_ChannelCode",
		notIn:"Contember_ChannelCode",
		lt:"Contember_ChannelCode",
		lte:"Contember_ChannelCode",
		gt:"Contember_ChannelCode",
		gte:"Contember_ChannelCode"
	},
	Contember_ChannelCode: true,
	Contember_VatRatePresetCondition:{
		and:"Contember_VatRatePresetCondition",
		or:"Contember_VatRatePresetCondition",
		not:"Contember_VatRatePresetCondition",
		eq:"Contember_VatRatePreset",
		notEq:"Contember_VatRatePreset",
		in:"Contember_VatRatePreset",
		notIn:"Contember_VatRatePreset",
		lt:"Contember_VatRatePreset",
		lte:"Contember_VatRatePreset",
		gt:"Contember_VatRatePreset",
		gte:"Contember_VatRatePreset"
	},
	Contember_VatRatePreset: true,
	Contember_OrderDeliveryStateCondition:{
		and:"Contember_OrderDeliveryStateCondition",
		or:"Contember_OrderDeliveryStateCondition",
		not:"Contember_OrderDeliveryStateCondition",
		eq:"Contember_OrderDeliveryState",
		notEq:"Contember_OrderDeliveryState",
		in:"Contember_OrderDeliveryState",
		notIn:"Contember_OrderDeliveryState",
		lt:"Contember_OrderDeliveryState",
		lte:"Contember_OrderDeliveryState",
		gt:"Contember_OrderDeliveryState",
		gte:"Contember_OrderDeliveryState"
	},
	Contember_OrderDeliveryState: true,
	Contember_LiftagoRideWhere:{
		id:"Contember_UUIDCondition",
		liftagoId:"Contember_StringCondition",
		data:"Contember_StringCondition",
		updatedAt:"Contember_DateTimeCondition",
		delivery:"Contember_OrderDeliveryWhere",
		and:"Contember_LiftagoRideWhere",
		or:"Contember_LiftagoRideWhere",
		not:"Contember_LiftagoRideWhere"
	},
	Contember_CleanerWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		canReachTopShelf:"Contember_BooleanCondition",
		createdAt:"Contember_DateTimeCondition",
		and:"Contember_CleanerWhere",
		or:"Contember_CleanerWhere",
		not:"Contember_CleanerWhere"
	},
	Contember_StocksManagerWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		managedProducts:"Contember_ProductWhere",
		createdAt:"Contember_DateTimeCondition",
		and:"Contember_StocksManagerWhere",
		or:"Contember_StocksManagerWhere",
		not:"Contember_StocksManagerWhere"
	},
	Contember_CourierManagerWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		createdAt:"Contember_DateTimeCondition",
		and:"Contember_CourierManagerWhere",
		or:"Contember_CourierManagerWhere",
		not:"Contember_CourierManagerWhere"
	},
	Contember_PickerWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		createdAt:"Contember_DateTimeCondition",
		and:"Contember_PickerWhere",
		or:"Contember_PickerWhere",
		not:"Contember_PickerWhere"
	},
	Contember_RemunerationProfileWhere:{
		id:"Contember_UUIDCondition",
		employmentInformation:"Contember_EmploymentInformationWhere",
		workMonths:"Contember_WorkMonthWhere",
		staff:"Contember_StaffWhere",
		and:"Contember_RemunerationProfileWhere",
		or:"Contember_RemunerationProfileWhere",
		not:"Contember_RemunerationProfileWhere"
	},
	Contember_EmploymentInformationWhere:{
		id:"Contember_UUIDCondition",
		hourlyRate:"Contember_IntCondition",
		remunerationProfiles:"Contember_RemunerationProfileWhere",
		monthlySalary:"Contember_IntCondition",
		type:"Contember_EmploymentTypeCondition",
		name:"Contember_StringCondition",
		and:"Contember_EmploymentInformationWhere",
		or:"Contember_EmploymentInformationWhere",
		not:"Contember_EmploymentInformationWhere"
	},
	Contember_EmploymentTypeCondition:{
		and:"Contember_EmploymentTypeCondition",
		or:"Contember_EmploymentTypeCondition",
		not:"Contember_EmploymentTypeCondition",
		eq:"Contember_EmploymentType",
		notEq:"Contember_EmploymentType",
		in:"Contember_EmploymentType",
		notIn:"Contember_EmploymentType",
		lt:"Contember_EmploymentType",
		lte:"Contember_EmploymentType",
		gt:"Contember_EmploymentType",
		gte:"Contember_EmploymentType"
	},
	Contember_EmploymentType: true,
	Contember_WorkMonthWhere:{
		id:"Contember_UUIDCondition",
		year:"Contember_IntCondition",
		month:"Contember_IntCondition",
		hoursWorked:"Contember_FloatCondition",
		billableHours:"Contember_FloatCondition",
		remunerationProfile:"Contember_RemunerationProfileWhere",
		and:"Contember_WorkMonthWhere",
		or:"Contember_WorkMonthWhere",
		not:"Contember_WorkMonthWhere"
	},
	Contember_CashierWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		staff:"Contember_StaffWhere",
		and:"Contember_CashierWhere",
		or:"Contember_CashierWhere",
		not:"Contember_CashierWhere"
	},
	Contember_InternalBuyerWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		staff:"Contember_StaffWhere",
		and:"Contember_InternalBuyerWhere",
		or:"Contember_InternalBuyerWhere",
		not:"Contember_InternalBuyerWhere"
	},
	Contember_SalesManagerWhere:{
		id:"Contember_UUIDCondition",
		staff:"Contember_StaffWhere",
		createdAt:"Contember_DateTimeCondition",
		countries:"Contember_CountryWhere",
		and:"Contember_SalesManagerWhere",
		or:"Contember_SalesManagerWhere",
		not:"Contember_SalesManagerWhere"
	},
	Contember_CashierManagerWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		staff:"Contember_StaffWhere",
		and:"Contember_CashierManagerWhere",
		or:"Contember_CashierManagerWhere",
		not:"Contember_CashierManagerWhere"
	},
	Contember_BusinessServiceEventWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		fulfilledAt:"Contember_DateTimeCondition",
		businessCustomer:"Contember_BusinessCustomerWhere",
		businessService:"Contember_BusinessServiceWhere",
		filledNumberOfPeople:"Contember_IntCondition",
		filledInvoiced:"Contember_BooleanCondition",
		filledEquipment:"Contember_StringCondition",
		filledMillTamDem:"Contember_BooleanCondition",
		filledSOEspresso:"Contember_BooleanCondition",
		filledFilterCoffeeInMenu:"Contember_BooleanCondition",
		filledWaterMeterStatus:"Contember_StringCondition",
		filledServiceAction:"Contember_StringCondition",
		filledInvoiceIdentifier:"Contember_StringCondition",
		filledNote:"Contember_StringCondition",
		filledSupervisor:"Contember_StaffWhere",
		filledContact:"Contember_StringCondition",
		filledPhone:"Contember_BooleanCondition",
		filledEmail:"Contember_BooleanCondition",
		filledGrindingStonesCondition:"Contember_StringCondition",
		createdBy:"Contember_StaffWhere",
		and:"Contember_BusinessServiceEventWhere",
		or:"Contember_BusinessServiceEventWhere",
		not:"Contember_BusinessServiceEventWhere"
	},
	Contember_BusinessServiceWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		requireNote:"Contember_IntCondition",
		locales:"Contember_BusinessServiceLocaleWhere",
		requireNumberOfPeople:"Contember_IntCondition",
		requireInvoiced:"Contember_IntCondition",
		requireEquipment:"Contember_IntCondition",
		requireMillTamDem:"Contember_IntCondition",
		requireSOEspresso:"Contember_IntCondition",
		requireFilterCoffeeInMenu:"Contember_IntCondition",
		requireWaterMeterStatus:"Contember_IntCondition",
		requireServiceAction:"Contember_IntCondition",
		optionsEquipment:"Contember_StringCondition",
		optionsServiceAction:"Contember_StringCondition",
		requireInvoiceIdentifier:"Contember_IntCondition",
		requireSupervisor:"Contember_IntCondition",
		requireContact:"Contember_IntCondition",
		requirePhone:"Contember_IntCondition",
		requireEmail:"Contember_IntCondition",
		requireGrindingStonesCondition:"Contember_IntCondition",
		and:"Contember_BusinessServiceWhere",
		or:"Contember_BusinessServiceWhere",
		not:"Contember_BusinessServiceWhere"
	},
	Contember_BusinessServiceLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		labelNote:"Contember_StringCondition",
		root:"Contember_BusinessServiceWhere",
		locale:"Contember_LocaleWhere",
		labelNumberOfPeople:"Contember_StringCondition",
		labelInvoiced:"Contember_StringCondition",
		labelEquipment:"Contember_StringCondition",
		labelMillTamDem:"Contember_StringCondition",
		labelSOEspresso:"Contember_StringCondition",
		labelFilterCoffeeInMenu:"Contember_StringCondition",
		labelWaterMeterStatus:"Contember_StringCondition",
		labelServiceAction:"Contember_StringCondition",
		labelInvoiceIdentifier:"Contember_StringCondition",
		labelSupervisor:"Contember_StringCondition",
		labelContact:"Contember_StringCondition",
		labelPhone:"Contember_StringCondition",
		labelEmail:"Contember_StringCondition",
		labelGrindingStonesCondition:"Contember_StringCondition",
		and:"Contember_BusinessServiceLocaleWhere",
		or:"Contember_BusinessServiceLocaleWhere",
		not:"Contember_BusinessServiceLocaleWhere"
	},
	Contember_DoubleshotCoffeeEquipmentWhere:{
		id:"Contember_UUIDCondition",
		espressoMachine:"Contember_StringCondition",
		espressoGrinder:"Contember_StringCondition",
		batchBrew:"Contember_StringCondition",
		batchGrinder:"Contember_StringCondition",
		waterFilter:"Contember_StringCondition",
		mechanicalFilter:"Contember_StringCondition",
		and:"Contember_DoubleshotCoffeeEquipmentWhere",
		or:"Contember_DoubleshotCoffeeEquipmentWhere",
		not:"Contember_DoubleshotCoffeeEquipmentWhere"
	},
	Contember_OrderRecurrenceWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		locales:"Contember_OrderRecurrenceLocaleWhere",
		order:"Contember_IntCondition",
		businessCategory:"Contember_BusinessCategoryWhere",
		and:"Contember_OrderRecurrenceWhere",
		or:"Contember_OrderRecurrenceWhere",
		not:"Contember_OrderRecurrenceWhere"
	},
	Contember_OrderRecurrenceLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_OrderRecurrenceWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_OrderRecurrenceLocaleWhere",
		or:"Contember_OrderRecurrenceLocaleWhere",
		not:"Contember_OrderRecurrenceLocaleWhere"
	},
	Contember_ProductListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_ProductListItemWhere",
		businessCategory:"Contember_BusinessCategoryWhere",
		and:"Contember_ProductListWhere",
		or:"Contember_ProductListWhere",
		not:"Contember_ProductListWhere"
	},
	Contember_ProductListItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		list:"Contember_ProductListWhere",
		product:"Contember_ProductWhere",
		defaultQuantityForOrder:"Contember_IntCondition",
		and:"Contember_ProductListItemWhere",
		or:"Contember_ProductListItemWhere",
		not:"Contember_ProductListItemWhere"
	},
	Contember_ProductCategoryListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_ProductCategoryListItemWhere",
		locales:"Contember_ProductCategoryListLocaleWhere",
		seasonalProductCategoriesOfBusinessCategory:"Contember_BusinessCategoryWhere",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_BusinessCategoryWhere",
		and:"Contember_ProductCategoryListWhere",
		or:"Contember_ProductCategoryListWhere",
		not:"Contember_ProductCategoryListWhere"
	},
	Contember_ProductCategoryListItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		list:"Contember_ProductCategoryListWhere",
		productCategory:"Contember_ProductCategoryWhere",
		showRecipeImages:"Contember_BooleanCondition",
		showPackingImages:"Contember_BooleanCondition",
		showAllAvailableProducts:"Contember_BooleanCondition",
		and:"Contember_ProductCategoryListItemWhere",
		or:"Contember_ProductCategoryListItemWhere",
		not:"Contember_ProductCategoryListItemWhere"
	},
	Contember_ProductCategoryListLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_ProductCategoryListWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_ProductCategoryListLocaleWhere",
		or:"Contember_ProductCategoryListLocaleWhere",
		not:"Contember_ProductCategoryListLocaleWhere"
	},
	Contember_ProductIngredientLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_ProductIngredientWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_ProductIngredientLocaleWhere",
		or:"Contember_ProductIngredientLocaleWhere",
		not:"Contember_ProductIngredientLocaleWhere"
	},
	Contember_ProductIngredientWhere:{
		id:"Contember_UUIDCondition",
		internalNote:"Contember_StringCondition",
		locales:"Contember_ProductIngredientLocaleWhere",
		internalName:"Contember_StringCondition",
		categories:"Contember_ProductIngredientCategoryWhere",
		allergens:"Contember_AllergenWhere",
		needsPrepping:"Contember_BooleanCondition",
		allowCustomerRating:"Contember_BooleanCondition",
		customerRatings:"Contember_CustomerIngredientRatingWhere",
		preferredQuantityUnit:"Contember_ProductIngredientItemQuantityUnitWhere",
		freshingContainer:"Contember_FreshingContainerWhere",
		image:"Contember_ImageWhere",
		suppliers:"Contember_IngredientSupplierListWhere",
		incrementQuantityNumber:"Contember_FloatCondition",
		and:"Contember_ProductIngredientWhere",
		or:"Contember_ProductIngredientWhere",
		not:"Contember_ProductIngredientWhere"
	},
	Contember_ProductIngredientCategoryWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		ingredients:"Contember_ProductIngredientWhere",
		locales:"Contember_ProductIngredientCategoryLocaleWhere",
		order:"Contember_IntCondition",
		and:"Contember_ProductIngredientCategoryWhere",
		or:"Contember_ProductIngredientCategoryWhere",
		not:"Contember_ProductIngredientCategoryWhere"
	},
	Contember_ProductIngredientCategoryLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_ProductIngredientCategoryWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_ProductIngredientCategoryLocaleWhere",
		or:"Contember_ProductIngredientCategoryLocaleWhere",
		not:"Contember_ProductIngredientCategoryLocaleWhere"
	},
	Contember_AllergenWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		locales:"Contember_AllergenLocaleWhere",
		ingredients:"Contember_ProductIngredientWhere",
		code:"Contember_IntCondition",
		and:"Contember_AllergenWhere",
		or:"Contember_AllergenWhere",
		not:"Contember_AllergenWhere"
	},
	Contember_AllergenLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_AllergenWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_AllergenLocaleWhere",
		or:"Contember_AllergenLocaleWhere",
		not:"Contember_AllergenLocaleWhere"
	},
	Contember_CustomerIngredientRatingWhere:{
		id:"Contember_UUIDCondition",
		customer:"Contember_CustomerWhere",
		rating:"Contember_ProductIngredientRatingWhere",
		ingredient:"Contember_ProductIngredientWhere",
		and:"Contember_CustomerIngredientRatingWhere",
		or:"Contember_CustomerIngredientRatingWhere",
		not:"Contember_CustomerIngredientRatingWhere"
	},
	Contember_ProductIngredientRatingWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		name:"Contember_StringCondition",
		icon:"Contember_ImageWhere",
		and:"Contember_ProductIngredientRatingWhere",
		or:"Contember_ProductIngredientRatingWhere",
		not:"Contember_ProductIngredientRatingWhere"
	},
	Contember_ProductIngredientItemQuantityUnitWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		and:"Contember_ProductIngredientItemQuantityUnitWhere",
		or:"Contember_ProductIngredientItemQuantityUnitWhere",
		not:"Contember_ProductIngredientItemQuantityUnitWhere"
	},
	Contember_FreshingContainerWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		volumeMl:"Contember_IntCondition",
		workspace:"Contember_WorkspaceWhere",
		and:"Contember_FreshingContainerWhere",
		or:"Contember_FreshingContainerWhere",
		not:"Contember_FreshingContainerWhere"
	},
	Contember_WorkspaceWhere:{
		id:"Contember_UUIDCondition",
		unique:"Contember_OneCondition",
		orderDeadlineTime:"Contember_StringCondition",
		ordersLoadingDurationHours:"Contember_IntCondition",
		freshingContainerVolumeMl:"Contember_IntCondition",
		freshingPrepareOrderByOffsetMinutes:"Contember_IntCondition",
		checkoutUrlTemplate:"Contember_StringCondition",
		orderUrlTemplate:"Contember_StringCondition",
		paymentUrlTemplate:"Contember_StringCondition",
		customerProfileUrlTemplate:"Contember_StringCondition",
		userProfileUrlTemplate:"Contember_StringCondition",
		messengerUrl:"Contember_StringCondition",
		messengerIosUrl:"Contember_StringCondition",
		messengerAndroidUrl:"Contember_StringCondition",
		freshingContainers:"Contember_FreshingContainerWhere",
		openingHours:"Contember_WeekHoursWhere",
		workingHours:"Contember_WeekHoursWhere",
		defaultFreshingContainer:"Contember_FreshingContainerWhere",
		creditsProduct:"Contember_VirtualProductWhere",
		discountVatRate:"Contember_VatRateWhere",
		name:"Contember_StringCondition",
		businessAddress:"Contember_StringCondition",
		migrationTest:"Contember_StringCondition",
		and:"Contember_WorkspaceWhere",
		or:"Contember_WorkspaceWhere",
		not:"Contember_WorkspaceWhere"
	},
	Contember_OneCondition:{
		and:"Contember_OneCondition",
		or:"Contember_OneCondition",
		not:"Contember_OneCondition",
		eq:"Contember_One",
		notEq:"Contember_One",
		in:"Contember_One",
		notIn:"Contember_One",
		lt:"Contember_One",
		lte:"Contember_One",
		gt:"Contember_One",
		gte:"Contember_One"
	},
	Contember_One: true,
	Contember_WeekHoursWhere:{
		id:"Contember_UUIDCondition",
		mon:"Contember_StringCondition",
		tue:"Contember_StringCondition",
		wed:"Contember_StringCondition",
		thu:"Contember_StringCondition",
		fri:"Contember_StringCondition",
		sat:"Contember_StringCondition",
		sun:"Contember_StringCondition",
		and:"Contember_WeekHoursWhere",
		or:"Contember_WeekHoursWhere",
		not:"Contember_WeekHoursWhere"
	},
	Contember_VirtualProductWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_VirtualProductTypeCondition",
		product:"Contember_ProductWhere",
		voucherCreditCents:"Contember_IntCondition",
		physicalRepresentation:"Contember_VirtualProductPhysicalRepresentationTypeCondition",
		recipientEmail:"Contember_BooleanCondition",
		and:"Contember_VirtualProductWhere",
		or:"Contember_VirtualProductWhere",
		not:"Contember_VirtualProductWhere"
	},
	Contember_VirtualProductTypeCondition:{
		and:"Contember_VirtualProductTypeCondition",
		or:"Contember_VirtualProductTypeCondition",
		not:"Contember_VirtualProductTypeCondition",
		eq:"Contember_VirtualProductType",
		notEq:"Contember_VirtualProductType",
		in:"Contember_VirtualProductType",
		notIn:"Contember_VirtualProductType",
		lt:"Contember_VirtualProductType",
		lte:"Contember_VirtualProductType",
		gt:"Contember_VirtualProductType",
		gte:"Contember_VirtualProductType"
	},
	Contember_VirtualProductType: true,
	Contember_VirtualProductPhysicalRepresentationTypeCondition:{
		and:"Contember_VirtualProductPhysicalRepresentationTypeCondition",
		or:"Contember_VirtualProductPhysicalRepresentationTypeCondition",
		not:"Contember_VirtualProductPhysicalRepresentationTypeCondition",
		eq:"Contember_VirtualProductPhysicalRepresentationType",
		notEq:"Contember_VirtualProductPhysicalRepresentationType",
		in:"Contember_VirtualProductPhysicalRepresentationType",
		notIn:"Contember_VirtualProductPhysicalRepresentationType",
		lt:"Contember_VirtualProductPhysicalRepresentationType",
		lte:"Contember_VirtualProductPhysicalRepresentationType",
		gt:"Contember_VirtualProductPhysicalRepresentationType",
		gte:"Contember_VirtualProductPhysicalRepresentationType"
	},
	Contember_VirtualProductPhysicalRepresentationType: true,
	Contember_IngredientSupplierListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_IngredientSupplierItemWhere",
		and:"Contember_IngredientSupplierListWhere",
		or:"Contember_IngredientSupplierListWhere",
		not:"Contember_IngredientSupplierListWhere"
	},
	Contember_IngredientSupplierItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		list:"Contember_IngredientSupplierListWhere",
		supplier:"Contember_IngredientSupplierWhere",
		and:"Contember_IngredientSupplierItemWhere",
		or:"Contember_IngredientSupplierItemWhere",
		not:"Contember_IngredientSupplierItemWhere"
	},
	Contember_IngredientSupplierWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		locales:"Contember_IngredientSupplierLocaleWhere",
		and:"Contember_IngredientSupplierWhere",
		or:"Contember_IngredientSupplierWhere",
		not:"Contember_IngredientSupplierWhere"
	},
	Contember_IngredientSupplierLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_IngredientSupplierWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_IngredientSupplierLocaleWhere",
		or:"Contember_IngredientSupplierLocaleWhere",
		not:"Contember_IngredientSupplierLocaleWhere"
	},
	Contember_BlogLocaleWhere:{
		id:"Contember_UUIDCondition",
		pageName:"Contember_StringCondition",
		title:"Contember_StringCondition",
		lead:"Contember_StringCondition",
		root:"Contember_BlogWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_BlogLocaleWhere",
		or:"Contember_BlogLocaleWhere",
		not:"Contember_BlogLocaleWhere"
	},
	Contember_BlogWhere:{
		id:"Contember_UUIDCondition",
		unique:"Contember_OneCondition",
		locales:"Contember_BlogLocaleWhere",
		and:"Contember_BlogWhere",
		or:"Contember_BlogWhere",
		not:"Contember_BlogWhere"
	},
	Contember_BlogPostLocaleWhere:{
		id:"Contember_UUIDCondition",
		title:"Contember_StringCondition",
		availableForWeb:"Contember_BooleanCondition",
		availableForMobile:"Contember_BooleanCondition",
		root:"Contember_BlogPostWhere",
		locale:"Contember_LocaleWhere",
		cover:"Contember_CoverWhere",
		content:"Contember_ContentWhere",
		link:"Contember_LinkableWhere",
		seo:"Contember_SeoWhere",
		products:"Contember_ProductWhere",
		and:"Contember_BlogPostLocaleWhere",
		or:"Contember_BlogPostLocaleWhere",
		not:"Contember_BlogPostLocaleWhere"
	},
	Contember_BlogPostWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		locales:"Contember_BlogPostLocaleWhere",
		and:"Contember_BlogPostWhere",
		or:"Contember_BlogPostWhere",
		not:"Contember_BlogPostWhere"
	},
	Contember_CoverWhere:{
		id:"Contember_UUIDCondition",
		primaryTitle:"Contember_StringCondition",
		secondaryTitle:"Contember_StringCondition",
		medium:"Contember_MediumWhere",
		buttonColors:"Contember_ColorPalleteWhere",
		and:"Contember_CoverWhere",
		or:"Contember_CoverWhere",
		not:"Contember_CoverWhere"
	},
	Contember_MediumWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_MediumTypeCondition",
		colorTheme:"Contember_ColorThemeCondition",
		image:"Contember_ImageWhere",
		video:"Contember_VideoWhere",
		and:"Contember_MediumWhere",
		or:"Contember_MediumWhere",
		not:"Contember_MediumWhere"
	},
	Contember_MediumTypeCondition:{
		and:"Contember_MediumTypeCondition",
		or:"Contember_MediumTypeCondition",
		not:"Contember_MediumTypeCondition",
		eq:"Contember_MediumType",
		notEq:"Contember_MediumType",
		in:"Contember_MediumType",
		notIn:"Contember_MediumType",
		lt:"Contember_MediumType",
		lte:"Contember_MediumType",
		gt:"Contember_MediumType",
		gte:"Contember_MediumType"
	},
	Contember_MediumType: true,
	Contember_ColorThemeCondition:{
		and:"Contember_ColorThemeCondition",
		or:"Contember_ColorThemeCondition",
		not:"Contember_ColorThemeCondition",
		eq:"Contember_ColorTheme",
		notEq:"Contember_ColorTheme",
		in:"Contember_ColorTheme",
		notIn:"Contember_ColorTheme",
		lt:"Contember_ColorTheme",
		lte:"Contember_ColorTheme",
		gt:"Contember_ColorTheme",
		gte:"Contember_ColorTheme"
	},
	Contember_ColorTheme: true,
	Contember_VideoWhere:{
		id:"Contember_UUIDCondition",
		src:"Contember_StringCondition",
		width:"Contember_IntCondition",
		height:"Contember_IntCondition",
		size:"Contember_IntCondition",
		type:"Contember_StringCondition",
		duration:"Contember_FloatCondition",
		poster:"Contember_ImageWhere",
		and:"Contember_VideoWhere",
		or:"Contember_VideoWhere",
		not:"Contember_VideoWhere"
	},
	Contember_ColorPalleteWhere:{
		id:"Contember_UUIDCondition",
		background:"Contember_StringCondition",
		text:"Contember_StringCondition",
		and:"Contember_ColorPalleteWhere",
		or:"Contember_ColorPalleteWhere",
		not:"Contember_ColorPalleteWhere"
	},
	Contember_ContentWhere:{
		id:"Contember_UUIDCondition",
		blocks:"Contember_ContentBlockWhere",
		and:"Contember_ContentWhere",
		or:"Contember_ContentWhere",
		not:"Contember_ContentWhere"
	},
	Contember_ContentBlockWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		json:"Contember_StringCondition",
		content:"Contember_ContentWhere",
		references:"Contember_ContentReferenceWhere",
		and:"Contember_ContentBlockWhere",
		or:"Contember_ContentBlockWhere",
		not:"Contember_ContentBlockWhere"
	},
	Contember_ContentReferenceWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_ContentReferenceTypeCondition",
		primaryText:"Contember_StringCondition",
		secondaryText:"Contember_StringCondition",
		jsonContent:"Contember_StringCondition",
		block:"Contember_ContentBlockWhere",
		colorPallete:"Contember_ColorPalleteWhere",
		medium:"Contember_MediumWhere",
		link:"Contember_LinkWhere",
		blogPosts:"Contember_BlogPostListWhere",
		links:"Contember_LinkListWhere",
		products:"Contember_ProductListWhere",
		contentSize:"Contember_ContentSizeCondition",
		misc:"Contember_StringCondition",
		hero:"Contember_HeroWhere",
		gallery:"Contember_GalleryWhere",
		socialsAndApps:"Contember_SocialsAndAppsWhere",
		productGrid:"Contember_ProductGridWhere",
		productBanners:"Contember_ProductBannerListWhere",
		deliveryRegions:"Contember_DeliveryRegionsWhere",
		textList:"Contember_TextListWhere",
		gridTiles:"Contember_GridTilesWhere",
		and:"Contember_ContentReferenceWhere",
		or:"Contember_ContentReferenceWhere",
		not:"Contember_ContentReferenceWhere"
	},
	Contember_ContentReferenceTypeCondition:{
		and:"Contember_ContentReferenceTypeCondition",
		or:"Contember_ContentReferenceTypeCondition",
		not:"Contember_ContentReferenceTypeCondition",
		eq:"Contember_ContentReferenceType",
		notEq:"Contember_ContentReferenceType",
		in:"Contember_ContentReferenceType",
		notIn:"Contember_ContentReferenceType",
		lt:"Contember_ContentReferenceType",
		lte:"Contember_ContentReferenceType",
		gt:"Contember_ContentReferenceType",
		gte:"Contember_ContentReferenceType"
	},
	Contember_ContentReferenceType: true,
	Contember_LinkWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_LinkTypeCondition",
		title:"Contember_StringCondition",
		externalLink:"Contember_StringCondition",
		internalLink:"Contember_LinkableWhere",
		and:"Contember_LinkWhere",
		or:"Contember_LinkWhere",
		not:"Contember_LinkWhere"
	},
	Contember_LinkTypeCondition:{
		and:"Contember_LinkTypeCondition",
		or:"Contember_LinkTypeCondition",
		not:"Contember_LinkTypeCondition",
		eq:"Contember_LinkType",
		notEq:"Contember_LinkType",
		in:"Contember_LinkType",
		notIn:"Contember_LinkType",
		lt:"Contember_LinkType",
		lte:"Contember_LinkType",
		gt:"Contember_LinkType",
		gte:"Contember_LinkType"
	},
	Contember_LinkType: true,
	Contember_LinkableWhere:{
		id:"Contember_UUIDCondition",
		url:"Contember_StringCondition",
		blogPost:"Contember_BlogPostLocaleWhere",
		page:"Contember_PageLocaleWhere",
		wikiPage:"Contember_WikiPageWhere",
		redirect:"Contember_RedirectWhere",
		and:"Contember_LinkableWhere",
		or:"Contember_LinkableWhere",
		not:"Contember_LinkableWhere"
	},
	Contember_PageLocaleWhere:{
		id:"Contember_UUIDCondition",
		title:"Contember_StringCondition",
		link:"Contember_LinkableWhere",
		root:"Contember_PageWhere",
		locale:"Contember_LocaleWhere",
		seo:"Contember_SeoWhere",
		content:"Contember_ContentWhere",
		cover:"Contember_CoverWhere",
		theme:"Contember_PageThemeCondition",
		and:"Contember_PageLocaleWhere",
		or:"Contember_PageLocaleWhere",
		not:"Contember_PageLocaleWhere"
	},
	Contember_PageWhere:{
		id:"Contember_UUIDCondition",
		locales:"Contember_PageLocaleWhere",
		and:"Contember_PageWhere",
		or:"Contember_PageWhere",
		not:"Contember_PageWhere"
	},
	Contember_SeoWhere:{
		id:"Contember_UUIDCondition",
		title:"Contember_StringCondition",
		description:"Contember_StringCondition",
		ogTitle:"Contember_StringCondition",
		ogDescription:"Contember_StringCondition",
		ogImage:"Contember_ImageWhere",
		and:"Contember_SeoWhere",
		or:"Contember_SeoWhere",
		not:"Contember_SeoWhere"
	},
	Contember_PageThemeCondition:{
		and:"Contember_PageThemeCondition",
		or:"Contember_PageThemeCondition",
		not:"Contember_PageThemeCondition",
		eq:"Contember_PageTheme",
		notEq:"Contember_PageTheme",
		in:"Contember_PageTheme",
		notIn:"Contember_PageTheme",
		lt:"Contember_PageTheme",
		lte:"Contember_PageTheme",
		gt:"Contember_PageTheme",
		gte:"Contember_PageTheme"
	},
	Contember_PageTheme: true,
	Contember_WikiPageWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		title:"Contember_StringCondition",
		emoji:"Contember_StringCondition",
		link:"Contember_LinkableWhere",
		parent:"Contember_WikiPageWhere",
		children:"Contember_WikiPageWhere",
		content:"Contember_ContentWhere",
		and:"Contember_WikiPageWhere",
		or:"Contember_WikiPageWhere",
		not:"Contember_WikiPageWhere"
	},
	Contember_RedirectWhere:{
		id:"Contember_UUIDCondition",
		link:"Contember_LinkableWhere",
		target:"Contember_LinkWhere",
		and:"Contember_RedirectWhere",
		or:"Contember_RedirectWhere",
		not:"Contember_RedirectWhere"
	},
	Contember_BlogPostListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_BlogPostListItemWhere",
		and:"Contember_BlogPostListWhere",
		or:"Contember_BlogPostListWhere",
		not:"Contember_BlogPostListWhere"
	},
	Contember_BlogPostListItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		caption:"Contember_StringCondition",
		list:"Contember_BlogPostListWhere",
		blogPost:"Contember_BlogPostLocaleWhere",
		and:"Contember_BlogPostListItemWhere",
		or:"Contember_BlogPostListItemWhere",
		not:"Contember_BlogPostListItemWhere"
	},
	Contember_LinkListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_LinkListItemWhere",
		and:"Contember_LinkListWhere",
		or:"Contember_LinkListWhere",
		not:"Contember_LinkListWhere"
	},
	Contember_LinkListItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		isPrimary:"Contember_BooleanCondition",
		list:"Contember_LinkListWhere",
		link:"Contember_LinkWhere",
		and:"Contember_LinkListItemWhere",
		or:"Contember_LinkListItemWhere",
		not:"Contember_LinkListItemWhere"
	},
	Contember_ContentSizeCondition:{
		and:"Contember_ContentSizeCondition",
		or:"Contember_ContentSizeCondition",
		not:"Contember_ContentSizeCondition",
		eq:"Contember_ContentSize",
		notEq:"Contember_ContentSize",
		in:"Contember_ContentSize",
		notIn:"Contember_ContentSize",
		lt:"Contember_ContentSize",
		lte:"Contember_ContentSize",
		gt:"Contember_ContentSize",
		gte:"Contember_ContentSize"
	},
	Contember_ContentSize: true,
	Contember_HeroWhere:{
		id:"Contember_UUIDCondition",
		showLogo:"Contember_BooleanCondition",
		middleContent:"Contember_StringCondition",
		medium:"Contember_MediumWhere",
		and:"Contember_HeroWhere",
		or:"Contember_HeroWhere",
		not:"Contember_HeroWhere"
	},
	Contember_GalleryWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_GalleryItemWhere",
		and:"Contember_GalleryWhere",
		or:"Contember_GalleryWhere",
		not:"Contember_GalleryWhere"
	},
	Contember_GalleryItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		caption:"Contember_StringCondition",
		list:"Contember_GalleryWhere",
		medium:"Contember_MediumWhere",
		and:"Contember_GalleryItemWhere",
		or:"Contember_GalleryItemWhere",
		not:"Contember_GalleryItemWhere"
	},
	Contember_SocialsAndAppsWhere:{
		id:"Contember_UUIDCondition",
		instagram:"Contember_BooleanCondition",
		appStore:"Contember_BooleanCondition",
		googlePlay:"Contember_BooleanCondition",
		and:"Contember_SocialsAndAppsWhere",
		or:"Contember_SocialsAndAppsWhere",
		not:"Contember_SocialsAndAppsWhere"
	},
	Contember_ProductGridWhere:{
		id:"Contember_UUIDCondition",
		isCarousel:"Contember_BooleanCondition",
		categories:"Contember_ProductGridCategoryWhere",
		type:"Contember_ProductGridTypeCondition",
		and:"Contember_ProductGridWhere",
		or:"Contember_ProductGridWhere",
		not:"Contember_ProductGridWhere"
	},
	Contember_ProductGridCategoryWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		title:"Contember_StringCondition",
		root:"Contember_ProductGridWhere",
		items:"Contember_ProductGridItemWhere",
		images:"Contember_ImageListWhere",
		and:"Contember_ProductGridCategoryWhere",
		or:"Contember_ProductGridCategoryWhere",
		not:"Contember_ProductGridCategoryWhere"
	},
	Contember_ProductGridItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		title:"Contember_StringCondition",
		lead:"Contember_StringCondition",
		product:"Contember_ProductWhere",
		medium:"Contember_MediumWhere",
		link:"Contember_LinkWhere",
		category:"Contember_ProductGridCategoryWhere",
		description:"Contember_StringCondition",
		and:"Contember_ProductGridItemWhere",
		or:"Contember_ProductGridItemWhere",
		not:"Contember_ProductGridItemWhere"
	},
	Contember_ImageListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_ImageListItemWhere",
		and:"Contember_ImageListWhere",
		or:"Contember_ImageListWhere",
		not:"Contember_ImageListWhere"
	},
	Contember_ImageListItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		list:"Contember_ImageListWhere",
		image:"Contember_ImageWhere",
		and:"Contember_ImageListItemWhere",
		or:"Contember_ImageListItemWhere",
		not:"Contember_ImageListItemWhere"
	},
	Contember_ProductGridTypeCondition:{
		and:"Contember_ProductGridTypeCondition",
		or:"Contember_ProductGridTypeCondition",
		not:"Contember_ProductGridTypeCondition",
		eq:"Contember_ProductGridType",
		notEq:"Contember_ProductGridType",
		in:"Contember_ProductGridType",
		notIn:"Contember_ProductGridType",
		lt:"Contember_ProductGridType",
		lte:"Contember_ProductGridType",
		gt:"Contember_ProductGridType",
		gte:"Contember_ProductGridType"
	},
	Contember_ProductGridType: true,
	Contember_ProductBannerListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_ProductBannerItemWhere",
		and:"Contember_ProductBannerListWhere",
		or:"Contember_ProductBannerListWhere",
		not:"Contember_ProductBannerListWhere"
	},
	Contember_ProductBannerItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		list:"Contember_ProductBannerListWhere",
		banner:"Contember_ProductBannerWhere",
		size:"Contember_ProductBannerItemSizeCondition",
		and:"Contember_ProductBannerItemWhere",
		or:"Contember_ProductBannerItemWhere",
		not:"Contember_ProductBannerItemWhere"
	},
	Contember_ProductBannerWhere:{
		id:"Contember_UUIDCondition",
		beforeTitle:"Contember_StringCondition",
		title:"Contember_StringCondition",
		label:"Contember_StringCondition",
		button:"Contember_LinkWhere",
		backgroundImage:"Contember_ImageWhere",
		productImage:"Contember_ImageWhere",
		product:"Contember_ProductWhere",
		and:"Contember_ProductBannerWhere",
		or:"Contember_ProductBannerWhere",
		not:"Contember_ProductBannerWhere"
	},
	Contember_ProductBannerItemSizeCondition:{
		and:"Contember_ProductBannerItemSizeCondition",
		or:"Contember_ProductBannerItemSizeCondition",
		not:"Contember_ProductBannerItemSizeCondition",
		eq:"Contember_ProductBannerItemSize",
		notEq:"Contember_ProductBannerItemSize",
		in:"Contember_ProductBannerItemSize",
		notIn:"Contember_ProductBannerItemSize",
		lt:"Contember_ProductBannerItemSize",
		lte:"Contember_ProductBannerItemSize",
		gt:"Contember_ProductBannerItemSize",
		gte:"Contember_ProductBannerItemSize"
	},
	Contember_ProductBannerItemSize: true,
	Contember_DeliveryRegionsWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_DeliveryRegionsItemWhere",
		and:"Contember_DeliveryRegionsWhere",
		or:"Contember_DeliveryRegionsWhere",
		not:"Contember_DeliveryRegionsWhere"
	},
	Contember_DeliveryRegionsItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		title:"Contember_StringCondition",
		offerLabel:"Contember_StringCondition",
		list:"Contember_DeliveryRegionsWhere",
		image:"Contember_ImageWhere",
		textList:"Contember_TextListWhere",
		gallery:"Contember_ImageListWhere",
		and:"Contember_DeliveryRegionsItemWhere",
		or:"Contember_DeliveryRegionsItemWhere",
		not:"Contember_DeliveryRegionsItemWhere"
	},
	Contember_TextListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_TextItemWhere",
		and:"Contember_TextListWhere",
		or:"Contember_TextListWhere",
		not:"Contember_TextListWhere"
	},
	Contember_TextItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		text:"Contember_StringCondition",
		list:"Contember_TextListWhere",
		title:"Contember_StringCondition",
		and:"Contember_TextItemWhere",
		or:"Contember_TextItemWhere",
		not:"Contember_TextItemWhere"
	},
	Contember_GridTilesWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_GridTileItemWhere",
		and:"Contember_GridTilesWhere",
		or:"Contember_GridTilesWhere",
		not:"Contember_GridTilesWhere"
	},
	Contember_GridTileItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		text:"Contember_StringCondition",
		list:"Contember_GridTilesWhere",
		medium:"Contember_MediumWhere",
		link:"Contember_LinkWhere",
		and:"Contember_GridTileItemWhere",
		or:"Contember_GridTileItemWhere",
		not:"Contember_GridTileItemWhere"
	},
	Contember_TranslationCataloguesIdentifierWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		catalogue:"Contember_TranslationCatalogueWhere",
		and:"Contember_TranslationCataloguesIdentifierWhere",
		or:"Contember_TranslationCataloguesIdentifierWhere",
		not:"Contember_TranslationCataloguesIdentifierWhere"
	},
	Contember_TranslationCatalogueWhere:{
		id:"Contember_UUIDCondition",
		domain:"Contember_TranslationDomainWhere",
		fallback:"Contember_TranslationCatalogueWhere",
		values:"Contember_TranslationValueWhere",
		identifier:"Contember_TranslationCataloguesIdentifierWhere",
		and:"Contember_TranslationCatalogueWhere",
		or:"Contember_TranslationCatalogueWhere",
		not:"Contember_TranslationCatalogueWhere"
	},
	Contember_TranslationDomainWhere:{
		id:"Contember_UUIDCondition",
		identifier:"Contember_StringCondition",
		name:"Contember_StringCondition",
		catalogues:"Contember_TranslationCatalogueWhere",
		keys:"Contember_TranslationKeyWhere",
		and:"Contember_TranslationDomainWhere",
		or:"Contember_TranslationDomainWhere",
		not:"Contember_TranslationDomainWhere"
	},
	Contember_TranslationKeyWhere:{
		id:"Contember_UUIDCondition",
		identifier:"Contember_StringCondition",
		contentType:"Contember_TranslationContentTypeCondition",
		note:"Contember_StringCondition",
		domain:"Contember_TranslationDomainWhere",
		values:"Contember_TranslationValueWhere",
		and:"Contember_TranslationKeyWhere",
		or:"Contember_TranslationKeyWhere",
		not:"Contember_TranslationKeyWhere"
	},
	Contember_TranslationContentTypeCondition:{
		and:"Contember_TranslationContentTypeCondition",
		or:"Contember_TranslationContentTypeCondition",
		not:"Contember_TranslationContentTypeCondition",
		eq:"Contember_TranslationContentType",
		notEq:"Contember_TranslationContentType",
		in:"Contember_TranslationContentType",
		notIn:"Contember_TranslationContentType",
		lt:"Contember_TranslationContentType",
		lte:"Contember_TranslationContentType",
		gt:"Contember_TranslationContentType",
		gte:"Contember_TranslationContentType"
	},
	Contember_TranslationContentType: true,
	Contember_TranslationValueWhere:{
		id:"Contember_UUIDCondition",
		value:"Contember_StringCondition",
		catalogue:"Contember_TranslationCatalogueWhere",
		key:"Contember_TranslationKeyWhere",
		and:"Contember_TranslationValueWhere",
		or:"Contember_TranslationValueWhere",
		not:"Contember_TranslationValueWhere"
	},
	Contember_MenuLocaleWhere:{
		id:"Contember_UUIDCondition",
		root:"Contember_SiteMenuWhere",
		locale:"Contember_LocaleWhere",
		items:"Contember_MenuItemWhere",
		secondaryItems:"Contember_LinkListWhere",
		eshopLink:"Contember_LinkWhere",
		and:"Contember_MenuLocaleWhere",
		or:"Contember_MenuLocaleWhere",
		not:"Contember_MenuLocaleWhere"
	},
	Contember_SiteMenuWhere:{
		id:"Contember_UUIDCondition",
		unique:"Contember_OneCondition",
		locales:"Contember_MenuLocaleWhere",
		and:"Contember_SiteMenuWhere",
		or:"Contember_SiteMenuWhere",
		not:"Contember_SiteMenuWhere"
	},
	Contember_MenuItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		menu:"Contember_MenuLocaleWhere",
		link:"Contember_LinkWhere",
		subitems:"Contember_MenuSubitemWhere",
		and:"Contember_MenuItemWhere",
		or:"Contember_MenuItemWhere",
		not:"Contember_MenuItemWhere"
	},
	Contember_MenuSubitemWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_MenuSubitemTypeCondition",
		title:"Contember_StringCondition",
		item:"Contember_MenuItemWhere",
		links:"Contember_MenuLinkListWhere",
		products:"Contember_MenuProductsWhere",
		delivery:"Contember_MenuDeliveryWhere",
		contact:"Contember_MenuContactWhere",
		order:"Contember_IntCondition",
		and:"Contember_MenuSubitemWhere",
		or:"Contember_MenuSubitemWhere",
		not:"Contember_MenuSubitemWhere"
	},
	Contember_MenuSubitemTypeCondition:{
		and:"Contember_MenuSubitemTypeCondition",
		or:"Contember_MenuSubitemTypeCondition",
		not:"Contember_MenuSubitemTypeCondition",
		eq:"Contember_MenuSubitemType",
		notEq:"Contember_MenuSubitemType",
		in:"Contember_MenuSubitemType",
		notIn:"Contember_MenuSubitemType",
		lt:"Contember_MenuSubitemType",
		lte:"Contember_MenuSubitemType",
		gt:"Contember_MenuSubitemType",
		gte:"Contember_MenuSubitemType"
	},
	Contember_MenuSubitemType: true,
	Contember_MenuLinkListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_MenuLinkItemWhere",
		and:"Contember_MenuLinkListWhere",
		or:"Contember_MenuLinkListWhere",
		not:"Contember_MenuLinkListWhere"
	},
	Contember_MenuLinkItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		list:"Contember_MenuLinkListWhere",
		link:"Contember_LinkWhere",
		image:"Contember_ImageWhere",
		description:"Contember_StringCondition",
		and:"Contember_MenuLinkItemWhere",
		or:"Contember_MenuLinkItemWhere",
		not:"Contember_MenuLinkItemWhere"
	},
	Contember_MenuProductsWhere:{
		id:"Contember_UUIDCondition",
		links:"Contember_MenuLinkListWhere",
		buttons:"Contember_LinkListWhere",
		and:"Contember_MenuProductsWhere",
		or:"Contember_MenuProductsWhere",
		not:"Contember_MenuProductsWhere"
	},
	Contember_MenuDeliveryWhere:{
		id:"Contember_UUIDCondition",
		deliveryRegions:"Contember_DeliveryRegionsWhere",
		text:"Contember_StringCondition",
		socialsAndApps:"Contember_SocialsAndAppsWhere",
		and:"Contember_MenuDeliveryWhere",
		or:"Contember_MenuDeliveryWhere",
		not:"Contember_MenuDeliveryWhere"
	},
	Contember_MenuContactWhere:{
		id:"Contember_UUIDCondition",
		text:"Contember_StringCondition",
		image:"Contember_ImageWhere",
		and:"Contember_MenuContactWhere",
		or:"Contember_MenuContactWhere",
		not:"Contember_MenuContactWhere"
	},
	Contember_ProductLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		description:"Contember_StringCondition",
		root:"Contember_ProductWhere",
		locale:"Contember_LocaleWhere",
		featureList:"Contember_TextListWhere",
		printableName:"Contember_StringCondition",
		firstSticker:"Contember_StringCondition",
		secondSticker:"Contember_StringCondition",
		and:"Contember_ProductLocaleWhere",
		or:"Contember_ProductLocaleWhere",
		not:"Contember_ProductLocaleWhere"
	},
	Contember_ProductRecipeLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_ProductRecipeWhere",
		locale:"Contember_LocaleWhere",
		description:"Contember_StringCondition",
		and:"Contember_ProductRecipeLocaleWhere",
		or:"Contember_ProductRecipeLocaleWhere",
		not:"Contember_ProductRecipeLocaleWhere"
	},
	Contember_ProductRecipeWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		products:"Contember_ProductWhere",
		internalNote:"Contember_StringCondition",
		color:"Contember_StringCondition",
		image:"Contember_ImageWhere",
		icon:"Contember_ImageWhere",
		ingredients:"Contember_ProductIngredientItemWhere",
		needsPrepping:"Contember_BooleanCondition",
		locales:"Contember_ProductRecipeLocaleWhere",
		gutTuning:"Contember_FloatCondition",
		freshingContainer:"Contember_FreshingContainerWhere",
		description:"Contember_StringCondition",
		nutritionFacts:"Contember_NutritionFactsWhere",
		and:"Contember_ProductRecipeWhere",
		or:"Contember_ProductRecipeWhere",
		not:"Contember_ProductRecipeWhere"
	},
	Contember_ProductIngredientItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		internalOrder:"Contember_IntCondition",
		recipe:"Contember_ProductRecipeWhere",
		ingredient:"Contember_ProductIngredientWhere",
		quantity:"Contember_ProductIngredientItemQuantityWhere",
		and:"Contember_ProductIngredientItemWhere",
		or:"Contember_ProductIngredientItemWhere",
		not:"Contember_ProductIngredientItemWhere"
	},
	Contember_ProductIngredientItemQuantityWhere:{
		id:"Contember_UUIDCondition",
		unit:"Contember_ProductIngredientItemQuantityUnitWhere",
		value:"Contember_FloatCondition",
		and:"Contember_ProductIngredientItemQuantityWhere",
		or:"Contember_ProductIngredientItemQuantityWhere",
		not:"Contember_ProductIngredientItemQuantityWhere"
	},
	Contember_NutritionFactsWhere:{
		id:"Contember_UUIDCondition",
		energyKiloJoule:"Contember_FloatCondition",
		energyKiloCalorie:"Contember_FloatCondition",
		fatsGrams:"Contember_FloatCondition",
		fatsOfWhichSaturatedGrams:"Contember_FloatCondition",
		carbohydrateGrams:"Contember_FloatCondition",
		carbohydrateOfWhichSugarsGrams:"Contember_FloatCondition",
		proteinGrams:"Contember_FloatCondition",
		fibreGrams:"Contember_FloatCondition",
		saltGrams:"Contember_FloatCondition",
		and:"Contember_NutritionFactsWhere",
		or:"Contember_NutritionFactsWhere",
		not:"Contember_NutritionFactsWhere"
	},
	Contember_DeliveryMethodLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		description:"Contember_StringCondition",
		trackingCodeUrlTemplate:"Contember_StringCondition",
		processingTitle:"Contember_StringCondition",
		processingText:"Contember_StringCondition",
		processingIconUrl:"Contember_StringCondition",
		dispatchedTitle:"Contember_StringCondition",
		dispatchedText:"Contember_StringCondition",
		dispatchedIconUrl:"Contember_StringCondition",
		fulfilledTitle:"Contember_StringCondition",
		fulfilledText:"Contember_StringCondition",
		fulfilledIconUrl:"Contember_StringCondition",
		locale:"Contember_LocaleWhere",
		root:"Contember_DeliveryMethodWhere",
		and:"Contember_DeliveryMethodLocaleWhere",
		or:"Contember_DeliveryMethodLocaleWhere",
		not:"Contember_DeliveryMethodLocaleWhere"
	},
	Contember_PipelineStageLocaleWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		root:"Contember_PipelineStageWhere",
		locale:"Contember_LocaleWhere",
		and:"Contember_PipelineStageLocaleWhere",
		or:"Contember_PipelineStageLocaleWhere",
		not:"Contember_PipelineStageLocaleWhere"
	},
	Contember_PipelineStageWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_PipelineStageItemWhere",
		pipeline:"Contember_PipelineWhere",
		order:"Contember_IntCondition",
		locales:"Contember_PipelineStageLocaleWhere",
		sentiment:"Contember_PipelineStageSentimentWhere",
		type:"Contember_PipelineStageTypeCondition",
		forms:"Contember_LeadFormWhere",
		showItemsWeight:"Contember_BooleanCondition",
		showItemsPrice:"Contember_BooleanCondition",
		and:"Contember_PipelineStageWhere",
		or:"Contember_PipelineStageWhere",
		not:"Contember_PipelineStageWhere"
	},
	Contember_PipelineStageItemWhere:{
		id:"Contember_UUIDCondition",
		list:"Contember_PipelineStageWhere",
		lead:"Contember_PipelineLeadWhere",
		order:"Contember_IntCondition",
		and:"Contember_PipelineStageItemWhere",
		or:"Contember_PipelineStageItemWhere",
		not:"Contember_PipelineStageItemWhere"
	},
	Contember_PipelineLeadWhere:{
		id:"Contember_UUIDCondition",
		createdAt:"Contember_DateTimeCondition",
		stageItem:"Contember_PipelineStageItemWhere",
		contactPerson:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		cafeType:"Contember_StringCondition",
		offerType:"Contember_StringCondition",
		ownMachine:"Contember_BooleanCondition",
		customerCity:"Contember_StringCondition",
		companyId:"Contember_StringCondition",
		customerName:"Contember_StringCondition",
		email:"Contember_StringCondition",
		phone:"Contember_StringCondition",
		form:"Contember_LeadFormWhere",
		meta:"Contember_StringCondition",
		multiroasterType:"Contember_StringCondition",
		and:"Contember_PipelineLeadWhere",
		or:"Contember_PipelineLeadWhere",
		not:"Contember_PipelineLeadWhere"
	},
	Contember_LeadFormWhere:{
		id:"Contember_UUIDCondition",
		code:"Contember_StringCondition",
		stage:"Contember_PipelineStageWhere",
		leads:"Contember_PipelineLeadWhere",
		and:"Contember_LeadFormWhere",
		or:"Contember_LeadFormWhere",
		not:"Contember_LeadFormWhere"
	},
	Contember_PipelineWhere:{
		id:"Contember_UUIDCondition",
		unique:"Contember_OneCondition",
		stages:"Contember_PipelineStageWhere",
		and:"Contember_PipelineWhere",
		or:"Contember_PipelineWhere",
		not:"Contember_PipelineWhere"
	},
	Contember_PipelineStageSentimentWhere:{
		id:"Contember_UUIDCondition",
		internalName:"Contember_StringCondition",
		backgroundColor:"Contember_StringCondition",
		textColor:"Contember_StringCondition",
		and:"Contember_PipelineStageSentimentWhere",
		or:"Contember_PipelineStageSentimentWhere",
		not:"Contember_PipelineStageSentimentWhere"
	},
	Contember_PipelineStageTypeCondition:{
		and:"Contember_PipelineStageTypeCondition",
		or:"Contember_PipelineStageTypeCondition",
		not:"Contember_PipelineStageTypeCondition",
		eq:"Contember_PipelineStageType",
		notEq:"Contember_PipelineStageType",
		in:"Contember_PipelineStageType",
		notIn:"Contember_PipelineStageType",
		lt:"Contember_PipelineStageType",
		lte:"Contember_PipelineStageType",
		gt:"Contember_PipelineStageType",
		gte:"Contember_PipelineStageType"
	},
	Contember_PipelineStageType: true,
	Contember_SubscriptionBoxLocaleWhere:{
		id:"Contember_UUIDCondition",
		title:"Contember_StringCondition",
		stickerText:"Contember_StringCondition",
		descriptionText:"Contember_StringCondition",
		root:"Contember_SubscriptionBoxWhere",
		locale:"Contember_LocaleWhere",
		secondaryTitle:"Contember_StringCondition",
		texts:"Contember_TextListWhere",
		secondaryTexts:"Contember_TextListWhere",
		and:"Contember_SubscriptionBoxLocaleWhere",
		or:"Contember_SubscriptionBoxLocaleWhere",
		not:"Contember_SubscriptionBoxLocaleWhere"
	},
	Contember_SubscriptionBoxWhere:{
		id:"Contember_UUIDCondition",
		locales:"Contember_SubscriptionBoxLocaleWhere",
		unique:"Contember_OneCondition",
		and:"Contember_SubscriptionBoxWhere",
		or:"Contember_SubscriptionBoxWhere",
		not:"Contember_SubscriptionBoxWhere"
	},
	Contember_PreparedPackingQuantityWhere:{
		id:"Contember_UUIDCondition",
		date:"Contember_DateCondition",
		quantity:"Contember_FloatCondition",
		recipe:"Contember_ProductRecipeWhere",
		packing:"Contember_ProductPackingWhere",
		packedInCardboard:"Contember_BooleanCondition",
		and:"Contember_PreparedPackingQuantityWhere",
		or:"Contember_PreparedPackingQuantityWhere",
		not:"Contember_PreparedPackingQuantityWhere"
	},
	Contember_ProductStockWhere:{
		id:"Contember_UUIDCondition",
		quantity:"Contember_IntCondition",
		product:"Contember_ProductWhere",
		store:"Contember_StoreWhere",
		and:"Contember_ProductStockWhere",
		or:"Contember_ProductStockWhere",
		not:"Contember_ProductStockWhere"
	},
	Contember_StoreWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		code:"Contember_StringCondition",
		description:"Contember_StringCondition",
		internalNote:"Contember_StringCondition",
		and:"Contember_StoreWhere",
		or:"Contember_StoreWhere",
		not:"Contember_StoreWhere"
	},
	Contember_ProductGroupPriceWhere:{
		id:"Contember_UUIDCondition",
		priceCents:"Contember_IntCondition",
		group:"Contember_CustomerGroupWhere",
		product:"Contember_ProductWhere",
		and:"Contember_ProductGroupPriceWhere",
		or:"Contember_ProductGroupPriceWhere",
		not:"Contember_ProductGroupPriceWhere"
	},
	Contember_CustomerGroupWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		productPrices:"Contember_ProductGroupPriceWhere",
		customers:"Contember_CustomerWhere",
		discountPermille:"Contember_IntCondition",
		code:"Contember_StringCondition",
		doubleshotLegacyId:"Contember_StringCondition",
		and:"Contember_CustomerGroupWhere",
		or:"Contember_CustomerGroupWhere",
		not:"Contember_CustomerGroupWhere"
	},
	Contember_ProductAvailabilityCondition:{
		and:"Contember_ProductAvailabilityCondition",
		or:"Contember_ProductAvailabilityCondition",
		not:"Contember_ProductAvailabilityCondition",
		eq:"Contember_ProductAvailability",
		notEq:"Contember_ProductAvailability",
		in:"Contember_ProductAvailability",
		notIn:"Contember_ProductAvailability",
		lt:"Contember_ProductAvailability",
		lte:"Contember_ProductAvailability",
		gt:"Contember_ProductAvailability",
		gte:"Contember_ProductAvailability"
	},
	Contember_ProductAvailability: true,
	Contember_ProductCustomerPriceWhere:{
		id:"Contember_UUIDCondition",
		groupDiscountPermille:"Contember_IntCondition",
		groupPriceCents:"Contember_IntCondition",
		fullPriceCents:"Contember_IntCondition",
		discountedPriceCents:"Contember_IntCondition",
		priceCents:"Contember_IntCondition",
		offered:"Contember_BooleanCondition",
		notDiscountable:"Contember_BooleanCondition",
		customer:"Contember_CustomerWhere",
		product:"Contember_ProductWhere",
		and:"Contember_ProductCustomerPriceWhere",
		or:"Contember_ProductCustomerPriceWhere",
		not:"Contember_ProductCustomerPriceWhere"
	},
	Contember_ProductMetadataWhere:{
		id:"Contember_UUIDCondition",
		archived:"Contember_BooleanCondition",
		available:"Contember_BooleanCondition",
		availableQuantity:"Contember_IntCondition",
		product:"Contember_ProductWhere",
		and:"Contember_ProductMetadataWhere",
		or:"Contember_ProductMetadataWhere",
		not:"Contember_ProductMetadataWhere"
	},
	Contember_ProductSalesDayWhere:{
		id:"Contember_UUIDCondition",
		productName:"Contember_StringCondition",
		orderDate:"Contember_DateCondition",
		priceCentsSum:"Contember_IntCondition",
		sum:"Contember_IntCondition",
		product:"Contember_ProductWhere",
		and:"Contember_ProductSalesDayWhere",
		or:"Contember_ProductSalesDayWhere",
		not:"Contember_ProductSalesDayWhere"
	},
	Contember_ProductSalesWeekWhere:{
		id:"Contember_UUIDCondition",
		productName:"Contember_StringCondition",
		orderDate:"Contember_DateCondition",
		priceCentsSum:"Contember_IntCondition",
		sum:"Contember_IntCondition",
		product:"Contember_ProductWhere",
		and:"Contember_ProductSalesWeekWhere",
		or:"Contember_ProductSalesWeekWhere",
		not:"Contember_ProductSalesWeekWhere"
	},
	Contember_ProductSalesMonthWhere:{
		id:"Contember_UUIDCondition",
		productName:"Contember_StringCondition",
		orderDate:"Contember_DateCondition",
		priceCentsSum:"Contember_IntCondition",
		sum:"Contember_IntCondition",
		product:"Contember_ProductWhere",
		and:"Contember_ProductSalesMonthWhere",
		or:"Contember_ProductSalesMonthWhere",
		not:"Contember_ProductSalesMonthWhere"
	},
	Contember_AddressMetadataWhere:{
		id:"Contember_UUIDCondition",
		deliveryZoneType:"Contember_DeliveryZoneTypeCondition",
		address:"Contember_AddressWhere",
		deliveryZone:"Contember_DeliveryZoneWhere",
		and:"Contember_AddressMetadataWhere",
		or:"Contember_AddressMetadataWhere",
		not:"Contember_AddressMetadataWhere"
	},
	Contember_DeliveryTimelinePresetWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_DeliveryTimelineTypeCondition",
		duration:"Contember_StringCondition",
		window:"Contember_StringCondition",
		deliveryMethod:"Contember_DeliveryMethodWhere",
		and:"Contember_DeliveryTimelinePresetWhere",
		or:"Contember_DeliveryTimelinePresetWhere",
		not:"Contember_DeliveryTimelinePresetWhere"
	},
	Contember_DeliveryTimelineTypeCondition:{
		and:"Contember_DeliveryTimelineTypeCondition",
		or:"Contember_DeliveryTimelineTypeCondition",
		not:"Contember_DeliveryTimelineTypeCondition",
		eq:"Contember_DeliveryTimelineType",
		notEq:"Contember_DeliveryTimelineType",
		in:"Contember_DeliveryTimelineType",
		notIn:"Contember_DeliveryTimelineType",
		lt:"Contember_DeliveryTimelineType",
		lte:"Contember_DeliveryTimelineType",
		gt:"Contember_DeliveryTimelineType",
		gte:"Contember_DeliveryTimelineType"
	},
	Contember_DeliveryTimelineType: true,
	Contember_PaymentMethodTypeCondition:{
		and:"Contember_PaymentMethodTypeCondition",
		or:"Contember_PaymentMethodTypeCondition",
		not:"Contember_PaymentMethodTypeCondition",
		eq:"Contember_PaymentMethodType",
		notEq:"Contember_PaymentMethodType",
		in:"Contember_PaymentMethodType",
		notIn:"Contember_PaymentMethodType",
		lt:"Contember_PaymentMethodType",
		lte:"Contember_PaymentMethodType",
		gt:"Contember_PaymentMethodType",
		gte:"Contember_PaymentMethodType"
	},
	Contember_PaymentMethodType: true,
	Contember_CustomerPermissionsWhere:{
		id:"Contember_UUIDCondition",
		canPlaceOrder:"Contember_BooleanCondition",
		customer:"Contember_CustomerWhere",
		and:"Contember_CustomerPermissionsWhere",
		or:"Contember_CustomerPermissionsWhere",
		not:"Contember_CustomerPermissionsWhere"
	},
	Contember_CustomerNameWhere:{
		id:"Contember_UUIDCondition",
		nominative:"Contember_StringCondition",
		vocative:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		aliases:"Contember_CustomerAliasWhere",
		and:"Contember_CustomerNameWhere",
		or:"Contember_CustomerNameWhere",
		not:"Contember_CustomerNameWhere"
	},
	Contember_CustomerAliasWhere:{
		id:"Contember_UUIDCondition",
		name:"Contember_StringCondition",
		order:"Contember_IntCondition",
		and:"Contember_CustomerAliasWhere",
		or:"Contember_CustomerAliasWhere",
		not:"Contember_CustomerAliasWhere"
	},
	Contember_CustomerFamiliarityCondition:{
		and:"Contember_CustomerFamiliarityCondition",
		or:"Contember_CustomerFamiliarityCondition",
		not:"Contember_CustomerFamiliarityCondition",
		eq:"Contember_CustomerFamiliarity",
		notEq:"Contember_CustomerFamiliarity",
		in:"Contember_CustomerFamiliarity",
		notIn:"Contember_CustomerFamiliarity",
		lt:"Contember_CustomerFamiliarity",
		lte:"Contember_CustomerFamiliarity",
		gt:"Contember_CustomerFamiliarity",
		gte:"Contember_CustomerFamiliarity"
	},
	Contember_CustomerFamiliarity: true,
	Contember_ApplicationShortcutListWhere:{
		id:"Contember_UUIDCondition",
		items:"Contember_ApplicationShortcutListItemWhere",
		customer:"Contember_CustomerWhere",
		and:"Contember_ApplicationShortcutListWhere",
		or:"Contember_ApplicationShortcutListWhere",
		not:"Contember_ApplicationShortcutListWhere"
	},
	Contember_ApplicationShortcutListItemWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		application:"Contember_ApplicationWhere",
		list:"Contember_ApplicationShortcutListWhere",
		and:"Contember_ApplicationShortcutListItemWhere",
		or:"Contember_ApplicationShortcutListItemWhere",
		not:"Contember_ApplicationShortcutListItemWhere"
	},
	Contember_ApplicationWhere:{
		id:"Contember_UUIDCondition",
		order:"Contember_IntCondition",
		handle:"Contember_StringCondition",
		url:"Contember_StringCondition",
		color:"Contember_StringCondition",
		icon:"Contember_ImageWhere",
		name:"Contember_StringCondition",
		short:"Contember_StringCondition",
		and:"Contember_ApplicationWhere",
		or:"Contember_ApplicationWhere",
		not:"Contember_ApplicationWhere"
	},
	Contember_InvoicingPlanWhere:{
		id:"Contember_UUIDCondition",
		customer:"Contember_CustomerWhere",
		preset:"Contember_InvoicingPresetWhere",
		and:"Contember_InvoicingPlanWhere",
		or:"Contember_InvoicingPlanWhere",
		not:"Contember_InvoicingPlanWhere"
	},
	Contember_InvoicingPresetWhere:{
		id:"Contember_UUIDCondition",
		type:"Contember_InvoicingPresetTypeCondition",
		startsAt:"Contember_DateTimeCondition",
		endsAt:"Contember_DateTimeCondition",
		intervalOfDays:"Contember_IntCondition",
		invoicingPlan:"Contember_InvoicingPlanWhere",
		and:"Contember_InvoicingPresetWhere",
		or:"Contember_InvoicingPresetWhere",
		not:"Contember_InvoicingPresetWhere"
	},
	Contember_InvoicingPresetTypeCondition:{
		and:"Contember_InvoicingPresetTypeCondition",
		or:"Contember_InvoicingPresetTypeCondition",
		not:"Contember_InvoicingPresetTypeCondition",
		eq:"Contember_InvoicingPresetType",
		notEq:"Contember_InvoicingPresetType",
		in:"Contember_InvoicingPresetType",
		notIn:"Contember_InvoicingPresetType",
		lt:"Contember_InvoicingPresetType",
		lte:"Contember_InvoicingPresetType",
		gt:"Contember_InvoicingPresetType",
		gte:"Contember_InvoicingPresetType"
	},
	Contember_InvoicingPresetType: true,
	Contember_CustomerSpendingWhere:{
		id:"Contember_UUIDCondition",
		totalCents:"Contember_IntCondition",
		customer:"Contember_CustomerWhere",
		and:"Contember_CustomerSpendingWhere",
		or:"Contember_CustomerSpendingWhere",
		not:"Contember_CustomerSpendingWhere"
	},
	Contember_CustomerMetadataWhere:{
		id:"Contember_UUIDCondition",
		isBusiness:"Contember_BooleanCondition",
		name:"Contember_StringCondition",
		fallbackName:"Contember_StringCondition",
		remainingCreditsCents:"Contember_IntCondition",
		ordersTotalCents:"Contember_IntCondition",
		customerProfileUrl:"Contember_StringCondition",
		customer:"Contember_CustomerWhere",
		and:"Contember_CustomerMetadataWhere",
		or:"Contember_CustomerMetadataWhere",
		not:"Contember_CustomerMetadataWhere"
	},
	Contember_GpsWhere:{
		id:"Contember_UUIDCondition",
		latitude:"Contember_FloatCondition",
		longitude:"Contember_FloatCondition",
		address:"Contember_AddressWhere",
		and:"Contember_GpsWhere",
		or:"Contember_GpsWhere",
		not:"Contember_GpsWhere"
	},
	Contember_AddressOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		firstName:"Contember_OrderDirection",
		lastName:"Contember_OrderDirection",
		addressLine2:"Contember_OrderDirection",
		email:"Contember_OrderDirection",
		phone:"Contember_OrderDirection",
		companyName:"Contember_OrderDirection",
		companyIdentifier:"Contember_OrderDirection",
		vatIdentifier:"Contember_OrderDirection",
		externalIdentifier:"Contember_OrderDirection",
		hidden:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		internalNote:"Contember_OrderDirection",
		country:"Contember_CountryOrderBy",
		deletedAt:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		googlePlaceId:"Contember_OrderDirection",
		raw:"Contember_OrderDirection",
		formatted:"Contember_OrderDirection",
		geocodeGeneratedAt:"Contember_OrderDirection",
		geocodeResponse:"Contember_OrderDirection",
		deliveryZone:"Contember_DeliveryZoneOrderBy",
		meta:"Contember_AddressMetadataOrderBy",
		gps:"Contember_GpsOrderBy",
		geocodeValid:"Contember_OrderDirection",
		fullName:"Contember_OrderDirection",
		fakturoidSubjectId:"Contember_OrderDirection",
		addressLine1:"Contember_OrderDirection",
		city:"Contember_OrderDirection",
		postalCode:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		defaultDeliveryAddressOfCustomer:"Contember_CustomerOrderBy"
	},
	Contember_OrderDirection: true,
	Contember_CustomerOrderBy:{
		id:"Contember_OrderDirection",
		defaultBillingAddress:"Contember_AddressOrderBy",
		defaultPaymentMethod:"Contember_CustomerPaymentMethodOrderBy",
		permissions:"Contember_CustomerPermissionsOrderBy",
		group:"Contember_CustomerGroupOrderBy",
		photo:"Contember_ImageOrderBy",
		phone:"Contember_OrderDirection",
		name:"Contember_CustomerNameOrderBy",
		internalNote:"Contember_OrderDirection",
		welcomeMessage:"Contember_OrderDirection",
		familiarity:"Contember_OrderDirection",
		business:"Contember_BusinessCustomerOrderBy",
		shortcutList:"Contember_ApplicationShortcutListOrderBy",
		user:"Contember_UserOrderBy",
		disabledAt:"Contember_OrderDirection",
		invoicingPlan:"Contember_InvoicingPlanOrderBy",
		email:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		defaultDeliveryAddress:"Contember_AddressOrderBy",
		defaultBillingSubject:"Contember_BillingSubjectOrderBy",
		prefersPackingsWithoutCardboard:"Contember_OrderDirection",
		anonymousSession:"Contember_AnonymousSessionOrderBy",
		spending:"Contember_CustomerSpendingOrderBy",
		doubleshotLegacyId:"Contember_OrderDirection",
		meta:"Contember_CustomerMetadataOrderBy",
		doubleshotLastUpdatedAt:"Contember_OrderDirection"
	},
	Contember_CustomerPaymentMethodOrderBy:{
		id:"Contember_OrderDirection",
		caption:"Contember_OrderDirection",
		data:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		paymentMethod:"Contember_PaymentMethodOrderBy"
	},
	Contember_PaymentMethodOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		enabled:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		blockingDispatch:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		reduceGratuityByPercentage:"Contember_OrderDirection"
	},
	Contember_CustomerPermissionsOrderBy:{
		id:"Contember_OrderDirection",
		canPlaceOrder:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy"
	},
	Contember_CustomerGroupOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		discountPermille:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		doubleshotLegacyId:"Contember_OrderDirection"
	},
	Contember_ImageOrderBy:{
		id:"Contember_OrderDirection",
		url:"Contember_OrderDirection",
		width:"Contember_OrderDirection",
		height:"Contember_OrderDirection",
		size:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		alt:"Contember_OrderDirection"
	},
	Contember_CustomerNameOrderBy:{
		id:"Contember_OrderDirection",
		nominative:"Contember_OrderDirection",
		vocative:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy"
	},
	Contember_BusinessCustomerOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		category:"Contember_BusinessCategoryOrderBy",
		accountManager:"Contember_BusinessToBusinessManagerOrderBy",
		logo:"Contember_ImageOrderBy",
		note:"Contember_OrderDirection",
		doubleshotCoffeeEquipment:"Contember_DoubleshotCoffeeEquipmentOrderBy"
	},
	Contember_BusinessCategoryOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		visibleToNewCustomers:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		specificProducts:"Contember_ProductListOrderBy",
		seasonalProductCategories:"Contember_ProductCategoryListOrderBy",
		specificProductsProductCategories:"Contember_ProductCategoryListOrderBy",
		slug:"Contember_OrderDirection"
	},
	Contember_ProductListOrderBy:{
		id:"Contember_OrderDirection",
		businessCategory:"Contember_BusinessCategoryOrderBy"
	},
	Contember_ProductCategoryListOrderBy:{
		id:"Contember_OrderDirection",
		seasonalProductCategoriesOfBusinessCategory:"Contember_BusinessCategoryOrderBy",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_BusinessCategoryOrderBy"
	},
	Contember_BusinessToBusinessManagerOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection"
	},
	Contember_StaffOrderBy:{
		id:"Contember_OrderDirection",
		bartender:"Contember_BartenderOrderBy",
		courier:"Contember_CourierOrderBy",
		cleaner:"Contember_CleanerOrderBy",
		shiftsManager:"Contember_ShiftsManagerOrderBy",
		businessToBusinessManager:"Contember_BusinessToBusinessManagerOrderBy",
		user:"Contember_UserOrderBy",
		shiftsProfile:"Contember_ShiftsProfileOrderBy",
		stocksManager:"Contember_StocksManagerOrderBy",
		photo:"Contember_ImageOrderBy",
		courierManager:"Contember_CourierManagerOrderBy",
		picker:"Contember_PickerOrderBy",
		createdAt:"Contember_OrderDirection",
		remunerationProfile:"Contember_RemunerationProfileOrderBy",
		cashier:"Contember_CashierOrderBy",
		internalBuyer:"Contember_InternalBuyerOrderBy",
		salesManager:"Contember_SalesManagerOrderBy",
		cashierManager:"Contember_CashierManagerOrderBy"
	},
	Contember_BartenderOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection"
	},
	Contember_CourierOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection",
		showInPicker:"Contember_OrderDirection"
	},
	Contember_CleanerOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		canReachTopShelf:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection"
	},
	Contember_ShiftsManagerOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection"
	},
	Contember_UserOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		customer:"Contember_CustomerOrderBy",
		tenantPersonId:"Contember_OrderDirection",
		disabledAt:"Contember_OrderDirection",
		verifiedAt:"Contember_OrderDirection",
		email:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		deletionRequestedAt:"Contember_OrderDirection",
		deletionExpectedAt:"Contember_OrderDirection",
		deletedAt:"Contember_OrderDirection",
		meta:"Contember_UserMetadataOrderBy",
		roles:"Contember_UserRolesOrderBy"
	},
	Contember_UserMetadataOrderBy:{
		id:"Contember_OrderDirection",
		userProfileUrl:"Contember_OrderDirection",
		tenantEmail:"Contember_OrderDirection",
		tenantPersonId:"Contember_OrderDirection",
		tenantIdentityId:"Contember_OrderDirection",
		globalRoles:"Contember_OrderDirection",
		projectRoles:"Contember_OrderDirection",
		projectRolesString:"Contember_OrderDirection",
		externalIdentifiers:"Contember_OrderDirection",
		externalIdentifiersString:"Contember_OrderDirection",
		idpSlugs:"Contember_OrderDirection",
		idpSlugsString:"Contember_OrderDirection",
		user:"Contember_UserOrderBy"
	},
	Contember_UserRolesOrderBy:{
		id:"Contember_OrderDirection",
		isCustomer:"Contember_OrderDirection",
		isStaff:"Contember_OrderDirection",
		isBartender:"Contember_OrderDirection",
		isCashier:"Contember_OrderDirection",
		isCourier:"Contember_OrderDirection",
		isCleaner:"Contember_OrderDirection",
		isShiftsManager:"Contember_OrderDirection",
		isInternalBuyer:"Contember_OrderDirection",
		isStocksManager:"Contember_OrderDirection",
		isCourierManager:"Contember_OrderDirection",
		isCashierManager:"Contember_OrderDirection",
		isBusinessToBusinessManager:"Contember_OrderDirection",
		isPicker:"Contember_OrderDirection",
		user:"Contember_UserOrderBy"
	},
	Contember_ShiftsProfileOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		allowSelfUnassigning:"Contember_OrderDirection"
	},
	Contember_StocksManagerOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection"
	},
	Contember_CourierManagerOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection"
	},
	Contember_PickerOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection"
	},
	Contember_RemunerationProfileOrderBy:{
		id:"Contember_OrderDirection",
		workMonths:"Contember_WorkMonthOrderBy",
		staff:"Contember_StaffOrderBy"
	},
	Contember_WorkMonthOrderBy:{
		id:"Contember_OrderDirection",
		year:"Contember_OrderDirection",
		month:"Contember_OrderDirection",
		hoursWorked:"Contember_OrderDirection",
		billableHours:"Contember_OrderDirection"
	},
	Contember_CashierOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy"
	},
	Contember_InternalBuyerOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy"
	},
	Contember_SalesManagerOrderBy:{
		id:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy",
		createdAt:"Contember_OrderDirection"
	},
	Contember_CashierManagerOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		staff:"Contember_StaffOrderBy"
	},
	Contember_DoubleshotCoffeeEquipmentOrderBy:{
		id:"Contember_OrderDirection",
		espressoMachine:"Contember_OrderDirection",
		espressoGrinder:"Contember_OrderDirection",
		batchBrew:"Contember_OrderDirection",
		batchGrinder:"Contember_OrderDirection",
		waterFilter:"Contember_OrderDirection",
		mechanicalFilter:"Contember_OrderDirection"
	},
	Contember_ApplicationShortcutListOrderBy:{
		id:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy"
	},
	Contember_InvoicingPlanOrderBy:{
		id:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		preset:"Contember_InvoicingPresetOrderBy"
	},
	Contember_InvoicingPresetOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		startsAt:"Contember_OrderDirection",
		endsAt:"Contember_OrderDirection",
		intervalOfDays:"Contember_OrderDirection",
		invoicingPlan:"Contember_InvoicingPlanOrderBy"
	},
	Contember_BillingSubjectOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		fullName:"Contember_OrderDirection",
		addressLine1:"Contember_OrderDirection",
		addressLine2:"Contember_OrderDirection",
		city:"Contember_OrderDirection",
		postalCode:"Contember_OrderDirection",
		email:"Contember_OrderDirection",
		phone:"Contember_OrderDirection",
		companyName:"Contember_OrderDirection",
		companyIdentifier:"Contember_OrderDirection",
		vatIdentifier:"Contember_OrderDirection",
		externalIdentifier:"Contember_OrderDirection",
		hidden:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		deletedAt:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		fakturoidSubjectId:"Contember_OrderDirection",
		country:"Contember_CountryOrderBy",
		customer:"Contember_CustomerOrderBy",
		defaultBillingSubjectOfCustomer:"Contember_CustomerOrderBy"
	},
	Contember_CountryOrderBy:{
		id:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		order:"Contember_OrderDirection"
	},
	Contember_AnonymousSessionOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		lastActiveAt:"Contember_OrderDirection",
		anonymousSessionKey:"Contember_OrderDirection",
		email:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy"
	},
	Contember_CustomerSpendingOrderBy:{
		id:"Contember_OrderDirection",
		totalCents:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy"
	},
	Contember_CustomerMetadataOrderBy:{
		id:"Contember_OrderDirection",
		isBusiness:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		fallbackName:"Contember_OrderDirection",
		remainingCreditsCents:"Contember_OrderDirection",
		ordersTotalCents:"Contember_OrderDirection",
		customerProfileUrl:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy"
	},
	Contember_DeliveryZoneOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		type:"Contember_OrderDirection"
	},
	Contember_AddressMetadataOrderBy:{
		id:"Contember_OrderDirection",
		deliveryZoneType:"Contember_OrderDirection",
		address:"Contember_AddressOrderBy",
		deliveryZone:"Contember_DeliveryZoneOrderBy"
	},
	Contember_GpsOrderBy:{
		id:"Contember_OrderDirection",
		latitude:"Contember_OrderDirection",
		longitude:"Contember_OrderDirection",
		address:"Contember_AddressOrderBy"
	},
	Contember_CustomerPaymentMethod:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		paymentMethod:{
			filter:"Contember_PaymentMethodWhere"
		}
	},
	Json: "String",
	PaymentMethod:{
		deliveryMethods:{
			filter:"Contember_DeliveryMethodWhere",
			orderBy:"Contember_DeliveryMethodOrderBy"
		},
		channels:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"Contember_DeliveryMethodWhere",
			orderBy:"Contember_DeliveryMethodOrderBy"
		},
		paginateChannels:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		}
	},
	DeliveryMethod:{
		paymentMethods:{
			filter:"Contember_PaymentMethodWhere",
			orderBy:"Contember_PaymentMethodOrderBy"
		},
		suitableForDeliveryZones:{
			filter:"Contember_DeliveryZoneWhere",
			orderBy:"Contember_DeliveryZoneOrderBy"
		},
		channels:{
			filter:"Contember_ChannelDeliveryWhere",
			orderBy:"Contember_ChannelDeliveryOrderBy"
		},
		courierHandlers:{
			filter:"Contember_CourierWhere",
			orderBy:"Contember_CourierOrderBy"
		},
		locales:{
			filter:"Contember_DeliveryMethodLocaleWhere",
			orderBy:"Contember_DeliveryMethodLocaleOrderBy"
		},
		timelinePreset:{
			filter:"Contember_DeliveryTimelinePresetWhere"
		},
		localesByLocale:{
			by:"Contember_DeliveryMethodLocalesByLocaleUniqueWhere",
			filter:"Contember_DeliveryMethodLocaleWhere"
		},
		paginatePaymentMethods:{
			filter:"Contember_PaymentMethodWhere",
			orderBy:"Contember_PaymentMethodOrderBy"
		},
		paginateSuitableForDeliveryZones:{
			filter:"Contember_DeliveryZoneWhere",
			orderBy:"Contember_DeliveryZoneOrderBy"
		},
		paginateChannels:{
			filter:"Contember_ChannelDeliveryWhere",
			orderBy:"Contember_ChannelDeliveryOrderBy"
		},
		paginateCourierHandlers:{
			filter:"Contember_CourierWhere",
			orderBy:"Contember_CourierOrderBy"
		},
		paginateLocales:{
			filter:"Contember_DeliveryMethodLocaleWhere",
			orderBy:"Contember_DeliveryMethodLocaleOrderBy"
		}
	},
	Contember_DeliveryZone:{
		addresses:{
			filter:"Contember_AddressWhere",
			orderBy:"Contember_AddressOrderBy"
		},
		suitableDeliveryMethods:{
			filter:"Contember_DeliveryMethodWhere",
			orderBy:"Contember_DeliveryMethodOrderBy"
		},
		deliveryZonesOfTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		deliveryZonesOfProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		addressesMetadata:{
			filter:"Contember_AddressMetadataWhere",
			orderBy:"Contember_AddressMetadataOrderBy"
		},
		addressesByMeta:{
			by:"Contember_DeliveryZoneAddressesByMetaUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByGps:{
			by:"Contember_DeliveryZoneAddressesByGpsUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByInvoices:{
			by:"Contember_DeliveryZoneAddressesByInvoicesUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByBillingAddressOfOrders:{
			by:"Contember_DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByDeliveryAddressOfOrderDeliveries:{
			by:"Contember_DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesByDefaultDeliveryAddressOfCustomer:{
			by:"Contember_DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere",
			filter:"Contember_AddressWhere"
		},
		addressesMetadataByAddress:{
			by:"Contember_DeliveryZoneAddressesMetadataByAddressUniqueWhere",
			filter:"Contember_AddressMetadataWhere"
		},
		paginateAddresses:{
			filter:"Contember_AddressWhere",
			orderBy:"Contember_AddressOrderBy"
		},
		paginateSuitableDeliveryMethods:{
			filter:"Contember_DeliveryMethodWhere",
			orderBy:"Contember_DeliveryMethodOrderBy"
		},
		paginateDeliveryZonesOfTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		paginateDeliveryZonesOfProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		paginateAddressesMetadata:{
			filter:"Contember_AddressMetadataWhere",
			orderBy:"Contember_AddressMetadataOrderBy"
		}
	},
	Contember_DeliveryMethodOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		enabled:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		requireDeliveryAddress:"Contember_OrderDirection",
		enableDeliveryTracking:"Contember_OrderDirection",
		timelinePreset:"Contember_DeliveryTimelinePresetOrderBy"
	},
	Contember_DeliveryTimelinePresetOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		duration:"Contember_OrderDirection",
		window:"Contember_OrderDirection",
		deliveryMethod:"Contember_DeliveryMethodOrderBy"
	},
	Contember_Tag:{
		customers:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		products:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		categories:{
			filter:"Contember_ProductCategoryWhere",
			orderBy:"Contember_ProductCategoryOrderBy"
		},
		addTagsOnRegistration:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		nestedCategories:{
			filter:"Contember_InheritedProductCategoryTagWhere",
			orderBy:"Contember_InheritedProductCategoryTagOrderBy"
		},
		deliveryZones:{
			filter:"Contember_DeliveryZoneWhere",
			orderBy:"Contember_DeliveryZoneOrderBy"
		},
		publicInChannels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		allowChannelPayments:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		},
		fulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		},
		paginateCustomers:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		paginateProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		paginateCategories:{
			filter:"Contember_ProductCategoryWhere",
			orderBy:"Contember_ProductCategoryOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		paginateNestedCategories:{
			filter:"Contember_InheritedProductCategoryTagWhere",
			orderBy:"Contember_InheritedProductCategoryTagOrderBy"
		},
		paginateDeliveryZones:{
			filter:"Contember_DeliveryZoneWhere",
			orderBy:"Contember_DeliveryZoneOrderBy"
		},
		paginatePublicInChannels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		paginateAllowChannelPayments:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		}
	},
	Contember_ProductPacking:{
		image:{
			filter:"Contember_ImageWhere"
		},
		icon:{
			filter:"Contember_ImageWhere"
		},
		locales:{
			filter:"Contember_ProductPackingLocaleWhere",
			orderBy:"Contember_ProductPackingLocaleOrderBy"
		},
		preparedPackingQuantities:{
			filter:"Contember_PreparedPackingQuantityWhere",
			orderBy:"Contember_PreparedPackingQuantityOrderBy"
		},
		localesByLocale:{
			by:"Contember_ProductPackingLocalesByLocaleUniqueWhere",
			filter:"Contember_ProductPackingLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_ProductPackingLocaleWhere",
			orderBy:"Contember_ProductPackingLocaleOrderBy"
		},
		paginatePreparedPackingQuantities:{
			filter:"Contember_PreparedPackingQuantityWhere",
			orderBy:"Contember_PreparedPackingQuantityOrderBy"
		}
	},
	Contember_ProductPackingLocale:{
		root:{
			filter:"Contember_ProductPackingWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_Locale:{
		businessCategories:{
			filter:"Contember_BusinessCategoryLocaleWhere",
			orderBy:"Contember_BusinessCategoryLocaleOrderBy"
		},
		productCategoryLists:{
			filter:"Contember_ProductCategoryListLocaleWhere",
			orderBy:"Contember_ProductCategoryListLocaleOrderBy"
		},
		productIngredients:{
			filter:"Contember_ProductIngredientLocaleWhere",
			orderBy:"Contember_ProductIngredientLocaleOrderBy"
		},
		orderRecurrences:{
			filter:"Contember_OrderRecurrenceLocaleWhere",
			orderBy:"Contember_OrderRecurrenceLocaleOrderBy"
		},
		blogs:{
			filter:"Contember_BlogLocaleWhere",
			orderBy:"Contember_BlogLocaleOrderBy"
		},
		blogPosts:{
			filter:"Contember_BlogPostLocaleWhere",
			orderBy:"Contember_BlogPostLocaleOrderBy"
		},
		identifier:{
			filter:"Contember_TranslationCataloguesIdentifierWhere"
		},
		menus:{
			filter:"Contember_MenuLocaleWhere",
			orderBy:"Contember_MenuLocaleOrderBy"
		},
		pages:{
			filter:"Contember_PageLocaleWhere",
			orderBy:"Contember_PageLocaleOrderBy"
		},
		productIngredientCategories:{
			filter:"Contember_ProductIngredientCategoryLocaleWhere",
			orderBy:"Contember_ProductIngredientCategoryLocaleOrderBy"
		},
		allergens:{
			filter:"Contember_AllergenLocaleWhere",
			orderBy:"Contember_AllergenLocaleOrderBy"
		},
		productPackings:{
			filter:"Contember_ProductPackingLocaleWhere",
			orderBy:"Contember_ProductPackingLocaleOrderBy"
		},
		products:{
			filter:"Contember_ProductLocaleWhere",
			orderBy:"Contember_ProductLocaleOrderBy"
		},
		productRecipes:{
			filter:"Contember_ProductRecipeLocaleWhere",
			orderBy:"Contember_ProductRecipeLocaleOrderBy"
		},
		pointOfSaleCategories:{
			filter:"Contember_PointOfSaleCategoryLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryLocaleOrderBy"
		},
		pointOfSaleCategoryProducts:{
			filter:"Contember_PointOfSaleCategoryProductLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryProductLocaleOrderBy"
		},
		deliveryMethods:{
			filter:"Contember_DeliveryMethodLocaleWhere",
			orderBy:"Contember_DeliveryMethodLocaleOrderBy"
		},
		ingredientSuppliers:{
			filter:"Contember_IngredientSupplierLocaleWhere",
			orderBy:"Contember_IngredientSupplierLocaleOrderBy"
		},
		identificationCodes:{
			filter:"Contember_IdentificationCodeLocaleWhere",
			orderBy:"Contember_IdentificationCodeLocaleOrderBy"
		},
		pipelineStages:{
			filter:"Contember_PipelineStageLocaleWhere",
			orderBy:"Contember_PipelineStageLocaleOrderBy"
		},
		subscriptionBoxes:{
			filter:"Contember_SubscriptionBoxLocaleWhere",
			orderBy:"Contember_SubscriptionBoxLocaleOrderBy"
		},
		businessServices:{
			filter:"Contember_BusinessServiceLocaleWhere",
			orderBy:"Contember_BusinessServiceLocaleOrderBy"
		},
		businessCategoriesByRoot:{
			by:"Contember_LocaleBusinessCategoriesByRootUniqueWhere",
			filter:"Contember_BusinessCategoryLocaleWhere"
		},
		productCategoryListsByRoot:{
			by:"Contember_LocaleProductCategoryListsByRootUniqueWhere",
			filter:"Contember_ProductCategoryListLocaleWhere"
		},
		productIngredientsByRoot:{
			by:"Contember_LocaleProductIngredientsByRootUniqueWhere",
			filter:"Contember_ProductIngredientLocaleWhere"
		},
		orderRecurrencesByRoot:{
			by:"Contember_LocaleOrderRecurrencesByRootUniqueWhere",
			filter:"Contember_OrderRecurrenceLocaleWhere"
		},
		blogsByRoot:{
			by:"Contember_LocaleBlogsByRootUniqueWhere",
			filter:"Contember_BlogLocaleWhere"
		},
		blogPostsByRoot:{
			by:"Contember_LocaleBlogPostsByRootUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		blogPostsByCover:{
			by:"Contember_LocaleBlogPostsByCoverUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		blogPostsByContent:{
			by:"Contember_LocaleBlogPostsByContentUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		blogPostsByLink:{
			by:"Contember_LocaleBlogPostsByLinkUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		blogPostsBySeo:{
			by:"Contember_LocaleBlogPostsBySeoUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		menusByRoot:{
			by:"Contember_LocaleMenusByRootUniqueWhere",
			filter:"Contember_MenuLocaleWhere"
		},
		menusByItems:{
			by:"Contember_LocaleMenusByItemsUniqueWhere",
			filter:"Contember_MenuLocaleWhere"
		},
		menusBySecondaryItems:{
			by:"Contember_LocaleMenusBySecondaryItemsUniqueWhere",
			filter:"Contember_MenuLocaleWhere"
		},
		pagesByRoot:{
			by:"Contember_LocalePagesByRootUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		pagesByLink:{
			by:"Contember_LocalePagesByLinkUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		pagesBySeo:{
			by:"Contember_LocalePagesBySeoUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		pagesByContent:{
			by:"Contember_LocalePagesByContentUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		pagesByCover:{
			by:"Contember_LocalePagesByCoverUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		productIngredientCategoriesByRoot:{
			by:"Contember_LocaleProductIngredientCategoriesByRootUniqueWhere",
			filter:"Contember_ProductIngredientCategoryLocaleWhere"
		},
		allergensByRoot:{
			by:"Contember_LocaleAllergensByRootUniqueWhere",
			filter:"Contember_AllergenLocaleWhere"
		},
		productPackingsByRoot:{
			by:"Contember_LocaleProductPackingsByRootUniqueWhere",
			filter:"Contember_ProductPackingLocaleWhere"
		},
		productsByRoot:{
			by:"Contember_LocaleProductsByRootUniqueWhere",
			filter:"Contember_ProductLocaleWhere"
		},
		productsByFeatureList:{
			by:"Contember_LocaleProductsByFeatureListUniqueWhere",
			filter:"Contember_ProductLocaleWhere"
		},
		productRecipesByRoot:{
			by:"Contember_LocaleProductRecipesByRootUniqueWhere",
			filter:"Contember_ProductRecipeLocaleWhere"
		},
		pointOfSaleCategoriesByRoot:{
			by:"Contember_LocalePointOfSaleCategoriesByRootUniqueWhere",
			filter:"Contember_PointOfSaleCategoryLocaleWhere"
		},
		pointOfSaleCategoryProductsByRoot:{
			by:"Contember_LocalePointOfSaleCategoryProductsByRootUniqueWhere",
			filter:"Contember_PointOfSaleCategoryProductLocaleWhere"
		},
		deliveryMethodsByRoot:{
			by:"Contember_LocaleDeliveryMethodsByRootUniqueWhere",
			filter:"Contember_DeliveryMethodLocaleWhere"
		},
		ingredientSuppliersByRoot:{
			by:"Contember_LocaleIngredientSuppliersByRootUniqueWhere",
			filter:"Contember_IngredientSupplierLocaleWhere"
		},
		identificationCodesByRoot:{
			by:"Contember_LocaleIdentificationCodesByRootUniqueWhere",
			filter:"Contember_IdentificationCodeLocaleWhere"
		},
		pipelineStagesByRoot:{
			by:"Contember_LocalePipelineStagesByRootUniqueWhere",
			filter:"Contember_PipelineStageLocaleWhere"
		},
		subscriptionBoxesByRoot:{
			by:"Contember_LocaleSubscriptionBoxesByRootUniqueWhere",
			filter:"Contember_SubscriptionBoxLocaleWhere"
		},
		subscriptionBoxesByTexts:{
			by:"Contember_LocaleSubscriptionBoxesByTextsUniqueWhere",
			filter:"Contember_SubscriptionBoxLocaleWhere"
		},
		subscriptionBoxesBySecondaryTexts:{
			by:"Contember_LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere",
			filter:"Contember_SubscriptionBoxLocaleWhere"
		},
		businessServicesByRoot:{
			by:"Contember_LocaleBusinessServicesByRootUniqueWhere",
			filter:"Contember_BusinessServiceLocaleWhere"
		},
		paginateBusinessCategories:{
			filter:"Contember_BusinessCategoryLocaleWhere",
			orderBy:"Contember_BusinessCategoryLocaleOrderBy"
		},
		paginateProductCategoryLists:{
			filter:"Contember_ProductCategoryListLocaleWhere",
			orderBy:"Contember_ProductCategoryListLocaleOrderBy"
		},
		paginateProductIngredients:{
			filter:"Contember_ProductIngredientLocaleWhere",
			orderBy:"Contember_ProductIngredientLocaleOrderBy"
		},
		paginateOrderRecurrences:{
			filter:"Contember_OrderRecurrenceLocaleWhere",
			orderBy:"Contember_OrderRecurrenceLocaleOrderBy"
		},
		paginateBlogs:{
			filter:"Contember_BlogLocaleWhere",
			orderBy:"Contember_BlogLocaleOrderBy"
		},
		paginateBlogPosts:{
			filter:"Contember_BlogPostLocaleWhere",
			orderBy:"Contember_BlogPostLocaleOrderBy"
		},
		paginateMenus:{
			filter:"Contember_MenuLocaleWhere",
			orderBy:"Contember_MenuLocaleOrderBy"
		},
		paginatePages:{
			filter:"Contember_PageLocaleWhere",
			orderBy:"Contember_PageLocaleOrderBy"
		},
		paginateProductIngredientCategories:{
			filter:"Contember_ProductIngredientCategoryLocaleWhere",
			orderBy:"Contember_ProductIngredientCategoryLocaleOrderBy"
		},
		paginateAllergens:{
			filter:"Contember_AllergenLocaleWhere",
			orderBy:"Contember_AllergenLocaleOrderBy"
		},
		paginateProductPackings:{
			filter:"Contember_ProductPackingLocaleWhere",
			orderBy:"Contember_ProductPackingLocaleOrderBy"
		},
		paginateProducts:{
			filter:"Contember_ProductLocaleWhere",
			orderBy:"Contember_ProductLocaleOrderBy"
		},
		paginateProductRecipes:{
			filter:"Contember_ProductRecipeLocaleWhere",
			orderBy:"Contember_ProductRecipeLocaleOrderBy"
		},
		paginatePointOfSaleCategories:{
			filter:"Contember_PointOfSaleCategoryLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryLocaleOrderBy"
		},
		paginatePointOfSaleCategoryProducts:{
			filter:"Contember_PointOfSaleCategoryProductLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryProductLocaleOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"Contember_DeliveryMethodLocaleWhere",
			orderBy:"Contember_DeliveryMethodLocaleOrderBy"
		},
		paginateIngredientSuppliers:{
			filter:"Contember_IngredientSupplierLocaleWhere",
			orderBy:"Contember_IngredientSupplierLocaleOrderBy"
		},
		paginateIdentificationCodes:{
			filter:"Contember_IdentificationCodeLocaleWhere",
			orderBy:"Contember_IdentificationCodeLocaleOrderBy"
		},
		paginatePipelineStages:{
			filter:"Contember_PipelineStageLocaleWhere",
			orderBy:"Contember_PipelineStageLocaleOrderBy"
		},
		paginateSubscriptionBoxes:{
			filter:"Contember_SubscriptionBoxLocaleWhere",
			orderBy:"Contember_SubscriptionBoxLocaleOrderBy"
		},
		paginateBusinessServices:{
			filter:"Contember_BusinessServiceLocaleWhere",
			orderBy:"Contember_BusinessServiceLocaleOrderBy"
		}
	},
	Contember_BusinessCategoryLocale:{
		root:{
			filter:"Contember_BusinessCategoryWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_BusinessCategory:{
		locales:{
			filter:"Contember_BusinessCategoryLocaleWhere",
			orderBy:"Contember_BusinessCategoryLocaleOrderBy"
		},
		businessCustomers:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		},
		recurrences:{
			filter:"Contember_OrderRecurrenceWhere",
			orderBy:"Contember_OrderRecurrenceOrderBy"
		},
		specificProducts:{
			filter:"Contember_ProductListWhere"
		},
		seasonalProductCategories:{
			filter:"Contember_ProductCategoryListWhere"
		},
		specificProductsProductCategories:{
			filter:"Contember_ProductCategoryListWhere"
		},
		localesByLocale:{
			by:"Contember_BusinessCategoryLocalesByLocaleUniqueWhere",
			filter:"Contember_BusinessCategoryLocaleWhere"
		},
		businessCustomersByCustomer:{
			by:"Contember_BusinessCategoryBusinessCustomersByCustomerUniqueWhere",
			filter:"Contember_BusinessCustomerWhere"
		},
		businessCustomersByBusinessServiceEvents:{
			by:"Contember_BusinessCategoryBusinessCustomersByBusinessServiceEventsUniqueWhere",
			filter:"Contember_BusinessCustomerWhere"
		},
		businessCustomersByDoubleshotCoffeeEquipment:{
			by:"Contember_BusinessCategoryBusinessCustomersByDoubleshotCoffeeEquipmentUniqueWhere",
			filter:"Contember_BusinessCustomerWhere"
		},
		paginateLocales:{
			filter:"Contember_BusinessCategoryLocaleWhere",
			orderBy:"Contember_BusinessCategoryLocaleOrderBy"
		},
		paginateBusinessCustomers:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		},
		paginateRecurrences:{
			filter:"Contember_OrderRecurrenceWhere",
			orderBy:"Contember_OrderRecurrenceOrderBy"
		}
	},
	Contember_BusinessCategoryLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_BusinessCategoryOrderBy",
		locale:"Contember_LocaleOrderBy",
		title:"Contember_OrderDirection"
	},
	Contember_LocaleOrderBy:{
		id:"Contember_OrderDirection",
		identifier:"Contember_TranslationCataloguesIdentifierOrderBy"
	},
	Contember_TranslationCataloguesIdentifierOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection"
	},
	Contember_BusinessCustomer:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		owners:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		members:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		category:{
			filter:"Contember_BusinessCategoryWhere"
		},
		accountManager:{
			filter:"Contember_BusinessToBusinessManagerWhere"
		},
		logo:{
			filter:"Contember_ImageWhere"
		},
		businessServiceEvents:{
			filter:"Contember_BusinessServiceEventWhere",
			orderBy:"Contember_BusinessServiceEventOrderBy"
		},
		doubleshotCoffeeEquipment:{
			filter:"Contember_DoubleshotCoffeeEquipmentWhere"
		},
		paginateOwners:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		paginateMembers:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		paginateBusinessServiceEvents:{
			filter:"Contember_BusinessServiceEventWhere",
			orderBy:"Contember_BusinessServiceEventOrderBy"
		}
	},
	Contember_BusinessToBusinessManager:{
		staff:{
			filter:"Contember_StaffWhere"
		},
		managedBusinesses:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		},
		managedBusinessesByCustomer:{
			by:"Contember_BusinessToBusinessManagerManagedBusinessesByCustomerUniqueWhere",
			filter:"Contember_BusinessCustomerWhere"
		},
		managedBusinessesByBusinessServiceEvents:{
			by:"Contember_BusinessToBusinessManagerManagedBusinessesByBusinessServiceEventsUniqueWhere",
			filter:"Contember_BusinessCustomerWhere"
		},
		managedBusinessesByDoubleshotCoffeeEquipment:{
			by:"Contember_BusinessToBusinessManagerManagedBusinessesByDoubleshotCoffeeEquipmentUniqueWhere",
			filter:"Contember_BusinessCustomerWhere"
		},
		paginateManagedBusinesses:{
			filter:"Contember_BusinessCustomerWhere",
			orderBy:"Contember_BusinessCustomerOrderBy"
		}
	},
	Contember_Staff:{
		bartender:{
			filter:"Contember_BartenderWhere"
		},
		courier:{
			filter:"Contember_CourierWhere"
		},
		cleaner:{
			filter:"Contember_CleanerWhere"
		},
		shiftsManager:{
			filter:"Contember_ShiftsManagerWhere"
		},
		businessToBusinessManager:{
			filter:"Contember_BusinessToBusinessManagerWhere"
		},
		user:{
			filter:"Contember_UserWhere"
		},
		shiftsProfile:{
			filter:"Contember_ShiftsProfileWhere"
		},
		stocksManager:{
			filter:"Contember_StocksManagerWhere"
		},
		photo:{
			filter:"Contember_ImageWhere"
		},
		courierManager:{
			filter:"Contember_CourierManagerWhere"
		},
		picker:{
			filter:"Contember_PickerWhere"
		},
		remunerationProfile:{
			filter:"Contember_RemunerationProfileWhere"
		},
		cashier:{
			filter:"Contember_CashierWhere"
		},
		venues:{
			filter:"Contember_VenueWhere",
			orderBy:"Contember_VenueOrderBy"
		},
		internalBuyer:{
			filter:"Contember_InternalBuyerWhere"
		},
		salesManager:{
			filter:"Contember_SalesManagerWhere"
		},
		cashierManager:{
			filter:"Contember_CashierManagerWhere"
		},
		paginateVenues:{
			filter:"Contember_VenueWhere",
			orderBy:"Contember_VenueOrderBy"
		}
	},
	Contember_Bartender:{
		staff:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_Courier:{
		staff:{
			filter:"Contember_StaffWhere"
		},
		routes:{
			filter:"Contember_DeliveryRouteWhere",
			orderBy:"Contember_DeliveryRouteOrderBy"
		},
		deliveryMethods:{
			filter:"Contember_DeliveryMethodWhere",
			orderBy:"Contember_DeliveryMethodOrderBy"
		},
		routesByWaypoints:{
			by:"Contember_CourierRoutesByWaypointsUniqueWhere",
			filter:"Contember_DeliveryRouteWhere"
		},
		paginateRoutes:{
			filter:"Contember_DeliveryRouteWhere",
			orderBy:"Contember_DeliveryRouteOrderBy"
		},
		paginateDeliveryMethods:{
			filter:"Contember_DeliveryMethodWhere",
			orderBy:"Contember_DeliveryMethodOrderBy"
		}
	},
	Contember_DeliveryRoute:{
		courier:{
			filter:"Contember_CourierWhere"
		},
		waypoints:{
			filter:"Contember_DeliveryWaypointWhere",
			orderBy:"Contember_DeliveryWaypointOrderBy"
		},
		waypointsByDelivery:{
			by:"Contember_DeliveryRouteWaypointsByDeliveryUniqueWhere",
			filter:"Contember_DeliveryWaypointWhere"
		},
		paginateWaypoints:{
			filter:"Contember_DeliveryWaypointWhere",
			orderBy:"Contember_DeliveryWaypointOrderBy"
		}
	},
	Contember_DeliveryWaypoint:{
		route:{
			filter:"Contember_DeliveryRouteWhere"
		},
		delivery:{
			filter:"Contember_OrderDeliveryWhere"
		}
	},
	OrderDelivery:{
		method:{
			filter:"Contember_DeliveryMethodWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		order:{
			filter:"Contember_OrderWhere"
		},
		deliveryWaypoint:{
			filter:"Contember_DeliveryWaypointWhere"
		},
		events:{
			filter:"Contember_OrderEventWhere",
			orderBy:"Contember_OrderEventOrderBy"
		},
		liftagoRide:{
			filter:"Contember_LiftagoRideWhere"
		},
		address:{
			filter:"Contember_AddressWhere"
		},
		paginateEvents:{
			filter:"Contember_OrderEventWhere",
			orderBy:"Contember_OrderEventOrderBy"
		}
	},
	Contember_Channel:{
		payments:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		},
		deliveries:{
			filter:"Contember_ChannelDeliveryWhere",
			orderBy:"Contember_ChannelDeliveryOrderBy"
		},
		pointOfSales:{
			filter:"Contember_PointOfSaleWhere",
			orderBy:"Contember_PointOfSaleOrderBy"
		},
		carts:{
			filter:"Contember_CartWhere",
			orderBy:"Contember_CartOrderBy"
		},
		customers:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		addTagsOnRegistration:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		publicTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		venue:{
			filter:"Contember_VenueWhere"
		},
		cartsByItems:{
			by:"Contember_ChannelCartsByItemsUniqueWhere",
			filter:"Contember_CartWhere"
		},
		cartsByOrder:{
			by:"Contember_ChannelCartsByOrderUniqueWhere",
			filter:"Contember_CartWhere"
		},
		cartsByPriceLines:{
			by:"Contember_ChannelCartsByPriceLinesUniqueWhere",
			filter:"Contember_CartWhere"
		},
		cartsBySummary:{
			by:"Contember_ChannelCartsBySummaryUniqueWhere",
			filter:"Contember_CartWhere"
		},
		paginatePayments:{
			filter:"Contember_ChannelPaymentWhere",
			orderBy:"Contember_ChannelPaymentOrderBy"
		},
		paginateDeliveries:{
			filter:"Contember_ChannelDeliveryWhere",
			orderBy:"Contember_ChannelDeliveryOrderBy"
		},
		paginatePointOfSales:{
			filter:"Contember_PointOfSaleWhere",
			orderBy:"Contember_PointOfSaleOrderBy"
		},
		paginateCarts:{
			filter:"Contember_CartWhere",
			orderBy:"Contember_CartOrderBy"
		},
		paginateCustomers:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		paginateAddTagsOnRegistration:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		paginatePublicTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		}
	},
	Contember_TagOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection"
	},
	Contember_ChannelPaymentOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		channel:"Contember_ChannelOrderBy",
		method:"Contember_PaymentMethodOrderBy",
		vatRate:"Contember_VatRateOrderBy",
		requireBillingAddress:"Contember_OrderDirection",
		provideReceipt:"Contember_OrderDirection",
		requireBillingSubject:"Contember_OrderDirection"
	},
	Contember_ChannelOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		disabled:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy"
	},
	Contember_VenueOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		deadlineTemplate:"Contember_DeadlineTemplateOrderBy",
		address:"Contember_OrderDirection",
		pointOfSalePngBase64ReceiptLogo:"Contember_OrderDirection",
		pointOfSaleInitialView:"Contember_OrderDirection",
		pointOfSalePreferPrintReceipt:"Contember_OrderDirection"
	},
	Contember_DeadlineTemplateOrderBy:{
		id:"Contember_OrderDirection",
		startDate:"Contember_OrderDirection",
		period:"Contember_OrderDirection",
		cutoff:"Contember_OrderDirection",
		closed:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy"
	},
	Contember_VatRateOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		ratePermille:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		preset:"Contember_OrderDirection"
	},
	Contember_ChannelDeliveryOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		priceCents:"Contember_OrderDirection",
		channel:"Contember_ChannelOrderBy",
		method:"Contember_DeliveryMethodOrderBy",
		vatRate:"Contember_VatRateOrderBy",
		defaultVisible:"Contember_OrderDirection"
	},
	Contember_PointOfSale:{
		channels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		permissions:{
			filter:"Contember_PointOfSalePermissionsWhere"
		},
		paginateChannels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		}
	},
	Contember_PointOfSalePermissions:{
		pointOfSale:{
			filter:"Contember_PointOfSaleWhere"
		}
	},
	Contember_PointOfSaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		permissions:"Contember_PointOfSalePermissionsOrderBy",
		tenantPersonId:"Contember_OrderDirection"
	},
	Contember_PointOfSalePermissionsOrderBy:{
		id:"Contember_OrderDirection",
		canPlaceOrder:"Contember_OrderDirection",
		pointOfSale:"Contember_PointOfSaleOrderBy"
	},
	Contember_Cart:{
		items:{
			filter:"Contember_CartItemWhere",
			orderBy:"Contember_CartItemOrderBy"
		},
		channel:{
			filter:"Contember_ChannelWhere"
		},
		order:{
			filter:"Contember_OrderWhere"
		},
		customer:{
			filter:"Contember_CustomerWhere"
		},
		deliveryAddress:{
			filter:"Contember_AddressWhere"
		},
		billingAddress:{
			filter:"Contember_AddressWhere"
		},
		delivery:{
			filter:"Contember_ChannelDeliveryWhere"
		},
		payment:{
			filter:"Contember_ChannelPaymentWhere"
		},
		billingSubject:{
			filter:"Contember_BillingSubjectWhere"
		},
		priceLines:{
			filter:"Contember_CartPriceLineWhere",
			orderBy:"Contember_CartPriceLineOrderBy"
		},
		summary:{
			filter:"Contember_CartSummaryWhere"
		},
		itemsByProduct:{
			by:"Contember_CartItemsByProductUniqueWhere",
			filter:"Contember_CartItemWhere"
		},
		paginateItems:{
			filter:"Contember_CartItemWhere",
			orderBy:"Contember_CartItemOrderBy"
		},
		paginatePriceLines:{
			filter:"Contember_CartPriceLineWhere",
			orderBy:"Contember_CartPriceLineOrderBy"
		}
	},
	Contember_CartItem:{
		cart:{
			filter:"Contember_CartWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		},
		fulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		}
	},
	Contember_FulfillmentNote:{
		products:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		tags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		venues:{
			filter:"Contember_VenueWhere",
			orderBy:"Contember_VenueOrderBy"
		},
		cartItems:{
			filter:"Contember_CartItemWhere",
			orderBy:"Contember_CartItemOrderBy"
		},
		orderItems:{
			filter:"Contember_OrderItemWhere",
			orderBy:"Contember_OrderItemOrderBy"
		},
		orders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		paginateProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		paginateTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		paginateVenues:{
			filter:"Contember_VenueWhere",
			orderBy:"Contember_VenueOrderBy"
		},
		paginateCartItems:{
			filter:"Contember_CartItemWhere",
			orderBy:"Contember_CartItemOrderBy"
		},
		paginateOrderItems:{
			filter:"Contember_OrderItemWhere",
			orderBy:"Contember_OrderItemOrderBy"
		},
		paginateOrders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		}
	},
	Contember_ProductOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		priceCents:"Contember_OrderDirection",
		notDiscountable:"Contember_OrderDirection",
		packing:"Contember_ProductPackingOrderBy",
		recipe:"Contember_ProductRecipeOrderBy",
		vatRate:"Contember_VatRateOrderBy",
		image:"Contember_ImageOrderBy",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		availability:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		manager:"Contember_StocksManagerOrderBy",
		maximumQuantityPerOrder:"Contember_OrderDirection",
		archivedAt:"Contember_OrderDirection",
		virtualProduct:"Contember_VirtualProductOrderBy",
		meta:"Contember_ProductMetadataOrderBy",
		page:"Contember_PageOrderBy",
		hasSubscription:"Contember_OrderDirection",
		pointOfSaleTileColor:"Contember_OrderDirection"
	},
	Contember_ProductPackingOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		image:"Contember_ImageOrderBy",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		volumeMl:"Contember_OrderDirection",
		icon:"Contember_ImageOrderBy",
		shortName:"Contember_OrderDirection",
		isPackedInCardboard:"Contember_OrderDirection"
	},
	Contember_ProductRecipeOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		color:"Contember_OrderDirection",
		image:"Contember_ImageOrderBy",
		icon:"Contember_ImageOrderBy",
		needsPrepping:"Contember_OrderDirection",
		gutTuning:"Contember_OrderDirection",
		freshingContainer:"Contember_FreshingContainerOrderBy",
		description:"Contember_OrderDirection",
		nutritionFacts:"Contember_NutritionFactsOrderBy"
	},
	Contember_FreshingContainerOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		volumeMl:"Contember_OrderDirection",
		workspace:"Contember_WorkspaceOrderBy"
	},
	Contember_WorkspaceOrderBy:{
		id:"Contember_OrderDirection",
		unique:"Contember_OrderDirection",
		orderDeadlineTime:"Contember_OrderDirection",
		ordersLoadingDurationHours:"Contember_OrderDirection",
		freshingContainerVolumeMl:"Contember_OrderDirection",
		freshingPrepareOrderByOffsetMinutes:"Contember_OrderDirection",
		checkoutUrlTemplate:"Contember_OrderDirection",
		orderUrlTemplate:"Contember_OrderDirection",
		paymentUrlTemplate:"Contember_OrderDirection",
		customerProfileUrlTemplate:"Contember_OrderDirection",
		userProfileUrlTemplate:"Contember_OrderDirection",
		messengerUrl:"Contember_OrderDirection",
		messengerIosUrl:"Contember_OrderDirection",
		messengerAndroidUrl:"Contember_OrderDirection",
		openingHours:"Contember_WeekHoursOrderBy",
		workingHours:"Contember_WeekHoursOrderBy",
		defaultFreshingContainer:"Contember_FreshingContainerOrderBy",
		creditsProduct:"Contember_VirtualProductOrderBy",
		discountVatRate:"Contember_VatRateOrderBy",
		name:"Contember_OrderDirection",
		businessAddress:"Contember_OrderDirection",
		migrationTest:"Contember_OrderDirection"
	},
	Contember_WeekHoursOrderBy:{
		id:"Contember_OrderDirection",
		mon:"Contember_OrderDirection",
		tue:"Contember_OrderDirection",
		wed:"Contember_OrderDirection",
		thu:"Contember_OrderDirection",
		fri:"Contember_OrderDirection",
		sat:"Contember_OrderDirection",
		sun:"Contember_OrderDirection"
	},
	Contember_VirtualProductOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy",
		voucherCreditCents:"Contember_OrderDirection",
		physicalRepresentation:"Contember_OrderDirection",
		recipientEmail:"Contember_OrderDirection"
	},
	Contember_NutritionFactsOrderBy:{
		id:"Contember_OrderDirection",
		energyKiloJoule:"Contember_OrderDirection",
		energyKiloCalorie:"Contember_OrderDirection",
		fatsGrams:"Contember_OrderDirection",
		fatsOfWhichSaturatedGrams:"Contember_OrderDirection",
		carbohydrateGrams:"Contember_OrderDirection",
		carbohydrateOfWhichSugarsGrams:"Contember_OrderDirection",
		proteinGrams:"Contember_OrderDirection",
		fibreGrams:"Contember_OrderDirection",
		saltGrams:"Contember_OrderDirection"
	},
	Contember_ProductMetadataOrderBy:{
		id:"Contember_OrderDirection",
		archived:"Contember_OrderDirection",
		available:"Contember_OrderDirection",
		availableQuantity:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy"
	},
	Contember_PageOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_Venue:{
		deadlineTemplate:{
			filter:"Contember_DeadlineTemplateWhere"
		},
		timetableDays:{
			filter:"Contember_TimetableDayWhere",
			orderBy:"Contember_TimetableDayOrderBy"
		},
		timetableTemplates:{
			filter:"Contember_TimetableTemplateWhere",
			orderBy:"Contember_TimetableTemplateOrderBy"
		},
		staffMembers:{
			filter:"Contember_StaffWhere",
			orderBy:"Contember_StaffOrderBy"
		},
		employeeRoles:{
			filter:"Contember_VenueEmployeeRoleWhere",
			orderBy:"Contember_VenueEmployeeRoleOrderBy"
		},
		pointOfSaleApplicationDevices:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		pointOfSaleTerminals:{
			filter:"Contember_PointOfSaleTerminalWhere",
			orderBy:"Contember_PointOfSaleTerminalOrderBy"
		},
		pointOfSalePrinters:{
			filter:"Contember_PointOfSalePrinterWhere",
			orderBy:"Contember_PointOfSalePrinterOrderBy"
		},
		orders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		fulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		},
		areas:{
			filter:"Contember_AreaWhere",
			orderBy:"Contember_AreaOrderBy"
		},
		enabledUnassignReasons:{
			filter:"Contember_UnassignReasonWhere",
			orderBy:"Contember_UnassignReasonOrderBy"
		},
		categories:{
			filter:"Contember_PointOfSaleCategoryWhere",
			orderBy:"Contember_PointOfSaleCategoryOrderBy"
		},
		channels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		},
		timetableDaysByDate:{
			by:"Contember_VenueTimetableDaysByDateUniqueWhere",
			filter:"Contember_TimetableDayWhere"
		},
		timetableDaysByShiftGroups:{
			by:"Contember_VenueTimetableDaysByShiftGroupsUniqueWhere",
			filter:"Contember_TimetableDayWhere"
		},
		timetableDaysByNotes:{
			by:"Contember_VenueTimetableDaysByNotesUniqueWhere",
			filter:"Contember_TimetableDayWhere"
		},
		timetableDaysByMeta:{
			by:"Contember_VenueTimetableDaysByMetaUniqueWhere",
			filter:"Contember_TimetableDayWhere"
		},
		timetableTemplatesByStaffOptions:{
			by:"Contember_VenueTimetableTemplatesByStaffOptionsUniqueWhere",
			filter:"Contember_TimetableTemplateWhere"
		},
		employeeRolesByRole:{
			by:"Contember_VenueEmployeeRolesByRoleUniqueWhere",
			filter:"Contember_VenueEmployeeRoleWhere"
		},
		employeeRolesByEmployeePrivileges:{
			by:"Contember_VenueEmployeeRolesByEmployeePrivilegesUniqueWhere",
			filter:"Contember_VenueEmployeeRoleWhere"
		},
		pointOfSaleApplicationDevicesByIdentifier:{
			by:"Contember_VenuePointOfSaleApplicationDevicesByIdentifierUniqueWhere",
			filter:"Contember_PointOfSaleApplicationDeviceWhere"
		},
		pointOfSaleTerminalsByApplicationDevices:{
			by:"Contember_VenuePointOfSaleTerminalsByApplicationDevicesUniqueWhere",
			filter:"Contember_PointOfSaleTerminalWhere"
		},
		pointOfSalePrintersByApplicationDevicesReceipt:{
			by:"Contember_VenuePointOfSalePrintersByApplicationDevicesReceiptUniqueWhere",
			filter:"Contember_PointOfSalePrinterWhere"
		},
		ordersBySeq:{
			by:"Contember_VenueOrdersBySeqUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDoubleshotLegacyId:{
			by:"Contember_VenueOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDoubleShotLegacyNumber:{
			by:"Contember_VenueOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDiscounts:{
			by:"Contember_VenueOrdersByDiscountsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDelivery:{
			by:"Contember_VenueOrdersByDeliveryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByItems:{
			by:"Contember_VenueOrdersByItemsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByCart:{
			by:"Contember_VenueOrdersByCartUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByEvents:{
			by:"Contember_VenueOrdersByEventsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByPriceLines:{
			by:"Contember_VenueOrdersByPriceLinesUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByPayments:{
			by:"Contember_VenueOrdersByPaymentsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersBySummary:{
			by:"Contember_VenueOrdersBySummaryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByGratuity:{
			by:"Contember_VenueOrdersByGratuityUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		areasByTables:{
			by:"Contember_VenueAreasByTablesUniqueWhere",
			filter:"Contember_AreaWhere"
		},
		areasByLines:{
			by:"Contember_VenueAreasByLinesUniqueWhere",
			filter:"Contember_AreaWhere"
		},
		categoriesBySlug:{
			by:"Contember_VenueCategoriesBySlugUniqueWhere",
			filter:"Contember_PointOfSaleCategoryWhere"
		},
		categoriesByLocales:{
			by:"Contember_VenueCategoriesByLocalesUniqueWhere",
			filter:"Contember_PointOfSaleCategoryWhere"
		},
		categoriesByProducts:{
			by:"Contember_VenueCategoriesByProductsUniqueWhere",
			filter:"Contember_PointOfSaleCategoryWhere"
		},
		channelsByCode:{
			by:"Contember_VenueChannelsByCodeUniqueWhere",
			filter:"Contember_ChannelWhere"
		},
		channelsByPayments:{
			by:"Contember_VenueChannelsByPaymentsUniqueWhere",
			filter:"Contember_ChannelWhere"
		},
		channelsByDeliveries:{
			by:"Contember_VenueChannelsByDeliveriesUniqueWhere",
			filter:"Contember_ChannelWhere"
		},
		channelsByCarts:{
			by:"Contember_VenueChannelsByCartsUniqueWhere",
			filter:"Contember_ChannelWhere"
		},
		paginateTimetableDays:{
			filter:"Contember_TimetableDayWhere",
			orderBy:"Contember_TimetableDayOrderBy"
		},
		paginateTimetableTemplates:{
			filter:"Contember_TimetableTemplateWhere",
			orderBy:"Contember_TimetableTemplateOrderBy"
		},
		paginateStaffMembers:{
			filter:"Contember_StaffWhere",
			orderBy:"Contember_StaffOrderBy"
		},
		paginateEmployeeRoles:{
			filter:"Contember_VenueEmployeeRoleWhere",
			orderBy:"Contember_VenueEmployeeRoleOrderBy"
		},
		paginatePointOfSaleApplicationDevices:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		paginatePointOfSaleTerminals:{
			filter:"Contember_PointOfSaleTerminalWhere",
			orderBy:"Contember_PointOfSaleTerminalOrderBy"
		},
		paginatePointOfSalePrinters:{
			filter:"Contember_PointOfSalePrinterWhere",
			orderBy:"Contember_PointOfSalePrinterOrderBy"
		},
		paginateOrders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		},
		paginateAreas:{
			filter:"Contember_AreaWhere",
			orderBy:"Contember_AreaOrderBy"
		},
		paginateEnabledUnassignReasons:{
			filter:"Contember_UnassignReasonWhere",
			orderBy:"Contember_UnassignReasonOrderBy"
		},
		paginateCategories:{
			filter:"Contember_PointOfSaleCategoryWhere",
			orderBy:"Contember_PointOfSaleCategoryOrderBy"
		},
		paginateChannels:{
			filter:"Contember_ChannelWhere",
			orderBy:"Contember_ChannelOrderBy"
		}
	},
	Contember_DeadlineTemplate:{
		venue:{
			filter:"Contember_VenueWhere"
		}
	},
	Contember_TimetableDay:{
		shiftGroups:{
			filter:"Contember_TimetableShiftGroupWhere",
			orderBy:"Contember_TimetableShiftGroupOrderBy"
		},
		notes:{
			filter:"Contember_TimetableDayNoteWhere",
			orderBy:"Contember_TimetableDayNoteOrderBy"
		},
		venue:{
			filter:"Contember_VenueWhere"
		},
		meta:{
			filter:"Contember_TimetableDayMetadataWhere"
		},
		shiftGroupsByShifts:{
			by:"Contember_TimetableDayShiftGroupsByShiftsUniqueWhere",
			filter:"Contember_TimetableShiftGroupWhere"
		},
		shiftGroupsByQueue:{
			by:"Contember_TimetableDayShiftGroupsByQueueUniqueWhere",
			filter:"Contember_TimetableShiftGroupWhere"
		},
		paginateShiftGroups:{
			filter:"Contember_TimetableShiftGroupWhere",
			orderBy:"Contember_TimetableShiftGroupOrderBy"
		},
		paginateNotes:{
			filter:"Contember_TimetableDayNoteWhere",
			orderBy:"Contember_TimetableDayNoteOrderBy"
		}
	},
	Contember_TimetableShiftGroup:{
		shifts:{
			filter:"Contember_TimetableShiftWhere",
			orderBy:"Contember_TimetableShiftOrderBy"
		},
		day:{
			filter:"Contember_TimetableDayWhere"
		},
		type:{
			filter:"Contember_TimetableEmployeeRoleWhere"
		},
		position:{
			filter:"Contember_TimetableShiftPositionWhere"
		},
		queue:{
			filter:"Contember_EmployeeQueueWhere"
		},
		generatedByTemplate:{
			filter:"Contember_TimetableTemplateWhere"
		},
		shiftsByQueueItem:{
			by:"Contember_TimetableShiftGroupShiftsByQueueItemUniqueWhere",
			filter:"Contember_TimetableShiftWhere"
		},
		shiftsBySwapInfo:{
			by:"Contember_TimetableShiftGroupShiftsBySwapInfoUniqueWhere",
			filter:"Contember_TimetableShiftWhere"
		},
		paginateShifts:{
			filter:"Contember_TimetableShiftWhere",
			orderBy:"Contember_TimetableShiftOrderBy"
		}
	},
	Contember_TimetableShift:{
		group:{
			filter:"Contember_TimetableShiftGroupWhere"
		},
		shiftsProfile:{
			filter:"Contember_ShiftsProfileWhere"
		},
		queueItem:{
			filter:"Contember_EmployeeQueueItemWhere"
		},
		swapInfo:{
			filter:"Contember_TimetableShiftSwapInfoWhere"
		}
	},
	Contember_ShiftsProfile:{
		queueItems:{
			filter:"Contember_EmployeeQueueItemWhere",
			orderBy:"Contember_EmployeeQueueItemOrderBy"
		},
		staff:{
			filter:"Contember_StaffWhere"
		},
		roles:{
			filter:"Contember_TimetableEmployeeRoleWhere",
			orderBy:"Contember_TimetableEmployeeRoleOrderBy"
		},
		shifts:{
			filter:"Contember_TimetableShiftWhere",
			orderBy:"Contember_TimetableShiftOrderBy"
		},
		queueItemsByQueue:{
			by:"Contember_ShiftsProfileQueueItemsByQueueUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		queueItemsByShift:{
			by:"Contember_ShiftsProfileQueueItemsByShiftUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		queueItemsByUnassignInfo:{
			by:"Contember_ShiftsProfileQueueItemsByUnassignInfoUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		queueItemsByMeta:{
			by:"Contember_ShiftsProfileQueueItemsByMetaUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		shiftsByQueueItem:{
			by:"Contember_ShiftsProfileShiftsByQueueItemUniqueWhere",
			filter:"Contember_TimetableShiftWhere"
		},
		shiftsBySwapInfo:{
			by:"Contember_ShiftsProfileShiftsBySwapInfoUniqueWhere",
			filter:"Contember_TimetableShiftWhere"
		},
		paginateQueueItems:{
			filter:"Contember_EmployeeQueueItemWhere",
			orderBy:"Contember_EmployeeQueueItemOrderBy"
		},
		paginateRoles:{
			filter:"Contember_TimetableEmployeeRoleWhere",
			orderBy:"Contember_TimetableEmployeeRoleOrderBy"
		},
		paginateShifts:{
			filter:"Contember_TimetableShiftWhere",
			orderBy:"Contember_TimetableShiftOrderBy"
		}
	},
	Contember_EmployeeQueueItem:{
		queue:{
			filter:"Contember_EmployeeQueueWhere"
		},
		shiftsProfile:{
			filter:"Contember_ShiftsProfileWhere"
		},
		shift:{
			filter:"Contember_TimetableShiftWhere"
		},
		unassignInfo:{
			filter:"Contember_UnassignedShiftInfoWhere"
		},
		meta:{
			filter:"Contember_EmployeeQueueItemMetadataWhere"
		}
	},
	Contember_EmployeeQueue:{
		shiftGroup:{
			filter:"Contember_TimetableShiftGroupWhere"
		},
		items:{
			filter:"Contember_EmployeeQueueItemWhere",
			orderBy:"Contember_EmployeeQueueItemOrderBy"
		},
		itemsByShiftsProfile:{
			by:"Contember_EmployeeQueueItemsByShiftsProfileUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		itemsByShift:{
			by:"Contember_EmployeeQueueItemsByShiftUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		itemsByUnassignInfo:{
			by:"Contember_EmployeeQueueItemsByUnassignInfoUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		itemsByMeta:{
			by:"Contember_EmployeeQueueItemsByMetaUniqueWhere",
			filter:"Contember_EmployeeQueueItemWhere"
		},
		paginateItems:{
			filter:"Contember_EmployeeQueueItemWhere",
			orderBy:"Contember_EmployeeQueueItemOrderBy"
		}
	},
	Contember_EmployeeQueueItemOrderBy:{
		id:"Contember_OrderDirection",
		start:"Contember_OrderDirection",
		end:"Contember_OrderDirection",
		queue:"Contember_EmployeeQueueOrderBy",
		shiftsProfile:"Contember_ShiftsProfileOrderBy",
		shift:"Contember_TimetableShiftOrderBy",
		unassignInfo:"Contember_UnassignedShiftInfoOrderBy",
		meta:"Contember_EmployeeQueueItemMetadataOrderBy"
	},
	Contember_EmployeeQueueOrderBy:{
		id:"Contember_OrderDirection",
		shiftGroup:"Contember_TimetableShiftGroupOrderBy",
		contemberBugFix:"Contember_OrderDirection"
	},
	Contember_TimetableShiftGroupOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		day:"Contember_TimetableDayOrderBy",
		type:"Contember_TimetableEmployeeRoleOrderBy",
		position:"Contember_TimetableShiftPositionOrderBy",
		queue:"Contember_EmployeeQueueOrderBy",
		generatedByTemplate:"Contember_TimetableTemplateOrderBy"
	},
	Contember_TimetableDayOrderBy:{
		id:"Contember_OrderDirection",
		date:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		tipsCents:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy",
		meta:"Contember_TimetableDayMetadataOrderBy"
	},
	Contember_TimetableDayMetadataOrderBy:{
		id:"Contember_OrderDirection",
		numberOfWorkers:"Contember_OrderDirection",
		tipsTotalCents:"Contember_OrderDirection",
		totalMinutes:"Contember_OrderDirection",
		tipsCentsPerMinute:"Contember_OrderDirection",
		day:"Contember_TimetableDayOrderBy"
	},
	Contember_TimetableEmployeeRoleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		color:"Contember_OrderDirection"
	},
	Contember_TimetableShiftPositionOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		start:"Contember_OrderDirection",
		end:"Contember_OrderDirection",
		isDynamic:"Contember_OrderDirection"
	},
	Contember_TimetableTemplateOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		startDate:"Contember_OrderDirection",
		period:"Contember_OrderDirection",
		slots:"Contember_OrderDirection",
		position:"Contember_TimetableShiftPositionOrderBy",
		internalName:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy",
		employeeRole:"Contember_TimetableEmployeeRoleOrderBy",
		workdaysOnly:"Contember_OrderDirection",
		monday:"Contember_OrderDirection",
		tuesday:"Contember_OrderDirection",
		wednesday:"Contember_OrderDirection",
		thursday:"Contember_OrderDirection",
		friday:"Contember_OrderDirection",
		saturday:"Contember_OrderDirection",
		sunday:"Contember_OrderDirection",
		type:"Contember_OrderDirection"
	},
	Contember_TimetableShiftOrderBy:{
		id:"Contember_OrderDirection",
		locked:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		assigned:"Contember_OrderDirection",
		group:"Contember_TimetableShiftGroupOrderBy",
		shiftsProfile:"Contember_ShiftsProfileOrderBy",
		queueItem:"Contember_EmployeeQueueItemOrderBy",
		swapInfo:"Contember_TimetableShiftSwapInfoOrderBy",
		isOvertime:"Contember_OrderDirection"
	},
	Contember_TimetableShiftSwapInfoOrderBy:{
		id:"Contember_OrderDirection",
		assigned:"Contember_OrderDirection",
		requested:"Contember_OrderDirection",
		confirmed:"Contember_OrderDirection",
		swappedShiftsProfile:"Contember_ShiftsProfileOrderBy",
		shift:"Contember_TimetableShiftOrderBy"
	},
	Contember_UnassignedShiftInfoOrderBy:{
		id:"Contember_OrderDirection",
		hoursMissed:"Contember_OrderDirection",
		queueItem:"Contember_EmployeeQueueItemOrderBy",
		legacyReason:"Contember_OrderDirection",
		reason:"Contember_UnassignReasonOrderBy"
	},
	Contember_UnassignReasonOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		name:"Contember_OrderDirection"
	},
	Contember_EmployeeQueueItemMetadataOrderBy:{
		id:"Contember_OrderDirection",
		totalMinutes:"Contember_OrderDirection",
		queueItem:"Contember_EmployeeQueueItemOrderBy",
		includeInTipsCalculation:"Contember_OrderDirection"
	},
	Contember_EmployeeQueueItemsByShiftsProfileUniqueWhere:{
		shiftsProfile:"Contember_ShiftsProfileUniqueWhere"
	},
	Contember_ShiftsProfileUniqueWhere:{
		id:"UUID",
		queueItems:"Contember_EmployeeQueueItemUniqueWhere",
		staff:"Contember_StaffUniqueWhere",
		shifts:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_EmployeeQueueItemUniqueWhere:{
		id:"UUID",
		shiftsProfile:"Contember_ShiftsProfileUniqueWhere",
		queue:"Contember_EmployeeQueueUniqueWhere",
		shift:"Contember_TimetableShiftUniqueWhere",
		unassignInfo:"Contember_UnassignedShiftInfoUniqueWhere",
		meta:"Contember_EmployeeQueueItemMetadataUniqueWhere"
	},
	Contember_EmployeeQueueUniqueWhere:{
		id:"UUID",
		shiftGroup:"Contember_TimetableShiftGroupUniqueWhere",
		items:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_TimetableShiftGroupUniqueWhere:{
		id:"UUID",
		shifts:"Contember_TimetableShiftUniqueWhere",
		queue:"Contember_EmployeeQueueUniqueWhere"
	},
	Contember_TimetableShiftUniqueWhere:{
		id:"UUID",
		queueItem:"Contember_EmployeeQueueItemUniqueWhere",
		swapInfo:"Contember_TimetableShiftSwapInfoUniqueWhere"
	},
	Contember_TimetableShiftSwapInfoUniqueWhere:{
		id:"UUID",
		swappedShiftsProfile:"Contember_ShiftsProfileUniqueWhere",
		shift:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_UnassignedShiftInfoUniqueWhere:{
		id:"UUID",
		queueItem:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_EmployeeQueueItemMetadataUniqueWhere:{
		id:"UUID",
		queueItem:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_StaffUniqueWhere:{
		id:"UUID",
		bartender:"Contember_BartenderUniqueWhere",
		courier:"Contember_CourierUniqueWhere",
		cleaner:"Contember_CleanerUniqueWhere",
		shiftsManager:"Contember_ShiftsManagerUniqueWhere",
		businessToBusinessManager:"Contember_BusinessToBusinessManagerUniqueWhere",
		user:"Contember_UserUniqueWhere",
		shiftsProfile:"Contember_ShiftsProfileUniqueWhere",
		stocksManager:"Contember_StocksManagerUniqueWhere",
		courierManager:"Contember_CourierManagerUniqueWhere",
		picker:"Contember_PickerUniqueWhere",
		remunerationProfile:"Contember_RemunerationProfileUniqueWhere",
		cashier:"Contember_CashierUniqueWhere",
		internalBuyer:"Contember_InternalBuyerUniqueWhere",
		salesManager:"Contember_SalesManagerUniqueWhere",
		cashierManager:"Contember_CashierManagerUniqueWhere"
	},
	Contember_BartenderUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_CourierUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere",
		routes:"Contember_DeliveryRouteUniqueWhere"
	},
	Contember_DeliveryRouteUniqueWhere:{
		id:"UUID",
		waypoints:"Contember_DeliveryWaypointUniqueWhere"
	},
	Contember_DeliveryWaypointUniqueWhere:{
		id:"UUID",
		delivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_OrderDeliveryUniqueWhere:{
		id:"UUID",
		order:"Contember_OrderUniqueWhere",
		deliveryWaypoint:"Contember_DeliveryWaypointUniqueWhere",
		events:"Contember_OrderEventUniqueWhere",
		liftagoRide:"Contember_LiftagoRideUniqueWhere"
	},
	Contember_OrderUniqueWhere:{
		id:"UUID",
		discounts:"Contember_OrderDiscountUniqueWhere",
		delivery:"Contember_OrderDeliveryUniqueWhere",
		items:"Contember_OrderItemUniqueWhere",
		cart:"Contember_CartUniqueWhere",
		events:"Contember_OrderEventUniqueWhere",
		priceLines:"Contember_OrderPriceLineUniqueWhere",
		payments:"Contember_OrderPaymentUniqueWhere",
		summary:"Contember_OrderSummaryUniqueWhere",
		gratuity:"Contember_GratuityUniqueWhere"
	},
	Contember_OrderDiscountUniqueWhere:{
		id:"UUID",
		customerCreditTransactions:"Contember_CustomerCreditTransactionUniqueWhere",
		voucherRedemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_CustomerCreditTransactionUniqueWhere:{
		id:"UUID",
		virtualProductEffect:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_VirtualProductEffectUniqueWhere:{
		id:"UUID",
		resultCustomerCreditTransaction:"Contember_CustomerCreditTransactionUniqueWhere",
		resultVouchers:"Contember_VoucherUniqueWhere"
	},
	Contember_VoucherUniqueWhere:{
		id:"UUID",
		redemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_VoucherRedemptionUniqueWhere:{
		id:"UUID",
		customerCredit:"Contember_CustomerCreditUniqueWhere",
		orderDiscount:"Contember_OrderDiscountUniqueWhere",
		voucher:"Contember_VoucherUniqueWhere"
	},
	Contember_CustomerCreditUniqueWhere:{
		id:"UUID",
		transactions:"Contember_CustomerCreditTransactionUniqueWhere",
		voucherRedemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_OrderItemUniqueWhere:{
		id:"UUID",
		virtualProductEffects:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_CartUniqueWhere:{
		id:"UUID",
		items:"Contember_CartItemUniqueWhere",
		order:"Contember_OrderUniqueWhere",
		priceLines:"Contember_CartPriceLineUniqueWhere",
		summary:"Contember_CartSummaryUniqueWhere"
	},
	Contember_CartItemUniqueWhere:{
		id:"UUID",
		cart:"Contember_CartUniqueWhere",
		product:"Contember_ProductUniqueWhere"
	},
	Contember_ProductUniqueWhere:{
		id:"UUID",
		stocks:"Contember_ProductStockUniqueWhere",
		groupPrices:"Contember_ProductGroupPriceUniqueWhere",
		cartItems:"Contember_CartItemUniqueWhere",
		orderItems:"Contember_OrderItemUniqueWhere",
		inheritedCategories:"Contember_NestedProductCategoryProductUniqueWhere",
		locales:"Contember_ProductLocaleUniqueWhere",
		customerPrices:"Contember_ProductCustomerPriceUniqueWhere",
		virtualProduct:"Contember_VirtualProductUniqueWhere",
		meta:"Contember_ProductMetadataUniqueWhere",
		page:"Contember_PageUniqueWhere",
		salesDays:"Contember_ProductSalesDayUniqueWhere",
		salesWeeks:"Contember_ProductSalesWeekUniqueWhere",
		salesMonths:"Contember_ProductSalesMonthUniqueWhere"
	},
	Contember_ProductStockUniqueWhere:{
		id:"UUID",
		product:"Contember_ProductUniqueWhere",
		store:"Contember_StoreUniqueWhere"
	},
	Contember_StoreUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductGroupPriceUniqueWhere:{
		id:"UUID",
		group:"Contember_CustomerGroupUniqueWhere",
		product:"Contember_ProductUniqueWhere"
	},
	Contember_CustomerGroupUniqueWhere:{
		id:"UUID",
		productPrices:"Contember_ProductGroupPriceUniqueWhere",
		customers:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerUniqueWhere:{
		id:"UUID",
		addresses:"Contember_AddressUniqueWhere",
		defaultBillingAddress:"Contember_AddressUniqueWhere",
		defaultPaymentMethod:"Contember_CustomerPaymentMethodUniqueWhere",
		credits:"Contember_CustomerCreditUniqueWhere",
		paymentMethods:"Contember_CustomerPaymentMethodUniqueWhere",
		orders:"Contember_OrderUniqueWhere",
		permissions:"Contember_CustomerPermissionsUniqueWhere",
		name:"Contember_CustomerNameUniqueWhere",
		ingredientRatings:"Contember_CustomerIngredientRatingUniqueWhere",
		business:"Contember_BusinessCustomerUniqueWhere",
		carts:"Contember_CartUniqueWhere",
		shortcutList:"Contember_ApplicationShortcutListUniqueWhere",
		user:"Contember_UserUniqueWhere",
		invoices:"Contember_InvoiceUniqueWhere",
		invoicingPlan:"Contember_InvoicingPlanUniqueWhere",
		billingSubjects:"Contember_BillingSubjectUniqueWhere",
		offeredProducts:"Contember_ProductCustomerPriceUniqueWhere",
		defaultDeliveryAddress:"Contember_AddressUniqueWhere",
		defaultBillingSubject:"Contember_BillingSubjectUniqueWhere",
		anonymousSession:"Contember_AnonymousSessionUniqueWhere",
		boughtVouchers:"Contember_VoucherUniqueWhere",
		ownsVouchers:"Contember_VoucherUniqueWhere",
		redeemedVouchers:"Contember_VoucherRedemptionUniqueWhere",
		spending:"Contember_CustomerSpendingUniqueWhere",
		customerTabs:"Contember_CustomerTabUniqueWhere",
		lead:"Contember_PipelineLeadUniqueWhere",
		meta:"Contember_CustomerMetadataUniqueWhere"
	},
	Contember_AddressUniqueWhere:{
		id:"UUID",
		meta:"Contember_AddressMetadataUniqueWhere",
		gps:"Contember_GpsUniqueWhere",
		invoices:"Contember_InvoiceUniqueWhere",
		billingAddressOfOrders:"Contember_OrderUniqueWhere",
		deliveryAddressOfOrderDeliveries:"Contember_OrderDeliveryUniqueWhere",
		defaultDeliveryAddressOfCustomer:"Contember_CustomerUniqueWhere"
	},
	Contember_AddressMetadataUniqueWhere:{
		id:"UUID",
		address:"Contember_AddressUniqueWhere"
	},
	Contember_GpsUniqueWhere:{
		id:"UUID",
		address:"Contember_AddressUniqueWhere"
	},
	Contember_InvoiceUniqueWhere:{
		id:"UUID",
		orderPayments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_OrderPaymentUniqueWhere:{
		id:"UUID",
		events:"Contember_OrderEventUniqueWhere",
		meta:"Contember_OrderPaymentMetadataUniqueWhere"
	},
	Contember_OrderEventUniqueWhere:{
		id:"UUID"
	},
	Contember_OrderPaymentMetadataUniqueWhere:{
		id:"UUID",
		orderPayment:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_CustomerPaymentMethodUniqueWhere:{
		id:"UUID"
	},
	Contember_CustomerPermissionsUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerNameUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerIngredientRatingUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere",
		ingredient:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_ProductIngredientUniqueWhere:{
		id:"UUID",
		locales:"Contember_ProductIngredientLocaleUniqueWhere",
		customerRatings:"Contember_CustomerIngredientRatingUniqueWhere",
		suppliers:"Contember_IngredientSupplierListUniqueWhere"
	},
	Contember_ProductIngredientLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_ProductIngredientUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleUniqueWhere:{
		id:"UUID",
		businessCategories:"Contember_BusinessCategoryLocaleUniqueWhere",
		productCategoryLists:"Contember_ProductCategoryListLocaleUniqueWhere",
		productIngredients:"Contember_ProductIngredientLocaleUniqueWhere",
		orderRecurrences:"Contember_OrderRecurrenceLocaleUniqueWhere",
		blogs:"Contember_BlogLocaleUniqueWhere",
		blogPosts:"Contember_BlogPostLocaleUniqueWhere",
		identifier:"Contember_TranslationCataloguesIdentifierUniqueWhere",
		menus:"Contember_MenuLocaleUniqueWhere",
		pages:"Contember_PageLocaleUniqueWhere",
		productIngredientCategories:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		allergens:"Contember_AllergenLocaleUniqueWhere",
		productPackings:"Contember_ProductPackingLocaleUniqueWhere",
		products:"Contember_ProductLocaleUniqueWhere",
		productRecipes:"Contember_ProductRecipeLocaleUniqueWhere",
		pointOfSaleCategories:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		pointOfSaleCategoryProducts:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		deliveryMethods:"Contember_DeliveryMethodLocaleUniqueWhere",
		ingredientSuppliers:"Contember_IngredientSupplierLocaleUniqueWhere",
		identificationCodes:"Contember_IdentificationCodeLocaleUniqueWhere",
		pipelineStages:"Contember_PipelineStageLocaleUniqueWhere",
		subscriptionBoxes:"Contember_SubscriptionBoxLocaleUniqueWhere",
		businessServices:"Contember_BusinessServiceLocaleUniqueWhere"
	},
	Contember_BusinessCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_BusinessCategoryUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BusinessCategoryUniqueWhere:{
		id:"UUID",
		locales:"Contember_BusinessCategoryLocaleUniqueWhere",
		businessCustomers:"Contember_BusinessCustomerUniqueWhere",
		specificProducts:"Contember_ProductListUniqueWhere",
		seasonalProductCategories:"Contember_ProductCategoryListUniqueWhere",
		specificProductsProductCategories:"Contember_ProductCategoryListUniqueWhere"
	},
	Contember_BusinessCustomerUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere",
		businessServiceEvents:"Contember_BusinessServiceEventUniqueWhere",
		doubleshotCoffeeEquipment:"Contember_DoubleshotCoffeeEquipmentUniqueWhere"
	},
	Contember_BusinessServiceEventUniqueWhere:{
		id:"UUID"
	},
	Contember_DoubleshotCoffeeEquipmentUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductListUniqueWhere:{
		id:"UUID",
		items:"Contember_ProductListItemUniqueWhere",
		businessCategory:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_ProductListItemUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductCategoryListUniqueWhere:{
		id:"UUID",
		items:"Contember_ProductCategoryListItemUniqueWhere",
		locales:"Contember_ProductCategoryListLocaleUniqueWhere",
		seasonalProductCategoriesOfBusinessCategory:"Contember_BusinessCategoryUniqueWhere",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_ProductCategoryListItemUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductCategoryListLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_ProductCategoryListUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_OrderRecurrenceLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_OrderRecurrenceUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_OrderRecurrenceUniqueWhere:{
		id:"UUID",
		locales:"Contember_OrderRecurrenceLocaleUniqueWhere"
	},
	Contember_BlogLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_BlogUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BlogUniqueWhere:{
		id:"UUID",
		unique:"Contember_One",
		locales:"Contember_BlogLocaleUniqueWhere"
	},
	Contember_BlogPostLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_BlogPostUniqueWhere",
		locale:"Contember_LocaleUniqueWhere",
		cover:"Contember_CoverUniqueWhere",
		content:"Contember_ContentUniqueWhere",
		link:"Contember_LinkableUniqueWhere",
		seo:"Contember_SeoUniqueWhere"
	},
	Contember_BlogPostUniqueWhere:{
		id:"UUID",
		locales:"Contember_BlogPostLocaleUniqueWhere"
	},
	Contember_CoverUniqueWhere:{
		id:"UUID"
	},
	Contember_ContentUniqueWhere:{
		id:"UUID",
		blocks:"Contember_ContentBlockUniqueWhere"
	},
	Contember_ContentBlockUniqueWhere:{
		id:"UUID",
		references:"Contember_ContentReferenceUniqueWhere"
	},
	Contember_ContentReferenceUniqueWhere:{
		id:"UUID",
		products:"Contember_ProductListUniqueWhere",
		productBanners:"Contember_ProductBannerListUniqueWhere",
		deliveryRegions:"Contember_DeliveryRegionsUniqueWhere"
	},
	Contember_ProductBannerListUniqueWhere:{
		id:"UUID",
		items:"Contember_ProductBannerItemUniqueWhere"
	},
	Contember_ProductBannerItemUniqueWhere:{
		id:"UUID"
	},
	Contember_DeliveryRegionsUniqueWhere:{
		id:"UUID",
		items:"Contember_DeliveryRegionsItemUniqueWhere"
	},
	Contember_DeliveryRegionsItemUniqueWhere:{
		id:"UUID",
		image:"Contember_ImageUniqueWhere",
		textList:"Contember_TextListUniqueWhere",
		gallery:"Contember_ImageListUniqueWhere"
	},
	Contember_ImageUniqueWhere:{
		id:"UUID"
	},
	Contember_TextListUniqueWhere:{
		id:"UUID",
		items:"Contember_TextItemUniqueWhere"
	},
	Contember_TextItemUniqueWhere:{
		id:"UUID"
	},
	Contember_ImageListUniqueWhere:{
		id:"UUID",
		items:"Contember_ImageListItemUniqueWhere"
	},
	Contember_ImageListItemUniqueWhere:{
		id:"UUID",
		image:"Contember_ImageUniqueWhere"
	},
	Contember_LinkableUniqueWhere:{
		id:"UUID",
		blogPost:"Contember_BlogPostLocaleUniqueWhere",
		page:"Contember_PageLocaleUniqueWhere",
		wikiPage:"Contember_WikiPageUniqueWhere",
		redirect:"Contember_RedirectUniqueWhere"
	},
	Contember_PageLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_PageUniqueWhere",
		locale:"Contember_LocaleUniqueWhere",
		link:"Contember_LinkableUniqueWhere",
		seo:"Contember_SeoUniqueWhere",
		content:"Contember_ContentUniqueWhere",
		cover:"Contember_CoverUniqueWhere"
	},
	Contember_PageUniqueWhere:{
		id:"UUID",
		locales:"Contember_PageLocaleUniqueWhere"
	},
	Contember_SeoUniqueWhere:{
		id:"UUID",
		ogImage:"Contember_ImageUniqueWhere"
	},
	Contember_WikiPageUniqueWhere:{
		id:"UUID",
		link:"Contember_LinkableUniqueWhere",
		children:"Contember_WikiPageUniqueWhere",
		content:"Contember_ContentUniqueWhere"
	},
	Contember_RedirectUniqueWhere:{
		id:"UUID",
		link:"Contember_LinkableUniqueWhere"
	},
	Contember_TranslationCataloguesIdentifierUniqueWhere:{
		id:"UUID",
		catalogue:"Contember_TranslationCatalogueUniqueWhere"
	},
	Contember_TranslationCatalogueUniqueWhere:{
		id:"UUID",
		domain:"Contember_TranslationDomainUniqueWhere",
		identifier:"Contember_TranslationCataloguesIdentifierUniqueWhere",
		values:"Contember_TranslationValueUniqueWhere"
	},
	Contember_TranslationDomainUniqueWhere:{
		id:"UUID",
		catalogues:"Contember_TranslationCatalogueUniqueWhere",
		keys:"Contember_TranslationKeyUniqueWhere"
	},
	Contember_TranslationKeyUniqueWhere:{
		id:"UUID",
		domain:"Contember_TranslationDomainUniqueWhere",
		values:"Contember_TranslationValueUniqueWhere"
	},
	Contember_TranslationValueUniqueWhere:{
		id:"UUID",
		catalogue:"Contember_TranslationCatalogueUniqueWhere",
		key:"Contember_TranslationKeyUniqueWhere"
	},
	Contember_MenuLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_SiteMenuUniqueWhere",
		locale:"Contember_LocaleUniqueWhere",
		items:"Contember_MenuItemUniqueWhere",
		secondaryItems:"Contember_LinkListUniqueWhere"
	},
	Contember_SiteMenuUniqueWhere:{
		id:"UUID",
		unique:"Contember_One",
		locales:"Contember_MenuLocaleUniqueWhere"
	},
	Contember_MenuItemUniqueWhere:{
		id:"UUID",
		subitems:"Contember_MenuSubitemUniqueWhere"
	},
	Contember_MenuSubitemUniqueWhere:{
		id:"UUID"
	},
	Contember_LinkListUniqueWhere:{
		id:"UUID",
		items:"Contember_LinkListItemUniqueWhere"
	},
	Contember_LinkListItemUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductIngredientCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_ProductIngredientCategoryUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_ProductIngredientCategoryUniqueWhere:{
		id:"UUID",
		locales:"Contember_ProductIngredientCategoryLocaleUniqueWhere"
	},
	Contember_AllergenLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_AllergenUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_AllergenUniqueWhere:{
		id:"UUID",
		locales:"Contember_AllergenLocaleUniqueWhere"
	},
	Contember_ProductPackingLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_ProductPackingUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_ProductPackingUniqueWhere:{
		id:"UUID",
		locales:"Contember_ProductPackingLocaleUniqueWhere",
		preparedPackingQuantities:"Contember_PreparedPackingQuantityUniqueWhere"
	},
	Contember_PreparedPackingQuantityUniqueWhere:{
		id:"UUID",
		date:"Date",
		recipe:"Contember_ProductRecipeUniqueWhere",
		packing:"Contember_ProductPackingUniqueWhere"
	},
	Contember_ProductRecipeUniqueWhere:{
		id:"UUID",
		products:"Contember_ProductUniqueWhere",
		ingredients:"Contember_ProductIngredientItemUniqueWhere",
		locales:"Contember_ProductRecipeLocaleUniqueWhere",
		nutritionFacts:"Contember_NutritionFactsUniqueWhere"
	},
	Contember_ProductIngredientItemUniqueWhere:{
		id:"UUID",
		quantity:"Contember_ProductIngredientItemQuantityUniqueWhere"
	},
	Contember_ProductIngredientItemQuantityUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductRecipeLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_ProductRecipeUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_NutritionFactsUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_ProductUniqueWhere",
		locale:"Contember_LocaleUniqueWhere",
		featureList:"Contember_TextListUniqueWhere"
	},
	Contember_PointOfSaleCategoryLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_PointOfSaleCategoryUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_PointOfSaleCategoryUniqueWhere:{
		id:"UUID",
		locales:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		products:"Contember_PointOfSaleCategoryProductUniqueWhere"
	},
	Contember_PointOfSaleCategoryProductUniqueWhere:{
		id:"UUID",
		locales:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere"
	},
	Contember_PointOfSaleCategoryProductLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_PointOfSaleCategoryProductUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_DeliveryMethodLocaleUniqueWhere:{
		id:"UUID",
		locale:"Contember_LocaleUniqueWhere",
		root:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_DeliveryMethodUniqueWhere:{
		id:"UUID",
		channels:"Contember_ChannelDeliveryUniqueWhere",
		locales:"Contember_DeliveryMethodLocaleUniqueWhere",
		timelinePreset:"Contember_DeliveryTimelinePresetUniqueWhere"
	},
	Contember_ChannelDeliveryUniqueWhere:{
		id:"UUID"
	},
	Contember_DeliveryTimelinePresetUniqueWhere:{
		id:"UUID",
		deliveryMethod:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_IngredientSupplierLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_IngredientSupplierUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_IngredientSupplierUniqueWhere:{
		id:"UUID",
		locales:"Contember_IngredientSupplierLocaleUniqueWhere"
	},
	Contember_IdentificationCodeLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_IdentificationCodeUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_IdentificationCodeUniqueWhere:{
		id:"UUID",
		attempts:"Contember_IdentificationAttemptUniqueWhere",
		locales:"Contember_IdentificationCodeLocaleUniqueWhere",
		applicationDevices:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_IdentificationAttemptUniqueWhere:{
		id:"UUID"
	},
	Contember_PointOfSaleApplicationDeviceUniqueWhere:{
		id:"UUID",
		identifier:"Contember_DeviceIdentifierUniqueWhere"
	},
	Contember_DeviceIdentifierUniqueWhere:{
		id:"UUID",
		device:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		meta:"Contember_DeviceIdentifierMetadataUniqueWhere"
	},
	Contember_DeviceIdentifierMetadataUniqueWhere:{
		id:"UUID",
		deviceIdentifier:"Contember_DeviceIdentifierUniqueWhere"
	},
	Contember_PipelineStageLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_PipelineStageUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_PipelineStageUniqueWhere:{
		id:"UUID",
		items:"Contember_PipelineStageItemUniqueWhere",
		locales:"Contember_PipelineStageLocaleUniqueWhere",
		forms:"Contember_LeadFormUniqueWhere"
	},
	Contember_PipelineStageItemUniqueWhere:{
		id:"UUID",
		lead:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_PipelineLeadUniqueWhere:{
		id:"UUID",
		stageItem:"Contember_PipelineStageItemUniqueWhere"
	},
	Contember_LeadFormUniqueWhere:{
		id:"UUID",
		leads:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_SubscriptionBoxLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_SubscriptionBoxUniqueWhere",
		locale:"Contember_LocaleUniqueWhere",
		texts:"Contember_TextListUniqueWhere",
		secondaryTexts:"Contember_TextListUniqueWhere"
	},
	Contember_SubscriptionBoxUniqueWhere:{
		id:"UUID",
		unique:"Contember_One",
		locales:"Contember_SubscriptionBoxLocaleUniqueWhere"
	},
	Contember_BusinessServiceLocaleUniqueWhere:{
		id:"UUID",
		root:"Contember_BusinessServiceUniqueWhere",
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BusinessServiceUniqueWhere:{
		id:"UUID",
		locales:"Contember_BusinessServiceLocaleUniqueWhere"
	},
	Contember_IngredientSupplierListUniqueWhere:{
		id:"UUID",
		items:"Contember_IngredientSupplierItemUniqueWhere"
	},
	Contember_IngredientSupplierItemUniqueWhere:{
		id:"UUID"
	},
	Contember_ApplicationShortcutListUniqueWhere:{
		id:"UUID",
		items:"Contember_ApplicationShortcutListItemUniqueWhere",
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_ApplicationShortcutListItemUniqueWhere:{
		id:"UUID"
	},
	Contember_UserUniqueWhere:{
		id:"UUID",
		tenantPersonId:"UUID",
		staff:"Contember_StaffUniqueWhere",
		customer:"Contember_CustomerUniqueWhere",
		devices:"Contember_UserDeviceUniqueWhere",
		meta:"Contember_UserMetadataUniqueWhere",
		identificationAttempts:"Contember_IdentificationAttemptUniqueWhere",
		roles:"Contember_UserRolesUniqueWhere"
	},
	Contember_UserDeviceUniqueWhere:{
		id:"UUID"
	},
	Contember_UserMetadataUniqueWhere:{
		id:"UUID",
		tenantPersonId:"UUID",
		tenantIdentityId:"UUID",
		user:"Contember_UserUniqueWhere"
	},
	Contember_UserRolesUniqueWhere:{
		id:"UUID",
		user:"Contember_UserUniqueWhere"
	},
	Contember_InvoicingPlanUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere",
		preset:"Contember_InvoicingPresetUniqueWhere"
	},
	Contember_InvoicingPresetUniqueWhere:{
		id:"UUID",
		invoicingPlan:"Contember_InvoicingPlanUniqueWhere"
	},
	Contember_BillingSubjectUniqueWhere:{
		id:"UUID",
		invoices:"Contember_InvoiceUniqueWhere",
		defaultBillingSubjectOfCustomer:"Contember_CustomerUniqueWhere",
		orders:"Contember_OrderUniqueWhere"
	},
	Contember_ProductCustomerPriceUniqueWhere:{
		id:"UUID",
		product:"Contember_ProductUniqueWhere",
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_AnonymousSessionUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere",
		devices:"Contember_UserDeviceUniqueWhere"
	},
	Contember_CustomerSpendingUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerTabUniqueWhere:{
		id:"UUID",
		items:"Contember_CustomerTabItemUniqueWhere",
		meta:"Contember_CustomerTabMetadataUniqueWhere"
	},
	Contember_CustomerTabItemUniqueWhere:{
		id:"UUID"
	},
	Contember_CustomerTabMetadataUniqueWhere:{
		id:"UUID",
		customerTab:"Contember_CustomerTabUniqueWhere"
	},
	Contember_CustomerMetadataUniqueWhere:{
		id:"UUID",
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_NestedProductCategoryProductUniqueWhere:{
		id:"UUID"
	},
	Contember_VirtualProductUniqueWhere:{
		id:"UUID",
		product:"Contember_ProductUniqueWhere"
	},
	Contember_ProductMetadataUniqueWhere:{
		id:"UUID",
		product:"Contember_ProductUniqueWhere"
	},
	Contember_ProductSalesDayUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductSalesWeekUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductSalesMonthUniqueWhere:{
		id:"UUID"
	},
	Contember_CartPriceLineUniqueWhere:{
		id:"UUID"
	},
	Contember_CartSummaryUniqueWhere:{
		id:"UUID",
		cart:"Contember_CartUniqueWhere"
	},
	Contember_OrderPriceLineUniqueWhere:{
		id:"UUID",
		orderItem:"Contember_OrderItemUniqueWhere",
		orderDiscount:"Contember_OrderDiscountUniqueWhere",
		orderPayment:"Contember_OrderPaymentUniqueWhere",
		orderDelivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_OrderSummaryUniqueWhere:{
		id:"UUID",
		order:"Contember_OrderUniqueWhere"
	},
	Contember_GratuityUniqueWhere:{
		id:"UUID",
		order:"Contember_OrderUniqueWhere"
	},
	Contember_LiftagoRideUniqueWhere:{
		id:"UUID",
		delivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_CleanerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_ShiftsManagerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_BusinessToBusinessManagerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere",
		managedBusinesses:"Contember_BusinessCustomerUniqueWhere"
	},
	Contember_StocksManagerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere",
		managedProducts:"Contember_ProductUniqueWhere"
	},
	Contember_CourierManagerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_PickerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_RemunerationProfileUniqueWhere:{
		id:"UUID",
		employmentInformation:"Contember_EmploymentInformationUniqueWhere",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_EmploymentInformationUniqueWhere:{
		id:"UUID"
	},
	Contember_CashierUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_InternalBuyerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_SalesManagerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_CashierManagerUniqueWhere:{
		id:"UUID",
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_EmployeeQueueItemsByShiftUniqueWhere:{
		shift:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_EmployeeQueueItemsByUnassignInfoUniqueWhere:{
		unassignInfo:"Contember_UnassignedShiftInfoUniqueWhere"
	},
	Contember_EmployeeQueueItemsByMetaUniqueWhere:{
		meta:"Contember_EmployeeQueueItemMetadataUniqueWhere"
	},
	Contember_UnassignedShiftInfo:{
		queueItem:{
			filter:"Contember_EmployeeQueueItemWhere"
		},
		reason:{
			filter:"Contember_UnassignReasonWhere"
		}
	},
	Contember_UnassignReason:{
		venues:{
			filter:"Contember_VenueWhere",
			orderBy:"Contember_VenueOrderBy"
		},
		paginateVenues:{
			filter:"Contember_VenueWhere",
			orderBy:"Contember_VenueOrderBy"
		}
	},
	Contember_EmployeeQueueItemMetadata:{
		queueItem:{
			filter:"Contember_EmployeeQueueItemWhere"
		}
	},
	Contember_TimetableEmployeeRole:{
		shiftGroups:{
			filter:"Contember_TimetableShiftGroupWhere",
			orderBy:"Contember_TimetableShiftGroupOrderBy"
		},
		shiftsProfiles:{
			filter:"Contember_ShiftsProfileWhere",
			orderBy:"Contember_ShiftsProfileOrderBy"
		},
		shiftsManagers:{
			filter:"Contember_ShiftsManagerWhere",
			orderBy:"Contember_ShiftsManagerOrderBy"
		},
		timetableTemplates:{
			filter:"Contember_TimetableTemplateWhere",
			orderBy:"Contember_TimetableTemplateOrderBy"
		},
		shiftGroupsByShifts:{
			by:"Contember_TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere",
			filter:"Contember_TimetableShiftGroupWhere"
		},
		shiftGroupsByQueue:{
			by:"Contember_TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere",
			filter:"Contember_TimetableShiftGroupWhere"
		},
		timetableTemplatesByStaffOptions:{
			by:"Contember_TimetableEmployeeRoleTimetableTemplatesByStaffOptionsUniqueWhere",
			filter:"Contember_TimetableTemplateWhere"
		},
		paginateShiftGroups:{
			filter:"Contember_TimetableShiftGroupWhere",
			orderBy:"Contember_TimetableShiftGroupOrderBy"
		},
		paginateShiftsProfiles:{
			filter:"Contember_ShiftsProfileWhere",
			orderBy:"Contember_ShiftsProfileOrderBy"
		},
		paginateShiftsManagers:{
			filter:"Contember_ShiftsManagerWhere",
			orderBy:"Contember_ShiftsManagerOrderBy"
		},
		paginateTimetableTemplates:{
			filter:"Contember_TimetableTemplateWhere",
			orderBy:"Contember_TimetableTemplateOrderBy"
		}
	},
	Contember_ShiftsManager:{
		staff:{
			filter:"Contember_StaffWhere"
		},
		managedRoles:{
			filter:"Contember_TimetableEmployeeRoleWhere",
			orderBy:"Contember_TimetableEmployeeRoleOrderBy"
		},
		paginateManagedRoles:{
			filter:"Contember_TimetableEmployeeRoleWhere",
			orderBy:"Contember_TimetableEmployeeRoleOrderBy"
		}
	},
	Contember_TimetableTemplate:{
		position:{
			filter:"Contember_TimetableShiftPositionWhere"
		},
		staffOptions:{
			filter:"Contember_TimetableTemplateStaffOptionsWhere",
			orderBy:"Contember_TimetableTemplateStaffOptionsOrderBy"
		},
		venue:{
			filter:"Contember_VenueWhere"
		},
		employeeRole:{
			filter:"Contember_TimetableEmployeeRoleWhere"
		},
		paginateStaffOptions:{
			filter:"Contember_TimetableTemplateStaffOptionsWhere",
			orderBy:"Contember_TimetableTemplateStaffOptionsOrderBy"
		}
	},
	Contember_TimetableShiftPosition:{
		shiftGroups:{
			filter:"Contember_TimetableShiftGroupWhere",
			orderBy:"Contember_TimetableShiftGroupOrderBy"
		},
		timetableTemplates:{
			filter:"Contember_TimetableTemplateWhere",
			orderBy:"Contember_TimetableTemplateOrderBy"
		},
		shiftGroupsByShifts:{
			by:"Contember_TimetableShiftPositionShiftGroupsByShiftsUniqueWhere",
			filter:"Contember_TimetableShiftGroupWhere"
		},
		shiftGroupsByQueue:{
			by:"Contember_TimetableShiftPositionShiftGroupsByQueueUniqueWhere",
			filter:"Contember_TimetableShiftGroupWhere"
		},
		timetableTemplatesByStaffOptions:{
			by:"Contember_TimetableShiftPositionTimetableTemplatesByStaffOptionsUniqueWhere",
			filter:"Contember_TimetableTemplateWhere"
		},
		paginateShiftGroups:{
			filter:"Contember_TimetableShiftGroupWhere",
			orderBy:"Contember_TimetableShiftGroupOrderBy"
		},
		paginateTimetableTemplates:{
			filter:"Contember_TimetableTemplateWhere",
			orderBy:"Contember_TimetableTemplateOrderBy"
		}
	},
	Contember_TimetableShiftPositionShiftGroupsByShiftsUniqueWhere:{
		shifts:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_TimetableShiftPositionShiftGroupsByQueueUniqueWhere:{
		queue:"Contember_EmployeeQueueUniqueWhere"
	},
	Contember_TimetableShiftPositionTimetableTemplatesByStaffOptionsUniqueWhere:{
		staffOptions:"Contember_TimetableTemplateStaffOptionsUniqueWhere"
	},
	Contember_TimetableTemplateStaffOptionsUniqueWhere:{
		id:"UUID"
	},
	Contember_TimetableTemplateStaffOptions:{
		timetableTemplate:{
			filter:"Contember_TimetableTemplateWhere"
		},
		autoAssignedEmployee:{
			filter:"Contember_ShiftsProfileWhere"
		}
	},
	Contember_TimetableTemplateStaffOptionsOrderBy:{
		id:"Contember_OrderDirection",
		startTime:"Contember_OrderDirection",
		endTime:"Contember_OrderDirection",
		timetableTemplate:"Contember_TimetableTemplateOrderBy",
		autoAssignedEmployee:"Contember_ShiftsProfileOrderBy"
	},
	Contember_TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere:{
		shifts:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere:{
		queue:"Contember_EmployeeQueueUniqueWhere"
	},
	Contember_TimetableEmployeeRoleTimetableTemplatesByStaffOptionsUniqueWhere:{
		staffOptions:"Contember_TimetableTemplateStaffOptionsUniqueWhere"
	},
	Contember_ShiftsProfileQueueItemsByQueueUniqueWhere:{
		queue:"Contember_EmployeeQueueUniqueWhere"
	},
	Contember_ShiftsProfileQueueItemsByShiftUniqueWhere:{
		shift:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_ShiftsProfileQueueItemsByUnassignInfoUniqueWhere:{
		unassignInfo:"Contember_UnassignedShiftInfoUniqueWhere"
	},
	Contember_ShiftsProfileQueueItemsByMetaUniqueWhere:{
		meta:"Contember_EmployeeQueueItemMetadataUniqueWhere"
	},
	Contember_ShiftsProfileShiftsByQueueItemUniqueWhere:{
		queueItem:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_ShiftsProfileShiftsBySwapInfoUniqueWhere:{
		swapInfo:"Contember_TimetableShiftSwapInfoUniqueWhere"
	},
	Contember_TimetableShiftSwapInfo:{
		swappedShiftsProfile:{
			filter:"Contember_ShiftsProfileWhere"
		},
		shift:{
			filter:"Contember_TimetableShiftWhere"
		}
	},
	Contember_TimetableShiftGroupShiftsByQueueItemUniqueWhere:{
		queueItem:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_TimetableShiftGroupShiftsBySwapInfoUniqueWhere:{
		swapInfo:"Contember_TimetableShiftSwapInfoUniqueWhere"
	},
	Contember_TimetableDayNote:{
		day:{
			filter:"Contember_TimetableDayWhere"
		},
		role:{
			filter:"Contember_TimetableEmployeeRoleWhere"
		}
	},
	Contember_TimetableDayNoteOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		day:"Contember_TimetableDayOrderBy",
		role:"Contember_TimetableEmployeeRoleOrderBy"
	},
	Contember_TimetableDayMetadata:{
		day:{
			filter:"Contember_TimetableDayWhere"
		}
	},
	Contember_TimetableDayShiftGroupsByShiftsUniqueWhere:{
		shifts:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_TimetableDayShiftGroupsByQueueUniqueWhere:{
		queue:"Contember_EmployeeQueueUniqueWhere"
	},
	Contember_VenueEmployeeRole:{
		employeePrivileges:{
			filter:"Contember_TimetablesEmployeePrivilegesWhere"
		},
		venue:{
			filter:"Contember_VenueWhere"
		},
		role:{
			filter:"Contember_TimetableEmployeeRoleWhere"
		}
	},
	Contember_TimetablesEmployeePrivileges:{
		venueEmployeeRole:{
			filter:"Contember_VenueEmployeeRoleWhere"
		}
	},
	Contember_VenueEmployeeRoleOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		employeePrivileges:"Contember_TimetablesEmployeePrivilegesOrderBy",
		venue:"Contember_VenueOrderBy",
		role:"Contember_TimetableEmployeeRoleOrderBy",
		getsTipsShare:"Contember_OrderDirection",
		notesEnabled:"Contember_OrderDirection"
	},
	Contember_TimetablesEmployeePrivilegesOrderBy:{
		id:"Contember_OrderDirection",
		venueEmployeeRole:"Contember_VenueEmployeeRoleOrderBy",
		read:"Contember_OrderDirection",
		unassign:"Contember_OrderDirection",
		assign:"Contember_OrderDirection"
	},
	Contember_PointOfSaleApplicationDevice:{
		venue:{
			filter:"Contember_VenueWhere"
		},
		identificationCode:{
			filter:"Contember_IdentificationCodeWhere"
		},
		identifier:{
			filter:"Contember_DeviceIdentifierWhere"
		},
		terminal:{
			filter:"Contember_PointOfSaleTerminalWhere"
		},
		receiptPrinter:{
			filter:"Contember_PointOfSalePrinterWhere"
		},
		ticketPrinters:{
			filter:"Contember_PointOfSalePrinterWhere",
			orderBy:"Contember_PointOfSalePrinterOrderBy"
		},
		paginateTicketPrinters:{
			filter:"Contember_PointOfSalePrinterWhere",
			orderBy:"Contember_PointOfSalePrinterOrderBy"
		}
	},
	Contember_IdentificationCode:{
		attempts:{
			filter:"Contember_IdentificationAttemptWhere",
			orderBy:"Contember_IdentificationAttemptOrderBy"
		},
		locales:{
			filter:"Contember_IdentificationCodeLocaleWhere",
			orderBy:"Contember_IdentificationCodeLocaleOrderBy"
		},
		applicationDevices:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		localesByLocale:{
			by:"Contember_IdentificationCodeLocalesByLocaleUniqueWhere",
			filter:"Contember_IdentificationCodeLocaleWhere"
		},
		applicationDevicesByIdentifier:{
			by:"Contember_IdentificationCodeApplicationDevicesByIdentifierUniqueWhere",
			filter:"Contember_PointOfSaleApplicationDeviceWhere"
		},
		paginateAttempts:{
			filter:"Contember_IdentificationAttemptWhere",
			orderBy:"Contember_IdentificationAttemptOrderBy"
		},
		paginateLocales:{
			filter:"Contember_IdentificationCodeLocaleWhere",
			orderBy:"Contember_IdentificationCodeLocaleOrderBy"
		},
		paginateApplicationDevices:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		}
	},
	Contember_IdentificationAttempt:{
		code:{
			filter:"Contember_IdentificationCodeWhere"
		},
		user:{
			filter:"Contember_UserWhere"
		}
	},
	Contember_User:{
		staff:{
			filter:"Contember_StaffWhere"
		},
		customer:{
			filter:"Contember_CustomerWhere"
		},
		devices:{
			filter:"Contember_UserDeviceWhere",
			orderBy:"Contember_UserDeviceOrderBy"
		},
		meta:{
			filter:"Contember_UserMetadataWhere"
		},
		identificationAttempts:{
			filter:"Contember_IdentificationAttemptWhere",
			orderBy:"Contember_IdentificationAttemptOrderBy"
		},
		roles:{
			filter:"Contember_UserRolesWhere"
		},
		devicesByDeviceId:{
			by:"Contember_UserDevicesByDeviceIdUniqueWhere",
			filter:"Contember_UserDeviceWhere"
		},
		paginateDevices:{
			filter:"Contember_UserDeviceWhere",
			orderBy:"Contember_UserDeviceOrderBy"
		},
		paginateIdentificationAttempts:{
			filter:"Contember_IdentificationAttemptWhere",
			orderBy:"Contember_IdentificationAttemptOrderBy"
		}
	},
	Contember_UserDevice:{
		user:{
			filter:"Contember_UserWhere"
		},
		anonymousSession:{
			filter:"Contember_AnonymousSessionWhere"
		}
	},
	Contember_AnonymousSession:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		devices:{
			filter:"Contember_UserDeviceWhere",
			orderBy:"Contember_UserDeviceOrderBy"
		},
		devicesByDeviceId:{
			by:"Contember_AnonymousSessionDevicesByDeviceIdUniqueWhere",
			filter:"Contember_UserDeviceWhere"
		},
		paginateDevices:{
			filter:"Contember_UserDeviceWhere",
			orderBy:"Contember_UserDeviceOrderBy"
		}
	},
	Contember_UserDeviceOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		lastActiveAt:"Contember_OrderDirection",
		platform:"Contember_OrderDirection",
		deviceId:"Contember_OrderDirection",
		notificationsEnabled:"Contember_OrderDirection",
		user:"Contember_UserOrderBy",
		appVersion:"Contember_OrderDirection",
		osVersion:"Contember_OrderDirection",
		apiVersion:"Contember_OrderDirection",
		anonymousSession:"Contember_AnonymousSessionOrderBy"
	},
	Contember_AnonymousSessionDevicesByDeviceIdUniqueWhere:{

	},
	Contember_UserMetadata:{
		user:{
			filter:"Contember_UserWhere"
		}
	},
	Contember_IdentificationAttemptOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		code:"Contember_IdentificationCodeOrderBy",
		user:"Contember_UserOrderBy"
	},
	Contember_IdentificationCodeOrderBy:{
		id:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection"
	},
	Contember_UserRoles:{
		user:{
			filter:"Contember_UserWhere"
		}
	},
	Contember_UserDevicesByDeviceIdUniqueWhere:{

	},
	Contember_IdentificationCodeLocale:{
		root:{
			filter:"Contember_IdentificationCodeWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_IdentificationCodeLocaleOrderBy:{
		id:"Contember_OrderDirection",
		successMessage:"Contember_OrderDirection",
		root:"Contember_IdentificationCodeOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_PointOfSaleApplicationDeviceOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy",
		identificationCode:"Contember_IdentificationCodeOrderBy",
		identifier:"Contember_DeviceIdentifierOrderBy",
		terminal:"Contember_PointOfSaleTerminalOrderBy",
		receiptPrinter:"Contember_PointOfSalePrinterOrderBy"
	},
	Contember_DeviceIdentifierOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		device:"Contember_PointOfSaleApplicationDeviceOrderBy",
		updatedAt:"Contember_OrderDirection",
		platform:"Contember_OrderDirection",
		resolution:"Contember_OrderDirection",
		devicePixelRatio:"Contember_OrderDirection",
		updatedByUser:"Contember_UserOrderBy",
		identifier:"Contember_OrderDirection",
		meta:"Contember_DeviceIdentifierMetadataOrderBy"
	},
	Contember_DeviceIdentifierMetadataOrderBy:{
		id:"Contember_OrderDirection",
		prettyIdentifier:"Contember_OrderDirection",
		deviceIdentifier:"Contember_DeviceIdentifierOrderBy"
	},
	Contember_PointOfSaleTerminalOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy",
		ipAddress:"Contember_OrderDirection",
		port:"Contember_OrderDirection",
		type:"Contember_OrderDirection"
	},
	Contember_PointOfSalePrinterOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		ipAddress:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy"
	},
	Contember_IdentificationCodeLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_IdentificationCodeApplicationDevicesByIdentifierUniqueWhere:{
		identifier:"Contember_DeviceIdentifierUniqueWhere"
	},
	Contember_DeviceIdentifier:{
		device:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere"
		},
		updatedByUser:{
			filter:"Contember_UserWhere"
		},
		meta:{
			filter:"Contember_DeviceIdentifierMetadataWhere"
		}
	},
	Contember_DeviceIdentifierMetadata:{
		deviceIdentifier:{
			filter:"Contember_DeviceIdentifierWhere"
		}
	},
	Contember_PointOfSaleTerminal:{
		venue:{
			filter:"Contember_VenueWhere"
		},
		applicationDevices:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		applicationDevicesByIdentifier:{
			by:"Contember_PointOfSaleTerminalApplicationDevicesByIdentifierUniqueWhere",
			filter:"Contember_PointOfSaleApplicationDeviceWhere"
		},
		paginateApplicationDevices:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		}
	},
	Contember_PointOfSaleTerminalApplicationDevicesByIdentifierUniqueWhere:{
		identifier:"Contember_DeviceIdentifierUniqueWhere"
	},
	Contember_PointOfSalePrinter:{
		applicationDevicesReceipt:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		applicationDevicesTicket:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		venue:{
			filter:"Contember_VenueWhere"
		},
		ticketCategories:{
			filter:"Contember_PointOfSaleCategoryWhere",
			orderBy:"Contember_PointOfSaleCategoryOrderBy"
		},
		applicationDevicesReceiptByIdentifier:{
			by:"Contember_PointOfSalePrinterApplicationDevicesReceiptByIdentifierUniqueWhere",
			filter:"Contember_PointOfSaleApplicationDeviceWhere"
		},
		paginateApplicationDevicesReceipt:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		paginateApplicationDevicesTicket:{
			filter:"Contember_PointOfSaleApplicationDeviceWhere",
			orderBy:"Contember_PointOfSaleApplicationDeviceOrderBy"
		},
		paginateTicketCategories:{
			filter:"Contember_PointOfSaleCategoryWhere",
			orderBy:"Contember_PointOfSaleCategoryOrderBy"
		}
	},
	Contember_PointOfSaleCategory:{
		locales:{
			filter:"Contember_PointOfSaleCategoryLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryLocaleOrderBy"
		},
		products:{
			filter:"Contember_PointOfSaleCategoryProductWhere",
			orderBy:"Contember_PointOfSaleCategoryProductOrderBy"
		},
		category:{
			filter:"Contember_ProductCategoryWhere"
		},
		venue:{
			filter:"Contember_VenueWhere"
		},
		ticketPrinters:{
			filter:"Contember_PointOfSalePrinterWhere",
			orderBy:"Contember_PointOfSalePrinterOrderBy"
		},
		localesByLocale:{
			by:"Contember_PointOfSaleCategoryLocalesByLocaleUniqueWhere",
			filter:"Contember_PointOfSaleCategoryLocaleWhere"
		},
		productsByLocales:{
			by:"Contember_PointOfSaleCategoryProductsByLocalesUniqueWhere",
			filter:"Contember_PointOfSaleCategoryProductWhere"
		},
		paginateLocales:{
			filter:"Contember_PointOfSaleCategoryLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryLocaleOrderBy"
		},
		paginateProducts:{
			filter:"Contember_PointOfSaleCategoryProductWhere",
			orderBy:"Contember_PointOfSaleCategoryProductOrderBy"
		},
		paginateTicketPrinters:{
			filter:"Contember_PointOfSalePrinterWhere",
			orderBy:"Contember_PointOfSalePrinterOrderBy"
		}
	},
	Contember_PointOfSaleCategoryLocale:{
		root:{
			filter:"Contember_PointOfSaleCategoryWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_PointOfSaleCategoryLocaleOrderBy:{
		id:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		root:"Contember_PointOfSaleCategoryOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_PointOfSaleCategoryOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		slug:"Contember_OrderDirection",
		category:"Contember_ProductCategoryOrderBy",
		venue:"Contember_VenueOrderBy"
	},
	Contember_ProductCategoryOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		parent:"Contember_ProductCategoryOrderBy",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		color:"Contember_OrderDirection",
		image:"Contember_ImageOrderBy",
		order:"Contember_OrderDirection"
	},
	Contember_PointOfSaleCategoryProduct:{
		category:{
			filter:"Contember_PointOfSaleCategoryWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		},
		locales:{
			filter:"Contember_PointOfSaleCategoryProductLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryProductLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_PointOfSaleCategoryProductLocalesByLocaleUniqueWhere",
			filter:"Contember_PointOfSaleCategoryProductLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_PointOfSaleCategoryProductLocaleWhere",
			orderBy:"Contember_PointOfSaleCategoryProductLocaleOrderBy"
		}
	},
	Contember_PointOfSaleCategoryProductLocale:{
		root:{
			filter:"Contember_PointOfSaleCategoryProductWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_PointOfSaleCategoryProductLocaleOrderBy:{
		id:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		root:"Contember_PointOfSaleCategoryProductOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_PointOfSaleCategoryProductOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		category:"Contember_PointOfSaleCategoryOrderBy",
		product:"Contember_ProductOrderBy"
	},
	Contember_PointOfSaleCategoryProductLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_ProductCategory:{
		products:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		tags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		parent:{
			filter:"Contember_ProductCategoryWhere"
		},
		children:{
			filter:"Contember_ProductCategoryWhere",
			orderBy:"Contember_ProductCategoryOrderBy"
		},
		image:{
			filter:"Contember_ImageWhere"
		},
		descendants:{
			filter:"Contember_ProductCategoryTreeWhere",
			orderBy:"Contember_ProductCategoryTreeOrderBy"
		},
		ancestors:{
			filter:"Contember_ProductCategoryTreeWhere",
			orderBy:"Contember_ProductCategoryTreeOrderBy"
		},
		inheritedTags:{
			filter:"Contember_InheritedProductCategoryTagWhere",
			orderBy:"Contember_InheritedProductCategoryTagOrderBy"
		},
		nestedProducts:{
			filter:"Contember_NestedProductCategoryProductWhere",
			orderBy:"Contember_NestedProductCategoryProductOrderBy"
		},
		childrenByCode:{
			by:"Contember_ProductCategoryChildrenByCodeUniqueWhere",
			filter:"Contember_ProductCategoryWhere"
		},
		childrenByChildren:{
			by:"Contember_ProductCategoryChildrenByChildrenUniqueWhere",
			filter:"Contember_ProductCategoryWhere"
		},
		childrenByDescendants:{
			by:"Contember_ProductCategoryChildrenByDescendantsUniqueWhere",
			filter:"Contember_ProductCategoryWhere"
		},
		childrenByAncestors:{
			by:"Contember_ProductCategoryChildrenByAncestorsUniqueWhere",
			filter:"Contember_ProductCategoryWhere"
		},
		childrenByInheritedTags:{
			by:"Contember_ProductCategoryChildrenByInheritedTagsUniqueWhere",
			filter:"Contember_ProductCategoryWhere"
		},
		childrenByNestedProducts:{
			by:"Contember_ProductCategoryChildrenByNestedProductsUniqueWhere",
			filter:"Contember_ProductCategoryWhere"
		},
		paginateProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		paginateTags:{
			filter:"Contember_TagWhere",
			orderBy:"Contember_TagOrderBy"
		},
		paginateChildren:{
			filter:"Contember_ProductCategoryWhere",
			orderBy:"Contember_ProductCategoryOrderBy"
		},
		paginateDescendants:{
			filter:"Contember_ProductCategoryTreeWhere",
			orderBy:"Contember_ProductCategoryTreeOrderBy"
		},
		paginateAncestors:{
			filter:"Contember_ProductCategoryTreeWhere",
			orderBy:"Contember_ProductCategoryTreeOrderBy"
		},
		paginateInheritedTags:{
			filter:"Contember_InheritedProductCategoryTagWhere",
			orderBy:"Contember_InheritedProductCategoryTagOrderBy"
		},
		paginateNestedProducts:{
			filter:"Contember_NestedProductCategoryProductWhere",
			orderBy:"Contember_NestedProductCategoryProductOrderBy"
		}
	},
	Contember_ProductCategoryTree:{
		ancestor:{
			filter:"Contember_ProductCategoryWhere"
		},
		descendant:{
			filter:"Contember_ProductCategoryWhere"
		}
	},
	Contember_ProductCategoryTreeOrderBy:{
		id:"Contember_OrderDirection",
		depth:"Contember_OrderDirection",
		ancestor:"Contember_ProductCategoryOrderBy",
		descendant:"Contember_ProductCategoryOrderBy"
	},
	Contember_InheritedProductCategoryTag:{
		productCategory:{
			filter:"Contember_ProductCategoryWhere"
		},
		tag:{
			filter:"Contember_TagWhere"
		}
	},
	Contember_InheritedProductCategoryTagOrderBy:{
		id:"Contember_OrderDirection",
		depth:"Contember_OrderDirection",
		productCategory:"Contember_ProductCategoryOrderBy",
		tag:"Contember_TagOrderBy"
	},
	Contember_NestedProductCategoryProduct:{
		product:{
			filter:"Contember_ProductWhere"
		},
		productCategory:{
			filter:"Contember_ProductCategoryWhere"
		}
	},
	Contember_NestedProductCategoryProductOrderBy:{
		id:"Contember_OrderDirection",
		depth:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy",
		productCategory:"Contember_ProductCategoryOrderBy"
	},
	Contember_ProductCategoryChildrenByCodeUniqueWhere:{

	},
	Contember_ProductCategoryChildrenByChildrenUniqueWhere:{
		children:"Contember_ProductCategoryUniqueWhere"
	},
	Contember_ProductCategoryUniqueWhere:{
		id:"UUID",
		children:"Contember_ProductCategoryUniqueWhere",
		descendants:"Contember_ProductCategoryTreeUniqueWhere",
		ancestors:"Contember_ProductCategoryTreeUniqueWhere",
		inheritedTags:"Contember_InheritedProductCategoryTagUniqueWhere",
		nestedProducts:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_ProductCategoryTreeUniqueWhere:{
		id:"UUID"
	},
	Contember_InheritedProductCategoryTagUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductCategoryChildrenByDescendantsUniqueWhere:{
		descendants:"Contember_ProductCategoryTreeUniqueWhere"
	},
	Contember_ProductCategoryChildrenByAncestorsUniqueWhere:{
		ancestors:"Contember_ProductCategoryTreeUniqueWhere"
	},
	Contember_ProductCategoryChildrenByInheritedTagsUniqueWhere:{
		inheritedTags:"Contember_InheritedProductCategoryTagUniqueWhere"
	},
	Contember_ProductCategoryChildrenByNestedProductsUniqueWhere:{
		nestedProducts:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_PointOfSaleCategoryLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_PointOfSaleCategoryProductsByLocalesUniqueWhere:{
		locales:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere"
	},
	Contember_PointOfSalePrinterApplicationDevicesReceiptByIdentifierUniqueWhere:{
		identifier:"Contember_DeviceIdentifierUniqueWhere"
	},
	OrderDiscount:{
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		order:{
			filter:"Contember_OrderWhere"
		},
		customerCreditTransactions:{
			filter:"Contember_CustomerCreditTransactionWhere",
			orderBy:"Contember_CustomerCreditTransactionOrderBy"
		},
		voucherRedemption:{
			filter:"Contember_VoucherRedemptionWhere"
		},
		customerCreditTransactionsByVirtualProductEffect:{
			by:"Contember_OrderDiscountCustomerCreditTransactionsByVirtualProductEffectUniqueWhere",
			filter:"Contember_CustomerCreditTransactionWhere"
		},
		paginateCustomerCreditTransactions:{
			filter:"Contember_CustomerCreditTransactionWhere",
			orderBy:"Contember_CustomerCreditTransactionOrderBy"
		}
	},
	Contember_CustomerCreditTransaction:{
		customerCredit:{
			filter:"Contember_CustomerCreditWhere"
		},
		orderDiscount:{
			filter:"Contember_OrderDiscountWhere"
		},
		virtualProductEffect:{
			filter:"Contember_VirtualProductEffectWhere"
		}
	},
	Contember_CustomerCreditTransactionOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		amountCents:"Contember_OrderDirection",
		customerCredit:"Contember_CustomerCreditOrderBy",
		orderDiscount:"Contember_OrderDiscountOrderBy",
		virtualProductEffect:"Contember_VirtualProductEffectOrderBy"
	},
	Contember_CustomerCreditOrderBy:{
		id:"Contember_OrderDirection",
		initialCreditCents:"Contember_OrderDirection",
		remainingCreditsCents:"Contember_OrderDirection",
		expiresAt:"Contember_OrderDirection",
		reason:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		vatRate:"Contember_VatRateOrderBy",
		createdAt:"Contember_OrderDirection",
		voucherRedemption:"Contember_VoucherRedemptionOrderBy",
		invoiceUrl:"Contember_OrderDirection"
	},
	Contember_VoucherRedemptionOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		manualRedemptionNote:"Contember_OrderDirection",
		customerCredit:"Contember_CustomerCreditOrderBy",
		orderDiscount:"Contember_OrderDiscountOrderBy",
		voucher:"Contember_VoucherOrderBy",
		customer:"Contember_CustomerOrderBy"
	},
	Contember_OrderDiscountOrderBy:{
		id:"Contember_OrderDirection",
		discountCents:"Contember_OrderDirection",
		vatRate:"Contember_VatRateOrderBy",
		order:"Contember_OrderOrderBy",
		description:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		voucherRedemption:"Contember_VoucherRedemptionOrderBy"
	},
	Contember_OrderOrderBy:{
		id:"Contember_OrderDirection",
		state:"Contember_OrderDirection",
		fulfilledAt:"Contember_OrderDirection",
		canceledAt:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		channel:"Contember_ChannelOrderBy",
		delivery:"Contember_OrderDeliveryOrderBy",
		internalNote:"Contember_OrderDirection",
		cart:"Contember_CartOrderBy",
		createdAt:"Contember_OrderDirection",
		billingAddress:"Contember_AddressOrderBy",
		seq:"Contember_OrderDirection",
		billingSubject:"Contember_BillingSubjectOrderBy",
		summary:"Contember_OrderSummaryOrderBy",
		doubleshotLegacyId:"Contember_OrderDirection",
		gratuity:"Contember_GratuityOrderBy",
		venue:"Contember_VenueOrderBy",
		fulfillmentNote:"Contember_OrderDirection",
		doubleshotTotalWeightGrams:"Contember_OrderDirection",
		doubleShotLegacyNumber:"Contember_OrderDirection",
		doubleshotLastUpdatedAt:"Contember_OrderDirection",
		doubleshotAdminUrl:"Contember_OrderDirection"
	},
	Contember_OrderDeliveryOrderBy:{
		id:"Contember_OrderDirection",
		priceCents:"Contember_OrderDirection",
		deliveredAt:"Contember_OrderDirection",
		method:"Contember_DeliveryMethodOrderBy",
		vatRate:"Contember_VatRateOrderBy",
		order:"Contember_OrderOrderBy",
		expectedAt:"Contember_OrderDirection",
		state:"Contember_OrderDirection",
		loadingDurationHours:"Contember_OrderDirection",
		dispatchAt:"Contember_OrderDirection",
		duration:"Contember_OrderDirection",
		window:"Contember_OrderDirection",
		expectedEndAt:"Contember_OrderDirection",
		processingAt:"Contember_OrderDirection",
		deliveringAt:"Contember_OrderDirection",
		failedAt:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		deliveryWaypoint:"Contember_DeliveryWaypointOrderBy",
		prepareAt:"Contember_OrderDirection",
		liftagoRide:"Contember_LiftagoRideOrderBy",
		pickedAt:"Contember_OrderDirection",
		address:"Contember_AddressOrderBy",
		trackingCode:"Contember_OrderDirection"
	},
	Contember_DeliveryWaypointOrderBy:{
		id:"Contember_OrderDirection",
		estimatedArrival:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		route:"Contember_DeliveryRouteOrderBy",
		sortingOrder:"Contember_OrderDirection",
		delivery:"Contember_OrderDeliveryOrderBy",
		estimatedDistance:"Contember_OrderDirection",
		estimatedDuration:"Contember_OrderDirection",
		originDeparture:"Contember_OrderDirection",
		originAddress:"Contember_OrderDirection",
		transitMode:"Contember_OrderDirection"
	},
	Contember_DeliveryRouteOrderBy:{
		id:"Contember_OrderDirection",
		courier:"Contember_CourierOrderBy",
		createdAt:"Contember_OrderDirection",
		dispatchAt:"Contember_OrderDirection"
	},
	Contember_LiftagoRideOrderBy:{
		id:"Contember_OrderDirection",
		liftagoId:"Contember_OrderDirection",
		data:"Contember_OrderDirection",
		updatedAt:"Contember_OrderDirection",
		delivery:"Contember_OrderDeliveryOrderBy"
	},
	Contember_CartOrderBy:{
		id:"Contember_OrderDirection",
		confirmedAt:"Contember_OrderDirection",
		deletedAt:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		handle:"Contember_OrderDirection",
		state:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		channel:"Contember_ChannelOrderBy",
		order:"Contember_OrderOrderBy",
		customer:"Contember_CustomerOrderBy",
		deliveryAddress:"Contember_AddressOrderBy",
		billingAddress:"Contember_AddressOrderBy",
		deliveryOption:"Contember_OrderDirection",
		paymentOption:"Contember_OrderDirection",
		delivery:"Contember_ChannelDeliveryOrderBy",
		payment:"Contember_ChannelPaymentOrderBy",
		billingSubject:"Contember_BillingSubjectOrderBy",
		summary:"Contember_CartSummaryOrderBy"
	},
	Contember_CartSummaryOrderBy:{
		id:"Contember_OrderDirection",
		itemsPriceCents:"Contember_OrderDirection",
		deliveryPriceCents:"Contember_OrderDirection",
		totalPriceCents:"Contember_OrderDirection",
		itemsCount:"Contember_OrderDirection",
		itemsQuantity:"Contember_OrderDirection",
		checkoutUrl:"Contember_OrderDirection",
		cart:"Contember_CartOrderBy"
	},
	Contember_OrderSummaryOrderBy:{
		id:"Contember_OrderDirection",
		totalPriceCents:"Contember_OrderDirection",
		discountPriceCents:"Contember_OrderDirection",
		undiscountedPriceCents:"Contember_OrderDirection",
		itemsQuantity:"Contember_OrderDirection",
		orderUrl:"Contember_OrderDirection",
		orderNumber:"Contember_OrderDirection",
		order:"Contember_OrderOrderBy"
	},
	Contember_GratuityOrderBy:{
		id:"Contember_OrderDirection",
		amountCents:"Contember_OrderDirection",
		order:"Contember_OrderOrderBy"
	},
	Contember_VoucherOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		activatedAt:"Contember_OrderDirection",
		expiresAt:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		creditCents:"Contember_OrderDirection",
		buyer:"Contember_CustomerOrderBy",
		owner:"Contember_CustomerOrderBy",
		vatRate:"Contember_VatRateOrderBy",
		virtualProductEffect:"Contember_VirtualProductEffectOrderBy",
		redemption:"Contember_VoucherRedemptionOrderBy"
	},
	Contember_VirtualProductEffectOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		succeededAt:"Contember_OrderDirection",
		orderItem:"Contember_OrderItemOrderBy",
		failedAt:"Contember_OrderDirection",
		revertedAt:"Contember_OrderDirection",
		resultCustomerCreditTransaction:"Contember_CustomerCreditTransactionOrderBy"
	},
	Contember_OrderItemOrderBy:{
		id:"Contember_OrderDirection",
		unitPriceCents:"Contember_OrderDirection",
		quantity:"Contember_OrderDirection",
		vatRate:"Contember_VatRateOrderBy",
		order:"Contember_OrderOrderBy",
		pickedAt:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy"
	},
	Contember_VoucherRedemption:{
		customerCredit:{
			filter:"Contember_CustomerCreditWhere"
		},
		orderDiscount:{
			filter:"Contember_OrderDiscountWhere"
		},
		voucher:{
			filter:"Contember_VoucherWhere"
		},
		customer:{
			filter:"Contember_CustomerWhere"
		}
	},
	Contember_Voucher:{
		buyer:{
			filter:"Contember_CustomerWhere"
		},
		owner:{
			filter:"Contember_CustomerWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		virtualProductEffect:{
			filter:"Contember_VirtualProductEffectWhere"
		},
		redemption:{
			filter:"Contember_VoucherRedemptionWhere"
		}
	},
	Contember_VirtualProductEffect:{
		orderItem:{
			filter:"Contember_OrderItemWhere"
		},
		resultCustomerCreditTransaction:{
			filter:"Contember_CustomerCreditTransactionWhere"
		},
		resultVouchers:{
			filter:"Contember_VoucherWhere",
			orderBy:"Contember_VoucherOrderBy"
		},
		resultVouchersByCode:{
			by:"Contember_VirtualProductEffectResultVouchersByCodeUniqueWhere",
			filter:"Contember_VoucherWhere"
		},
		resultVouchersByRedemption:{
			by:"Contember_VirtualProductEffectResultVouchersByRedemptionUniqueWhere",
			filter:"Contember_VoucherWhere"
		},
		paginateResultVouchers:{
			filter:"Contember_VoucherWhere",
			orderBy:"Contember_VoucherOrderBy"
		}
	},
	OrderItem:{
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		order:{
			filter:"Contember_OrderWhere"
		},
		virtualProductEffects:{
			filter:"Contember_VirtualProductEffectWhere",
			orderBy:"Contember_VirtualProductEffectOrderBy"
		},
		fulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		},
		product:{
			filter:"Contember_ProductWhere"
		},
		virtualProductEffectsByResultCustomerCreditTransaction:{
			by:"Contember_OrderItemVirtualProductEffectsByResultCustomerCreditTransactionUniqueWhere",
			filter:"Contember_VirtualProductEffectWhere"
		},
		virtualProductEffectsByResultVouchers:{
			by:"Contember_OrderItemVirtualProductEffectsByResultVouchersUniqueWhere",
			filter:"Contember_VirtualProductEffectWhere"
		},
		paginateVirtualProductEffects:{
			filter:"Contember_VirtualProductEffectWhere",
			orderBy:"Contember_VirtualProductEffectOrderBy"
		},
		paginateFulfillmentNotes:{
			filter:"Contember_FulfillmentNoteWhere",
			orderBy:"Contember_FulfillmentNoteOrderBy"
		}
	},
	Contember_FulfillmentNoteOrderBy:{
		id:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		highlight:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		emoji:"Contember_OrderDirection"
	},
	Contember_OrderItemVirtualProductEffectsByResultCustomerCreditTransactionUniqueWhere:{
		resultCustomerCreditTransaction:"Contember_CustomerCreditTransactionUniqueWhere"
	},
	Contember_OrderItemVirtualProductEffectsByResultVouchersUniqueWhere:{
		resultVouchers:"Contember_VoucherUniqueWhere"
	},
	Contember_VirtualProductEffectResultVouchersByCodeUniqueWhere:{

	},
	Contember_VirtualProductEffectResultVouchersByRedemptionUniqueWhere:{
		redemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_CustomerCreditTransactionsByVirtualProductEffectUniqueWhere:{
		virtualProductEffect:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_OrderDiscountCustomerCreditTransactionsByVirtualProductEffectUniqueWhere:{
		virtualProductEffect:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_OrderEvent:{
		order:{
			filter:"Contember_OrderWhere"
		},
		payment:{
			filter:"Contember_OrderPaymentWhere"
		},
		delivery:{
			filter:"Contember_OrderDeliveryWhere"
		}
	},
	Contember_OrderEventOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		data:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		order:"Contember_OrderOrderBy",
		payment:"Contember_OrderPaymentOrderBy",
		delivery:"Contember_OrderDeliveryOrderBy"
	},
	Contember_OrderPaymentOrderBy:{
		id:"Contember_OrderDirection",
		data:"Contember_OrderDirection",
		paymentCents:"Contember_OrderDirection",
		method:"Contember_PaymentMethodOrderBy",
		failedAt:"Contember_OrderDirection",
		state:"Contember_OrderDirection",
		approvedAt:"Contember_OrderDirection",
		order:"Contember_OrderOrderBy",
		invoice:"Contember_InvoiceOrderBy",
		walletType:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		publicReceiptUrl:"Contember_OrderDirection",
		priceCents:"Contember_OrderDirection",
		vatRate:"Contember_VatRateOrderBy",
		meta:"Contember_OrderPaymentMetadataOrderBy",
		otherCurrencyPriceCents:"Contember_OrderDirection"
	},
	Contember_InvoiceOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		publicKey:"Contember_OrderDirection",
		fakturoidId:"Contember_OrderDirection",
		fakturoidData:"Contember_OrderDirection",
		legacyUrl:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		address:"Contember_AddressOrderBy",
		publicUrl:"Contember_OrderDirection",
		billingSubject:"Contember_BillingSubjectOrderBy"
	},
	Contember_OrderPaymentMetadataOrderBy:{
		id:"Contember_OrderDirection",
		paymentUrl:"Contember_OrderDirection",
		orderPayment:"Contember_OrderPaymentOrderBy"
	},
	Contember_Invoice:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		orderPayments:{
			filter:"Contember_OrderPaymentWhere",
			orderBy:"Contember_OrderPaymentOrderBy"
		},
		address:{
			filter:"Contember_AddressWhere"
		},
		billingSubject:{
			filter:"Contember_BillingSubjectWhere"
		},
		orderPaymentsByEvents:{
			by:"Contember_InvoiceOrderPaymentsByEventsUniqueWhere",
			filter:"Contember_OrderPaymentWhere"
		},
		orderPaymentsByMeta:{
			by:"Contember_InvoiceOrderPaymentsByMetaUniqueWhere",
			filter:"Contember_OrderPaymentWhere"
		},
		paginateOrderPayments:{
			filter:"Contember_OrderPaymentWhere",
			orderBy:"Contember_OrderPaymentOrderBy"
		}
	},
	Contember_BillingSubject:{
		country:{
			filter:"Contember_CountryWhere"
		},
		customer:{
			filter:"Contember_CustomerWhere"
		},
		invoices:{
			filter:"Contember_InvoiceWhere",
			orderBy:"Contember_InvoiceOrderBy"
		},
		defaultBillingSubjectOfCustomer:{
			filter:"Contember_CustomerWhere"
		},
		orders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		},
		invoicesByPublicKey:{
			by:"Contember_BillingSubjectInvoicesByPublicKeyUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		invoicesByFakturoidId:{
			by:"Contember_BillingSubjectInvoicesByFakturoidIdUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		invoicesByOrderPayments:{
			by:"Contember_BillingSubjectInvoicesByOrderPaymentsUniqueWhere",
			filter:"Contember_InvoiceWhere"
		},
		ordersBySeq:{
			by:"Contember_BillingSubjectOrdersBySeqUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDoubleshotLegacyId:{
			by:"Contember_BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDoubleShotLegacyNumber:{
			by:"Contember_BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDiscounts:{
			by:"Contember_BillingSubjectOrdersByDiscountsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByDelivery:{
			by:"Contember_BillingSubjectOrdersByDeliveryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByItems:{
			by:"Contember_BillingSubjectOrdersByItemsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByCart:{
			by:"Contember_BillingSubjectOrdersByCartUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByEvents:{
			by:"Contember_BillingSubjectOrdersByEventsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByPriceLines:{
			by:"Contember_BillingSubjectOrdersByPriceLinesUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByPayments:{
			by:"Contember_BillingSubjectOrdersByPaymentsUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersBySummary:{
			by:"Contember_BillingSubjectOrdersBySummaryUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		ordersByGratuity:{
			by:"Contember_BillingSubjectOrdersByGratuityUniqueWhere",
			filter:"Contember_OrderWhere"
		},
		paginateInvoices:{
			filter:"Contember_InvoiceWhere",
			orderBy:"Contember_InvoiceOrderBy"
		},
		paginateOrders:{
			filter:"Contember_OrderWhere",
			orderBy:"Contember_OrderOrderBy"
		}
	},
	Contember_BillingSubjectInvoicesByPublicKeyUniqueWhere:{

	},
	Contember_BillingSubjectInvoicesByFakturoidIdUniqueWhere:{

	},
	Contember_BillingSubjectInvoicesByOrderPaymentsUniqueWhere:{
		orderPayments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_BillingSubjectOrdersBySeqUniqueWhere:{

	},
	Contember_BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	Contember_BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	Contember_BillingSubjectOrdersByDiscountsUniqueWhere:{
		discounts:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_BillingSubjectOrdersByDeliveryUniqueWhere:{
		delivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_BillingSubjectOrdersByItemsUniqueWhere:{
		items:"Contember_OrderItemUniqueWhere"
	},
	Contember_BillingSubjectOrdersByCartUniqueWhere:{
		cart:"Contember_CartUniqueWhere"
	},
	Contember_BillingSubjectOrdersByEventsUniqueWhere:{
		events:"Contember_OrderEventUniqueWhere"
	},
	Contember_BillingSubjectOrdersByPriceLinesUniqueWhere:{
		priceLines:"Contember_OrderPriceLineUniqueWhere"
	},
	Contember_BillingSubjectOrdersByPaymentsUniqueWhere:{
		payments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_BillingSubjectOrdersBySummaryUniqueWhere:{
		summary:"Contember_OrderSummaryUniqueWhere"
	},
	Contember_BillingSubjectOrdersByGratuityUniqueWhere:{
		gratuity:"Contember_GratuityUniqueWhere"
	},
	Contember_InvoiceOrderPaymentsByEventsUniqueWhere:{
		events:"Contember_OrderEventUniqueWhere"
	},
	Contember_InvoiceOrderPaymentsByMetaUniqueWhere:{
		meta:"Contember_OrderPaymentMetadataUniqueWhere"
	},
	Contember_OrderPaymentMetadata:{
		orderPayment:{
			filter:"Contember_OrderPaymentWhere"
		}
	},
	OrderPriceLine:{
		orderItem:{
			filter:"Contember_OrderItemWhere"
		},
		orderDiscount:{
			filter:"Contember_OrderDiscountWhere"
		},
		orderPayment:{
			filter:"Contember_OrderPaymentWhere"
		},
		orderDelivery:{
			filter:"Contember_OrderDeliveryWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		order:{
			filter:"Contember_OrderWhere"
		}
	},
	Contember_OrderPriceLineOrderBy:{
		id:"Contember_OrderDirection",
		unitPriceCents:"Contember_OrderDirection",
		quantity:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		orderItem:"Contember_OrderItemOrderBy",
		orderDiscount:"Contember_OrderDiscountOrderBy",
		orderPayment:"Contember_OrderPaymentOrderBy",
		orderDelivery:"Contember_OrderDeliveryOrderBy",
		vatRatePermille:"Contember_OrderDirection",
		vatRate:"Contember_VatRateOrderBy",
		order:"Contember_OrderOrderBy"
	},
	OrderSummary:{
		order:{
			filter:"Contember_OrderWhere"
		}
	},
	Contember_Gratuity:{
		order:{
			filter:"Contember_OrderWhere"
		}
	},
	Contember_OrderDiscountsByCustomerCreditTransactionsUniqueWhere:{
		customerCreditTransactions:"Contember_CustomerCreditTransactionUniqueWhere"
	},
	Contember_OrderDiscountsByVoucherRedemptionUniqueWhere:{
		voucherRedemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_OrderItemsByVirtualProductEffectsUniqueWhere:{
		virtualProductEffects:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_OrderPriceLinesByOrderItemUniqueWhere:{
		orderItem:"Contember_OrderItemUniqueWhere"
	},
	Contember_OrderPriceLinesByOrderDiscountUniqueWhere:{
		orderDiscount:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_OrderPriceLinesByOrderPaymentUniqueWhere:{
		orderPayment:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_OrderPriceLinesByOrderDeliveryUniqueWhere:{
		orderDelivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_OrderPaymentsByEventsUniqueWhere:{
		events:"Contember_OrderEventUniqueWhere"
	},
	Contember_OrderPaymentsByMetaUniqueWhere:{
		meta:"Contember_OrderPaymentMetadataUniqueWhere"
	},
	Contember_Area:{
		venue:{
			filter:"Contember_VenueWhere"
		},
		tables:{
			filter:"Contember_TableWhere",
			orderBy:"Contember_TableOrderBy"
		},
		lines:{
			filter:"Contember_LineWhere",
			orderBy:"Contember_LineOrderBy"
		},
		tablesByCustomerTabs:{
			by:"Contember_AreaTablesByCustomerTabsUniqueWhere",
			filter:"Contember_TableWhere"
		},
		tablesBySeats:{
			by:"Contember_AreaTablesBySeatsUniqueWhere",
			filter:"Contember_TableWhere"
		},
		paginateTables:{
			filter:"Contember_TableWhere",
			orderBy:"Contember_TableOrderBy"
		},
		paginateLines:{
			filter:"Contember_LineWhere",
			orderBy:"Contember_LineOrderBy"
		}
	},
	Contember_Table:{
		customerTabs:{
			filter:"Contember_CustomerTabWhere",
			orderBy:"Contember_CustomerTabOrderBy"
		},
		area:{
			filter:"Contember_AreaWhere"
		},
		seats:{
			filter:"Contember_SeatWhere",
			orderBy:"Contember_SeatOrderBy"
		},
		customerTabsByItems:{
			by:"Contember_TableCustomerTabsByItemsUniqueWhere",
			filter:"Contember_CustomerTabWhere"
		},
		customerTabsByMeta:{
			by:"Contember_TableCustomerTabsByMetaUniqueWhere",
			filter:"Contember_CustomerTabWhere"
		},
		seatsByCustomerTabs:{
			by:"Contember_TableSeatsByCustomerTabsUniqueWhere",
			filter:"Contember_SeatWhere"
		},
		paginateCustomerTabs:{
			filter:"Contember_CustomerTabWhere",
			orderBy:"Contember_CustomerTabOrderBy"
		},
		paginateSeats:{
			filter:"Contember_SeatWhere",
			orderBy:"Contember_SeatOrderBy"
		}
	},
	Contember_CustomerTab:{
		items:{
			filter:"Contember_CustomerTabItemWhere",
			orderBy:"Contember_CustomerTabItemOrderBy"
		},
		meta:{
			filter:"Contember_CustomerTabMetadataWhere"
		},
		customer:{
			filter:"Contember_CustomerWhere"
		},
		table:{
			filter:"Contember_TableWhere"
		},
		seat:{
			filter:"Contember_SeatWhere"
		},
		paginateItems:{
			filter:"Contember_CustomerTabItemWhere",
			orderBy:"Contember_CustomerTabItemOrderBy"
		}
	},
	Contember_CustomerTabItem:{
		tab:{
			filter:"Contember_CustomerTabWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_CustomerTabItemOrderBy:{
		id:"Contember_OrderDirection",
		addedAt:"Contember_OrderDirection",
		tab:"Contember_CustomerTabOrderBy",
		product:"Contember_ProductOrderBy",
		paidAt:"Contember_OrderDirection"
	},
	Contember_CustomerTabOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		color:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		meta:"Contember_CustomerTabMetadataOrderBy",
		customer:"Contember_CustomerOrderBy",
		table:"Contember_TableOrderBy",
		seat:"Contember_SeatOrderBy"
	},
	Contember_CustomerTabMetadataOrderBy:{
		id:"Contember_OrderDirection",
		totalPriceCents:"Contember_OrderDirection",
		totalSpentCents:"Contember_OrderDirection",
		unpaidCents:"Contember_OrderDirection",
		customerTab:"Contember_CustomerTabOrderBy"
	},
	Contember_TableOrderBy:{
		id:"Contember_OrderDirection",
		shape:"Contember_OrderDirection",
		width:"Contember_OrderDirection",
		height:"Contember_OrderDirection",
		positionX:"Contember_OrderDirection",
		positionY:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		color:"Contember_OrderDirection",
		area:"Contember_AreaOrderBy"
	},
	Contember_AreaOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		venue:"Contember_VenueOrderBy"
	},
	Contember_SeatOrderBy:{
		id:"Contember_OrderDirection",
		positionX:"Contember_OrderDirection",
		positionY:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		color:"Contember_OrderDirection",
		table:"Contember_TableOrderBy"
	},
	Contember_CustomerTabMetadata:{
		customerTab:{
			filter:"Contember_CustomerTabWhere"
		}
	},
	Contember_Seat:{
		customerTabs:{
			filter:"Contember_CustomerTabWhere",
			orderBy:"Contember_CustomerTabOrderBy"
		},
		table:{
			filter:"Contember_TableWhere"
		},
		customerTabsByItems:{
			by:"Contember_SeatCustomerTabsByItemsUniqueWhere",
			filter:"Contember_CustomerTabWhere"
		},
		customerTabsByMeta:{
			by:"Contember_SeatCustomerTabsByMetaUniqueWhere",
			filter:"Contember_CustomerTabWhere"
		},
		paginateCustomerTabs:{
			filter:"Contember_CustomerTabWhere",
			orderBy:"Contember_CustomerTabOrderBy"
		}
	},
	Contember_SeatCustomerTabsByItemsUniqueWhere:{
		items:"Contember_CustomerTabItemUniqueWhere"
	},
	Contember_SeatCustomerTabsByMetaUniqueWhere:{
		meta:"Contember_CustomerTabMetadataUniqueWhere"
	},
	Contember_TableCustomerTabsByItemsUniqueWhere:{
		items:"Contember_CustomerTabItemUniqueWhere"
	},
	Contember_TableCustomerTabsByMetaUniqueWhere:{
		meta:"Contember_CustomerTabMetadataUniqueWhere"
	},
	Contember_TableSeatsByCustomerTabsUniqueWhere:{
		customerTabs:"Contember_CustomerTabUniqueWhere"
	},
	Contember_Line:{
		area:{
			filter:"Contember_AreaWhere"
		}
	},
	Contember_LineOrderBy:{
		id:"Contember_OrderDirection",
		positionStartX:"Contember_OrderDirection",
		positionStartY:"Contember_OrderDirection",
		positionEndX:"Contember_OrderDirection",
		positionEndY:"Contember_OrderDirection",
		area:"Contember_AreaOrderBy"
	},
	Contember_AreaTablesByCustomerTabsUniqueWhere:{
		customerTabs:"Contember_CustomerTabUniqueWhere"
	},
	Contember_AreaTablesBySeatsUniqueWhere:{
		seats:"Contember_SeatUniqueWhere"
	},
	Contember_SeatUniqueWhere:{
		id:"UUID",
		customerTabs:"Contember_CustomerTabUniqueWhere"
	},
	Contember_VenueTimetableDaysByDateUniqueWhere:{
		date:"Date"
	},
	Contember_VenueTimetableDaysByShiftGroupsUniqueWhere:{
		shiftGroups:"Contember_TimetableShiftGroupUniqueWhere"
	},
	Contember_VenueTimetableDaysByNotesUniqueWhere:{
		notes:"Contember_TimetableDayNoteUniqueWhere"
	},
	Contember_TimetableDayNoteUniqueWhere:{
		id:"UUID"
	},
	Contember_VenueTimetableDaysByMetaUniqueWhere:{
		meta:"Contember_TimetableDayMetadataUniqueWhere"
	},
	Contember_TimetableDayMetadataUniqueWhere:{
		id:"UUID",
		day:"Contember_TimetableDayUniqueWhere"
	},
	Contember_TimetableDayUniqueWhere:{
		id:"UUID",
		venue:"Contember_VenueUniqueWhere",
		date:"Date",
		shiftGroups:"Contember_TimetableShiftGroupUniqueWhere",
		notes:"Contember_TimetableDayNoteUniqueWhere",
		meta:"Contember_TimetableDayMetadataUniqueWhere"
	},
	Contember_VenueUniqueWhere:{
		id:"UUID",
		deadlineTemplate:"Contember_DeadlineTemplateUniqueWhere",
		timetableDays:"Contember_TimetableDayUniqueWhere",
		timetableTemplates:"Contember_TimetableTemplateUniqueWhere",
		employeeRoles:"Contember_VenueEmployeeRoleUniqueWhere",
		pointOfSaleApplicationDevices:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		pointOfSaleTerminals:"Contember_PointOfSaleTerminalUniqueWhere",
		pointOfSalePrinters:"Contember_PointOfSalePrinterUniqueWhere",
		orders:"Contember_OrderUniqueWhere",
		areas:"Contember_AreaUniqueWhere",
		categories:"Contember_PointOfSaleCategoryUniqueWhere",
		channels:"Contember_ChannelUniqueWhere"
	},
	Contember_DeadlineTemplateUniqueWhere:{
		id:"UUID",
		venue:"Contember_VenueUniqueWhere"
	},
	Contember_TimetableTemplateUniqueWhere:{
		id:"UUID",
		staffOptions:"Contember_TimetableTemplateStaffOptionsUniqueWhere"
	},
	Contember_VenueEmployeeRoleUniqueWhere:{
		id:"UUID",
		venue:"Contember_VenueUniqueWhere",
		role:"Contember_TimetableEmployeeRoleUniqueWhere",
		employeePrivileges:"Contember_TimetablesEmployeePrivilegesUniqueWhere"
	},
	Contember_TimetableEmployeeRoleUniqueWhere:{
		id:"UUID",
		shiftGroups:"Contember_TimetableShiftGroupUniqueWhere",
		timetableTemplates:"Contember_TimetableTemplateUniqueWhere"
	},
	Contember_TimetablesEmployeePrivilegesUniqueWhere:{
		id:"UUID",
		venueEmployeeRole:"Contember_VenueEmployeeRoleUniqueWhere"
	},
	Contember_PointOfSaleTerminalUniqueWhere:{
		id:"UUID",
		applicationDevices:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_PointOfSalePrinterUniqueWhere:{
		id:"UUID",
		applicationDevicesReceipt:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_AreaUniqueWhere:{
		id:"UUID",
		tables:"Contember_TableUniqueWhere",
		lines:"Contember_LineUniqueWhere"
	},
	Contember_TableUniqueWhere:{
		id:"UUID",
		customerTabs:"Contember_CustomerTabUniqueWhere",
		seats:"Contember_SeatUniqueWhere"
	},
	Contember_LineUniqueWhere:{
		id:"UUID"
	},
	Contember_ChannelUniqueWhere:{
		id:"UUID",
		code:"Contember_ChannelCode",
		payments:"Contember_ChannelPaymentUniqueWhere",
		deliveries:"Contember_ChannelDeliveryUniqueWhere",
		carts:"Contember_CartUniqueWhere"
	},
	Contember_ChannelPaymentUniqueWhere:{
		id:"UUID"
	},
	Contember_VenueTimetableTemplatesByStaffOptionsUniqueWhere:{
		staffOptions:"Contember_TimetableTemplateStaffOptionsUniqueWhere"
	},
	Contember_VenueEmployeeRolesByRoleUniqueWhere:{
		role:"Contember_TimetableEmployeeRoleUniqueWhere"
	},
	Contember_VenueEmployeeRolesByEmployeePrivilegesUniqueWhere:{
		employeePrivileges:"Contember_TimetablesEmployeePrivilegesUniqueWhere"
	},
	Contember_VenuePointOfSaleApplicationDevicesByIdentifierUniqueWhere:{
		identifier:"Contember_DeviceIdentifierUniqueWhere"
	},
	Contember_VenuePointOfSaleTerminalsByApplicationDevicesUniqueWhere:{
		applicationDevices:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_VenuePointOfSalePrintersByApplicationDevicesReceiptUniqueWhere:{
		applicationDevicesReceipt:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_VenueOrdersBySeqUniqueWhere:{

	},
	Contember_VenueOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	Contember_VenueOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	Contember_VenueOrdersByDiscountsUniqueWhere:{
		discounts:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_VenueOrdersByDeliveryUniqueWhere:{
		delivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_VenueOrdersByItemsUniqueWhere:{
		items:"Contember_OrderItemUniqueWhere"
	},
	Contember_VenueOrdersByCartUniqueWhere:{
		cart:"Contember_CartUniqueWhere"
	},
	Contember_VenueOrdersByEventsUniqueWhere:{
		events:"Contember_OrderEventUniqueWhere"
	},
	Contember_VenueOrdersByPriceLinesUniqueWhere:{
		priceLines:"Contember_OrderPriceLineUniqueWhere"
	},
	Contember_VenueOrdersByPaymentsUniqueWhere:{
		payments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_VenueOrdersBySummaryUniqueWhere:{
		summary:"Contember_OrderSummaryUniqueWhere"
	},
	Contember_VenueOrdersByGratuityUniqueWhere:{
		gratuity:"Contember_GratuityUniqueWhere"
	},
	Contember_VenueAreasByTablesUniqueWhere:{
		tables:"Contember_TableUniqueWhere"
	},
	Contember_VenueAreasByLinesUniqueWhere:{
		lines:"Contember_LineUniqueWhere"
	},
	Contember_VenueCategoriesBySlugUniqueWhere:{

	},
	Contember_VenueCategoriesByLocalesUniqueWhere:{
		locales:"Contember_PointOfSaleCategoryLocaleUniqueWhere"
	},
	Contember_VenueCategoriesByProductsUniqueWhere:{
		products:"Contember_PointOfSaleCategoryProductUniqueWhere"
	},
	Contember_VenueChannelsByCodeUniqueWhere:{
		code:"Contember_ChannelCode"
	},
	Contember_VenueChannelsByPaymentsUniqueWhere:{
		payments:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_VenueChannelsByDeliveriesUniqueWhere:{
		deliveries:"Contember_ChannelDeliveryUniqueWhere"
	},
	Contember_VenueChannelsByCartsUniqueWhere:{
		carts:"Contember_CartUniqueWhere"
	},
	Contember_CartItemOrderBy:{
		id:"Contember_OrderDirection",
		quantity:"Contember_OrderDirection",
		cart:"Contember_CartOrderBy",
		product:"Contember_ProductOrderBy",
		createdAt:"Contember_OrderDirection",
		updatedAt:"Contember_OrderDirection",
		fulfillmentNote:"Contember_OrderDirection"
	},
	Contember_CartPriceLine:{
		item:{
			filter:"Contember_CartItemWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		},
		vatRate:{
			filter:"Contember_VatRateWhere"
		},
		cart:{
			filter:"Contember_CartWhere"
		}
	},
	Contember_CartPriceLineOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		item:"Contember_CartItemOrderBy",
		product:"Contember_ProductOrderBy",
		unitPriceCents:"Contember_OrderDirection",
		priceCents:"Contember_OrderDirection",
		quantity:"Contember_OrderDirection",
		vatRatePermille:"Contember_OrderDirection",
		vatRate:"Contember_VatRateOrderBy",
		cart:"Contember_CartOrderBy"
	},
	Contember_CartSummary:{
		cart:{
			filter:"Contember_CartWhere"
		}
	},
	Contember_CartItemsByProductUniqueWhere:{
		product:"Contember_ProductUniqueWhere"
	},
	Contember_ChannelCartsByItemsUniqueWhere:{
		items:"Contember_CartItemUniqueWhere"
	},
	Contember_ChannelCartsByOrderUniqueWhere:{
		order:"Contember_OrderUniqueWhere"
	},
	Contember_ChannelCartsByPriceLinesUniqueWhere:{
		priceLines:"Contember_CartPriceLineUniqueWhere"
	},
	Contember_ChannelCartsBySummaryUniqueWhere:{
		summary:"Contember_CartSummaryUniqueWhere"
	},
	Contember_LiftagoRide:{
		delivery:{
			filter:"Contember_OrderDeliveryWhere"
		}
	},
	Contember_DeliveryRouteWaypointsByDeliveryUniqueWhere:{
		delivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_CourierRoutesByWaypointsUniqueWhere:{
		waypoints:"Contember_DeliveryWaypointUniqueWhere"
	},
	Contember_Cleaner:{
		staff:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_StocksManager:{
		staff:{
			filter:"Contember_StaffWhere"
		},
		managedProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		managedProductsByCode:{
			by:"Contember_StocksManagerManagedProductsByCodeUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByStocks:{
			by:"Contember_StocksManagerManagedProductsByStocksUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByGroupPrices:{
			by:"Contember_StocksManagerManagedProductsByGroupPricesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByCartItems:{
			by:"Contember_StocksManagerManagedProductsByCartItemsUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByOrderItems:{
			by:"Contember_StocksManagerManagedProductsByOrderItemsUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByInheritedCategories:{
			by:"Contember_StocksManagerManagedProductsByInheritedCategoriesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByLocales:{
			by:"Contember_StocksManagerManagedProductsByLocalesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByCustomerPrices:{
			by:"Contember_StocksManagerManagedProductsByCustomerPricesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByVirtualProduct:{
			by:"Contember_StocksManagerManagedProductsByVirtualProductUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByMeta:{
			by:"Contember_StocksManagerManagedProductsByMetaUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsByPage:{
			by:"Contember_StocksManagerManagedProductsByPageUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsBySalesDays:{
			by:"Contember_StocksManagerManagedProductsBySalesDaysUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsBySalesWeeks:{
			by:"Contember_StocksManagerManagedProductsBySalesWeeksUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		managedProductsBySalesMonths:{
			by:"Contember_StocksManagerManagedProductsBySalesMonthsUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		paginateManagedProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		}
	},
	Contember_StocksManagerManagedProductsByCodeUniqueWhere:{

	},
	Contember_StocksManagerManagedProductsByStocksUniqueWhere:{
		stocks:"Contember_ProductStockUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByGroupPricesUniqueWhere:{
		groupPrices:"Contember_ProductGroupPriceUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByCartItemsUniqueWhere:{
		cartItems:"Contember_CartItemUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByOrderItemsUniqueWhere:{
		orderItems:"Contember_OrderItemUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByInheritedCategoriesUniqueWhere:{
		inheritedCategories:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByLocalesUniqueWhere:{
		locales:"Contember_ProductLocaleUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByCustomerPricesUniqueWhere:{
		customerPrices:"Contember_ProductCustomerPriceUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByVirtualProductUniqueWhere:{
		virtualProduct:"Contember_VirtualProductUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByMetaUniqueWhere:{
		meta:"Contember_ProductMetadataUniqueWhere"
	},
	Contember_StocksManagerManagedProductsByPageUniqueWhere:{
		page:"Contember_PageUniqueWhere"
	},
	Contember_StocksManagerManagedProductsBySalesDaysUniqueWhere:{
		salesDays:"Contember_ProductSalesDayUniqueWhere"
	},
	Contember_StocksManagerManagedProductsBySalesWeeksUniqueWhere:{
		salesWeeks:"Contember_ProductSalesWeekUniqueWhere"
	},
	Contember_StocksManagerManagedProductsBySalesMonthsUniqueWhere:{
		salesMonths:"Contember_ProductSalesMonthUniqueWhere"
	},
	Contember_CourierManager:{
		staff:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_Picker:{
		staff:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_RemunerationProfile:{
		employmentInformation:{
			filter:"Contember_EmploymentInformationWhere",
			orderBy:"Contember_EmploymentInformationOrderBy"
		},
		workMonths:{
			filter:"Contember_WorkMonthWhere"
		},
		staff:{
			filter:"Contember_StaffWhere"
		},
		paginateEmploymentInformation:{
			filter:"Contember_EmploymentInformationWhere",
			orderBy:"Contember_EmploymentInformationOrderBy"
		}
	},
	Contember_EmploymentInformation:{
		remunerationProfiles:{
			filter:"Contember_RemunerationProfileWhere"
		}
	},
	Contember_EmploymentInformationOrderBy:{
		id:"Contember_OrderDirection",
		hourlyRate:"Contember_OrderDirection",
		remunerationProfiles:"Contember_RemunerationProfileOrderBy",
		monthlySalary:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		name:"Contember_OrderDirection"
	},
	Contember_WorkMonth:{
		remunerationProfile:{
			filter:"Contember_RemunerationProfileWhere",
			orderBy:"Contember_RemunerationProfileOrderBy"
		},
		remunerationProfileByEmploymentInformation:{
			by:"Contember_WorkMonthRemunerationProfileByEmploymentInformationUniqueWhere",
			filter:"Contember_RemunerationProfileWhere"
		},
		remunerationProfileByStaff:{
			by:"Contember_WorkMonthRemunerationProfileByStaffUniqueWhere",
			filter:"Contember_RemunerationProfileWhere"
		},
		paginateRemunerationProfile:{
			filter:"Contember_RemunerationProfileWhere",
			orderBy:"Contember_RemunerationProfileOrderBy"
		}
	},
	Contember_WorkMonthRemunerationProfileByEmploymentInformationUniqueWhere:{
		employmentInformation:"Contember_EmploymentInformationUniqueWhere"
	},
	Contember_WorkMonthRemunerationProfileByStaffUniqueWhere:{
		staff:"Contember_StaffUniqueWhere"
	},
	Contember_Cashier:{
		staff:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_InternalBuyer:{
		staff:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_SalesManager:{
		staff:{
			filter:"Contember_StaffWhere"
		},
		countries:{
			filter:"Contember_CountryWhere",
			orderBy:"Contember_CountryOrderBy"
		},
		paginateCountries:{
			filter:"Contember_CountryWhere",
			orderBy:"Contember_CountryOrderBy"
		}
	},
	Contember_CashierManager:{
		staff:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_BusinessToBusinessManagerManagedBusinessesByCustomerUniqueWhere:{
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_BusinessToBusinessManagerManagedBusinessesByBusinessServiceEventsUniqueWhere:{
		businessServiceEvents:"Contember_BusinessServiceEventUniqueWhere"
	},
	Contember_BusinessToBusinessManagerManagedBusinessesByDoubleshotCoffeeEquipmentUniqueWhere:{
		doubleshotCoffeeEquipment:"Contember_DoubleshotCoffeeEquipmentUniqueWhere"
	},
	Contember_BusinessServiceEvent:{
		businessCustomer:{
			filter:"Contember_BusinessCustomerWhere"
		},
		businessService:{
			filter:"Contember_BusinessServiceWhere"
		},
		filledSupervisor:{
			filter:"Contember_StaffWhere"
		},
		createdBy:{
			filter:"Contember_StaffWhere"
		}
	},
	Contember_BusinessService:{
		locales:{
			filter:"Contember_BusinessServiceLocaleWhere",
			orderBy:"Contember_BusinessServiceLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_BusinessServiceLocalesByLocaleUniqueWhere",
			filter:"Contember_BusinessServiceLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_BusinessServiceLocaleWhere",
			orderBy:"Contember_BusinessServiceLocaleOrderBy"
		}
	},
	Contember_BusinessServiceLocale:{
		root:{
			filter:"Contember_BusinessServiceWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_BusinessServiceLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		labelNote:"Contember_OrderDirection",
		root:"Contember_BusinessServiceOrderBy",
		locale:"Contember_LocaleOrderBy",
		labelNumberOfPeople:"Contember_OrderDirection",
		labelInvoiced:"Contember_OrderDirection",
		labelEquipment:"Contember_OrderDirection",
		labelMillTamDem:"Contember_OrderDirection",
		labelSOEspresso:"Contember_OrderDirection",
		labelFilterCoffeeInMenu:"Contember_OrderDirection",
		labelWaterMeterStatus:"Contember_OrderDirection",
		labelServiceAction:"Contember_OrderDirection",
		labelInvoiceIdentifier:"Contember_OrderDirection",
		labelSupervisor:"Contember_OrderDirection",
		labelContact:"Contember_OrderDirection",
		labelPhone:"Contember_OrderDirection",
		labelEmail:"Contember_OrderDirection",
		labelGrindingStonesCondition:"Contember_OrderDirection"
	},
	Contember_BusinessServiceOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		requireNote:"Contember_OrderDirection",
		requireNumberOfPeople:"Contember_OrderDirection",
		requireInvoiced:"Contember_OrderDirection",
		requireEquipment:"Contember_OrderDirection",
		requireMillTamDem:"Contember_OrderDirection",
		requireSOEspresso:"Contember_OrderDirection",
		requireFilterCoffeeInMenu:"Contember_OrderDirection",
		requireWaterMeterStatus:"Contember_OrderDirection",
		requireServiceAction:"Contember_OrderDirection",
		optionsEquipment:"Contember_OrderDirection",
		optionsServiceAction:"Contember_OrderDirection",
		requireInvoiceIdentifier:"Contember_OrderDirection",
		requireSupervisor:"Contember_OrderDirection",
		requireContact:"Contember_OrderDirection",
		requirePhone:"Contember_OrderDirection",
		requireEmail:"Contember_OrderDirection",
		requireGrindingStonesCondition:"Contember_OrderDirection"
	},
	Contember_BusinessServiceLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BusinessServiceEventOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		fulfilledAt:"Contember_OrderDirection",
		businessCustomer:"Contember_BusinessCustomerOrderBy",
		businessService:"Contember_BusinessServiceOrderBy",
		filledNumberOfPeople:"Contember_OrderDirection",
		filledInvoiced:"Contember_OrderDirection",
		filledEquipment:"Contember_OrderDirection",
		filledMillTamDem:"Contember_OrderDirection",
		filledSOEspresso:"Contember_OrderDirection",
		filledFilterCoffeeInMenu:"Contember_OrderDirection",
		filledWaterMeterStatus:"Contember_OrderDirection",
		filledServiceAction:"Contember_OrderDirection",
		filledInvoiceIdentifier:"Contember_OrderDirection",
		filledNote:"Contember_OrderDirection",
		filledSupervisor:"Contember_StaffOrderBy",
		filledContact:"Contember_OrderDirection",
		filledPhone:"Contember_OrderDirection",
		filledEmail:"Contember_OrderDirection",
		filledGrindingStonesCondition:"Contember_OrderDirection",
		createdBy:"Contember_StaffOrderBy"
	},
	Contember_OrderRecurrence:{
		locales:{
			filter:"Contember_OrderRecurrenceLocaleWhere",
			orderBy:"Contember_OrderRecurrenceLocaleOrderBy"
		},
		businessCategory:{
			filter:"Contember_BusinessCategoryWhere",
			orderBy:"Contember_BusinessCategoryOrderBy"
		},
		localesByLocale:{
			by:"Contember_OrderRecurrenceLocalesByLocaleUniqueWhere",
			filter:"Contember_OrderRecurrenceLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_OrderRecurrenceLocaleWhere",
			orderBy:"Contember_OrderRecurrenceLocaleOrderBy"
		},
		paginateBusinessCategory:{
			filter:"Contember_BusinessCategoryWhere",
			orderBy:"Contember_BusinessCategoryOrderBy"
		}
	},
	Contember_OrderRecurrenceLocale:{
		root:{
			filter:"Contember_OrderRecurrenceWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_OrderRecurrenceLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_OrderRecurrenceOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_OrderRecurrenceOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		order:"Contember_OrderDirection"
	},
	Contember_OrderRecurrenceLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_ProductList:{
		items:{
			filter:"Contember_ProductListItemWhere",
			orderBy:"Contember_ProductListItemOrderBy"
		},
		businessCategory:{
			filter:"Contember_BusinessCategoryWhere"
		},
		paginateItems:{
			filter:"Contember_ProductListItemWhere",
			orderBy:"Contember_ProductListItemOrderBy"
		}
	},
	Contember_ProductListItem:{
		list:{
			filter:"Contember_ProductListWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_ProductListItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		list:"Contember_ProductListOrderBy",
		product:"Contember_ProductOrderBy",
		defaultQuantityForOrder:"Contember_OrderDirection"
	},
	Contember_ProductCategoryList:{
		items:{
			filter:"Contember_ProductCategoryListItemWhere",
			orderBy:"Contember_ProductCategoryListItemOrderBy"
		},
		locales:{
			filter:"Contember_ProductCategoryListLocaleWhere",
			orderBy:"Contember_ProductCategoryListLocaleOrderBy"
		},
		seasonalProductCategoriesOfBusinessCategory:{
			filter:"Contember_BusinessCategoryWhere"
		},
		specificProductsProductCategoriesOfBusinessCategory:{
			filter:"Contember_BusinessCategoryWhere"
		},
		localesByLocale:{
			by:"Contember_ProductCategoryListLocalesByLocaleUniqueWhere",
			filter:"Contember_ProductCategoryListLocaleWhere"
		},
		paginateItems:{
			filter:"Contember_ProductCategoryListItemWhere",
			orderBy:"Contember_ProductCategoryListItemOrderBy"
		},
		paginateLocales:{
			filter:"Contember_ProductCategoryListLocaleWhere",
			orderBy:"Contember_ProductCategoryListLocaleOrderBy"
		}
	},
	Contember_ProductCategoryListItem:{
		list:{
			filter:"Contember_ProductCategoryListWhere"
		},
		productCategory:{
			filter:"Contember_ProductCategoryWhere"
		}
	},
	Contember_ProductCategoryListItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		list:"Contember_ProductCategoryListOrderBy",
		productCategory:"Contember_ProductCategoryOrderBy",
		showRecipeImages:"Contember_OrderDirection",
		showPackingImages:"Contember_OrderDirection",
		showAllAvailableProducts:"Contember_OrderDirection"
	},
	Contember_ProductCategoryListLocale:{
		root:{
			filter:"Contember_ProductCategoryListWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_ProductCategoryListLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_ProductCategoryListOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_ProductCategoryListLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BusinessCategoryLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BusinessCategoryBusinessCustomersByCustomerUniqueWhere:{
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_BusinessCategoryBusinessCustomersByBusinessServiceEventsUniqueWhere:{
		businessServiceEvents:"Contember_BusinessServiceEventUniqueWhere"
	},
	Contember_BusinessCategoryBusinessCustomersByDoubleshotCoffeeEquipmentUniqueWhere:{
		doubleshotCoffeeEquipment:"Contember_DoubleshotCoffeeEquipmentUniqueWhere"
	},
	Contember_ProductIngredientLocale:{
		root:{
			filter:"Contember_ProductIngredientWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_ProductIngredient:{
		locales:{
			filter:"Contember_ProductIngredientLocaleWhere",
			orderBy:"Contember_ProductIngredientLocaleOrderBy"
		},
		categories:{
			filter:"Contember_ProductIngredientCategoryWhere",
			orderBy:"Contember_ProductIngredientCategoryOrderBy"
		},
		allergens:{
			filter:"Contember_AllergenWhere",
			orderBy:"Contember_AllergenOrderBy"
		},
		customerRatings:{
			filter:"Contember_CustomerIngredientRatingWhere",
			orderBy:"Contember_CustomerIngredientRatingOrderBy"
		},
		preferredQuantityUnit:{
			filter:"Contember_ProductIngredientItemQuantityUnitWhere"
		},
		freshingContainer:{
			filter:"Contember_FreshingContainerWhere"
		},
		image:{
			filter:"Contember_ImageWhere"
		},
		suppliers:{
			filter:"Contember_IngredientSupplierListWhere"
		},
		localesByLocale:{
			by:"Contember_ProductIngredientLocalesByLocaleUniqueWhere",
			filter:"Contember_ProductIngredientLocaleWhere"
		},
		customerRatingsByCustomer:{
			by:"Contember_ProductIngredientCustomerRatingsByCustomerUniqueWhere",
			filter:"Contember_CustomerIngredientRatingWhere"
		},
		paginateLocales:{
			filter:"Contember_ProductIngredientLocaleWhere",
			orderBy:"Contember_ProductIngredientLocaleOrderBy"
		},
		paginateCategories:{
			filter:"Contember_ProductIngredientCategoryWhere",
			orderBy:"Contember_ProductIngredientCategoryOrderBy"
		},
		paginateAllergens:{
			filter:"Contember_AllergenWhere",
			orderBy:"Contember_AllergenOrderBy"
		},
		paginateCustomerRatings:{
			filter:"Contember_CustomerIngredientRatingWhere",
			orderBy:"Contember_CustomerIngredientRatingOrderBy"
		}
	},
	Contember_ProductIngredientLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_ProductIngredientOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_ProductIngredientOrderBy:{
		id:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		needsPrepping:"Contember_OrderDirection",
		allowCustomerRating:"Contember_OrderDirection",
		preferredQuantityUnit:"Contember_ProductIngredientItemQuantityUnitOrderBy",
		freshingContainer:"Contember_FreshingContainerOrderBy",
		image:"Contember_ImageOrderBy",
		suppliers:"Contember_IngredientSupplierListOrderBy",
		incrementQuantityNumber:"Contember_OrderDirection"
	},
	Contember_ProductIngredientItemQuantityUnitOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection"
	},
	Contember_IngredientSupplierListOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_ProductIngredientCategory:{
		ingredients:{
			filter:"Contember_ProductIngredientWhere",
			orderBy:"Contember_ProductIngredientOrderBy"
		},
		locales:{
			filter:"Contember_ProductIngredientCategoryLocaleWhere",
			orderBy:"Contember_ProductIngredientCategoryLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_ProductIngredientCategoryLocalesByLocaleUniqueWhere",
			filter:"Contember_ProductIngredientCategoryLocaleWhere"
		},
		paginateIngredients:{
			filter:"Contember_ProductIngredientWhere",
			orderBy:"Contember_ProductIngredientOrderBy"
		},
		paginateLocales:{
			filter:"Contember_ProductIngredientCategoryLocaleWhere",
			orderBy:"Contember_ProductIngredientCategoryLocaleOrderBy"
		}
	},
	Contember_ProductIngredientCategoryLocale:{
		root:{
			filter:"Contember_ProductIngredientCategoryWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_ProductIngredientCategoryLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_ProductIngredientCategoryOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_ProductIngredientCategoryOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection",
		order:"Contember_OrderDirection"
	},
	Contember_ProductIngredientCategoryLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_Allergen:{
		locales:{
			filter:"Contember_AllergenLocaleWhere",
			orderBy:"Contember_AllergenLocaleOrderBy"
		},
		ingredients:{
			filter:"Contember_ProductIngredientWhere",
			orderBy:"Contember_ProductIngredientOrderBy"
		},
		localesByLocale:{
			by:"Contember_AllergenLocalesByLocaleUniqueWhere",
			filter:"Contember_AllergenLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_AllergenLocaleWhere",
			orderBy:"Contember_AllergenLocaleOrderBy"
		},
		paginateIngredients:{
			filter:"Contember_ProductIngredientWhere",
			orderBy:"Contember_ProductIngredientOrderBy"
		}
	},
	Contember_AllergenLocale:{
		root:{
			filter:"Contember_AllergenWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_AllergenLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_AllergenOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_AllergenOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		code:"Contember_OrderDirection"
	},
	Contember_AllergenLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_CustomerIngredientRating:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		rating:{
			filter:"Contember_ProductIngredientRatingWhere"
		},
		ingredient:{
			filter:"Contember_ProductIngredientWhere"
		}
	},
	Contember_ProductIngredientRating:{
		icon:{
			filter:"Contember_ImageWhere"
		}
	},
	Contember_CustomerIngredientRatingOrderBy:{
		id:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		rating:"Contember_ProductIngredientRatingOrderBy",
		ingredient:"Contember_ProductIngredientOrderBy"
	},
	Contember_ProductIngredientRatingOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		icon:"Contember_ImageOrderBy"
	},
	Contember_FreshingContainer:{
		workspace:{
			filter:"Contember_WorkspaceWhere"
		}
	},
	Contember_Workspace:{
		freshingContainers:{
			filter:"Contember_FreshingContainerWhere",
			orderBy:"Contember_FreshingContainerOrderBy"
		},
		openingHours:{
			filter:"Contember_WeekHoursWhere"
		},
		workingHours:{
			filter:"Contember_WeekHoursWhere"
		},
		defaultFreshingContainer:{
			filter:"Contember_FreshingContainerWhere"
		},
		creditsProduct:{
			filter:"Contember_VirtualProductWhere"
		},
		discountVatRate:{
			filter:"Contember_VatRateWhere"
		},
		paginateFreshingContainers:{
			filter:"Contember_FreshingContainerWhere",
			orderBy:"Contember_FreshingContainerOrderBy"
		}
	},
	Contember_VirtualProduct:{
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_IngredientSupplierList:{
		items:{
			filter:"Contember_IngredientSupplierItemWhere",
			orderBy:"Contember_IngredientSupplierItemOrderBy"
		},
		paginateItems:{
			filter:"Contember_IngredientSupplierItemWhere",
			orderBy:"Contember_IngredientSupplierItemOrderBy"
		}
	},
	Contember_IngredientSupplierItem:{
		list:{
			filter:"Contember_IngredientSupplierListWhere"
		},
		supplier:{
			filter:"Contember_IngredientSupplierWhere"
		}
	},
	Contember_IngredientSupplier:{
		locales:{
			filter:"Contember_IngredientSupplierLocaleWhere",
			orderBy:"Contember_IngredientSupplierLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_IngredientSupplierLocalesByLocaleUniqueWhere",
			filter:"Contember_IngredientSupplierLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_IngredientSupplierLocaleWhere",
			orderBy:"Contember_IngredientSupplierLocaleOrderBy"
		}
	},
	Contember_IngredientSupplierLocale:{
		root:{
			filter:"Contember_IngredientSupplierWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_IngredientSupplierLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_IngredientSupplierOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_IngredientSupplierOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection"
	},
	Contember_IngredientSupplierLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_IngredientSupplierItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		list:"Contember_IngredientSupplierListOrderBy",
		supplier:"Contember_IngredientSupplierOrderBy"
	},
	Contember_ProductIngredientLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_ProductIngredientCustomerRatingsByCustomerUniqueWhere:{
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_BlogLocale:{
		root:{
			filter:"Contember_BlogWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_Blog:{
		locales:{
			filter:"Contember_BlogLocaleWhere",
			orderBy:"Contember_BlogLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_BlogLocalesByLocaleUniqueWhere",
			filter:"Contember_BlogLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_BlogLocaleWhere",
			orderBy:"Contember_BlogLocaleOrderBy"
		}
	},
	Contember_BlogLocaleOrderBy:{
		id:"Contember_OrderDirection",
		pageName:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		lead:"Contember_OrderDirection",
		root:"Contember_BlogOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_BlogOrderBy:{
		id:"Contember_OrderDirection",
		unique:"Contember_OrderDirection"
	},
	Contember_BlogLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BlogPostLocale:{
		root:{
			filter:"Contember_BlogPostWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		},
		cover:{
			filter:"Contember_CoverWhere"
		},
		content:{
			filter:"Contember_ContentWhere"
		},
		link:{
			filter:"Contember_LinkableWhere"
		},
		seo:{
			filter:"Contember_SeoWhere"
		},
		products:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		paginateProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		}
	},
	Contember_BlogPost:{
		locales:{
			filter:"Contember_BlogPostLocaleWhere",
			orderBy:"Contember_BlogPostLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_BlogPostLocalesByLocaleUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		localesByCover:{
			by:"Contember_BlogPostLocalesByCoverUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		localesByContent:{
			by:"Contember_BlogPostLocalesByContentUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		localesByLink:{
			by:"Contember_BlogPostLocalesByLinkUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		localesBySeo:{
			by:"Contember_BlogPostLocalesBySeoUniqueWhere",
			filter:"Contember_BlogPostLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_BlogPostLocaleWhere",
			orderBy:"Contember_BlogPostLocaleOrderBy"
		}
	},
	Contember_BlogPostLocaleOrderBy:{
		id:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		availableForWeb:"Contember_OrderDirection",
		availableForMobile:"Contember_OrderDirection",
		root:"Contember_BlogPostOrderBy",
		locale:"Contember_LocaleOrderBy",
		cover:"Contember_CoverOrderBy",
		content:"Contember_ContentOrderBy",
		link:"Contember_LinkableOrderBy",
		seo:"Contember_SeoOrderBy"
	},
	Contember_BlogPostOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection"
	},
	Contember_CoverOrderBy:{
		id:"Contember_OrderDirection",
		primaryTitle:"Contember_OrderDirection",
		secondaryTitle:"Contember_OrderDirection",
		medium:"Contember_MediumOrderBy",
		buttonColors:"Contember_ColorPalleteOrderBy"
	},
	Contember_MediumOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		colorTheme:"Contember_OrderDirection",
		image:"Contember_ImageOrderBy",
		video:"Contember_VideoOrderBy"
	},
	Contember_VideoOrderBy:{
		id:"Contember_OrderDirection",
		src:"Contember_OrderDirection",
		width:"Contember_OrderDirection",
		height:"Contember_OrderDirection",
		size:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		duration:"Contember_OrderDirection",
		poster:"Contember_ImageOrderBy"
	},
	Contember_ColorPalleteOrderBy:{
		id:"Contember_OrderDirection",
		background:"Contember_OrderDirection",
		text:"Contember_OrderDirection"
	},
	Contember_ContentOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_LinkableOrderBy:{
		id:"Contember_OrderDirection",
		url:"Contember_OrderDirection",
		blogPost:"Contember_BlogPostLocaleOrderBy",
		page:"Contember_PageLocaleOrderBy",
		wikiPage:"Contember_WikiPageOrderBy",
		redirect:"Contember_RedirectOrderBy"
	},
	Contember_PageLocaleOrderBy:{
		id:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		link:"Contember_LinkableOrderBy",
		root:"Contember_PageOrderBy",
		locale:"Contember_LocaleOrderBy",
		seo:"Contember_SeoOrderBy",
		content:"Contember_ContentOrderBy",
		cover:"Contember_CoverOrderBy",
		theme:"Contember_OrderDirection"
	},
	Contember_SeoOrderBy:{
		id:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		ogTitle:"Contember_OrderDirection",
		ogDescription:"Contember_OrderDirection",
		ogImage:"Contember_ImageOrderBy"
	},
	Contember_WikiPageOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		emoji:"Contember_OrderDirection",
		link:"Contember_LinkableOrderBy",
		parent:"Contember_WikiPageOrderBy",
		content:"Contember_ContentOrderBy"
	},
	Contember_RedirectOrderBy:{
		id:"Contember_OrderDirection",
		link:"Contember_LinkableOrderBy",
		target:"Contember_LinkOrderBy"
	},
	Contember_LinkOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		externalLink:"Contember_OrderDirection",
		internalLink:"Contember_LinkableOrderBy"
	},
	Contember_BlogPostLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_BlogPostLocalesByCoverUniqueWhere:{
		cover:"Contember_CoverUniqueWhere"
	},
	Contember_BlogPostLocalesByContentUniqueWhere:{
		content:"Contember_ContentUniqueWhere"
	},
	Contember_BlogPostLocalesByLinkUniqueWhere:{
		link:"Contember_LinkableUniqueWhere"
	},
	Contember_BlogPostLocalesBySeoUniqueWhere:{
		seo:"Contember_SeoUniqueWhere"
	},
	Contember_Cover:{
		medium:{
			filter:"Contember_MediumWhere"
		},
		buttonColors:{
			filter:"Contember_ColorPalleteWhere"
		}
	},
	Contember_Medium:{
		image:{
			filter:"Contember_ImageWhere"
		},
		video:{
			filter:"Contember_VideoWhere"
		}
	},
	Contember_Video:{
		poster:{
			filter:"Contember_ImageWhere"
		}
	},
	Contember_Content:{
		blocks:{
			filter:"Contember_ContentBlockWhere",
			orderBy:"Contember_ContentBlockOrderBy"
		},
		blocksByReferences:{
			by:"Contember_ContentBlocksByReferencesUniqueWhere",
			filter:"Contember_ContentBlockWhere"
		},
		paginateBlocks:{
			filter:"Contember_ContentBlockWhere",
			orderBy:"Contember_ContentBlockOrderBy"
		}
	},
	Contember_ContentBlock:{
		content:{
			filter:"Contember_ContentWhere"
		},
		references:{
			filter:"Contember_ContentReferenceWhere",
			orderBy:"Contember_ContentReferenceOrderBy"
		},
		referencesByProducts:{
			by:"Contember_ContentBlockReferencesByProductsUniqueWhere",
			filter:"Contember_ContentReferenceWhere"
		},
		referencesByProductBanners:{
			by:"Contember_ContentBlockReferencesByProductBannersUniqueWhere",
			filter:"Contember_ContentReferenceWhere"
		},
		referencesByDeliveryRegions:{
			by:"Contember_ContentBlockReferencesByDeliveryRegionsUniqueWhere",
			filter:"Contember_ContentReferenceWhere"
		},
		paginateReferences:{
			filter:"Contember_ContentReferenceWhere",
			orderBy:"Contember_ContentReferenceOrderBy"
		}
	},
	Contember_ContentReference:{
		block:{
			filter:"Contember_ContentBlockWhere"
		},
		colorPallete:{
			filter:"Contember_ColorPalleteWhere"
		},
		medium:{
			filter:"Contember_MediumWhere"
		},
		link:{
			filter:"Contember_LinkWhere"
		},
		blogPosts:{
			filter:"Contember_BlogPostListWhere"
		},
		links:{
			filter:"Contember_LinkListWhere"
		},
		products:{
			filter:"Contember_ProductListWhere"
		},
		hero:{
			filter:"Contember_HeroWhere"
		},
		gallery:{
			filter:"Contember_GalleryWhere"
		},
		socialsAndApps:{
			filter:"Contember_SocialsAndAppsWhere"
		},
		productGrid:{
			filter:"Contember_ProductGridWhere"
		},
		productBanners:{
			filter:"Contember_ProductBannerListWhere"
		},
		deliveryRegions:{
			filter:"Contember_DeliveryRegionsWhere"
		},
		textList:{
			filter:"Contember_TextListWhere"
		},
		gridTiles:{
			filter:"Contember_GridTilesWhere"
		}
	},
	Contember_Link:{
		internalLink:{
			filter:"Contember_LinkableWhere"
		}
	},
	Contember_Linkable:{
		blogPost:{
			filter:"Contember_BlogPostLocaleWhere"
		},
		page:{
			filter:"Contember_PageLocaleWhere"
		},
		wikiPage:{
			filter:"Contember_WikiPageWhere"
		},
		redirect:{
			filter:"Contember_RedirectWhere"
		}
	},
	Contember_PageLocale:{
		link:{
			filter:"Contember_LinkableWhere"
		},
		root:{
			filter:"Contember_PageWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		},
		seo:{
			filter:"Contember_SeoWhere"
		},
		content:{
			filter:"Contember_ContentWhere"
		},
		cover:{
			filter:"Contember_CoverWhere"
		}
	},
	Contember_Page:{
		locales:{
			filter:"Contember_PageLocaleWhere",
			orderBy:"Contember_PageLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_PageLocalesByLocaleUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		localesByLink:{
			by:"Contember_PageLocalesByLinkUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		localesBySeo:{
			by:"Contember_PageLocalesBySeoUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		localesByContent:{
			by:"Contember_PageLocalesByContentUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		localesByCover:{
			by:"Contember_PageLocalesByCoverUniqueWhere",
			filter:"Contember_PageLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_PageLocaleWhere",
			orderBy:"Contember_PageLocaleOrderBy"
		}
	},
	Contember_PageLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_PageLocalesByLinkUniqueWhere:{
		link:"Contember_LinkableUniqueWhere"
	},
	Contember_PageLocalesBySeoUniqueWhere:{
		seo:"Contember_SeoUniqueWhere"
	},
	Contember_PageLocalesByContentUniqueWhere:{
		content:"Contember_ContentUniqueWhere"
	},
	Contember_PageLocalesByCoverUniqueWhere:{
		cover:"Contember_CoverUniqueWhere"
	},
	Contember_Seo:{
		ogImage:{
			filter:"Contember_ImageWhere"
		}
	},
	Contember_WikiPage:{
		link:{
			filter:"Contember_LinkableWhere"
		},
		parent:{
			filter:"Contember_WikiPageWhere"
		},
		children:{
			filter:"Contember_WikiPageWhere",
			orderBy:"Contember_WikiPageOrderBy"
		},
		content:{
			filter:"Contember_ContentWhere"
		},
		childrenByLink:{
			by:"Contember_WikiPageChildrenByLinkUniqueWhere",
			filter:"Contember_WikiPageWhere"
		},
		childrenByChildren:{
			by:"Contember_WikiPageChildrenByChildrenUniqueWhere",
			filter:"Contember_WikiPageWhere"
		},
		childrenByContent:{
			by:"Contember_WikiPageChildrenByContentUniqueWhere",
			filter:"Contember_WikiPageWhere"
		},
		paginateChildren:{
			filter:"Contember_WikiPageWhere",
			orderBy:"Contember_WikiPageOrderBy"
		}
	},
	Contember_WikiPageChildrenByLinkUniqueWhere:{
		link:"Contember_LinkableUniqueWhere"
	},
	Contember_WikiPageChildrenByChildrenUniqueWhere:{
		children:"Contember_WikiPageUniqueWhere"
	},
	Contember_WikiPageChildrenByContentUniqueWhere:{
		content:"Contember_ContentUniqueWhere"
	},
	Contember_Redirect:{
		link:{
			filter:"Contember_LinkableWhere"
		},
		target:{
			filter:"Contember_LinkWhere"
		}
	},
	Contember_BlogPostList:{
		items:{
			filter:"Contember_BlogPostListItemWhere",
			orderBy:"Contember_BlogPostListItemOrderBy"
		},
		paginateItems:{
			filter:"Contember_BlogPostListItemWhere",
			orderBy:"Contember_BlogPostListItemOrderBy"
		}
	},
	Contember_BlogPostListItem:{
		list:{
			filter:"Contember_BlogPostListWhere"
		},
		blogPost:{
			filter:"Contember_BlogPostLocaleWhere"
		}
	},
	Contember_BlogPostListItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		caption:"Contember_OrderDirection",
		list:"Contember_BlogPostListOrderBy",
		blogPost:"Contember_BlogPostLocaleOrderBy"
	},
	Contember_BlogPostListOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_LinkList:{
		items:{
			filter:"Contember_LinkListItemWhere",
			orderBy:"Contember_LinkListItemOrderBy"
		},
		paginateItems:{
			filter:"Contember_LinkListItemWhere",
			orderBy:"Contember_LinkListItemOrderBy"
		}
	},
	Contember_LinkListItem:{
		list:{
			filter:"Contember_LinkListWhere"
		},
		link:{
			filter:"Contember_LinkWhere"
		}
	},
	Contember_LinkListItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		isPrimary:"Contember_OrderDirection",
		list:"Contember_LinkListOrderBy",
		link:"Contember_LinkOrderBy"
	},
	Contember_LinkListOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_Hero:{
		medium:{
			filter:"Contember_MediumWhere"
		}
	},
	Contember_Gallery:{
		items:{
			filter:"Contember_GalleryItemWhere",
			orderBy:"Contember_GalleryItemOrderBy"
		},
		paginateItems:{
			filter:"Contember_GalleryItemWhere",
			orderBy:"Contember_GalleryItemOrderBy"
		}
	},
	Contember_GalleryItem:{
		list:{
			filter:"Contember_GalleryWhere"
		},
		medium:{
			filter:"Contember_MediumWhere"
		}
	},
	Contember_GalleryItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		caption:"Contember_OrderDirection",
		list:"Contember_GalleryOrderBy",
		medium:"Contember_MediumOrderBy"
	},
	Contember_GalleryOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_ProductGrid:{
		categories:{
			filter:"Contember_ProductGridCategoryWhere",
			orderBy:"Contember_ProductGridCategoryOrderBy"
		},
		categoriesByItems:{
			by:"Contember_ProductGridCategoriesByItemsUniqueWhere",
			filter:"Contember_ProductGridCategoryWhere"
		},
		categoriesByImages:{
			by:"Contember_ProductGridCategoriesByImagesUniqueWhere",
			filter:"Contember_ProductGridCategoryWhere"
		},
		paginateCategories:{
			filter:"Contember_ProductGridCategoryWhere",
			orderBy:"Contember_ProductGridCategoryOrderBy"
		}
	},
	Contember_ProductGridCategory:{
		root:{
			filter:"Contember_ProductGridWhere"
		},
		items:{
			filter:"Contember_ProductGridItemWhere",
			orderBy:"Contember_ProductGridItemOrderBy"
		},
		images:{
			filter:"Contember_ImageListWhere"
		},
		paginateItems:{
			filter:"Contember_ProductGridItemWhere",
			orderBy:"Contember_ProductGridItemOrderBy"
		}
	},
	Contember_ProductGridItem:{
		product:{
			filter:"Contember_ProductWhere"
		},
		medium:{
			filter:"Contember_MediumWhere"
		},
		link:{
			filter:"Contember_LinkWhere"
		},
		category:{
			filter:"Contember_ProductGridCategoryWhere"
		}
	},
	Contember_ProductGridItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		lead:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy",
		medium:"Contember_MediumOrderBy",
		link:"Contember_LinkOrderBy",
		category:"Contember_ProductGridCategoryOrderBy",
		description:"Contember_OrderDirection"
	},
	Contember_ProductGridCategoryOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		root:"Contember_ProductGridOrderBy",
		images:"Contember_ImageListOrderBy"
	},
	Contember_ProductGridOrderBy:{
		id:"Contember_OrderDirection",
		isCarousel:"Contember_OrderDirection",
		type:"Contember_OrderDirection"
	},
	Contember_ImageListOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_ImageList:{
		items:{
			filter:"Contember_ImageListItemWhere",
			orderBy:"Contember_ImageListItemOrderBy"
		},
		itemsByImage:{
			by:"Contember_ImageListItemsByImageUniqueWhere",
			filter:"Contember_ImageListItemWhere"
		},
		paginateItems:{
			filter:"Contember_ImageListItemWhere",
			orderBy:"Contember_ImageListItemOrderBy"
		}
	},
	Contember_ImageListItem:{
		list:{
			filter:"Contember_ImageListWhere"
		},
		image:{
			filter:"Contember_ImageWhere"
		}
	},
	Contember_ImageListItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		list:"Contember_ImageListOrderBy",
		image:"Contember_ImageOrderBy"
	},
	Contember_ImageListItemsByImageUniqueWhere:{
		image:"Contember_ImageUniqueWhere"
	},
	Contember_ProductGridCategoriesByItemsUniqueWhere:{
		items:"Contember_ProductGridItemUniqueWhere"
	},
	Contember_ProductGridItemUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductGridCategoriesByImagesUniqueWhere:{
		images:"Contember_ImageListUniqueWhere"
	},
	Contember_ProductBannerList:{
		items:{
			filter:"Contember_ProductBannerItemWhere",
			orderBy:"Contember_ProductBannerItemOrderBy"
		},
		paginateItems:{
			filter:"Contember_ProductBannerItemWhere",
			orderBy:"Contember_ProductBannerItemOrderBy"
		}
	},
	Contember_ProductBannerItem:{
		list:{
			filter:"Contember_ProductBannerListWhere"
		},
		banner:{
			filter:"Contember_ProductBannerWhere"
		}
	},
	Contember_ProductBanner:{
		button:{
			filter:"Contember_LinkWhere"
		},
		backgroundImage:{
			filter:"Contember_ImageWhere"
		},
		productImage:{
			filter:"Contember_ImageWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_ProductBannerItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		list:"Contember_ProductBannerListOrderBy",
		banner:"Contember_ProductBannerOrderBy",
		size:"Contember_OrderDirection"
	},
	Contember_ProductBannerListOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_ProductBannerOrderBy:{
		id:"Contember_OrderDirection",
		beforeTitle:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		label:"Contember_OrderDirection",
		button:"Contember_LinkOrderBy",
		backgroundImage:"Contember_ImageOrderBy",
		productImage:"Contember_ImageOrderBy",
		product:"Contember_ProductOrderBy"
	},
	Contember_DeliveryRegions:{
		items:{
			filter:"Contember_DeliveryRegionsItemWhere",
			orderBy:"Contember_DeliveryRegionsItemOrderBy"
		},
		itemsByImage:{
			by:"Contember_DeliveryRegionsItemsByImageUniqueWhere",
			filter:"Contember_DeliveryRegionsItemWhere"
		},
		itemsByTextList:{
			by:"Contember_DeliveryRegionsItemsByTextListUniqueWhere",
			filter:"Contember_DeliveryRegionsItemWhere"
		},
		itemsByGallery:{
			by:"Contember_DeliveryRegionsItemsByGalleryUniqueWhere",
			filter:"Contember_DeliveryRegionsItemWhere"
		},
		paginateItems:{
			filter:"Contember_DeliveryRegionsItemWhere",
			orderBy:"Contember_DeliveryRegionsItemOrderBy"
		}
	},
	Contember_DeliveryRegionsItem:{
		list:{
			filter:"Contember_DeliveryRegionsWhere"
		},
		image:{
			filter:"Contember_ImageWhere"
		},
		textList:{
			filter:"Contember_TextListWhere"
		},
		gallery:{
			filter:"Contember_ImageListWhere"
		}
	},
	Contember_TextList:{
		items:{
			filter:"Contember_TextItemWhere",
			orderBy:"Contember_TextItemOrderBy"
		},
		paginateItems:{
			filter:"Contember_TextItemWhere",
			orderBy:"Contember_TextItemOrderBy"
		}
	},
	Contember_TextItem:{
		list:{
			filter:"Contember_TextListWhere"
		}
	},
	Contember_TextItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		text:"Contember_OrderDirection",
		list:"Contember_TextListOrderBy",
		title:"Contember_OrderDirection"
	},
	Contember_TextListOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_DeliveryRegionsItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		offerLabel:"Contember_OrderDirection",
		list:"Contember_DeliveryRegionsOrderBy",
		image:"Contember_ImageOrderBy",
		textList:"Contember_TextListOrderBy",
		gallery:"Contember_ImageListOrderBy"
	},
	Contember_DeliveryRegionsOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_DeliveryRegionsItemsByImageUniqueWhere:{
		image:"Contember_ImageUniqueWhere"
	},
	Contember_DeliveryRegionsItemsByTextListUniqueWhere:{
		textList:"Contember_TextListUniqueWhere"
	},
	Contember_DeliveryRegionsItemsByGalleryUniqueWhere:{
		gallery:"Contember_ImageListUniqueWhere"
	},
	Contember_GridTiles:{
		items:{
			filter:"Contember_GridTileItemWhere",
			orderBy:"Contember_GridTileItemOrderBy"
		},
		itemsByLink:{
			by:"Contember_GridTilesItemsByLinkUniqueWhere",
			filter:"Contember_GridTileItemWhere"
		},
		paginateItems:{
			filter:"Contember_GridTileItemWhere",
			orderBy:"Contember_GridTileItemOrderBy"
		}
	},
	Contember_GridTileItem:{
		list:{
			filter:"Contember_GridTilesWhere"
		},
		medium:{
			filter:"Contember_MediumWhere"
		},
		link:{
			filter:"Contember_LinkWhere"
		}
	},
	Contember_GridTileItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		text:"Contember_OrderDirection",
		list:"Contember_GridTilesOrderBy",
		medium:"Contember_MediumOrderBy",
		link:"Contember_LinkOrderBy"
	},
	Contember_GridTilesOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_GridTilesItemsByLinkUniqueWhere:{
		link:"Contember_LinkUniqueWhere"
	},
	Contember_LinkUniqueWhere:{
		id:"UUID"
	},
	Contember_ContentReferenceOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		primaryText:"Contember_OrderDirection",
		secondaryText:"Contember_OrderDirection",
		jsonContent:"Contember_OrderDirection",
		block:"Contember_ContentBlockOrderBy",
		colorPallete:"Contember_ColorPalleteOrderBy",
		medium:"Contember_MediumOrderBy",
		link:"Contember_LinkOrderBy",
		blogPosts:"Contember_BlogPostListOrderBy",
		links:"Contember_LinkListOrderBy",
		products:"Contember_ProductListOrderBy",
		contentSize:"Contember_OrderDirection",
		misc:"Contember_OrderDirection",
		hero:"Contember_HeroOrderBy",
		gallery:"Contember_GalleryOrderBy",
		socialsAndApps:"Contember_SocialsAndAppsOrderBy",
		productGrid:"Contember_ProductGridOrderBy",
		productBanners:"Contember_ProductBannerListOrderBy",
		deliveryRegions:"Contember_DeliveryRegionsOrderBy",
		textList:"Contember_TextListOrderBy",
		gridTiles:"Contember_GridTilesOrderBy"
	},
	Contember_ContentBlockOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		json:"Contember_OrderDirection",
		content:"Contember_ContentOrderBy"
	},
	Contember_HeroOrderBy:{
		id:"Contember_OrderDirection",
		showLogo:"Contember_OrderDirection",
		middleContent:"Contember_OrderDirection",
		medium:"Contember_MediumOrderBy"
	},
	Contember_SocialsAndAppsOrderBy:{
		id:"Contember_OrderDirection",
		instagram:"Contember_OrderDirection",
		appStore:"Contember_OrderDirection",
		googlePlay:"Contember_OrderDirection"
	},
	Contember_ContentBlockReferencesByProductsUniqueWhere:{
		products:"Contember_ProductListUniqueWhere"
	},
	Contember_ContentBlockReferencesByProductBannersUniqueWhere:{
		productBanners:"Contember_ProductBannerListUniqueWhere"
	},
	Contember_ContentBlockReferencesByDeliveryRegionsUniqueWhere:{
		deliveryRegions:"Contember_DeliveryRegionsUniqueWhere"
	},
	Contember_ContentBlocksByReferencesUniqueWhere:{
		references:"Contember_ContentReferenceUniqueWhere"
	},
	Contember_TranslationCataloguesIdentifier:{
		catalogue:{
			filter:"Contember_TranslationCatalogueWhere",
			orderBy:"Contember_TranslationCatalogueOrderBy"
		},
		catalogueByDomain:{
			by:"Contember_TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere",
			filter:"Contember_TranslationCatalogueWhere"
		},
		catalogueByValues:{
			by:"Contember_TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere",
			filter:"Contember_TranslationCatalogueWhere"
		},
		paginateCatalogue:{
			filter:"Contember_TranslationCatalogueWhere",
			orderBy:"Contember_TranslationCatalogueOrderBy"
		}
	},
	Contember_TranslationCatalogue:{
		domain:{
			filter:"Contember_TranslationDomainWhere"
		},
		fallback:{
			filter:"Contember_TranslationCatalogueWhere"
		},
		values:{
			filter:"Contember_TranslationValueWhere",
			orderBy:"Contember_TranslationValueOrderBy"
		},
		identifier:{
			filter:"Contember_TranslationCataloguesIdentifierWhere"
		},
		valuesByKey:{
			by:"Contember_TranslationCatalogueValuesByKeyUniqueWhere",
			filter:"Contember_TranslationValueWhere"
		},
		paginateValues:{
			filter:"Contember_TranslationValueWhere",
			orderBy:"Contember_TranslationValueOrderBy"
		}
	},
	Contember_TranslationDomain:{
		catalogues:{
			filter:"Contember_TranslationCatalogueWhere",
			orderBy:"Contember_TranslationCatalogueOrderBy"
		},
		keys:{
			filter:"Contember_TranslationKeyWhere",
			orderBy:"Contember_TranslationKeyOrderBy"
		},
		cataloguesByIdentifier:{
			by:"Contember_TranslationDomainCataloguesByIdentifierUniqueWhere",
			filter:"Contember_TranslationCatalogueWhere"
		},
		cataloguesByValues:{
			by:"Contember_TranslationDomainCataloguesByValuesUniqueWhere",
			filter:"Contember_TranslationCatalogueWhere"
		},
		keysByIdentifier:{
			by:"Contember_TranslationDomainKeysByIdentifierUniqueWhere",
			filter:"Contember_TranslationKeyWhere"
		},
		keysByValues:{
			by:"Contember_TranslationDomainKeysByValuesUniqueWhere",
			filter:"Contember_TranslationKeyWhere"
		},
		paginateCatalogues:{
			filter:"Contember_TranslationCatalogueWhere",
			orderBy:"Contember_TranslationCatalogueOrderBy"
		},
		paginateKeys:{
			filter:"Contember_TranslationKeyWhere",
			orderBy:"Contember_TranslationKeyOrderBy"
		}
	},
	Contember_TranslationCatalogueOrderBy:{
		id:"Contember_OrderDirection",
		domain:"Contember_TranslationDomainOrderBy",
		fallback:"Contember_TranslationCatalogueOrderBy",
		identifier:"Contember_TranslationCataloguesIdentifierOrderBy"
	},
	Contember_TranslationDomainOrderBy:{
		id:"Contember_OrderDirection",
		identifier:"Contember_OrderDirection",
		name:"Contember_OrderDirection"
	},
	Contember_TranslationKey:{
		domain:{
			filter:"Contember_TranslationDomainWhere"
		},
		values:{
			filter:"Contember_TranslationValueWhere",
			orderBy:"Contember_TranslationValueOrderBy"
		},
		valuesByCatalogue:{
			by:"Contember_TranslationKeyValuesByCatalogueUniqueWhere",
			filter:"Contember_TranslationValueWhere"
		},
		paginateValues:{
			filter:"Contember_TranslationValueWhere",
			orderBy:"Contember_TranslationValueOrderBy"
		}
	},
	Contember_TranslationValue:{
		catalogue:{
			filter:"Contember_TranslationCatalogueWhere"
		},
		key:{
			filter:"Contember_TranslationKeyWhere"
		}
	},
	Contember_TranslationValueOrderBy:{
		id:"Contember_OrderDirection",
		value:"Contember_OrderDirection",
		catalogue:"Contember_TranslationCatalogueOrderBy",
		key:"Contember_TranslationKeyOrderBy"
	},
	Contember_TranslationKeyOrderBy:{
		id:"Contember_OrderDirection",
		identifier:"Contember_OrderDirection",
		contentType:"Contember_OrderDirection",
		note:"Contember_OrderDirection",
		domain:"Contember_TranslationDomainOrderBy"
	},
	Contember_TranslationKeyValuesByCatalogueUniqueWhere:{
		catalogue:"Contember_TranslationCatalogueUniqueWhere"
	},
	Contember_TranslationDomainCataloguesByIdentifierUniqueWhere:{
		identifier:"Contember_TranslationCataloguesIdentifierUniqueWhere"
	},
	Contember_TranslationDomainCataloguesByValuesUniqueWhere:{
		values:"Contember_TranslationValueUniqueWhere"
	},
	Contember_TranslationDomainKeysByIdentifierUniqueWhere:{

	},
	Contember_TranslationDomainKeysByValuesUniqueWhere:{
		values:"Contember_TranslationValueUniqueWhere"
	},
	Contember_TranslationCatalogueValuesByKeyUniqueWhere:{
		key:"Contember_TranslationKeyUniqueWhere"
	},
	Contember_TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere:{
		domain:"Contember_TranslationDomainUniqueWhere"
	},
	Contember_TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere:{
		values:"Contember_TranslationValueUniqueWhere"
	},
	Contember_MenuLocale:{
		root:{
			filter:"Contember_SiteMenuWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		},
		items:{
			filter:"Contember_MenuItemWhere",
			orderBy:"Contember_MenuItemOrderBy"
		},
		secondaryItems:{
			filter:"Contember_LinkListWhere"
		},
		eshopLink:{
			filter:"Contember_LinkWhere"
		},
		itemsBySubitems:{
			by:"Contember_MenuLocaleItemsBySubitemsUniqueWhere",
			filter:"Contember_MenuItemWhere"
		},
		paginateItems:{
			filter:"Contember_MenuItemWhere",
			orderBy:"Contember_MenuItemOrderBy"
		}
	},
	Contember_SiteMenu:{
		locales:{
			filter:"Contember_MenuLocaleWhere",
			orderBy:"Contember_MenuLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_SiteMenuLocalesByLocaleUniqueWhere",
			filter:"Contember_MenuLocaleWhere"
		},
		localesByItems:{
			by:"Contember_SiteMenuLocalesByItemsUniqueWhere",
			filter:"Contember_MenuLocaleWhere"
		},
		localesBySecondaryItems:{
			by:"Contember_SiteMenuLocalesBySecondaryItemsUniqueWhere",
			filter:"Contember_MenuLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_MenuLocaleWhere",
			orderBy:"Contember_MenuLocaleOrderBy"
		}
	},
	Contember_MenuLocaleOrderBy:{
		id:"Contember_OrderDirection",
		root:"Contember_SiteMenuOrderBy",
		locale:"Contember_LocaleOrderBy",
		secondaryItems:"Contember_LinkListOrderBy",
		eshopLink:"Contember_LinkOrderBy"
	},
	Contember_SiteMenuOrderBy:{
		id:"Contember_OrderDirection",
		unique:"Contember_OrderDirection"
	},
	Contember_SiteMenuLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_SiteMenuLocalesByItemsUniqueWhere:{
		items:"Contember_MenuItemUniqueWhere"
	},
	Contember_SiteMenuLocalesBySecondaryItemsUniqueWhere:{
		secondaryItems:"Contember_LinkListUniqueWhere"
	},
	Contember_MenuItem:{
		menu:{
			filter:"Contember_MenuLocaleWhere"
		},
		link:{
			filter:"Contember_LinkWhere"
		},
		subitems:{
			filter:"Contember_MenuSubitemWhere",
			orderBy:"Contember_MenuSubitemOrderBy"
		},
		paginateSubitems:{
			filter:"Contember_MenuSubitemWhere",
			orderBy:"Contember_MenuSubitemOrderBy"
		}
	},
	Contember_MenuSubitem:{
		item:{
			filter:"Contember_MenuItemWhere"
		},
		links:{
			filter:"Contember_MenuLinkListWhere"
		},
		products:{
			filter:"Contember_MenuProductsWhere"
		},
		delivery:{
			filter:"Contember_MenuDeliveryWhere"
		},
		contact:{
			filter:"Contember_MenuContactWhere"
		}
	},
	Contember_MenuLinkList:{
		items:{
			filter:"Contember_MenuLinkItemWhere",
			orderBy:"Contember_MenuLinkItemOrderBy"
		},
		paginateItems:{
			filter:"Contember_MenuLinkItemWhere",
			orderBy:"Contember_MenuLinkItemOrderBy"
		}
	},
	Contember_MenuLinkItem:{
		list:{
			filter:"Contember_MenuLinkListWhere"
		},
		link:{
			filter:"Contember_LinkWhere"
		},
		image:{
			filter:"Contember_ImageWhere"
		}
	},
	Contember_MenuLinkItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		list:"Contember_MenuLinkListOrderBy",
		link:"Contember_LinkOrderBy",
		image:"Contember_ImageOrderBy",
		description:"Contember_OrderDirection"
	},
	Contember_MenuLinkListOrderBy:{
		id:"Contember_OrderDirection"
	},
	Contember_MenuProducts:{
		links:{
			filter:"Contember_MenuLinkListWhere"
		},
		buttons:{
			filter:"Contember_LinkListWhere"
		}
	},
	Contember_MenuDelivery:{
		deliveryRegions:{
			filter:"Contember_DeliveryRegionsWhere"
		},
		socialsAndApps:{
			filter:"Contember_SocialsAndAppsWhere"
		}
	},
	Contember_MenuContact:{
		image:{
			filter:"Contember_ImageWhere"
		}
	},
	Contember_MenuSubitemOrderBy:{
		id:"Contember_OrderDirection",
		type:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		item:"Contember_MenuItemOrderBy",
		links:"Contember_MenuLinkListOrderBy",
		products:"Contember_MenuProductsOrderBy",
		delivery:"Contember_MenuDeliveryOrderBy",
		contact:"Contember_MenuContactOrderBy",
		order:"Contember_OrderDirection"
	},
	Contember_MenuItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		menu:"Contember_MenuLocaleOrderBy",
		link:"Contember_LinkOrderBy"
	},
	Contember_MenuProductsOrderBy:{
		id:"Contember_OrderDirection",
		links:"Contember_MenuLinkListOrderBy",
		buttons:"Contember_LinkListOrderBy"
	},
	Contember_MenuDeliveryOrderBy:{
		id:"Contember_OrderDirection",
		deliveryRegions:"Contember_DeliveryRegionsOrderBy",
		text:"Contember_OrderDirection",
		socialsAndApps:"Contember_SocialsAndAppsOrderBy"
	},
	Contember_MenuContactOrderBy:{
		id:"Contember_OrderDirection",
		text:"Contember_OrderDirection",
		image:"Contember_ImageOrderBy"
	},
	Contember_MenuLocaleItemsBySubitemsUniqueWhere:{
		subitems:"Contember_MenuSubitemUniqueWhere"
	},
	Contember_ProductPackingLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		shortName:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		root:"Contember_ProductPackingOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_ProductLocale:{
		root:{
			filter:"Contember_ProductWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		},
		featureList:{
			filter:"Contember_TextListWhere"
		}
	},
	Contember_ProductLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		root:"Contember_ProductOrderBy",
		locale:"Contember_LocaleOrderBy",
		featureList:"Contember_TextListOrderBy",
		printableName:"Contember_OrderDirection",
		firstSticker:"Contember_OrderDirection",
		secondSticker:"Contember_OrderDirection"
	},
	Contember_ProductRecipeLocale:{
		root:{
			filter:"Contember_ProductRecipeWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_ProductRecipe:{
		products:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		image:{
			filter:"Contember_ImageWhere"
		},
		icon:{
			filter:"Contember_ImageWhere"
		},
		ingredients:{
			filter:"Contember_ProductIngredientItemWhere",
			orderBy:"Contember_ProductIngredientItemOrderBy"
		},
		locales:{
			filter:"Contember_ProductRecipeLocaleWhere",
			orderBy:"Contember_ProductRecipeLocaleOrderBy"
		},
		freshingContainer:{
			filter:"Contember_FreshingContainerWhere"
		},
		nutritionFacts:{
			filter:"Contember_NutritionFactsWhere"
		},
		productsByCode:{
			by:"Contember_ProductRecipeProductsByCodeUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByStocks:{
			by:"Contember_ProductRecipeProductsByStocksUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByGroupPrices:{
			by:"Contember_ProductRecipeProductsByGroupPricesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByCartItems:{
			by:"Contember_ProductRecipeProductsByCartItemsUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByOrderItems:{
			by:"Contember_ProductRecipeProductsByOrderItemsUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByInheritedCategories:{
			by:"Contember_ProductRecipeProductsByInheritedCategoriesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByLocales:{
			by:"Contember_ProductRecipeProductsByLocalesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByCustomerPrices:{
			by:"Contember_ProductRecipeProductsByCustomerPricesUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByVirtualProduct:{
			by:"Contember_ProductRecipeProductsByVirtualProductUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByMeta:{
			by:"Contember_ProductRecipeProductsByMetaUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsByPage:{
			by:"Contember_ProductRecipeProductsByPageUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsBySalesDays:{
			by:"Contember_ProductRecipeProductsBySalesDaysUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsBySalesWeeks:{
			by:"Contember_ProductRecipeProductsBySalesWeeksUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		productsBySalesMonths:{
			by:"Contember_ProductRecipeProductsBySalesMonthsUniqueWhere",
			filter:"Contember_ProductWhere"
		},
		ingredientsByQuantity:{
			by:"Contember_ProductRecipeIngredientsByQuantityUniqueWhere",
			filter:"Contember_ProductIngredientItemWhere"
		},
		localesByLocale:{
			by:"Contember_ProductRecipeLocalesByLocaleUniqueWhere",
			filter:"Contember_ProductRecipeLocaleWhere"
		},
		paginateProducts:{
			filter:"Contember_ProductWhere",
			orderBy:"Contember_ProductOrderBy"
		},
		paginateIngredients:{
			filter:"Contember_ProductIngredientItemWhere",
			orderBy:"Contember_ProductIngredientItemOrderBy"
		},
		paginateLocales:{
			filter:"Contember_ProductRecipeLocaleWhere",
			orderBy:"Contember_ProductRecipeLocaleOrderBy"
		}
	},
	Contember_ProductIngredientItem:{
		recipe:{
			filter:"Contember_ProductRecipeWhere"
		},
		ingredient:{
			filter:"Contember_ProductIngredientWhere"
		},
		quantity:{
			filter:"Contember_ProductIngredientItemQuantityWhere"
		}
	},
	Contember_ProductIngredientItemQuantity:{
		unit:{
			filter:"Contember_ProductIngredientItemQuantityUnitWhere"
		}
	},
	Contember_ProductIngredientItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		internalOrder:"Contember_OrderDirection",
		recipe:"Contember_ProductRecipeOrderBy",
		ingredient:"Contember_ProductIngredientOrderBy",
		quantity:"Contember_ProductIngredientItemQuantityOrderBy"
	},
	Contember_ProductIngredientItemQuantityOrderBy:{
		id:"Contember_OrderDirection",
		unit:"Contember_ProductIngredientItemQuantityUnitOrderBy",
		value:"Contember_OrderDirection"
	},
	Contember_ProductRecipeLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_ProductRecipeOrderBy",
		locale:"Contember_LocaleOrderBy",
		description:"Contember_OrderDirection"
	},
	Contember_ProductRecipeProductsByCodeUniqueWhere:{

	},
	Contember_ProductRecipeProductsByStocksUniqueWhere:{
		stocks:"Contember_ProductStockUniqueWhere"
	},
	Contember_ProductRecipeProductsByGroupPricesUniqueWhere:{
		groupPrices:"Contember_ProductGroupPriceUniqueWhere"
	},
	Contember_ProductRecipeProductsByCartItemsUniqueWhere:{
		cartItems:"Contember_CartItemUniqueWhere"
	},
	Contember_ProductRecipeProductsByOrderItemsUniqueWhere:{
		orderItems:"Contember_OrderItemUniqueWhere"
	},
	Contember_ProductRecipeProductsByInheritedCategoriesUniqueWhere:{
		inheritedCategories:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_ProductRecipeProductsByLocalesUniqueWhere:{
		locales:"Contember_ProductLocaleUniqueWhere"
	},
	Contember_ProductRecipeProductsByCustomerPricesUniqueWhere:{
		customerPrices:"Contember_ProductCustomerPriceUniqueWhere"
	},
	Contember_ProductRecipeProductsByVirtualProductUniqueWhere:{
		virtualProduct:"Contember_VirtualProductUniqueWhere"
	},
	Contember_ProductRecipeProductsByMetaUniqueWhere:{
		meta:"Contember_ProductMetadataUniqueWhere"
	},
	Contember_ProductRecipeProductsByPageUniqueWhere:{
		page:"Contember_PageUniqueWhere"
	},
	Contember_ProductRecipeProductsBySalesDaysUniqueWhere:{
		salesDays:"Contember_ProductSalesDayUniqueWhere"
	},
	Contember_ProductRecipeProductsBySalesWeeksUniqueWhere:{
		salesWeeks:"Contember_ProductSalesWeekUniqueWhere"
	},
	Contember_ProductRecipeProductsBySalesMonthsUniqueWhere:{
		salesMonths:"Contember_ProductSalesMonthUniqueWhere"
	},
	Contember_ProductRecipeIngredientsByQuantityUniqueWhere:{
		quantity:"Contember_ProductIngredientItemQuantityUniqueWhere"
	},
	Contember_ProductRecipeLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_DeliveryMethodLocale:{
		locale:{
			filter:"Contember_LocaleWhere"
		},
		root:{
			filter:"Contember_DeliveryMethodWhere"
		}
	},
	Contember_DeliveryMethodLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		trackingCodeUrlTemplate:"Contember_OrderDirection",
		processingTitle:"Contember_OrderDirection",
		processingText:"Contember_OrderDirection",
		processingIconUrl:"Contember_OrderDirection",
		dispatchedTitle:"Contember_OrderDirection",
		dispatchedText:"Contember_OrderDirection",
		dispatchedIconUrl:"Contember_OrderDirection",
		fulfilledTitle:"Contember_OrderDirection",
		fulfilledText:"Contember_OrderDirection",
		fulfilledIconUrl:"Contember_OrderDirection",
		locale:"Contember_LocaleOrderBy",
		root:"Contember_DeliveryMethodOrderBy"
	},
	Contember_PipelineStageLocale:{
		root:{
			filter:"Contember_PipelineStageWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		}
	},
	Contember_PipelineStage:{
		items:{
			filter:"Contember_PipelineStageItemWhere",
			orderBy:"Contember_PipelineStageItemOrderBy"
		},
		pipeline:{
			filter:"Contember_PipelineWhere"
		},
		locales:{
			filter:"Contember_PipelineStageLocaleWhere",
			orderBy:"Contember_PipelineStageLocaleOrderBy"
		},
		sentiment:{
			filter:"Contember_PipelineStageSentimentWhere"
		},
		forms:{
			filter:"Contember_LeadFormWhere",
			orderBy:"Contember_LeadFormOrderBy"
		},
		itemsByLead:{
			by:"Contember_PipelineStageItemsByLeadUniqueWhere",
			filter:"Contember_PipelineStageItemWhere"
		},
		localesByLocale:{
			by:"Contember_PipelineStageLocalesByLocaleUniqueWhere",
			filter:"Contember_PipelineStageLocaleWhere"
		},
		formsByCode:{
			by:"Contember_PipelineStageFormsByCodeUniqueWhere",
			filter:"Contember_LeadFormWhere"
		},
		formsByLeads:{
			by:"Contember_PipelineStageFormsByLeadsUniqueWhere",
			filter:"Contember_LeadFormWhere"
		},
		paginateItems:{
			filter:"Contember_PipelineStageItemWhere",
			orderBy:"Contember_PipelineStageItemOrderBy"
		},
		paginateLocales:{
			filter:"Contember_PipelineStageLocaleWhere",
			orderBy:"Contember_PipelineStageLocaleOrderBy"
		},
		paginateForms:{
			filter:"Contember_LeadFormWhere",
			orderBy:"Contember_LeadFormOrderBy"
		}
	},
	Contember_PipelineStageItem:{
		list:{
			filter:"Contember_PipelineStageWhere"
		},
		lead:{
			filter:"Contember_PipelineLeadWhere"
		}
	},
	Contember_PipelineLead:{
		stageItem:{
			filter:"Contember_PipelineStageItemWhere"
		},
		customer:{
			filter:"Contember_CustomerWhere"
		},
		form:{
			filter:"Contember_LeadFormWhere"
		}
	},
	Contember_LeadForm:{
		stage:{
			filter:"Contember_PipelineStageWhere"
		},
		leads:{
			filter:"Contember_PipelineLeadWhere",
			orderBy:"Contember_PipelineLeadOrderBy"
		},
		leadsByStageItem:{
			by:"Contember_LeadFormLeadsByStageItemUniqueWhere",
			filter:"Contember_PipelineLeadWhere"
		},
		paginateLeads:{
			filter:"Contember_PipelineLeadWhere",
			orderBy:"Contember_PipelineLeadOrderBy"
		}
	},
	Contember_PipelineLeadOrderBy:{
		id:"Contember_OrderDirection",
		createdAt:"Contember_OrderDirection",
		stageItem:"Contember_PipelineStageItemOrderBy",
		contactPerson:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		cafeType:"Contember_OrderDirection",
		offerType:"Contember_OrderDirection",
		ownMachine:"Contember_OrderDirection",
		customerCity:"Contember_OrderDirection",
		companyId:"Contember_OrderDirection",
		customerName:"Contember_OrderDirection",
		email:"Contember_OrderDirection",
		phone:"Contember_OrderDirection",
		form:"Contember_LeadFormOrderBy",
		meta:"Contember_OrderDirection",
		multiroasterType:"Contember_OrderDirection"
	},
	Contember_PipelineStageItemOrderBy:{
		id:"Contember_OrderDirection",
		list:"Contember_PipelineStageOrderBy",
		lead:"Contember_PipelineLeadOrderBy",
		order:"Contember_OrderDirection"
	},
	Contember_PipelineStageOrderBy:{
		id:"Contember_OrderDirection",
		pipeline:"Contember_PipelineOrderBy",
		order:"Contember_OrderDirection",
		sentiment:"Contember_PipelineStageSentimentOrderBy",
		type:"Contember_OrderDirection",
		showItemsWeight:"Contember_OrderDirection",
		showItemsPrice:"Contember_OrderDirection"
	},
	Contember_PipelineOrderBy:{
		id:"Contember_OrderDirection",
		unique:"Contember_OrderDirection"
	},
	Contember_PipelineStageSentimentOrderBy:{
		id:"Contember_OrderDirection",
		internalName:"Contember_OrderDirection",
		backgroundColor:"Contember_OrderDirection",
		textColor:"Contember_OrderDirection"
	},
	Contember_LeadFormOrderBy:{
		id:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		stage:"Contember_PipelineStageOrderBy"
	},
	Contember_LeadFormLeadsByStageItemUniqueWhere:{
		stageItem:"Contember_PipelineStageItemUniqueWhere"
	},
	Contember_Pipeline:{
		stages:{
			filter:"Contember_PipelineStageWhere",
			orderBy:"Contember_PipelineStageOrderBy"
		},
		stagesByItems:{
			by:"Contember_PipelineStagesByItemsUniqueWhere",
			filter:"Contember_PipelineStageWhere"
		},
		stagesByLocales:{
			by:"Contember_PipelineStagesByLocalesUniqueWhere",
			filter:"Contember_PipelineStageWhere"
		},
		stagesByForms:{
			by:"Contember_PipelineStagesByFormsUniqueWhere",
			filter:"Contember_PipelineStageWhere"
		},
		paginateStages:{
			filter:"Contember_PipelineStageWhere",
			orderBy:"Contember_PipelineStageOrderBy"
		}
	},
	Contember_PipelineStagesByItemsUniqueWhere:{
		items:"Contember_PipelineStageItemUniqueWhere"
	},
	Contember_PipelineStagesByLocalesUniqueWhere:{
		locales:"Contember_PipelineStageLocaleUniqueWhere"
	},
	Contember_PipelineStagesByFormsUniqueWhere:{
		forms:"Contember_LeadFormUniqueWhere"
	},
	Contember_PipelineStageLocaleOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		root:"Contember_PipelineStageOrderBy",
		locale:"Contember_LocaleOrderBy"
	},
	Contember_PipelineStageItemsByLeadUniqueWhere:{
		lead:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_PipelineStageLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_PipelineStageFormsByCodeUniqueWhere:{

	},
	Contember_PipelineStageFormsByLeadsUniqueWhere:{
		leads:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_SubscriptionBoxLocale:{
		root:{
			filter:"Contember_SubscriptionBoxWhere"
		},
		locale:{
			filter:"Contember_LocaleWhere"
		},
		texts:{
			filter:"Contember_TextListWhere"
		},
		secondaryTexts:{
			filter:"Contember_TextListWhere"
		}
	},
	Contember_SubscriptionBox:{
		locales:{
			filter:"Contember_SubscriptionBoxLocaleWhere",
			orderBy:"Contember_SubscriptionBoxLocaleOrderBy"
		},
		localesByLocale:{
			by:"Contember_SubscriptionBoxLocalesByLocaleUniqueWhere",
			filter:"Contember_SubscriptionBoxLocaleWhere"
		},
		localesByTexts:{
			by:"Contember_SubscriptionBoxLocalesByTextsUniqueWhere",
			filter:"Contember_SubscriptionBoxLocaleWhere"
		},
		localesBySecondaryTexts:{
			by:"Contember_SubscriptionBoxLocalesBySecondaryTextsUniqueWhere",
			filter:"Contember_SubscriptionBoxLocaleWhere"
		},
		paginateLocales:{
			filter:"Contember_SubscriptionBoxLocaleWhere",
			orderBy:"Contember_SubscriptionBoxLocaleOrderBy"
		}
	},
	Contember_SubscriptionBoxLocaleOrderBy:{
		id:"Contember_OrderDirection",
		title:"Contember_OrderDirection",
		stickerText:"Contember_OrderDirection",
		descriptionText:"Contember_OrderDirection",
		root:"Contember_SubscriptionBoxOrderBy",
		locale:"Contember_LocaleOrderBy",
		secondaryTitle:"Contember_OrderDirection",
		texts:"Contember_TextListOrderBy",
		secondaryTexts:"Contember_TextListOrderBy"
	},
	Contember_SubscriptionBoxOrderBy:{
		id:"Contember_OrderDirection",
		unique:"Contember_OrderDirection"
	},
	Contember_SubscriptionBoxLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_SubscriptionBoxLocalesByTextsUniqueWhere:{
		texts:"Contember_TextListUniqueWhere"
	},
	Contember_SubscriptionBoxLocalesBySecondaryTextsUniqueWhere:{
		secondaryTexts:"Contember_TextListUniqueWhere"
	},
	Contember_LocaleBusinessCategoriesByRootUniqueWhere:{
		root:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_LocaleProductCategoryListsByRootUniqueWhere:{
		root:"Contember_ProductCategoryListUniqueWhere"
	},
	Contember_LocaleProductIngredientsByRootUniqueWhere:{
		root:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_LocaleOrderRecurrencesByRootUniqueWhere:{
		root:"Contember_OrderRecurrenceUniqueWhere"
	},
	Contember_LocaleBlogsByRootUniqueWhere:{
		root:"Contember_BlogUniqueWhere"
	},
	Contember_LocaleBlogPostsByRootUniqueWhere:{
		root:"Contember_BlogPostUniqueWhere"
	},
	Contember_LocaleBlogPostsByCoverUniqueWhere:{
		cover:"Contember_CoverUniqueWhere"
	},
	Contember_LocaleBlogPostsByContentUniqueWhere:{
		content:"Contember_ContentUniqueWhere"
	},
	Contember_LocaleBlogPostsByLinkUniqueWhere:{
		link:"Contember_LinkableUniqueWhere"
	},
	Contember_LocaleBlogPostsBySeoUniqueWhere:{
		seo:"Contember_SeoUniqueWhere"
	},
	Contember_LocaleMenusByRootUniqueWhere:{
		root:"Contember_SiteMenuUniqueWhere"
	},
	Contember_LocaleMenusByItemsUniqueWhere:{
		items:"Contember_MenuItemUniqueWhere"
	},
	Contember_LocaleMenusBySecondaryItemsUniqueWhere:{
		secondaryItems:"Contember_LinkListUniqueWhere"
	},
	Contember_LocalePagesByRootUniqueWhere:{
		root:"Contember_PageUniqueWhere"
	},
	Contember_LocalePagesByLinkUniqueWhere:{
		link:"Contember_LinkableUniqueWhere"
	},
	Contember_LocalePagesBySeoUniqueWhere:{
		seo:"Contember_SeoUniqueWhere"
	},
	Contember_LocalePagesByContentUniqueWhere:{
		content:"Contember_ContentUniqueWhere"
	},
	Contember_LocalePagesByCoverUniqueWhere:{
		cover:"Contember_CoverUniqueWhere"
	},
	Contember_LocaleProductIngredientCategoriesByRootUniqueWhere:{
		root:"Contember_ProductIngredientCategoryUniqueWhere"
	},
	Contember_LocaleAllergensByRootUniqueWhere:{
		root:"Contember_AllergenUniqueWhere"
	},
	Contember_LocaleProductPackingsByRootUniqueWhere:{
		root:"Contember_ProductPackingUniqueWhere"
	},
	Contember_LocaleProductsByRootUniqueWhere:{
		root:"Contember_ProductUniqueWhere"
	},
	Contember_LocaleProductsByFeatureListUniqueWhere:{
		featureList:"Contember_TextListUniqueWhere"
	},
	Contember_LocaleProductRecipesByRootUniqueWhere:{
		root:"Contember_ProductRecipeUniqueWhere"
	},
	Contember_LocalePointOfSaleCategoriesByRootUniqueWhere:{
		root:"Contember_PointOfSaleCategoryUniqueWhere"
	},
	Contember_LocalePointOfSaleCategoryProductsByRootUniqueWhere:{
		root:"Contember_PointOfSaleCategoryProductUniqueWhere"
	},
	Contember_LocaleDeliveryMethodsByRootUniqueWhere:{
		root:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_LocaleIngredientSuppliersByRootUniqueWhere:{
		root:"Contember_IngredientSupplierUniqueWhere"
	},
	Contember_LocaleIdentificationCodesByRootUniqueWhere:{
		root:"Contember_IdentificationCodeUniqueWhere"
	},
	Contember_LocalePipelineStagesByRootUniqueWhere:{
		root:"Contember_PipelineStageUniqueWhere"
	},
	Contember_LocaleSubscriptionBoxesByRootUniqueWhere:{
		root:"Contember_SubscriptionBoxUniqueWhere"
	},
	Contember_LocaleSubscriptionBoxesByTextsUniqueWhere:{
		texts:"Contember_TextListUniqueWhere"
	},
	Contember_LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere:{
		secondaryTexts:"Contember_TextListUniqueWhere"
	},
	Contember_LocaleBusinessServicesByRootUniqueWhere:{
		root:"Contember_BusinessServiceUniqueWhere"
	},
	Contember_PreparedPackingQuantity:{
		recipe:{
			filter:"Contember_ProductRecipeWhere"
		},
		packing:{
			filter:"Contember_ProductPackingWhere"
		}
	},
	Contember_PreparedPackingQuantityOrderBy:{
		id:"Contember_OrderDirection",
		date:"Contember_OrderDirection",
		quantity:"Contember_OrderDirection",
		recipe:"Contember_ProductRecipeOrderBy",
		packing:"Contember_ProductPackingOrderBy",
		packedInCardboard:"Contember_OrderDirection"
	},
	Contember_ProductPackingLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_ProductStock:{
		product:{
			filter:"Contember_ProductWhere"
		},
		store:{
			filter:"Contember_StoreWhere"
		}
	},
	Contember_ProductStockOrderBy:{
		id:"Contember_OrderDirection",
		quantity:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy",
		store:"Contember_StoreOrderBy"
	},
	Contember_StoreOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		code:"Contember_OrderDirection",
		description:"Contember_OrderDirection",
		internalNote:"Contember_OrderDirection"
	},
	Contember_ProductGroupPrice:{
		group:{
			filter:"Contember_CustomerGroupWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_CustomerGroup:{
		productPrices:{
			filter:"Contember_ProductGroupPriceWhere",
			orderBy:"Contember_ProductGroupPriceOrderBy"
		},
		customers:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		},
		productPricesByProduct:{
			by:"Contember_CustomerGroupProductPricesByProductUniqueWhere",
			filter:"Contember_ProductGroupPriceWhere"
		},
		customersByDoubleshotLegacyId:{
			by:"Contember_CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByAddresses:{
			by:"Contember_CustomerGroupCustomersByAddressesUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByDefaultBillingAddress:{
			by:"Contember_CustomerGroupCustomersByDefaultBillingAddressUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByDefaultPaymentMethod:{
			by:"Contember_CustomerGroupCustomersByDefaultPaymentMethodUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByCredits:{
			by:"Contember_CustomerGroupCustomersByCreditsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByPaymentMethods:{
			by:"Contember_CustomerGroupCustomersByPaymentMethodsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByOrders:{
			by:"Contember_CustomerGroupCustomersByOrdersUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByPermissions:{
			by:"Contember_CustomerGroupCustomersByPermissionsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByName:{
			by:"Contember_CustomerGroupCustomersByNameUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByIngredientRatings:{
			by:"Contember_CustomerGroupCustomersByIngredientRatingsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByBusiness:{
			by:"Contember_CustomerGroupCustomersByBusinessUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByCarts:{
			by:"Contember_CustomerGroupCustomersByCartsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByShortcutList:{
			by:"Contember_CustomerGroupCustomersByShortcutListUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByUser:{
			by:"Contember_CustomerGroupCustomersByUserUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByInvoices:{
			by:"Contember_CustomerGroupCustomersByInvoicesUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByInvoicingPlan:{
			by:"Contember_CustomerGroupCustomersByInvoicingPlanUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByBillingSubjects:{
			by:"Contember_CustomerGroupCustomersByBillingSubjectsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByOfferedProducts:{
			by:"Contember_CustomerGroupCustomersByOfferedProductsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByDefaultDeliveryAddress:{
			by:"Contember_CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByDefaultBillingSubject:{
			by:"Contember_CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByAnonymousSession:{
			by:"Contember_CustomerGroupCustomersByAnonymousSessionUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByBoughtVouchers:{
			by:"Contember_CustomerGroupCustomersByBoughtVouchersUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByOwnsVouchers:{
			by:"Contember_CustomerGroupCustomersByOwnsVouchersUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByRedeemedVouchers:{
			by:"Contember_CustomerGroupCustomersByRedeemedVouchersUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersBySpending:{
			by:"Contember_CustomerGroupCustomersBySpendingUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByCustomerTabs:{
			by:"Contember_CustomerGroupCustomersByCustomerTabsUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByLead:{
			by:"Contember_CustomerGroupCustomersByLeadUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		customersByMeta:{
			by:"Contember_CustomerGroupCustomersByMetaUniqueWhere",
			filter:"Contember_CustomerWhere"
		},
		paginateProductPrices:{
			filter:"Contember_ProductGroupPriceWhere",
			orderBy:"Contember_ProductGroupPriceOrderBy"
		},
		paginateCustomers:{
			filter:"Contember_CustomerWhere",
			orderBy:"Contember_CustomerOrderBy"
		}
	},
	Contember_ProductGroupPriceOrderBy:{
		id:"Contember_OrderDirection",
		priceCents:"Contember_OrderDirection",
		group:"Contember_CustomerGroupOrderBy",
		product:"Contember_ProductOrderBy"
	},
	Contember_CustomerGroupProductPricesByProductUniqueWhere:{
		product:"Contember_ProductUniqueWhere"
	},
	Contember_CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere:{

	},
	Contember_CustomerGroupCustomersByAddressesUniqueWhere:{
		addresses:"Contember_AddressUniqueWhere"
	},
	Contember_CustomerGroupCustomersByDefaultBillingAddressUniqueWhere:{
		defaultBillingAddress:"Contember_AddressUniqueWhere"
	},
	Contember_CustomerGroupCustomersByDefaultPaymentMethodUniqueWhere:{
		defaultPaymentMethod:"Contember_CustomerPaymentMethodUniqueWhere"
	},
	Contember_CustomerGroupCustomersByCreditsUniqueWhere:{
		credits:"Contember_CustomerCreditUniqueWhere"
	},
	Contember_CustomerGroupCustomersByPaymentMethodsUniqueWhere:{
		paymentMethods:"Contember_CustomerPaymentMethodUniqueWhere"
	},
	Contember_CustomerGroupCustomersByOrdersUniqueWhere:{
		orders:"Contember_OrderUniqueWhere"
	},
	Contember_CustomerGroupCustomersByPermissionsUniqueWhere:{
		permissions:"Contember_CustomerPermissionsUniqueWhere"
	},
	Contember_CustomerGroupCustomersByNameUniqueWhere:{
		name:"Contember_CustomerNameUniqueWhere"
	},
	Contember_CustomerGroupCustomersByIngredientRatingsUniqueWhere:{
		ingredientRatings:"Contember_CustomerIngredientRatingUniqueWhere"
	},
	Contember_CustomerGroupCustomersByBusinessUniqueWhere:{
		business:"Contember_BusinessCustomerUniqueWhere"
	},
	Contember_CustomerGroupCustomersByCartsUniqueWhere:{
		carts:"Contember_CartUniqueWhere"
	},
	Contember_CustomerGroupCustomersByShortcutListUniqueWhere:{
		shortcutList:"Contember_ApplicationShortcutListUniqueWhere"
	},
	Contember_CustomerGroupCustomersByUserUniqueWhere:{
		user:"Contember_UserUniqueWhere"
	},
	Contember_CustomerGroupCustomersByInvoicesUniqueWhere:{
		invoices:"Contember_InvoiceUniqueWhere"
	},
	Contember_CustomerGroupCustomersByInvoicingPlanUniqueWhere:{
		invoicingPlan:"Contember_InvoicingPlanUniqueWhere"
	},
	Contember_CustomerGroupCustomersByBillingSubjectsUniqueWhere:{
		billingSubjects:"Contember_BillingSubjectUniqueWhere"
	},
	Contember_CustomerGroupCustomersByOfferedProductsUniqueWhere:{
		offeredProducts:"Contember_ProductCustomerPriceUniqueWhere"
	},
	Contember_CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere:{
		defaultDeliveryAddress:"Contember_AddressUniqueWhere"
	},
	Contember_CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere:{
		defaultBillingSubject:"Contember_BillingSubjectUniqueWhere"
	},
	Contember_CustomerGroupCustomersByAnonymousSessionUniqueWhere:{
		anonymousSession:"Contember_AnonymousSessionUniqueWhere"
	},
	Contember_CustomerGroupCustomersByBoughtVouchersUniqueWhere:{
		boughtVouchers:"Contember_VoucherUniqueWhere"
	},
	Contember_CustomerGroupCustomersByOwnsVouchersUniqueWhere:{
		ownsVouchers:"Contember_VoucherUniqueWhere"
	},
	Contember_CustomerGroupCustomersByRedeemedVouchersUniqueWhere:{
		redeemedVouchers:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_CustomerGroupCustomersBySpendingUniqueWhere:{
		spending:"Contember_CustomerSpendingUniqueWhere"
	},
	Contember_CustomerGroupCustomersByCustomerTabsUniqueWhere:{
		customerTabs:"Contember_CustomerTabUniqueWhere"
	},
	Contember_CustomerGroupCustomersByLeadUniqueWhere:{
		lead:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_CustomerGroupCustomersByMetaUniqueWhere:{
		meta:"Contember_CustomerMetadataUniqueWhere"
	},
	Contember_ProductCustomerPrice:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_ProductCustomerPriceOrderBy:{
		id:"Contember_OrderDirection",
		groupDiscountPermille:"Contember_OrderDirection",
		groupPriceCents:"Contember_OrderDirection",
		fullPriceCents:"Contember_OrderDirection",
		discountedPriceCents:"Contember_OrderDirection",
		priceCents:"Contember_OrderDirection",
		offered:"Contember_OrderDirection",
		notDiscountable:"Contember_OrderDirection",
		customer:"Contember_CustomerOrderBy",
		product:"Contember_ProductOrderBy"
	},
	Contember_ProductMetadata:{
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_ProductSalesDay:{
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_ProductSalesDayOrderBy:{
		id:"Contember_OrderDirection",
		productName:"Contember_OrderDirection",
		orderDate:"Contember_OrderDirection",
		priceCentsSum:"Contember_OrderDirection",
		sum:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy"
	},
	Contember_ProductSalesWeek:{
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_ProductSalesWeekOrderBy:{
		id:"Contember_OrderDirection",
		productName:"Contember_OrderDirection",
		orderDate:"Contember_OrderDirection",
		priceCentsSum:"Contember_OrderDirection",
		sum:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy"
	},
	Contember_ProductSalesMonth:{
		product:{
			filter:"Contember_ProductWhere"
		}
	},
	Contember_ProductSalesMonthOrderBy:{
		id:"Contember_OrderDirection",
		productName:"Contember_OrderDirection",
		orderDate:"Contember_OrderDirection",
		priceCentsSum:"Contember_OrderDirection",
		sum:"Contember_OrderDirection",
		product:"Contember_ProductOrderBy"
	},
	Contember_ProductStocksByStoreUniqueWhere:{
		store:"Contember_StoreUniqueWhere"
	},
	Contember_ProductGroupPricesByGroupUniqueWhere:{
		group:"Contember_CustomerGroupUniqueWhere"
	},
	Contember_ProductCartItemsByCartUniqueWhere:{
		cart:"Contember_CartUniqueWhere"
	},
	Contember_ProductOrderItemsByVirtualProductEffectsUniqueWhere:{
		virtualProductEffects:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_ProductLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_ProductLocalesByFeatureListUniqueWhere:{
		featureList:"Contember_TextListUniqueWhere"
	},
	Contember_ProductCustomerPricesByCustomerUniqueWhere:{
		customer:"Contember_CustomerUniqueWhere"
	},
	Contember_AddressMetadata:{
		address:{
			filter:"Contember_AddressWhere"
		},
		deliveryZone:{
			filter:"Contember_DeliveryZoneWhere"
		}
	},
	Contember_DeliveryZoneAddressesByMetaUniqueWhere:{
		meta:"Contember_AddressMetadataUniqueWhere"
	},
	Contember_DeliveryZoneAddressesByGpsUniqueWhere:{
		gps:"Contember_GpsUniqueWhere"
	},
	Contember_DeliveryZoneAddressesByInvoicesUniqueWhere:{
		invoices:"Contember_InvoiceUniqueWhere"
	},
	Contember_DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere:{
		billingAddressOfOrders:"Contember_OrderUniqueWhere"
	},
	Contember_DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere:{
		deliveryAddressOfOrderDeliveries:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere:{
		defaultDeliveryAddressOfCustomer:"Contember_CustomerUniqueWhere"
	},
	Contember_DeliveryZoneAddressesMetadataByAddressUniqueWhere:{
		address:"Contember_AddressUniqueWhere"
	},
	Contember_DeliveryTimelinePreset:{
		deliveryMethod:{
			filter:"Contember_DeliveryMethodWhere"
		}
	},
	Contember_DeliveryMethodLocalesByLocaleUniqueWhere:{
		locale:"Contember_LocaleUniqueWhere"
	},
	Contember_CustomerPermissions:{
		customer:{
			filter:"Contember_CustomerWhere"
		}
	},
	Contember_CustomerName:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		aliases:{
			filter:"Contember_CustomerAliasWhere",
			orderBy:"Contember_CustomerAliasOrderBy"
		},
		paginateAliases:{
			filter:"Contember_CustomerAliasWhere",
			orderBy:"Contember_CustomerAliasOrderBy"
		}
	},
	Contember_CustomerAliasOrderBy:{
		id:"Contember_OrderDirection",
		name:"Contember_OrderDirection",
		order:"Contember_OrderDirection"
	},
	Contember_ApplicationShortcutList:{
		items:{
			filter:"Contember_ApplicationShortcutListItemWhere",
			orderBy:"Contember_ApplicationShortcutListItemOrderBy"
		},
		customer:{
			filter:"Contember_CustomerWhere"
		},
		paginateItems:{
			filter:"Contember_ApplicationShortcutListItemWhere",
			orderBy:"Contember_ApplicationShortcutListItemOrderBy"
		}
	},
	Contember_ApplicationShortcutListItem:{
		application:{
			filter:"Contember_ApplicationWhere"
		},
		list:{
			filter:"Contember_ApplicationShortcutListWhere"
		}
	},
	Contember_Application:{
		icon:{
			filter:"Contember_ImageWhere"
		}
	},
	Contember_ApplicationShortcutListItemOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		application:"Contember_ApplicationOrderBy",
		list:"Contember_ApplicationShortcutListOrderBy"
	},
	Contember_ApplicationOrderBy:{
		id:"Contember_OrderDirection",
		order:"Contember_OrderDirection",
		handle:"Contember_OrderDirection",
		url:"Contember_OrderDirection",
		color:"Contember_OrderDirection",
		icon:"Contember_ImageOrderBy",
		name:"Contember_OrderDirection",
		short:"Contember_OrderDirection"
	},
	Contember_InvoicingPlan:{
		customer:{
			filter:"Contember_CustomerWhere"
		},
		preset:{
			filter:"Contember_InvoicingPresetWhere"
		}
	},
	Contember_InvoicingPreset:{
		invoicingPlan:{
			filter:"Contember_InvoicingPlanWhere"
		}
	},
	Contember_CustomerSpending:{
		customer:{
			filter:"Contember_CustomerWhere"
		}
	},
	Contember_CustomerMetadata:{
		customer:{
			filter:"Contember_CustomerWhere"
		}
	},
	Contember_CustomerAddressesByMetaUniqueWhere:{
		meta:"Contember_AddressMetadataUniqueWhere"
	},
	Contember_CustomerAddressesByGpsUniqueWhere:{
		gps:"Contember_GpsUniqueWhere"
	},
	Contember_CustomerAddressesByInvoicesUniqueWhere:{
		invoices:"Contember_InvoiceUniqueWhere"
	},
	Contember_CustomerAddressesByBillingAddressOfOrdersUniqueWhere:{
		billingAddressOfOrders:"Contember_OrderUniqueWhere"
	},
	Contember_CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere:{
		deliveryAddressOfOrderDeliveries:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere:{
		defaultDeliveryAddressOfCustomer:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerCreditsByTransactionsUniqueWhere:{
		transactions:"Contember_CustomerCreditTransactionUniqueWhere"
	},
	Contember_CustomerCreditsByVoucherRedemptionUniqueWhere:{
		voucherRedemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_CustomerOrdersBySeqUniqueWhere:{

	},
	Contember_CustomerOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	Contember_CustomerOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	Contember_CustomerOrdersByDiscountsUniqueWhere:{
		discounts:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_CustomerOrdersByDeliveryUniqueWhere:{
		delivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_CustomerOrdersByItemsUniqueWhere:{
		items:"Contember_OrderItemUniqueWhere"
	},
	Contember_CustomerOrdersByCartUniqueWhere:{
		cart:"Contember_CartUniqueWhere"
	},
	Contember_CustomerOrdersByEventsUniqueWhere:{
		events:"Contember_OrderEventUniqueWhere"
	},
	Contember_CustomerOrdersByPriceLinesUniqueWhere:{
		priceLines:"Contember_OrderPriceLineUniqueWhere"
	},
	Contember_CustomerOrdersByPaymentsUniqueWhere:{
		payments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_CustomerOrdersBySummaryUniqueWhere:{
		summary:"Contember_OrderSummaryUniqueWhere"
	},
	Contember_CustomerOrdersByGratuityUniqueWhere:{
		gratuity:"Contember_GratuityUniqueWhere"
	},
	Contember_CustomerIngredientRatingsByIngredientUniqueWhere:{
		ingredient:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_CustomerCartsByItemsUniqueWhere:{
		items:"Contember_CartItemUniqueWhere"
	},
	Contember_CustomerCartsByOrderUniqueWhere:{
		order:"Contember_OrderUniqueWhere"
	},
	Contember_CustomerCartsByPriceLinesUniqueWhere:{
		priceLines:"Contember_CartPriceLineUniqueWhere"
	},
	Contember_CustomerCartsBySummaryUniqueWhere:{
		summary:"Contember_CartSummaryUniqueWhere"
	},
	Contember_CustomerInvoicesByPublicKeyUniqueWhere:{

	},
	Contember_CustomerInvoicesByFakturoidIdUniqueWhere:{

	},
	Contember_CustomerInvoicesByOrderPaymentsUniqueWhere:{
		orderPayments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_CustomerBillingSubjectsByInvoicesUniqueWhere:{
		invoices:"Contember_InvoiceUniqueWhere"
	},
	Contember_CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere:{
		defaultBillingSubjectOfCustomer:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerBillingSubjectsByOrdersUniqueWhere:{
		orders:"Contember_OrderUniqueWhere"
	},
	Contember_CustomerOfferedProductsByProductUniqueWhere:{
		product:"Contember_ProductUniqueWhere"
	},
	Contember_CustomerBoughtVouchersByCodeUniqueWhere:{

	},
	Contember_CustomerBoughtVouchersByRedemptionUniqueWhere:{
		redemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_CustomerOwnsVouchersByCodeUniqueWhere:{

	},
	Contember_CustomerOwnsVouchersByRedemptionUniqueWhere:{
		redemption:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_CustomerRedeemedVouchersByCustomerCreditUniqueWhere:{
		customerCredit:"Contember_CustomerCreditUniqueWhere"
	},
	Contember_CustomerRedeemedVouchersByOrderDiscountUniqueWhere:{
		orderDiscount:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_CustomerRedeemedVouchersByVoucherUniqueWhere:{
		voucher:"Contember_VoucherUniqueWhere"
	},
	Contember_CustomerCustomerTabsByItemsUniqueWhere:{
		items:"Contember_CustomerTabItemUniqueWhere"
	},
	Contember_CustomerCustomerTabsByMetaUniqueWhere:{
		meta:"Contember_CustomerTabMetadataUniqueWhere"
	},
	Contember_CustomerLeadByStageItemUniqueWhere:{
		stageItem:"Contember_PipelineStageItemUniqueWhere"
	},
	Contember_Gps:{
		address:{
			filter:"Contember_AddressWhere"
		}
	},
	Contember_AddressInvoicesByPublicKeyUniqueWhere:{

	},
	Contember_AddressInvoicesByFakturoidIdUniqueWhere:{

	},
	Contember_AddressInvoicesByOrderPaymentsUniqueWhere:{
		orderPayments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersBySeqUniqueWhere:{

	},
	Contember_AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere:{

	},
	Contember_AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere:{

	},
	Contember_AddressBillingAddressOfOrdersByDiscountsUniqueWhere:{
		discounts:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersByDeliveryUniqueWhere:{
		delivery:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersByItemsUniqueWhere:{
		items:"Contember_OrderItemUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersByCartUniqueWhere:{
		cart:"Contember_CartUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersByEventsUniqueWhere:{
		events:"Contember_OrderEventUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersByPriceLinesUniqueWhere:{
		priceLines:"Contember_OrderPriceLineUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersByPaymentsUniqueWhere:{
		payments:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersBySummaryUniqueWhere:{
		summary:"Contember_OrderSummaryUniqueWhere"
	},
	Contember_AddressBillingAddressOfOrdersByGratuityUniqueWhere:{
		gratuity:"Contember_GratuityUniqueWhere"
	},
	Contember_AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere:{

	},
	Contember_AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere:{
		order:"Contember_OrderUniqueWhere"
	},
	Contember_AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere:{
		deliveryWaypoint:"Contember_DeliveryWaypointUniqueWhere"
	},
	Contember_AddressDeliveryAddressOfOrderDeliveriesByEventsUniqueWhere:{
		events:"Contember_OrderEventUniqueWhere"
	},
	Contember_AddressDeliveryAddressOfOrderDeliveriesByLiftagoRideUniqueWhere:{
		liftagoRide:"Contember_LiftagoRideUniqueWhere"
	},
	Contember_AddressCreateInput:{
		id:"UUID",
		customer:"Contember_AddressCreateCustomerEntityRelationInput",
		country:"Contember_AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressCreateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressCreateMetaEntityRelationInput",
		gps:"Contember_AddressCreateGpsEntityRelationInput",
		invoices:"Contember_AddressCreateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutAddressesCreateInput"
	},
	Contember_CustomerWithoutAddressesCreateInput:{
		id:"UUID",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateDefaultBillingAddressEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressCreateInput"
	},
	Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput:{
		connect:"Contember_CustomerPaymentMethodUniqueWhere",
		create:"Contember_CustomerPaymentMethodCreateInput"
	},
	Contember_CustomerPaymentMethodCreateInput:{
		id:"UUID",
		data:"Json",
		customer:"Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput",
		paymentMethod:"Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"
	},
	Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutPaymentMethodsCreateInput"
	},
	Contember_CustomerWithoutPaymentMethodsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateAddressesEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressWithoutCustomerCreateInput"
	},
	Contember_AddressWithoutCustomerCreateInput:{
		id:"UUID",
		country:"Contember_AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressCreateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressCreateMetaEntityRelationInput",
		gps:"Contember_AddressCreateGpsEntityRelationInput",
		invoices:"Contember_AddressCreateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressCreateCountryEntityRelationInput:{
		connect:"Contember_CountryUniqueWhere",
		create:"Contember_CountryCreateInput"
	},
	Contember_CountryUniqueWhere:{
		id:"UUID"
	},
	Contember_CountryCreateInput:{
		id:"UUID"
	},
	Contember_AddressCreateDeliveryZoneEntityRelationInput:{
		connect:"Contember_DeliveryZoneUniqueWhere",
		create:"Contember_DeliveryZoneWithoutAddressesCreateInput"
	},
	Contember_DeliveryZoneUniqueWhere:{
		id:"UUID",
		addresses:"Contember_AddressUniqueWhere",
		addressesMetadata:"Contember_AddressMetadataUniqueWhere"
	},
	Contember_DeliveryZoneWithoutAddressesCreateInput:{
		id:"UUID",
		type:"Contember_DeliveryZoneType",
		suitableDeliveryMethods:"Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput",
		deliveryZonesOfTags:"Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput",
		deliveryZonesOfProducts:"Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput:{
		connect:"Contember_DeliveryMethodUniqueWhere",
		create:"Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"
	},
	Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput:{
		id:"UUID",
		paymentMethods:"Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput",
		channels:"Contember_DeliveryMethodCreateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodCreateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput:{
		connect:"Contember_PaymentMethodUniqueWhere",
		create:"Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"
	},
	Contember_PaymentMethodUniqueWhere:{
		id:"UUID",
		channels:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_PaymentMethodWithoutDeliveryMethodsCreateInput:{
		id:"UUID",
		type:"Contember_PaymentMethodType",
		channels:"Contember_PaymentMethodCreateChannelsEntityRelationInput"
	},
	Contember_PaymentMethodCreateChannelsEntityRelationInput:{
		connect:"Contember_ChannelPaymentUniqueWhere",
		create:"Contember_ChannelPaymentWithoutMethodCreateInput"
	},
	Contember_ChannelPaymentWithoutMethodCreateInput:{
		id:"UUID",
		channel:"Contember_ChannelPaymentCreateChannelEntityRelationInput",
		vatRate:"Contember_ChannelPaymentCreateVatRateEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_ChannelPaymentCreateChannelEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelWithoutPaymentsCreateInput"
	},
	Contember_ChannelWithoutPaymentsCreateInput:{
		id:"UUID",
		deliveries:"Contember_ChannelCreateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelCreateCartsEntityRelationInput",
		customers:"Contember_ChannelCreateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelCreatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelCreateVenueEntityRelationInput"
	},
	Contember_ChannelCreateDeliveriesEntityRelationInput:{
		connect:"Contember_ChannelDeliveryUniqueWhere",
		create:"Contember_ChannelDeliveryWithoutChannelCreateInput"
	},
	Contember_ChannelDeliveryWithoutChannelCreateInput:{
		id:"UUID",
		method:"Contember_ChannelDeliveryCreateMethodEntityRelationInput",
		vatRate:"Contember_ChannelDeliveryCreateVatRateEntityRelationInput"
	},
	Contember_ChannelDeliveryCreateMethodEntityRelationInput:{
		connect:"Contember_DeliveryMethodUniqueWhere",
		create:"Contember_DeliveryMethodWithoutChannelsCreateInput"
	},
	Contember_DeliveryMethodWithoutChannelsCreateInput:{
		id:"UUID",
		paymentMethods:"Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodCreateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput:{
		connect:"Contember_DeliveryZoneUniqueWhere",
		create:"Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"
	},
	Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput:{
		id:"UUID",
		type:"Contember_DeliveryZoneType",
		addresses:"Contember_DeliveryZoneCreateAddressesEntityRelationInput",
		deliveryZonesOfTags:"Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput",
		deliveryZonesOfProducts:"Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneCreateAddressesEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressWithoutDeliveryZoneCreateInput"
	},
	Contember_AddressWithoutDeliveryZoneCreateInput:{
		id:"UUID",
		customer:"Contember_AddressCreateCustomerEntityRelationInput",
		country:"Contember_AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		meta:"Contember_AddressCreateMetaEntityRelationInput",
		gps:"Contember_AddressCreateGpsEntityRelationInput",
		invoices:"Contember_AddressCreateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressCreateMetaEntityRelationInput:{
		connect:"Contember_AddressMetadataUniqueWhere"
	},
	Contember_AddressCreateGpsEntityRelationInput:{
		connect:"Contember_GpsUniqueWhere",
		create:"Contember_GpsWithoutAddressCreateInput"
	},
	Contember_GpsWithoutAddressCreateInput:{
		id:"UUID"
	},
	Contember_AddressCreateInvoicesEntityRelationInput:{
		connect:"Contember_InvoiceUniqueWhere",
		create:"Contember_InvoiceWithoutAddressCreateInput"
	},
	Contember_InvoiceWithoutAddressCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customer:"Contember_InvoiceCreateCustomerEntityRelationInput",
		orderPayments:"Contember_InvoiceCreateOrderPaymentsEntityRelationInput",
		billingSubject:"Contember_InvoiceCreateBillingSubjectEntityRelationInput"
	},
	Contember_InvoiceCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutInvoicesCreateInput"
	},
	Contember_CustomerWithoutInvoicesCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateTagsEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutCustomersCreateInput"
	},
	Contember_TagUniqueWhere:{
		id:"UUID",
		nestedCategories:"Contember_InheritedProductCategoryTagUniqueWhere"
	},
	Contember_TagWithoutCustomersCreateInput:{
		id:"UUID",
		products:"Contember_TagCreateProductsEntityRelationInput",
		categories:"Contember_TagCreateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagCreateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagCreateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagCreatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagCreateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagCreateProductsEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutTagsCreateInput"
	},
	Contember_ProductWithoutTagsCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreatePackingEntityRelationInput:{
		connect:"Contember_ProductPackingUniqueWhere",
		create:"Contember_ProductPackingCreateInput"
	},
	Contember_ProductPackingCreateInput:{
		id:"UUID",
		image:"Contember_ProductPackingCreateImageEntityRelationInput",
		icon:"Contember_ProductPackingCreateIconEntityRelationInput",
		locales:"Contember_ProductPackingCreateLocalesEntityRelationInput",
		preparedPackingQuantities:"Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"
	},
	Contember_ProductPackingCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ImageCreateInput:{
		id:"UUID"
	},
	Contember_ProductPackingCreateIconEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductPackingCreateLocalesEntityRelationInput:{
		connect:"Contember_ProductPackingLocaleUniqueWhere",
		create:"Contember_ProductPackingLocaleWithoutRootCreateInput"
	},
	Contember_ProductPackingLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_ProductPackingLocaleCreateLocaleEntityRelationInput"
	},
	Contember_ProductPackingLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutProductPackingsCreateInput"
	},
	Contember_LocaleWithoutProductPackingsCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateBusinessCategoriesEntityRelationInput:{
		connect:"Contember_BusinessCategoryLocaleUniqueWhere",
		create:"Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"
	},
	Contember_BusinessCategoryLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_BusinessCategoryLocaleCreateRootEntityRelationInput"
	},
	Contember_BusinessCategoryLocaleCreateRootEntityRelationInput:{
		connect:"Contember_BusinessCategoryUniqueWhere",
		create:"Contember_BusinessCategoryWithoutLocalesCreateInput"
	},
	Contember_BusinessCategoryWithoutLocalesCreateInput:{
		id:"UUID",
		businessCustomers:"Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryCreateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput:{
		connect:"Contember_BusinessCustomerUniqueWhere",
		create:"Contember_BusinessCustomerWithoutCategoryCreateInput"
	},
	Contember_BusinessCustomerWithoutCategoryCreateInput:{
		id:"UUID",
		customer:"Contember_BusinessCustomerCreateCustomerEntityRelationInput",
		owners:"Contember_BusinessCustomerCreateOwnersEntityRelationInput",
		members:"Contember_BusinessCustomerCreateMembersEntityRelationInput",
		accountManager:"Contember_BusinessCustomerCreateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerCreateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutBusinessCreateInput"
	},
	Contember_CustomerWithoutBusinessCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateCreditsEntityRelationInput:{
		connect:"Contember_CustomerCreditUniqueWhere",
		create:"Contember_CustomerCreditWithoutCustomerCreateInput"
	},
	Contember_CustomerCreditWithoutCustomerCreateInput:{
		id:"UUID",
		expiresAt:"DateTime",
		vatRate:"Contember_CustomerCreditCreateVatRateEntityRelationInput",
		createdAt:"DateTime",
		transactions:"Contember_CustomerCreditCreateTransactionsEntityRelationInput",
		voucherRedemption:"Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"
	},
	Contember_CustomerCreditCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_VatRateUniqueWhere:{
		id:"UUID",
		preset:"Contember_VatRatePreset",
		channelDeliveries:"Contember_ChannelDeliveryUniqueWhere",
		channelPayments:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_VatRateCreateInput:{
		id:"UUID",
		channelDeliveries:"Contember_VatRateCreateChannelDeliveriesEntityRelationInput",
		channelPayments:"Contember_VatRateCreateChannelPaymentsEntityRelationInput",
		preset:"Contember_VatRatePreset"
	},
	Contember_VatRateCreateChannelDeliveriesEntityRelationInput:{
		connect:"Contember_ChannelDeliveryUniqueWhere",
		create:"Contember_ChannelDeliveryWithoutVatRateCreateInput"
	},
	Contember_ChannelDeliveryWithoutVatRateCreateInput:{
		id:"UUID",
		channel:"Contember_ChannelDeliveryCreateChannelEntityRelationInput",
		method:"Contember_ChannelDeliveryCreateMethodEntityRelationInput"
	},
	Contember_ChannelDeliveryCreateChannelEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelWithoutDeliveriesCreateInput"
	},
	Contember_ChannelWithoutDeliveriesCreateInput:{
		id:"UUID",
		payments:"Contember_ChannelCreatePaymentsEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelCreateCartsEntityRelationInput",
		customers:"Contember_ChannelCreateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelCreatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelCreateVenueEntityRelationInput"
	},
	Contember_ChannelCreatePaymentsEntityRelationInput:{
		connect:"Contember_ChannelPaymentUniqueWhere",
		create:"Contember_ChannelPaymentWithoutChannelCreateInput"
	},
	Contember_ChannelPaymentWithoutChannelCreateInput:{
		id:"UUID",
		method:"Contember_ChannelPaymentCreateMethodEntityRelationInput",
		vatRate:"Contember_ChannelPaymentCreateVatRateEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_ChannelPaymentCreateMethodEntityRelationInput:{
		connect:"Contember_PaymentMethodUniqueWhere",
		create:"Contember_PaymentMethodWithoutChannelsCreateInput"
	},
	Contember_PaymentMethodWithoutChannelsCreateInput:{
		id:"UUID",
		deliveryMethods:"Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput",
		type:"Contember_PaymentMethodType"
	},
	Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput:{
		connect:"Contember_DeliveryMethodUniqueWhere",
		create:"Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"
	},
	Contember_DeliveryMethodWithoutPaymentMethodsCreateInput:{
		id:"UUID",
		suitableForDeliveryZones:"Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodCreateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodCreateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodCreateChannelsEntityRelationInput:{
		connect:"Contember_ChannelDeliveryUniqueWhere",
		create:"Contember_ChannelDeliveryWithoutMethodCreateInput"
	},
	Contember_ChannelDeliveryWithoutMethodCreateInput:{
		id:"UUID",
		channel:"Contember_ChannelDeliveryCreateChannelEntityRelationInput",
		vatRate:"Contember_ChannelDeliveryCreateVatRateEntityRelationInput"
	},
	Contember_ChannelDeliveryCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateWithoutChannelDeliveriesCreateInput"
	},
	Contember_VatRateWithoutChannelDeliveriesCreateInput:{
		id:"UUID",
		channelPayments:"Contember_VatRateCreateChannelPaymentsEntityRelationInput",
		preset:"Contember_VatRatePreset"
	},
	Contember_VatRateCreateChannelPaymentsEntityRelationInput:{
		connect:"Contember_ChannelPaymentUniqueWhere",
		create:"Contember_ChannelPaymentWithoutVatRateCreateInput"
	},
	Contember_ChannelPaymentWithoutVatRateCreateInput:{
		id:"UUID",
		channel:"Contember_ChannelPaymentCreateChannelEntityRelationInput",
		method:"Contember_ChannelPaymentCreateMethodEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutAllowChannelPaymentsCreateInput"
	},
	Contember_TagWithoutAllowChannelPaymentsCreateInput:{
		id:"UUID",
		customers:"Contember_TagCreateCustomersEntityRelationInput",
		products:"Contember_TagCreateProductsEntityRelationInput",
		categories:"Contember_TagCreateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagCreateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagCreateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagCreatePublicInChannelsEntityRelationInput",
		fulfillmentNotes:"Contember_TagCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagCreateCustomersEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutTagsCreateInput"
	},
	Contember_CustomerWithoutTagsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreatePaymentMethodsEntityRelationInput:{
		connect:"Contember_CustomerPaymentMethodUniqueWhere",
		create:"Contember_CustomerPaymentMethodWithoutCustomerCreateInput"
	},
	Contember_CustomerPaymentMethodWithoutCustomerCreateInput:{
		id:"UUID",
		data:"Json",
		paymentMethod:"Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"
	},
	Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput:{
		connect:"Contember_PaymentMethodUniqueWhere",
		create:"Contember_PaymentMethodCreateInput"
	},
	Contember_PaymentMethodCreateInput:{
		id:"UUID",
		deliveryMethods:"Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput",
		type:"Contember_PaymentMethodType",
		channels:"Contember_PaymentMethodCreateChannelsEntityRelationInput"
	},
	Contember_CustomerCreateOrdersEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutCustomerCreateInput"
	},
	Contember_OrderWithoutCustomerCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateChannelEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelCreateInput"
	},
	Contember_ChannelCreateInput:{
		id:"UUID",
		payments:"Contember_ChannelCreatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelCreateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelCreateCartsEntityRelationInput",
		customers:"Contember_ChannelCreateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelCreatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelCreateVenueEntityRelationInput"
	},
	Contember_ChannelCreatePointOfSalesEntityRelationInput:{
		connect:"Contember_PointOfSaleUniqueWhere",
		create:"Contember_PointOfSaleWithoutChannelsCreateInput"
	},
	Contember_PointOfSaleUniqueWhere:{
		id:"UUID",
		tenantPersonId:"UUID",
		permissions:"Contember_PointOfSalePermissionsUniqueWhere"
	},
	Contember_PointOfSalePermissionsUniqueWhere:{
		id:"UUID",
		pointOfSale:"Contember_PointOfSaleUniqueWhere"
	},
	Contember_PointOfSaleWithoutChannelsCreateInput:{
		id:"UUID",
		permissions:"Contember_PointOfSaleCreatePermissionsEntityRelationInput",
		tenantPersonId:"UUID"
	},
	Contember_PointOfSaleCreatePermissionsEntityRelationInput:{
		connect:"Contember_PointOfSalePermissionsUniqueWhere"
	},
	Contember_ChannelCreateCartsEntityRelationInput:{
		connect:"Contember_CartUniqueWhere",
		create:"Contember_CartWithoutChannelCreateInput"
	},
	Contember_CartWithoutChannelCreateInput:{
		id:"UUID",
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"Contember_CartCreateItemsEntityRelationInput",
		state:"Contember_CartState",
		createdAt:"DateTime",
		order:"Contember_CartCreateOrderEntityRelationInput",
		customer:"Contember_CartCreateCustomerEntityRelationInput",
		deliveryAddress:"Contember_CartCreateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartCreateBillingAddressEntityRelationInput",
		delivery:"Contember_CartCreateDeliveryEntityRelationInput",
		payment:"Contember_CartCreatePaymentEntityRelationInput",
		billingSubject:"Contember_CartCreateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartCreatePriceLinesEntityRelationInput",
		summary:"Contember_CartCreateSummaryEntityRelationInput"
	},
	Contember_CartCreateItemsEntityRelationInput:{
		connect:"Contember_CartItemUniqueWhere",
		create:"Contember_CartItemWithoutCartCreateInput"
	},
	Contember_CartItemWithoutCartCreateInput:{
		id:"UUID",
		product:"Contember_CartItemCreateProductEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		fulfillmentNotes:"Contember_CartItemCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_CartItemCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutCartItemsCreateInput"
	},
	Contember_ProductWithoutCartItemsCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateRecipeEntityRelationInput:{
		connect:"Contember_ProductRecipeUniqueWhere",
		create:"Contember_ProductRecipeWithoutProductsCreateInput"
	},
	Contember_ProductRecipeWithoutProductsCreateInput:{
		id:"UUID",
		image:"Contember_ProductRecipeCreateImageEntityRelationInput",
		icon:"Contember_ProductRecipeCreateIconEntityRelationInput",
		ingredients:"Contember_ProductRecipeCreateIngredientsEntityRelationInput",
		locales:"Contember_ProductRecipeCreateLocalesEntityRelationInput",
		freshingContainer:"Contember_ProductRecipeCreateFreshingContainerEntityRelationInput",
		nutritionFacts:"Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"
	},
	Contember_ProductRecipeCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductRecipeCreateIconEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductRecipeCreateIngredientsEntityRelationInput:{
		connect:"Contember_ProductIngredientItemUniqueWhere",
		create:"Contember_ProductIngredientItemWithoutRecipeCreateInput"
	},
	Contember_ProductIngredientItemWithoutRecipeCreateInput:{
		id:"UUID",
		ingredient:"Contember_ProductIngredientItemCreateIngredientEntityRelationInput",
		quantity:"Contember_ProductIngredientItemCreateQuantityEntityRelationInput"
	},
	Contember_ProductIngredientItemCreateIngredientEntityRelationInput:{
		connect:"Contember_ProductIngredientUniqueWhere",
		create:"Contember_ProductIngredientCreateInput"
	},
	Contember_ProductIngredientCreateInput:{
		id:"UUID",
		locales:"Contember_ProductIngredientCreateLocalesEntityRelationInput",
		categories:"Contember_ProductIngredientCreateCategoriesEntityRelationInput",
		allergens:"Contember_ProductIngredientCreateAllergensEntityRelationInput",
		customerRatings:"Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientCreateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientCreateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientCreateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientCreateLocalesEntityRelationInput:{
		connect:"Contember_ProductIngredientLocaleUniqueWhere",
		create:"Contember_ProductIngredientLocaleWithoutRootCreateInput"
	},
	Contember_ProductIngredientLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput"
	},
	Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutProductIngredientsCreateInput"
	},
	Contember_LocaleWithoutProductIngredientsCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateProductCategoryListsEntityRelationInput:{
		connect:"Contember_ProductCategoryListLocaleUniqueWhere",
		create:"Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductCategoryListLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_ProductCategoryListLocaleCreateRootEntityRelationInput"
	},
	Contember_ProductCategoryListLocaleCreateRootEntityRelationInput:{
		connect:"Contember_ProductCategoryListUniqueWhere",
		create:"Contember_ProductCategoryListWithoutLocalesCreateInput"
	},
	Contember_ProductCategoryListWithoutLocalesCreateInput:{
		id:"UUID",
		items:"Contember_ProductCategoryListCreateItemsEntityRelationInput",
		seasonalProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListCreateItemsEntityRelationInput:{
		connect:"Contember_ProductCategoryListItemUniqueWhere",
		create:"Contember_ProductCategoryListItemWithoutListCreateInput"
	},
	Contember_ProductCategoryListItemWithoutListCreateInput:{
		id:"UUID",
		productCategory:"Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput:{
		connect:"Contember_ProductCategoryUniqueWhere",
		create:"Contember_ProductCategoryCreateInput"
	},
	Contember_ProductCategoryCreateInput:{
		id:"UUID",
		products:"Contember_ProductCategoryCreateProductsEntityRelationInput",
		tags:"Contember_ProductCategoryCreateTagsEntityRelationInput",
		parent:"Contember_ProductCategoryCreateParentEntityRelationInput",
		children:"Contember_ProductCategoryCreateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryCreateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryCreateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryCreateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryCreateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryCreateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryCreateProductsEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutCategoriesCreateInput"
	},
	Contember_ProductWithoutCategoriesCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateTagsEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutProductsCreateInput"
	},
	Contember_TagWithoutProductsCreateInput:{
		id:"UUID",
		customers:"Contember_TagCreateCustomersEntityRelationInput",
		categories:"Contember_TagCreateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagCreateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagCreateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagCreatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagCreateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagCreateCategoriesEntityRelationInput:{
		connect:"Contember_ProductCategoryUniqueWhere",
		create:"Contember_ProductCategoryWithoutTagsCreateInput"
	},
	Contember_ProductCategoryWithoutTagsCreateInput:{
		id:"UUID",
		products:"Contember_ProductCategoryCreateProductsEntityRelationInput",
		parent:"Contember_ProductCategoryCreateParentEntityRelationInput",
		children:"Contember_ProductCategoryCreateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryCreateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryCreateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryCreateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryCreateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryCreateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryCreateParentEntityRelationInput:{
		connect:"Contember_ProductCategoryUniqueWhere",
		create:"Contember_ProductCategoryWithoutChildrenCreateInput"
	},
	Contember_ProductCategoryWithoutChildrenCreateInput:{
		id:"UUID",
		products:"Contember_ProductCategoryCreateProductsEntityRelationInput",
		tags:"Contember_ProductCategoryCreateTagsEntityRelationInput",
		parent:"Contember_ProductCategoryCreateParentEntityRelationInput",
		image:"Contember_ProductCategoryCreateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryCreateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryCreateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryCreateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryCreateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryCreateTagsEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutCategoriesCreateInput"
	},
	Contember_TagWithoutCategoriesCreateInput:{
		id:"UUID",
		customers:"Contember_TagCreateCustomersEntityRelationInput",
		products:"Contember_TagCreateProductsEntityRelationInput",
		addTagsOnRegistration:"Contember_TagCreateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagCreateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagCreatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagCreateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagCreateAddTagsOnRegistrationEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"
	},
	Contember_ChannelWithoutAddTagsOnRegistrationCreateInput:{
		id:"UUID",
		payments:"Contember_ChannelCreatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelCreateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelCreateCartsEntityRelationInput",
		customers:"Contember_ChannelCreateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		publicTags:"Contember_ChannelCreatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelCreateVenueEntityRelationInput"
	},
	Contember_ChannelCreateCustomersEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutChannelsCreateInput"
	},
	Contember_CustomerWithoutChannelsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreatePermissionsEntityRelationInput:{
		connect:"Contember_CustomerPermissionsUniqueWhere"
	},
	Contember_CustomerCreateGroupEntityRelationInput:{
		connect:"Contember_CustomerGroupUniqueWhere",
		create:"Contember_CustomerGroupWithoutCustomersCreateInput"
	},
	Contember_CustomerGroupWithoutCustomersCreateInput:{
		id:"UUID",
		productPrices:"Contember_CustomerGroupCreateProductPricesEntityRelationInput"
	},
	Contember_CustomerGroupCreateProductPricesEntityRelationInput:{
		connect:"Contember_ProductGroupPriceUniqueWhere",
		create:"Contember_ProductGroupPriceWithoutGroupCreateInput"
	},
	Contember_ProductGroupPriceWithoutGroupCreateInput:{
		id:"UUID",
		product:"Contember_ProductGroupPriceCreateProductEntityRelationInput"
	},
	Contember_ProductGroupPriceCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutGroupPricesCreateInput"
	},
	Contember_ProductWithoutGroupPricesCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateCategoriesEntityRelationInput:{
		connect:"Contember_ProductCategoryUniqueWhere",
		create:"Contember_ProductCategoryWithoutProductsCreateInput"
	},
	Contember_ProductCategoryWithoutProductsCreateInput:{
		id:"UUID",
		tags:"Contember_ProductCategoryCreateTagsEntityRelationInput",
		parent:"Contember_ProductCategoryCreateParentEntityRelationInput",
		children:"Contember_ProductCategoryCreateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryCreateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryCreateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryCreateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryCreateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryCreateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryCreateChildrenEntityRelationInput:{
		connect:"Contember_ProductCategoryUniqueWhere",
		create:"Contember_ProductCategoryWithoutParentCreateInput"
	},
	Contember_ProductCategoryWithoutParentCreateInput:{
		id:"UUID",
		products:"Contember_ProductCategoryCreateProductsEntityRelationInput",
		tags:"Contember_ProductCategoryCreateTagsEntityRelationInput",
		children:"Contember_ProductCategoryCreateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryCreateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryCreateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryCreateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryCreateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryCreateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductCategoryCreateDescendantsEntityRelationInput:{
		connect:"Contember_ProductCategoryTreeUniqueWhere"
	},
	Contember_ProductCategoryCreateAncestorsEntityRelationInput:{
		connect:"Contember_ProductCategoryTreeUniqueWhere"
	},
	Contember_ProductCategoryCreateInheritedTagsEntityRelationInput:{
		connect:"Contember_InheritedProductCategoryTagUniqueWhere"
	},
	Contember_ProductCategoryCreateNestedProductsEntityRelationInput:{
		connect:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_ProductCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_ProductCreateStocksEntityRelationInput:{
		connect:"Contember_ProductStockUniqueWhere",
		create:"Contember_ProductStockWithoutProductCreateInput"
	},
	Contember_ProductStockWithoutProductCreateInput:{
		id:"UUID",
		store:"Contember_ProductStockCreateStoreEntityRelationInput"
	},
	Contember_ProductStockCreateStoreEntityRelationInput:{
		connect:"Contember_StoreUniqueWhere",
		create:"Contember_StoreCreateInput"
	},
	Contember_StoreCreateInput:{
		id:"UUID"
	},
	Contember_ProductCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductCreateCartItemsEntityRelationInput:{
		connect:"Contember_CartItemUniqueWhere",
		create:"Contember_CartItemWithoutProductCreateInput"
	},
	Contember_CartItemWithoutProductCreateInput:{
		id:"UUID",
		cart:"Contember_CartItemCreateCartEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		fulfillmentNotes:"Contember_CartItemCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_CartItemCreateCartEntityRelationInput:{
		connect:"Contember_CartUniqueWhere",
		create:"Contember_CartWithoutItemsCreateInput"
	},
	Contember_CartWithoutItemsCreateInput:{
		id:"UUID",
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		state:"Contember_CartState",
		createdAt:"DateTime",
		channel:"Contember_CartCreateChannelEntityRelationInput",
		order:"Contember_CartCreateOrderEntityRelationInput",
		customer:"Contember_CartCreateCustomerEntityRelationInput",
		deliveryAddress:"Contember_CartCreateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartCreateBillingAddressEntityRelationInput",
		delivery:"Contember_CartCreateDeliveryEntityRelationInput",
		payment:"Contember_CartCreatePaymentEntityRelationInput",
		billingSubject:"Contember_CartCreateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartCreatePriceLinesEntityRelationInput",
		summary:"Contember_CartCreateSummaryEntityRelationInput"
	},
	Contember_CartCreateChannelEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelWithoutCartsCreateInput"
	},
	Contember_ChannelWithoutCartsCreateInput:{
		id:"UUID",
		payments:"Contember_ChannelCreatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelCreateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		customers:"Contember_ChannelCreateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelCreatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelCreateVenueEntityRelationInput"
	},
	Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutAddTagsOnRegistrationCreateInput"
	},
	Contember_TagWithoutAddTagsOnRegistrationCreateInput:{
		id:"UUID",
		customers:"Contember_TagCreateCustomersEntityRelationInput",
		products:"Contember_TagCreateProductsEntityRelationInput",
		categories:"Contember_TagCreateCategoriesEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagCreateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagCreatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagCreateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagCreateNestedCategoriesEntityRelationInput:{
		connect:"Contember_InheritedProductCategoryTagUniqueWhere"
	},
	Contember_TagCreateDeliveryZonesEntityRelationInput:{
		connect:"Contember_DeliveryZoneUniqueWhere",
		create:"Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"
	},
	Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput:{
		id:"UUID",
		type:"Contember_DeliveryZoneType",
		addresses:"Contember_DeliveryZoneCreateAddressesEntityRelationInput",
		suitableDeliveryMethods:"Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput",
		deliveryZonesOfProducts:"Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutDeliveryZonesCreateInput"
	},
	Contember_ProductWithoutDeliveryZonesCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateGroupPricesEntityRelationInput:{
		connect:"Contember_ProductGroupPriceUniqueWhere",
		create:"Contember_ProductGroupPriceWithoutProductCreateInput"
	},
	Contember_ProductGroupPriceWithoutProductCreateInput:{
		id:"UUID",
		group:"Contember_ProductGroupPriceCreateGroupEntityRelationInput"
	},
	Contember_ProductGroupPriceCreateGroupEntityRelationInput:{
		connect:"Contember_CustomerGroupUniqueWhere",
		create:"Contember_CustomerGroupWithoutProductPricesCreateInput"
	},
	Contember_CustomerGroupWithoutProductPricesCreateInput:{
		id:"UUID",
		customers:"Contember_CustomerGroupCreateCustomersEntityRelationInput"
	},
	Contember_CustomerGroupCreateCustomersEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutGroupCreateInput"
	},
	Contember_CustomerWithoutGroupCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreatePhotoEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_CustomerCreateNameEntityRelationInput:{
		connect:"Contember_CustomerNameUniqueWhere",
		create:"Contember_CustomerNameWithoutCustomerCreateInput"
	},
	Contember_CustomerNameWithoutCustomerCreateInput:{
		id:"UUID",
		aliases:"Contember_CustomerNameCreateAliasesEntityRelationInput"
	},
	Contember_CustomerNameCreateAliasesEntityRelationInput:{
		connect:"Contember_CustomerAliasUniqueWhere",
		create:"Contember_CustomerAliasCreateInput"
	},
	Contember_CustomerAliasUniqueWhere:{
		id:"UUID"
	},
	Contember_CustomerAliasCreateInput:{
		id:"UUID"
	},
	Contember_CustomerCreateIngredientRatingsEntityRelationInput:{
		connect:"Contember_CustomerIngredientRatingUniqueWhere",
		create:"Contember_CustomerIngredientRatingWithoutCustomerCreateInput"
	},
	Contember_CustomerIngredientRatingWithoutCustomerCreateInput:{
		id:"UUID",
		rating:"Contember_CustomerIngredientRatingCreateRatingEntityRelationInput",
		ingredient:"Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput"
	},
	Contember_CustomerIngredientRatingCreateRatingEntityRelationInput:{
		connect:"Contember_ProductIngredientRatingUniqueWhere",
		create:"Contember_ProductIngredientRatingCreateInput"
	},
	Contember_ProductIngredientRatingUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductIngredientRatingCreateInput:{
		id:"UUID",
		icon:"Contember_ProductIngredientRatingCreateIconEntityRelationInput"
	},
	Contember_ProductIngredientRatingCreateIconEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput:{
		connect:"Contember_ProductIngredientUniqueWhere",
		create:"Contember_ProductIngredientWithoutCustomerRatingsCreateInput"
	},
	Contember_ProductIngredientWithoutCustomerRatingsCreateInput:{
		id:"UUID",
		locales:"Contember_ProductIngredientCreateLocalesEntityRelationInput",
		categories:"Contember_ProductIngredientCreateCategoriesEntityRelationInput",
		allergens:"Contember_ProductIngredientCreateAllergensEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientCreateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientCreateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientCreateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientCreateCategoriesEntityRelationInput:{
		connect:"Contember_ProductIngredientCategoryUniqueWhere",
		create:"Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"
	},
	Contember_ProductIngredientCategoryWithoutIngredientsCreateInput:{
		id:"UUID",
		locales:"Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput"
	},
	Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput:{
		connect:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		create:"Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"
	},
	Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput"
	},
	Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutProductIngredientCategoriesCreateInput"
	},
	Contember_LocaleWithoutProductIngredientCategoriesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateProductIngredientsEntityRelationInput:{
		connect:"Contember_ProductIngredientLocaleUniqueWhere",
		create:"Contember_ProductIngredientLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductIngredientLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_ProductIngredientLocaleCreateRootEntityRelationInput"
	},
	Contember_ProductIngredientLocaleCreateRootEntityRelationInput:{
		connect:"Contember_ProductIngredientUniqueWhere",
		create:"Contember_ProductIngredientWithoutLocalesCreateInput"
	},
	Contember_ProductIngredientWithoutLocalesCreateInput:{
		id:"UUID",
		categories:"Contember_ProductIngredientCreateCategoriesEntityRelationInput",
		allergens:"Contember_ProductIngredientCreateAllergensEntityRelationInput",
		customerRatings:"Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientCreateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientCreateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientCreateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientCreateAllergensEntityRelationInput:{
		connect:"Contember_AllergenUniqueWhere",
		create:"Contember_AllergenWithoutIngredientsCreateInput"
	},
	Contember_AllergenWithoutIngredientsCreateInput:{
		id:"UUID",
		locales:"Contember_AllergenCreateLocalesEntityRelationInput"
	},
	Contember_AllergenCreateLocalesEntityRelationInput:{
		connect:"Contember_AllergenLocaleUniqueWhere",
		create:"Contember_AllergenLocaleWithoutRootCreateInput"
	},
	Contember_AllergenLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_AllergenLocaleCreateLocaleEntityRelationInput"
	},
	Contember_AllergenLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutAllergensCreateInput"
	},
	Contember_LocaleWithoutAllergensCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateOrderRecurrencesEntityRelationInput:{
		connect:"Contember_OrderRecurrenceLocaleUniqueWhere",
		create:"Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"
	},
	Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput"
	},
	Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput:{
		connect:"Contember_OrderRecurrenceUniqueWhere",
		create:"Contember_OrderRecurrenceWithoutLocalesCreateInput"
	},
	Contember_OrderRecurrenceWithoutLocalesCreateInput:{
		id:"UUID",
		businessCategory:"Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput"
	},
	Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput:{
		connect:"Contember_BusinessCategoryUniqueWhere",
		create:"Contember_BusinessCategoryWithoutRecurrencesCreateInput"
	},
	Contember_BusinessCategoryWithoutRecurrencesCreateInput:{
		id:"UUID",
		locales:"Contember_BusinessCategoryCreateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_BusinessCategoryCreateLocalesEntityRelationInput:{
		connect:"Contember_BusinessCategoryLocaleUniqueWhere",
		create:"Contember_BusinessCategoryLocaleWithoutRootCreateInput"
	},
	Contember_BusinessCategoryLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput"
	},
	Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutBusinessCategoriesCreateInput"
	},
	Contember_LocaleWithoutBusinessCategoriesCreateInput:{
		id:"UUID",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateBlogsEntityRelationInput:{
		connect:"Contember_BlogLocaleUniqueWhere",
		create:"Contember_BlogLocaleWithoutLocaleCreateInput"
	},
	Contember_BlogLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_BlogLocaleCreateRootEntityRelationInput"
	},
	Contember_BlogLocaleCreateRootEntityRelationInput:{
		connect:"Contember_BlogUniqueWhere",
		create:"Contember_BlogWithoutLocalesCreateInput"
	},
	Contember_BlogWithoutLocalesCreateInput:{
		id:"UUID",
		unique:"Contember_One"
	},
	Contember_LocaleCreateBlogPostsEntityRelationInput:{
		connect:"Contember_BlogPostLocaleUniqueWhere",
		create:"Contember_BlogPostLocaleWithoutLocaleCreateInput"
	},
	Contember_BlogPostLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_BlogPostLocaleCreateRootEntityRelationInput",
		cover:"Contember_BlogPostLocaleCreateCoverEntityRelationInput",
		content:"Contember_BlogPostLocaleCreateContentEntityRelationInput",
		link:"Contember_BlogPostLocaleCreateLinkEntityRelationInput",
		seo:"Contember_BlogPostLocaleCreateSeoEntityRelationInput",
		products:"Contember_BlogPostLocaleCreateProductsEntityRelationInput"
	},
	Contember_BlogPostLocaleCreateRootEntityRelationInput:{
		connect:"Contember_BlogPostUniqueWhere",
		create:"Contember_BlogPostWithoutLocalesCreateInput"
	},
	Contember_BlogPostWithoutLocalesCreateInput:{
		id:"UUID"
	},
	Contember_BlogPostLocaleCreateCoverEntityRelationInput:{
		connect:"Contember_CoverUniqueWhere",
		create:"Contember_CoverCreateInput"
	},
	Contember_CoverCreateInput:{
		id:"UUID",
		medium:"Contember_CoverCreateMediumEntityRelationInput",
		buttonColors:"Contember_CoverCreateButtonColorsEntityRelationInput"
	},
	Contember_CoverCreateMediumEntityRelationInput:{
		connect:"Contember_MediumUniqueWhere",
		create:"Contember_MediumCreateInput"
	},
	Contember_MediumUniqueWhere:{
		id:"UUID"
	},
	Contember_MediumCreateInput:{
		id:"UUID",
		type:"Contember_MediumType",
		colorTheme:"Contember_ColorTheme",
		image:"Contember_MediumCreateImageEntityRelationInput",
		video:"Contember_MediumCreateVideoEntityRelationInput"
	},
	Contember_MediumCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_MediumCreateVideoEntityRelationInput:{
		connect:"Contember_VideoUniqueWhere",
		create:"Contember_VideoCreateInput"
	},
	Contember_VideoUniqueWhere:{
		id:"UUID"
	},
	Contember_VideoCreateInput:{
		id:"UUID",
		poster:"Contember_VideoCreatePosterEntityRelationInput"
	},
	Contember_VideoCreatePosterEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_CoverCreateButtonColorsEntityRelationInput:{
		connect:"Contember_ColorPalleteUniqueWhere",
		create:"Contember_ColorPalleteCreateInput"
	},
	Contember_ColorPalleteUniqueWhere:{
		id:"UUID"
	},
	Contember_ColorPalleteCreateInput:{
		id:"UUID"
	},
	Contember_BlogPostLocaleCreateContentEntityRelationInput:{
		connect:"Contember_ContentUniqueWhere",
		create:"Contember_ContentCreateInput"
	},
	Contember_ContentCreateInput:{
		id:"UUID",
		blocks:"Contember_ContentCreateBlocksEntityRelationInput"
	},
	Contember_ContentCreateBlocksEntityRelationInput:{
		connect:"Contember_ContentBlockUniqueWhere",
		create:"Contember_ContentBlockWithoutContentCreateInput"
	},
	Contember_ContentBlockWithoutContentCreateInput:{
		id:"UUID",
		references:"Contember_ContentBlockCreateReferencesEntityRelationInput"
	},
	Contember_ContentBlockCreateReferencesEntityRelationInput:{
		connect:"Contember_ContentReferenceUniqueWhere",
		create:"Contember_ContentReferenceWithoutBlockCreateInput"
	},
	Contember_ContentReferenceWithoutBlockCreateInput:{
		id:"UUID",
		type:"Contember_ContentReferenceType",
		colorPallete:"Contember_ContentReferenceCreateColorPalleteEntityRelationInput",
		medium:"Contember_ContentReferenceCreateMediumEntityRelationInput",
		link:"Contember_ContentReferenceCreateLinkEntityRelationInput",
		blogPosts:"Contember_ContentReferenceCreateBlogPostsEntityRelationInput",
		links:"Contember_ContentReferenceCreateLinksEntityRelationInput",
		products:"Contember_ContentReferenceCreateProductsEntityRelationInput",
		contentSize:"Contember_ContentSize",
		hero:"Contember_ContentReferenceCreateHeroEntityRelationInput",
		gallery:"Contember_ContentReferenceCreateGalleryEntityRelationInput",
		socialsAndApps:"Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput",
		productGrid:"Contember_ContentReferenceCreateProductGridEntityRelationInput",
		productBanners:"Contember_ContentReferenceCreateProductBannersEntityRelationInput",
		deliveryRegions:"Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput",
		textList:"Contember_ContentReferenceCreateTextListEntityRelationInput",
		gridTiles:"Contember_ContentReferenceCreateGridTilesEntityRelationInput"
	},
	Contember_ContentReferenceCreateColorPalleteEntityRelationInput:{
		connect:"Contember_ColorPalleteUniqueWhere",
		create:"Contember_ColorPalleteCreateInput"
	},
	Contember_ContentReferenceCreateMediumEntityRelationInput:{
		connect:"Contember_MediumUniqueWhere",
		create:"Contember_MediumCreateInput"
	},
	Contember_ContentReferenceCreateLinkEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_LinkCreateInput:{
		id:"UUID",
		type:"Contember_LinkType",
		internalLink:"Contember_LinkCreateInternalLinkEntityRelationInput"
	},
	Contember_LinkCreateInternalLinkEntityRelationInput:{
		connect:"Contember_LinkableUniqueWhere",
		create:"Contember_LinkableCreateInput"
	},
	Contember_LinkableCreateInput:{
		id:"UUID",
		blogPost:"Contember_LinkableCreateBlogPostEntityRelationInput",
		page:"Contember_LinkableCreatePageEntityRelationInput",
		wikiPage:"Contember_LinkableCreateWikiPageEntityRelationInput",
		redirect:"Contember_LinkableCreateRedirectEntityRelationInput"
	},
	Contember_LinkableCreateBlogPostEntityRelationInput:{
		connect:"Contember_BlogPostLocaleUniqueWhere",
		create:"Contember_BlogPostLocaleWithoutLinkCreateInput"
	},
	Contember_BlogPostLocaleWithoutLinkCreateInput:{
		id:"UUID",
		root:"Contember_BlogPostLocaleCreateRootEntityRelationInput",
		locale:"Contember_BlogPostLocaleCreateLocaleEntityRelationInput",
		cover:"Contember_BlogPostLocaleCreateCoverEntityRelationInput",
		content:"Contember_BlogPostLocaleCreateContentEntityRelationInput",
		seo:"Contember_BlogPostLocaleCreateSeoEntityRelationInput",
		products:"Contember_BlogPostLocaleCreateProductsEntityRelationInput"
	},
	Contember_BlogPostLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutBlogPostsCreateInput"
	},
	Contember_LocaleWithoutBlogPostsCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateIdentifierEntityRelationInput:{
		connect:"Contember_TranslationCataloguesIdentifierUniqueWhere",
		create:"Contember_TranslationCataloguesIdentifierCreateInput"
	},
	Contember_TranslationCataloguesIdentifierCreateInput:{
		id:"UUID",
		catalogue:"Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput"
	},
	Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput:{
		connect:"Contember_TranslationCatalogueUniqueWhere",
		create:"Contember_TranslationCatalogueWithoutIdentifierCreateInput"
	},
	Contember_TranslationCatalogueWithoutIdentifierCreateInput:{
		id:"UUID",
		domain:"Contember_TranslationCatalogueCreateDomainEntityRelationInput",
		fallback:"Contember_TranslationCatalogueCreateFallbackEntityRelationInput",
		values:"Contember_TranslationCatalogueCreateValuesEntityRelationInput"
	},
	Contember_TranslationCatalogueCreateDomainEntityRelationInput:{
		connect:"Contember_TranslationDomainUniqueWhere",
		create:"Contember_TranslationDomainWithoutCataloguesCreateInput"
	},
	Contember_TranslationDomainWithoutCataloguesCreateInput:{
		id:"UUID",
		keys:"Contember_TranslationDomainCreateKeysEntityRelationInput"
	},
	Contember_TranslationDomainCreateKeysEntityRelationInput:{
		connect:"Contember_TranslationKeyUniqueWhere",
		create:"Contember_TranslationKeyWithoutDomainCreateInput"
	},
	Contember_TranslationKeyWithoutDomainCreateInput:{
		id:"UUID",
		contentType:"Contember_TranslationContentType",
		values:"Contember_TranslationKeyCreateValuesEntityRelationInput"
	},
	Contember_TranslationKeyCreateValuesEntityRelationInput:{
		connect:"Contember_TranslationValueUniqueWhere",
		create:"Contember_TranslationValueWithoutKeyCreateInput"
	},
	Contember_TranslationValueWithoutKeyCreateInput:{
		id:"UUID",
		catalogue:"Contember_TranslationValueCreateCatalogueEntityRelationInput"
	},
	Contember_TranslationValueCreateCatalogueEntityRelationInput:{
		connect:"Contember_TranslationCatalogueUniqueWhere",
		create:"Contember_TranslationCatalogueWithoutValuesCreateInput"
	},
	Contember_TranslationCatalogueWithoutValuesCreateInput:{
		id:"UUID",
		domain:"Contember_TranslationCatalogueCreateDomainEntityRelationInput",
		fallback:"Contember_TranslationCatalogueCreateFallbackEntityRelationInput",
		identifier:"Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"
	},
	Contember_TranslationCatalogueCreateFallbackEntityRelationInput:{
		connect:"Contember_TranslationCatalogueUniqueWhere",
		create:"Contember_TranslationCatalogueCreateInput"
	},
	Contember_TranslationCatalogueCreateInput:{
		id:"UUID",
		domain:"Contember_TranslationCatalogueCreateDomainEntityRelationInput",
		fallback:"Contember_TranslationCatalogueCreateFallbackEntityRelationInput",
		values:"Contember_TranslationCatalogueCreateValuesEntityRelationInput",
		identifier:"Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"
	},
	Contember_TranslationCatalogueCreateValuesEntityRelationInput:{
		connect:"Contember_TranslationValueUniqueWhere",
		create:"Contember_TranslationValueWithoutCatalogueCreateInput"
	},
	Contember_TranslationValueWithoutCatalogueCreateInput:{
		id:"UUID",
		key:"Contember_TranslationValueCreateKeyEntityRelationInput"
	},
	Contember_TranslationValueCreateKeyEntityRelationInput:{
		connect:"Contember_TranslationKeyUniqueWhere",
		create:"Contember_TranslationKeyWithoutValuesCreateInput"
	},
	Contember_TranslationKeyWithoutValuesCreateInput:{
		id:"UUID",
		contentType:"Contember_TranslationContentType",
		domain:"Contember_TranslationKeyCreateDomainEntityRelationInput"
	},
	Contember_TranslationKeyCreateDomainEntityRelationInput:{
		connect:"Contember_TranslationDomainUniqueWhere",
		create:"Contember_TranslationDomainWithoutKeysCreateInput"
	},
	Contember_TranslationDomainWithoutKeysCreateInput:{
		id:"UUID",
		catalogues:"Contember_TranslationDomainCreateCataloguesEntityRelationInput"
	},
	Contember_TranslationDomainCreateCataloguesEntityRelationInput:{
		connect:"Contember_TranslationCatalogueUniqueWhere",
		create:"Contember_TranslationCatalogueWithoutDomainCreateInput"
	},
	Contember_TranslationCatalogueWithoutDomainCreateInput:{
		id:"UUID",
		fallback:"Contember_TranslationCatalogueCreateFallbackEntityRelationInput",
		values:"Contember_TranslationCatalogueCreateValuesEntityRelationInput",
		identifier:"Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"
	},
	Contember_TranslationCatalogueCreateIdentifierEntityRelationInput:{
		connect:"Contember_TranslationCataloguesIdentifierUniqueWhere",
		create:"Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"
	},
	Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput:{
		id:"UUID"
	},
	Contember_LocaleCreateMenusEntityRelationInput:{
		connect:"Contember_MenuLocaleUniqueWhere",
		create:"Contember_MenuLocaleWithoutLocaleCreateInput"
	},
	Contember_MenuLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_MenuLocaleCreateRootEntityRelationInput",
		items:"Contember_MenuLocaleCreateItemsEntityRelationInput",
		secondaryItems:"Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput",
		eshopLink:"Contember_MenuLocaleCreateEshopLinkEntityRelationInput"
	},
	Contember_MenuLocaleCreateRootEntityRelationInput:{
		connect:"Contember_SiteMenuUniqueWhere",
		create:"Contember_SiteMenuWithoutLocalesCreateInput"
	},
	Contember_SiteMenuWithoutLocalesCreateInput:{
		id:"UUID",
		unique:"Contember_One"
	},
	Contember_MenuLocaleCreateItemsEntityRelationInput:{
		connect:"Contember_MenuItemUniqueWhere",
		create:"Contember_MenuItemWithoutMenuCreateInput"
	},
	Contember_MenuItemWithoutMenuCreateInput:{
		id:"UUID",
		link:"Contember_MenuItemCreateLinkEntityRelationInput",
		subitems:"Contember_MenuItemCreateSubitemsEntityRelationInput"
	},
	Contember_MenuItemCreateLinkEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_MenuItemCreateSubitemsEntityRelationInput:{
		connect:"Contember_MenuSubitemUniqueWhere",
		create:"Contember_MenuSubitemWithoutItemCreateInput"
	},
	Contember_MenuSubitemWithoutItemCreateInput:{
		id:"UUID",
		type:"Contember_MenuSubitemType",
		links:"Contember_MenuSubitemCreateLinksEntityRelationInput",
		products:"Contember_MenuSubitemCreateProductsEntityRelationInput",
		delivery:"Contember_MenuSubitemCreateDeliveryEntityRelationInput",
		contact:"Contember_MenuSubitemCreateContactEntityRelationInput"
	},
	Contember_MenuSubitemCreateLinksEntityRelationInput:{
		connect:"Contember_MenuLinkListUniqueWhere",
		create:"Contember_MenuLinkListCreateInput"
	},
	Contember_MenuLinkListUniqueWhere:{
		id:"UUID",
		items:"Contember_MenuLinkItemUniqueWhere"
	},
	Contember_MenuLinkItemUniqueWhere:{
		id:"UUID"
	},
	Contember_MenuLinkListCreateInput:{
		id:"UUID",
		items:"Contember_MenuLinkListCreateItemsEntityRelationInput"
	},
	Contember_MenuLinkListCreateItemsEntityRelationInput:{
		connect:"Contember_MenuLinkItemUniqueWhere",
		create:"Contember_MenuLinkItemWithoutListCreateInput"
	},
	Contember_MenuLinkItemWithoutListCreateInput:{
		id:"UUID",
		link:"Contember_MenuLinkItemCreateLinkEntityRelationInput",
		image:"Contember_MenuLinkItemCreateImageEntityRelationInput"
	},
	Contember_MenuLinkItemCreateLinkEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_MenuLinkItemCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_MenuSubitemCreateProductsEntityRelationInput:{
		connect:"Contember_MenuProductsUniqueWhere",
		create:"Contember_MenuProductsCreateInput"
	},
	Contember_MenuProductsUniqueWhere:{
		id:"UUID",
		buttons:"Contember_LinkListUniqueWhere"
	},
	Contember_MenuProductsCreateInput:{
		id:"UUID",
		links:"Contember_MenuProductsCreateLinksEntityRelationInput",
		buttons:"Contember_MenuProductsCreateButtonsEntityRelationInput"
	},
	Contember_MenuProductsCreateLinksEntityRelationInput:{
		connect:"Contember_MenuLinkListUniqueWhere",
		create:"Contember_MenuLinkListCreateInput"
	},
	Contember_MenuProductsCreateButtonsEntityRelationInput:{
		connect:"Contember_LinkListUniqueWhere",
		create:"Contember_LinkListCreateInput"
	},
	Contember_LinkListCreateInput:{
		id:"UUID",
		items:"Contember_LinkListCreateItemsEntityRelationInput"
	},
	Contember_LinkListCreateItemsEntityRelationInput:{
		connect:"Contember_LinkListItemUniqueWhere",
		create:"Contember_LinkListItemWithoutListCreateInput"
	},
	Contember_LinkListItemWithoutListCreateInput:{
		id:"UUID",
		link:"Contember_LinkListItemCreateLinkEntityRelationInput"
	},
	Contember_LinkListItemCreateLinkEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_MenuSubitemCreateDeliveryEntityRelationInput:{
		connect:"Contember_MenuDeliveryUniqueWhere",
		create:"Contember_MenuDeliveryCreateInput"
	},
	Contember_MenuDeliveryUniqueWhere:{
		id:"UUID",
		deliveryRegions:"Contember_DeliveryRegionsUniqueWhere"
	},
	Contember_MenuDeliveryCreateInput:{
		id:"UUID",
		deliveryRegions:"Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput",
		socialsAndApps:"Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput"
	},
	Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput:{
		connect:"Contember_DeliveryRegionsUniqueWhere",
		create:"Contember_DeliveryRegionsCreateInput"
	},
	Contember_DeliveryRegionsCreateInput:{
		id:"UUID",
		items:"Contember_DeliveryRegionsCreateItemsEntityRelationInput"
	},
	Contember_DeliveryRegionsCreateItemsEntityRelationInput:{
		connect:"Contember_DeliveryRegionsItemUniqueWhere",
		create:"Contember_DeliveryRegionsItemWithoutListCreateInput"
	},
	Contember_DeliveryRegionsItemWithoutListCreateInput:{
		id:"UUID",
		image:"Contember_DeliveryRegionsItemCreateImageEntityRelationInput",
		textList:"Contember_DeliveryRegionsItemCreateTextListEntityRelationInput",
		gallery:"Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput"
	},
	Contember_DeliveryRegionsItemCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_DeliveryRegionsItemCreateTextListEntityRelationInput:{
		connect:"Contember_TextListUniqueWhere",
		create:"Contember_TextListCreateInput"
	},
	Contember_TextListCreateInput:{
		id:"UUID",
		items:"Contember_TextListCreateItemsEntityRelationInput"
	},
	Contember_TextListCreateItemsEntityRelationInput:{
		connect:"Contember_TextItemUniqueWhere",
		create:"Contember_TextItemWithoutListCreateInput"
	},
	Contember_TextItemWithoutListCreateInput:{
		id:"UUID"
	},
	Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput:{
		connect:"Contember_ImageListUniqueWhere",
		create:"Contember_ImageListCreateInput"
	},
	Contember_ImageListCreateInput:{
		id:"UUID",
		items:"Contember_ImageListCreateItemsEntityRelationInput"
	},
	Contember_ImageListCreateItemsEntityRelationInput:{
		connect:"Contember_ImageListItemUniqueWhere",
		create:"Contember_ImageListItemWithoutListCreateInput"
	},
	Contember_ImageListItemWithoutListCreateInput:{
		id:"UUID",
		image:"Contember_ImageListItemCreateImageEntityRelationInput"
	},
	Contember_ImageListItemCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput:{
		connect:"Contember_SocialsAndAppsUniqueWhere",
		create:"Contember_SocialsAndAppsCreateInput"
	},
	Contember_SocialsAndAppsUniqueWhere:{
		id:"UUID"
	},
	Contember_SocialsAndAppsCreateInput:{
		id:"UUID"
	},
	Contember_MenuSubitemCreateContactEntityRelationInput:{
		connect:"Contember_MenuContactUniqueWhere",
		create:"Contember_MenuContactCreateInput"
	},
	Contember_MenuContactUniqueWhere:{
		id:"UUID"
	},
	Contember_MenuContactCreateInput:{
		id:"UUID",
		image:"Contember_MenuContactCreateImageEntityRelationInput"
	},
	Contember_MenuContactCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput:{
		connect:"Contember_LinkListUniqueWhere",
		create:"Contember_LinkListCreateInput"
	},
	Contember_MenuLocaleCreateEshopLinkEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_LocaleCreatePagesEntityRelationInput:{
		connect:"Contember_PageLocaleUniqueWhere",
		create:"Contember_PageLocaleWithoutLocaleCreateInput"
	},
	Contember_PageLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		link:"Contember_PageLocaleCreateLinkEntityRelationInput",
		root:"Contember_PageLocaleCreateRootEntityRelationInput",
		seo:"Contember_PageLocaleCreateSeoEntityRelationInput",
		content:"Contember_PageLocaleCreateContentEntityRelationInput",
		cover:"Contember_PageLocaleCreateCoverEntityRelationInput",
		theme:"Contember_PageTheme"
	},
	Contember_PageLocaleCreateLinkEntityRelationInput:{
		connect:"Contember_LinkableUniqueWhere",
		create:"Contember_LinkableWithoutPageCreateInput"
	},
	Contember_LinkableWithoutPageCreateInput:{
		id:"UUID",
		blogPost:"Contember_LinkableCreateBlogPostEntityRelationInput",
		wikiPage:"Contember_LinkableCreateWikiPageEntityRelationInput",
		redirect:"Contember_LinkableCreateRedirectEntityRelationInput"
	},
	Contember_LinkableCreateWikiPageEntityRelationInput:{
		connect:"Contember_WikiPageUniqueWhere",
		create:"Contember_WikiPageWithoutLinkCreateInput"
	},
	Contember_WikiPageWithoutLinkCreateInput:{
		id:"UUID",
		parent:"Contember_WikiPageCreateParentEntityRelationInput",
		children:"Contember_WikiPageCreateChildrenEntityRelationInput",
		content:"Contember_WikiPageCreateContentEntityRelationInput"
	},
	Contember_WikiPageCreateParentEntityRelationInput:{
		connect:"Contember_WikiPageUniqueWhere",
		create:"Contember_WikiPageWithoutChildrenCreateInput"
	},
	Contember_WikiPageWithoutChildrenCreateInput:{
		id:"UUID",
		link:"Contember_WikiPageCreateLinkEntityRelationInput",
		parent:"Contember_WikiPageCreateParentEntityRelationInput",
		content:"Contember_WikiPageCreateContentEntityRelationInput"
	},
	Contember_WikiPageCreateLinkEntityRelationInput:{
		connect:"Contember_LinkableUniqueWhere",
		create:"Contember_LinkableWithoutWikiPageCreateInput"
	},
	Contember_LinkableWithoutWikiPageCreateInput:{
		id:"UUID",
		blogPost:"Contember_LinkableCreateBlogPostEntityRelationInput",
		page:"Contember_LinkableCreatePageEntityRelationInput",
		redirect:"Contember_LinkableCreateRedirectEntityRelationInput"
	},
	Contember_LinkableCreatePageEntityRelationInput:{
		connect:"Contember_PageLocaleUniqueWhere",
		create:"Contember_PageLocaleWithoutLinkCreateInput"
	},
	Contember_PageLocaleWithoutLinkCreateInput:{
		id:"UUID",
		root:"Contember_PageLocaleCreateRootEntityRelationInput",
		locale:"Contember_PageLocaleCreateLocaleEntityRelationInput",
		seo:"Contember_PageLocaleCreateSeoEntityRelationInput",
		content:"Contember_PageLocaleCreateContentEntityRelationInput",
		cover:"Contember_PageLocaleCreateCoverEntityRelationInput",
		theme:"Contember_PageTheme"
	},
	Contember_PageLocaleCreateRootEntityRelationInput:{
		connect:"Contember_PageUniqueWhere",
		create:"Contember_PageWithoutLocalesCreateInput"
	},
	Contember_PageWithoutLocalesCreateInput:{
		id:"UUID"
	},
	Contember_PageLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutPagesCreateInput"
	},
	Contember_LocaleWithoutPagesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput:{
		connect:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		create:"Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput"
	},
	Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput:{
		connect:"Contember_ProductIngredientCategoryUniqueWhere",
		create:"Contember_ProductIngredientCategoryWithoutLocalesCreateInput"
	},
	Contember_ProductIngredientCategoryWithoutLocalesCreateInput:{
		id:"UUID",
		ingredients:"Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput"
	},
	Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput:{
		connect:"Contember_ProductIngredientUniqueWhere",
		create:"Contember_ProductIngredientWithoutCategoriesCreateInput"
	},
	Contember_ProductIngredientWithoutCategoriesCreateInput:{
		id:"UUID",
		locales:"Contember_ProductIngredientCreateLocalesEntityRelationInput",
		allergens:"Contember_ProductIngredientCreateAllergensEntityRelationInput",
		customerRatings:"Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientCreateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientCreateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientCreateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput:{
		connect:"Contember_CustomerIngredientRatingUniqueWhere",
		create:"Contember_CustomerIngredientRatingWithoutIngredientCreateInput"
	},
	Contember_CustomerIngredientRatingWithoutIngredientCreateInput:{
		id:"UUID",
		customer:"Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput",
		rating:"Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"
	},
	Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutIngredientRatingsCreateInput"
	},
	Contember_CustomerWithoutIngredientRatingsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateBusinessEntityRelationInput:{
		connect:"Contember_BusinessCustomerUniqueWhere",
		create:"Contember_BusinessCustomerWithoutCustomerCreateInput"
	},
	Contember_BusinessCustomerWithoutCustomerCreateInput:{
		id:"UUID",
		owners:"Contember_BusinessCustomerCreateOwnersEntityRelationInput",
		members:"Contember_BusinessCustomerCreateMembersEntityRelationInput",
		category:"Contember_BusinessCustomerCreateCategoryEntityRelationInput",
		accountManager:"Contember_BusinessCustomerCreateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerCreateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerCreateOwnersEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutOwnerOfBusinessesCreateInput"
	},
	Contember_CustomerWithoutOwnerOfBusinessesCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateMemberOfBusinessesEntityRelationInput:{
		connect:"Contember_BusinessCustomerUniqueWhere",
		create:"Contember_BusinessCustomerWithoutMembersCreateInput"
	},
	Contember_BusinessCustomerWithoutMembersCreateInput:{
		id:"UUID",
		customer:"Contember_BusinessCustomerCreateCustomerEntityRelationInput",
		owners:"Contember_BusinessCustomerCreateOwnersEntityRelationInput",
		category:"Contember_BusinessCustomerCreateCategoryEntityRelationInput",
		accountManager:"Contember_BusinessCustomerCreateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerCreateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerCreateCategoryEntityRelationInput:{
		connect:"Contember_BusinessCategoryUniqueWhere",
		create:"Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"
	},
	Contember_BusinessCategoryWithoutBusinessCustomersCreateInput:{
		id:"UUID",
		locales:"Contember_BusinessCategoryCreateLocalesEntityRelationInput",
		recurrences:"Contember_BusinessCategoryCreateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_BusinessCategoryCreateRecurrencesEntityRelationInput:{
		connect:"Contember_OrderRecurrenceUniqueWhere",
		create:"Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"
	},
	Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput:{
		id:"UUID",
		locales:"Contember_OrderRecurrenceCreateLocalesEntityRelationInput"
	},
	Contember_OrderRecurrenceCreateLocalesEntityRelationInput:{
		connect:"Contember_OrderRecurrenceLocaleUniqueWhere",
		create:"Contember_OrderRecurrenceLocaleWithoutRootCreateInput"
	},
	Contember_OrderRecurrenceLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput"
	},
	Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutOrderRecurrencesCreateInput"
	},
	Contember_LocaleWithoutOrderRecurrencesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateAllergensEntityRelationInput:{
		connect:"Contember_AllergenLocaleUniqueWhere",
		create:"Contember_AllergenLocaleWithoutLocaleCreateInput"
	},
	Contember_AllergenLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_AllergenLocaleCreateRootEntityRelationInput"
	},
	Contember_AllergenLocaleCreateRootEntityRelationInput:{
		connect:"Contember_AllergenUniqueWhere",
		create:"Contember_AllergenWithoutLocalesCreateInput"
	},
	Contember_AllergenWithoutLocalesCreateInput:{
		id:"UUID",
		ingredients:"Contember_AllergenCreateIngredientsEntityRelationInput"
	},
	Contember_AllergenCreateIngredientsEntityRelationInput:{
		connect:"Contember_ProductIngredientUniqueWhere",
		create:"Contember_ProductIngredientWithoutAllergensCreateInput"
	},
	Contember_ProductIngredientWithoutAllergensCreateInput:{
		id:"UUID",
		locales:"Contember_ProductIngredientCreateLocalesEntityRelationInput",
		categories:"Contember_ProductIngredientCreateCategoriesEntityRelationInput",
		customerRatings:"Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientCreateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientCreateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientCreateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput:{
		connect:"Contember_ProductIngredientItemQuantityUnitUniqueWhere",
		create:"Contember_ProductIngredientItemQuantityUnitCreateInput"
	},
	Contember_ProductIngredientItemQuantityUnitUniqueWhere:{
		id:"UUID"
	},
	Contember_ProductIngredientItemQuantityUnitCreateInput:{
		id:"UUID"
	},
	Contember_ProductIngredientCreateFreshingContainerEntityRelationInput:{
		connect:"Contember_FreshingContainerUniqueWhere",
		create:"Contember_FreshingContainerCreateInput"
	},
	Contember_FreshingContainerUniqueWhere:{
		id:"UUID"
	},
	Contember_FreshingContainerCreateInput:{
		id:"UUID",
		workspace:"Contember_FreshingContainerCreateWorkspaceEntityRelationInput"
	},
	Contember_FreshingContainerCreateWorkspaceEntityRelationInput:{
		connect:"Contember_WorkspaceUniqueWhere",
		create:"Contember_WorkspaceWithoutFreshingContainersCreateInput"
	},
	Contember_WorkspaceUniqueWhere:{
		id:"UUID",
		unique:"Contember_One",
		freshingContainers:"Contember_FreshingContainerUniqueWhere",
		openingHours:"Contember_WeekHoursUniqueWhere",
		workingHours:"Contember_WeekHoursUniqueWhere",
		creditsProduct:"Contember_VirtualProductUniqueWhere",
		discountVatRate:"Contember_VatRateUniqueWhere"
	},
	Contember_WeekHoursUniqueWhere:{
		id:"UUID"
	},
	Contember_WorkspaceWithoutFreshingContainersCreateInput:{
		id:"UUID",
		unique:"Contember_One",
		openingHours:"Contember_WorkspaceCreateOpeningHoursEntityRelationInput",
		workingHours:"Contember_WorkspaceCreateWorkingHoursEntityRelationInput",
		defaultFreshingContainer:"Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput",
		creditsProduct:"Contember_WorkspaceCreateCreditsProductEntityRelationInput",
		discountVatRate:"Contember_WorkspaceCreateDiscountVatRateEntityRelationInput"
	},
	Contember_WorkspaceCreateOpeningHoursEntityRelationInput:{
		connect:"Contember_WeekHoursUniqueWhere",
		create:"Contember_WeekHoursCreateInput"
	},
	Contember_WeekHoursCreateInput:{
		id:"UUID"
	},
	Contember_WorkspaceCreateWorkingHoursEntityRelationInput:{
		connect:"Contember_WeekHoursUniqueWhere",
		create:"Contember_WeekHoursCreateInput"
	},
	Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput:{
		connect:"Contember_FreshingContainerUniqueWhere",
		create:"Contember_FreshingContainerCreateInput"
	},
	Contember_WorkspaceCreateCreditsProductEntityRelationInput:{
		connect:"Contember_VirtualProductUniqueWhere",
		create:"Contember_VirtualProductCreateInput"
	},
	Contember_VirtualProductCreateInput:{
		id:"UUID",
		type:"Contember_VirtualProductType",
		product:"Contember_VirtualProductCreateProductEntityRelationInput",
		physicalRepresentation:"Contember_VirtualProductPhysicalRepresentationType"
	},
	Contember_VirtualProductCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutVirtualProductCreateInput"
	},
	Contember_ProductWithoutVirtualProductCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateManagerEntityRelationInput:{
		connect:"Contember_StocksManagerUniqueWhere",
		create:"Contember_StocksManagerWithoutManagedProductsCreateInput"
	},
	Contember_StocksManagerWithoutManagedProductsCreateInput:{
		id:"UUID",
		staff:"Contember_StocksManagerCreateStaffEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_StocksManagerCreateStaffEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffWithoutStocksManagerCreateInput"
	},
	Contember_StaffWithoutStocksManagerCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		courier:"Contember_StaffCreateCourierEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffCreateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffCreateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffCreateShiftsProfileEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		venues:"Contember_StaffCreateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_StaffCreateBartenderEntityRelationInput:{
		connect:"Contember_BartenderUniqueWhere",
		create:"Contember_BartenderWithoutStaffCreateInput"
	},
	Contember_BartenderWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime"
	},
	Contember_StaffCreateCourierEntityRelationInput:{
		connect:"Contember_CourierUniqueWhere",
		create:"Contember_CourierWithoutStaffCreateInput"
	},
	Contember_CourierWithoutStaffCreateInput:{
		id:"UUID",
		routes:"Contember_CourierCreateRoutesEntityRelationInput",
		createdAt:"DateTime",
		deliveryMethods:"Contember_CourierCreateDeliveryMethodsEntityRelationInput"
	},
	Contember_CourierCreateRoutesEntityRelationInput:{
		connect:"Contember_DeliveryRouteUniqueWhere",
		create:"Contember_DeliveryRouteWithoutCourierCreateInput"
	},
	Contember_DeliveryRouteWithoutCourierCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		waypoints:"Contember_DeliveryRouteCreateWaypointsEntityRelationInput"
	},
	Contember_DeliveryRouteCreateWaypointsEntityRelationInput:{
		connect:"Contember_DeliveryWaypointUniqueWhere",
		create:"Contember_DeliveryWaypointWithoutRouteCreateInput"
	},
	Contember_DeliveryWaypointWithoutRouteCreateInput:{
		id:"UUID",
		estimatedArrival:"DateTime",
		delivery:"Contember_DeliveryWaypointCreateDeliveryEntityRelationInput",
		originDeparture:"DateTime"
	},
	Contember_DeliveryWaypointCreateDeliveryEntityRelationInput:{
		connect:"Contember_OrderDeliveryUniqueWhere",
		create:"Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"
	},
	Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput:{
		id:"UUID",
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryCreateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryCreateVatRateEntityRelationInput",
		order:"Contember_OrderDeliveryCreateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		events:"Contember_OrderDeliveryCreateEventsEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime",
		address:"Contember_OrderDeliveryCreateAddressEntityRelationInput"
	},
	Contember_OrderDeliveryCreateMethodEntityRelationInput:{
		connect:"Contember_DeliveryMethodUniqueWhere",
		create:"Contember_DeliveryMethodCreateInput"
	},
	Contember_DeliveryMethodCreateInput:{
		id:"UUID",
		paymentMethods:"Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodCreateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodCreateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput:{
		connect:"Contember_CourierUniqueWhere",
		create:"Contember_CourierWithoutDeliveryMethodsCreateInput"
	},
	Contember_CourierWithoutDeliveryMethodsCreateInput:{
		id:"UUID",
		staff:"Contember_CourierCreateStaffEntityRelationInput",
		routes:"Contember_CourierCreateRoutesEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_CourierCreateStaffEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffWithoutCourierCreateInput"
	},
	Contember_StaffWithoutCourierCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffCreateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffCreateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffCreateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffCreateStocksManagerEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		venues:"Contember_StaffCreateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_StaffCreateCleanerEntityRelationInput:{
		connect:"Contember_CleanerUniqueWhere",
		create:"Contember_CleanerWithoutStaffCreateInput"
	},
	Contember_CleanerWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime"
	},
	Contember_StaffCreateShiftsManagerEntityRelationInput:{
		connect:"Contember_ShiftsManagerUniqueWhere",
		create:"Contember_ShiftsManagerWithoutStaffCreateInput"
	},
	Contember_ShiftsManagerWithoutStaffCreateInput:{
		id:"UUID",
		managedRoles:"Contember_ShiftsManagerCreateManagedRolesEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_ShiftsManagerCreateManagedRolesEntityRelationInput:{
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		create:"Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"
	},
	Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput:{
		id:"UUID",
		shiftGroups:"Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput",
		shiftsProfiles:"Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput:{
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		create:"Contember_TimetableShiftGroupWithoutTypeCreateInput"
	},
	Contember_TimetableShiftGroupWithoutTypeCreateInput:{
		id:"UUID",
		shifts:"Contember_TimetableShiftGroupCreateShiftsEntityRelationInput",
		day:"Contember_TimetableShiftGroupCreateDayEntityRelationInput",
		position:"Contember_TimetableShiftGroupCreatePositionEntityRelationInput",
		queue:"Contember_TimetableShiftGroupCreateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupCreateShiftsEntityRelationInput:{
		connect:"Contember_TimetableShiftUniqueWhere",
		create:"Contember_TimetableShiftWithoutGroupCreateInput"
	},
	Contember_TimetableShiftWithoutGroupCreateInput:{
		id:"UUID",
		assigned:"Date",
		shiftsProfile:"Contember_TimetableShiftCreateShiftsProfileEntityRelationInput",
		queueItem:"Contember_TimetableShiftCreateQueueItemEntityRelationInput",
		swapInfo:"Contember_TimetableShiftCreateSwapInfoEntityRelationInput"
	},
	Contember_TimetableShiftCreateShiftsProfileEntityRelationInput:{
		connect:"Contember_ShiftsProfileUniqueWhere",
		create:"Contember_ShiftsProfileWithoutShiftsCreateInput"
	},
	Contember_ShiftsProfileWithoutShiftsCreateInput:{
		id:"UUID",
		queueItems:"Contember_ShiftsProfileCreateQueueItemsEntityRelationInput",
		staff:"Contember_ShiftsProfileCreateStaffEntityRelationInput",
		roles:"Contember_ShiftsProfileCreateRolesEntityRelationInput"
	},
	Contember_ShiftsProfileCreateQueueItemsEntityRelationInput:{
		connect:"Contember_EmployeeQueueItemUniqueWhere",
		create:"Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"
	},
	Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput:{
		id:"UUID",
		queue:"Contember_EmployeeQueueItemCreateQueueEntityRelationInput",
		shift:"Contember_EmployeeQueueItemCreateShiftEntityRelationInput",
		unassignInfo:"Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput",
		meta:"Contember_EmployeeQueueItemCreateMetaEntityRelationInput"
	},
	Contember_EmployeeQueueItemCreateQueueEntityRelationInput:{
		connect:"Contember_EmployeeQueueUniqueWhere",
		create:"Contember_EmployeeQueueWithoutItemsCreateInput"
	},
	Contember_EmployeeQueueWithoutItemsCreateInput:{
		id:"UUID",
		shiftGroup:"Contember_EmployeeQueueCreateShiftGroupEntityRelationInput"
	},
	Contember_EmployeeQueueCreateShiftGroupEntityRelationInput:{
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		create:"Contember_TimetableShiftGroupWithoutQueueCreateInput"
	},
	Contember_TimetableShiftGroupWithoutQueueCreateInput:{
		id:"UUID",
		shifts:"Contember_TimetableShiftGroupCreateShiftsEntityRelationInput",
		day:"Contember_TimetableShiftGroupCreateDayEntityRelationInput",
		type:"Contember_TimetableShiftGroupCreateTypeEntityRelationInput",
		position:"Contember_TimetableShiftGroupCreatePositionEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupCreateDayEntityRelationInput:{
		connect:"Contember_TimetableDayUniqueWhere",
		create:"Contember_TimetableDayWithoutShiftGroupsCreateInput"
	},
	Contember_TimetableDayWithoutShiftGroupsCreateInput:{
		id:"UUID",
		date:"Date",
		type:"Contember_TimetableDayType",
		notes:"Contember_TimetableDayCreateNotesEntityRelationInput",
		venue:"Contember_TimetableDayCreateVenueEntityRelationInput",
		meta:"Contember_TimetableDayCreateMetaEntityRelationInput"
	},
	Contember_TimetableDayCreateNotesEntityRelationInput:{
		connect:"Contember_TimetableDayNoteUniqueWhere",
		create:"Contember_TimetableDayNoteWithoutDayCreateInput"
	},
	Contember_TimetableDayNoteWithoutDayCreateInput:{
		id:"UUID",
		role:"Contember_TimetableDayNoteCreateRoleEntityRelationInput"
	},
	Contember_TimetableDayNoteCreateRoleEntityRelationInput:{
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		create:"Contember_TimetableEmployeeRoleCreateInput"
	},
	Contember_TimetableEmployeeRoleCreateInput:{
		id:"UUID",
		shiftGroups:"Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput",
		shiftsProfiles:"Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput:{
		connect:"Contember_ShiftsProfileUniqueWhere",
		create:"Contember_ShiftsProfileWithoutRolesCreateInput"
	},
	Contember_ShiftsProfileWithoutRolesCreateInput:{
		id:"UUID",
		queueItems:"Contember_ShiftsProfileCreateQueueItemsEntityRelationInput",
		staff:"Contember_ShiftsProfileCreateStaffEntityRelationInput",
		shifts:"Contember_ShiftsProfileCreateShiftsEntityRelationInput"
	},
	Contember_ShiftsProfileCreateStaffEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffWithoutShiftsProfileCreateInput"
	},
	Contember_StaffWithoutShiftsProfileCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		courier:"Contember_StaffCreateCourierEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffCreateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffCreateUserEntityRelationInput",
		stocksManager:"Contember_StaffCreateStocksManagerEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		venues:"Contember_StaffCreateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput:{
		connect:"Contember_BusinessToBusinessManagerUniqueWhere",
		create:"Contember_BusinessToBusinessManagerWithoutStaffCreateInput"
	},
	Contember_BusinessToBusinessManagerWithoutStaffCreateInput:{
		id:"UUID",
		managedBusinesses:"Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput:{
		connect:"Contember_BusinessCustomerUniqueWhere",
		create:"Contember_BusinessCustomerWithoutAccountManagerCreateInput"
	},
	Contember_BusinessCustomerWithoutAccountManagerCreateInput:{
		id:"UUID",
		customer:"Contember_BusinessCustomerCreateCustomerEntityRelationInput",
		owners:"Contember_BusinessCustomerCreateOwnersEntityRelationInput",
		members:"Contember_BusinessCustomerCreateMembersEntityRelationInput",
		category:"Contember_BusinessCustomerCreateCategoryEntityRelationInput",
		logo:"Contember_BusinessCustomerCreateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerCreateMembersEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutMemberOfBusinessesCreateInput"
	},
	Contember_CustomerWithoutMemberOfBusinessesCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput:{
		connect:"Contember_BusinessCustomerUniqueWhere",
		create:"Contember_BusinessCustomerWithoutOwnersCreateInput"
	},
	Contember_BusinessCustomerWithoutOwnersCreateInput:{
		id:"UUID",
		customer:"Contember_BusinessCustomerCreateCustomerEntityRelationInput",
		members:"Contember_BusinessCustomerCreateMembersEntityRelationInput",
		category:"Contember_BusinessCustomerCreateCategoryEntityRelationInput",
		accountManager:"Contember_BusinessCustomerCreateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerCreateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerCreateAccountManagerEntityRelationInput:{
		connect:"Contember_BusinessToBusinessManagerUniqueWhere",
		create:"Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"
	},
	Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput:{
		id:"UUID",
		staff:"Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffWithoutBusinessToBusinessManagerCreateInput"
	},
	Contember_StaffWithoutBusinessToBusinessManagerCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		courier:"Contember_StaffCreateCourierEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffCreateShiftsManagerEntityRelationInput",
		user:"Contember_StaffCreateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffCreateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffCreateStocksManagerEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		venues:"Contember_StaffCreateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_StaffCreateUserEntityRelationInput:{
		connect:"Contember_UserUniqueWhere",
		create:"Contember_UserWithoutStaffCreateInput"
	},
	Contember_UserWithoutStaffCreateInput:{
		id:"UUID",
		customer:"Contember_UserCreateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserCreateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserCreateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserCreateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserCreateRolesEntityRelationInput"
	},
	Contember_UserCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutUserCreateInput"
	},
	Contember_CustomerWithoutUserCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateCartsEntityRelationInput:{
		connect:"Contember_CartUniqueWhere",
		create:"Contember_CartWithoutCustomerCreateInput"
	},
	Contember_CartWithoutCustomerCreateInput:{
		id:"UUID",
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"Contember_CartCreateItemsEntityRelationInput",
		state:"Contember_CartState",
		createdAt:"DateTime",
		channel:"Contember_CartCreateChannelEntityRelationInput",
		order:"Contember_CartCreateOrderEntityRelationInput",
		deliveryAddress:"Contember_CartCreateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartCreateBillingAddressEntityRelationInput",
		delivery:"Contember_CartCreateDeliveryEntityRelationInput",
		payment:"Contember_CartCreatePaymentEntityRelationInput",
		billingSubject:"Contember_CartCreateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartCreatePriceLinesEntityRelationInput",
		summary:"Contember_CartCreateSummaryEntityRelationInput"
	},
	Contember_CartCreateOrderEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutCartCreateInput"
	},
	Contember_OrderWithoutCartCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutOrdersCreateInput"
	},
	Contember_CustomerWithoutOrdersCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateShortcutListEntityRelationInput:{
		connect:"Contember_ApplicationShortcutListUniqueWhere",
		create:"Contember_ApplicationShortcutListWithoutCustomerCreateInput"
	},
	Contember_ApplicationShortcutListWithoutCustomerCreateInput:{
		id:"UUID",
		items:"Contember_ApplicationShortcutListCreateItemsEntityRelationInput"
	},
	Contember_ApplicationShortcutListCreateItemsEntityRelationInput:{
		connect:"Contember_ApplicationShortcutListItemUniqueWhere",
		create:"Contember_ApplicationShortcutListItemWithoutListCreateInput"
	},
	Contember_ApplicationShortcutListItemWithoutListCreateInput:{
		id:"UUID",
		application:"Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput"
	},
	Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput:{
		connect:"Contember_ApplicationUniqueWhere",
		create:"Contember_ApplicationCreateInput"
	},
	Contember_ApplicationUniqueWhere:{
		id:"UUID",
		icon:"Contember_ImageUniqueWhere"
	},
	Contember_ApplicationCreateInput:{
		id:"UUID",
		icon:"Contember_ApplicationCreateIconEntityRelationInput"
	},
	Contember_ApplicationCreateIconEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_CustomerCreateUserEntityRelationInput:{
		connect:"Contember_UserUniqueWhere",
		create:"Contember_UserWithoutCustomerCreateInput"
	},
	Contember_UserWithoutCustomerCreateInput:{
		id:"UUID",
		staff:"Contember_UserCreateStaffEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserCreateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserCreateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserCreateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserCreateRolesEntityRelationInput"
	},
	Contember_UserCreateStaffEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffWithoutUserCreateInput"
	},
	Contember_StaffWithoutUserCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		courier:"Contember_StaffCreateCourierEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffCreateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput",
		shiftsProfile:"Contember_StaffCreateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffCreateStocksManagerEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		venues:"Contember_StaffCreateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_StaffCreateShiftsProfileEntityRelationInput:{
		connect:"Contember_ShiftsProfileUniqueWhere",
		create:"Contember_ShiftsProfileWithoutStaffCreateInput"
	},
	Contember_ShiftsProfileWithoutStaffCreateInput:{
		id:"UUID",
		queueItems:"Contember_ShiftsProfileCreateQueueItemsEntityRelationInput",
		roles:"Contember_ShiftsProfileCreateRolesEntityRelationInput",
		shifts:"Contember_ShiftsProfileCreateShiftsEntityRelationInput"
	},
	Contember_ShiftsProfileCreateRolesEntityRelationInput:{
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		create:"Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"
	},
	Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput:{
		id:"UUID",
		shiftGroups:"Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput:{
		connect:"Contember_ShiftsManagerUniqueWhere",
		create:"Contember_ShiftsManagerWithoutManagedRolesCreateInput"
	},
	Contember_ShiftsManagerWithoutManagedRolesCreateInput:{
		id:"UUID",
		staff:"Contember_ShiftsManagerCreateStaffEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_ShiftsManagerCreateStaffEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffWithoutShiftsManagerCreateInput"
	},
	Contember_StaffWithoutShiftsManagerCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		courier:"Contember_StaffCreateCourierEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffCreateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffCreateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffCreateStocksManagerEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		venues:"Contember_StaffCreateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_StaffCreateStocksManagerEntityRelationInput:{
		connect:"Contember_StocksManagerUniqueWhere",
		create:"Contember_StocksManagerWithoutStaffCreateInput"
	},
	Contember_StocksManagerWithoutStaffCreateInput:{
		id:"UUID",
		managedProducts:"Contember_StocksManagerCreateManagedProductsEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_StocksManagerCreateManagedProductsEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutManagerCreateInput"
	},
	Contember_ProductWithoutManagerCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateOrderItemsEntityRelationInput:{
		connect:"Contember_OrderItemUniqueWhere",
		create:"Contember_OrderItemWithoutProductCreateInput"
	},
	Contember_OrderItemWithoutProductCreateInput:{
		id:"UUID",
		vatRate:"Contember_OrderItemCreateVatRateEntityRelationInput",
		order:"Contember_OrderItemCreateOrderEntityRelationInput",
		virtualProductEffects:"Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput",
		pickedAt:"DateTime",
		fulfillmentNotes:"Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_OrderItemCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderItemCreateOrderEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutItemsCreateInput"
	},
	Contember_OrderWithoutItemsCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateDiscountsEntityRelationInput:{
		connect:"Contember_OrderDiscountUniqueWhere",
		create:"Contember_OrderDiscountWithoutOrderCreateInput"
	},
	Contember_OrderDiscountWithoutOrderCreateInput:{
		id:"UUID",
		vatRate:"Contember_OrderDiscountCreateVatRateEntityRelationInput",
		type:"Contember_OrderDiscountType",
		customerCreditTransactions:"Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput",
		voucherRedemption:"Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"
	},
	Contember_OrderDiscountCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput:{
		connect:"Contember_CustomerCreditTransactionUniqueWhere",
		create:"Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"
	},
	Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customerCredit:"Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput",
		virtualProductEffect:"Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"
	},
	Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput:{
		connect:"Contember_CustomerCreditUniqueWhere",
		create:"Contember_CustomerCreditWithoutTransactionsCreateInput"
	},
	Contember_CustomerCreditWithoutTransactionsCreateInput:{
		id:"UUID",
		expiresAt:"DateTime",
		customer:"Contember_CustomerCreditCreateCustomerEntityRelationInput",
		vatRate:"Contember_CustomerCreditCreateVatRateEntityRelationInput",
		createdAt:"DateTime",
		voucherRedemption:"Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"
	},
	Contember_CustomerCreditCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutCreditsCreateInput"
	},
	Contember_CustomerWithoutCreditsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateChannelsEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelWithoutCustomersCreateInput"
	},
	Contember_ChannelWithoutCustomersCreateInput:{
		id:"UUID",
		payments:"Contember_ChannelCreatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelCreateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelCreateCartsEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelCreatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelCreateVenueEntityRelationInput"
	},
	Contember_ChannelCreatePublicTagsEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutPublicInChannelsCreateInput"
	},
	Contember_TagWithoutPublicInChannelsCreateInput:{
		id:"UUID",
		customers:"Contember_TagCreateCustomersEntityRelationInput",
		products:"Contember_TagCreateProductsEntityRelationInput",
		categories:"Contember_TagCreateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagCreateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagCreateDeliveryZonesEntityRelationInput",
		allowChannelPayments:"Contember_TagCreateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagCreateAllowChannelPaymentsEntityRelationInput:{
		connect:"Contember_ChannelPaymentUniqueWhere",
		create:"Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"
	},
	Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput:{
		id:"UUID",
		channel:"Contember_ChannelPaymentCreateChannelEntityRelationInput",
		method:"Contember_ChannelPaymentCreateMethodEntityRelationInput",
		vatRate:"Contember_ChannelPaymentCreateVatRateEntityRelationInput"
	},
	Contember_ChannelPaymentCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateWithoutChannelPaymentsCreateInput"
	},
	Contember_VatRateWithoutChannelPaymentsCreateInput:{
		id:"UUID",
		channelDeliveries:"Contember_VatRateCreateChannelDeliveriesEntityRelationInput",
		preset:"Contember_VatRatePreset"
	},
	Contember_TagCreateFulfillmentNotesEntityRelationInput:{
		connect:"Contember_FulfillmentNoteUniqueWhere",
		create:"Contember_FulfillmentNoteWithoutTagsCreateInput"
	},
	Contember_FulfillmentNoteUniqueWhere:{
		id:"UUID"
	},
	Contember_FulfillmentNoteWithoutTagsCreateInput:{
		id:"UUID",
		products:"Contember_FulfillmentNoteCreateProductsEntityRelationInput",
		venues:"Contember_FulfillmentNoteCreateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteCreateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteCreateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_FulfillmentNoteCreateProductsEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutFulfillmentNotesCreateInput"
	},
	Contember_ProductWithoutFulfillmentNotesCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput"
	},
	Contember_ProductCreateInheritedCategoriesEntityRelationInput:{
		connect:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_ProductCreateDeliveryZonesEntityRelationInput:{
		connect:"Contember_DeliveryZoneUniqueWhere",
		create:"Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"
	},
	Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput:{
		id:"UUID",
		type:"Contember_DeliveryZoneType",
		addresses:"Contember_DeliveryZoneCreateAddressesEntityRelationInput",
		suitableDeliveryMethods:"Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput",
		deliveryZonesOfTags:"Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutDeliveryZonesCreateInput"
	},
	Contember_TagWithoutDeliveryZonesCreateInput:{
		id:"UUID",
		customers:"Contember_TagCreateCustomersEntityRelationInput",
		products:"Contember_TagCreateProductsEntityRelationInput",
		categories:"Contember_TagCreateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagCreateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		publicInChannels:"Contember_TagCreatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagCreateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagCreatePublicInChannelsEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelWithoutPublicTagsCreateInput"
	},
	Contember_ChannelWithoutPublicTagsCreateInput:{
		id:"UUID",
		payments:"Contember_ChannelCreatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelCreateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelCreateCartsEntityRelationInput",
		customers:"Contember_ChannelCreateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput",
		venue:"Contember_ChannelCreateVenueEntityRelationInput"
	},
	Contember_ChannelCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutChannelsCreateInput"
	},
	Contember_VenueWithoutChannelsCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreateDeadlineTemplateEntityRelationInput:{
		connect:"Contember_DeadlineTemplateUniqueWhere",
		create:"Contember_DeadlineTemplateWithoutVenueCreateInput"
	},
	Contember_DeadlineTemplateWithoutVenueCreateInput:{
		id:"UUID",
		startDate:"Date"
	},
	Contember_VenueCreateTimetableDaysEntityRelationInput:{
		connect:"Contember_TimetableDayUniqueWhere",
		create:"Contember_TimetableDayWithoutVenueCreateInput"
	},
	Contember_TimetableDayWithoutVenueCreateInput:{
		id:"UUID",
		date:"Date",
		type:"Contember_TimetableDayType",
		shiftGroups:"Contember_TimetableDayCreateShiftGroupsEntityRelationInput",
		notes:"Contember_TimetableDayCreateNotesEntityRelationInput",
		meta:"Contember_TimetableDayCreateMetaEntityRelationInput"
	},
	Contember_TimetableDayCreateShiftGroupsEntityRelationInput:{
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		create:"Contember_TimetableShiftGroupWithoutDayCreateInput"
	},
	Contember_TimetableShiftGroupWithoutDayCreateInput:{
		id:"UUID",
		shifts:"Contember_TimetableShiftGroupCreateShiftsEntityRelationInput",
		type:"Contember_TimetableShiftGroupCreateTypeEntityRelationInput",
		position:"Contember_TimetableShiftGroupCreatePositionEntityRelationInput",
		queue:"Contember_TimetableShiftGroupCreateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupCreateTypeEntityRelationInput:{
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		create:"Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"
	},
	Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput:{
		id:"UUID",
		shiftsProfiles:"Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput:{
		connect:"Contember_TimetableTemplateUniqueWhere",
		create:"Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"
	},
	Contember_TimetableTemplateWithoutEmployeeRoleCreateInput:{
		id:"UUID",
		startDate:"Date",
		position:"Contember_TimetableTemplateCreatePositionEntityRelationInput",
		staffOptions:"Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput",
		venue:"Contember_TimetableTemplateCreateVenueEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableTemplateCreatePositionEntityRelationInput:{
		connect:"Contember_TimetableShiftPositionUniqueWhere",
		create:"Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"
	},
	Contember_TimetableShiftPositionUniqueWhere:{
		id:"UUID",
		shiftGroups:"Contember_TimetableShiftGroupUniqueWhere",
		timetableTemplates:"Contember_TimetableTemplateUniqueWhere"
	},
	Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput:{
		id:"UUID",
		shiftGroups:"Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput"
	},
	Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput:{
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		create:"Contember_TimetableShiftGroupWithoutPositionCreateInput"
	},
	Contember_TimetableShiftGroupWithoutPositionCreateInput:{
		id:"UUID",
		shifts:"Contember_TimetableShiftGroupCreateShiftsEntityRelationInput",
		day:"Contember_TimetableShiftGroupCreateDayEntityRelationInput",
		type:"Contember_TimetableShiftGroupCreateTypeEntityRelationInput",
		queue:"Contember_TimetableShiftGroupCreateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupCreateQueueEntityRelationInput:{
		connect:"Contember_EmployeeQueueUniqueWhere",
		create:"Contember_EmployeeQueueWithoutShiftGroupCreateInput"
	},
	Contember_EmployeeQueueWithoutShiftGroupCreateInput:{
		id:"UUID",
		items:"Contember_EmployeeQueueCreateItemsEntityRelationInput"
	},
	Contember_EmployeeQueueCreateItemsEntityRelationInput:{
		connect:"Contember_EmployeeQueueItemUniqueWhere",
		create:"Contember_EmployeeQueueItemWithoutQueueCreateInput"
	},
	Contember_EmployeeQueueItemWithoutQueueCreateInput:{
		id:"UUID",
		shiftsProfile:"Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput",
		shift:"Contember_EmployeeQueueItemCreateShiftEntityRelationInput",
		unassignInfo:"Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput",
		meta:"Contember_EmployeeQueueItemCreateMetaEntityRelationInput"
	},
	Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput:{
		connect:"Contember_ShiftsProfileUniqueWhere",
		create:"Contember_ShiftsProfileWithoutQueueItemsCreateInput"
	},
	Contember_ShiftsProfileWithoutQueueItemsCreateInput:{
		id:"UUID",
		staff:"Contember_ShiftsProfileCreateStaffEntityRelationInput",
		roles:"Contember_ShiftsProfileCreateRolesEntityRelationInput",
		shifts:"Contember_ShiftsProfileCreateShiftsEntityRelationInput"
	},
	Contember_ShiftsProfileCreateShiftsEntityRelationInput:{
		connect:"Contember_TimetableShiftUniqueWhere",
		create:"Contember_TimetableShiftWithoutShiftsProfileCreateInput"
	},
	Contember_TimetableShiftWithoutShiftsProfileCreateInput:{
		id:"UUID",
		assigned:"Date",
		group:"Contember_TimetableShiftCreateGroupEntityRelationInput",
		queueItem:"Contember_TimetableShiftCreateQueueItemEntityRelationInput",
		swapInfo:"Contember_TimetableShiftCreateSwapInfoEntityRelationInput"
	},
	Contember_TimetableShiftCreateGroupEntityRelationInput:{
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		create:"Contember_TimetableShiftGroupWithoutShiftsCreateInput"
	},
	Contember_TimetableShiftGroupWithoutShiftsCreateInput:{
		id:"UUID",
		day:"Contember_TimetableShiftGroupCreateDayEntityRelationInput",
		type:"Contember_TimetableShiftGroupCreateTypeEntityRelationInput",
		position:"Contember_TimetableShiftGroupCreatePositionEntityRelationInput",
		queue:"Contember_TimetableShiftGroupCreateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupCreatePositionEntityRelationInput:{
		connect:"Contember_TimetableShiftPositionUniqueWhere",
		create:"Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"
	},
	Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput:{
		id:"UUID",
		timetableTemplates:"Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput:{
		connect:"Contember_TimetableTemplateUniqueWhere",
		create:"Contember_TimetableTemplateWithoutPositionCreateInput"
	},
	Contember_TimetableTemplateWithoutPositionCreateInput:{
		id:"UUID",
		startDate:"Date",
		staffOptions:"Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput",
		venue:"Contember_TimetableTemplateCreateVenueEntityRelationInput",
		employeeRole:"Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput:{
		connect:"Contember_TimetableTemplateStaffOptionsUniqueWhere",
		create:"Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"
	},
	Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput:{
		id:"UUID",
		autoAssignedEmployee:"Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput"
	},
	Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput:{
		connect:"Contember_ShiftsProfileUniqueWhere",
		create:"Contember_ShiftsProfileCreateInput"
	},
	Contember_ShiftsProfileCreateInput:{
		id:"UUID",
		queueItems:"Contember_ShiftsProfileCreateQueueItemsEntityRelationInput",
		staff:"Contember_ShiftsProfileCreateStaffEntityRelationInput",
		roles:"Contember_ShiftsProfileCreateRolesEntityRelationInput",
		shifts:"Contember_ShiftsProfileCreateShiftsEntityRelationInput"
	},
	Contember_TimetableTemplateCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutTimetableTemplatesCreateInput"
	},
	Contember_VenueWithoutTimetableTemplatesCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreateStaffMembersEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffWithoutVenuesCreateInput"
	},
	Contember_StaffWithoutVenuesCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		courier:"Contember_StaffCreateCourierEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffCreateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffCreateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffCreateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffCreateStocksManagerEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_StaffCreatePhotoEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_StaffCreateCourierManagerEntityRelationInput:{
		connect:"Contember_CourierManagerUniqueWhere",
		create:"Contember_CourierManagerWithoutStaffCreateInput"
	},
	Contember_CourierManagerWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime"
	},
	Contember_StaffCreatePickerEntityRelationInput:{
		connect:"Contember_PickerUniqueWhere",
		create:"Contember_PickerWithoutStaffCreateInput"
	},
	Contember_PickerWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime"
	},
	Contember_StaffCreateRemunerationProfileEntityRelationInput:{
		connect:"Contember_RemunerationProfileUniqueWhere",
		create:"Contember_RemunerationProfileWithoutStaffCreateInput"
	},
	Contember_RemunerationProfileWithoutStaffCreateInput:{
		id:"UUID",
		employmentInformation:"Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput",
		workMonths:"Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput"
	},
	Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput:{
		connect:"Contember_EmploymentInformationUniqueWhere",
		create:"Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"
	},
	Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput:{
		id:"UUID",
		type:"Contember_EmploymentType"
	},
	Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput:{
		connect:"Contember_WorkMonthUniqueWhere",
		create:"Contember_WorkMonthWithoutRemunerationProfileCreateInput"
	},
	Contember_WorkMonthUniqueWhere:{
		id:"UUID",
		remunerationProfile:"Contember_RemunerationProfileUniqueWhere"
	},
	Contember_WorkMonthWithoutRemunerationProfileCreateInput:{
		id:"UUID"
	},
	Contember_StaffCreateCashierEntityRelationInput:{
		connect:"Contember_CashierUniqueWhere",
		create:"Contember_CashierWithoutStaffCreateInput"
	},
	Contember_CashierWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime"
	},
	Contember_StaffCreateInternalBuyerEntityRelationInput:{
		connect:"Contember_InternalBuyerUniqueWhere",
		create:"Contember_InternalBuyerWithoutStaffCreateInput"
	},
	Contember_InternalBuyerWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime"
	},
	Contember_StaffCreateSalesManagerEntityRelationInput:{
		connect:"Contember_SalesManagerUniqueWhere",
		create:"Contember_SalesManagerWithoutStaffCreateInput"
	},
	Contember_SalesManagerWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		countries:"Contember_SalesManagerCreateCountriesEntityRelationInput"
	},
	Contember_SalesManagerCreateCountriesEntityRelationInput:{
		connect:"Contember_CountryUniqueWhere",
		create:"Contember_CountryCreateInput"
	},
	Contember_StaffCreateCashierManagerEntityRelationInput:{
		connect:"Contember_CashierManagerUniqueWhere",
		create:"Contember_CashierManagerWithoutStaffCreateInput"
	},
	Contember_CashierManagerWithoutStaffCreateInput:{
		id:"UUID",
		createdAt:"DateTime"
	},
	Contember_VenueCreateEmployeeRolesEntityRelationInput:{
		connect:"Contember_VenueEmployeeRoleUniqueWhere",
		create:"Contember_VenueEmployeeRoleWithoutVenueCreateInput"
	},
	Contember_VenueEmployeeRoleWithoutVenueCreateInput:{
		id:"UUID",
		employeePrivileges:"Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput",
		role:"Contember_VenueEmployeeRoleCreateRoleEntityRelationInput"
	},
	Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput:{
		connect:"Contember_TimetablesEmployeePrivilegesUniqueWhere",
		create:"Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"
	},
	Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput:{
		id:"UUID"
	},
	Contember_VenueEmployeeRoleCreateRoleEntityRelationInput:{
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		create:"Contember_TimetableEmployeeRoleCreateInput"
	},
	Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput:{
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		create:"Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput:{
		id:"UUID",
		identificationCode:"Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput:{
		connect:"Contember_IdentificationCodeUniqueWhere",
		create:"Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"
	},
	Contember_IdentificationCodeWithoutApplicationDevicesCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		attempts:"Contember_IdentificationCodeCreateAttemptsEntityRelationInput",
		locales:"Contember_IdentificationCodeCreateLocalesEntityRelationInput"
	},
	Contember_IdentificationCodeCreateAttemptsEntityRelationInput:{
		connect:"Contember_IdentificationAttemptUniqueWhere",
		create:"Contember_IdentificationAttemptWithoutCodeCreateInput"
	},
	Contember_IdentificationAttemptWithoutCodeCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		user:"Contember_IdentificationAttemptCreateUserEntityRelationInput"
	},
	Contember_IdentificationAttemptCreateUserEntityRelationInput:{
		connect:"Contember_UserUniqueWhere",
		create:"Contember_UserWithoutIdentificationAttemptsCreateInput"
	},
	Contember_UserWithoutIdentificationAttemptsCreateInput:{
		id:"UUID",
		staff:"Contember_UserCreateStaffEntityRelationInput",
		customer:"Contember_UserCreateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserCreateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserCreateMetaEntityRelationInput",
		roles:"Contember_UserCreateRolesEntityRelationInput"
	},
	Contember_UserCreateDevicesEntityRelationInput:{
		connect:"Contember_UserDeviceUniqueWhere",
		create:"Contember_UserDeviceWithoutUserCreateInput"
	},
	Contember_UserDeviceWithoutUserCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		anonymousSession:"Contember_UserDeviceCreateAnonymousSessionEntityRelationInput"
	},
	Contember_UserDeviceCreateAnonymousSessionEntityRelationInput:{
		connect:"Contember_AnonymousSessionUniqueWhere",
		create:"Contember_AnonymousSessionWithoutDevicesCreateInput"
	},
	Contember_AnonymousSessionWithoutDevicesCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		customer:"Contember_AnonymousSessionCreateCustomerEntityRelationInput"
	},
	Contember_AnonymousSessionCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutAnonymousSessionCreateInput"
	},
	Contember_CustomerWithoutAnonymousSessionCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateInvoicesEntityRelationInput:{
		connect:"Contember_InvoiceUniqueWhere",
		create:"Contember_InvoiceWithoutCustomerCreateInput"
	},
	Contember_InvoiceWithoutCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		orderPayments:"Contember_InvoiceCreateOrderPaymentsEntityRelationInput",
		address:"Contember_InvoiceCreateAddressEntityRelationInput",
		billingSubject:"Contember_InvoiceCreateBillingSubjectEntityRelationInput"
	},
	Contember_InvoiceCreateOrderPaymentsEntityRelationInput:{
		connect:"Contember_OrderPaymentUniqueWhere",
		create:"Contember_OrderPaymentWithoutInvoiceCreateInput"
	},
	Contember_OrderPaymentWithoutInvoiceCreateInput:{
		id:"UUID",
		data:"Json",
		method:"Contember_OrderPaymentCreateMethodEntityRelationInput",
		failedAt:"DateTime",
		state:"Contember_OrderPaymentState",
		approvedAt:"DateTime",
		events:"Contember_OrderPaymentCreateEventsEntityRelationInput",
		order:"Contember_OrderPaymentCreateOrderEntityRelationInput",
		createdAt:"DateTime",
		vatRate:"Contember_OrderPaymentCreateVatRateEntityRelationInput",
		meta:"Contember_OrderPaymentCreateMetaEntityRelationInput"
	},
	Contember_OrderPaymentCreateMethodEntityRelationInput:{
		connect:"Contember_PaymentMethodUniqueWhere",
		create:"Contember_PaymentMethodCreateInput"
	},
	Contember_OrderPaymentCreateEventsEntityRelationInput:{
		connect:"Contember_OrderEventUniqueWhere",
		create:"Contember_OrderEventWithoutPaymentCreateInput"
	},
	Contember_OrderEventWithoutPaymentCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		order:"Contember_OrderEventCreateOrderEntityRelationInput",
		delivery:"Contember_OrderEventCreateDeliveryEntityRelationInput"
	},
	Contember_OrderEventCreateOrderEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutEventsCreateInput"
	},
	Contember_OrderWithoutEventsCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateDeliveryEntityRelationInput:{
		connect:"Contember_OrderDeliveryUniqueWhere",
		create:"Contember_OrderDeliveryWithoutOrderCreateInput"
	},
	Contember_OrderDeliveryWithoutOrderCreateInput:{
		id:"UUID",
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryCreateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryCreateVatRateEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		deliveryWaypoint:"Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput",
		events:"Contember_OrderDeliveryCreateEventsEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime",
		address:"Contember_OrderDeliveryCreateAddressEntityRelationInput"
	},
	Contember_OrderDeliveryCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput:{
		connect:"Contember_DeliveryWaypointUniqueWhere",
		create:"Contember_DeliveryWaypointWithoutDeliveryCreateInput"
	},
	Contember_DeliveryWaypointWithoutDeliveryCreateInput:{
		id:"UUID",
		estimatedArrival:"DateTime",
		route:"Contember_DeliveryWaypointCreateRouteEntityRelationInput",
		originDeparture:"DateTime"
	},
	Contember_DeliveryWaypointCreateRouteEntityRelationInput:{
		connect:"Contember_DeliveryRouteUniqueWhere",
		create:"Contember_DeliveryRouteWithoutWaypointsCreateInput"
	},
	Contember_DeliveryRouteWithoutWaypointsCreateInput:{
		id:"UUID",
		courier:"Contember_DeliveryRouteCreateCourierEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_DeliveryRouteCreateCourierEntityRelationInput:{
		connect:"Contember_CourierUniqueWhere",
		create:"Contember_CourierWithoutRoutesCreateInput"
	},
	Contember_CourierWithoutRoutesCreateInput:{
		id:"UUID",
		staff:"Contember_CourierCreateStaffEntityRelationInput",
		createdAt:"DateTime",
		deliveryMethods:"Contember_CourierCreateDeliveryMethodsEntityRelationInput"
	},
	Contember_CourierCreateDeliveryMethodsEntityRelationInput:{
		connect:"Contember_DeliveryMethodUniqueWhere",
		create:"Contember_DeliveryMethodWithoutCourierHandlersCreateInput"
	},
	Contember_DeliveryMethodWithoutCourierHandlersCreateInput:{
		id:"UUID",
		paymentMethods:"Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodCreateChannelsEntityRelationInput",
		locales:"Contember_DeliveryMethodCreateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodCreateLocalesEntityRelationInput:{
		connect:"Contember_DeliveryMethodLocaleUniqueWhere",
		create:"Contember_DeliveryMethodLocaleWithoutRootCreateInput"
	},
	Contember_DeliveryMethodLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput"
	},
	Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutDeliveryMethodsCreateInput"
	},
	Contember_LocaleWithoutDeliveryMethodsCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateProductPackingsEntityRelationInput:{
		connect:"Contember_ProductPackingLocaleUniqueWhere",
		create:"Contember_ProductPackingLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductPackingLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_ProductPackingLocaleCreateRootEntityRelationInput"
	},
	Contember_ProductPackingLocaleCreateRootEntityRelationInput:{
		connect:"Contember_ProductPackingUniqueWhere",
		create:"Contember_ProductPackingWithoutLocalesCreateInput"
	},
	Contember_ProductPackingWithoutLocalesCreateInput:{
		id:"UUID",
		image:"Contember_ProductPackingCreateImageEntityRelationInput",
		icon:"Contember_ProductPackingCreateIconEntityRelationInput",
		preparedPackingQuantities:"Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"
	},
	Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput:{
		connect:"Contember_PreparedPackingQuantityUniqueWhere",
		create:"Contember_PreparedPackingQuantityWithoutPackingCreateInput"
	},
	Contember_PreparedPackingQuantityWithoutPackingCreateInput:{
		id:"UUID",
		date:"Date",
		recipe:"Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput"
	},
	Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput:{
		connect:"Contember_ProductRecipeUniqueWhere",
		create:"Contember_ProductRecipeCreateInput"
	},
	Contember_ProductRecipeCreateInput:{
		id:"UUID",
		products:"Contember_ProductRecipeCreateProductsEntityRelationInput",
		image:"Contember_ProductRecipeCreateImageEntityRelationInput",
		icon:"Contember_ProductRecipeCreateIconEntityRelationInput",
		ingredients:"Contember_ProductRecipeCreateIngredientsEntityRelationInput",
		locales:"Contember_ProductRecipeCreateLocalesEntityRelationInput",
		freshingContainer:"Contember_ProductRecipeCreateFreshingContainerEntityRelationInput",
		nutritionFacts:"Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"
	},
	Contember_ProductRecipeCreateProductsEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutRecipeCreateInput"
	},
	Contember_ProductWithoutRecipeCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateLocalesEntityRelationInput:{
		connect:"Contember_ProductLocaleUniqueWhere",
		create:"Contember_ProductLocaleWithoutRootCreateInput"
	},
	Contember_ProductLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_ProductLocaleCreateLocaleEntityRelationInput",
		featureList:"Contember_ProductLocaleCreateFeatureListEntityRelationInput"
	},
	Contember_ProductLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutProductsCreateInput"
	},
	Contember_LocaleWithoutProductsCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateProductRecipesEntityRelationInput:{
		connect:"Contember_ProductRecipeLocaleUniqueWhere",
		create:"Contember_ProductRecipeLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductRecipeLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_ProductRecipeLocaleCreateRootEntityRelationInput"
	},
	Contember_ProductRecipeLocaleCreateRootEntityRelationInput:{
		connect:"Contember_ProductRecipeUniqueWhere",
		create:"Contember_ProductRecipeWithoutLocalesCreateInput"
	},
	Contember_ProductRecipeWithoutLocalesCreateInput:{
		id:"UUID",
		products:"Contember_ProductRecipeCreateProductsEntityRelationInput",
		image:"Contember_ProductRecipeCreateImageEntityRelationInput",
		icon:"Contember_ProductRecipeCreateIconEntityRelationInput",
		ingredients:"Contember_ProductRecipeCreateIngredientsEntityRelationInput",
		freshingContainer:"Contember_ProductRecipeCreateFreshingContainerEntityRelationInput",
		nutritionFacts:"Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"
	},
	Contember_ProductRecipeCreateFreshingContainerEntityRelationInput:{
		connect:"Contember_FreshingContainerUniqueWhere",
		create:"Contember_FreshingContainerCreateInput"
	},
	Contember_ProductRecipeCreateNutritionFactsEntityRelationInput:{
		connect:"Contember_NutritionFactsUniqueWhere",
		create:"Contember_NutritionFactsCreateInput"
	},
	Contember_NutritionFactsCreateInput:{
		id:"UUID"
	},
	Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		create:"Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"
	},
	Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput"
	},
	Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryUniqueWhere",
		create:"Contember_PointOfSaleCategoryWithoutLocalesCreateInput"
	},
	Contember_PointOfSaleCategoryWithoutLocalesCreateInput:{
		id:"UUID",
		products:"Contember_PointOfSaleCategoryCreateProductsEntityRelationInput",
		category:"Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput",
		venue:"Contember_PointOfSaleCategoryCreateVenueEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleCategoryCreateProductsEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryProductUniqueWhere",
		create:"Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"
	},
	Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput:{
		id:"UUID",
		product:"Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput",
		locales:"Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductCreateInput"
	},
	Contember_ProductCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductCreateCustomerPricesEntityRelationInput:{
		connect:"Contember_ProductCustomerPriceUniqueWhere"
	},
	Contember_ProductCreateVirtualProductEntityRelationInput:{
		connect:"Contember_VirtualProductUniqueWhere",
		create:"Contember_VirtualProductWithoutProductCreateInput"
	},
	Contember_VirtualProductWithoutProductCreateInput:{
		id:"UUID",
		type:"Contember_VirtualProductType",
		physicalRepresentation:"Contember_VirtualProductPhysicalRepresentationType"
	},
	Contember_ProductCreateMetaEntityRelationInput:{
		connect:"Contember_ProductMetadataUniqueWhere"
	},
	Contember_ProductCreatePageEntityRelationInput:{
		connect:"Contember_PageUniqueWhere",
		create:"Contember_PageCreateInput"
	},
	Contember_PageCreateInput:{
		id:"UUID",
		locales:"Contember_PageCreateLocalesEntityRelationInput"
	},
	Contember_PageCreateLocalesEntityRelationInput:{
		connect:"Contember_PageLocaleUniqueWhere",
		create:"Contember_PageLocaleWithoutRootCreateInput"
	},
	Contember_PageLocaleWithoutRootCreateInput:{
		id:"UUID",
		link:"Contember_PageLocaleCreateLinkEntityRelationInput",
		locale:"Contember_PageLocaleCreateLocaleEntityRelationInput",
		seo:"Contember_PageLocaleCreateSeoEntityRelationInput",
		content:"Contember_PageLocaleCreateContentEntityRelationInput",
		cover:"Contember_PageLocaleCreateCoverEntityRelationInput",
		theme:"Contember_PageTheme"
	},
	Contember_PageLocaleCreateSeoEntityRelationInput:{
		connect:"Contember_SeoUniqueWhere",
		create:"Contember_SeoCreateInput"
	},
	Contember_SeoCreateInput:{
		id:"UUID",
		ogImage:"Contember_SeoCreateOgImageEntityRelationInput"
	},
	Contember_SeoCreateOgImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_PageLocaleCreateContentEntityRelationInput:{
		connect:"Contember_ContentUniqueWhere",
		create:"Contember_ContentCreateInput"
	},
	Contember_PageLocaleCreateCoverEntityRelationInput:{
		connect:"Contember_CoverUniqueWhere",
		create:"Contember_CoverCreateInput"
	},
	Contember_ProductCreateSalesDaysEntityRelationInput:{
		connect:"Contember_ProductSalesDayUniqueWhere"
	},
	Contember_ProductCreateSalesWeeksEntityRelationInput:{
		connect:"Contember_ProductSalesWeekUniqueWhere"
	},
	Contember_ProductCreateSalesMonthsEntityRelationInput:{
		connect:"Contember_ProductSalesMonthUniqueWhere"
	},
	Contember_ProductCreateFulfillmentNotesEntityRelationInput:{
		connect:"Contember_FulfillmentNoteUniqueWhere",
		create:"Contember_FulfillmentNoteWithoutProductsCreateInput"
	},
	Contember_FulfillmentNoteWithoutProductsCreateInput:{
		id:"UUID",
		tags:"Contember_FulfillmentNoteCreateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteCreateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteCreateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteCreateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_FulfillmentNoteCreateTagsEntityRelationInput:{
		connect:"Contember_TagUniqueWhere",
		create:"Contember_TagWithoutFulfillmentNotesCreateInput"
	},
	Contember_TagWithoutFulfillmentNotesCreateInput:{
		id:"UUID",
		customers:"Contember_TagCreateCustomersEntityRelationInput",
		products:"Contember_TagCreateProductsEntityRelationInput",
		categories:"Contember_TagCreateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagCreateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagCreateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagCreateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagCreatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagCreateAllowChannelPaymentsEntityRelationInput"
	},
	Contember_FulfillmentNoteCreateVenuesEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutFulfillmentNotesCreateInput"
	},
	Contember_VenueWithoutFulfillmentNotesCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreateTimetableTemplatesEntityRelationInput:{
		connect:"Contember_TimetableTemplateUniqueWhere",
		create:"Contember_TimetableTemplateWithoutVenueCreateInput"
	},
	Contember_TimetableTemplateWithoutVenueCreateInput:{
		id:"UUID",
		startDate:"Date",
		position:"Contember_TimetableTemplateCreatePositionEntityRelationInput",
		staffOptions:"Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput",
		employeeRole:"Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput:{
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		create:"Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"
	},
	Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput:{
		id:"UUID",
		shiftGroups:"Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput",
		shiftsProfiles:"Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"
	},
	Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput:{
		connect:"Contember_PointOfSaleTerminalUniqueWhere",
		create:"Contember_PointOfSaleTerminalWithoutVenueCreateInput"
	},
	Contember_PointOfSaleTerminalWithoutVenueCreateInput:{
		id:"UUID",
		applicationDevices:"Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput",
		type:"Contember_PointOfSaleTerminalType"
	},
	Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput:{
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		create:"Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput:{
		id:"UUID",
		venue:"Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput",
		identificationCode:"Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"
	},
	Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreatePointOfSalePrintersEntityRelationInput:{
		connect:"Contember_PointOfSalePrinterUniqueWhere",
		create:"Contember_PointOfSalePrinterWithoutVenueCreateInput"
	},
	Contember_PointOfSalePrinterWithoutVenueCreateInput:{
		id:"UUID",
		applicationDevicesReceipt:"Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput",
		applicationDevicesTicket:"Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput",
		ticketCategories:"Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"
	},
	Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput:{
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		create:"Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput:{
		id:"UUID",
		venue:"Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput",
		identificationCode:"Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput:{
		connect:"Contember_DeviceIdentifierUniqueWhere",
		create:"Contember_DeviceIdentifierWithoutDeviceCreateInput"
	},
	Contember_DeviceIdentifierWithoutDeviceCreateInput:{
		id:"UUID",
		updatedAt:"DateTime",
		updatedByUser:"Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput"
	},
	Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput:{
		connect:"Contember_UserUniqueWhere",
		create:"Contember_UserCreateInput"
	},
	Contember_UserCreateInput:{
		id:"UUID",
		staff:"Contember_UserCreateStaffEntityRelationInput",
		customer:"Contember_UserCreateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserCreateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserCreateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserCreateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserCreateRolesEntityRelationInput"
	},
	Contember_UserCreateMetaEntityRelationInput:{
		connect:"Contember_UserMetadataUniqueWhere"
	},
	Contember_UserCreateIdentificationAttemptsEntityRelationInput:{
		connect:"Contember_IdentificationAttemptUniqueWhere",
		create:"Contember_IdentificationAttemptWithoutUserCreateInput"
	},
	Contember_IdentificationAttemptWithoutUserCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		code:"Contember_IdentificationAttemptCreateCodeEntityRelationInput"
	},
	Contember_IdentificationAttemptCreateCodeEntityRelationInput:{
		connect:"Contember_IdentificationCodeUniqueWhere",
		create:"Contember_IdentificationCodeWithoutAttemptsCreateInput"
	},
	Contember_IdentificationCodeWithoutAttemptsCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		locales:"Contember_IdentificationCodeCreateLocalesEntityRelationInput",
		applicationDevices:"Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"
	},
	Contember_IdentificationCodeCreateLocalesEntityRelationInput:{
		connect:"Contember_IdentificationCodeLocaleUniqueWhere",
		create:"Contember_IdentificationCodeLocaleWithoutRootCreateInput"
	},
	Contember_IdentificationCodeLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput"
	},
	Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutIdentificationCodesCreateInput"
	},
	Contember_LocaleWithoutIdentificationCodesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateProductsEntityRelationInput:{
		connect:"Contember_ProductLocaleUniqueWhere",
		create:"Contember_ProductLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_ProductLocaleCreateRootEntityRelationInput",
		featureList:"Contember_ProductLocaleCreateFeatureListEntityRelationInput"
	},
	Contember_ProductLocaleCreateRootEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutLocalesCreateInput"
	},
	Contember_ProductWithoutLocalesCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		orderItems:"Contember_ProductCreateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductLocaleCreateFeatureListEntityRelationInput:{
		connect:"Contember_TextListUniqueWhere",
		create:"Contember_TextListCreateInput"
	},
	Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		create:"Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"
	},
	Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryProductUniqueWhere",
		create:"Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"
	},
	Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput:{
		id:"UUID",
		category:"Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput",
		product:"Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryUniqueWhere",
		create:"Contember_PointOfSaleCategoryWithoutProductsCreateInput"
	},
	Contember_PointOfSaleCategoryWithoutProductsCreateInput:{
		id:"UUID",
		locales:"Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput",
		category:"Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput",
		venue:"Contember_PointOfSaleCategoryCreateVenueEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		create:"Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"
	},
	Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput"
	},
	Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"
	},
	Contember_LocaleWithoutPointOfSaleCategoriesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateDeliveryMethodsEntityRelationInput:{
		connect:"Contember_DeliveryMethodLocaleUniqueWhere",
		create:"Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"
	},
	Contember_DeliveryMethodLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_DeliveryMethodLocaleCreateRootEntityRelationInput"
	},
	Contember_DeliveryMethodLocaleCreateRootEntityRelationInput:{
		connect:"Contember_DeliveryMethodUniqueWhere",
		create:"Contember_DeliveryMethodWithoutLocalesCreateInput"
	},
	Contember_DeliveryMethodWithoutLocalesCreateInput:{
		id:"UUID",
		paymentMethods:"Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodCreateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput:{
		connect:"Contember_DeliveryTimelinePresetUniqueWhere",
		create:"Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"
	},
	Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput:{
		id:"UUID",
		type:"Contember_DeliveryTimelineType"
	},
	Contember_LocaleCreateIngredientSuppliersEntityRelationInput:{
		connect:"Contember_IngredientSupplierLocaleUniqueWhere",
		create:"Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"
	},
	Contember_IngredientSupplierLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_IngredientSupplierLocaleCreateRootEntityRelationInput"
	},
	Contember_IngredientSupplierLocaleCreateRootEntityRelationInput:{
		connect:"Contember_IngredientSupplierUniqueWhere",
		create:"Contember_IngredientSupplierWithoutLocalesCreateInput"
	},
	Contember_IngredientSupplierWithoutLocalesCreateInput:{
		id:"UUID"
	},
	Contember_LocaleCreateIdentificationCodesEntityRelationInput:{
		connect:"Contember_IdentificationCodeLocaleUniqueWhere",
		create:"Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"
	},
	Contember_IdentificationCodeLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_IdentificationCodeLocaleCreateRootEntityRelationInput"
	},
	Contember_IdentificationCodeLocaleCreateRootEntityRelationInput:{
		connect:"Contember_IdentificationCodeUniqueWhere",
		create:"Contember_IdentificationCodeWithoutLocalesCreateInput"
	},
	Contember_IdentificationCodeWithoutLocalesCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		attempts:"Contember_IdentificationCodeCreateAttemptsEntityRelationInput",
		applicationDevices:"Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"
	},
	Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput:{
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		create:"Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput:{
		id:"UUID",
		venue:"Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput:{
		connect:"Contember_PointOfSaleTerminalUniqueWhere",
		create:"Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"
	},
	Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput:{
		id:"UUID",
		venue:"Contember_PointOfSaleTerminalCreateVenueEntityRelationInput",
		type:"Contember_PointOfSaleTerminalType"
	},
	Contember_PointOfSaleTerminalCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutPointOfSaleTerminalsCreateInput"
	},
	Contember_VenueWithoutPointOfSaleTerminalsCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreateOrdersEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutVenueCreateInput"
	},
	Contember_OrderWithoutVenueCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateItemsEntityRelationInput:{
		connect:"Contember_OrderItemUniqueWhere",
		create:"Contember_OrderItemWithoutOrderCreateInput"
	},
	Contember_OrderItemWithoutOrderCreateInput:{
		id:"UUID",
		vatRate:"Contember_OrderItemCreateVatRateEntityRelationInput",
		virtualProductEffects:"Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput",
		pickedAt:"DateTime",
		fulfillmentNotes:"Contember_OrderItemCreateFulfillmentNotesEntityRelationInput",
		product:"Contember_OrderItemCreateProductEntityRelationInput"
	},
	Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput:{
		connect:"Contember_VirtualProductEffectUniqueWhere",
		create:"Contember_VirtualProductEffectWithoutOrderItemCreateInput"
	},
	Contember_VirtualProductEffectWithoutOrderItemCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		succeededAt:"DateTime",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultCustomerCreditTransaction:"Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput",
		resultVouchers:"Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"
	},
	Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput:{
		connect:"Contember_CustomerCreditTransactionUniqueWhere",
		create:"Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"
	},
	Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customerCredit:"Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput",
		orderDiscount:"Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"
	},
	Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput:{
		connect:"Contember_OrderDiscountUniqueWhere",
		create:"Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"
	},
	Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput:{
		id:"UUID",
		vatRate:"Contember_OrderDiscountCreateVatRateEntityRelationInput",
		order:"Contember_OrderDiscountCreateOrderEntityRelationInput",
		type:"Contember_OrderDiscountType",
		voucherRedemption:"Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"
	},
	Contember_OrderDiscountCreateOrderEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutDiscountsCreateInput"
	},
	Contember_OrderWithoutDiscountsCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateCartEntityRelationInput:{
		connect:"Contember_CartUniqueWhere",
		create:"Contember_CartWithoutOrderCreateInput"
	},
	Contember_CartWithoutOrderCreateInput:{
		id:"UUID",
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"Contember_CartCreateItemsEntityRelationInput",
		state:"Contember_CartState",
		createdAt:"DateTime",
		channel:"Contember_CartCreateChannelEntityRelationInput",
		customer:"Contember_CartCreateCustomerEntityRelationInput",
		deliveryAddress:"Contember_CartCreateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartCreateBillingAddressEntityRelationInput",
		delivery:"Contember_CartCreateDeliveryEntityRelationInput",
		payment:"Contember_CartCreatePaymentEntityRelationInput",
		billingSubject:"Contember_CartCreateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartCreatePriceLinesEntityRelationInput",
		summary:"Contember_CartCreateSummaryEntityRelationInput"
	},
	Contember_CartCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutCartsCreateInput"
	},
	Contember_CustomerWithoutCartsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateInvoicingPlanEntityRelationInput:{
		connect:"Contember_InvoicingPlanUniqueWhere",
		create:"Contember_InvoicingPlanWithoutCustomerCreateInput"
	},
	Contember_InvoicingPlanWithoutCustomerCreateInput:{
		id:"UUID",
		preset:"Contember_InvoicingPlanCreatePresetEntityRelationInput"
	},
	Contember_InvoicingPlanCreatePresetEntityRelationInput:{
		connect:"Contember_InvoicingPresetUniqueWhere",
		create:"Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"
	},
	Contember_InvoicingPresetWithoutInvoicingPlanCreateInput:{
		id:"UUID",
		type:"Contember_InvoicingPresetType",
		startsAt:"DateTime",
		endsAt:"DateTime"
	},
	Contember_CustomerCreateBillingSubjectsEntityRelationInput:{
		connect:"Contember_BillingSubjectUniqueWhere",
		create:"Contember_BillingSubjectWithoutCustomerCreateInput"
	},
	Contember_BillingSubjectWithoutCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectCreateCountryEntityRelationInput",
		invoices:"Contember_BillingSubjectCreateInvoicesEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput",
		orders:"Contember_BillingSubjectCreateOrdersEntityRelationInput"
	},
	Contember_BillingSubjectCreateCountryEntityRelationInput:{
		connect:"Contember_CountryUniqueWhere",
		create:"Contember_CountryCreateInput"
	},
	Contember_BillingSubjectCreateInvoicesEntityRelationInput:{
		connect:"Contember_InvoiceUniqueWhere",
		create:"Contember_InvoiceWithoutBillingSubjectCreateInput"
	},
	Contember_InvoiceWithoutBillingSubjectCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customer:"Contember_InvoiceCreateCustomerEntityRelationInput",
		orderPayments:"Contember_InvoiceCreateOrderPaymentsEntityRelationInput",
		address:"Contember_InvoiceCreateAddressEntityRelationInput"
	},
	Contember_InvoiceCreateAddressEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressWithoutInvoicesCreateInput"
	},
	Contember_AddressWithoutInvoicesCreateInput:{
		id:"UUID",
		customer:"Contember_AddressCreateCustomerEntityRelationInput",
		country:"Contember_AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressCreateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressCreateMetaEntityRelationInput",
		gps:"Contember_AddressCreateGpsEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutBillingAddressCreateInput"
	},
	Contember_OrderWithoutBillingAddressCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateEventsEntityRelationInput:{
		connect:"Contember_OrderEventUniqueWhere",
		create:"Contember_OrderEventWithoutOrderCreateInput"
	},
	Contember_OrderEventWithoutOrderCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		payment:"Contember_OrderEventCreatePaymentEntityRelationInput",
		delivery:"Contember_OrderEventCreateDeliveryEntityRelationInput"
	},
	Contember_OrderEventCreatePaymentEntityRelationInput:{
		connect:"Contember_OrderPaymentUniqueWhere",
		create:"Contember_OrderPaymentWithoutEventsCreateInput"
	},
	Contember_OrderPaymentWithoutEventsCreateInput:{
		id:"UUID",
		data:"Json",
		method:"Contember_OrderPaymentCreateMethodEntityRelationInput",
		failedAt:"DateTime",
		state:"Contember_OrderPaymentState",
		approvedAt:"DateTime",
		order:"Contember_OrderPaymentCreateOrderEntityRelationInput",
		invoice:"Contember_OrderPaymentCreateInvoiceEntityRelationInput",
		createdAt:"DateTime",
		vatRate:"Contember_OrderPaymentCreateVatRateEntityRelationInput",
		meta:"Contember_OrderPaymentCreateMetaEntityRelationInput"
	},
	Contember_OrderPaymentCreateOrderEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutPaymentsCreateInput"
	},
	Contember_OrderWithoutPaymentsCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateBillingAddressEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressWithoutBillingAddressOfOrdersCreateInput"
	},
	Contember_AddressWithoutBillingAddressOfOrdersCreateInput:{
		id:"UUID",
		customer:"Contember_AddressCreateCustomerEntityRelationInput",
		country:"Contember_AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressCreateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressCreateMetaEntityRelationInput",
		gps:"Contember_AddressCreateGpsEntityRelationInput",
		invoices:"Contember_AddressCreateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		deliveryAddressOfOrderDeliveries:"Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput:{
		connect:"Contember_OrderDeliveryUniqueWhere",
		create:"Contember_OrderDeliveryWithoutAddressCreateInput"
	},
	Contember_OrderDeliveryWithoutAddressCreateInput:{
		id:"UUID",
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryCreateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryCreateVatRateEntityRelationInput",
		order:"Contember_OrderDeliveryCreateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		deliveryWaypoint:"Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput",
		events:"Contember_OrderDeliveryCreateEventsEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime"
	},
	Contember_OrderDeliveryCreateOrderEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutDeliveryCreateInput"
	},
	Contember_OrderWithoutDeliveryCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreatePriceLinesEntityRelationInput:{
		connect:"Contember_OrderPriceLineUniqueWhere"
	},
	Contember_OrderCreatePaymentsEntityRelationInput:{
		connect:"Contember_OrderPaymentUniqueWhere",
		create:"Contember_OrderPaymentWithoutOrderCreateInput"
	},
	Contember_OrderPaymentWithoutOrderCreateInput:{
		id:"UUID",
		data:"Json",
		method:"Contember_OrderPaymentCreateMethodEntityRelationInput",
		failedAt:"DateTime",
		state:"Contember_OrderPaymentState",
		approvedAt:"DateTime",
		events:"Contember_OrderPaymentCreateEventsEntityRelationInput",
		invoice:"Contember_OrderPaymentCreateInvoiceEntityRelationInput",
		createdAt:"DateTime",
		vatRate:"Contember_OrderPaymentCreateVatRateEntityRelationInput",
		meta:"Contember_OrderPaymentCreateMetaEntityRelationInput"
	},
	Contember_OrderPaymentCreateInvoiceEntityRelationInput:{
		connect:"Contember_InvoiceUniqueWhere",
		create:"Contember_InvoiceWithoutOrderPaymentsCreateInput"
	},
	Contember_InvoiceWithoutOrderPaymentsCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customer:"Contember_InvoiceCreateCustomerEntityRelationInput",
		address:"Contember_InvoiceCreateAddressEntityRelationInput",
		billingSubject:"Contember_InvoiceCreateBillingSubjectEntityRelationInput"
	},
	Contember_InvoiceCreateBillingSubjectEntityRelationInput:{
		connect:"Contember_BillingSubjectUniqueWhere",
		create:"Contember_BillingSubjectWithoutInvoicesCreateInput"
	},
	Contember_BillingSubjectWithoutInvoicesCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectCreateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectCreateCustomerEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput",
		orders:"Contember_BillingSubjectCreateOrdersEntityRelationInput"
	},
	Contember_BillingSubjectCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutBillingSubjectsCreateInput"
	},
	Contember_CustomerWithoutBillingSubjectsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateOfferedProductsEntityRelationInput:{
		connect:"Contember_ProductCustomerPriceUniqueWhere"
	},
	Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"
	},
	Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput:{
		id:"UUID",
		customer:"Contember_AddressCreateCustomerEntityRelationInput",
		country:"Contember_AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressCreateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressCreateMetaEntityRelationInput",
		gps:"Contember_AddressCreateGpsEntityRelationInput",
		invoices:"Contember_AddressCreateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"
	},
	Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput:{
		connect:"Contember_BillingSubjectUniqueWhere",
		create:"Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"
	},
	Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectCreateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectCreateCustomerEntityRelationInput",
		invoices:"Contember_BillingSubjectCreateInvoicesEntityRelationInput",
		orders:"Contember_BillingSubjectCreateOrdersEntityRelationInput"
	},
	Contember_BillingSubjectCreateOrdersEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutBillingSubjectCreateInput"
	},
	Contember_OrderWithoutBillingSubjectCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderCreateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateSummaryEntityRelationInput:{
		connect:"Contember_OrderSummaryUniqueWhere"
	},
	Contember_OrderCreateGratuityEntityRelationInput:{
		connect:"Contember_GratuityUniqueWhere",
		create:"Contember_GratuityWithoutOrderCreateInput"
	},
	Contember_GratuityWithoutOrderCreateInput:{
		id:"UUID"
	},
	Contember_OrderCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutOrdersCreateInput"
	},
	Contember_VenueWithoutOrdersCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreateFulfillmentNotesEntityRelationInput:{
		connect:"Contember_FulfillmentNoteUniqueWhere",
		create:"Contember_FulfillmentNoteWithoutVenuesCreateInput"
	},
	Contember_FulfillmentNoteWithoutVenuesCreateInput:{
		id:"UUID",
		products:"Contember_FulfillmentNoteCreateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteCreateTagsEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteCreateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteCreateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_FulfillmentNoteCreateCartItemsEntityRelationInput:{
		connect:"Contember_CartItemUniqueWhere",
		create:"Contember_CartItemWithoutFulfillmentNotesCreateInput"
	},
	Contember_CartItemWithoutFulfillmentNotesCreateInput:{
		id:"UUID",
		cart:"Contember_CartItemCreateCartEntityRelationInput",
		product:"Contember_CartItemCreateProductEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput:{
		connect:"Contember_OrderItemUniqueWhere",
		create:"Contember_OrderItemWithoutFulfillmentNotesCreateInput"
	},
	Contember_OrderItemWithoutFulfillmentNotesCreateInput:{
		id:"UUID",
		vatRate:"Contember_OrderItemCreateVatRateEntityRelationInput",
		order:"Contember_OrderItemCreateOrderEntityRelationInput",
		virtualProductEffects:"Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput",
		pickedAt:"DateTime",
		product:"Contember_OrderItemCreateProductEntityRelationInput"
	},
	Contember_OrderItemCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductWithoutOrderItemsCreateInput"
	},
	Contember_ProductWithoutOrderItemsCreateInput:{
		id:"UUID",
		packing:"Contember_ProductCreatePackingEntityRelationInput",
		recipe:"Contember_ProductCreateRecipeEntityRelationInput",
		tags:"Contember_ProductCreateTagsEntityRelationInput",
		categories:"Contember_ProductCreateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductCreateVatRateEntityRelationInput",
		stocks:"Contember_ProductCreateStocksEntityRelationInput",
		groupPrices:"Contember_ProductCreateGroupPricesEntityRelationInput",
		image:"Contember_ProductCreateImageEntityRelationInput",
		cartItems:"Contember_ProductCreateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductCreateManagerEntityRelationInput",
		inheritedCategories:"Contember_ProductCreateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductCreateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductCreateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCreateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductCreateVirtualProductEntityRelationInput",
		meta:"Contember_ProductCreateMetaEntityRelationInput",
		page:"Contember_ProductCreatePageEntityRelationInput",
		salesDays:"Contember_ProductCreateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductCreateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductCreateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductCreateFulfillmentNotesEntityRelationInput"
	},
	Contember_FulfillmentNoteCreateOrdersEntityRelationInput:{
		connect:"Contember_OrderUniqueWhere",
		create:"Contember_OrderWithoutFulfillmentNotesCreateInput"
	},
	Contember_OrderWithoutFulfillmentNotesCreateInput:{
		id:"UUID",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderCreateCustomerEntityRelationInput",
		channel:"Contember_OrderCreateChannelEntityRelationInput",
		discounts:"Contember_OrderCreateDiscountsEntityRelationInput",
		delivery:"Contember_OrderCreateDeliveryEntityRelationInput",
		items:"Contember_OrderCreateItemsEntityRelationInput",
		cart:"Contember_OrderCreateCartEntityRelationInput",
		events:"Contember_OrderCreateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderCreateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderCreatePriceLinesEntityRelationInput",
		payments:"Contember_OrderCreatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderCreateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderCreateSummaryEntityRelationInput",
		gratuity:"Contember_OrderCreateGratuityEntityRelationInput",
		venue:"Contember_OrderCreateVenueEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderCreateBillingSubjectEntityRelationInput:{
		connect:"Contember_BillingSubjectUniqueWhere",
		create:"Contember_BillingSubjectWithoutOrdersCreateInput"
	},
	Contember_BillingSubjectWithoutOrdersCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectCreateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectCreateCustomerEntityRelationInput",
		invoices:"Contember_BillingSubjectCreateInvoicesEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"
	},
	Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutDefaultBillingSubjectCreateInput"
	},
	Contember_CustomerWithoutDefaultBillingSubjectCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateAnonymousSessionEntityRelationInput:{
		connect:"Contember_AnonymousSessionUniqueWhere",
		create:"Contember_AnonymousSessionWithoutCustomerCreateInput"
	},
	Contember_AnonymousSessionWithoutCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		devices:"Contember_AnonymousSessionCreateDevicesEntityRelationInput"
	},
	Contember_AnonymousSessionCreateDevicesEntityRelationInput:{
		connect:"Contember_UserDeviceUniqueWhere",
		create:"Contember_UserDeviceWithoutAnonymousSessionCreateInput"
	},
	Contember_UserDeviceWithoutAnonymousSessionCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		user:"Contember_UserDeviceCreateUserEntityRelationInput"
	},
	Contember_UserDeviceCreateUserEntityRelationInput:{
		connect:"Contember_UserUniqueWhere",
		create:"Contember_UserWithoutDevicesCreateInput"
	},
	Contember_UserWithoutDevicesCreateInput:{
		id:"UUID",
		staff:"Contember_UserCreateStaffEntityRelationInput",
		customer:"Contember_UserCreateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserCreateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserCreateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserCreateRolesEntityRelationInput"
	},
	Contember_UserCreateRolesEntityRelationInput:{
		connect:"Contember_UserRolesUniqueWhere"
	},
	Contember_CustomerCreateBoughtVouchersEntityRelationInput:{
		connect:"Contember_VoucherUniqueWhere",
		create:"Contember_VoucherWithoutBuyerCreateInput"
	},
	Contember_VoucherWithoutBuyerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		owner:"Contember_VoucherCreateOwnerEntityRelationInput",
		vatRate:"Contember_VoucherCreateVatRateEntityRelationInput",
		virtualProductEffect:"Contember_VoucherCreateVirtualProductEffectEntityRelationInput",
		redemption:"Contember_VoucherCreateRedemptionEntityRelationInput"
	},
	Contember_VoucherCreateOwnerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutOwnsVouchersCreateInput"
	},
	Contember_CustomerWithoutOwnsVouchersCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateRedeemedVouchersEntityRelationInput:{
		connect:"Contember_VoucherRedemptionUniqueWhere",
		create:"Contember_VoucherRedemptionWithoutCustomerCreateInput"
	},
	Contember_VoucherRedemptionWithoutCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customerCredit:"Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput",
		orderDiscount:"Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput",
		voucher:"Contember_VoucherRedemptionCreateVoucherEntityRelationInput"
	},
	Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput:{
		connect:"Contember_CustomerCreditUniqueWhere",
		create:"Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"
	},
	Contember_CustomerCreditWithoutVoucherRedemptionCreateInput:{
		id:"UUID",
		expiresAt:"DateTime",
		customer:"Contember_CustomerCreditCreateCustomerEntityRelationInput",
		vatRate:"Contember_CustomerCreditCreateVatRateEntityRelationInput",
		createdAt:"DateTime",
		transactions:"Contember_CustomerCreditCreateTransactionsEntityRelationInput"
	},
	Contember_CustomerCreditCreateTransactionsEntityRelationInput:{
		connect:"Contember_CustomerCreditTransactionUniqueWhere",
		create:"Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"
	},
	Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		orderDiscount:"Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput",
		virtualProductEffect:"Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"
	},
	Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput:{
		connect:"Contember_VirtualProductEffectUniqueWhere",
		create:"Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"
	},
	Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		succeededAt:"DateTime",
		orderItem:"Contember_VirtualProductEffectCreateOrderItemEntityRelationInput",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultVouchers:"Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"
	},
	Contember_VirtualProductEffectCreateOrderItemEntityRelationInput:{
		connect:"Contember_OrderItemUniqueWhere",
		create:"Contember_OrderItemWithoutVirtualProductEffectsCreateInput"
	},
	Contember_OrderItemWithoutVirtualProductEffectsCreateInput:{
		id:"UUID",
		vatRate:"Contember_OrderItemCreateVatRateEntityRelationInput",
		order:"Contember_OrderItemCreateOrderEntityRelationInput",
		pickedAt:"DateTime",
		fulfillmentNotes:"Contember_OrderItemCreateFulfillmentNotesEntityRelationInput",
		product:"Contember_OrderItemCreateProductEntityRelationInput"
	},
	Contember_OrderItemCreateFulfillmentNotesEntityRelationInput:{
		connect:"Contember_FulfillmentNoteUniqueWhere",
		create:"Contember_FulfillmentNoteWithoutOrderItemsCreateInput"
	},
	Contember_FulfillmentNoteWithoutOrderItemsCreateInput:{
		id:"UUID",
		products:"Contember_FulfillmentNoteCreateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteCreateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteCreateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteCreateCartItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteCreateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput:{
		connect:"Contember_VoucherUniqueWhere",
		create:"Contember_VoucherWithoutVirtualProductEffectCreateInput"
	},
	Contember_VoucherWithoutVirtualProductEffectCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		buyer:"Contember_VoucherCreateBuyerEntityRelationInput",
		owner:"Contember_VoucherCreateOwnerEntityRelationInput",
		vatRate:"Contember_VoucherCreateVatRateEntityRelationInput",
		redemption:"Contember_VoucherCreateRedemptionEntityRelationInput"
	},
	Contember_VoucherCreateBuyerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutBoughtVouchersCreateInput"
	},
	Contember_CustomerWithoutBoughtVouchersCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateOwnsVouchersEntityRelationInput:{
		connect:"Contember_VoucherUniqueWhere",
		create:"Contember_VoucherWithoutOwnerCreateInput"
	},
	Contember_VoucherWithoutOwnerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		buyer:"Contember_VoucherCreateBuyerEntityRelationInput",
		vatRate:"Contember_VoucherCreateVatRateEntityRelationInput",
		virtualProductEffect:"Contember_VoucherCreateVirtualProductEffectEntityRelationInput",
		redemption:"Contember_VoucherCreateRedemptionEntityRelationInput"
	},
	Contember_VoucherCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_VoucherCreateVirtualProductEffectEntityRelationInput:{
		connect:"Contember_VirtualProductEffectUniqueWhere",
		create:"Contember_VirtualProductEffectWithoutResultVouchersCreateInput"
	},
	Contember_VirtualProductEffectWithoutResultVouchersCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		succeededAt:"DateTime",
		orderItem:"Contember_VirtualProductEffectCreateOrderItemEntityRelationInput",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultCustomerCreditTransaction:"Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"
	},
	Contember_VoucherCreateRedemptionEntityRelationInput:{
		connect:"Contember_VoucherRedemptionUniqueWhere",
		create:"Contember_VoucherRedemptionWithoutVoucherCreateInput"
	},
	Contember_VoucherRedemptionWithoutVoucherCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customerCredit:"Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput",
		orderDiscount:"Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput",
		customer:"Contember_VoucherRedemptionCreateCustomerEntityRelationInput"
	},
	Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput:{
		connect:"Contember_OrderDiscountUniqueWhere",
		create:"Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"
	},
	Contember_OrderDiscountWithoutVoucherRedemptionCreateInput:{
		id:"UUID",
		vatRate:"Contember_OrderDiscountCreateVatRateEntityRelationInput",
		order:"Contember_OrderDiscountCreateOrderEntityRelationInput",
		type:"Contember_OrderDiscountType",
		customerCreditTransactions:"Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"
	},
	Contember_VoucherRedemptionCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutRedeemedVouchersCreateInput"
	},
	Contember_CustomerWithoutRedeemedVouchersCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateSpendingEntityRelationInput:{
		connect:"Contember_CustomerSpendingUniqueWhere"
	},
	Contember_CustomerCreateCustomerTabsEntityRelationInput:{
		connect:"Contember_CustomerTabUniqueWhere",
		create:"Contember_CustomerTabWithoutCustomerCreateInput"
	},
	Contember_CustomerTabWithoutCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		items:"Contember_CustomerTabCreateItemsEntityRelationInput",
		meta:"Contember_CustomerTabCreateMetaEntityRelationInput",
		table:"Contember_CustomerTabCreateTableEntityRelationInput",
		seat:"Contember_CustomerTabCreateSeatEntityRelationInput"
	},
	Contember_CustomerTabCreateItemsEntityRelationInput:{
		connect:"Contember_CustomerTabItemUniqueWhere",
		create:"Contember_CustomerTabItemWithoutTabCreateInput"
	},
	Contember_CustomerTabItemWithoutTabCreateInput:{
		id:"UUID",
		addedAt:"DateTime",
		product:"Contember_CustomerTabItemCreateProductEntityRelationInput",
		paidAt:"DateTime"
	},
	Contember_CustomerTabItemCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductCreateInput"
	},
	Contember_CustomerTabCreateMetaEntityRelationInput:{
		connect:"Contember_CustomerTabMetadataUniqueWhere"
	},
	Contember_CustomerTabCreateTableEntityRelationInput:{
		connect:"Contember_TableUniqueWhere",
		create:"Contember_TableWithoutCustomerTabsCreateInput"
	},
	Contember_TableWithoutCustomerTabsCreateInput:{
		id:"UUID",
		shape:"Contember_TableShape",
		area:"Contember_TableCreateAreaEntityRelationInput",
		seats:"Contember_TableCreateSeatsEntityRelationInput"
	},
	Contember_TableCreateAreaEntityRelationInput:{
		connect:"Contember_AreaUniqueWhere",
		create:"Contember_AreaWithoutTablesCreateInput"
	},
	Contember_AreaWithoutTablesCreateInput:{
		id:"UUID",
		venue:"Contember_AreaCreateVenueEntityRelationInput",
		lines:"Contember_AreaCreateLinesEntityRelationInput"
	},
	Contember_AreaCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutAreasCreateInput"
	},
	Contember_VenueWithoutAreasCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput:{
		connect:"Contember_UnassignReasonUniqueWhere",
		create:"Contember_UnassignReasonWithoutVenuesCreateInput"
	},
	Contember_UnassignReasonUniqueWhere:{
		id:"UUID"
	},
	Contember_UnassignReasonWithoutVenuesCreateInput:{
		id:"UUID"
	},
	Contember_VenueCreateCategoriesEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryUniqueWhere",
		create:"Contember_PointOfSaleCategoryWithoutVenueCreateInput"
	},
	Contember_PointOfSaleCategoryWithoutVenueCreateInput:{
		id:"UUID",
		locales:"Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput",
		products:"Contember_PointOfSaleCategoryCreateProductsEntityRelationInput",
		category:"Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput:{
		connect:"Contember_ProductCategoryUniqueWhere",
		create:"Contember_ProductCategoryCreateInput"
	},
	Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput:{
		connect:"Contember_PointOfSalePrinterUniqueWhere",
		create:"Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"
	},
	Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput:{
		id:"UUID",
		applicationDevicesReceipt:"Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput",
		applicationDevicesTicket:"Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput",
		venue:"Contember_PointOfSalePrinterCreateVenueEntityRelationInput"
	},
	Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput:{
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		create:"Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput:{
		id:"UUID",
		venue:"Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput",
		identificationCode:"Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput:{
		connect:"Contember_PointOfSalePrinterUniqueWhere",
		create:"Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"
	},
	Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput:{
		id:"UUID",
		applicationDevicesTicket:"Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput",
		venue:"Contember_PointOfSalePrinterCreateVenueEntityRelationInput",
		ticketCategories:"Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"
	},
	Contember_PointOfSalePrinterCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutPointOfSalePrintersCreateInput"
	},
	Contember_VenueWithoutPointOfSalePrintersCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueCreateAreasEntityRelationInput:{
		connect:"Contember_AreaUniqueWhere",
		create:"Contember_AreaWithoutVenueCreateInput"
	},
	Contember_AreaWithoutVenueCreateInput:{
		id:"UUID",
		tables:"Contember_AreaCreateTablesEntityRelationInput",
		lines:"Contember_AreaCreateLinesEntityRelationInput"
	},
	Contember_AreaCreateTablesEntityRelationInput:{
		connect:"Contember_TableUniqueWhere",
		create:"Contember_TableWithoutAreaCreateInput"
	},
	Contember_TableWithoutAreaCreateInput:{
		id:"UUID",
		shape:"Contember_TableShape",
		customerTabs:"Contember_TableCreateCustomerTabsEntityRelationInput",
		seats:"Contember_TableCreateSeatsEntityRelationInput"
	},
	Contember_TableCreateCustomerTabsEntityRelationInput:{
		connect:"Contember_CustomerTabUniqueWhere",
		create:"Contember_CustomerTabWithoutTableCreateInput"
	},
	Contember_CustomerTabWithoutTableCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		items:"Contember_CustomerTabCreateItemsEntityRelationInput",
		meta:"Contember_CustomerTabCreateMetaEntityRelationInput",
		customer:"Contember_CustomerTabCreateCustomerEntityRelationInput",
		seat:"Contember_CustomerTabCreateSeatEntityRelationInput"
	},
	Contember_CustomerTabCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutCustomerTabsCreateInput"
	},
	Contember_CustomerWithoutCustomerTabsCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateLeadEntityRelationInput:{
		connect:"Contember_PipelineLeadUniqueWhere",
		create:"Contember_PipelineLeadWithoutCustomerCreateInput"
	},
	Contember_PipelineLeadWithoutCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		stageItem:"Contember_PipelineLeadCreateStageItemEntityRelationInput",
		form:"Contember_PipelineLeadCreateFormEntityRelationInput"
	},
	Contember_PipelineLeadCreateStageItemEntityRelationInput:{
		connect:"Contember_PipelineStageItemUniqueWhere",
		create:"Contember_PipelineStageItemWithoutLeadCreateInput"
	},
	Contember_PipelineStageItemWithoutLeadCreateInput:{
		id:"UUID",
		list:"Contember_PipelineStageItemCreateListEntityRelationInput"
	},
	Contember_PipelineStageItemCreateListEntityRelationInput:{
		connect:"Contember_PipelineStageUniqueWhere",
		create:"Contember_PipelineStageWithoutItemsCreateInput"
	},
	Contember_PipelineStageWithoutItemsCreateInput:{
		id:"UUID",
		pipeline:"Contember_PipelineStageCreatePipelineEntityRelationInput",
		locales:"Contember_PipelineStageCreateLocalesEntityRelationInput",
		sentiment:"Contember_PipelineStageCreateSentimentEntityRelationInput",
		type:"Contember_PipelineStageType",
		forms:"Contember_PipelineStageCreateFormsEntityRelationInput"
	},
	Contember_PipelineStageCreatePipelineEntityRelationInput:{
		connect:"Contember_PipelineUniqueWhere",
		create:"Contember_PipelineWithoutStagesCreateInput"
	},
	Contember_PipelineUniqueWhere:{
		id:"UUID",
		unique:"Contember_One",
		stages:"Contember_PipelineStageUniqueWhere"
	},
	Contember_PipelineWithoutStagesCreateInput:{
		id:"UUID",
		unique:"Contember_One"
	},
	Contember_PipelineStageCreateLocalesEntityRelationInput:{
		connect:"Contember_PipelineStageLocaleUniqueWhere",
		create:"Contember_PipelineStageLocaleWithoutRootCreateInput"
	},
	Contember_PipelineStageLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_PipelineStageLocaleCreateLocaleEntityRelationInput"
	},
	Contember_PipelineStageLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutPipelineStagesCreateInput"
	},
	Contember_LocaleWithoutPipelineStagesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleCreateSubscriptionBoxesEntityRelationInput:{
		connect:"Contember_SubscriptionBoxLocaleUniqueWhere",
		create:"Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"
	},
	Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput",
		texts:"Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput",
		secondaryTexts:"Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput"
	},
	Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput:{
		connect:"Contember_SubscriptionBoxUniqueWhere",
		create:"Contember_SubscriptionBoxWithoutLocalesCreateInput"
	},
	Contember_SubscriptionBoxWithoutLocalesCreateInput:{
		id:"UUID",
		unique:"Contember_One"
	},
	Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput:{
		connect:"Contember_TextListUniqueWhere",
		create:"Contember_TextListCreateInput"
	},
	Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput:{
		connect:"Contember_TextListUniqueWhere",
		create:"Contember_TextListCreateInput"
	},
	Contember_LocaleCreateBusinessServicesEntityRelationInput:{
		connect:"Contember_BusinessServiceLocaleUniqueWhere",
		create:"Contember_BusinessServiceLocaleWithoutLocaleCreateInput"
	},
	Contember_BusinessServiceLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_BusinessServiceLocaleCreateRootEntityRelationInput"
	},
	Contember_BusinessServiceLocaleCreateRootEntityRelationInput:{
		connect:"Contember_BusinessServiceUniqueWhere",
		create:"Contember_BusinessServiceWithoutLocalesCreateInput"
	},
	Contember_BusinessServiceWithoutLocalesCreateInput:{
		id:"UUID"
	},
	Contember_PipelineStageCreateSentimentEntityRelationInput:{
		connect:"Contember_PipelineStageSentimentUniqueWhere",
		create:"Contember_PipelineStageSentimentCreateInput"
	},
	Contember_PipelineStageSentimentUniqueWhere:{
		id:"UUID"
	},
	Contember_PipelineStageSentimentCreateInput:{
		id:"UUID"
	},
	Contember_PipelineStageCreateFormsEntityRelationInput:{
		connect:"Contember_LeadFormUniqueWhere",
		create:"Contember_LeadFormWithoutStageCreateInput"
	},
	Contember_LeadFormWithoutStageCreateInput:{
		id:"UUID",
		leads:"Contember_LeadFormCreateLeadsEntityRelationInput"
	},
	Contember_LeadFormCreateLeadsEntityRelationInput:{
		connect:"Contember_PipelineLeadUniqueWhere",
		create:"Contember_PipelineLeadWithoutFormCreateInput"
	},
	Contember_PipelineLeadWithoutFormCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		stageItem:"Contember_PipelineLeadCreateStageItemEntityRelationInput",
		customer:"Contember_PipelineLeadCreateCustomerEntityRelationInput"
	},
	Contember_PipelineLeadCreateCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutLeadCreateInput"
	},
	Contember_CustomerWithoutLeadCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerCreateMetaEntityRelationInput:{
		connect:"Contember_CustomerMetadataUniqueWhere"
	},
	Contember_PipelineLeadCreateFormEntityRelationInput:{
		connect:"Contember_LeadFormUniqueWhere",
		create:"Contember_LeadFormWithoutLeadsCreateInput"
	},
	Contember_LeadFormWithoutLeadsCreateInput:{
		id:"UUID",
		stage:"Contember_LeadFormCreateStageEntityRelationInput"
	},
	Contember_LeadFormCreateStageEntityRelationInput:{
		connect:"Contember_PipelineStageUniqueWhere",
		create:"Contember_PipelineStageWithoutFormsCreateInput"
	},
	Contember_PipelineStageWithoutFormsCreateInput:{
		id:"UUID",
		items:"Contember_PipelineStageCreateItemsEntityRelationInput",
		pipeline:"Contember_PipelineStageCreatePipelineEntityRelationInput",
		locales:"Contember_PipelineStageCreateLocalesEntityRelationInput",
		sentiment:"Contember_PipelineStageCreateSentimentEntityRelationInput",
		type:"Contember_PipelineStageType"
	},
	Contember_PipelineStageCreateItemsEntityRelationInput:{
		connect:"Contember_PipelineStageItemUniqueWhere",
		create:"Contember_PipelineStageItemWithoutListCreateInput"
	},
	Contember_PipelineStageItemWithoutListCreateInput:{
		id:"UUID",
		lead:"Contember_PipelineStageItemCreateLeadEntityRelationInput"
	},
	Contember_PipelineStageItemCreateLeadEntityRelationInput:{
		connect:"Contember_PipelineLeadUniqueWhere",
		create:"Contember_PipelineLeadWithoutStageItemCreateInput"
	},
	Contember_PipelineLeadWithoutStageItemCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customer:"Contember_PipelineLeadCreateCustomerEntityRelationInput",
		form:"Contember_PipelineLeadCreateFormEntityRelationInput"
	},
	Contember_CustomerTabCreateSeatEntityRelationInput:{
		connect:"Contember_SeatUniqueWhere",
		create:"Contember_SeatWithoutCustomerTabsCreateInput"
	},
	Contember_SeatWithoutCustomerTabsCreateInput:{
		id:"UUID",
		table:"Contember_SeatCreateTableEntityRelationInput"
	},
	Contember_SeatCreateTableEntityRelationInput:{
		connect:"Contember_TableUniqueWhere",
		create:"Contember_TableWithoutSeatsCreateInput"
	},
	Contember_TableWithoutSeatsCreateInput:{
		id:"UUID",
		shape:"Contember_TableShape",
		customerTabs:"Contember_TableCreateCustomerTabsEntityRelationInput",
		area:"Contember_TableCreateAreaEntityRelationInput"
	},
	Contember_TableCreateSeatsEntityRelationInput:{
		connect:"Contember_SeatUniqueWhere",
		create:"Contember_SeatWithoutTableCreateInput"
	},
	Contember_SeatWithoutTableCreateInput:{
		id:"UUID",
		customerTabs:"Contember_SeatCreateCustomerTabsEntityRelationInput"
	},
	Contember_SeatCreateCustomerTabsEntityRelationInput:{
		connect:"Contember_CustomerTabUniqueWhere",
		create:"Contember_CustomerTabWithoutSeatCreateInput"
	},
	Contember_CustomerTabWithoutSeatCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		items:"Contember_CustomerTabCreateItemsEntityRelationInput",
		meta:"Contember_CustomerTabCreateMetaEntityRelationInput",
		customer:"Contember_CustomerTabCreateCustomerEntityRelationInput",
		table:"Contember_CustomerTabCreateTableEntityRelationInput"
	},
	Contember_AreaCreateLinesEntityRelationInput:{
		connect:"Contember_LineUniqueWhere",
		create:"Contember_LineWithoutAreaCreateInput"
	},
	Contember_LineWithoutAreaCreateInput:{
		id:"UUID"
	},
	Contember_VenueCreateChannelsEntityRelationInput:{
		connect:"Contember_ChannelUniqueWhere",
		create:"Contember_ChannelWithoutVenueCreateInput"
	},
	Contember_ChannelWithoutVenueCreateInput:{
		id:"UUID",
		payments:"Contember_ChannelCreatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelCreateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelCreatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelCreateCartsEntityRelationInput",
		customers:"Contember_ChannelCreateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelCreatePublicTagsEntityRelationInput"
	},
	Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryUniqueWhere",
		create:"Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"
	},
	Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput:{
		id:"UUID",
		locales:"Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput",
		products:"Contember_PointOfSaleCategoryCreateProductsEntityRelationInput",
		category:"Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput",
		venue:"Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"
	},
	Contember_PointOfSaleCategoryCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutCategoriesCreateInput"
	},
	Contember_VenueWithoutCategoriesCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VoucherRedemptionCreateVoucherEntityRelationInput:{
		connect:"Contember_VoucherUniqueWhere",
		create:"Contember_VoucherWithoutRedemptionCreateInput"
	},
	Contember_VoucherWithoutRedemptionCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		buyer:"Contember_VoucherCreateBuyerEntityRelationInput",
		owner:"Contember_VoucherCreateOwnerEntityRelationInput",
		vatRate:"Contember_VoucherCreateVatRateEntityRelationInput",
		virtualProductEffect:"Contember_VoucherCreateVirtualProductEffectEntityRelationInput"
	},
	Contember_OrderCreateFulfillmentNotesEntityRelationInput:{
		connect:"Contember_FulfillmentNoteUniqueWhere",
		create:"Contember_FulfillmentNoteWithoutOrdersCreateInput"
	},
	Contember_FulfillmentNoteWithoutOrdersCreateInput:{
		id:"UUID",
		products:"Contember_FulfillmentNoteCreateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteCreateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteCreateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteCreateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_OrderPaymentCreateVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderPaymentCreateMetaEntityRelationInput:{
		connect:"Contember_OrderPaymentMetadataUniqueWhere"
	},
	Contember_OrderDeliveryCreateEventsEntityRelationInput:{
		connect:"Contember_OrderEventUniqueWhere",
		create:"Contember_OrderEventWithoutDeliveryCreateInput"
	},
	Contember_OrderEventWithoutDeliveryCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		order:"Contember_OrderEventCreateOrderEntityRelationInput",
		payment:"Contember_OrderEventCreatePaymentEntityRelationInput"
	},
	Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput:{
		connect:"Contember_LiftagoRideUniqueWhere",
		create:"Contember_LiftagoRideWithoutDeliveryCreateInput"
	},
	Contember_LiftagoRideWithoutDeliveryCreateInput:{
		id:"UUID",
		updatedAt:"DateTime"
	},
	Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput:{
		connect:"Contember_CustomerUniqueWhere",
		create:"Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"
	},
	Contember_CustomerWithoutDefaultDeliveryAddressCreateInput:{
		id:"UUID",
		addresses:"Contember_CustomerCreateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerCreateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerCreateTagsEntityRelationInput",
		credits:"Contember_CustomerCreateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerCreatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerCreateOrdersEntityRelationInput",
		permissions:"Contember_CustomerCreatePermissionsEntityRelationInput",
		group:"Contember_CustomerCreateGroupEntityRelationInput",
		photo:"Contember_CustomerCreatePhotoEntityRelationInput",
		name:"Contember_CustomerCreateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerCreateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerCreateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerCreateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerCreateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerCreateShortcutListEntityRelationInput",
		user:"Contember_CustomerCreateUserEntityRelationInput",
		channels:"Contember_CustomerCreateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerCreateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerCreateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerCreateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerCreateOfferedProductsEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerCreateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerCreateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerCreateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerCreateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerCreateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerCreateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerCreateLeadEntityRelationInput",
		meta:"Contember_CustomerCreateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderEventCreateDeliveryEntityRelationInput:{
		connect:"Contember_OrderDeliveryUniqueWhere",
		create:"Contember_OrderDeliveryWithoutEventsCreateInput"
	},
	Contember_OrderDeliveryWithoutEventsCreateInput:{
		id:"UUID",
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryCreateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryCreateVatRateEntityRelationInput",
		order:"Contember_OrderDeliveryCreateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		deliveryWaypoint:"Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime",
		address:"Contember_OrderDeliveryCreateAddressEntityRelationInput"
	},
	Contember_OrderDeliveryCreateAddressEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"
	},
	Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput:{
		id:"UUID",
		customer:"Contember_AddressCreateCustomerEntityRelationInput",
		country:"Contember_AddressCreateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressCreateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressCreateMetaEntityRelationInput",
		gps:"Contember_AddressCreateGpsEntityRelationInput",
		invoices:"Contember_AddressCreateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_CartCreateDeliveryAddressEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressCreateInput"
	},
	Contember_CartCreateBillingAddressEntityRelationInput:{
		connect:"Contember_AddressUniqueWhere",
		create:"Contember_AddressCreateInput"
	},
	Contember_CartCreateDeliveryEntityRelationInput:{
		connect:"Contember_ChannelDeliveryUniqueWhere",
		create:"Contember_ChannelDeliveryCreateInput"
	},
	Contember_ChannelDeliveryCreateInput:{
		id:"UUID",
		channel:"Contember_ChannelDeliveryCreateChannelEntityRelationInput",
		method:"Contember_ChannelDeliveryCreateMethodEntityRelationInput",
		vatRate:"Contember_ChannelDeliveryCreateVatRateEntityRelationInput"
	},
	Contember_CartCreatePaymentEntityRelationInput:{
		connect:"Contember_ChannelPaymentUniqueWhere",
		create:"Contember_ChannelPaymentCreateInput"
	},
	Contember_ChannelPaymentCreateInput:{
		id:"UUID",
		channel:"Contember_ChannelPaymentCreateChannelEntityRelationInput",
		method:"Contember_ChannelPaymentCreateMethodEntityRelationInput",
		vatRate:"Contember_ChannelPaymentCreateVatRateEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_CartCreateBillingSubjectEntityRelationInput:{
		connect:"Contember_BillingSubjectUniqueWhere",
		create:"Contember_BillingSubjectCreateInput"
	},
	Contember_BillingSubjectCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectCreateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectCreateCustomerEntityRelationInput",
		invoices:"Contember_BillingSubjectCreateInvoicesEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput",
		orders:"Contember_BillingSubjectCreateOrdersEntityRelationInput"
	},
	Contember_CartCreatePriceLinesEntityRelationInput:{
		connect:"Contember_CartPriceLineUniqueWhere"
	},
	Contember_CartCreateSummaryEntityRelationInput:{
		connect:"Contember_CartSummaryUniqueWhere"
	},
	Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput:{
		connect:"Contember_VoucherRedemptionUniqueWhere",
		create:"Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"
	},
	Contember_VoucherRedemptionWithoutOrderDiscountCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		customerCredit:"Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput",
		voucher:"Contember_VoucherRedemptionCreateVoucherEntityRelationInput",
		customer:"Contember_VoucherRedemptionCreateCustomerEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput:{
		connect:"Contember_PointOfSalePrinterUniqueWhere",
		create:"Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"
	},
	Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput:{
		id:"UUID",
		applicationDevicesReceipt:"Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput",
		venue:"Contember_PointOfSalePrinterCreateVenueEntityRelationInput",
		ticketCategories:"Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"
	},
	Contember_LocaleCreatePipelineStagesEntityRelationInput:{
		connect:"Contember_PipelineStageLocaleUniqueWhere",
		create:"Contember_PipelineStageLocaleWithoutLocaleCreateInput"
	},
	Contember_PipelineStageLocaleWithoutLocaleCreateInput:{
		id:"UUID",
		root:"Contember_PipelineStageLocaleCreateRootEntityRelationInput"
	},
	Contember_PipelineStageLocaleCreateRootEntityRelationInput:{
		connect:"Contember_PipelineStageUniqueWhere",
		create:"Contember_PipelineStageWithoutLocalesCreateInput"
	},
	Contember_PipelineStageWithoutLocalesCreateInput:{
		id:"UUID",
		items:"Contember_PipelineStageCreateItemsEntityRelationInput",
		pipeline:"Contember_PipelineStageCreatePipelineEntityRelationInput",
		sentiment:"Contember_PipelineStageCreateSentimentEntityRelationInput",
		type:"Contember_PipelineStageType",
		forms:"Contember_PipelineStageCreateFormsEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput:{
		connect:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		create:"Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"
	},
	Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"
	},
	Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_ProductRecipeCreateLocalesEntityRelationInput:{
		connect:"Contember_ProductRecipeLocaleUniqueWhere",
		create:"Contember_ProductRecipeLocaleWithoutRootCreateInput"
	},
	Contember_ProductRecipeLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput"
	},
	Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutProductRecipesCreateInput"
	},
	Contember_LocaleWithoutProductRecipesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput:{
		connect:"Contember_TimetableTemplateUniqueWhere",
		create:"Contember_TimetableTemplateCreateInput"
	},
	Contember_TimetableTemplateCreateInput:{
		id:"UUID",
		startDate:"Date",
		position:"Contember_TimetableTemplateCreatePositionEntityRelationInput",
		staffOptions:"Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput",
		venue:"Contember_TimetableTemplateCreateVenueEntityRelationInput",
		employeeRole:"Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableShiftCreateQueueItemEntityRelationInput:{
		connect:"Contember_EmployeeQueueItemUniqueWhere",
		create:"Contember_EmployeeQueueItemWithoutShiftCreateInput"
	},
	Contember_EmployeeQueueItemWithoutShiftCreateInput:{
		id:"UUID",
		queue:"Contember_EmployeeQueueItemCreateQueueEntityRelationInput",
		shiftsProfile:"Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput",
		unassignInfo:"Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput",
		meta:"Contember_EmployeeQueueItemCreateMetaEntityRelationInput"
	},
	Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput:{
		connect:"Contember_UnassignedShiftInfoUniqueWhere",
		create:"Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"
	},
	Contember_UnassignedShiftInfoWithoutQueueItemCreateInput:{
		id:"UUID",
		legacyReason:"Contember_UnassignedInfoReason",
		reason:"Contember_UnassignedShiftInfoCreateReasonEntityRelationInput"
	},
	Contember_UnassignedShiftInfoCreateReasonEntityRelationInput:{
		connect:"Contember_UnassignReasonUniqueWhere",
		create:"Contember_UnassignReasonCreateInput"
	},
	Contember_UnassignReasonCreateInput:{
		id:"UUID",
		venues:"Contember_UnassignReasonCreateVenuesEntityRelationInput"
	},
	Contember_UnassignReasonCreateVenuesEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutEnabledUnassignReasonsCreateInput"
	},
	Contember_VenueWithoutEnabledUnassignReasonsCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_EmployeeQueueItemCreateMetaEntityRelationInput:{
		connect:"Contember_EmployeeQueueItemMetadataUniqueWhere"
	},
	Contember_TimetableShiftCreateSwapInfoEntityRelationInput:{
		connect:"Contember_TimetableShiftSwapInfoUniqueWhere",
		create:"Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"
	},
	Contember_TimetableShiftSwapInfoWithoutShiftCreateInput:{
		id:"UUID",
		assigned:"Date",
		swappedShiftsProfile:"Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput"
	},
	Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput:{
		connect:"Contember_ShiftsProfileUniqueWhere",
		create:"Contember_ShiftsProfileCreateInput"
	},
	Contember_EmployeeQueueItemCreateShiftEntityRelationInput:{
		connect:"Contember_TimetableShiftUniqueWhere",
		create:"Contember_TimetableShiftWithoutQueueItemCreateInput"
	},
	Contember_TimetableShiftWithoutQueueItemCreateInput:{
		id:"UUID",
		assigned:"Date",
		group:"Contember_TimetableShiftCreateGroupEntityRelationInput",
		shiftsProfile:"Contember_TimetableShiftCreateShiftsProfileEntityRelationInput",
		swapInfo:"Contember_TimetableShiftCreateSwapInfoEntityRelationInput"
	},
	Contember_TimetableDayCreateMetaEntityRelationInput:{
		connect:"Contember_TimetableDayMetadataUniqueWhere"
	},
	Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput:{
		connect:"Contember_AddressMetadataUniqueWhere"
	},
	Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput:{
		connect:"Contember_VoucherRedemptionUniqueWhere",
		create:"Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"
	},
	Contember_VoucherRedemptionWithoutCustomerCreditCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		orderDiscount:"Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput",
		voucher:"Contember_VoucherRedemptionCreateVoucherEntityRelationInput",
		customer:"Contember_VoucherRedemptionCreateCustomerEntityRelationInput"
	},
	Contember_StaffCreateVenuesEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutStaffMembersCreateInput"
	},
	Contember_VenueWithoutStaffMembersCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueCreateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_BusinessCustomerCreateLogoEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput:{
		connect:"Contember_BusinessServiceEventUniqueWhere",
		create:"Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"
	},
	Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput:{
		id:"UUID",
		createdAt:"DateTime",
		fulfilledAt:"DateTime",
		businessService:"Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput",
		filledSupervisor:"Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput",
		createdBy:"Contember_BusinessServiceEventCreateCreatedByEntityRelationInput"
	},
	Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput:{
		connect:"Contember_BusinessServiceUniqueWhere",
		create:"Contember_BusinessServiceCreateInput"
	},
	Contember_BusinessServiceCreateInput:{
		id:"UUID",
		locales:"Contember_BusinessServiceCreateLocalesEntityRelationInput"
	},
	Contember_BusinessServiceCreateLocalesEntityRelationInput:{
		connect:"Contember_BusinessServiceLocaleUniqueWhere",
		create:"Contember_BusinessServiceLocaleWithoutRootCreateInput"
	},
	Contember_BusinessServiceLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput"
	},
	Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutBusinessServicesCreateInput"
	},
	Contember_LocaleWithoutBusinessServicesCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"
	},
	Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffCreateInput"
	},
	Contember_StaffCreateInput:{
		id:"UUID",
		bartender:"Contember_StaffCreateBartenderEntityRelationInput",
		courier:"Contember_StaffCreateCourierEntityRelationInput",
		cleaner:"Contember_StaffCreateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffCreateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffCreateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffCreateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffCreateStocksManagerEntityRelationInput",
		photo:"Contember_StaffCreatePhotoEntityRelationInput",
		courierManager:"Contember_StaffCreateCourierManagerEntityRelationInput",
		picker:"Contember_StaffCreatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffCreateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffCreateCashierEntityRelationInput",
		venues:"Contember_StaffCreateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffCreateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffCreateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffCreateCashierManagerEntityRelationInput"
	},
	Contember_BusinessServiceEventCreateCreatedByEntityRelationInput:{
		connect:"Contember_StaffUniqueWhere",
		create:"Contember_StaffCreateInput"
	},
	Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput:{
		connect:"Contember_DoubleshotCoffeeEquipmentUniqueWhere",
		create:"Contember_DoubleshotCoffeeEquipmentCreateInput"
	},
	Contember_DoubleshotCoffeeEquipmentCreateInput:{
		id:"UUID"
	},
	Contember_TimetableDayCreateVenueEntityRelationInput:{
		connect:"Contember_VenueUniqueWhere",
		create:"Contember_VenueWithoutTimetableDaysCreateInput"
	},
	Contember_VenueWithoutTimetableDaysCreateInput:{
		id:"UUID",
		deadlineTemplate:"Contember_VenueCreateDeadlineTemplateEntityRelationInput",
		timetableTemplates:"Contember_VenueCreateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueCreateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueCreateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueCreatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueCreateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueCreateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueCreateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueCreateCategoriesEntityRelationInput",
		channels:"Contember_VenueCreateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_WorkspaceCreateDiscountVatRateEntityRelationInput:{
		connect:"Contember_VatRateUniqueWhere",
		create:"Contember_VatRateCreateInput"
	},
	Contember_ProductIngredientCreateImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductIngredientCreateSuppliersEntityRelationInput:{
		connect:"Contember_IngredientSupplierListUniqueWhere",
		create:"Contember_IngredientSupplierListCreateInput"
	},
	Contember_IngredientSupplierListCreateInput:{
		id:"UUID",
		items:"Contember_IngredientSupplierListCreateItemsEntityRelationInput"
	},
	Contember_IngredientSupplierListCreateItemsEntityRelationInput:{
		connect:"Contember_IngredientSupplierItemUniqueWhere",
		create:"Contember_IngredientSupplierItemWithoutListCreateInput"
	},
	Contember_IngredientSupplierItemWithoutListCreateInput:{
		id:"UUID",
		supplier:"Contember_IngredientSupplierItemCreateSupplierEntityRelationInput"
	},
	Contember_IngredientSupplierItemCreateSupplierEntityRelationInput:{
		connect:"Contember_IngredientSupplierUniqueWhere",
		create:"Contember_IngredientSupplierCreateInput"
	},
	Contember_IngredientSupplierCreateInput:{
		id:"UUID",
		locales:"Contember_IngredientSupplierCreateLocalesEntityRelationInput"
	},
	Contember_IngredientSupplierCreateLocalesEntityRelationInput:{
		connect:"Contember_IngredientSupplierLocaleUniqueWhere",
		create:"Contember_IngredientSupplierLocaleWithoutRootCreateInput"
	},
	Contember_IngredientSupplierLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput"
	},
	Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutIngredientSuppliersCreateInput"
	},
	Contember_LocaleWithoutIngredientSuppliersCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleCreateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput:{
		connect:"Contember_ProductListUniqueWhere",
		create:"Contember_ProductListWithoutBusinessCategoryCreateInput"
	},
	Contember_ProductListWithoutBusinessCategoryCreateInput:{
		id:"UUID",
		items:"Contember_ProductListCreateItemsEntityRelationInput"
	},
	Contember_ProductListCreateItemsEntityRelationInput:{
		connect:"Contember_ProductListItemUniqueWhere",
		create:"Contember_ProductListItemWithoutListCreateInput"
	},
	Contember_ProductListItemWithoutListCreateInput:{
		id:"UUID",
		product:"Contember_ProductListItemCreateProductEntityRelationInput"
	},
	Contember_ProductListItemCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductCreateInput"
	},
	Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput:{
		connect:"Contember_ProductCategoryListUniqueWhere",
		create:"Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"
	},
	Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput:{
		id:"UUID",
		items:"Contember_ProductCategoryListCreateItemsEntityRelationInput",
		locales:"Contember_ProductCategoryListCreateLocalesEntityRelationInput",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListCreateLocalesEntityRelationInput:{
		connect:"Contember_ProductCategoryListLocaleUniqueWhere",
		create:"Contember_ProductCategoryListLocaleWithoutRootCreateInput"
	},
	Contember_ProductCategoryListLocaleWithoutRootCreateInput:{
		id:"UUID",
		locale:"Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput"
	},
	Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput:{
		connect:"Contember_LocaleUniqueWhere",
		create:"Contember_LocaleWithoutProductCategoryListsCreateInput"
	},
	Contember_LocaleWithoutProductCategoryListsCreateInput:{
		id:"UUID",
		businessCategories:"Contember_LocaleCreateBusinessCategoriesEntityRelationInput",
		productIngredients:"Contember_LocaleCreateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleCreateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleCreateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleCreateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleCreateIdentifierEntityRelationInput",
		menus:"Contember_LocaleCreateMenusEntityRelationInput",
		pages:"Contember_LocaleCreatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleCreateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleCreateProductPackingsEntityRelationInput",
		products:"Contember_LocaleCreateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleCreateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleCreateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleCreateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleCreateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleCreatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleCreateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleCreateBusinessServicesEntityRelationInput"
	},
	Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput:{
		connect:"Contember_BusinessCategoryUniqueWhere",
		create:"Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"
	},
	Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput:{
		id:"UUID",
		locales:"Contember_BusinessCategoryCreateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryCreateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"
	},
	Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput:{
		connect:"Contember_ProductCategoryListUniqueWhere",
		create:"Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"
	},
	Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput:{
		id:"UUID",
		items:"Contember_ProductCategoryListCreateItemsEntityRelationInput",
		locales:"Contember_ProductCategoryListCreateLocalesEntityRelationInput",
		seasonalProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput:{
		connect:"Contember_BusinessCategoryUniqueWhere",
		create:"Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"
	},
	Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput:{
		id:"UUID",
		locales:"Contember_BusinessCategoryCreateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryCreateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_LinkableCreateRedirectEntityRelationInput:{
		connect:"Contember_RedirectUniqueWhere",
		create:"Contember_RedirectWithoutLinkCreateInput"
	},
	Contember_RedirectWithoutLinkCreateInput:{
		id:"UUID",
		target:"Contember_RedirectCreateTargetEntityRelationInput"
	},
	Contember_RedirectCreateTargetEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_WikiPageCreateContentEntityRelationInput:{
		connect:"Contember_ContentUniqueWhere",
		create:"Contember_ContentCreateInput"
	},
	Contember_WikiPageCreateChildrenEntityRelationInput:{
		connect:"Contember_WikiPageUniqueWhere",
		create:"Contember_WikiPageWithoutParentCreateInput"
	},
	Contember_WikiPageWithoutParentCreateInput:{
		id:"UUID",
		link:"Contember_WikiPageCreateLinkEntityRelationInput",
		children:"Contember_WikiPageCreateChildrenEntityRelationInput",
		content:"Contember_WikiPageCreateContentEntityRelationInput"
	},
	Contember_BlogPostLocaleCreateSeoEntityRelationInput:{
		connect:"Contember_SeoUniqueWhere",
		create:"Contember_SeoCreateInput"
	},
	Contember_BlogPostLocaleCreateProductsEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductCreateInput"
	},
	Contember_ContentReferenceCreateBlogPostsEntityRelationInput:{
		connect:"Contember_BlogPostListUniqueWhere",
		create:"Contember_BlogPostListCreateInput"
	},
	Contember_BlogPostListUniqueWhere:{
		id:"UUID",
		items:"Contember_BlogPostListItemUniqueWhere"
	},
	Contember_BlogPostListItemUniqueWhere:{
		id:"UUID"
	},
	Contember_BlogPostListCreateInput:{
		id:"UUID",
		items:"Contember_BlogPostListCreateItemsEntityRelationInput"
	},
	Contember_BlogPostListCreateItemsEntityRelationInput:{
		connect:"Contember_BlogPostListItemUniqueWhere",
		create:"Contember_BlogPostListItemWithoutListCreateInput"
	},
	Contember_BlogPostListItemWithoutListCreateInput:{
		id:"UUID",
		blogPost:"Contember_BlogPostListItemCreateBlogPostEntityRelationInput"
	},
	Contember_BlogPostListItemCreateBlogPostEntityRelationInput:{
		connect:"Contember_BlogPostLocaleUniqueWhere",
		create:"Contember_BlogPostLocaleCreateInput"
	},
	Contember_BlogPostLocaleCreateInput:{
		id:"UUID",
		root:"Contember_BlogPostLocaleCreateRootEntityRelationInput",
		locale:"Contember_BlogPostLocaleCreateLocaleEntityRelationInput",
		cover:"Contember_BlogPostLocaleCreateCoverEntityRelationInput",
		content:"Contember_BlogPostLocaleCreateContentEntityRelationInput",
		link:"Contember_BlogPostLocaleCreateLinkEntityRelationInput",
		seo:"Contember_BlogPostLocaleCreateSeoEntityRelationInput",
		products:"Contember_BlogPostLocaleCreateProductsEntityRelationInput"
	},
	Contember_BlogPostLocaleCreateLinkEntityRelationInput:{
		connect:"Contember_LinkableUniqueWhere",
		create:"Contember_LinkableWithoutBlogPostCreateInput"
	},
	Contember_LinkableWithoutBlogPostCreateInput:{
		id:"UUID",
		page:"Contember_LinkableCreatePageEntityRelationInput",
		wikiPage:"Contember_LinkableCreateWikiPageEntityRelationInput",
		redirect:"Contember_LinkableCreateRedirectEntityRelationInput"
	},
	Contember_ContentReferenceCreateLinksEntityRelationInput:{
		connect:"Contember_LinkListUniqueWhere",
		create:"Contember_LinkListCreateInput"
	},
	Contember_ContentReferenceCreateProductsEntityRelationInput:{
		connect:"Contember_ProductListUniqueWhere",
		create:"Contember_ProductListCreateInput"
	},
	Contember_ProductListCreateInput:{
		id:"UUID",
		items:"Contember_ProductListCreateItemsEntityRelationInput",
		businessCategory:"Contember_ProductListCreateBusinessCategoryEntityRelationInput"
	},
	Contember_ProductListCreateBusinessCategoryEntityRelationInput:{
		connect:"Contember_BusinessCategoryUniqueWhere",
		create:"Contember_BusinessCategoryWithoutSpecificProductsCreateInput"
	},
	Contember_BusinessCategoryWithoutSpecificProductsCreateInput:{
		id:"UUID",
		locales:"Contember_BusinessCategoryCreateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryCreateRecurrencesEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_ContentReferenceCreateHeroEntityRelationInput:{
		connect:"Contember_HeroUniqueWhere",
		create:"Contember_HeroCreateInput"
	},
	Contember_HeroUniqueWhere:{
		id:"UUID"
	},
	Contember_HeroCreateInput:{
		id:"UUID",
		medium:"Contember_HeroCreateMediumEntityRelationInput"
	},
	Contember_HeroCreateMediumEntityRelationInput:{
		connect:"Contember_MediumUniqueWhere",
		create:"Contember_MediumCreateInput"
	},
	Contember_ContentReferenceCreateGalleryEntityRelationInput:{
		connect:"Contember_GalleryUniqueWhere",
		create:"Contember_GalleryCreateInput"
	},
	Contember_GalleryUniqueWhere:{
		id:"UUID",
		items:"Contember_GalleryItemUniqueWhere"
	},
	Contember_GalleryItemUniqueWhere:{
		id:"UUID"
	},
	Contember_GalleryCreateInput:{
		id:"UUID",
		items:"Contember_GalleryCreateItemsEntityRelationInput"
	},
	Contember_GalleryCreateItemsEntityRelationInput:{
		connect:"Contember_GalleryItemUniqueWhere",
		create:"Contember_GalleryItemWithoutListCreateInput"
	},
	Contember_GalleryItemWithoutListCreateInput:{
		id:"UUID",
		medium:"Contember_GalleryItemCreateMediumEntityRelationInput"
	},
	Contember_GalleryItemCreateMediumEntityRelationInput:{
		connect:"Contember_MediumUniqueWhere",
		create:"Contember_MediumCreateInput"
	},
	Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput:{
		connect:"Contember_SocialsAndAppsUniqueWhere",
		create:"Contember_SocialsAndAppsCreateInput"
	},
	Contember_ContentReferenceCreateProductGridEntityRelationInput:{
		connect:"Contember_ProductGridUniqueWhere",
		create:"Contember_ProductGridCreateInput"
	},
	Contember_ProductGridUniqueWhere:{
		id:"UUID",
		categories:"Contember_ProductGridCategoryUniqueWhere"
	},
	Contember_ProductGridCategoryUniqueWhere:{
		id:"UUID",
		items:"Contember_ProductGridItemUniqueWhere",
		images:"Contember_ImageListUniqueWhere"
	},
	Contember_ProductGridCreateInput:{
		id:"UUID",
		categories:"Contember_ProductGridCreateCategoriesEntityRelationInput",
		type:"Contember_ProductGridType"
	},
	Contember_ProductGridCreateCategoriesEntityRelationInput:{
		connect:"Contember_ProductGridCategoryUniqueWhere",
		create:"Contember_ProductGridCategoryWithoutRootCreateInput"
	},
	Contember_ProductGridCategoryWithoutRootCreateInput:{
		id:"UUID",
		items:"Contember_ProductGridCategoryCreateItemsEntityRelationInput",
		images:"Contember_ProductGridCategoryCreateImagesEntityRelationInput"
	},
	Contember_ProductGridCategoryCreateItemsEntityRelationInput:{
		connect:"Contember_ProductGridItemUniqueWhere",
		create:"Contember_ProductGridItemWithoutCategoryCreateInput"
	},
	Contember_ProductGridItemWithoutCategoryCreateInput:{
		id:"UUID",
		product:"Contember_ProductGridItemCreateProductEntityRelationInput",
		medium:"Contember_ProductGridItemCreateMediumEntityRelationInput",
		link:"Contember_ProductGridItemCreateLinkEntityRelationInput"
	},
	Contember_ProductGridItemCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductCreateInput"
	},
	Contember_ProductGridItemCreateMediumEntityRelationInput:{
		connect:"Contember_MediumUniqueWhere",
		create:"Contember_MediumCreateInput"
	},
	Contember_ProductGridItemCreateLinkEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_ProductGridCategoryCreateImagesEntityRelationInput:{
		connect:"Contember_ImageListUniqueWhere",
		create:"Contember_ImageListCreateInput"
	},
	Contember_ContentReferenceCreateProductBannersEntityRelationInput:{
		connect:"Contember_ProductBannerListUniqueWhere",
		create:"Contember_ProductBannerListCreateInput"
	},
	Contember_ProductBannerListCreateInput:{
		id:"UUID",
		items:"Contember_ProductBannerListCreateItemsEntityRelationInput"
	},
	Contember_ProductBannerListCreateItemsEntityRelationInput:{
		connect:"Contember_ProductBannerItemUniqueWhere",
		create:"Contember_ProductBannerItemWithoutListCreateInput"
	},
	Contember_ProductBannerItemWithoutListCreateInput:{
		id:"UUID",
		banner:"Contember_ProductBannerItemCreateBannerEntityRelationInput",
		size:"Contember_ProductBannerItemSize"
	},
	Contember_ProductBannerItemCreateBannerEntityRelationInput:{
		connect:"Contember_ProductBannerUniqueWhere",
		create:"Contember_ProductBannerCreateInput"
	},
	Contember_ProductBannerUniqueWhere:{
		id:"UUID",
		button:"Contember_LinkUniqueWhere"
	},
	Contember_ProductBannerCreateInput:{
		id:"UUID",
		button:"Contember_ProductBannerCreateButtonEntityRelationInput",
		backgroundImage:"Contember_ProductBannerCreateBackgroundImageEntityRelationInput",
		productImage:"Contember_ProductBannerCreateProductImageEntityRelationInput",
		product:"Contember_ProductBannerCreateProductEntityRelationInput"
	},
	Contember_ProductBannerCreateButtonEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_ProductBannerCreateBackgroundImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductBannerCreateProductImageEntityRelationInput:{
		connect:"Contember_ImageUniqueWhere",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductBannerCreateProductEntityRelationInput:{
		connect:"Contember_ProductUniqueWhere",
		create:"Contember_ProductCreateInput"
	},
	Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput:{
		connect:"Contember_DeliveryRegionsUniqueWhere",
		create:"Contember_DeliveryRegionsCreateInput"
	},
	Contember_ContentReferenceCreateTextListEntityRelationInput:{
		connect:"Contember_TextListUniqueWhere",
		create:"Contember_TextListCreateInput"
	},
	Contember_ContentReferenceCreateGridTilesEntityRelationInput:{
		connect:"Contember_GridTilesUniqueWhere",
		create:"Contember_GridTilesCreateInput"
	},
	Contember_GridTilesUniqueWhere:{
		id:"UUID",
		items:"Contember_GridTileItemUniqueWhere"
	},
	Contember_GridTileItemUniqueWhere:{
		id:"UUID",
		link:"Contember_LinkUniqueWhere"
	},
	Contember_GridTilesCreateInput:{
		id:"UUID",
		items:"Contember_GridTilesCreateItemsEntityRelationInput"
	},
	Contember_GridTilesCreateItemsEntityRelationInput:{
		connect:"Contember_GridTileItemUniqueWhere",
		create:"Contember_GridTileItemWithoutListCreateInput"
	},
	Contember_GridTileItemWithoutListCreateInput:{
		id:"UUID",
		medium:"Contember_GridTileItemCreateMediumEntityRelationInput",
		link:"Contember_GridTileItemCreateLinkEntityRelationInput"
	},
	Contember_GridTileItemCreateMediumEntityRelationInput:{
		connect:"Contember_MediumUniqueWhere",
		create:"Contember_MediumCreateInput"
	},
	Contember_GridTileItemCreateLinkEntityRelationInput:{
		connect:"Contember_LinkUniqueWhere",
		create:"Contember_LinkCreateInput"
	},
	Contember_CartItemCreateFulfillmentNotesEntityRelationInput:{
		connect:"Contember_FulfillmentNoteUniqueWhere",
		create:"Contember_FulfillmentNoteWithoutCartItemsCreateInput"
	},
	Contember_FulfillmentNoteWithoutCartItemsCreateInput:{
		id:"UUID",
		products:"Contember_FulfillmentNoteCreateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteCreateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteCreateVenuesEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteCreateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_ProductIngredientItemCreateQuantityEntityRelationInput:{
		connect:"Contember_ProductIngredientItemQuantityUniqueWhere",
		create:"Contember_ProductIngredientItemQuantityCreateInput"
	},
	Contember_ProductIngredientItemQuantityCreateInput:{
		id:"UUID",
		unit:"Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput"
	},
	Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput:{
		connect:"Contember_ProductIngredientItemQuantityUnitUniqueWhere",
		create:"Contember_ProductIngredientItemQuantityUnitCreateInput"
	},
	Contember_AddressUpdateInput:{
		customer:"Contember_AddressUpdateCustomerEntityRelationInput",
		country:"Contember_AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressUpdateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressUpdateMetaEntityRelationInput",
		gps:"Contember_AddressUpdateGpsEntityRelationInput",
		invoices:"Contember_AddressUpdateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutAddressesCreateInput",
		update:"Contember_CustomerWithoutAddressesUpdateInput",
		upsert:"Contember_AddressUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutAddressesUpdateInput:{
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput:{
		create:"Contember_AddressCreateInput",
		update:"Contember_AddressUpdateInput",
		upsert:"Contember_CustomerUpsertDefaultBillingAddressRelationInput",
		connect:"Contember_AddressUniqueWhere"
	},
	Contember_CustomerUpsertDefaultBillingAddressRelationInput:{
		update:"Contember_AddressUpdateInput",
		create:"Contember_AddressCreateInput"
	},
	Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput:{
		create:"Contember_CustomerPaymentMethodCreateInput",
		update:"Contember_CustomerPaymentMethodUpdateInput",
		upsert:"Contember_CustomerUpsertDefaultPaymentMethodRelationInput",
		connect:"Contember_CustomerPaymentMethodUniqueWhere"
	},
	Contember_CustomerPaymentMethodUpdateInput:{
		data:"Json",
		customer:"Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput",
		paymentMethod:"Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"
	},
	Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutPaymentMethodsCreateInput",
		update:"Contember_CustomerWithoutPaymentMethodsUpdateInput",
		upsert:"Contember_CustomerPaymentMethodUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutPaymentMethodsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateAddressesEntityRelationInput:{
		create:"Contember_AddressWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateAddressesRelationInput",
		upsert:"Contember_CustomerUpsertAddressesRelationInput",
		connect:"Contember_AddressUniqueWhere",
		disconnect:"Contember_AddressUniqueWhere",
		delete:"Contember_AddressUniqueWhere"
	},
	Contember_CustomerUpdateAddressesRelationInput:{
		by:"Contember_AddressUniqueWhere",
		data:"Contember_AddressWithoutCustomerUpdateInput"
	},
	Contember_AddressWithoutCustomerUpdateInput:{
		country:"Contember_AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressUpdateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressUpdateMetaEntityRelationInput",
		gps:"Contember_AddressUpdateGpsEntityRelationInput",
		invoices:"Contember_AddressUpdateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressUpdateCountryEntityRelationInput:{
		create:"Contember_CountryCreateInput",
		update:"Contember_CountryUpdateInput",
		upsert:"Contember_AddressUpsertCountryRelationInput",
		connect:"Contember_CountryUniqueWhere"
	},
	Contember_CountryUpdateInput:{

	},
	Contember_AddressUpsertCountryRelationInput:{
		update:"Contember_CountryUpdateInput",
		create:"Contember_CountryCreateInput"
	},
	Contember_AddressUpdateDeliveryZoneEntityRelationInput:{
		create:"Contember_DeliveryZoneWithoutAddressesCreateInput",
		update:"Contember_DeliveryZoneWithoutAddressesUpdateInput",
		upsert:"Contember_AddressUpsertDeliveryZoneRelationInput",
		connect:"Contember_DeliveryZoneUniqueWhere"
	},
	Contember_DeliveryZoneWithoutAddressesUpdateInput:{
		type:"Contember_DeliveryZoneType",
		suitableDeliveryMethods:"Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput",
		deliveryZonesOfTags:"Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput",
		deliveryZonesOfProducts:"Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput:{
		create:"Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput",
		update:"Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput",
		upsert:"Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput",
		connect:"Contember_DeliveryMethodUniqueWhere",
		disconnect:"Contember_DeliveryMethodUniqueWhere",
		delete:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodUniqueWhere",
		data:"Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"
	},
	Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput:{
		paymentMethods:"Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput",
		channels:"Contember_DeliveryMethodUpdateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodUpdateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput:{
		create:"Contember_PaymentMethodWithoutDeliveryMethodsCreateInput",
		update:"Contember_DeliveryMethodUpdatePaymentMethodsRelationInput",
		upsert:"Contember_DeliveryMethodUpsertPaymentMethodsRelationInput",
		connect:"Contember_PaymentMethodUniqueWhere",
		disconnect:"Contember_PaymentMethodUniqueWhere",
		delete:"Contember_PaymentMethodUniqueWhere"
	},
	Contember_DeliveryMethodUpdatePaymentMethodsRelationInput:{
		by:"Contember_PaymentMethodUniqueWhere",
		data:"Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"
	},
	Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput:{
		type:"Contember_PaymentMethodType",
		channels:"Contember_PaymentMethodUpdateChannelsEntityRelationInput"
	},
	Contember_PaymentMethodUpdateChannelsEntityRelationInput:{
		create:"Contember_ChannelPaymentWithoutMethodCreateInput",
		update:"Contember_PaymentMethodUpdateChannelsRelationInput",
		upsert:"Contember_PaymentMethodUpsertChannelsRelationInput",
		connect:"Contember_ChannelPaymentUniqueWhere",
		disconnect:"Contember_ChannelPaymentUniqueWhere",
		delete:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_PaymentMethodUpdateChannelsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		data:"Contember_ChannelPaymentWithoutMethodUpdateInput"
	},
	Contember_ChannelPaymentWithoutMethodUpdateInput:{
		channel:"Contember_ChannelPaymentUpdateChannelEntityRelationInput",
		vatRate:"Contember_ChannelPaymentUpdateVatRateEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_ChannelPaymentUpdateChannelEntityRelationInput:{
		create:"Contember_ChannelWithoutPaymentsCreateInput",
		update:"Contember_ChannelWithoutPaymentsUpdateInput",
		upsert:"Contember_ChannelPaymentUpsertChannelRelationInput",
		connect:"Contember_ChannelUniqueWhere"
	},
	Contember_ChannelWithoutPaymentsUpdateInput:{
		deliveries:"Contember_ChannelUpdateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelUpdateCartsEntityRelationInput",
		customers:"Contember_ChannelUpdateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelUpdatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelUpdateVenueEntityRelationInput"
	},
	Contember_ChannelUpdateDeliveriesEntityRelationInput:{
		create:"Contember_ChannelDeliveryWithoutChannelCreateInput",
		update:"Contember_ChannelUpdateDeliveriesRelationInput",
		upsert:"Contember_ChannelUpsertDeliveriesRelationInput",
		connect:"Contember_ChannelDeliveryUniqueWhere",
		disconnect:"Contember_ChannelDeliveryUniqueWhere",
		delete:"Contember_ChannelDeliveryUniqueWhere"
	},
	Contember_ChannelUpdateDeliveriesRelationInput:{
		by:"Contember_ChannelDeliveryUniqueWhere",
		data:"Contember_ChannelDeliveryWithoutChannelUpdateInput"
	},
	Contember_ChannelDeliveryWithoutChannelUpdateInput:{
		method:"Contember_ChannelDeliveryUpdateMethodEntityRelationInput",
		vatRate:"Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"
	},
	Contember_ChannelDeliveryUpdateMethodEntityRelationInput:{
		create:"Contember_DeliveryMethodWithoutChannelsCreateInput",
		update:"Contember_DeliveryMethodWithoutChannelsUpdateInput",
		upsert:"Contember_ChannelDeliveryUpsertMethodRelationInput",
		connect:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_DeliveryMethodWithoutChannelsUpdateInput:{
		paymentMethods:"Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodUpdateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput:{
		create:"Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput",
		update:"Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput",
		upsert:"Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput",
		connect:"Contember_DeliveryZoneUniqueWhere",
		disconnect:"Contember_DeliveryZoneUniqueWhere",
		delete:"Contember_DeliveryZoneUniqueWhere"
	},
	Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput:{
		by:"Contember_DeliveryZoneUniqueWhere",
		data:"Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"
	},
	Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput:{
		type:"Contember_DeliveryZoneType",
		addresses:"Contember_DeliveryZoneUpdateAddressesEntityRelationInput",
		deliveryZonesOfTags:"Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput",
		deliveryZonesOfProducts:"Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneUpdateAddressesEntityRelationInput:{
		create:"Contember_AddressWithoutDeliveryZoneCreateInput",
		update:"Contember_DeliveryZoneUpdateAddressesRelationInput",
		upsert:"Contember_DeliveryZoneUpsertAddressesRelationInput",
		connect:"Contember_AddressUniqueWhere",
		disconnect:"Contember_AddressUniqueWhere",
		delete:"Contember_AddressUniqueWhere"
	},
	Contember_DeliveryZoneUpdateAddressesRelationInput:{
		by:"Contember_AddressUniqueWhere",
		data:"Contember_AddressWithoutDeliveryZoneUpdateInput"
	},
	Contember_AddressWithoutDeliveryZoneUpdateInput:{
		customer:"Contember_AddressUpdateCustomerEntityRelationInput",
		country:"Contember_AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		meta:"Contember_AddressUpdateMetaEntityRelationInput",
		gps:"Contember_AddressUpdateGpsEntityRelationInput",
		invoices:"Contember_AddressUpdateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressUpdateMetaEntityRelationInput:{
		connect:"Contember_AddressMetadataUniqueWhere"
	},
	Contember_AddressUpdateGpsEntityRelationInput:{
		create:"Contember_GpsWithoutAddressCreateInput",
		update:"Contember_GpsWithoutAddressUpdateInput",
		upsert:"Contember_AddressUpsertGpsRelationInput",
		connect:"Contember_GpsUniqueWhere"
	},
	Contember_GpsWithoutAddressUpdateInput:{

	},
	Contember_AddressUpsertGpsRelationInput:{
		update:"Contember_GpsWithoutAddressUpdateInput",
		create:"Contember_GpsWithoutAddressCreateInput"
	},
	Contember_AddressUpdateInvoicesEntityRelationInput:{
		create:"Contember_InvoiceWithoutAddressCreateInput",
		update:"Contember_AddressUpdateInvoicesRelationInput",
		upsert:"Contember_AddressUpsertInvoicesRelationInput",
		connect:"Contember_InvoiceUniqueWhere",
		disconnect:"Contember_InvoiceUniqueWhere",
		delete:"Contember_InvoiceUniqueWhere"
	},
	Contember_AddressUpdateInvoicesRelationInput:{
		by:"Contember_InvoiceUniqueWhere",
		data:"Contember_InvoiceWithoutAddressUpdateInput"
	},
	Contember_InvoiceWithoutAddressUpdateInput:{
		createdAt:"DateTime",
		customer:"Contember_InvoiceUpdateCustomerEntityRelationInput",
		orderPayments:"Contember_InvoiceUpdateOrderPaymentsEntityRelationInput",
		billingSubject:"Contember_InvoiceUpdateBillingSubjectEntityRelationInput"
	},
	Contember_InvoiceUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutInvoicesCreateInput",
		update:"Contember_CustomerWithoutInvoicesUpdateInput",
		upsert:"Contember_InvoiceUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutInvoicesUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateTagsEntityRelationInput:{
		create:"Contember_TagWithoutCustomersCreateInput",
		update:"Contember_CustomerUpdateTagsRelationInput",
		upsert:"Contember_CustomerUpsertTagsRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_CustomerUpdateTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutCustomersUpdateInput"
	},
	Contember_TagWithoutCustomersUpdateInput:{
		products:"Contember_TagUpdateProductsEntityRelationInput",
		categories:"Contember_TagUpdateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagUpdateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagUpdatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagUpdateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagUpdateProductsEntityRelationInput:{
		create:"Contember_ProductWithoutTagsCreateInput",
		update:"Contember_TagUpdateProductsRelationInput",
		upsert:"Contember_TagUpsertProductsRelationInput",
		connect:"Contember_ProductUniqueWhere",
		disconnect:"Contember_ProductUniqueWhere",
		delete:"Contember_ProductUniqueWhere"
	},
	Contember_TagUpdateProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		data:"Contember_ProductWithoutTagsUpdateInput"
	},
	Contember_ProductWithoutTagsUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdatePackingEntityRelationInput:{
		create:"Contember_ProductPackingCreateInput",
		update:"Contember_ProductPackingUpdateInput",
		upsert:"Contember_ProductUpsertPackingRelationInput",
		connect:"Contember_ProductPackingUniqueWhere"
	},
	Contember_ProductPackingUpdateInput:{
		image:"Contember_ProductPackingUpdateImageEntityRelationInput",
		icon:"Contember_ProductPackingUpdateIconEntityRelationInput",
		locales:"Contember_ProductPackingUpdateLocalesEntityRelationInput",
		preparedPackingQuantities:"Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"
	},
	Contember_ProductPackingUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductPackingUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ImageUpdateInput:{

	},
	Contember_ProductPackingUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductPackingUpdateIconEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductPackingUpsertIconRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductPackingUpsertIconRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductPackingUpdateLocalesEntityRelationInput:{
		create:"Contember_ProductPackingLocaleWithoutRootCreateInput",
		update:"Contember_ProductPackingUpdateLocalesRelationInput",
		upsert:"Contember_ProductPackingUpsertLocalesRelationInput",
		connect:"Contember_ProductPackingLocaleUniqueWhere",
		disconnect:"Contember_ProductPackingLocaleUniqueWhere",
		delete:"Contember_ProductPackingLocaleUniqueWhere"
	},
	Contember_ProductPackingUpdateLocalesRelationInput:{
		by:"Contember_ProductPackingLocaleUniqueWhere",
		data:"Contember_ProductPackingLocaleWithoutRootUpdateInput"
	},
	Contember_ProductPackingLocaleWithoutRootUpdateInput:{
		locale:"Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutProductPackingsCreateInput",
		update:"Contember_LocaleWithoutProductPackingsUpdateInput",
		upsert:"Contember_ProductPackingLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutProductPackingsUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateBusinessCategoriesEntityRelationInput:{
		create:"Contember_BusinessCategoryLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateBusinessCategoriesRelationInput",
		upsert:"Contember_LocaleUpsertBusinessCategoriesRelationInput",
		connect:"Contember_BusinessCategoryLocaleUniqueWhere",
		disconnect:"Contember_BusinessCategoryLocaleUniqueWhere",
		delete:"Contember_BusinessCategoryLocaleUniqueWhere"
	},
	Contember_LocaleUpdateBusinessCategoriesRelationInput:{
		by:"Contember_BusinessCategoryLocaleUniqueWhere",
		data:"Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"
	},
	Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput:{
		root:"Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput"
	},
	Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput:{
		create:"Contember_BusinessCategoryWithoutLocalesCreateInput",
		update:"Contember_BusinessCategoryWithoutLocalesUpdateInput",
		upsert:"Contember_BusinessCategoryLocaleUpsertRootRelationInput",
		connect:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_BusinessCategoryWithoutLocalesUpdateInput:{
		businessCustomers:"Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput:{
		create:"Contember_BusinessCustomerWithoutCategoryCreateInput",
		update:"Contember_BusinessCategoryUpdateBusinessCustomersRelationInput",
		upsert:"Contember_BusinessCategoryUpsertBusinessCustomersRelationInput",
		connect:"Contember_BusinessCustomerUniqueWhere",
		disconnect:"Contember_BusinessCustomerUniqueWhere",
		delete:"Contember_BusinessCustomerUniqueWhere"
	},
	Contember_BusinessCategoryUpdateBusinessCustomersRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		data:"Contember_BusinessCustomerWithoutCategoryUpdateInput"
	},
	Contember_BusinessCustomerWithoutCategoryUpdateInput:{
		customer:"Contember_BusinessCustomerUpdateCustomerEntityRelationInput",
		owners:"Contember_BusinessCustomerUpdateOwnersEntityRelationInput",
		members:"Contember_BusinessCustomerUpdateMembersEntityRelationInput",
		accountManager:"Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerUpdateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutBusinessCreateInput",
		update:"Contember_CustomerWithoutBusinessUpdateInput",
		upsert:"Contember_BusinessCustomerUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutBusinessUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateCreditsEntityRelationInput:{
		create:"Contember_CustomerCreditWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateCreditsRelationInput",
		upsert:"Contember_CustomerUpsertCreditsRelationInput",
		connect:"Contember_CustomerCreditUniqueWhere",
		disconnect:"Contember_CustomerCreditUniqueWhere",
		delete:"Contember_CustomerCreditUniqueWhere"
	},
	Contember_CustomerUpdateCreditsRelationInput:{
		by:"Contember_CustomerCreditUniqueWhere",
		data:"Contember_CustomerCreditWithoutCustomerUpdateInput"
	},
	Contember_CustomerCreditWithoutCustomerUpdateInput:{
		expiresAt:"DateTime",
		vatRate:"Contember_CustomerCreditUpdateVatRateEntityRelationInput",
		createdAt:"DateTime",
		transactions:"Contember_CustomerCreditUpdateTransactionsEntityRelationInput",
		voucherRedemption:"Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"
	},
	Contember_CustomerCreditUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_CustomerCreditUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_VatRateUpdateInput:{
		channelDeliveries:"Contember_VatRateUpdateChannelDeliveriesEntityRelationInput",
		channelPayments:"Contember_VatRateUpdateChannelPaymentsEntityRelationInput",
		preset:"Contember_VatRatePreset"
	},
	Contember_VatRateUpdateChannelDeliveriesEntityRelationInput:{
		create:"Contember_ChannelDeliveryWithoutVatRateCreateInput",
		update:"Contember_VatRateUpdateChannelDeliveriesRelationInput",
		upsert:"Contember_VatRateUpsertChannelDeliveriesRelationInput",
		connect:"Contember_ChannelDeliveryUniqueWhere",
		disconnect:"Contember_ChannelDeliveryUniqueWhere",
		delete:"Contember_ChannelDeliveryUniqueWhere"
	},
	Contember_VatRateUpdateChannelDeliveriesRelationInput:{
		by:"Contember_ChannelDeliveryUniqueWhere",
		data:"Contember_ChannelDeliveryWithoutVatRateUpdateInput"
	},
	Contember_ChannelDeliveryWithoutVatRateUpdateInput:{
		channel:"Contember_ChannelDeliveryUpdateChannelEntityRelationInput",
		method:"Contember_ChannelDeliveryUpdateMethodEntityRelationInput"
	},
	Contember_ChannelDeliveryUpdateChannelEntityRelationInput:{
		create:"Contember_ChannelWithoutDeliveriesCreateInput",
		update:"Contember_ChannelWithoutDeliveriesUpdateInput",
		upsert:"Contember_ChannelDeliveryUpsertChannelRelationInput",
		connect:"Contember_ChannelUniqueWhere"
	},
	Contember_ChannelWithoutDeliveriesUpdateInput:{
		payments:"Contember_ChannelUpdatePaymentsEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelUpdateCartsEntityRelationInput",
		customers:"Contember_ChannelUpdateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelUpdatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelUpdateVenueEntityRelationInput"
	},
	Contember_ChannelUpdatePaymentsEntityRelationInput:{
		create:"Contember_ChannelPaymentWithoutChannelCreateInput",
		update:"Contember_ChannelUpdatePaymentsRelationInput",
		upsert:"Contember_ChannelUpsertPaymentsRelationInput",
		connect:"Contember_ChannelPaymentUniqueWhere",
		disconnect:"Contember_ChannelPaymentUniqueWhere",
		delete:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_ChannelUpdatePaymentsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		data:"Contember_ChannelPaymentWithoutChannelUpdateInput"
	},
	Contember_ChannelPaymentWithoutChannelUpdateInput:{
		method:"Contember_ChannelPaymentUpdateMethodEntityRelationInput",
		vatRate:"Contember_ChannelPaymentUpdateVatRateEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_ChannelPaymentUpdateMethodEntityRelationInput:{
		create:"Contember_PaymentMethodWithoutChannelsCreateInput",
		update:"Contember_PaymentMethodWithoutChannelsUpdateInput",
		upsert:"Contember_ChannelPaymentUpsertMethodRelationInput",
		connect:"Contember_PaymentMethodUniqueWhere"
	},
	Contember_PaymentMethodWithoutChannelsUpdateInput:{
		deliveryMethods:"Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput",
		type:"Contember_PaymentMethodType"
	},
	Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput:{
		create:"Contember_DeliveryMethodWithoutPaymentMethodsCreateInput",
		update:"Contember_PaymentMethodUpdateDeliveryMethodsRelationInput",
		upsert:"Contember_PaymentMethodUpsertDeliveryMethodsRelationInput",
		connect:"Contember_DeliveryMethodUniqueWhere",
		disconnect:"Contember_DeliveryMethodUniqueWhere",
		delete:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_PaymentMethodUpdateDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodUniqueWhere",
		data:"Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"
	},
	Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput:{
		suitableForDeliveryZones:"Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodUpdateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodUpdateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodUpdateChannelsEntityRelationInput:{
		create:"Contember_ChannelDeliveryWithoutMethodCreateInput",
		update:"Contember_DeliveryMethodUpdateChannelsRelationInput",
		upsert:"Contember_DeliveryMethodUpsertChannelsRelationInput",
		connect:"Contember_ChannelDeliveryUniqueWhere",
		disconnect:"Contember_ChannelDeliveryUniqueWhere",
		delete:"Contember_ChannelDeliveryUniqueWhere"
	},
	Contember_DeliveryMethodUpdateChannelsRelationInput:{
		by:"Contember_ChannelDeliveryUniqueWhere",
		data:"Contember_ChannelDeliveryWithoutMethodUpdateInput"
	},
	Contember_ChannelDeliveryWithoutMethodUpdateInput:{
		channel:"Contember_ChannelDeliveryUpdateChannelEntityRelationInput",
		vatRate:"Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"
	},
	Contember_ChannelDeliveryUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateWithoutChannelDeliveriesCreateInput",
		update:"Contember_VatRateWithoutChannelDeliveriesUpdateInput",
		upsert:"Contember_ChannelDeliveryUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_VatRateWithoutChannelDeliveriesUpdateInput:{
		channelPayments:"Contember_VatRateUpdateChannelPaymentsEntityRelationInput",
		preset:"Contember_VatRatePreset"
	},
	Contember_VatRateUpdateChannelPaymentsEntityRelationInput:{
		create:"Contember_ChannelPaymentWithoutVatRateCreateInput",
		update:"Contember_VatRateUpdateChannelPaymentsRelationInput",
		upsert:"Contember_VatRateUpsertChannelPaymentsRelationInput",
		connect:"Contember_ChannelPaymentUniqueWhere",
		disconnect:"Contember_ChannelPaymentUniqueWhere",
		delete:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_VatRateUpdateChannelPaymentsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		data:"Contember_ChannelPaymentWithoutVatRateUpdateInput"
	},
	Contember_ChannelPaymentWithoutVatRateUpdateInput:{
		channel:"Contember_ChannelPaymentUpdateChannelEntityRelationInput",
		method:"Contember_ChannelPaymentUpdateMethodEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput:{
		create:"Contember_TagWithoutAllowChannelPaymentsCreateInput",
		update:"Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput",
		upsert:"Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutAllowChannelPaymentsUpdateInput"
	},
	Contember_TagWithoutAllowChannelPaymentsUpdateInput:{
		customers:"Contember_TagUpdateCustomersEntityRelationInput",
		products:"Contember_TagUpdateProductsEntityRelationInput",
		categories:"Contember_TagUpdateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagUpdateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagUpdatePublicInChannelsEntityRelationInput",
		fulfillmentNotes:"Contember_TagUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagUpdateCustomersEntityRelationInput:{
		create:"Contember_CustomerWithoutTagsCreateInput",
		update:"Contember_TagUpdateCustomersRelationInput",
		upsert:"Contember_TagUpsertCustomersRelationInput",
		connect:"Contember_CustomerUniqueWhere",
		disconnect:"Contember_CustomerUniqueWhere",
		delete:"Contember_CustomerUniqueWhere"
	},
	Contember_TagUpdateCustomersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		data:"Contember_CustomerWithoutTagsUpdateInput"
	},
	Contember_CustomerWithoutTagsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdatePaymentMethodsEntityRelationInput:{
		create:"Contember_CustomerPaymentMethodWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdatePaymentMethodsRelationInput",
		upsert:"Contember_CustomerUpsertPaymentMethodsRelationInput",
		connect:"Contember_CustomerPaymentMethodUniqueWhere",
		disconnect:"Contember_CustomerPaymentMethodUniqueWhere",
		delete:"Contember_CustomerPaymentMethodUniqueWhere"
	},
	Contember_CustomerUpdatePaymentMethodsRelationInput:{
		by:"Contember_CustomerPaymentMethodUniqueWhere",
		data:"Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"
	},
	Contember_CustomerPaymentMethodWithoutCustomerUpdateInput:{
		data:"Json",
		paymentMethod:"Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"
	},
	Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput:{
		create:"Contember_PaymentMethodCreateInput",
		update:"Contember_PaymentMethodUpdateInput",
		upsert:"Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput",
		connect:"Contember_PaymentMethodUniqueWhere"
	},
	Contember_PaymentMethodUpdateInput:{
		deliveryMethods:"Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput",
		type:"Contember_PaymentMethodType",
		channels:"Contember_PaymentMethodUpdateChannelsEntityRelationInput"
	},
	Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput:{
		update:"Contember_PaymentMethodUpdateInput",
		create:"Contember_PaymentMethodCreateInput"
	},
	Contember_CustomerUpsertPaymentMethodsRelationInput:{
		by:"Contember_CustomerPaymentMethodUniqueWhere",
		update:"Contember_CustomerPaymentMethodWithoutCustomerUpdateInput",
		create:"Contember_CustomerPaymentMethodWithoutCustomerCreateInput"
	},
	Contember_CustomerUpdateOrdersEntityRelationInput:{
		create:"Contember_OrderWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateOrdersRelationInput",
		upsert:"Contember_CustomerUpsertOrdersRelationInput",
		connect:"Contember_OrderUniqueWhere",
		disconnect:"Contember_OrderUniqueWhere",
		delete:"Contember_OrderUniqueWhere"
	},
	Contember_CustomerUpdateOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		data:"Contember_OrderWithoutCustomerUpdateInput"
	},
	Contember_OrderWithoutCustomerUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateChannelEntityRelationInput:{
		create:"Contember_ChannelCreateInput",
		update:"Contember_ChannelUpdateInput",
		upsert:"Contember_OrderUpsertChannelRelationInput",
		connect:"Contember_ChannelUniqueWhere"
	},
	Contember_ChannelUpdateInput:{
		payments:"Contember_ChannelUpdatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelUpdateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelUpdateCartsEntityRelationInput",
		customers:"Contember_ChannelUpdateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelUpdatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelUpdateVenueEntityRelationInput"
	},
	Contember_ChannelUpdatePointOfSalesEntityRelationInput:{
		create:"Contember_PointOfSaleWithoutChannelsCreateInput",
		update:"Contember_ChannelUpdatePointOfSalesRelationInput",
		upsert:"Contember_ChannelUpsertPointOfSalesRelationInput",
		connect:"Contember_PointOfSaleUniqueWhere",
		disconnect:"Contember_PointOfSaleUniqueWhere",
		delete:"Contember_PointOfSaleUniqueWhere"
	},
	Contember_ChannelUpdatePointOfSalesRelationInput:{
		by:"Contember_PointOfSaleUniqueWhere",
		data:"Contember_PointOfSaleWithoutChannelsUpdateInput"
	},
	Contember_PointOfSaleWithoutChannelsUpdateInput:{
		permissions:"Contember_PointOfSaleUpdatePermissionsEntityRelationInput",
		tenantPersonId:"UUID"
	},
	Contember_PointOfSaleUpdatePermissionsEntityRelationInput:{
		connect:"Contember_PointOfSalePermissionsUniqueWhere"
	},
	Contember_ChannelUpsertPointOfSalesRelationInput:{
		by:"Contember_PointOfSaleUniqueWhere",
		update:"Contember_PointOfSaleWithoutChannelsUpdateInput",
		create:"Contember_PointOfSaleWithoutChannelsCreateInput"
	},
	Contember_ChannelUpdateCartsEntityRelationInput:{
		create:"Contember_CartWithoutChannelCreateInput",
		update:"Contember_ChannelUpdateCartsRelationInput",
		upsert:"Contember_ChannelUpsertCartsRelationInput",
		connect:"Contember_CartUniqueWhere",
		disconnect:"Contember_CartUniqueWhere",
		delete:"Contember_CartUniqueWhere"
	},
	Contember_ChannelUpdateCartsRelationInput:{
		by:"Contember_CartUniqueWhere",
		data:"Contember_CartWithoutChannelUpdateInput"
	},
	Contember_CartWithoutChannelUpdateInput:{
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"Contember_CartUpdateItemsEntityRelationInput",
		state:"Contember_CartState",
		createdAt:"DateTime",
		order:"Contember_CartUpdateOrderEntityRelationInput",
		customer:"Contember_CartUpdateCustomerEntityRelationInput",
		deliveryAddress:"Contember_CartUpdateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartUpdateBillingAddressEntityRelationInput",
		delivery:"Contember_CartUpdateDeliveryEntityRelationInput",
		payment:"Contember_CartUpdatePaymentEntityRelationInput",
		billingSubject:"Contember_CartUpdateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartUpdatePriceLinesEntityRelationInput",
		summary:"Contember_CartUpdateSummaryEntityRelationInput"
	},
	Contember_CartUpdateItemsEntityRelationInput:{
		create:"Contember_CartItemWithoutCartCreateInput",
		update:"Contember_CartUpdateItemsRelationInput",
		upsert:"Contember_CartUpsertItemsRelationInput",
		connect:"Contember_CartItemUniqueWhere",
		disconnect:"Contember_CartItemUniqueWhere",
		delete:"Contember_CartItemUniqueWhere"
	},
	Contember_CartUpdateItemsRelationInput:{
		by:"Contember_CartItemUniqueWhere",
		data:"Contember_CartItemWithoutCartUpdateInput"
	},
	Contember_CartItemWithoutCartUpdateInput:{
		product:"Contember_CartItemUpdateProductEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		fulfillmentNotes:"Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_CartItemUpdateProductEntityRelationInput:{
		create:"Contember_ProductWithoutCartItemsCreateInput",
		update:"Contember_ProductWithoutCartItemsUpdateInput",
		upsert:"Contember_CartItemUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductWithoutCartItemsUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateRecipeEntityRelationInput:{
		create:"Contember_ProductRecipeWithoutProductsCreateInput",
		update:"Contember_ProductRecipeWithoutProductsUpdateInput",
		upsert:"Contember_ProductUpsertRecipeRelationInput",
		connect:"Contember_ProductRecipeUniqueWhere"
	},
	Contember_ProductRecipeWithoutProductsUpdateInput:{
		image:"Contember_ProductRecipeUpdateImageEntityRelationInput",
		icon:"Contember_ProductRecipeUpdateIconEntityRelationInput",
		ingredients:"Contember_ProductRecipeUpdateIngredientsEntityRelationInput",
		locales:"Contember_ProductRecipeUpdateLocalesEntityRelationInput",
		freshingContainer:"Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput",
		nutritionFacts:"Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"
	},
	Contember_ProductRecipeUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductRecipeUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductRecipeUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductRecipeUpdateIconEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductRecipeUpsertIconRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductRecipeUpsertIconRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductRecipeUpdateIngredientsEntityRelationInput:{
		create:"Contember_ProductIngredientItemWithoutRecipeCreateInput",
		update:"Contember_ProductRecipeUpdateIngredientsRelationInput",
		upsert:"Contember_ProductRecipeUpsertIngredientsRelationInput",
		connect:"Contember_ProductIngredientItemUniqueWhere",
		disconnect:"Contember_ProductIngredientItemUniqueWhere",
		delete:"Contember_ProductIngredientItemUniqueWhere"
	},
	Contember_ProductRecipeUpdateIngredientsRelationInput:{
		by:"Contember_ProductIngredientItemUniqueWhere",
		data:"Contember_ProductIngredientItemWithoutRecipeUpdateInput"
	},
	Contember_ProductIngredientItemWithoutRecipeUpdateInput:{
		ingredient:"Contember_ProductIngredientItemUpdateIngredientEntityRelationInput",
		quantity:"Contember_ProductIngredientItemUpdateQuantityEntityRelationInput"
	},
	Contember_ProductIngredientItemUpdateIngredientEntityRelationInput:{
		create:"Contember_ProductIngredientCreateInput",
		update:"Contember_ProductIngredientUpdateInput",
		upsert:"Contember_ProductIngredientItemUpsertIngredientRelationInput",
		connect:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_ProductIngredientUpdateInput:{
		locales:"Contember_ProductIngredientUpdateLocalesEntityRelationInput",
		categories:"Contember_ProductIngredientUpdateCategoriesEntityRelationInput",
		allergens:"Contember_ProductIngredientUpdateAllergensEntityRelationInput",
		customerRatings:"Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientUpdateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientUpdateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientUpdateLocalesEntityRelationInput:{
		create:"Contember_ProductIngredientLocaleWithoutRootCreateInput",
		update:"Contember_ProductIngredientUpdateLocalesRelationInput",
		upsert:"Contember_ProductIngredientUpsertLocalesRelationInput",
		connect:"Contember_ProductIngredientLocaleUniqueWhere",
		disconnect:"Contember_ProductIngredientLocaleUniqueWhere",
		delete:"Contember_ProductIngredientLocaleUniqueWhere"
	},
	Contember_ProductIngredientUpdateLocalesRelationInput:{
		by:"Contember_ProductIngredientLocaleUniqueWhere",
		data:"Contember_ProductIngredientLocaleWithoutRootUpdateInput"
	},
	Contember_ProductIngredientLocaleWithoutRootUpdateInput:{
		locale:"Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutProductIngredientsCreateInput",
		update:"Contember_LocaleWithoutProductIngredientsUpdateInput",
		upsert:"Contember_ProductIngredientLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutProductIngredientsUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateProductCategoryListsEntityRelationInput:{
		create:"Contember_ProductCategoryListLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateProductCategoryListsRelationInput",
		upsert:"Contember_LocaleUpsertProductCategoryListsRelationInput",
		connect:"Contember_ProductCategoryListLocaleUniqueWhere",
		disconnect:"Contember_ProductCategoryListLocaleUniqueWhere",
		delete:"Contember_ProductCategoryListLocaleUniqueWhere"
	},
	Contember_LocaleUpdateProductCategoryListsRelationInput:{
		by:"Contember_ProductCategoryListLocaleUniqueWhere",
		data:"Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"
	},
	Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput:{
		root:"Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput"
	},
	Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput:{
		create:"Contember_ProductCategoryListWithoutLocalesCreateInput",
		update:"Contember_ProductCategoryListWithoutLocalesUpdateInput",
		upsert:"Contember_ProductCategoryListLocaleUpsertRootRelationInput",
		connect:"Contember_ProductCategoryListUniqueWhere"
	},
	Contember_ProductCategoryListWithoutLocalesUpdateInput:{
		items:"Contember_ProductCategoryListUpdateItemsEntityRelationInput",
		seasonalProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListUpdateItemsEntityRelationInput:{
		create:"Contember_ProductCategoryListItemWithoutListCreateInput",
		update:"Contember_ProductCategoryListUpdateItemsRelationInput",
		upsert:"Contember_ProductCategoryListUpsertItemsRelationInput",
		connect:"Contember_ProductCategoryListItemUniqueWhere",
		disconnect:"Contember_ProductCategoryListItemUniqueWhere",
		delete:"Contember_ProductCategoryListItemUniqueWhere"
	},
	Contember_ProductCategoryListUpdateItemsRelationInput:{
		by:"Contember_ProductCategoryListItemUniqueWhere",
		data:"Contember_ProductCategoryListItemWithoutListUpdateInput"
	},
	Contember_ProductCategoryListItemWithoutListUpdateInput:{
		productCategory:"Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput:{
		create:"Contember_ProductCategoryCreateInput",
		update:"Contember_ProductCategoryUpdateInput",
		upsert:"Contember_ProductCategoryListItemUpsertProductCategoryRelationInput",
		connect:"Contember_ProductCategoryUniqueWhere"
	},
	Contember_ProductCategoryUpdateInput:{
		products:"Contember_ProductCategoryUpdateProductsEntityRelationInput",
		tags:"Contember_ProductCategoryUpdateTagsEntityRelationInput",
		parent:"Contember_ProductCategoryUpdateParentEntityRelationInput",
		children:"Contember_ProductCategoryUpdateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryUpdateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryUpdateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryUpdateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryUpdateProductsEntityRelationInput:{
		create:"Contember_ProductWithoutCategoriesCreateInput",
		update:"Contember_ProductCategoryUpdateProductsRelationInput",
		upsert:"Contember_ProductCategoryUpsertProductsRelationInput",
		connect:"Contember_ProductUniqueWhere",
		disconnect:"Contember_ProductUniqueWhere",
		delete:"Contember_ProductUniqueWhere"
	},
	Contember_ProductCategoryUpdateProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		data:"Contember_ProductWithoutCategoriesUpdateInput"
	},
	Contember_ProductWithoutCategoriesUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateTagsEntityRelationInput:{
		create:"Contember_TagWithoutProductsCreateInput",
		update:"Contember_ProductUpdateTagsRelationInput",
		upsert:"Contember_ProductUpsertTagsRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_ProductUpdateTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutProductsUpdateInput"
	},
	Contember_TagWithoutProductsUpdateInput:{
		customers:"Contember_TagUpdateCustomersEntityRelationInput",
		categories:"Contember_TagUpdateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagUpdateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagUpdatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagUpdateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagUpdateCategoriesEntityRelationInput:{
		create:"Contember_ProductCategoryWithoutTagsCreateInput",
		update:"Contember_TagUpdateCategoriesRelationInput",
		upsert:"Contember_TagUpsertCategoriesRelationInput",
		connect:"Contember_ProductCategoryUniqueWhere",
		disconnect:"Contember_ProductCategoryUniqueWhere",
		delete:"Contember_ProductCategoryUniqueWhere"
	},
	Contember_TagUpdateCategoriesRelationInput:{
		by:"Contember_ProductCategoryUniqueWhere",
		data:"Contember_ProductCategoryWithoutTagsUpdateInput"
	},
	Contember_ProductCategoryWithoutTagsUpdateInput:{
		products:"Contember_ProductCategoryUpdateProductsEntityRelationInput",
		parent:"Contember_ProductCategoryUpdateParentEntityRelationInput",
		children:"Contember_ProductCategoryUpdateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryUpdateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryUpdateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryUpdateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryUpdateParentEntityRelationInput:{
		create:"Contember_ProductCategoryWithoutChildrenCreateInput",
		update:"Contember_ProductCategoryWithoutChildrenUpdateInput",
		upsert:"Contember_ProductCategoryUpsertParentRelationInput",
		connect:"Contember_ProductCategoryUniqueWhere"
	},
	Contember_ProductCategoryWithoutChildrenUpdateInput:{
		products:"Contember_ProductCategoryUpdateProductsEntityRelationInput",
		tags:"Contember_ProductCategoryUpdateTagsEntityRelationInput",
		parent:"Contember_ProductCategoryUpdateParentEntityRelationInput",
		image:"Contember_ProductCategoryUpdateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryUpdateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryUpdateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryUpdateTagsEntityRelationInput:{
		create:"Contember_TagWithoutCategoriesCreateInput",
		update:"Contember_ProductCategoryUpdateTagsRelationInput",
		upsert:"Contember_ProductCategoryUpsertTagsRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_ProductCategoryUpdateTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutCategoriesUpdateInput"
	},
	Contember_TagWithoutCategoriesUpdateInput:{
		customers:"Contember_TagUpdateCustomersEntityRelationInput",
		products:"Contember_TagUpdateProductsEntityRelationInput",
		addTagsOnRegistration:"Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagUpdateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagUpdatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagUpdateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput:{
		create:"Contember_ChannelWithoutAddTagsOnRegistrationCreateInput",
		update:"Contember_TagUpdateAddTagsOnRegistrationRelationInput",
		upsert:"Contember_TagUpsertAddTagsOnRegistrationRelationInput",
		connect:"Contember_ChannelUniqueWhere",
		disconnect:"Contember_ChannelUniqueWhere",
		delete:"Contember_ChannelUniqueWhere"
	},
	Contember_TagUpdateAddTagsOnRegistrationRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		data:"Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"
	},
	Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput:{
		payments:"Contember_ChannelUpdatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelUpdateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelUpdateCartsEntityRelationInput",
		customers:"Contember_ChannelUpdateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		publicTags:"Contember_ChannelUpdatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelUpdateVenueEntityRelationInput"
	},
	Contember_ChannelUpdateCustomersEntityRelationInput:{
		create:"Contember_CustomerWithoutChannelsCreateInput",
		update:"Contember_ChannelUpdateCustomersRelationInput",
		upsert:"Contember_ChannelUpsertCustomersRelationInput",
		connect:"Contember_CustomerUniqueWhere",
		disconnect:"Contember_CustomerUniqueWhere",
		delete:"Contember_CustomerUniqueWhere"
	},
	Contember_ChannelUpdateCustomersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		data:"Contember_CustomerWithoutChannelsUpdateInput"
	},
	Contember_CustomerWithoutChannelsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdatePermissionsEntityRelationInput:{
		connect:"Contember_CustomerPermissionsUniqueWhere"
	},
	Contember_CustomerUpdateGroupEntityRelationInput:{
		create:"Contember_CustomerGroupWithoutCustomersCreateInput",
		update:"Contember_CustomerGroupWithoutCustomersUpdateInput",
		upsert:"Contember_CustomerUpsertGroupRelationInput",
		connect:"Contember_CustomerGroupUniqueWhere"
	},
	Contember_CustomerGroupWithoutCustomersUpdateInput:{
		productPrices:"Contember_CustomerGroupUpdateProductPricesEntityRelationInput"
	},
	Contember_CustomerGroupUpdateProductPricesEntityRelationInput:{
		create:"Contember_ProductGroupPriceWithoutGroupCreateInput",
		update:"Contember_CustomerGroupUpdateProductPricesRelationInput",
		upsert:"Contember_CustomerGroupUpsertProductPricesRelationInput",
		connect:"Contember_ProductGroupPriceUniqueWhere",
		disconnect:"Contember_ProductGroupPriceUniqueWhere",
		delete:"Contember_ProductGroupPriceUniqueWhere"
	},
	Contember_CustomerGroupUpdateProductPricesRelationInput:{
		by:"Contember_ProductGroupPriceUniqueWhere",
		data:"Contember_ProductGroupPriceWithoutGroupUpdateInput"
	},
	Contember_ProductGroupPriceWithoutGroupUpdateInput:{
		product:"Contember_ProductGroupPriceUpdateProductEntityRelationInput"
	},
	Contember_ProductGroupPriceUpdateProductEntityRelationInput:{
		create:"Contember_ProductWithoutGroupPricesCreateInput",
		update:"Contember_ProductWithoutGroupPricesUpdateInput",
		upsert:"Contember_ProductGroupPriceUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductWithoutGroupPricesUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateCategoriesEntityRelationInput:{
		create:"Contember_ProductCategoryWithoutProductsCreateInput",
		update:"Contember_ProductUpdateCategoriesRelationInput",
		upsert:"Contember_ProductUpsertCategoriesRelationInput",
		connect:"Contember_ProductCategoryUniqueWhere",
		disconnect:"Contember_ProductCategoryUniqueWhere",
		delete:"Contember_ProductCategoryUniqueWhere"
	},
	Contember_ProductUpdateCategoriesRelationInput:{
		by:"Contember_ProductCategoryUniqueWhere",
		data:"Contember_ProductCategoryWithoutProductsUpdateInput"
	},
	Contember_ProductCategoryWithoutProductsUpdateInput:{
		tags:"Contember_ProductCategoryUpdateTagsEntityRelationInput",
		parent:"Contember_ProductCategoryUpdateParentEntityRelationInput",
		children:"Contember_ProductCategoryUpdateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryUpdateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryUpdateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryUpdateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryUpdateChildrenEntityRelationInput:{
		create:"Contember_ProductCategoryWithoutParentCreateInput",
		update:"Contember_ProductCategoryUpdateChildrenRelationInput",
		upsert:"Contember_ProductCategoryUpsertChildrenRelationInput",
		connect:"Contember_ProductCategoryUniqueWhere",
		disconnect:"Contember_ProductCategoryUniqueWhere",
		delete:"Contember_ProductCategoryUniqueWhere"
	},
	Contember_ProductCategoryUpdateChildrenRelationInput:{
		by:"Contember_ProductCategoryUniqueWhere",
		data:"Contember_ProductCategoryWithoutParentUpdateInput"
	},
	Contember_ProductCategoryWithoutParentUpdateInput:{
		products:"Contember_ProductCategoryUpdateProductsEntityRelationInput",
		tags:"Contember_ProductCategoryUpdateTagsEntityRelationInput",
		children:"Contember_ProductCategoryUpdateChildrenEntityRelationInput",
		image:"Contember_ProductCategoryUpdateImageEntityRelationInput",
		descendants:"Contember_ProductCategoryUpdateDescendantsEntityRelationInput",
		ancestors:"Contember_ProductCategoryUpdateAncestorsEntityRelationInput",
		inheritedTags:"Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput",
		nestedProducts:"Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"
	},
	Contember_ProductCategoryUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductCategoryUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductCategoryUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductCategoryUpdateDescendantsEntityRelationInput:{
		connect:"Contember_ProductCategoryTreeUniqueWhere",
		disconnect:"Contember_ProductCategoryTreeUniqueWhere",
		delete:"Contember_ProductCategoryTreeUniqueWhere"
	},
	Contember_ProductCategoryUpdateAncestorsEntityRelationInput:{
		connect:"Contember_ProductCategoryTreeUniqueWhere",
		disconnect:"Contember_ProductCategoryTreeUniqueWhere",
		delete:"Contember_ProductCategoryTreeUniqueWhere"
	},
	Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput:{
		connect:"Contember_InheritedProductCategoryTagUniqueWhere",
		disconnect:"Contember_InheritedProductCategoryTagUniqueWhere",
		delete:"Contember_InheritedProductCategoryTagUniqueWhere"
	},
	Contember_ProductCategoryUpdateNestedProductsEntityRelationInput:{
		connect:"Contember_NestedProductCategoryProductUniqueWhere",
		disconnect:"Contember_NestedProductCategoryProductUniqueWhere",
		delete:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_ProductCategoryUpsertChildrenRelationInput:{
		by:"Contember_ProductCategoryUniqueWhere",
		update:"Contember_ProductCategoryWithoutParentUpdateInput",
		create:"Contember_ProductCategoryWithoutParentCreateInput"
	},
	Contember_ProductUpsertCategoriesRelationInput:{
		by:"Contember_ProductCategoryUniqueWhere",
		update:"Contember_ProductCategoryWithoutProductsUpdateInput",
		create:"Contember_ProductCategoryWithoutProductsCreateInput"
	},
	Contember_ProductUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_ProductUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_ProductUpsertVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_ProductUpdateStocksEntityRelationInput:{
		create:"Contember_ProductStockWithoutProductCreateInput",
		update:"Contember_ProductUpdateStocksRelationInput",
		upsert:"Contember_ProductUpsertStocksRelationInput",
		connect:"Contember_ProductStockUniqueWhere",
		disconnect:"Contember_ProductStockUniqueWhere",
		delete:"Contember_ProductStockUniqueWhere"
	},
	Contember_ProductUpdateStocksRelationInput:{
		by:"Contember_ProductStockUniqueWhere",
		data:"Contember_ProductStockWithoutProductUpdateInput"
	},
	Contember_ProductStockWithoutProductUpdateInput:{
		store:"Contember_ProductStockUpdateStoreEntityRelationInput"
	},
	Contember_ProductStockUpdateStoreEntityRelationInput:{
		create:"Contember_StoreCreateInput",
		update:"Contember_StoreUpdateInput",
		upsert:"Contember_ProductStockUpsertStoreRelationInput",
		connect:"Contember_StoreUniqueWhere"
	},
	Contember_StoreUpdateInput:{

	},
	Contember_ProductStockUpsertStoreRelationInput:{
		update:"Contember_StoreUpdateInput",
		create:"Contember_StoreCreateInput"
	},
	Contember_ProductUpsertStocksRelationInput:{
		by:"Contember_ProductStockUniqueWhere",
		update:"Contember_ProductStockWithoutProductUpdateInput",
		create:"Contember_ProductStockWithoutProductCreateInput"
	},
	Contember_ProductUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductUpdateCartItemsEntityRelationInput:{
		create:"Contember_CartItemWithoutProductCreateInput",
		update:"Contember_ProductUpdateCartItemsRelationInput",
		upsert:"Contember_ProductUpsertCartItemsRelationInput",
		connect:"Contember_CartItemUniqueWhere",
		disconnect:"Contember_CartItemUniqueWhere",
		delete:"Contember_CartItemUniqueWhere"
	},
	Contember_ProductUpdateCartItemsRelationInput:{
		by:"Contember_CartItemUniqueWhere",
		data:"Contember_CartItemWithoutProductUpdateInput"
	},
	Contember_CartItemWithoutProductUpdateInput:{
		cart:"Contember_CartItemUpdateCartEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		fulfillmentNotes:"Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_CartItemUpdateCartEntityRelationInput:{
		create:"Contember_CartWithoutItemsCreateInput",
		update:"Contember_CartWithoutItemsUpdateInput",
		upsert:"Contember_CartItemUpsertCartRelationInput",
		connect:"Contember_CartUniqueWhere"
	},
	Contember_CartWithoutItemsUpdateInput:{
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		state:"Contember_CartState",
		createdAt:"DateTime",
		channel:"Contember_CartUpdateChannelEntityRelationInput",
		order:"Contember_CartUpdateOrderEntityRelationInput",
		customer:"Contember_CartUpdateCustomerEntityRelationInput",
		deliveryAddress:"Contember_CartUpdateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartUpdateBillingAddressEntityRelationInput",
		delivery:"Contember_CartUpdateDeliveryEntityRelationInput",
		payment:"Contember_CartUpdatePaymentEntityRelationInput",
		billingSubject:"Contember_CartUpdateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartUpdatePriceLinesEntityRelationInput",
		summary:"Contember_CartUpdateSummaryEntityRelationInput"
	},
	Contember_CartUpdateChannelEntityRelationInput:{
		create:"Contember_ChannelWithoutCartsCreateInput",
		update:"Contember_ChannelWithoutCartsUpdateInput",
		upsert:"Contember_CartUpsertChannelRelationInput",
		connect:"Contember_ChannelUniqueWhere"
	},
	Contember_ChannelWithoutCartsUpdateInput:{
		payments:"Contember_ChannelUpdatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelUpdateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		customers:"Contember_ChannelUpdateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelUpdatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelUpdateVenueEntityRelationInput"
	},
	Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput:{
		create:"Contember_TagWithoutAddTagsOnRegistrationCreateInput",
		update:"Contember_ChannelUpdateAddTagsOnRegistrationRelationInput",
		upsert:"Contember_ChannelUpsertAddTagsOnRegistrationRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_ChannelUpdateAddTagsOnRegistrationRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutAddTagsOnRegistrationUpdateInput"
	},
	Contember_TagWithoutAddTagsOnRegistrationUpdateInput:{
		customers:"Contember_TagUpdateCustomersEntityRelationInput",
		products:"Contember_TagUpdateProductsEntityRelationInput",
		categories:"Contember_TagUpdateCategoriesEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagUpdateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagUpdatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagUpdateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagUpdateNestedCategoriesEntityRelationInput:{
		connect:"Contember_InheritedProductCategoryTagUniqueWhere",
		disconnect:"Contember_InheritedProductCategoryTagUniqueWhere",
		delete:"Contember_InheritedProductCategoryTagUniqueWhere"
	},
	Contember_TagUpdateDeliveryZonesEntityRelationInput:{
		create:"Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput",
		update:"Contember_TagUpdateDeliveryZonesRelationInput",
		upsert:"Contember_TagUpsertDeliveryZonesRelationInput",
		connect:"Contember_DeliveryZoneUniqueWhere",
		disconnect:"Contember_DeliveryZoneUniqueWhere",
		delete:"Contember_DeliveryZoneUniqueWhere"
	},
	Contember_TagUpdateDeliveryZonesRelationInput:{
		by:"Contember_DeliveryZoneUniqueWhere",
		data:"Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"
	},
	Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput:{
		type:"Contember_DeliveryZoneType",
		addresses:"Contember_DeliveryZoneUpdateAddressesEntityRelationInput",
		suitableDeliveryMethods:"Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput",
		deliveryZonesOfProducts:"Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput:{
		create:"Contember_ProductWithoutDeliveryZonesCreateInput",
		update:"Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput",
		upsert:"Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput",
		connect:"Contember_ProductUniqueWhere",
		disconnect:"Contember_ProductUniqueWhere",
		delete:"Contember_ProductUniqueWhere"
	},
	Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		data:"Contember_ProductWithoutDeliveryZonesUpdateInput"
	},
	Contember_ProductWithoutDeliveryZonesUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateGroupPricesEntityRelationInput:{
		create:"Contember_ProductGroupPriceWithoutProductCreateInput",
		update:"Contember_ProductUpdateGroupPricesRelationInput",
		upsert:"Contember_ProductUpsertGroupPricesRelationInput",
		connect:"Contember_ProductGroupPriceUniqueWhere",
		disconnect:"Contember_ProductGroupPriceUniqueWhere",
		delete:"Contember_ProductGroupPriceUniqueWhere"
	},
	Contember_ProductUpdateGroupPricesRelationInput:{
		by:"Contember_ProductGroupPriceUniqueWhere",
		data:"Contember_ProductGroupPriceWithoutProductUpdateInput"
	},
	Contember_ProductGroupPriceWithoutProductUpdateInput:{
		group:"Contember_ProductGroupPriceUpdateGroupEntityRelationInput"
	},
	Contember_ProductGroupPriceUpdateGroupEntityRelationInput:{
		create:"Contember_CustomerGroupWithoutProductPricesCreateInput",
		update:"Contember_CustomerGroupWithoutProductPricesUpdateInput",
		upsert:"Contember_ProductGroupPriceUpsertGroupRelationInput",
		connect:"Contember_CustomerGroupUniqueWhere"
	},
	Contember_CustomerGroupWithoutProductPricesUpdateInput:{
		customers:"Contember_CustomerGroupUpdateCustomersEntityRelationInput"
	},
	Contember_CustomerGroupUpdateCustomersEntityRelationInput:{
		create:"Contember_CustomerWithoutGroupCreateInput",
		update:"Contember_CustomerGroupUpdateCustomersRelationInput",
		upsert:"Contember_CustomerGroupUpsertCustomersRelationInput",
		connect:"Contember_CustomerUniqueWhere",
		disconnect:"Contember_CustomerUniqueWhere",
		delete:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerGroupUpdateCustomersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		data:"Contember_CustomerWithoutGroupUpdateInput"
	},
	Contember_CustomerWithoutGroupUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdatePhotoEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_CustomerUpsertPhotoRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_CustomerUpsertPhotoRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_CustomerUpdateNameEntityRelationInput:{
		create:"Contember_CustomerNameWithoutCustomerCreateInput",
		update:"Contember_CustomerNameWithoutCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertNameRelationInput",
		connect:"Contember_CustomerNameUniqueWhere"
	},
	Contember_CustomerNameWithoutCustomerUpdateInput:{
		aliases:"Contember_CustomerNameUpdateAliasesEntityRelationInput"
	},
	Contember_CustomerNameUpdateAliasesEntityRelationInput:{
		create:"Contember_CustomerAliasCreateInput",
		update:"Contember_CustomerNameUpdateAliasesRelationInput",
		upsert:"Contember_CustomerNameUpsertAliasesRelationInput",
		connect:"Contember_CustomerAliasUniqueWhere",
		disconnect:"Contember_CustomerAliasUniqueWhere",
		delete:"Contember_CustomerAliasUniqueWhere"
	},
	Contember_CustomerNameUpdateAliasesRelationInput:{
		by:"Contember_CustomerAliasUniqueWhere",
		data:"Contember_CustomerAliasUpdateInput"
	},
	Contember_CustomerAliasUpdateInput:{

	},
	Contember_CustomerNameUpsertAliasesRelationInput:{
		by:"Contember_CustomerAliasUniqueWhere",
		update:"Contember_CustomerAliasUpdateInput",
		create:"Contember_CustomerAliasCreateInput"
	},
	Contember_CustomerUpsertNameRelationInput:{
		update:"Contember_CustomerNameWithoutCustomerUpdateInput",
		create:"Contember_CustomerNameWithoutCustomerCreateInput"
	},
	Contember_CustomerUpdateIngredientRatingsEntityRelationInput:{
		create:"Contember_CustomerIngredientRatingWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateIngredientRatingsRelationInput",
		upsert:"Contember_CustomerUpsertIngredientRatingsRelationInput",
		connect:"Contember_CustomerIngredientRatingUniqueWhere",
		disconnect:"Contember_CustomerIngredientRatingUniqueWhere",
		delete:"Contember_CustomerIngredientRatingUniqueWhere"
	},
	Contember_CustomerUpdateIngredientRatingsRelationInput:{
		by:"Contember_CustomerIngredientRatingUniqueWhere",
		data:"Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"
	},
	Contember_CustomerIngredientRatingWithoutCustomerUpdateInput:{
		rating:"Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput",
		ingredient:"Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput"
	},
	Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput:{
		create:"Contember_ProductIngredientRatingCreateInput",
		update:"Contember_ProductIngredientRatingUpdateInput",
		upsert:"Contember_CustomerIngredientRatingUpsertRatingRelationInput",
		connect:"Contember_ProductIngredientRatingUniqueWhere"
	},
	Contember_ProductIngredientRatingUpdateInput:{
		icon:"Contember_ProductIngredientRatingUpdateIconEntityRelationInput"
	},
	Contember_ProductIngredientRatingUpdateIconEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductIngredientRatingUpsertIconRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductIngredientRatingUpsertIconRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_CustomerIngredientRatingUpsertRatingRelationInput:{
		update:"Contember_ProductIngredientRatingUpdateInput",
		create:"Contember_ProductIngredientRatingCreateInput"
	},
	Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput:{
		create:"Contember_ProductIngredientWithoutCustomerRatingsCreateInput",
		update:"Contember_ProductIngredientWithoutCustomerRatingsUpdateInput",
		upsert:"Contember_CustomerIngredientRatingUpsertIngredientRelationInput",
		connect:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_ProductIngredientWithoutCustomerRatingsUpdateInput:{
		locales:"Contember_ProductIngredientUpdateLocalesEntityRelationInput",
		categories:"Contember_ProductIngredientUpdateCategoriesEntityRelationInput",
		allergens:"Contember_ProductIngredientUpdateAllergensEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientUpdateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientUpdateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientUpdateCategoriesEntityRelationInput:{
		create:"Contember_ProductIngredientCategoryWithoutIngredientsCreateInput",
		update:"Contember_ProductIngredientUpdateCategoriesRelationInput",
		upsert:"Contember_ProductIngredientUpsertCategoriesRelationInput",
		connect:"Contember_ProductIngredientCategoryUniqueWhere",
		disconnect:"Contember_ProductIngredientCategoryUniqueWhere",
		delete:"Contember_ProductIngredientCategoryUniqueWhere"
	},
	Contember_ProductIngredientUpdateCategoriesRelationInput:{
		by:"Contember_ProductIngredientCategoryUniqueWhere",
		data:"Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"
	},
	Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput:{
		locales:"Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput"
	},
	Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput:{
		create:"Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput",
		update:"Contember_ProductIngredientCategoryUpdateLocalesRelationInput",
		upsert:"Contember_ProductIngredientCategoryUpsertLocalesRelationInput",
		connect:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		disconnect:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		delete:"Contember_ProductIngredientCategoryLocaleUniqueWhere"
	},
	Contember_ProductIngredientCategoryUpdateLocalesRelationInput:{
		by:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		data:"Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"
	},
	Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput:{
		locale:"Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutProductIngredientCategoriesCreateInput",
		update:"Contember_LocaleWithoutProductIngredientCategoriesUpdateInput",
		upsert:"Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutProductIngredientCategoriesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateProductIngredientsEntityRelationInput:{
		create:"Contember_ProductIngredientLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateProductIngredientsRelationInput",
		upsert:"Contember_LocaleUpsertProductIngredientsRelationInput",
		connect:"Contember_ProductIngredientLocaleUniqueWhere",
		disconnect:"Contember_ProductIngredientLocaleUniqueWhere",
		delete:"Contember_ProductIngredientLocaleUniqueWhere"
	},
	Contember_LocaleUpdateProductIngredientsRelationInput:{
		by:"Contember_ProductIngredientLocaleUniqueWhere",
		data:"Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"
	},
	Contember_ProductIngredientLocaleWithoutLocaleUpdateInput:{
		root:"Contember_ProductIngredientLocaleUpdateRootEntityRelationInput"
	},
	Contember_ProductIngredientLocaleUpdateRootEntityRelationInput:{
		create:"Contember_ProductIngredientWithoutLocalesCreateInput",
		update:"Contember_ProductIngredientWithoutLocalesUpdateInput",
		upsert:"Contember_ProductIngredientLocaleUpsertRootRelationInput",
		connect:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_ProductIngredientWithoutLocalesUpdateInput:{
		categories:"Contember_ProductIngredientUpdateCategoriesEntityRelationInput",
		allergens:"Contember_ProductIngredientUpdateAllergensEntityRelationInput",
		customerRatings:"Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientUpdateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientUpdateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientUpdateAllergensEntityRelationInput:{
		create:"Contember_AllergenWithoutIngredientsCreateInput",
		update:"Contember_ProductIngredientUpdateAllergensRelationInput",
		upsert:"Contember_ProductIngredientUpsertAllergensRelationInput",
		connect:"Contember_AllergenUniqueWhere",
		disconnect:"Contember_AllergenUniqueWhere",
		delete:"Contember_AllergenUniqueWhere"
	},
	Contember_ProductIngredientUpdateAllergensRelationInput:{
		by:"Contember_AllergenUniqueWhere",
		data:"Contember_AllergenWithoutIngredientsUpdateInput"
	},
	Contember_AllergenWithoutIngredientsUpdateInput:{
		locales:"Contember_AllergenUpdateLocalesEntityRelationInput"
	},
	Contember_AllergenUpdateLocalesEntityRelationInput:{
		create:"Contember_AllergenLocaleWithoutRootCreateInput",
		update:"Contember_AllergenUpdateLocalesRelationInput",
		upsert:"Contember_AllergenUpsertLocalesRelationInput",
		connect:"Contember_AllergenLocaleUniqueWhere",
		disconnect:"Contember_AllergenLocaleUniqueWhere",
		delete:"Contember_AllergenLocaleUniqueWhere"
	},
	Contember_AllergenUpdateLocalesRelationInput:{
		by:"Contember_AllergenLocaleUniqueWhere",
		data:"Contember_AllergenLocaleWithoutRootUpdateInput"
	},
	Contember_AllergenLocaleWithoutRootUpdateInput:{
		locale:"Contember_AllergenLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_AllergenLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutAllergensCreateInput",
		update:"Contember_LocaleWithoutAllergensUpdateInput",
		upsert:"Contember_AllergenLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutAllergensUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateOrderRecurrencesEntityRelationInput:{
		create:"Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateOrderRecurrencesRelationInput",
		upsert:"Contember_LocaleUpsertOrderRecurrencesRelationInput",
		connect:"Contember_OrderRecurrenceLocaleUniqueWhere",
		disconnect:"Contember_OrderRecurrenceLocaleUniqueWhere",
		delete:"Contember_OrderRecurrenceLocaleUniqueWhere"
	},
	Contember_LocaleUpdateOrderRecurrencesRelationInput:{
		by:"Contember_OrderRecurrenceLocaleUniqueWhere",
		data:"Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"
	},
	Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput:{
		root:"Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput"
	},
	Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput:{
		create:"Contember_OrderRecurrenceWithoutLocalesCreateInput",
		update:"Contember_OrderRecurrenceWithoutLocalesUpdateInput",
		upsert:"Contember_OrderRecurrenceLocaleUpsertRootRelationInput",
		connect:"Contember_OrderRecurrenceUniqueWhere"
	},
	Contember_OrderRecurrenceWithoutLocalesUpdateInput:{
		businessCategory:"Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput"
	},
	Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput:{
		create:"Contember_BusinessCategoryWithoutRecurrencesCreateInput",
		update:"Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput",
		upsert:"Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput",
		connect:"Contember_BusinessCategoryUniqueWhere",
		disconnect:"Contember_BusinessCategoryUniqueWhere",
		delete:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput:{
		by:"Contember_BusinessCategoryUniqueWhere",
		data:"Contember_BusinessCategoryWithoutRecurrencesUpdateInput"
	},
	Contember_BusinessCategoryWithoutRecurrencesUpdateInput:{
		locales:"Contember_BusinessCategoryUpdateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_BusinessCategoryUpdateLocalesEntityRelationInput:{
		create:"Contember_BusinessCategoryLocaleWithoutRootCreateInput",
		update:"Contember_BusinessCategoryUpdateLocalesRelationInput",
		upsert:"Contember_BusinessCategoryUpsertLocalesRelationInput",
		connect:"Contember_BusinessCategoryLocaleUniqueWhere",
		disconnect:"Contember_BusinessCategoryLocaleUniqueWhere",
		delete:"Contember_BusinessCategoryLocaleUniqueWhere"
	},
	Contember_BusinessCategoryUpdateLocalesRelationInput:{
		by:"Contember_BusinessCategoryLocaleUniqueWhere",
		data:"Contember_BusinessCategoryLocaleWithoutRootUpdateInput"
	},
	Contember_BusinessCategoryLocaleWithoutRootUpdateInput:{
		locale:"Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutBusinessCategoriesCreateInput",
		update:"Contember_LocaleWithoutBusinessCategoriesUpdateInput",
		upsert:"Contember_BusinessCategoryLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutBusinessCategoriesUpdateInput:{
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateBlogsEntityRelationInput:{
		create:"Contember_BlogLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateBlogsRelationInput",
		upsert:"Contember_LocaleUpsertBlogsRelationInput",
		connect:"Contember_BlogLocaleUniqueWhere",
		disconnect:"Contember_BlogLocaleUniqueWhere",
		delete:"Contember_BlogLocaleUniqueWhere"
	},
	Contember_LocaleUpdateBlogsRelationInput:{
		by:"Contember_BlogLocaleUniqueWhere",
		data:"Contember_BlogLocaleWithoutLocaleUpdateInput"
	},
	Contember_BlogLocaleWithoutLocaleUpdateInput:{
		root:"Contember_BlogLocaleUpdateRootEntityRelationInput"
	},
	Contember_BlogLocaleUpdateRootEntityRelationInput:{
		create:"Contember_BlogWithoutLocalesCreateInput",
		update:"Contember_BlogWithoutLocalesUpdateInput",
		upsert:"Contember_BlogLocaleUpsertRootRelationInput",
		connect:"Contember_BlogUniqueWhere"
	},
	Contember_BlogWithoutLocalesUpdateInput:{
		unique:"Contember_One"
	},
	Contember_BlogLocaleUpsertRootRelationInput:{
		update:"Contember_BlogWithoutLocalesUpdateInput",
		create:"Contember_BlogWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertBlogsRelationInput:{
		by:"Contember_BlogLocaleUniqueWhere",
		update:"Contember_BlogLocaleWithoutLocaleUpdateInput",
		create:"Contember_BlogLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdateBlogPostsEntityRelationInput:{
		create:"Contember_BlogPostLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateBlogPostsRelationInput",
		upsert:"Contember_LocaleUpsertBlogPostsRelationInput",
		connect:"Contember_BlogPostLocaleUniqueWhere",
		disconnect:"Contember_BlogPostLocaleUniqueWhere",
		delete:"Contember_BlogPostLocaleUniqueWhere"
	},
	Contember_LocaleUpdateBlogPostsRelationInput:{
		by:"Contember_BlogPostLocaleUniqueWhere",
		data:"Contember_BlogPostLocaleWithoutLocaleUpdateInput"
	},
	Contember_BlogPostLocaleWithoutLocaleUpdateInput:{
		root:"Contember_BlogPostLocaleUpdateRootEntityRelationInput",
		cover:"Contember_BlogPostLocaleUpdateCoverEntityRelationInput",
		content:"Contember_BlogPostLocaleUpdateContentEntityRelationInput",
		link:"Contember_BlogPostLocaleUpdateLinkEntityRelationInput",
		seo:"Contember_BlogPostLocaleUpdateSeoEntityRelationInput",
		products:"Contember_BlogPostLocaleUpdateProductsEntityRelationInput"
	},
	Contember_BlogPostLocaleUpdateRootEntityRelationInput:{
		create:"Contember_BlogPostWithoutLocalesCreateInput",
		update:"Contember_BlogPostWithoutLocalesUpdateInput",
		upsert:"Contember_BlogPostLocaleUpsertRootRelationInput",
		connect:"Contember_BlogPostUniqueWhere"
	},
	Contember_BlogPostWithoutLocalesUpdateInput:{

	},
	Contember_BlogPostLocaleUpsertRootRelationInput:{
		update:"Contember_BlogPostWithoutLocalesUpdateInput",
		create:"Contember_BlogPostWithoutLocalesCreateInput"
	},
	Contember_BlogPostLocaleUpdateCoverEntityRelationInput:{
		create:"Contember_CoverCreateInput",
		update:"Contember_CoverUpdateInput",
		upsert:"Contember_BlogPostLocaleUpsertCoverRelationInput",
		connect:"Contember_CoverUniqueWhere"
	},
	Contember_CoverUpdateInput:{
		medium:"Contember_CoverUpdateMediumEntityRelationInput",
		buttonColors:"Contember_CoverUpdateButtonColorsEntityRelationInput"
	},
	Contember_CoverUpdateMediumEntityRelationInput:{
		create:"Contember_MediumCreateInput",
		update:"Contember_MediumUpdateInput",
		upsert:"Contember_CoverUpsertMediumRelationInput",
		connect:"Contember_MediumUniqueWhere"
	},
	Contember_MediumUpdateInput:{
		type:"Contember_MediumType",
		colorTheme:"Contember_ColorTheme",
		image:"Contember_MediumUpdateImageEntityRelationInput",
		video:"Contember_MediumUpdateVideoEntityRelationInput"
	},
	Contember_MediumUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_MediumUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_MediumUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_MediumUpdateVideoEntityRelationInput:{
		create:"Contember_VideoCreateInput",
		update:"Contember_VideoUpdateInput",
		upsert:"Contember_MediumUpsertVideoRelationInput",
		connect:"Contember_VideoUniqueWhere"
	},
	Contember_VideoUpdateInput:{
		poster:"Contember_VideoUpdatePosterEntityRelationInput"
	},
	Contember_VideoUpdatePosterEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_VideoUpsertPosterRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_VideoUpsertPosterRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_MediumUpsertVideoRelationInput:{
		update:"Contember_VideoUpdateInput",
		create:"Contember_VideoCreateInput"
	},
	Contember_CoverUpsertMediumRelationInput:{
		update:"Contember_MediumUpdateInput",
		create:"Contember_MediumCreateInput"
	},
	Contember_CoverUpdateButtonColorsEntityRelationInput:{
		create:"Contember_ColorPalleteCreateInput",
		update:"Contember_ColorPalleteUpdateInput",
		upsert:"Contember_CoverUpsertButtonColorsRelationInput",
		connect:"Contember_ColorPalleteUniqueWhere"
	},
	Contember_ColorPalleteUpdateInput:{

	},
	Contember_CoverUpsertButtonColorsRelationInput:{
		update:"Contember_ColorPalleteUpdateInput",
		create:"Contember_ColorPalleteCreateInput"
	},
	Contember_BlogPostLocaleUpsertCoverRelationInput:{
		update:"Contember_CoverUpdateInput",
		create:"Contember_CoverCreateInput"
	},
	Contember_BlogPostLocaleUpdateContentEntityRelationInput:{
		create:"Contember_ContentCreateInput",
		update:"Contember_ContentUpdateInput",
		upsert:"Contember_BlogPostLocaleUpsertContentRelationInput",
		connect:"Contember_ContentUniqueWhere"
	},
	Contember_ContentUpdateInput:{
		blocks:"Contember_ContentUpdateBlocksEntityRelationInput"
	},
	Contember_ContentUpdateBlocksEntityRelationInput:{
		create:"Contember_ContentBlockWithoutContentCreateInput",
		update:"Contember_ContentUpdateBlocksRelationInput",
		upsert:"Contember_ContentUpsertBlocksRelationInput",
		connect:"Contember_ContentBlockUniqueWhere",
		disconnect:"Contember_ContentBlockUniqueWhere",
		delete:"Contember_ContentBlockUniqueWhere"
	},
	Contember_ContentUpdateBlocksRelationInput:{
		by:"Contember_ContentBlockUniqueWhere",
		data:"Contember_ContentBlockWithoutContentUpdateInput"
	},
	Contember_ContentBlockWithoutContentUpdateInput:{
		references:"Contember_ContentBlockUpdateReferencesEntityRelationInput"
	},
	Contember_ContentBlockUpdateReferencesEntityRelationInput:{
		create:"Contember_ContentReferenceWithoutBlockCreateInput",
		update:"Contember_ContentBlockUpdateReferencesRelationInput",
		upsert:"Contember_ContentBlockUpsertReferencesRelationInput",
		connect:"Contember_ContentReferenceUniqueWhere",
		disconnect:"Contember_ContentReferenceUniqueWhere",
		delete:"Contember_ContentReferenceUniqueWhere"
	},
	Contember_ContentBlockUpdateReferencesRelationInput:{
		by:"Contember_ContentReferenceUniqueWhere",
		data:"Contember_ContentReferenceWithoutBlockUpdateInput"
	},
	Contember_ContentReferenceWithoutBlockUpdateInput:{
		type:"Contember_ContentReferenceType",
		colorPallete:"Contember_ContentReferenceUpdateColorPalleteEntityRelationInput",
		medium:"Contember_ContentReferenceUpdateMediumEntityRelationInput",
		link:"Contember_ContentReferenceUpdateLinkEntityRelationInput",
		blogPosts:"Contember_ContentReferenceUpdateBlogPostsEntityRelationInput",
		links:"Contember_ContentReferenceUpdateLinksEntityRelationInput",
		products:"Contember_ContentReferenceUpdateProductsEntityRelationInput",
		contentSize:"Contember_ContentSize",
		hero:"Contember_ContentReferenceUpdateHeroEntityRelationInput",
		gallery:"Contember_ContentReferenceUpdateGalleryEntityRelationInput",
		socialsAndApps:"Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput",
		productGrid:"Contember_ContentReferenceUpdateProductGridEntityRelationInput",
		productBanners:"Contember_ContentReferenceUpdateProductBannersEntityRelationInput",
		deliveryRegions:"Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput",
		textList:"Contember_ContentReferenceUpdateTextListEntityRelationInput",
		gridTiles:"Contember_ContentReferenceUpdateGridTilesEntityRelationInput"
	},
	Contember_ContentReferenceUpdateColorPalleteEntityRelationInput:{
		create:"Contember_ColorPalleteCreateInput",
		update:"Contember_ColorPalleteUpdateInput",
		upsert:"Contember_ContentReferenceUpsertColorPalleteRelationInput",
		connect:"Contember_ColorPalleteUniqueWhere"
	},
	Contember_ContentReferenceUpsertColorPalleteRelationInput:{
		update:"Contember_ColorPalleteUpdateInput",
		create:"Contember_ColorPalleteCreateInput"
	},
	Contember_ContentReferenceUpdateMediumEntityRelationInput:{
		create:"Contember_MediumCreateInput",
		update:"Contember_MediumUpdateInput",
		upsert:"Contember_ContentReferenceUpsertMediumRelationInput",
		connect:"Contember_MediumUniqueWhere"
	},
	Contember_ContentReferenceUpsertMediumRelationInput:{
		update:"Contember_MediumUpdateInput",
		create:"Contember_MediumCreateInput"
	},
	Contember_ContentReferenceUpdateLinkEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_ContentReferenceUpsertLinkRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_LinkUpdateInput:{
		type:"Contember_LinkType",
		internalLink:"Contember_LinkUpdateInternalLinkEntityRelationInput"
	},
	Contember_LinkUpdateInternalLinkEntityRelationInput:{
		create:"Contember_LinkableCreateInput",
		update:"Contember_LinkableUpdateInput",
		upsert:"Contember_LinkUpsertInternalLinkRelationInput",
		connect:"Contember_LinkableUniqueWhere"
	},
	Contember_LinkableUpdateInput:{
		blogPost:"Contember_LinkableUpdateBlogPostEntityRelationInput",
		page:"Contember_LinkableUpdatePageEntityRelationInput",
		wikiPage:"Contember_LinkableUpdateWikiPageEntityRelationInput",
		redirect:"Contember_LinkableUpdateRedirectEntityRelationInput"
	},
	Contember_LinkableUpdateBlogPostEntityRelationInput:{
		create:"Contember_BlogPostLocaleWithoutLinkCreateInput",
		update:"Contember_BlogPostLocaleWithoutLinkUpdateInput",
		upsert:"Contember_LinkableUpsertBlogPostRelationInput",
		connect:"Contember_BlogPostLocaleUniqueWhere"
	},
	Contember_BlogPostLocaleWithoutLinkUpdateInput:{
		root:"Contember_BlogPostLocaleUpdateRootEntityRelationInput",
		locale:"Contember_BlogPostLocaleUpdateLocaleEntityRelationInput",
		cover:"Contember_BlogPostLocaleUpdateCoverEntityRelationInput",
		content:"Contember_BlogPostLocaleUpdateContentEntityRelationInput",
		seo:"Contember_BlogPostLocaleUpdateSeoEntityRelationInput",
		products:"Contember_BlogPostLocaleUpdateProductsEntityRelationInput"
	},
	Contember_BlogPostLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutBlogPostsCreateInput",
		update:"Contember_LocaleWithoutBlogPostsUpdateInput",
		upsert:"Contember_BlogPostLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutBlogPostsUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateIdentifierEntityRelationInput:{
		create:"Contember_TranslationCataloguesIdentifierCreateInput",
		update:"Contember_TranslationCataloguesIdentifierUpdateInput",
		upsert:"Contember_LocaleUpsertIdentifierRelationInput",
		connect:"Contember_TranslationCataloguesIdentifierUniqueWhere"
	},
	Contember_TranslationCataloguesIdentifierUpdateInput:{
		catalogue:"Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput"
	},
	Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput:{
		create:"Contember_TranslationCatalogueWithoutIdentifierCreateInput",
		update:"Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput",
		upsert:"Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput",
		connect:"Contember_TranslationCatalogueUniqueWhere",
		disconnect:"Contember_TranslationCatalogueUniqueWhere",
		delete:"Contember_TranslationCatalogueUniqueWhere"
	},
	Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput:{
		by:"Contember_TranslationCatalogueUniqueWhere",
		data:"Contember_TranslationCatalogueWithoutIdentifierUpdateInput"
	},
	Contember_TranslationCatalogueWithoutIdentifierUpdateInput:{
		domain:"Contember_TranslationCatalogueUpdateDomainEntityRelationInput",
		fallback:"Contember_TranslationCatalogueUpdateFallbackEntityRelationInput",
		values:"Contember_TranslationCatalogueUpdateValuesEntityRelationInput"
	},
	Contember_TranslationCatalogueUpdateDomainEntityRelationInput:{
		create:"Contember_TranslationDomainWithoutCataloguesCreateInput",
		update:"Contember_TranslationDomainWithoutCataloguesUpdateInput",
		upsert:"Contember_TranslationCatalogueUpsertDomainRelationInput",
		connect:"Contember_TranslationDomainUniqueWhere"
	},
	Contember_TranslationDomainWithoutCataloguesUpdateInput:{
		keys:"Contember_TranslationDomainUpdateKeysEntityRelationInput"
	},
	Contember_TranslationDomainUpdateKeysEntityRelationInput:{
		create:"Contember_TranslationKeyWithoutDomainCreateInput",
		update:"Contember_TranslationDomainUpdateKeysRelationInput",
		upsert:"Contember_TranslationDomainUpsertKeysRelationInput",
		connect:"Contember_TranslationKeyUniqueWhere",
		disconnect:"Contember_TranslationKeyUniqueWhere",
		delete:"Contember_TranslationKeyUniqueWhere"
	},
	Contember_TranslationDomainUpdateKeysRelationInput:{
		by:"Contember_TranslationKeyUniqueWhere",
		data:"Contember_TranslationKeyWithoutDomainUpdateInput"
	},
	Contember_TranslationKeyWithoutDomainUpdateInput:{
		contentType:"Contember_TranslationContentType",
		values:"Contember_TranslationKeyUpdateValuesEntityRelationInput"
	},
	Contember_TranslationKeyUpdateValuesEntityRelationInput:{
		create:"Contember_TranslationValueWithoutKeyCreateInput",
		update:"Contember_TranslationKeyUpdateValuesRelationInput",
		upsert:"Contember_TranslationKeyUpsertValuesRelationInput",
		connect:"Contember_TranslationValueUniqueWhere",
		disconnect:"Contember_TranslationValueUniqueWhere",
		delete:"Contember_TranslationValueUniqueWhere"
	},
	Contember_TranslationKeyUpdateValuesRelationInput:{
		by:"Contember_TranslationValueUniqueWhere",
		data:"Contember_TranslationValueWithoutKeyUpdateInput"
	},
	Contember_TranslationValueWithoutKeyUpdateInput:{
		catalogue:"Contember_TranslationValueUpdateCatalogueEntityRelationInput"
	},
	Contember_TranslationValueUpdateCatalogueEntityRelationInput:{
		create:"Contember_TranslationCatalogueWithoutValuesCreateInput",
		update:"Contember_TranslationCatalogueWithoutValuesUpdateInput",
		upsert:"Contember_TranslationValueUpsertCatalogueRelationInput",
		connect:"Contember_TranslationCatalogueUniqueWhere"
	},
	Contember_TranslationCatalogueWithoutValuesUpdateInput:{
		domain:"Contember_TranslationCatalogueUpdateDomainEntityRelationInput",
		fallback:"Contember_TranslationCatalogueUpdateFallbackEntityRelationInput",
		identifier:"Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"
	},
	Contember_TranslationCatalogueUpdateFallbackEntityRelationInput:{
		create:"Contember_TranslationCatalogueCreateInput",
		update:"Contember_TranslationCatalogueUpdateInput",
		upsert:"Contember_TranslationCatalogueUpsertFallbackRelationInput",
		connect:"Contember_TranslationCatalogueUniqueWhere"
	},
	Contember_TranslationCatalogueUpdateInput:{
		domain:"Contember_TranslationCatalogueUpdateDomainEntityRelationInput",
		fallback:"Contember_TranslationCatalogueUpdateFallbackEntityRelationInput",
		values:"Contember_TranslationCatalogueUpdateValuesEntityRelationInput",
		identifier:"Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"
	},
	Contember_TranslationCatalogueUpdateValuesEntityRelationInput:{
		create:"Contember_TranslationValueWithoutCatalogueCreateInput",
		update:"Contember_TranslationCatalogueUpdateValuesRelationInput",
		upsert:"Contember_TranslationCatalogueUpsertValuesRelationInput",
		connect:"Contember_TranslationValueUniqueWhere",
		disconnect:"Contember_TranslationValueUniqueWhere",
		delete:"Contember_TranslationValueUniqueWhere"
	},
	Contember_TranslationCatalogueUpdateValuesRelationInput:{
		by:"Contember_TranslationValueUniqueWhere",
		data:"Contember_TranslationValueWithoutCatalogueUpdateInput"
	},
	Contember_TranslationValueWithoutCatalogueUpdateInput:{
		key:"Contember_TranslationValueUpdateKeyEntityRelationInput"
	},
	Contember_TranslationValueUpdateKeyEntityRelationInput:{
		create:"Contember_TranslationKeyWithoutValuesCreateInput",
		update:"Contember_TranslationKeyWithoutValuesUpdateInput",
		upsert:"Contember_TranslationValueUpsertKeyRelationInput",
		connect:"Contember_TranslationKeyUniqueWhere"
	},
	Contember_TranslationKeyWithoutValuesUpdateInput:{
		contentType:"Contember_TranslationContentType",
		domain:"Contember_TranslationKeyUpdateDomainEntityRelationInput"
	},
	Contember_TranslationKeyUpdateDomainEntityRelationInput:{
		create:"Contember_TranslationDomainWithoutKeysCreateInput",
		update:"Contember_TranslationDomainWithoutKeysUpdateInput",
		upsert:"Contember_TranslationKeyUpsertDomainRelationInput",
		connect:"Contember_TranslationDomainUniqueWhere"
	},
	Contember_TranslationDomainWithoutKeysUpdateInput:{
		catalogues:"Contember_TranslationDomainUpdateCataloguesEntityRelationInput"
	},
	Contember_TranslationDomainUpdateCataloguesEntityRelationInput:{
		create:"Contember_TranslationCatalogueWithoutDomainCreateInput",
		update:"Contember_TranslationDomainUpdateCataloguesRelationInput",
		upsert:"Contember_TranslationDomainUpsertCataloguesRelationInput",
		connect:"Contember_TranslationCatalogueUniqueWhere",
		disconnect:"Contember_TranslationCatalogueUniqueWhere",
		delete:"Contember_TranslationCatalogueUniqueWhere"
	},
	Contember_TranslationDomainUpdateCataloguesRelationInput:{
		by:"Contember_TranslationCatalogueUniqueWhere",
		data:"Contember_TranslationCatalogueWithoutDomainUpdateInput"
	},
	Contember_TranslationCatalogueWithoutDomainUpdateInput:{
		fallback:"Contember_TranslationCatalogueUpdateFallbackEntityRelationInput",
		values:"Contember_TranslationCatalogueUpdateValuesEntityRelationInput",
		identifier:"Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"
	},
	Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput:{
		create:"Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput",
		update:"Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput",
		upsert:"Contember_TranslationCatalogueUpsertIdentifierRelationInput",
		connect:"Contember_TranslationCataloguesIdentifierUniqueWhere"
	},
	Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput:{

	},
	Contember_TranslationCatalogueUpsertIdentifierRelationInput:{
		update:"Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput",
		create:"Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"
	},
	Contember_TranslationDomainUpsertCataloguesRelationInput:{
		by:"Contember_TranslationCatalogueUniqueWhere",
		update:"Contember_TranslationCatalogueWithoutDomainUpdateInput",
		create:"Contember_TranslationCatalogueWithoutDomainCreateInput"
	},
	Contember_TranslationKeyUpsertDomainRelationInput:{
		update:"Contember_TranslationDomainWithoutKeysUpdateInput",
		create:"Contember_TranslationDomainWithoutKeysCreateInput"
	},
	Contember_TranslationValueUpsertKeyRelationInput:{
		update:"Contember_TranslationKeyWithoutValuesUpdateInput",
		create:"Contember_TranslationKeyWithoutValuesCreateInput"
	},
	Contember_TranslationCatalogueUpsertValuesRelationInput:{
		by:"Contember_TranslationValueUniqueWhere",
		update:"Contember_TranslationValueWithoutCatalogueUpdateInput",
		create:"Contember_TranslationValueWithoutCatalogueCreateInput"
	},
	Contember_TranslationCatalogueUpsertFallbackRelationInput:{
		update:"Contember_TranslationCatalogueUpdateInput",
		create:"Contember_TranslationCatalogueCreateInput"
	},
	Contember_TranslationValueUpsertCatalogueRelationInput:{
		update:"Contember_TranslationCatalogueWithoutValuesUpdateInput",
		create:"Contember_TranslationCatalogueWithoutValuesCreateInput"
	},
	Contember_TranslationKeyUpsertValuesRelationInput:{
		by:"Contember_TranslationValueUniqueWhere",
		update:"Contember_TranslationValueWithoutKeyUpdateInput",
		create:"Contember_TranslationValueWithoutKeyCreateInput"
	},
	Contember_TranslationDomainUpsertKeysRelationInput:{
		by:"Contember_TranslationKeyUniqueWhere",
		update:"Contember_TranslationKeyWithoutDomainUpdateInput",
		create:"Contember_TranslationKeyWithoutDomainCreateInput"
	},
	Contember_TranslationCatalogueUpsertDomainRelationInput:{
		update:"Contember_TranslationDomainWithoutCataloguesUpdateInput",
		create:"Contember_TranslationDomainWithoutCataloguesCreateInput"
	},
	Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput:{
		by:"Contember_TranslationCatalogueUniqueWhere",
		update:"Contember_TranslationCatalogueWithoutIdentifierUpdateInput",
		create:"Contember_TranslationCatalogueWithoutIdentifierCreateInput"
	},
	Contember_LocaleUpsertIdentifierRelationInput:{
		update:"Contember_TranslationCataloguesIdentifierUpdateInput",
		create:"Contember_TranslationCataloguesIdentifierCreateInput"
	},
	Contember_LocaleUpdateMenusEntityRelationInput:{
		create:"Contember_MenuLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateMenusRelationInput",
		upsert:"Contember_LocaleUpsertMenusRelationInput",
		connect:"Contember_MenuLocaleUniqueWhere",
		disconnect:"Contember_MenuLocaleUniqueWhere",
		delete:"Contember_MenuLocaleUniqueWhere"
	},
	Contember_LocaleUpdateMenusRelationInput:{
		by:"Contember_MenuLocaleUniqueWhere",
		data:"Contember_MenuLocaleWithoutLocaleUpdateInput"
	},
	Contember_MenuLocaleWithoutLocaleUpdateInput:{
		root:"Contember_MenuLocaleUpdateRootEntityRelationInput",
		items:"Contember_MenuLocaleUpdateItemsEntityRelationInput",
		secondaryItems:"Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput",
		eshopLink:"Contember_MenuLocaleUpdateEshopLinkEntityRelationInput"
	},
	Contember_MenuLocaleUpdateRootEntityRelationInput:{
		create:"Contember_SiteMenuWithoutLocalesCreateInput",
		update:"Contember_SiteMenuWithoutLocalesUpdateInput",
		upsert:"Contember_MenuLocaleUpsertRootRelationInput",
		connect:"Contember_SiteMenuUniqueWhere"
	},
	Contember_SiteMenuWithoutLocalesUpdateInput:{
		unique:"Contember_One"
	},
	Contember_MenuLocaleUpsertRootRelationInput:{
		update:"Contember_SiteMenuWithoutLocalesUpdateInput",
		create:"Contember_SiteMenuWithoutLocalesCreateInput"
	},
	Contember_MenuLocaleUpdateItemsEntityRelationInput:{
		create:"Contember_MenuItemWithoutMenuCreateInput",
		update:"Contember_MenuLocaleUpdateItemsRelationInput",
		upsert:"Contember_MenuLocaleUpsertItemsRelationInput",
		connect:"Contember_MenuItemUniqueWhere",
		disconnect:"Contember_MenuItemUniqueWhere",
		delete:"Contember_MenuItemUniqueWhere"
	},
	Contember_MenuLocaleUpdateItemsRelationInput:{
		by:"Contember_MenuItemUniqueWhere",
		data:"Contember_MenuItemWithoutMenuUpdateInput"
	},
	Contember_MenuItemWithoutMenuUpdateInput:{
		link:"Contember_MenuItemUpdateLinkEntityRelationInput",
		subitems:"Contember_MenuItemUpdateSubitemsEntityRelationInput"
	},
	Contember_MenuItemUpdateLinkEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_MenuItemUpsertLinkRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_MenuItemUpsertLinkRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_MenuItemUpdateSubitemsEntityRelationInput:{
		create:"Contember_MenuSubitemWithoutItemCreateInput",
		update:"Contember_MenuItemUpdateSubitemsRelationInput",
		upsert:"Contember_MenuItemUpsertSubitemsRelationInput",
		connect:"Contember_MenuSubitemUniqueWhere",
		disconnect:"Contember_MenuSubitemUniqueWhere",
		delete:"Contember_MenuSubitemUniqueWhere"
	},
	Contember_MenuItemUpdateSubitemsRelationInput:{
		by:"Contember_MenuSubitemUniqueWhere",
		data:"Contember_MenuSubitemWithoutItemUpdateInput"
	},
	Contember_MenuSubitemWithoutItemUpdateInput:{
		type:"Contember_MenuSubitemType",
		links:"Contember_MenuSubitemUpdateLinksEntityRelationInput",
		products:"Contember_MenuSubitemUpdateProductsEntityRelationInput",
		delivery:"Contember_MenuSubitemUpdateDeliveryEntityRelationInput",
		contact:"Contember_MenuSubitemUpdateContactEntityRelationInput"
	},
	Contember_MenuSubitemUpdateLinksEntityRelationInput:{
		create:"Contember_MenuLinkListCreateInput",
		update:"Contember_MenuLinkListUpdateInput",
		upsert:"Contember_MenuSubitemUpsertLinksRelationInput",
		connect:"Contember_MenuLinkListUniqueWhere"
	},
	Contember_MenuLinkListUpdateInput:{
		items:"Contember_MenuLinkListUpdateItemsEntityRelationInput"
	},
	Contember_MenuLinkListUpdateItemsEntityRelationInput:{
		create:"Contember_MenuLinkItemWithoutListCreateInput",
		update:"Contember_MenuLinkListUpdateItemsRelationInput",
		upsert:"Contember_MenuLinkListUpsertItemsRelationInput",
		connect:"Contember_MenuLinkItemUniqueWhere",
		disconnect:"Contember_MenuLinkItemUniqueWhere",
		delete:"Contember_MenuLinkItemUniqueWhere"
	},
	Contember_MenuLinkListUpdateItemsRelationInput:{
		by:"Contember_MenuLinkItemUniqueWhere",
		data:"Contember_MenuLinkItemWithoutListUpdateInput"
	},
	Contember_MenuLinkItemWithoutListUpdateInput:{
		link:"Contember_MenuLinkItemUpdateLinkEntityRelationInput",
		image:"Contember_MenuLinkItemUpdateImageEntityRelationInput"
	},
	Contember_MenuLinkItemUpdateLinkEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_MenuLinkItemUpsertLinkRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_MenuLinkItemUpsertLinkRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_MenuLinkItemUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_MenuLinkItemUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_MenuLinkItemUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_MenuLinkListUpsertItemsRelationInput:{
		by:"Contember_MenuLinkItemUniqueWhere",
		update:"Contember_MenuLinkItemWithoutListUpdateInput",
		create:"Contember_MenuLinkItemWithoutListCreateInput"
	},
	Contember_MenuSubitemUpsertLinksRelationInput:{
		update:"Contember_MenuLinkListUpdateInput",
		create:"Contember_MenuLinkListCreateInput"
	},
	Contember_MenuSubitemUpdateProductsEntityRelationInput:{
		create:"Contember_MenuProductsCreateInput",
		update:"Contember_MenuProductsUpdateInput",
		upsert:"Contember_MenuSubitemUpsertProductsRelationInput",
		connect:"Contember_MenuProductsUniqueWhere"
	},
	Contember_MenuProductsUpdateInput:{
		links:"Contember_MenuProductsUpdateLinksEntityRelationInput",
		buttons:"Contember_MenuProductsUpdateButtonsEntityRelationInput"
	},
	Contember_MenuProductsUpdateLinksEntityRelationInput:{
		create:"Contember_MenuLinkListCreateInput",
		update:"Contember_MenuLinkListUpdateInput",
		upsert:"Contember_MenuProductsUpsertLinksRelationInput",
		connect:"Contember_MenuLinkListUniqueWhere"
	},
	Contember_MenuProductsUpsertLinksRelationInput:{
		update:"Contember_MenuLinkListUpdateInput",
		create:"Contember_MenuLinkListCreateInput"
	},
	Contember_MenuProductsUpdateButtonsEntityRelationInput:{
		create:"Contember_LinkListCreateInput",
		update:"Contember_LinkListUpdateInput",
		upsert:"Contember_MenuProductsUpsertButtonsRelationInput",
		connect:"Contember_LinkListUniqueWhere"
	},
	Contember_LinkListUpdateInput:{
		items:"Contember_LinkListUpdateItemsEntityRelationInput"
	},
	Contember_LinkListUpdateItemsEntityRelationInput:{
		create:"Contember_LinkListItemWithoutListCreateInput",
		update:"Contember_LinkListUpdateItemsRelationInput",
		upsert:"Contember_LinkListUpsertItemsRelationInput",
		connect:"Contember_LinkListItemUniqueWhere",
		disconnect:"Contember_LinkListItemUniqueWhere",
		delete:"Contember_LinkListItemUniqueWhere"
	},
	Contember_LinkListUpdateItemsRelationInput:{
		by:"Contember_LinkListItemUniqueWhere",
		data:"Contember_LinkListItemWithoutListUpdateInput"
	},
	Contember_LinkListItemWithoutListUpdateInput:{
		link:"Contember_LinkListItemUpdateLinkEntityRelationInput"
	},
	Contember_LinkListItemUpdateLinkEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_LinkListItemUpsertLinkRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_LinkListItemUpsertLinkRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_LinkListUpsertItemsRelationInput:{
		by:"Contember_LinkListItemUniqueWhere",
		update:"Contember_LinkListItemWithoutListUpdateInput",
		create:"Contember_LinkListItemWithoutListCreateInput"
	},
	Contember_MenuProductsUpsertButtonsRelationInput:{
		update:"Contember_LinkListUpdateInput",
		create:"Contember_LinkListCreateInput"
	},
	Contember_MenuSubitemUpsertProductsRelationInput:{
		update:"Contember_MenuProductsUpdateInput",
		create:"Contember_MenuProductsCreateInput"
	},
	Contember_MenuSubitemUpdateDeliveryEntityRelationInput:{
		create:"Contember_MenuDeliveryCreateInput",
		update:"Contember_MenuDeliveryUpdateInput",
		upsert:"Contember_MenuSubitemUpsertDeliveryRelationInput",
		connect:"Contember_MenuDeliveryUniqueWhere"
	},
	Contember_MenuDeliveryUpdateInput:{
		deliveryRegions:"Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput",
		socialsAndApps:"Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput"
	},
	Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput:{
		create:"Contember_DeliveryRegionsCreateInput",
		update:"Contember_DeliveryRegionsUpdateInput",
		upsert:"Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput",
		connect:"Contember_DeliveryRegionsUniqueWhere"
	},
	Contember_DeliveryRegionsUpdateInput:{
		items:"Contember_DeliveryRegionsUpdateItemsEntityRelationInput"
	},
	Contember_DeliveryRegionsUpdateItemsEntityRelationInput:{
		create:"Contember_DeliveryRegionsItemWithoutListCreateInput",
		update:"Contember_DeliveryRegionsUpdateItemsRelationInput",
		upsert:"Contember_DeliveryRegionsUpsertItemsRelationInput",
		connect:"Contember_DeliveryRegionsItemUniqueWhere",
		disconnect:"Contember_DeliveryRegionsItemUniqueWhere",
		delete:"Contember_DeliveryRegionsItemUniqueWhere"
	},
	Contember_DeliveryRegionsUpdateItemsRelationInput:{
		by:"Contember_DeliveryRegionsItemUniqueWhere",
		data:"Contember_DeliveryRegionsItemWithoutListUpdateInput"
	},
	Contember_DeliveryRegionsItemWithoutListUpdateInput:{
		image:"Contember_DeliveryRegionsItemUpdateImageEntityRelationInput",
		textList:"Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput",
		gallery:"Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput"
	},
	Contember_DeliveryRegionsItemUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_DeliveryRegionsItemUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_DeliveryRegionsItemUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput:{
		create:"Contember_TextListCreateInput",
		update:"Contember_TextListUpdateInput",
		upsert:"Contember_DeliveryRegionsItemUpsertTextListRelationInput",
		connect:"Contember_TextListUniqueWhere"
	},
	Contember_TextListUpdateInput:{
		items:"Contember_TextListUpdateItemsEntityRelationInput"
	},
	Contember_TextListUpdateItemsEntityRelationInput:{
		create:"Contember_TextItemWithoutListCreateInput",
		update:"Contember_TextListUpdateItemsRelationInput",
		upsert:"Contember_TextListUpsertItemsRelationInput",
		connect:"Contember_TextItemUniqueWhere",
		disconnect:"Contember_TextItemUniqueWhere",
		delete:"Contember_TextItemUniqueWhere"
	},
	Contember_TextListUpdateItemsRelationInput:{
		by:"Contember_TextItemUniqueWhere",
		data:"Contember_TextItemWithoutListUpdateInput"
	},
	Contember_TextItemWithoutListUpdateInput:{

	},
	Contember_TextListUpsertItemsRelationInput:{
		by:"Contember_TextItemUniqueWhere",
		update:"Contember_TextItemWithoutListUpdateInput",
		create:"Contember_TextItemWithoutListCreateInput"
	},
	Contember_DeliveryRegionsItemUpsertTextListRelationInput:{
		update:"Contember_TextListUpdateInput",
		create:"Contember_TextListCreateInput"
	},
	Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput:{
		create:"Contember_ImageListCreateInput",
		update:"Contember_ImageListUpdateInput",
		upsert:"Contember_DeliveryRegionsItemUpsertGalleryRelationInput",
		connect:"Contember_ImageListUniqueWhere"
	},
	Contember_ImageListUpdateInput:{
		items:"Contember_ImageListUpdateItemsEntityRelationInput"
	},
	Contember_ImageListUpdateItemsEntityRelationInput:{
		create:"Contember_ImageListItemWithoutListCreateInput",
		update:"Contember_ImageListUpdateItemsRelationInput",
		upsert:"Contember_ImageListUpsertItemsRelationInput",
		connect:"Contember_ImageListItemUniqueWhere",
		disconnect:"Contember_ImageListItemUniqueWhere",
		delete:"Contember_ImageListItemUniqueWhere"
	},
	Contember_ImageListUpdateItemsRelationInput:{
		by:"Contember_ImageListItemUniqueWhere",
		data:"Contember_ImageListItemWithoutListUpdateInput"
	},
	Contember_ImageListItemWithoutListUpdateInput:{
		image:"Contember_ImageListItemUpdateImageEntityRelationInput"
	},
	Contember_ImageListItemUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ImageListItemUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ImageListItemUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ImageListUpsertItemsRelationInput:{
		by:"Contember_ImageListItemUniqueWhere",
		update:"Contember_ImageListItemWithoutListUpdateInput",
		create:"Contember_ImageListItemWithoutListCreateInput"
	},
	Contember_DeliveryRegionsItemUpsertGalleryRelationInput:{
		update:"Contember_ImageListUpdateInput",
		create:"Contember_ImageListCreateInput"
	},
	Contember_DeliveryRegionsUpsertItemsRelationInput:{
		by:"Contember_DeliveryRegionsItemUniqueWhere",
		update:"Contember_DeliveryRegionsItemWithoutListUpdateInput",
		create:"Contember_DeliveryRegionsItemWithoutListCreateInput"
	},
	Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput:{
		update:"Contember_DeliveryRegionsUpdateInput",
		create:"Contember_DeliveryRegionsCreateInput"
	},
	Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput:{
		create:"Contember_SocialsAndAppsCreateInput",
		update:"Contember_SocialsAndAppsUpdateInput",
		upsert:"Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput",
		connect:"Contember_SocialsAndAppsUniqueWhere"
	},
	Contember_SocialsAndAppsUpdateInput:{

	},
	Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput:{
		update:"Contember_SocialsAndAppsUpdateInput",
		create:"Contember_SocialsAndAppsCreateInput"
	},
	Contember_MenuSubitemUpsertDeliveryRelationInput:{
		update:"Contember_MenuDeliveryUpdateInput",
		create:"Contember_MenuDeliveryCreateInput"
	},
	Contember_MenuSubitemUpdateContactEntityRelationInput:{
		create:"Contember_MenuContactCreateInput",
		update:"Contember_MenuContactUpdateInput",
		upsert:"Contember_MenuSubitemUpsertContactRelationInput",
		connect:"Contember_MenuContactUniqueWhere"
	},
	Contember_MenuContactUpdateInput:{
		image:"Contember_MenuContactUpdateImageEntityRelationInput"
	},
	Contember_MenuContactUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_MenuContactUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_MenuContactUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_MenuSubitemUpsertContactRelationInput:{
		update:"Contember_MenuContactUpdateInput",
		create:"Contember_MenuContactCreateInput"
	},
	Contember_MenuItemUpsertSubitemsRelationInput:{
		by:"Contember_MenuSubitemUniqueWhere",
		update:"Contember_MenuSubitemWithoutItemUpdateInput",
		create:"Contember_MenuSubitemWithoutItemCreateInput"
	},
	Contember_MenuLocaleUpsertItemsRelationInput:{
		by:"Contember_MenuItemUniqueWhere",
		update:"Contember_MenuItemWithoutMenuUpdateInput",
		create:"Contember_MenuItemWithoutMenuCreateInput"
	},
	Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput:{
		create:"Contember_LinkListCreateInput",
		update:"Contember_LinkListUpdateInput",
		upsert:"Contember_MenuLocaleUpsertSecondaryItemsRelationInput",
		connect:"Contember_LinkListUniqueWhere"
	},
	Contember_MenuLocaleUpsertSecondaryItemsRelationInput:{
		update:"Contember_LinkListUpdateInput",
		create:"Contember_LinkListCreateInput"
	},
	Contember_MenuLocaleUpdateEshopLinkEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_MenuLocaleUpsertEshopLinkRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_MenuLocaleUpsertEshopLinkRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_LocaleUpsertMenusRelationInput:{
		by:"Contember_MenuLocaleUniqueWhere",
		update:"Contember_MenuLocaleWithoutLocaleUpdateInput",
		create:"Contember_MenuLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdatePagesEntityRelationInput:{
		create:"Contember_PageLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdatePagesRelationInput",
		upsert:"Contember_LocaleUpsertPagesRelationInput",
		connect:"Contember_PageLocaleUniqueWhere",
		disconnect:"Contember_PageLocaleUniqueWhere",
		delete:"Contember_PageLocaleUniqueWhere"
	},
	Contember_LocaleUpdatePagesRelationInput:{
		by:"Contember_PageLocaleUniqueWhere",
		data:"Contember_PageLocaleWithoutLocaleUpdateInput"
	},
	Contember_PageLocaleWithoutLocaleUpdateInput:{
		link:"Contember_PageLocaleUpdateLinkEntityRelationInput",
		root:"Contember_PageLocaleUpdateRootEntityRelationInput",
		seo:"Contember_PageLocaleUpdateSeoEntityRelationInput",
		content:"Contember_PageLocaleUpdateContentEntityRelationInput",
		cover:"Contember_PageLocaleUpdateCoverEntityRelationInput",
		theme:"Contember_PageTheme"
	},
	Contember_PageLocaleUpdateLinkEntityRelationInput:{
		create:"Contember_LinkableWithoutPageCreateInput",
		update:"Contember_LinkableWithoutPageUpdateInput",
		upsert:"Contember_PageLocaleUpsertLinkRelationInput",
		connect:"Contember_LinkableUniqueWhere"
	},
	Contember_LinkableWithoutPageUpdateInput:{
		blogPost:"Contember_LinkableUpdateBlogPostEntityRelationInput",
		wikiPage:"Contember_LinkableUpdateWikiPageEntityRelationInput",
		redirect:"Contember_LinkableUpdateRedirectEntityRelationInput"
	},
	Contember_LinkableUpdateWikiPageEntityRelationInput:{
		create:"Contember_WikiPageWithoutLinkCreateInput",
		update:"Contember_WikiPageWithoutLinkUpdateInput",
		upsert:"Contember_LinkableUpsertWikiPageRelationInput",
		connect:"Contember_WikiPageUniqueWhere"
	},
	Contember_WikiPageWithoutLinkUpdateInput:{
		parent:"Contember_WikiPageUpdateParentEntityRelationInput",
		children:"Contember_WikiPageUpdateChildrenEntityRelationInput",
		content:"Contember_WikiPageUpdateContentEntityRelationInput"
	},
	Contember_WikiPageUpdateParentEntityRelationInput:{
		create:"Contember_WikiPageWithoutChildrenCreateInput",
		update:"Contember_WikiPageWithoutChildrenUpdateInput",
		upsert:"Contember_WikiPageUpsertParentRelationInput",
		connect:"Contember_WikiPageUniqueWhere"
	},
	Contember_WikiPageWithoutChildrenUpdateInput:{
		link:"Contember_WikiPageUpdateLinkEntityRelationInput",
		parent:"Contember_WikiPageUpdateParentEntityRelationInput",
		content:"Contember_WikiPageUpdateContentEntityRelationInput"
	},
	Contember_WikiPageUpdateLinkEntityRelationInput:{
		create:"Contember_LinkableWithoutWikiPageCreateInput",
		update:"Contember_LinkableWithoutWikiPageUpdateInput",
		upsert:"Contember_WikiPageUpsertLinkRelationInput",
		connect:"Contember_LinkableUniqueWhere"
	},
	Contember_LinkableWithoutWikiPageUpdateInput:{
		blogPost:"Contember_LinkableUpdateBlogPostEntityRelationInput",
		page:"Contember_LinkableUpdatePageEntityRelationInput",
		redirect:"Contember_LinkableUpdateRedirectEntityRelationInput"
	},
	Contember_LinkableUpdatePageEntityRelationInput:{
		create:"Contember_PageLocaleWithoutLinkCreateInput",
		update:"Contember_PageLocaleWithoutLinkUpdateInput",
		upsert:"Contember_LinkableUpsertPageRelationInput",
		connect:"Contember_PageLocaleUniqueWhere"
	},
	Contember_PageLocaleWithoutLinkUpdateInput:{
		root:"Contember_PageLocaleUpdateRootEntityRelationInput",
		locale:"Contember_PageLocaleUpdateLocaleEntityRelationInput",
		seo:"Contember_PageLocaleUpdateSeoEntityRelationInput",
		content:"Contember_PageLocaleUpdateContentEntityRelationInput",
		cover:"Contember_PageLocaleUpdateCoverEntityRelationInput",
		theme:"Contember_PageTheme"
	},
	Contember_PageLocaleUpdateRootEntityRelationInput:{
		create:"Contember_PageWithoutLocalesCreateInput",
		update:"Contember_PageWithoutLocalesUpdateInput",
		upsert:"Contember_PageLocaleUpsertRootRelationInput",
		connect:"Contember_PageUniqueWhere"
	},
	Contember_PageWithoutLocalesUpdateInput:{

	},
	Contember_PageLocaleUpsertRootRelationInput:{
		update:"Contember_PageWithoutLocalesUpdateInput",
		create:"Contember_PageWithoutLocalesCreateInput"
	},
	Contember_PageLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutPagesCreateInput",
		update:"Contember_LocaleWithoutPagesUpdateInput",
		upsert:"Contember_PageLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutPagesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput:{
		create:"Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateProductIngredientCategoriesRelationInput",
		upsert:"Contember_LocaleUpsertProductIngredientCategoriesRelationInput",
		connect:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		disconnect:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		delete:"Contember_ProductIngredientCategoryLocaleUniqueWhere"
	},
	Contember_LocaleUpdateProductIngredientCategoriesRelationInput:{
		by:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		data:"Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"
	},
	Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput:{
		root:"Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput"
	},
	Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput:{
		create:"Contember_ProductIngredientCategoryWithoutLocalesCreateInput",
		update:"Contember_ProductIngredientCategoryWithoutLocalesUpdateInput",
		upsert:"Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput",
		connect:"Contember_ProductIngredientCategoryUniqueWhere"
	},
	Contember_ProductIngredientCategoryWithoutLocalesUpdateInput:{
		ingredients:"Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput"
	},
	Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput:{
		create:"Contember_ProductIngredientWithoutCategoriesCreateInput",
		update:"Contember_ProductIngredientCategoryUpdateIngredientsRelationInput",
		upsert:"Contember_ProductIngredientCategoryUpsertIngredientsRelationInput",
		connect:"Contember_ProductIngredientUniqueWhere",
		disconnect:"Contember_ProductIngredientUniqueWhere",
		delete:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_ProductIngredientCategoryUpdateIngredientsRelationInput:{
		by:"Contember_ProductIngredientUniqueWhere",
		data:"Contember_ProductIngredientWithoutCategoriesUpdateInput"
	},
	Contember_ProductIngredientWithoutCategoriesUpdateInput:{
		locales:"Contember_ProductIngredientUpdateLocalesEntityRelationInput",
		allergens:"Contember_ProductIngredientUpdateAllergensEntityRelationInput",
		customerRatings:"Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientUpdateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientUpdateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput:{
		create:"Contember_CustomerIngredientRatingWithoutIngredientCreateInput",
		update:"Contember_ProductIngredientUpdateCustomerRatingsRelationInput",
		upsert:"Contember_ProductIngredientUpsertCustomerRatingsRelationInput",
		connect:"Contember_CustomerIngredientRatingUniqueWhere",
		disconnect:"Contember_CustomerIngredientRatingUniqueWhere",
		delete:"Contember_CustomerIngredientRatingUniqueWhere"
	},
	Contember_ProductIngredientUpdateCustomerRatingsRelationInput:{
		by:"Contember_CustomerIngredientRatingUniqueWhere",
		data:"Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"
	},
	Contember_CustomerIngredientRatingWithoutIngredientUpdateInput:{
		customer:"Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput",
		rating:"Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"
	},
	Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutIngredientRatingsCreateInput",
		update:"Contember_CustomerWithoutIngredientRatingsUpdateInput",
		upsert:"Contember_CustomerIngredientRatingUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutIngredientRatingsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateBusinessEntityRelationInput:{
		create:"Contember_BusinessCustomerWithoutCustomerCreateInput",
		update:"Contember_BusinessCustomerWithoutCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertBusinessRelationInput",
		connect:"Contember_BusinessCustomerUniqueWhere"
	},
	Contember_BusinessCustomerWithoutCustomerUpdateInput:{
		owners:"Contember_BusinessCustomerUpdateOwnersEntityRelationInput",
		members:"Contember_BusinessCustomerUpdateMembersEntityRelationInput",
		category:"Contember_BusinessCustomerUpdateCategoryEntityRelationInput",
		accountManager:"Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerUpdateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerUpdateOwnersEntityRelationInput:{
		create:"Contember_CustomerWithoutOwnerOfBusinessesCreateInput",
		update:"Contember_BusinessCustomerUpdateOwnersRelationInput",
		upsert:"Contember_BusinessCustomerUpsertOwnersRelationInput",
		connect:"Contember_CustomerUniqueWhere",
		disconnect:"Contember_CustomerUniqueWhere",
		delete:"Contember_CustomerUniqueWhere"
	},
	Contember_BusinessCustomerUpdateOwnersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		data:"Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"
	},
	Contember_CustomerWithoutOwnerOfBusinessesUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput:{
		create:"Contember_BusinessCustomerWithoutMembersCreateInput",
		update:"Contember_CustomerUpdateMemberOfBusinessesRelationInput",
		upsert:"Contember_CustomerUpsertMemberOfBusinessesRelationInput",
		connect:"Contember_BusinessCustomerUniqueWhere",
		disconnect:"Contember_BusinessCustomerUniqueWhere",
		delete:"Contember_BusinessCustomerUniqueWhere"
	},
	Contember_CustomerUpdateMemberOfBusinessesRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		data:"Contember_BusinessCustomerWithoutMembersUpdateInput"
	},
	Contember_BusinessCustomerWithoutMembersUpdateInput:{
		customer:"Contember_BusinessCustomerUpdateCustomerEntityRelationInput",
		owners:"Contember_BusinessCustomerUpdateOwnersEntityRelationInput",
		category:"Contember_BusinessCustomerUpdateCategoryEntityRelationInput",
		accountManager:"Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerUpdateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerUpdateCategoryEntityRelationInput:{
		create:"Contember_BusinessCategoryWithoutBusinessCustomersCreateInput",
		update:"Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput",
		upsert:"Contember_BusinessCustomerUpsertCategoryRelationInput",
		connect:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput:{
		locales:"Contember_BusinessCategoryUpdateLocalesEntityRelationInput",
		recurrences:"Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput:{
		create:"Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput",
		update:"Contember_BusinessCategoryUpdateRecurrencesRelationInput",
		upsert:"Contember_BusinessCategoryUpsertRecurrencesRelationInput",
		connect:"Contember_OrderRecurrenceUniqueWhere",
		disconnect:"Contember_OrderRecurrenceUniqueWhere",
		delete:"Contember_OrderRecurrenceUniqueWhere"
	},
	Contember_BusinessCategoryUpdateRecurrencesRelationInput:{
		by:"Contember_OrderRecurrenceUniqueWhere",
		data:"Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"
	},
	Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput:{
		locales:"Contember_OrderRecurrenceUpdateLocalesEntityRelationInput"
	},
	Contember_OrderRecurrenceUpdateLocalesEntityRelationInput:{
		create:"Contember_OrderRecurrenceLocaleWithoutRootCreateInput",
		update:"Contember_OrderRecurrenceUpdateLocalesRelationInput",
		upsert:"Contember_OrderRecurrenceUpsertLocalesRelationInput",
		connect:"Contember_OrderRecurrenceLocaleUniqueWhere",
		disconnect:"Contember_OrderRecurrenceLocaleUniqueWhere",
		delete:"Contember_OrderRecurrenceLocaleUniqueWhere"
	},
	Contember_OrderRecurrenceUpdateLocalesRelationInput:{
		by:"Contember_OrderRecurrenceLocaleUniqueWhere",
		data:"Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"
	},
	Contember_OrderRecurrenceLocaleWithoutRootUpdateInput:{
		locale:"Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutOrderRecurrencesCreateInput",
		update:"Contember_LocaleWithoutOrderRecurrencesUpdateInput",
		upsert:"Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutOrderRecurrencesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateAllergensEntityRelationInput:{
		create:"Contember_AllergenLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateAllergensRelationInput",
		upsert:"Contember_LocaleUpsertAllergensRelationInput",
		connect:"Contember_AllergenLocaleUniqueWhere",
		disconnect:"Contember_AllergenLocaleUniqueWhere",
		delete:"Contember_AllergenLocaleUniqueWhere"
	},
	Contember_LocaleUpdateAllergensRelationInput:{
		by:"Contember_AllergenLocaleUniqueWhere",
		data:"Contember_AllergenLocaleWithoutLocaleUpdateInput"
	},
	Contember_AllergenLocaleWithoutLocaleUpdateInput:{
		root:"Contember_AllergenLocaleUpdateRootEntityRelationInput"
	},
	Contember_AllergenLocaleUpdateRootEntityRelationInput:{
		create:"Contember_AllergenWithoutLocalesCreateInput",
		update:"Contember_AllergenWithoutLocalesUpdateInput",
		upsert:"Contember_AllergenLocaleUpsertRootRelationInput",
		connect:"Contember_AllergenUniqueWhere"
	},
	Contember_AllergenWithoutLocalesUpdateInput:{
		ingredients:"Contember_AllergenUpdateIngredientsEntityRelationInput"
	},
	Contember_AllergenUpdateIngredientsEntityRelationInput:{
		create:"Contember_ProductIngredientWithoutAllergensCreateInput",
		update:"Contember_AllergenUpdateIngredientsRelationInput",
		upsert:"Contember_AllergenUpsertIngredientsRelationInput",
		connect:"Contember_ProductIngredientUniqueWhere",
		disconnect:"Contember_ProductIngredientUniqueWhere",
		delete:"Contember_ProductIngredientUniqueWhere"
	},
	Contember_AllergenUpdateIngredientsRelationInput:{
		by:"Contember_ProductIngredientUniqueWhere",
		data:"Contember_ProductIngredientWithoutAllergensUpdateInput"
	},
	Contember_ProductIngredientWithoutAllergensUpdateInput:{
		locales:"Contember_ProductIngredientUpdateLocalesEntityRelationInput",
		categories:"Contember_ProductIngredientUpdateCategoriesEntityRelationInput",
		customerRatings:"Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput",
		preferredQuantityUnit:"Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput",
		freshingContainer:"Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput",
		image:"Contember_ProductIngredientUpdateImageEntityRelationInput",
		suppliers:"Contember_ProductIngredientUpdateSuppliersEntityRelationInput"
	},
	Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput:{
		create:"Contember_ProductIngredientItemQuantityUnitCreateInput",
		update:"Contember_ProductIngredientItemQuantityUnitUpdateInput",
		upsert:"Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput",
		connect:"Contember_ProductIngredientItemQuantityUnitUniqueWhere"
	},
	Contember_ProductIngredientItemQuantityUnitUpdateInput:{

	},
	Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput:{
		update:"Contember_ProductIngredientItemQuantityUnitUpdateInput",
		create:"Contember_ProductIngredientItemQuantityUnitCreateInput"
	},
	Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput:{
		create:"Contember_FreshingContainerCreateInput",
		update:"Contember_FreshingContainerUpdateInput",
		upsert:"Contember_ProductIngredientUpsertFreshingContainerRelationInput",
		connect:"Contember_FreshingContainerUniqueWhere"
	},
	Contember_FreshingContainerUpdateInput:{
		workspace:"Contember_FreshingContainerUpdateWorkspaceEntityRelationInput"
	},
	Contember_FreshingContainerUpdateWorkspaceEntityRelationInput:{
		create:"Contember_WorkspaceWithoutFreshingContainersCreateInput",
		update:"Contember_WorkspaceWithoutFreshingContainersUpdateInput",
		upsert:"Contember_FreshingContainerUpsertWorkspaceRelationInput",
		connect:"Contember_WorkspaceUniqueWhere"
	},
	Contember_WorkspaceWithoutFreshingContainersUpdateInput:{
		unique:"Contember_One",
		openingHours:"Contember_WorkspaceUpdateOpeningHoursEntityRelationInput",
		workingHours:"Contember_WorkspaceUpdateWorkingHoursEntityRelationInput",
		defaultFreshingContainer:"Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput",
		creditsProduct:"Contember_WorkspaceUpdateCreditsProductEntityRelationInput",
		discountVatRate:"Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput"
	},
	Contember_WorkspaceUpdateOpeningHoursEntityRelationInput:{
		create:"Contember_WeekHoursCreateInput",
		update:"Contember_WeekHoursUpdateInput",
		upsert:"Contember_WorkspaceUpsertOpeningHoursRelationInput",
		connect:"Contember_WeekHoursUniqueWhere"
	},
	Contember_WeekHoursUpdateInput:{

	},
	Contember_WorkspaceUpsertOpeningHoursRelationInput:{
		update:"Contember_WeekHoursUpdateInput",
		create:"Contember_WeekHoursCreateInput"
	},
	Contember_WorkspaceUpdateWorkingHoursEntityRelationInput:{
		create:"Contember_WeekHoursCreateInput",
		update:"Contember_WeekHoursUpdateInput",
		upsert:"Contember_WorkspaceUpsertWorkingHoursRelationInput",
		connect:"Contember_WeekHoursUniqueWhere"
	},
	Contember_WorkspaceUpsertWorkingHoursRelationInput:{
		update:"Contember_WeekHoursUpdateInput",
		create:"Contember_WeekHoursCreateInput"
	},
	Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput:{
		create:"Contember_FreshingContainerCreateInput",
		update:"Contember_FreshingContainerUpdateInput",
		upsert:"Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput",
		connect:"Contember_FreshingContainerUniqueWhere"
	},
	Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput:{
		update:"Contember_FreshingContainerUpdateInput",
		create:"Contember_FreshingContainerCreateInput"
	},
	Contember_WorkspaceUpdateCreditsProductEntityRelationInput:{
		create:"Contember_VirtualProductCreateInput",
		update:"Contember_VirtualProductUpdateInput",
		upsert:"Contember_WorkspaceUpsertCreditsProductRelationInput",
		connect:"Contember_VirtualProductUniqueWhere"
	},
	Contember_VirtualProductUpdateInput:{
		type:"Contember_VirtualProductType",
		product:"Contember_VirtualProductUpdateProductEntityRelationInput",
		physicalRepresentation:"Contember_VirtualProductPhysicalRepresentationType"
	},
	Contember_VirtualProductUpdateProductEntityRelationInput:{
		create:"Contember_ProductWithoutVirtualProductCreateInput",
		update:"Contember_ProductWithoutVirtualProductUpdateInput",
		upsert:"Contember_VirtualProductUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductWithoutVirtualProductUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateManagerEntityRelationInput:{
		create:"Contember_StocksManagerWithoutManagedProductsCreateInput",
		update:"Contember_StocksManagerWithoutManagedProductsUpdateInput",
		upsert:"Contember_ProductUpsertManagerRelationInput",
		connect:"Contember_StocksManagerUniqueWhere"
	},
	Contember_StocksManagerWithoutManagedProductsUpdateInput:{
		staff:"Contember_StocksManagerUpdateStaffEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_StocksManagerUpdateStaffEntityRelationInput:{
		create:"Contember_StaffWithoutStocksManagerCreateInput",
		update:"Contember_StaffWithoutStocksManagerUpdateInput",
		upsert:"Contember_StocksManagerUpsertStaffRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_StaffWithoutStocksManagerUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		courier:"Contember_StaffUpdateCourierEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffUpdateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffUpdateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffUpdateShiftsProfileEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		venues:"Contember_StaffUpdateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_StaffUpdateBartenderEntityRelationInput:{
		create:"Contember_BartenderWithoutStaffCreateInput",
		update:"Contember_BartenderWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertBartenderRelationInput",
		connect:"Contember_BartenderUniqueWhere"
	},
	Contember_BartenderWithoutStaffUpdateInput:{
		createdAt:"DateTime"
	},
	Contember_StaffUpsertBartenderRelationInput:{
		update:"Contember_BartenderWithoutStaffUpdateInput",
		create:"Contember_BartenderWithoutStaffCreateInput"
	},
	Contember_StaffUpdateCourierEntityRelationInput:{
		create:"Contember_CourierWithoutStaffCreateInput",
		update:"Contember_CourierWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertCourierRelationInput",
		connect:"Contember_CourierUniqueWhere"
	},
	Contember_CourierWithoutStaffUpdateInput:{
		routes:"Contember_CourierUpdateRoutesEntityRelationInput",
		createdAt:"DateTime",
		deliveryMethods:"Contember_CourierUpdateDeliveryMethodsEntityRelationInput"
	},
	Contember_CourierUpdateRoutesEntityRelationInput:{
		create:"Contember_DeliveryRouteWithoutCourierCreateInput",
		update:"Contember_CourierUpdateRoutesRelationInput",
		upsert:"Contember_CourierUpsertRoutesRelationInput",
		connect:"Contember_DeliveryRouteUniqueWhere",
		disconnect:"Contember_DeliveryRouteUniqueWhere",
		delete:"Contember_DeliveryRouteUniqueWhere"
	},
	Contember_CourierUpdateRoutesRelationInput:{
		by:"Contember_DeliveryRouteUniqueWhere",
		data:"Contember_DeliveryRouteWithoutCourierUpdateInput"
	},
	Contember_DeliveryRouteWithoutCourierUpdateInput:{
		createdAt:"DateTime",
		waypoints:"Contember_DeliveryRouteUpdateWaypointsEntityRelationInput"
	},
	Contember_DeliveryRouteUpdateWaypointsEntityRelationInput:{
		create:"Contember_DeliveryWaypointWithoutRouteCreateInput",
		update:"Contember_DeliveryRouteUpdateWaypointsRelationInput",
		upsert:"Contember_DeliveryRouteUpsertWaypointsRelationInput",
		connect:"Contember_DeliveryWaypointUniqueWhere",
		disconnect:"Contember_DeliveryWaypointUniqueWhere",
		delete:"Contember_DeliveryWaypointUniqueWhere"
	},
	Contember_DeliveryRouteUpdateWaypointsRelationInput:{
		by:"Contember_DeliveryWaypointUniqueWhere",
		data:"Contember_DeliveryWaypointWithoutRouteUpdateInput"
	},
	Contember_DeliveryWaypointWithoutRouteUpdateInput:{
		estimatedArrival:"DateTime",
		delivery:"Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput",
		originDeparture:"DateTime"
	},
	Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput:{
		create:"Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput",
		update:"Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput",
		upsert:"Contember_DeliveryWaypointUpsertDeliveryRelationInput",
		connect:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput:{
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryUpdateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryUpdateVatRateEntityRelationInput",
		order:"Contember_OrderDeliveryUpdateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		events:"Contember_OrderDeliveryUpdateEventsEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime",
		address:"Contember_OrderDeliveryUpdateAddressEntityRelationInput"
	},
	Contember_OrderDeliveryUpdateMethodEntityRelationInput:{
		create:"Contember_DeliveryMethodCreateInput",
		update:"Contember_DeliveryMethodUpdateInput",
		upsert:"Contember_OrderDeliveryUpsertMethodRelationInput",
		connect:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_DeliveryMethodUpdateInput:{
		paymentMethods:"Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodUpdateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput",
		locales:"Contember_DeliveryMethodUpdateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput:{
		create:"Contember_CourierWithoutDeliveryMethodsCreateInput",
		update:"Contember_DeliveryMethodUpdateCourierHandlersRelationInput",
		upsert:"Contember_DeliveryMethodUpsertCourierHandlersRelationInput",
		connect:"Contember_CourierUniqueWhere",
		disconnect:"Contember_CourierUniqueWhere",
		delete:"Contember_CourierUniqueWhere"
	},
	Contember_DeliveryMethodUpdateCourierHandlersRelationInput:{
		by:"Contember_CourierUniqueWhere",
		data:"Contember_CourierWithoutDeliveryMethodsUpdateInput"
	},
	Contember_CourierWithoutDeliveryMethodsUpdateInput:{
		staff:"Contember_CourierUpdateStaffEntityRelationInput",
		routes:"Contember_CourierUpdateRoutesEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_CourierUpdateStaffEntityRelationInput:{
		create:"Contember_StaffWithoutCourierCreateInput",
		update:"Contember_StaffWithoutCourierUpdateInput",
		upsert:"Contember_CourierUpsertStaffRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_StaffWithoutCourierUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffUpdateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffUpdateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffUpdateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffUpdateStocksManagerEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		venues:"Contember_StaffUpdateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_StaffUpdateCleanerEntityRelationInput:{
		create:"Contember_CleanerWithoutStaffCreateInput",
		update:"Contember_CleanerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertCleanerRelationInput",
		connect:"Contember_CleanerUniqueWhere"
	},
	Contember_CleanerWithoutStaffUpdateInput:{
		createdAt:"DateTime"
	},
	Contember_StaffUpsertCleanerRelationInput:{
		update:"Contember_CleanerWithoutStaffUpdateInput",
		create:"Contember_CleanerWithoutStaffCreateInput"
	},
	Contember_StaffUpdateShiftsManagerEntityRelationInput:{
		create:"Contember_ShiftsManagerWithoutStaffCreateInput",
		update:"Contember_ShiftsManagerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertShiftsManagerRelationInput",
		connect:"Contember_ShiftsManagerUniqueWhere"
	},
	Contember_ShiftsManagerWithoutStaffUpdateInput:{
		managedRoles:"Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput:{
		create:"Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput",
		update:"Contember_ShiftsManagerUpdateManagedRolesRelationInput",
		upsert:"Contember_ShiftsManagerUpsertManagedRolesRelationInput",
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		disconnect:"Contember_TimetableEmployeeRoleUniqueWhere",
		delete:"Contember_TimetableEmployeeRoleUniqueWhere"
	},
	Contember_ShiftsManagerUpdateManagedRolesRelationInput:{
		by:"Contember_TimetableEmployeeRoleUniqueWhere",
		data:"Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"
	},
	Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput:{
		shiftGroups:"Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput",
		shiftsProfiles:"Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput:{
		create:"Contember_TimetableShiftGroupWithoutTypeCreateInput",
		update:"Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput",
		upsert:"Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput",
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		disconnect:"Contember_TimetableShiftGroupUniqueWhere",
		delete:"Contember_TimetableShiftGroupUniqueWhere"
	},
	Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput:{
		by:"Contember_TimetableShiftGroupUniqueWhere",
		data:"Contember_TimetableShiftGroupWithoutTypeUpdateInput"
	},
	Contember_TimetableShiftGroupWithoutTypeUpdateInput:{
		shifts:"Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput",
		day:"Contember_TimetableShiftGroupUpdateDayEntityRelationInput",
		position:"Contember_TimetableShiftGroupUpdatePositionEntityRelationInput",
		queue:"Contember_TimetableShiftGroupUpdateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput:{
		create:"Contember_TimetableShiftWithoutGroupCreateInput",
		update:"Contember_TimetableShiftGroupUpdateShiftsRelationInput",
		upsert:"Contember_TimetableShiftGroupUpsertShiftsRelationInput",
		connect:"Contember_TimetableShiftUniqueWhere",
		disconnect:"Contember_TimetableShiftUniqueWhere",
		delete:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_TimetableShiftGroupUpdateShiftsRelationInput:{
		by:"Contember_TimetableShiftUniqueWhere",
		data:"Contember_TimetableShiftWithoutGroupUpdateInput"
	},
	Contember_TimetableShiftWithoutGroupUpdateInput:{
		assigned:"Date",
		shiftsProfile:"Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput",
		queueItem:"Contember_TimetableShiftUpdateQueueItemEntityRelationInput",
		swapInfo:"Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"
	},
	Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput:{
		create:"Contember_ShiftsProfileWithoutShiftsCreateInput",
		update:"Contember_ShiftsProfileWithoutShiftsUpdateInput",
		upsert:"Contember_TimetableShiftUpsertShiftsProfileRelationInput",
		connect:"Contember_ShiftsProfileUniqueWhere"
	},
	Contember_ShiftsProfileWithoutShiftsUpdateInput:{
		queueItems:"Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput",
		staff:"Contember_ShiftsProfileUpdateStaffEntityRelationInput",
		roles:"Contember_ShiftsProfileUpdateRolesEntityRelationInput"
	},
	Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput:{
		create:"Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput",
		update:"Contember_ShiftsProfileUpdateQueueItemsRelationInput",
		upsert:"Contember_ShiftsProfileUpsertQueueItemsRelationInput",
		connect:"Contember_EmployeeQueueItemUniqueWhere",
		disconnect:"Contember_EmployeeQueueItemUniqueWhere",
		delete:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_ShiftsProfileUpdateQueueItemsRelationInput:{
		by:"Contember_EmployeeQueueItemUniqueWhere",
		data:"Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"
	},
	Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput:{
		queue:"Contember_EmployeeQueueItemUpdateQueueEntityRelationInput",
		shift:"Contember_EmployeeQueueItemUpdateShiftEntityRelationInput",
		unassignInfo:"Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput",
		meta:"Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"
	},
	Contember_EmployeeQueueItemUpdateQueueEntityRelationInput:{
		create:"Contember_EmployeeQueueWithoutItemsCreateInput",
		update:"Contember_EmployeeQueueWithoutItemsUpdateInput",
		upsert:"Contember_EmployeeQueueItemUpsertQueueRelationInput",
		connect:"Contember_EmployeeQueueUniqueWhere"
	},
	Contember_EmployeeQueueWithoutItemsUpdateInput:{
		shiftGroup:"Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput"
	},
	Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput:{
		create:"Contember_TimetableShiftGroupWithoutQueueCreateInput",
		update:"Contember_TimetableShiftGroupWithoutQueueUpdateInput",
		upsert:"Contember_EmployeeQueueUpsertShiftGroupRelationInput",
		connect:"Contember_TimetableShiftGroupUniqueWhere"
	},
	Contember_TimetableShiftGroupWithoutQueueUpdateInput:{
		shifts:"Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput",
		day:"Contember_TimetableShiftGroupUpdateDayEntityRelationInput",
		type:"Contember_TimetableShiftGroupUpdateTypeEntityRelationInput",
		position:"Contember_TimetableShiftGroupUpdatePositionEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupUpdateDayEntityRelationInput:{
		create:"Contember_TimetableDayWithoutShiftGroupsCreateInput",
		update:"Contember_TimetableDayWithoutShiftGroupsUpdateInput",
		upsert:"Contember_TimetableShiftGroupUpsertDayRelationInput",
		connect:"Contember_TimetableDayUniqueWhere"
	},
	Contember_TimetableDayWithoutShiftGroupsUpdateInput:{
		date:"Date",
		type:"Contember_TimetableDayType",
		notes:"Contember_TimetableDayUpdateNotesEntityRelationInput",
		venue:"Contember_TimetableDayUpdateVenueEntityRelationInput",
		meta:"Contember_TimetableDayUpdateMetaEntityRelationInput"
	},
	Contember_TimetableDayUpdateNotesEntityRelationInput:{
		create:"Contember_TimetableDayNoteWithoutDayCreateInput",
		update:"Contember_TimetableDayUpdateNotesRelationInput",
		upsert:"Contember_TimetableDayUpsertNotesRelationInput",
		connect:"Contember_TimetableDayNoteUniqueWhere",
		disconnect:"Contember_TimetableDayNoteUniqueWhere",
		delete:"Contember_TimetableDayNoteUniqueWhere"
	},
	Contember_TimetableDayUpdateNotesRelationInput:{
		by:"Contember_TimetableDayNoteUniqueWhere",
		data:"Contember_TimetableDayNoteWithoutDayUpdateInput"
	},
	Contember_TimetableDayNoteWithoutDayUpdateInput:{
		role:"Contember_TimetableDayNoteUpdateRoleEntityRelationInput"
	},
	Contember_TimetableDayNoteUpdateRoleEntityRelationInput:{
		create:"Contember_TimetableEmployeeRoleCreateInput",
		update:"Contember_TimetableEmployeeRoleUpdateInput",
		upsert:"Contember_TimetableDayNoteUpsertRoleRelationInput",
		connect:"Contember_TimetableEmployeeRoleUniqueWhere"
	},
	Contember_TimetableEmployeeRoleUpdateInput:{
		shiftGroups:"Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput",
		shiftsProfiles:"Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput:{
		create:"Contember_ShiftsProfileWithoutRolesCreateInput",
		update:"Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput",
		upsert:"Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput",
		connect:"Contember_ShiftsProfileUniqueWhere",
		disconnect:"Contember_ShiftsProfileUniqueWhere",
		delete:"Contember_ShiftsProfileUniqueWhere"
	},
	Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput:{
		by:"Contember_ShiftsProfileUniqueWhere",
		data:"Contember_ShiftsProfileWithoutRolesUpdateInput"
	},
	Contember_ShiftsProfileWithoutRolesUpdateInput:{
		queueItems:"Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput",
		staff:"Contember_ShiftsProfileUpdateStaffEntityRelationInput",
		shifts:"Contember_ShiftsProfileUpdateShiftsEntityRelationInput"
	},
	Contember_ShiftsProfileUpdateStaffEntityRelationInput:{
		create:"Contember_StaffWithoutShiftsProfileCreateInput",
		update:"Contember_StaffWithoutShiftsProfileUpdateInput",
		upsert:"Contember_ShiftsProfileUpsertStaffRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_StaffWithoutShiftsProfileUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		courier:"Contember_StaffUpdateCourierEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffUpdateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffUpdateUserEntityRelationInput",
		stocksManager:"Contember_StaffUpdateStocksManagerEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		venues:"Contember_StaffUpdateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput:{
		create:"Contember_BusinessToBusinessManagerWithoutStaffCreateInput",
		update:"Contember_BusinessToBusinessManagerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertBusinessToBusinessManagerRelationInput",
		connect:"Contember_BusinessToBusinessManagerUniqueWhere"
	},
	Contember_BusinessToBusinessManagerWithoutStaffUpdateInput:{
		managedBusinesses:"Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput:{
		create:"Contember_BusinessCustomerWithoutAccountManagerCreateInput",
		update:"Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput",
		upsert:"Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput",
		connect:"Contember_BusinessCustomerUniqueWhere",
		disconnect:"Contember_BusinessCustomerUniqueWhere",
		delete:"Contember_BusinessCustomerUniqueWhere"
	},
	Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		data:"Contember_BusinessCustomerWithoutAccountManagerUpdateInput"
	},
	Contember_BusinessCustomerWithoutAccountManagerUpdateInput:{
		customer:"Contember_BusinessCustomerUpdateCustomerEntityRelationInput",
		owners:"Contember_BusinessCustomerUpdateOwnersEntityRelationInput",
		members:"Contember_BusinessCustomerUpdateMembersEntityRelationInput",
		category:"Contember_BusinessCustomerUpdateCategoryEntityRelationInput",
		logo:"Contember_BusinessCustomerUpdateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerUpdateMembersEntityRelationInput:{
		create:"Contember_CustomerWithoutMemberOfBusinessesCreateInput",
		update:"Contember_BusinessCustomerUpdateMembersRelationInput",
		upsert:"Contember_BusinessCustomerUpsertMembersRelationInput",
		connect:"Contember_CustomerUniqueWhere",
		disconnect:"Contember_CustomerUniqueWhere",
		delete:"Contember_CustomerUniqueWhere"
	},
	Contember_BusinessCustomerUpdateMembersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		data:"Contember_CustomerWithoutMemberOfBusinessesUpdateInput"
	},
	Contember_CustomerWithoutMemberOfBusinessesUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput:{
		create:"Contember_BusinessCustomerWithoutOwnersCreateInput",
		update:"Contember_CustomerUpdateOwnerOfBusinessesRelationInput",
		upsert:"Contember_CustomerUpsertOwnerOfBusinessesRelationInput",
		connect:"Contember_BusinessCustomerUniqueWhere",
		disconnect:"Contember_BusinessCustomerUniqueWhere",
		delete:"Contember_BusinessCustomerUniqueWhere"
	},
	Contember_CustomerUpdateOwnerOfBusinessesRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		data:"Contember_BusinessCustomerWithoutOwnersUpdateInput"
	},
	Contember_BusinessCustomerWithoutOwnersUpdateInput:{
		customer:"Contember_BusinessCustomerUpdateCustomerEntityRelationInput",
		members:"Contember_BusinessCustomerUpdateMembersEntityRelationInput",
		category:"Contember_BusinessCustomerUpdateCategoryEntityRelationInput",
		accountManager:"Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput",
		logo:"Contember_BusinessCustomerUpdateLogoEntityRelationInput",
		businessServiceEvents:"Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput",
		doubleshotCoffeeEquipment:"Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"
	},
	Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput:{
		create:"Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput",
		update:"Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput",
		upsert:"Contember_BusinessCustomerUpsertAccountManagerRelationInput",
		connect:"Contember_BusinessToBusinessManagerUniqueWhere"
	},
	Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput:{
		staff:"Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput:{
		create:"Contember_StaffWithoutBusinessToBusinessManagerCreateInput",
		update:"Contember_StaffWithoutBusinessToBusinessManagerUpdateInput",
		upsert:"Contember_BusinessToBusinessManagerUpsertStaffRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_StaffWithoutBusinessToBusinessManagerUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		courier:"Contember_StaffUpdateCourierEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffUpdateShiftsManagerEntityRelationInput",
		user:"Contember_StaffUpdateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffUpdateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffUpdateStocksManagerEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		venues:"Contember_StaffUpdateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_StaffUpdateUserEntityRelationInput:{
		create:"Contember_UserWithoutStaffCreateInput",
		update:"Contember_UserWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertUserRelationInput",
		connect:"Contember_UserUniqueWhere"
	},
	Contember_UserWithoutStaffUpdateInput:{
		customer:"Contember_UserUpdateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserUpdateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserUpdateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserUpdateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserUpdateRolesEntityRelationInput"
	},
	Contember_UserUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutUserCreateInput",
		update:"Contember_CustomerWithoutUserUpdateInput",
		upsert:"Contember_UserUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutUserUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateCartsEntityRelationInput:{
		create:"Contember_CartWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateCartsRelationInput",
		upsert:"Contember_CustomerUpsertCartsRelationInput",
		connect:"Contember_CartUniqueWhere",
		disconnect:"Contember_CartUniqueWhere",
		delete:"Contember_CartUniqueWhere"
	},
	Contember_CustomerUpdateCartsRelationInput:{
		by:"Contember_CartUniqueWhere",
		data:"Contember_CartWithoutCustomerUpdateInput"
	},
	Contember_CartWithoutCustomerUpdateInput:{
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"Contember_CartUpdateItemsEntityRelationInput",
		state:"Contember_CartState",
		createdAt:"DateTime",
		channel:"Contember_CartUpdateChannelEntityRelationInput",
		order:"Contember_CartUpdateOrderEntityRelationInput",
		deliveryAddress:"Contember_CartUpdateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartUpdateBillingAddressEntityRelationInput",
		delivery:"Contember_CartUpdateDeliveryEntityRelationInput",
		payment:"Contember_CartUpdatePaymentEntityRelationInput",
		billingSubject:"Contember_CartUpdateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartUpdatePriceLinesEntityRelationInput",
		summary:"Contember_CartUpdateSummaryEntityRelationInput"
	},
	Contember_CartUpdateOrderEntityRelationInput:{
		create:"Contember_OrderWithoutCartCreateInput",
		update:"Contember_OrderWithoutCartUpdateInput",
		upsert:"Contember_CartUpsertOrderRelationInput",
		connect:"Contember_OrderUniqueWhere"
	},
	Contember_OrderWithoutCartUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutOrdersCreateInput",
		update:"Contember_CustomerWithoutOrdersUpdateInput",
		upsert:"Contember_OrderUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutOrdersUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateShortcutListEntityRelationInput:{
		create:"Contember_ApplicationShortcutListWithoutCustomerCreateInput",
		update:"Contember_ApplicationShortcutListWithoutCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertShortcutListRelationInput",
		connect:"Contember_ApplicationShortcutListUniqueWhere"
	},
	Contember_ApplicationShortcutListWithoutCustomerUpdateInput:{
		items:"Contember_ApplicationShortcutListUpdateItemsEntityRelationInput"
	},
	Contember_ApplicationShortcutListUpdateItemsEntityRelationInput:{
		create:"Contember_ApplicationShortcutListItemWithoutListCreateInput",
		update:"Contember_ApplicationShortcutListUpdateItemsRelationInput",
		upsert:"Contember_ApplicationShortcutListUpsertItemsRelationInput",
		connect:"Contember_ApplicationShortcutListItemUniqueWhere",
		disconnect:"Contember_ApplicationShortcutListItemUniqueWhere",
		delete:"Contember_ApplicationShortcutListItemUniqueWhere"
	},
	Contember_ApplicationShortcutListUpdateItemsRelationInput:{
		by:"Contember_ApplicationShortcutListItemUniqueWhere",
		data:"Contember_ApplicationShortcutListItemWithoutListUpdateInput"
	},
	Contember_ApplicationShortcutListItemWithoutListUpdateInput:{
		application:"Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput"
	},
	Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput:{
		create:"Contember_ApplicationCreateInput",
		update:"Contember_ApplicationUpdateInput",
		upsert:"Contember_ApplicationShortcutListItemUpsertApplicationRelationInput",
		connect:"Contember_ApplicationUniqueWhere"
	},
	Contember_ApplicationUpdateInput:{
		icon:"Contember_ApplicationUpdateIconEntityRelationInput"
	},
	Contember_ApplicationUpdateIconEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ApplicationUpsertIconRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ApplicationUpsertIconRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ApplicationShortcutListItemUpsertApplicationRelationInput:{
		update:"Contember_ApplicationUpdateInput",
		create:"Contember_ApplicationCreateInput"
	},
	Contember_ApplicationShortcutListUpsertItemsRelationInput:{
		by:"Contember_ApplicationShortcutListItemUniqueWhere",
		update:"Contember_ApplicationShortcutListItemWithoutListUpdateInput",
		create:"Contember_ApplicationShortcutListItemWithoutListCreateInput"
	},
	Contember_CustomerUpsertShortcutListRelationInput:{
		update:"Contember_ApplicationShortcutListWithoutCustomerUpdateInput",
		create:"Contember_ApplicationShortcutListWithoutCustomerCreateInput"
	},
	Contember_CustomerUpdateUserEntityRelationInput:{
		create:"Contember_UserWithoutCustomerCreateInput",
		update:"Contember_UserWithoutCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertUserRelationInput",
		connect:"Contember_UserUniqueWhere"
	},
	Contember_UserWithoutCustomerUpdateInput:{
		staff:"Contember_UserUpdateStaffEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserUpdateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserUpdateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserUpdateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserUpdateRolesEntityRelationInput"
	},
	Contember_UserUpdateStaffEntityRelationInput:{
		create:"Contember_StaffWithoutUserCreateInput",
		update:"Contember_StaffWithoutUserUpdateInput",
		upsert:"Contember_UserUpsertStaffRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_StaffWithoutUserUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		courier:"Contember_StaffUpdateCourierEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffUpdateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput",
		shiftsProfile:"Contember_StaffUpdateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffUpdateStocksManagerEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		venues:"Contember_StaffUpdateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_StaffUpdateShiftsProfileEntityRelationInput:{
		create:"Contember_ShiftsProfileWithoutStaffCreateInput",
		update:"Contember_ShiftsProfileWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertShiftsProfileRelationInput",
		connect:"Contember_ShiftsProfileUniqueWhere"
	},
	Contember_ShiftsProfileWithoutStaffUpdateInput:{
		queueItems:"Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput",
		roles:"Contember_ShiftsProfileUpdateRolesEntityRelationInput",
		shifts:"Contember_ShiftsProfileUpdateShiftsEntityRelationInput"
	},
	Contember_ShiftsProfileUpdateRolesEntityRelationInput:{
		create:"Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput",
		update:"Contember_ShiftsProfileUpdateRolesRelationInput",
		upsert:"Contember_ShiftsProfileUpsertRolesRelationInput",
		connect:"Contember_TimetableEmployeeRoleUniqueWhere",
		disconnect:"Contember_TimetableEmployeeRoleUniqueWhere",
		delete:"Contember_TimetableEmployeeRoleUniqueWhere"
	},
	Contember_ShiftsProfileUpdateRolesRelationInput:{
		by:"Contember_TimetableEmployeeRoleUniqueWhere",
		data:"Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"
	},
	Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput:{
		shiftGroups:"Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput:{
		create:"Contember_ShiftsManagerWithoutManagedRolesCreateInput",
		update:"Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput",
		upsert:"Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput",
		connect:"Contember_ShiftsManagerUniqueWhere",
		disconnect:"Contember_ShiftsManagerUniqueWhere",
		delete:"Contember_ShiftsManagerUniqueWhere"
	},
	Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput:{
		by:"Contember_ShiftsManagerUniqueWhere",
		data:"Contember_ShiftsManagerWithoutManagedRolesUpdateInput"
	},
	Contember_ShiftsManagerWithoutManagedRolesUpdateInput:{
		staff:"Contember_ShiftsManagerUpdateStaffEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_ShiftsManagerUpdateStaffEntityRelationInput:{
		create:"Contember_StaffWithoutShiftsManagerCreateInput",
		update:"Contember_StaffWithoutShiftsManagerUpdateInput",
		upsert:"Contember_ShiftsManagerUpsertStaffRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_StaffWithoutShiftsManagerUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		courier:"Contember_StaffUpdateCourierEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffUpdateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffUpdateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffUpdateStocksManagerEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		venues:"Contember_StaffUpdateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_StaffUpdateStocksManagerEntityRelationInput:{
		create:"Contember_StocksManagerWithoutStaffCreateInput",
		update:"Contember_StocksManagerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertStocksManagerRelationInput",
		connect:"Contember_StocksManagerUniqueWhere"
	},
	Contember_StocksManagerWithoutStaffUpdateInput:{
		managedProducts:"Contember_StocksManagerUpdateManagedProductsEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_StocksManagerUpdateManagedProductsEntityRelationInput:{
		create:"Contember_ProductWithoutManagerCreateInput",
		update:"Contember_StocksManagerUpdateManagedProductsRelationInput",
		upsert:"Contember_StocksManagerUpsertManagedProductsRelationInput",
		connect:"Contember_ProductUniqueWhere",
		disconnect:"Contember_ProductUniqueWhere",
		delete:"Contember_ProductUniqueWhere"
	},
	Contember_StocksManagerUpdateManagedProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		data:"Contember_ProductWithoutManagerUpdateInput"
	},
	Contember_ProductWithoutManagerUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateOrderItemsEntityRelationInput:{
		create:"Contember_OrderItemWithoutProductCreateInput",
		update:"Contember_ProductUpdateOrderItemsRelationInput",
		upsert:"Contember_ProductUpsertOrderItemsRelationInput",
		connect:"Contember_OrderItemUniqueWhere",
		disconnect:"Contember_OrderItemUniqueWhere",
		delete:"Contember_OrderItemUniqueWhere"
	},
	Contember_ProductUpdateOrderItemsRelationInput:{
		by:"Contember_OrderItemUniqueWhere",
		data:"Contember_OrderItemWithoutProductUpdateInput"
	},
	Contember_OrderItemWithoutProductUpdateInput:{
		vatRate:"Contember_OrderItemUpdateVatRateEntityRelationInput",
		order:"Contember_OrderItemUpdateOrderEntityRelationInput",
		virtualProductEffects:"Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput",
		pickedAt:"DateTime",
		fulfillmentNotes:"Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_OrderItemUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_OrderItemUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_OrderItemUpsertVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderItemUpdateOrderEntityRelationInput:{
		create:"Contember_OrderWithoutItemsCreateInput",
		update:"Contember_OrderWithoutItemsUpdateInput",
		upsert:"Contember_OrderItemUpsertOrderRelationInput",
		connect:"Contember_OrderUniqueWhere"
	},
	Contember_OrderWithoutItemsUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateDiscountsEntityRelationInput:{
		create:"Contember_OrderDiscountWithoutOrderCreateInput",
		update:"Contember_OrderUpdateDiscountsRelationInput",
		upsert:"Contember_OrderUpsertDiscountsRelationInput",
		connect:"Contember_OrderDiscountUniqueWhere",
		disconnect:"Contember_OrderDiscountUniqueWhere",
		delete:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_OrderUpdateDiscountsRelationInput:{
		by:"Contember_OrderDiscountUniqueWhere",
		data:"Contember_OrderDiscountWithoutOrderUpdateInput"
	},
	Contember_OrderDiscountWithoutOrderUpdateInput:{
		vatRate:"Contember_OrderDiscountUpdateVatRateEntityRelationInput",
		type:"Contember_OrderDiscountType",
		customerCreditTransactions:"Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput",
		voucherRedemption:"Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"
	},
	Contember_OrderDiscountUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_OrderDiscountUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_OrderDiscountUpsertVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput:{
		create:"Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput",
		update:"Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput",
		upsert:"Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput",
		connect:"Contember_CustomerCreditTransactionUniqueWhere",
		disconnect:"Contember_CustomerCreditTransactionUniqueWhere",
		delete:"Contember_CustomerCreditTransactionUniqueWhere"
	},
	Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput:{
		by:"Contember_CustomerCreditTransactionUniqueWhere",
		data:"Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"
	},
	Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput:{
		createdAt:"DateTime",
		customerCredit:"Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput",
		virtualProductEffect:"Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"
	},
	Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput:{
		create:"Contember_CustomerCreditWithoutTransactionsCreateInput",
		update:"Contember_CustomerCreditWithoutTransactionsUpdateInput",
		upsert:"Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput",
		connect:"Contember_CustomerCreditUniqueWhere"
	},
	Contember_CustomerCreditWithoutTransactionsUpdateInput:{
		expiresAt:"DateTime",
		customer:"Contember_CustomerCreditUpdateCustomerEntityRelationInput",
		vatRate:"Contember_CustomerCreditUpdateVatRateEntityRelationInput",
		createdAt:"DateTime",
		voucherRedemption:"Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"
	},
	Contember_CustomerCreditUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutCreditsCreateInput",
		update:"Contember_CustomerWithoutCreditsUpdateInput",
		upsert:"Contember_CustomerCreditUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutCreditsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateChannelsEntityRelationInput:{
		create:"Contember_ChannelWithoutCustomersCreateInput",
		update:"Contember_CustomerUpdateChannelsRelationInput",
		upsert:"Contember_CustomerUpsertChannelsRelationInput",
		connect:"Contember_ChannelUniqueWhere",
		disconnect:"Contember_ChannelUniqueWhere",
		delete:"Contember_ChannelUniqueWhere"
	},
	Contember_CustomerUpdateChannelsRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		data:"Contember_ChannelWithoutCustomersUpdateInput"
	},
	Contember_ChannelWithoutCustomersUpdateInput:{
		payments:"Contember_ChannelUpdatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelUpdateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelUpdateCartsEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelUpdatePublicTagsEntityRelationInput",
		venue:"Contember_ChannelUpdateVenueEntityRelationInput"
	},
	Contember_ChannelUpdatePublicTagsEntityRelationInput:{
		create:"Contember_TagWithoutPublicInChannelsCreateInput",
		update:"Contember_ChannelUpdatePublicTagsRelationInput",
		upsert:"Contember_ChannelUpsertPublicTagsRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_ChannelUpdatePublicTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutPublicInChannelsUpdateInput"
	},
	Contember_TagWithoutPublicInChannelsUpdateInput:{
		customers:"Contember_TagUpdateCustomersEntityRelationInput",
		products:"Contember_TagUpdateProductsEntityRelationInput",
		categories:"Contember_TagUpdateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagUpdateDeliveryZonesEntityRelationInput",
		allowChannelPayments:"Contember_TagUpdateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagUpdateAllowChannelPaymentsEntityRelationInput:{
		create:"Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput",
		update:"Contember_TagUpdateAllowChannelPaymentsRelationInput",
		upsert:"Contember_TagUpsertAllowChannelPaymentsRelationInput",
		connect:"Contember_ChannelPaymentUniqueWhere",
		disconnect:"Contember_ChannelPaymentUniqueWhere",
		delete:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_TagUpdateAllowChannelPaymentsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		data:"Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"
	},
	Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput:{
		channel:"Contember_ChannelPaymentUpdateChannelEntityRelationInput",
		method:"Contember_ChannelPaymentUpdateMethodEntityRelationInput",
		vatRate:"Contember_ChannelPaymentUpdateVatRateEntityRelationInput"
	},
	Contember_ChannelPaymentUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateWithoutChannelPaymentsCreateInput",
		update:"Contember_VatRateWithoutChannelPaymentsUpdateInput",
		upsert:"Contember_ChannelPaymentUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_VatRateWithoutChannelPaymentsUpdateInput:{
		channelDeliveries:"Contember_VatRateUpdateChannelDeliveriesEntityRelationInput",
		preset:"Contember_VatRatePreset"
	},
	Contember_ChannelPaymentUpsertVatRateRelationInput:{
		update:"Contember_VatRateWithoutChannelPaymentsUpdateInput",
		create:"Contember_VatRateWithoutChannelPaymentsCreateInput"
	},
	Contember_TagUpsertAllowChannelPaymentsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		update:"Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput",
		create:"Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"
	},
	Contember_TagUpdateFulfillmentNotesEntityRelationInput:{
		create:"Contember_FulfillmentNoteWithoutTagsCreateInput",
		update:"Contember_TagUpdateFulfillmentNotesRelationInput",
		upsert:"Contember_TagUpsertFulfillmentNotesRelationInput",
		connect:"Contember_FulfillmentNoteUniqueWhere",
		disconnect:"Contember_FulfillmentNoteUniqueWhere",
		delete:"Contember_FulfillmentNoteUniqueWhere"
	},
	Contember_TagUpdateFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		data:"Contember_FulfillmentNoteWithoutTagsUpdateInput"
	},
	Contember_FulfillmentNoteWithoutTagsUpdateInput:{
		products:"Contember_FulfillmentNoteUpdateProductsEntityRelationInput",
		venues:"Contember_FulfillmentNoteUpdateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteUpdateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_FulfillmentNoteUpdateProductsEntityRelationInput:{
		create:"Contember_ProductWithoutFulfillmentNotesCreateInput",
		update:"Contember_FulfillmentNoteUpdateProductsRelationInput",
		upsert:"Contember_FulfillmentNoteUpsertProductsRelationInput",
		connect:"Contember_ProductUniqueWhere",
		disconnect:"Contember_ProductUniqueWhere",
		delete:"Contember_ProductUniqueWhere"
	},
	Contember_FulfillmentNoteUpdateProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		data:"Contember_ProductWithoutFulfillmentNotesUpdateInput"
	},
	Contember_ProductWithoutFulfillmentNotesUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput"
	},
	Contember_ProductUpdateInheritedCategoriesEntityRelationInput:{
		connect:"Contember_NestedProductCategoryProductUniqueWhere",
		disconnect:"Contember_NestedProductCategoryProductUniqueWhere",
		delete:"Contember_NestedProductCategoryProductUniqueWhere"
	},
	Contember_ProductUpdateDeliveryZonesEntityRelationInput:{
		create:"Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput",
		update:"Contember_ProductUpdateDeliveryZonesRelationInput",
		upsert:"Contember_ProductUpsertDeliveryZonesRelationInput",
		connect:"Contember_DeliveryZoneUniqueWhere",
		disconnect:"Contember_DeliveryZoneUniqueWhere",
		delete:"Contember_DeliveryZoneUniqueWhere"
	},
	Contember_ProductUpdateDeliveryZonesRelationInput:{
		by:"Contember_DeliveryZoneUniqueWhere",
		data:"Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"
	},
	Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput:{
		type:"Contember_DeliveryZoneType",
		addresses:"Contember_DeliveryZoneUpdateAddressesEntityRelationInput",
		suitableDeliveryMethods:"Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput",
		deliveryZonesOfTags:"Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput",
		addressesMetadata:"Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"
	},
	Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput:{
		create:"Contember_TagWithoutDeliveryZonesCreateInput",
		update:"Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput",
		upsert:"Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutDeliveryZonesUpdateInput"
	},
	Contember_TagWithoutDeliveryZonesUpdateInput:{
		customers:"Contember_TagUpdateCustomersEntityRelationInput",
		products:"Contember_TagUpdateProductsEntityRelationInput",
		categories:"Contember_TagUpdateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		publicInChannels:"Contember_TagUpdatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagUpdateAllowChannelPaymentsEntityRelationInput",
		fulfillmentNotes:"Contember_TagUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_TagUpdatePublicInChannelsEntityRelationInput:{
		create:"Contember_ChannelWithoutPublicTagsCreateInput",
		update:"Contember_TagUpdatePublicInChannelsRelationInput",
		upsert:"Contember_TagUpsertPublicInChannelsRelationInput",
		connect:"Contember_ChannelUniqueWhere",
		disconnect:"Contember_ChannelUniqueWhere",
		delete:"Contember_ChannelUniqueWhere"
	},
	Contember_TagUpdatePublicInChannelsRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		data:"Contember_ChannelWithoutPublicTagsUpdateInput"
	},
	Contember_ChannelWithoutPublicTagsUpdateInput:{
		payments:"Contember_ChannelUpdatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelUpdateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelUpdateCartsEntityRelationInput",
		customers:"Contember_ChannelUpdateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput",
		venue:"Contember_ChannelUpdateVenueEntityRelationInput"
	},
	Contember_ChannelUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutChannelsCreateInput",
		update:"Contember_VenueWithoutChannelsUpdateInput",
		upsert:"Contember_ChannelUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutChannelsUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdateDeadlineTemplateEntityRelationInput:{
		create:"Contember_DeadlineTemplateWithoutVenueCreateInput",
		update:"Contember_DeadlineTemplateWithoutVenueUpdateInput",
		upsert:"Contember_VenueUpsertDeadlineTemplateRelationInput",
		connect:"Contember_DeadlineTemplateUniqueWhere"
	},
	Contember_DeadlineTemplateWithoutVenueUpdateInput:{
		startDate:"Date"
	},
	Contember_VenueUpsertDeadlineTemplateRelationInput:{
		update:"Contember_DeadlineTemplateWithoutVenueUpdateInput",
		create:"Contember_DeadlineTemplateWithoutVenueCreateInput"
	},
	Contember_VenueUpdateTimetableDaysEntityRelationInput:{
		create:"Contember_TimetableDayWithoutVenueCreateInput",
		update:"Contember_VenueUpdateTimetableDaysRelationInput",
		upsert:"Contember_VenueUpsertTimetableDaysRelationInput",
		connect:"Contember_TimetableDayUniqueWhere",
		disconnect:"Contember_TimetableDayUniqueWhere",
		delete:"Contember_TimetableDayUniqueWhere"
	},
	Contember_VenueUpdateTimetableDaysRelationInput:{
		by:"Contember_TimetableDayUniqueWhere",
		data:"Contember_TimetableDayWithoutVenueUpdateInput"
	},
	Contember_TimetableDayWithoutVenueUpdateInput:{
		date:"Date",
		type:"Contember_TimetableDayType",
		shiftGroups:"Contember_TimetableDayUpdateShiftGroupsEntityRelationInput",
		notes:"Contember_TimetableDayUpdateNotesEntityRelationInput",
		meta:"Contember_TimetableDayUpdateMetaEntityRelationInput"
	},
	Contember_TimetableDayUpdateShiftGroupsEntityRelationInput:{
		create:"Contember_TimetableShiftGroupWithoutDayCreateInput",
		update:"Contember_TimetableDayUpdateShiftGroupsRelationInput",
		upsert:"Contember_TimetableDayUpsertShiftGroupsRelationInput",
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		disconnect:"Contember_TimetableShiftGroupUniqueWhere",
		delete:"Contember_TimetableShiftGroupUniqueWhere"
	},
	Contember_TimetableDayUpdateShiftGroupsRelationInput:{
		by:"Contember_TimetableShiftGroupUniqueWhere",
		data:"Contember_TimetableShiftGroupWithoutDayUpdateInput"
	},
	Contember_TimetableShiftGroupWithoutDayUpdateInput:{
		shifts:"Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput",
		type:"Contember_TimetableShiftGroupUpdateTypeEntityRelationInput",
		position:"Contember_TimetableShiftGroupUpdatePositionEntityRelationInput",
		queue:"Contember_TimetableShiftGroupUpdateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupUpdateTypeEntityRelationInput:{
		create:"Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput",
		update:"Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput",
		upsert:"Contember_TimetableShiftGroupUpsertTypeRelationInput",
		connect:"Contember_TimetableEmployeeRoleUniqueWhere"
	},
	Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput:{
		shiftsProfiles:"Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput",
		timetableTemplates:"Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput:{
		create:"Contember_TimetableTemplateWithoutEmployeeRoleCreateInput",
		update:"Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput",
		upsert:"Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput",
		connect:"Contember_TimetableTemplateUniqueWhere",
		disconnect:"Contember_TimetableTemplateUniqueWhere",
		delete:"Contember_TimetableTemplateUniqueWhere"
	},
	Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput:{
		by:"Contember_TimetableTemplateUniqueWhere",
		data:"Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"
	},
	Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput:{
		startDate:"Date",
		position:"Contember_TimetableTemplateUpdatePositionEntityRelationInput",
		staffOptions:"Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput",
		venue:"Contember_TimetableTemplateUpdateVenueEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableTemplateUpdatePositionEntityRelationInput:{
		create:"Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput",
		update:"Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput",
		upsert:"Contember_TimetableTemplateUpsertPositionRelationInput",
		connect:"Contember_TimetableShiftPositionUniqueWhere"
	},
	Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput:{
		shiftGroups:"Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput"
	},
	Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput:{
		create:"Contember_TimetableShiftGroupWithoutPositionCreateInput",
		update:"Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput",
		upsert:"Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput",
		connect:"Contember_TimetableShiftGroupUniqueWhere",
		disconnect:"Contember_TimetableShiftGroupUniqueWhere",
		delete:"Contember_TimetableShiftGroupUniqueWhere"
	},
	Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput:{
		by:"Contember_TimetableShiftGroupUniqueWhere",
		data:"Contember_TimetableShiftGroupWithoutPositionUpdateInput"
	},
	Contember_TimetableShiftGroupWithoutPositionUpdateInput:{
		shifts:"Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput",
		day:"Contember_TimetableShiftGroupUpdateDayEntityRelationInput",
		type:"Contember_TimetableShiftGroupUpdateTypeEntityRelationInput",
		queue:"Contember_TimetableShiftGroupUpdateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupUpdateQueueEntityRelationInput:{
		create:"Contember_EmployeeQueueWithoutShiftGroupCreateInput",
		update:"Contember_EmployeeQueueWithoutShiftGroupUpdateInput",
		upsert:"Contember_TimetableShiftGroupUpsertQueueRelationInput",
		connect:"Contember_EmployeeQueueUniqueWhere"
	},
	Contember_EmployeeQueueWithoutShiftGroupUpdateInput:{
		items:"Contember_EmployeeQueueUpdateItemsEntityRelationInput"
	},
	Contember_EmployeeQueueUpdateItemsEntityRelationInput:{
		create:"Contember_EmployeeQueueItemWithoutQueueCreateInput",
		update:"Contember_EmployeeQueueUpdateItemsRelationInput",
		upsert:"Contember_EmployeeQueueUpsertItemsRelationInput",
		connect:"Contember_EmployeeQueueItemUniqueWhere",
		disconnect:"Contember_EmployeeQueueItemUniqueWhere",
		delete:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_EmployeeQueueUpdateItemsRelationInput:{
		by:"Contember_EmployeeQueueItemUniqueWhere",
		data:"Contember_EmployeeQueueItemWithoutQueueUpdateInput"
	},
	Contember_EmployeeQueueItemWithoutQueueUpdateInput:{
		shiftsProfile:"Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput",
		shift:"Contember_EmployeeQueueItemUpdateShiftEntityRelationInput",
		unassignInfo:"Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput",
		meta:"Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"
	},
	Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput:{
		create:"Contember_ShiftsProfileWithoutQueueItemsCreateInput",
		update:"Contember_ShiftsProfileWithoutQueueItemsUpdateInput",
		upsert:"Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput",
		connect:"Contember_ShiftsProfileUniqueWhere"
	},
	Contember_ShiftsProfileWithoutQueueItemsUpdateInput:{
		staff:"Contember_ShiftsProfileUpdateStaffEntityRelationInput",
		roles:"Contember_ShiftsProfileUpdateRolesEntityRelationInput",
		shifts:"Contember_ShiftsProfileUpdateShiftsEntityRelationInput"
	},
	Contember_ShiftsProfileUpdateShiftsEntityRelationInput:{
		create:"Contember_TimetableShiftWithoutShiftsProfileCreateInput",
		update:"Contember_ShiftsProfileUpdateShiftsRelationInput",
		upsert:"Contember_ShiftsProfileUpsertShiftsRelationInput",
		connect:"Contember_TimetableShiftUniqueWhere",
		disconnect:"Contember_TimetableShiftUniqueWhere",
		delete:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_ShiftsProfileUpdateShiftsRelationInput:{
		by:"Contember_TimetableShiftUniqueWhere",
		data:"Contember_TimetableShiftWithoutShiftsProfileUpdateInput"
	},
	Contember_TimetableShiftWithoutShiftsProfileUpdateInput:{
		assigned:"Date",
		group:"Contember_TimetableShiftUpdateGroupEntityRelationInput",
		queueItem:"Contember_TimetableShiftUpdateQueueItemEntityRelationInput",
		swapInfo:"Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"
	},
	Contember_TimetableShiftUpdateGroupEntityRelationInput:{
		create:"Contember_TimetableShiftGroupWithoutShiftsCreateInput",
		update:"Contember_TimetableShiftGroupWithoutShiftsUpdateInput",
		upsert:"Contember_TimetableShiftUpsertGroupRelationInput",
		connect:"Contember_TimetableShiftGroupUniqueWhere"
	},
	Contember_TimetableShiftGroupWithoutShiftsUpdateInput:{
		day:"Contember_TimetableShiftGroupUpdateDayEntityRelationInput",
		type:"Contember_TimetableShiftGroupUpdateTypeEntityRelationInput",
		position:"Contember_TimetableShiftGroupUpdatePositionEntityRelationInput",
		queue:"Contember_TimetableShiftGroupUpdateQueueEntityRelationInput",
		generatedByTemplate:"Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"
	},
	Contember_TimetableShiftGroupUpdatePositionEntityRelationInput:{
		create:"Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput",
		update:"Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput",
		upsert:"Contember_TimetableShiftGroupUpsertPositionRelationInput",
		connect:"Contember_TimetableShiftPositionUniqueWhere"
	},
	Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput:{
		timetableTemplates:"Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput"
	},
	Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput:{
		create:"Contember_TimetableTemplateWithoutPositionCreateInput",
		update:"Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput",
		upsert:"Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput",
		connect:"Contember_TimetableTemplateUniqueWhere",
		disconnect:"Contember_TimetableTemplateUniqueWhere",
		delete:"Contember_TimetableTemplateUniqueWhere"
	},
	Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput:{
		by:"Contember_TimetableTemplateUniqueWhere",
		data:"Contember_TimetableTemplateWithoutPositionUpdateInput"
	},
	Contember_TimetableTemplateWithoutPositionUpdateInput:{
		startDate:"Date",
		staffOptions:"Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput",
		venue:"Contember_TimetableTemplateUpdateVenueEntityRelationInput",
		employeeRole:"Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput:{
		create:"Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput",
		update:"Contember_TimetableTemplateUpdateStaffOptionsRelationInput",
		upsert:"Contember_TimetableTemplateUpsertStaffOptionsRelationInput",
		connect:"Contember_TimetableTemplateStaffOptionsUniqueWhere",
		disconnect:"Contember_TimetableTemplateStaffOptionsUniqueWhere",
		delete:"Contember_TimetableTemplateStaffOptionsUniqueWhere"
	},
	Contember_TimetableTemplateUpdateStaffOptionsRelationInput:{
		by:"Contember_TimetableTemplateStaffOptionsUniqueWhere",
		data:"Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"
	},
	Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput:{
		autoAssignedEmployee:"Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput"
	},
	Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput:{
		create:"Contember_ShiftsProfileCreateInput",
		update:"Contember_ShiftsProfileUpdateInput",
		upsert:"Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput",
		connect:"Contember_ShiftsProfileUniqueWhere"
	},
	Contember_ShiftsProfileUpdateInput:{
		queueItems:"Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput",
		staff:"Contember_ShiftsProfileUpdateStaffEntityRelationInput",
		roles:"Contember_ShiftsProfileUpdateRolesEntityRelationInput",
		shifts:"Contember_ShiftsProfileUpdateShiftsEntityRelationInput"
	},
	Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput:{
		update:"Contember_ShiftsProfileUpdateInput",
		create:"Contember_ShiftsProfileCreateInput"
	},
	Contember_TimetableTemplateUpsertStaffOptionsRelationInput:{
		by:"Contember_TimetableTemplateStaffOptionsUniqueWhere",
		update:"Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput",
		create:"Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"
	},
	Contember_TimetableTemplateUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutTimetableTemplatesCreateInput",
		update:"Contember_VenueWithoutTimetableTemplatesUpdateInput",
		upsert:"Contember_TimetableTemplateUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutTimetableTemplatesUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdateStaffMembersEntityRelationInput:{
		create:"Contember_StaffWithoutVenuesCreateInput",
		update:"Contember_VenueUpdateStaffMembersRelationInput",
		upsert:"Contember_VenueUpsertStaffMembersRelationInput",
		connect:"Contember_StaffUniqueWhere",
		disconnect:"Contember_StaffUniqueWhere",
		delete:"Contember_StaffUniqueWhere"
	},
	Contember_VenueUpdateStaffMembersRelationInput:{
		by:"Contember_StaffUniqueWhere",
		data:"Contember_StaffWithoutVenuesUpdateInput"
	},
	Contember_StaffWithoutVenuesUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		courier:"Contember_StaffUpdateCourierEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffUpdateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffUpdateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffUpdateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffUpdateStocksManagerEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_StaffUpdatePhotoEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_StaffUpsertPhotoRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_StaffUpsertPhotoRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_StaffUpdateCourierManagerEntityRelationInput:{
		create:"Contember_CourierManagerWithoutStaffCreateInput",
		update:"Contember_CourierManagerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertCourierManagerRelationInput",
		connect:"Contember_CourierManagerUniqueWhere"
	},
	Contember_CourierManagerWithoutStaffUpdateInput:{
		createdAt:"DateTime"
	},
	Contember_StaffUpsertCourierManagerRelationInput:{
		update:"Contember_CourierManagerWithoutStaffUpdateInput",
		create:"Contember_CourierManagerWithoutStaffCreateInput"
	},
	Contember_StaffUpdatePickerEntityRelationInput:{
		create:"Contember_PickerWithoutStaffCreateInput",
		update:"Contember_PickerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertPickerRelationInput",
		connect:"Contember_PickerUniqueWhere"
	},
	Contember_PickerWithoutStaffUpdateInput:{
		createdAt:"DateTime"
	},
	Contember_StaffUpsertPickerRelationInput:{
		update:"Contember_PickerWithoutStaffUpdateInput",
		create:"Contember_PickerWithoutStaffCreateInput"
	},
	Contember_StaffUpdateRemunerationProfileEntityRelationInput:{
		create:"Contember_RemunerationProfileWithoutStaffCreateInput",
		update:"Contember_RemunerationProfileWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertRemunerationProfileRelationInput",
		connect:"Contember_RemunerationProfileUniqueWhere"
	},
	Contember_RemunerationProfileWithoutStaffUpdateInput:{
		employmentInformation:"Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput",
		workMonths:"Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput"
	},
	Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput:{
		create:"Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput",
		update:"Contember_RemunerationProfileUpdateEmploymentInformationRelationInput",
		upsert:"Contember_RemunerationProfileUpsertEmploymentInformationRelationInput",
		connect:"Contember_EmploymentInformationUniqueWhere",
		disconnect:"Contember_EmploymentInformationUniqueWhere",
		delete:"Contember_EmploymentInformationUniqueWhere"
	},
	Contember_RemunerationProfileUpdateEmploymentInformationRelationInput:{
		by:"Contember_EmploymentInformationUniqueWhere",
		data:"Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"
	},
	Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput:{
		type:"Contember_EmploymentType"
	},
	Contember_RemunerationProfileUpsertEmploymentInformationRelationInput:{
		by:"Contember_EmploymentInformationUniqueWhere",
		update:"Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput",
		create:"Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"
	},
	Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput:{
		create:"Contember_WorkMonthWithoutRemunerationProfileCreateInput",
		update:"Contember_WorkMonthWithoutRemunerationProfileUpdateInput",
		upsert:"Contember_RemunerationProfileUpsertWorkMonthsRelationInput",
		connect:"Contember_WorkMonthUniqueWhere"
	},
	Contember_WorkMonthWithoutRemunerationProfileUpdateInput:{

	},
	Contember_RemunerationProfileUpsertWorkMonthsRelationInput:{
		update:"Contember_WorkMonthWithoutRemunerationProfileUpdateInput",
		create:"Contember_WorkMonthWithoutRemunerationProfileCreateInput"
	},
	Contember_StaffUpsertRemunerationProfileRelationInput:{
		update:"Contember_RemunerationProfileWithoutStaffUpdateInput",
		create:"Contember_RemunerationProfileWithoutStaffCreateInput"
	},
	Contember_StaffUpdateCashierEntityRelationInput:{
		create:"Contember_CashierWithoutStaffCreateInput",
		update:"Contember_CashierWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertCashierRelationInput",
		connect:"Contember_CashierUniqueWhere"
	},
	Contember_CashierWithoutStaffUpdateInput:{
		createdAt:"DateTime"
	},
	Contember_StaffUpsertCashierRelationInput:{
		update:"Contember_CashierWithoutStaffUpdateInput",
		create:"Contember_CashierWithoutStaffCreateInput"
	},
	Contember_StaffUpdateInternalBuyerEntityRelationInput:{
		create:"Contember_InternalBuyerWithoutStaffCreateInput",
		update:"Contember_InternalBuyerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertInternalBuyerRelationInput",
		connect:"Contember_InternalBuyerUniqueWhere"
	},
	Contember_InternalBuyerWithoutStaffUpdateInput:{
		createdAt:"DateTime"
	},
	Contember_StaffUpsertInternalBuyerRelationInput:{
		update:"Contember_InternalBuyerWithoutStaffUpdateInput",
		create:"Contember_InternalBuyerWithoutStaffCreateInput"
	},
	Contember_StaffUpdateSalesManagerEntityRelationInput:{
		create:"Contember_SalesManagerWithoutStaffCreateInput",
		update:"Contember_SalesManagerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertSalesManagerRelationInput",
		connect:"Contember_SalesManagerUniqueWhere"
	},
	Contember_SalesManagerWithoutStaffUpdateInput:{
		createdAt:"DateTime",
		countries:"Contember_SalesManagerUpdateCountriesEntityRelationInput"
	},
	Contember_SalesManagerUpdateCountriesEntityRelationInput:{
		create:"Contember_CountryCreateInput",
		update:"Contember_SalesManagerUpdateCountriesRelationInput",
		upsert:"Contember_SalesManagerUpsertCountriesRelationInput",
		connect:"Contember_CountryUniqueWhere",
		disconnect:"Contember_CountryUniqueWhere",
		delete:"Contember_CountryUniqueWhere"
	},
	Contember_SalesManagerUpdateCountriesRelationInput:{
		by:"Contember_CountryUniqueWhere",
		data:"Contember_CountryUpdateInput"
	},
	Contember_SalesManagerUpsertCountriesRelationInput:{
		by:"Contember_CountryUniqueWhere",
		update:"Contember_CountryUpdateInput",
		create:"Contember_CountryCreateInput"
	},
	Contember_StaffUpsertSalesManagerRelationInput:{
		update:"Contember_SalesManagerWithoutStaffUpdateInput",
		create:"Contember_SalesManagerWithoutStaffCreateInput"
	},
	Contember_StaffUpdateCashierManagerEntityRelationInput:{
		create:"Contember_CashierManagerWithoutStaffCreateInput",
		update:"Contember_CashierManagerWithoutStaffUpdateInput",
		upsert:"Contember_StaffUpsertCashierManagerRelationInput",
		connect:"Contember_CashierManagerUniqueWhere"
	},
	Contember_CashierManagerWithoutStaffUpdateInput:{
		createdAt:"DateTime"
	},
	Contember_StaffUpsertCashierManagerRelationInput:{
		update:"Contember_CashierManagerWithoutStaffUpdateInput",
		create:"Contember_CashierManagerWithoutStaffCreateInput"
	},
	Contember_VenueUpsertStaffMembersRelationInput:{
		by:"Contember_StaffUniqueWhere",
		update:"Contember_StaffWithoutVenuesUpdateInput",
		create:"Contember_StaffWithoutVenuesCreateInput"
	},
	Contember_VenueUpdateEmployeeRolesEntityRelationInput:{
		create:"Contember_VenueEmployeeRoleWithoutVenueCreateInput",
		update:"Contember_VenueUpdateEmployeeRolesRelationInput",
		upsert:"Contember_VenueUpsertEmployeeRolesRelationInput",
		connect:"Contember_VenueEmployeeRoleUniqueWhere",
		disconnect:"Contember_VenueEmployeeRoleUniqueWhere",
		delete:"Contember_VenueEmployeeRoleUniqueWhere"
	},
	Contember_VenueUpdateEmployeeRolesRelationInput:{
		by:"Contember_VenueEmployeeRoleUniqueWhere",
		data:"Contember_VenueEmployeeRoleWithoutVenueUpdateInput"
	},
	Contember_VenueEmployeeRoleWithoutVenueUpdateInput:{
		employeePrivileges:"Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput",
		role:"Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput"
	},
	Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput:{
		create:"Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput",
		update:"Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput",
		upsert:"Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput",
		connect:"Contember_TimetablesEmployeePrivilegesUniqueWhere"
	},
	Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput:{

	},
	Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput:{
		update:"Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput",
		create:"Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"
	},
	Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput:{
		create:"Contember_TimetableEmployeeRoleCreateInput",
		update:"Contember_TimetableEmployeeRoleUpdateInput",
		upsert:"Contember_VenueEmployeeRoleUpsertRoleRelationInput",
		connect:"Contember_TimetableEmployeeRoleUniqueWhere"
	},
	Contember_VenueEmployeeRoleUpsertRoleRelationInput:{
		update:"Contember_TimetableEmployeeRoleUpdateInput",
		create:"Contember_TimetableEmployeeRoleCreateInput"
	},
	Contember_VenueUpsertEmployeeRolesRelationInput:{
		by:"Contember_VenueEmployeeRoleUniqueWhere",
		update:"Contember_VenueEmployeeRoleWithoutVenueUpdateInput",
		create:"Contember_VenueEmployeeRoleWithoutVenueCreateInput"
	},
	Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput:{
		create:"Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput",
		update:"Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput",
		upsert:"Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput",
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		disconnect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		delete:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		data:"Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput:{
		identificationCode:"Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput:{
		create:"Contember_IdentificationCodeWithoutApplicationDevicesCreateInput",
		update:"Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput",
		upsert:"Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput",
		connect:"Contember_IdentificationCodeUniqueWhere"
	},
	Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput:{
		createdAt:"DateTime",
		attempts:"Contember_IdentificationCodeUpdateAttemptsEntityRelationInput",
		locales:"Contember_IdentificationCodeUpdateLocalesEntityRelationInput"
	},
	Contember_IdentificationCodeUpdateAttemptsEntityRelationInput:{
		create:"Contember_IdentificationAttemptWithoutCodeCreateInput",
		update:"Contember_IdentificationCodeUpdateAttemptsRelationInput",
		upsert:"Contember_IdentificationCodeUpsertAttemptsRelationInput",
		connect:"Contember_IdentificationAttemptUniqueWhere",
		disconnect:"Contember_IdentificationAttemptUniqueWhere",
		delete:"Contember_IdentificationAttemptUniqueWhere"
	},
	Contember_IdentificationCodeUpdateAttemptsRelationInput:{
		by:"Contember_IdentificationAttemptUniqueWhere",
		data:"Contember_IdentificationAttemptWithoutCodeUpdateInput"
	},
	Contember_IdentificationAttemptWithoutCodeUpdateInput:{
		createdAt:"DateTime",
		user:"Contember_IdentificationAttemptUpdateUserEntityRelationInput"
	},
	Contember_IdentificationAttemptUpdateUserEntityRelationInput:{
		create:"Contember_UserWithoutIdentificationAttemptsCreateInput",
		update:"Contember_UserWithoutIdentificationAttemptsUpdateInput",
		upsert:"Contember_IdentificationAttemptUpsertUserRelationInput",
		connect:"Contember_UserUniqueWhere"
	},
	Contember_UserWithoutIdentificationAttemptsUpdateInput:{
		staff:"Contember_UserUpdateStaffEntityRelationInput",
		customer:"Contember_UserUpdateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserUpdateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserUpdateMetaEntityRelationInput",
		roles:"Contember_UserUpdateRolesEntityRelationInput"
	},
	Contember_UserUpdateDevicesEntityRelationInput:{
		create:"Contember_UserDeviceWithoutUserCreateInput",
		update:"Contember_UserUpdateDevicesRelationInput",
		upsert:"Contember_UserUpsertDevicesRelationInput",
		connect:"Contember_UserDeviceUniqueWhere",
		disconnect:"Contember_UserDeviceUniqueWhere",
		delete:"Contember_UserDeviceUniqueWhere"
	},
	Contember_UserUpdateDevicesRelationInput:{
		by:"Contember_UserDeviceUniqueWhere",
		data:"Contember_UserDeviceWithoutUserUpdateInput"
	},
	Contember_UserDeviceWithoutUserUpdateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		anonymousSession:"Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput"
	},
	Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput:{
		create:"Contember_AnonymousSessionWithoutDevicesCreateInput",
		update:"Contember_AnonymousSessionWithoutDevicesUpdateInput",
		upsert:"Contember_UserDeviceUpsertAnonymousSessionRelationInput",
		connect:"Contember_AnonymousSessionUniqueWhere"
	},
	Contember_AnonymousSessionWithoutDevicesUpdateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		customer:"Contember_AnonymousSessionUpdateCustomerEntityRelationInput"
	},
	Contember_AnonymousSessionUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutAnonymousSessionCreateInput",
		update:"Contember_CustomerWithoutAnonymousSessionUpdateInput",
		upsert:"Contember_AnonymousSessionUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutAnonymousSessionUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateInvoicesEntityRelationInput:{
		create:"Contember_InvoiceWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateInvoicesRelationInput",
		upsert:"Contember_CustomerUpsertInvoicesRelationInput",
		connect:"Contember_InvoiceUniqueWhere",
		disconnect:"Contember_InvoiceUniqueWhere",
		delete:"Contember_InvoiceUniqueWhere"
	},
	Contember_CustomerUpdateInvoicesRelationInput:{
		by:"Contember_InvoiceUniqueWhere",
		data:"Contember_InvoiceWithoutCustomerUpdateInput"
	},
	Contember_InvoiceWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		orderPayments:"Contember_InvoiceUpdateOrderPaymentsEntityRelationInput",
		address:"Contember_InvoiceUpdateAddressEntityRelationInput",
		billingSubject:"Contember_InvoiceUpdateBillingSubjectEntityRelationInput"
	},
	Contember_InvoiceUpdateOrderPaymentsEntityRelationInput:{
		create:"Contember_OrderPaymentWithoutInvoiceCreateInput",
		update:"Contember_InvoiceUpdateOrderPaymentsRelationInput",
		upsert:"Contember_InvoiceUpsertOrderPaymentsRelationInput",
		connect:"Contember_OrderPaymentUniqueWhere",
		disconnect:"Contember_OrderPaymentUniqueWhere",
		delete:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_InvoiceUpdateOrderPaymentsRelationInput:{
		by:"Contember_OrderPaymentUniqueWhere",
		data:"Contember_OrderPaymentWithoutInvoiceUpdateInput"
	},
	Contember_OrderPaymentWithoutInvoiceUpdateInput:{
		data:"Json",
		method:"Contember_OrderPaymentUpdateMethodEntityRelationInput",
		failedAt:"DateTime",
		state:"Contember_OrderPaymentState",
		approvedAt:"DateTime",
		events:"Contember_OrderPaymentUpdateEventsEntityRelationInput",
		order:"Contember_OrderPaymentUpdateOrderEntityRelationInput",
		createdAt:"DateTime",
		vatRate:"Contember_OrderPaymentUpdateVatRateEntityRelationInput",
		meta:"Contember_OrderPaymentUpdateMetaEntityRelationInput"
	},
	Contember_OrderPaymentUpdateMethodEntityRelationInput:{
		create:"Contember_PaymentMethodCreateInput",
		update:"Contember_PaymentMethodUpdateInput",
		upsert:"Contember_OrderPaymentUpsertMethodRelationInput",
		connect:"Contember_PaymentMethodUniqueWhere"
	},
	Contember_OrderPaymentUpsertMethodRelationInput:{
		update:"Contember_PaymentMethodUpdateInput",
		create:"Contember_PaymentMethodCreateInput"
	},
	Contember_OrderPaymentUpdateEventsEntityRelationInput:{
		create:"Contember_OrderEventWithoutPaymentCreateInput",
		update:"Contember_OrderPaymentUpdateEventsRelationInput",
		upsert:"Contember_OrderPaymentUpsertEventsRelationInput",
		connect:"Contember_OrderEventUniqueWhere",
		disconnect:"Contember_OrderEventUniqueWhere",
		delete:"Contember_OrderEventUniqueWhere"
	},
	Contember_OrderPaymentUpdateEventsRelationInput:{
		by:"Contember_OrderEventUniqueWhere",
		data:"Contember_OrderEventWithoutPaymentUpdateInput"
	},
	Contember_OrderEventWithoutPaymentUpdateInput:{
		createdAt:"DateTime",
		order:"Contember_OrderEventUpdateOrderEntityRelationInput",
		delivery:"Contember_OrderEventUpdateDeliveryEntityRelationInput"
	},
	Contember_OrderEventUpdateOrderEntityRelationInput:{
		create:"Contember_OrderWithoutEventsCreateInput",
		update:"Contember_OrderWithoutEventsUpdateInput",
		upsert:"Contember_OrderEventUpsertOrderRelationInput",
		connect:"Contember_OrderUniqueWhere"
	},
	Contember_OrderWithoutEventsUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateDeliveryEntityRelationInput:{
		create:"Contember_OrderDeliveryWithoutOrderCreateInput",
		update:"Contember_OrderDeliveryWithoutOrderUpdateInput",
		upsert:"Contember_OrderUpsertDeliveryRelationInput",
		connect:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_OrderDeliveryWithoutOrderUpdateInput:{
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryUpdateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryUpdateVatRateEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		deliveryWaypoint:"Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput",
		events:"Contember_OrderDeliveryUpdateEventsEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime",
		address:"Contember_OrderDeliveryUpdateAddressEntityRelationInput"
	},
	Contember_OrderDeliveryUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_OrderDeliveryUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_OrderDeliveryUpsertVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput:{
		create:"Contember_DeliveryWaypointWithoutDeliveryCreateInput",
		update:"Contember_DeliveryWaypointWithoutDeliveryUpdateInput",
		upsert:"Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput",
		connect:"Contember_DeliveryWaypointUniqueWhere"
	},
	Contember_DeliveryWaypointWithoutDeliveryUpdateInput:{
		estimatedArrival:"DateTime",
		route:"Contember_DeliveryWaypointUpdateRouteEntityRelationInput",
		originDeparture:"DateTime"
	},
	Contember_DeliveryWaypointUpdateRouteEntityRelationInput:{
		create:"Contember_DeliveryRouteWithoutWaypointsCreateInput",
		update:"Contember_DeliveryRouteWithoutWaypointsUpdateInput",
		upsert:"Contember_DeliveryWaypointUpsertRouteRelationInput",
		connect:"Contember_DeliveryRouteUniqueWhere"
	},
	Contember_DeliveryRouteWithoutWaypointsUpdateInput:{
		courier:"Contember_DeliveryRouteUpdateCourierEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_DeliveryRouteUpdateCourierEntityRelationInput:{
		create:"Contember_CourierWithoutRoutesCreateInput",
		update:"Contember_CourierWithoutRoutesUpdateInput",
		upsert:"Contember_DeliveryRouteUpsertCourierRelationInput",
		connect:"Contember_CourierUniqueWhere"
	},
	Contember_CourierWithoutRoutesUpdateInput:{
		staff:"Contember_CourierUpdateStaffEntityRelationInput",
		createdAt:"DateTime",
		deliveryMethods:"Contember_CourierUpdateDeliveryMethodsEntityRelationInput"
	},
	Contember_CourierUpdateDeliveryMethodsEntityRelationInput:{
		create:"Contember_DeliveryMethodWithoutCourierHandlersCreateInput",
		update:"Contember_CourierUpdateDeliveryMethodsRelationInput",
		upsert:"Contember_CourierUpsertDeliveryMethodsRelationInput",
		connect:"Contember_DeliveryMethodUniqueWhere",
		disconnect:"Contember_DeliveryMethodUniqueWhere",
		delete:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_CourierUpdateDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodUniqueWhere",
		data:"Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"
	},
	Contember_DeliveryMethodWithoutCourierHandlersUpdateInput:{
		paymentMethods:"Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodUpdateChannelsEntityRelationInput",
		locales:"Contember_DeliveryMethodUpdateLocalesEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodUpdateLocalesEntityRelationInput:{
		create:"Contember_DeliveryMethodLocaleWithoutRootCreateInput",
		update:"Contember_DeliveryMethodUpdateLocalesRelationInput",
		upsert:"Contember_DeliveryMethodUpsertLocalesRelationInput",
		connect:"Contember_DeliveryMethodLocaleUniqueWhere",
		disconnect:"Contember_DeliveryMethodLocaleUniqueWhere",
		delete:"Contember_DeliveryMethodLocaleUniqueWhere"
	},
	Contember_DeliveryMethodUpdateLocalesRelationInput:{
		by:"Contember_DeliveryMethodLocaleUniqueWhere",
		data:"Contember_DeliveryMethodLocaleWithoutRootUpdateInput"
	},
	Contember_DeliveryMethodLocaleWithoutRootUpdateInput:{
		locale:"Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutDeliveryMethodsCreateInput",
		update:"Contember_LocaleWithoutDeliveryMethodsUpdateInput",
		upsert:"Contember_DeliveryMethodLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutDeliveryMethodsUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateProductPackingsEntityRelationInput:{
		create:"Contember_ProductPackingLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateProductPackingsRelationInput",
		upsert:"Contember_LocaleUpsertProductPackingsRelationInput",
		connect:"Contember_ProductPackingLocaleUniqueWhere",
		disconnect:"Contember_ProductPackingLocaleUniqueWhere",
		delete:"Contember_ProductPackingLocaleUniqueWhere"
	},
	Contember_LocaleUpdateProductPackingsRelationInput:{
		by:"Contember_ProductPackingLocaleUniqueWhere",
		data:"Contember_ProductPackingLocaleWithoutLocaleUpdateInput"
	},
	Contember_ProductPackingLocaleWithoutLocaleUpdateInput:{
		root:"Contember_ProductPackingLocaleUpdateRootEntityRelationInput"
	},
	Contember_ProductPackingLocaleUpdateRootEntityRelationInput:{
		create:"Contember_ProductPackingWithoutLocalesCreateInput",
		update:"Contember_ProductPackingWithoutLocalesUpdateInput",
		upsert:"Contember_ProductPackingLocaleUpsertRootRelationInput",
		connect:"Contember_ProductPackingUniqueWhere"
	},
	Contember_ProductPackingWithoutLocalesUpdateInput:{
		image:"Contember_ProductPackingUpdateImageEntityRelationInput",
		icon:"Contember_ProductPackingUpdateIconEntityRelationInput",
		preparedPackingQuantities:"Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"
	},
	Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput:{
		create:"Contember_PreparedPackingQuantityWithoutPackingCreateInput",
		update:"Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput",
		upsert:"Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput",
		connect:"Contember_PreparedPackingQuantityUniqueWhere",
		disconnect:"Contember_PreparedPackingQuantityUniqueWhere",
		delete:"Contember_PreparedPackingQuantityUniqueWhere"
	},
	Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput:{
		by:"Contember_PreparedPackingQuantityUniqueWhere",
		data:"Contember_PreparedPackingQuantityWithoutPackingUpdateInput"
	},
	Contember_PreparedPackingQuantityWithoutPackingUpdateInput:{
		date:"Date",
		recipe:"Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput"
	},
	Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput:{
		create:"Contember_ProductRecipeCreateInput",
		update:"Contember_ProductRecipeUpdateInput",
		upsert:"Contember_PreparedPackingQuantityUpsertRecipeRelationInput",
		connect:"Contember_ProductRecipeUniqueWhere"
	},
	Contember_ProductRecipeUpdateInput:{
		products:"Contember_ProductRecipeUpdateProductsEntityRelationInput",
		image:"Contember_ProductRecipeUpdateImageEntityRelationInput",
		icon:"Contember_ProductRecipeUpdateIconEntityRelationInput",
		ingredients:"Contember_ProductRecipeUpdateIngredientsEntityRelationInput",
		locales:"Contember_ProductRecipeUpdateLocalesEntityRelationInput",
		freshingContainer:"Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput",
		nutritionFacts:"Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"
	},
	Contember_ProductRecipeUpdateProductsEntityRelationInput:{
		create:"Contember_ProductWithoutRecipeCreateInput",
		update:"Contember_ProductRecipeUpdateProductsRelationInput",
		upsert:"Contember_ProductRecipeUpsertProductsRelationInput",
		connect:"Contember_ProductUniqueWhere",
		disconnect:"Contember_ProductUniqueWhere",
		delete:"Contember_ProductUniqueWhere"
	},
	Contember_ProductRecipeUpdateProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		data:"Contember_ProductWithoutRecipeUpdateInput"
	},
	Contember_ProductWithoutRecipeUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateLocalesEntityRelationInput:{
		create:"Contember_ProductLocaleWithoutRootCreateInput",
		update:"Contember_ProductUpdateLocalesRelationInput",
		upsert:"Contember_ProductUpsertLocalesRelationInput",
		connect:"Contember_ProductLocaleUniqueWhere",
		disconnect:"Contember_ProductLocaleUniqueWhere",
		delete:"Contember_ProductLocaleUniqueWhere"
	},
	Contember_ProductUpdateLocalesRelationInput:{
		by:"Contember_ProductLocaleUniqueWhere",
		data:"Contember_ProductLocaleWithoutRootUpdateInput"
	},
	Contember_ProductLocaleWithoutRootUpdateInput:{
		locale:"Contember_ProductLocaleUpdateLocaleEntityRelationInput",
		featureList:"Contember_ProductLocaleUpdateFeatureListEntityRelationInput"
	},
	Contember_ProductLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutProductsCreateInput",
		update:"Contember_LocaleWithoutProductsUpdateInput",
		upsert:"Contember_ProductLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutProductsUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateProductRecipesEntityRelationInput:{
		create:"Contember_ProductRecipeLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateProductRecipesRelationInput",
		upsert:"Contember_LocaleUpsertProductRecipesRelationInput",
		connect:"Contember_ProductRecipeLocaleUniqueWhere",
		disconnect:"Contember_ProductRecipeLocaleUniqueWhere",
		delete:"Contember_ProductRecipeLocaleUniqueWhere"
	},
	Contember_LocaleUpdateProductRecipesRelationInput:{
		by:"Contember_ProductRecipeLocaleUniqueWhere",
		data:"Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"
	},
	Contember_ProductRecipeLocaleWithoutLocaleUpdateInput:{
		root:"Contember_ProductRecipeLocaleUpdateRootEntityRelationInput"
	},
	Contember_ProductRecipeLocaleUpdateRootEntityRelationInput:{
		create:"Contember_ProductRecipeWithoutLocalesCreateInput",
		update:"Contember_ProductRecipeWithoutLocalesUpdateInput",
		upsert:"Contember_ProductRecipeLocaleUpsertRootRelationInput",
		connect:"Contember_ProductRecipeUniqueWhere"
	},
	Contember_ProductRecipeWithoutLocalesUpdateInput:{
		products:"Contember_ProductRecipeUpdateProductsEntityRelationInput",
		image:"Contember_ProductRecipeUpdateImageEntityRelationInput",
		icon:"Contember_ProductRecipeUpdateIconEntityRelationInput",
		ingredients:"Contember_ProductRecipeUpdateIngredientsEntityRelationInput",
		freshingContainer:"Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput",
		nutritionFacts:"Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"
	},
	Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput:{
		create:"Contember_FreshingContainerCreateInput",
		update:"Contember_FreshingContainerUpdateInput",
		upsert:"Contember_ProductRecipeUpsertFreshingContainerRelationInput",
		connect:"Contember_FreshingContainerUniqueWhere"
	},
	Contember_ProductRecipeUpsertFreshingContainerRelationInput:{
		update:"Contember_FreshingContainerUpdateInput",
		create:"Contember_FreshingContainerCreateInput"
	},
	Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput:{
		create:"Contember_NutritionFactsCreateInput",
		update:"Contember_NutritionFactsUpdateInput",
		upsert:"Contember_ProductRecipeUpsertNutritionFactsRelationInput",
		connect:"Contember_NutritionFactsUniqueWhere"
	},
	Contember_NutritionFactsUpdateInput:{

	},
	Contember_ProductRecipeUpsertNutritionFactsRelationInput:{
		update:"Contember_NutritionFactsUpdateInput",
		create:"Contember_NutritionFactsCreateInput"
	},
	Contember_ProductRecipeLocaleUpsertRootRelationInput:{
		update:"Contember_ProductRecipeWithoutLocalesUpdateInput",
		create:"Contember_ProductRecipeWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertProductRecipesRelationInput:{
		by:"Contember_ProductRecipeLocaleUniqueWhere",
		update:"Contember_ProductRecipeLocaleWithoutLocaleUpdateInput",
		create:"Contember_ProductRecipeLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdatePointOfSaleCategoriesRelationInput",
		upsert:"Contember_LocaleUpsertPointOfSaleCategoriesRelationInput",
		connect:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		disconnect:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		delete:"Contember_PointOfSaleCategoryLocaleUniqueWhere"
	},
	Contember_LocaleUpdatePointOfSaleCategoriesRelationInput:{
		by:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		data:"Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"
	},
	Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput:{
		root:"Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput"
	},
	Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryWithoutLocalesCreateInput",
		update:"Contember_PointOfSaleCategoryWithoutLocalesUpdateInput",
		upsert:"Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput",
		connect:"Contember_PointOfSaleCategoryUniqueWhere"
	},
	Contember_PointOfSaleCategoryWithoutLocalesUpdateInput:{
		products:"Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput",
		category:"Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput",
		venue:"Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput",
		update:"Contember_PointOfSaleCategoryUpdateProductsRelationInput",
		upsert:"Contember_PointOfSaleCategoryUpsertProductsRelationInput",
		connect:"Contember_PointOfSaleCategoryProductUniqueWhere",
		disconnect:"Contember_PointOfSaleCategoryProductUniqueWhere",
		delete:"Contember_PointOfSaleCategoryProductUniqueWhere"
	},
	Contember_PointOfSaleCategoryUpdateProductsRelationInput:{
		by:"Contember_PointOfSaleCategoryProductUniqueWhere",
		data:"Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"
	},
	Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput:{
		product:"Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput",
		locales:"Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput:{
		create:"Contember_ProductCreateInput",
		update:"Contember_ProductUpdateInput",
		upsert:"Contember_PointOfSaleCategoryProductUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductUpdateCustomerPricesEntityRelationInput:{
		connect:"Contember_ProductCustomerPriceUniqueWhere",
		disconnect:"Contember_ProductCustomerPriceUniqueWhere",
		delete:"Contember_ProductCustomerPriceUniqueWhere"
	},
	Contember_ProductUpdateVirtualProductEntityRelationInput:{
		create:"Contember_VirtualProductWithoutProductCreateInput",
		update:"Contember_VirtualProductWithoutProductUpdateInput",
		upsert:"Contember_ProductUpsertVirtualProductRelationInput",
		connect:"Contember_VirtualProductUniqueWhere"
	},
	Contember_VirtualProductWithoutProductUpdateInput:{
		type:"Contember_VirtualProductType",
		physicalRepresentation:"Contember_VirtualProductPhysicalRepresentationType"
	},
	Contember_ProductUpsertVirtualProductRelationInput:{
		update:"Contember_VirtualProductWithoutProductUpdateInput",
		create:"Contember_VirtualProductWithoutProductCreateInput"
	},
	Contember_ProductUpdateMetaEntityRelationInput:{
		connect:"Contember_ProductMetadataUniqueWhere"
	},
	Contember_ProductUpdatePageEntityRelationInput:{
		create:"Contember_PageCreateInput",
		update:"Contember_PageUpdateInput",
		upsert:"Contember_ProductUpsertPageRelationInput",
		connect:"Contember_PageUniqueWhere"
	},
	Contember_PageUpdateInput:{
		locales:"Contember_PageUpdateLocalesEntityRelationInput"
	},
	Contember_PageUpdateLocalesEntityRelationInput:{
		create:"Contember_PageLocaleWithoutRootCreateInput",
		update:"Contember_PageUpdateLocalesRelationInput",
		upsert:"Contember_PageUpsertLocalesRelationInput",
		connect:"Contember_PageLocaleUniqueWhere",
		disconnect:"Contember_PageLocaleUniqueWhere",
		delete:"Contember_PageLocaleUniqueWhere"
	},
	Contember_PageUpdateLocalesRelationInput:{
		by:"Contember_PageLocaleUniqueWhere",
		data:"Contember_PageLocaleWithoutRootUpdateInput"
	},
	Contember_PageLocaleWithoutRootUpdateInput:{
		link:"Contember_PageLocaleUpdateLinkEntityRelationInput",
		locale:"Contember_PageLocaleUpdateLocaleEntityRelationInput",
		seo:"Contember_PageLocaleUpdateSeoEntityRelationInput",
		content:"Contember_PageLocaleUpdateContentEntityRelationInput",
		cover:"Contember_PageLocaleUpdateCoverEntityRelationInput",
		theme:"Contember_PageTheme"
	},
	Contember_PageLocaleUpdateSeoEntityRelationInput:{
		create:"Contember_SeoCreateInput",
		update:"Contember_SeoUpdateInput",
		upsert:"Contember_PageLocaleUpsertSeoRelationInput",
		connect:"Contember_SeoUniqueWhere"
	},
	Contember_SeoUpdateInput:{
		ogImage:"Contember_SeoUpdateOgImageEntityRelationInput"
	},
	Contember_SeoUpdateOgImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_SeoUpsertOgImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_SeoUpsertOgImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_PageLocaleUpsertSeoRelationInput:{
		update:"Contember_SeoUpdateInput",
		create:"Contember_SeoCreateInput"
	},
	Contember_PageLocaleUpdateContentEntityRelationInput:{
		create:"Contember_ContentCreateInput",
		update:"Contember_ContentUpdateInput",
		upsert:"Contember_PageLocaleUpsertContentRelationInput",
		connect:"Contember_ContentUniqueWhere"
	},
	Contember_PageLocaleUpsertContentRelationInput:{
		update:"Contember_ContentUpdateInput",
		create:"Contember_ContentCreateInput"
	},
	Contember_PageLocaleUpdateCoverEntityRelationInput:{
		create:"Contember_CoverCreateInput",
		update:"Contember_CoverUpdateInput",
		upsert:"Contember_PageLocaleUpsertCoverRelationInput",
		connect:"Contember_CoverUniqueWhere"
	},
	Contember_PageLocaleUpsertCoverRelationInput:{
		update:"Contember_CoverUpdateInput",
		create:"Contember_CoverCreateInput"
	},
	Contember_PageUpsertLocalesRelationInput:{
		by:"Contember_PageLocaleUniqueWhere",
		update:"Contember_PageLocaleWithoutRootUpdateInput",
		create:"Contember_PageLocaleWithoutRootCreateInput"
	},
	Contember_ProductUpsertPageRelationInput:{
		update:"Contember_PageUpdateInput",
		create:"Contember_PageCreateInput"
	},
	Contember_ProductUpdateSalesDaysEntityRelationInput:{
		connect:"Contember_ProductSalesDayUniqueWhere",
		disconnect:"Contember_ProductSalesDayUniqueWhere",
		delete:"Contember_ProductSalesDayUniqueWhere"
	},
	Contember_ProductUpdateSalesWeeksEntityRelationInput:{
		connect:"Contember_ProductSalesWeekUniqueWhere",
		disconnect:"Contember_ProductSalesWeekUniqueWhere",
		delete:"Contember_ProductSalesWeekUniqueWhere"
	},
	Contember_ProductUpdateSalesMonthsEntityRelationInput:{
		connect:"Contember_ProductSalesMonthUniqueWhere",
		disconnect:"Contember_ProductSalesMonthUniqueWhere",
		delete:"Contember_ProductSalesMonthUniqueWhere"
	},
	Contember_ProductUpdateFulfillmentNotesEntityRelationInput:{
		create:"Contember_FulfillmentNoteWithoutProductsCreateInput",
		update:"Contember_ProductUpdateFulfillmentNotesRelationInput",
		upsert:"Contember_ProductUpsertFulfillmentNotesRelationInput",
		connect:"Contember_FulfillmentNoteUniqueWhere",
		disconnect:"Contember_FulfillmentNoteUniqueWhere",
		delete:"Contember_FulfillmentNoteUniqueWhere"
	},
	Contember_ProductUpdateFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		data:"Contember_FulfillmentNoteWithoutProductsUpdateInput"
	},
	Contember_FulfillmentNoteWithoutProductsUpdateInput:{
		tags:"Contember_FulfillmentNoteUpdateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteUpdateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteUpdateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_FulfillmentNoteUpdateTagsEntityRelationInput:{
		create:"Contember_TagWithoutFulfillmentNotesCreateInput",
		update:"Contember_FulfillmentNoteUpdateTagsRelationInput",
		upsert:"Contember_FulfillmentNoteUpsertTagsRelationInput",
		connect:"Contember_TagUniqueWhere",
		disconnect:"Contember_TagUniqueWhere",
		delete:"Contember_TagUniqueWhere"
	},
	Contember_FulfillmentNoteUpdateTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		data:"Contember_TagWithoutFulfillmentNotesUpdateInput"
	},
	Contember_TagWithoutFulfillmentNotesUpdateInput:{
		customers:"Contember_TagUpdateCustomersEntityRelationInput",
		products:"Contember_TagUpdateProductsEntityRelationInput",
		categories:"Contember_TagUpdateCategoriesEntityRelationInput",
		addTagsOnRegistration:"Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput",
		nestedCategories:"Contember_TagUpdateNestedCategoriesEntityRelationInput",
		deliveryZones:"Contember_TagUpdateDeliveryZonesEntityRelationInput",
		publicInChannels:"Contember_TagUpdatePublicInChannelsEntityRelationInput",
		allowChannelPayments:"Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"
	},
	Contember_FulfillmentNoteUpsertTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutFulfillmentNotesUpdateInput",
		create:"Contember_TagWithoutFulfillmentNotesCreateInput"
	},
	Contember_FulfillmentNoteUpdateVenuesEntityRelationInput:{
		create:"Contember_VenueWithoutFulfillmentNotesCreateInput",
		update:"Contember_FulfillmentNoteUpdateVenuesRelationInput",
		upsert:"Contember_FulfillmentNoteUpsertVenuesRelationInput",
		connect:"Contember_VenueUniqueWhere",
		disconnect:"Contember_VenueUniqueWhere",
		delete:"Contember_VenueUniqueWhere"
	},
	Contember_FulfillmentNoteUpdateVenuesRelationInput:{
		by:"Contember_VenueUniqueWhere",
		data:"Contember_VenueWithoutFulfillmentNotesUpdateInput"
	},
	Contember_VenueWithoutFulfillmentNotesUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdateTimetableTemplatesEntityRelationInput:{
		create:"Contember_TimetableTemplateWithoutVenueCreateInput",
		update:"Contember_VenueUpdateTimetableTemplatesRelationInput",
		upsert:"Contember_VenueUpsertTimetableTemplatesRelationInput",
		connect:"Contember_TimetableTemplateUniqueWhere",
		disconnect:"Contember_TimetableTemplateUniqueWhere",
		delete:"Contember_TimetableTemplateUniqueWhere"
	},
	Contember_VenueUpdateTimetableTemplatesRelationInput:{
		by:"Contember_TimetableTemplateUniqueWhere",
		data:"Contember_TimetableTemplateWithoutVenueUpdateInput"
	},
	Contember_TimetableTemplateWithoutVenueUpdateInput:{
		startDate:"Date",
		position:"Contember_TimetableTemplateUpdatePositionEntityRelationInput",
		staffOptions:"Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput",
		employeeRole:"Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput:{
		create:"Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput",
		update:"Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput",
		upsert:"Contember_TimetableTemplateUpsertEmployeeRoleRelationInput",
		connect:"Contember_TimetableEmployeeRoleUniqueWhere"
	},
	Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput:{
		shiftGroups:"Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput",
		shiftsProfiles:"Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput",
		shiftsManagers:"Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"
	},
	Contember_TimetableTemplateUpsertEmployeeRoleRelationInput:{
		update:"Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput",
		create:"Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"
	},
	Contember_VenueUpsertTimetableTemplatesRelationInput:{
		by:"Contember_TimetableTemplateUniqueWhere",
		update:"Contember_TimetableTemplateWithoutVenueUpdateInput",
		create:"Contember_TimetableTemplateWithoutVenueCreateInput"
	},
	Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput:{
		create:"Contember_PointOfSaleTerminalWithoutVenueCreateInput",
		update:"Contember_VenueUpdatePointOfSaleTerminalsRelationInput",
		upsert:"Contember_VenueUpsertPointOfSaleTerminalsRelationInput",
		connect:"Contember_PointOfSaleTerminalUniqueWhere",
		disconnect:"Contember_PointOfSaleTerminalUniqueWhere",
		delete:"Contember_PointOfSaleTerminalUniqueWhere"
	},
	Contember_VenueUpdatePointOfSaleTerminalsRelationInput:{
		by:"Contember_PointOfSaleTerminalUniqueWhere",
		data:"Contember_PointOfSaleTerminalWithoutVenueUpdateInput"
	},
	Contember_PointOfSaleTerminalWithoutVenueUpdateInput:{
		applicationDevices:"Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput",
		type:"Contember_PointOfSaleTerminalType"
	},
	Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput:{
		create:"Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput",
		update:"Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput",
		upsert:"Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput",
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		disconnect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		delete:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		data:"Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput:{
		venue:"Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput",
		identificationCode:"Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput",
		update:"Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput",
		upsert:"Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdatePointOfSalePrintersEntityRelationInput:{
		create:"Contember_PointOfSalePrinterWithoutVenueCreateInput",
		update:"Contember_VenueUpdatePointOfSalePrintersRelationInput",
		upsert:"Contember_VenueUpsertPointOfSalePrintersRelationInput",
		connect:"Contember_PointOfSalePrinterUniqueWhere",
		disconnect:"Contember_PointOfSalePrinterUniqueWhere",
		delete:"Contember_PointOfSalePrinterUniqueWhere"
	},
	Contember_VenueUpdatePointOfSalePrintersRelationInput:{
		by:"Contember_PointOfSalePrinterUniqueWhere",
		data:"Contember_PointOfSalePrinterWithoutVenueUpdateInput"
	},
	Contember_PointOfSalePrinterWithoutVenueUpdateInput:{
		applicationDevicesReceipt:"Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput",
		applicationDevicesTicket:"Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput",
		ticketCategories:"Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"
	},
	Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput:{
		create:"Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput",
		update:"Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput",
		upsert:"Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput",
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		disconnect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		delete:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		data:"Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput:{
		venue:"Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput",
		identificationCode:"Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput:{
		create:"Contember_DeviceIdentifierWithoutDeviceCreateInput",
		update:"Contember_DeviceIdentifierWithoutDeviceUpdateInput",
		upsert:"Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput",
		connect:"Contember_DeviceIdentifierUniqueWhere"
	},
	Contember_DeviceIdentifierWithoutDeviceUpdateInput:{
		updatedAt:"DateTime",
		updatedByUser:"Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput"
	},
	Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput:{
		create:"Contember_UserCreateInput",
		update:"Contember_UserUpdateInput",
		upsert:"Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput",
		connect:"Contember_UserUniqueWhere"
	},
	Contember_UserUpdateInput:{
		staff:"Contember_UserUpdateStaffEntityRelationInput",
		customer:"Contember_UserUpdateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserUpdateDevicesEntityRelationInput",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserUpdateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserUpdateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserUpdateRolesEntityRelationInput"
	},
	Contember_UserUpdateMetaEntityRelationInput:{
		connect:"Contember_UserMetadataUniqueWhere"
	},
	Contember_UserUpdateIdentificationAttemptsEntityRelationInput:{
		create:"Contember_IdentificationAttemptWithoutUserCreateInput",
		update:"Contember_UserUpdateIdentificationAttemptsRelationInput",
		upsert:"Contember_UserUpsertIdentificationAttemptsRelationInput",
		connect:"Contember_IdentificationAttemptUniqueWhere",
		disconnect:"Contember_IdentificationAttemptUniqueWhere",
		delete:"Contember_IdentificationAttemptUniqueWhere"
	},
	Contember_UserUpdateIdentificationAttemptsRelationInput:{
		by:"Contember_IdentificationAttemptUniqueWhere",
		data:"Contember_IdentificationAttemptWithoutUserUpdateInput"
	},
	Contember_IdentificationAttemptWithoutUserUpdateInput:{
		createdAt:"DateTime",
		code:"Contember_IdentificationAttemptUpdateCodeEntityRelationInput"
	},
	Contember_IdentificationAttemptUpdateCodeEntityRelationInput:{
		create:"Contember_IdentificationCodeWithoutAttemptsCreateInput",
		update:"Contember_IdentificationCodeWithoutAttemptsUpdateInput",
		upsert:"Contember_IdentificationAttemptUpsertCodeRelationInput",
		connect:"Contember_IdentificationCodeUniqueWhere"
	},
	Contember_IdentificationCodeWithoutAttemptsUpdateInput:{
		createdAt:"DateTime",
		locales:"Contember_IdentificationCodeUpdateLocalesEntityRelationInput",
		applicationDevices:"Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"
	},
	Contember_IdentificationCodeUpdateLocalesEntityRelationInput:{
		create:"Contember_IdentificationCodeLocaleWithoutRootCreateInput",
		update:"Contember_IdentificationCodeUpdateLocalesRelationInput",
		upsert:"Contember_IdentificationCodeUpsertLocalesRelationInput",
		connect:"Contember_IdentificationCodeLocaleUniqueWhere",
		disconnect:"Contember_IdentificationCodeLocaleUniqueWhere",
		delete:"Contember_IdentificationCodeLocaleUniqueWhere"
	},
	Contember_IdentificationCodeUpdateLocalesRelationInput:{
		by:"Contember_IdentificationCodeLocaleUniqueWhere",
		data:"Contember_IdentificationCodeLocaleWithoutRootUpdateInput"
	},
	Contember_IdentificationCodeLocaleWithoutRootUpdateInput:{
		locale:"Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutIdentificationCodesCreateInput",
		update:"Contember_LocaleWithoutIdentificationCodesUpdateInput",
		upsert:"Contember_IdentificationCodeLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutIdentificationCodesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateProductsEntityRelationInput:{
		create:"Contember_ProductLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateProductsRelationInput",
		upsert:"Contember_LocaleUpsertProductsRelationInput",
		connect:"Contember_ProductLocaleUniqueWhere",
		disconnect:"Contember_ProductLocaleUniqueWhere",
		delete:"Contember_ProductLocaleUniqueWhere"
	},
	Contember_LocaleUpdateProductsRelationInput:{
		by:"Contember_ProductLocaleUniqueWhere",
		data:"Contember_ProductLocaleWithoutLocaleUpdateInput"
	},
	Contember_ProductLocaleWithoutLocaleUpdateInput:{
		root:"Contember_ProductLocaleUpdateRootEntityRelationInput",
		featureList:"Contember_ProductLocaleUpdateFeatureListEntityRelationInput"
	},
	Contember_ProductLocaleUpdateRootEntityRelationInput:{
		create:"Contember_ProductWithoutLocalesCreateInput",
		update:"Contember_ProductWithoutLocalesUpdateInput",
		upsert:"Contember_ProductLocaleUpsertRootRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductWithoutLocalesUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		orderItems:"Contember_ProductUpdateOrderItemsEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_ProductLocaleUpsertRootRelationInput:{
		update:"Contember_ProductWithoutLocalesUpdateInput",
		create:"Contember_ProductWithoutLocalesCreateInput"
	},
	Contember_ProductLocaleUpdateFeatureListEntityRelationInput:{
		create:"Contember_TextListCreateInput",
		update:"Contember_TextListUpdateInput",
		upsert:"Contember_ProductLocaleUpsertFeatureListRelationInput",
		connect:"Contember_TextListUniqueWhere"
	},
	Contember_ProductLocaleUpsertFeatureListRelationInput:{
		update:"Contember_TextListUpdateInput",
		create:"Contember_TextListCreateInput"
	},
	Contember_LocaleUpsertProductsRelationInput:{
		by:"Contember_ProductLocaleUniqueWhere",
		update:"Contember_ProductLocaleWithoutLocaleUpdateInput",
		create:"Contember_ProductLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput",
		upsert:"Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput",
		connect:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		disconnect:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		delete:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere"
	},
	Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput:{
		by:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		data:"Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"
	},
	Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput:{
		root:"Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput",
		update:"Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput",
		upsert:"Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput",
		connect:"Contember_PointOfSaleCategoryProductUniqueWhere"
	},
	Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput:{
		category:"Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput",
		product:"Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryWithoutProductsCreateInput",
		update:"Contember_PointOfSaleCategoryWithoutProductsUpdateInput",
		upsert:"Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput",
		connect:"Contember_PointOfSaleCategoryUniqueWhere"
	},
	Contember_PointOfSaleCategoryWithoutProductsUpdateInput:{
		locales:"Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput",
		category:"Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput",
		venue:"Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput",
		update:"Contember_PointOfSaleCategoryUpdateLocalesRelationInput",
		upsert:"Contember_PointOfSaleCategoryUpsertLocalesRelationInput",
		connect:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		disconnect:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		delete:"Contember_PointOfSaleCategoryLocaleUniqueWhere"
	},
	Contember_PointOfSaleCategoryUpdateLocalesRelationInput:{
		by:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		data:"Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"
	},
	Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput:{
		locale:"Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutPointOfSaleCategoriesCreateInput",
		update:"Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput",
		upsert:"Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateDeliveryMethodsEntityRelationInput:{
		create:"Contember_DeliveryMethodLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateDeliveryMethodsRelationInput",
		upsert:"Contember_LocaleUpsertDeliveryMethodsRelationInput",
		connect:"Contember_DeliveryMethodLocaleUniqueWhere",
		disconnect:"Contember_DeliveryMethodLocaleUniqueWhere",
		delete:"Contember_DeliveryMethodLocaleUniqueWhere"
	},
	Contember_LocaleUpdateDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodLocaleUniqueWhere",
		data:"Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"
	},
	Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput:{
		root:"Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput"
	},
	Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput:{
		create:"Contember_DeliveryMethodWithoutLocalesCreateInput",
		update:"Contember_DeliveryMethodWithoutLocalesUpdateInput",
		upsert:"Contember_DeliveryMethodLocaleUpsertRootRelationInput",
		connect:"Contember_DeliveryMethodUniqueWhere"
	},
	Contember_DeliveryMethodWithoutLocalesUpdateInput:{
		paymentMethods:"Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput",
		suitableForDeliveryZones:"Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput",
		channels:"Contember_DeliveryMethodUpdateChannelsEntityRelationInput",
		courierHandlers:"Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput",
		timelinePreset:"Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"
	},
	Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput:{
		create:"Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput",
		update:"Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput",
		upsert:"Contember_DeliveryMethodUpsertTimelinePresetRelationInput",
		connect:"Contember_DeliveryTimelinePresetUniqueWhere"
	},
	Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput:{
		type:"Contember_DeliveryTimelineType"
	},
	Contember_DeliveryMethodUpsertTimelinePresetRelationInput:{
		update:"Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput",
		create:"Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"
	},
	Contember_DeliveryMethodLocaleUpsertRootRelationInput:{
		update:"Contember_DeliveryMethodWithoutLocalesUpdateInput",
		create:"Contember_DeliveryMethodWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodLocaleUniqueWhere",
		update:"Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput",
		create:"Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdateIngredientSuppliersEntityRelationInput:{
		create:"Contember_IngredientSupplierLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateIngredientSuppliersRelationInput",
		upsert:"Contember_LocaleUpsertIngredientSuppliersRelationInput",
		connect:"Contember_IngredientSupplierLocaleUniqueWhere",
		disconnect:"Contember_IngredientSupplierLocaleUniqueWhere",
		delete:"Contember_IngredientSupplierLocaleUniqueWhere"
	},
	Contember_LocaleUpdateIngredientSuppliersRelationInput:{
		by:"Contember_IngredientSupplierLocaleUniqueWhere",
		data:"Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"
	},
	Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput:{
		root:"Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput"
	},
	Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput:{
		create:"Contember_IngredientSupplierWithoutLocalesCreateInput",
		update:"Contember_IngredientSupplierWithoutLocalesUpdateInput",
		upsert:"Contember_IngredientSupplierLocaleUpsertRootRelationInput",
		connect:"Contember_IngredientSupplierUniqueWhere"
	},
	Contember_IngredientSupplierWithoutLocalesUpdateInput:{

	},
	Contember_IngredientSupplierLocaleUpsertRootRelationInput:{
		update:"Contember_IngredientSupplierWithoutLocalesUpdateInput",
		create:"Contember_IngredientSupplierWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertIngredientSuppliersRelationInput:{
		by:"Contember_IngredientSupplierLocaleUniqueWhere",
		update:"Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput",
		create:"Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdateIdentificationCodesEntityRelationInput:{
		create:"Contember_IdentificationCodeLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateIdentificationCodesRelationInput",
		upsert:"Contember_LocaleUpsertIdentificationCodesRelationInput",
		connect:"Contember_IdentificationCodeLocaleUniqueWhere",
		disconnect:"Contember_IdentificationCodeLocaleUniqueWhere",
		delete:"Contember_IdentificationCodeLocaleUniqueWhere"
	},
	Contember_LocaleUpdateIdentificationCodesRelationInput:{
		by:"Contember_IdentificationCodeLocaleUniqueWhere",
		data:"Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"
	},
	Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput:{
		root:"Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput"
	},
	Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput:{
		create:"Contember_IdentificationCodeWithoutLocalesCreateInput",
		update:"Contember_IdentificationCodeWithoutLocalesUpdateInput",
		upsert:"Contember_IdentificationCodeLocaleUpsertRootRelationInput",
		connect:"Contember_IdentificationCodeUniqueWhere"
	},
	Contember_IdentificationCodeWithoutLocalesUpdateInput:{
		createdAt:"DateTime",
		attempts:"Contember_IdentificationCodeUpdateAttemptsEntityRelationInput",
		applicationDevices:"Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"
	},
	Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput:{
		create:"Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput",
		update:"Contember_IdentificationCodeUpdateApplicationDevicesRelationInput",
		upsert:"Contember_IdentificationCodeUpsertApplicationDevicesRelationInput",
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		disconnect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		delete:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_IdentificationCodeUpdateApplicationDevicesRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		data:"Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput:{
		venue:"Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput:{
		create:"Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput",
		update:"Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput",
		upsert:"Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput",
		connect:"Contember_PointOfSaleTerminalUniqueWhere"
	},
	Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput:{
		venue:"Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput",
		type:"Contember_PointOfSaleTerminalType"
	},
	Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutPointOfSaleTerminalsCreateInput",
		update:"Contember_VenueWithoutPointOfSaleTerminalsUpdateInput",
		upsert:"Contember_PointOfSaleTerminalUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutPointOfSaleTerminalsUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdateOrdersEntityRelationInput:{
		create:"Contember_OrderWithoutVenueCreateInput",
		update:"Contember_VenueUpdateOrdersRelationInput",
		upsert:"Contember_VenueUpsertOrdersRelationInput",
		connect:"Contember_OrderUniqueWhere",
		disconnect:"Contember_OrderUniqueWhere",
		delete:"Contember_OrderUniqueWhere"
	},
	Contember_VenueUpdateOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		data:"Contember_OrderWithoutVenueUpdateInput"
	},
	Contember_OrderWithoutVenueUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateItemsEntityRelationInput:{
		create:"Contember_OrderItemWithoutOrderCreateInput",
		update:"Contember_OrderUpdateItemsRelationInput",
		upsert:"Contember_OrderUpsertItemsRelationInput",
		connect:"Contember_OrderItemUniqueWhere",
		disconnect:"Contember_OrderItemUniqueWhere",
		delete:"Contember_OrderItemUniqueWhere"
	},
	Contember_OrderUpdateItemsRelationInput:{
		by:"Contember_OrderItemUniqueWhere",
		data:"Contember_OrderItemWithoutOrderUpdateInput"
	},
	Contember_OrderItemWithoutOrderUpdateInput:{
		vatRate:"Contember_OrderItemUpdateVatRateEntityRelationInput",
		virtualProductEffects:"Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput",
		pickedAt:"DateTime",
		fulfillmentNotes:"Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput",
		product:"Contember_OrderItemUpdateProductEntityRelationInput"
	},
	Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput:{
		create:"Contember_VirtualProductEffectWithoutOrderItemCreateInput",
		update:"Contember_OrderItemUpdateVirtualProductEffectsRelationInput",
		upsert:"Contember_OrderItemUpsertVirtualProductEffectsRelationInput",
		connect:"Contember_VirtualProductEffectUniqueWhere",
		disconnect:"Contember_VirtualProductEffectUniqueWhere",
		delete:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_OrderItemUpdateVirtualProductEffectsRelationInput:{
		by:"Contember_VirtualProductEffectUniqueWhere",
		data:"Contember_VirtualProductEffectWithoutOrderItemUpdateInput"
	},
	Contember_VirtualProductEffectWithoutOrderItemUpdateInput:{
		createdAt:"DateTime",
		succeededAt:"DateTime",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultCustomerCreditTransaction:"Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput",
		resultVouchers:"Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"
	},
	Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput:{
		create:"Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput",
		update:"Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput",
		upsert:"Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput",
		connect:"Contember_CustomerCreditTransactionUniqueWhere"
	},
	Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput:{
		createdAt:"DateTime",
		customerCredit:"Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput",
		orderDiscount:"Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"
	},
	Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput:{
		create:"Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput",
		update:"Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput",
		upsert:"Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput",
		connect:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput:{
		vatRate:"Contember_OrderDiscountUpdateVatRateEntityRelationInput",
		order:"Contember_OrderDiscountUpdateOrderEntityRelationInput",
		type:"Contember_OrderDiscountType",
		voucherRedemption:"Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"
	},
	Contember_OrderDiscountUpdateOrderEntityRelationInput:{
		create:"Contember_OrderWithoutDiscountsCreateInput",
		update:"Contember_OrderWithoutDiscountsUpdateInput",
		upsert:"Contember_OrderDiscountUpsertOrderRelationInput",
		connect:"Contember_OrderUniqueWhere"
	},
	Contember_OrderWithoutDiscountsUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateCartEntityRelationInput:{
		create:"Contember_CartWithoutOrderCreateInput",
		update:"Contember_CartWithoutOrderUpdateInput",
		upsert:"Contember_OrderUpsertCartRelationInput",
		connect:"Contember_CartUniqueWhere"
	},
	Contember_CartWithoutOrderUpdateInput:{
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		items:"Contember_CartUpdateItemsEntityRelationInput",
		state:"Contember_CartState",
		createdAt:"DateTime",
		channel:"Contember_CartUpdateChannelEntityRelationInput",
		customer:"Contember_CartUpdateCustomerEntityRelationInput",
		deliveryAddress:"Contember_CartUpdateDeliveryAddressEntityRelationInput",
		billingAddress:"Contember_CartUpdateBillingAddressEntityRelationInput",
		delivery:"Contember_CartUpdateDeliveryEntityRelationInput",
		payment:"Contember_CartUpdatePaymentEntityRelationInput",
		billingSubject:"Contember_CartUpdateBillingSubjectEntityRelationInput",
		priceLines:"Contember_CartUpdatePriceLinesEntityRelationInput",
		summary:"Contember_CartUpdateSummaryEntityRelationInput"
	},
	Contember_CartUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutCartsCreateInput",
		update:"Contember_CustomerWithoutCartsUpdateInput",
		upsert:"Contember_CartUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutCartsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateInvoicingPlanEntityRelationInput:{
		create:"Contember_InvoicingPlanWithoutCustomerCreateInput",
		update:"Contember_InvoicingPlanWithoutCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertInvoicingPlanRelationInput",
		connect:"Contember_InvoicingPlanUniqueWhere"
	},
	Contember_InvoicingPlanWithoutCustomerUpdateInput:{
		preset:"Contember_InvoicingPlanUpdatePresetEntityRelationInput"
	},
	Contember_InvoicingPlanUpdatePresetEntityRelationInput:{
		create:"Contember_InvoicingPresetWithoutInvoicingPlanCreateInput",
		update:"Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput",
		upsert:"Contember_InvoicingPlanUpsertPresetRelationInput",
		connect:"Contember_InvoicingPresetUniqueWhere"
	},
	Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput:{
		type:"Contember_InvoicingPresetType",
		startsAt:"DateTime",
		endsAt:"DateTime"
	},
	Contember_InvoicingPlanUpsertPresetRelationInput:{
		update:"Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput",
		create:"Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"
	},
	Contember_CustomerUpsertInvoicingPlanRelationInput:{
		update:"Contember_InvoicingPlanWithoutCustomerUpdateInput",
		create:"Contember_InvoicingPlanWithoutCustomerCreateInput"
	},
	Contember_CustomerUpdateBillingSubjectsEntityRelationInput:{
		create:"Contember_BillingSubjectWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateBillingSubjectsRelationInput",
		upsert:"Contember_CustomerUpsertBillingSubjectsRelationInput",
		connect:"Contember_BillingSubjectUniqueWhere",
		disconnect:"Contember_BillingSubjectUniqueWhere",
		delete:"Contember_BillingSubjectUniqueWhere"
	},
	Contember_CustomerUpdateBillingSubjectsRelationInput:{
		by:"Contember_BillingSubjectUniqueWhere",
		data:"Contember_BillingSubjectWithoutCustomerUpdateInput"
	},
	Contember_BillingSubjectWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectUpdateCountryEntityRelationInput",
		invoices:"Contember_BillingSubjectUpdateInvoicesEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput",
		orders:"Contember_BillingSubjectUpdateOrdersEntityRelationInput"
	},
	Contember_BillingSubjectUpdateCountryEntityRelationInput:{
		create:"Contember_CountryCreateInput",
		update:"Contember_CountryUpdateInput",
		upsert:"Contember_BillingSubjectUpsertCountryRelationInput",
		connect:"Contember_CountryUniqueWhere"
	},
	Contember_BillingSubjectUpsertCountryRelationInput:{
		update:"Contember_CountryUpdateInput",
		create:"Contember_CountryCreateInput"
	},
	Contember_BillingSubjectUpdateInvoicesEntityRelationInput:{
		create:"Contember_InvoiceWithoutBillingSubjectCreateInput",
		update:"Contember_BillingSubjectUpdateInvoicesRelationInput",
		upsert:"Contember_BillingSubjectUpsertInvoicesRelationInput",
		connect:"Contember_InvoiceUniqueWhere",
		disconnect:"Contember_InvoiceUniqueWhere",
		delete:"Contember_InvoiceUniqueWhere"
	},
	Contember_BillingSubjectUpdateInvoicesRelationInput:{
		by:"Contember_InvoiceUniqueWhere",
		data:"Contember_InvoiceWithoutBillingSubjectUpdateInput"
	},
	Contember_InvoiceWithoutBillingSubjectUpdateInput:{
		createdAt:"DateTime",
		customer:"Contember_InvoiceUpdateCustomerEntityRelationInput",
		orderPayments:"Contember_InvoiceUpdateOrderPaymentsEntityRelationInput",
		address:"Contember_InvoiceUpdateAddressEntityRelationInput"
	},
	Contember_InvoiceUpdateAddressEntityRelationInput:{
		create:"Contember_AddressWithoutInvoicesCreateInput",
		update:"Contember_AddressWithoutInvoicesUpdateInput",
		upsert:"Contember_InvoiceUpsertAddressRelationInput",
		connect:"Contember_AddressUniqueWhere"
	},
	Contember_AddressWithoutInvoicesUpdateInput:{
		customer:"Contember_AddressUpdateCustomerEntityRelationInput",
		country:"Contember_AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressUpdateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressUpdateMetaEntityRelationInput",
		gps:"Contember_AddressUpdateGpsEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput:{
		create:"Contember_OrderWithoutBillingAddressCreateInput",
		update:"Contember_AddressUpdateBillingAddressOfOrdersRelationInput",
		upsert:"Contember_AddressUpsertBillingAddressOfOrdersRelationInput",
		connect:"Contember_OrderUniqueWhere",
		disconnect:"Contember_OrderUniqueWhere",
		delete:"Contember_OrderUniqueWhere"
	},
	Contember_AddressUpdateBillingAddressOfOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		data:"Contember_OrderWithoutBillingAddressUpdateInput"
	},
	Contember_OrderWithoutBillingAddressUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateEventsEntityRelationInput:{
		create:"Contember_OrderEventWithoutOrderCreateInput",
		update:"Contember_OrderUpdateEventsRelationInput",
		upsert:"Contember_OrderUpsertEventsRelationInput",
		connect:"Contember_OrderEventUniqueWhere",
		disconnect:"Contember_OrderEventUniqueWhere",
		delete:"Contember_OrderEventUniqueWhere"
	},
	Contember_OrderUpdateEventsRelationInput:{
		by:"Contember_OrderEventUniqueWhere",
		data:"Contember_OrderEventWithoutOrderUpdateInput"
	},
	Contember_OrderEventWithoutOrderUpdateInput:{
		createdAt:"DateTime",
		payment:"Contember_OrderEventUpdatePaymentEntityRelationInput",
		delivery:"Contember_OrderEventUpdateDeliveryEntityRelationInput"
	},
	Contember_OrderEventUpdatePaymentEntityRelationInput:{
		create:"Contember_OrderPaymentWithoutEventsCreateInput",
		update:"Contember_OrderPaymentWithoutEventsUpdateInput",
		upsert:"Contember_OrderEventUpsertPaymentRelationInput",
		connect:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_OrderPaymentWithoutEventsUpdateInput:{
		data:"Json",
		method:"Contember_OrderPaymentUpdateMethodEntityRelationInput",
		failedAt:"DateTime",
		state:"Contember_OrderPaymentState",
		approvedAt:"DateTime",
		order:"Contember_OrderPaymentUpdateOrderEntityRelationInput",
		invoice:"Contember_OrderPaymentUpdateInvoiceEntityRelationInput",
		createdAt:"DateTime",
		vatRate:"Contember_OrderPaymentUpdateVatRateEntityRelationInput",
		meta:"Contember_OrderPaymentUpdateMetaEntityRelationInput"
	},
	Contember_OrderPaymentUpdateOrderEntityRelationInput:{
		create:"Contember_OrderWithoutPaymentsCreateInput",
		update:"Contember_OrderWithoutPaymentsUpdateInput",
		upsert:"Contember_OrderPaymentUpsertOrderRelationInput",
		connect:"Contember_OrderUniqueWhere"
	},
	Contember_OrderWithoutPaymentsUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateBillingAddressEntityRelationInput:{
		create:"Contember_AddressWithoutBillingAddressOfOrdersCreateInput",
		update:"Contember_AddressWithoutBillingAddressOfOrdersUpdateInput",
		upsert:"Contember_OrderUpsertBillingAddressRelationInput",
		connect:"Contember_AddressUniqueWhere"
	},
	Contember_AddressWithoutBillingAddressOfOrdersUpdateInput:{
		customer:"Contember_AddressUpdateCustomerEntityRelationInput",
		country:"Contember_AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressUpdateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressUpdateMetaEntityRelationInput",
		gps:"Contember_AddressUpdateGpsEntityRelationInput",
		invoices:"Contember_AddressUpdateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		deliveryAddressOfOrderDeliveries:"Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput:{
		create:"Contember_OrderDeliveryWithoutAddressCreateInput",
		update:"Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput",
		upsert:"Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput",
		connect:"Contember_OrderDeliveryUniqueWhere",
		disconnect:"Contember_OrderDeliveryUniqueWhere",
		delete:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput:{
		by:"Contember_OrderDeliveryUniqueWhere",
		data:"Contember_OrderDeliveryWithoutAddressUpdateInput"
	},
	Contember_OrderDeliveryWithoutAddressUpdateInput:{
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryUpdateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryUpdateVatRateEntityRelationInput",
		order:"Contember_OrderDeliveryUpdateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		deliveryWaypoint:"Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput",
		events:"Contember_OrderDeliveryUpdateEventsEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime"
	},
	Contember_OrderDeliveryUpdateOrderEntityRelationInput:{
		create:"Contember_OrderWithoutDeliveryCreateInput",
		update:"Contember_OrderWithoutDeliveryUpdateInput",
		upsert:"Contember_OrderDeliveryUpsertOrderRelationInput",
		connect:"Contember_OrderUniqueWhere"
	},
	Contember_OrderWithoutDeliveryUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdatePriceLinesEntityRelationInput:{
		connect:"Contember_OrderPriceLineUniqueWhere",
		disconnect:"Contember_OrderPriceLineUniqueWhere",
		delete:"Contember_OrderPriceLineUniqueWhere"
	},
	Contember_OrderUpdatePaymentsEntityRelationInput:{
		create:"Contember_OrderPaymentWithoutOrderCreateInput",
		update:"Contember_OrderUpdatePaymentsRelationInput",
		upsert:"Contember_OrderUpsertPaymentsRelationInput",
		connect:"Contember_OrderPaymentUniqueWhere",
		disconnect:"Contember_OrderPaymentUniqueWhere",
		delete:"Contember_OrderPaymentUniqueWhere"
	},
	Contember_OrderUpdatePaymentsRelationInput:{
		by:"Contember_OrderPaymentUniqueWhere",
		data:"Contember_OrderPaymentWithoutOrderUpdateInput"
	},
	Contember_OrderPaymentWithoutOrderUpdateInput:{
		data:"Json",
		method:"Contember_OrderPaymentUpdateMethodEntityRelationInput",
		failedAt:"DateTime",
		state:"Contember_OrderPaymentState",
		approvedAt:"DateTime",
		events:"Contember_OrderPaymentUpdateEventsEntityRelationInput",
		invoice:"Contember_OrderPaymentUpdateInvoiceEntityRelationInput",
		createdAt:"DateTime",
		vatRate:"Contember_OrderPaymentUpdateVatRateEntityRelationInput",
		meta:"Contember_OrderPaymentUpdateMetaEntityRelationInput"
	},
	Contember_OrderPaymentUpdateInvoiceEntityRelationInput:{
		create:"Contember_InvoiceWithoutOrderPaymentsCreateInput",
		update:"Contember_InvoiceWithoutOrderPaymentsUpdateInput",
		upsert:"Contember_OrderPaymentUpsertInvoiceRelationInput",
		connect:"Contember_InvoiceUniqueWhere"
	},
	Contember_InvoiceWithoutOrderPaymentsUpdateInput:{
		createdAt:"DateTime",
		customer:"Contember_InvoiceUpdateCustomerEntityRelationInput",
		address:"Contember_InvoiceUpdateAddressEntityRelationInput",
		billingSubject:"Contember_InvoiceUpdateBillingSubjectEntityRelationInput"
	},
	Contember_InvoiceUpdateBillingSubjectEntityRelationInput:{
		create:"Contember_BillingSubjectWithoutInvoicesCreateInput",
		update:"Contember_BillingSubjectWithoutInvoicesUpdateInput",
		upsert:"Contember_InvoiceUpsertBillingSubjectRelationInput",
		connect:"Contember_BillingSubjectUniqueWhere"
	},
	Contember_BillingSubjectWithoutInvoicesUpdateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectUpdateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectUpdateCustomerEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput",
		orders:"Contember_BillingSubjectUpdateOrdersEntityRelationInput"
	},
	Contember_BillingSubjectUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutBillingSubjectsCreateInput",
		update:"Contember_CustomerWithoutBillingSubjectsUpdateInput",
		upsert:"Contember_BillingSubjectUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutBillingSubjectsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateOfferedProductsEntityRelationInput:{
		connect:"Contember_ProductCustomerPriceUniqueWhere",
		disconnect:"Contember_ProductCustomerPriceUniqueWhere",
		delete:"Contember_ProductCustomerPriceUniqueWhere"
	},
	Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput:{
		create:"Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput",
		update:"Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertDefaultDeliveryAddressRelationInput",
		connect:"Contember_AddressUniqueWhere"
	},
	Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput:{
		customer:"Contember_AddressUpdateCustomerEntityRelationInput",
		country:"Contember_AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressUpdateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressUpdateMetaEntityRelationInput",
		gps:"Contember_AddressUpdateGpsEntityRelationInput",
		invoices:"Contember_AddressUpdateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput",
		deliveryAddressOfOrderDeliveries:"Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"
	},
	Contember_CustomerUpsertDefaultDeliveryAddressRelationInput:{
		update:"Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput",
		create:"Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"
	},
	Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput:{
		create:"Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput",
		update:"Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertDefaultBillingSubjectRelationInput",
		connect:"Contember_BillingSubjectUniqueWhere"
	},
	Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectUpdateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectUpdateCustomerEntityRelationInput",
		invoices:"Contember_BillingSubjectUpdateInvoicesEntityRelationInput",
		orders:"Contember_BillingSubjectUpdateOrdersEntityRelationInput"
	},
	Contember_BillingSubjectUpdateOrdersEntityRelationInput:{
		create:"Contember_OrderWithoutBillingSubjectCreateInput",
		update:"Contember_BillingSubjectUpdateOrdersRelationInput",
		upsert:"Contember_BillingSubjectUpsertOrdersRelationInput",
		connect:"Contember_OrderUniqueWhere",
		disconnect:"Contember_OrderUniqueWhere",
		delete:"Contember_OrderUniqueWhere"
	},
	Contember_BillingSubjectUpdateOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		data:"Contember_OrderWithoutBillingSubjectUpdateInput"
	},
	Contember_OrderWithoutBillingSubjectUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		fulfillmentNotes:"Contember_OrderUpdateFulfillmentNotesEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateSummaryEntityRelationInput:{
		connect:"Contember_OrderSummaryUniqueWhere"
	},
	Contember_OrderUpdateGratuityEntityRelationInput:{
		create:"Contember_GratuityWithoutOrderCreateInput",
		update:"Contember_GratuityWithoutOrderUpdateInput",
		upsert:"Contember_OrderUpsertGratuityRelationInput",
		connect:"Contember_GratuityUniqueWhere"
	},
	Contember_GratuityWithoutOrderUpdateInput:{

	},
	Contember_OrderUpsertGratuityRelationInput:{
		update:"Contember_GratuityWithoutOrderUpdateInput",
		create:"Contember_GratuityWithoutOrderCreateInput"
	},
	Contember_OrderUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutOrdersCreateInput",
		update:"Contember_VenueWithoutOrdersUpdateInput",
		upsert:"Contember_OrderUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutOrdersUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdateFulfillmentNotesEntityRelationInput:{
		create:"Contember_FulfillmentNoteWithoutVenuesCreateInput",
		update:"Contember_VenueUpdateFulfillmentNotesRelationInput",
		upsert:"Contember_VenueUpsertFulfillmentNotesRelationInput",
		connect:"Contember_FulfillmentNoteUniqueWhere",
		disconnect:"Contember_FulfillmentNoteUniqueWhere",
		delete:"Contember_FulfillmentNoteUniqueWhere"
	},
	Contember_VenueUpdateFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		data:"Contember_FulfillmentNoteWithoutVenuesUpdateInput"
	},
	Contember_FulfillmentNoteWithoutVenuesUpdateInput:{
		products:"Contember_FulfillmentNoteUpdateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteUpdateTagsEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteUpdateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput:{
		create:"Contember_CartItemWithoutFulfillmentNotesCreateInput",
		update:"Contember_FulfillmentNoteUpdateCartItemsRelationInput",
		upsert:"Contember_FulfillmentNoteUpsertCartItemsRelationInput",
		connect:"Contember_CartItemUniqueWhere",
		disconnect:"Contember_CartItemUniqueWhere",
		delete:"Contember_CartItemUniqueWhere"
	},
	Contember_FulfillmentNoteUpdateCartItemsRelationInput:{
		by:"Contember_CartItemUniqueWhere",
		data:"Contember_CartItemWithoutFulfillmentNotesUpdateInput"
	},
	Contember_CartItemWithoutFulfillmentNotesUpdateInput:{
		cart:"Contember_CartItemUpdateCartEntityRelationInput",
		product:"Contember_CartItemUpdateProductEntityRelationInput",
		createdAt:"DateTime",
		updatedAt:"DateTime"
	},
	Contember_FulfillmentNoteUpsertCartItemsRelationInput:{
		by:"Contember_CartItemUniqueWhere",
		update:"Contember_CartItemWithoutFulfillmentNotesUpdateInput",
		create:"Contember_CartItemWithoutFulfillmentNotesCreateInput"
	},
	Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput:{
		create:"Contember_OrderItemWithoutFulfillmentNotesCreateInput",
		update:"Contember_FulfillmentNoteUpdateOrderItemsRelationInput",
		upsert:"Contember_FulfillmentNoteUpsertOrderItemsRelationInput",
		connect:"Contember_OrderItemUniqueWhere",
		disconnect:"Contember_OrderItemUniqueWhere",
		delete:"Contember_OrderItemUniqueWhere"
	},
	Contember_FulfillmentNoteUpdateOrderItemsRelationInput:{
		by:"Contember_OrderItemUniqueWhere",
		data:"Contember_OrderItemWithoutFulfillmentNotesUpdateInput"
	},
	Contember_OrderItemWithoutFulfillmentNotesUpdateInput:{
		vatRate:"Contember_OrderItemUpdateVatRateEntityRelationInput",
		order:"Contember_OrderItemUpdateOrderEntityRelationInput",
		virtualProductEffects:"Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput",
		pickedAt:"DateTime",
		product:"Contember_OrderItemUpdateProductEntityRelationInput"
	},
	Contember_OrderItemUpdateProductEntityRelationInput:{
		create:"Contember_ProductWithoutOrderItemsCreateInput",
		update:"Contember_ProductWithoutOrderItemsUpdateInput",
		upsert:"Contember_OrderItemUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductWithoutOrderItemsUpdateInput:{
		packing:"Contember_ProductUpdatePackingEntityRelationInput",
		recipe:"Contember_ProductUpdateRecipeEntityRelationInput",
		tags:"Contember_ProductUpdateTagsEntityRelationInput",
		categories:"Contember_ProductUpdateCategoriesEntityRelationInput",
		vatRate:"Contember_ProductUpdateVatRateEntityRelationInput",
		stocks:"Contember_ProductUpdateStocksEntityRelationInput",
		groupPrices:"Contember_ProductUpdateGroupPricesEntityRelationInput",
		image:"Contember_ProductUpdateImageEntityRelationInput",
		cartItems:"Contember_ProductUpdateCartItemsEntityRelationInput",
		availability:"Contember_ProductAvailability",
		manager:"Contember_ProductUpdateManagerEntityRelationInput",
		inheritedCategories:"Contember_ProductUpdateInheritedCategoriesEntityRelationInput",
		deliveryZones:"Contember_ProductUpdateDeliveryZonesEntityRelationInput",
		locales:"Contember_ProductUpdateLocalesEntityRelationInput",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductUpdateCustomerPricesEntityRelationInput",
		virtualProduct:"Contember_ProductUpdateVirtualProductEntityRelationInput",
		meta:"Contember_ProductUpdateMetaEntityRelationInput",
		page:"Contember_ProductUpdatePageEntityRelationInput",
		salesDays:"Contember_ProductUpdateSalesDaysEntityRelationInput",
		salesWeeks:"Contember_ProductUpdateSalesWeeksEntityRelationInput",
		salesMonths:"Contember_ProductUpdateSalesMonthsEntityRelationInput",
		fulfillmentNotes:"Contember_ProductUpdateFulfillmentNotesEntityRelationInput"
	},
	Contember_OrderItemUpsertProductRelationInput:{
		update:"Contember_ProductWithoutOrderItemsUpdateInput",
		create:"Contember_ProductWithoutOrderItemsCreateInput"
	},
	Contember_FulfillmentNoteUpsertOrderItemsRelationInput:{
		by:"Contember_OrderItemUniqueWhere",
		update:"Contember_OrderItemWithoutFulfillmentNotesUpdateInput",
		create:"Contember_OrderItemWithoutFulfillmentNotesCreateInput"
	},
	Contember_FulfillmentNoteUpdateOrdersEntityRelationInput:{
		create:"Contember_OrderWithoutFulfillmentNotesCreateInput",
		update:"Contember_FulfillmentNoteUpdateOrdersRelationInput",
		upsert:"Contember_FulfillmentNoteUpsertOrdersRelationInput",
		connect:"Contember_OrderUniqueWhere",
		disconnect:"Contember_OrderUniqueWhere",
		delete:"Contember_OrderUniqueWhere"
	},
	Contember_FulfillmentNoteUpdateOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		data:"Contember_OrderWithoutFulfillmentNotesUpdateInput"
	},
	Contember_OrderWithoutFulfillmentNotesUpdateInput:{
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		customer:"Contember_OrderUpdateCustomerEntityRelationInput",
		channel:"Contember_OrderUpdateChannelEntityRelationInput",
		discounts:"Contember_OrderUpdateDiscountsEntityRelationInput",
		delivery:"Contember_OrderUpdateDeliveryEntityRelationInput",
		items:"Contember_OrderUpdateItemsEntityRelationInput",
		cart:"Contember_OrderUpdateCartEntityRelationInput",
		events:"Contember_OrderUpdateEventsEntityRelationInput",
		createdAt:"DateTime",
		billingAddress:"Contember_OrderUpdateBillingAddressEntityRelationInput",
		priceLines:"Contember_OrderUpdatePriceLinesEntityRelationInput",
		payments:"Contember_OrderUpdatePaymentsEntityRelationInput",
		billingSubject:"Contember_OrderUpdateBillingSubjectEntityRelationInput",
		summary:"Contember_OrderUpdateSummaryEntityRelationInput",
		gratuity:"Contember_OrderUpdateGratuityEntityRelationInput",
		venue:"Contember_OrderUpdateVenueEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_OrderUpdateBillingSubjectEntityRelationInput:{
		create:"Contember_BillingSubjectWithoutOrdersCreateInput",
		update:"Contember_BillingSubjectWithoutOrdersUpdateInput",
		upsert:"Contember_OrderUpsertBillingSubjectRelationInput",
		connect:"Contember_BillingSubjectUniqueWhere"
	},
	Contember_BillingSubjectWithoutOrdersUpdateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectUpdateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectUpdateCustomerEntityRelationInput",
		invoices:"Contember_BillingSubjectUpdateInvoicesEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"
	},
	Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutDefaultBillingSubjectCreateInput",
		update:"Contember_CustomerWithoutDefaultBillingSubjectUpdateInput",
		upsert:"Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutDefaultBillingSubjectUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateAnonymousSessionEntityRelationInput:{
		create:"Contember_AnonymousSessionWithoutCustomerCreateInput",
		update:"Contember_AnonymousSessionWithoutCustomerUpdateInput",
		upsert:"Contember_CustomerUpsertAnonymousSessionRelationInput",
		connect:"Contember_AnonymousSessionUniqueWhere"
	},
	Contember_AnonymousSessionWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		devices:"Contember_AnonymousSessionUpdateDevicesEntityRelationInput"
	},
	Contember_AnonymousSessionUpdateDevicesEntityRelationInput:{
		create:"Contember_UserDeviceWithoutAnonymousSessionCreateInput",
		update:"Contember_AnonymousSessionUpdateDevicesRelationInput",
		upsert:"Contember_AnonymousSessionUpsertDevicesRelationInput",
		connect:"Contember_UserDeviceUniqueWhere",
		disconnect:"Contember_UserDeviceUniqueWhere",
		delete:"Contember_UserDeviceUniqueWhere"
	},
	Contember_AnonymousSessionUpdateDevicesRelationInput:{
		by:"Contember_UserDeviceUniqueWhere",
		data:"Contember_UserDeviceWithoutAnonymousSessionUpdateInput"
	},
	Contember_UserDeviceWithoutAnonymousSessionUpdateInput:{
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		user:"Contember_UserDeviceUpdateUserEntityRelationInput"
	},
	Contember_UserDeviceUpdateUserEntityRelationInput:{
		create:"Contember_UserWithoutDevicesCreateInput",
		update:"Contember_UserWithoutDevicesUpdateInput",
		upsert:"Contember_UserDeviceUpsertUserRelationInput",
		connect:"Contember_UserUniqueWhere"
	},
	Contember_UserWithoutDevicesUpdateInput:{
		staff:"Contember_UserUpdateStaffEntityRelationInput",
		customer:"Contember_UserUpdateCustomerEntityRelationInput",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		verifiedAt:"DateTime",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserUpdateMetaEntityRelationInput",
		identificationAttempts:"Contember_UserUpdateIdentificationAttemptsEntityRelationInput",
		roles:"Contember_UserUpdateRolesEntityRelationInput"
	},
	Contember_UserUpdateRolesEntityRelationInput:{
		connect:"Contember_UserRolesUniqueWhere"
	},
	Contember_UserDeviceUpsertUserRelationInput:{
		update:"Contember_UserWithoutDevicesUpdateInput",
		create:"Contember_UserWithoutDevicesCreateInput"
	},
	Contember_AnonymousSessionUpsertDevicesRelationInput:{
		by:"Contember_UserDeviceUniqueWhere",
		update:"Contember_UserDeviceWithoutAnonymousSessionUpdateInput",
		create:"Contember_UserDeviceWithoutAnonymousSessionCreateInput"
	},
	Contember_CustomerUpsertAnonymousSessionRelationInput:{
		update:"Contember_AnonymousSessionWithoutCustomerUpdateInput",
		create:"Contember_AnonymousSessionWithoutCustomerCreateInput"
	},
	Contember_CustomerUpdateBoughtVouchersEntityRelationInput:{
		create:"Contember_VoucherWithoutBuyerCreateInput",
		update:"Contember_CustomerUpdateBoughtVouchersRelationInput",
		upsert:"Contember_CustomerUpsertBoughtVouchersRelationInput",
		connect:"Contember_VoucherUniqueWhere",
		disconnect:"Contember_VoucherUniqueWhere",
		delete:"Contember_VoucherUniqueWhere"
	},
	Contember_CustomerUpdateBoughtVouchersRelationInput:{
		by:"Contember_VoucherUniqueWhere",
		data:"Contember_VoucherWithoutBuyerUpdateInput"
	},
	Contember_VoucherWithoutBuyerUpdateInput:{
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		owner:"Contember_VoucherUpdateOwnerEntityRelationInput",
		vatRate:"Contember_VoucherUpdateVatRateEntityRelationInput",
		virtualProductEffect:"Contember_VoucherUpdateVirtualProductEffectEntityRelationInput",
		redemption:"Contember_VoucherUpdateRedemptionEntityRelationInput"
	},
	Contember_VoucherUpdateOwnerEntityRelationInput:{
		create:"Contember_CustomerWithoutOwnsVouchersCreateInput",
		update:"Contember_CustomerWithoutOwnsVouchersUpdateInput",
		upsert:"Contember_VoucherUpsertOwnerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutOwnsVouchersUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateRedeemedVouchersEntityRelationInput:{
		create:"Contember_VoucherRedemptionWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateRedeemedVouchersRelationInput",
		upsert:"Contember_CustomerUpsertRedeemedVouchersRelationInput",
		connect:"Contember_VoucherRedemptionUniqueWhere",
		disconnect:"Contember_VoucherRedemptionUniqueWhere",
		delete:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_CustomerUpdateRedeemedVouchersRelationInput:{
		by:"Contember_VoucherRedemptionUniqueWhere",
		data:"Contember_VoucherRedemptionWithoutCustomerUpdateInput"
	},
	Contember_VoucherRedemptionWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		customerCredit:"Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput",
		orderDiscount:"Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput",
		voucher:"Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"
	},
	Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput:{
		create:"Contember_CustomerCreditWithoutVoucherRedemptionCreateInput",
		update:"Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput",
		upsert:"Contember_VoucherRedemptionUpsertCustomerCreditRelationInput",
		connect:"Contember_CustomerCreditUniqueWhere"
	},
	Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput:{
		expiresAt:"DateTime",
		customer:"Contember_CustomerCreditUpdateCustomerEntityRelationInput",
		vatRate:"Contember_CustomerCreditUpdateVatRateEntityRelationInput",
		createdAt:"DateTime",
		transactions:"Contember_CustomerCreditUpdateTransactionsEntityRelationInput"
	},
	Contember_CustomerCreditUpdateTransactionsEntityRelationInput:{
		create:"Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput",
		update:"Contember_CustomerCreditUpdateTransactionsRelationInput",
		upsert:"Contember_CustomerCreditUpsertTransactionsRelationInput",
		connect:"Contember_CustomerCreditTransactionUniqueWhere",
		disconnect:"Contember_CustomerCreditTransactionUniqueWhere",
		delete:"Contember_CustomerCreditTransactionUniqueWhere"
	},
	Contember_CustomerCreditUpdateTransactionsRelationInput:{
		by:"Contember_CustomerCreditTransactionUniqueWhere",
		data:"Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"
	},
	Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput:{
		createdAt:"DateTime",
		orderDiscount:"Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput",
		virtualProductEffect:"Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"
	},
	Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput:{
		create:"Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput",
		update:"Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput",
		upsert:"Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput",
		connect:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput:{
		createdAt:"DateTime",
		succeededAt:"DateTime",
		orderItem:"Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultVouchers:"Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"
	},
	Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput:{
		create:"Contember_OrderItemWithoutVirtualProductEffectsCreateInput",
		update:"Contember_OrderItemWithoutVirtualProductEffectsUpdateInput",
		upsert:"Contember_VirtualProductEffectUpsertOrderItemRelationInput",
		connect:"Contember_OrderItemUniqueWhere"
	},
	Contember_OrderItemWithoutVirtualProductEffectsUpdateInput:{
		vatRate:"Contember_OrderItemUpdateVatRateEntityRelationInput",
		order:"Contember_OrderItemUpdateOrderEntityRelationInput",
		pickedAt:"DateTime",
		fulfillmentNotes:"Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput",
		product:"Contember_OrderItemUpdateProductEntityRelationInput"
	},
	Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput:{
		create:"Contember_FulfillmentNoteWithoutOrderItemsCreateInput",
		update:"Contember_OrderItemUpdateFulfillmentNotesRelationInput",
		upsert:"Contember_OrderItemUpsertFulfillmentNotesRelationInput",
		connect:"Contember_FulfillmentNoteUniqueWhere",
		disconnect:"Contember_FulfillmentNoteUniqueWhere",
		delete:"Contember_FulfillmentNoteUniqueWhere"
	},
	Contember_OrderItemUpdateFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		data:"Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"
	},
	Contember_FulfillmentNoteWithoutOrderItemsUpdateInput:{
		products:"Contember_FulfillmentNoteUpdateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteUpdateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteUpdateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteUpdateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_OrderItemUpsertFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		update:"Contember_FulfillmentNoteWithoutOrderItemsUpdateInput",
		create:"Contember_FulfillmentNoteWithoutOrderItemsCreateInput"
	},
	Contember_VirtualProductEffectUpsertOrderItemRelationInput:{
		update:"Contember_OrderItemWithoutVirtualProductEffectsUpdateInput",
		create:"Contember_OrderItemWithoutVirtualProductEffectsCreateInput"
	},
	Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput:{
		create:"Contember_VoucherWithoutVirtualProductEffectCreateInput",
		update:"Contember_VirtualProductEffectUpdateResultVouchersRelationInput",
		upsert:"Contember_VirtualProductEffectUpsertResultVouchersRelationInput",
		connect:"Contember_VoucherUniqueWhere",
		disconnect:"Contember_VoucherUniqueWhere",
		delete:"Contember_VoucherUniqueWhere"
	},
	Contember_VirtualProductEffectUpdateResultVouchersRelationInput:{
		by:"Contember_VoucherUniqueWhere",
		data:"Contember_VoucherWithoutVirtualProductEffectUpdateInput"
	},
	Contember_VoucherWithoutVirtualProductEffectUpdateInput:{
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		buyer:"Contember_VoucherUpdateBuyerEntityRelationInput",
		owner:"Contember_VoucherUpdateOwnerEntityRelationInput",
		vatRate:"Contember_VoucherUpdateVatRateEntityRelationInput",
		redemption:"Contember_VoucherUpdateRedemptionEntityRelationInput"
	},
	Contember_VoucherUpdateBuyerEntityRelationInput:{
		create:"Contember_CustomerWithoutBoughtVouchersCreateInput",
		update:"Contember_CustomerWithoutBoughtVouchersUpdateInput",
		upsert:"Contember_VoucherUpsertBuyerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutBoughtVouchersUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateOwnsVouchersEntityRelationInput:{
		create:"Contember_VoucherWithoutOwnerCreateInput",
		update:"Contember_CustomerUpdateOwnsVouchersRelationInput",
		upsert:"Contember_CustomerUpsertOwnsVouchersRelationInput",
		connect:"Contember_VoucherUniqueWhere",
		disconnect:"Contember_VoucherUniqueWhere",
		delete:"Contember_VoucherUniqueWhere"
	},
	Contember_CustomerUpdateOwnsVouchersRelationInput:{
		by:"Contember_VoucherUniqueWhere",
		data:"Contember_VoucherWithoutOwnerUpdateInput"
	},
	Contember_VoucherWithoutOwnerUpdateInput:{
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		buyer:"Contember_VoucherUpdateBuyerEntityRelationInput",
		vatRate:"Contember_VoucherUpdateVatRateEntityRelationInput",
		virtualProductEffect:"Contember_VoucherUpdateVirtualProductEffectEntityRelationInput",
		redemption:"Contember_VoucherUpdateRedemptionEntityRelationInput"
	},
	Contember_VoucherUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_VoucherUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_VoucherUpsertVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_VoucherUpdateVirtualProductEffectEntityRelationInput:{
		create:"Contember_VirtualProductEffectWithoutResultVouchersCreateInput",
		update:"Contember_VirtualProductEffectWithoutResultVouchersUpdateInput",
		upsert:"Contember_VoucherUpsertVirtualProductEffectRelationInput",
		connect:"Contember_VirtualProductEffectUniqueWhere"
	},
	Contember_VirtualProductEffectWithoutResultVouchersUpdateInput:{
		createdAt:"DateTime",
		succeededAt:"DateTime",
		orderItem:"Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultCustomerCreditTransaction:"Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"
	},
	Contember_VoucherUpsertVirtualProductEffectRelationInput:{
		update:"Contember_VirtualProductEffectWithoutResultVouchersUpdateInput",
		create:"Contember_VirtualProductEffectWithoutResultVouchersCreateInput"
	},
	Contember_VoucherUpdateRedemptionEntityRelationInput:{
		create:"Contember_VoucherRedemptionWithoutVoucherCreateInput",
		update:"Contember_VoucherRedemptionWithoutVoucherUpdateInput",
		upsert:"Contember_VoucherUpsertRedemptionRelationInput",
		connect:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_VoucherRedemptionWithoutVoucherUpdateInput:{
		createdAt:"DateTime",
		customerCredit:"Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput",
		orderDiscount:"Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput",
		customer:"Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"
	},
	Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput:{
		create:"Contember_OrderDiscountWithoutVoucherRedemptionCreateInput",
		update:"Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput",
		upsert:"Contember_VoucherRedemptionUpsertOrderDiscountRelationInput",
		connect:"Contember_OrderDiscountUniqueWhere"
	},
	Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput:{
		vatRate:"Contember_OrderDiscountUpdateVatRateEntityRelationInput",
		order:"Contember_OrderDiscountUpdateOrderEntityRelationInput",
		type:"Contember_OrderDiscountType",
		customerCreditTransactions:"Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"
	},
	Contember_VoucherRedemptionUpsertOrderDiscountRelationInput:{
		update:"Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput",
		create:"Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"
	},
	Contember_VoucherRedemptionUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutRedeemedVouchersCreateInput",
		update:"Contember_CustomerWithoutRedeemedVouchersUpdateInput",
		upsert:"Contember_VoucherRedemptionUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutRedeemedVouchersUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateSpendingEntityRelationInput:{
		connect:"Contember_CustomerSpendingUniqueWhere"
	},
	Contember_CustomerUpdateCustomerTabsEntityRelationInput:{
		create:"Contember_CustomerTabWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateCustomerTabsRelationInput",
		upsert:"Contember_CustomerUpsertCustomerTabsRelationInput",
		connect:"Contember_CustomerTabUniqueWhere",
		disconnect:"Contember_CustomerTabUniqueWhere",
		delete:"Contember_CustomerTabUniqueWhere"
	},
	Contember_CustomerUpdateCustomerTabsRelationInput:{
		by:"Contember_CustomerTabUniqueWhere",
		data:"Contember_CustomerTabWithoutCustomerUpdateInput"
	},
	Contember_CustomerTabWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		items:"Contember_CustomerTabUpdateItemsEntityRelationInput",
		meta:"Contember_CustomerTabUpdateMetaEntityRelationInput",
		table:"Contember_CustomerTabUpdateTableEntityRelationInput",
		seat:"Contember_CustomerTabUpdateSeatEntityRelationInput"
	},
	Contember_CustomerTabUpdateItemsEntityRelationInput:{
		create:"Contember_CustomerTabItemWithoutTabCreateInput",
		update:"Contember_CustomerTabUpdateItemsRelationInput",
		upsert:"Contember_CustomerTabUpsertItemsRelationInput",
		connect:"Contember_CustomerTabItemUniqueWhere",
		disconnect:"Contember_CustomerTabItemUniqueWhere",
		delete:"Contember_CustomerTabItemUniqueWhere"
	},
	Contember_CustomerTabUpdateItemsRelationInput:{
		by:"Contember_CustomerTabItemUniqueWhere",
		data:"Contember_CustomerTabItemWithoutTabUpdateInput"
	},
	Contember_CustomerTabItemWithoutTabUpdateInput:{
		addedAt:"DateTime",
		product:"Contember_CustomerTabItemUpdateProductEntityRelationInput",
		paidAt:"DateTime"
	},
	Contember_CustomerTabItemUpdateProductEntityRelationInput:{
		create:"Contember_ProductCreateInput",
		update:"Contember_ProductUpdateInput",
		upsert:"Contember_CustomerTabItemUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_CustomerTabItemUpsertProductRelationInput:{
		update:"Contember_ProductUpdateInput",
		create:"Contember_ProductCreateInput"
	},
	Contember_CustomerTabUpsertItemsRelationInput:{
		by:"Contember_CustomerTabItemUniqueWhere",
		update:"Contember_CustomerTabItemWithoutTabUpdateInput",
		create:"Contember_CustomerTabItemWithoutTabCreateInput"
	},
	Contember_CustomerTabUpdateMetaEntityRelationInput:{
		connect:"Contember_CustomerTabMetadataUniqueWhere"
	},
	Contember_CustomerTabUpdateTableEntityRelationInput:{
		create:"Contember_TableWithoutCustomerTabsCreateInput",
		update:"Contember_TableWithoutCustomerTabsUpdateInput",
		upsert:"Contember_CustomerTabUpsertTableRelationInput",
		connect:"Contember_TableUniqueWhere"
	},
	Contember_TableWithoutCustomerTabsUpdateInput:{
		shape:"Contember_TableShape",
		area:"Contember_TableUpdateAreaEntityRelationInput",
		seats:"Contember_TableUpdateSeatsEntityRelationInput"
	},
	Contember_TableUpdateAreaEntityRelationInput:{
		create:"Contember_AreaWithoutTablesCreateInput",
		update:"Contember_AreaWithoutTablesUpdateInput",
		upsert:"Contember_TableUpsertAreaRelationInput",
		connect:"Contember_AreaUniqueWhere"
	},
	Contember_AreaWithoutTablesUpdateInput:{
		venue:"Contember_AreaUpdateVenueEntityRelationInput",
		lines:"Contember_AreaUpdateLinesEntityRelationInput"
	},
	Contember_AreaUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutAreasCreateInput",
		update:"Contember_VenueWithoutAreasUpdateInput",
		upsert:"Contember_AreaUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutAreasUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput:{
		create:"Contember_UnassignReasonWithoutVenuesCreateInput",
		update:"Contember_VenueUpdateEnabledUnassignReasonsRelationInput",
		upsert:"Contember_VenueUpsertEnabledUnassignReasonsRelationInput",
		connect:"Contember_UnassignReasonUniqueWhere",
		disconnect:"Contember_UnassignReasonUniqueWhere",
		delete:"Contember_UnassignReasonUniqueWhere"
	},
	Contember_VenueUpdateEnabledUnassignReasonsRelationInput:{
		by:"Contember_UnassignReasonUniqueWhere",
		data:"Contember_UnassignReasonWithoutVenuesUpdateInput"
	},
	Contember_UnassignReasonWithoutVenuesUpdateInput:{

	},
	Contember_VenueUpsertEnabledUnassignReasonsRelationInput:{
		by:"Contember_UnassignReasonUniqueWhere",
		update:"Contember_UnassignReasonWithoutVenuesUpdateInput",
		create:"Contember_UnassignReasonWithoutVenuesCreateInput"
	},
	Contember_VenueUpdateCategoriesEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryWithoutVenueCreateInput",
		update:"Contember_VenueUpdateCategoriesRelationInput",
		upsert:"Contember_VenueUpsertCategoriesRelationInput",
		connect:"Contember_PointOfSaleCategoryUniqueWhere",
		disconnect:"Contember_PointOfSaleCategoryUniqueWhere",
		delete:"Contember_PointOfSaleCategoryUniqueWhere"
	},
	Contember_VenueUpdateCategoriesRelationInput:{
		by:"Contember_PointOfSaleCategoryUniqueWhere",
		data:"Contember_PointOfSaleCategoryWithoutVenueUpdateInput"
	},
	Contember_PointOfSaleCategoryWithoutVenueUpdateInput:{
		locales:"Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput",
		products:"Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput",
		category:"Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput",
		ticketPrinters:"Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"
	},
	Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput:{
		create:"Contember_ProductCategoryCreateInput",
		update:"Contember_ProductCategoryUpdateInput",
		upsert:"Contember_PointOfSaleCategoryUpsertCategoryRelationInput",
		connect:"Contember_ProductCategoryUniqueWhere"
	},
	Contember_PointOfSaleCategoryUpsertCategoryRelationInput:{
		update:"Contember_ProductCategoryUpdateInput",
		create:"Contember_ProductCategoryCreateInput"
	},
	Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput:{
		create:"Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput",
		update:"Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput",
		upsert:"Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput",
		connect:"Contember_PointOfSalePrinterUniqueWhere",
		disconnect:"Contember_PointOfSalePrinterUniqueWhere",
		delete:"Contember_PointOfSalePrinterUniqueWhere"
	},
	Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput:{
		by:"Contember_PointOfSalePrinterUniqueWhere",
		data:"Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"
	},
	Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput:{
		applicationDevicesReceipt:"Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput",
		applicationDevicesTicket:"Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput",
		venue:"Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"
	},
	Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput:{
		create:"Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput",
		update:"Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput",
		upsert:"Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput",
		connect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		disconnect:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		delete:"Contember_PointOfSaleApplicationDeviceUniqueWhere"
	},
	Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		data:"Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"
	},
	Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput:{
		venue:"Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput",
		identificationCode:"Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput",
		identifier:"Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput",
		terminal:"Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput",
		receiptPrinter:"Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput:{
		create:"Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput",
		update:"Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput",
		upsert:"Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput",
		connect:"Contember_PointOfSalePrinterUniqueWhere"
	},
	Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput:{
		applicationDevicesTicket:"Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput",
		venue:"Contember_PointOfSalePrinterUpdateVenueEntityRelationInput",
		ticketCategories:"Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"
	},
	Contember_PointOfSalePrinterUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutPointOfSalePrintersCreateInput",
		update:"Contember_VenueWithoutPointOfSalePrintersUpdateInput",
		upsert:"Contember_PointOfSalePrinterUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutPointOfSalePrintersUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_VenueUpdateAreasEntityRelationInput:{
		create:"Contember_AreaWithoutVenueCreateInput",
		update:"Contember_VenueUpdateAreasRelationInput",
		upsert:"Contember_VenueUpsertAreasRelationInput",
		connect:"Contember_AreaUniqueWhere",
		disconnect:"Contember_AreaUniqueWhere",
		delete:"Contember_AreaUniqueWhere"
	},
	Contember_VenueUpdateAreasRelationInput:{
		by:"Contember_AreaUniqueWhere",
		data:"Contember_AreaWithoutVenueUpdateInput"
	},
	Contember_AreaWithoutVenueUpdateInput:{
		tables:"Contember_AreaUpdateTablesEntityRelationInput",
		lines:"Contember_AreaUpdateLinesEntityRelationInput"
	},
	Contember_AreaUpdateTablesEntityRelationInput:{
		create:"Contember_TableWithoutAreaCreateInput",
		update:"Contember_AreaUpdateTablesRelationInput",
		upsert:"Contember_AreaUpsertTablesRelationInput",
		connect:"Contember_TableUniqueWhere",
		disconnect:"Contember_TableUniqueWhere",
		delete:"Contember_TableUniqueWhere"
	},
	Contember_AreaUpdateTablesRelationInput:{
		by:"Contember_TableUniqueWhere",
		data:"Contember_TableWithoutAreaUpdateInput"
	},
	Contember_TableWithoutAreaUpdateInput:{
		shape:"Contember_TableShape",
		customerTabs:"Contember_TableUpdateCustomerTabsEntityRelationInput",
		seats:"Contember_TableUpdateSeatsEntityRelationInput"
	},
	Contember_TableUpdateCustomerTabsEntityRelationInput:{
		create:"Contember_CustomerTabWithoutTableCreateInput",
		update:"Contember_TableUpdateCustomerTabsRelationInput",
		upsert:"Contember_TableUpsertCustomerTabsRelationInput",
		connect:"Contember_CustomerTabUniqueWhere",
		disconnect:"Contember_CustomerTabUniqueWhere",
		delete:"Contember_CustomerTabUniqueWhere"
	},
	Contember_TableUpdateCustomerTabsRelationInput:{
		by:"Contember_CustomerTabUniqueWhere",
		data:"Contember_CustomerTabWithoutTableUpdateInput"
	},
	Contember_CustomerTabWithoutTableUpdateInput:{
		createdAt:"DateTime",
		items:"Contember_CustomerTabUpdateItemsEntityRelationInput",
		meta:"Contember_CustomerTabUpdateMetaEntityRelationInput",
		customer:"Contember_CustomerTabUpdateCustomerEntityRelationInput",
		seat:"Contember_CustomerTabUpdateSeatEntityRelationInput"
	},
	Contember_CustomerTabUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutCustomerTabsCreateInput",
		update:"Contember_CustomerWithoutCustomerTabsUpdateInput",
		upsert:"Contember_CustomerTabUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutCustomerTabsUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateLeadEntityRelationInput:{
		create:"Contember_PipelineLeadWithoutCustomerCreateInput",
		update:"Contember_CustomerUpdateLeadRelationInput",
		upsert:"Contember_CustomerUpsertLeadRelationInput",
		connect:"Contember_PipelineLeadUniqueWhere",
		disconnect:"Contember_PipelineLeadUniqueWhere",
		delete:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_CustomerUpdateLeadRelationInput:{
		by:"Contember_PipelineLeadUniqueWhere",
		data:"Contember_PipelineLeadWithoutCustomerUpdateInput"
	},
	Contember_PipelineLeadWithoutCustomerUpdateInput:{
		createdAt:"DateTime",
		stageItem:"Contember_PipelineLeadUpdateStageItemEntityRelationInput",
		form:"Contember_PipelineLeadUpdateFormEntityRelationInput"
	},
	Contember_PipelineLeadUpdateStageItemEntityRelationInput:{
		create:"Contember_PipelineStageItemWithoutLeadCreateInput",
		update:"Contember_PipelineStageItemWithoutLeadUpdateInput",
		upsert:"Contember_PipelineLeadUpsertStageItemRelationInput",
		connect:"Contember_PipelineStageItemUniqueWhere"
	},
	Contember_PipelineStageItemWithoutLeadUpdateInput:{
		list:"Contember_PipelineStageItemUpdateListEntityRelationInput"
	},
	Contember_PipelineStageItemUpdateListEntityRelationInput:{
		create:"Contember_PipelineStageWithoutItemsCreateInput",
		update:"Contember_PipelineStageWithoutItemsUpdateInput",
		upsert:"Contember_PipelineStageItemUpsertListRelationInput",
		connect:"Contember_PipelineStageUniqueWhere"
	},
	Contember_PipelineStageWithoutItemsUpdateInput:{
		pipeline:"Contember_PipelineStageUpdatePipelineEntityRelationInput",
		locales:"Contember_PipelineStageUpdateLocalesEntityRelationInput",
		sentiment:"Contember_PipelineStageUpdateSentimentEntityRelationInput",
		type:"Contember_PipelineStageType",
		forms:"Contember_PipelineStageUpdateFormsEntityRelationInput"
	},
	Contember_PipelineStageUpdatePipelineEntityRelationInput:{
		create:"Contember_PipelineWithoutStagesCreateInput",
		update:"Contember_PipelineWithoutStagesUpdateInput",
		upsert:"Contember_PipelineStageUpsertPipelineRelationInput",
		connect:"Contember_PipelineUniqueWhere"
	},
	Contember_PipelineWithoutStagesUpdateInput:{
		unique:"Contember_One"
	},
	Contember_PipelineStageUpsertPipelineRelationInput:{
		update:"Contember_PipelineWithoutStagesUpdateInput",
		create:"Contember_PipelineWithoutStagesCreateInput"
	},
	Contember_PipelineStageUpdateLocalesEntityRelationInput:{
		create:"Contember_PipelineStageLocaleWithoutRootCreateInput",
		update:"Contember_PipelineStageUpdateLocalesRelationInput",
		upsert:"Contember_PipelineStageUpsertLocalesRelationInput",
		connect:"Contember_PipelineStageLocaleUniqueWhere",
		disconnect:"Contember_PipelineStageLocaleUniqueWhere",
		delete:"Contember_PipelineStageLocaleUniqueWhere"
	},
	Contember_PipelineStageUpdateLocalesRelationInput:{
		by:"Contember_PipelineStageLocaleUniqueWhere",
		data:"Contember_PipelineStageLocaleWithoutRootUpdateInput"
	},
	Contember_PipelineStageLocaleWithoutRootUpdateInput:{
		locale:"Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutPipelineStagesCreateInput",
		update:"Contember_LocaleWithoutPipelineStagesUpdateInput",
		upsert:"Contember_PipelineStageLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutPipelineStagesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput:{
		create:"Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateSubscriptionBoxesRelationInput",
		upsert:"Contember_LocaleUpsertSubscriptionBoxesRelationInput",
		connect:"Contember_SubscriptionBoxLocaleUniqueWhere",
		disconnect:"Contember_SubscriptionBoxLocaleUniqueWhere",
		delete:"Contember_SubscriptionBoxLocaleUniqueWhere"
	},
	Contember_LocaleUpdateSubscriptionBoxesRelationInput:{
		by:"Contember_SubscriptionBoxLocaleUniqueWhere",
		data:"Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"
	},
	Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput:{
		root:"Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput",
		texts:"Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput",
		secondaryTexts:"Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput"
	},
	Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput:{
		create:"Contember_SubscriptionBoxWithoutLocalesCreateInput",
		update:"Contember_SubscriptionBoxWithoutLocalesUpdateInput",
		upsert:"Contember_SubscriptionBoxLocaleUpsertRootRelationInput",
		connect:"Contember_SubscriptionBoxUniqueWhere"
	},
	Contember_SubscriptionBoxWithoutLocalesUpdateInput:{
		unique:"Contember_One"
	},
	Contember_SubscriptionBoxLocaleUpsertRootRelationInput:{
		update:"Contember_SubscriptionBoxWithoutLocalesUpdateInput",
		create:"Contember_SubscriptionBoxWithoutLocalesCreateInput"
	},
	Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput:{
		create:"Contember_TextListCreateInput",
		update:"Contember_TextListUpdateInput",
		upsert:"Contember_SubscriptionBoxLocaleUpsertTextsRelationInput",
		connect:"Contember_TextListUniqueWhere"
	},
	Contember_SubscriptionBoxLocaleUpsertTextsRelationInput:{
		update:"Contember_TextListUpdateInput",
		create:"Contember_TextListCreateInput"
	},
	Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput:{
		create:"Contember_TextListCreateInput",
		update:"Contember_TextListUpdateInput",
		upsert:"Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput",
		connect:"Contember_TextListUniqueWhere"
	},
	Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput:{
		update:"Contember_TextListUpdateInput",
		create:"Contember_TextListCreateInput"
	},
	Contember_LocaleUpsertSubscriptionBoxesRelationInput:{
		by:"Contember_SubscriptionBoxLocaleUniqueWhere",
		update:"Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput",
		create:"Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdateBusinessServicesEntityRelationInput:{
		create:"Contember_BusinessServiceLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdateBusinessServicesRelationInput",
		upsert:"Contember_LocaleUpsertBusinessServicesRelationInput",
		connect:"Contember_BusinessServiceLocaleUniqueWhere",
		disconnect:"Contember_BusinessServiceLocaleUniqueWhere",
		delete:"Contember_BusinessServiceLocaleUniqueWhere"
	},
	Contember_LocaleUpdateBusinessServicesRelationInput:{
		by:"Contember_BusinessServiceLocaleUniqueWhere",
		data:"Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"
	},
	Contember_BusinessServiceLocaleWithoutLocaleUpdateInput:{
		root:"Contember_BusinessServiceLocaleUpdateRootEntityRelationInput"
	},
	Contember_BusinessServiceLocaleUpdateRootEntityRelationInput:{
		create:"Contember_BusinessServiceWithoutLocalesCreateInput",
		update:"Contember_BusinessServiceWithoutLocalesUpdateInput",
		upsert:"Contember_BusinessServiceLocaleUpsertRootRelationInput",
		connect:"Contember_BusinessServiceUniqueWhere"
	},
	Contember_BusinessServiceWithoutLocalesUpdateInput:{

	},
	Contember_BusinessServiceLocaleUpsertRootRelationInput:{
		update:"Contember_BusinessServiceWithoutLocalesUpdateInput",
		create:"Contember_BusinessServiceWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertBusinessServicesRelationInput:{
		by:"Contember_BusinessServiceLocaleUniqueWhere",
		update:"Contember_BusinessServiceLocaleWithoutLocaleUpdateInput",
		create:"Contember_BusinessServiceLocaleWithoutLocaleCreateInput"
	},
	Contember_PipelineStageLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutPipelineStagesUpdateInput",
		create:"Contember_LocaleWithoutPipelineStagesCreateInput"
	},
	Contember_PipelineStageUpsertLocalesRelationInput:{
		by:"Contember_PipelineStageLocaleUniqueWhere",
		update:"Contember_PipelineStageLocaleWithoutRootUpdateInput",
		create:"Contember_PipelineStageLocaleWithoutRootCreateInput"
	},
	Contember_PipelineStageUpdateSentimentEntityRelationInput:{
		create:"Contember_PipelineStageSentimentCreateInput",
		update:"Contember_PipelineStageSentimentUpdateInput",
		upsert:"Contember_PipelineStageUpsertSentimentRelationInput",
		connect:"Contember_PipelineStageSentimentUniqueWhere"
	},
	Contember_PipelineStageSentimentUpdateInput:{

	},
	Contember_PipelineStageUpsertSentimentRelationInput:{
		update:"Contember_PipelineStageSentimentUpdateInput",
		create:"Contember_PipelineStageSentimentCreateInput"
	},
	Contember_PipelineStageUpdateFormsEntityRelationInput:{
		create:"Contember_LeadFormWithoutStageCreateInput",
		update:"Contember_PipelineStageUpdateFormsRelationInput",
		upsert:"Contember_PipelineStageUpsertFormsRelationInput",
		connect:"Contember_LeadFormUniqueWhere",
		disconnect:"Contember_LeadFormUniqueWhere",
		delete:"Contember_LeadFormUniqueWhere"
	},
	Contember_PipelineStageUpdateFormsRelationInput:{
		by:"Contember_LeadFormUniqueWhere",
		data:"Contember_LeadFormWithoutStageUpdateInput"
	},
	Contember_LeadFormWithoutStageUpdateInput:{
		leads:"Contember_LeadFormUpdateLeadsEntityRelationInput"
	},
	Contember_LeadFormUpdateLeadsEntityRelationInput:{
		create:"Contember_PipelineLeadWithoutFormCreateInput",
		update:"Contember_LeadFormUpdateLeadsRelationInput",
		upsert:"Contember_LeadFormUpsertLeadsRelationInput",
		connect:"Contember_PipelineLeadUniqueWhere",
		disconnect:"Contember_PipelineLeadUniqueWhere",
		delete:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_LeadFormUpdateLeadsRelationInput:{
		by:"Contember_PipelineLeadUniqueWhere",
		data:"Contember_PipelineLeadWithoutFormUpdateInput"
	},
	Contember_PipelineLeadWithoutFormUpdateInput:{
		createdAt:"DateTime",
		stageItem:"Contember_PipelineLeadUpdateStageItemEntityRelationInput",
		customer:"Contember_PipelineLeadUpdateCustomerEntityRelationInput"
	},
	Contember_PipelineLeadUpdateCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutLeadCreateInput",
		update:"Contember_CustomerWithoutLeadUpdateInput",
		upsert:"Contember_PipelineLeadUpsertCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutLeadUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_CustomerUpdateMetaEntityRelationInput:{
		connect:"Contember_CustomerMetadataUniqueWhere"
	},
	Contember_PipelineLeadUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutLeadUpdateInput",
		create:"Contember_CustomerWithoutLeadCreateInput"
	},
	Contember_LeadFormUpsertLeadsRelationInput:{
		by:"Contember_PipelineLeadUniqueWhere",
		update:"Contember_PipelineLeadWithoutFormUpdateInput",
		create:"Contember_PipelineLeadWithoutFormCreateInput"
	},
	Contember_PipelineStageUpsertFormsRelationInput:{
		by:"Contember_LeadFormUniqueWhere",
		update:"Contember_LeadFormWithoutStageUpdateInput",
		create:"Contember_LeadFormWithoutStageCreateInput"
	},
	Contember_PipelineStageItemUpsertListRelationInput:{
		update:"Contember_PipelineStageWithoutItemsUpdateInput",
		create:"Contember_PipelineStageWithoutItemsCreateInput"
	},
	Contember_PipelineLeadUpsertStageItemRelationInput:{
		update:"Contember_PipelineStageItemWithoutLeadUpdateInput",
		create:"Contember_PipelineStageItemWithoutLeadCreateInput"
	},
	Contember_PipelineLeadUpdateFormEntityRelationInput:{
		create:"Contember_LeadFormWithoutLeadsCreateInput",
		update:"Contember_LeadFormWithoutLeadsUpdateInput",
		upsert:"Contember_PipelineLeadUpsertFormRelationInput",
		connect:"Contember_LeadFormUniqueWhere"
	},
	Contember_LeadFormWithoutLeadsUpdateInput:{
		stage:"Contember_LeadFormUpdateStageEntityRelationInput"
	},
	Contember_LeadFormUpdateStageEntityRelationInput:{
		create:"Contember_PipelineStageWithoutFormsCreateInput",
		update:"Contember_PipelineStageWithoutFormsUpdateInput",
		upsert:"Contember_LeadFormUpsertStageRelationInput",
		connect:"Contember_PipelineStageUniqueWhere"
	},
	Contember_PipelineStageWithoutFormsUpdateInput:{
		items:"Contember_PipelineStageUpdateItemsEntityRelationInput",
		pipeline:"Contember_PipelineStageUpdatePipelineEntityRelationInput",
		locales:"Contember_PipelineStageUpdateLocalesEntityRelationInput",
		sentiment:"Contember_PipelineStageUpdateSentimentEntityRelationInput",
		type:"Contember_PipelineStageType"
	},
	Contember_PipelineStageUpdateItemsEntityRelationInput:{
		create:"Contember_PipelineStageItemWithoutListCreateInput",
		update:"Contember_PipelineStageUpdateItemsRelationInput",
		upsert:"Contember_PipelineStageUpsertItemsRelationInput",
		connect:"Contember_PipelineStageItemUniqueWhere",
		disconnect:"Contember_PipelineStageItemUniqueWhere",
		delete:"Contember_PipelineStageItemUniqueWhere"
	},
	Contember_PipelineStageUpdateItemsRelationInput:{
		by:"Contember_PipelineStageItemUniqueWhere",
		data:"Contember_PipelineStageItemWithoutListUpdateInput"
	},
	Contember_PipelineStageItemWithoutListUpdateInput:{
		lead:"Contember_PipelineStageItemUpdateLeadEntityRelationInput"
	},
	Contember_PipelineStageItemUpdateLeadEntityRelationInput:{
		create:"Contember_PipelineLeadWithoutStageItemCreateInput",
		update:"Contember_PipelineLeadWithoutStageItemUpdateInput",
		upsert:"Contember_PipelineStageItemUpsertLeadRelationInput",
		connect:"Contember_PipelineLeadUniqueWhere"
	},
	Contember_PipelineLeadWithoutStageItemUpdateInput:{
		createdAt:"DateTime",
		customer:"Contember_PipelineLeadUpdateCustomerEntityRelationInput",
		form:"Contember_PipelineLeadUpdateFormEntityRelationInput"
	},
	Contember_PipelineStageItemUpsertLeadRelationInput:{
		update:"Contember_PipelineLeadWithoutStageItemUpdateInput",
		create:"Contember_PipelineLeadWithoutStageItemCreateInput"
	},
	Contember_PipelineStageUpsertItemsRelationInput:{
		by:"Contember_PipelineStageItemUniqueWhere",
		update:"Contember_PipelineStageItemWithoutListUpdateInput",
		create:"Contember_PipelineStageItemWithoutListCreateInput"
	},
	Contember_LeadFormUpsertStageRelationInput:{
		update:"Contember_PipelineStageWithoutFormsUpdateInput",
		create:"Contember_PipelineStageWithoutFormsCreateInput"
	},
	Contember_PipelineLeadUpsertFormRelationInput:{
		update:"Contember_LeadFormWithoutLeadsUpdateInput",
		create:"Contember_LeadFormWithoutLeadsCreateInput"
	},
	Contember_CustomerUpsertLeadRelationInput:{
		by:"Contember_PipelineLeadUniqueWhere",
		update:"Contember_PipelineLeadWithoutCustomerUpdateInput",
		create:"Contember_PipelineLeadWithoutCustomerCreateInput"
	},
	Contember_CustomerTabUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutCustomerTabsUpdateInput",
		create:"Contember_CustomerWithoutCustomerTabsCreateInput"
	},
	Contember_CustomerTabUpdateSeatEntityRelationInput:{
		create:"Contember_SeatWithoutCustomerTabsCreateInput",
		update:"Contember_SeatWithoutCustomerTabsUpdateInput",
		upsert:"Contember_CustomerTabUpsertSeatRelationInput",
		connect:"Contember_SeatUniqueWhere"
	},
	Contember_SeatWithoutCustomerTabsUpdateInput:{
		table:"Contember_SeatUpdateTableEntityRelationInput"
	},
	Contember_SeatUpdateTableEntityRelationInput:{
		create:"Contember_TableWithoutSeatsCreateInput",
		update:"Contember_TableWithoutSeatsUpdateInput",
		upsert:"Contember_SeatUpsertTableRelationInput",
		connect:"Contember_TableUniqueWhere"
	},
	Contember_TableWithoutSeatsUpdateInput:{
		shape:"Contember_TableShape",
		customerTabs:"Contember_TableUpdateCustomerTabsEntityRelationInput",
		area:"Contember_TableUpdateAreaEntityRelationInput"
	},
	Contember_SeatUpsertTableRelationInput:{
		update:"Contember_TableWithoutSeatsUpdateInput",
		create:"Contember_TableWithoutSeatsCreateInput"
	},
	Contember_CustomerTabUpsertSeatRelationInput:{
		update:"Contember_SeatWithoutCustomerTabsUpdateInput",
		create:"Contember_SeatWithoutCustomerTabsCreateInput"
	},
	Contember_TableUpsertCustomerTabsRelationInput:{
		by:"Contember_CustomerTabUniqueWhere",
		update:"Contember_CustomerTabWithoutTableUpdateInput",
		create:"Contember_CustomerTabWithoutTableCreateInput"
	},
	Contember_TableUpdateSeatsEntityRelationInput:{
		create:"Contember_SeatWithoutTableCreateInput",
		update:"Contember_TableUpdateSeatsRelationInput",
		upsert:"Contember_TableUpsertSeatsRelationInput",
		connect:"Contember_SeatUniqueWhere",
		disconnect:"Contember_SeatUniqueWhere",
		delete:"Contember_SeatUniqueWhere"
	},
	Contember_TableUpdateSeatsRelationInput:{
		by:"Contember_SeatUniqueWhere",
		data:"Contember_SeatWithoutTableUpdateInput"
	},
	Contember_SeatWithoutTableUpdateInput:{
		customerTabs:"Contember_SeatUpdateCustomerTabsEntityRelationInput"
	},
	Contember_SeatUpdateCustomerTabsEntityRelationInput:{
		create:"Contember_CustomerTabWithoutSeatCreateInput",
		update:"Contember_SeatUpdateCustomerTabsRelationInput",
		upsert:"Contember_SeatUpsertCustomerTabsRelationInput",
		connect:"Contember_CustomerTabUniqueWhere",
		disconnect:"Contember_CustomerTabUniqueWhere",
		delete:"Contember_CustomerTabUniqueWhere"
	},
	Contember_SeatUpdateCustomerTabsRelationInput:{
		by:"Contember_CustomerTabUniqueWhere",
		data:"Contember_CustomerTabWithoutSeatUpdateInput"
	},
	Contember_CustomerTabWithoutSeatUpdateInput:{
		createdAt:"DateTime",
		items:"Contember_CustomerTabUpdateItemsEntityRelationInput",
		meta:"Contember_CustomerTabUpdateMetaEntityRelationInput",
		customer:"Contember_CustomerTabUpdateCustomerEntityRelationInput",
		table:"Contember_CustomerTabUpdateTableEntityRelationInput"
	},
	Contember_SeatUpsertCustomerTabsRelationInput:{
		by:"Contember_CustomerTabUniqueWhere",
		update:"Contember_CustomerTabWithoutSeatUpdateInput",
		create:"Contember_CustomerTabWithoutSeatCreateInput"
	},
	Contember_TableUpsertSeatsRelationInput:{
		by:"Contember_SeatUniqueWhere",
		update:"Contember_SeatWithoutTableUpdateInput",
		create:"Contember_SeatWithoutTableCreateInput"
	},
	Contember_AreaUpsertTablesRelationInput:{
		by:"Contember_TableUniqueWhere",
		update:"Contember_TableWithoutAreaUpdateInput",
		create:"Contember_TableWithoutAreaCreateInput"
	},
	Contember_AreaUpdateLinesEntityRelationInput:{
		create:"Contember_LineWithoutAreaCreateInput",
		update:"Contember_AreaUpdateLinesRelationInput",
		upsert:"Contember_AreaUpsertLinesRelationInput",
		connect:"Contember_LineUniqueWhere",
		disconnect:"Contember_LineUniqueWhere",
		delete:"Contember_LineUniqueWhere"
	},
	Contember_AreaUpdateLinesRelationInput:{
		by:"Contember_LineUniqueWhere",
		data:"Contember_LineWithoutAreaUpdateInput"
	},
	Contember_LineWithoutAreaUpdateInput:{

	},
	Contember_AreaUpsertLinesRelationInput:{
		by:"Contember_LineUniqueWhere",
		update:"Contember_LineWithoutAreaUpdateInput",
		create:"Contember_LineWithoutAreaCreateInput"
	},
	Contember_VenueUpsertAreasRelationInput:{
		by:"Contember_AreaUniqueWhere",
		update:"Contember_AreaWithoutVenueUpdateInput",
		create:"Contember_AreaWithoutVenueCreateInput"
	},
	Contember_VenueUpdateChannelsEntityRelationInput:{
		create:"Contember_ChannelWithoutVenueCreateInput",
		update:"Contember_VenueUpdateChannelsRelationInput",
		upsert:"Contember_VenueUpsertChannelsRelationInput",
		connect:"Contember_ChannelUniqueWhere",
		disconnect:"Contember_ChannelUniqueWhere",
		delete:"Contember_ChannelUniqueWhere"
	},
	Contember_VenueUpdateChannelsRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		data:"Contember_ChannelWithoutVenueUpdateInput"
	},
	Contember_ChannelWithoutVenueUpdateInput:{
		payments:"Contember_ChannelUpdatePaymentsEntityRelationInput",
		deliveries:"Contember_ChannelUpdateDeliveriesEntityRelationInput",
		pointOfSales:"Contember_ChannelUpdatePointOfSalesEntityRelationInput",
		carts:"Contember_ChannelUpdateCartsEntityRelationInput",
		customers:"Contember_ChannelUpdateCustomersEntityRelationInput",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput",
		publicTags:"Contember_ChannelUpdatePublicTagsEntityRelationInput"
	},
	Contember_VenueUpsertChannelsRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		update:"Contember_ChannelWithoutVenueUpdateInput",
		create:"Contember_ChannelWithoutVenueCreateInput"
	},
	Contember_PointOfSalePrinterUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutPointOfSalePrintersUpdateInput",
		create:"Contember_VenueWithoutPointOfSalePrintersCreateInput"
	},
	Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput",
		update:"Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput",
		upsert:"Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput",
		connect:"Contember_PointOfSaleCategoryUniqueWhere",
		disconnect:"Contember_PointOfSaleCategoryUniqueWhere",
		delete:"Contember_PointOfSaleCategoryUniqueWhere"
	},
	Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput:{
		by:"Contember_PointOfSaleCategoryUniqueWhere",
		data:"Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"
	},
	Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput:{
		locales:"Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput",
		products:"Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput",
		category:"Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput",
		venue:"Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"
	},
	Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutCategoriesCreateInput",
		update:"Contember_VenueWithoutCategoriesUpdateInput",
		upsert:"Contember_PointOfSaleCategoryUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutCategoriesUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_PointOfSaleCategoryUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutCategoriesUpdateInput",
		create:"Contember_VenueWithoutCategoriesCreateInput"
	},
	Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput:{
		by:"Contember_PointOfSaleCategoryUniqueWhere",
		update:"Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput",
		create:"Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput:{
		update:"Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput",
		create:"Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"
	},
	Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		update:"Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput",
		create:"Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"
	},
	Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput:{
		by:"Contember_PointOfSalePrinterUniqueWhere",
		update:"Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput",
		create:"Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"
	},
	Contember_VenueUpsertCategoriesRelationInput:{
		by:"Contember_PointOfSaleCategoryUniqueWhere",
		update:"Contember_PointOfSaleCategoryWithoutVenueUpdateInput",
		create:"Contember_PointOfSaleCategoryWithoutVenueCreateInput"
	},
	Contember_AreaUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutAreasUpdateInput",
		create:"Contember_VenueWithoutAreasCreateInput"
	},
	Contember_TableUpsertAreaRelationInput:{
		update:"Contember_AreaWithoutTablesUpdateInput",
		create:"Contember_AreaWithoutTablesCreateInput"
	},
	Contember_CustomerTabUpsertTableRelationInput:{
		update:"Contember_TableWithoutCustomerTabsUpdateInput",
		create:"Contember_TableWithoutCustomerTabsCreateInput"
	},
	Contember_CustomerUpsertCustomerTabsRelationInput:{
		by:"Contember_CustomerTabUniqueWhere",
		update:"Contember_CustomerTabWithoutCustomerUpdateInput",
		create:"Contember_CustomerTabWithoutCustomerCreateInput"
	},
	Contember_VoucherRedemptionUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutRedeemedVouchersUpdateInput",
		create:"Contember_CustomerWithoutRedeemedVouchersCreateInput"
	},
	Contember_VoucherUpsertRedemptionRelationInput:{
		update:"Contember_VoucherRedemptionWithoutVoucherUpdateInput",
		create:"Contember_VoucherRedemptionWithoutVoucherCreateInput"
	},
	Contember_CustomerUpsertOwnsVouchersRelationInput:{
		by:"Contember_VoucherUniqueWhere",
		update:"Contember_VoucherWithoutOwnerUpdateInput",
		create:"Contember_VoucherWithoutOwnerCreateInput"
	},
	Contember_VoucherUpsertBuyerRelationInput:{
		update:"Contember_CustomerWithoutBoughtVouchersUpdateInput",
		create:"Contember_CustomerWithoutBoughtVouchersCreateInput"
	},
	Contember_VirtualProductEffectUpsertResultVouchersRelationInput:{
		by:"Contember_VoucherUniqueWhere",
		update:"Contember_VoucherWithoutVirtualProductEffectUpdateInput",
		create:"Contember_VoucherWithoutVirtualProductEffectCreateInput"
	},
	Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput:{
		update:"Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput",
		create:"Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"
	},
	Contember_CustomerCreditUpsertTransactionsRelationInput:{
		by:"Contember_CustomerCreditTransactionUniqueWhere",
		update:"Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput",
		create:"Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"
	},
	Contember_VoucherRedemptionUpsertCustomerCreditRelationInput:{
		update:"Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput",
		create:"Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"
	},
	Contember_VoucherRedemptionUpdateVoucherEntityRelationInput:{
		create:"Contember_VoucherWithoutRedemptionCreateInput",
		update:"Contember_VoucherWithoutRedemptionUpdateInput",
		upsert:"Contember_VoucherRedemptionUpsertVoucherRelationInput",
		connect:"Contember_VoucherUniqueWhere"
	},
	Contember_VoucherWithoutRedemptionUpdateInput:{
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		type:"Contember_VoucherType",
		buyer:"Contember_VoucherUpdateBuyerEntityRelationInput",
		owner:"Contember_VoucherUpdateOwnerEntityRelationInput",
		vatRate:"Contember_VoucherUpdateVatRateEntityRelationInput",
		virtualProductEffect:"Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"
	},
	Contember_VoucherRedemptionUpsertVoucherRelationInput:{
		update:"Contember_VoucherWithoutRedemptionUpdateInput",
		create:"Contember_VoucherWithoutRedemptionCreateInput"
	},
	Contember_CustomerUpsertRedeemedVouchersRelationInput:{
		by:"Contember_VoucherRedemptionUniqueWhere",
		update:"Contember_VoucherRedemptionWithoutCustomerUpdateInput",
		create:"Contember_VoucherRedemptionWithoutCustomerCreateInput"
	},
	Contember_VoucherUpsertOwnerRelationInput:{
		update:"Contember_CustomerWithoutOwnsVouchersUpdateInput",
		create:"Contember_CustomerWithoutOwnsVouchersCreateInput"
	},
	Contember_CustomerUpsertBoughtVouchersRelationInput:{
		by:"Contember_VoucherUniqueWhere",
		update:"Contember_VoucherWithoutBuyerUpdateInput",
		create:"Contember_VoucherWithoutBuyerCreateInput"
	},
	Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput:{
		update:"Contember_CustomerWithoutDefaultBillingSubjectUpdateInput",
		create:"Contember_CustomerWithoutDefaultBillingSubjectCreateInput"
	},
	Contember_OrderUpsertBillingSubjectRelationInput:{
		update:"Contember_BillingSubjectWithoutOrdersUpdateInput",
		create:"Contember_BillingSubjectWithoutOrdersCreateInput"
	},
	Contember_FulfillmentNoteUpsertOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		update:"Contember_OrderWithoutFulfillmentNotesUpdateInput",
		create:"Contember_OrderWithoutFulfillmentNotesCreateInput"
	},
	Contember_VenueUpsertFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		update:"Contember_FulfillmentNoteWithoutVenuesUpdateInput",
		create:"Contember_FulfillmentNoteWithoutVenuesCreateInput"
	},
	Contember_OrderUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutOrdersUpdateInput",
		create:"Contember_VenueWithoutOrdersCreateInput"
	},
	Contember_OrderUpdateFulfillmentNotesEntityRelationInput:{
		create:"Contember_FulfillmentNoteWithoutOrdersCreateInput",
		update:"Contember_OrderUpdateFulfillmentNotesRelationInput",
		upsert:"Contember_OrderUpsertFulfillmentNotesRelationInput",
		connect:"Contember_FulfillmentNoteUniqueWhere",
		disconnect:"Contember_FulfillmentNoteUniqueWhere",
		delete:"Contember_FulfillmentNoteUniqueWhere"
	},
	Contember_OrderUpdateFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		data:"Contember_FulfillmentNoteWithoutOrdersUpdateInput"
	},
	Contember_FulfillmentNoteWithoutOrdersUpdateInput:{
		products:"Contember_FulfillmentNoteUpdateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteUpdateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteUpdateVenuesEntityRelationInput",
		cartItems:"Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_OrderUpsertFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		update:"Contember_FulfillmentNoteWithoutOrdersUpdateInput",
		create:"Contember_FulfillmentNoteWithoutOrdersCreateInput"
	},
	Contember_BillingSubjectUpsertOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		update:"Contember_OrderWithoutBillingSubjectUpdateInput",
		create:"Contember_OrderWithoutBillingSubjectCreateInput"
	},
	Contember_CustomerUpsertDefaultBillingSubjectRelationInput:{
		update:"Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput",
		create:"Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"
	},
	Contember_BillingSubjectUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutBillingSubjectsUpdateInput",
		create:"Contember_CustomerWithoutBillingSubjectsCreateInput"
	},
	Contember_InvoiceUpsertBillingSubjectRelationInput:{
		update:"Contember_BillingSubjectWithoutInvoicesUpdateInput",
		create:"Contember_BillingSubjectWithoutInvoicesCreateInput"
	},
	Contember_OrderPaymentUpsertInvoiceRelationInput:{
		update:"Contember_InvoiceWithoutOrderPaymentsUpdateInput",
		create:"Contember_InvoiceWithoutOrderPaymentsCreateInput"
	},
	Contember_OrderPaymentUpdateVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_OrderPaymentUpsertVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_OrderPaymentUpsertVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_OrderPaymentUpdateMetaEntityRelationInput:{
		connect:"Contember_OrderPaymentMetadataUniqueWhere"
	},
	Contember_OrderUpsertPaymentsRelationInput:{
		by:"Contember_OrderPaymentUniqueWhere",
		update:"Contember_OrderPaymentWithoutOrderUpdateInput",
		create:"Contember_OrderPaymentWithoutOrderCreateInput"
	},
	Contember_OrderDeliveryUpsertOrderRelationInput:{
		update:"Contember_OrderWithoutDeliveryUpdateInput",
		create:"Contember_OrderWithoutDeliveryCreateInput"
	},
	Contember_OrderDeliveryUpdateEventsEntityRelationInput:{
		create:"Contember_OrderEventWithoutDeliveryCreateInput",
		update:"Contember_OrderDeliveryUpdateEventsRelationInput",
		upsert:"Contember_OrderDeliveryUpsertEventsRelationInput",
		connect:"Contember_OrderEventUniqueWhere",
		disconnect:"Contember_OrderEventUniqueWhere",
		delete:"Contember_OrderEventUniqueWhere"
	},
	Contember_OrderDeliveryUpdateEventsRelationInput:{
		by:"Contember_OrderEventUniqueWhere",
		data:"Contember_OrderEventWithoutDeliveryUpdateInput"
	},
	Contember_OrderEventWithoutDeliveryUpdateInput:{
		createdAt:"DateTime",
		order:"Contember_OrderEventUpdateOrderEntityRelationInput",
		payment:"Contember_OrderEventUpdatePaymentEntityRelationInput"
	},
	Contember_OrderDeliveryUpsertEventsRelationInput:{
		by:"Contember_OrderEventUniqueWhere",
		update:"Contember_OrderEventWithoutDeliveryUpdateInput",
		create:"Contember_OrderEventWithoutDeliveryCreateInput"
	},
	Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput:{
		create:"Contember_LiftagoRideWithoutDeliveryCreateInput",
		update:"Contember_LiftagoRideWithoutDeliveryUpdateInput",
		upsert:"Contember_OrderDeliveryUpsertLiftagoRideRelationInput",
		connect:"Contember_LiftagoRideUniqueWhere"
	},
	Contember_LiftagoRideWithoutDeliveryUpdateInput:{
		updatedAt:"DateTime"
	},
	Contember_OrderDeliveryUpsertLiftagoRideRelationInput:{
		update:"Contember_LiftagoRideWithoutDeliveryUpdateInput",
		create:"Contember_LiftagoRideWithoutDeliveryCreateInput"
	},
	Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput:{
		by:"Contember_OrderDeliveryUniqueWhere",
		update:"Contember_OrderDeliveryWithoutAddressUpdateInput",
		create:"Contember_OrderDeliveryWithoutAddressCreateInput"
	},
	Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput:{
		create:"Contember_CustomerWithoutDefaultDeliveryAddressCreateInput",
		update:"Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput",
		upsert:"Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput",
		connect:"Contember_CustomerUniqueWhere"
	},
	Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput:{
		update:"Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput",
		create:"Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"
	},
	Contember_OrderUpsertBillingAddressRelationInput:{
		update:"Contember_AddressWithoutBillingAddressOfOrdersUpdateInput",
		create:"Contember_AddressWithoutBillingAddressOfOrdersCreateInput"
	},
	Contember_OrderPaymentUpsertOrderRelationInput:{
		update:"Contember_OrderWithoutPaymentsUpdateInput",
		create:"Contember_OrderWithoutPaymentsCreateInput"
	},
	Contember_OrderEventUpsertPaymentRelationInput:{
		update:"Contember_OrderPaymentWithoutEventsUpdateInput",
		create:"Contember_OrderPaymentWithoutEventsCreateInput"
	},
	Contember_OrderEventUpdateDeliveryEntityRelationInput:{
		create:"Contember_OrderDeliveryWithoutEventsCreateInput",
		update:"Contember_OrderDeliveryWithoutEventsUpdateInput",
		upsert:"Contember_OrderEventUpsertDeliveryRelationInput",
		connect:"Contember_OrderDeliveryUniqueWhere"
	},
	Contember_OrderDeliveryWithoutEventsUpdateInput:{
		deliveredAt:"DateTime",
		method:"Contember_OrderDeliveryUpdateMethodEntityRelationInput",
		vatRate:"Contember_OrderDeliveryUpdateVatRateEntityRelationInput",
		order:"Contember_OrderDeliveryUpdateOrderEntityRelationInput",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		dispatchAt:"DateTime",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		deliveryWaypoint:"Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput",
		prepareAt:"DateTime",
		liftagoRide:"Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput",
		pickedAt:"DateTime",
		address:"Contember_OrderDeliveryUpdateAddressEntityRelationInput"
	},
	Contember_OrderDeliveryUpdateAddressEntityRelationInput:{
		create:"Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput",
		update:"Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput",
		upsert:"Contember_OrderDeliveryUpsertAddressRelationInput",
		connect:"Contember_AddressUniqueWhere"
	},
	Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput:{
		customer:"Contember_AddressUpdateCustomerEntityRelationInput",
		country:"Contember_AddressUpdateCountryEntityRelationInput",
		deletedAt:"DateTime",
		geocodeGeneratedAt:"DateTime",
		deliveryZone:"Contember_AddressUpdateDeliveryZoneEntityRelationInput",
		meta:"Contember_AddressUpdateMetaEntityRelationInput",
		gps:"Contember_AddressUpdateGpsEntityRelationInput",
		invoices:"Contember_AddressUpdateInvoicesEntityRelationInput",
		createdAt:"DateTime",
		billingAddressOfOrders:"Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput",
		defaultDeliveryAddressOfCustomer:"Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"
	},
	Contember_OrderDeliveryUpsertAddressRelationInput:{
		update:"Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput",
		create:"Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"
	},
	Contember_OrderEventUpsertDeliveryRelationInput:{
		update:"Contember_OrderDeliveryWithoutEventsUpdateInput",
		create:"Contember_OrderDeliveryWithoutEventsCreateInput"
	},
	Contember_OrderUpsertEventsRelationInput:{
		by:"Contember_OrderEventUniqueWhere",
		update:"Contember_OrderEventWithoutOrderUpdateInput",
		create:"Contember_OrderEventWithoutOrderCreateInput"
	},
	Contember_AddressUpsertBillingAddressOfOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		update:"Contember_OrderWithoutBillingAddressUpdateInput",
		create:"Contember_OrderWithoutBillingAddressCreateInput"
	},
	Contember_InvoiceUpsertAddressRelationInput:{
		update:"Contember_AddressWithoutInvoicesUpdateInput",
		create:"Contember_AddressWithoutInvoicesCreateInput"
	},
	Contember_BillingSubjectUpsertInvoicesRelationInput:{
		by:"Contember_InvoiceUniqueWhere",
		update:"Contember_InvoiceWithoutBillingSubjectUpdateInput",
		create:"Contember_InvoiceWithoutBillingSubjectCreateInput"
	},
	Contember_CustomerUpsertBillingSubjectsRelationInput:{
		by:"Contember_BillingSubjectUniqueWhere",
		update:"Contember_BillingSubjectWithoutCustomerUpdateInput",
		create:"Contember_BillingSubjectWithoutCustomerCreateInput"
	},
	Contember_CartUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutCartsUpdateInput",
		create:"Contember_CustomerWithoutCartsCreateInput"
	},
	Contember_CartUpdateDeliveryAddressEntityRelationInput:{
		create:"Contember_AddressCreateInput",
		update:"Contember_AddressUpdateInput",
		upsert:"Contember_CartUpsertDeliveryAddressRelationInput",
		connect:"Contember_AddressUniqueWhere"
	},
	Contember_CartUpsertDeliveryAddressRelationInput:{
		update:"Contember_AddressUpdateInput",
		create:"Contember_AddressCreateInput"
	},
	Contember_CartUpdateBillingAddressEntityRelationInput:{
		create:"Contember_AddressCreateInput",
		update:"Contember_AddressUpdateInput",
		upsert:"Contember_CartUpsertBillingAddressRelationInput",
		connect:"Contember_AddressUniqueWhere"
	},
	Contember_CartUpsertBillingAddressRelationInput:{
		update:"Contember_AddressUpdateInput",
		create:"Contember_AddressCreateInput"
	},
	Contember_CartUpdateDeliveryEntityRelationInput:{
		create:"Contember_ChannelDeliveryCreateInput",
		update:"Contember_ChannelDeliveryUpdateInput",
		upsert:"Contember_CartUpsertDeliveryRelationInput",
		connect:"Contember_ChannelDeliveryUniqueWhere"
	},
	Contember_ChannelDeliveryUpdateInput:{
		channel:"Contember_ChannelDeliveryUpdateChannelEntityRelationInput",
		method:"Contember_ChannelDeliveryUpdateMethodEntityRelationInput",
		vatRate:"Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"
	},
	Contember_CartUpsertDeliveryRelationInput:{
		update:"Contember_ChannelDeliveryUpdateInput",
		create:"Contember_ChannelDeliveryCreateInput"
	},
	Contember_CartUpdatePaymentEntityRelationInput:{
		create:"Contember_ChannelPaymentCreateInput",
		update:"Contember_ChannelPaymentUpdateInput",
		upsert:"Contember_CartUpsertPaymentRelationInput",
		connect:"Contember_ChannelPaymentUniqueWhere"
	},
	Contember_ChannelPaymentUpdateInput:{
		channel:"Contember_ChannelPaymentUpdateChannelEntityRelationInput",
		method:"Contember_ChannelPaymentUpdateMethodEntityRelationInput",
		vatRate:"Contember_ChannelPaymentUpdateVatRateEntityRelationInput",
		allowOnlyForTags:"Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"
	},
	Contember_CartUpsertPaymentRelationInput:{
		update:"Contember_ChannelPaymentUpdateInput",
		create:"Contember_ChannelPaymentCreateInput"
	},
	Contember_CartUpdateBillingSubjectEntityRelationInput:{
		create:"Contember_BillingSubjectCreateInput",
		update:"Contember_BillingSubjectUpdateInput",
		upsert:"Contember_CartUpsertBillingSubjectRelationInput",
		connect:"Contember_BillingSubjectUniqueWhere"
	},
	Contember_BillingSubjectUpdateInput:{
		createdAt:"DateTime",
		deletedAt:"DateTime",
		country:"Contember_BillingSubjectUpdateCountryEntityRelationInput",
		customer:"Contember_BillingSubjectUpdateCustomerEntityRelationInput",
		invoices:"Contember_BillingSubjectUpdateInvoicesEntityRelationInput",
		defaultBillingSubjectOfCustomer:"Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput",
		orders:"Contember_BillingSubjectUpdateOrdersEntityRelationInput"
	},
	Contember_CartUpsertBillingSubjectRelationInput:{
		update:"Contember_BillingSubjectUpdateInput",
		create:"Contember_BillingSubjectCreateInput"
	},
	Contember_CartUpdatePriceLinesEntityRelationInput:{
		connect:"Contember_CartPriceLineUniqueWhere",
		disconnect:"Contember_CartPriceLineUniqueWhere",
		delete:"Contember_CartPriceLineUniqueWhere"
	},
	Contember_CartUpdateSummaryEntityRelationInput:{
		connect:"Contember_CartSummaryUniqueWhere"
	},
	Contember_OrderUpsertCartRelationInput:{
		update:"Contember_CartWithoutOrderUpdateInput",
		create:"Contember_CartWithoutOrderCreateInput"
	},
	Contember_OrderDiscountUpsertOrderRelationInput:{
		update:"Contember_OrderWithoutDiscountsUpdateInput",
		create:"Contember_OrderWithoutDiscountsCreateInput"
	},
	Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput:{
		create:"Contember_VoucherRedemptionWithoutOrderDiscountCreateInput",
		update:"Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput",
		upsert:"Contember_OrderDiscountUpsertVoucherRedemptionRelationInput",
		connect:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput:{
		createdAt:"DateTime",
		customerCredit:"Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput",
		voucher:"Contember_VoucherRedemptionUpdateVoucherEntityRelationInput",
		customer:"Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"
	},
	Contember_OrderDiscountUpsertVoucherRedemptionRelationInput:{
		update:"Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput",
		create:"Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"
	},
	Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput:{
		update:"Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput",
		create:"Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"
	},
	Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput:{
		update:"Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput",
		create:"Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"
	},
	Contember_OrderItemUpsertVirtualProductEffectsRelationInput:{
		by:"Contember_VirtualProductEffectUniqueWhere",
		update:"Contember_VirtualProductEffectWithoutOrderItemUpdateInput",
		create:"Contember_VirtualProductEffectWithoutOrderItemCreateInput"
	},
	Contember_OrderUpsertItemsRelationInput:{
		by:"Contember_OrderItemUniqueWhere",
		update:"Contember_OrderItemWithoutOrderUpdateInput",
		create:"Contember_OrderItemWithoutOrderCreateInput"
	},
	Contember_VenueUpsertOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		update:"Contember_OrderWithoutVenueUpdateInput",
		create:"Contember_OrderWithoutVenueCreateInput"
	},
	Contember_PointOfSaleTerminalUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutPointOfSaleTerminalsUpdateInput",
		create:"Contember_VenueWithoutPointOfSaleTerminalsCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput:{
		update:"Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput",
		create:"Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput:{
		create:"Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput",
		update:"Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput",
		upsert:"Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput",
		connect:"Contember_PointOfSalePrinterUniqueWhere",
		disconnect:"Contember_PointOfSalePrinterUniqueWhere",
		delete:"Contember_PointOfSalePrinterUniqueWhere"
	},
	Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput:{
		by:"Contember_PointOfSalePrinterUniqueWhere",
		data:"Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"
	},
	Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput:{
		applicationDevicesReceipt:"Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput",
		venue:"Contember_PointOfSalePrinterUpdateVenueEntityRelationInput",
		ticketCategories:"Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"
	},
	Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput:{
		by:"Contember_PointOfSalePrinterUniqueWhere",
		update:"Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput",
		create:"Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"
	},
	Contember_IdentificationCodeUpsertApplicationDevicesRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		update:"Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput",
		create:"Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"
	},
	Contember_IdentificationCodeLocaleUpsertRootRelationInput:{
		update:"Contember_IdentificationCodeWithoutLocalesUpdateInput",
		create:"Contember_IdentificationCodeWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertIdentificationCodesRelationInput:{
		by:"Contember_IdentificationCodeLocaleUniqueWhere",
		update:"Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput",
		create:"Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"
	},
	Contember_LocaleUpdatePipelineStagesEntityRelationInput:{
		create:"Contember_PipelineStageLocaleWithoutLocaleCreateInput",
		update:"Contember_LocaleUpdatePipelineStagesRelationInput",
		upsert:"Contember_LocaleUpsertPipelineStagesRelationInput",
		connect:"Contember_PipelineStageLocaleUniqueWhere",
		disconnect:"Contember_PipelineStageLocaleUniqueWhere",
		delete:"Contember_PipelineStageLocaleUniqueWhere"
	},
	Contember_LocaleUpdatePipelineStagesRelationInput:{
		by:"Contember_PipelineStageLocaleUniqueWhere",
		data:"Contember_PipelineStageLocaleWithoutLocaleUpdateInput"
	},
	Contember_PipelineStageLocaleWithoutLocaleUpdateInput:{
		root:"Contember_PipelineStageLocaleUpdateRootEntityRelationInput"
	},
	Contember_PipelineStageLocaleUpdateRootEntityRelationInput:{
		create:"Contember_PipelineStageWithoutLocalesCreateInput",
		update:"Contember_PipelineStageWithoutLocalesUpdateInput",
		upsert:"Contember_PipelineStageLocaleUpsertRootRelationInput",
		connect:"Contember_PipelineStageUniqueWhere"
	},
	Contember_PipelineStageWithoutLocalesUpdateInput:{
		items:"Contember_PipelineStageUpdateItemsEntityRelationInput",
		pipeline:"Contember_PipelineStageUpdatePipelineEntityRelationInput",
		sentiment:"Contember_PipelineStageUpdateSentimentEntityRelationInput",
		type:"Contember_PipelineStageType",
		forms:"Contember_PipelineStageUpdateFormsEntityRelationInput"
	},
	Contember_PipelineStageLocaleUpsertRootRelationInput:{
		update:"Contember_PipelineStageWithoutLocalesUpdateInput",
		create:"Contember_PipelineStageWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertPipelineStagesRelationInput:{
		by:"Contember_PipelineStageLocaleUniqueWhere",
		update:"Contember_PipelineStageLocaleWithoutLocaleUpdateInput",
		create:"Contember_PipelineStageLocaleWithoutLocaleCreateInput"
	},
	Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput",
		create:"Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"
	},
	Contember_PointOfSaleCategoryUpsertLocalesRelationInput:{
		by:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		update:"Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput",
		create:"Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"
	},
	Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput:{
		update:"Contember_PointOfSaleCategoryWithoutProductsUpdateInput",
		create:"Contember_PointOfSaleCategoryWithoutProductsCreateInput"
	},
	Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput:{
		update:"Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput",
		create:"Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput:{
		by:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		update:"Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput",
		create:"Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"
	},
	Contember_IdentificationCodeLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutIdentificationCodesUpdateInput",
		create:"Contember_LocaleWithoutIdentificationCodesCreateInput"
	},
	Contember_IdentificationCodeUpsertLocalesRelationInput:{
		by:"Contember_IdentificationCodeLocaleUniqueWhere",
		update:"Contember_IdentificationCodeLocaleWithoutRootUpdateInput",
		create:"Contember_IdentificationCodeLocaleWithoutRootCreateInput"
	},
	Contember_IdentificationAttemptUpsertCodeRelationInput:{
		update:"Contember_IdentificationCodeWithoutAttemptsUpdateInput",
		create:"Contember_IdentificationCodeWithoutAttemptsCreateInput"
	},
	Contember_UserUpsertIdentificationAttemptsRelationInput:{
		by:"Contember_IdentificationAttemptUniqueWhere",
		update:"Contember_IdentificationAttemptWithoutUserUpdateInput",
		create:"Contember_IdentificationAttemptWithoutUserCreateInput"
	},
	Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput:{
		update:"Contember_UserUpdateInput",
		create:"Contember_UserCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput:{
		update:"Contember_DeviceIdentifierWithoutDeviceUpdateInput",
		create:"Contember_DeviceIdentifierWithoutDeviceCreateInput"
	},
	Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		update:"Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput",
		create:"Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"
	},
	Contember_VenueUpsertPointOfSalePrintersRelationInput:{
		by:"Contember_PointOfSalePrinterUniqueWhere",
		update:"Contember_PointOfSalePrinterWithoutVenueUpdateInput",
		create:"Contember_PointOfSalePrinterWithoutVenueCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput",
		create:"Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"
	},
	Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		update:"Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput",
		create:"Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"
	},
	Contember_VenueUpsertPointOfSaleTerminalsRelationInput:{
		by:"Contember_PointOfSaleTerminalUniqueWhere",
		update:"Contember_PointOfSaleTerminalWithoutVenueUpdateInput",
		create:"Contember_PointOfSaleTerminalWithoutVenueCreateInput"
	},
	Contember_FulfillmentNoteUpsertVenuesRelationInput:{
		by:"Contember_VenueUniqueWhere",
		update:"Contember_VenueWithoutFulfillmentNotesUpdateInput",
		create:"Contember_VenueWithoutFulfillmentNotesCreateInput"
	},
	Contember_ProductUpsertFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		update:"Contember_FulfillmentNoteWithoutProductsUpdateInput",
		create:"Contember_FulfillmentNoteWithoutProductsCreateInput"
	},
	Contember_PointOfSaleCategoryProductUpsertProductRelationInput:{
		update:"Contember_ProductUpdateInput",
		create:"Contember_ProductCreateInput"
	},
	Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput:{
		create:"Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput",
		update:"Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput",
		upsert:"Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput",
		connect:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		disconnect:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		delete:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere"
	},
	Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput:{
		by:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		data:"Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"
	},
	Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput:{
		locale:"Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput",
		update:"Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput",
		upsert:"Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput",
		create:"Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"
	},
	Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput:{
		by:"Contember_PointOfSaleCategoryProductLocaleUniqueWhere",
		update:"Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput",
		create:"Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"
	},
	Contember_PointOfSaleCategoryUpsertProductsRelationInput:{
		by:"Contember_PointOfSaleCategoryProductUniqueWhere",
		update:"Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput",
		create:"Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"
	},
	Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput:{
		update:"Contember_PointOfSaleCategoryWithoutLocalesUpdateInput",
		create:"Contember_PointOfSaleCategoryWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertPointOfSaleCategoriesRelationInput:{
		by:"Contember_PointOfSaleCategoryLocaleUniqueWhere",
		update:"Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput",
		create:"Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutProductsUpdateInput",
		create:"Contember_LocaleWithoutProductsCreateInput"
	},
	Contember_ProductUpsertLocalesRelationInput:{
		by:"Contember_ProductLocaleUniqueWhere",
		update:"Contember_ProductLocaleWithoutRootUpdateInput",
		create:"Contember_ProductLocaleWithoutRootCreateInput"
	},
	Contember_ProductRecipeUpsertProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		update:"Contember_ProductWithoutRecipeUpdateInput",
		create:"Contember_ProductWithoutRecipeCreateInput"
	},
	Contember_ProductRecipeUpdateLocalesEntityRelationInput:{
		create:"Contember_ProductRecipeLocaleWithoutRootCreateInput",
		update:"Contember_ProductRecipeUpdateLocalesRelationInput",
		upsert:"Contember_ProductRecipeUpsertLocalesRelationInput",
		connect:"Contember_ProductRecipeLocaleUniqueWhere",
		disconnect:"Contember_ProductRecipeLocaleUniqueWhere",
		delete:"Contember_ProductRecipeLocaleUniqueWhere"
	},
	Contember_ProductRecipeUpdateLocalesRelationInput:{
		by:"Contember_ProductRecipeLocaleUniqueWhere",
		data:"Contember_ProductRecipeLocaleWithoutRootUpdateInput"
	},
	Contember_ProductRecipeLocaleWithoutRootUpdateInput:{
		locale:"Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutProductRecipesCreateInput",
		update:"Contember_LocaleWithoutProductRecipesUpdateInput",
		upsert:"Contember_ProductRecipeLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutProductRecipesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_ProductRecipeLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutProductRecipesUpdateInput",
		create:"Contember_LocaleWithoutProductRecipesCreateInput"
	},
	Contember_ProductRecipeUpsertLocalesRelationInput:{
		by:"Contember_ProductRecipeLocaleUniqueWhere",
		update:"Contember_ProductRecipeLocaleWithoutRootUpdateInput",
		create:"Contember_ProductRecipeLocaleWithoutRootCreateInput"
	},
	Contember_PreparedPackingQuantityUpsertRecipeRelationInput:{
		update:"Contember_ProductRecipeUpdateInput",
		create:"Contember_ProductRecipeCreateInput"
	},
	Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput:{
		by:"Contember_PreparedPackingQuantityUniqueWhere",
		update:"Contember_PreparedPackingQuantityWithoutPackingUpdateInput",
		create:"Contember_PreparedPackingQuantityWithoutPackingCreateInput"
	},
	Contember_ProductPackingLocaleUpsertRootRelationInput:{
		update:"Contember_ProductPackingWithoutLocalesUpdateInput",
		create:"Contember_ProductPackingWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertProductPackingsRelationInput:{
		by:"Contember_ProductPackingLocaleUniqueWhere",
		update:"Contember_ProductPackingLocaleWithoutLocaleUpdateInput",
		create:"Contember_ProductPackingLocaleWithoutLocaleCreateInput"
	},
	Contember_DeliveryMethodLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutDeliveryMethodsUpdateInput",
		create:"Contember_LocaleWithoutDeliveryMethodsCreateInput"
	},
	Contember_DeliveryMethodUpsertLocalesRelationInput:{
		by:"Contember_DeliveryMethodLocaleUniqueWhere",
		update:"Contember_DeliveryMethodLocaleWithoutRootUpdateInput",
		create:"Contember_DeliveryMethodLocaleWithoutRootCreateInput"
	},
	Contember_CourierUpsertDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodUniqueWhere",
		update:"Contember_DeliveryMethodWithoutCourierHandlersUpdateInput",
		create:"Contember_DeliveryMethodWithoutCourierHandlersCreateInput"
	},
	Contember_DeliveryRouteUpsertCourierRelationInput:{
		update:"Contember_CourierWithoutRoutesUpdateInput",
		create:"Contember_CourierWithoutRoutesCreateInput"
	},
	Contember_DeliveryWaypointUpsertRouteRelationInput:{
		update:"Contember_DeliveryRouteWithoutWaypointsUpdateInput",
		create:"Contember_DeliveryRouteWithoutWaypointsCreateInput"
	},
	Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput:{
		update:"Contember_DeliveryWaypointWithoutDeliveryUpdateInput",
		create:"Contember_DeliveryWaypointWithoutDeliveryCreateInput"
	},
	Contember_OrderUpsertDeliveryRelationInput:{
		update:"Contember_OrderDeliveryWithoutOrderUpdateInput",
		create:"Contember_OrderDeliveryWithoutOrderCreateInput"
	},
	Contember_OrderEventUpsertOrderRelationInput:{
		update:"Contember_OrderWithoutEventsUpdateInput",
		create:"Contember_OrderWithoutEventsCreateInput"
	},
	Contember_OrderPaymentUpsertEventsRelationInput:{
		by:"Contember_OrderEventUniqueWhere",
		update:"Contember_OrderEventWithoutPaymentUpdateInput",
		create:"Contember_OrderEventWithoutPaymentCreateInput"
	},
	Contember_InvoiceUpsertOrderPaymentsRelationInput:{
		by:"Contember_OrderPaymentUniqueWhere",
		update:"Contember_OrderPaymentWithoutInvoiceUpdateInput",
		create:"Contember_OrderPaymentWithoutInvoiceCreateInput"
	},
	Contember_CustomerUpsertInvoicesRelationInput:{
		by:"Contember_InvoiceUniqueWhere",
		update:"Contember_InvoiceWithoutCustomerUpdateInput",
		create:"Contember_InvoiceWithoutCustomerCreateInput"
	},
	Contember_AnonymousSessionUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutAnonymousSessionUpdateInput",
		create:"Contember_CustomerWithoutAnonymousSessionCreateInput"
	},
	Contember_UserDeviceUpsertAnonymousSessionRelationInput:{
		update:"Contember_AnonymousSessionWithoutDevicesUpdateInput",
		create:"Contember_AnonymousSessionWithoutDevicesCreateInput"
	},
	Contember_UserUpsertDevicesRelationInput:{
		by:"Contember_UserDeviceUniqueWhere",
		update:"Contember_UserDeviceWithoutUserUpdateInput",
		create:"Contember_UserDeviceWithoutUserCreateInput"
	},
	Contember_IdentificationAttemptUpsertUserRelationInput:{
		update:"Contember_UserWithoutIdentificationAttemptsUpdateInput",
		create:"Contember_UserWithoutIdentificationAttemptsCreateInput"
	},
	Contember_IdentificationCodeUpsertAttemptsRelationInput:{
		by:"Contember_IdentificationAttemptUniqueWhere",
		update:"Contember_IdentificationAttemptWithoutCodeUpdateInput",
		create:"Contember_IdentificationAttemptWithoutCodeCreateInput"
	},
	Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput:{
		update:"Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput",
		create:"Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"
	},
	Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput:{
		by:"Contember_PointOfSaleApplicationDeviceUniqueWhere",
		update:"Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput",
		create:"Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"
	},
	Contember_TimetableTemplateUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutTimetableTemplatesUpdateInput",
		create:"Contember_VenueWithoutTimetableTemplatesCreateInput"
	},
	Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput:{
		by:"Contember_TimetableTemplateUniqueWhere",
		update:"Contember_TimetableTemplateWithoutPositionUpdateInput",
		create:"Contember_TimetableTemplateWithoutPositionCreateInput"
	},
	Contember_TimetableShiftGroupUpsertPositionRelationInput:{
		update:"Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput",
		create:"Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"
	},
	Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput:{
		create:"Contember_TimetableTemplateCreateInput",
		update:"Contember_TimetableTemplateUpdateInput",
		upsert:"Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput",
		connect:"Contember_TimetableTemplateUniqueWhere"
	},
	Contember_TimetableTemplateUpdateInput:{
		startDate:"Date",
		position:"Contember_TimetableTemplateUpdatePositionEntityRelationInput",
		staffOptions:"Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput",
		venue:"Contember_TimetableTemplateUpdateVenueEntityRelationInput",
		employeeRole:"Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput",
		type:"Contember_TemplateType"
	},
	Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput:{
		update:"Contember_TimetableTemplateUpdateInput",
		create:"Contember_TimetableTemplateCreateInput"
	},
	Contember_TimetableShiftUpsertGroupRelationInput:{
		update:"Contember_TimetableShiftGroupWithoutShiftsUpdateInput",
		create:"Contember_TimetableShiftGroupWithoutShiftsCreateInput"
	},
	Contember_TimetableShiftUpdateQueueItemEntityRelationInput:{
		create:"Contember_EmployeeQueueItemWithoutShiftCreateInput",
		update:"Contember_EmployeeQueueItemWithoutShiftUpdateInput",
		upsert:"Contember_TimetableShiftUpsertQueueItemRelationInput",
		connect:"Contember_EmployeeQueueItemUniqueWhere"
	},
	Contember_EmployeeQueueItemWithoutShiftUpdateInput:{
		queue:"Contember_EmployeeQueueItemUpdateQueueEntityRelationInput",
		shiftsProfile:"Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput",
		unassignInfo:"Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput",
		meta:"Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"
	},
	Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput:{
		create:"Contember_UnassignedShiftInfoWithoutQueueItemCreateInput",
		update:"Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput",
		upsert:"Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput",
		connect:"Contember_UnassignedShiftInfoUniqueWhere"
	},
	Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput:{
		legacyReason:"Contember_UnassignedInfoReason",
		reason:"Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput"
	},
	Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput:{
		create:"Contember_UnassignReasonCreateInput",
		update:"Contember_UnassignReasonUpdateInput",
		upsert:"Contember_UnassignedShiftInfoUpsertReasonRelationInput",
		connect:"Contember_UnassignReasonUniqueWhere"
	},
	Contember_UnassignReasonUpdateInput:{
		venues:"Contember_UnassignReasonUpdateVenuesEntityRelationInput"
	},
	Contember_UnassignReasonUpdateVenuesEntityRelationInput:{
		create:"Contember_VenueWithoutEnabledUnassignReasonsCreateInput",
		update:"Contember_UnassignReasonUpdateVenuesRelationInput",
		upsert:"Contember_UnassignReasonUpsertVenuesRelationInput",
		connect:"Contember_VenueUniqueWhere",
		disconnect:"Contember_VenueUniqueWhere",
		delete:"Contember_VenueUniqueWhere"
	},
	Contember_UnassignReasonUpdateVenuesRelationInput:{
		by:"Contember_VenueUniqueWhere",
		data:"Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"
	},
	Contember_VenueWithoutEnabledUnassignReasonsUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_UnassignReasonUpsertVenuesRelationInput:{
		by:"Contember_VenueUniqueWhere",
		update:"Contember_VenueWithoutEnabledUnassignReasonsUpdateInput",
		create:"Contember_VenueWithoutEnabledUnassignReasonsCreateInput"
	},
	Contember_UnassignedShiftInfoUpsertReasonRelationInput:{
		update:"Contember_UnassignReasonUpdateInput",
		create:"Contember_UnassignReasonCreateInput"
	},
	Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput:{
		update:"Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput",
		create:"Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"
	},
	Contember_EmployeeQueueItemUpdateMetaEntityRelationInput:{
		connect:"Contember_EmployeeQueueItemMetadataUniqueWhere"
	},
	Contember_TimetableShiftUpsertQueueItemRelationInput:{
		update:"Contember_EmployeeQueueItemWithoutShiftUpdateInput",
		create:"Contember_EmployeeQueueItemWithoutShiftCreateInput"
	},
	Contember_TimetableShiftUpdateSwapInfoEntityRelationInput:{
		create:"Contember_TimetableShiftSwapInfoWithoutShiftCreateInput",
		update:"Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput",
		upsert:"Contember_TimetableShiftUpsertSwapInfoRelationInput",
		connect:"Contember_TimetableShiftSwapInfoUniqueWhere"
	},
	Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput:{
		assigned:"Date",
		swappedShiftsProfile:"Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput"
	},
	Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput:{
		create:"Contember_ShiftsProfileCreateInput",
		update:"Contember_ShiftsProfileUpdateInput",
		upsert:"Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput",
		connect:"Contember_ShiftsProfileUniqueWhere"
	},
	Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput:{
		update:"Contember_ShiftsProfileUpdateInput",
		create:"Contember_ShiftsProfileCreateInput"
	},
	Contember_TimetableShiftUpsertSwapInfoRelationInput:{
		update:"Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput",
		create:"Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"
	},
	Contember_ShiftsProfileUpsertShiftsRelationInput:{
		by:"Contember_TimetableShiftUniqueWhere",
		update:"Contember_TimetableShiftWithoutShiftsProfileUpdateInput",
		create:"Contember_TimetableShiftWithoutShiftsProfileCreateInput"
	},
	Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput:{
		update:"Contember_ShiftsProfileWithoutQueueItemsUpdateInput",
		create:"Contember_ShiftsProfileWithoutQueueItemsCreateInput"
	},
	Contember_EmployeeQueueItemUpdateShiftEntityRelationInput:{
		create:"Contember_TimetableShiftWithoutQueueItemCreateInput",
		update:"Contember_TimetableShiftWithoutQueueItemUpdateInput",
		upsert:"Contember_EmployeeQueueItemUpsertShiftRelationInput",
		connect:"Contember_TimetableShiftUniqueWhere"
	},
	Contember_TimetableShiftWithoutQueueItemUpdateInput:{
		assigned:"Date",
		group:"Contember_TimetableShiftUpdateGroupEntityRelationInput",
		shiftsProfile:"Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput",
		swapInfo:"Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"
	},
	Contember_EmployeeQueueItemUpsertShiftRelationInput:{
		update:"Contember_TimetableShiftWithoutQueueItemUpdateInput",
		create:"Contember_TimetableShiftWithoutQueueItemCreateInput"
	},
	Contember_EmployeeQueueUpsertItemsRelationInput:{
		by:"Contember_EmployeeQueueItemUniqueWhere",
		update:"Contember_EmployeeQueueItemWithoutQueueUpdateInput",
		create:"Contember_EmployeeQueueItemWithoutQueueCreateInput"
	},
	Contember_TimetableShiftGroupUpsertQueueRelationInput:{
		update:"Contember_EmployeeQueueWithoutShiftGroupUpdateInput",
		create:"Contember_EmployeeQueueWithoutShiftGroupCreateInput"
	},
	Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput:{
		by:"Contember_TimetableShiftGroupUniqueWhere",
		update:"Contember_TimetableShiftGroupWithoutPositionUpdateInput",
		create:"Contember_TimetableShiftGroupWithoutPositionCreateInput"
	},
	Contember_TimetableTemplateUpsertPositionRelationInput:{
		update:"Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput",
		create:"Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"
	},
	Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput:{
		by:"Contember_TimetableTemplateUniqueWhere",
		update:"Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput",
		create:"Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"
	},
	Contember_TimetableShiftGroupUpsertTypeRelationInput:{
		update:"Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput",
		create:"Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"
	},
	Contember_TimetableDayUpsertShiftGroupsRelationInput:{
		by:"Contember_TimetableShiftGroupUniqueWhere",
		update:"Contember_TimetableShiftGroupWithoutDayUpdateInput",
		create:"Contember_TimetableShiftGroupWithoutDayCreateInput"
	},
	Contember_TimetableDayUpdateMetaEntityRelationInput:{
		connect:"Contember_TimetableDayMetadataUniqueWhere"
	},
	Contember_VenueUpsertTimetableDaysRelationInput:{
		by:"Contember_TimetableDayUniqueWhere",
		update:"Contember_TimetableDayWithoutVenueUpdateInput",
		create:"Contember_TimetableDayWithoutVenueCreateInput"
	},
	Contember_ChannelUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutChannelsUpdateInput",
		create:"Contember_VenueWithoutChannelsCreateInput"
	},
	Contember_TagUpsertPublicInChannelsRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		update:"Contember_ChannelWithoutPublicTagsUpdateInput",
		create:"Contember_ChannelWithoutPublicTagsCreateInput"
	},
	Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutDeliveryZonesUpdateInput",
		create:"Contember_TagWithoutDeliveryZonesCreateInput"
	},
	Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput:{
		connect:"Contember_AddressMetadataUniqueWhere",
		disconnect:"Contember_AddressMetadataUniqueWhere",
		delete:"Contember_AddressMetadataUniqueWhere"
	},
	Contember_ProductUpsertDeliveryZonesRelationInput:{
		by:"Contember_DeliveryZoneUniqueWhere",
		update:"Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput",
		create:"Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"
	},
	Contember_FulfillmentNoteUpsertProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		update:"Contember_ProductWithoutFulfillmentNotesUpdateInput",
		create:"Contember_ProductWithoutFulfillmentNotesCreateInput"
	},
	Contember_TagUpsertFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		update:"Contember_FulfillmentNoteWithoutTagsUpdateInput",
		create:"Contember_FulfillmentNoteWithoutTagsCreateInput"
	},
	Contember_ChannelUpsertPublicTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutPublicInChannelsUpdateInput",
		create:"Contember_TagWithoutPublicInChannelsCreateInput"
	},
	Contember_CustomerUpsertChannelsRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		update:"Contember_ChannelWithoutCustomersUpdateInput",
		create:"Contember_ChannelWithoutCustomersCreateInput"
	},
	Contember_CustomerCreditUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutCreditsUpdateInput",
		create:"Contember_CustomerWithoutCreditsCreateInput"
	},
	Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput:{
		create:"Contember_VoucherRedemptionWithoutCustomerCreditCreateInput",
		update:"Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput",
		upsert:"Contember_CustomerCreditUpsertVoucherRedemptionRelationInput",
		connect:"Contember_VoucherRedemptionUniqueWhere"
	},
	Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput:{
		createdAt:"DateTime",
		orderDiscount:"Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput",
		voucher:"Contember_VoucherRedemptionUpdateVoucherEntityRelationInput",
		customer:"Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"
	},
	Contember_CustomerCreditUpsertVoucherRedemptionRelationInput:{
		update:"Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput",
		create:"Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"
	},
	Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput:{
		update:"Contember_CustomerCreditWithoutTransactionsUpdateInput",
		create:"Contember_CustomerCreditWithoutTransactionsCreateInput"
	},
	Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput:{
		by:"Contember_CustomerCreditTransactionUniqueWhere",
		update:"Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput",
		create:"Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"
	},
	Contember_OrderUpsertDiscountsRelationInput:{
		by:"Contember_OrderDiscountUniqueWhere",
		update:"Contember_OrderDiscountWithoutOrderUpdateInput",
		create:"Contember_OrderDiscountWithoutOrderCreateInput"
	},
	Contember_OrderItemUpsertOrderRelationInput:{
		update:"Contember_OrderWithoutItemsUpdateInput",
		create:"Contember_OrderWithoutItemsCreateInput"
	},
	Contember_ProductUpsertOrderItemsRelationInput:{
		by:"Contember_OrderItemUniqueWhere",
		update:"Contember_OrderItemWithoutProductUpdateInput",
		create:"Contember_OrderItemWithoutProductCreateInput"
	},
	Contember_StocksManagerUpsertManagedProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		update:"Contember_ProductWithoutManagerUpdateInput",
		create:"Contember_ProductWithoutManagerCreateInput"
	},
	Contember_StaffUpsertStocksManagerRelationInput:{
		update:"Contember_StocksManagerWithoutStaffUpdateInput",
		create:"Contember_StocksManagerWithoutStaffCreateInput"
	},
	Contember_StaffUpdateVenuesEntityRelationInput:{
		create:"Contember_VenueWithoutStaffMembersCreateInput",
		update:"Contember_StaffUpdateVenuesRelationInput",
		upsert:"Contember_StaffUpsertVenuesRelationInput",
		connect:"Contember_VenueUniqueWhere",
		disconnect:"Contember_VenueUniqueWhere",
		delete:"Contember_VenueUniqueWhere"
	},
	Contember_StaffUpdateVenuesRelationInput:{
		by:"Contember_VenueUniqueWhere",
		data:"Contember_VenueWithoutStaffMembersUpdateInput"
	},
	Contember_VenueWithoutStaffMembersUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableDays:"Contember_VenueUpdateTimetableDaysEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_StaffUpsertVenuesRelationInput:{
		by:"Contember_VenueUniqueWhere",
		update:"Contember_VenueWithoutStaffMembersUpdateInput",
		create:"Contember_VenueWithoutStaffMembersCreateInput"
	},
	Contember_ShiftsManagerUpsertStaffRelationInput:{
		update:"Contember_StaffWithoutShiftsManagerUpdateInput",
		create:"Contember_StaffWithoutShiftsManagerCreateInput"
	},
	Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput:{
		by:"Contember_ShiftsManagerUniqueWhere",
		update:"Contember_ShiftsManagerWithoutManagedRolesUpdateInput",
		create:"Contember_ShiftsManagerWithoutManagedRolesCreateInput"
	},
	Contember_ShiftsProfileUpsertRolesRelationInput:{
		by:"Contember_TimetableEmployeeRoleUniqueWhere",
		update:"Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput",
		create:"Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"
	},
	Contember_StaffUpsertShiftsProfileRelationInput:{
		update:"Contember_ShiftsProfileWithoutStaffUpdateInput",
		create:"Contember_ShiftsProfileWithoutStaffCreateInput"
	},
	Contember_UserUpsertStaffRelationInput:{
		update:"Contember_StaffWithoutUserUpdateInput",
		create:"Contember_StaffWithoutUserCreateInput"
	},
	Contember_CustomerUpsertUserRelationInput:{
		update:"Contember_UserWithoutCustomerUpdateInput",
		create:"Contember_UserWithoutCustomerCreateInput"
	},
	Contember_OrderUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutOrdersUpdateInput",
		create:"Contember_CustomerWithoutOrdersCreateInput"
	},
	Contember_CartUpsertOrderRelationInput:{
		update:"Contember_OrderWithoutCartUpdateInput",
		create:"Contember_OrderWithoutCartCreateInput"
	},
	Contember_CustomerUpsertCartsRelationInput:{
		by:"Contember_CartUniqueWhere",
		update:"Contember_CartWithoutCustomerUpdateInput",
		create:"Contember_CartWithoutCustomerCreateInput"
	},
	Contember_UserUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutUserUpdateInput",
		create:"Contember_CustomerWithoutUserCreateInput"
	},
	Contember_StaffUpsertUserRelationInput:{
		update:"Contember_UserWithoutStaffUpdateInput",
		create:"Contember_UserWithoutStaffCreateInput"
	},
	Contember_BusinessToBusinessManagerUpsertStaffRelationInput:{
		update:"Contember_StaffWithoutBusinessToBusinessManagerUpdateInput",
		create:"Contember_StaffWithoutBusinessToBusinessManagerCreateInput"
	},
	Contember_BusinessCustomerUpsertAccountManagerRelationInput:{
		update:"Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput",
		create:"Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"
	},
	Contember_BusinessCustomerUpdateLogoEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_BusinessCustomerUpsertLogoRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_BusinessCustomerUpsertLogoRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput:{
		create:"Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput",
		update:"Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput",
		upsert:"Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput",
		connect:"Contember_BusinessServiceEventUniqueWhere",
		disconnect:"Contember_BusinessServiceEventUniqueWhere",
		delete:"Contember_BusinessServiceEventUniqueWhere"
	},
	Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput:{
		by:"Contember_BusinessServiceEventUniqueWhere",
		data:"Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"
	},
	Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput:{
		createdAt:"DateTime",
		fulfilledAt:"DateTime",
		businessService:"Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput",
		filledSupervisor:"Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput",
		createdBy:"Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput"
	},
	Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput:{
		create:"Contember_BusinessServiceCreateInput",
		update:"Contember_BusinessServiceUpdateInput",
		upsert:"Contember_BusinessServiceEventUpsertBusinessServiceRelationInput",
		connect:"Contember_BusinessServiceUniqueWhere"
	},
	Contember_BusinessServiceUpdateInput:{
		locales:"Contember_BusinessServiceUpdateLocalesEntityRelationInput"
	},
	Contember_BusinessServiceUpdateLocalesEntityRelationInput:{
		create:"Contember_BusinessServiceLocaleWithoutRootCreateInput",
		update:"Contember_BusinessServiceUpdateLocalesRelationInput",
		upsert:"Contember_BusinessServiceUpsertLocalesRelationInput",
		connect:"Contember_BusinessServiceLocaleUniqueWhere",
		disconnect:"Contember_BusinessServiceLocaleUniqueWhere",
		delete:"Contember_BusinessServiceLocaleUniqueWhere"
	},
	Contember_BusinessServiceUpdateLocalesRelationInput:{
		by:"Contember_BusinessServiceLocaleUniqueWhere",
		data:"Contember_BusinessServiceLocaleWithoutRootUpdateInput"
	},
	Contember_BusinessServiceLocaleWithoutRootUpdateInput:{
		locale:"Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutBusinessServicesCreateInput",
		update:"Contember_LocaleWithoutBusinessServicesUpdateInput",
		upsert:"Contember_BusinessServiceLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutBusinessServicesUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"
	},
	Contember_BusinessServiceLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutBusinessServicesUpdateInput",
		create:"Contember_LocaleWithoutBusinessServicesCreateInput"
	},
	Contember_BusinessServiceUpsertLocalesRelationInput:{
		by:"Contember_BusinessServiceLocaleUniqueWhere",
		update:"Contember_BusinessServiceLocaleWithoutRootUpdateInput",
		create:"Contember_BusinessServiceLocaleWithoutRootCreateInput"
	},
	Contember_BusinessServiceEventUpsertBusinessServiceRelationInput:{
		update:"Contember_BusinessServiceUpdateInput",
		create:"Contember_BusinessServiceCreateInput"
	},
	Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput:{
		create:"Contember_StaffCreateInput",
		update:"Contember_StaffUpdateInput",
		upsert:"Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_StaffUpdateInput:{
		bartender:"Contember_StaffUpdateBartenderEntityRelationInput",
		courier:"Contember_StaffUpdateCourierEntityRelationInput",
		cleaner:"Contember_StaffUpdateCleanerEntityRelationInput",
		shiftsManager:"Contember_StaffUpdateShiftsManagerEntityRelationInput",
		businessToBusinessManager:"Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput",
		user:"Contember_StaffUpdateUserEntityRelationInput",
		shiftsProfile:"Contember_StaffUpdateShiftsProfileEntityRelationInput",
		stocksManager:"Contember_StaffUpdateStocksManagerEntityRelationInput",
		photo:"Contember_StaffUpdatePhotoEntityRelationInput",
		courierManager:"Contember_StaffUpdateCourierManagerEntityRelationInput",
		picker:"Contember_StaffUpdatePickerEntityRelationInput",
		createdAt:"DateTime",
		remunerationProfile:"Contember_StaffUpdateRemunerationProfileEntityRelationInput",
		cashier:"Contember_StaffUpdateCashierEntityRelationInput",
		venues:"Contember_StaffUpdateVenuesEntityRelationInput",
		internalBuyer:"Contember_StaffUpdateInternalBuyerEntityRelationInput",
		salesManager:"Contember_StaffUpdateSalesManagerEntityRelationInput",
		cashierManager:"Contember_StaffUpdateCashierManagerEntityRelationInput"
	},
	Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput:{
		update:"Contember_StaffUpdateInput",
		create:"Contember_StaffCreateInput"
	},
	Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput:{
		create:"Contember_StaffCreateInput",
		update:"Contember_StaffUpdateInput",
		upsert:"Contember_BusinessServiceEventUpsertCreatedByRelationInput",
		connect:"Contember_StaffUniqueWhere"
	},
	Contember_BusinessServiceEventUpsertCreatedByRelationInput:{
		update:"Contember_StaffUpdateInput",
		create:"Contember_StaffCreateInput"
	},
	Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput:{
		by:"Contember_BusinessServiceEventUniqueWhere",
		update:"Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput",
		create:"Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"
	},
	Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput:{
		create:"Contember_DoubleshotCoffeeEquipmentCreateInput",
		update:"Contember_DoubleshotCoffeeEquipmentUpdateInput",
		upsert:"Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput",
		connect:"Contember_DoubleshotCoffeeEquipmentUniqueWhere"
	},
	Contember_DoubleshotCoffeeEquipmentUpdateInput:{

	},
	Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput:{
		update:"Contember_DoubleshotCoffeeEquipmentUpdateInput",
		create:"Contember_DoubleshotCoffeeEquipmentCreateInput"
	},
	Contember_CustomerUpsertOwnerOfBusinessesRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		update:"Contember_BusinessCustomerWithoutOwnersUpdateInput",
		create:"Contember_BusinessCustomerWithoutOwnersCreateInput"
	},
	Contember_BusinessCustomerUpsertMembersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		update:"Contember_CustomerWithoutMemberOfBusinessesUpdateInput",
		create:"Contember_CustomerWithoutMemberOfBusinessesCreateInput"
	},
	Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		update:"Contember_BusinessCustomerWithoutAccountManagerUpdateInput",
		create:"Contember_BusinessCustomerWithoutAccountManagerCreateInput"
	},
	Contember_StaffUpsertBusinessToBusinessManagerRelationInput:{
		update:"Contember_BusinessToBusinessManagerWithoutStaffUpdateInput",
		create:"Contember_BusinessToBusinessManagerWithoutStaffCreateInput"
	},
	Contember_ShiftsProfileUpsertStaffRelationInput:{
		update:"Contember_StaffWithoutShiftsProfileUpdateInput",
		create:"Contember_StaffWithoutShiftsProfileCreateInput"
	},
	Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput:{
		by:"Contember_ShiftsProfileUniqueWhere",
		update:"Contember_ShiftsProfileWithoutRolesUpdateInput",
		create:"Contember_ShiftsProfileWithoutRolesCreateInput"
	},
	Contember_TimetableDayNoteUpsertRoleRelationInput:{
		update:"Contember_TimetableEmployeeRoleUpdateInput",
		create:"Contember_TimetableEmployeeRoleCreateInput"
	},
	Contember_TimetableDayUpsertNotesRelationInput:{
		by:"Contember_TimetableDayNoteUniqueWhere",
		update:"Contember_TimetableDayNoteWithoutDayUpdateInput",
		create:"Contember_TimetableDayNoteWithoutDayCreateInput"
	},
	Contember_TimetableDayUpdateVenueEntityRelationInput:{
		create:"Contember_VenueWithoutTimetableDaysCreateInput",
		update:"Contember_VenueWithoutTimetableDaysUpdateInput",
		upsert:"Contember_TimetableDayUpsertVenueRelationInput",
		connect:"Contember_VenueUniqueWhere"
	},
	Contember_VenueWithoutTimetableDaysUpdateInput:{
		deadlineTemplate:"Contember_VenueUpdateDeadlineTemplateEntityRelationInput",
		timetableTemplates:"Contember_VenueUpdateTimetableTemplatesEntityRelationInput",
		staffMembers:"Contember_VenueUpdateStaffMembersEntityRelationInput",
		employeeRoles:"Contember_VenueUpdateEmployeeRolesEntityRelationInput",
		pointOfSaleApplicationDevices:"Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput",
		pointOfSaleTerminals:"Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput",
		pointOfSalePrinters:"Contember_VenueUpdatePointOfSalePrintersEntityRelationInput",
		orders:"Contember_VenueUpdateOrdersEntityRelationInput",
		fulfillmentNotes:"Contember_VenueUpdateFulfillmentNotesEntityRelationInput",
		areas:"Contember_VenueUpdateAreasEntityRelationInput",
		enabledUnassignReasons:"Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput",
		categories:"Contember_VenueUpdateCategoriesEntityRelationInput",
		channels:"Contember_VenueUpdateChannelsEntityRelationInput",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView"
	},
	Contember_TimetableDayUpsertVenueRelationInput:{
		update:"Contember_VenueWithoutTimetableDaysUpdateInput",
		create:"Contember_VenueWithoutTimetableDaysCreateInput"
	},
	Contember_TimetableShiftGroupUpsertDayRelationInput:{
		update:"Contember_TimetableDayWithoutShiftGroupsUpdateInput",
		create:"Contember_TimetableDayWithoutShiftGroupsCreateInput"
	},
	Contember_EmployeeQueueUpsertShiftGroupRelationInput:{
		update:"Contember_TimetableShiftGroupWithoutQueueUpdateInput",
		create:"Contember_TimetableShiftGroupWithoutQueueCreateInput"
	},
	Contember_EmployeeQueueItemUpsertQueueRelationInput:{
		update:"Contember_EmployeeQueueWithoutItemsUpdateInput",
		create:"Contember_EmployeeQueueWithoutItemsCreateInput"
	},
	Contember_ShiftsProfileUpsertQueueItemsRelationInput:{
		by:"Contember_EmployeeQueueItemUniqueWhere",
		update:"Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput",
		create:"Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"
	},
	Contember_TimetableShiftUpsertShiftsProfileRelationInput:{
		update:"Contember_ShiftsProfileWithoutShiftsUpdateInput",
		create:"Contember_ShiftsProfileWithoutShiftsCreateInput"
	},
	Contember_TimetableShiftGroupUpsertShiftsRelationInput:{
		by:"Contember_TimetableShiftUniqueWhere",
		update:"Contember_TimetableShiftWithoutGroupUpdateInput",
		create:"Contember_TimetableShiftWithoutGroupCreateInput"
	},
	Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput:{
		by:"Contember_TimetableShiftGroupUniqueWhere",
		update:"Contember_TimetableShiftGroupWithoutTypeUpdateInput",
		create:"Contember_TimetableShiftGroupWithoutTypeCreateInput"
	},
	Contember_ShiftsManagerUpsertManagedRolesRelationInput:{
		by:"Contember_TimetableEmployeeRoleUniqueWhere",
		update:"Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput",
		create:"Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"
	},
	Contember_StaffUpsertShiftsManagerRelationInput:{
		update:"Contember_ShiftsManagerWithoutStaffUpdateInput",
		create:"Contember_ShiftsManagerWithoutStaffCreateInput"
	},
	Contember_CourierUpsertStaffRelationInput:{
		update:"Contember_StaffWithoutCourierUpdateInput",
		create:"Contember_StaffWithoutCourierCreateInput"
	},
	Contember_DeliveryMethodUpsertCourierHandlersRelationInput:{
		by:"Contember_CourierUniqueWhere",
		update:"Contember_CourierWithoutDeliveryMethodsUpdateInput",
		create:"Contember_CourierWithoutDeliveryMethodsCreateInput"
	},
	Contember_OrderDeliveryUpsertMethodRelationInput:{
		update:"Contember_DeliveryMethodUpdateInput",
		create:"Contember_DeliveryMethodCreateInput"
	},
	Contember_DeliveryWaypointUpsertDeliveryRelationInput:{
		update:"Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput",
		create:"Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"
	},
	Contember_DeliveryRouteUpsertWaypointsRelationInput:{
		by:"Contember_DeliveryWaypointUniqueWhere",
		update:"Contember_DeliveryWaypointWithoutRouteUpdateInput",
		create:"Contember_DeliveryWaypointWithoutRouteCreateInput"
	},
	Contember_CourierUpsertRoutesRelationInput:{
		by:"Contember_DeliveryRouteUniqueWhere",
		update:"Contember_DeliveryRouteWithoutCourierUpdateInput",
		create:"Contember_DeliveryRouteWithoutCourierCreateInput"
	},
	Contember_StaffUpsertCourierRelationInput:{
		update:"Contember_CourierWithoutStaffUpdateInput",
		create:"Contember_CourierWithoutStaffCreateInput"
	},
	Contember_StocksManagerUpsertStaffRelationInput:{
		update:"Contember_StaffWithoutStocksManagerUpdateInput",
		create:"Contember_StaffWithoutStocksManagerCreateInput"
	},
	Contember_ProductUpsertManagerRelationInput:{
		update:"Contember_StocksManagerWithoutManagedProductsUpdateInput",
		create:"Contember_StocksManagerWithoutManagedProductsCreateInput"
	},
	Contember_VirtualProductUpsertProductRelationInput:{
		update:"Contember_ProductWithoutVirtualProductUpdateInput",
		create:"Contember_ProductWithoutVirtualProductCreateInput"
	},
	Contember_WorkspaceUpsertCreditsProductRelationInput:{
		update:"Contember_VirtualProductUpdateInput",
		create:"Contember_VirtualProductCreateInput"
	},
	Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput:{
		create:"Contember_VatRateCreateInput",
		update:"Contember_VatRateUpdateInput",
		upsert:"Contember_WorkspaceUpsertDiscountVatRateRelationInput",
		connect:"Contember_VatRateUniqueWhere"
	},
	Contember_WorkspaceUpsertDiscountVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_FreshingContainerUpsertWorkspaceRelationInput:{
		update:"Contember_WorkspaceWithoutFreshingContainersUpdateInput",
		create:"Contember_WorkspaceWithoutFreshingContainersCreateInput"
	},
	Contember_ProductIngredientUpsertFreshingContainerRelationInput:{
		update:"Contember_FreshingContainerUpdateInput",
		create:"Contember_FreshingContainerCreateInput"
	},
	Contember_ProductIngredientUpdateImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductIngredientUpsertImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductIngredientUpsertImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductIngredientUpdateSuppliersEntityRelationInput:{
		create:"Contember_IngredientSupplierListCreateInput",
		update:"Contember_IngredientSupplierListUpdateInput",
		upsert:"Contember_ProductIngredientUpsertSuppliersRelationInput",
		connect:"Contember_IngredientSupplierListUniqueWhere"
	},
	Contember_IngredientSupplierListUpdateInput:{
		items:"Contember_IngredientSupplierListUpdateItemsEntityRelationInput"
	},
	Contember_IngredientSupplierListUpdateItemsEntityRelationInput:{
		create:"Contember_IngredientSupplierItemWithoutListCreateInput",
		update:"Contember_IngredientSupplierListUpdateItemsRelationInput",
		upsert:"Contember_IngredientSupplierListUpsertItemsRelationInput",
		connect:"Contember_IngredientSupplierItemUniqueWhere",
		disconnect:"Contember_IngredientSupplierItemUniqueWhere",
		delete:"Contember_IngredientSupplierItemUniqueWhere"
	},
	Contember_IngredientSupplierListUpdateItemsRelationInput:{
		by:"Contember_IngredientSupplierItemUniqueWhere",
		data:"Contember_IngredientSupplierItemWithoutListUpdateInput"
	},
	Contember_IngredientSupplierItemWithoutListUpdateInput:{
		supplier:"Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput"
	},
	Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput:{
		create:"Contember_IngredientSupplierCreateInput",
		update:"Contember_IngredientSupplierUpdateInput",
		upsert:"Contember_IngredientSupplierItemUpsertSupplierRelationInput",
		connect:"Contember_IngredientSupplierUniqueWhere"
	},
	Contember_IngredientSupplierUpdateInput:{
		locales:"Contember_IngredientSupplierUpdateLocalesEntityRelationInput"
	},
	Contember_IngredientSupplierUpdateLocalesEntityRelationInput:{
		create:"Contember_IngredientSupplierLocaleWithoutRootCreateInput",
		update:"Contember_IngredientSupplierUpdateLocalesRelationInput",
		upsert:"Contember_IngredientSupplierUpsertLocalesRelationInput",
		connect:"Contember_IngredientSupplierLocaleUniqueWhere",
		disconnect:"Contember_IngredientSupplierLocaleUniqueWhere",
		delete:"Contember_IngredientSupplierLocaleUniqueWhere"
	},
	Contember_IngredientSupplierUpdateLocalesRelationInput:{
		by:"Contember_IngredientSupplierLocaleUniqueWhere",
		data:"Contember_IngredientSupplierLocaleWithoutRootUpdateInput"
	},
	Contember_IngredientSupplierLocaleWithoutRootUpdateInput:{
		locale:"Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutIngredientSuppliersCreateInput",
		update:"Contember_LocaleWithoutIngredientSuppliersUpdateInput",
		upsert:"Contember_IngredientSupplierLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutIngredientSuppliersUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productCategoryLists:"Contember_LocaleUpdateProductCategoryListsEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_IngredientSupplierLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutIngredientSuppliersUpdateInput",
		create:"Contember_LocaleWithoutIngredientSuppliersCreateInput"
	},
	Contember_IngredientSupplierUpsertLocalesRelationInput:{
		by:"Contember_IngredientSupplierLocaleUniqueWhere",
		update:"Contember_IngredientSupplierLocaleWithoutRootUpdateInput",
		create:"Contember_IngredientSupplierLocaleWithoutRootCreateInput"
	},
	Contember_IngredientSupplierItemUpsertSupplierRelationInput:{
		update:"Contember_IngredientSupplierUpdateInput",
		create:"Contember_IngredientSupplierCreateInput"
	},
	Contember_IngredientSupplierListUpsertItemsRelationInput:{
		by:"Contember_IngredientSupplierItemUniqueWhere",
		update:"Contember_IngredientSupplierItemWithoutListUpdateInput",
		create:"Contember_IngredientSupplierItemWithoutListCreateInput"
	},
	Contember_ProductIngredientUpsertSuppliersRelationInput:{
		update:"Contember_IngredientSupplierListUpdateInput",
		create:"Contember_IngredientSupplierListCreateInput"
	},
	Contember_AllergenUpsertIngredientsRelationInput:{
		by:"Contember_ProductIngredientUniqueWhere",
		update:"Contember_ProductIngredientWithoutAllergensUpdateInput",
		create:"Contember_ProductIngredientWithoutAllergensCreateInput"
	},
	Contember_AllergenLocaleUpsertRootRelationInput:{
		update:"Contember_AllergenWithoutLocalesUpdateInput",
		create:"Contember_AllergenWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertAllergensRelationInput:{
		by:"Contember_AllergenLocaleUniqueWhere",
		update:"Contember_AllergenLocaleWithoutLocaleUpdateInput",
		create:"Contember_AllergenLocaleWithoutLocaleCreateInput"
	},
	Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutOrderRecurrencesUpdateInput",
		create:"Contember_LocaleWithoutOrderRecurrencesCreateInput"
	},
	Contember_OrderRecurrenceUpsertLocalesRelationInput:{
		by:"Contember_OrderRecurrenceLocaleUniqueWhere",
		update:"Contember_OrderRecurrenceLocaleWithoutRootUpdateInput",
		create:"Contember_OrderRecurrenceLocaleWithoutRootCreateInput"
	},
	Contember_BusinessCategoryUpsertRecurrencesRelationInput:{
		by:"Contember_OrderRecurrenceUniqueWhere",
		update:"Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput",
		create:"Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"
	},
	Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput:{
		create:"Contember_ProductListWithoutBusinessCategoryCreateInput",
		update:"Contember_ProductListWithoutBusinessCategoryUpdateInput",
		upsert:"Contember_BusinessCategoryUpsertSpecificProductsRelationInput",
		connect:"Contember_ProductListUniqueWhere"
	},
	Contember_ProductListWithoutBusinessCategoryUpdateInput:{
		items:"Contember_ProductListUpdateItemsEntityRelationInput"
	},
	Contember_ProductListUpdateItemsEntityRelationInput:{
		create:"Contember_ProductListItemWithoutListCreateInput",
		update:"Contember_ProductListUpdateItemsRelationInput",
		upsert:"Contember_ProductListUpsertItemsRelationInput",
		connect:"Contember_ProductListItemUniqueWhere",
		disconnect:"Contember_ProductListItemUniqueWhere",
		delete:"Contember_ProductListItemUniqueWhere"
	},
	Contember_ProductListUpdateItemsRelationInput:{
		by:"Contember_ProductListItemUniqueWhere",
		data:"Contember_ProductListItemWithoutListUpdateInput"
	},
	Contember_ProductListItemWithoutListUpdateInput:{
		product:"Contember_ProductListItemUpdateProductEntityRelationInput"
	},
	Contember_ProductListItemUpdateProductEntityRelationInput:{
		create:"Contember_ProductCreateInput",
		update:"Contember_ProductUpdateInput",
		upsert:"Contember_ProductListItemUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductListItemUpsertProductRelationInput:{
		update:"Contember_ProductUpdateInput",
		create:"Contember_ProductCreateInput"
	},
	Contember_ProductListUpsertItemsRelationInput:{
		by:"Contember_ProductListItemUniqueWhere",
		update:"Contember_ProductListItemWithoutListUpdateInput",
		create:"Contember_ProductListItemWithoutListCreateInput"
	},
	Contember_BusinessCategoryUpsertSpecificProductsRelationInput:{
		update:"Contember_ProductListWithoutBusinessCategoryUpdateInput",
		create:"Contember_ProductListWithoutBusinessCategoryCreateInput"
	},
	Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput:{
		create:"Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput",
		update:"Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput",
		upsert:"Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput",
		connect:"Contember_ProductCategoryListUniqueWhere"
	},
	Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput:{
		items:"Contember_ProductCategoryListUpdateItemsEntityRelationInput",
		locales:"Contember_ProductCategoryListUpdateLocalesEntityRelationInput",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListUpdateLocalesEntityRelationInput:{
		create:"Contember_ProductCategoryListLocaleWithoutRootCreateInput",
		update:"Contember_ProductCategoryListUpdateLocalesRelationInput",
		upsert:"Contember_ProductCategoryListUpsertLocalesRelationInput",
		connect:"Contember_ProductCategoryListLocaleUniqueWhere",
		disconnect:"Contember_ProductCategoryListLocaleUniqueWhere",
		delete:"Contember_ProductCategoryListLocaleUniqueWhere"
	},
	Contember_ProductCategoryListUpdateLocalesRelationInput:{
		by:"Contember_ProductCategoryListLocaleUniqueWhere",
		data:"Contember_ProductCategoryListLocaleWithoutRootUpdateInput"
	},
	Contember_ProductCategoryListLocaleWithoutRootUpdateInput:{
		locale:"Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput"
	},
	Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput:{
		create:"Contember_LocaleWithoutProductCategoryListsCreateInput",
		update:"Contember_LocaleWithoutProductCategoryListsUpdateInput",
		upsert:"Contember_ProductCategoryListLocaleUpsertLocaleRelationInput",
		connect:"Contember_LocaleUniqueWhere"
	},
	Contember_LocaleWithoutProductCategoryListsUpdateInput:{
		businessCategories:"Contember_LocaleUpdateBusinessCategoriesEntityRelationInput",
		productIngredients:"Contember_LocaleUpdateProductIngredientsEntityRelationInput",
		orderRecurrences:"Contember_LocaleUpdateOrderRecurrencesEntityRelationInput",
		blogs:"Contember_LocaleUpdateBlogsEntityRelationInput",
		blogPosts:"Contember_LocaleUpdateBlogPostsEntityRelationInput",
		identifier:"Contember_LocaleUpdateIdentifierEntityRelationInput",
		menus:"Contember_LocaleUpdateMenusEntityRelationInput",
		pages:"Contember_LocaleUpdatePagesEntityRelationInput",
		productIngredientCategories:"Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput",
		allergens:"Contember_LocaleUpdateAllergensEntityRelationInput",
		productPackings:"Contember_LocaleUpdateProductPackingsEntityRelationInput",
		products:"Contember_LocaleUpdateProductsEntityRelationInput",
		productRecipes:"Contember_LocaleUpdateProductRecipesEntityRelationInput",
		pointOfSaleCategories:"Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput",
		pointOfSaleCategoryProducts:"Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput",
		deliveryMethods:"Contember_LocaleUpdateDeliveryMethodsEntityRelationInput",
		ingredientSuppliers:"Contember_LocaleUpdateIngredientSuppliersEntityRelationInput",
		identificationCodes:"Contember_LocaleUpdateIdentificationCodesEntityRelationInput",
		pipelineStages:"Contember_LocaleUpdatePipelineStagesEntityRelationInput",
		subscriptionBoxes:"Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput",
		businessServices:"Contember_LocaleUpdateBusinessServicesEntityRelationInput"
	},
	Contember_ProductCategoryListLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutProductCategoryListsUpdateInput",
		create:"Contember_LocaleWithoutProductCategoryListsCreateInput"
	},
	Contember_ProductCategoryListUpsertLocalesRelationInput:{
		by:"Contember_ProductCategoryListLocaleUniqueWhere",
		update:"Contember_ProductCategoryListLocaleWithoutRootUpdateInput",
		create:"Contember_ProductCategoryListLocaleWithoutRootCreateInput"
	},
	Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput:{
		create:"Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput",
		update:"Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput",
		upsert:"Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput",
		connect:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput:{
		locales:"Contember_BusinessCategoryUpdateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"
	},
	Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput:{
		update:"Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput",
		create:"Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"
	},
	Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput:{
		update:"Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput",
		create:"Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"
	},
	Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput:{
		create:"Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput",
		update:"Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput",
		upsert:"Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput",
		connect:"Contember_ProductCategoryListUniqueWhere"
	},
	Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput:{
		items:"Contember_ProductCategoryListUpdateItemsEntityRelationInput",
		locales:"Contember_ProductCategoryListUpdateLocalesEntityRelationInput",
		seasonalProductCategoriesOfBusinessCategory:"Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"
	},
	Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput:{
		create:"Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput",
		update:"Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput",
		upsert:"Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput",
		connect:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput:{
		locales:"Contember_BusinessCategoryUpdateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput",
		specificProducts:"Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput:{
		update:"Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput",
		create:"Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"
	},
	Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput:{
		update:"Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput",
		create:"Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"
	},
	Contember_BusinessCustomerUpsertCategoryRelationInput:{
		update:"Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput",
		create:"Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"
	},
	Contember_CustomerUpsertMemberOfBusinessesRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		update:"Contember_BusinessCustomerWithoutMembersUpdateInput",
		create:"Contember_BusinessCustomerWithoutMembersCreateInput"
	},
	Contember_BusinessCustomerUpsertOwnersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		update:"Contember_CustomerWithoutOwnerOfBusinessesUpdateInput",
		create:"Contember_CustomerWithoutOwnerOfBusinessesCreateInput"
	},
	Contember_CustomerUpsertBusinessRelationInput:{
		update:"Contember_BusinessCustomerWithoutCustomerUpdateInput",
		create:"Contember_BusinessCustomerWithoutCustomerCreateInput"
	},
	Contember_CustomerIngredientRatingUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutIngredientRatingsUpdateInput",
		create:"Contember_CustomerWithoutIngredientRatingsCreateInput"
	},
	Contember_ProductIngredientUpsertCustomerRatingsRelationInput:{
		by:"Contember_CustomerIngredientRatingUniqueWhere",
		update:"Contember_CustomerIngredientRatingWithoutIngredientUpdateInput",
		create:"Contember_CustomerIngredientRatingWithoutIngredientCreateInput"
	},
	Contember_ProductIngredientCategoryUpsertIngredientsRelationInput:{
		by:"Contember_ProductIngredientUniqueWhere",
		update:"Contember_ProductIngredientWithoutCategoriesUpdateInput",
		create:"Contember_ProductIngredientWithoutCategoriesCreateInput"
	},
	Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput:{
		update:"Contember_ProductIngredientCategoryWithoutLocalesUpdateInput",
		create:"Contember_ProductIngredientCategoryWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertProductIngredientCategoriesRelationInput:{
		by:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		update:"Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput",
		create:"Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"
	},
	Contember_PageLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutPagesUpdateInput",
		create:"Contember_LocaleWithoutPagesCreateInput"
	},
	Contember_LinkableUpsertPageRelationInput:{
		update:"Contember_PageLocaleWithoutLinkUpdateInput",
		create:"Contember_PageLocaleWithoutLinkCreateInput"
	},
	Contember_LinkableUpdateRedirectEntityRelationInput:{
		create:"Contember_RedirectWithoutLinkCreateInput",
		update:"Contember_RedirectWithoutLinkUpdateInput",
		upsert:"Contember_LinkableUpsertRedirectRelationInput",
		connect:"Contember_RedirectUniqueWhere"
	},
	Contember_RedirectWithoutLinkUpdateInput:{
		target:"Contember_RedirectUpdateTargetEntityRelationInput"
	},
	Contember_RedirectUpdateTargetEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_RedirectUpsertTargetRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_RedirectUpsertTargetRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_LinkableUpsertRedirectRelationInput:{
		update:"Contember_RedirectWithoutLinkUpdateInput",
		create:"Contember_RedirectWithoutLinkCreateInput"
	},
	Contember_WikiPageUpsertLinkRelationInput:{
		update:"Contember_LinkableWithoutWikiPageUpdateInput",
		create:"Contember_LinkableWithoutWikiPageCreateInput"
	},
	Contember_WikiPageUpdateContentEntityRelationInput:{
		create:"Contember_ContentCreateInput",
		update:"Contember_ContentUpdateInput",
		upsert:"Contember_WikiPageUpsertContentRelationInput",
		connect:"Contember_ContentUniqueWhere"
	},
	Contember_WikiPageUpsertContentRelationInput:{
		update:"Contember_ContentUpdateInput",
		create:"Contember_ContentCreateInput"
	},
	Contember_WikiPageUpsertParentRelationInput:{
		update:"Contember_WikiPageWithoutChildrenUpdateInput",
		create:"Contember_WikiPageWithoutChildrenCreateInput"
	},
	Contember_WikiPageUpdateChildrenEntityRelationInput:{
		create:"Contember_WikiPageWithoutParentCreateInput",
		update:"Contember_WikiPageUpdateChildrenRelationInput",
		upsert:"Contember_WikiPageUpsertChildrenRelationInput",
		connect:"Contember_WikiPageUniqueWhere",
		disconnect:"Contember_WikiPageUniqueWhere",
		delete:"Contember_WikiPageUniqueWhere"
	},
	Contember_WikiPageUpdateChildrenRelationInput:{
		by:"Contember_WikiPageUniqueWhere",
		data:"Contember_WikiPageWithoutParentUpdateInput"
	},
	Contember_WikiPageWithoutParentUpdateInput:{
		link:"Contember_WikiPageUpdateLinkEntityRelationInput",
		children:"Contember_WikiPageUpdateChildrenEntityRelationInput",
		content:"Contember_WikiPageUpdateContentEntityRelationInput"
	},
	Contember_WikiPageUpsertChildrenRelationInput:{
		by:"Contember_WikiPageUniqueWhere",
		update:"Contember_WikiPageWithoutParentUpdateInput",
		create:"Contember_WikiPageWithoutParentCreateInput"
	},
	Contember_LinkableUpsertWikiPageRelationInput:{
		update:"Contember_WikiPageWithoutLinkUpdateInput",
		create:"Contember_WikiPageWithoutLinkCreateInput"
	},
	Contember_PageLocaleUpsertLinkRelationInput:{
		update:"Contember_LinkableWithoutPageUpdateInput",
		create:"Contember_LinkableWithoutPageCreateInput"
	},
	Contember_LocaleUpsertPagesRelationInput:{
		by:"Contember_PageLocaleUniqueWhere",
		update:"Contember_PageLocaleWithoutLocaleUpdateInput",
		create:"Contember_PageLocaleWithoutLocaleCreateInput"
	},
	Contember_BlogPostLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutBlogPostsUpdateInput",
		create:"Contember_LocaleWithoutBlogPostsCreateInput"
	},
	Contember_BlogPostLocaleUpdateSeoEntityRelationInput:{
		create:"Contember_SeoCreateInput",
		update:"Contember_SeoUpdateInput",
		upsert:"Contember_BlogPostLocaleUpsertSeoRelationInput",
		connect:"Contember_SeoUniqueWhere"
	},
	Contember_BlogPostLocaleUpsertSeoRelationInput:{
		update:"Contember_SeoUpdateInput",
		create:"Contember_SeoCreateInput"
	},
	Contember_BlogPostLocaleUpdateProductsEntityRelationInput:{
		create:"Contember_ProductCreateInput",
		update:"Contember_BlogPostLocaleUpdateProductsRelationInput",
		upsert:"Contember_BlogPostLocaleUpsertProductsRelationInput",
		connect:"Contember_ProductUniqueWhere",
		disconnect:"Contember_ProductUniqueWhere",
		delete:"Contember_ProductUniqueWhere"
	},
	Contember_BlogPostLocaleUpdateProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		data:"Contember_ProductUpdateInput"
	},
	Contember_BlogPostLocaleUpsertProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		update:"Contember_ProductUpdateInput",
		create:"Contember_ProductCreateInput"
	},
	Contember_LinkableUpsertBlogPostRelationInput:{
		update:"Contember_BlogPostLocaleWithoutLinkUpdateInput",
		create:"Contember_BlogPostLocaleWithoutLinkCreateInput"
	},
	Contember_LinkUpsertInternalLinkRelationInput:{
		update:"Contember_LinkableUpdateInput",
		create:"Contember_LinkableCreateInput"
	},
	Contember_ContentReferenceUpsertLinkRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_ContentReferenceUpdateBlogPostsEntityRelationInput:{
		create:"Contember_BlogPostListCreateInput",
		update:"Contember_BlogPostListUpdateInput",
		upsert:"Contember_ContentReferenceUpsertBlogPostsRelationInput",
		connect:"Contember_BlogPostListUniqueWhere"
	},
	Contember_BlogPostListUpdateInput:{
		items:"Contember_BlogPostListUpdateItemsEntityRelationInput"
	},
	Contember_BlogPostListUpdateItemsEntityRelationInput:{
		create:"Contember_BlogPostListItemWithoutListCreateInput",
		update:"Contember_BlogPostListUpdateItemsRelationInput",
		upsert:"Contember_BlogPostListUpsertItemsRelationInput",
		connect:"Contember_BlogPostListItemUniqueWhere",
		disconnect:"Contember_BlogPostListItemUniqueWhere",
		delete:"Contember_BlogPostListItemUniqueWhere"
	},
	Contember_BlogPostListUpdateItemsRelationInput:{
		by:"Contember_BlogPostListItemUniqueWhere",
		data:"Contember_BlogPostListItemWithoutListUpdateInput"
	},
	Contember_BlogPostListItemWithoutListUpdateInput:{
		blogPost:"Contember_BlogPostListItemUpdateBlogPostEntityRelationInput"
	},
	Contember_BlogPostListItemUpdateBlogPostEntityRelationInput:{
		create:"Contember_BlogPostLocaleCreateInput",
		update:"Contember_BlogPostLocaleUpdateInput",
		upsert:"Contember_BlogPostListItemUpsertBlogPostRelationInput",
		connect:"Contember_BlogPostLocaleUniqueWhere"
	},
	Contember_BlogPostLocaleUpdateInput:{
		root:"Contember_BlogPostLocaleUpdateRootEntityRelationInput",
		locale:"Contember_BlogPostLocaleUpdateLocaleEntityRelationInput",
		cover:"Contember_BlogPostLocaleUpdateCoverEntityRelationInput",
		content:"Contember_BlogPostLocaleUpdateContentEntityRelationInput",
		link:"Contember_BlogPostLocaleUpdateLinkEntityRelationInput",
		seo:"Contember_BlogPostLocaleUpdateSeoEntityRelationInput",
		products:"Contember_BlogPostLocaleUpdateProductsEntityRelationInput"
	},
	Contember_BlogPostLocaleUpdateLinkEntityRelationInput:{
		create:"Contember_LinkableWithoutBlogPostCreateInput",
		update:"Contember_LinkableWithoutBlogPostUpdateInput",
		upsert:"Contember_BlogPostLocaleUpsertLinkRelationInput",
		connect:"Contember_LinkableUniqueWhere"
	},
	Contember_LinkableWithoutBlogPostUpdateInput:{
		page:"Contember_LinkableUpdatePageEntityRelationInput",
		wikiPage:"Contember_LinkableUpdateWikiPageEntityRelationInput",
		redirect:"Contember_LinkableUpdateRedirectEntityRelationInput"
	},
	Contember_BlogPostLocaleUpsertLinkRelationInput:{
		update:"Contember_LinkableWithoutBlogPostUpdateInput",
		create:"Contember_LinkableWithoutBlogPostCreateInput"
	},
	Contember_BlogPostListItemUpsertBlogPostRelationInput:{
		update:"Contember_BlogPostLocaleUpdateInput",
		create:"Contember_BlogPostLocaleCreateInput"
	},
	Contember_BlogPostListUpsertItemsRelationInput:{
		by:"Contember_BlogPostListItemUniqueWhere",
		update:"Contember_BlogPostListItemWithoutListUpdateInput",
		create:"Contember_BlogPostListItemWithoutListCreateInput"
	},
	Contember_ContentReferenceUpsertBlogPostsRelationInput:{
		update:"Contember_BlogPostListUpdateInput",
		create:"Contember_BlogPostListCreateInput"
	},
	Contember_ContentReferenceUpdateLinksEntityRelationInput:{
		create:"Contember_LinkListCreateInput",
		update:"Contember_LinkListUpdateInput",
		upsert:"Contember_ContentReferenceUpsertLinksRelationInput",
		connect:"Contember_LinkListUniqueWhere"
	},
	Contember_ContentReferenceUpsertLinksRelationInput:{
		update:"Contember_LinkListUpdateInput",
		create:"Contember_LinkListCreateInput"
	},
	Contember_ContentReferenceUpdateProductsEntityRelationInput:{
		create:"Contember_ProductListCreateInput",
		update:"Contember_ProductListUpdateInput",
		upsert:"Contember_ContentReferenceUpsertProductsRelationInput",
		connect:"Contember_ProductListUniqueWhere"
	},
	Contember_ProductListUpdateInput:{
		items:"Contember_ProductListUpdateItemsEntityRelationInput",
		businessCategory:"Contember_ProductListUpdateBusinessCategoryEntityRelationInput"
	},
	Contember_ProductListUpdateBusinessCategoryEntityRelationInput:{
		create:"Contember_BusinessCategoryWithoutSpecificProductsCreateInput",
		update:"Contember_BusinessCategoryWithoutSpecificProductsUpdateInput",
		upsert:"Contember_ProductListUpsertBusinessCategoryRelationInput",
		connect:"Contember_BusinessCategoryUniqueWhere"
	},
	Contember_BusinessCategoryWithoutSpecificProductsUpdateInput:{
		locales:"Contember_BusinessCategoryUpdateLocalesEntityRelationInput",
		businessCustomers:"Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput",
		recurrences:"Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput",
		seasonalProductCategories:"Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput",
		specificProductsProductCategories:"Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"
	},
	Contember_ProductListUpsertBusinessCategoryRelationInput:{
		update:"Contember_BusinessCategoryWithoutSpecificProductsUpdateInput",
		create:"Contember_BusinessCategoryWithoutSpecificProductsCreateInput"
	},
	Contember_ContentReferenceUpsertProductsRelationInput:{
		update:"Contember_ProductListUpdateInput",
		create:"Contember_ProductListCreateInput"
	},
	Contember_ContentReferenceUpdateHeroEntityRelationInput:{
		create:"Contember_HeroCreateInput",
		update:"Contember_HeroUpdateInput",
		upsert:"Contember_ContentReferenceUpsertHeroRelationInput",
		connect:"Contember_HeroUniqueWhere"
	},
	Contember_HeroUpdateInput:{
		medium:"Contember_HeroUpdateMediumEntityRelationInput"
	},
	Contember_HeroUpdateMediumEntityRelationInput:{
		create:"Contember_MediumCreateInput",
		update:"Contember_MediumUpdateInput",
		upsert:"Contember_HeroUpsertMediumRelationInput",
		connect:"Contember_MediumUniqueWhere"
	},
	Contember_HeroUpsertMediumRelationInput:{
		update:"Contember_MediumUpdateInput",
		create:"Contember_MediumCreateInput"
	},
	Contember_ContentReferenceUpsertHeroRelationInput:{
		update:"Contember_HeroUpdateInput",
		create:"Contember_HeroCreateInput"
	},
	Contember_ContentReferenceUpdateGalleryEntityRelationInput:{
		create:"Contember_GalleryCreateInput",
		update:"Contember_GalleryUpdateInput",
		upsert:"Contember_ContentReferenceUpsertGalleryRelationInput",
		connect:"Contember_GalleryUniqueWhere"
	},
	Contember_GalleryUpdateInput:{
		items:"Contember_GalleryUpdateItemsEntityRelationInput"
	},
	Contember_GalleryUpdateItemsEntityRelationInput:{
		create:"Contember_GalleryItemWithoutListCreateInput",
		update:"Contember_GalleryUpdateItemsRelationInput",
		upsert:"Contember_GalleryUpsertItemsRelationInput",
		connect:"Contember_GalleryItemUniqueWhere",
		disconnect:"Contember_GalleryItemUniqueWhere",
		delete:"Contember_GalleryItemUniqueWhere"
	},
	Contember_GalleryUpdateItemsRelationInput:{
		by:"Contember_GalleryItemUniqueWhere",
		data:"Contember_GalleryItemWithoutListUpdateInput"
	},
	Contember_GalleryItemWithoutListUpdateInput:{
		medium:"Contember_GalleryItemUpdateMediumEntityRelationInput"
	},
	Contember_GalleryItemUpdateMediumEntityRelationInput:{
		create:"Contember_MediumCreateInput",
		update:"Contember_MediumUpdateInput",
		upsert:"Contember_GalleryItemUpsertMediumRelationInput",
		connect:"Contember_MediumUniqueWhere"
	},
	Contember_GalleryItemUpsertMediumRelationInput:{
		update:"Contember_MediumUpdateInput",
		create:"Contember_MediumCreateInput"
	},
	Contember_GalleryUpsertItemsRelationInput:{
		by:"Contember_GalleryItemUniqueWhere",
		update:"Contember_GalleryItemWithoutListUpdateInput",
		create:"Contember_GalleryItemWithoutListCreateInput"
	},
	Contember_ContentReferenceUpsertGalleryRelationInput:{
		update:"Contember_GalleryUpdateInput",
		create:"Contember_GalleryCreateInput"
	},
	Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput:{
		create:"Contember_SocialsAndAppsCreateInput",
		update:"Contember_SocialsAndAppsUpdateInput",
		upsert:"Contember_ContentReferenceUpsertSocialsAndAppsRelationInput",
		connect:"Contember_SocialsAndAppsUniqueWhere"
	},
	Contember_ContentReferenceUpsertSocialsAndAppsRelationInput:{
		update:"Contember_SocialsAndAppsUpdateInput",
		create:"Contember_SocialsAndAppsCreateInput"
	},
	Contember_ContentReferenceUpdateProductGridEntityRelationInput:{
		create:"Contember_ProductGridCreateInput",
		update:"Contember_ProductGridUpdateInput",
		upsert:"Contember_ContentReferenceUpsertProductGridRelationInput",
		connect:"Contember_ProductGridUniqueWhere"
	},
	Contember_ProductGridUpdateInput:{
		categories:"Contember_ProductGridUpdateCategoriesEntityRelationInput",
		type:"Contember_ProductGridType"
	},
	Contember_ProductGridUpdateCategoriesEntityRelationInput:{
		create:"Contember_ProductGridCategoryWithoutRootCreateInput",
		update:"Contember_ProductGridUpdateCategoriesRelationInput",
		upsert:"Contember_ProductGridUpsertCategoriesRelationInput",
		connect:"Contember_ProductGridCategoryUniqueWhere",
		disconnect:"Contember_ProductGridCategoryUniqueWhere",
		delete:"Contember_ProductGridCategoryUniqueWhere"
	},
	Contember_ProductGridUpdateCategoriesRelationInput:{
		by:"Contember_ProductGridCategoryUniqueWhere",
		data:"Contember_ProductGridCategoryWithoutRootUpdateInput"
	},
	Contember_ProductGridCategoryWithoutRootUpdateInput:{
		items:"Contember_ProductGridCategoryUpdateItemsEntityRelationInput",
		images:"Contember_ProductGridCategoryUpdateImagesEntityRelationInput"
	},
	Contember_ProductGridCategoryUpdateItemsEntityRelationInput:{
		create:"Contember_ProductGridItemWithoutCategoryCreateInput",
		update:"Contember_ProductGridCategoryUpdateItemsRelationInput",
		upsert:"Contember_ProductGridCategoryUpsertItemsRelationInput",
		connect:"Contember_ProductGridItemUniqueWhere",
		disconnect:"Contember_ProductGridItemUniqueWhere",
		delete:"Contember_ProductGridItemUniqueWhere"
	},
	Contember_ProductGridCategoryUpdateItemsRelationInput:{
		by:"Contember_ProductGridItemUniqueWhere",
		data:"Contember_ProductGridItemWithoutCategoryUpdateInput"
	},
	Contember_ProductGridItemWithoutCategoryUpdateInput:{
		product:"Contember_ProductGridItemUpdateProductEntityRelationInput",
		medium:"Contember_ProductGridItemUpdateMediumEntityRelationInput",
		link:"Contember_ProductGridItemUpdateLinkEntityRelationInput"
	},
	Contember_ProductGridItemUpdateProductEntityRelationInput:{
		create:"Contember_ProductCreateInput",
		update:"Contember_ProductUpdateInput",
		upsert:"Contember_ProductGridItemUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductGridItemUpsertProductRelationInput:{
		update:"Contember_ProductUpdateInput",
		create:"Contember_ProductCreateInput"
	},
	Contember_ProductGridItemUpdateMediumEntityRelationInput:{
		create:"Contember_MediumCreateInput",
		update:"Contember_MediumUpdateInput",
		upsert:"Contember_ProductGridItemUpsertMediumRelationInput",
		connect:"Contember_MediumUniqueWhere"
	},
	Contember_ProductGridItemUpsertMediumRelationInput:{
		update:"Contember_MediumUpdateInput",
		create:"Contember_MediumCreateInput"
	},
	Contember_ProductGridItemUpdateLinkEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_ProductGridItemUpsertLinkRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_ProductGridItemUpsertLinkRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_ProductGridCategoryUpsertItemsRelationInput:{
		by:"Contember_ProductGridItemUniqueWhere",
		update:"Contember_ProductGridItemWithoutCategoryUpdateInput",
		create:"Contember_ProductGridItemWithoutCategoryCreateInput"
	},
	Contember_ProductGridCategoryUpdateImagesEntityRelationInput:{
		create:"Contember_ImageListCreateInput",
		update:"Contember_ImageListUpdateInput",
		upsert:"Contember_ProductGridCategoryUpsertImagesRelationInput",
		connect:"Contember_ImageListUniqueWhere"
	},
	Contember_ProductGridCategoryUpsertImagesRelationInput:{
		update:"Contember_ImageListUpdateInput",
		create:"Contember_ImageListCreateInput"
	},
	Contember_ProductGridUpsertCategoriesRelationInput:{
		by:"Contember_ProductGridCategoryUniqueWhere",
		update:"Contember_ProductGridCategoryWithoutRootUpdateInput",
		create:"Contember_ProductGridCategoryWithoutRootCreateInput"
	},
	Contember_ContentReferenceUpsertProductGridRelationInput:{
		update:"Contember_ProductGridUpdateInput",
		create:"Contember_ProductGridCreateInput"
	},
	Contember_ContentReferenceUpdateProductBannersEntityRelationInput:{
		create:"Contember_ProductBannerListCreateInput",
		update:"Contember_ProductBannerListUpdateInput",
		upsert:"Contember_ContentReferenceUpsertProductBannersRelationInput",
		connect:"Contember_ProductBannerListUniqueWhere"
	},
	Contember_ProductBannerListUpdateInput:{
		items:"Contember_ProductBannerListUpdateItemsEntityRelationInput"
	},
	Contember_ProductBannerListUpdateItemsEntityRelationInput:{
		create:"Contember_ProductBannerItemWithoutListCreateInput",
		update:"Contember_ProductBannerListUpdateItemsRelationInput",
		upsert:"Contember_ProductBannerListUpsertItemsRelationInput",
		connect:"Contember_ProductBannerItemUniqueWhere",
		disconnect:"Contember_ProductBannerItemUniqueWhere",
		delete:"Contember_ProductBannerItemUniqueWhere"
	},
	Contember_ProductBannerListUpdateItemsRelationInput:{
		by:"Contember_ProductBannerItemUniqueWhere",
		data:"Contember_ProductBannerItemWithoutListUpdateInput"
	},
	Contember_ProductBannerItemWithoutListUpdateInput:{
		banner:"Contember_ProductBannerItemUpdateBannerEntityRelationInput",
		size:"Contember_ProductBannerItemSize"
	},
	Contember_ProductBannerItemUpdateBannerEntityRelationInput:{
		create:"Contember_ProductBannerCreateInput",
		update:"Contember_ProductBannerUpdateInput",
		upsert:"Contember_ProductBannerItemUpsertBannerRelationInput",
		connect:"Contember_ProductBannerUniqueWhere"
	},
	Contember_ProductBannerUpdateInput:{
		button:"Contember_ProductBannerUpdateButtonEntityRelationInput",
		backgroundImage:"Contember_ProductBannerUpdateBackgroundImageEntityRelationInput",
		productImage:"Contember_ProductBannerUpdateProductImageEntityRelationInput",
		product:"Contember_ProductBannerUpdateProductEntityRelationInput"
	},
	Contember_ProductBannerUpdateButtonEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_ProductBannerUpsertButtonRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_ProductBannerUpsertButtonRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_ProductBannerUpdateBackgroundImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductBannerUpsertBackgroundImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductBannerUpsertBackgroundImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductBannerUpdateProductImageEntityRelationInput:{
		create:"Contember_ImageCreateInput",
		update:"Contember_ImageUpdateInput",
		upsert:"Contember_ProductBannerUpsertProductImageRelationInput",
		connect:"Contember_ImageUniqueWhere"
	},
	Contember_ProductBannerUpsertProductImageRelationInput:{
		update:"Contember_ImageUpdateInput",
		create:"Contember_ImageCreateInput"
	},
	Contember_ProductBannerUpdateProductEntityRelationInput:{
		create:"Contember_ProductCreateInput",
		update:"Contember_ProductUpdateInput",
		upsert:"Contember_ProductBannerUpsertProductRelationInput",
		connect:"Contember_ProductUniqueWhere"
	},
	Contember_ProductBannerUpsertProductRelationInput:{
		update:"Contember_ProductUpdateInput",
		create:"Contember_ProductCreateInput"
	},
	Contember_ProductBannerItemUpsertBannerRelationInput:{
		update:"Contember_ProductBannerUpdateInput",
		create:"Contember_ProductBannerCreateInput"
	},
	Contember_ProductBannerListUpsertItemsRelationInput:{
		by:"Contember_ProductBannerItemUniqueWhere",
		update:"Contember_ProductBannerItemWithoutListUpdateInput",
		create:"Contember_ProductBannerItemWithoutListCreateInput"
	},
	Contember_ContentReferenceUpsertProductBannersRelationInput:{
		update:"Contember_ProductBannerListUpdateInput",
		create:"Contember_ProductBannerListCreateInput"
	},
	Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput:{
		create:"Contember_DeliveryRegionsCreateInput",
		update:"Contember_DeliveryRegionsUpdateInput",
		upsert:"Contember_ContentReferenceUpsertDeliveryRegionsRelationInput",
		connect:"Contember_DeliveryRegionsUniqueWhere"
	},
	Contember_ContentReferenceUpsertDeliveryRegionsRelationInput:{
		update:"Contember_DeliveryRegionsUpdateInput",
		create:"Contember_DeliveryRegionsCreateInput"
	},
	Contember_ContentReferenceUpdateTextListEntityRelationInput:{
		create:"Contember_TextListCreateInput",
		update:"Contember_TextListUpdateInput",
		upsert:"Contember_ContentReferenceUpsertTextListRelationInput",
		connect:"Contember_TextListUniqueWhere"
	},
	Contember_ContentReferenceUpsertTextListRelationInput:{
		update:"Contember_TextListUpdateInput",
		create:"Contember_TextListCreateInput"
	},
	Contember_ContentReferenceUpdateGridTilesEntityRelationInput:{
		create:"Contember_GridTilesCreateInput",
		update:"Contember_GridTilesUpdateInput",
		upsert:"Contember_ContentReferenceUpsertGridTilesRelationInput",
		connect:"Contember_GridTilesUniqueWhere"
	},
	Contember_GridTilesUpdateInput:{
		items:"Contember_GridTilesUpdateItemsEntityRelationInput"
	},
	Contember_GridTilesUpdateItemsEntityRelationInput:{
		create:"Contember_GridTileItemWithoutListCreateInput",
		update:"Contember_GridTilesUpdateItemsRelationInput",
		upsert:"Contember_GridTilesUpsertItemsRelationInput",
		connect:"Contember_GridTileItemUniqueWhere",
		disconnect:"Contember_GridTileItemUniqueWhere",
		delete:"Contember_GridTileItemUniqueWhere"
	},
	Contember_GridTilesUpdateItemsRelationInput:{
		by:"Contember_GridTileItemUniqueWhere",
		data:"Contember_GridTileItemWithoutListUpdateInput"
	},
	Contember_GridTileItemWithoutListUpdateInput:{
		medium:"Contember_GridTileItemUpdateMediumEntityRelationInput",
		link:"Contember_GridTileItemUpdateLinkEntityRelationInput"
	},
	Contember_GridTileItemUpdateMediumEntityRelationInput:{
		create:"Contember_MediumCreateInput",
		update:"Contember_MediumUpdateInput",
		upsert:"Contember_GridTileItemUpsertMediumRelationInput",
		connect:"Contember_MediumUniqueWhere"
	},
	Contember_GridTileItemUpsertMediumRelationInput:{
		update:"Contember_MediumUpdateInput",
		create:"Contember_MediumCreateInput"
	},
	Contember_GridTileItemUpdateLinkEntityRelationInput:{
		create:"Contember_LinkCreateInput",
		update:"Contember_LinkUpdateInput",
		upsert:"Contember_GridTileItemUpsertLinkRelationInput",
		connect:"Contember_LinkUniqueWhere"
	},
	Contember_GridTileItemUpsertLinkRelationInput:{
		update:"Contember_LinkUpdateInput",
		create:"Contember_LinkCreateInput"
	},
	Contember_GridTilesUpsertItemsRelationInput:{
		by:"Contember_GridTileItemUniqueWhere",
		update:"Contember_GridTileItemWithoutListUpdateInput",
		create:"Contember_GridTileItemWithoutListCreateInput"
	},
	Contember_ContentReferenceUpsertGridTilesRelationInput:{
		update:"Contember_GridTilesUpdateInput",
		create:"Contember_GridTilesCreateInput"
	},
	Contember_ContentBlockUpsertReferencesRelationInput:{
		by:"Contember_ContentReferenceUniqueWhere",
		update:"Contember_ContentReferenceWithoutBlockUpdateInput",
		create:"Contember_ContentReferenceWithoutBlockCreateInput"
	},
	Contember_ContentUpsertBlocksRelationInput:{
		by:"Contember_ContentBlockUniqueWhere",
		update:"Contember_ContentBlockWithoutContentUpdateInput",
		create:"Contember_ContentBlockWithoutContentCreateInput"
	},
	Contember_BlogPostLocaleUpsertContentRelationInput:{
		update:"Contember_ContentUpdateInput",
		create:"Contember_ContentCreateInput"
	},
	Contember_LocaleUpsertBlogPostsRelationInput:{
		by:"Contember_BlogPostLocaleUniqueWhere",
		update:"Contember_BlogPostLocaleWithoutLocaleUpdateInput",
		create:"Contember_BlogPostLocaleWithoutLocaleCreateInput"
	},
	Contember_BusinessCategoryLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutBusinessCategoriesUpdateInput",
		create:"Contember_LocaleWithoutBusinessCategoriesCreateInput"
	},
	Contember_BusinessCategoryUpsertLocalesRelationInput:{
		by:"Contember_BusinessCategoryLocaleUniqueWhere",
		update:"Contember_BusinessCategoryLocaleWithoutRootUpdateInput",
		create:"Contember_BusinessCategoryLocaleWithoutRootCreateInput"
	},
	Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput:{
		by:"Contember_BusinessCategoryUniqueWhere",
		update:"Contember_BusinessCategoryWithoutRecurrencesUpdateInput",
		create:"Contember_BusinessCategoryWithoutRecurrencesCreateInput"
	},
	Contember_OrderRecurrenceLocaleUpsertRootRelationInput:{
		update:"Contember_OrderRecurrenceWithoutLocalesUpdateInput",
		create:"Contember_OrderRecurrenceWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertOrderRecurrencesRelationInput:{
		by:"Contember_OrderRecurrenceLocaleUniqueWhere",
		update:"Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput",
		create:"Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"
	},
	Contember_AllergenLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutAllergensUpdateInput",
		create:"Contember_LocaleWithoutAllergensCreateInput"
	},
	Contember_AllergenUpsertLocalesRelationInput:{
		by:"Contember_AllergenLocaleUniqueWhere",
		update:"Contember_AllergenLocaleWithoutRootUpdateInput",
		create:"Contember_AllergenLocaleWithoutRootCreateInput"
	},
	Contember_ProductIngredientUpsertAllergensRelationInput:{
		by:"Contember_AllergenUniqueWhere",
		update:"Contember_AllergenWithoutIngredientsUpdateInput",
		create:"Contember_AllergenWithoutIngredientsCreateInput"
	},
	Contember_ProductIngredientLocaleUpsertRootRelationInput:{
		update:"Contember_ProductIngredientWithoutLocalesUpdateInput",
		create:"Contember_ProductIngredientWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertProductIngredientsRelationInput:{
		by:"Contember_ProductIngredientLocaleUniqueWhere",
		update:"Contember_ProductIngredientLocaleWithoutLocaleUpdateInput",
		create:"Contember_ProductIngredientLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutProductIngredientCategoriesUpdateInput",
		create:"Contember_LocaleWithoutProductIngredientCategoriesCreateInput"
	},
	Contember_ProductIngredientCategoryUpsertLocalesRelationInput:{
		by:"Contember_ProductIngredientCategoryLocaleUniqueWhere",
		update:"Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput",
		create:"Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"
	},
	Contember_ProductIngredientUpsertCategoriesRelationInput:{
		by:"Contember_ProductIngredientCategoryUniqueWhere",
		update:"Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput",
		create:"Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"
	},
	Contember_CustomerIngredientRatingUpsertIngredientRelationInput:{
		update:"Contember_ProductIngredientWithoutCustomerRatingsUpdateInput",
		create:"Contember_ProductIngredientWithoutCustomerRatingsCreateInput"
	},
	Contember_CustomerUpsertIngredientRatingsRelationInput:{
		by:"Contember_CustomerIngredientRatingUniqueWhere",
		update:"Contember_CustomerIngredientRatingWithoutCustomerUpdateInput",
		create:"Contember_CustomerIngredientRatingWithoutCustomerCreateInput"
	},
	Contember_CustomerGroupUpsertCustomersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		update:"Contember_CustomerWithoutGroupUpdateInput",
		create:"Contember_CustomerWithoutGroupCreateInput"
	},
	Contember_ProductGroupPriceUpsertGroupRelationInput:{
		update:"Contember_CustomerGroupWithoutProductPricesUpdateInput",
		create:"Contember_CustomerGroupWithoutProductPricesCreateInput"
	},
	Contember_ProductUpsertGroupPricesRelationInput:{
		by:"Contember_ProductGroupPriceUniqueWhere",
		update:"Contember_ProductGroupPriceWithoutProductUpdateInput",
		create:"Contember_ProductGroupPriceWithoutProductCreateInput"
	},
	Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		update:"Contember_ProductWithoutDeliveryZonesUpdateInput",
		create:"Contember_ProductWithoutDeliveryZonesCreateInput"
	},
	Contember_TagUpsertDeliveryZonesRelationInput:{
		by:"Contember_DeliveryZoneUniqueWhere",
		update:"Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput",
		create:"Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"
	},
	Contember_ChannelUpsertAddTagsOnRegistrationRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutAddTagsOnRegistrationUpdateInput",
		create:"Contember_TagWithoutAddTagsOnRegistrationCreateInput"
	},
	Contember_CartUpsertChannelRelationInput:{
		update:"Contember_ChannelWithoutCartsUpdateInput",
		create:"Contember_ChannelWithoutCartsCreateInput"
	},
	Contember_CartItemUpsertCartRelationInput:{
		update:"Contember_CartWithoutItemsUpdateInput",
		create:"Contember_CartWithoutItemsCreateInput"
	},
	Contember_CartItemUpdateFulfillmentNotesEntityRelationInput:{
		create:"Contember_FulfillmentNoteWithoutCartItemsCreateInput",
		update:"Contember_CartItemUpdateFulfillmentNotesRelationInput",
		upsert:"Contember_CartItemUpsertFulfillmentNotesRelationInput",
		connect:"Contember_FulfillmentNoteUniqueWhere",
		disconnect:"Contember_FulfillmentNoteUniqueWhere",
		delete:"Contember_FulfillmentNoteUniqueWhere"
	},
	Contember_CartItemUpdateFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		data:"Contember_FulfillmentNoteWithoutCartItemsUpdateInput"
	},
	Contember_FulfillmentNoteWithoutCartItemsUpdateInput:{
		products:"Contember_FulfillmentNoteUpdateProductsEntityRelationInput",
		tags:"Contember_FulfillmentNoteUpdateTagsEntityRelationInput",
		venues:"Contember_FulfillmentNoteUpdateVenuesEntityRelationInput",
		orderItems:"Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput",
		orders:"Contember_FulfillmentNoteUpdateOrdersEntityRelationInput",
		createdAt:"DateTime"
	},
	Contember_CartItemUpsertFulfillmentNotesRelationInput:{
		by:"Contember_FulfillmentNoteUniqueWhere",
		update:"Contember_FulfillmentNoteWithoutCartItemsUpdateInput",
		create:"Contember_FulfillmentNoteWithoutCartItemsCreateInput"
	},
	Contember_ProductUpsertCartItemsRelationInput:{
		by:"Contember_CartItemUniqueWhere",
		update:"Contember_CartItemWithoutProductUpdateInput",
		create:"Contember_CartItemWithoutProductCreateInput"
	},
	Contember_ProductGroupPriceUpsertProductRelationInput:{
		update:"Contember_ProductWithoutGroupPricesUpdateInput",
		create:"Contember_ProductWithoutGroupPricesCreateInput"
	},
	Contember_CustomerGroupUpsertProductPricesRelationInput:{
		by:"Contember_ProductGroupPriceUniqueWhere",
		update:"Contember_ProductGroupPriceWithoutGroupUpdateInput",
		create:"Contember_ProductGroupPriceWithoutGroupCreateInput"
	},
	Contember_CustomerUpsertGroupRelationInput:{
		update:"Contember_CustomerGroupWithoutCustomersUpdateInput",
		create:"Contember_CustomerGroupWithoutCustomersCreateInput"
	},
	Contember_ChannelUpsertCustomersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		update:"Contember_CustomerWithoutChannelsUpdateInput",
		create:"Contember_CustomerWithoutChannelsCreateInput"
	},
	Contember_TagUpsertAddTagsOnRegistrationRelationInput:{
		by:"Contember_ChannelUniqueWhere",
		update:"Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput",
		create:"Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"
	},
	Contember_ProductCategoryUpsertTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutCategoriesUpdateInput",
		create:"Contember_TagWithoutCategoriesCreateInput"
	},
	Contember_ProductCategoryUpsertParentRelationInput:{
		update:"Contember_ProductCategoryWithoutChildrenUpdateInput",
		create:"Contember_ProductCategoryWithoutChildrenCreateInput"
	},
	Contember_TagUpsertCategoriesRelationInput:{
		by:"Contember_ProductCategoryUniqueWhere",
		update:"Contember_ProductCategoryWithoutTagsUpdateInput",
		create:"Contember_ProductCategoryWithoutTagsCreateInput"
	},
	Contember_ProductUpsertTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutProductsUpdateInput",
		create:"Contember_TagWithoutProductsCreateInput"
	},
	Contember_ProductCategoryUpsertProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		update:"Contember_ProductWithoutCategoriesUpdateInput",
		create:"Contember_ProductWithoutCategoriesCreateInput"
	},
	Contember_ProductCategoryListItemUpsertProductCategoryRelationInput:{
		update:"Contember_ProductCategoryUpdateInput",
		create:"Contember_ProductCategoryCreateInput"
	},
	Contember_ProductCategoryListUpsertItemsRelationInput:{
		by:"Contember_ProductCategoryListItemUniqueWhere",
		update:"Contember_ProductCategoryListItemWithoutListUpdateInput",
		create:"Contember_ProductCategoryListItemWithoutListCreateInput"
	},
	Contember_ProductCategoryListLocaleUpsertRootRelationInput:{
		update:"Contember_ProductCategoryListWithoutLocalesUpdateInput",
		create:"Contember_ProductCategoryListWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertProductCategoryListsRelationInput:{
		by:"Contember_ProductCategoryListLocaleUniqueWhere",
		update:"Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput",
		create:"Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductIngredientLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutProductIngredientsUpdateInput",
		create:"Contember_LocaleWithoutProductIngredientsCreateInput"
	},
	Contember_ProductIngredientUpsertLocalesRelationInput:{
		by:"Contember_ProductIngredientLocaleUniqueWhere",
		update:"Contember_ProductIngredientLocaleWithoutRootUpdateInput",
		create:"Contember_ProductIngredientLocaleWithoutRootCreateInput"
	},
	Contember_ProductIngredientItemUpsertIngredientRelationInput:{
		update:"Contember_ProductIngredientUpdateInput",
		create:"Contember_ProductIngredientCreateInput"
	},
	Contember_ProductIngredientItemUpdateQuantityEntityRelationInput:{
		create:"Contember_ProductIngredientItemQuantityCreateInput",
		update:"Contember_ProductIngredientItemQuantityUpdateInput",
		upsert:"Contember_ProductIngredientItemUpsertQuantityRelationInput",
		connect:"Contember_ProductIngredientItemQuantityUniqueWhere"
	},
	Contember_ProductIngredientItemQuantityUpdateInput:{
		unit:"Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput"
	},
	Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput:{
		create:"Contember_ProductIngredientItemQuantityUnitCreateInput",
		update:"Contember_ProductIngredientItemQuantityUnitUpdateInput",
		upsert:"Contember_ProductIngredientItemQuantityUpsertUnitRelationInput",
		connect:"Contember_ProductIngredientItemQuantityUnitUniqueWhere"
	},
	Contember_ProductIngredientItemQuantityUpsertUnitRelationInput:{
		update:"Contember_ProductIngredientItemQuantityUnitUpdateInput",
		create:"Contember_ProductIngredientItemQuantityUnitCreateInput"
	},
	Contember_ProductIngredientItemUpsertQuantityRelationInput:{
		update:"Contember_ProductIngredientItemQuantityUpdateInput",
		create:"Contember_ProductIngredientItemQuantityCreateInput"
	},
	Contember_ProductRecipeUpsertIngredientsRelationInput:{
		by:"Contember_ProductIngredientItemUniqueWhere",
		update:"Contember_ProductIngredientItemWithoutRecipeUpdateInput",
		create:"Contember_ProductIngredientItemWithoutRecipeCreateInput"
	},
	Contember_ProductUpsertRecipeRelationInput:{
		update:"Contember_ProductRecipeWithoutProductsUpdateInput",
		create:"Contember_ProductRecipeWithoutProductsCreateInput"
	},
	Contember_CartItemUpsertProductRelationInput:{
		update:"Contember_ProductWithoutCartItemsUpdateInput",
		create:"Contember_ProductWithoutCartItemsCreateInput"
	},
	Contember_CartUpsertItemsRelationInput:{
		by:"Contember_CartItemUniqueWhere",
		update:"Contember_CartItemWithoutCartUpdateInput",
		create:"Contember_CartItemWithoutCartCreateInput"
	},
	Contember_ChannelUpsertCartsRelationInput:{
		by:"Contember_CartUniqueWhere",
		update:"Contember_CartWithoutChannelUpdateInput",
		create:"Contember_CartWithoutChannelCreateInput"
	},
	Contember_OrderUpsertChannelRelationInput:{
		update:"Contember_ChannelUpdateInput",
		create:"Contember_ChannelCreateInput"
	},
	Contember_CustomerUpsertOrdersRelationInput:{
		by:"Contember_OrderUniqueWhere",
		update:"Contember_OrderWithoutCustomerUpdateInput",
		create:"Contember_OrderWithoutCustomerCreateInput"
	},
	Contember_TagUpsertCustomersRelationInput:{
		by:"Contember_CustomerUniqueWhere",
		update:"Contember_CustomerWithoutTagsUpdateInput",
		create:"Contember_CustomerWithoutTagsCreateInput"
	},
	Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutAllowChannelPaymentsUpdateInput",
		create:"Contember_TagWithoutAllowChannelPaymentsCreateInput"
	},
	Contember_VatRateUpsertChannelPaymentsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		update:"Contember_ChannelPaymentWithoutVatRateUpdateInput",
		create:"Contember_ChannelPaymentWithoutVatRateCreateInput"
	},
	Contember_ChannelDeliveryUpsertVatRateRelationInput:{
		update:"Contember_VatRateWithoutChannelDeliveriesUpdateInput",
		create:"Contember_VatRateWithoutChannelDeliveriesCreateInput"
	},
	Contember_DeliveryMethodUpsertChannelsRelationInput:{
		by:"Contember_ChannelDeliveryUniqueWhere",
		update:"Contember_ChannelDeliveryWithoutMethodUpdateInput",
		create:"Contember_ChannelDeliveryWithoutMethodCreateInput"
	},
	Contember_PaymentMethodUpsertDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodUniqueWhere",
		update:"Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput",
		create:"Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"
	},
	Contember_ChannelPaymentUpsertMethodRelationInput:{
		update:"Contember_PaymentMethodWithoutChannelsUpdateInput",
		create:"Contember_PaymentMethodWithoutChannelsCreateInput"
	},
	Contember_ChannelUpsertPaymentsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		update:"Contember_ChannelPaymentWithoutChannelUpdateInput",
		create:"Contember_ChannelPaymentWithoutChannelCreateInput"
	},
	Contember_ChannelDeliveryUpsertChannelRelationInput:{
		update:"Contember_ChannelWithoutDeliveriesUpdateInput",
		create:"Contember_ChannelWithoutDeliveriesCreateInput"
	},
	Contember_VatRateUpsertChannelDeliveriesRelationInput:{
		by:"Contember_ChannelDeliveryUniqueWhere",
		update:"Contember_ChannelDeliveryWithoutVatRateUpdateInput",
		create:"Contember_ChannelDeliveryWithoutVatRateCreateInput"
	},
	Contember_CustomerCreditUpsertVatRateRelationInput:{
		update:"Contember_VatRateUpdateInput",
		create:"Contember_VatRateCreateInput"
	},
	Contember_CustomerUpsertCreditsRelationInput:{
		by:"Contember_CustomerCreditUniqueWhere",
		update:"Contember_CustomerCreditWithoutCustomerUpdateInput",
		create:"Contember_CustomerCreditWithoutCustomerCreateInput"
	},
	Contember_BusinessCustomerUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutBusinessUpdateInput",
		create:"Contember_CustomerWithoutBusinessCreateInput"
	},
	Contember_BusinessCategoryUpsertBusinessCustomersRelationInput:{
		by:"Contember_BusinessCustomerUniqueWhere",
		update:"Contember_BusinessCustomerWithoutCategoryUpdateInput",
		create:"Contember_BusinessCustomerWithoutCategoryCreateInput"
	},
	Contember_BusinessCategoryLocaleUpsertRootRelationInput:{
		update:"Contember_BusinessCategoryWithoutLocalesUpdateInput",
		create:"Contember_BusinessCategoryWithoutLocalesCreateInput"
	},
	Contember_LocaleUpsertBusinessCategoriesRelationInput:{
		by:"Contember_BusinessCategoryLocaleUniqueWhere",
		update:"Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput",
		create:"Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"
	},
	Contember_ProductPackingLocaleUpsertLocaleRelationInput:{
		update:"Contember_LocaleWithoutProductPackingsUpdateInput",
		create:"Contember_LocaleWithoutProductPackingsCreateInput"
	},
	Contember_ProductPackingUpsertLocalesRelationInput:{
		by:"Contember_ProductPackingLocaleUniqueWhere",
		update:"Contember_ProductPackingLocaleWithoutRootUpdateInput",
		create:"Contember_ProductPackingLocaleWithoutRootCreateInput"
	},
	Contember_ProductUpsertPackingRelationInput:{
		update:"Contember_ProductPackingUpdateInput",
		create:"Contember_ProductPackingCreateInput"
	},
	Contember_TagUpsertProductsRelationInput:{
		by:"Contember_ProductUniqueWhere",
		update:"Contember_ProductWithoutTagsUpdateInput",
		create:"Contember_ProductWithoutTagsCreateInput"
	},
	Contember_CustomerUpsertTagsRelationInput:{
		by:"Contember_TagUniqueWhere",
		update:"Contember_TagWithoutCustomersUpdateInput",
		create:"Contember_TagWithoutCustomersCreateInput"
	},
	Contember_InvoiceUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutInvoicesUpdateInput",
		create:"Contember_CustomerWithoutInvoicesCreateInput"
	},
	Contember_AddressUpsertInvoicesRelationInput:{
		by:"Contember_InvoiceUniqueWhere",
		update:"Contember_InvoiceWithoutAddressUpdateInput",
		create:"Contember_InvoiceWithoutAddressCreateInput"
	},
	Contember_DeliveryZoneUpsertAddressesRelationInput:{
		by:"Contember_AddressUniqueWhere",
		update:"Contember_AddressWithoutDeliveryZoneUpdateInput",
		create:"Contember_AddressWithoutDeliveryZoneCreateInput"
	},
	Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput:{
		by:"Contember_DeliveryZoneUniqueWhere",
		update:"Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput",
		create:"Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"
	},
	Contember_ChannelDeliveryUpsertMethodRelationInput:{
		update:"Contember_DeliveryMethodWithoutChannelsUpdateInput",
		create:"Contember_DeliveryMethodWithoutChannelsCreateInput"
	},
	Contember_ChannelUpsertDeliveriesRelationInput:{
		by:"Contember_ChannelDeliveryUniqueWhere",
		update:"Contember_ChannelDeliveryWithoutChannelUpdateInput",
		create:"Contember_ChannelDeliveryWithoutChannelCreateInput"
	},
	Contember_ChannelPaymentUpsertChannelRelationInput:{
		update:"Contember_ChannelWithoutPaymentsUpdateInput",
		create:"Contember_ChannelWithoutPaymentsCreateInput"
	},
	Contember_PaymentMethodUpsertChannelsRelationInput:{
		by:"Contember_ChannelPaymentUniqueWhere",
		update:"Contember_ChannelPaymentWithoutMethodUpdateInput",
		create:"Contember_ChannelPaymentWithoutMethodCreateInput"
	},
	Contember_DeliveryMethodUpsertPaymentMethodsRelationInput:{
		by:"Contember_PaymentMethodUniqueWhere",
		update:"Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput",
		create:"Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"
	},
	Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput:{
		by:"Contember_DeliveryMethodUniqueWhere",
		update:"Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput",
		create:"Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"
	},
	Contember_AddressUpsertDeliveryZoneRelationInput:{
		update:"Contember_DeliveryZoneWithoutAddressesUpdateInput",
		create:"Contember_DeliveryZoneWithoutAddressesCreateInput"
	},
	Contember_CustomerUpsertAddressesRelationInput:{
		by:"Contember_AddressUniqueWhere",
		update:"Contember_AddressWithoutCustomerUpdateInput",
		create:"Contember_AddressWithoutCustomerCreateInput"
	},
	Contember_CustomerPaymentMethodUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutPaymentMethodsUpdateInput",
		create:"Contember_CustomerWithoutPaymentMethodsCreateInput"
	},
	Contember_CustomerUpsertDefaultPaymentMethodRelationInput:{
		update:"Contember_CustomerPaymentMethodUpdateInput",
		create:"Contember_CustomerPaymentMethodCreateInput"
	},
	Contember_AddressUpsertCustomerRelationInput:{
		update:"Contember_CustomerWithoutAddressesUpdateInput",
		create:"Contember_CustomerWithoutAddressesCreateInput"
	},
	Contember_CustomerUpdateInput:{
		addresses:"Contember_CustomerUpdateAddressesEntityRelationInput",
		defaultBillingAddress:"Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput",
		defaultPaymentMethod:"Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput",
		tags:"Contember_CustomerUpdateTagsEntityRelationInput",
		credits:"Contember_CustomerUpdateCreditsEntityRelationInput",
		paymentMethods:"Contember_CustomerUpdatePaymentMethodsEntityRelationInput",
		orders:"Contember_CustomerUpdateOrdersEntityRelationInput",
		permissions:"Contember_CustomerUpdatePermissionsEntityRelationInput",
		group:"Contember_CustomerUpdateGroupEntityRelationInput",
		photo:"Contember_CustomerUpdatePhotoEntityRelationInput",
		name:"Contember_CustomerUpdateNameEntityRelationInput",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerUpdateIngredientRatingsEntityRelationInput",
		business:"Contember_CustomerUpdateBusinessEntityRelationInput",
		ownerOfBusinesses:"Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput",
		memberOfBusinesses:"Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput",
		carts:"Contember_CustomerUpdateCartsEntityRelationInput",
		shortcutList:"Contember_CustomerUpdateShortcutListEntityRelationInput",
		user:"Contember_CustomerUpdateUserEntityRelationInput",
		channels:"Contember_CustomerUpdateChannelsEntityRelationInput",
		disabledAt:"DateTime",
		invoices:"Contember_CustomerUpdateInvoicesEntityRelationInput",
		invoicingPlan:"Contember_CustomerUpdateInvoicingPlanEntityRelationInput",
		createdAt:"DateTime",
		billingSubjects:"Contember_CustomerUpdateBillingSubjectsEntityRelationInput",
		offeredProducts:"Contember_CustomerUpdateOfferedProductsEntityRelationInput",
		defaultDeliveryAddress:"Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput",
		defaultBillingSubject:"Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput",
		anonymousSession:"Contember_CustomerUpdateAnonymousSessionEntityRelationInput",
		boughtVouchers:"Contember_CustomerUpdateBoughtVouchersEntityRelationInput",
		ownsVouchers:"Contember_CustomerUpdateOwnsVouchersEntityRelationInput",
		redeemedVouchers:"Contember_CustomerUpdateRedeemedVouchersEntityRelationInput",
		spending:"Contember_CustomerUpdateSpendingEntityRelationInput",
		customerTabs:"Contember_CustomerUpdateCustomerTabsEntityRelationInput",
		lead:"Contember_CustomerUpdateLeadEntityRelationInput",
		meta:"Contember_CustomerUpdateMetaEntityRelationInput",
		doubleshotLastUpdatedAt:"DateTime"
	},
	Contember_QueryTransaction:{
		getCustomer:{
			by:"Contember_CustomerUniqueWhere",
			filter:"Contember_CustomerWhere"
		}
	},
	Contember__MutationErrorType: true,
	Contember_MutationTransaction:{
		updateCustomer:{
			by:"Contember_CustomerUniqueWhere",
			filter:"Contember_CustomerWhere",
			data:"Contember_CustomerUpdateInput"
		}
	},
	Contember_MutationTransactionOptions:{

	}
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		getInfo:"InfoResponse",
		getCart:"Cart",
		getCartIssues:"CartIssuesResponse",
		getCartDeliveryOptions:"DeliveryOptionsResponse",
		getCartPaymentOptions:"PaymentOptionsResponse",
		getPayment:"PaymentResponse",
		getVoucher:"VoucherResponse",
		getActiveOrder:"OrderInfoResponse",
		listActiveOrder:"OrderInfoResponse",
		listCustomerToast:"Toast",
		getCustomer:"Contember_Customer",
		transaction:"Contember_QueryTransaction",
		_info:"Contember_Info"
	},
	Mutation:{
		createCart:"UpdateCartResponse",
		copyCartItems:"UpdateCartResponse",
		setItems:"UpdateCartResponse",
		calculatePosCart:"PosCart",
		deleteCart:"DeleteCartResponse",
		addItemToCart:"UpdateCartResponse",
		setItemQuantityInCart:"UpdateCartResponse",
		setCartDeliveryOption:"SetCartDeliveryOptionResponse",
		setCartPaymentOption:"SetCartPaymentOptionResponse",
		confirmOrder:"ConfirmOrderResponse",
		updateCustomerInfo:"UpdateCustomerInfoResponse",
		addAddress:"AddAddressResponse",
		editAddress:"EditAddressResponse",
		action:"ActionResponse",
		redeemVoucher:"RedeemVoucherResponse",
		updateCustomer:"Contember_CustomerUpdateResult",
		transaction:"Contember_MutationTransaction"
	},
	InfoResponse:{
		soonestDeliveryOption:"DeliveryOption"
	},
	DeliveryOption:{
		id:"String",
		selected:"Boolean",
		delivery:"ChannelDelivery",
		address:"Address",
		timeline:"DeliveryTimeline",
		timelineDescription:"String",
		priceCents:"Int",
		vatRate:"VatRate"
	},
	ChannelDelivery:{
		id:"UUID",
		_meta:"ChannelDeliveryMeta",
		order:"Int",
		priceCents:"Int",
		channel:"Contember_Channel",
		method:"DeliveryMethod",
		vatRate:"VatRate",
		defaultVisible:"Boolean"
	},
	Address:{
		id:"UUID",
		_meta:"AddressMeta",
		name:"String",
		firstName:"String",
		lastName:"String",
		addressLine2:"String",
		email:"String",
		phone:"String",
		companyName:"String",
		companyIdentifier:"String",
		vatIdentifier:"String",
		externalIdentifier:"String",
		hidden:"Boolean",
		customer:"Contember_Customer",
		internalNote:"String",
		country:"Contember_Country",
		deletedAt:"DateTime",
		note:"String",
		googlePlaceId:"String",
		raw:"String",
		formatted:"String",
		geocodeGeneratedAt:"DateTime",
		geocodeResponse:"String",
		deliveryZone:"Contember_DeliveryZone",
		meta:"Contember_AddressMetadata",
		gps:"Contember_Gps",
		geocodeValid:"Boolean",
		fullName:"String",
		fakturoidSubjectId:"String",
		invoices:"Contember_Invoice",
		addressLine1:"String",
		city:"String",
		postalCode:"String",
		createdAt:"DateTime",
		billingAddressOfOrders:"Order",
		deliveryAddressOfOrderDeliveries:"OrderDelivery",
		defaultDeliveryAddressOfCustomer:"Contember_Customer",
		invoicesByPublicKey:"Contember_Invoice",
		invoicesByFakturoidId:"Contember_Invoice",
		invoicesByOrderPayments:"Contember_Invoice",
		billingAddressOfOrdersBySeq:"Order",
		billingAddressOfOrdersByDoubleshotLegacyId:"Order",
		billingAddressOfOrdersByDoubleShotLegacyNumber:"Order",
		billingAddressOfOrdersByDiscounts:"Order",
		billingAddressOfOrdersByDelivery:"Order",
		billingAddressOfOrdersByItems:"Order",
		billingAddressOfOrdersByCart:"Order",
		billingAddressOfOrdersByEvents:"Order",
		billingAddressOfOrdersByPriceLines:"Order",
		billingAddressOfOrdersByPayments:"Order",
		billingAddressOfOrdersBySummary:"Order",
		billingAddressOfOrdersByGratuity:"Order",
		deliveryAddressOfOrderDeliveriesByTrackingCode:"OrderDelivery",
		deliveryAddressOfOrderDeliveriesByOrder:"OrderDelivery",
		deliveryAddressOfOrderDeliveriesByDeliveryWaypoint:"OrderDelivery",
		deliveryAddressOfOrderDeliveriesByEvents:"OrderDelivery",
		deliveryAddressOfOrderDeliveriesByLiftagoRide:"OrderDelivery",
		paginateInvoices:"Contember_InvoiceConnection",
		paginateBillingAddressOfOrders:"OrderConnection",
		paginateDeliveryAddressOfOrderDeliveries:"OrderDeliveryConnection"
	},
	DeliveryTimeline:{
		type:"DeliveryTimelineType",
		prepareAt:"String",
		loadingHours:"Int",
		dispatchAt:"String",
		duration:"String",
		expectedAt:"String",
		window:"String",
		expectedEndAt:"String"
	},
	VatRate:{
		id:"UUID",
		_meta:"VatRateMeta",
		name:"String",
		ratePermille:"Int",
		description:"String",
		internalNote:"String",
		channelDeliveries:"ChannelDelivery",
		channelPayments:"ChannelPayment",
		preset:"Contember_VatRatePreset",
		paginateChannelDeliveries:"ChannelDeliveryConnection",
		paginateChannelPayments:"ChannelPaymentConnection"
	},
	Cart:{
		id:"UUID",
		handle:"String",
		itemsPriceCents:"Int",
		deliveryPriceCents:"Int",
		totalPriceCents:"Int",
		remainingPriceCents:"Int",
		itemsCount:"Int",
		items:"CartItem",
		customerId:"UUID",
		channel:"ChannelCode",
		order:"Order",
		deliveryZones:"String",
		deliveryOption:"DeliveryOption",
		paymentOption:"PaymentOption",
		checkoutUrl:"String"
	},
	CartItem:{
		itemId:"UUID",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		productId:"UUID",
		productName:"String",
		productCode:"String",
		quantity:"Int",
		availableQuantity:"Int",
		unitPriceCents:"Int",
		priceCents:"Int",
		product:"Product",
		vatRate:"VatRate",
		deliveryZones:"String"
	},
	Product:{
		id:"UUID",
		_meta:"ProductMeta",
		name:"String",
		code:"String",
		priceCents:"Int",
		notDiscountable:"Boolean",
		packing:"Contember_ProductPacking",
		recipe:"Contember_ProductRecipe",
		tags:"Contember_Tag",
		categories:"Contember_ProductCategory",
		vatRate:"VatRate",
		stocks:"Contember_ProductStock",
		groupPrices:"Contember_ProductGroupPrice",
		image:"Contember_Image",
		description:"String",
		internalNote:"String",
		cartItems:"Contember_CartItem",
		availability:"Contember_ProductAvailability",
		order:"Float",
		manager:"Contember_StocksManager",
		orderItems:"OrderItem",
		maximumQuantityPerOrder:"Int",
		inheritedCategories:"Contember_NestedProductCategoryProduct",
		deliveryZones:"Contember_DeliveryZone",
		locales:"Contember_ProductLocale",
		archivedAt:"DateTime",
		customerPrices:"Contember_ProductCustomerPrice",
		virtualProduct:"Contember_VirtualProduct",
		meta:"Contember_ProductMetadata",
		page:"Contember_Page",
		salesDays:"Contember_ProductSalesDay",
		salesWeeks:"Contember_ProductSalesWeek",
		salesMonths:"Contember_ProductSalesMonth",
		fulfillmentNotes:"Contember_FulfillmentNote",
		hasSubscription:"Boolean",
		pointOfSaleTileColor:"String",
		stocksByStore:"Contember_ProductStock",
		groupPricesByGroup:"Contember_ProductGroupPrice",
		cartItemsByCart:"Contember_CartItem",
		orderItemsByVirtualProductEffects:"OrderItem",
		localesByLocale:"Contember_ProductLocale",
		localesByFeatureList:"Contember_ProductLocale",
		customerPricesByCustomer:"Contember_ProductCustomerPrice",
		paginateTags:"Contember_TagConnection",
		paginateCategories:"Contember_ProductCategoryConnection",
		paginateStocks:"Contember_ProductStockConnection",
		paginateGroupPrices:"Contember_ProductGroupPriceConnection",
		paginateCartItems:"Contember_CartItemConnection",
		paginateOrderItems:"OrderItemConnection",
		paginateInheritedCategories:"Contember_NestedProductCategoryProductConnection",
		paginateDeliveryZones:"Contember_DeliveryZoneConnection",
		paginateLocales:"Contember_ProductLocaleConnection",
		paginateCustomerPrices:"Contember_ProductCustomerPriceConnection",
		paginateSalesDays:"Contember_ProductSalesDayConnection",
		paginateSalesWeeks:"Contember_ProductSalesWeekConnection",
		paginateSalesMonths:"Contember_ProductSalesMonthConnection",
		paginateFulfillmentNotes:"Contember_FulfillmentNoteConnection"
	},
	Order:{
		id:"UUID",
		_meta:"OrderMeta",
		state:"Contember_OrderState",
		fulfilledAt:"DateTime",
		canceledAt:"DateTime",
		note:"String",
		customer:"Contember_Customer",
		channel:"Contember_Channel",
		discounts:"OrderDiscount",
		delivery:"OrderDelivery",
		items:"OrderItem",
		internalNote:"String",
		cart:"Contember_Cart",
		events:"Contember_OrderEvent",
		createdAt:"DateTime",
		billingAddress:"Address",
		priceLines:"OrderPriceLine",
		payments:"OrderPayment",
		seq:"Int",
		billingSubject:"Contember_BillingSubject",
		summary:"OrderSummary",
		doubleshotLegacyId:"String",
		gratuity:"Contember_Gratuity",
		venue:"Contember_Venue",
		fulfillmentNote:"String",
		fulfillmentNotes:"Contember_FulfillmentNote",
		doubleshotTotalWeightGrams:"Int",
		doubleShotLegacyNumber:"String",
		doubleshotLastUpdatedAt:"DateTime",
		doubleshotAdminUrl:"String",
		discountsByCustomerCreditTransactions:"OrderDiscount",
		discountsByVoucherRedemption:"OrderDiscount",
		itemsByVirtualProductEffects:"OrderItem",
		priceLinesByOrderItem:"OrderPriceLine",
		priceLinesByOrderDiscount:"OrderPriceLine",
		priceLinesByOrderPayment:"OrderPriceLine",
		priceLinesByOrderDelivery:"OrderPriceLine",
		paymentsByEvents:"OrderPayment",
		paymentsByMeta:"OrderPayment",
		paginateDiscounts:"OrderDiscountConnection",
		paginateItems:"OrderItemConnection",
		paginateEvents:"Contember_OrderEventConnection",
		paginatePriceLines:"OrderPriceLineConnection",
		paginatePayments:"OrderPaymentConnection",
		paginateFulfillmentNotes:"Contember_FulfillmentNoteConnection"
	},
	PaymentOption:{
		id:"String",
		payment:"ChannelPayment",
		creditsCents:"Int",
		priceCents:"Int",
		vatRate:"VatRate",
		infoAvailableCredits:"Int"
	},
	ChannelPayment:{
		id:"UUID",
		_meta:"ChannelPaymentMeta",
		order:"Int",
		channel:"Contember_Channel",
		method:"PaymentMethod",
		vatRate:"VatRate",
		requireBillingAddress:"Boolean",
		provideReceipt:"Boolean",
		requireBillingSubject:"Boolean",
		allowOnlyForTags:"Contember_Tag",
		paginateAllowOnlyForTags:"Contember_TagConnection"
	},
	CartIssuesResponse:{
		ok:"Boolean",
		issues:"CartIssue"
	},
	CartIssue:{
		scope:"String",
		code:"String",
		message:"String"
	},
	DeliveryOptionsResponse:{
		methods:"DeliveryMethodOption",
		addresses:"DeliveryAddressAddress",
		selected:"DeliveryOption"
	},
	DeliveryMethodOption:{
		id:"UUID",
		order:"Int",
		selected:"Boolean",
		delivery:"ChannelDelivery",
		priceCents:"Int",
		vatRate:"VatRate",
		options:"DeliveryOption"
	},
	DeliveryAddressAddress:{
		id:"UUID",
		selected:"Boolean",
		address:"Address",
		methods:"DeliveryMethodOption"
	},
	PaymentOptionsResponse:{
		availableCreditsCents:"Int",
		credits:"CustomerCredit",
		options:"PaymentOption"
	},
	CustomerCredit:{
		id:"UUID",
		_meta:"CustomerCreditMeta",
		initialCreditCents:"Int",
		remainingCreditsCents:"Int",
		expiresAt:"DateTime",
		reason:"String",
		customer:"Contember_Customer",
		vatRate:"VatRate",
		createdAt:"DateTime",
		transactions:"Contember_CustomerCreditTransaction",
		voucherRedemption:"Contember_VoucherRedemption",
		invoiceUrl:"String",
		transactionsByVirtualProductEffect:"Contember_CustomerCreditTransaction",
		paginateTransactions:"Contember_CustomerCreditTransactionConnection"
	},
	PaymentResponse:{
		ok:"Boolean",
		errorMessage:"String",
		payment:"OrderPaymentInfo"
	},
	OrderPaymentInfo:{
		id:"UUID"
	},
	VoucherResponse:{
		redeemable:"Boolean",
		expiredAt:"DateTime",
		redeemedAt:"DateTime",
		creditCents:"Int",
		type:"String",
		note:"String"
	},
	OrderInfoResponse:{
		order:"Order",
		timelineDescription:"String"
	},
	Toast:{
		key:"String",
		title:"String",
		text:"String",
		icon:"ToastIcon",
		detail:"ToastDetail",
		button:"ToastButton",
		rating:"ToastRating"
	},
	ToastIcon:{
		url:"String"
	},
	ToastDetail:{
		order:"Order"
	},
	ToastButton:{
		caption:"String",
		url:"String",
		iosUrl:"String",
		androidUrl:"String",
		webview:"Boolean"
	},
	ToastRating:{
		value:"ToastRatingValue",
		url:"String",
		iosUrl:"String",
		androidUrl:"String"
	},
	UpdateCartResponse:{
		ok:"Boolean",
		errorCode:"String",
		cart:"Cart"
	},
	PosCart:{
		customerId:"UUID",
		items:"PosItem",
		totalPriceCents:"Int"
	},
	PosItem:{
		productId:"UUID",
		quantity:"Int",
		unitPriceCents:"Int",
		priceCents:"Int"
	},
	DeleteCartResponse:{
		ok:"Boolean",
		errorCode:"String"
	},
	SetCartDeliveryOptionResponse:{
		ok:"Boolean",
		errorCode:"String"
	},
	SetCartPaymentOptionResponse:{
		ok:"Boolean",
		errorCode:"String"
	},
	ConfirmOrderResponse:{
		ok:"Boolean",
		errorCode:"String",
		order:"Order",
		waitingForPayment:"OrderPayment"
	},
	OrderPayment:{
		id:"UUID",
		_meta:"OrderPaymentMeta",
		data:"Json",
		paymentCents:"Int",
		method:"PaymentMethod",
		failedAt:"DateTime",
		state:"Contember_OrderPaymentState",
		approvedAt:"DateTime",
		events:"Contember_OrderEvent",
		order:"Order",
		invoice:"Contember_Invoice",
		walletType:"String",
		createdAt:"DateTime",
		publicReceiptUrl:"String",
		priceCents:"Int",
		vatRate:"VatRate",
		meta:"Contember_OrderPaymentMetadata",
		otherCurrencyPriceCents:"String",
		paginateEvents:"Contember_OrderEventConnection"
	},
	UpdateCustomerInfoResponse:{
		ok:"Boolean",
		errorCode:"String",
		errorMessage:"String"
	},
	AddAddressResponse:{
		ok:"Boolean",
		errorMessage:"String",
		node:"Address"
	},
	EditAddressResponse:{
		ok:"Boolean",
		errorMessage:"String",
		node:"Address"
	},
	ActionResponse:{
		ok:"Boolean",
		data:"String",
		errorMessage:"String"
	},
	RedeemVoucherResponse:{
		ok:"Boolean",
		message:"String",
		errorMessage:"String",
		expiredAt:"DateTime",
		redeemedAt:"DateTime",
		creditCents:"Int",
		type:"String",
		note:"String"
	},
	AddressMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		firstName:"Contember_FieldMeta",
		lastName:"Contember_FieldMeta",
		addressLine2:"Contember_FieldMeta",
		email:"Contember_FieldMeta",
		phone:"Contember_FieldMeta",
		companyName:"Contember_FieldMeta",
		companyIdentifier:"Contember_FieldMeta",
		vatIdentifier:"Contember_FieldMeta",
		externalIdentifier:"Contember_FieldMeta",
		hidden:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		country:"Contember_FieldMeta",
		deletedAt:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		googlePlaceId:"Contember_FieldMeta",
		raw:"Contember_FieldMeta",
		formatted:"Contember_FieldMeta",
		geocodeGeneratedAt:"Contember_FieldMeta",
		geocodeResponse:"Contember_FieldMeta",
		deliveryZone:"Contember_FieldMeta",
		meta:"Contember_FieldMeta",
		gps:"Contember_FieldMeta",
		geocodeValid:"Contember_FieldMeta",
		fullName:"Contember_FieldMeta",
		fakturoidSubjectId:"Contember_FieldMeta",
		invoices:"Contember_FieldMeta",
		addressLine1:"Contember_FieldMeta",
		city:"Contember_FieldMeta",
		postalCode:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		billingAddressOfOrders:"Contember_FieldMeta",
		deliveryAddressOfOrderDeliveries:"Contember_FieldMeta",
		defaultDeliveryAddressOfCustomer:"Contember_FieldMeta"
	},
	Contember_FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	Contember_Customer:{
		_meta:"Contember_CustomerMeta",
		id:"UUID",
		addresses:"Address",
		defaultBillingAddress:"Address",
		defaultPaymentMethod:"Contember_CustomerPaymentMethod",
		tags:"Contember_Tag",
		credits:"CustomerCredit",
		paymentMethods:"Contember_CustomerPaymentMethod",
		orders:"Order",
		permissions:"Contember_CustomerPermissions",
		group:"Contember_CustomerGroup",
		photo:"Contember_Image",
		phone:"String",
		name:"Contember_CustomerName",
		internalNote:"String",
		welcomeMessage:"String",
		familiarity:"Contember_CustomerFamiliarity",
		ingredientRatings:"Contember_CustomerIngredientRating",
		business:"Contember_BusinessCustomer",
		ownerOfBusinesses:"Contember_BusinessCustomer",
		memberOfBusinesses:"Contember_BusinessCustomer",
		carts:"Contember_Cart",
		shortcutList:"Contember_ApplicationShortcutList",
		user:"Contember_User",
		channels:"Contember_Channel",
		disabledAt:"DateTime",
		invoices:"Contember_Invoice",
		invoicingPlan:"Contember_InvoicingPlan",
		email:"String",
		createdAt:"DateTime",
		billingSubjects:"Contember_BillingSubject",
		offeredProducts:"Contember_ProductCustomerPrice",
		defaultDeliveryAddress:"Address",
		defaultBillingSubject:"Contember_BillingSubject",
		prefersPackingsWithoutCardboard:"Boolean",
		anonymousSession:"Contember_AnonymousSession",
		boughtVouchers:"Contember_Voucher",
		ownsVouchers:"Contember_Voucher",
		redeemedVouchers:"Contember_VoucherRedemption",
		spending:"Contember_CustomerSpending",
		customerTabs:"Contember_CustomerTab",
		lead:"Contember_PipelineLead",
		doubleshotLegacyId:"String",
		meta:"Contember_CustomerMetadata",
		doubleshotLastUpdatedAt:"DateTime",
		addressesByMeta:"Address",
		addressesByGps:"Address",
		addressesByInvoices:"Address",
		addressesByBillingAddressOfOrders:"Address",
		addressesByDeliveryAddressOfOrderDeliveries:"Address",
		addressesByDefaultDeliveryAddressOfCustomer:"Address",
		creditsByTransactions:"CustomerCredit",
		creditsByVoucherRedemption:"CustomerCredit",
		ordersBySeq:"Order",
		ordersByDoubleshotLegacyId:"Order",
		ordersByDoubleShotLegacyNumber:"Order",
		ordersByDiscounts:"Order",
		ordersByDelivery:"Order",
		ordersByItems:"Order",
		ordersByCart:"Order",
		ordersByEvents:"Order",
		ordersByPriceLines:"Order",
		ordersByPayments:"Order",
		ordersBySummary:"Order",
		ordersByGratuity:"Order",
		ingredientRatingsByIngredient:"Contember_CustomerIngredientRating",
		cartsByItems:"Contember_Cart",
		cartsByOrder:"Contember_Cart",
		cartsByPriceLines:"Contember_Cart",
		cartsBySummary:"Contember_Cart",
		invoicesByPublicKey:"Contember_Invoice",
		invoicesByFakturoidId:"Contember_Invoice",
		invoicesByOrderPayments:"Contember_Invoice",
		billingSubjectsByInvoices:"Contember_BillingSubject",
		billingSubjectsByDefaultBillingSubjectOfCustomer:"Contember_BillingSubject",
		billingSubjectsByOrders:"Contember_BillingSubject",
		offeredProductsByProduct:"Contember_ProductCustomerPrice",
		boughtVouchersByCode:"Contember_Voucher",
		boughtVouchersByRedemption:"Contember_Voucher",
		ownsVouchersByCode:"Contember_Voucher",
		ownsVouchersByRedemption:"Contember_Voucher",
		redeemedVouchersByCustomerCredit:"Contember_VoucherRedemption",
		redeemedVouchersByOrderDiscount:"Contember_VoucherRedemption",
		redeemedVouchersByVoucher:"Contember_VoucherRedemption",
		customerTabsByItems:"Contember_CustomerTab",
		customerTabsByMeta:"Contember_CustomerTab",
		leadByStageItem:"Contember_PipelineLead",
		paginateAddresses:"AddressConnection",
		paginateTags:"Contember_TagConnection",
		paginateCredits:"CustomerCreditConnection",
		paginatePaymentMethods:"Contember_CustomerPaymentMethodConnection",
		paginateOrders:"OrderConnection",
		paginateIngredientRatings:"Contember_CustomerIngredientRatingConnection",
		paginateOwnerOfBusinesses:"Contember_BusinessCustomerConnection",
		paginateMemberOfBusinesses:"Contember_BusinessCustomerConnection",
		paginateCarts:"Contember_CartConnection",
		paginateChannels:"Contember_ChannelConnection",
		paginateInvoices:"Contember_InvoiceConnection",
		paginateBillingSubjects:"Contember_BillingSubjectConnection",
		paginateOfferedProducts:"Contember_ProductCustomerPriceConnection",
		paginateBoughtVouchers:"Contember_VoucherConnection",
		paginateOwnsVouchers:"Contember_VoucherConnection",
		paginateRedeemedVouchers:"Contember_VoucherRedemptionConnection",
		paginateCustomerTabs:"Contember_CustomerTabConnection",
		paginateLead:"Contember_PipelineLeadConnection"
	},
	Contember_CustomerMeta:{
		id:"Contember_FieldMeta",
		addresses:"Contember_FieldMeta",
		defaultBillingAddress:"Contember_FieldMeta",
		defaultPaymentMethod:"Contember_FieldMeta",
		tags:"Contember_FieldMeta",
		credits:"Contember_FieldMeta",
		paymentMethods:"Contember_FieldMeta",
		orders:"Contember_FieldMeta",
		permissions:"Contember_FieldMeta",
		group:"Contember_FieldMeta",
		photo:"Contember_FieldMeta",
		phone:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		welcomeMessage:"Contember_FieldMeta",
		familiarity:"Contember_FieldMeta",
		ingredientRatings:"Contember_FieldMeta",
		business:"Contember_FieldMeta",
		ownerOfBusinesses:"Contember_FieldMeta",
		memberOfBusinesses:"Contember_FieldMeta",
		carts:"Contember_FieldMeta",
		shortcutList:"Contember_FieldMeta",
		user:"Contember_FieldMeta",
		channels:"Contember_FieldMeta",
		disabledAt:"Contember_FieldMeta",
		invoices:"Contember_FieldMeta",
		invoicingPlan:"Contember_FieldMeta",
		email:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		billingSubjects:"Contember_FieldMeta",
		offeredProducts:"Contember_FieldMeta",
		defaultDeliveryAddress:"Contember_FieldMeta",
		defaultBillingSubject:"Contember_FieldMeta",
		prefersPackingsWithoutCardboard:"Contember_FieldMeta",
		anonymousSession:"Contember_FieldMeta",
		boughtVouchers:"Contember_FieldMeta",
		ownsVouchers:"Contember_FieldMeta",
		redeemedVouchers:"Contember_FieldMeta",
		spending:"Contember_FieldMeta",
		customerTabs:"Contember_FieldMeta",
		lead:"Contember_FieldMeta",
		doubleshotLegacyId:"Contember_FieldMeta",
		meta:"Contember_FieldMeta",
		doubleshotLastUpdatedAt:"Contember_FieldMeta"
	},
	Contember_CustomerPaymentMethod:{
		_meta:"Contember_CustomerPaymentMethodMeta",
		id:"UUID",
		caption:"String",
		data:"Json",
		customer:"Contember_Customer",
		paymentMethod:"PaymentMethod"
	},
	Contember_CustomerPaymentMethodMeta:{
		id:"Contember_FieldMeta",
		caption:"Contember_FieldMeta",
		data:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		paymentMethod:"Contember_FieldMeta"
	},
	PaymentMethod:{
		_meta:"PaymentMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		deliveryMethods:"DeliveryMethod",
		description:"String",
		internalNote:"String",
		blockingDispatch:"Boolean",
		type:"Contember_PaymentMethodType",
		channels:"ChannelPayment",
		reduceGratuityByPercentage:"Int",
		paginateDeliveryMethods:"DeliveryMethodConnection",
		paginateChannels:"ChannelPaymentConnection"
	},
	PaymentMethodMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		enabled:"Contember_FieldMeta",
		deliveryMethods:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		blockingDispatch:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		channels:"Contember_FieldMeta",
		reduceGratuityByPercentage:"Contember_FieldMeta"
	},
	DeliveryMethod:{
		_meta:"DeliveryMethodMeta",
		id:"UUID",
		name:"String",
		code:"String",
		enabled:"Boolean",
		paymentMethods:"PaymentMethod",
		description:"String",
		internalNote:"String",
		requireDeliveryAddress:"Boolean",
		suitableForDeliveryZones:"Contember_DeliveryZone",
		channels:"ChannelDelivery",
		courierHandlers:"Contember_Courier",
		enableDeliveryTracking:"Boolean",
		locales:"Contember_DeliveryMethodLocale",
		timelinePreset:"Contember_DeliveryTimelinePreset",
		localesByLocale:"Contember_DeliveryMethodLocale",
		paginatePaymentMethods:"PaymentMethodConnection",
		paginateSuitableForDeliveryZones:"Contember_DeliveryZoneConnection",
		paginateChannels:"ChannelDeliveryConnection",
		paginateCourierHandlers:"Contember_CourierConnection",
		paginateLocales:"Contember_DeliveryMethodLocaleConnection"
	},
	DeliveryMethodMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		enabled:"Contember_FieldMeta",
		paymentMethods:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		requireDeliveryAddress:"Contember_FieldMeta",
		suitableForDeliveryZones:"Contember_FieldMeta",
		channels:"Contember_FieldMeta",
		courierHandlers:"Contember_FieldMeta",
		enableDeliveryTracking:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		timelinePreset:"Contember_FieldMeta"
	},
	Contember_DeliveryZone:{
		_meta:"Contember_DeliveryZoneMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String",
		type:"Contember_DeliveryZoneType",
		addresses:"Address",
		suitableDeliveryMethods:"DeliveryMethod",
		deliveryZonesOfTags:"Contember_Tag",
		deliveryZonesOfProducts:"Product",
		addressesMetadata:"Contember_AddressMetadata",
		addressesByMeta:"Address",
		addressesByGps:"Address",
		addressesByInvoices:"Address",
		addressesByBillingAddressOfOrders:"Address",
		addressesByDeliveryAddressOfOrderDeliveries:"Address",
		addressesByDefaultDeliveryAddressOfCustomer:"Address",
		addressesMetadataByAddress:"Contember_AddressMetadata",
		paginateAddresses:"AddressConnection",
		paginateSuitableDeliveryMethods:"DeliveryMethodConnection",
		paginateDeliveryZonesOfTags:"Contember_TagConnection",
		paginateDeliveryZonesOfProducts:"ProductConnection",
		paginateAddressesMetadata:"Contember_AddressMetadataConnection"
	},
	Contember_DeliveryZoneMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		addresses:"Contember_FieldMeta",
		suitableDeliveryMethods:"Contember_FieldMeta",
		deliveryZonesOfTags:"Contember_FieldMeta",
		deliveryZonesOfProducts:"Contember_FieldMeta",
		addressesMetadata:"Contember_FieldMeta"
	},
	Contember_Tag:{
		_meta:"Contember_TagMeta",
		id:"UUID",
		name:"String",
		code:"String",
		customers:"Contember_Customer",
		products:"Product",
		categories:"Contember_ProductCategory",
		description:"String",
		internalNote:"String",
		addTagsOnRegistration:"Contember_Channel",
		nestedCategories:"Contember_InheritedProductCategoryTag",
		deliveryZones:"Contember_DeliveryZone",
		publicInChannels:"Contember_Channel",
		allowChannelPayments:"ChannelPayment",
		fulfillmentNotes:"Contember_FulfillmentNote",
		paginateCustomers:"Contember_CustomerConnection",
		paginateProducts:"ProductConnection",
		paginateCategories:"Contember_ProductCategoryConnection",
		paginateAddTagsOnRegistration:"Contember_ChannelConnection",
		paginateNestedCategories:"Contember_InheritedProductCategoryTagConnection",
		paginateDeliveryZones:"Contember_DeliveryZoneConnection",
		paginatePublicInChannels:"Contember_ChannelConnection",
		paginateAllowChannelPayments:"ChannelPaymentConnection",
		paginateFulfillmentNotes:"Contember_FulfillmentNoteConnection"
	},
	Contember_TagMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		customers:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		categories:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		addTagsOnRegistration:"Contember_FieldMeta",
		nestedCategories:"Contember_FieldMeta",
		deliveryZones:"Contember_FieldMeta",
		publicInChannels:"Contember_FieldMeta",
		allowChannelPayments:"Contember_FieldMeta",
		fulfillmentNotes:"Contember_FieldMeta"
	},
	ProductMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		priceCents:"Contember_FieldMeta",
		notDiscountable:"Contember_FieldMeta",
		packing:"Contember_FieldMeta",
		recipe:"Contember_FieldMeta",
		tags:"Contember_FieldMeta",
		categories:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		stocks:"Contember_FieldMeta",
		groupPrices:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		cartItems:"Contember_FieldMeta",
		availability:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		manager:"Contember_FieldMeta",
		orderItems:"Contember_FieldMeta",
		maximumQuantityPerOrder:"Contember_FieldMeta",
		inheritedCategories:"Contember_FieldMeta",
		deliveryZones:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		archivedAt:"Contember_FieldMeta",
		customerPrices:"Contember_FieldMeta",
		virtualProduct:"Contember_FieldMeta",
		meta:"Contember_FieldMeta",
		page:"Contember_FieldMeta",
		salesDays:"Contember_FieldMeta",
		salesWeeks:"Contember_FieldMeta",
		salesMonths:"Contember_FieldMeta",
		fulfillmentNotes:"Contember_FieldMeta",
		hasSubscription:"Contember_FieldMeta",
		pointOfSaleTileColor:"Contember_FieldMeta"
	},
	Contember_ProductPacking:{
		_meta:"Contember_ProductPackingMeta",
		id:"UUID",
		name:"String",
		image:"Contember_Image",
		description:"String",
		internalNote:"String",
		volumeMl:"Int",
		icon:"Contember_Image",
		shortName:"String",
		locales:"Contember_ProductPackingLocale",
		preparedPackingQuantities:"Contember_PreparedPackingQuantity",
		isPackedInCardboard:"Boolean",
		localesByLocale:"Contember_ProductPackingLocale",
		paginateLocales:"Contember_ProductPackingLocaleConnection",
		paginatePreparedPackingQuantities:"Contember_PreparedPackingQuantityConnection"
	},
	Contember_ProductPackingMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		volumeMl:"Contember_FieldMeta",
		icon:"Contember_FieldMeta",
		shortName:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		preparedPackingQuantities:"Contember_FieldMeta",
		isPackedInCardboard:"Contember_FieldMeta"
	},
	Contember_Image:{
		_meta:"Contember_ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		alt:"String"
	},
	Contember_ImageMeta:{
		id:"Contember_FieldMeta",
		url:"Contember_FieldMeta",
		width:"Contember_FieldMeta",
		height:"Contember_FieldMeta",
		size:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		alt:"Contember_FieldMeta"
	},
	Contember_ProductPackingLocale:{
		_meta:"Contember_ProductPackingLocaleMeta",
		id:"UUID",
		name:"String",
		shortName:"String",
		description:"String",
		root:"Contember_ProductPacking",
		locale:"Contember_Locale"
	},
	Contember_ProductPackingLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		shortName:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_Locale:{
		_meta:"Contember_LocaleMeta",
		id:"UUID",
		businessCategories:"Contember_BusinessCategoryLocale",
		productCategoryLists:"Contember_ProductCategoryListLocale",
		productIngredients:"Contember_ProductIngredientLocale",
		orderRecurrences:"Contember_OrderRecurrenceLocale",
		blogs:"Contember_BlogLocale",
		blogPosts:"Contember_BlogPostLocale",
		identifier:"Contember_TranslationCataloguesIdentifier",
		menus:"Contember_MenuLocale",
		pages:"Contember_PageLocale",
		productIngredientCategories:"Contember_ProductIngredientCategoryLocale",
		allergens:"Contember_AllergenLocale",
		productPackings:"Contember_ProductPackingLocale",
		products:"Contember_ProductLocale",
		productRecipes:"Contember_ProductRecipeLocale",
		pointOfSaleCategories:"Contember_PointOfSaleCategoryLocale",
		pointOfSaleCategoryProducts:"Contember_PointOfSaleCategoryProductLocale",
		deliveryMethods:"Contember_DeliveryMethodLocale",
		ingredientSuppliers:"Contember_IngredientSupplierLocale",
		identificationCodes:"Contember_IdentificationCodeLocale",
		pipelineStages:"Contember_PipelineStageLocale",
		subscriptionBoxes:"Contember_SubscriptionBoxLocale",
		businessServices:"Contember_BusinessServiceLocale",
		businessCategoriesByRoot:"Contember_BusinessCategoryLocale",
		productCategoryListsByRoot:"Contember_ProductCategoryListLocale",
		productIngredientsByRoot:"Contember_ProductIngredientLocale",
		orderRecurrencesByRoot:"Contember_OrderRecurrenceLocale",
		blogsByRoot:"Contember_BlogLocale",
		blogPostsByRoot:"Contember_BlogPostLocale",
		blogPostsByCover:"Contember_BlogPostLocale",
		blogPostsByContent:"Contember_BlogPostLocale",
		blogPostsByLink:"Contember_BlogPostLocale",
		blogPostsBySeo:"Contember_BlogPostLocale",
		menusByRoot:"Contember_MenuLocale",
		menusByItems:"Contember_MenuLocale",
		menusBySecondaryItems:"Contember_MenuLocale",
		pagesByRoot:"Contember_PageLocale",
		pagesByLink:"Contember_PageLocale",
		pagesBySeo:"Contember_PageLocale",
		pagesByContent:"Contember_PageLocale",
		pagesByCover:"Contember_PageLocale",
		productIngredientCategoriesByRoot:"Contember_ProductIngredientCategoryLocale",
		allergensByRoot:"Contember_AllergenLocale",
		productPackingsByRoot:"Contember_ProductPackingLocale",
		productsByRoot:"Contember_ProductLocale",
		productsByFeatureList:"Contember_ProductLocale",
		productRecipesByRoot:"Contember_ProductRecipeLocale",
		pointOfSaleCategoriesByRoot:"Contember_PointOfSaleCategoryLocale",
		pointOfSaleCategoryProductsByRoot:"Contember_PointOfSaleCategoryProductLocale",
		deliveryMethodsByRoot:"Contember_DeliveryMethodLocale",
		ingredientSuppliersByRoot:"Contember_IngredientSupplierLocale",
		identificationCodesByRoot:"Contember_IdentificationCodeLocale",
		pipelineStagesByRoot:"Contember_PipelineStageLocale",
		subscriptionBoxesByRoot:"Contember_SubscriptionBoxLocale",
		subscriptionBoxesByTexts:"Contember_SubscriptionBoxLocale",
		subscriptionBoxesBySecondaryTexts:"Contember_SubscriptionBoxLocale",
		businessServicesByRoot:"Contember_BusinessServiceLocale",
		paginateBusinessCategories:"Contember_BusinessCategoryLocaleConnection",
		paginateProductCategoryLists:"Contember_ProductCategoryListLocaleConnection",
		paginateProductIngredients:"Contember_ProductIngredientLocaleConnection",
		paginateOrderRecurrences:"Contember_OrderRecurrenceLocaleConnection",
		paginateBlogs:"Contember_BlogLocaleConnection",
		paginateBlogPosts:"Contember_BlogPostLocaleConnection",
		paginateMenus:"Contember_MenuLocaleConnection",
		paginatePages:"Contember_PageLocaleConnection",
		paginateProductIngredientCategories:"Contember_ProductIngredientCategoryLocaleConnection",
		paginateAllergens:"Contember_AllergenLocaleConnection",
		paginateProductPackings:"Contember_ProductPackingLocaleConnection",
		paginateProducts:"Contember_ProductLocaleConnection",
		paginateProductRecipes:"Contember_ProductRecipeLocaleConnection",
		paginatePointOfSaleCategories:"Contember_PointOfSaleCategoryLocaleConnection",
		paginatePointOfSaleCategoryProducts:"Contember_PointOfSaleCategoryProductLocaleConnection",
		paginateDeliveryMethods:"Contember_DeliveryMethodLocaleConnection",
		paginateIngredientSuppliers:"Contember_IngredientSupplierLocaleConnection",
		paginateIdentificationCodes:"Contember_IdentificationCodeLocaleConnection",
		paginatePipelineStages:"Contember_PipelineStageLocaleConnection",
		paginateSubscriptionBoxes:"Contember_SubscriptionBoxLocaleConnection",
		paginateBusinessServices:"Contember_BusinessServiceLocaleConnection"
	},
	Contember_LocaleMeta:{
		id:"Contember_FieldMeta",
		businessCategories:"Contember_FieldMeta",
		productCategoryLists:"Contember_FieldMeta",
		productIngredients:"Contember_FieldMeta",
		orderRecurrences:"Contember_FieldMeta",
		blogs:"Contember_FieldMeta",
		blogPosts:"Contember_FieldMeta",
		identifier:"Contember_FieldMeta",
		menus:"Contember_FieldMeta",
		pages:"Contember_FieldMeta",
		productIngredientCategories:"Contember_FieldMeta",
		allergens:"Contember_FieldMeta",
		productPackings:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		productRecipes:"Contember_FieldMeta",
		pointOfSaleCategories:"Contember_FieldMeta",
		pointOfSaleCategoryProducts:"Contember_FieldMeta",
		deliveryMethods:"Contember_FieldMeta",
		ingredientSuppliers:"Contember_FieldMeta",
		identificationCodes:"Contember_FieldMeta",
		pipelineStages:"Contember_FieldMeta",
		subscriptionBoxes:"Contember_FieldMeta",
		businessServices:"Contember_FieldMeta"
	},
	Contember_BusinessCategoryLocale:{
		_meta:"Contember_BusinessCategoryLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_BusinessCategory",
		locale:"Contember_Locale",
		title:"String"
	},
	Contember_BusinessCategoryLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		title:"Contember_FieldMeta"
	},
	Contember_BusinessCategory:{
		_meta:"Contember_BusinessCategoryMeta",
		id:"UUID",
		order:"Int",
		visibleToNewCustomers:"Boolean",
		locales:"Contember_BusinessCategoryLocale",
		internalName:"String",
		businessCustomers:"Contember_BusinessCustomer",
		recurrences:"Contember_OrderRecurrence",
		specificProducts:"Contember_ProductList",
		seasonalProductCategories:"Contember_ProductCategoryList",
		specificProductsProductCategories:"Contember_ProductCategoryList",
		slug:"String",
		localesByLocale:"Contember_BusinessCategoryLocale",
		businessCustomersByCustomer:"Contember_BusinessCustomer",
		businessCustomersByBusinessServiceEvents:"Contember_BusinessCustomer",
		businessCustomersByDoubleshotCoffeeEquipment:"Contember_BusinessCustomer",
		paginateLocales:"Contember_BusinessCategoryLocaleConnection",
		paginateBusinessCustomers:"Contember_BusinessCustomerConnection",
		paginateRecurrences:"Contember_OrderRecurrenceConnection"
	},
	Contember_BusinessCategoryMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		visibleToNewCustomers:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		businessCustomers:"Contember_FieldMeta",
		recurrences:"Contember_FieldMeta",
		specificProducts:"Contember_FieldMeta",
		seasonalProductCategories:"Contember_FieldMeta",
		specificProductsProductCategories:"Contember_FieldMeta",
		slug:"Contember_FieldMeta"
	},
	Contember_BusinessCustomer:{
		_meta:"Contember_BusinessCustomerMeta",
		id:"UUID",
		name:"String",
		customer:"Contember_Customer",
		owners:"Contember_Customer",
		members:"Contember_Customer",
		category:"Contember_BusinessCategory",
		accountManager:"Contember_BusinessToBusinessManager",
		logo:"Contember_Image",
		note:"String",
		businessServiceEvents:"Contember_BusinessServiceEvent",
		doubleshotCoffeeEquipment:"Contember_DoubleshotCoffeeEquipment",
		paginateOwners:"Contember_CustomerConnection",
		paginateMembers:"Contember_CustomerConnection",
		paginateBusinessServiceEvents:"Contember_BusinessServiceEventConnection"
	},
	Contember_BusinessCustomerMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		owners:"Contember_FieldMeta",
		members:"Contember_FieldMeta",
		category:"Contember_FieldMeta",
		accountManager:"Contember_FieldMeta",
		logo:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		businessServiceEvents:"Contember_FieldMeta",
		doubleshotCoffeeEquipment:"Contember_FieldMeta"
	},
	Contember_BusinessToBusinessManager:{
		_meta:"Contember_BusinessToBusinessManagerMeta",
		id:"UUID",
		staff:"Contember_Staff",
		managedBusinesses:"Contember_BusinessCustomer",
		createdAt:"DateTime",
		managedBusinessesByCustomer:"Contember_BusinessCustomer",
		managedBusinessesByBusinessServiceEvents:"Contember_BusinessCustomer",
		managedBusinessesByDoubleshotCoffeeEquipment:"Contember_BusinessCustomer",
		paginateManagedBusinesses:"Contember_BusinessCustomerConnection"
	},
	Contember_BusinessToBusinessManagerMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		managedBusinesses:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta"
	},
	Contember_Staff:{
		_meta:"Contember_StaffMeta",
		id:"UUID",
		bartender:"Contember_Bartender",
		courier:"Contember_Courier",
		cleaner:"Contember_Cleaner",
		shiftsManager:"Contember_ShiftsManager",
		businessToBusinessManager:"Contember_BusinessToBusinessManager",
		user:"Contember_User",
		shiftsProfile:"Contember_ShiftsProfile",
		stocksManager:"Contember_StocksManager",
		photo:"Contember_Image",
		courierManager:"Contember_CourierManager",
		picker:"Contember_Picker",
		createdAt:"DateTime",
		remunerationProfile:"Contember_RemunerationProfile",
		cashier:"Contember_Cashier",
		venues:"Contember_Venue",
		internalBuyer:"Contember_InternalBuyer",
		salesManager:"Contember_SalesManager",
		cashierManager:"Contember_CashierManager",
		paginateVenues:"Contember_VenueConnection"
	},
	Contember_StaffMeta:{
		id:"Contember_FieldMeta",
		bartender:"Contember_FieldMeta",
		courier:"Contember_FieldMeta",
		cleaner:"Contember_FieldMeta",
		shiftsManager:"Contember_FieldMeta",
		businessToBusinessManager:"Contember_FieldMeta",
		user:"Contember_FieldMeta",
		shiftsProfile:"Contember_FieldMeta",
		stocksManager:"Contember_FieldMeta",
		photo:"Contember_FieldMeta",
		courierManager:"Contember_FieldMeta",
		picker:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		remunerationProfile:"Contember_FieldMeta",
		cashier:"Contember_FieldMeta",
		venues:"Contember_FieldMeta",
		internalBuyer:"Contember_FieldMeta",
		salesManager:"Contember_FieldMeta",
		cashierManager:"Contember_FieldMeta"
	},
	Contember_Bartender:{
		_meta:"Contember_BartenderMeta",
		id:"UUID",
		staff:"Contember_Staff",
		createdAt:"DateTime"
	},
	Contember_BartenderMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta"
	},
	Contember_Courier:{
		_meta:"Contember_CourierMeta",
		id:"UUID",
		staff:"Contember_Staff",
		routes:"Contember_DeliveryRoute",
		createdAt:"DateTime",
		deliveryMethods:"DeliveryMethod",
		showInPicker:"Boolean",
		routesByWaypoints:"Contember_DeliveryRoute",
		paginateRoutes:"Contember_DeliveryRouteConnection",
		paginateDeliveryMethods:"DeliveryMethodConnection"
	},
	Contember_CourierMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		routes:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		deliveryMethods:"Contember_FieldMeta",
		showInPicker:"Contember_FieldMeta"
	},
	Contember_DeliveryRoute:{
		_meta:"Contember_DeliveryRouteMeta",
		id:"UUID",
		courier:"Contember_Courier",
		createdAt:"DateTime",
		waypoints:"Contember_DeliveryWaypoint",
		dispatchAt:"String",
		waypointsByDelivery:"Contember_DeliveryWaypoint",
		paginateWaypoints:"Contember_DeliveryWaypointConnection"
	},
	Contember_DeliveryRouteMeta:{
		id:"Contember_FieldMeta",
		courier:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		waypoints:"Contember_FieldMeta",
		dispatchAt:"Contember_FieldMeta"
	},
	Contember_DeliveryWaypoint:{
		_meta:"Contember_DeliveryWaypointMeta",
		id:"UUID",
		estimatedArrival:"DateTime",
		note:"String",
		route:"Contember_DeliveryRoute",
		sortingOrder:"Int",
		delivery:"OrderDelivery",
		estimatedDistance:"Int",
		estimatedDuration:"Int",
		originDeparture:"DateTime",
		originAddress:"String",
		transitMode:"String"
	},
	Contember_DeliveryWaypointMeta:{
		id:"Contember_FieldMeta",
		estimatedArrival:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		route:"Contember_FieldMeta",
		sortingOrder:"Contember_FieldMeta",
		delivery:"Contember_FieldMeta",
		estimatedDistance:"Contember_FieldMeta",
		estimatedDuration:"Contember_FieldMeta",
		originDeparture:"Contember_FieldMeta",
		originAddress:"Contember_FieldMeta",
		transitMode:"Contember_FieldMeta"
	},
	OrderDelivery:{
		_meta:"OrderDeliveryMeta",
		id:"UUID",
		priceCents:"Int",
		deliveredAt:"DateTime",
		method:"DeliveryMethod",
		vatRate:"VatRate",
		order:"Order",
		expectedAt:"DateTime",
		state:"Contember_OrderDeliveryState",
		loadingDurationHours:"Int",
		dispatchAt:"DateTime",
		duration:"String",
		window:"String",
		expectedEndAt:"DateTime",
		processingAt:"DateTime",
		deliveringAt:"DateTime",
		failedAt:"DateTime",
		internalNote:"String",
		deliveryWaypoint:"Contember_DeliveryWaypoint",
		events:"Contember_OrderEvent",
		prepareAt:"DateTime",
		liftagoRide:"Contember_LiftagoRide",
		pickedAt:"DateTime",
		address:"Address",
		trackingCode:"String",
		paginateEvents:"Contember_OrderEventConnection"
	},
	OrderDeliveryMeta:{
		id:"Contember_FieldMeta",
		priceCents:"Contember_FieldMeta",
		deliveredAt:"Contember_FieldMeta",
		method:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		expectedAt:"Contember_FieldMeta",
		state:"Contember_FieldMeta",
		loadingDurationHours:"Contember_FieldMeta",
		dispatchAt:"Contember_FieldMeta",
		duration:"Contember_FieldMeta",
		window:"Contember_FieldMeta",
		expectedEndAt:"Contember_FieldMeta",
		processingAt:"Contember_FieldMeta",
		deliveringAt:"Contember_FieldMeta",
		failedAt:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		deliveryWaypoint:"Contember_FieldMeta",
		events:"Contember_FieldMeta",
		prepareAt:"Contember_FieldMeta",
		liftagoRide:"Contember_FieldMeta",
		pickedAt:"Contember_FieldMeta",
		address:"Contember_FieldMeta",
		trackingCode:"Contember_FieldMeta"
	},
	VatRateMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		ratePermille:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		channelDeliveries:"Contember_FieldMeta",
		channelPayments:"Contember_FieldMeta",
		preset:"Contember_FieldMeta"
	},
	ChannelDeliveryMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		priceCents:"Contember_FieldMeta",
		channel:"Contember_FieldMeta",
		method:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		defaultVisible:"Contember_FieldMeta"
	},
	Contember_Channel:{
		_meta:"Contember_ChannelMeta",
		id:"UUID",
		name:"String",
		disabled:"Boolean",
		payments:"ChannelPayment",
		deliveries:"ChannelDelivery",
		pointOfSales:"Contember_PointOfSale",
		carts:"Contember_Cart",
		customers:"Contember_Customer",
		code:"Contember_ChannelCode",
		addTagsOnRegistration:"Contember_Tag",
		publicTags:"Contember_Tag",
		venue:"Contember_Venue",
		cartsByItems:"Contember_Cart",
		cartsByOrder:"Contember_Cart",
		cartsByPriceLines:"Contember_Cart",
		cartsBySummary:"Contember_Cart",
		paginatePayments:"ChannelPaymentConnection",
		paginateDeliveries:"ChannelDeliveryConnection",
		paginatePointOfSales:"Contember_PointOfSaleConnection",
		paginateCarts:"Contember_CartConnection",
		paginateCustomers:"Contember_CustomerConnection",
		paginateAddTagsOnRegistration:"Contember_TagConnection",
		paginatePublicTags:"Contember_TagConnection"
	},
	Contember_ChannelMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		disabled:"Contember_FieldMeta",
		payments:"Contember_FieldMeta",
		deliveries:"Contember_FieldMeta",
		pointOfSales:"Contember_FieldMeta",
		carts:"Contember_FieldMeta",
		customers:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		addTagsOnRegistration:"Contember_FieldMeta",
		publicTags:"Contember_FieldMeta",
		venue:"Contember_FieldMeta"
	},
	ChannelPaymentMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		channel:"Contember_FieldMeta",
		method:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		requireBillingAddress:"Contember_FieldMeta",
		provideReceipt:"Contember_FieldMeta",
		requireBillingSubject:"Contember_FieldMeta",
		allowOnlyForTags:"Contember_FieldMeta"
	},
	Contember_TagConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TagEdge"
	},
	Contember_PageInfo:{
		totalCount:"Int"
	},
	Contember_TagEdge:{
		node:"Contember_Tag"
	},
	Contember_PointOfSale:{
		_meta:"Contember_PointOfSaleMeta",
		id:"UUID",
		name:"String",
		code:"String",
		channels:"Contember_Channel",
		permissions:"Contember_PointOfSalePermissions",
		tenantPersonId:"UUID",
		paginateChannels:"Contember_ChannelConnection"
	},
	Contember_PointOfSaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		channels:"Contember_FieldMeta",
		permissions:"Contember_FieldMeta",
		tenantPersonId:"Contember_FieldMeta"
	},
	Contember_PointOfSalePermissions:{
		_meta:"Contember_PointOfSalePermissionsMeta",
		id:"UUID",
		canPlaceOrder:"Boolean",
		pointOfSale:"Contember_PointOfSale"
	},
	Contember_PointOfSalePermissionsMeta:{
		id:"Contember_FieldMeta",
		canPlaceOrder:"Contember_FieldMeta",
		pointOfSale:"Contember_FieldMeta"
	},
	Contember_ChannelConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ChannelEdge"
	},
	Contember_ChannelEdge:{
		node:"Contember_Channel"
	},
	Contember_Cart:{
		_meta:"Contember_CartMeta",
		id:"UUID",
		confirmedAt:"DateTime",
		deletedAt:"DateTime",
		note:"String",
		items:"Contember_CartItem",
		handle:"String",
		state:"Contember_CartState",
		createdAt:"DateTime",
		channel:"Contember_Channel",
		order:"Order",
		customer:"Contember_Customer",
		deliveryAddress:"Address",
		billingAddress:"Address",
		deliveryOption:"String",
		paymentOption:"String",
		delivery:"ChannelDelivery",
		payment:"ChannelPayment",
		billingSubject:"Contember_BillingSubject",
		priceLines:"Contember_CartPriceLine",
		summary:"Contember_CartSummary",
		itemsByProduct:"Contember_CartItem",
		paginateItems:"Contember_CartItemConnection",
		paginatePriceLines:"Contember_CartPriceLineConnection"
	},
	Contember_CartMeta:{
		id:"Contember_FieldMeta",
		confirmedAt:"Contember_FieldMeta",
		deletedAt:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		handle:"Contember_FieldMeta",
		state:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		channel:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		deliveryAddress:"Contember_FieldMeta",
		billingAddress:"Contember_FieldMeta",
		deliveryOption:"Contember_FieldMeta",
		paymentOption:"Contember_FieldMeta",
		delivery:"Contember_FieldMeta",
		payment:"Contember_FieldMeta",
		billingSubject:"Contember_FieldMeta",
		priceLines:"Contember_FieldMeta",
		summary:"Contember_FieldMeta"
	},
	Contember_CartItem:{
		_meta:"Contember_CartItemMeta",
		id:"UUID",
		quantity:"Int",
		cart:"Contember_Cart",
		product:"Product",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		fulfillmentNote:"String",
		fulfillmentNotes:"Contember_FulfillmentNote",
		paginateFulfillmentNotes:"Contember_FulfillmentNoteConnection"
	},
	Contember_CartItemMeta:{
		id:"Contember_FieldMeta",
		quantity:"Contember_FieldMeta",
		cart:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		updatedAt:"Contember_FieldMeta",
		fulfillmentNote:"Contember_FieldMeta",
		fulfillmentNotes:"Contember_FieldMeta"
	},
	Contember_FulfillmentNote:{
		_meta:"Contember_FulfillmentNoteMeta",
		id:"UUID",
		note:"String",
		products:"Product",
		tags:"Contember_Tag",
		venues:"Contember_Venue",
		cartItems:"Contember_CartItem",
		orderItems:"OrderItem",
		orders:"Order",
		highlight:"Boolean",
		createdAt:"DateTime",
		emoji:"String",
		paginateProducts:"ProductConnection",
		paginateTags:"Contember_TagConnection",
		paginateVenues:"Contember_VenueConnection",
		paginateCartItems:"Contember_CartItemConnection",
		paginateOrderItems:"OrderItemConnection",
		paginateOrders:"OrderConnection"
	},
	Contember_FulfillmentNoteMeta:{
		id:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		tags:"Contember_FieldMeta",
		venues:"Contember_FieldMeta",
		cartItems:"Contember_FieldMeta",
		orderItems:"Contember_FieldMeta",
		orders:"Contember_FieldMeta",
		highlight:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		emoji:"Contember_FieldMeta"
	},
	Contember_Venue:{
		_meta:"Contember_VenueMeta",
		id:"UUID",
		name:"String",
		deadlineTemplate:"Contember_DeadlineTemplate",
		timetableDays:"Contember_TimetableDay",
		timetableTemplates:"Contember_TimetableTemplate",
		staffMembers:"Contember_Staff",
		employeeRoles:"Contember_VenueEmployeeRole",
		pointOfSaleApplicationDevices:"Contember_PointOfSaleApplicationDevice",
		pointOfSaleTerminals:"Contember_PointOfSaleTerminal",
		pointOfSalePrinters:"Contember_PointOfSalePrinter",
		orders:"Order",
		fulfillmentNotes:"Contember_FulfillmentNote",
		areas:"Contember_Area",
		enabledUnassignReasons:"Contember_UnassignReason",
		categories:"Contember_PointOfSaleCategory",
		channels:"Contember_Channel",
		address:"String",
		pointOfSalePngBase64ReceiptLogo:"String",
		pointOfSaleInitialView:"Contember_PointOfSaleInitialView",
		pointOfSalePreferPrintReceipt:"Boolean",
		timetableDaysByDate:"Contember_TimetableDay",
		timetableDaysByShiftGroups:"Contember_TimetableDay",
		timetableDaysByNotes:"Contember_TimetableDay",
		timetableDaysByMeta:"Contember_TimetableDay",
		timetableTemplatesByStaffOptions:"Contember_TimetableTemplate",
		employeeRolesByRole:"Contember_VenueEmployeeRole",
		employeeRolesByEmployeePrivileges:"Contember_VenueEmployeeRole",
		pointOfSaleApplicationDevicesByIdentifier:"Contember_PointOfSaleApplicationDevice",
		pointOfSaleTerminalsByApplicationDevices:"Contember_PointOfSaleTerminal",
		pointOfSalePrintersByApplicationDevicesReceipt:"Contember_PointOfSalePrinter",
		ordersBySeq:"Order",
		ordersByDoubleshotLegacyId:"Order",
		ordersByDoubleShotLegacyNumber:"Order",
		ordersByDiscounts:"Order",
		ordersByDelivery:"Order",
		ordersByItems:"Order",
		ordersByCart:"Order",
		ordersByEvents:"Order",
		ordersByPriceLines:"Order",
		ordersByPayments:"Order",
		ordersBySummary:"Order",
		ordersByGratuity:"Order",
		areasByTables:"Contember_Area",
		areasByLines:"Contember_Area",
		categoriesBySlug:"Contember_PointOfSaleCategory",
		categoriesByLocales:"Contember_PointOfSaleCategory",
		categoriesByProducts:"Contember_PointOfSaleCategory",
		channelsByCode:"Contember_Channel",
		channelsByPayments:"Contember_Channel",
		channelsByDeliveries:"Contember_Channel",
		channelsByCarts:"Contember_Channel",
		paginateTimetableDays:"Contember_TimetableDayConnection",
		paginateTimetableTemplates:"Contember_TimetableTemplateConnection",
		paginateStaffMembers:"Contember_StaffConnection",
		paginateEmployeeRoles:"Contember_VenueEmployeeRoleConnection",
		paginatePointOfSaleApplicationDevices:"Contember_PointOfSaleApplicationDeviceConnection",
		paginatePointOfSaleTerminals:"Contember_PointOfSaleTerminalConnection",
		paginatePointOfSalePrinters:"Contember_PointOfSalePrinterConnection",
		paginateOrders:"OrderConnection",
		paginateFulfillmentNotes:"Contember_FulfillmentNoteConnection",
		paginateAreas:"Contember_AreaConnection",
		paginateEnabledUnassignReasons:"Contember_UnassignReasonConnection",
		paginateCategories:"Contember_PointOfSaleCategoryConnection",
		paginateChannels:"Contember_ChannelConnection"
	},
	Contember_VenueMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		deadlineTemplate:"Contember_FieldMeta",
		timetableDays:"Contember_FieldMeta",
		timetableTemplates:"Contember_FieldMeta",
		staffMembers:"Contember_FieldMeta",
		employeeRoles:"Contember_FieldMeta",
		pointOfSaleApplicationDevices:"Contember_FieldMeta",
		pointOfSaleTerminals:"Contember_FieldMeta",
		pointOfSalePrinters:"Contember_FieldMeta",
		orders:"Contember_FieldMeta",
		fulfillmentNotes:"Contember_FieldMeta",
		areas:"Contember_FieldMeta",
		enabledUnassignReasons:"Contember_FieldMeta",
		categories:"Contember_FieldMeta",
		channels:"Contember_FieldMeta",
		address:"Contember_FieldMeta",
		pointOfSalePngBase64ReceiptLogo:"Contember_FieldMeta",
		pointOfSaleInitialView:"Contember_FieldMeta",
		pointOfSalePreferPrintReceipt:"Contember_FieldMeta"
	},
	Contember_DeadlineTemplate:{
		_meta:"Contember_DeadlineTemplateMeta",
		id:"UUID",
		startDate:"Date",
		period:"Int",
		cutoff:"Int",
		closed:"Boolean",
		venue:"Contember_Venue"
	},
	Contember_DeadlineTemplateMeta:{
		id:"Contember_FieldMeta",
		startDate:"Contember_FieldMeta",
		period:"Contember_FieldMeta",
		cutoff:"Contember_FieldMeta",
		closed:"Contember_FieldMeta",
		venue:"Contember_FieldMeta"
	},
	Contember_TimetableDay:{
		_meta:"Contember_TimetableDayMeta",
		id:"UUID",
		date:"Date",
		type:"Contember_TimetableDayType",
		shiftGroups:"Contember_TimetableShiftGroup",
		tipsCents:"Int",
		notes:"Contember_TimetableDayNote",
		venue:"Contember_Venue",
		meta:"Contember_TimetableDayMetadata",
		shiftGroupsByShifts:"Contember_TimetableShiftGroup",
		shiftGroupsByQueue:"Contember_TimetableShiftGroup",
		paginateShiftGroups:"Contember_TimetableShiftGroupConnection",
		paginateNotes:"Contember_TimetableDayNoteConnection"
	},
	Contember_TimetableDayMeta:{
		id:"Contember_FieldMeta",
		date:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		shiftGroups:"Contember_FieldMeta",
		tipsCents:"Contember_FieldMeta",
		notes:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		meta:"Contember_FieldMeta"
	},
	Contember_TimetableShiftGroup:{
		_meta:"Contember_TimetableShiftGroupMeta",
		id:"UUID",
		order:"Int",
		shifts:"Contember_TimetableShift",
		day:"Contember_TimetableDay",
		type:"Contember_TimetableEmployeeRole",
		position:"Contember_TimetableShiftPosition",
		queue:"Contember_EmployeeQueue",
		generatedByTemplate:"Contember_TimetableTemplate",
		shiftsByQueueItem:"Contember_TimetableShift",
		shiftsBySwapInfo:"Contember_TimetableShift",
		paginateShifts:"Contember_TimetableShiftConnection"
	},
	Contember_TimetableShiftGroupMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		shifts:"Contember_FieldMeta",
		day:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		position:"Contember_FieldMeta",
		queue:"Contember_FieldMeta",
		generatedByTemplate:"Contember_FieldMeta"
	},
	Contember_TimetableShift:{
		_meta:"Contember_TimetableShiftMeta",
		id:"UUID",
		locked:"Boolean",
		order:"Int",
		assigned:"Date",
		group:"Contember_TimetableShiftGroup",
		shiftsProfile:"Contember_ShiftsProfile",
		queueItem:"Contember_EmployeeQueueItem",
		swapInfo:"Contember_TimetableShiftSwapInfo",
		isOvertime:"Boolean"
	},
	Contember_TimetableShiftMeta:{
		id:"Contember_FieldMeta",
		locked:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		assigned:"Contember_FieldMeta",
		group:"Contember_FieldMeta",
		shiftsProfile:"Contember_FieldMeta",
		queueItem:"Contember_FieldMeta",
		swapInfo:"Contember_FieldMeta",
		isOvertime:"Contember_FieldMeta"
	},
	Contember_ShiftsProfile:{
		_meta:"Contember_ShiftsProfileMeta",
		id:"UUID",
		queueItems:"Contember_EmployeeQueueItem",
		staff:"Contember_Staff",
		roles:"Contember_TimetableEmployeeRole",
		shifts:"Contember_TimetableShift",
		allowSelfUnassigning:"Boolean",
		queueItemsByQueue:"Contember_EmployeeQueueItem",
		queueItemsByShift:"Contember_EmployeeQueueItem",
		queueItemsByUnassignInfo:"Contember_EmployeeQueueItem",
		queueItemsByMeta:"Contember_EmployeeQueueItem",
		shiftsByQueueItem:"Contember_TimetableShift",
		shiftsBySwapInfo:"Contember_TimetableShift",
		paginateQueueItems:"Contember_EmployeeQueueItemConnection",
		paginateRoles:"Contember_TimetableEmployeeRoleConnection",
		paginateShifts:"Contember_TimetableShiftConnection"
	},
	Contember_ShiftsProfileMeta:{
		id:"Contember_FieldMeta",
		queueItems:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		roles:"Contember_FieldMeta",
		shifts:"Contember_FieldMeta",
		allowSelfUnassigning:"Contember_FieldMeta"
	},
	Contember_EmployeeQueueItem:{
		_meta:"Contember_EmployeeQueueItemMeta",
		id:"UUID",
		start:"String",
		end:"String",
		queue:"Contember_EmployeeQueue",
		shiftsProfile:"Contember_ShiftsProfile",
		shift:"Contember_TimetableShift",
		unassignInfo:"Contember_UnassignedShiftInfo",
		meta:"Contember_EmployeeQueueItemMetadata"
	},
	Contember_EmployeeQueueItemMeta:{
		id:"Contember_FieldMeta",
		start:"Contember_FieldMeta",
		end:"Contember_FieldMeta",
		queue:"Contember_FieldMeta",
		shiftsProfile:"Contember_FieldMeta",
		shift:"Contember_FieldMeta",
		unassignInfo:"Contember_FieldMeta",
		meta:"Contember_FieldMeta"
	},
	Contember_EmployeeQueue:{
		_meta:"Contember_EmployeeQueueMeta",
		id:"UUID",
		shiftGroup:"Contember_TimetableShiftGroup",
		items:"Contember_EmployeeQueueItem",
		contemberBugFix:"Boolean",
		itemsByShiftsProfile:"Contember_EmployeeQueueItem",
		itemsByShift:"Contember_EmployeeQueueItem",
		itemsByUnassignInfo:"Contember_EmployeeQueueItem",
		itemsByMeta:"Contember_EmployeeQueueItem",
		paginateItems:"Contember_EmployeeQueueItemConnection"
	},
	Contember_EmployeeQueueMeta:{
		id:"Contember_FieldMeta",
		shiftGroup:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		contemberBugFix:"Contember_FieldMeta"
	},
	Contember_EmployeeQueueItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_EmployeeQueueItemEdge"
	},
	Contember_EmployeeQueueItemEdge:{
		node:"Contember_EmployeeQueueItem"
	},
	Contember_UnassignedShiftInfo:{
		_meta:"Contember_UnassignedShiftInfoMeta",
		id:"UUID",
		hoursMissed:"Int",
		queueItem:"Contember_EmployeeQueueItem",
		legacyReason:"Contember_UnassignedInfoReason",
		reason:"Contember_UnassignReason"
	},
	Contember_UnassignedShiftInfoMeta:{
		id:"Contember_FieldMeta",
		hoursMissed:"Contember_FieldMeta",
		queueItem:"Contember_FieldMeta",
		legacyReason:"Contember_FieldMeta",
		reason:"Contember_FieldMeta"
	},
	Contember_UnassignReason:{
		_meta:"Contember_UnassignReasonMeta",
		id:"UUID",
		order:"Int",
		name:"String",
		venues:"Contember_Venue",
		paginateVenues:"Contember_VenueConnection"
	},
	Contember_UnassignReasonMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		venues:"Contember_FieldMeta"
	},
	Contember_VenueConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_VenueEdge"
	},
	Contember_VenueEdge:{
		node:"Contember_Venue"
	},
	Contember_EmployeeQueueItemMetadata:{
		_meta:"Contember_EmployeeQueueItemMetadataMeta",
		id:"UUID",
		totalMinutes:"Int",
		queueItem:"Contember_EmployeeQueueItem",
		includeInTipsCalculation:"Boolean"
	},
	Contember_EmployeeQueueItemMetadataMeta:{
		id:"Contember_FieldMeta",
		totalMinutes:"Contember_FieldMeta",
		queueItem:"Contember_FieldMeta",
		includeInTipsCalculation:"Contember_FieldMeta"
	},
	Contember_TimetableEmployeeRole:{
		_meta:"Contember_TimetableEmployeeRoleMeta",
		id:"UUID",
		shiftGroups:"Contember_TimetableShiftGroup",
		shiftsProfiles:"Contember_ShiftsProfile",
		shiftsManagers:"Contember_ShiftsManager",
		name:"String",
		timetableTemplates:"Contember_TimetableTemplate",
		color:"String",
		shiftGroupsByShifts:"Contember_TimetableShiftGroup",
		shiftGroupsByQueue:"Contember_TimetableShiftGroup",
		timetableTemplatesByStaffOptions:"Contember_TimetableTemplate",
		paginateShiftGroups:"Contember_TimetableShiftGroupConnection",
		paginateShiftsProfiles:"Contember_ShiftsProfileConnection",
		paginateShiftsManagers:"Contember_ShiftsManagerConnection",
		paginateTimetableTemplates:"Contember_TimetableTemplateConnection"
	},
	Contember_TimetableEmployeeRoleMeta:{
		id:"Contember_FieldMeta",
		shiftGroups:"Contember_FieldMeta",
		shiftsProfiles:"Contember_FieldMeta",
		shiftsManagers:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		timetableTemplates:"Contember_FieldMeta",
		color:"Contember_FieldMeta"
	},
	Contember_ShiftsManager:{
		_meta:"Contember_ShiftsManagerMeta",
		id:"UUID",
		staff:"Contember_Staff",
		managedRoles:"Contember_TimetableEmployeeRole",
		createdAt:"DateTime",
		paginateManagedRoles:"Contember_TimetableEmployeeRoleConnection"
	},
	Contember_ShiftsManagerMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		managedRoles:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta"
	},
	Contember_TimetableEmployeeRoleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TimetableEmployeeRoleEdge"
	},
	Contember_TimetableEmployeeRoleEdge:{
		node:"Contember_TimetableEmployeeRole"
	},
	Contember_TimetableTemplate:{
		_meta:"Contember_TimetableTemplateMeta",
		id:"UUID",
		order:"Int",
		startDate:"Date",
		period:"Int",
		slots:"Int",
		position:"Contember_TimetableShiftPosition",
		staffOptions:"Contember_TimetableTemplateStaffOptions",
		internalName:"String",
		venue:"Contember_Venue",
		employeeRole:"Contember_TimetableEmployeeRole",
		workdaysOnly:"Boolean",
		monday:"Boolean",
		tuesday:"Boolean",
		wednesday:"Boolean",
		thursday:"Boolean",
		friday:"Boolean",
		saturday:"Boolean",
		sunday:"Boolean",
		type:"Contember_TemplateType",
		paginateStaffOptions:"Contember_TimetableTemplateStaffOptionsConnection"
	},
	Contember_TimetableTemplateMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		startDate:"Contember_FieldMeta",
		period:"Contember_FieldMeta",
		slots:"Contember_FieldMeta",
		position:"Contember_FieldMeta",
		staffOptions:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		employeeRole:"Contember_FieldMeta",
		workdaysOnly:"Contember_FieldMeta",
		monday:"Contember_FieldMeta",
		tuesday:"Contember_FieldMeta",
		wednesday:"Contember_FieldMeta",
		thursday:"Contember_FieldMeta",
		friday:"Contember_FieldMeta",
		saturday:"Contember_FieldMeta",
		sunday:"Contember_FieldMeta",
		type:"Contember_FieldMeta"
	},
	Contember_TimetableShiftPosition:{
		_meta:"Contember_TimetableShiftPositionMeta",
		id:"UUID",
		name:"String",
		start:"String",
		end:"String",
		shiftGroups:"Contember_TimetableShiftGroup",
		timetableTemplates:"Contember_TimetableTemplate",
		isDynamic:"Boolean",
		shiftGroupsByShifts:"Contember_TimetableShiftGroup",
		shiftGroupsByQueue:"Contember_TimetableShiftGroup",
		timetableTemplatesByStaffOptions:"Contember_TimetableTemplate",
		paginateShiftGroups:"Contember_TimetableShiftGroupConnection",
		paginateTimetableTemplates:"Contember_TimetableTemplateConnection"
	},
	Contember_TimetableShiftPositionMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		start:"Contember_FieldMeta",
		end:"Contember_FieldMeta",
		shiftGroups:"Contember_FieldMeta",
		timetableTemplates:"Contember_FieldMeta",
		isDynamic:"Contember_FieldMeta"
	},
	Contember_TimetableShiftGroupConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TimetableShiftGroupEdge"
	},
	Contember_TimetableShiftGroupEdge:{
		node:"Contember_TimetableShiftGroup"
	},
	Contember_TimetableTemplateConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TimetableTemplateEdge"
	},
	Contember_TimetableTemplateEdge:{
		node:"Contember_TimetableTemplate"
	},
	Contember_TimetableTemplateStaffOptions:{
		_meta:"Contember_TimetableTemplateStaffOptionsMeta",
		id:"UUID",
		startTime:"String",
		endTime:"String",
		timetableTemplate:"Contember_TimetableTemplate",
		autoAssignedEmployee:"Contember_ShiftsProfile"
	},
	Contember_TimetableTemplateStaffOptionsMeta:{
		id:"Contember_FieldMeta",
		startTime:"Contember_FieldMeta",
		endTime:"Contember_FieldMeta",
		timetableTemplate:"Contember_FieldMeta",
		autoAssignedEmployee:"Contember_FieldMeta"
	},
	Contember_TimetableTemplateStaffOptionsConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TimetableTemplateStaffOptionsEdge"
	},
	Contember_TimetableTemplateStaffOptionsEdge:{
		node:"Contember_TimetableTemplateStaffOptions"
	},
	Contember_ShiftsProfileConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ShiftsProfileEdge"
	},
	Contember_ShiftsProfileEdge:{
		node:"Contember_ShiftsProfile"
	},
	Contember_ShiftsManagerConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ShiftsManagerEdge"
	},
	Contember_ShiftsManagerEdge:{
		node:"Contember_ShiftsManager"
	},
	Contember_TimetableShiftConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TimetableShiftEdge"
	},
	Contember_TimetableShiftEdge:{
		node:"Contember_TimetableShift"
	},
	Contember_TimetableShiftSwapInfo:{
		_meta:"Contember_TimetableShiftSwapInfoMeta",
		id:"UUID",
		assigned:"Date",
		requested:"Boolean",
		confirmed:"Boolean",
		swappedShiftsProfile:"Contember_ShiftsProfile",
		shift:"Contember_TimetableShift"
	},
	Contember_TimetableShiftSwapInfoMeta:{
		id:"Contember_FieldMeta",
		assigned:"Contember_FieldMeta",
		requested:"Contember_FieldMeta",
		confirmed:"Contember_FieldMeta",
		swappedShiftsProfile:"Contember_FieldMeta",
		shift:"Contember_FieldMeta"
	},
	Contember_TimetableDayNote:{
		_meta:"Contember_TimetableDayNoteMeta",
		id:"UUID",
		order:"Int",
		note:"String",
		day:"Contember_TimetableDay",
		role:"Contember_TimetableEmployeeRole"
	},
	Contember_TimetableDayNoteMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		day:"Contember_FieldMeta",
		role:"Contember_FieldMeta"
	},
	Contember_TimetableDayMetadata:{
		_meta:"Contember_TimetableDayMetadataMeta",
		id:"UUID",
		numberOfWorkers:"Int",
		tipsTotalCents:"Int",
		totalMinutes:"Int",
		tipsCentsPerMinute:"Float",
		day:"Contember_TimetableDay"
	},
	Contember_TimetableDayMetadataMeta:{
		id:"Contember_FieldMeta",
		numberOfWorkers:"Contember_FieldMeta",
		tipsTotalCents:"Contember_FieldMeta",
		totalMinutes:"Contember_FieldMeta",
		tipsCentsPerMinute:"Contember_FieldMeta",
		day:"Contember_FieldMeta"
	},
	Contember_TimetableDayNoteConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TimetableDayNoteEdge"
	},
	Contember_TimetableDayNoteEdge:{
		node:"Contember_TimetableDayNote"
	},
	Contember_VenueEmployeeRole:{
		_meta:"Contember_VenueEmployeeRoleMeta",
		id:"UUID",
		order:"Int",
		employeePrivileges:"Contember_TimetablesEmployeePrivileges",
		venue:"Contember_Venue",
		role:"Contember_TimetableEmployeeRole",
		getsTipsShare:"Boolean",
		notesEnabled:"Boolean"
	},
	Contember_VenueEmployeeRoleMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		employeePrivileges:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		role:"Contember_FieldMeta",
		getsTipsShare:"Contember_FieldMeta",
		notesEnabled:"Contember_FieldMeta"
	},
	Contember_TimetablesEmployeePrivileges:{
		_meta:"Contember_TimetablesEmployeePrivilegesMeta",
		id:"UUID",
		venueEmployeeRole:"Contember_VenueEmployeeRole",
		read:"Boolean",
		unassign:"Boolean",
		assign:"Boolean"
	},
	Contember_TimetablesEmployeePrivilegesMeta:{
		id:"Contember_FieldMeta",
		venueEmployeeRole:"Contember_FieldMeta",
		read:"Contember_FieldMeta",
		unassign:"Contember_FieldMeta",
		assign:"Contember_FieldMeta"
	},
	Contember_PointOfSaleApplicationDevice:{
		_meta:"Contember_PointOfSaleApplicationDeviceMeta",
		id:"UUID",
		internalName:"String",
		note:"String",
		venue:"Contember_Venue",
		identificationCode:"Contember_IdentificationCode",
		identifier:"Contember_DeviceIdentifier",
		terminal:"Contember_PointOfSaleTerminal",
		receiptPrinter:"Contember_PointOfSalePrinter",
		ticketPrinters:"Contember_PointOfSalePrinter",
		paginateTicketPrinters:"Contember_PointOfSalePrinterConnection"
	},
	Contember_PointOfSaleApplicationDeviceMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		identificationCode:"Contember_FieldMeta",
		identifier:"Contember_FieldMeta",
		terminal:"Contember_FieldMeta",
		receiptPrinter:"Contember_FieldMeta",
		ticketPrinters:"Contember_FieldMeta"
	},
	Contember_IdentificationCode:{
		_meta:"Contember_IdentificationCodeMeta",
		id:"UUID",
		code:"String",
		createdAt:"DateTime",
		attempts:"Contember_IdentificationAttempt",
		locales:"Contember_IdentificationCodeLocale",
		internalName:"String",
		applicationDevices:"Contember_PointOfSaleApplicationDevice",
		localesByLocale:"Contember_IdentificationCodeLocale",
		applicationDevicesByIdentifier:"Contember_PointOfSaleApplicationDevice",
		paginateAttempts:"Contember_IdentificationAttemptConnection",
		paginateLocales:"Contember_IdentificationCodeLocaleConnection",
		paginateApplicationDevices:"Contember_PointOfSaleApplicationDeviceConnection"
	},
	Contember_IdentificationCodeMeta:{
		id:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		attempts:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		applicationDevices:"Contember_FieldMeta"
	},
	Contember_IdentificationAttempt:{
		_meta:"Contember_IdentificationAttemptMeta",
		id:"UUID",
		createdAt:"DateTime",
		code:"Contember_IdentificationCode",
		user:"Contember_User"
	},
	Contember_IdentificationAttemptMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		user:"Contember_FieldMeta"
	},
	Contember_User:{
		_meta:"Contember_UserMeta",
		id:"UUID",
		name:"String",
		internalNote:"String",
		staff:"Contember_Staff",
		customer:"Contember_Customer",
		tenantPersonId:"UUID",
		disabledAt:"DateTime",
		devices:"Contember_UserDevice",
		verifiedAt:"DateTime",
		email:"String",
		createdAt:"DateTime",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime",
		deletedAt:"DateTime",
		meta:"Contember_UserMetadata",
		identificationAttempts:"Contember_IdentificationAttempt",
		roles:"Contember_UserRoles",
		devicesByDeviceId:"Contember_UserDevice",
		paginateDevices:"Contember_UserDeviceConnection",
		paginateIdentificationAttempts:"Contember_IdentificationAttemptConnection"
	},
	Contember_UserMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		tenantPersonId:"Contember_FieldMeta",
		disabledAt:"Contember_FieldMeta",
		devices:"Contember_FieldMeta",
		verifiedAt:"Contember_FieldMeta",
		email:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		deletionRequestedAt:"Contember_FieldMeta",
		deletionExpectedAt:"Contember_FieldMeta",
		deletedAt:"Contember_FieldMeta",
		meta:"Contember_FieldMeta",
		identificationAttempts:"Contember_FieldMeta",
		roles:"Contember_FieldMeta"
	},
	Contember_UserDevice:{
		_meta:"Contember_UserDeviceMeta",
		id:"UUID",
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		platform:"String",
		deviceId:"String",
		notificationsEnabled:"Boolean",
		user:"Contember_User",
		appVersion:"String",
		osVersion:"String",
		apiVersion:"String",
		anonymousSession:"Contember_AnonymousSession"
	},
	Contember_UserDeviceMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		lastActiveAt:"Contember_FieldMeta",
		platform:"Contember_FieldMeta",
		deviceId:"Contember_FieldMeta",
		notificationsEnabled:"Contember_FieldMeta",
		user:"Contember_FieldMeta",
		appVersion:"Contember_FieldMeta",
		osVersion:"Contember_FieldMeta",
		apiVersion:"Contember_FieldMeta",
		anonymousSession:"Contember_FieldMeta"
	},
	Contember_AnonymousSession:{
		_meta:"Contember_AnonymousSessionMeta",
		id:"UUID",
		createdAt:"DateTime",
		lastActiveAt:"DateTime",
		anonymousSessionKey:"String",
		email:"String",
		name:"String",
		internalNote:"String",
		customer:"Contember_Customer",
		devices:"Contember_UserDevice",
		devicesByDeviceId:"Contember_UserDevice",
		paginateDevices:"Contember_UserDeviceConnection"
	},
	Contember_AnonymousSessionMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		lastActiveAt:"Contember_FieldMeta",
		anonymousSessionKey:"Contember_FieldMeta",
		email:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		devices:"Contember_FieldMeta"
	},
	Contember_UserDeviceConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_UserDeviceEdge"
	},
	Contember_UserDeviceEdge:{
		node:"Contember_UserDevice"
	},
	Contember_UserMetadata:{
		_meta:"Contember_UserMetadataMeta",
		id:"UUID",
		userProfileUrl:"String",
		tenantEmail:"String",
		tenantPersonId:"UUID",
		tenantIdentityId:"UUID",
		globalRoles:"Json",
		projectRoles:"Json",
		projectRolesString:"String",
		externalIdentifiers:"Json",
		externalIdentifiersString:"String",
		idpSlugs:"Json",
		idpSlugsString:"String",
		user:"Contember_User"
	},
	Contember_UserMetadataMeta:{
		id:"Contember_FieldMeta",
		userProfileUrl:"Contember_FieldMeta",
		tenantEmail:"Contember_FieldMeta",
		tenantPersonId:"Contember_FieldMeta",
		tenantIdentityId:"Contember_FieldMeta",
		globalRoles:"Contember_FieldMeta",
		projectRoles:"Contember_FieldMeta",
		projectRolesString:"Contember_FieldMeta",
		externalIdentifiers:"Contember_FieldMeta",
		externalIdentifiersString:"Contember_FieldMeta",
		idpSlugs:"Contember_FieldMeta",
		idpSlugsString:"Contember_FieldMeta",
		user:"Contember_FieldMeta"
	},
	Contember_UserRoles:{
		_meta:"Contember_UserRolesMeta",
		id:"UUID",
		isCustomer:"Boolean",
		isStaff:"Boolean",
		isBartender:"Boolean",
		isCashier:"Boolean",
		isCourier:"Boolean",
		isCleaner:"Boolean",
		isShiftsManager:"Boolean",
		isInternalBuyer:"Boolean",
		isStocksManager:"Boolean",
		isCourierManager:"Boolean",
		isCashierManager:"Boolean",
		isBusinessToBusinessManager:"Boolean",
		isPicker:"Boolean",
		user:"Contember_User"
	},
	Contember_UserRolesMeta:{
		id:"Contember_FieldMeta",
		isCustomer:"Contember_FieldMeta",
		isStaff:"Contember_FieldMeta",
		isBartender:"Contember_FieldMeta",
		isCashier:"Contember_FieldMeta",
		isCourier:"Contember_FieldMeta",
		isCleaner:"Contember_FieldMeta",
		isShiftsManager:"Contember_FieldMeta",
		isInternalBuyer:"Contember_FieldMeta",
		isStocksManager:"Contember_FieldMeta",
		isCourierManager:"Contember_FieldMeta",
		isCashierManager:"Contember_FieldMeta",
		isBusinessToBusinessManager:"Contember_FieldMeta",
		isPicker:"Contember_FieldMeta",
		user:"Contember_FieldMeta"
	},
	Contember_IdentificationAttemptConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_IdentificationAttemptEdge"
	},
	Contember_IdentificationAttemptEdge:{
		node:"Contember_IdentificationAttempt"
	},
	Contember_IdentificationCodeLocale:{
		_meta:"Contember_IdentificationCodeLocaleMeta",
		id:"UUID",
		successMessage:"String",
		root:"Contember_IdentificationCode",
		locale:"Contember_Locale"
	},
	Contember_IdentificationCodeLocaleMeta:{
		id:"Contember_FieldMeta",
		successMessage:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_IdentificationCodeLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_IdentificationCodeLocaleEdge"
	},
	Contember_IdentificationCodeLocaleEdge:{
		node:"Contember_IdentificationCodeLocale"
	},
	Contember_PointOfSaleApplicationDeviceConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSaleApplicationDeviceEdge"
	},
	Contember_PointOfSaleApplicationDeviceEdge:{
		node:"Contember_PointOfSaleApplicationDevice"
	},
	Contember_DeviceIdentifier:{
		_meta:"Contember_DeviceIdentifierMeta",
		id:"UUID",
		internalName:"String",
		device:"Contember_PointOfSaleApplicationDevice",
		updatedAt:"DateTime",
		platform:"String",
		resolution:"String",
		devicePixelRatio:"Float",
		updatedByUser:"Contember_User",
		identifier:"String",
		meta:"Contember_DeviceIdentifierMetadata"
	},
	Contember_DeviceIdentifierMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		device:"Contember_FieldMeta",
		updatedAt:"Contember_FieldMeta",
		platform:"Contember_FieldMeta",
		resolution:"Contember_FieldMeta",
		devicePixelRatio:"Contember_FieldMeta",
		updatedByUser:"Contember_FieldMeta",
		identifier:"Contember_FieldMeta",
		meta:"Contember_FieldMeta"
	},
	Contember_DeviceIdentifierMetadata:{
		_meta:"Contember_DeviceIdentifierMetadataMeta",
		id:"UUID",
		prettyIdentifier:"String",
		deviceIdentifier:"Contember_DeviceIdentifier"
	},
	Contember_DeviceIdentifierMetadataMeta:{
		id:"Contember_FieldMeta",
		prettyIdentifier:"Contember_FieldMeta",
		deviceIdentifier:"Contember_FieldMeta"
	},
	Contember_PointOfSaleTerminal:{
		_meta:"Contember_PointOfSaleTerminalMeta",
		id:"UUID",
		internalName:"String",
		note:"String",
		venue:"Contember_Venue",
		applicationDevices:"Contember_PointOfSaleApplicationDevice",
		ipAddress:"String",
		port:"Int",
		type:"Contember_PointOfSaleTerminalType",
		applicationDevicesByIdentifier:"Contember_PointOfSaleApplicationDevice",
		paginateApplicationDevices:"Contember_PointOfSaleApplicationDeviceConnection"
	},
	Contember_PointOfSaleTerminalMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		applicationDevices:"Contember_FieldMeta",
		ipAddress:"Contember_FieldMeta",
		port:"Contember_FieldMeta",
		type:"Contember_FieldMeta"
	},
	Contember_PointOfSalePrinter:{
		_meta:"Contember_PointOfSalePrinterMeta",
		id:"UUID",
		internalName:"String",
		note:"String",
		ipAddress:"String",
		applicationDevicesReceipt:"Contember_PointOfSaleApplicationDevice",
		applicationDevicesTicket:"Contember_PointOfSaleApplicationDevice",
		venue:"Contember_Venue",
		ticketCategories:"Contember_PointOfSaleCategory",
		applicationDevicesReceiptByIdentifier:"Contember_PointOfSaleApplicationDevice",
		paginateApplicationDevicesReceipt:"Contember_PointOfSaleApplicationDeviceConnection",
		paginateApplicationDevicesTicket:"Contember_PointOfSaleApplicationDeviceConnection",
		paginateTicketCategories:"Contember_PointOfSaleCategoryConnection"
	},
	Contember_PointOfSalePrinterMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		ipAddress:"Contember_FieldMeta",
		applicationDevicesReceipt:"Contember_FieldMeta",
		applicationDevicesTicket:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		ticketCategories:"Contember_FieldMeta"
	},
	Contember_PointOfSaleCategory:{
		_meta:"Contember_PointOfSaleCategoryMeta",
		id:"UUID",
		order:"Int",
		slug:"String",
		locales:"Contember_PointOfSaleCategoryLocale",
		products:"Contember_PointOfSaleCategoryProduct",
		category:"Contember_ProductCategory",
		venue:"Contember_Venue",
		ticketPrinters:"Contember_PointOfSalePrinter",
		localesByLocale:"Contember_PointOfSaleCategoryLocale",
		productsByLocales:"Contember_PointOfSaleCategoryProduct",
		paginateLocales:"Contember_PointOfSaleCategoryLocaleConnection",
		paginateProducts:"Contember_PointOfSaleCategoryProductConnection",
		paginateTicketPrinters:"Contember_PointOfSalePrinterConnection"
	},
	Contember_PointOfSaleCategoryMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		slug:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		category:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		ticketPrinters:"Contember_FieldMeta"
	},
	Contember_PointOfSaleCategoryLocale:{
		_meta:"Contember_PointOfSaleCategoryLocaleMeta",
		id:"UUID",
		title:"String",
		root:"Contember_PointOfSaleCategory",
		locale:"Contember_Locale"
	},
	Contember_PointOfSaleCategoryLocaleMeta:{
		id:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_PointOfSaleCategoryProduct:{
		_meta:"Contember_PointOfSaleCategoryProductMeta",
		id:"UUID",
		order:"Int",
		category:"Contember_PointOfSaleCategory",
		product:"Product",
		locales:"Contember_PointOfSaleCategoryProductLocale",
		localesByLocale:"Contember_PointOfSaleCategoryProductLocale",
		paginateLocales:"Contember_PointOfSaleCategoryProductLocaleConnection"
	},
	Contember_PointOfSaleCategoryProductMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		category:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		locales:"Contember_FieldMeta"
	},
	Contember_PointOfSaleCategoryProductLocale:{
		_meta:"Contember_PointOfSaleCategoryProductLocaleMeta",
		id:"UUID",
		title:"String",
		root:"Contember_PointOfSaleCategoryProduct",
		locale:"Contember_Locale"
	},
	Contember_PointOfSaleCategoryProductLocaleMeta:{
		id:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_PointOfSaleCategoryProductLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSaleCategoryProductLocaleEdge"
	},
	Contember_PointOfSaleCategoryProductLocaleEdge:{
		node:"Contember_PointOfSaleCategoryProductLocale"
	},
	Contember_ProductCategory:{
		_meta:"Contember_ProductCategoryMeta",
		id:"UUID",
		name:"String",
		code:"String",
		products:"Product",
		tags:"Contember_Tag",
		parent:"Contember_ProductCategory",
		children:"Contember_ProductCategory",
		description:"String",
		internalNote:"String",
		color:"String",
		image:"Contember_Image",
		order:"Float",
		descendants:"Contember_ProductCategoryTree",
		ancestors:"Contember_ProductCategoryTree",
		inheritedTags:"Contember_InheritedProductCategoryTag",
		nestedProducts:"Contember_NestedProductCategoryProduct",
		childrenByCode:"Contember_ProductCategory",
		childrenByChildren:"Contember_ProductCategory",
		childrenByDescendants:"Contember_ProductCategory",
		childrenByAncestors:"Contember_ProductCategory",
		childrenByInheritedTags:"Contember_ProductCategory",
		childrenByNestedProducts:"Contember_ProductCategory",
		paginateProducts:"ProductConnection",
		paginateTags:"Contember_TagConnection",
		paginateChildren:"Contember_ProductCategoryConnection",
		paginateDescendants:"Contember_ProductCategoryTreeConnection",
		paginateAncestors:"Contember_ProductCategoryTreeConnection",
		paginateInheritedTags:"Contember_InheritedProductCategoryTagConnection",
		paginateNestedProducts:"Contember_NestedProductCategoryProductConnection"
	},
	Contember_ProductCategoryMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		tags:"Contember_FieldMeta",
		parent:"Contember_FieldMeta",
		children:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		color:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		descendants:"Contember_FieldMeta",
		ancestors:"Contember_FieldMeta",
		inheritedTags:"Contember_FieldMeta",
		nestedProducts:"Contember_FieldMeta"
	},
	Contember_ProductCategoryTree:{
		_meta:"Contember_ProductCategoryTreeMeta",
		id:"UUID",
		depth:"Int",
		ancestor:"Contember_ProductCategory",
		descendant:"Contember_ProductCategory"
	},
	Contember_ProductCategoryTreeMeta:{
		id:"Contember_FieldMeta",
		depth:"Contember_FieldMeta",
		ancestor:"Contember_FieldMeta",
		descendant:"Contember_FieldMeta"
	},
	Contember_InheritedProductCategoryTag:{
		_meta:"Contember_InheritedProductCategoryTagMeta",
		id:"UUID",
		depth:"Int",
		productCategory:"Contember_ProductCategory",
		tag:"Contember_Tag"
	},
	Contember_InheritedProductCategoryTagMeta:{
		id:"Contember_FieldMeta",
		depth:"Contember_FieldMeta",
		productCategory:"Contember_FieldMeta",
		tag:"Contember_FieldMeta"
	},
	Contember_NestedProductCategoryProduct:{
		_meta:"Contember_NestedProductCategoryProductMeta",
		id:"UUID",
		depth:"Int",
		product:"Product",
		productCategory:"Contember_ProductCategory"
	},
	Contember_NestedProductCategoryProductMeta:{
		id:"Contember_FieldMeta",
		depth:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		productCategory:"Contember_FieldMeta"
	},
	ProductConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"ProductEdge"
	},
	ProductEdge:{
		node:"Product"
	},
	Contember_ProductCategoryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductCategoryEdge"
	},
	Contember_ProductCategoryEdge:{
		node:"Contember_ProductCategory"
	},
	Contember_ProductCategoryTreeConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductCategoryTreeEdge"
	},
	Contember_ProductCategoryTreeEdge:{
		node:"Contember_ProductCategoryTree"
	},
	Contember_InheritedProductCategoryTagConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_InheritedProductCategoryTagEdge"
	},
	Contember_InheritedProductCategoryTagEdge:{
		node:"Contember_InheritedProductCategoryTag"
	},
	Contember_NestedProductCategoryProductConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_NestedProductCategoryProductEdge"
	},
	Contember_NestedProductCategoryProductEdge:{
		node:"Contember_NestedProductCategoryProduct"
	},
	Contember_PointOfSaleCategoryLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSaleCategoryLocaleEdge"
	},
	Contember_PointOfSaleCategoryLocaleEdge:{
		node:"Contember_PointOfSaleCategoryLocale"
	},
	Contember_PointOfSaleCategoryProductConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSaleCategoryProductEdge"
	},
	Contember_PointOfSaleCategoryProductEdge:{
		node:"Contember_PointOfSaleCategoryProduct"
	},
	Contember_PointOfSalePrinterConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSalePrinterEdge"
	},
	Contember_PointOfSalePrinterEdge:{
		node:"Contember_PointOfSalePrinter"
	},
	Contember_PointOfSaleCategoryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSaleCategoryEdge"
	},
	Contember_PointOfSaleCategoryEdge:{
		node:"Contember_PointOfSaleCategory"
	},
	OrderMeta:{
		id:"Contember_FieldMeta",
		state:"Contember_FieldMeta",
		fulfilledAt:"Contember_FieldMeta",
		canceledAt:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		channel:"Contember_FieldMeta",
		discounts:"Contember_FieldMeta",
		delivery:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		cart:"Contember_FieldMeta",
		events:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		billingAddress:"Contember_FieldMeta",
		priceLines:"Contember_FieldMeta",
		payments:"Contember_FieldMeta",
		seq:"Contember_FieldMeta",
		billingSubject:"Contember_FieldMeta",
		summary:"Contember_FieldMeta",
		doubleshotLegacyId:"Contember_FieldMeta",
		gratuity:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		fulfillmentNote:"Contember_FieldMeta",
		fulfillmentNotes:"Contember_FieldMeta",
		doubleshotTotalWeightGrams:"Contember_FieldMeta",
		doubleShotLegacyNumber:"Contember_FieldMeta",
		doubleshotLastUpdatedAt:"Contember_FieldMeta",
		doubleshotAdminUrl:"Contember_FieldMeta"
	},
	OrderDiscount:{
		_meta:"OrderDiscountMeta",
		id:"UUID",
		discountCents:"Int",
		vatRate:"VatRate",
		order:"Order",
		description:"String",
		type:"Contember_OrderDiscountType",
		customerCreditTransactions:"Contember_CustomerCreditTransaction",
		voucherRedemption:"Contember_VoucherRedemption",
		customerCreditTransactionsByVirtualProductEffect:"Contember_CustomerCreditTransaction",
		paginateCustomerCreditTransactions:"Contember_CustomerCreditTransactionConnection"
	},
	OrderDiscountMeta:{
		id:"Contember_FieldMeta",
		discountCents:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		customerCreditTransactions:"Contember_FieldMeta",
		voucherRedemption:"Contember_FieldMeta"
	},
	Contember_CustomerCreditTransaction:{
		_meta:"Contember_CustomerCreditTransactionMeta",
		id:"UUID",
		createdAt:"DateTime",
		amountCents:"Int",
		customerCredit:"CustomerCredit",
		orderDiscount:"OrderDiscount",
		virtualProductEffect:"Contember_VirtualProductEffect"
	},
	Contember_CustomerCreditTransactionMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		amountCents:"Contember_FieldMeta",
		customerCredit:"Contember_FieldMeta",
		orderDiscount:"Contember_FieldMeta",
		virtualProductEffect:"Contember_FieldMeta"
	},
	CustomerCreditMeta:{
		id:"Contember_FieldMeta",
		initialCreditCents:"Contember_FieldMeta",
		remainingCreditsCents:"Contember_FieldMeta",
		expiresAt:"Contember_FieldMeta",
		reason:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		transactions:"Contember_FieldMeta",
		voucherRedemption:"Contember_FieldMeta",
		invoiceUrl:"Contember_FieldMeta"
	},
	Contember_VoucherRedemption:{
		_meta:"Contember_VoucherRedemptionMeta",
		id:"UUID",
		createdAt:"DateTime",
		note:"String",
		manualRedemptionNote:"String",
		customerCredit:"CustomerCredit",
		orderDiscount:"OrderDiscount",
		voucher:"Contember_Voucher",
		customer:"Contember_Customer"
	},
	Contember_VoucherRedemptionMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		manualRedemptionNote:"Contember_FieldMeta",
		customerCredit:"Contember_FieldMeta",
		orderDiscount:"Contember_FieldMeta",
		voucher:"Contember_FieldMeta",
		customer:"Contember_FieldMeta"
	},
	Contember_Voucher:{
		_meta:"Contember_VoucherMeta",
		id:"UUID",
		createdAt:"DateTime",
		activatedAt:"DateTime",
		expiresAt:"DateTime",
		internalNote:"String",
		note:"String",
		code:"String",
		type:"Contember_VoucherType",
		creditCents:"Int",
		buyer:"Contember_Customer",
		owner:"Contember_Customer",
		vatRate:"VatRate",
		virtualProductEffect:"Contember_VirtualProductEffect",
		redemption:"Contember_VoucherRedemption"
	},
	Contember_VoucherMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		activatedAt:"Contember_FieldMeta",
		expiresAt:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		creditCents:"Contember_FieldMeta",
		buyer:"Contember_FieldMeta",
		owner:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		virtualProductEffect:"Contember_FieldMeta",
		redemption:"Contember_FieldMeta"
	},
	Contember_VirtualProductEffect:{
		_meta:"Contember_VirtualProductEffectMeta",
		id:"UUID",
		createdAt:"DateTime",
		succeededAt:"DateTime",
		orderItem:"OrderItem",
		failedAt:"DateTime",
		revertedAt:"DateTime",
		resultCustomerCreditTransaction:"Contember_CustomerCreditTransaction",
		resultVouchers:"Contember_Voucher",
		resultVouchersByCode:"Contember_Voucher",
		resultVouchersByRedemption:"Contember_Voucher",
		paginateResultVouchers:"Contember_VoucherConnection"
	},
	Contember_VirtualProductEffectMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		succeededAt:"Contember_FieldMeta",
		orderItem:"Contember_FieldMeta",
		failedAt:"Contember_FieldMeta",
		revertedAt:"Contember_FieldMeta",
		resultCustomerCreditTransaction:"Contember_FieldMeta",
		resultVouchers:"Contember_FieldMeta"
	},
	OrderItem:{
		_meta:"OrderItemMeta",
		id:"UUID",
		unitPriceCents:"Int",
		quantity:"Int",
		vatRate:"VatRate",
		order:"Order",
		virtualProductEffects:"Contember_VirtualProductEffect",
		pickedAt:"DateTime",
		note:"String",
		fulfillmentNotes:"Contember_FulfillmentNote",
		product:"Product",
		virtualProductEffectsByResultCustomerCreditTransaction:"Contember_VirtualProductEffect",
		virtualProductEffectsByResultVouchers:"Contember_VirtualProductEffect",
		paginateVirtualProductEffects:"Contember_VirtualProductEffectConnection",
		paginateFulfillmentNotes:"Contember_FulfillmentNoteConnection"
	},
	OrderItemMeta:{
		id:"Contember_FieldMeta",
		unitPriceCents:"Contember_FieldMeta",
		quantity:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		virtualProductEffects:"Contember_FieldMeta",
		pickedAt:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		fulfillmentNotes:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_VirtualProductEffectConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_VirtualProductEffectEdge"
	},
	Contember_VirtualProductEffectEdge:{
		node:"Contember_VirtualProductEffect"
	},
	Contember_FulfillmentNoteConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_FulfillmentNoteEdge"
	},
	Contember_FulfillmentNoteEdge:{
		node:"Contember_FulfillmentNote"
	},
	Contember_VoucherConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_VoucherEdge"
	},
	Contember_VoucherEdge:{
		node:"Contember_Voucher"
	},
	Contember_CustomerCreditTransactionConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CustomerCreditTransactionEdge"
	},
	Contember_CustomerCreditTransactionEdge:{
		node:"Contember_CustomerCreditTransaction"
	},
	Contember_OrderEvent:{
		_meta:"Contember_OrderEventMeta",
		id:"UUID",
		type:"String",
		data:"String",
		createdAt:"DateTime",
		order:"Order",
		payment:"OrderPayment",
		delivery:"OrderDelivery"
	},
	Contember_OrderEventMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		data:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		payment:"Contember_FieldMeta",
		delivery:"Contember_FieldMeta"
	},
	OrderPaymentMeta:{
		id:"Contember_FieldMeta",
		data:"Contember_FieldMeta",
		paymentCents:"Contember_FieldMeta",
		method:"Contember_FieldMeta",
		failedAt:"Contember_FieldMeta",
		state:"Contember_FieldMeta",
		approvedAt:"Contember_FieldMeta",
		events:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		invoice:"Contember_FieldMeta",
		walletType:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		publicReceiptUrl:"Contember_FieldMeta",
		priceCents:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		meta:"Contember_FieldMeta",
		otherCurrencyPriceCents:"Contember_FieldMeta"
	},
	Contember_Invoice:{
		_meta:"Contember_InvoiceMeta",
		id:"UUID",
		createdAt:"DateTime",
		publicKey:"String",
		fakturoidId:"String",
		fakturoidData:"String",
		legacyUrl:"String",
		customer:"Contember_Customer",
		orderPayments:"OrderPayment",
		address:"Address",
		publicUrl:"String",
		billingSubject:"Contember_BillingSubject",
		orderPaymentsByEvents:"OrderPayment",
		orderPaymentsByMeta:"OrderPayment",
		paginateOrderPayments:"OrderPaymentConnection"
	},
	Contember_InvoiceMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		publicKey:"Contember_FieldMeta",
		fakturoidId:"Contember_FieldMeta",
		fakturoidData:"Contember_FieldMeta",
		legacyUrl:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		orderPayments:"Contember_FieldMeta",
		address:"Contember_FieldMeta",
		publicUrl:"Contember_FieldMeta",
		billingSubject:"Contember_FieldMeta"
	},
	Contember_BillingSubject:{
		_meta:"Contember_BillingSubjectMeta",
		id:"UUID",
		name:"String",
		fullName:"String",
		addressLine1:"String",
		addressLine2:"String",
		city:"String",
		postalCode:"String",
		email:"String",
		phone:"String",
		companyName:"String",
		companyIdentifier:"String",
		vatIdentifier:"String",
		externalIdentifier:"String",
		hidden:"Boolean",
		createdAt:"DateTime",
		deletedAt:"DateTime",
		note:"String",
		internalNote:"String",
		fakturoidSubjectId:"String",
		country:"Contember_Country",
		customer:"Contember_Customer",
		invoices:"Contember_Invoice",
		defaultBillingSubjectOfCustomer:"Contember_Customer",
		orders:"Order",
		invoicesByPublicKey:"Contember_Invoice",
		invoicesByFakturoidId:"Contember_Invoice",
		invoicesByOrderPayments:"Contember_Invoice",
		ordersBySeq:"Order",
		ordersByDoubleshotLegacyId:"Order",
		ordersByDoubleShotLegacyNumber:"Order",
		ordersByDiscounts:"Order",
		ordersByDelivery:"Order",
		ordersByItems:"Order",
		ordersByCart:"Order",
		ordersByEvents:"Order",
		ordersByPriceLines:"Order",
		ordersByPayments:"Order",
		ordersBySummary:"Order",
		ordersByGratuity:"Order",
		paginateInvoices:"Contember_InvoiceConnection",
		paginateOrders:"OrderConnection"
	},
	Contember_BillingSubjectMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		fullName:"Contember_FieldMeta",
		addressLine1:"Contember_FieldMeta",
		addressLine2:"Contember_FieldMeta",
		city:"Contember_FieldMeta",
		postalCode:"Contember_FieldMeta",
		email:"Contember_FieldMeta",
		phone:"Contember_FieldMeta",
		companyName:"Contember_FieldMeta",
		companyIdentifier:"Contember_FieldMeta",
		vatIdentifier:"Contember_FieldMeta",
		externalIdentifier:"Contember_FieldMeta",
		hidden:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		deletedAt:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		fakturoidSubjectId:"Contember_FieldMeta",
		country:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		invoices:"Contember_FieldMeta",
		defaultBillingSubjectOfCustomer:"Contember_FieldMeta",
		orders:"Contember_FieldMeta"
	},
	Contember_Country:{
		_meta:"Contember_CountryMeta",
		id:"UUID",
		code:"String",
		order:"Int"
	},
	Contember_CountryMeta:{
		id:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	Contember_InvoiceConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_InvoiceEdge"
	},
	Contember_InvoiceEdge:{
		node:"Contember_Invoice"
	},
	OrderConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"OrderEdge"
	},
	OrderEdge:{
		node:"Order"
	},
	OrderPaymentConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"OrderPaymentEdge"
	},
	OrderPaymentEdge:{
		node:"OrderPayment"
	},
	Contember_OrderPaymentMetadata:{
		_meta:"Contember_OrderPaymentMetadataMeta",
		id:"UUID",
		paymentUrl:"String",
		orderPayment:"OrderPayment"
	},
	Contember_OrderPaymentMetadataMeta:{
		id:"Contember_FieldMeta",
		paymentUrl:"Contember_FieldMeta",
		orderPayment:"Contember_FieldMeta"
	},
	Contember_OrderEventConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_OrderEventEdge"
	},
	Contember_OrderEventEdge:{
		node:"Contember_OrderEvent"
	},
	OrderPriceLine:{
		_meta:"OrderPriceLineMeta",
		id:"UUID",
		unitPriceCents:"Int",
		quantity:"Int",
		type:"Contember_OrderPriceLineType",
		orderItem:"OrderItem",
		orderDiscount:"OrderDiscount",
		orderPayment:"OrderPayment",
		orderDelivery:"OrderDelivery",
		vatRatePermille:"Int",
		vatRate:"VatRate",
		order:"Order"
	},
	OrderPriceLineMeta:{
		id:"Contember_FieldMeta",
		unitPriceCents:"Contember_FieldMeta",
		quantity:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		orderItem:"Contember_FieldMeta",
		orderDiscount:"Contember_FieldMeta",
		orderPayment:"Contember_FieldMeta",
		orderDelivery:"Contember_FieldMeta",
		vatRatePermille:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	OrderSummary:{
		_meta:"OrderSummaryMeta",
		id:"UUID",
		totalPriceCents:"Int",
		discountPriceCents:"Int",
		undiscountedPriceCents:"Int",
		itemsQuantity:"Int",
		orderUrl:"String",
		orderNumber:"String",
		order:"Order"
	},
	OrderSummaryMeta:{
		id:"Contember_FieldMeta",
		totalPriceCents:"Contember_FieldMeta",
		discountPriceCents:"Contember_FieldMeta",
		undiscountedPriceCents:"Contember_FieldMeta",
		itemsQuantity:"Contember_FieldMeta",
		orderUrl:"Contember_FieldMeta",
		orderNumber:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	Contember_Gratuity:{
		_meta:"Contember_GratuityMeta",
		id:"UUID",
		amountCents:"Int",
		order:"Order"
	},
	Contember_GratuityMeta:{
		id:"Contember_FieldMeta",
		amountCents:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	OrderDiscountConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"OrderDiscountEdge"
	},
	OrderDiscountEdge:{
		node:"OrderDiscount"
	},
	OrderItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"OrderItemEdge"
	},
	OrderItemEdge:{
		node:"OrderItem"
	},
	OrderPriceLineConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"OrderPriceLineEdge"
	},
	OrderPriceLineEdge:{
		node:"OrderPriceLine"
	},
	Contember_Area:{
		_meta:"Contember_AreaMeta",
		id:"UUID",
		order:"Int",
		name:"String",
		venue:"Contember_Venue",
		tables:"Contember_Table",
		lines:"Contember_Line",
		tablesByCustomerTabs:"Contember_Table",
		tablesBySeats:"Contember_Table",
		paginateTables:"Contember_TableConnection",
		paginateLines:"Contember_LineConnection"
	},
	Contember_AreaMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		venue:"Contember_FieldMeta",
		tables:"Contember_FieldMeta",
		lines:"Contember_FieldMeta"
	},
	Contember_Table:{
		_meta:"Contember_TableMeta",
		id:"UUID",
		shape:"Contember_TableShape",
		width:"Int",
		height:"Int",
		positionX:"Int",
		positionY:"Int",
		name:"String",
		color:"String",
		customerTabs:"Contember_CustomerTab",
		area:"Contember_Area",
		seats:"Contember_Seat",
		customerTabsByItems:"Contember_CustomerTab",
		customerTabsByMeta:"Contember_CustomerTab",
		seatsByCustomerTabs:"Contember_Seat",
		paginateCustomerTabs:"Contember_CustomerTabConnection",
		paginateSeats:"Contember_SeatConnection"
	},
	Contember_TableMeta:{
		id:"Contember_FieldMeta",
		shape:"Contember_FieldMeta",
		width:"Contember_FieldMeta",
		height:"Contember_FieldMeta",
		positionX:"Contember_FieldMeta",
		positionY:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		color:"Contember_FieldMeta",
		customerTabs:"Contember_FieldMeta",
		area:"Contember_FieldMeta",
		seats:"Contember_FieldMeta"
	},
	Contember_CustomerTab:{
		_meta:"Contember_CustomerTabMeta",
		id:"UUID",
		createdAt:"DateTime",
		color:"String",
		items:"Contember_CustomerTabItem",
		name:"String",
		meta:"Contember_CustomerTabMetadata",
		customer:"Contember_Customer",
		table:"Contember_Table",
		seat:"Contember_Seat",
		paginateItems:"Contember_CustomerTabItemConnection"
	},
	Contember_CustomerTabMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		color:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		meta:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		table:"Contember_FieldMeta",
		seat:"Contember_FieldMeta"
	},
	Contember_CustomerTabItem:{
		_meta:"Contember_CustomerTabItemMeta",
		id:"UUID",
		addedAt:"DateTime",
		tab:"Contember_CustomerTab",
		product:"Product",
		paidAt:"DateTime"
	},
	Contember_CustomerTabItemMeta:{
		id:"Contember_FieldMeta",
		addedAt:"Contember_FieldMeta",
		tab:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		paidAt:"Contember_FieldMeta"
	},
	Contember_CustomerTabMetadata:{
		_meta:"Contember_CustomerTabMetadataMeta",
		id:"UUID",
		totalPriceCents:"Int",
		totalSpentCents:"Int",
		unpaidCents:"Int",
		customerTab:"Contember_CustomerTab"
	},
	Contember_CustomerTabMetadataMeta:{
		id:"Contember_FieldMeta",
		totalPriceCents:"Contember_FieldMeta",
		totalSpentCents:"Contember_FieldMeta",
		unpaidCents:"Contember_FieldMeta",
		customerTab:"Contember_FieldMeta"
	},
	Contember_Seat:{
		_meta:"Contember_SeatMeta",
		id:"UUID",
		positionX:"Int",
		positionY:"Int",
		name:"String",
		color:"String",
		customerTabs:"Contember_CustomerTab",
		table:"Contember_Table",
		customerTabsByItems:"Contember_CustomerTab",
		customerTabsByMeta:"Contember_CustomerTab",
		paginateCustomerTabs:"Contember_CustomerTabConnection"
	},
	Contember_SeatMeta:{
		id:"Contember_FieldMeta",
		positionX:"Contember_FieldMeta",
		positionY:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		color:"Contember_FieldMeta",
		customerTabs:"Contember_FieldMeta",
		table:"Contember_FieldMeta"
	},
	Contember_CustomerTabConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CustomerTabEdge"
	},
	Contember_CustomerTabEdge:{
		node:"Contember_CustomerTab"
	},
	Contember_CustomerTabItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CustomerTabItemEdge"
	},
	Contember_CustomerTabItemEdge:{
		node:"Contember_CustomerTabItem"
	},
	Contember_SeatConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_SeatEdge"
	},
	Contember_SeatEdge:{
		node:"Contember_Seat"
	},
	Contember_Line:{
		_meta:"Contember_LineMeta",
		id:"UUID",
		positionStartX:"Int",
		positionStartY:"Int",
		positionEndX:"Int",
		positionEndY:"Int",
		area:"Contember_Area"
	},
	Contember_LineMeta:{
		id:"Contember_FieldMeta",
		positionStartX:"Contember_FieldMeta",
		positionStartY:"Contember_FieldMeta",
		positionEndX:"Contember_FieldMeta",
		positionEndY:"Contember_FieldMeta",
		area:"Contember_FieldMeta"
	},
	Contember_TableConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TableEdge"
	},
	Contember_TableEdge:{
		node:"Contember_Table"
	},
	Contember_LineConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_LineEdge"
	},
	Contember_LineEdge:{
		node:"Contember_Line"
	},
	Contember_TimetableDayConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TimetableDayEdge"
	},
	Contember_TimetableDayEdge:{
		node:"Contember_TimetableDay"
	},
	Contember_StaffConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_StaffEdge"
	},
	Contember_StaffEdge:{
		node:"Contember_Staff"
	},
	Contember_VenueEmployeeRoleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_VenueEmployeeRoleEdge"
	},
	Contember_VenueEmployeeRoleEdge:{
		node:"Contember_VenueEmployeeRole"
	},
	Contember_PointOfSaleTerminalConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSaleTerminalEdge"
	},
	Contember_PointOfSaleTerminalEdge:{
		node:"Contember_PointOfSaleTerminal"
	},
	Contember_AreaConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_AreaEdge"
	},
	Contember_AreaEdge:{
		node:"Contember_Area"
	},
	Contember_UnassignReasonConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_UnassignReasonEdge"
	},
	Contember_UnassignReasonEdge:{
		node:"Contember_UnassignReason"
	},
	Contember_CartItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CartItemEdge"
	},
	Contember_CartItemEdge:{
		node:"Contember_CartItem"
	},
	Contember_CartPriceLine:{
		_meta:"Contember_CartPriceLineMeta",
		id:"UUID",
		type:"Contember_CartPriceLineType",
		item:"Contember_CartItem",
		product:"Product",
		unitPriceCents:"Int",
		priceCents:"Int",
		quantity:"Int",
		vatRatePermille:"Int",
		vatRate:"VatRate",
		cart:"Contember_Cart"
	},
	Contember_CartPriceLineMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		item:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		unitPriceCents:"Contember_FieldMeta",
		priceCents:"Contember_FieldMeta",
		quantity:"Contember_FieldMeta",
		vatRatePermille:"Contember_FieldMeta",
		vatRate:"Contember_FieldMeta",
		cart:"Contember_FieldMeta"
	},
	Contember_CartSummary:{
		_meta:"Contember_CartSummaryMeta",
		id:"UUID",
		itemsPriceCents:"Int",
		deliveryPriceCents:"Int",
		totalPriceCents:"Int",
		itemsCount:"Int",
		itemsQuantity:"Int",
		checkoutUrl:"String",
		cart:"Contember_Cart"
	},
	Contember_CartSummaryMeta:{
		id:"Contember_FieldMeta",
		itemsPriceCents:"Contember_FieldMeta",
		deliveryPriceCents:"Contember_FieldMeta",
		totalPriceCents:"Contember_FieldMeta",
		itemsCount:"Contember_FieldMeta",
		itemsQuantity:"Contember_FieldMeta",
		checkoutUrl:"Contember_FieldMeta",
		cart:"Contember_FieldMeta"
	},
	Contember_CartPriceLineConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CartPriceLineEdge"
	},
	Contember_CartPriceLineEdge:{
		node:"Contember_CartPriceLine"
	},
	ChannelPaymentConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"ChannelPaymentEdge"
	},
	ChannelPaymentEdge:{
		node:"ChannelPayment"
	},
	ChannelDeliveryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"ChannelDeliveryEdge"
	},
	ChannelDeliveryEdge:{
		node:"ChannelDelivery"
	},
	Contember_PointOfSaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PointOfSaleEdge"
	},
	Contember_PointOfSaleEdge:{
		node:"Contember_PointOfSale"
	},
	Contember_CartConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CartEdge"
	},
	Contember_CartEdge:{
		node:"Contember_Cart"
	},
	Contember_CustomerConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CustomerEdge"
	},
	Contember_CustomerEdge:{
		node:"Contember_Customer"
	},
	Contember_LiftagoRide:{
		_meta:"Contember_LiftagoRideMeta",
		id:"UUID",
		liftagoId:"String",
		data:"String",
		updatedAt:"DateTime",
		delivery:"OrderDelivery"
	},
	Contember_LiftagoRideMeta:{
		id:"Contember_FieldMeta",
		liftagoId:"Contember_FieldMeta",
		data:"Contember_FieldMeta",
		updatedAt:"Contember_FieldMeta",
		delivery:"Contember_FieldMeta"
	},
	Contember_DeliveryWaypointConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_DeliveryWaypointEdge"
	},
	Contember_DeliveryWaypointEdge:{
		node:"Contember_DeliveryWaypoint"
	},
	Contember_DeliveryRouteConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_DeliveryRouteEdge"
	},
	Contember_DeliveryRouteEdge:{
		node:"Contember_DeliveryRoute"
	},
	DeliveryMethodConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"DeliveryMethodEdge"
	},
	DeliveryMethodEdge:{
		node:"DeliveryMethod"
	},
	Contember_Cleaner:{
		_meta:"Contember_CleanerMeta",
		id:"UUID",
		staff:"Contember_Staff",
		canReachTopShelf:"Boolean",
		createdAt:"DateTime"
	},
	Contember_CleanerMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		canReachTopShelf:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta"
	},
	Contember_StocksManager:{
		_meta:"Contember_StocksManagerMeta",
		id:"UUID",
		staff:"Contember_Staff",
		managedProducts:"Product",
		createdAt:"DateTime",
		managedProductsByCode:"Product",
		managedProductsByStocks:"Product",
		managedProductsByGroupPrices:"Product",
		managedProductsByCartItems:"Product",
		managedProductsByOrderItems:"Product",
		managedProductsByInheritedCategories:"Product",
		managedProductsByLocales:"Product",
		managedProductsByCustomerPrices:"Product",
		managedProductsByVirtualProduct:"Product",
		managedProductsByMeta:"Product",
		managedProductsByPage:"Product",
		managedProductsBySalesDays:"Product",
		managedProductsBySalesWeeks:"Product",
		managedProductsBySalesMonths:"Product",
		paginateManagedProducts:"ProductConnection"
	},
	Contember_StocksManagerMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		managedProducts:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta"
	},
	Contember_CourierManager:{
		_meta:"Contember_CourierManagerMeta",
		id:"UUID",
		staff:"Contember_Staff",
		createdAt:"DateTime"
	},
	Contember_CourierManagerMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta"
	},
	Contember_Picker:{
		_meta:"Contember_PickerMeta",
		id:"UUID",
		staff:"Contember_Staff",
		createdAt:"DateTime"
	},
	Contember_PickerMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta"
	},
	Contember_RemunerationProfile:{
		_meta:"Contember_RemunerationProfileMeta",
		id:"UUID",
		employmentInformation:"Contember_EmploymentInformation",
		workMonths:"Contember_WorkMonth",
		staff:"Contember_Staff",
		paginateEmploymentInformation:"Contember_EmploymentInformationConnection"
	},
	Contember_RemunerationProfileMeta:{
		id:"Contember_FieldMeta",
		employmentInformation:"Contember_FieldMeta",
		workMonths:"Contember_FieldMeta",
		staff:"Contember_FieldMeta"
	},
	Contember_EmploymentInformation:{
		_meta:"Contember_EmploymentInformationMeta",
		id:"UUID",
		hourlyRate:"Int",
		remunerationProfiles:"Contember_RemunerationProfile",
		monthlySalary:"Int",
		type:"Contember_EmploymentType",
		name:"String"
	},
	Contember_EmploymentInformationMeta:{
		id:"Contember_FieldMeta",
		hourlyRate:"Contember_FieldMeta",
		remunerationProfiles:"Contember_FieldMeta",
		monthlySalary:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		name:"Contember_FieldMeta"
	},
	Contember_WorkMonth:{
		_meta:"Contember_WorkMonthMeta",
		id:"UUID",
		year:"Int",
		month:"Int",
		hoursWorked:"Float",
		billableHours:"Float",
		remunerationProfile:"Contember_RemunerationProfile",
		remunerationProfileByEmploymentInformation:"Contember_RemunerationProfile",
		remunerationProfileByStaff:"Contember_RemunerationProfile",
		paginateRemunerationProfile:"Contember_RemunerationProfileConnection"
	},
	Contember_WorkMonthMeta:{
		id:"Contember_FieldMeta",
		year:"Contember_FieldMeta",
		month:"Contember_FieldMeta",
		hoursWorked:"Contember_FieldMeta",
		billableHours:"Contember_FieldMeta",
		remunerationProfile:"Contember_FieldMeta"
	},
	Contember_RemunerationProfileConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_RemunerationProfileEdge"
	},
	Contember_RemunerationProfileEdge:{
		node:"Contember_RemunerationProfile"
	},
	Contember_EmploymentInformationConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_EmploymentInformationEdge"
	},
	Contember_EmploymentInformationEdge:{
		node:"Contember_EmploymentInformation"
	},
	Contember_Cashier:{
		_meta:"Contember_CashierMeta",
		id:"UUID",
		createdAt:"DateTime",
		staff:"Contember_Staff"
	},
	Contember_CashierMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		staff:"Contember_FieldMeta"
	},
	Contember_InternalBuyer:{
		_meta:"Contember_InternalBuyerMeta",
		id:"UUID",
		createdAt:"DateTime",
		staff:"Contember_Staff"
	},
	Contember_InternalBuyerMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		staff:"Contember_FieldMeta"
	},
	Contember_SalesManager:{
		_meta:"Contember_SalesManagerMeta",
		id:"UUID",
		staff:"Contember_Staff",
		createdAt:"DateTime",
		countries:"Contember_Country",
		paginateCountries:"Contember_CountryConnection"
	},
	Contember_SalesManagerMeta:{
		id:"Contember_FieldMeta",
		staff:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		countries:"Contember_FieldMeta"
	},
	Contember_CountryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CountryEdge"
	},
	Contember_CountryEdge:{
		node:"Contember_Country"
	},
	Contember_CashierManager:{
		_meta:"Contember_CashierManagerMeta",
		id:"UUID",
		createdAt:"DateTime",
		staff:"Contember_Staff"
	},
	Contember_CashierManagerMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		staff:"Contember_FieldMeta"
	},
	Contember_BusinessCustomerConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BusinessCustomerEdge"
	},
	Contember_BusinessCustomerEdge:{
		node:"Contember_BusinessCustomer"
	},
	Contember_BusinessServiceEvent:{
		_meta:"Contember_BusinessServiceEventMeta",
		id:"UUID",
		createdAt:"DateTime",
		fulfilledAt:"DateTime",
		businessCustomer:"Contember_BusinessCustomer",
		businessService:"Contember_BusinessService",
		filledNumberOfPeople:"Int",
		filledInvoiced:"Boolean",
		filledEquipment:"String",
		filledMillTamDem:"Boolean",
		filledSOEspresso:"Boolean",
		filledFilterCoffeeInMenu:"Boolean",
		filledWaterMeterStatus:"String",
		filledServiceAction:"String",
		filledInvoiceIdentifier:"String",
		filledNote:"String",
		filledSupervisor:"Contember_Staff",
		filledContact:"String",
		filledPhone:"Boolean",
		filledEmail:"Boolean",
		filledGrindingStonesCondition:"String",
		createdBy:"Contember_Staff"
	},
	Contember_BusinessServiceEventMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		fulfilledAt:"Contember_FieldMeta",
		businessCustomer:"Contember_FieldMeta",
		businessService:"Contember_FieldMeta",
		filledNumberOfPeople:"Contember_FieldMeta",
		filledInvoiced:"Contember_FieldMeta",
		filledEquipment:"Contember_FieldMeta",
		filledMillTamDem:"Contember_FieldMeta",
		filledSOEspresso:"Contember_FieldMeta",
		filledFilterCoffeeInMenu:"Contember_FieldMeta",
		filledWaterMeterStatus:"Contember_FieldMeta",
		filledServiceAction:"Contember_FieldMeta",
		filledInvoiceIdentifier:"Contember_FieldMeta",
		filledNote:"Contember_FieldMeta",
		filledSupervisor:"Contember_FieldMeta",
		filledContact:"Contember_FieldMeta",
		filledPhone:"Contember_FieldMeta",
		filledEmail:"Contember_FieldMeta",
		filledGrindingStonesCondition:"Contember_FieldMeta",
		createdBy:"Contember_FieldMeta"
	},
	Contember_BusinessService:{
		_meta:"Contember_BusinessServiceMeta",
		id:"UUID",
		order:"Int",
		requireNote:"Int",
		locales:"Contember_BusinessServiceLocale",
		requireNumberOfPeople:"Int",
		requireInvoiced:"Int",
		requireEquipment:"Int",
		requireMillTamDem:"Int",
		requireSOEspresso:"Int",
		requireFilterCoffeeInMenu:"Int",
		requireWaterMeterStatus:"Int",
		requireServiceAction:"Int",
		optionsEquipment:"String",
		optionsServiceAction:"String",
		requireInvoiceIdentifier:"Int",
		requireSupervisor:"Int",
		requireContact:"Int",
		requirePhone:"Int",
		requireEmail:"Int",
		requireGrindingStonesCondition:"Int",
		localesByLocale:"Contember_BusinessServiceLocale",
		paginateLocales:"Contember_BusinessServiceLocaleConnection"
	},
	Contember_BusinessServiceMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		requireNote:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		requireNumberOfPeople:"Contember_FieldMeta",
		requireInvoiced:"Contember_FieldMeta",
		requireEquipment:"Contember_FieldMeta",
		requireMillTamDem:"Contember_FieldMeta",
		requireSOEspresso:"Contember_FieldMeta",
		requireFilterCoffeeInMenu:"Contember_FieldMeta",
		requireWaterMeterStatus:"Contember_FieldMeta",
		requireServiceAction:"Contember_FieldMeta",
		optionsEquipment:"Contember_FieldMeta",
		optionsServiceAction:"Contember_FieldMeta",
		requireInvoiceIdentifier:"Contember_FieldMeta",
		requireSupervisor:"Contember_FieldMeta",
		requireContact:"Contember_FieldMeta",
		requirePhone:"Contember_FieldMeta",
		requireEmail:"Contember_FieldMeta",
		requireGrindingStonesCondition:"Contember_FieldMeta"
	},
	Contember_BusinessServiceLocale:{
		_meta:"Contember_BusinessServiceLocaleMeta",
		id:"UUID",
		name:"String",
		labelNote:"String",
		root:"Contember_BusinessService",
		locale:"Contember_Locale",
		labelNumberOfPeople:"String",
		labelInvoiced:"String",
		labelEquipment:"String",
		labelMillTamDem:"String",
		labelSOEspresso:"String",
		labelFilterCoffeeInMenu:"String",
		labelWaterMeterStatus:"String",
		labelServiceAction:"String",
		labelInvoiceIdentifier:"String",
		labelSupervisor:"String",
		labelContact:"String",
		labelPhone:"String",
		labelEmail:"String",
		labelGrindingStonesCondition:"String"
	},
	Contember_BusinessServiceLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		labelNote:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		labelNumberOfPeople:"Contember_FieldMeta",
		labelInvoiced:"Contember_FieldMeta",
		labelEquipment:"Contember_FieldMeta",
		labelMillTamDem:"Contember_FieldMeta",
		labelSOEspresso:"Contember_FieldMeta",
		labelFilterCoffeeInMenu:"Contember_FieldMeta",
		labelWaterMeterStatus:"Contember_FieldMeta",
		labelServiceAction:"Contember_FieldMeta",
		labelInvoiceIdentifier:"Contember_FieldMeta",
		labelSupervisor:"Contember_FieldMeta",
		labelContact:"Contember_FieldMeta",
		labelPhone:"Contember_FieldMeta",
		labelEmail:"Contember_FieldMeta",
		labelGrindingStonesCondition:"Contember_FieldMeta"
	},
	Contember_BusinessServiceLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BusinessServiceLocaleEdge"
	},
	Contember_BusinessServiceLocaleEdge:{
		node:"Contember_BusinessServiceLocale"
	},
	Contember_DoubleshotCoffeeEquipment:{
		_meta:"Contember_DoubleshotCoffeeEquipmentMeta",
		id:"UUID",
		espressoMachine:"String",
		espressoGrinder:"String",
		batchBrew:"String",
		batchGrinder:"String",
		waterFilter:"String",
		mechanicalFilter:"String"
	},
	Contember_DoubleshotCoffeeEquipmentMeta:{
		id:"Contember_FieldMeta",
		espressoMachine:"Contember_FieldMeta",
		espressoGrinder:"Contember_FieldMeta",
		batchBrew:"Contember_FieldMeta",
		batchGrinder:"Contember_FieldMeta",
		waterFilter:"Contember_FieldMeta",
		mechanicalFilter:"Contember_FieldMeta"
	},
	Contember_BusinessServiceEventConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BusinessServiceEventEdge"
	},
	Contember_BusinessServiceEventEdge:{
		node:"Contember_BusinessServiceEvent"
	},
	Contember_OrderRecurrence:{
		_meta:"Contember_OrderRecurrenceMeta",
		id:"UUID",
		internalName:"String",
		locales:"Contember_OrderRecurrenceLocale",
		order:"Int",
		businessCategory:"Contember_BusinessCategory",
		localesByLocale:"Contember_OrderRecurrenceLocale",
		paginateLocales:"Contember_OrderRecurrenceLocaleConnection",
		paginateBusinessCategory:"Contember_BusinessCategoryConnection"
	},
	Contember_OrderRecurrenceMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		businessCategory:"Contember_FieldMeta"
	},
	Contember_OrderRecurrenceLocale:{
		_meta:"Contember_OrderRecurrenceLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_OrderRecurrence",
		locale:"Contember_Locale"
	},
	Contember_OrderRecurrenceLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_OrderRecurrenceLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_OrderRecurrenceLocaleEdge"
	},
	Contember_OrderRecurrenceLocaleEdge:{
		node:"Contember_OrderRecurrenceLocale"
	},
	Contember_BusinessCategoryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BusinessCategoryEdge"
	},
	Contember_BusinessCategoryEdge:{
		node:"Contember_BusinessCategory"
	},
	Contember_ProductList:{
		_meta:"Contember_ProductListMeta",
		id:"UUID",
		items:"Contember_ProductListItem",
		businessCategory:"Contember_BusinessCategory",
		paginateItems:"Contember_ProductListItemConnection"
	},
	Contember_ProductListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		businessCategory:"Contember_FieldMeta"
	},
	Contember_ProductListItem:{
		_meta:"Contember_ProductListItemMeta",
		id:"UUID",
		order:"Int",
		list:"Contember_ProductList",
		product:"Product",
		defaultQuantityForOrder:"Int"
	},
	Contember_ProductListItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		defaultQuantityForOrder:"Contember_FieldMeta"
	},
	Contember_ProductListItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductListItemEdge"
	},
	Contember_ProductListItemEdge:{
		node:"Contember_ProductListItem"
	},
	Contember_ProductCategoryList:{
		_meta:"Contember_ProductCategoryListMeta",
		id:"UUID",
		items:"Contember_ProductCategoryListItem",
		locales:"Contember_ProductCategoryListLocale",
		seasonalProductCategoriesOfBusinessCategory:"Contember_BusinessCategory",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_BusinessCategory",
		localesByLocale:"Contember_ProductCategoryListLocale",
		paginateItems:"Contember_ProductCategoryListItemConnection",
		paginateLocales:"Contember_ProductCategoryListLocaleConnection"
	},
	Contember_ProductCategoryListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		seasonalProductCategoriesOfBusinessCategory:"Contember_FieldMeta",
		specificProductsProductCategoriesOfBusinessCategory:"Contember_FieldMeta"
	},
	Contember_ProductCategoryListItem:{
		_meta:"Contember_ProductCategoryListItemMeta",
		id:"UUID",
		order:"Int",
		list:"Contember_ProductCategoryList",
		productCategory:"Contember_ProductCategory",
		showRecipeImages:"Boolean",
		showPackingImages:"Boolean",
		showAllAvailableProducts:"Boolean"
	},
	Contember_ProductCategoryListItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		productCategory:"Contember_FieldMeta",
		showRecipeImages:"Contember_FieldMeta",
		showPackingImages:"Contember_FieldMeta",
		showAllAvailableProducts:"Contember_FieldMeta"
	},
	Contember_ProductCategoryListLocale:{
		_meta:"Contember_ProductCategoryListLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_ProductCategoryList",
		locale:"Contember_Locale"
	},
	Contember_ProductCategoryListLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_ProductCategoryListItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductCategoryListItemEdge"
	},
	Contember_ProductCategoryListItemEdge:{
		node:"Contember_ProductCategoryListItem"
	},
	Contember_ProductCategoryListLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductCategoryListLocaleEdge"
	},
	Contember_ProductCategoryListLocaleEdge:{
		node:"Contember_ProductCategoryListLocale"
	},
	Contember_BusinessCategoryLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BusinessCategoryLocaleEdge"
	},
	Contember_BusinessCategoryLocaleEdge:{
		node:"Contember_BusinessCategoryLocale"
	},
	Contember_OrderRecurrenceConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_OrderRecurrenceEdge"
	},
	Contember_OrderRecurrenceEdge:{
		node:"Contember_OrderRecurrence"
	},
	Contember_ProductIngredientLocale:{
		_meta:"Contember_ProductIngredientLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_ProductIngredient",
		locale:"Contember_Locale"
	},
	Contember_ProductIngredientLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_ProductIngredient:{
		_meta:"Contember_ProductIngredientMeta",
		id:"UUID",
		internalNote:"String",
		locales:"Contember_ProductIngredientLocale",
		internalName:"String",
		categories:"Contember_ProductIngredientCategory",
		allergens:"Contember_Allergen",
		needsPrepping:"Boolean",
		allowCustomerRating:"Boolean",
		customerRatings:"Contember_CustomerIngredientRating",
		preferredQuantityUnit:"Contember_ProductIngredientItemQuantityUnit",
		freshingContainer:"Contember_FreshingContainer",
		image:"Contember_Image",
		suppliers:"Contember_IngredientSupplierList",
		incrementQuantityNumber:"Float",
		localesByLocale:"Contember_ProductIngredientLocale",
		customerRatingsByCustomer:"Contember_CustomerIngredientRating",
		paginateLocales:"Contember_ProductIngredientLocaleConnection",
		paginateCategories:"Contember_ProductIngredientCategoryConnection",
		paginateAllergens:"Contember_AllergenConnection",
		paginateCustomerRatings:"Contember_CustomerIngredientRatingConnection"
	},
	Contember_ProductIngredientMeta:{
		id:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		categories:"Contember_FieldMeta",
		allergens:"Contember_FieldMeta",
		needsPrepping:"Contember_FieldMeta",
		allowCustomerRating:"Contember_FieldMeta",
		customerRatings:"Contember_FieldMeta",
		preferredQuantityUnit:"Contember_FieldMeta",
		freshingContainer:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		suppliers:"Contember_FieldMeta",
		incrementQuantityNumber:"Contember_FieldMeta"
	},
	Contember_ProductIngredientCategory:{
		_meta:"Contember_ProductIngredientCategoryMeta",
		id:"UUID",
		internalName:"String",
		internalNote:"String",
		ingredients:"Contember_ProductIngredient",
		locales:"Contember_ProductIngredientCategoryLocale",
		order:"Int",
		localesByLocale:"Contember_ProductIngredientCategoryLocale",
		paginateIngredients:"Contember_ProductIngredientConnection",
		paginateLocales:"Contember_ProductIngredientCategoryLocaleConnection"
	},
	Contember_ProductIngredientCategoryMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		ingredients:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	Contember_ProductIngredientCategoryLocale:{
		_meta:"Contember_ProductIngredientCategoryLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_ProductIngredientCategory",
		locale:"Contember_Locale"
	},
	Contember_ProductIngredientCategoryLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_ProductIngredientConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductIngredientEdge"
	},
	Contember_ProductIngredientEdge:{
		node:"Contember_ProductIngredient"
	},
	Contember_ProductIngredientCategoryLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductIngredientCategoryLocaleEdge"
	},
	Contember_ProductIngredientCategoryLocaleEdge:{
		node:"Contember_ProductIngredientCategoryLocale"
	},
	Contember_Allergen:{
		_meta:"Contember_AllergenMeta",
		id:"UUID",
		internalName:"String",
		locales:"Contember_AllergenLocale",
		ingredients:"Contember_ProductIngredient",
		code:"Int",
		localesByLocale:"Contember_AllergenLocale",
		paginateLocales:"Contember_AllergenLocaleConnection",
		paginateIngredients:"Contember_ProductIngredientConnection"
	},
	Contember_AllergenMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		ingredients:"Contember_FieldMeta",
		code:"Contember_FieldMeta"
	},
	Contember_AllergenLocale:{
		_meta:"Contember_AllergenLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_Allergen",
		locale:"Contember_Locale"
	},
	Contember_AllergenLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_AllergenLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_AllergenLocaleEdge"
	},
	Contember_AllergenLocaleEdge:{
		node:"Contember_AllergenLocale"
	},
	Contember_CustomerIngredientRating:{
		_meta:"Contember_CustomerIngredientRatingMeta",
		id:"UUID",
		customer:"Contember_Customer",
		rating:"Contember_ProductIngredientRating",
		ingredient:"Contember_ProductIngredient"
	},
	Contember_CustomerIngredientRatingMeta:{
		id:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		rating:"Contember_FieldMeta",
		ingredient:"Contember_FieldMeta"
	},
	Contember_ProductIngredientRating:{
		_meta:"Contember_ProductIngredientRatingMeta",
		id:"UUID",
		order:"Int",
		name:"String",
		icon:"Contember_Image"
	},
	Contember_ProductIngredientRatingMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		icon:"Contember_FieldMeta"
	},
	Contember_ProductIngredientItemQuantityUnit:{
		_meta:"Contember_ProductIngredientItemQuantityUnitMeta",
		id:"UUID",
		name:"String"
	},
	Contember_ProductIngredientItemQuantityUnitMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta"
	},
	Contember_FreshingContainer:{
		_meta:"Contember_FreshingContainerMeta",
		id:"UUID",
		order:"Int",
		volumeMl:"Int",
		workspace:"Contember_Workspace"
	},
	Contember_FreshingContainerMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		volumeMl:"Contember_FieldMeta",
		workspace:"Contember_FieldMeta"
	},
	Contember_Workspace:{
		_meta:"Contember_WorkspaceMeta",
		id:"UUID",
		unique:"Contember_One",
		orderDeadlineTime:"String",
		ordersLoadingDurationHours:"Int",
		freshingContainerVolumeMl:"Int",
		freshingPrepareOrderByOffsetMinutes:"Int",
		checkoutUrlTemplate:"String",
		orderUrlTemplate:"String",
		paymentUrlTemplate:"String",
		customerProfileUrlTemplate:"String",
		userProfileUrlTemplate:"String",
		messengerUrl:"String",
		messengerIosUrl:"String",
		messengerAndroidUrl:"String",
		freshingContainers:"Contember_FreshingContainer",
		openingHours:"Contember_WeekHours",
		workingHours:"Contember_WeekHours",
		defaultFreshingContainer:"Contember_FreshingContainer",
		creditsProduct:"Contember_VirtualProduct",
		discountVatRate:"VatRate",
		name:"String",
		businessAddress:"String",
		migrationTest:"String",
		paginateFreshingContainers:"Contember_FreshingContainerConnection"
	},
	Contember_WorkspaceMeta:{
		id:"Contember_FieldMeta",
		unique:"Contember_FieldMeta",
		orderDeadlineTime:"Contember_FieldMeta",
		ordersLoadingDurationHours:"Contember_FieldMeta",
		freshingContainerVolumeMl:"Contember_FieldMeta",
		freshingPrepareOrderByOffsetMinutes:"Contember_FieldMeta",
		checkoutUrlTemplate:"Contember_FieldMeta",
		orderUrlTemplate:"Contember_FieldMeta",
		paymentUrlTemplate:"Contember_FieldMeta",
		customerProfileUrlTemplate:"Contember_FieldMeta",
		userProfileUrlTemplate:"Contember_FieldMeta",
		messengerUrl:"Contember_FieldMeta",
		messengerIosUrl:"Contember_FieldMeta",
		messengerAndroidUrl:"Contember_FieldMeta",
		freshingContainers:"Contember_FieldMeta",
		openingHours:"Contember_FieldMeta",
		workingHours:"Contember_FieldMeta",
		defaultFreshingContainer:"Contember_FieldMeta",
		creditsProduct:"Contember_FieldMeta",
		discountVatRate:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		businessAddress:"Contember_FieldMeta",
		migrationTest:"Contember_FieldMeta"
	},
	Contember_WeekHours:{
		_meta:"Contember_WeekHoursMeta",
		id:"UUID",
		mon:"String",
		tue:"String",
		wed:"String",
		thu:"String",
		fri:"String",
		sat:"String",
		sun:"String"
	},
	Contember_WeekHoursMeta:{
		id:"Contember_FieldMeta",
		mon:"Contember_FieldMeta",
		tue:"Contember_FieldMeta",
		wed:"Contember_FieldMeta",
		thu:"Contember_FieldMeta",
		fri:"Contember_FieldMeta",
		sat:"Contember_FieldMeta",
		sun:"Contember_FieldMeta"
	},
	Contember_VirtualProduct:{
		_meta:"Contember_VirtualProductMeta",
		id:"UUID",
		type:"Contember_VirtualProductType",
		product:"Product",
		voucherCreditCents:"Int",
		physicalRepresentation:"Contember_VirtualProductPhysicalRepresentationType",
		recipientEmail:"Boolean"
	},
	Contember_VirtualProductMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		voucherCreditCents:"Contember_FieldMeta",
		physicalRepresentation:"Contember_FieldMeta",
		recipientEmail:"Contember_FieldMeta"
	},
	Contember_FreshingContainerConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_FreshingContainerEdge"
	},
	Contember_FreshingContainerEdge:{
		node:"Contember_FreshingContainer"
	},
	Contember_IngredientSupplierList:{
		_meta:"Contember_IngredientSupplierListMeta",
		id:"UUID",
		items:"Contember_IngredientSupplierItem",
		paginateItems:"Contember_IngredientSupplierItemConnection"
	},
	Contember_IngredientSupplierListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_IngredientSupplierItem:{
		_meta:"Contember_IngredientSupplierItemMeta",
		id:"UUID",
		order:"Int",
		list:"Contember_IngredientSupplierList",
		supplier:"Contember_IngredientSupplier"
	},
	Contember_IngredientSupplierItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		supplier:"Contember_FieldMeta"
	},
	Contember_IngredientSupplier:{
		_meta:"Contember_IngredientSupplierMeta",
		id:"UUID",
		internalName:"String",
		internalNote:"String",
		locales:"Contember_IngredientSupplierLocale",
		localesByLocale:"Contember_IngredientSupplierLocale",
		paginateLocales:"Contember_IngredientSupplierLocaleConnection"
	},
	Contember_IngredientSupplierMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		locales:"Contember_FieldMeta"
	},
	Contember_IngredientSupplierLocale:{
		_meta:"Contember_IngredientSupplierLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_IngredientSupplier",
		locale:"Contember_Locale"
	},
	Contember_IngredientSupplierLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_IngredientSupplierLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_IngredientSupplierLocaleEdge"
	},
	Contember_IngredientSupplierLocaleEdge:{
		node:"Contember_IngredientSupplierLocale"
	},
	Contember_IngredientSupplierItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_IngredientSupplierItemEdge"
	},
	Contember_IngredientSupplierItemEdge:{
		node:"Contember_IngredientSupplierItem"
	},
	Contember_ProductIngredientLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductIngredientLocaleEdge"
	},
	Contember_ProductIngredientLocaleEdge:{
		node:"Contember_ProductIngredientLocale"
	},
	Contember_ProductIngredientCategoryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductIngredientCategoryEdge"
	},
	Contember_ProductIngredientCategoryEdge:{
		node:"Contember_ProductIngredientCategory"
	},
	Contember_AllergenConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_AllergenEdge"
	},
	Contember_AllergenEdge:{
		node:"Contember_Allergen"
	},
	Contember_CustomerIngredientRatingConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CustomerIngredientRatingEdge"
	},
	Contember_CustomerIngredientRatingEdge:{
		node:"Contember_CustomerIngredientRating"
	},
	Contember_BlogLocale:{
		_meta:"Contember_BlogLocaleMeta",
		id:"UUID",
		pageName:"String",
		title:"String",
		lead:"String",
		root:"Contember_Blog",
		locale:"Contember_Locale"
	},
	Contember_BlogLocaleMeta:{
		id:"Contember_FieldMeta",
		pageName:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		lead:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_Blog:{
		_meta:"Contember_BlogMeta",
		id:"UUID",
		unique:"Contember_One",
		locales:"Contember_BlogLocale",
		localesByLocale:"Contember_BlogLocale",
		paginateLocales:"Contember_BlogLocaleConnection"
	},
	Contember_BlogMeta:{
		id:"Contember_FieldMeta",
		unique:"Contember_FieldMeta",
		locales:"Contember_FieldMeta"
	},
	Contember_BlogLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BlogLocaleEdge"
	},
	Contember_BlogLocaleEdge:{
		node:"Contember_BlogLocale"
	},
	Contember_BlogPostLocale:{
		_meta:"Contember_BlogPostLocaleMeta",
		id:"UUID",
		title:"String",
		availableForWeb:"Boolean",
		availableForMobile:"Boolean",
		root:"Contember_BlogPost",
		locale:"Contember_Locale",
		cover:"Contember_Cover",
		content:"Contember_Content",
		link:"Contember_Linkable",
		seo:"Contember_Seo",
		products:"Product",
		paginateProducts:"ProductConnection"
	},
	Contember_BlogPostLocaleMeta:{
		id:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		availableForWeb:"Contember_FieldMeta",
		availableForMobile:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		cover:"Contember_FieldMeta",
		content:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		seo:"Contember_FieldMeta",
		products:"Contember_FieldMeta"
	},
	Contember_BlogPost:{
		_meta:"Contember_BlogPostMeta",
		id:"UUID",
		order:"Int",
		locales:"Contember_BlogPostLocale",
		localesByLocale:"Contember_BlogPostLocale",
		localesByCover:"Contember_BlogPostLocale",
		localesByContent:"Contember_BlogPostLocale",
		localesByLink:"Contember_BlogPostLocale",
		localesBySeo:"Contember_BlogPostLocale",
		paginateLocales:"Contember_BlogPostLocaleConnection"
	},
	Contember_BlogPostMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		locales:"Contember_FieldMeta"
	},
	Contember_BlogPostLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BlogPostLocaleEdge"
	},
	Contember_BlogPostLocaleEdge:{
		node:"Contember_BlogPostLocale"
	},
	Contember_Cover:{
		_meta:"Contember_CoverMeta",
		id:"UUID",
		primaryTitle:"String",
		secondaryTitle:"String",
		medium:"Contember_Medium",
		buttonColors:"Contember_ColorPallete"
	},
	Contember_CoverMeta:{
		id:"Contember_FieldMeta",
		primaryTitle:"Contember_FieldMeta",
		secondaryTitle:"Contember_FieldMeta",
		medium:"Contember_FieldMeta",
		buttonColors:"Contember_FieldMeta"
	},
	Contember_Medium:{
		_meta:"Contember_MediumMeta",
		id:"UUID",
		type:"Contember_MediumType",
		colorTheme:"Contember_ColorTheme",
		image:"Contember_Image",
		video:"Contember_Video"
	},
	Contember_MediumMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		colorTheme:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		video:"Contember_FieldMeta"
	},
	Contember_Video:{
		_meta:"Contember_VideoMeta",
		id:"UUID",
		src:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		duration:"Float",
		poster:"Contember_Image"
	},
	Contember_VideoMeta:{
		id:"Contember_FieldMeta",
		src:"Contember_FieldMeta",
		width:"Contember_FieldMeta",
		height:"Contember_FieldMeta",
		size:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		duration:"Contember_FieldMeta",
		poster:"Contember_FieldMeta"
	},
	Contember_ColorPallete:{
		_meta:"Contember_ColorPalleteMeta",
		id:"UUID",
		background:"String",
		text:"String"
	},
	Contember_ColorPalleteMeta:{
		id:"Contember_FieldMeta",
		background:"Contember_FieldMeta",
		text:"Contember_FieldMeta"
	},
	Contember_Content:{
		_meta:"Contember_ContentMeta",
		id:"UUID",
		blocks:"Contember_ContentBlock",
		blocksByReferences:"Contember_ContentBlock",
		paginateBlocks:"Contember_ContentBlockConnection"
	},
	Contember_ContentMeta:{
		id:"Contember_FieldMeta",
		blocks:"Contember_FieldMeta"
	},
	Contember_ContentBlock:{
		_meta:"Contember_ContentBlockMeta",
		id:"UUID",
		order:"Int",
		json:"String",
		content:"Contember_Content",
		references:"Contember_ContentReference",
		referencesByProducts:"Contember_ContentReference",
		referencesByProductBanners:"Contember_ContentReference",
		referencesByDeliveryRegions:"Contember_ContentReference",
		paginateReferences:"Contember_ContentReferenceConnection"
	},
	Contember_ContentBlockMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		json:"Contember_FieldMeta",
		content:"Contember_FieldMeta",
		references:"Contember_FieldMeta"
	},
	Contember_ContentReference:{
		_meta:"Contember_ContentReferenceMeta",
		id:"UUID",
		type:"Contember_ContentReferenceType",
		primaryText:"String",
		secondaryText:"String",
		jsonContent:"String",
		block:"Contember_ContentBlock",
		colorPallete:"Contember_ColorPallete",
		medium:"Contember_Medium",
		link:"Contember_Link",
		blogPosts:"Contember_BlogPostList",
		links:"Contember_LinkList",
		products:"Contember_ProductList",
		contentSize:"Contember_ContentSize",
		misc:"String",
		hero:"Contember_Hero",
		gallery:"Contember_Gallery",
		socialsAndApps:"Contember_SocialsAndApps",
		productGrid:"Contember_ProductGrid",
		productBanners:"Contember_ProductBannerList",
		deliveryRegions:"Contember_DeliveryRegions",
		textList:"Contember_TextList",
		gridTiles:"Contember_GridTiles"
	},
	Contember_ContentReferenceMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		primaryText:"Contember_FieldMeta",
		secondaryText:"Contember_FieldMeta",
		jsonContent:"Contember_FieldMeta",
		block:"Contember_FieldMeta",
		colorPallete:"Contember_FieldMeta",
		medium:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		blogPosts:"Contember_FieldMeta",
		links:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		contentSize:"Contember_FieldMeta",
		misc:"Contember_FieldMeta",
		hero:"Contember_FieldMeta",
		gallery:"Contember_FieldMeta",
		socialsAndApps:"Contember_FieldMeta",
		productGrid:"Contember_FieldMeta",
		productBanners:"Contember_FieldMeta",
		deliveryRegions:"Contember_FieldMeta",
		textList:"Contember_FieldMeta",
		gridTiles:"Contember_FieldMeta"
	},
	Contember_Link:{
		_meta:"Contember_LinkMeta",
		id:"UUID",
		type:"Contember_LinkType",
		title:"String",
		externalLink:"String",
		internalLink:"Contember_Linkable"
	},
	Contember_LinkMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		externalLink:"Contember_FieldMeta",
		internalLink:"Contember_FieldMeta"
	},
	Contember_Linkable:{
		_meta:"Contember_LinkableMeta",
		id:"UUID",
		url:"String",
		blogPost:"Contember_BlogPostLocale",
		page:"Contember_PageLocale",
		wikiPage:"Contember_WikiPage",
		redirect:"Contember_Redirect"
	},
	Contember_LinkableMeta:{
		id:"Contember_FieldMeta",
		url:"Contember_FieldMeta",
		blogPost:"Contember_FieldMeta",
		page:"Contember_FieldMeta",
		wikiPage:"Contember_FieldMeta",
		redirect:"Contember_FieldMeta"
	},
	Contember_PageLocale:{
		_meta:"Contember_PageLocaleMeta",
		id:"UUID",
		title:"String",
		link:"Contember_Linkable",
		root:"Contember_Page",
		locale:"Contember_Locale",
		seo:"Contember_Seo",
		content:"Contember_Content",
		cover:"Contember_Cover",
		theme:"Contember_PageTheme"
	},
	Contember_PageLocaleMeta:{
		id:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		seo:"Contember_FieldMeta",
		content:"Contember_FieldMeta",
		cover:"Contember_FieldMeta",
		theme:"Contember_FieldMeta"
	},
	Contember_Page:{
		_meta:"Contember_PageMeta",
		id:"UUID",
		locales:"Contember_PageLocale",
		localesByLocale:"Contember_PageLocale",
		localesByLink:"Contember_PageLocale",
		localesBySeo:"Contember_PageLocale",
		localesByContent:"Contember_PageLocale",
		localesByCover:"Contember_PageLocale",
		paginateLocales:"Contember_PageLocaleConnection"
	},
	Contember_PageMeta:{
		id:"Contember_FieldMeta",
		locales:"Contember_FieldMeta"
	},
	Contember_PageLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PageLocaleEdge"
	},
	Contember_PageLocaleEdge:{
		node:"Contember_PageLocale"
	},
	Contember_Seo:{
		_meta:"Contember_SeoMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		ogImage:"Contember_Image"
	},
	Contember_SeoMeta:{
		id:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		ogTitle:"Contember_FieldMeta",
		ogDescription:"Contember_FieldMeta",
		ogImage:"Contember_FieldMeta"
	},
	Contember_WikiPage:{
		_meta:"Contember_WikiPageMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		emoji:"String",
		link:"Contember_Linkable",
		parent:"Contember_WikiPage",
		children:"Contember_WikiPage",
		content:"Contember_Content",
		childrenByLink:"Contember_WikiPage",
		childrenByChildren:"Contember_WikiPage",
		childrenByContent:"Contember_WikiPage",
		paginateChildren:"Contember_WikiPageConnection"
	},
	Contember_WikiPageMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		emoji:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		parent:"Contember_FieldMeta",
		children:"Contember_FieldMeta",
		content:"Contember_FieldMeta"
	},
	Contember_WikiPageConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_WikiPageEdge"
	},
	Contember_WikiPageEdge:{
		node:"Contember_WikiPage"
	},
	Contember_Redirect:{
		_meta:"Contember_RedirectMeta",
		id:"UUID",
		link:"Contember_Linkable",
		target:"Contember_Link"
	},
	Contember_RedirectMeta:{
		id:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		target:"Contember_FieldMeta"
	},
	Contember_BlogPostList:{
		_meta:"Contember_BlogPostListMeta",
		id:"UUID",
		items:"Contember_BlogPostListItem",
		paginateItems:"Contember_BlogPostListItemConnection"
	},
	Contember_BlogPostListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_BlogPostListItem:{
		_meta:"Contember_BlogPostListItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"Contember_BlogPostList",
		blogPost:"Contember_BlogPostLocale"
	},
	Contember_BlogPostListItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		caption:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		blogPost:"Contember_FieldMeta"
	},
	Contember_BlogPostListItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BlogPostListItemEdge"
	},
	Contember_BlogPostListItemEdge:{
		node:"Contember_BlogPostListItem"
	},
	Contember_LinkList:{
		_meta:"Contember_LinkListMeta",
		id:"UUID",
		items:"Contember_LinkListItem",
		paginateItems:"Contember_LinkListItemConnection"
	},
	Contember_LinkListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_LinkListItem:{
		_meta:"Contember_LinkListItemMeta",
		id:"UUID",
		order:"Int",
		isPrimary:"Boolean",
		list:"Contember_LinkList",
		link:"Contember_Link"
	},
	Contember_LinkListItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		isPrimary:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		link:"Contember_FieldMeta"
	},
	Contember_LinkListItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_LinkListItemEdge"
	},
	Contember_LinkListItemEdge:{
		node:"Contember_LinkListItem"
	},
	Contember_Hero:{
		_meta:"Contember_HeroMeta",
		id:"UUID",
		showLogo:"Boolean",
		middleContent:"String",
		medium:"Contember_Medium"
	},
	Contember_HeroMeta:{
		id:"Contember_FieldMeta",
		showLogo:"Contember_FieldMeta",
		middleContent:"Contember_FieldMeta",
		medium:"Contember_FieldMeta"
	},
	Contember_Gallery:{
		_meta:"Contember_GalleryMeta",
		id:"UUID",
		items:"Contember_GalleryItem",
		paginateItems:"Contember_GalleryItemConnection"
	},
	Contember_GalleryMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_GalleryItem:{
		_meta:"Contember_GalleryItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"Contember_Gallery",
		medium:"Contember_Medium"
	},
	Contember_GalleryItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		caption:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		medium:"Contember_FieldMeta"
	},
	Contember_GalleryItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_GalleryItemEdge"
	},
	Contember_GalleryItemEdge:{
		node:"Contember_GalleryItem"
	},
	Contember_SocialsAndApps:{
		_meta:"Contember_SocialsAndAppsMeta",
		id:"UUID",
		instagram:"Boolean",
		appStore:"Boolean",
		googlePlay:"Boolean"
	},
	Contember_SocialsAndAppsMeta:{
		id:"Contember_FieldMeta",
		instagram:"Contember_FieldMeta",
		appStore:"Contember_FieldMeta",
		googlePlay:"Contember_FieldMeta"
	},
	Contember_ProductGrid:{
		_meta:"Contember_ProductGridMeta",
		id:"UUID",
		isCarousel:"Boolean",
		categories:"Contember_ProductGridCategory",
		type:"Contember_ProductGridType",
		categoriesByItems:"Contember_ProductGridCategory",
		categoriesByImages:"Contember_ProductGridCategory",
		paginateCategories:"Contember_ProductGridCategoryConnection"
	},
	Contember_ProductGridMeta:{
		id:"Contember_FieldMeta",
		isCarousel:"Contember_FieldMeta",
		categories:"Contember_FieldMeta",
		type:"Contember_FieldMeta"
	},
	Contember_ProductGridCategory:{
		_meta:"Contember_ProductGridCategoryMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		root:"Contember_ProductGrid",
		items:"Contember_ProductGridItem",
		images:"Contember_ImageList",
		paginateItems:"Contember_ProductGridItemConnection"
	},
	Contember_ProductGridCategoryMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		images:"Contember_FieldMeta"
	},
	Contember_ProductGridItem:{
		_meta:"Contember_ProductGridItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		lead:"String",
		product:"Product",
		medium:"Contember_Medium",
		link:"Contember_Link",
		category:"Contember_ProductGridCategory",
		description:"String"
	},
	Contember_ProductGridItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		lead:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		medium:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		category:"Contember_FieldMeta",
		description:"Contember_FieldMeta"
	},
	Contember_ImageList:{
		_meta:"Contember_ImageListMeta",
		id:"UUID",
		items:"Contember_ImageListItem",
		itemsByImage:"Contember_ImageListItem",
		paginateItems:"Contember_ImageListItemConnection"
	},
	Contember_ImageListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_ImageListItem:{
		_meta:"Contember_ImageListItemMeta",
		id:"UUID",
		order:"Int",
		list:"Contember_ImageList",
		image:"Contember_Image"
	},
	Contember_ImageListItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		image:"Contember_FieldMeta"
	},
	Contember_ImageListItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ImageListItemEdge"
	},
	Contember_ImageListItemEdge:{
		node:"Contember_ImageListItem"
	},
	Contember_ProductGridItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductGridItemEdge"
	},
	Contember_ProductGridItemEdge:{
		node:"Contember_ProductGridItem"
	},
	Contember_ProductGridCategoryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductGridCategoryEdge"
	},
	Contember_ProductGridCategoryEdge:{
		node:"Contember_ProductGridCategory"
	},
	Contember_ProductBannerList:{
		_meta:"Contember_ProductBannerListMeta",
		id:"UUID",
		items:"Contember_ProductBannerItem",
		paginateItems:"Contember_ProductBannerItemConnection"
	},
	Contember_ProductBannerListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_ProductBannerItem:{
		_meta:"Contember_ProductBannerItemMeta",
		id:"UUID",
		order:"Int",
		list:"Contember_ProductBannerList",
		banner:"Contember_ProductBanner",
		size:"Contember_ProductBannerItemSize"
	},
	Contember_ProductBannerItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		banner:"Contember_FieldMeta",
		size:"Contember_FieldMeta"
	},
	Contember_ProductBanner:{
		_meta:"Contember_ProductBannerMeta",
		id:"UUID",
		beforeTitle:"String",
		title:"String",
		label:"String",
		button:"Contember_Link",
		backgroundImage:"Contember_Image",
		productImage:"Contember_Image",
		product:"Product"
	},
	Contember_ProductBannerMeta:{
		id:"Contember_FieldMeta",
		beforeTitle:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		label:"Contember_FieldMeta",
		button:"Contember_FieldMeta",
		backgroundImage:"Contember_FieldMeta",
		productImage:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_ProductBannerItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductBannerItemEdge"
	},
	Contember_ProductBannerItemEdge:{
		node:"Contember_ProductBannerItem"
	},
	Contember_DeliveryRegions:{
		_meta:"Contember_DeliveryRegionsMeta",
		id:"UUID",
		items:"Contember_DeliveryRegionsItem",
		itemsByImage:"Contember_DeliveryRegionsItem",
		itemsByTextList:"Contember_DeliveryRegionsItem",
		itemsByGallery:"Contember_DeliveryRegionsItem",
		paginateItems:"Contember_DeliveryRegionsItemConnection"
	},
	Contember_DeliveryRegionsMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_DeliveryRegionsItem:{
		_meta:"Contember_DeliveryRegionsItemMeta",
		id:"UUID",
		order:"Int",
		title:"String",
		offerLabel:"String",
		list:"Contember_DeliveryRegions",
		image:"Contember_Image",
		textList:"Contember_TextList",
		gallery:"Contember_ImageList"
	},
	Contember_DeliveryRegionsItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		offerLabel:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		textList:"Contember_FieldMeta",
		gallery:"Contember_FieldMeta"
	},
	Contember_TextList:{
		_meta:"Contember_TextListMeta",
		id:"UUID",
		items:"Contember_TextItem",
		paginateItems:"Contember_TextItemConnection"
	},
	Contember_TextListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_TextItem:{
		_meta:"Contember_TextItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"Contember_TextList",
		title:"String"
	},
	Contember_TextItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		text:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		title:"Contember_FieldMeta"
	},
	Contember_TextItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TextItemEdge"
	},
	Contember_TextItemEdge:{
		node:"Contember_TextItem"
	},
	Contember_DeliveryRegionsItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_DeliveryRegionsItemEdge"
	},
	Contember_DeliveryRegionsItemEdge:{
		node:"Contember_DeliveryRegionsItem"
	},
	Contember_GridTiles:{
		_meta:"Contember_GridTilesMeta",
		id:"UUID",
		items:"Contember_GridTileItem",
		itemsByLink:"Contember_GridTileItem",
		paginateItems:"Contember_GridTileItemConnection"
	},
	Contember_GridTilesMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_GridTileItem:{
		_meta:"Contember_GridTileItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"Contember_GridTiles",
		medium:"Contember_Medium",
		link:"Contember_Link"
	},
	Contember_GridTileItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		text:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		medium:"Contember_FieldMeta",
		link:"Contember_FieldMeta"
	},
	Contember_GridTileItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_GridTileItemEdge"
	},
	Contember_GridTileItemEdge:{
		node:"Contember_GridTileItem"
	},
	Contember_ContentReferenceConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ContentReferenceEdge"
	},
	Contember_ContentReferenceEdge:{
		node:"Contember_ContentReference"
	},
	Contember_ContentBlockConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ContentBlockEdge"
	},
	Contember_ContentBlockEdge:{
		node:"Contember_ContentBlock"
	},
	Contember_TranslationCataloguesIdentifier:{
		_meta:"Contember_TranslationCataloguesIdentifierMeta",
		id:"UUID",
		name:"String",
		code:"String",
		catalogue:"Contember_TranslationCatalogue",
		catalogueByDomain:"Contember_TranslationCatalogue",
		catalogueByValues:"Contember_TranslationCatalogue",
		paginateCatalogue:"Contember_TranslationCatalogueConnection"
	},
	Contember_TranslationCataloguesIdentifierMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		catalogue:"Contember_FieldMeta"
	},
	Contember_TranslationCatalogue:{
		_meta:"Contember_TranslationCatalogueMeta",
		id:"UUID",
		domain:"Contember_TranslationDomain",
		fallback:"Contember_TranslationCatalogue",
		values:"Contember_TranslationValue",
		identifier:"Contember_TranslationCataloguesIdentifier",
		valuesByKey:"Contember_TranslationValue",
		paginateValues:"Contember_TranslationValueConnection"
	},
	Contember_TranslationCatalogueMeta:{
		id:"Contember_FieldMeta",
		domain:"Contember_FieldMeta",
		fallback:"Contember_FieldMeta",
		values:"Contember_FieldMeta",
		identifier:"Contember_FieldMeta"
	},
	Contember_TranslationDomain:{
		_meta:"Contember_TranslationDomainMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		catalogues:"Contember_TranslationCatalogue",
		keys:"Contember_TranslationKey",
		cataloguesByIdentifier:"Contember_TranslationCatalogue",
		cataloguesByValues:"Contember_TranslationCatalogue",
		keysByIdentifier:"Contember_TranslationKey",
		keysByValues:"Contember_TranslationKey",
		paginateCatalogues:"Contember_TranslationCatalogueConnection",
		paginateKeys:"Contember_TranslationKeyConnection"
	},
	Contember_TranslationDomainMeta:{
		id:"Contember_FieldMeta",
		identifier:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		catalogues:"Contember_FieldMeta",
		keys:"Contember_FieldMeta"
	},
	Contember_TranslationKey:{
		_meta:"Contember_TranslationKeyMeta",
		id:"UUID",
		identifier:"String",
		contentType:"Contember_TranslationContentType",
		note:"String",
		domain:"Contember_TranslationDomain",
		values:"Contember_TranslationValue",
		valuesByCatalogue:"Contember_TranslationValue",
		paginateValues:"Contember_TranslationValueConnection"
	},
	Contember_TranslationKeyMeta:{
		id:"Contember_FieldMeta",
		identifier:"Contember_FieldMeta",
		contentType:"Contember_FieldMeta",
		note:"Contember_FieldMeta",
		domain:"Contember_FieldMeta",
		values:"Contember_FieldMeta"
	},
	Contember_TranslationValue:{
		_meta:"Contember_TranslationValueMeta",
		id:"UUID",
		value:"String",
		catalogue:"Contember_TranslationCatalogue",
		key:"Contember_TranslationKey"
	},
	Contember_TranslationValueMeta:{
		id:"Contember_FieldMeta",
		value:"Contember_FieldMeta",
		catalogue:"Contember_FieldMeta",
		key:"Contember_FieldMeta"
	},
	Contember_TranslationValueConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TranslationValueEdge"
	},
	Contember_TranslationValueEdge:{
		node:"Contember_TranslationValue"
	},
	Contember_TranslationCatalogueConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TranslationCatalogueEdge"
	},
	Contember_TranslationCatalogueEdge:{
		node:"Contember_TranslationCatalogue"
	},
	Contember_TranslationKeyConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_TranslationKeyEdge"
	},
	Contember_TranslationKeyEdge:{
		node:"Contember_TranslationKey"
	},
	Contember_MenuLocale:{
		_meta:"Contember_MenuLocaleMeta",
		id:"UUID",
		root:"Contember_SiteMenu",
		locale:"Contember_Locale",
		items:"Contember_MenuItem",
		secondaryItems:"Contember_LinkList",
		eshopLink:"Contember_Link",
		itemsBySubitems:"Contember_MenuItem",
		paginateItems:"Contember_MenuItemConnection"
	},
	Contember_MenuLocaleMeta:{
		id:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		secondaryItems:"Contember_FieldMeta",
		eshopLink:"Contember_FieldMeta"
	},
	Contember_SiteMenu:{
		_meta:"Contember_SiteMenuMeta",
		id:"UUID",
		unique:"Contember_One",
		locales:"Contember_MenuLocale",
		localesByLocale:"Contember_MenuLocale",
		localesByItems:"Contember_MenuLocale",
		localesBySecondaryItems:"Contember_MenuLocale",
		paginateLocales:"Contember_MenuLocaleConnection"
	},
	Contember_SiteMenuMeta:{
		id:"Contember_FieldMeta",
		unique:"Contember_FieldMeta",
		locales:"Contember_FieldMeta"
	},
	Contember_MenuLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_MenuLocaleEdge"
	},
	Contember_MenuLocaleEdge:{
		node:"Contember_MenuLocale"
	},
	Contember_MenuItem:{
		_meta:"Contember_MenuItemMeta",
		id:"UUID",
		order:"Int",
		menu:"Contember_MenuLocale",
		link:"Contember_Link",
		subitems:"Contember_MenuSubitem",
		paginateSubitems:"Contember_MenuSubitemConnection"
	},
	Contember_MenuItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		menu:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		subitems:"Contember_FieldMeta"
	},
	Contember_MenuSubitem:{
		_meta:"Contember_MenuSubitemMeta",
		id:"UUID",
		type:"Contember_MenuSubitemType",
		title:"String",
		item:"Contember_MenuItem",
		links:"Contember_MenuLinkList",
		products:"Contember_MenuProducts",
		delivery:"Contember_MenuDelivery",
		contact:"Contember_MenuContact",
		order:"Int"
	},
	Contember_MenuSubitemMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		item:"Contember_FieldMeta",
		links:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		delivery:"Contember_FieldMeta",
		contact:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	Contember_MenuLinkList:{
		_meta:"Contember_MenuLinkListMeta",
		id:"UUID",
		items:"Contember_MenuLinkItem",
		paginateItems:"Contember_MenuLinkItemConnection"
	},
	Contember_MenuLinkListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta"
	},
	Contember_MenuLinkItem:{
		_meta:"Contember_MenuLinkItemMeta",
		id:"UUID",
		order:"Int",
		list:"Contember_MenuLinkList",
		link:"Contember_Link",
		image:"Contember_Image",
		description:"String"
	},
	Contember_MenuLinkItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		link:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		description:"Contember_FieldMeta"
	},
	Contember_MenuLinkItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_MenuLinkItemEdge"
	},
	Contember_MenuLinkItemEdge:{
		node:"Contember_MenuLinkItem"
	},
	Contember_MenuProducts:{
		_meta:"Contember_MenuProductsMeta",
		id:"UUID",
		links:"Contember_MenuLinkList",
		buttons:"Contember_LinkList"
	},
	Contember_MenuProductsMeta:{
		id:"Contember_FieldMeta",
		links:"Contember_FieldMeta",
		buttons:"Contember_FieldMeta"
	},
	Contember_MenuDelivery:{
		_meta:"Contember_MenuDeliveryMeta",
		id:"UUID",
		deliveryRegions:"Contember_DeliveryRegions",
		text:"String",
		socialsAndApps:"Contember_SocialsAndApps"
	},
	Contember_MenuDeliveryMeta:{
		id:"Contember_FieldMeta",
		deliveryRegions:"Contember_FieldMeta",
		text:"Contember_FieldMeta",
		socialsAndApps:"Contember_FieldMeta"
	},
	Contember_MenuContact:{
		_meta:"Contember_MenuContactMeta",
		id:"UUID",
		text:"String",
		image:"Contember_Image"
	},
	Contember_MenuContactMeta:{
		id:"Contember_FieldMeta",
		text:"Contember_FieldMeta",
		image:"Contember_FieldMeta"
	},
	Contember_MenuSubitemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_MenuSubitemEdge"
	},
	Contember_MenuSubitemEdge:{
		node:"Contember_MenuSubitem"
	},
	Contember_MenuItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_MenuItemEdge"
	},
	Contember_MenuItemEdge:{
		node:"Contember_MenuItem"
	},
	Contember_ProductLocale:{
		_meta:"Contember_ProductLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		root:"Product",
		locale:"Contember_Locale",
		featureList:"Contember_TextList",
		printableName:"String",
		firstSticker:"String",
		secondSticker:"String"
	},
	Contember_ProductLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		featureList:"Contember_FieldMeta",
		printableName:"Contember_FieldMeta",
		firstSticker:"Contember_FieldMeta",
		secondSticker:"Contember_FieldMeta"
	},
	Contember_ProductRecipeLocale:{
		_meta:"Contember_ProductRecipeLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_ProductRecipe",
		locale:"Contember_Locale",
		description:"String"
	},
	Contember_ProductRecipeLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		description:"Contember_FieldMeta"
	},
	Contember_ProductRecipe:{
		_meta:"Contember_ProductRecipeMeta",
		id:"UUID",
		name:"String",
		products:"Product",
		internalNote:"String",
		color:"String",
		image:"Contember_Image",
		icon:"Contember_Image",
		ingredients:"Contember_ProductIngredientItem",
		needsPrepping:"Boolean",
		locales:"Contember_ProductRecipeLocale",
		gutTuning:"Float",
		freshingContainer:"Contember_FreshingContainer",
		description:"String",
		nutritionFacts:"Contember_NutritionFacts",
		productsByCode:"Product",
		productsByStocks:"Product",
		productsByGroupPrices:"Product",
		productsByCartItems:"Product",
		productsByOrderItems:"Product",
		productsByInheritedCategories:"Product",
		productsByLocales:"Product",
		productsByCustomerPrices:"Product",
		productsByVirtualProduct:"Product",
		productsByMeta:"Product",
		productsByPage:"Product",
		productsBySalesDays:"Product",
		productsBySalesWeeks:"Product",
		productsBySalesMonths:"Product",
		ingredientsByQuantity:"Contember_ProductIngredientItem",
		localesByLocale:"Contember_ProductRecipeLocale",
		paginateProducts:"ProductConnection",
		paginateIngredients:"Contember_ProductIngredientItemConnection",
		paginateLocales:"Contember_ProductRecipeLocaleConnection"
	},
	Contember_ProductRecipeMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		products:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta",
		color:"Contember_FieldMeta",
		image:"Contember_FieldMeta",
		icon:"Contember_FieldMeta",
		ingredients:"Contember_FieldMeta",
		needsPrepping:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		gutTuning:"Contember_FieldMeta",
		freshingContainer:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		nutritionFacts:"Contember_FieldMeta"
	},
	Contember_ProductIngredientItem:{
		_meta:"Contember_ProductIngredientItemMeta",
		id:"UUID",
		order:"Int",
		internalOrder:"Int",
		recipe:"Contember_ProductRecipe",
		ingredient:"Contember_ProductIngredient",
		quantity:"Contember_ProductIngredientItemQuantity"
	},
	Contember_ProductIngredientItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		internalOrder:"Contember_FieldMeta",
		recipe:"Contember_FieldMeta",
		ingredient:"Contember_FieldMeta",
		quantity:"Contember_FieldMeta"
	},
	Contember_ProductIngredientItemQuantity:{
		_meta:"Contember_ProductIngredientItemQuantityMeta",
		id:"UUID",
		unit:"Contember_ProductIngredientItemQuantityUnit",
		value:"Float"
	},
	Contember_ProductIngredientItemQuantityMeta:{
		id:"Contember_FieldMeta",
		unit:"Contember_FieldMeta",
		value:"Contember_FieldMeta"
	},
	Contember_NutritionFacts:{
		_meta:"Contember_NutritionFactsMeta",
		id:"UUID",
		energyKiloJoule:"Float",
		energyKiloCalorie:"Float",
		fatsGrams:"Float",
		fatsOfWhichSaturatedGrams:"Float",
		carbohydrateGrams:"Float",
		carbohydrateOfWhichSugarsGrams:"Float",
		proteinGrams:"Float",
		fibreGrams:"Float",
		saltGrams:"Float"
	},
	Contember_NutritionFactsMeta:{
		id:"Contember_FieldMeta",
		energyKiloJoule:"Contember_FieldMeta",
		energyKiloCalorie:"Contember_FieldMeta",
		fatsGrams:"Contember_FieldMeta",
		fatsOfWhichSaturatedGrams:"Contember_FieldMeta",
		carbohydrateGrams:"Contember_FieldMeta",
		carbohydrateOfWhichSugarsGrams:"Contember_FieldMeta",
		proteinGrams:"Contember_FieldMeta",
		fibreGrams:"Contember_FieldMeta",
		saltGrams:"Contember_FieldMeta"
	},
	Contember_ProductIngredientItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductIngredientItemEdge"
	},
	Contember_ProductIngredientItemEdge:{
		node:"Contember_ProductIngredientItem"
	},
	Contember_ProductRecipeLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductRecipeLocaleEdge"
	},
	Contember_ProductRecipeLocaleEdge:{
		node:"Contember_ProductRecipeLocale"
	},
	Contember_DeliveryMethodLocale:{
		_meta:"Contember_DeliveryMethodLocaleMeta",
		id:"UUID",
		name:"String",
		description:"String",
		trackingCodeUrlTemplate:"String",
		processingTitle:"String",
		processingText:"String",
		processingIconUrl:"String",
		dispatchedTitle:"String",
		dispatchedText:"String",
		dispatchedIconUrl:"String",
		fulfilledTitle:"String",
		fulfilledText:"String",
		fulfilledIconUrl:"String",
		locale:"Contember_Locale",
		root:"DeliveryMethod"
	},
	Contember_DeliveryMethodLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		trackingCodeUrlTemplate:"Contember_FieldMeta",
		processingTitle:"Contember_FieldMeta",
		processingText:"Contember_FieldMeta",
		processingIconUrl:"Contember_FieldMeta",
		dispatchedTitle:"Contember_FieldMeta",
		dispatchedText:"Contember_FieldMeta",
		dispatchedIconUrl:"Contember_FieldMeta",
		fulfilledTitle:"Contember_FieldMeta",
		fulfilledText:"Contember_FieldMeta",
		fulfilledIconUrl:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		root:"Contember_FieldMeta"
	},
	Contember_PipelineStageLocale:{
		_meta:"Contember_PipelineStageLocaleMeta",
		id:"UUID",
		name:"String",
		root:"Contember_PipelineStage",
		locale:"Contember_Locale"
	},
	Contember_PipelineStageLocaleMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta"
	},
	Contember_PipelineStage:{
		_meta:"Contember_PipelineStageMeta",
		id:"UUID",
		items:"Contember_PipelineStageItem",
		pipeline:"Contember_Pipeline",
		order:"Int",
		locales:"Contember_PipelineStageLocale",
		sentiment:"Contember_PipelineStageSentiment",
		type:"Contember_PipelineStageType",
		forms:"Contember_LeadForm",
		showItemsWeight:"Boolean",
		showItemsPrice:"Boolean",
		itemsByLead:"Contember_PipelineStageItem",
		localesByLocale:"Contember_PipelineStageLocale",
		formsByCode:"Contember_LeadForm",
		formsByLeads:"Contember_LeadForm",
		paginateItems:"Contember_PipelineStageItemConnection",
		paginateLocales:"Contember_PipelineStageLocaleConnection",
		paginateForms:"Contember_LeadFormConnection"
	},
	Contember_PipelineStageMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		pipeline:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		sentiment:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		forms:"Contember_FieldMeta",
		showItemsWeight:"Contember_FieldMeta",
		showItemsPrice:"Contember_FieldMeta"
	},
	Contember_PipelineStageItem:{
		_meta:"Contember_PipelineStageItemMeta",
		id:"UUID",
		list:"Contember_PipelineStage",
		lead:"Contember_PipelineLead",
		order:"Int"
	},
	Contember_PipelineStageItemMeta:{
		id:"Contember_FieldMeta",
		list:"Contember_FieldMeta",
		lead:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	Contember_PipelineLead:{
		_meta:"Contember_PipelineLeadMeta",
		id:"UUID",
		createdAt:"DateTime",
		stageItem:"Contember_PipelineStageItem",
		contactPerson:"String",
		customer:"Contember_Customer",
		cafeType:"String",
		offerType:"String",
		ownMachine:"Boolean",
		customerCity:"String",
		companyId:"String",
		customerName:"String",
		email:"String",
		phone:"String",
		form:"Contember_LeadForm",
		meta:"String",
		multiroasterType:"String"
	},
	Contember_PipelineLeadMeta:{
		id:"Contember_FieldMeta",
		createdAt:"Contember_FieldMeta",
		stageItem:"Contember_FieldMeta",
		contactPerson:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		cafeType:"Contember_FieldMeta",
		offerType:"Contember_FieldMeta",
		ownMachine:"Contember_FieldMeta",
		customerCity:"Contember_FieldMeta",
		companyId:"Contember_FieldMeta",
		customerName:"Contember_FieldMeta",
		email:"Contember_FieldMeta",
		phone:"Contember_FieldMeta",
		form:"Contember_FieldMeta",
		meta:"Contember_FieldMeta",
		multiroasterType:"Contember_FieldMeta"
	},
	Contember_LeadForm:{
		_meta:"Contember_LeadFormMeta",
		id:"UUID",
		code:"String",
		stage:"Contember_PipelineStage",
		leads:"Contember_PipelineLead",
		leadsByStageItem:"Contember_PipelineLead",
		paginateLeads:"Contember_PipelineLeadConnection"
	},
	Contember_LeadFormMeta:{
		id:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		stage:"Contember_FieldMeta",
		leads:"Contember_FieldMeta"
	},
	Contember_PipelineLeadConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PipelineLeadEdge"
	},
	Contember_PipelineLeadEdge:{
		node:"Contember_PipelineLead"
	},
	Contember_Pipeline:{
		_meta:"Contember_PipelineMeta",
		id:"UUID",
		unique:"Contember_One",
		stages:"Contember_PipelineStage",
		stagesByItems:"Contember_PipelineStage",
		stagesByLocales:"Contember_PipelineStage",
		stagesByForms:"Contember_PipelineStage",
		paginateStages:"Contember_PipelineStageConnection"
	},
	Contember_PipelineMeta:{
		id:"Contember_FieldMeta",
		unique:"Contember_FieldMeta",
		stages:"Contember_FieldMeta"
	},
	Contember_PipelineStageConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PipelineStageEdge"
	},
	Contember_PipelineStageEdge:{
		node:"Contember_PipelineStage"
	},
	Contember_PipelineStageSentiment:{
		_meta:"Contember_PipelineStageSentimentMeta",
		id:"UUID",
		internalName:"String",
		backgroundColor:"String",
		textColor:"String"
	},
	Contember_PipelineStageSentimentMeta:{
		id:"Contember_FieldMeta",
		internalName:"Contember_FieldMeta",
		backgroundColor:"Contember_FieldMeta",
		textColor:"Contember_FieldMeta"
	},
	Contember_PipelineStageItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PipelineStageItemEdge"
	},
	Contember_PipelineStageItemEdge:{
		node:"Contember_PipelineStageItem"
	},
	Contember_PipelineStageLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PipelineStageLocaleEdge"
	},
	Contember_PipelineStageLocaleEdge:{
		node:"Contember_PipelineStageLocale"
	},
	Contember_LeadFormConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_LeadFormEdge"
	},
	Contember_LeadFormEdge:{
		node:"Contember_LeadForm"
	},
	Contember_SubscriptionBoxLocale:{
		_meta:"Contember_SubscriptionBoxLocaleMeta",
		id:"UUID",
		title:"String",
		stickerText:"String",
		descriptionText:"String",
		root:"Contember_SubscriptionBox",
		locale:"Contember_Locale",
		secondaryTitle:"String",
		texts:"Contember_TextList",
		secondaryTexts:"Contember_TextList"
	},
	Contember_SubscriptionBoxLocaleMeta:{
		id:"Contember_FieldMeta",
		title:"Contember_FieldMeta",
		stickerText:"Contember_FieldMeta",
		descriptionText:"Contember_FieldMeta",
		root:"Contember_FieldMeta",
		locale:"Contember_FieldMeta",
		secondaryTitle:"Contember_FieldMeta",
		texts:"Contember_FieldMeta",
		secondaryTexts:"Contember_FieldMeta"
	},
	Contember_SubscriptionBox:{
		_meta:"Contember_SubscriptionBoxMeta",
		id:"UUID",
		locales:"Contember_SubscriptionBoxLocale",
		unique:"Contember_One",
		localesByLocale:"Contember_SubscriptionBoxLocale",
		localesByTexts:"Contember_SubscriptionBoxLocale",
		localesBySecondaryTexts:"Contember_SubscriptionBoxLocale",
		paginateLocales:"Contember_SubscriptionBoxLocaleConnection"
	},
	Contember_SubscriptionBoxMeta:{
		id:"Contember_FieldMeta",
		locales:"Contember_FieldMeta",
		unique:"Contember_FieldMeta"
	},
	Contember_SubscriptionBoxLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_SubscriptionBoxLocaleEdge"
	},
	Contember_SubscriptionBoxLocaleEdge:{
		node:"Contember_SubscriptionBoxLocale"
	},
	Contember_ProductPackingLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductPackingLocaleEdge"
	},
	Contember_ProductPackingLocaleEdge:{
		node:"Contember_ProductPackingLocale"
	},
	Contember_ProductLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductLocaleEdge"
	},
	Contember_ProductLocaleEdge:{
		node:"Contember_ProductLocale"
	},
	Contember_DeliveryMethodLocaleConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_DeliveryMethodLocaleEdge"
	},
	Contember_DeliveryMethodLocaleEdge:{
		node:"Contember_DeliveryMethodLocale"
	},
	Contember_PreparedPackingQuantity:{
		_meta:"Contember_PreparedPackingQuantityMeta",
		id:"UUID",
		date:"Date",
		quantity:"Float",
		recipe:"Contember_ProductRecipe",
		packing:"Contember_ProductPacking",
		packedInCardboard:"Boolean"
	},
	Contember_PreparedPackingQuantityMeta:{
		id:"Contember_FieldMeta",
		date:"Contember_FieldMeta",
		quantity:"Contember_FieldMeta",
		recipe:"Contember_FieldMeta",
		packing:"Contember_FieldMeta",
		packedInCardboard:"Contember_FieldMeta"
	},
	Contember_PreparedPackingQuantityConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_PreparedPackingQuantityEdge"
	},
	Contember_PreparedPackingQuantityEdge:{
		node:"Contember_PreparedPackingQuantity"
	},
	Contember_ProductStock:{
		_meta:"Contember_ProductStockMeta",
		id:"UUID",
		quantity:"Int",
		product:"Product",
		store:"Contember_Store"
	},
	Contember_ProductStockMeta:{
		id:"Contember_FieldMeta",
		quantity:"Contember_FieldMeta",
		product:"Contember_FieldMeta",
		store:"Contember_FieldMeta"
	},
	Contember_Store:{
		_meta:"Contember_StoreMeta",
		id:"UUID",
		name:"String",
		code:"String",
		description:"String",
		internalNote:"String"
	},
	Contember_StoreMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		description:"Contember_FieldMeta",
		internalNote:"Contember_FieldMeta"
	},
	Contember_ProductGroupPrice:{
		_meta:"Contember_ProductGroupPriceMeta",
		id:"UUID",
		priceCents:"Int",
		group:"Contember_CustomerGroup",
		product:"Product"
	},
	Contember_ProductGroupPriceMeta:{
		id:"Contember_FieldMeta",
		priceCents:"Contember_FieldMeta",
		group:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_CustomerGroup:{
		_meta:"Contember_CustomerGroupMeta",
		id:"UUID",
		name:"String",
		productPrices:"Contember_ProductGroupPrice",
		customers:"Contember_Customer",
		discountPermille:"Int",
		code:"String",
		doubleshotLegacyId:"String",
		productPricesByProduct:"Contember_ProductGroupPrice",
		customersByDoubleshotLegacyId:"Contember_Customer",
		customersByAddresses:"Contember_Customer",
		customersByDefaultBillingAddress:"Contember_Customer",
		customersByDefaultPaymentMethod:"Contember_Customer",
		customersByCredits:"Contember_Customer",
		customersByPaymentMethods:"Contember_Customer",
		customersByOrders:"Contember_Customer",
		customersByPermissions:"Contember_Customer",
		customersByName:"Contember_Customer",
		customersByIngredientRatings:"Contember_Customer",
		customersByBusiness:"Contember_Customer",
		customersByCarts:"Contember_Customer",
		customersByShortcutList:"Contember_Customer",
		customersByUser:"Contember_Customer",
		customersByInvoices:"Contember_Customer",
		customersByInvoicingPlan:"Contember_Customer",
		customersByBillingSubjects:"Contember_Customer",
		customersByOfferedProducts:"Contember_Customer",
		customersByDefaultDeliveryAddress:"Contember_Customer",
		customersByDefaultBillingSubject:"Contember_Customer",
		customersByAnonymousSession:"Contember_Customer",
		customersByBoughtVouchers:"Contember_Customer",
		customersByOwnsVouchers:"Contember_Customer",
		customersByRedeemedVouchers:"Contember_Customer",
		customersBySpending:"Contember_Customer",
		customersByCustomerTabs:"Contember_Customer",
		customersByLead:"Contember_Customer",
		customersByMeta:"Contember_Customer",
		paginateProductPrices:"Contember_ProductGroupPriceConnection",
		paginateCustomers:"Contember_CustomerConnection"
	},
	Contember_CustomerGroupMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		productPrices:"Contember_FieldMeta",
		customers:"Contember_FieldMeta",
		discountPermille:"Contember_FieldMeta",
		code:"Contember_FieldMeta",
		doubleshotLegacyId:"Contember_FieldMeta"
	},
	Contember_ProductGroupPriceConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductGroupPriceEdge"
	},
	Contember_ProductGroupPriceEdge:{
		node:"Contember_ProductGroupPrice"
	},
	Contember_ProductCustomerPrice:{
		_meta:"Contember_ProductCustomerPriceMeta",
		id:"UUID",
		groupDiscountPermille:"Int",
		groupPriceCents:"Int",
		fullPriceCents:"Int",
		discountedPriceCents:"Int",
		priceCents:"Int",
		offered:"Boolean",
		notDiscountable:"Boolean",
		customer:"Contember_Customer",
		product:"Product"
	},
	Contember_ProductCustomerPriceMeta:{
		id:"Contember_FieldMeta",
		groupDiscountPermille:"Contember_FieldMeta",
		groupPriceCents:"Contember_FieldMeta",
		fullPriceCents:"Contember_FieldMeta",
		discountedPriceCents:"Contember_FieldMeta",
		priceCents:"Contember_FieldMeta",
		offered:"Contember_FieldMeta",
		notDiscountable:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_ProductMetadata:{
		_meta:"Contember_ProductMetadataMeta",
		id:"UUID",
		archived:"Boolean",
		available:"Boolean",
		availableQuantity:"Int",
		product:"Product"
	},
	Contember_ProductMetadataMeta:{
		id:"Contember_FieldMeta",
		archived:"Contember_FieldMeta",
		available:"Contember_FieldMeta",
		availableQuantity:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_ProductSalesDay:{
		_meta:"Contember_ProductSalesDayMeta",
		id:"UUID",
		productName:"String",
		orderDate:"Date",
		priceCentsSum:"Int",
		sum:"Int",
		product:"Product"
	},
	Contember_ProductSalesDayMeta:{
		id:"Contember_FieldMeta",
		productName:"Contember_FieldMeta",
		orderDate:"Contember_FieldMeta",
		priceCentsSum:"Contember_FieldMeta",
		sum:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_ProductSalesWeek:{
		_meta:"Contember_ProductSalesWeekMeta",
		id:"UUID",
		productName:"String",
		orderDate:"Date",
		priceCentsSum:"Int",
		sum:"Int",
		product:"Product"
	},
	Contember_ProductSalesWeekMeta:{
		id:"Contember_FieldMeta",
		productName:"Contember_FieldMeta",
		orderDate:"Contember_FieldMeta",
		priceCentsSum:"Contember_FieldMeta",
		sum:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_ProductSalesMonth:{
		_meta:"Contember_ProductSalesMonthMeta",
		id:"UUID",
		productName:"String",
		orderDate:"Date",
		priceCentsSum:"Int",
		sum:"Int",
		product:"Product"
	},
	Contember_ProductSalesMonthMeta:{
		id:"Contember_FieldMeta",
		productName:"Contember_FieldMeta",
		orderDate:"Contember_FieldMeta",
		priceCentsSum:"Contember_FieldMeta",
		sum:"Contember_FieldMeta",
		product:"Contember_FieldMeta"
	},
	Contember_ProductStockConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductStockEdge"
	},
	Contember_ProductStockEdge:{
		node:"Contember_ProductStock"
	},
	Contember_DeliveryZoneConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_DeliveryZoneEdge"
	},
	Contember_DeliveryZoneEdge:{
		node:"Contember_DeliveryZone"
	},
	Contember_ProductCustomerPriceConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductCustomerPriceEdge"
	},
	Contember_ProductCustomerPriceEdge:{
		node:"Contember_ProductCustomerPrice"
	},
	Contember_ProductSalesDayConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductSalesDayEdge"
	},
	Contember_ProductSalesDayEdge:{
		node:"Contember_ProductSalesDay"
	},
	Contember_ProductSalesWeekConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductSalesWeekEdge"
	},
	Contember_ProductSalesWeekEdge:{
		node:"Contember_ProductSalesWeek"
	},
	Contember_ProductSalesMonthConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ProductSalesMonthEdge"
	},
	Contember_ProductSalesMonthEdge:{
		node:"Contember_ProductSalesMonth"
	},
	Contember_AddressMetadata:{
		_meta:"Contember_AddressMetadataMeta",
		id:"UUID",
		deliveryZoneType:"Contember_DeliveryZoneType",
		address:"Address",
		deliveryZone:"Contember_DeliveryZone"
	},
	Contember_AddressMetadataMeta:{
		id:"Contember_FieldMeta",
		deliveryZoneType:"Contember_FieldMeta",
		address:"Contember_FieldMeta",
		deliveryZone:"Contember_FieldMeta"
	},
	AddressConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"AddressEdge"
	},
	AddressEdge:{
		node:"Address"
	},
	Contember_AddressMetadataConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_AddressMetadataEdge"
	},
	Contember_AddressMetadataEdge:{
		node:"Contember_AddressMetadata"
	},
	Contember_DeliveryTimelinePreset:{
		_meta:"Contember_DeliveryTimelinePresetMeta",
		id:"UUID",
		type:"Contember_DeliveryTimelineType",
		duration:"String",
		window:"String",
		deliveryMethod:"DeliveryMethod"
	},
	Contember_DeliveryTimelinePresetMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		duration:"Contember_FieldMeta",
		window:"Contember_FieldMeta",
		deliveryMethod:"Contember_FieldMeta"
	},
	PaymentMethodConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"PaymentMethodEdge"
	},
	PaymentMethodEdge:{
		node:"PaymentMethod"
	},
	Contember_CourierConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CourierEdge"
	},
	Contember_CourierEdge:{
		node:"Contember_Courier"
	},
	Contember_CustomerPermissions:{
		_meta:"Contember_CustomerPermissionsMeta",
		id:"UUID",
		canPlaceOrder:"Boolean",
		customer:"Contember_Customer"
	},
	Contember_CustomerPermissionsMeta:{
		id:"Contember_FieldMeta",
		canPlaceOrder:"Contember_FieldMeta",
		customer:"Contember_FieldMeta"
	},
	Contember_CustomerName:{
		_meta:"Contember_CustomerNameMeta",
		id:"UUID",
		nominative:"String",
		vocative:"String",
		customer:"Contember_Customer",
		aliases:"Contember_CustomerAlias",
		paginateAliases:"Contember_CustomerAliasConnection"
	},
	Contember_CustomerNameMeta:{
		id:"Contember_FieldMeta",
		nominative:"Contember_FieldMeta",
		vocative:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		aliases:"Contember_FieldMeta"
	},
	Contember_CustomerAlias:{
		_meta:"Contember_CustomerAliasMeta",
		id:"UUID",
		name:"String",
		order:"Int"
	},
	Contember_CustomerAliasMeta:{
		id:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		order:"Contember_FieldMeta"
	},
	Contember_CustomerAliasConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CustomerAliasEdge"
	},
	Contember_CustomerAliasEdge:{
		node:"Contember_CustomerAlias"
	},
	Contember_ApplicationShortcutList:{
		_meta:"Contember_ApplicationShortcutListMeta",
		id:"UUID",
		items:"Contember_ApplicationShortcutListItem",
		customer:"Contember_Customer",
		paginateItems:"Contember_ApplicationShortcutListItemConnection"
	},
	Contember_ApplicationShortcutListMeta:{
		id:"Contember_FieldMeta",
		items:"Contember_FieldMeta",
		customer:"Contember_FieldMeta"
	},
	Contember_ApplicationShortcutListItem:{
		_meta:"Contember_ApplicationShortcutListItemMeta",
		id:"UUID",
		order:"Int",
		application:"Contember_Application",
		list:"Contember_ApplicationShortcutList"
	},
	Contember_ApplicationShortcutListItemMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		application:"Contember_FieldMeta",
		list:"Contember_FieldMeta"
	},
	Contember_Application:{
		_meta:"Contember_ApplicationMeta",
		id:"UUID",
		order:"Int",
		handle:"String",
		url:"String",
		color:"String",
		icon:"Contember_Image",
		name:"String",
		short:"String"
	},
	Contember_ApplicationMeta:{
		id:"Contember_FieldMeta",
		order:"Contember_FieldMeta",
		handle:"Contember_FieldMeta",
		url:"Contember_FieldMeta",
		color:"Contember_FieldMeta",
		icon:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		short:"Contember_FieldMeta"
	},
	Contember_ApplicationShortcutListItemConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_ApplicationShortcutListItemEdge"
	},
	Contember_ApplicationShortcutListItemEdge:{
		node:"Contember_ApplicationShortcutListItem"
	},
	Contember_InvoicingPlan:{
		_meta:"Contember_InvoicingPlanMeta",
		id:"UUID",
		customer:"Contember_Customer",
		preset:"Contember_InvoicingPreset"
	},
	Contember_InvoicingPlanMeta:{
		id:"Contember_FieldMeta",
		customer:"Contember_FieldMeta",
		preset:"Contember_FieldMeta"
	},
	Contember_InvoicingPreset:{
		_meta:"Contember_InvoicingPresetMeta",
		id:"UUID",
		type:"Contember_InvoicingPresetType",
		startsAt:"DateTime",
		endsAt:"DateTime",
		intervalOfDays:"Int",
		invoicingPlan:"Contember_InvoicingPlan"
	},
	Contember_InvoicingPresetMeta:{
		id:"Contember_FieldMeta",
		type:"Contember_FieldMeta",
		startsAt:"Contember_FieldMeta",
		endsAt:"Contember_FieldMeta",
		intervalOfDays:"Contember_FieldMeta",
		invoicingPlan:"Contember_FieldMeta"
	},
	Contember_CustomerSpending:{
		_meta:"Contember_CustomerSpendingMeta",
		id:"UUID",
		totalCents:"Int",
		customer:"Contember_Customer"
	},
	Contember_CustomerSpendingMeta:{
		id:"Contember_FieldMeta",
		totalCents:"Contember_FieldMeta",
		customer:"Contember_FieldMeta"
	},
	Contember_CustomerMetadata:{
		_meta:"Contember_CustomerMetadataMeta",
		id:"UUID",
		isBusiness:"Boolean",
		name:"String",
		fallbackName:"String",
		remainingCreditsCents:"Int",
		ordersTotalCents:"Int",
		customerProfileUrl:"String",
		customer:"Contember_Customer"
	},
	Contember_CustomerMetadataMeta:{
		id:"Contember_FieldMeta",
		isBusiness:"Contember_FieldMeta",
		name:"Contember_FieldMeta",
		fallbackName:"Contember_FieldMeta",
		remainingCreditsCents:"Contember_FieldMeta",
		ordersTotalCents:"Contember_FieldMeta",
		customerProfileUrl:"Contember_FieldMeta",
		customer:"Contember_FieldMeta"
	},
	CustomerCreditConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"CustomerCreditEdge"
	},
	CustomerCreditEdge:{
		node:"CustomerCredit"
	},
	Contember_CustomerPaymentMethodConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_CustomerPaymentMethodEdge"
	},
	Contember_CustomerPaymentMethodEdge:{
		node:"Contember_CustomerPaymentMethod"
	},
	Contember_BillingSubjectConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_BillingSubjectEdge"
	},
	Contember_BillingSubjectEdge:{
		node:"Contember_BillingSubject"
	},
	Contember_VoucherRedemptionConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"Contember_VoucherRedemptionEdge"
	},
	Contember_VoucherRedemptionEdge:{
		node:"Contember_VoucherRedemption"
	},
	Contember_Gps:{
		_meta:"Contember_GpsMeta",
		id:"UUID",
		latitude:"Float",
		longitude:"Float",
		address:"Address"
	},
	Contember_GpsMeta:{
		id:"Contember_FieldMeta",
		latitude:"Contember_FieldMeta",
		longitude:"Contember_FieldMeta",
		address:"Contember_FieldMeta"
	},
	OrderDeliveryConnection:{
		pageInfo:"Contember_PageInfo",
		edges:"OrderDeliveryEdge"
	},
	OrderDeliveryEdge:{
		node:"OrderDelivery"
	},
	Contember__ValidationResult:{
		valid:"Boolean",
		errors:"Contember__ValidationError"
	},
	Contember__ValidationError:{
		path:"Contember__PathFragment",
		message:"Contember__ValidationMessage"
	},
	Contember__PathFragment:{
		"...on Contember__FieldPathFragment":"Contember__FieldPathFragment",
		"...on Contember__IndexPathFragment":"Contember__IndexPathFragment"
	},
	Contember__FieldPathFragment:{
		field:"String"
	},
	Contember__IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	Contember__ValidationMessage:{
		text:"String"
	},
	Contember_QueryTransaction:{
		getCustomer:"Contember_Customer"
	},
	Contember_Info:{
		description:"String"
	},
	Contember_MutationResult:{
		"...on Contember_CustomerUpdateResult": "Contember_CustomerUpdateResult",
		ok:"Boolean",
		errorMessage:"String",
		errors:"Contember__MutationError"
	},
	Contember__MutationError:{
		path:"Contember__PathFragment",
		paths:"Contember__PathFragment",
		type:"Contember__MutationErrorType",
		message:"String"
	},
	Contember_CustomerUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"Contember__MutationError",
		node:"Contember_Customer",
		validation:"Contember__ValidationResult"
	},
	Contember_MutationTransaction:{
		updateCustomer:"Contember_CustomerUpdateResult"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"



const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, unknown> = {}) => {
  const fetchOptions = options[1] || {};
  if (fetchOptions.method && fetchOptions.method === 'GET') {
    return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  }
  return fetch(`${options[0]}`, {
    body: JSON.stringify({ query, variables }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...fetchOptions,
  })
    .then(handleFetchResponse)
    .then((response: GraphQLResponse) => {
      if (response.errors) {
        throw new GraphQLError(response);
      }
      return response.data;
    });
};




export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};






export const InternalsBuildQuery = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  options?: OperationOptions,
) => {
  const ibb = (k: string, o: InputValueType | VType, p = '', root = true): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt(props, returns, ops, options?.variables?.values)(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const hasVariables = root && options?.variables?.$params ? `(${options.variables?.$params})` : '';
    const keyForDirectives = o.__directives ?? '';
    return `${k} ${keyForDirectives}${hasOperationName}${hasVariables}{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false))
      .join('\n')}}`;
  };
  return ibb;
};










export const Thunder = (fn: FetchFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation)(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullSubscriptionConstruct(fn)(operation)(o as any, ops) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: OperationOptions,
) => InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, ops)(operation, o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();

export const Gql = Chain(HOST);






export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o), options?.variables?.values);
};






export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o));
};





export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | boolean
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string | undefined>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions<Z extends Record<string, unknown> = Record<string, unknown>> = {
  variables?: VariableInput<Z>;
  operationName?: string;
};

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;


export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');




const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (typeof propsP1 === 'boolean' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'object') {
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  variables?: Record<string, unknown>,
) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (a.startsWith('$') && variables?.[a.slice(1)]) {
        return a;
      }
      const checkType = ResolveFromPath(props, returns, ops)(p);
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};




export const resolverFor = <X, T extends keyof ValueTypes, Z extends keyof ValueTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;


export type SelectionFunction<V> = <T>(t: T | V) => T;
export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;




export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : Record<string, unknown>
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>[keyof MapType<SRC, R[P]>];
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};


export const useZeusVariables =
  <T extends Record<string, unknown>>(variables: T) =>
  <
    Z extends {
      [P in keyof T]: unknown;
    },
  >(
    values: Z,
  ) => {
    return {
      $params: Object.keys(variables)
        .map((k) => `$${k}: ${variables[k as keyof T]}`)
        .join(', '),
      $: <U extends keyof Z>(variable: U) => {
        return `$${String(variable)}` as unknown as Z[U];
      },
      values,
    };
  };

export type VariableInput<Z extends Record<string, unknown>> = {
  $params: ReturnType<ReturnType<typeof useZeusVariables>>['$params'];
  values: Z;
};


type ZEUS_INTERFACES = GraphQLTypes["Contember_MutationResult"]
type ZEUS_UNIONS = GraphQLTypes["Contember__PathFragment"]

export type ValueTypes = {
    ["Query"]: AliasType<{
getInfo?: [{	customerId: ValueTypes["UUID"],	channel?: ValueTypes["ChannelCode"] | undefined | null},ValueTypes["InfoResponse"]],
getCart?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null},ValueTypes["Cart"]],
getCartIssues?: [{	cartId: ValueTypes["UUID"]},ValueTypes["CartIssuesResponse"]],
getCartDeliveryOptions?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null,	time?: ValueTypes["Date"] | undefined | null},ValueTypes["DeliveryOptionsResponse"]],
getCartPaymentOptions?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null},ValueTypes["PaymentOptionsResponse"]],
getPayment?: [{	paymentId: ValueTypes["UUID"]},ValueTypes["PaymentResponse"]],
getVoucher?: [{	code: string},ValueTypes["VoucherResponse"]],
getActiveOrder?: [{	orderId: ValueTypes["UUID"],	locale?: string | undefined | null},ValueTypes["OrderInfoResponse"]],
listActiveOrder?: [{	customerId: ValueTypes["UUID"],	locale?: string | undefined | null},ValueTypes["OrderInfoResponse"]],
listCustomerToast?: [{	customerId: ValueTypes["UUID"],	locale?: string | undefined | null},ValueTypes["Toast"]],
getCustomer?: [{	by: ValueTypes["Contember_CustomerUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
	transaction?:ValueTypes["Contember_QueryTransaction"],
	_info?:ValueTypes["Contember_Info"],
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
createCart?: [{	customerId: ValueTypes["UUID"],	channel: ValueTypes["ChannelCode"],	handle?: string | undefined | null,	items: Array<ValueTypes["CartItemInput"]>},ValueTypes["UpdateCartResponse"]],
copyCartItems?: [{	sourceCartId: ValueTypes["UUID"],	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null},ValueTypes["UpdateCartResponse"]],
setItems?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null,	items: Array<ValueTypes["CartItemInput"]>},ValueTypes["UpdateCartResponse"]],
calculatePosCart?: [{	cart: ValueTypes["PosCartInput"]},ValueTypes["PosCart"]],
deleteCart?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null},ValueTypes["DeleteCartResponse"]],
addItemToCart?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null,	productId: ValueTypes["UUID"],	quantity?: number | undefined | null},ValueTypes["UpdateCartResponse"]],
setItemQuantityInCart?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null,	productId: ValueTypes["UUID"],	quantity?: number | undefined | null},ValueTypes["UpdateCartResponse"]],
setCartDeliveryOption?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null,	deliveryOptionId?: string | undefined | null},ValueTypes["SetCartDeliveryOptionResponse"]],
setCartPaymentOption?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null,	paymentOptionId?: string | undefined | null,	billingAddressId?: ValueTypes["UUID"] | undefined | null},ValueTypes["SetCartPaymentOptionResponse"]],
confirmOrder?: [{	cartId?: ValueTypes["UUID"] | undefined | null,	customerId?: ValueTypes["UUID"] | undefined | null,	channel?: ValueTypes["ChannelCode"] | undefined | null,	handle?: string | undefined | null,	note?: string | undefined | null,	pos?: ValueTypes["PosFulfillment"] | undefined | null},ValueTypes["ConfirmOrderResponse"]],
updateCustomerInfo?: [{	customerId: ValueTypes["UUID"],	email?: string | undefined | null,	fullName?: string | undefined | null,	phone?: string | undefined | null,	defaultAddress?: string | undefined | null,	relevantCartId?: ValueTypes["UUID"] | undefined | null,	deliveryType?: string | undefined | null},ValueTypes["UpdateCustomerInfoResponse"]],
addAddress?: [{	customerId: ValueTypes["UUID"],	cartId?: ValueTypes["UUID"] | undefined | null,	data: ValueTypes["AddressData"]},ValueTypes["AddAddressResponse"]],
editAddress?: [{	addressId: ValueTypes["UUID"],	cartId?: ValueTypes["UUID"] | undefined | null,	data: ValueTypes["AddressData"]},ValueTypes["EditAddressResponse"]],
action?: [{	key: string},ValueTypes["ActionResponse"]],
redeemVoucher?: [{	code: string,	customerId?: string | undefined | null},ValueTypes["RedeemVoucherResponse"]],
updateCustomer?: [{	by: ValueTypes["Contember_CustomerUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	data: ValueTypes["Contember_CustomerUpdateInput"]},ValueTypes["Contember_CustomerUpdateResult"]],
transaction?: [{	options?: ValueTypes["Contember_MutationTransactionOptions"] | undefined | null},ValueTypes["Contember_MutationTransaction"]],
		__typename?: boolean | `@${string}`
}>;
	["InfoResponse"]: AliasType<{
	soonestDeliveryOption?:ValueTypes["DeliveryOption"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryOption"]: AliasType<{
	id?:boolean | `@${string}`,
	selected?:boolean | `@${string}`,
	delivery?:ValueTypes["ChannelDelivery"],
	address?:ValueTypes["Address"],
	timeline?:ValueTypes["DeliveryTimeline"],
	timelineDescription?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	vatRate?:ValueTypes["VatRate"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDelivery"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["ChannelDeliveryMeta"],
	order?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
channel?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
method?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	defaultVisible?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:string;
	["Address"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["AddressMeta"],
	name?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	addressLine2?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	companyIdentifier?:boolean | `@${string}`,
	vatIdentifier?:boolean | `@${string}`,
	externalIdentifier?:boolean | `@${string}`,
	hidden?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
	internalNote?:boolean | `@${string}`,
country?: [{	filter?: ValueTypes["Contember_CountryWhere"] | undefined | null},ValueTypes["Contember_Country"]],
	deletedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	googlePlaceId?:boolean | `@${string}`,
	raw?:boolean | `@${string}`,
	formatted?:boolean | `@${string}`,
	geocodeGeneratedAt?:boolean | `@${string}`,
	geocodeResponse?:boolean | `@${string}`,
deliveryZone?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null},ValueTypes["Contember_DeliveryZone"]],
meta?: [{	filter?: ValueTypes["Contember_AddressMetadataWhere"] | undefined | null},ValueTypes["Contember_AddressMetadata"]],
gps?: [{	filter?: ValueTypes["Contember_GpsWhere"] | undefined | null},ValueTypes["Contember_Gps"]],
	geocodeValid?:boolean | `@${string}`,
	fullName?:boolean | `@${string}`,
	fakturoidSubjectId?:boolean | `@${string}`,
invoices?: [{	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Invoice"]],
	addressLine1?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	postalCode?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
billingAddressOfOrders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
deliveryAddressOfOrderDeliveries?: [{	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDelivery"]],
defaultDeliveryAddressOfCustomer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
invoicesByPublicKey?: [{	by: ValueTypes["Contember_AddressInvoicesByPublicKeyUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
invoicesByFakturoidId?: [{	by: ValueTypes["Contember_AddressInvoicesByFakturoidIdUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
invoicesByOrderPayments?: [{	by: ValueTypes["Contember_AddressInvoicesByOrderPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
billingAddressOfOrdersBySeq?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersBySeqUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDoubleshotLegacyId?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDoubleShotLegacyNumber?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDiscounts?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByDiscountsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByDelivery?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByItems?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByItemsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByCart?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByCartUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByEvents?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByEventsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByPriceLines?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByPayments?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersBySummary?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersBySummaryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
billingAddressOfOrdersByGratuity?: [{	by: ValueTypes["Contember_AddressBillingAddressOfOrdersByGratuityUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
deliveryAddressOfOrderDeliveriesByTrackingCode?: [{	by: ValueTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"],	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
deliveryAddressOfOrderDeliveriesByOrder?: [{	by: ValueTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"],	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
deliveryAddressOfOrderDeliveriesByDeliveryWaypoint?: [{	by: ValueTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"],	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
deliveryAddressOfOrderDeliveriesByEvents?: [{	by: ValueTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByEventsUniqueWhere"],	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
deliveryAddressOfOrderDeliveriesByLiftagoRide?: [{	by: ValueTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByLiftagoRideUniqueWhere"],	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
paginateInvoices?: [{	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_InvoiceConnection"]],
paginateBillingAddressOfOrders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateDeliveryAddressOfOrderDeliveries?: [{	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDeliveryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimeline"]: AliasType<{
	type?:boolean | `@${string}`,
	prepareAt?:boolean | `@${string}`,
	loadingHours?:boolean | `@${string}`,
	dispatchAt?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	expectedAt?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
	expectedEndAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryTimelineType"]:DeliveryTimelineType;
	["VatRate"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["VatRateMeta"],
	name?:boolean | `@${string}`,
	ratePermille?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
channelDeliveries?: [{	filter?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
channelPayments?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
	preset?:boolean | `@${string}`,
paginateChannelDeliveries?: [{	filter?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
paginateChannelPayments?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["ChannelCode"]:ChannelCode;
	["Cart"]: AliasType<{
	id?:boolean | `@${string}`,
	handle?:boolean | `@${string}`,
	itemsPriceCents?:boolean | `@${string}`,
	deliveryPriceCents?:boolean | `@${string}`,
	totalPriceCents?:boolean | `@${string}`,
	remainingPriceCents?:boolean | `@${string}`,
	itemsCount?:boolean | `@${string}`,
	items?:ValueTypes["CartItem"],
	customerId?:boolean | `@${string}`,
	channel?:boolean | `@${string}`,
	order?:ValueTypes["Order"],
	deliveryZones?:boolean | `@${string}`,
	deliveryOption?:ValueTypes["DeliveryOption"],
	paymentOption?:ValueTypes["PaymentOption"],
	checkoutUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CartItem"]: AliasType<{
	itemId?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	productId?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	productCode?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	availableQuantity?:boolean | `@${string}`,
	unitPriceCents?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	product?:ValueTypes["Product"],
	vatRate?:ValueTypes["VatRate"],
	deliveryZones?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateTime"]:string;
	["Product"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["ProductMeta"],
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	notDiscountable?:boolean | `@${string}`,
packing?: [{	filter?: ValueTypes["Contember_ProductPackingWhere"] | undefined | null},ValueTypes["Contember_ProductPacking"]],
recipe?: [{	filter?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null},ValueTypes["Contember_ProductRecipe"]],
tags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
categories?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategory"]],
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
stocks?: [{	filter?: ValueTypes["Contember_ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductStockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductStock"]],
groupPrices?: [{	filter?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGroupPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductGroupPrice"]],
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
cartItems?: [{	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CartItem"]],
	availability?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
manager?: [{	filter?: ValueTypes["Contember_StocksManagerWhere"] | undefined | null},ValueTypes["Contember_StocksManager"]],
orderItems?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
	maximumQuantityPerOrder?:boolean | `@${string}`,
inheritedCategories?: [{	filter?: ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_NestedProductCategoryProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_NestedProductCategoryProduct"]],
deliveryZones?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryZone"]],
locales?: [{	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductLocale"]],
	archivedAt?:boolean | `@${string}`,
customerPrices?: [{	filter?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCustomerPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCustomerPrice"]],
virtualProduct?: [{	filter?: ValueTypes["Contember_VirtualProductWhere"] | undefined | null},ValueTypes["Contember_VirtualProduct"]],
meta?: [{	filter?: ValueTypes["Contember_ProductMetadataWhere"] | undefined | null},ValueTypes["Contember_ProductMetadata"]],
page?: [{	filter?: ValueTypes["Contember_PageWhere"] | undefined | null},ValueTypes["Contember_Page"]],
salesDays?: [{	filter?: ValueTypes["Contember_ProductSalesDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductSalesDayOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductSalesDay"]],
salesWeeks?: [{	filter?: ValueTypes["Contember_ProductSalesWeekWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductSalesWeekOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductSalesWeek"]],
salesMonths?: [{	filter?: ValueTypes["Contember_ProductSalesMonthWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductSalesMonthOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductSalesMonth"]],
fulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_FulfillmentNote"]],
	hasSubscription?:boolean | `@${string}`,
	pointOfSaleTileColor?:boolean | `@${string}`,
stocksByStore?: [{	by: ValueTypes["Contember_ProductStocksByStoreUniqueWhere"],	filter?: ValueTypes["Contember_ProductStockWhere"] | undefined | null},ValueTypes["Contember_ProductStock"]],
groupPricesByGroup?: [{	by: ValueTypes["Contember_ProductGroupPricesByGroupUniqueWhere"],	filter?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null},ValueTypes["Contember_ProductGroupPrice"]],
cartItemsByCart?: [{	by: ValueTypes["Contember_ProductCartItemsByCartUniqueWhere"],	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null},ValueTypes["Contember_CartItem"]],
orderItemsByVirtualProductEffects?: [{	by: ValueTypes["Contember_ProductOrderItemsByVirtualProductEffectsUniqueWhere"],	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
localesByLocale?: [{	by: ValueTypes["Contember_ProductLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductLocale"]],
localesByFeatureList?: [{	by: ValueTypes["Contember_ProductLocalesByFeatureListUniqueWhere"],	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductLocale"]],
customerPricesByCustomer?: [{	by: ValueTypes["Contember_ProductCustomerPricesByCustomerUniqueWhere"],	filter?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null},ValueTypes["Contember_ProductCustomerPrice"]],
paginateTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
paginateCategories?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryConnection"]],
paginateStocks?: [{	filter?: ValueTypes["Contember_ProductStockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductStockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductStockConnection"]],
paginateGroupPrices?: [{	filter?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGroupPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductGroupPriceConnection"]],
paginateCartItems?: [{	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CartItemConnection"]],
paginateOrderItems?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginateInheritedCategories?: [{	filter?: ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_NestedProductCategoryProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_NestedProductCategoryProductConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryZoneConnection"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductLocaleConnection"]],
paginateCustomerPrices?: [{	filter?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCustomerPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCustomerPriceConnection"]],
paginateSalesDays?: [{	filter?: ValueTypes["Contember_ProductSalesDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductSalesDayOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductSalesDayConnection"]],
paginateSalesWeeks?: [{	filter?: ValueTypes["Contember_ProductSalesWeekWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductSalesWeekOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductSalesWeekConnection"]],
paginateSalesMonths?: [{	filter?: ValueTypes["Contember_ProductSalesMonthWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductSalesMonthOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductSalesMonthConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Order"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["OrderMeta"],
	state?:boolean | `@${string}`,
	fulfilledAt?:boolean | `@${string}`,
	canceledAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
channel?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
discounts?: [{	filter?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderDiscountOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderDiscount"]],
delivery?: [{	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
items?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
	internalNote?:boolean | `@${string}`,
cart?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
events?: [{	filter?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderEventOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_OrderEvent"]],
	createdAt?:boolean | `@${string}`,
billingAddress?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
priceLines?: [{	filter?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPriceLine"]],
payments?: [{	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
	seq?:boolean | `@${string}`,
billingSubject?: [{	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null},ValueTypes["Contember_BillingSubject"]],
summary?: [{	filter?: ValueTypes["Contember_OrderSummaryWhere"] | undefined | null},ValueTypes["OrderSummary"]],
	doubleshotLegacyId?:boolean | `@${string}`,
gratuity?: [{	filter?: ValueTypes["Contember_GratuityWhere"] | undefined | null},ValueTypes["Contember_Gratuity"]],
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
	fulfillmentNote?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_FulfillmentNote"]],
	doubleshotTotalWeightGrams?:boolean | `@${string}`,
	doubleShotLegacyNumber?:boolean | `@${string}`,
	doubleshotLastUpdatedAt?:boolean | `@${string}`,
	doubleshotAdminUrl?:boolean | `@${string}`,
discountsByCustomerCreditTransactions?: [{	by: ValueTypes["Contember_OrderDiscountsByCustomerCreditTransactionsUniqueWhere"],	filter?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
discountsByVoucherRedemption?: [{	by: ValueTypes["Contember_OrderDiscountsByVoucherRedemptionUniqueWhere"],	filter?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
itemsByVirtualProductEffects?: [{	by: ValueTypes["Contember_OrderItemsByVirtualProductEffectsUniqueWhere"],	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
priceLinesByOrderItem?: [{	by: ValueTypes["Contember_OrderPriceLinesByOrderItemUniqueWhere"],	filter?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
priceLinesByOrderDiscount?: [{	by: ValueTypes["Contember_OrderPriceLinesByOrderDiscountUniqueWhere"],	filter?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
priceLinesByOrderPayment?: [{	by: ValueTypes["Contember_OrderPriceLinesByOrderPaymentUniqueWhere"],	filter?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
priceLinesByOrderDelivery?: [{	by: ValueTypes["Contember_OrderPriceLinesByOrderDeliveryUniqueWhere"],	filter?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null},ValueTypes["OrderPriceLine"]],
paymentsByEvents?: [{	by: ValueTypes["Contember_OrderPaymentsByEventsUniqueWhere"],	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
paymentsByMeta?: [{	by: ValueTypes["Contember_OrderPaymentsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
paginateDiscounts?: [{	filter?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderDiscountOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderDiscountConnection"]],
paginateItems?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginateEvents?: [{	filter?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderEventOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_OrderEventConnection"]],
paginatePriceLines?: [{	filter?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPriceLineConnection"]],
paginatePayments?: [{	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentOption"]: AliasType<{
	id?:boolean | `@${string}`,
	payment?:ValueTypes["ChannelPayment"],
	creditsCents?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	vatRate?:ValueTypes["VatRate"],
	infoAvailableCredits?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ChannelPayment"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["ChannelPaymentMeta"],
	order?:boolean | `@${string}`,
channel?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
method?: [{	filter?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	requireBillingAddress?:boolean | `@${string}`,
	provideReceipt?:boolean | `@${string}`,
	requireBillingSubject?:boolean | `@${string}`,
allowOnlyForTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
paginateAllowOnlyForTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["CartIssuesResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	issues?:ValueTypes["CartIssue"],
		__typename?: boolean | `@${string}`
}>;
	["CartIssue"]: AliasType<{
	scope?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeliveryOptionsResponse"]: AliasType<{
	methods?:ValueTypes["DeliveryMethodOption"],
	addresses?:ValueTypes["DeliveryAddressAddress"],
	selected?:ValueTypes["DeliveryOption"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodOption"]: AliasType<{
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	selected?:boolean | `@${string}`,
	delivery?:ValueTypes["ChannelDelivery"],
	priceCents?:boolean | `@${string}`,
	vatRate?:ValueTypes["VatRate"],
	options?:ValueTypes["DeliveryOption"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryAddressAddress"]: AliasType<{
	id?:boolean | `@${string}`,
	selected?:boolean | `@${string}`,
	address?:ValueTypes["Address"],
	methods?:ValueTypes["DeliveryMethodOption"],
		__typename?: boolean | `@${string}`
}>;
	["Date"]:unknown;
	["PaymentOptionsResponse"]: AliasType<{
	availableCreditsCents?:boolean | `@${string}`,
	credits?:ValueTypes["CustomerCredit"],
	options?:ValueTypes["PaymentOption"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerCredit"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["CustomerCreditMeta"],
	initialCreditCents?:boolean | `@${string}`,
	remainingCreditsCents?:boolean | `@${string}`,
	expiresAt?:boolean | `@${string}`,
	reason?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	createdAt?:boolean | `@${string}`,
transactions?: [{	filter?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerCreditTransactionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerCreditTransaction"]],
voucherRedemption?: [{	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null},ValueTypes["Contember_VoucherRedemption"]],
	invoiceUrl?:boolean | `@${string}`,
transactionsByVirtualProductEffect?: [{	by: ValueTypes["Contember_CustomerCreditTransactionsByVirtualProductEffectUniqueWhere"],	filter?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null},ValueTypes["Contember_CustomerCreditTransaction"]],
paginateTransactions?: [{	filter?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerCreditTransactionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerCreditTransactionConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	payment?:ValueTypes["OrderPaymentInfo"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentInfo"]: AliasType<{
	id?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VoucherResponse"]: AliasType<{
	redeemable?:boolean | `@${string}`,
	expiredAt?:boolean | `@${string}`,
	redeemedAt?:boolean | `@${string}`,
	creditCents?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["OrderInfoResponse"]: AliasType<{
	order?:ValueTypes["Order"],
	timelineDescription?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Toast"]: AliasType<{
	key?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	icon?:ValueTypes["ToastIcon"],
	detail?:ValueTypes["ToastDetail"],
	button?:ValueTypes["ToastButton"],
	rating?:ValueTypes["ToastRating"],
		__typename?: boolean | `@${string}`
}>;
	["ToastIcon"]: AliasType<{
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ToastDetail"]: AliasType<{
	order?:ValueTypes["Order"],
		__typename?: boolean | `@${string}`
}>;
	["ToastButton"]: AliasType<{
	caption?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	iosUrl?:boolean | `@${string}`,
	androidUrl?:boolean | `@${string}`,
	webview?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ToastRating"]: AliasType<{
	value?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	iosUrl?:boolean | `@${string}`,
	androidUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ToastRatingValue"]:ToastRatingValue;
	["UpdateCartResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorCode?:boolean | `@${string}`,
	cart?:ValueTypes["Cart"],
		__typename?: boolean | `@${string}`
}>;
	["CartItemInput"]: {
	productId: ValueTypes["UUID"],
	quantity: number
};
	["PosCart"]: AliasType<{
	customerId?:boolean | `@${string}`,
	items?:ValueTypes["PosItem"],
	totalPriceCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PosItem"]: AliasType<{
	productId?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	unitPriceCents?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PosCartInput"]: {
	customerId?: ValueTypes["UUID"] | undefined | null,
	items: Array<ValueTypes["PosItemInput"]>
};
	["PosItemInput"]: {
	productId: ValueTypes["UUID"],
	quantity: number
};
	["DeleteCartResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorCode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetCartDeliveryOptionResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorCode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SetCartPaymentOptionResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorCode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ConfirmOrderResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorCode?:boolean | `@${string}`,
	order?:ValueTypes["Order"],
	waitingForPayment?:ValueTypes["OrderPayment"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPayment"]: AliasType<{
	id?:boolean | `@${string}`,
	_meta?:ValueTypes["OrderPaymentMeta"],
	data?:boolean | `@${string}`,
	paymentCents?:boolean | `@${string}`,
method?: [{	filter?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
	failedAt?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	approvedAt?:boolean | `@${string}`,
events?: [{	filter?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderEventOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_OrderEvent"]],
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
invoice?: [{	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
	walletType?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	publicReceiptUrl?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
meta?: [{	filter?: ValueTypes["Contember_OrderPaymentMetadataWhere"] | undefined | null},ValueTypes["Contember_OrderPaymentMetadata"]],
	otherCurrencyPriceCents?:boolean | `@${string}`,
paginateEvents?: [{	filter?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderEventOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_OrderEventConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PosFulfillment"]: {
	paidAt?: ValueTypes["DateTime"] | undefined | null,
	paymentMethodCode: string,
	paymentCents: number,
	gratuityCents?: number | undefined | null,
	deviceIdentifier?: string | undefined | null,
	items?: Array<ValueTypes["PosItemInput"]> | undefined | null
};
	["UpdateCustomerInfoResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorCode?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddAddressResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	node?:ValueTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["AddressData"]: {
	raw: string,
	name?: string | undefined | null,
	email?: string | undefined | null,
	fullName?: string | undefined | null,
	phone?: string | undefined | null,
	note?: string | undefined | null,
	setIsDefaultDeliveryAddress?: boolean | undefined | null
};
	["EditAddressResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	node?:ValueTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["ActionResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RedeemVoucherResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	expiredAt?:boolean | `@${string}`,
	redeemedAt?:boolean | `@${string}`,
	creditCents?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AddressMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	firstName?:ValueTypes["Contember_FieldMeta"],
	lastName?:ValueTypes["Contember_FieldMeta"],
	addressLine2?:ValueTypes["Contember_FieldMeta"],
	email?:ValueTypes["Contember_FieldMeta"],
	phone?:ValueTypes["Contember_FieldMeta"],
	companyName?:ValueTypes["Contember_FieldMeta"],
	companyIdentifier?:ValueTypes["Contember_FieldMeta"],
	vatIdentifier?:ValueTypes["Contember_FieldMeta"],
	externalIdentifier?:ValueTypes["Contember_FieldMeta"],
	hidden?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	country?:ValueTypes["Contember_FieldMeta"],
	deletedAt?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	googlePlaceId?:ValueTypes["Contember_FieldMeta"],
	raw?:ValueTypes["Contember_FieldMeta"],
	formatted?:ValueTypes["Contember_FieldMeta"],
	geocodeGeneratedAt?:ValueTypes["Contember_FieldMeta"],
	geocodeResponse?:ValueTypes["Contember_FieldMeta"],
	deliveryZone?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
	gps?:ValueTypes["Contember_FieldMeta"],
	geocodeValid?:ValueTypes["Contember_FieldMeta"],
	fullName?:ValueTypes["Contember_FieldMeta"],
	fakturoidSubjectId?:ValueTypes["Contember_FieldMeta"],
	invoices?:ValueTypes["Contember_FieldMeta"],
	addressLine1?:ValueTypes["Contember_FieldMeta"],
	city?:ValueTypes["Contember_FieldMeta"],
	postalCode?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	billingAddressOfOrders?:ValueTypes["Contember_FieldMeta"],
	deliveryAddressOfOrderDeliveries?:ValueTypes["Contember_FieldMeta"],
	defaultDeliveryAddressOfCustomer?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FieldMeta"]: AliasType<{
	readable?:boolean | `@${string}`,
	updatable?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_Customer"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerMeta"],
	id?:boolean | `@${string}`,
addresses?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
defaultBillingAddress?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
defaultPaymentMethod?: [{	filter?: ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null},ValueTypes["Contember_CustomerPaymentMethod"]],
tags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
credits?: [{	filter?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerCreditOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["CustomerCredit"]],
paymentMethods?: [{	filter?: ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerPaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerPaymentMethod"]],
orders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
permissions?: [{	filter?: ValueTypes["Contember_CustomerPermissionsWhere"] | undefined | null},ValueTypes["Contember_CustomerPermissions"]],
group?: [{	filter?: ValueTypes["Contember_CustomerGroupWhere"] | undefined | null},ValueTypes["Contember_CustomerGroup"]],
photo?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	phone?:boolean | `@${string}`,
name?: [{	filter?: ValueTypes["Contember_CustomerNameWhere"] | undefined | null},ValueTypes["Contember_CustomerName"]],
	internalNote?:boolean | `@${string}`,
	welcomeMessage?:boolean | `@${string}`,
	familiarity?:boolean | `@${string}`,
ingredientRatings?: [{	filter?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerIngredientRating"]],
business?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
ownerOfBusinesses?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
memberOfBusinesses?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
carts?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Cart"]],
shortcutList?: [{	filter?: ValueTypes["Contember_ApplicationShortcutListWhere"] | undefined | null},ValueTypes["Contember_ApplicationShortcutList"]],
user?: [{	filter?: ValueTypes["Contember_UserWhere"] | undefined | null},ValueTypes["Contember_User"]],
channels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Channel"]],
	disabledAt?:boolean | `@${string}`,
invoices?: [{	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Invoice"]],
invoicingPlan?: [{	filter?: ValueTypes["Contember_InvoicingPlanWhere"] | undefined | null},ValueTypes["Contember_InvoicingPlan"]],
	email?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
billingSubjects?: [{	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BillingSubjectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BillingSubject"]],
offeredProducts?: [{	filter?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCustomerPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCustomerPrice"]],
defaultDeliveryAddress?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
defaultBillingSubject?: [{	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null},ValueTypes["Contember_BillingSubject"]],
	prefersPackingsWithoutCardboard?:boolean | `@${string}`,
anonymousSession?: [{	filter?: ValueTypes["Contember_AnonymousSessionWhere"] | undefined | null},ValueTypes["Contember_AnonymousSession"]],
boughtVouchers?: [{	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Voucher"]],
ownsVouchers?: [{	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Voucher"]],
redeemedVouchers?: [{	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherRedemptionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_VoucherRedemption"]],
spending?: [{	filter?: ValueTypes["Contember_CustomerSpendingWhere"] | undefined | null},ValueTypes["Contember_CustomerSpending"]],
customerTabs?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerTab"]],
lead?: [{	filter?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineLeadOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PipelineLead"]],
	doubleshotLegacyId?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["Contember_CustomerMetadataWhere"] | undefined | null},ValueTypes["Contember_CustomerMetadata"]],
	doubleshotLastUpdatedAt?:boolean | `@${string}`,
addressesByMeta?: [{	by: ValueTypes["Contember_CustomerAddressesByMetaUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByGps?: [{	by: ValueTypes["Contember_CustomerAddressesByGpsUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByInvoices?: [{	by: ValueTypes["Contember_CustomerAddressesByInvoicesUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByBillingAddressOfOrders?: [{	by: ValueTypes["Contember_CustomerAddressesByBillingAddressOfOrdersUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDeliveryAddressOfOrderDeliveries?: [{	by: ValueTypes["Contember_CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDefaultDeliveryAddressOfCustomer?: [{	by: ValueTypes["Contember_CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
creditsByTransactions?: [{	by: ValueTypes["Contember_CustomerCreditsByTransactionsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
creditsByVoucherRedemption?: [{	by: ValueTypes["Contember_CustomerCreditsByVoucherRedemptionUniqueWhere"],	filter?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
ordersBySeq?: [{	by: ValueTypes["Contember_CustomerOrdersBySeqUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleshotLegacyId?: [{	by: ValueTypes["Contember_CustomerOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleShotLegacyNumber?: [{	by: ValueTypes["Contember_CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDiscounts?: [{	by: ValueTypes["Contember_CustomerOrdersByDiscountsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDelivery?: [{	by: ValueTypes["Contember_CustomerOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByItems?: [{	by: ValueTypes["Contember_CustomerOrdersByItemsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByCart?: [{	by: ValueTypes["Contember_CustomerOrdersByCartUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByEvents?: [{	by: ValueTypes["Contember_CustomerOrdersByEventsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPriceLines?: [{	by: ValueTypes["Contember_CustomerOrdersByPriceLinesUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPayments?: [{	by: ValueTypes["Contember_CustomerOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersBySummary?: [{	by: ValueTypes["Contember_CustomerOrdersBySummaryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByGratuity?: [{	by: ValueTypes["Contember_CustomerOrdersByGratuityUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ingredientRatingsByIngredient?: [{	by: ValueTypes["Contember_CustomerIngredientRatingsByIngredientUniqueWhere"],	filter?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["Contember_CustomerIngredientRating"]],
cartsByItems?: [{	by: ValueTypes["Contember_CustomerCartsByItemsUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
cartsByOrder?: [{	by: ValueTypes["Contember_CustomerCartsByOrderUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
cartsByPriceLines?: [{	by: ValueTypes["Contember_CustomerCartsByPriceLinesUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
cartsBySummary?: [{	by: ValueTypes["Contember_CustomerCartsBySummaryUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
invoicesByPublicKey?: [{	by: ValueTypes["Contember_CustomerInvoicesByPublicKeyUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
invoicesByFakturoidId?: [{	by: ValueTypes["Contember_CustomerInvoicesByFakturoidIdUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
invoicesByOrderPayments?: [{	by: ValueTypes["Contember_CustomerInvoicesByOrderPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
billingSubjectsByInvoices?: [{	by: ValueTypes["Contember_CustomerBillingSubjectsByInvoicesUniqueWhere"],	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null},ValueTypes["Contember_BillingSubject"]],
billingSubjectsByDefaultBillingSubjectOfCustomer?: [{	by: ValueTypes["Contember_CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"],	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null},ValueTypes["Contember_BillingSubject"]],
billingSubjectsByOrders?: [{	by: ValueTypes["Contember_CustomerBillingSubjectsByOrdersUniqueWhere"],	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null},ValueTypes["Contember_BillingSubject"]],
offeredProductsByProduct?: [{	by: ValueTypes["Contember_CustomerOfferedProductsByProductUniqueWhere"],	filter?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null},ValueTypes["Contember_ProductCustomerPrice"]],
boughtVouchersByCode?: [{	by: ValueTypes["Contember_CustomerBoughtVouchersByCodeUniqueWhere"],	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null},ValueTypes["Contember_Voucher"]],
boughtVouchersByRedemption?: [{	by: ValueTypes["Contember_CustomerBoughtVouchersByRedemptionUniqueWhere"],	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null},ValueTypes["Contember_Voucher"]],
ownsVouchersByCode?: [{	by: ValueTypes["Contember_CustomerOwnsVouchersByCodeUniqueWhere"],	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null},ValueTypes["Contember_Voucher"]],
ownsVouchersByRedemption?: [{	by: ValueTypes["Contember_CustomerOwnsVouchersByRedemptionUniqueWhere"],	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null},ValueTypes["Contember_Voucher"]],
redeemedVouchersByCustomerCredit?: [{	by: ValueTypes["Contember_CustomerRedeemedVouchersByCustomerCreditUniqueWhere"],	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null},ValueTypes["Contember_VoucherRedemption"]],
redeemedVouchersByOrderDiscount?: [{	by: ValueTypes["Contember_CustomerRedeemedVouchersByOrderDiscountUniqueWhere"],	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null},ValueTypes["Contember_VoucherRedemption"]],
redeemedVouchersByVoucher?: [{	by: ValueTypes["Contember_CustomerRedeemedVouchersByVoucherUniqueWhere"],	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null},ValueTypes["Contember_VoucherRedemption"]],
customerTabsByItems?: [{	by: ValueTypes["Contember_CustomerCustomerTabsByItemsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
customerTabsByMeta?: [{	by: ValueTypes["Contember_CustomerCustomerTabsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
leadByStageItem?: [{	by: ValueTypes["Contember_CustomerLeadByStageItemUniqueWhere"],	filter?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null},ValueTypes["Contember_PipelineLead"]],
paginateAddresses?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
paginateTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
paginateCredits?: [{	filter?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerCreditOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["CustomerCreditConnection"]],
paginatePaymentMethods?: [{	filter?: ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerPaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerPaymentMethodConnection"]],
paginateOrders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateIngredientRatings?: [{	filter?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerIngredientRatingConnection"]],
paginateOwnerOfBusinesses?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessCustomerConnection"]],
paginateMemberOfBusinesses?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessCustomerConnection"]],
paginateCarts?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CartConnection"]],
paginateChannels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ChannelConnection"]],
paginateInvoices?: [{	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_InvoiceConnection"]],
paginateBillingSubjects?: [{	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BillingSubjectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BillingSubjectConnection"]],
paginateOfferedProducts?: [{	filter?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCustomerPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCustomerPriceConnection"]],
paginateBoughtVouchers?: [{	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VoucherConnection"]],
paginateOwnsVouchers?: [{	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VoucherConnection"]],
paginateRedeemedVouchers?: [{	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherRedemptionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VoucherRedemptionConnection"]],
paginateCustomerTabs?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerTabConnection"]],
paginateLead?: [{	filter?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineLeadOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PipelineLeadConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	addresses?:ValueTypes["Contember_FieldMeta"],
	defaultBillingAddress?:ValueTypes["Contember_FieldMeta"],
	defaultPaymentMethod?:ValueTypes["Contember_FieldMeta"],
	tags?:ValueTypes["Contember_FieldMeta"],
	credits?:ValueTypes["Contember_FieldMeta"],
	paymentMethods?:ValueTypes["Contember_FieldMeta"],
	orders?:ValueTypes["Contember_FieldMeta"],
	permissions?:ValueTypes["Contember_FieldMeta"],
	group?:ValueTypes["Contember_FieldMeta"],
	photo?:ValueTypes["Contember_FieldMeta"],
	phone?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	welcomeMessage?:ValueTypes["Contember_FieldMeta"],
	familiarity?:ValueTypes["Contember_FieldMeta"],
	ingredientRatings?:ValueTypes["Contember_FieldMeta"],
	business?:ValueTypes["Contember_FieldMeta"],
	ownerOfBusinesses?:ValueTypes["Contember_FieldMeta"],
	memberOfBusinesses?:ValueTypes["Contember_FieldMeta"],
	carts?:ValueTypes["Contember_FieldMeta"],
	shortcutList?:ValueTypes["Contember_FieldMeta"],
	user?:ValueTypes["Contember_FieldMeta"],
	channels?:ValueTypes["Contember_FieldMeta"],
	disabledAt?:ValueTypes["Contember_FieldMeta"],
	invoices?:ValueTypes["Contember_FieldMeta"],
	invoicingPlan?:ValueTypes["Contember_FieldMeta"],
	email?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	billingSubjects?:ValueTypes["Contember_FieldMeta"],
	offeredProducts?:ValueTypes["Contember_FieldMeta"],
	defaultDeliveryAddress?:ValueTypes["Contember_FieldMeta"],
	defaultBillingSubject?:ValueTypes["Contember_FieldMeta"],
	prefersPackingsWithoutCardboard?:ValueTypes["Contember_FieldMeta"],
	anonymousSession?:ValueTypes["Contember_FieldMeta"],
	boughtVouchers?:ValueTypes["Contember_FieldMeta"],
	ownsVouchers?:ValueTypes["Contember_FieldMeta"],
	redeemedVouchers?:ValueTypes["Contember_FieldMeta"],
	spending?:ValueTypes["Contember_FieldMeta"],
	customerTabs?:ValueTypes["Contember_FieldMeta"],
	lead?:ValueTypes["Contember_FieldMeta"],
	doubleshotLegacyId?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
	doubleshotLastUpdatedAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AddressWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	firstName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	lastName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	addressLine2?: ValueTypes["Contember_StringCondition"] | undefined | null,
	email?: ValueTypes["Contember_StringCondition"] | undefined | null,
	phone?: ValueTypes["Contember_StringCondition"] | undefined | null,
	companyName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	companyIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	vatIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	externalIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	hidden?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	country?: ValueTypes["Contember_CountryWhere"] | undefined | null,
	deletedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	googlePlaceId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	raw?: ValueTypes["Contember_StringCondition"] | undefined | null,
	formatted?: ValueTypes["Contember_StringCondition"] | undefined | null,
	geocodeGeneratedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	geocodeResponse?: ValueTypes["Contember_StringCondition"] | undefined | null,
	deliveryZone?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,
	meta?: ValueTypes["Contember_AddressMetadataWhere"] | undefined | null,
	gps?: ValueTypes["Contember_GpsWhere"] | undefined | null,
	geocodeValid?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	fullName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	invoices?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,
	addressLine1?: ValueTypes["Contember_StringCondition"] | undefined | null,
	city?: ValueTypes["Contember_StringCondition"] | undefined | null,
	postalCode?: ValueTypes["Contember_StringCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	billingAddressOfOrders?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_AddressWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_AddressWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_AddressWhere"] | undefined | null
};
	["Contember_UUIDCondition"]: {
	and?: Array<ValueTypes["Contember_UUIDCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_UUIDCondition"]> | undefined | null,
	not?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["UUID"] | undefined | null,
	notEq?: ValueTypes["UUID"] | undefined | null,
	in?: Array<ValueTypes["UUID"]> | undefined | null,
	notIn?: Array<ValueTypes["UUID"]> | undefined | null,
	lt?: ValueTypes["UUID"] | undefined | null,
	lte?: ValueTypes["UUID"] | undefined | null,
	gt?: ValueTypes["UUID"] | undefined | null,
	gte?: ValueTypes["UUID"] | undefined | null
};
	["Contember_StringCondition"]: {
	and?: Array<ValueTypes["Contember_StringCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_StringCondition"]> | undefined | null,
	not?: ValueTypes["Contember_StringCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: string | undefined | null,
	notEq?: string | undefined | null,
	in?: Array<string> | undefined | null,
	notIn?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	contains?: string | undefined | null,
	startsWith?: string | undefined | null,
	endsWith?: string | undefined | null,
	containsCI?: string | undefined | null,
	startsWithCI?: string | undefined | null,
	endsWithCI?: string | undefined | null
};
	["Contember_BooleanCondition"]: {
	and?: Array<ValueTypes["Contember_BooleanCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_BooleanCondition"]> | undefined | null,
	not?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: boolean | undefined | null,
	notEq?: boolean | undefined | null,
	in?: Array<boolean> | undefined | null,
	notIn?: Array<boolean> | undefined | null,
	lt?: boolean | undefined | null,
	lte?: boolean | undefined | null,
	gt?: boolean | undefined | null,
	gte?: boolean | undefined | null
};
	["Contember_CustomerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	addresses?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null,
	tags?: ValueTypes["Contember_TagWhere"] | undefined | null,
	credits?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null,
	paymentMethods?: ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null,
	orders?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	permissions?: ValueTypes["Contember_CustomerPermissionsWhere"] | undefined | null,
	group?: ValueTypes["Contember_CustomerGroupWhere"] | undefined | null,
	photo?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	phone?: ValueTypes["Contember_StringCondition"] | undefined | null,
	name?: ValueTypes["Contember_CustomerNameWhere"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	welcomeMessage?: ValueTypes["Contember_StringCondition"] | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarityCondition"] | undefined | null,
	ingredientRatings?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null,
	business?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,
	ownerOfBusinesses?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,
	memberOfBusinesses?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,
	carts?: ValueTypes["Contember_CartWhere"] | undefined | null,
	shortcutList?: ValueTypes["Contember_ApplicationShortcutListWhere"] | undefined | null,
	user?: ValueTypes["Contember_UserWhere"] | undefined | null,
	channels?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	disabledAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	invoices?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_InvoicingPlanWhere"] | undefined | null,
	email?: ValueTypes["Contember_StringCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	billingSubjects?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null,
	offeredProducts?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null,
	prefersPackingsWithoutCardboard?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	anonymousSession?: ValueTypes["Contember_AnonymousSessionWhere"] | undefined | null,
	boughtVouchers?: ValueTypes["Contember_VoucherWhere"] | undefined | null,
	ownsVouchers?: ValueTypes["Contember_VoucherWhere"] | undefined | null,
	redeemedVouchers?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null,
	spending?: ValueTypes["Contember_CustomerSpendingWhere"] | undefined | null,
	customerTabs?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,
	lead?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	meta?: ValueTypes["Contember_CustomerMetadataWhere"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerWhere"] | undefined | null
};
	["Contember_CustomerPaymentMethodWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	caption?: ValueTypes["Contember_StringCondition"] | undefined | null,
	data?: ValueTypes["Contember_JsonCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	paymentMethod?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerPaymentMethodWhere"] | undefined | null
};
	["Contember_JsonCondition"]: {
	and?: Array<ValueTypes["Contember_JsonCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_JsonCondition"]> | undefined | null,
	not?: ValueTypes["Contember_JsonCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null
};
	["Contember_PaymentMethodWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	enabled?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	deliveryMethods?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	blockingDispatch?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	type?: ValueTypes["Contember_PaymentMethodTypeCondition"] | undefined | null,
	channels?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,
	reduceGratuityByPercentage?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PaymentMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PaymentMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null
};
	["Contember_DeliveryMethodWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	enabled?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	paymentMethods?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	suitableForDeliveryZones?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,
	channels?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,
	courierHandlers?: ValueTypes["Contember_CourierWhere"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	locales?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryTimelinePresetWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null
};
	["Contember_DeliveryZoneWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneTypeCondition"] | undefined | null,
	addresses?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	suitableDeliveryMethods?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,
	deliveryZonesOfTags?: ValueTypes["Contember_TagWhere"] | undefined | null,
	deliveryZonesOfProducts?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	addressesMetadata?: ValueTypes["Contember_AddressMetadataWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null
};
	["Contember_DeliveryZoneTypeCondition"]: {
	and?: Array<ValueTypes["Contember_DeliveryZoneTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryZoneTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_DeliveryZoneTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	notEq?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	in?: Array<ValueTypes["Contember_DeliveryZoneType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_DeliveryZoneType"]> | undefined | null,
	lt?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	lte?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	gt?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	gte?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null
};
	["Contember_DeliveryZoneType"]:Contember_DeliveryZoneType;
	["Contember_TagWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customers?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	products?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	categories?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	nestedCategories?: ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null,
	deliveryZones?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,
	publicInChannels?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	allowChannelPayments?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TagWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TagWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TagWhere"] | undefined | null
};
	["Contember_ProductWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	priceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	notDiscountable?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	packing?: ValueTypes["Contember_ProductPackingWhere"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null,
	tags?: ValueTypes["Contember_TagWhere"] | undefined | null,
	categories?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	stocks?: ValueTypes["Contember_ProductStockWhere"] | undefined | null,
	groupPrices?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	cartItems?: ValueTypes["Contember_CartItemWhere"] | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailabilityCondition"] | undefined | null,
	order?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	manager?: ValueTypes["Contember_StocksManagerWhere"] | undefined | null,
	orderItems?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["Contember_IntCondition"] | undefined | null,
	inheritedCategories?: ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null,
	deliveryZones?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,
	locales?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null,
	archivedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	customerPrices?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null,
	virtualProduct?: ValueTypes["Contember_VirtualProductWhere"] | undefined | null,
	meta?: ValueTypes["Contember_ProductMetadataWhere"] | undefined | null,
	page?: ValueTypes["Contember_PageWhere"] | undefined | null,
	salesDays?: ValueTypes["Contember_ProductSalesDayWhere"] | undefined | null,
	salesWeeks?: ValueTypes["Contember_ProductSalesWeekWhere"] | undefined | null,
	salesMonths?: ValueTypes["Contember_ProductSalesMonthWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,
	hasSubscription?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	pointOfSaleTileColor?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductWhere"] | undefined | null
};
	["Contember_IntCondition"]: {
	and?: Array<ValueTypes["Contember_IntCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_IntCondition"]> | undefined | null,
	not?: ValueTypes["Contember_IntCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["Contember_ProductPackingWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	volumeMl?: ValueTypes["Contember_IntCondition"] | undefined | null,
	icon?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	shortName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	locales?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null,
	preparedPackingQuantities?: ValueTypes["Contember_PreparedPackingQuantityWhere"] | undefined | null,
	isPackedInCardboard?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductPackingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductPackingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductPackingWhere"] | undefined | null
};
	["Contember_ImageWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	url?: ValueTypes["Contember_StringCondition"] | undefined | null,
	width?: ValueTypes["Contember_IntCondition"] | undefined | null,
	height?: ValueTypes["Contember_IntCondition"] | undefined | null,
	size?: ValueTypes["Contember_IntCondition"] | undefined | null,
	type?: ValueTypes["Contember_StringCondition"] | undefined | null,
	alt?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ImageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ImageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ImageWhere"] | undefined | null
};
	["Contember_ProductPackingLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	shortName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_ProductPackingWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null
};
	["Contember_LocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	businessCategories?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null,
	productIngredients?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null,
	blogs?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null,
	blogPosts?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined | null,
	menus?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null,
	pages?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null,
	productIngredientCategories?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null,
	allergens?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null,
	productPackings?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null,
	products?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null,
	productRecipes?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null,
	pointOfSaleCategories?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null,
	pointOfSaleCategoryProducts?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null,
	ingredientSuppliers?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null,
	identificationCodes?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null,
	pipelineStages?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null,
	subscriptionBoxes?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null,
	businessServices?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LocaleWhere"] | undefined | null
};
	["Contember_BusinessCategoryLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null
};
	["Contember_BusinessCategoryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	locales?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	businessCustomers?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,
	recurrences?: ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null,
	specificProducts?: ValueTypes["Contember_ProductListWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null,
	slug?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null
};
	["Contember_BusinessCustomerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	owners?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	members?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	category?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessToBusinessManagerWhere"] | undefined | null,
	logo?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	businessServiceEvents?: ValueTypes["Contember_BusinessServiceEventWhere"] | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null
};
	["Contember_BusinessToBusinessManagerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	managedBusinesses?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_BusinessToBusinessManagerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BusinessToBusinessManagerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BusinessToBusinessManagerWhere"] | undefined | null
};
	["Contember_StaffWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	bartender?: ValueTypes["Contember_BartenderWhere"] | undefined | null,
	courier?: ValueTypes["Contember_CourierWhere"] | undefined | null,
	cleaner?: ValueTypes["Contember_CleanerWhere"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_BusinessToBusinessManagerWhere"] | undefined | null,
	user?: ValueTypes["Contember_UserWhere"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StocksManagerWhere"] | undefined | null,
	photo?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	courierManager?: ValueTypes["Contember_CourierManagerWhere"] | undefined | null,
	picker?: ValueTypes["Contember_PickerWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null,
	cashier?: ValueTypes["Contember_CashierWhere"] | undefined | null,
	venues?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	internalBuyer?: ValueTypes["Contember_InternalBuyerWhere"] | undefined | null,
	salesManager?: ValueTypes["Contember_SalesManagerWhere"] | undefined | null,
	cashierManager?: ValueTypes["Contember_CashierManagerWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_StaffWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_StaffWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_StaffWhere"] | undefined | null
};
	["Contember_BartenderWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_BartenderWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BartenderWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BartenderWhere"] | undefined | null
};
	["Contember_DateTimeCondition"]: {
	and?: Array<ValueTypes["Contember_DateTimeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_DateTimeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["DateTime"] | undefined | null,
	notEq?: ValueTypes["DateTime"] | undefined | null,
	in?: Array<ValueTypes["DateTime"]> | undefined | null,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null,
	lt?: ValueTypes["DateTime"] | undefined | null,
	lte?: ValueTypes["DateTime"] | undefined | null,
	gt?: ValueTypes["DateTime"] | undefined | null,
	gte?: ValueTypes["DateTime"] | undefined | null
};
	["Contember_CourierWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	routes?: ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	deliveryMethods?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,
	showInPicker?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CourierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CourierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CourierWhere"] | undefined | null
};
	["Contember_DeliveryRouteWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	courier?: ValueTypes["Contember_CourierWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	waypoints?: ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null,
	dispatchAt?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null
};
	["Contember_DeliveryWaypointWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	estimatedArrival?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	route?: ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null,
	sortingOrder?: ValueTypes["Contember_IntCondition"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,
	estimatedDistance?: ValueTypes["Contember_IntCondition"] | undefined | null,
	estimatedDuration?: ValueTypes["Contember_IntCondition"] | undefined | null,
	originDeparture?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	originAddress?: ValueTypes["Contember_StringCondition"] | undefined | null,
	transitMode?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null
};
	["Contember_OrderDeliveryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	priceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	deliveredAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	method?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	expectedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryStateCondition"] | undefined | null,
	loadingDurationHours?: ValueTypes["Contember_IntCondition"] | undefined | null,
	dispatchAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	duration?: ValueTypes["Contember_StringCondition"] | undefined | null,
	window?: ValueTypes["Contember_StringCondition"] | undefined | null,
	expectedEndAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	processingAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	deliveringAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	failedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null,
	events?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,
	prepareAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_LiftagoRideWhere"] | undefined | null,
	pickedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	address?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	trackingCode?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null
};
	["Contember_VatRateWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ratePermille?: ValueTypes["Contember_IntCondition"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	channelDeliveries?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,
	channelPayments?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,
	preset?: ValueTypes["Contember_VatRatePresetCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_VatRateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VatRateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VatRateWhere"] | undefined | null
};
	["Contember_ChannelDeliveryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	priceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	method?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	defaultVisible?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null
};
	["Contember_ChannelWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	disabled?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	payments?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,
	deliveries?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,
	pointOfSales?: ValueTypes["Contember_PointOfSaleWhere"] | undefined | null,
	carts?: ValueTypes["Contember_CartWhere"] | undefined | null,
	customers?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	code?: ValueTypes["Contember_ChannelCodeCondition"] | undefined | null,
	addTagsOnRegistration?: ValueTypes["Contember_TagWhere"] | undefined | null,
	publicTags?: ValueTypes["Contember_TagWhere"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ChannelWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ChannelWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ChannelWhere"] | undefined | null
};
	["Contember_ChannelPaymentWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	method?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	requireBillingAddress?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	provideReceipt?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	requireBillingSubject?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	allowOnlyForTags?: ValueTypes["Contember_TagWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null
};
	["Contember_PointOfSaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	channels?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	permissions?: ValueTypes["Contember_PointOfSalePermissionsWhere"] | undefined | null,
	tenantPersonId?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleWhere"] | undefined | null
};
	["Contember_PointOfSalePermissionsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	canPlaceOrder?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	pointOfSale?: ValueTypes["Contember_PointOfSaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSalePermissionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSalePermissionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSalePermissionsWhere"] | undefined | null
};
	["Contember_CartWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	confirmedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	deletedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	items?: ValueTypes["Contember_CartItemWhere"] | undefined | null,
	handle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	state?: ValueTypes["Contember_CartStateCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	billingAddress?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	deliveryOption?: ValueTypes["Contember_StringCondition"] | undefined | null,
	paymentOption?: ValueTypes["Contember_StringCondition"] | undefined | null,
	delivery?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,
	payment?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,
	billingSubject?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null,
	priceLines?: ValueTypes["Contember_CartPriceLineWhere"] | undefined | null,
	summary?: ValueTypes["Contember_CartSummaryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CartWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CartWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CartWhere"] | undefined | null
};
	["Contember_CartItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	quantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	cart?: ValueTypes["Contember_CartWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	updatedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	fulfillmentNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CartItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CartItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CartItemWhere"] | undefined | null
};
	["Contember_FulfillmentNoteWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	products?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	tags?: ValueTypes["Contember_TagWhere"] | undefined | null,
	venues?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	cartItems?: ValueTypes["Contember_CartItemWhere"] | undefined | null,
	orderItems?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,
	orders?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	highlight?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	emoji?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null
};
	["Contember_VenueWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_DeadlineTemplateWhere"] | undefined | null,
	timetableDays?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,
	staffMembers?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	employeeRoles?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null,
	pointOfSaleApplicationDevices?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,
	pointOfSaleTerminals?: ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null,
	pointOfSalePrinters?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,
	orders?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	fulfillmentNotes?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,
	areas?: ValueTypes["Contember_AreaWhere"] | undefined | null,
	enabledUnassignReasons?: ValueTypes["Contember_UnassignReasonWhere"] | undefined | null,
	categories?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,
	channels?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	address?: ValueTypes["Contember_StringCondition"] | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: ValueTypes["Contember_StringCondition"] | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialViewCondition"] | undefined | null,
	pointOfSalePreferPrintReceipt?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_VenueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VenueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VenueWhere"] | undefined | null
};
	["Contember_DeadlineTemplateWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	startDate?: ValueTypes["Contember_DateCondition"] | undefined | null,
	period?: ValueTypes["Contember_IntCondition"] | undefined | null,
	cutoff?: ValueTypes["Contember_IntCondition"] | undefined | null,
	closed?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeadlineTemplateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeadlineTemplateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeadlineTemplateWhere"] | undefined | null
};
	["Contember_DateCondition"]: {
	and?: Array<ValueTypes["Contember_DateCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_DateCondition"]> | undefined | null,
	not?: ValueTypes["Contember_DateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Date"] | undefined | null,
	notEq?: ValueTypes["Date"] | undefined | null,
	in?: Array<ValueTypes["Date"]> | undefined | null,
	notIn?: Array<ValueTypes["Date"]> | undefined | null,
	lt?: ValueTypes["Date"] | undefined | null,
	lte?: ValueTypes["Date"] | undefined | null,
	gt?: ValueTypes["Date"] | undefined | null,
	gte?: ValueTypes["Date"] | undefined | null
};
	["Contember_TimetableDayWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	date?: ValueTypes["Contember_DateCondition"] | undefined | null,
	type?: ValueTypes["Contember_TimetableDayTypeCondition"] | undefined | null,
	shiftGroups?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,
	tipsCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	notes?: ValueTypes["Contember_TimetableDayNoteWhere"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_TimetableDayMetadataWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableDayWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableDayWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null
};
	["Contember_TimetableDayTypeCondition"]: {
	and?: Array<ValueTypes["Contember_TimetableDayTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableDayTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_TimetableDayTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	notEq?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	in?: Array<ValueTypes["Contember_TimetableDayType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_TimetableDayType"]> | undefined | null,
	lt?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	lte?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	gt?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	gte?: ValueTypes["Contember_TimetableDayType"] | undefined | null
};
	["Contember_TimetableDayType"]:Contember_TimetableDayType;
	["Contember_TimetableShiftGroupWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	shifts?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,
	day?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null,
	type?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftPositionWhere"] | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueWhere"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null
};
	["Contember_TimetableShiftWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	locked?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	assigned?: ValueTypes["Contember_DateCondition"] | undefined | null,
	group?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined | null,
	isOvertime?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableShiftWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableShiftWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null
};
	["Contember_ShiftsProfileWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	queueItems?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	roles?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,
	shifts?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,
	allowSelfUnassigning?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	start?: ValueTypes["Contember_StringCondition"] | undefined | null,
	end?: ValueTypes["Contember_StringCondition"] | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueWhere"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,
	shift?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_UnassignedShiftInfoWhere"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null
};
	["Contember_EmployeeQueueWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	shiftGroup?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,
	items?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,
	contemberBugFix?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_EmployeeQueueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_EmployeeQueueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_EmployeeQueueWhere"] | undefined | null
};
	["Contember_UnassignedShiftInfoWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	hoursMissed?: ValueTypes["Contember_IntCondition"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,
	legacyReason?: ValueTypes["Contember_UnassignedInfoReasonCondition"] | undefined | null,
	reason?: ValueTypes["Contember_UnassignReasonWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_UnassignedShiftInfoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_UnassignedShiftInfoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_UnassignedShiftInfoWhere"] | undefined | null
};
	["Contember_UnassignedInfoReasonCondition"]: {
	and?: Array<ValueTypes["Contember_UnassignedInfoReasonCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_UnassignedInfoReasonCondition"]> | undefined | null,
	not?: ValueTypes["Contember_UnassignedInfoReasonCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null,
	notEq?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null,
	in?: Array<ValueTypes["Contember_UnassignedInfoReason"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_UnassignedInfoReason"]> | undefined | null,
	lt?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null,
	lte?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null,
	gt?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null,
	gte?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null
};
	["Contember_UnassignedInfoReason"]:Contember_UnassignedInfoReason;
	["Contember_UnassignReasonWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	venues?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_UnassignReasonWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_UnassignReasonWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_UnassignReasonWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	totalMinutes?: ValueTypes["Contember_IntCondition"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,
	includeInTipsCalculation?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined | null
};
	["Contember_TimetableEmployeeRoleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	shiftGroups?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,
	shiftsProfiles?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,
	shiftsManagers?: ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	timetableTemplates?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,
	color?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null
};
	["Contember_ShiftsManagerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	managedRoles?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null
};
	["Contember_TimetableTemplateWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	startDate?: ValueTypes["Contember_DateCondition"] | undefined | null,
	period?: ValueTypes["Contember_IntCondition"] | undefined | null,
	slots?: ValueTypes["Contember_IntCondition"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftPositionWhere"] | undefined | null,
	staffOptions?: ValueTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,
	workdaysOnly?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	monday?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	tuesday?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	wednesday?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	thursday?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	friday?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	saturday?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	sunday?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	type?: ValueTypes["Contember_TemplateTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null
};
	["Contember_TimetableShiftPositionWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	start?: ValueTypes["Contember_StringCondition"] | undefined | null,
	end?: ValueTypes["Contember_StringCondition"] | undefined | null,
	shiftGroups?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,
	isDynamic?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableShiftPositionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableShiftPositionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableShiftPositionWhere"] | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	startTime?: ValueTypes["Contember_StringCondition"] | undefined | null,
	endTime?: ValueTypes["Contember_StringCondition"] | undefined | null,
	timetableTemplate?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,
	autoAssignedEmployee?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined | null
};
	["Contember_TemplateTypeCondition"]: {
	and?: Array<ValueTypes["Contember_TemplateTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_TemplateTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_TemplateTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_TemplateType"] | undefined | null,
	notEq?: ValueTypes["Contember_TemplateType"] | undefined | null,
	in?: Array<ValueTypes["Contember_TemplateType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_TemplateType"]> | undefined | null,
	lt?: ValueTypes["Contember_TemplateType"] | undefined | null,
	lte?: ValueTypes["Contember_TemplateType"] | undefined | null,
	gt?: ValueTypes["Contember_TemplateType"] | undefined | null,
	gte?: ValueTypes["Contember_TemplateType"] | undefined | null
};
	["Contember_TemplateType"]:Contember_TemplateType;
	["Contember_TimetableShiftSwapInfoWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	assigned?: ValueTypes["Contember_DateCondition"] | undefined | null,
	requested?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	confirmed?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	swappedShiftsProfile?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,
	shift?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined | null
};
	["Contember_TimetableDayNoteWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	day?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null,
	role?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableDayNoteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableDayNoteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableDayNoteWhere"] | undefined | null
};
	["Contember_TimetableDayMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	numberOfWorkers?: ValueTypes["Contember_IntCondition"] | undefined | null,
	tipsTotalCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	totalMinutes?: ValueTypes["Contember_IntCondition"] | undefined | null,
	tipsCentsPerMinute?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	day?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetableDayMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetableDayMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetableDayMetadataWhere"] | undefined | null
};
	["Contember_FloatCondition"]: {
	and?: Array<ValueTypes["Contember_FloatCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_FloatCondition"]> | undefined | null,
	not?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: number | undefined | null,
	notEq?: number | undefined | null,
	in?: Array<number> | undefined | null,
	notIn?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null
};
	["Contember_VenueEmployeeRoleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	employeePrivileges?: ValueTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	role?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,
	getsTipsShare?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	notesEnabled?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null
};
	["Contember_TimetablesEmployeePrivilegesWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	venueEmployeeRole?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null,
	read?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	unassign?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	assign?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	identificationCode?: ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null,
	identifier?: ValueTypes["Contember_DeviceIdentifierWhere"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,
	ticketPrinters?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null
};
	["Contember_IdentificationCodeWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	attempts?: ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null,
	locales?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	applicationDevices?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null
};
	["Contember_IdentificationAttemptWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	code?: ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null,
	user?: ValueTypes["Contember_UserWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null
};
	["Contember_UserWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	tenantPersonId?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	disabledAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	devices?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null,
	verifiedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	email?: ValueTypes["Contember_StringCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	deletionRequestedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	deletionExpectedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	deletedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	meta?: ValueTypes["Contember_UserMetadataWhere"] | undefined | null,
	identificationAttempts?: ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null,
	roles?: ValueTypes["Contember_UserRolesWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_UserWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_UserWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_UserWhere"] | undefined | null
};
	["Contember_UserDeviceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	lastActiveAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	platform?: ValueTypes["Contember_StringCondition"] | undefined | null,
	deviceId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	notificationsEnabled?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	user?: ValueTypes["Contember_UserWhere"] | undefined | null,
	appVersion?: ValueTypes["Contember_StringCondition"] | undefined | null,
	osVersion?: ValueTypes["Contember_StringCondition"] | undefined | null,
	apiVersion?: ValueTypes["Contember_StringCondition"] | undefined | null,
	anonymousSession?: ValueTypes["Contember_AnonymousSessionWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_UserDeviceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_UserDeviceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null
};
	["Contember_AnonymousSessionWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	lastActiveAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	anonymousSessionKey?: ValueTypes["Contember_StringCondition"] | undefined | null,
	email?: ValueTypes["Contember_StringCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	devices?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_AnonymousSessionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_AnonymousSessionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_AnonymousSessionWhere"] | undefined | null
};
	["Contember_UserMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	userProfileUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	tenantEmail?: ValueTypes["Contember_StringCondition"] | undefined | null,
	tenantPersonId?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	tenantIdentityId?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	globalRoles?: ValueTypes["Contember_JsonCondition"] | undefined | null,
	projectRoles?: ValueTypes["Contember_JsonCondition"] | undefined | null,
	projectRolesString?: ValueTypes["Contember_StringCondition"] | undefined | null,
	externalIdentifiers?: ValueTypes["Contember_JsonCondition"] | undefined | null,
	externalIdentifiersString?: ValueTypes["Contember_StringCondition"] | undefined | null,
	idpSlugs?: ValueTypes["Contember_JsonCondition"] | undefined | null,
	idpSlugsString?: ValueTypes["Contember_StringCondition"] | undefined | null,
	user?: ValueTypes["Contember_UserWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_UserMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_UserMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_UserMetadataWhere"] | undefined | null
};
	["Contember_UserRolesWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	isCustomer?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isStaff?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isBartender?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isCashier?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isCourier?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isCleaner?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isShiftsManager?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isInternalBuyer?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isStocksManager?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isCourierManager?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isCashierManager?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isBusinessToBusinessManager?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	isPicker?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	user?: ValueTypes["Contember_UserWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_UserRolesWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_UserRolesWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_UserRolesWhere"] | undefined | null
};
	["Contember_IdentificationCodeLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	successMessage?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null
};
	["Contember_DeviceIdentifierWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	device?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,
	updatedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	platform?: ValueTypes["Contember_StringCondition"] | undefined | null,
	resolution?: ValueTypes["Contember_StringCondition"] | undefined | null,
	devicePixelRatio?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	updatedByUser?: ValueTypes["Contember_UserWhere"] | undefined | null,
	identifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	meta?: ValueTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeviceIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeviceIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeviceIdentifierWhere"] | undefined | null
};
	["Contember_DeviceIdentifierMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	prettyIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	deviceIdentifier?: ValueTypes["Contember_DeviceIdentifierWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined | null
};
	["Contember_PointOfSaleTerminalWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	applicationDevices?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,
	ipAddress?: ValueTypes["Contember_StringCondition"] | undefined | null,
	port?: ValueTypes["Contember_IntCondition"] | undefined | null,
	type?: ValueTypes["Contember_PointOfSaleTerminalTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null
};
	["Contember_PointOfSaleTerminalTypeCondition"]: {
	and?: Array<ValueTypes["Contember_PointOfSaleTerminalTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleTerminalTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleTerminalTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	notEq?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	in?: Array<ValueTypes["Contember_PointOfSaleTerminalType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_PointOfSaleTerminalType"]> | undefined | null,
	lt?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	lte?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	gt?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	gte?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null
};
	["Contember_PointOfSaleTerminalType"]:Contember_PointOfSaleTerminalType;
	["Contember_PointOfSalePrinterWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ipAddress?: ValueTypes["Contember_StringCondition"] | undefined | null,
	applicationDevicesReceipt?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,
	applicationDevicesTicket?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	ticketCategories?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	slug?: ValueTypes["Contember_StringCondition"] | undefined | null,
	locales?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null,
	products?: ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null,
	category?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	ticketPrinters?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	locales?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null
};
	["Contember_ProductCategoryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	products?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	tags?: ValueTypes["Contember_TagWhere"] | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	children?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	color?: ValueTypes["Contember_StringCondition"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	order?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	descendants?: ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null,
	ancestors?: ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null,
	inheritedTags?: ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null,
	nestedProducts?: ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null
};
	["Contember_ProductCategoryTreeWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	depth?: ValueTypes["Contember_IntCondition"] | undefined | null,
	ancestor?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	descendant?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null
};
	["Contember_InheritedProductCategoryTagWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	depth?: ValueTypes["Contember_IntCondition"] | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	tag?: ValueTypes["Contember_TagWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null
};
	["Contember_NestedProductCategoryProductWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	depth?: ValueTypes["Contember_IntCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null
};
	["Contember_OrderWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	state?: ValueTypes["Contember_OrderStateCondition"] | undefined | null,
	fulfilledAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	canceledAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelWhere"] | undefined | null,
	discounts?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,
	items?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	cart?: ValueTypes["Contember_CartWhere"] | undefined | null,
	events?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	billingAddress?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	priceLines?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null,
	payments?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,
	seq?: ValueTypes["Contember_IntCondition"] | undefined | null,
	billingSubject?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null,
	summary?: ValueTypes["Contember_OrderSummaryWhere"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	gratuity?: ValueTypes["Contember_GratuityWhere"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	fulfillmentNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,
	doubleshotTotalWeightGrams?: ValueTypes["Contember_IntCondition"] | undefined | null,
	doubleShotLegacyNumber?: ValueTypes["Contember_StringCondition"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	doubleshotAdminUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderWhere"] | undefined | null
};
	["Contember_OrderStateCondition"]: {
	and?: Array<ValueTypes["Contember_OrderStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderStateCondition"]> | undefined | null,
	not?: ValueTypes["Contember_OrderStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_OrderState"] | undefined | null,
	notEq?: ValueTypes["Contember_OrderState"] | undefined | null,
	in?: Array<ValueTypes["Contember_OrderState"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_OrderState"]> | undefined | null,
	lt?: ValueTypes["Contember_OrderState"] | undefined | null,
	lte?: ValueTypes["Contember_OrderState"] | undefined | null,
	gt?: ValueTypes["Contember_OrderState"] | undefined | null,
	gte?: ValueTypes["Contember_OrderState"] | undefined | null
};
	["Contember_OrderState"]:Contember_OrderState;
	["Contember_OrderDiscountWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	discountCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	type?: ValueTypes["Contember_OrderDiscountTypeCondition"] | undefined | null,
	customerCreditTransactions?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderDiscountWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderDiscountWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null
};
	["Contember_OrderDiscountTypeCondition"]: {
	and?: Array<ValueTypes["Contember_OrderDiscountTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderDiscountTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_OrderDiscountTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	notEq?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	in?: Array<ValueTypes["Contember_OrderDiscountType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_OrderDiscountType"]> | undefined | null,
	lt?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	lte?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	gt?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	gte?: ValueTypes["Contember_OrderDiscountType"] | undefined | null
};
	["Contember_OrderDiscountType"]:Contember_OrderDiscountType;
	["Contember_CustomerCreditTransactionWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	amountCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null
};
	["Contember_CustomerCreditWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	initialCreditCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	remainingCreditsCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	expiresAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	reason?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	transactions?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null,
	invoiceUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerCreditWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerCreditWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null
};
	["Contember_VoucherRedemptionWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	manualRedemptionNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null
};
	["Contember_VoucherWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	activatedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	expiresAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	type?: ValueTypes["Contember_VoucherTypeCondition"] | undefined | null,
	creditCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	buyer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	owner?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_VoucherWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VoucherWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VoucherWhere"] | undefined | null
};
	["Contember_VoucherTypeCondition"]: {
	and?: Array<ValueTypes["Contember_VoucherTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_VoucherTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_VoucherTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_VoucherType"] | undefined | null,
	notEq?: ValueTypes["Contember_VoucherType"] | undefined | null,
	in?: Array<ValueTypes["Contember_VoucherType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_VoucherType"]> | undefined | null,
	lt?: ValueTypes["Contember_VoucherType"] | undefined | null,
	lte?: ValueTypes["Contember_VoucherType"] | undefined | null,
	gt?: ValueTypes["Contember_VoucherType"] | undefined | null,
	gte?: ValueTypes["Contember_VoucherType"] | undefined | null
};
	["Contember_VoucherType"]:Contember_VoucherType;
	["Contember_VirtualProductEffectWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	succeededAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	orderItem?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,
	failedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	revertedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	resultCustomerCreditTransaction?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null,
	resultVouchers?: ValueTypes["Contember_VoucherWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null
};
	["Contember_OrderItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	unitPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	quantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	virtualProductEffects?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null,
	pickedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fulfillmentNotes?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderItemWhere"] | undefined | null
};
	["Contember_OrderEventWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_StringCondition"] | undefined | null,
	data?: ValueTypes["Contember_StringCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	payment?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderEventWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderEventWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderEventWhere"] | undefined | null
};
	["Contember_OrderPaymentWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	data?: ValueTypes["Contember_JsonCondition"] | undefined | null,
	paymentCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	method?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null,
	failedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	state?: ValueTypes["Contember_OrderPaymentStateCondition"] | undefined | null,
	approvedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	events?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	invoice?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,
	walletType?: ValueTypes["Contember_StringCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	publicReceiptUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	priceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentMetadataWhere"] | undefined | null,
	otherCurrencyPriceCents?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderPaymentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderPaymentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null
};
	["Contember_OrderPaymentStateCondition"]: {
	and?: Array<ValueTypes["Contember_OrderPaymentStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderPaymentStateCondition"]> | undefined | null,
	not?: ValueTypes["Contember_OrderPaymentStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	notEq?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	in?: Array<ValueTypes["Contember_OrderPaymentState"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_OrderPaymentState"]> | undefined | null,
	lt?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	lte?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	gt?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	gte?: ValueTypes["Contember_OrderPaymentState"] | undefined | null
};
	["Contember_OrderPaymentState"]:Contember_OrderPaymentState;
	["Contember_InvoiceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	publicKey?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fakturoidId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fakturoidData?: ValueTypes["Contember_StringCondition"] | undefined | null,
	legacyUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	orderPayments?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,
	address?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	publicUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	billingSubject?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_InvoiceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_InvoiceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_InvoiceWhere"] | undefined | null
};
	["Contember_BillingSubjectWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fullName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	addressLine1?: ValueTypes["Contember_StringCondition"] | undefined | null,
	addressLine2?: ValueTypes["Contember_StringCondition"] | undefined | null,
	city?: ValueTypes["Contember_StringCondition"] | undefined | null,
	postalCode?: ValueTypes["Contember_StringCondition"] | undefined | null,
	email?: ValueTypes["Contember_StringCondition"] | undefined | null,
	phone?: ValueTypes["Contember_StringCondition"] | undefined | null,
	companyName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	companyIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	vatIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	externalIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	hidden?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	deletedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	country?: ValueTypes["Contember_CountryWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	invoices?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	orders?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BillingSubjectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BillingSubjectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null
};
	["Contember_CountryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CountryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CountryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CountryWhere"] | undefined | null
};
	["Contember_OrderPaymentMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	paymentUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	orderPayment?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderPaymentMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderPaymentMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderPaymentMetadataWhere"] | undefined | null
};
	["Contember_OrderPriceLineWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	unitPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	quantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	type?: ValueTypes["Contember_OrderPriceLineTypeCondition"] | undefined | null,
	orderItem?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null,
	orderPayment?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,
	orderDelivery?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,
	vatRatePermille?: ValueTypes["Contember_IntCondition"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderPriceLineWhere"] | undefined | null
};
	["Contember_OrderPriceLineTypeCondition"]: {
	and?: Array<ValueTypes["Contember_OrderPriceLineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderPriceLineTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_OrderPriceLineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_OrderPriceLineType"] | undefined | null,
	notEq?: ValueTypes["Contember_OrderPriceLineType"] | undefined | null,
	in?: Array<ValueTypes["Contember_OrderPriceLineType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_OrderPriceLineType"]> | undefined | null,
	lt?: ValueTypes["Contember_OrderPriceLineType"] | undefined | null,
	lte?: ValueTypes["Contember_OrderPriceLineType"] | undefined | null,
	gt?: ValueTypes["Contember_OrderPriceLineType"] | undefined | null,
	gte?: ValueTypes["Contember_OrderPriceLineType"] | undefined | null
};
	["Contember_OrderPriceLineType"]:Contember_OrderPriceLineType;
	["Contember_OrderSummaryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	totalPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	discountPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	undiscountedPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	itemsQuantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	orderUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	orderNumber?: ValueTypes["Contember_StringCondition"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderSummaryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderSummaryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderSummaryWhere"] | undefined | null
};
	["Contember_GratuityWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	amountCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	order?: ValueTypes["Contember_OrderWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_GratuityWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_GratuityWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_GratuityWhere"] | undefined | null
};
	["Contember_AreaWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	venue?: ValueTypes["Contember_VenueWhere"] | undefined | null,
	tables?: ValueTypes["Contember_TableWhere"] | undefined | null,
	lines?: ValueTypes["Contember_LineWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_AreaWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_AreaWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_AreaWhere"] | undefined | null
};
	["Contember_TableWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	shape?: ValueTypes["Contember_TableShapeCondition"] | undefined | null,
	width?: ValueTypes["Contember_IntCondition"] | undefined | null,
	height?: ValueTypes["Contember_IntCondition"] | undefined | null,
	positionX?: ValueTypes["Contember_IntCondition"] | undefined | null,
	positionY?: ValueTypes["Contember_IntCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	color?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customerTabs?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,
	area?: ValueTypes["Contember_AreaWhere"] | undefined | null,
	seats?: ValueTypes["Contember_SeatWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TableWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TableWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TableWhere"] | undefined | null
};
	["Contember_TableShapeCondition"]: {
	and?: Array<ValueTypes["Contember_TableShapeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_TableShapeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_TableShapeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_TableShape"] | undefined | null,
	notEq?: ValueTypes["Contember_TableShape"] | undefined | null,
	in?: Array<ValueTypes["Contember_TableShape"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_TableShape"]> | undefined | null,
	lt?: ValueTypes["Contember_TableShape"] | undefined | null,
	lte?: ValueTypes["Contember_TableShape"] | undefined | null,
	gt?: ValueTypes["Contember_TableShape"] | undefined | null,
	gte?: ValueTypes["Contember_TableShape"] | undefined | null
};
	["Contember_TableShape"]:Contember_TableShape;
	["Contember_CustomerTabWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	color?: ValueTypes["Contember_StringCondition"] | undefined | null,
	items?: ValueTypes["Contember_CustomerTabItemWhere"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabMetadataWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	table?: ValueTypes["Contember_TableWhere"] | undefined | null,
	seat?: ValueTypes["Contember_SeatWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerTabWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerTabWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null
};
	["Contember_CustomerTabItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	addedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	tab?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	paidAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerTabItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerTabItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerTabItemWhere"] | undefined | null
};
	["Contember_CustomerTabMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	totalPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	totalSpentCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	unpaidCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	customerTab?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerTabMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerTabMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerTabMetadataWhere"] | undefined | null
};
	["Contember_SeatWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	positionX?: ValueTypes["Contember_IntCondition"] | undefined | null,
	positionY?: ValueTypes["Contember_IntCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	color?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customerTabs?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,
	table?: ValueTypes["Contember_TableWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_SeatWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_SeatWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_SeatWhere"] | undefined | null
};
	["Contember_LineWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	positionStartX?: ValueTypes["Contember_IntCondition"] | undefined | null,
	positionStartY?: ValueTypes["Contember_IntCondition"] | undefined | null,
	positionEndX?: ValueTypes["Contember_IntCondition"] | undefined | null,
	positionEndY?: ValueTypes["Contember_IntCondition"] | undefined | null,
	area?: ValueTypes["Contember_AreaWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LineWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LineWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LineWhere"] | undefined | null
};
	["Contember_PointOfSaleInitialViewCondition"]: {
	and?: Array<ValueTypes["Contember_PointOfSaleInitialViewCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_PointOfSaleInitialViewCondition"]> | undefined | null,
	not?: ValueTypes["Contember_PointOfSaleInitialViewCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	notEq?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	in?: Array<ValueTypes["Contember_PointOfSaleInitialView"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_PointOfSaleInitialView"]> | undefined | null,
	lt?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	lte?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	gt?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	gte?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null
};
	["Contember_PointOfSaleInitialView"]:Contember_PointOfSaleInitialView;
	["Contember_CartStateCondition"]: {
	and?: Array<ValueTypes["Contember_CartStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_CartStateCondition"]> | undefined | null,
	not?: ValueTypes["Contember_CartStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_CartState"] | undefined | null,
	notEq?: ValueTypes["Contember_CartState"] | undefined | null,
	in?: Array<ValueTypes["Contember_CartState"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_CartState"]> | undefined | null,
	lt?: ValueTypes["Contember_CartState"] | undefined | null,
	lte?: ValueTypes["Contember_CartState"] | undefined | null,
	gt?: ValueTypes["Contember_CartState"] | undefined | null,
	gte?: ValueTypes["Contember_CartState"] | undefined | null
};
	["Contember_CartState"]:Contember_CartState;
	["Contember_CartPriceLineWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_CartPriceLineTypeCondition"] | undefined | null,
	item?: ValueTypes["Contember_CartItemWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	unitPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	priceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	quantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	vatRatePermille?: ValueTypes["Contember_IntCondition"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	cart?: ValueTypes["Contember_CartWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CartPriceLineWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CartPriceLineWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CartPriceLineWhere"] | undefined | null
};
	["Contember_CartPriceLineTypeCondition"]: {
	and?: Array<ValueTypes["Contember_CartPriceLineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_CartPriceLineTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_CartPriceLineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_CartPriceLineType"] | undefined | null,
	notEq?: ValueTypes["Contember_CartPriceLineType"] | undefined | null,
	in?: Array<ValueTypes["Contember_CartPriceLineType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_CartPriceLineType"]> | undefined | null,
	lt?: ValueTypes["Contember_CartPriceLineType"] | undefined | null,
	lte?: ValueTypes["Contember_CartPriceLineType"] | undefined | null,
	gt?: ValueTypes["Contember_CartPriceLineType"] | undefined | null,
	gte?: ValueTypes["Contember_CartPriceLineType"] | undefined | null
};
	["Contember_CartPriceLineType"]:Contember_CartPriceLineType;
	["Contember_CartSummaryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	itemsPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	deliveryPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	totalPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	itemsCount?: ValueTypes["Contember_IntCondition"] | undefined | null,
	itemsQuantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	checkoutUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	cart?: ValueTypes["Contember_CartWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CartSummaryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CartSummaryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CartSummaryWhere"] | undefined | null
};
	["Contember_ChannelCodeCondition"]: {
	and?: Array<ValueTypes["Contember_ChannelCodeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_ChannelCodeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_ChannelCodeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	notEq?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	in?: Array<ValueTypes["Contember_ChannelCode"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_ChannelCode"]> | undefined | null,
	lt?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	lte?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	gt?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	gte?: ValueTypes["Contember_ChannelCode"] | undefined | null
};
	["Contember_ChannelCode"]:Contember_ChannelCode;
	["Contember_VatRatePresetCondition"]: {
	and?: Array<ValueTypes["Contember_VatRatePresetCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_VatRatePresetCondition"]> | undefined | null,
	not?: ValueTypes["Contember_VatRatePresetCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	notEq?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	in?: Array<ValueTypes["Contember_VatRatePreset"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_VatRatePreset"]> | undefined | null,
	lt?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	lte?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	gt?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	gte?: ValueTypes["Contember_VatRatePreset"] | undefined | null
};
	["Contember_VatRatePreset"]:Contember_VatRatePreset;
	["Contember_OrderDeliveryStateCondition"]: {
	and?: Array<ValueTypes["Contember_OrderDeliveryStateCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderDeliveryStateCondition"]> | undefined | null,
	not?: ValueTypes["Contember_OrderDeliveryStateCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	notEq?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	in?: Array<ValueTypes["Contember_OrderDeliveryState"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_OrderDeliveryState"]> | undefined | null,
	lt?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	lte?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	gt?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	gte?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null
};
	["Contember_OrderDeliveryState"]:Contember_OrderDeliveryState;
	["Contember_LiftagoRideWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	liftagoId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	data?: ValueTypes["Contember_StringCondition"] | undefined | null,
	updatedAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LiftagoRideWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LiftagoRideWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LiftagoRideWhere"] | undefined | null
};
	["Contember_CleanerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	canReachTopShelf?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CleanerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CleanerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CleanerWhere"] | undefined | null
};
	["Contember_StocksManagerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	managedProducts?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_StocksManagerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_StocksManagerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_StocksManagerWhere"] | undefined | null
};
	["Contember_CourierManagerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CourierManagerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CourierManagerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CourierManagerWhere"] | undefined | null
};
	["Contember_PickerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PickerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PickerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PickerWhere"] | undefined | null
};
	["Contember_RemunerationProfileWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	employmentInformation?: ValueTypes["Contember_EmploymentInformationWhere"] | undefined | null,
	workMonths?: ValueTypes["Contember_WorkMonthWhere"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null
};
	["Contember_EmploymentInformationWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	hourlyRate?: ValueTypes["Contember_IntCondition"] | undefined | null,
	remunerationProfiles?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null,
	monthlySalary?: ValueTypes["Contember_IntCondition"] | undefined | null,
	type?: ValueTypes["Contember_EmploymentTypeCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_EmploymentInformationWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_EmploymentInformationWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_EmploymentInformationWhere"] | undefined | null
};
	["Contember_EmploymentTypeCondition"]: {
	and?: Array<ValueTypes["Contember_EmploymentTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_EmploymentTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_EmploymentTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_EmploymentType"] | undefined | null,
	notEq?: ValueTypes["Contember_EmploymentType"] | undefined | null,
	in?: Array<ValueTypes["Contember_EmploymentType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_EmploymentType"]> | undefined | null,
	lt?: ValueTypes["Contember_EmploymentType"] | undefined | null,
	lte?: ValueTypes["Contember_EmploymentType"] | undefined | null,
	gt?: ValueTypes["Contember_EmploymentType"] | undefined | null,
	gte?: ValueTypes["Contember_EmploymentType"] | undefined | null
};
	["Contember_EmploymentType"]:Contember_EmploymentType;
	["Contember_WorkMonthWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	year?: ValueTypes["Contember_IntCondition"] | undefined | null,
	month?: ValueTypes["Contember_IntCondition"] | undefined | null,
	hoursWorked?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	billableHours?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_WorkMonthWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_WorkMonthWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_WorkMonthWhere"] | undefined | null
};
	["Contember_CashierWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CashierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CashierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CashierWhere"] | undefined | null
};
	["Contember_InternalBuyerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_InternalBuyerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_InternalBuyerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_InternalBuyerWhere"] | undefined | null
};
	["Contember_SalesManagerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	countries?: ValueTypes["Contember_CountryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_SalesManagerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_SalesManagerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_SalesManagerWhere"] | undefined | null
};
	["Contember_CashierManagerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	staff?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CashierManagerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CashierManagerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CashierManagerWhere"] | undefined | null
};
	["Contember_BusinessServiceEventWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	fulfilledAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	businessCustomer?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,
	businessService?: ValueTypes["Contember_BusinessServiceWhere"] | undefined | null,
	filledNumberOfPeople?: ValueTypes["Contember_IntCondition"] | undefined | null,
	filledInvoiced?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	filledEquipment?: ValueTypes["Contember_StringCondition"] | undefined | null,
	filledMillTamDem?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	filledSOEspresso?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	filledFilterCoffeeInMenu?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	filledWaterMeterStatus?: ValueTypes["Contember_StringCondition"] | undefined | null,
	filledServiceAction?: ValueTypes["Contember_StringCondition"] | undefined | null,
	filledInvoiceIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	filledNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	filledSupervisor?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	filledContact?: ValueTypes["Contember_StringCondition"] | undefined | null,
	filledPhone?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	filledEmail?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	filledGrindingStonesCondition?: ValueTypes["Contember_StringCondition"] | undefined | null,
	createdBy?: ValueTypes["Contember_StaffWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BusinessServiceEventWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BusinessServiceEventWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BusinessServiceEventWhere"] | undefined | null
};
	["Contember_BusinessServiceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireNote?: ValueTypes["Contember_IntCondition"] | undefined | null,
	locales?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null,
	requireNumberOfPeople?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireInvoiced?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireEquipment?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireMillTamDem?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireSOEspresso?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireFilterCoffeeInMenu?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireWaterMeterStatus?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireServiceAction?: ValueTypes["Contember_IntCondition"] | undefined | null,
	optionsEquipment?: ValueTypes["Contember_StringCondition"] | undefined | null,
	optionsServiceAction?: ValueTypes["Contember_StringCondition"] | undefined | null,
	requireInvoiceIdentifier?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireSupervisor?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireContact?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requirePhone?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireEmail?: ValueTypes["Contember_IntCondition"] | undefined | null,
	requireGrindingStonesCondition?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_BusinessServiceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BusinessServiceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BusinessServiceWhere"] | undefined | null
};
	["Contember_BusinessServiceLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_BusinessServiceWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	labelNumberOfPeople?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelInvoiced?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelEquipment?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelMillTamDem?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelSOEspresso?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelFilterCoffeeInMenu?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelWaterMeterStatus?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelServiceAction?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelInvoiceIdentifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelSupervisor?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelContact?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelPhone?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelEmail?: ValueTypes["Contember_StringCondition"] | undefined | null,
	labelGrindingStonesCondition?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null
};
	["Contember_DoubleshotCoffeeEquipmentWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	espressoMachine?: ValueTypes["Contember_StringCondition"] | undefined | null,
	espressoGrinder?: ValueTypes["Contember_StringCondition"] | undefined | null,
	batchBrew?: ValueTypes["Contember_StringCondition"] | undefined | null,
	batchGrinder?: ValueTypes["Contember_StringCondition"] | undefined | null,
	waterFilter?: ValueTypes["Contember_StringCondition"] | undefined | null,
	mechanicalFilter?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined | null
};
	["Contember_OrderRecurrenceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	locales?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	businessCategory?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null
};
	["Contember_OrderRecurrenceLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null
};
	["Contember_ProductListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_ProductListItemWhere"] | undefined | null,
	businessCategory?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductListWhere"] | undefined | null
};
	["Contember_ProductListItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	list?: ValueTypes["Contember_ProductListWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductListItemWhere"] | undefined | null
};
	["Contember_ProductCategoryListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_ProductCategoryListItemWhere"] | undefined | null,
	locales?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null
};
	["Contember_ProductCategoryListItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	list?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,
	showRecipeImages?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	showPackingImages?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductCategoryListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductCategoryListItemWhere"] | undefined | null
};
	["Contember_ProductCategoryListLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null
};
	["Contember_ProductIngredientLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null
};
	["Contember_ProductIngredientWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	locales?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	categories?: ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null,
	allergens?: ValueTypes["Contember_AllergenWhere"] | undefined | null,
	needsPrepping?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	allowCustomerRating?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	customerRatings?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	suppliers?: ValueTypes["Contember_IngredientSupplierListWhere"] | undefined | null,
	incrementQuantityNumber?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null
};
	["Contember_ProductIngredientCategoryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ingredients?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,
	locales?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null
};
	["Contember_AllergenWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	locales?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null,
	ingredients?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,
	code?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_AllergenWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_AllergenWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_AllergenWhere"] | undefined | null
};
	["Contember_AllergenLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_AllergenWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null
};
	["Contember_CustomerIngredientRatingWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	rating?: ValueTypes["Contember_ProductIngredientRatingWhere"] | undefined | null,
	ingredient?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null
};
	["Contember_ProductIngredientRatingWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	icon?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientRatingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientRatingWhere"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityUnitWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined | null
};
	["Contember_FreshingContainerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	volumeMl?: ValueTypes["Contember_IntCondition"] | undefined | null,
	workspace?: ValueTypes["Contember_WorkspaceWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_FreshingContainerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_FreshingContainerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null
};
	["Contember_WorkspaceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	unique?: ValueTypes["Contember_OneCondition"] | undefined | null,
	orderDeadlineTime?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["Contember_IntCondition"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["Contember_IntCondition"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["Contember_IntCondition"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["Contember_StringCondition"] | undefined | null,
	orderUrlTemplate?: ValueTypes["Contember_StringCondition"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["Contember_StringCondition"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["Contember_StringCondition"] | undefined | null,
	messengerUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	messengerIosUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	freshingContainers?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null,
	openingHours?: ValueTypes["Contember_WeekHoursWhere"] | undefined | null,
	workingHours?: ValueTypes["Contember_WeekHoursWhere"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null,
	creditsProduct?: ValueTypes["Contember_VirtualProductWhere"] | undefined | null,
	discountVatRate?: ValueTypes["Contember_VatRateWhere"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	businessAddress?: ValueTypes["Contember_StringCondition"] | undefined | null,
	migrationTest?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_WorkspaceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_WorkspaceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_WorkspaceWhere"] | undefined | null
};
	["Contember_OneCondition"]: {
	and?: Array<ValueTypes["Contember_OneCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_OneCondition"]> | undefined | null,
	not?: ValueTypes["Contember_OneCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_One"] | undefined | null,
	notEq?: ValueTypes["Contember_One"] | undefined | null,
	in?: Array<ValueTypes["Contember_One"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_One"]> | undefined | null,
	lt?: ValueTypes["Contember_One"] | undefined | null,
	lte?: ValueTypes["Contember_One"] | undefined | null,
	gt?: ValueTypes["Contember_One"] | undefined | null,
	gte?: ValueTypes["Contember_One"] | undefined | null
};
	["Contember_One"]:Contember_One;
	["Contember_WeekHoursWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	mon?: ValueTypes["Contember_StringCondition"] | undefined | null,
	tue?: ValueTypes["Contember_StringCondition"] | undefined | null,
	wed?: ValueTypes["Contember_StringCondition"] | undefined | null,
	thu?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fri?: ValueTypes["Contember_StringCondition"] | undefined | null,
	sat?: ValueTypes["Contember_StringCondition"] | undefined | null,
	sun?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_WeekHoursWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_WeekHoursWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_WeekHoursWhere"] | undefined | null
};
	["Contember_VirtualProductWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_VirtualProductTypeCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	voucherCreditCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	physicalRepresentation?: ValueTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	recipientEmail?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_VirtualProductWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VirtualProductWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VirtualProductWhere"] | undefined | null
};
	["Contember_VirtualProductTypeCondition"]: {
	and?: Array<ValueTypes["Contember_VirtualProductTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_VirtualProductTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_VirtualProductTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	notEq?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	in?: Array<ValueTypes["Contember_VirtualProductType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_VirtualProductType"]> | undefined | null,
	lt?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	lte?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	gt?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	gte?: ValueTypes["Contember_VirtualProductType"] | undefined | null
};
	["Contember_VirtualProductType"]:Contember_VirtualProductType;
	["Contember_VirtualProductPhysicalRepresentationTypeCondition"]: {
	and?: Array<ValueTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	notEq?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	in?: Array<ValueTypes["Contember_VirtualProductPhysicalRepresentationType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_VirtualProductPhysicalRepresentationType"]> | undefined | null,
	lt?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	lte?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	gt?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	gte?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null
};
	["Contember_VirtualProductPhysicalRepresentationType"]:Contember_VirtualProductPhysicalRepresentationType;
	["Contember_IngredientSupplierListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_IngredientSupplierItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_IngredientSupplierListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_IngredientSupplierListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_IngredientSupplierListWhere"] | undefined | null
};
	["Contember_IngredientSupplierItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	list?: ValueTypes["Contember_IngredientSupplierListWhere"] | undefined | null,
	supplier?: ValueTypes["Contember_IngredientSupplierWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_IngredientSupplierItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_IngredientSupplierItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_IngredientSupplierItemWhere"] | undefined | null
};
	["Contember_IngredientSupplierWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	locales?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_IngredientSupplierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_IngredientSupplierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_IngredientSupplierWhere"] | undefined | null
};
	["Contember_IngredientSupplierLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_IngredientSupplierWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null
};
	["Contember_BlogLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	pageName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	lead?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_BlogWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BlogLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BlogLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null
};
	["Contember_BlogWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	unique?: ValueTypes["Contember_OneCondition"] | undefined | null,
	locales?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BlogWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BlogWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BlogWhere"] | undefined | null
};
	["Contember_BlogPostLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	availableForWeb?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	availableForMobile?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	root?: ValueTypes["Contember_BlogPostWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	cover?: ValueTypes["Contember_CoverWhere"] | undefined | null,
	content?: ValueTypes["Contember_ContentWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkableWhere"] | undefined | null,
	seo?: ValueTypes["Contember_SeoWhere"] | undefined | null,
	products?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null
};
	["Contember_BlogPostWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	locales?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BlogPostWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BlogPostWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BlogPostWhere"] | undefined | null
};
	["Contember_CoverWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	primaryTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	secondaryTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	medium?: ValueTypes["Contember_MediumWhere"] | undefined | null,
	buttonColors?: ValueTypes["Contember_ColorPalleteWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CoverWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CoverWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CoverWhere"] | undefined | null
};
	["Contember_MediumWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_MediumTypeCondition"] | undefined | null,
	colorTheme?: ValueTypes["Contember_ColorThemeCondition"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	video?: ValueTypes["Contember_VideoWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_MediumWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MediumWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MediumWhere"] | undefined | null
};
	["Contember_MediumTypeCondition"]: {
	and?: Array<ValueTypes["Contember_MediumTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_MediumTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_MediumTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_MediumType"] | undefined | null,
	notEq?: ValueTypes["Contember_MediumType"] | undefined | null,
	in?: Array<ValueTypes["Contember_MediumType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_MediumType"]> | undefined | null,
	lt?: ValueTypes["Contember_MediumType"] | undefined | null,
	lte?: ValueTypes["Contember_MediumType"] | undefined | null,
	gt?: ValueTypes["Contember_MediumType"] | undefined | null,
	gte?: ValueTypes["Contember_MediumType"] | undefined | null
};
	["Contember_MediumType"]:Contember_MediumType;
	["Contember_ColorThemeCondition"]: {
	and?: Array<ValueTypes["Contember_ColorThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_ColorThemeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_ColorThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_ColorTheme"] | undefined | null,
	notEq?: ValueTypes["Contember_ColorTheme"] | undefined | null,
	in?: Array<ValueTypes["Contember_ColorTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_ColorTheme"]> | undefined | null,
	lt?: ValueTypes["Contember_ColorTheme"] | undefined | null,
	lte?: ValueTypes["Contember_ColorTheme"] | undefined | null,
	gt?: ValueTypes["Contember_ColorTheme"] | undefined | null,
	gte?: ValueTypes["Contember_ColorTheme"] | undefined | null
};
	["Contember_ColorTheme"]:Contember_ColorTheme;
	["Contember_VideoWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	src?: ValueTypes["Contember_StringCondition"] | undefined | null,
	width?: ValueTypes["Contember_IntCondition"] | undefined | null,
	height?: ValueTypes["Contember_IntCondition"] | undefined | null,
	size?: ValueTypes["Contember_IntCondition"] | undefined | null,
	type?: ValueTypes["Contember_StringCondition"] | undefined | null,
	duration?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	poster?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_VideoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_VideoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_VideoWhere"] | undefined | null
};
	["Contember_ColorPalleteWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	background?: ValueTypes["Contember_StringCondition"] | undefined | null,
	text?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ColorPalleteWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ColorPalleteWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ColorPalleteWhere"] | undefined | null
};
	["Contember_ContentWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	blocks?: ValueTypes["Contember_ContentBlockWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ContentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ContentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ContentWhere"] | undefined | null
};
	["Contember_ContentBlockWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	json?: ValueTypes["Contember_StringCondition"] | undefined | null,
	content?: ValueTypes["Contember_ContentWhere"] | undefined | null,
	references?: ValueTypes["Contember_ContentReferenceWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ContentBlockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ContentBlockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ContentBlockWhere"] | undefined | null
};
	["Contember_ContentReferenceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_ContentReferenceTypeCondition"] | undefined | null,
	primaryText?: ValueTypes["Contember_StringCondition"] | undefined | null,
	secondaryText?: ValueTypes["Contember_StringCondition"] | undefined | null,
	jsonContent?: ValueTypes["Contember_StringCondition"] | undefined | null,
	block?: ValueTypes["Contember_ContentBlockWhere"] | undefined | null,
	colorPallete?: ValueTypes["Contember_ColorPalleteWhere"] | undefined | null,
	medium?: ValueTypes["Contember_MediumWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	blogPosts?: ValueTypes["Contember_BlogPostListWhere"] | undefined | null,
	links?: ValueTypes["Contember_LinkListWhere"] | undefined | null,
	products?: ValueTypes["Contember_ProductListWhere"] | undefined | null,
	contentSize?: ValueTypes["Contember_ContentSizeCondition"] | undefined | null,
	misc?: ValueTypes["Contember_StringCondition"] | undefined | null,
	hero?: ValueTypes["Contember_HeroWhere"] | undefined | null,
	gallery?: ValueTypes["Contember_GalleryWhere"] | undefined | null,
	socialsAndApps?: ValueTypes["Contember_SocialsAndAppsWhere"] | undefined | null,
	productGrid?: ValueTypes["Contember_ProductGridWhere"] | undefined | null,
	productBanners?: ValueTypes["Contember_ProductBannerListWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null,
	textList?: ValueTypes["Contember_TextListWhere"] | undefined | null,
	gridTiles?: ValueTypes["Contember_GridTilesWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ContentReferenceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ContentReferenceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ContentReferenceWhere"] | undefined | null
};
	["Contember_ContentReferenceTypeCondition"]: {
	and?: Array<ValueTypes["Contember_ContentReferenceTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_ContentReferenceTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_ContentReferenceTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_ContentReferenceType"] | undefined | null,
	notEq?: ValueTypes["Contember_ContentReferenceType"] | undefined | null,
	in?: Array<ValueTypes["Contember_ContentReferenceType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_ContentReferenceType"]> | undefined | null,
	lt?: ValueTypes["Contember_ContentReferenceType"] | undefined | null,
	lte?: ValueTypes["Contember_ContentReferenceType"] | undefined | null,
	gt?: ValueTypes["Contember_ContentReferenceType"] | undefined | null,
	gte?: ValueTypes["Contember_ContentReferenceType"] | undefined | null
};
	["Contember_ContentReferenceType"]:Contember_ContentReferenceType;
	["Contember_LinkWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_LinkTypeCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	externalLink?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalLink?: ValueTypes["Contember_LinkableWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LinkWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LinkWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LinkWhere"] | undefined | null
};
	["Contember_LinkTypeCondition"]: {
	and?: Array<ValueTypes["Contember_LinkTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_LinkTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_LinkTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_LinkType"] | undefined | null,
	notEq?: ValueTypes["Contember_LinkType"] | undefined | null,
	in?: Array<ValueTypes["Contember_LinkType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_LinkType"]> | undefined | null,
	lt?: ValueTypes["Contember_LinkType"] | undefined | null,
	lte?: ValueTypes["Contember_LinkType"] | undefined | null,
	gt?: ValueTypes["Contember_LinkType"] | undefined | null,
	gte?: ValueTypes["Contember_LinkType"] | undefined | null
};
	["Contember_LinkType"]:Contember_LinkType;
	["Contember_LinkableWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	url?: ValueTypes["Contember_StringCondition"] | undefined | null,
	blogPost?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,
	page?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null,
	wikiPage?: ValueTypes["Contember_WikiPageWhere"] | undefined | null,
	redirect?: ValueTypes["Contember_RedirectWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LinkableWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LinkableWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LinkableWhere"] | undefined | null
};
	["Contember_PageLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	link?: ValueTypes["Contember_LinkableWhere"] | undefined | null,
	root?: ValueTypes["Contember_PageWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	seo?: ValueTypes["Contember_SeoWhere"] | undefined | null,
	content?: ValueTypes["Contember_ContentWhere"] | undefined | null,
	cover?: ValueTypes["Contember_CoverWhere"] | undefined | null,
	theme?: ValueTypes["Contember_PageThemeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null
};
	["Contember_PageWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	locales?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PageWhere"] | undefined | null
};
	["Contember_SeoWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ogTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ogDescription?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ogImage?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_SeoWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_SeoWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_SeoWhere"] | undefined | null
};
	["Contember_PageThemeCondition"]: {
	and?: Array<ValueTypes["Contember_PageThemeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_PageThemeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_PageThemeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_PageTheme"] | undefined | null,
	notEq?: ValueTypes["Contember_PageTheme"] | undefined | null,
	in?: Array<ValueTypes["Contember_PageTheme"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_PageTheme"]> | undefined | null,
	lt?: ValueTypes["Contember_PageTheme"] | undefined | null,
	lte?: ValueTypes["Contember_PageTheme"] | undefined | null,
	gt?: ValueTypes["Contember_PageTheme"] | undefined | null,
	gte?: ValueTypes["Contember_PageTheme"] | undefined | null
};
	["Contember_PageTheme"]:Contember_PageTheme;
	["Contember_WikiPageWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	emoji?: ValueTypes["Contember_StringCondition"] | undefined | null,
	link?: ValueTypes["Contember_LinkableWhere"] | undefined | null,
	parent?: ValueTypes["Contember_WikiPageWhere"] | undefined | null,
	children?: ValueTypes["Contember_WikiPageWhere"] | undefined | null,
	content?: ValueTypes["Contember_ContentWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_WikiPageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_WikiPageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_WikiPageWhere"] | undefined | null
};
	["Contember_RedirectWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	link?: ValueTypes["Contember_LinkableWhere"] | undefined | null,
	target?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_RedirectWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_RedirectWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_RedirectWhere"] | undefined | null
};
	["Contember_BlogPostListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_BlogPostListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BlogPostListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BlogPostListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BlogPostListWhere"] | undefined | null
};
	["Contember_BlogPostListItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	caption?: ValueTypes["Contember_StringCondition"] | undefined | null,
	list?: ValueTypes["Contember_BlogPostListWhere"] | undefined | null,
	blogPost?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_BlogPostListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_BlogPostListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_BlogPostListItemWhere"] | undefined | null
};
	["Contember_LinkListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_LinkListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LinkListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LinkListWhere"] | undefined | null
};
	["Contember_LinkListItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	isPrimary?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	list?: ValueTypes["Contember_LinkListWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LinkListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LinkListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LinkListItemWhere"] | undefined | null
};
	["Contember_ContentSizeCondition"]: {
	and?: Array<ValueTypes["Contember_ContentSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_ContentSizeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_ContentSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_ContentSize"] | undefined | null,
	notEq?: ValueTypes["Contember_ContentSize"] | undefined | null,
	in?: Array<ValueTypes["Contember_ContentSize"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_ContentSize"]> | undefined | null,
	lt?: ValueTypes["Contember_ContentSize"] | undefined | null,
	lte?: ValueTypes["Contember_ContentSize"] | undefined | null,
	gt?: ValueTypes["Contember_ContentSize"] | undefined | null,
	gte?: ValueTypes["Contember_ContentSize"] | undefined | null
};
	["Contember_ContentSize"]:Contember_ContentSize;
	["Contember_HeroWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	showLogo?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	middleContent?: ValueTypes["Contember_StringCondition"] | undefined | null,
	medium?: ValueTypes["Contember_MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_HeroWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_HeroWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_HeroWhere"] | undefined | null
};
	["Contember_GalleryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_GalleryItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_GalleryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_GalleryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_GalleryWhere"] | undefined | null
};
	["Contember_GalleryItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	caption?: ValueTypes["Contember_StringCondition"] | undefined | null,
	list?: ValueTypes["Contember_GalleryWhere"] | undefined | null,
	medium?: ValueTypes["Contember_MediumWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_GalleryItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_GalleryItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_GalleryItemWhere"] | undefined | null
};
	["Contember_SocialsAndAppsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	instagram?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	appStore?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	googlePlay?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_SocialsAndAppsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_SocialsAndAppsWhere"] | undefined | null
};
	["Contember_ProductGridWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	isCarousel?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	categories?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null,
	type?: ValueTypes["Contember_ProductGridTypeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductGridWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductGridWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductGridWhere"] | undefined | null
};
	["Contember_ProductGridCategoryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_ProductGridWhere"] | undefined | null,
	items?: ValueTypes["Contember_ProductGridItemWhere"] | undefined | null,
	images?: ValueTypes["Contember_ImageListWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null
};
	["Contember_ProductGridItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	lead?: ValueTypes["Contember_StringCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	medium?: ValueTypes["Contember_MediumWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	category?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductGridItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductGridItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductGridItemWhere"] | undefined | null
};
	["Contember_ImageListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_ImageListItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ImageListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ImageListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ImageListWhere"] | undefined | null
};
	["Contember_ImageListItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	list?: ValueTypes["Contember_ImageListWhere"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ImageListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ImageListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ImageListItemWhere"] | undefined | null
};
	["Contember_ProductGridTypeCondition"]: {
	and?: Array<ValueTypes["Contember_ProductGridTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductGridTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_ProductGridTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_ProductGridType"] | undefined | null,
	notEq?: ValueTypes["Contember_ProductGridType"] | undefined | null,
	in?: Array<ValueTypes["Contember_ProductGridType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_ProductGridType"]> | undefined | null,
	lt?: ValueTypes["Contember_ProductGridType"] | undefined | null,
	lte?: ValueTypes["Contember_ProductGridType"] | undefined | null,
	gt?: ValueTypes["Contember_ProductGridType"] | undefined | null,
	gte?: ValueTypes["Contember_ProductGridType"] | undefined | null
};
	["Contember_ProductGridType"]:Contember_ProductGridType;
	["Contember_ProductBannerListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_ProductBannerItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductBannerListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductBannerListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductBannerListWhere"] | undefined | null
};
	["Contember_ProductBannerItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	list?: ValueTypes["Contember_ProductBannerListWhere"] | undefined | null,
	banner?: ValueTypes["Contember_ProductBannerWhere"] | undefined | null,
	size?: ValueTypes["Contember_ProductBannerItemSizeCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductBannerItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductBannerItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductBannerItemWhere"] | undefined | null
};
	["Contember_ProductBannerWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	beforeTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	label?: ValueTypes["Contember_StringCondition"] | undefined | null,
	button?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	backgroundImage?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	productImage?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductBannerWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductBannerWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductBannerWhere"] | undefined | null
};
	["Contember_ProductBannerItemSizeCondition"]: {
	and?: Array<ValueTypes["Contember_ProductBannerItemSizeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductBannerItemSizeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_ProductBannerItemSizeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null,
	notEq?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null,
	in?: Array<ValueTypes["Contember_ProductBannerItemSize"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_ProductBannerItemSize"]> | undefined | null,
	lt?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null,
	lte?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null,
	gt?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null,
	gte?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null
};
	["Contember_ProductBannerItemSize"]:Contember_ProductBannerItemSize;
	["Contember_DeliveryRegionsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null
};
	["Contember_DeliveryRegionsItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	offerLabel?: ValueTypes["Contember_StringCondition"] | undefined | null,
	list?: ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	textList?: ValueTypes["Contember_TextListWhere"] | undefined | null,
	gallery?: ValueTypes["Contember_ImageListWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null
};
	["Contember_TextListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_TextItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TextListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TextListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TextListWhere"] | undefined | null
};
	["Contember_TextItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	text?: ValueTypes["Contember_StringCondition"] | undefined | null,
	list?: ValueTypes["Contember_TextListWhere"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_TextItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TextItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TextItemWhere"] | undefined | null
};
	["Contember_GridTilesWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_GridTileItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_GridTilesWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_GridTilesWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_GridTilesWhere"] | undefined | null
};
	["Contember_GridTileItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	text?: ValueTypes["Contember_StringCondition"] | undefined | null,
	list?: ValueTypes["Contember_GridTilesWhere"] | undefined | null,
	medium?: ValueTypes["Contember_MediumWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_GridTileItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_GridTileItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_GridTileItemWhere"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	catalogue?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined | null
};
	["Contember_TranslationCatalogueWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationDomainWhere"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,
	values?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null
};
	["Contember_TranslationDomainWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	catalogues?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,
	keys?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TranslationDomainWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TranslationDomainWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TranslationDomainWhere"] | undefined | null
};
	["Contember_TranslationKeyWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	identifier?: ValueTypes["Contember_StringCondition"] | undefined | null,
	contentType?: ValueTypes["Contember_TranslationContentTypeCondition"] | undefined | null,
	note?: ValueTypes["Contember_StringCondition"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationDomainWhere"] | undefined | null,
	values?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TranslationKeyWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TranslationKeyWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null
};
	["Contember_TranslationContentTypeCondition"]: {
	and?: Array<ValueTypes["Contember_TranslationContentTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_TranslationContentTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_TranslationContentTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	notEq?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	in?: Array<ValueTypes["Contember_TranslationContentType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_TranslationContentType"]> | undefined | null,
	lt?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	lte?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	gt?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	gte?: ValueTypes["Contember_TranslationContentType"] | undefined | null
};
	["Contember_TranslationContentType"]:Contember_TranslationContentType;
	["Contember_TranslationValueWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	value?: ValueTypes["Contember_StringCondition"] | undefined | null,
	catalogue?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,
	key?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_TranslationValueWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_TranslationValueWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null
};
	["Contember_MenuLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	root?: ValueTypes["Contember_SiteMenuWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	items?: ValueTypes["Contember_MenuItemWhere"] | undefined | null,
	secondaryItems?: ValueTypes["Contember_LinkListWhere"] | undefined | null,
	eshopLink?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null
};
	["Contember_SiteMenuWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	unique?: ValueTypes["Contember_OneCondition"] | undefined | null,
	locales?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_SiteMenuWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_SiteMenuWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_SiteMenuWhere"] | undefined | null
};
	["Contember_MenuItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	menu?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	subitems?: ValueTypes["Contember_MenuSubitemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuItemWhere"] | undefined | null
};
	["Contember_MenuSubitemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_MenuSubitemTypeCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	item?: ValueTypes["Contember_MenuItemWhere"] | undefined | null,
	links?: ValueTypes["Contember_MenuLinkListWhere"] | undefined | null,
	products?: ValueTypes["Contember_MenuProductsWhere"] | undefined | null,
	delivery?: ValueTypes["Contember_MenuDeliveryWhere"] | undefined | null,
	contact?: ValueTypes["Contember_MenuContactWhere"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuSubitemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuSubitemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuSubitemWhere"] | undefined | null
};
	["Contember_MenuSubitemTypeCondition"]: {
	and?: Array<ValueTypes["Contember_MenuSubitemTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuSubitemTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_MenuSubitemTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_MenuSubitemType"] | undefined | null,
	notEq?: ValueTypes["Contember_MenuSubitemType"] | undefined | null,
	in?: Array<ValueTypes["Contember_MenuSubitemType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_MenuSubitemType"]> | undefined | null,
	lt?: ValueTypes["Contember_MenuSubitemType"] | undefined | null,
	lte?: ValueTypes["Contember_MenuSubitemType"] | undefined | null,
	gt?: ValueTypes["Contember_MenuSubitemType"] | undefined | null,
	gte?: ValueTypes["Contember_MenuSubitemType"] | undefined | null
};
	["Contember_MenuSubitemType"]:Contember_MenuSubitemType;
	["Contember_MenuLinkListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_MenuLinkItemWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuLinkListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuLinkListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuLinkListWhere"] | undefined | null
};
	["Contember_MenuLinkItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	list?: ValueTypes["Contember_MenuLinkListWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkWhere"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuLinkItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuLinkItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuLinkItemWhere"] | undefined | null
};
	["Contember_MenuProductsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	links?: ValueTypes["Contember_MenuLinkListWhere"] | undefined | null,
	buttons?: ValueTypes["Contember_LinkListWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuProductsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuProductsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuProductsWhere"] | undefined | null
};
	["Contember_MenuDeliveryWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null,
	text?: ValueTypes["Contember_StringCondition"] | undefined | null,
	socialsAndApps?: ValueTypes["Contember_SocialsAndAppsWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuDeliveryWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuDeliveryWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuDeliveryWhere"] | undefined | null
};
	["Contember_MenuContactWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	text?: ValueTypes["Contember_StringCondition"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_MenuContactWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_MenuContactWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_MenuContactWhere"] | undefined | null
};
	["Contember_ProductLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	featureList?: ValueTypes["Contember_TextListWhere"] | undefined | null,
	printableName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	firstSticker?: ValueTypes["Contember_StringCondition"] | undefined | null,
	secondSticker?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null
};
	["Contember_ProductRecipeLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null
};
	["Contember_ProductRecipeWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	products?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	color?: ValueTypes["Contember_StringCondition"] | undefined | null,
	image?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	icon?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	ingredients?: ValueTypes["Contember_ProductIngredientItemWhere"] | undefined | null,
	needsPrepping?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	locales?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null,
	gutTuning?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	nutritionFacts?: ValueTypes["Contember_NutritionFactsWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductRecipeWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductRecipeWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null
};
	["Contember_ProductIngredientItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	internalOrder?: ValueTypes["Contember_IntCondition"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null,
	ingredient?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,
	quantity?: ValueTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientItemWhere"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	unit?: ValueTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined | null,
	value?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined | null
};
	["Contember_NutritionFactsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	energyKiloJoule?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	energyKiloCalorie?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	fatsGrams?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	fatsOfWhichSaturatedGrams?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	carbohydrateGrams?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	carbohydrateOfWhichSugarsGrams?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	proteinGrams?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	fibreGrams?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	saltGrams?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_NutritionFactsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_NutritionFactsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_NutritionFactsWhere"] | undefined | null
};
	["Contember_DeliveryMethodLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["Contember_StringCondition"] | undefined | null,
	processingTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	processingText?: ValueTypes["Contember_StringCondition"] | undefined | null,
	processingIconUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	dispatchedTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	dispatchedText?: ValueTypes["Contember_StringCondition"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fulfilledTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fulfilledText?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	root?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null
};
	["Contember_PipelineStageLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null
};
	["Contember_PipelineStageWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null,
	pipeline?: ValueTypes["Contember_PipelineWhere"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	locales?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageSentimentWhere"] | undefined | null,
	type?: ValueTypes["Contember_PipelineStageTypeCondition"] | undefined | null,
	forms?: ValueTypes["Contember_LeadFormWhere"] | undefined | null,
	showItemsWeight?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	showItemsPrice?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PipelineStageWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PipelineStageWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null
};
	["Contember_PipelineStageItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	list?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null,
	lead?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null
};
	["Contember_PipelineLeadWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	createdAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	stageItem?: ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null,
	contactPerson?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	cafeType?: ValueTypes["Contember_StringCondition"] | undefined | null,
	offerType?: ValueTypes["Contember_StringCondition"] | undefined | null,
	ownMachine?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	customerCity?: ValueTypes["Contember_StringCondition"] | undefined | null,
	companyId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customerName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	email?: ValueTypes["Contember_StringCondition"] | undefined | null,
	phone?: ValueTypes["Contember_StringCondition"] | undefined | null,
	form?: ValueTypes["Contember_LeadFormWhere"] | undefined | null,
	meta?: ValueTypes["Contember_StringCondition"] | undefined | null,
	multiroasterType?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PipelineLeadWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PipelineLeadWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null
};
	["Contember_LeadFormWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	stage?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null,
	leads?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_LeadFormWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_LeadFormWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_LeadFormWhere"] | undefined | null
};
	["Contember_PipelineWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	unique?: ValueTypes["Contember_OneCondition"] | undefined | null,
	stages?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_PipelineWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PipelineWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PipelineWhere"] | undefined | null
};
	["Contember_PipelineStageSentimentWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	internalName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	backgroundColor?: ValueTypes["Contember_StringCondition"] | undefined | null,
	textColor?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PipelineStageSentimentWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PipelineStageSentimentWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PipelineStageSentimentWhere"] | undefined | null
};
	["Contember_PipelineStageTypeCondition"]: {
	and?: Array<ValueTypes["Contember_PipelineStageTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_PipelineStageTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_PipelineStageTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	notEq?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	in?: Array<ValueTypes["Contember_PipelineStageType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_PipelineStageType"]> | undefined | null,
	lt?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	lte?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	gt?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	gte?: ValueTypes["Contember_PipelineStageType"] | undefined | null
};
	["Contember_PipelineStageType"]:Contember_PipelineStageType;
	["Contember_SubscriptionBoxLocaleWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	title?: ValueTypes["Contember_StringCondition"] | undefined | null,
	stickerText?: ValueTypes["Contember_StringCondition"] | undefined | null,
	descriptionText?: ValueTypes["Contember_StringCondition"] | undefined | null,
	root?: ValueTypes["Contember_SubscriptionBoxWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleWhere"] | undefined | null,
	secondaryTitle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	texts?: ValueTypes["Contember_TextListWhere"] | undefined | null,
	secondaryTexts?: ValueTypes["Contember_TextListWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null
};
	["Contember_SubscriptionBoxWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	locales?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null,
	unique?: ValueTypes["Contember_OneCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_SubscriptionBoxWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_SubscriptionBoxWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_SubscriptionBoxWhere"] | undefined | null
};
	["Contember_PreparedPackingQuantityWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	date?: ValueTypes["Contember_DateCondition"] | undefined | null,
	quantity?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null,
	packing?: ValueTypes["Contember_ProductPackingWhere"] | undefined | null,
	packedInCardboard?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_PreparedPackingQuantityWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_PreparedPackingQuantityWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_PreparedPackingQuantityWhere"] | undefined | null
};
	["Contember_ProductStockWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	quantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	store?: ValueTypes["Contember_StoreWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductStockWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductStockWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductStockWhere"] | undefined | null
};
	["Contember_StoreWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	description?: ValueTypes["Contember_StringCondition"] | undefined | null,
	internalNote?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_StoreWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_StoreWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_StoreWhere"] | undefined | null
};
	["Contember_ProductGroupPriceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	priceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	group?: ValueTypes["Contember_CustomerGroupWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null
};
	["Contember_CustomerGroupWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	productPrices?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null,
	customers?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	discountPermille?: ValueTypes["Contember_IntCondition"] | undefined | null,
	code?: ValueTypes["Contember_StringCondition"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerGroupWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerGroupWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerGroupWhere"] | undefined | null
};
	["Contember_ProductAvailabilityCondition"]: {
	and?: Array<ValueTypes["Contember_ProductAvailabilityCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductAvailabilityCondition"]> | undefined | null,
	not?: ValueTypes["Contember_ProductAvailabilityCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	notEq?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	in?: Array<ValueTypes["Contember_ProductAvailability"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_ProductAvailability"]> | undefined | null,
	lt?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	lte?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	gt?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	gte?: ValueTypes["Contember_ProductAvailability"] | undefined | null
};
	["Contember_ProductAvailability"]:Contember_ProductAvailability;
	["Contember_ProductCustomerPriceWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	groupDiscountPermille?: ValueTypes["Contember_IntCondition"] | undefined | null,
	groupPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	fullPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	discountedPriceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	priceCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	offered?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	notDiscountable?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductCustomerPriceWhere"] | undefined | null
};
	["Contember_ProductMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	archived?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	available?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	availableQuantity?: ValueTypes["Contember_IntCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductMetadataWhere"] | undefined | null
};
	["Contember_ProductSalesDayWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	productName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	orderDate?: ValueTypes["Contember_DateCondition"] | undefined | null,
	priceCentsSum?: ValueTypes["Contember_IntCondition"] | undefined | null,
	sum?: ValueTypes["Contember_IntCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductSalesDayWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductSalesDayWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductSalesDayWhere"] | undefined | null
};
	["Contember_ProductSalesWeekWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	productName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	orderDate?: ValueTypes["Contember_DateCondition"] | undefined | null,
	priceCentsSum?: ValueTypes["Contember_IntCondition"] | undefined | null,
	sum?: ValueTypes["Contember_IntCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductSalesWeekWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductSalesWeekWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductSalesWeekWhere"] | undefined | null
};
	["Contember_ProductSalesMonthWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	productName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	orderDate?: ValueTypes["Contember_DateCondition"] | undefined | null,
	priceCentsSum?: ValueTypes["Contember_IntCondition"] | undefined | null,
	sum?: ValueTypes["Contember_IntCondition"] | undefined | null,
	product?: ValueTypes["Contember_ProductWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ProductSalesMonthWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ProductSalesMonthWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ProductSalesMonthWhere"] | undefined | null
};
	["Contember_AddressMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	deliveryZoneType?: ValueTypes["Contember_DeliveryZoneTypeCondition"] | undefined | null,
	address?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	deliveryZone?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_AddressMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_AddressMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_AddressMetadataWhere"] | undefined | null
};
	["Contember_DeliveryTimelinePresetWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_DeliveryTimelineTypeCondition"] | undefined | null,
	duration?: ValueTypes["Contember_StringCondition"] | undefined | null,
	window?: ValueTypes["Contember_StringCondition"] | undefined | null,
	deliveryMethod?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryTimelinePresetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_DeliveryTimelinePresetWhere"] | undefined | null
};
	["Contember_DeliveryTimelineTypeCondition"]: {
	and?: Array<ValueTypes["Contember_DeliveryTimelineTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_DeliveryTimelineTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_DeliveryTimelineTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null,
	notEq?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null,
	in?: Array<ValueTypes["Contember_DeliveryTimelineType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_DeliveryTimelineType"]> | undefined | null,
	lt?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null,
	lte?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null,
	gt?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null,
	gte?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null
};
	["Contember_DeliveryTimelineType"]:Contember_DeliveryTimelineType;
	["Contember_PaymentMethodTypeCondition"]: {
	and?: Array<ValueTypes["Contember_PaymentMethodTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_PaymentMethodTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_PaymentMethodTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	notEq?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	in?: Array<ValueTypes["Contember_PaymentMethodType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_PaymentMethodType"]> | undefined | null,
	lt?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	lte?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	gt?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	gte?: ValueTypes["Contember_PaymentMethodType"] | undefined | null
};
	["Contember_PaymentMethodType"]:Contember_PaymentMethodType;
	["Contember_CustomerPermissionsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	canPlaceOrder?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerPermissionsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerPermissionsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerPermissionsWhere"] | undefined | null
};
	["Contember_CustomerNameWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	nominative?: ValueTypes["Contember_StringCondition"] | undefined | null,
	vocative?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	aliases?: ValueTypes["Contember_CustomerAliasWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerNameWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerNameWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerNameWhere"] | undefined | null
};
	["Contember_CustomerAliasWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerAliasWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerAliasWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerAliasWhere"] | undefined | null
};
	["Contember_CustomerFamiliarityCondition"]: {
	and?: Array<ValueTypes["Contember_CustomerFamiliarityCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerFamiliarityCondition"]> | undefined | null,
	not?: ValueTypes["Contember_CustomerFamiliarityCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	notEq?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	in?: Array<ValueTypes["Contember_CustomerFamiliarity"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_CustomerFamiliarity"]> | undefined | null,
	lt?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	lte?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	gt?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	gte?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null
};
	["Contember_CustomerFamiliarity"]:Contember_CustomerFamiliarity;
	["Contember_ApplicationShortcutListWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	items?: ValueTypes["Contember_ApplicationShortcutListItemWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ApplicationShortcutListWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ApplicationShortcutListWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ApplicationShortcutListWhere"] | undefined | null
};
	["Contember_ApplicationShortcutListItemWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	application?: ValueTypes["Contember_ApplicationWhere"] | undefined | null,
	list?: ValueTypes["Contember_ApplicationShortcutListWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_ApplicationShortcutListItemWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ApplicationShortcutListItemWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ApplicationShortcutListItemWhere"] | undefined | null
};
	["Contember_ApplicationWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	order?: ValueTypes["Contember_IntCondition"] | undefined | null,
	handle?: ValueTypes["Contember_StringCondition"] | undefined | null,
	url?: ValueTypes["Contember_StringCondition"] | undefined | null,
	color?: ValueTypes["Contember_StringCondition"] | undefined | null,
	icon?: ValueTypes["Contember_ImageWhere"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	short?: ValueTypes["Contember_StringCondition"] | undefined | null,
	and?: Array<ValueTypes["Contember_ApplicationWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_ApplicationWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_ApplicationWhere"] | undefined | null
};
	["Contember_InvoicingPlanWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	preset?: ValueTypes["Contember_InvoicingPresetWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_InvoicingPlanWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_InvoicingPlanWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_InvoicingPlanWhere"] | undefined | null
};
	["Contember_InvoicingPresetWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	type?: ValueTypes["Contember_InvoicingPresetTypeCondition"] | undefined | null,
	startsAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	endsAt?: ValueTypes["Contember_DateTimeCondition"] | undefined | null,
	intervalOfDays?: ValueTypes["Contember_IntCondition"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_InvoicingPlanWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_InvoicingPresetWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_InvoicingPresetWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_InvoicingPresetWhere"] | undefined | null
};
	["Contember_InvoicingPresetTypeCondition"]: {
	and?: Array<ValueTypes["Contember_InvoicingPresetTypeCondition"]> | undefined | null,
	or?: Array<ValueTypes["Contember_InvoicingPresetTypeCondition"]> | undefined | null,
	not?: ValueTypes["Contember_InvoicingPresetTypeCondition"] | undefined | null,
	null?: boolean | undefined | null,
	isNull?: boolean | undefined | null,
	eq?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null,
	notEq?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null,
	in?: Array<ValueTypes["Contember_InvoicingPresetType"]> | undefined | null,
	notIn?: Array<ValueTypes["Contember_InvoicingPresetType"]> | undefined | null,
	lt?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null,
	lte?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null,
	gt?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null,
	gte?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null
};
	["Contember_InvoicingPresetType"]:Contember_InvoicingPresetType;
	["Contember_CustomerSpendingWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	totalCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerSpendingWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerSpendingWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerSpendingWhere"] | undefined | null
};
	["Contember_CustomerMetadataWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	isBusiness?: ValueTypes["Contember_BooleanCondition"] | undefined | null,
	name?: ValueTypes["Contember_StringCondition"] | undefined | null,
	fallbackName?: ValueTypes["Contember_StringCondition"] | undefined | null,
	remainingCreditsCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	ordersTotalCents?: ValueTypes["Contember_IntCondition"] | undefined | null,
	customerProfileUrl?: ValueTypes["Contember_StringCondition"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_CustomerMetadataWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_CustomerMetadataWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_CustomerMetadataWhere"] | undefined | null
};
	["Contember_GpsWhere"]: {
	id?: ValueTypes["Contember_UUIDCondition"] | undefined | null,
	latitude?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	longitude?: ValueTypes["Contember_FloatCondition"] | undefined | null,
	address?: ValueTypes["Contember_AddressWhere"] | undefined | null,
	and?: Array<ValueTypes["Contember_GpsWhere"] | undefined | null> | undefined | null,
	or?: Array<ValueTypes["Contember_GpsWhere"] | undefined | null> | undefined | null,
	not?: ValueTypes["Contember_GpsWhere"] | undefined | null
};
	["Contember_AddressOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	firstName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	lastName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	addressLine2?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	email?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	phone?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	companyName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	companyIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	externalIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	hidden?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	country?: ValueTypes["Contember_CountryOrderBy"] | undefined | null,
	deletedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	googlePlaceId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	raw?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	formatted?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	geocodeGeneratedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	geocodeResponse?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveryZone?: ValueTypes["Contember_DeliveryZoneOrderBy"] | undefined | null,
	meta?: ValueTypes["Contember_AddressMetadataOrderBy"] | undefined | null,
	gps?: ValueTypes["Contember_GpsOrderBy"] | undefined | null,
	geocodeValid?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fullName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	addressLine1?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	city?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	postalCode?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_OrderDirection"]:Contember_OrderDirection;
	["Contember_CustomerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerPaymentMethodOrderBy"] | undefined | null,
	permissions?: ValueTypes["Contember_CustomerPermissionsOrderBy"] | undefined | null,
	group?: ValueTypes["Contember_CustomerGroupOrderBy"] | undefined | null,
	photo?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	phone?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_CustomerNameOrderBy"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	welcomeMessage?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	familiarity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	business?: ValueTypes["Contember_BusinessCustomerOrderBy"] | undefined | null,
	shortcutList?: ValueTypes["Contember_ApplicationShortcutListOrderBy"] | undefined | null,
	user?: ValueTypes["Contember_UserOrderBy"] | undefined | null,
	disabledAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_InvoicingPlanOrderBy"] | undefined | null,
	email?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_BillingSubjectOrderBy"] | undefined | null,
	prefersPackingsWithoutCardboard?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	anonymousSession?: ValueTypes["Contember_AnonymousSessionOrderBy"] | undefined | null,
	spending?: ValueTypes["Contember_CustomerSpendingOrderBy"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	meta?: ValueTypes["Contember_CustomerMetadataOrderBy"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CustomerPaymentMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	caption?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	data?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	paymentMethod?: ValueTypes["Contember_PaymentMethodOrderBy"] | undefined | null
};
	["Contember_PaymentMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	enabled?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	blockingDispatch?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	reduceGratuityByPercentage?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CustomerPermissionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	canPlaceOrder?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_CustomerGroupOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	discountPermille?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ImageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	url?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	width?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	height?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	size?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	alt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CustomerNameOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	nominative?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vocative?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_BusinessCustomerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	category?: ValueTypes["Contember_BusinessCategoryOrderBy"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessToBusinessManagerOrderBy"] | undefined | null,
	logo?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_DoubleshotCoffeeEquipmentOrderBy"] | undefined | null
};
	["Contember_BusinessCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	visibleToNewCustomers?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	specificProducts?: ValueTypes["Contember_ProductListOrderBy"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_ProductCategoryListOrderBy"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_ProductCategoryListOrderBy"] | undefined | null,
	slug?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	businessCategory?: ValueTypes["Contember_BusinessCategoryOrderBy"] | undefined | null
};
	["Contember_ProductCategoryListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["Contember_BusinessCategoryOrderBy"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["Contember_BusinessCategoryOrderBy"] | undefined | null
};
	["Contember_BusinessToBusinessManagerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_StaffOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	bartender?: ValueTypes["Contember_BartenderOrderBy"] | undefined | null,
	courier?: ValueTypes["Contember_CourierOrderBy"] | undefined | null,
	cleaner?: ValueTypes["Contember_CleanerOrderBy"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_ShiftsManagerOrderBy"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_BusinessToBusinessManagerOrderBy"] | undefined | null,
	user?: ValueTypes["Contember_UserOrderBy"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileOrderBy"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StocksManagerOrderBy"] | undefined | null,
	photo?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	courierManager?: ValueTypes["Contember_CourierManagerOrderBy"] | undefined | null,
	picker?: ValueTypes["Contember_PickerOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_RemunerationProfileOrderBy"] | undefined | null,
	cashier?: ValueTypes["Contember_CashierOrderBy"] | undefined | null,
	internalBuyer?: ValueTypes["Contember_InternalBuyerOrderBy"] | undefined | null,
	salesManager?: ValueTypes["Contember_SalesManagerOrderBy"] | undefined | null,
	cashierManager?: ValueTypes["Contember_CashierManagerOrderBy"] | undefined | null
};
	["Contember_BartenderOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CourierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	showInPicker?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CleanerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	canReachTopShelf?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ShiftsManagerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_UserOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	tenantPersonId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	disabledAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	verifiedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	email?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deletionRequestedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deletionExpectedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deletedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	meta?: ValueTypes["Contember_UserMetadataOrderBy"] | undefined | null,
	roles?: ValueTypes["Contember_UserRolesOrderBy"] | undefined | null
};
	["Contember_UserMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	userProfileUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tenantEmail?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tenantPersonId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tenantIdentityId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	globalRoles?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	projectRoles?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	projectRolesString?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	externalIdentifiers?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	externalIdentifiersString?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	idpSlugs?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	idpSlugsString?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	user?: ValueTypes["Contember_UserOrderBy"] | undefined | null
};
	["Contember_UserRolesOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isCustomer?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isStaff?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isBartender?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isCashier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isCourier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isCleaner?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isShiftsManager?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isInternalBuyer?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isStocksManager?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isCourierManager?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isCashierManager?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isBusinessToBusinessManager?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isPicker?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	user?: ValueTypes["Contember_UserOrderBy"] | undefined | null
};
	["Contember_ShiftsProfileOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	allowSelfUnassigning?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_StocksManagerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CourierManagerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PickerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_RemunerationProfileOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	workMonths?: ValueTypes["Contember_WorkMonthOrderBy"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null
};
	["Contember_WorkMonthOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	year?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	month?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	hoursWorked?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	billableHours?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CashierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null
};
	["Contember_InternalBuyerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null
};
	["Contember_SalesManagerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CashierManagerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	staff?: ValueTypes["Contember_StaffOrderBy"] | undefined | null
};
	["Contember_DoubleshotCoffeeEquipmentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	espressoMachine?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	espressoGrinder?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	batchBrew?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	batchGrinder?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	waterFilter?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	mechanicalFilter?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ApplicationShortcutListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_InvoicingPlanOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	preset?: ValueTypes["Contember_InvoicingPresetOrderBy"] | undefined | null
};
	["Contember_InvoicingPresetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	startsAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	endsAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	intervalOfDays?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_InvoicingPlanOrderBy"] | undefined | null
};
	["Contember_BillingSubjectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fullName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	addressLine1?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	addressLine2?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	city?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	postalCode?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	email?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	phone?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	companyName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	companyIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	externalIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	hidden?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deletedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fakturoidSubjectId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	country?: ValueTypes["Contember_CountryOrderBy"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_CountryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_AnonymousSessionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	lastActiveAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	anonymousSessionKey?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	email?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_CustomerSpendingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	totalCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_CustomerMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isBusiness?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fallbackName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	remainingCreditsCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ordersTotalCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customerProfileUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_DeliveryZoneOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_AddressMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveryZoneType?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	address?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	deliveryZone?: ValueTypes["Contember_DeliveryZoneOrderBy"] | undefined | null
};
	["Contember_GpsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	latitude?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	longitude?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	address?: ValueTypes["Contember_AddressOrderBy"] | undefined | null
};
	["Contember_CustomerPaymentMethod"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerPaymentMethodMeta"],
	id?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
paymentMethod?: [{	filter?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null},ValueTypes["PaymentMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerPaymentMethodMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	caption?:ValueTypes["Contember_FieldMeta"],
	data?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	paymentMethod?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Json"]:unknown;
	["PaymentMethod"]: AliasType<{
	_meta?:ValueTypes["PaymentMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
deliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	blockingDispatch?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
channels?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
	reduceGratuityByPercentage?:boolean | `@${string}`,
paginateDeliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
paginateChannels?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	enabled?:ValueTypes["Contember_FieldMeta"],
	deliveryMethods?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	blockingDispatch?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	channels?:ValueTypes["Contember_FieldMeta"],
	reduceGratuityByPercentage?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethod"]: AliasType<{
	_meta?:ValueTypes["DeliveryMethodMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
paymentMethods?: [{	filter?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PaymentMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["PaymentMethod"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	requireDeliveryAddress?:boolean | `@${string}`,
suitableForDeliveryZones?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryZone"]],
channels?: [{	filter?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
courierHandlers?: [{	filter?: ValueTypes["Contember_CourierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CourierOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Courier"]],
	enableDeliveryTracking?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryMethodLocale"]],
timelinePreset?: [{	filter?: ValueTypes["Contember_DeliveryTimelinePresetWhere"] | undefined | null},ValueTypes["Contember_DeliveryTimelinePreset"]],
localesByLocale?: [{	by: ValueTypes["Contember_DeliveryMethodLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["Contember_DeliveryMethodLocale"]],
paginatePaymentMethods?: [{	filter?: ValueTypes["Contember_PaymentMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PaymentMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["PaymentMethodConnection"]],
paginateSuitableForDeliveryZones?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryZoneConnection"]],
paginateChannels?: [{	filter?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
paginateCourierHandlers?: [{	filter?: ValueTypes["Contember_CourierWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CourierOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CourierConnection"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryMethodLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	enabled?:ValueTypes["Contember_FieldMeta"],
	paymentMethods?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	requireDeliveryAddress?:ValueTypes["Contember_FieldMeta"],
	suitableForDeliveryZones?:ValueTypes["Contember_FieldMeta"],
	channels?:ValueTypes["Contember_FieldMeta"],
	courierHandlers?:ValueTypes["Contember_FieldMeta"],
	enableDeliveryTracking?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	timelinePreset?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryZone"]: AliasType<{
	_meta?:ValueTypes["Contember_DeliveryZoneMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
addresses?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AddressOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Address"]],
suitableDeliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
deliveryZonesOfTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
deliveryZonesOfProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
addressesMetadata?: [{	filter?: ValueTypes["Contember_AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AddressMetadataOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_AddressMetadata"]],
addressesByMeta?: [{	by: ValueTypes["Contember_DeliveryZoneAddressesByMetaUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByGps?: [{	by: ValueTypes["Contember_DeliveryZoneAddressesByGpsUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByInvoices?: [{	by: ValueTypes["Contember_DeliveryZoneAddressesByInvoicesUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByBillingAddressOfOrders?: [{	by: ValueTypes["Contember_DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDeliveryAddressOfOrderDeliveries?: [{	by: ValueTypes["Contember_DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesByDefaultDeliveryAddressOfCustomer?: [{	by: ValueTypes["Contember_DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"],	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
addressesMetadataByAddress?: [{	by: ValueTypes["Contember_DeliveryZoneAddressesMetadataByAddressUniqueWhere"],	filter?: ValueTypes["Contember_AddressMetadataWhere"] | undefined | null},ValueTypes["Contember_AddressMetadata"]],
paginateAddresses?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AddressOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["AddressConnection"]],
paginateSuitableDeliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
paginateDeliveryZonesOfTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
paginateDeliveryZonesOfProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateAddressesMetadata?: [{	filter?: ValueTypes["Contember_AddressMetadataWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AddressMetadataOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_AddressMetadataConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryZoneMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	addresses?:ValueTypes["Contember_FieldMeta"],
	suitableDeliveryMethods?:ValueTypes["Contember_FieldMeta"],
	deliveryZonesOfTags?:ValueTypes["Contember_FieldMeta"],
	deliveryZonesOfProducts?:ValueTypes["Contember_FieldMeta"],
	addressesMetadata?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryMethodOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	enabled?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireDeliveryAddress?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	enableDeliveryTracking?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryTimelinePresetOrderBy"] | undefined | null
};
	["Contember_DeliveryTimelinePresetOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	duration?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	window?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveryMethod?: ValueTypes["Contember_DeliveryMethodOrderBy"] | undefined | null
};
	["Contember_Tag"]: AliasType<{
	_meta?:ValueTypes["Contember_TagMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
customers?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Customer"]],
products?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
categories?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Channel"]],
nestedCategories?: [{	filter?: ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InheritedProductCategoryTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_InheritedProductCategoryTag"]],
deliveryZones?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryZoneOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryZone"]],
publicInChannels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Channel"]],
allowChannelPayments?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
fulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_FulfillmentNote"]],
paginateCustomers?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerConnection"]],
paginateProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateCategories?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryConnection"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ChannelConnection"]],
paginateNestedCategories?: [{	filter?: ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InheritedProductCategoryTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_InheritedProductCategoryTagConnection"]],
paginateDeliveryZones?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryZoneOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryZoneConnection"]],
paginatePublicInChannels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ChannelConnection"]],
paginateAllowChannelPayments?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TagMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	customers?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	categories?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	addTagsOnRegistration?:ValueTypes["Contember_FieldMeta"],
	nestedCategories?:ValueTypes["Contember_FieldMeta"],
	deliveryZones?:ValueTypes["Contember_FieldMeta"],
	publicInChannels?:ValueTypes["Contember_FieldMeta"],
	allowChannelPayments?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNotes?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ProductMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	priceCents?:ValueTypes["Contember_FieldMeta"],
	notDiscountable?:ValueTypes["Contember_FieldMeta"],
	packing?:ValueTypes["Contember_FieldMeta"],
	recipe?:ValueTypes["Contember_FieldMeta"],
	tags?:ValueTypes["Contember_FieldMeta"],
	categories?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	stocks?:ValueTypes["Contember_FieldMeta"],
	groupPrices?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	cartItems?:ValueTypes["Contember_FieldMeta"],
	availability?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	manager?:ValueTypes["Contember_FieldMeta"],
	orderItems?:ValueTypes["Contember_FieldMeta"],
	maximumQuantityPerOrder?:ValueTypes["Contember_FieldMeta"],
	inheritedCategories?:ValueTypes["Contember_FieldMeta"],
	deliveryZones?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	archivedAt?:ValueTypes["Contember_FieldMeta"],
	customerPrices?:ValueTypes["Contember_FieldMeta"],
	virtualProduct?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
	page?:ValueTypes["Contember_FieldMeta"],
	salesDays?:ValueTypes["Contember_FieldMeta"],
	salesWeeks?:ValueTypes["Contember_FieldMeta"],
	salesMonths?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNotes?:ValueTypes["Contember_FieldMeta"],
	hasSubscription?:ValueTypes["Contember_FieldMeta"],
	pointOfSaleTileColor?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductPacking"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductPackingMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	volumeMl?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	shortName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductPackingLocale"]],
preparedPackingQuantities?: [{	filter?: ValueTypes["Contember_PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PreparedPackingQuantityOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PreparedPackingQuantity"]],
	isPackedInCardboard?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["Contember_ProductPackingLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductPackingLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductPackingLocaleConnection"]],
paginatePreparedPackingQuantities?: [{	filter?: ValueTypes["Contember_PreparedPackingQuantityWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PreparedPackingQuantityOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PreparedPackingQuantityConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductPackingMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	volumeMl?:ValueTypes["Contember_FieldMeta"],
	icon?:ValueTypes["Contember_FieldMeta"],
	shortName?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	preparedPackingQuantities?:ValueTypes["Contember_FieldMeta"],
	isPackedInCardboard?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Image"]: AliasType<{
	_meta?:ValueTypes["Contember_ImageMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	alt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ImageMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	url?:ValueTypes["Contember_FieldMeta"],
	width?:ValueTypes["Contember_FieldMeta"],
	height?:ValueTypes["Contember_FieldMeta"],
	size?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	alt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductPackingLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductPackingLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	shortName?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_ProductPackingWhere"] | undefined | null},ValueTypes["Contember_ProductPacking"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductPackingLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	shortName?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Locale"]: AliasType<{
	_meta?:ValueTypes["Contember_LocaleMeta"],
	id?:boolean | `@${string}`,
businessCategories?: [{	filter?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessCategoryLocale"]],
productCategoryLists?: [{	filter?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategoryListLocale"]],
productIngredients?: [{	filter?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredientLocale"]],
orderRecurrences?: [{	filter?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_OrderRecurrenceLocale"]],
blogs?: [{	filter?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BlogLocale"]],
blogPosts?: [{	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
identifier?: [{	filter?: ValueTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["Contember_TranslationCataloguesIdentifier"]],
menus?: [{	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_MenuLocale"]],
pages?: [{	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PageLocale"]],
productIngredientCategories?: [{	filter?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredientCategoryLocale"]],
allergens?: [{	filter?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_AllergenLocale"]],
productPackings?: [{	filter?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductPackingLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductPackingLocale"]],
products?: [{	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductLocale"]],
productRecipes?: [{	filter?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductRecipeLocale"]],
pointOfSaleCategories?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryLocale"]],
pointOfSaleCategoryProducts?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProductLocale"]],
deliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryMethodLocale"]],
ingredientSuppliers?: [{	filter?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IngredientSupplierLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_IngredientSupplierLocale"]],
identificationCodes?: [{	filter?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_IdentificationCodeLocale"]],
pipelineStages?: [{	filter?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PipelineStageLocale"]],
subscriptionBoxes?: [{	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
businessServices?: [{	filter?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessServiceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessServiceLocale"]],
businessCategoriesByRoot?: [{	by: ValueTypes["Contember_LocaleBusinessCategoriesByRootUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["Contember_BusinessCategoryLocale"]],
productCategoryListsByRoot?: [{	by: ValueTypes["Contember_LocaleProductCategoryListsByRootUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductCategoryListLocale"]],
productIngredientsByRoot?: [{	by: ValueTypes["Contember_LocaleProductIngredientsByRootUniqueWhere"],	filter?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientLocale"]],
orderRecurrencesByRoot?: [{	by: ValueTypes["Contember_LocaleOrderRecurrencesByRootUniqueWhere"],	filter?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["Contember_OrderRecurrenceLocale"]],
blogsByRoot?: [{	by: ValueTypes["Contember_LocaleBlogsByRootUniqueWhere"],	filter?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogLocale"]],
blogPostsByRoot?: [{	by: ValueTypes["Contember_LocaleBlogPostsByRootUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
blogPostsByCover?: [{	by: ValueTypes["Contember_LocaleBlogPostsByCoverUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
blogPostsByContent?: [{	by: ValueTypes["Contember_LocaleBlogPostsByContentUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
blogPostsByLink?: [{	by: ValueTypes["Contember_LocaleBlogPostsByLinkUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
blogPostsBySeo?: [{	by: ValueTypes["Contember_LocaleBlogPostsBySeoUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
menusByRoot?: [{	by: ValueTypes["Contember_LocaleMenusByRootUniqueWhere"],	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null},ValueTypes["Contember_MenuLocale"]],
menusByItems?: [{	by: ValueTypes["Contember_LocaleMenusByItemsUniqueWhere"],	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null},ValueTypes["Contember_MenuLocale"]],
menusBySecondaryItems?: [{	by: ValueTypes["Contember_LocaleMenusBySecondaryItemsUniqueWhere"],	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null},ValueTypes["Contember_MenuLocale"]],
pagesByRoot?: [{	by: ValueTypes["Contember_LocalePagesByRootUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
pagesByLink?: [{	by: ValueTypes["Contember_LocalePagesByLinkUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
pagesBySeo?: [{	by: ValueTypes["Contember_LocalePagesBySeoUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
pagesByContent?: [{	by: ValueTypes["Contember_LocalePagesByContentUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
pagesByCover?: [{	by: ValueTypes["Contember_LocalePagesByCoverUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
productIngredientCategoriesByRoot?: [{	by: ValueTypes["Contember_LocaleProductIngredientCategoriesByRootUniqueWhere"],	filter?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientCategoryLocale"]],
allergensByRoot?: [{	by: ValueTypes["Contember_LocaleAllergensByRootUniqueWhere"],	filter?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null},ValueTypes["Contember_AllergenLocale"]],
productPackingsByRoot?: [{	by: ValueTypes["Contember_LocaleProductPackingsByRootUniqueWhere"],	filter?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductPackingLocale"]],
productsByRoot?: [{	by: ValueTypes["Contember_LocaleProductsByRootUniqueWhere"],	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductLocale"]],
productsByFeatureList?: [{	by: ValueTypes["Contember_LocaleProductsByFeatureListUniqueWhere"],	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductLocale"]],
productRecipesByRoot?: [{	by: ValueTypes["Contember_LocaleProductRecipesByRootUniqueWhere"],	filter?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductRecipeLocale"]],
pointOfSaleCategoriesByRoot?: [{	by: ValueTypes["Contember_LocalePointOfSaleCategoriesByRootUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategoryLocale"]],
pointOfSaleCategoryProductsByRoot?: [{	by: ValueTypes["Contember_LocalePointOfSaleCategoryProductsByRootUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProductLocale"]],
deliveryMethodsByRoot?: [{	by: ValueTypes["Contember_LocaleDeliveryMethodsByRootUniqueWhere"],	filter?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null},ValueTypes["Contember_DeliveryMethodLocale"]],
ingredientSuppliersByRoot?: [{	by: ValueTypes["Contember_LocaleIngredientSuppliersByRootUniqueWhere"],	filter?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null},ValueTypes["Contember_IngredientSupplierLocale"]],
identificationCodesByRoot?: [{	by: ValueTypes["Contember_LocaleIdentificationCodesByRootUniqueWhere"],	filter?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["Contember_IdentificationCodeLocale"]],
pipelineStagesByRoot?: [{	by: ValueTypes["Contember_LocalePipelineStagesByRootUniqueWhere"],	filter?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null},ValueTypes["Contember_PipelineStageLocale"]],
subscriptionBoxesByRoot?: [{	by: ValueTypes["Contember_LocaleSubscriptionBoxesByRootUniqueWhere"],	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
subscriptionBoxesByTexts?: [{	by: ValueTypes["Contember_LocaleSubscriptionBoxesByTextsUniqueWhere"],	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
subscriptionBoxesBySecondaryTexts?: [{	by: ValueTypes["Contember_LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"],	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
businessServicesByRoot?: [{	by: ValueTypes["Contember_LocaleBusinessServicesByRootUniqueWhere"],	filter?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null},ValueTypes["Contember_BusinessServiceLocale"]],
paginateBusinessCategories?: [{	filter?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessCategoryLocaleConnection"]],
paginateProductCategoryLists?: [{	filter?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryListLocaleConnection"]],
paginateProductIngredients?: [{	filter?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientLocaleConnection"]],
paginateOrderRecurrences?: [{	filter?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_OrderRecurrenceLocaleConnection"]],
paginateBlogs?: [{	filter?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BlogLocaleConnection"]],
paginateBlogPosts?: [{	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BlogPostLocaleConnection"]],
paginateMenus?: [{	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_MenuLocaleConnection"]],
paginatePages?: [{	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PageLocaleConnection"]],
paginateProductIngredientCategories?: [{	filter?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientCategoryLocaleConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_AllergenLocaleConnection"]],
paginateProductPackings?: [{	filter?: ValueTypes["Contember_ProductPackingLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductPackingLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductPackingLocaleConnection"]],
paginateProducts?: [{	filter?: ValueTypes["Contember_ProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductLocaleConnection"]],
paginateProductRecipes?: [{	filter?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductRecipeLocaleConnection"]],
paginatePointOfSaleCategories?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryLocaleConnection"]],
paginatePointOfSaleCategoryProducts?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProductLocaleConnection"]],
paginateDeliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryMethodLocaleConnection"]],
paginateIngredientSuppliers?: [{	filter?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IngredientSupplierLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_IngredientSupplierLocaleConnection"]],
paginateIdentificationCodes?: [{	filter?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_IdentificationCodeLocaleConnection"]],
paginatePipelineStages?: [{	filter?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PipelineStageLocaleConnection"]],
paginateSubscriptionBoxes?: [{	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_SubscriptionBoxLocaleConnection"]],
paginateBusinessServices?: [{	filter?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessServiceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessServiceLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	businessCategories?:ValueTypes["Contember_FieldMeta"],
	productCategoryLists?:ValueTypes["Contember_FieldMeta"],
	productIngredients?:ValueTypes["Contember_FieldMeta"],
	orderRecurrences?:ValueTypes["Contember_FieldMeta"],
	blogs?:ValueTypes["Contember_FieldMeta"],
	blogPosts?:ValueTypes["Contember_FieldMeta"],
	identifier?:ValueTypes["Contember_FieldMeta"],
	menus?:ValueTypes["Contember_FieldMeta"],
	pages?:ValueTypes["Contember_FieldMeta"],
	productIngredientCategories?:ValueTypes["Contember_FieldMeta"],
	allergens?:ValueTypes["Contember_FieldMeta"],
	productPackings?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	productRecipes?:ValueTypes["Contember_FieldMeta"],
	pointOfSaleCategories?:ValueTypes["Contember_FieldMeta"],
	pointOfSaleCategoryProducts?:ValueTypes["Contember_FieldMeta"],
	deliveryMethods?:ValueTypes["Contember_FieldMeta"],
	ingredientSuppliers?:ValueTypes["Contember_FieldMeta"],
	identificationCodes?:ValueTypes["Contember_FieldMeta"],
	pipelineStages?:ValueTypes["Contember_FieldMeta"],
	subscriptionBoxes?:ValueTypes["Contember_FieldMeta"],
	businessServices?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_BusinessCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null},ValueTypes["Contember_BusinessCategory"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategory"]: AliasType<{
	_meta?:ValueTypes["Contember_BusinessCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	visibleToNewCustomers?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessCategoryLocale"]],
	internalName?:boolean | `@${string}`,
businessCustomers?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
recurrences?: [{	filter?: ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderRecurrenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_OrderRecurrence"]],
specificProducts?: [{	filter?: ValueTypes["Contember_ProductListWhere"] | undefined | null},ValueTypes["Contember_ProductList"]],
seasonalProductCategories?: [{	filter?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null},ValueTypes["Contember_ProductCategoryList"]],
specificProductsProductCategories?: [{	filter?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null},ValueTypes["Contember_ProductCategoryList"]],
	slug?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["Contember_BusinessCategoryLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null},ValueTypes["Contember_BusinessCategoryLocale"]],
businessCustomersByCustomer?: [{	by: ValueTypes["Contember_BusinessCategoryBusinessCustomersByCustomerUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
businessCustomersByBusinessServiceEvents?: [{	by: ValueTypes["Contember_BusinessCategoryBusinessCustomersByBusinessServiceEventsUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
businessCustomersByDoubleshotCoffeeEquipment?: [{	by: ValueTypes["Contember_BusinessCategoryBusinessCustomersByDoubleshotCoffeeEquipmentUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_BusinessCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessCategoryLocaleConnection"]],
paginateBusinessCustomers?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessCustomerConnection"]],
paginateRecurrences?: [{	filter?: ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderRecurrenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_OrderRecurrenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	visibleToNewCustomers?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	businessCustomers?:ValueTypes["Contember_FieldMeta"],
	recurrences?:ValueTypes["Contember_FieldMeta"],
	specificProducts?:ValueTypes["Contember_FieldMeta"],
	seasonalProductCategories?:ValueTypes["Contember_FieldMeta"],
	specificProductsProductCategories?:ValueTypes["Contember_FieldMeta"],
	slug?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_BusinessCategoryOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_LocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_BusinessCustomer"]: AliasType<{
	_meta?:ValueTypes["Contember_BusinessCustomerMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
owners?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Customer"]],
members?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Customer"]],
category?: [{	filter?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null},ValueTypes["Contember_BusinessCategory"]],
accountManager?: [{	filter?: ValueTypes["Contember_BusinessToBusinessManagerWhere"] | undefined | null},ValueTypes["Contember_BusinessToBusinessManager"]],
logo?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	note?:boolean | `@${string}`,
businessServiceEvents?: [{	filter?: ValueTypes["Contember_BusinessServiceEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessServiceEventOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessServiceEvent"]],
doubleshotCoffeeEquipment?: [{	filter?: ValueTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined | null},ValueTypes["Contember_DoubleshotCoffeeEquipment"]],
paginateOwners?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerConnection"]],
paginateMembers?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerConnection"]],
paginateBusinessServiceEvents?: [{	filter?: ValueTypes["Contember_BusinessServiceEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessServiceEventOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessServiceEventConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCustomerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	owners?:ValueTypes["Contember_FieldMeta"],
	members?:ValueTypes["Contember_FieldMeta"],
	category?:ValueTypes["Contember_FieldMeta"],
	accountManager?:ValueTypes["Contember_FieldMeta"],
	logo?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	businessServiceEvents?:ValueTypes["Contember_FieldMeta"],
	doubleshotCoffeeEquipment?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessToBusinessManager"]: AliasType<{
	_meta?:ValueTypes["Contember_BusinessToBusinessManagerMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
managedBusinesses?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
	createdAt?:boolean | `@${string}`,
managedBusinessesByCustomer?: [{	by: ValueTypes["Contember_BusinessToBusinessManagerManagedBusinessesByCustomerUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
managedBusinessesByBusinessServiceEvents?: [{	by: ValueTypes["Contember_BusinessToBusinessManagerManagedBusinessesByBusinessServiceEventsUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
managedBusinessesByDoubleshotCoffeeEquipment?: [{	by: ValueTypes["Contember_BusinessToBusinessManagerManagedBusinessesByDoubleshotCoffeeEquipmentUniqueWhere"],	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
paginateManagedBusinesses?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessCustomerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessToBusinessManagerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	managedBusinesses?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Staff"]: AliasType<{
	_meta?:ValueTypes["Contember_StaffMeta"],
	id?:boolean | `@${string}`,
bartender?: [{	filter?: ValueTypes["Contember_BartenderWhere"] | undefined | null},ValueTypes["Contember_Bartender"]],
courier?: [{	filter?: ValueTypes["Contember_CourierWhere"] | undefined | null},ValueTypes["Contember_Courier"]],
cleaner?: [{	filter?: ValueTypes["Contember_CleanerWhere"] | undefined | null},ValueTypes["Contember_Cleaner"]],
shiftsManager?: [{	filter?: ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null},ValueTypes["Contember_ShiftsManager"]],
businessToBusinessManager?: [{	filter?: ValueTypes["Contember_BusinessToBusinessManagerWhere"] | undefined | null},ValueTypes["Contember_BusinessToBusinessManager"]],
user?: [{	filter?: ValueTypes["Contember_UserWhere"] | undefined | null},ValueTypes["Contember_User"]],
shiftsProfile?: [{	filter?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null},ValueTypes["Contember_ShiftsProfile"]],
stocksManager?: [{	filter?: ValueTypes["Contember_StocksManagerWhere"] | undefined | null},ValueTypes["Contember_StocksManager"]],
photo?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
courierManager?: [{	filter?: ValueTypes["Contember_CourierManagerWhere"] | undefined | null},ValueTypes["Contember_CourierManager"]],
picker?: [{	filter?: ValueTypes["Contember_PickerWhere"] | undefined | null},ValueTypes["Contember_Picker"]],
	createdAt?:boolean | `@${string}`,
remunerationProfile?: [{	filter?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null},ValueTypes["Contember_RemunerationProfile"]],
cashier?: [{	filter?: ValueTypes["Contember_CashierWhere"] | undefined | null},ValueTypes["Contember_Cashier"]],
venues?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Venue"]],
internalBuyer?: [{	filter?: ValueTypes["Contember_InternalBuyerWhere"] | undefined | null},ValueTypes["Contember_InternalBuyer"]],
salesManager?: [{	filter?: ValueTypes["Contember_SalesManagerWhere"] | undefined | null},ValueTypes["Contember_SalesManager"]],
cashierManager?: [{	filter?: ValueTypes["Contember_CashierManagerWhere"] | undefined | null},ValueTypes["Contember_CashierManager"]],
paginateVenues?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VenueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_StaffMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	bartender?:ValueTypes["Contember_FieldMeta"],
	courier?:ValueTypes["Contember_FieldMeta"],
	cleaner?:ValueTypes["Contember_FieldMeta"],
	shiftsManager?:ValueTypes["Contember_FieldMeta"],
	businessToBusinessManager?:ValueTypes["Contember_FieldMeta"],
	user?:ValueTypes["Contember_FieldMeta"],
	shiftsProfile?:ValueTypes["Contember_FieldMeta"],
	stocksManager?:ValueTypes["Contember_FieldMeta"],
	photo?:ValueTypes["Contember_FieldMeta"],
	courierManager?:ValueTypes["Contember_FieldMeta"],
	picker?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	remunerationProfile?:ValueTypes["Contember_FieldMeta"],
	cashier?:ValueTypes["Contember_FieldMeta"],
	venues?:ValueTypes["Contember_FieldMeta"],
	internalBuyer?:ValueTypes["Contember_FieldMeta"],
	salesManager?:ValueTypes["Contember_FieldMeta"],
	cashierManager?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Bartender"]: AliasType<{
	_meta?:ValueTypes["Contember_BartenderMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_BartenderMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Courier"]: AliasType<{
	_meta?:ValueTypes["Contember_CourierMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
routes?: [{	filter?: ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryRouteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryRoute"]],
	createdAt?:boolean | `@${string}`,
deliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["DeliveryMethod"]],
	showInPicker?:boolean | `@${string}`,
routesByWaypoints?: [{	by: ValueTypes["Contember_CourierRoutesByWaypointsUniqueWhere"],	filter?: ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null},ValueTypes["Contember_DeliveryRoute"]],
paginateRoutes?: [{	filter?: ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryRouteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryRouteConnection"]],
paginateDeliveryMethods?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryMethodOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["DeliveryMethodConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CourierMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	routes?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	deliveryMethods?:ValueTypes["Contember_FieldMeta"],
	showInPicker?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRoute"]: AliasType<{
	_meta?:ValueTypes["Contember_DeliveryRouteMeta"],
	id?:boolean | `@${string}`,
courier?: [{	filter?: ValueTypes["Contember_CourierWhere"] | undefined | null},ValueTypes["Contember_Courier"]],
	createdAt?:boolean | `@${string}`,
waypoints?: [{	filter?: ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryWaypointOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryWaypoint"]],
	dispatchAt?:boolean | `@${string}`,
waypointsByDelivery?: [{	by: ValueTypes["Contember_DeliveryRouteWaypointsByDeliveryUniqueWhere"],	filter?: ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null},ValueTypes["Contember_DeliveryWaypoint"]],
paginateWaypoints?: [{	filter?: ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryWaypointOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryWaypointConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRouteMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	courier?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	waypoints?:ValueTypes["Contember_FieldMeta"],
	dispatchAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryWaypoint"]: AliasType<{
	_meta?:ValueTypes["Contember_DeliveryWaypointMeta"],
	id?:boolean | `@${string}`,
	estimatedArrival?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
route?: [{	filter?: ValueTypes["Contember_DeliveryRouteWhere"] | undefined | null},ValueTypes["Contember_DeliveryRoute"]],
	sortingOrder?:boolean | `@${string}`,
delivery?: [{	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
	estimatedDistance?:boolean | `@${string}`,
	estimatedDuration?:boolean | `@${string}`,
	originDeparture?:boolean | `@${string}`,
	originAddress?:boolean | `@${string}`,
	transitMode?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryWaypointMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	estimatedArrival?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	route?:ValueTypes["Contember_FieldMeta"],
	sortingOrder?:ValueTypes["Contember_FieldMeta"],
	delivery?:ValueTypes["Contember_FieldMeta"],
	estimatedDistance?:ValueTypes["Contember_FieldMeta"],
	estimatedDuration?:ValueTypes["Contember_FieldMeta"],
	originDeparture?:ValueTypes["Contember_FieldMeta"],
	originAddress?:ValueTypes["Contember_FieldMeta"],
	transitMode?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDelivery"]: AliasType<{
	_meta?:ValueTypes["OrderDeliveryMeta"],
	id?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	deliveredAt?:boolean | `@${string}`,
method?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
	expectedAt?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	loadingDurationHours?:boolean | `@${string}`,
	dispatchAt?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
	expectedEndAt?:boolean | `@${string}`,
	processingAt?:boolean | `@${string}`,
	deliveringAt?:boolean | `@${string}`,
	failedAt?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
deliveryWaypoint?: [{	filter?: ValueTypes["Contember_DeliveryWaypointWhere"] | undefined | null},ValueTypes["Contember_DeliveryWaypoint"]],
events?: [{	filter?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderEventOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_OrderEvent"]],
	prepareAt?:boolean | `@${string}`,
liftagoRide?: [{	filter?: ValueTypes["Contember_LiftagoRideWhere"] | undefined | null},ValueTypes["Contember_LiftagoRide"]],
	pickedAt?:boolean | `@${string}`,
address?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
	trackingCode?:boolean | `@${string}`,
paginateEvents?: [{	filter?: ValueTypes["Contember_OrderEventWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderEventOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_OrderEventConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderDeliveryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	priceCents?:ValueTypes["Contember_FieldMeta"],
	deliveredAt?:ValueTypes["Contember_FieldMeta"],
	method?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	expectedAt?:ValueTypes["Contember_FieldMeta"],
	state?:ValueTypes["Contember_FieldMeta"],
	loadingDurationHours?:ValueTypes["Contember_FieldMeta"],
	dispatchAt?:ValueTypes["Contember_FieldMeta"],
	duration?:ValueTypes["Contember_FieldMeta"],
	window?:ValueTypes["Contember_FieldMeta"],
	expectedEndAt?:ValueTypes["Contember_FieldMeta"],
	processingAt?:ValueTypes["Contember_FieldMeta"],
	deliveringAt?:ValueTypes["Contember_FieldMeta"],
	failedAt?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	deliveryWaypoint?:ValueTypes["Contember_FieldMeta"],
	events?:ValueTypes["Contember_FieldMeta"],
	prepareAt?:ValueTypes["Contember_FieldMeta"],
	liftagoRide?:ValueTypes["Contember_FieldMeta"],
	pickedAt?:ValueTypes["Contember_FieldMeta"],
	address?:ValueTypes["Contember_FieldMeta"],
	trackingCode?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["VatRateMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	ratePermille?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	channelDeliveries?:ValueTypes["Contember_FieldMeta"],
	channelPayments?:ValueTypes["Contember_FieldMeta"],
	preset?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDeliveryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	priceCents?:ValueTypes["Contember_FieldMeta"],
	channel?:ValueTypes["Contember_FieldMeta"],
	method?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	defaultVisible?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Channel"]: AliasType<{
	_meta?:ValueTypes["Contember_ChannelMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	disabled?:boolean | `@${string}`,
payments?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelPayment"]],
deliveries?: [{	filter?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelDeliveryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["ChannelDelivery"]],
pointOfSales?: [{	filter?: ValueTypes["Contember_PointOfSaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSale"]],
carts?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Cart"]],
customers?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Customer"]],
	code?:boolean | `@${string}`,
addTagsOnRegistration?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
publicTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
cartsByItems?: [{	by: ValueTypes["Contember_ChannelCartsByItemsUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
cartsByOrder?: [{	by: ValueTypes["Contember_ChannelCartsByOrderUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
cartsByPriceLines?: [{	by: ValueTypes["Contember_ChannelCartsByPriceLinesUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
cartsBySummary?: [{	by: ValueTypes["Contember_ChannelCartsBySummaryUniqueWhere"],	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
paginatePayments?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelPaymentConnection"]],
paginateDeliveries?: [{	filter?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelDeliveryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ChannelDeliveryConnection"]],
paginatePointOfSales?: [{	filter?: ValueTypes["Contember_PointOfSaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleConnection"]],
paginateCarts?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CartConnection"]],
paginateCustomers?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerConnection"]],
paginateAddTagsOnRegistration?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
paginatePublicTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ChannelMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	disabled?:ValueTypes["Contember_FieldMeta"],
	payments?:ValueTypes["Contember_FieldMeta"],
	deliveries?:ValueTypes["Contember_FieldMeta"],
	pointOfSales?:ValueTypes["Contember_FieldMeta"],
	carts?:ValueTypes["Contember_FieldMeta"],
	customers?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	addTagsOnRegistration?:ValueTypes["Contember_FieldMeta"],
	publicTags?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelPaymentMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	channel?:ValueTypes["Contember_FieldMeta"],
	method?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	requireBillingAddress?:ValueTypes["Contember_FieldMeta"],
	provideReceipt?:ValueTypes["Contember_FieldMeta"],
	requireBillingSubject?:ValueTypes["Contember_FieldMeta"],
	allowOnlyForTags?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TagConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PageInfo"]: AliasType<{
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_TagEdge"]: AliasType<{
	node?:ValueTypes["Contember_Tag"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ChannelPaymentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelOrderBy"] | undefined | null,
	method?: ValueTypes["Contember_PaymentMethodOrderBy"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	requireBillingAddress?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	provideReceipt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireBillingSubject?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ChannelOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	disabled?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null
};
	["Contember_VenueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_DeadlineTemplateOrderBy"] | undefined | null,
	address?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	pointOfSalePreferPrintReceipt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_DeadlineTemplateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	startDate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	period?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	cutoff?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	closed?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null
};
	["Contember_VatRateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ratePermille?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	preset?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ChannelDeliveryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelOrderBy"] | undefined | null,
	method?: ValueTypes["Contember_DeliveryMethodOrderBy"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	defaultVisible?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PointOfSale"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
channels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Channel"]],
permissions?: [{	filter?: ValueTypes["Contember_PointOfSalePermissionsWhere"] | undefined | null},ValueTypes["Contember_PointOfSalePermissions"]],
	tenantPersonId?:boolean | `@${string}`,
paginateChannels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ChannelConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	channels?:ValueTypes["Contember_FieldMeta"],
	permissions?:ValueTypes["Contember_FieldMeta"],
	tenantPersonId?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSalePermissions"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSalePermissionsMeta"],
	id?:boolean | `@${string}`,
	canPlaceOrder?:boolean | `@${string}`,
pointOfSale?: [{	filter?: ValueTypes["Contember_PointOfSaleWhere"] | undefined | null},ValueTypes["Contember_PointOfSale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSalePermissionsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	canPlaceOrder?:ValueTypes["Contember_FieldMeta"],
	pointOfSale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ChannelConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ChannelEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ChannelEdge"]: AliasType<{
	node?:ValueTypes["Contember_Channel"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	permissions?: ValueTypes["Contember_PointOfSalePermissionsOrderBy"] | undefined | null,
	tenantPersonId?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PointOfSalePermissionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	canPlaceOrder?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	pointOfSale?: ValueTypes["Contember_PointOfSaleOrderBy"] | undefined | null
};
	["Contember_Cart"]: AliasType<{
	_meta?:ValueTypes["Contember_CartMeta"],
	id?:boolean | `@${string}`,
	confirmedAt?:boolean | `@${string}`,
	deletedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CartItem"]],
	handle?:boolean | `@${string}`,
	state?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
channel?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
deliveryAddress?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
billingAddress?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
	deliveryOption?:boolean | `@${string}`,
	paymentOption?:boolean | `@${string}`,
delivery?: [{	filter?: ValueTypes["Contember_ChannelDeliveryWhere"] | undefined | null},ValueTypes["ChannelDelivery"]],
payment?: [{	filter?: ValueTypes["Contember_ChannelPaymentWhere"] | undefined | null},ValueTypes["ChannelPayment"]],
billingSubject?: [{	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null},ValueTypes["Contember_BillingSubject"]],
priceLines?: [{	filter?: ValueTypes["Contember_CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartPriceLineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CartPriceLine"]],
summary?: [{	filter?: ValueTypes["Contember_CartSummaryWhere"] | undefined | null},ValueTypes["Contember_CartSummary"]],
itemsByProduct?: [{	by: ValueTypes["Contember_CartItemsByProductUniqueWhere"],	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null},ValueTypes["Contember_CartItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CartItemConnection"]],
paginatePriceLines?: [{	filter?: ValueTypes["Contember_CartPriceLineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartPriceLineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CartPriceLineConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	confirmedAt?:ValueTypes["Contember_FieldMeta"],
	deletedAt?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	handle?:ValueTypes["Contember_FieldMeta"],
	state?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	channel?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	deliveryAddress?:ValueTypes["Contember_FieldMeta"],
	billingAddress?:ValueTypes["Contember_FieldMeta"],
	deliveryOption?:ValueTypes["Contember_FieldMeta"],
	paymentOption?:ValueTypes["Contember_FieldMeta"],
	delivery?:ValueTypes["Contember_FieldMeta"],
	payment?:ValueTypes["Contember_FieldMeta"],
	billingSubject?:ValueTypes["Contember_FieldMeta"],
	priceLines?:ValueTypes["Contember_FieldMeta"],
	summary?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartItem"]: AliasType<{
	_meta?:ValueTypes["Contember_CartItemMeta"],
	id?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
cart?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	fulfillmentNote?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_FulfillmentNote"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	quantity?:ValueTypes["Contember_FieldMeta"],
	cart?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	updatedAt?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNote?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNotes?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FulfillmentNote"]: AliasType<{
	_meta?:ValueTypes["Contember_FulfillmentNoteMeta"],
	id?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
tags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
venues?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Venue"]],
cartItems?: [{	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CartItem"]],
orderItems?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderItem"]],
orders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
	highlight?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	emoji?:boolean | `@${string}`,
paginateProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
paginateVenues?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VenueConnection"]],
paginateCartItems?: [{	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CartItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CartItemConnection"]],
paginateOrderItems?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderItemConnection"]],
paginateOrders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FulfillmentNoteMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	tags?:ValueTypes["Contember_FieldMeta"],
	venues?:ValueTypes["Contember_FieldMeta"],
	cartItems?:ValueTypes["Contember_FieldMeta"],
	orderItems?:ValueTypes["Contember_FieldMeta"],
	orders?:ValueTypes["Contember_FieldMeta"],
	highlight?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	emoji?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	notDiscountable?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	packing?: ValueTypes["Contember_ProductPackingOrderBy"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductRecipeOrderBy"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	availability?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	manager?: ValueTypes["Contember_StocksManagerOrderBy"] | undefined | null,
	maximumQuantityPerOrder?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	archivedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	virtualProduct?: ValueTypes["Contember_VirtualProductOrderBy"] | undefined | null,
	meta?: ValueTypes["Contember_ProductMetadataOrderBy"] | undefined | null,
	page?: ValueTypes["Contember_PageOrderBy"] | undefined | null,
	hasSubscription?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	pointOfSaleTileColor?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductPackingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	volumeMl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	icon?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	shortName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isPackedInCardboard?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductRecipeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	color?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	icon?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	needsPrepping?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	gutTuning?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_FreshingContainerOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	nutritionFacts?: ValueTypes["Contember_NutritionFactsOrderBy"] | undefined | null
};
	["Contember_FreshingContainerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	volumeMl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	workspace?: ValueTypes["Contember_WorkspaceOrderBy"] | undefined | null
};
	["Contember_WorkspaceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unique?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderDeadlineTime?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ordersLoadingDurationHours?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	freshingContainerVolumeMl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	checkoutUrlTemplate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderUrlTemplate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	paymentUrlTemplate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customerProfileUrlTemplate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	userProfileUrlTemplate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	messengerUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	messengerIosUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	messengerAndroidUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	openingHours?: ValueTypes["Contember_WeekHoursOrderBy"] | undefined | null,
	workingHours?: ValueTypes["Contember_WeekHoursOrderBy"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["Contember_FreshingContainerOrderBy"] | undefined | null,
	creditsProduct?: ValueTypes["Contember_VirtualProductOrderBy"] | undefined | null,
	discountVatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	businessAddress?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	migrationTest?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_WeekHoursOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	mon?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tue?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	wed?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	thu?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fri?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	sat?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	sun?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_VirtualProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	voucherCreditCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	physicalRepresentation?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	recipientEmail?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_NutritionFactsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	energyKiloJoule?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	energyKiloCalorie?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fatsGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fatsOfWhichSaturatedGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	carbohydrateGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	carbohydrateOfWhichSugarsGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	proteinGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fibreGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	saltGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	archived?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	available?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	availableQuantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_PageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_Venue"]: AliasType<{
	_meta?:ValueTypes["Contember_VenueMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
deadlineTemplate?: [{	filter?: ValueTypes["Contember_DeadlineTemplateWhere"] | undefined | null},ValueTypes["Contember_DeadlineTemplate"]],
timetableDays?: [{	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableDayOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableDay"]],
timetableTemplates?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
staffMembers?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_StaffOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Staff"]],
employeeRoles?: [{	filter?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_VenueEmployeeRole"]],
pointOfSaleApplicationDevices?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
pointOfSaleTerminals?: [{	filter?: ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleTerminalOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleTerminal"]],
pointOfSalePrinters?: [{	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSalePrinterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSalePrinter"]],
orders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
fulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_FulfillmentNote"]],
areas?: [{	filter?: ValueTypes["Contember_AreaWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AreaOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Area"]],
enabledUnassignReasons?: [{	filter?: ValueTypes["Contember_UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_UnassignReasonOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_UnassignReason"]],
categories?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategory"]],
channels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Channel"]],
	address?:boolean | `@${string}`,
	pointOfSalePngBase64ReceiptLogo?:boolean | `@${string}`,
	pointOfSaleInitialView?:boolean | `@${string}`,
	pointOfSalePreferPrintReceipt?:boolean | `@${string}`,
timetableDaysByDate?: [{	by: ValueTypes["Contember_VenueTimetableDaysByDateUniqueWhere"],	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null},ValueTypes["Contember_TimetableDay"]],
timetableDaysByShiftGroups?: [{	by: ValueTypes["Contember_VenueTimetableDaysByShiftGroupsUniqueWhere"],	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null},ValueTypes["Contember_TimetableDay"]],
timetableDaysByNotes?: [{	by: ValueTypes["Contember_VenueTimetableDaysByNotesUniqueWhere"],	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null},ValueTypes["Contember_TimetableDay"]],
timetableDaysByMeta?: [{	by: ValueTypes["Contember_VenueTimetableDaysByMetaUniqueWhere"],	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null},ValueTypes["Contember_TimetableDay"]],
timetableTemplatesByStaffOptions?: [{	by: ValueTypes["Contember_VenueTimetableTemplatesByStaffOptionsUniqueWhere"],	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
employeeRolesByRole?: [{	by: ValueTypes["Contember_VenueEmployeeRolesByRoleUniqueWhere"],	filter?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["Contember_VenueEmployeeRole"]],
employeeRolesByEmployeePrivileges?: [{	by: ValueTypes["Contember_VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"],	filter?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["Contember_VenueEmployeeRole"]],
pointOfSaleApplicationDevicesByIdentifier?: [{	by: ValueTypes["Contember_VenuePointOfSaleApplicationDevicesByIdentifierUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
pointOfSaleTerminalsByApplicationDevices?: [{	by: ValueTypes["Contember_VenuePointOfSaleTerminalsByApplicationDevicesUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleTerminal"]],
pointOfSalePrintersByApplicationDevicesReceipt?: [{	by: ValueTypes["Contember_VenuePointOfSalePrintersByApplicationDevicesReceiptUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null},ValueTypes["Contember_PointOfSalePrinter"]],
ordersBySeq?: [{	by: ValueTypes["Contember_VenueOrdersBySeqUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleshotLegacyId?: [{	by: ValueTypes["Contember_VenueOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleShotLegacyNumber?: [{	by: ValueTypes["Contember_VenueOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDiscounts?: [{	by: ValueTypes["Contember_VenueOrdersByDiscountsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDelivery?: [{	by: ValueTypes["Contember_VenueOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByItems?: [{	by: ValueTypes["Contember_VenueOrdersByItemsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByCart?: [{	by: ValueTypes["Contember_VenueOrdersByCartUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByEvents?: [{	by: ValueTypes["Contember_VenueOrdersByEventsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPriceLines?: [{	by: ValueTypes["Contember_VenueOrdersByPriceLinesUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPayments?: [{	by: ValueTypes["Contember_VenueOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersBySummary?: [{	by: ValueTypes["Contember_VenueOrdersBySummaryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByGratuity?: [{	by: ValueTypes["Contember_VenueOrdersByGratuityUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
areasByTables?: [{	by: ValueTypes["Contember_VenueAreasByTablesUniqueWhere"],	filter?: ValueTypes["Contember_AreaWhere"] | undefined | null},ValueTypes["Contember_Area"]],
areasByLines?: [{	by: ValueTypes["Contember_VenueAreasByLinesUniqueWhere"],	filter?: ValueTypes["Contember_AreaWhere"] | undefined | null},ValueTypes["Contember_Area"]],
categoriesBySlug?: [{	by: ValueTypes["Contember_VenueCategoriesBySlugUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategory"]],
categoriesByLocales?: [{	by: ValueTypes["Contember_VenueCategoriesByLocalesUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategory"]],
categoriesByProducts?: [{	by: ValueTypes["Contember_VenueCategoriesByProductsUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategory"]],
channelsByCode?: [{	by: ValueTypes["Contember_VenueChannelsByCodeUniqueWhere"],	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
channelsByPayments?: [{	by: ValueTypes["Contember_VenueChannelsByPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
channelsByDeliveries?: [{	by: ValueTypes["Contember_VenueChannelsByDeliveriesUniqueWhere"],	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
channelsByCarts?: [{	by: ValueTypes["Contember_VenueChannelsByCartsUniqueWhere"],	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null},ValueTypes["Contember_Channel"]],
paginateTimetableDays?: [{	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableDayOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableDayConnection"]],
paginateTimetableTemplates?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableTemplateConnection"]],
paginateStaffMembers?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_StaffOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_StaffConnection"]],
paginateEmployeeRoles?: [{	filter?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VenueEmployeeRoleConnection"]],
paginatePointOfSaleApplicationDevices?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDeviceConnection"]],
paginatePointOfSaleTerminals?: [{	filter?: ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleTerminalOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleTerminalConnection"]],
paginatePointOfSalePrinters?: [{	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSalePrinterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSalePrinterConnection"]],
paginateOrders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_FulfillmentNoteConnection"]],
paginateAreas?: [{	filter?: ValueTypes["Contember_AreaWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AreaOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_AreaConnection"]],
paginateEnabledUnassignReasons?: [{	filter?: ValueTypes["Contember_UnassignReasonWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_UnassignReasonOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_UnassignReasonConnection"]],
paginateCategories?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryConnection"]],
paginateChannels?: [{	filter?: ValueTypes["Contember_ChannelWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ChannelOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ChannelConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	deadlineTemplate?:ValueTypes["Contember_FieldMeta"],
	timetableDays?:ValueTypes["Contember_FieldMeta"],
	timetableTemplates?:ValueTypes["Contember_FieldMeta"],
	staffMembers?:ValueTypes["Contember_FieldMeta"],
	employeeRoles?:ValueTypes["Contember_FieldMeta"],
	pointOfSaleApplicationDevices?:ValueTypes["Contember_FieldMeta"],
	pointOfSaleTerminals?:ValueTypes["Contember_FieldMeta"],
	pointOfSalePrinters?:ValueTypes["Contember_FieldMeta"],
	orders?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNotes?:ValueTypes["Contember_FieldMeta"],
	areas?:ValueTypes["Contember_FieldMeta"],
	enabledUnassignReasons?:ValueTypes["Contember_FieldMeta"],
	categories?:ValueTypes["Contember_FieldMeta"],
	channels?:ValueTypes["Contember_FieldMeta"],
	address?:ValueTypes["Contember_FieldMeta"],
	pointOfSalePngBase64ReceiptLogo?:ValueTypes["Contember_FieldMeta"],
	pointOfSaleInitialView?:ValueTypes["Contember_FieldMeta"],
	pointOfSalePreferPrintReceipt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeadlineTemplate"]: AliasType<{
	_meta?:ValueTypes["Contember_DeadlineTemplateMeta"],
	id?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	period?:boolean | `@${string}`,
	cutoff?:boolean | `@${string}`,
	closed?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeadlineTemplateMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	startDate?:ValueTypes["Contember_FieldMeta"],
	period?:ValueTypes["Contember_FieldMeta"],
	cutoff?:ValueTypes["Contember_FieldMeta"],
	closed?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDay"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableDayMeta"],
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
shiftGroups?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
	tipsCents?:boolean | `@${string}`,
notes?: [{	filter?: ValueTypes["Contember_TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableDayNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableDayNote"]],
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
meta?: [{	filter?: ValueTypes["Contember_TimetableDayMetadataWhere"] | undefined | null},ValueTypes["Contember_TimetableDayMetadata"]],
shiftGroupsByShifts?: [{	by: ValueTypes["Contember_TimetableDayShiftGroupsByShiftsUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
shiftGroupsByQueue?: [{	by: ValueTypes["Contember_TimetableDayShiftGroupsByQueueUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
paginateShiftGroups?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableShiftGroupConnection"]],
paginateNotes?: [{	filter?: ValueTypes["Contember_TimetableDayNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableDayNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableDayNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDayMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	date?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	shiftGroups?:ValueTypes["Contember_FieldMeta"],
	tipsCents?:ValueTypes["Contember_FieldMeta"],
	notes?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftGroup"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableShiftGroupMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
shifts?: [{	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableShift"]],
day?: [{	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null},ValueTypes["Contember_TimetableDay"]],
type?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["Contember_TimetableEmployeeRole"]],
position?: [{	filter?: ValueTypes["Contember_TimetableShiftPositionWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftPosition"]],
queue?: [{	filter?: ValueTypes["Contember_EmployeeQueueWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueue"]],
generatedByTemplate?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
shiftsByQueueItem?: [{	by: ValueTypes["Contember_TimetableShiftGroupShiftsByQueueItemUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null},ValueTypes["Contember_TimetableShift"]],
shiftsBySwapInfo?: [{	by: ValueTypes["Contember_TimetableShiftGroupShiftsBySwapInfoUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null},ValueTypes["Contember_TimetableShift"]],
paginateShifts?: [{	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableShiftConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftGroupMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	shifts?:ValueTypes["Contember_FieldMeta"],
	day?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	position?:ValueTypes["Contember_FieldMeta"],
	queue?:ValueTypes["Contember_FieldMeta"],
	generatedByTemplate?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShift"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableShiftMeta"],
	id?:boolean | `@${string}`,
	locked?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	assigned?:boolean | `@${string}`,
group?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
shiftsProfile?: [{	filter?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null},ValueTypes["Contember_ShiftsProfile"]],
queueItem?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
swapInfo?: [{	filter?: ValueTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftSwapInfo"]],
	isOvertime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	locked?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	assigned?:ValueTypes["Contember_FieldMeta"],
	group?:ValueTypes["Contember_FieldMeta"],
	shiftsProfile?:ValueTypes["Contember_FieldMeta"],
	queueItem?:ValueTypes["Contember_FieldMeta"],
	swapInfo?:ValueTypes["Contember_FieldMeta"],
	isOvertime?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsProfile"]: AliasType<{
	_meta?:ValueTypes["Contember_ShiftsProfileMeta"],
	id?:boolean | `@${string}`,
queueItems?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_EmployeeQueueItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
roles?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableEmployeeRole"]],
shifts?: [{	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableShift"]],
	allowSelfUnassigning?:boolean | `@${string}`,
queueItemsByQueue?: [{	by: ValueTypes["Contember_ShiftsProfileQueueItemsByQueueUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
queueItemsByShift?: [{	by: ValueTypes["Contember_ShiftsProfileQueueItemsByShiftUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
queueItemsByUnassignInfo?: [{	by: ValueTypes["Contember_ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
queueItemsByMeta?: [{	by: ValueTypes["Contember_ShiftsProfileQueueItemsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
shiftsByQueueItem?: [{	by: ValueTypes["Contember_ShiftsProfileShiftsByQueueItemUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null},ValueTypes["Contember_TimetableShift"]],
shiftsBySwapInfo?: [{	by: ValueTypes["Contember_ShiftsProfileShiftsBySwapInfoUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null},ValueTypes["Contember_TimetableShift"]],
paginateQueueItems?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_EmployeeQueueItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_EmployeeQueueItemConnection"]],
paginateRoles?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableEmployeeRoleConnection"]],
paginateShifts?: [{	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableShiftConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsProfileMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	queueItems?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	roles?:ValueTypes["Contember_FieldMeta"],
	shifts?:ValueTypes["Contember_FieldMeta"],
	allowSelfUnassigning?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueueItem"]: AliasType<{
	_meta?:ValueTypes["Contember_EmployeeQueueItemMeta"],
	id?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
queue?: [{	filter?: ValueTypes["Contember_EmployeeQueueWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueue"]],
shiftsProfile?: [{	filter?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null},ValueTypes["Contember_ShiftsProfile"]],
shift?: [{	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null},ValueTypes["Contember_TimetableShift"]],
unassignInfo?: [{	filter?: ValueTypes["Contember_UnassignedShiftInfoWhere"] | undefined | null},ValueTypes["Contember_UnassignedShiftInfo"]],
meta?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItemMetadata"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueueItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	start?:ValueTypes["Contember_FieldMeta"],
	end?:ValueTypes["Contember_FieldMeta"],
	queue?:ValueTypes["Contember_FieldMeta"],
	shiftsProfile?:ValueTypes["Contember_FieldMeta"],
	shift?:ValueTypes["Contember_FieldMeta"],
	unassignInfo?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueue"]: AliasType<{
	_meta?:ValueTypes["Contember_EmployeeQueueMeta"],
	id?:boolean | `@${string}`,
shiftGroup?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
items?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_EmployeeQueueItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
	contemberBugFix?:boolean | `@${string}`,
itemsByShiftsProfile?: [{	by: ValueTypes["Contember_EmployeeQueueItemsByShiftsProfileUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
itemsByShift?: [{	by: ValueTypes["Contember_EmployeeQueueItemsByShiftUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
itemsByUnassignInfo?: [{	by: ValueTypes["Contember_EmployeeQueueItemsByUnassignInfoUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
itemsByMeta?: [{	by: ValueTypes["Contember_EmployeeQueueItemsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_EmployeeQueueItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_EmployeeQueueItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueueMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	shiftGroup?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	contemberBugFix?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueueItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	start?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	end?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueOrderBy"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileOrderBy"] | undefined | null,
	shift?: ValueTypes["Contember_TimetableShiftOrderBy"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_UnassignedShiftInfoOrderBy"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemMetadataOrderBy"] | undefined | null
};
	["Contember_EmployeeQueueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	shiftGroup?: ValueTypes["Contember_TimetableShiftGroupOrderBy"] | undefined | null,
	contemberBugFix?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TimetableShiftGroupOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	day?: ValueTypes["Contember_TimetableDayOrderBy"] | undefined | null,
	type?: ValueTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftPositionOrderBy"] | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueOrderBy"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableTemplateOrderBy"] | undefined | null
};
	["Contember_TimetableDayOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	date?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tipsCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null,
	meta?: ValueTypes["Contember_TimetableDayMetadataOrderBy"] | undefined | null
};
	["Contember_TimetableDayMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	numberOfWorkers?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tipsTotalCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	totalMinutes?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tipsCentsPerMinute?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	day?: ValueTypes["Contember_TimetableDayOrderBy"] | undefined | null
};
	["Contember_TimetableEmployeeRoleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	color?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TimetableShiftPositionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	start?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	end?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isDynamic?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TimetableTemplateOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	startDate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	period?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	slots?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftPositionOrderBy"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined | null,
	workdaysOnly?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	monday?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tuesday?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	wednesday?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	thursday?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	friday?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	saturday?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	sunday?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TimetableShiftOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	locked?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	assigned?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	group?: ValueTypes["Contember_TimetableShiftGroupOrderBy"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileOrderBy"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemOrderBy"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftSwapInfoOrderBy"] | undefined | null,
	isOvertime?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TimetableShiftSwapInfoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	assigned?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requested?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	confirmed?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	swappedShiftsProfile?: ValueTypes["Contember_ShiftsProfileOrderBy"] | undefined | null,
	shift?: ValueTypes["Contember_TimetableShiftOrderBy"] | undefined | null
};
	["Contember_UnassignedShiftInfoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	hoursMissed?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemOrderBy"] | undefined | null,
	legacyReason?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	reason?: ValueTypes["Contember_UnassignReasonOrderBy"] | undefined | null
};
	["Contember_UnassignReasonOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_EmployeeQueueItemMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	totalMinutes?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemOrderBy"] | undefined | null,
	includeInTipsCalculation?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_EmployeeQueueItemsByShiftsProfileUniqueWhere"]: {
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItems?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	shifts?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null,
	shift?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined | null
};
	["Contember_EmployeeQueueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroup?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	items?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftGroupUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shifts?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftSwapInfoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	swappedShiftsProfile?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	shift?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null
};
	["Contember_UnassignedShiftInfoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItem?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["Contember_StaffUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_BartenderUniqueWhere"] | undefined | null,
	courier?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	cleaner?: ValueTypes["Contember_CleanerUniqueWhere"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined | null,
	user?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StocksManagerUniqueWhere"] | undefined | null,
	courierManager?: ValueTypes["Contember_CourierManagerUniqueWhere"] | undefined | null,
	picker?: ValueTypes["Contember_PickerUniqueWhere"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_RemunerationProfileUniqueWhere"] | undefined | null,
	cashier?: ValueTypes["Contember_CashierUniqueWhere"] | undefined | null,
	internalBuyer?: ValueTypes["Contember_InternalBuyerUniqueWhere"] | undefined | null,
	salesManager?: ValueTypes["Contember_SalesManagerUniqueWhere"] | undefined | null,
	cashierManager?: ValueTypes["Contember_CashierManagerUniqueWhere"] | undefined | null
};
	["Contember_BartenderUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_CourierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	routes?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null
};
	["Contember_DeliveryRouteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	waypoints?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null
};
	["Contember_DeliveryWaypointUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_OrderDeliveryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	trackingCode?: string | undefined | null,
	order?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_LiftagoRideUniqueWhere"] | undefined | null
};
	["Contember_OrderUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	seq?: number | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	discounts?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	items?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	priceLines?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null,
	payments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	summary?: ValueTypes["Contember_OrderSummaryUniqueWhere"] | undefined | null,
	gratuity?: ValueTypes["Contember_GratuityUniqueWhere"] | undefined | null
};
	["Contember_OrderDiscountUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customerCreditTransactions?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreditTransactionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null
};
	["Contember_VirtualProductEffectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	resultCustomerCreditTransaction?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	resultVouchers?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null
};
	["Contember_VoucherUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	redemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_VoucherRedemptionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreditUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	transactions?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_OrderItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	virtualProductEffects?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null
};
	["Contember_CartUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	order?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	priceLines?: ValueTypes["Contember_CartPriceLineUniqueWhere"] | undefined | null,
	summary?: ValueTypes["Contember_CartSummaryUniqueWhere"] | undefined | null
};
	["Contember_CartItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_ProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	stocks?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null,
	groupPrices?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	cartItems?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	orderItems?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	inheritedCategories?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	locales?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	customerPrices?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	virtualProduct?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_ProductMetadataUniqueWhere"] | undefined | null,
	page?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null,
	salesDays?: ValueTypes["Contember_ProductSalesDayUniqueWhere"] | undefined | null,
	salesWeeks?: ValueTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined | null,
	salesMonths?: ValueTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined | null
};
	["Contember_ProductStockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	store?: ValueTypes["Contember_StoreUniqueWhere"] | undefined | null
};
	["Contember_StoreUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["Contember_ProductGroupPriceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	group?: ValueTypes["Contember_CustomerGroupUniqueWhere"] | undefined | null,
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	productPrices?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	customers?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	addresses?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	credits?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	paymentMethods?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	orders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	permissions?: ValueTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined | null,
	name?: ValueTypes["Contember_CustomerNameUniqueWhere"] | undefined | null,
	ingredientRatings?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	business?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	carts?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	shortcutList?: ValueTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined | null,
	user?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	invoices?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_InvoicingPlanUniqueWhere"] | undefined | null,
	billingSubjects?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	offeredProducts?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	anonymousSession?: ValueTypes["Contember_AnonymousSessionUniqueWhere"] | undefined | null,
	boughtVouchers?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	ownsVouchers?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	redeemedVouchers?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	spending?: ValueTypes["Contember_CustomerSpendingUniqueWhere"] | undefined | null,
	customerTabs?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	lead?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_CustomerMetadataUniqueWhere"] | undefined | null
};
	["Contember_AddressUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	meta?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null,
	gps?: ValueTypes["Contember_GpsUniqueWhere"] | undefined | null,
	invoices?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	billingAddressOfOrders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_AddressMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	address?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null
};
	["Contember_GpsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	address?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null
};
	["Contember_InvoiceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	orderPayments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_OrderPaymentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined | null
};
	["Contember_OrderEventUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_OrderPaymentMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	orderPayment?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_CustomerPaymentMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_CustomerPermissionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerNameUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerIngredientRatingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	ingredient?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	customerRatings?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	suppliers?: ValueTypes["Contember_IngredientSupplierListUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_LocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	productCategoryLists?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	productIngredients?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	orderRecurrences?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	blogs?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null,
	blogPosts?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	menus?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null,
	pages?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	productIngredientCategories?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	allergens?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	productPackings?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	products?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	productRecipes?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	pointOfSaleCategories?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	pointOfSaleCategoryProducts?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	deliveryMethods?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	ingredientSuppliers?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	identificationCodes?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	pipelineStages?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	subscriptionBoxes?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null,
	businessServices?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null
};
	["Contember_BusinessCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_BusinessCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locales?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	businessCustomers?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	specificProducts?: ValueTypes["Contember_ProductListUniqueWhere"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null
};
	["Contember_BusinessCustomerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	businessServiceEvents?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined | null
};
	["Contember_BusinessServiceEventUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_DoubleshotCoffeeEquipmentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_ProductListItemUniqueWhere"] | undefined | null,
	businessCategory?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null
};
	["Contember_ProductListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductCategoryListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null
};
	["Contember_ProductCategoryListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductCategoryListLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_OrderRecurrenceLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_OrderRecurrenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null
};
	["Contember_BlogLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_BlogUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_BlogUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	locales?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null
};
	["Contember_BlogPostLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_BlogPostUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	cover?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null,
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null
};
	["Contember_BlogPostUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null
};
	["Contember_CoverUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ContentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	blocks?: ValueTypes["Contember_ContentBlockUniqueWhere"] | undefined | null
};
	["Contember_ContentBlockUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	references?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null
};
	["Contember_ContentReferenceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["Contember_ProductListUniqueWhere"] | undefined | null,
	productBanners?: ValueTypes["Contember_ProductBannerListUniqueWhere"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined | null
};
	["Contember_ProductBannerListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_ProductBannerItemUniqueWhere"] | undefined | null
};
	["Contember_ProductBannerItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_DeliveryRegionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined | null
};
	["Contember_DeliveryRegionsItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	textList?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	gallery?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null
};
	["Contember_ImageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_TextListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_TextItemUniqueWhere"] | undefined | null
};
	["Contember_TextItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ImageListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_ImageListItemUniqueWhere"] | undefined | null
};
	["Contember_ImageListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	image?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null
};
	["Contember_LinkableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	blogPost?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	page?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	wikiPage?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	redirect?: ValueTypes["Contember_RedirectUniqueWhere"] | undefined | null
};
	["Contember_PageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	seo?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null,
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	cover?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null
};
	["Contember_PageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null
};
	["Contember_SeoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	ogImage?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null
};
	["Contember_WikiPageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	children?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null
};
	["Contember_RedirectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	catalogue?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null
};
	["Contember_TranslationCatalogueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	values?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null
};
	["Contember_TranslationDomainUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	catalogues?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	keys?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null
};
	["Contember_TranslationKeyUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationDomainUniqueWhere"] | undefined | null,
	identifier?: string | undefined | null,
	values?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null
};
	["Contember_TranslationValueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	catalogue?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	key?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null
};
	["Contember_MenuLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_SiteMenuUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	items?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null,
	secondaryItems?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null
};
	["Contember_SiteMenuUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	locales?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null
};
	["Contember_MenuItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	subitems?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null
};
	["Contember_MenuSubitemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_LinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_LinkListItemUniqueWhere"] | undefined | null
};
	["Contember_LinkListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null
};
	["Contember_AllergenLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_AllergenUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: number | undefined | null,
	locales?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductPackingLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_ProductPackingUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductPackingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	preparedPackingQuantities?: ValueTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined | null
};
	["Contember_PreparedPackingQuantityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null,
	packing?: ValueTypes["Contember_ProductPackingUniqueWhere"] | undefined | null,
	packedInCardboard?: boolean | undefined | null
};
	["Contember_ProductRecipeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	products?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	ingredients?: ValueTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	nutritionFacts?: ValueTypes["Contember_NutritionFactsUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	quantity?: ValueTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductRecipeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_NutritionFactsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	featureList?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	slug?: string | undefined | null,
	locales?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	products?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_DeliveryMethodLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	root?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null
};
	["Contember_DeliveryMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	channels?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	locales?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryTimelinePresetUniqueWhere"] | undefined | null
};
	["Contember_ChannelDeliveryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_DeliveryTimelinePresetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deliveryMethod?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null
};
	["Contember_IngredientSupplierLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_IngredientSupplierUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_IngredientSupplierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null
};
	["Contember_IdentificationCodeLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_IdentificationCodeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	attempts?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	locales?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	applicationDevices?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null
};
	["Contember_IdentificationAttemptUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null
};
	["Contember_DeviceIdentifierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	device?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_DeviceIdentifierMetadataUniqueWhere"] | undefined | null
};
	["Contember_DeviceIdentifierMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deviceIdentifier?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null
};
	["Contember_PipelineStageLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_PipelineStageUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	locales?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	forms?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null
};
	["Contember_PipelineStageItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	lead?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null
};
	["Contember_PipelineLeadUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	stageItem?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null
};
	["Contember_LeadFormUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	leads?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null
};
	["Contember_SubscriptionBoxLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	texts?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	secondaryTexts?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_SubscriptionBoxUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	locales?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null
};
	["Contember_BusinessServiceLocaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_BusinessServiceUniqueWhere"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_BusinessServiceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null
};
	["Contember_IngredientSupplierListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined | null
};
	["Contember_IngredientSupplierItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ApplicationShortcutListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_ApplicationShortcutListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_UserUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	devices?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_UserMetadataUniqueWhere"] | undefined | null,
	identificationAttempts?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	roles?: ValueTypes["Contember_UserRolesUniqueWhere"] | undefined | null
};
	["Contember_UserDeviceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deviceId?: string | undefined | null
};
	["Contember_UserMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	tenantEmail?: string | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	tenantIdentityId?: ValueTypes["UUID"] | undefined | null,
	user?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null
};
	["Contember_UserRolesUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	user?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null
};
	["Contember_InvoicingPlanUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	preset?: ValueTypes["Contember_InvoicingPresetUniqueWhere"] | undefined | null
};
	["Contember_InvoicingPresetUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_InvoicingPlanUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	invoices?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	orders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_ProductCustomerPriceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_AnonymousSessionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	devices?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null
};
	["Contember_CustomerSpendingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerTabUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined | null
};
	["Contember_CustomerTabItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_CustomerTabMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customerTab?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null
};
	["Contember_CustomerMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_NestedProductCategoryProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_VirtualProductUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_ProductMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_ProductSalesDayUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductSalesWeekUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductSalesMonthUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_CartPriceLineUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_CartSummaryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_OrderPriceLineUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	orderItem?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	orderPayment?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	orderDelivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_OrderSummaryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_GratuityUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_LiftagoRideUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_CleanerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_ShiftsManagerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_BusinessToBusinessManagerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	managedBusinesses?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	managedProducts?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_CourierManagerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_PickerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_RemunerationProfileUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	employmentInformation?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_EmploymentInformationUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_CashierUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_InternalBuyerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_SalesManagerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_CashierManagerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemsByShiftUniqueWhere"]: {
	shift?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemsByUnassignInfoUniqueWhere"]: {
	unassignInfo?: ValueTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined | null
};
	["Contember_EmployeeQueueItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_EmployeeQueueItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueueItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_EmployeeQueueItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UnassignedShiftInfo"]: AliasType<{
	_meta?:ValueTypes["Contember_UnassignedShiftInfoMeta"],
	id?:boolean | `@${string}`,
	hoursMissed?:boolean | `@${string}`,
queueItem?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
	legacyReason?:boolean | `@${string}`,
reason?: [{	filter?: ValueTypes["Contember_UnassignReasonWhere"] | undefined | null},ValueTypes["Contember_UnassignReason"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UnassignedShiftInfoMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	hoursMissed?:ValueTypes["Contember_FieldMeta"],
	queueItem?:ValueTypes["Contember_FieldMeta"],
	legacyReason?:ValueTypes["Contember_FieldMeta"],
	reason?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UnassignReason"]: AliasType<{
	_meta?:ValueTypes["Contember_UnassignReasonMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
venues?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Venue"]],
paginateVenues?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VenueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VenueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UnassignReasonMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	venues?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_VenueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueEdge"]: AliasType<{
	node?:ValueTypes["Contember_Venue"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueueItemMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_EmployeeQueueItemMetadataMeta"],
	id?:boolean | `@${string}`,
	totalMinutes?:boolean | `@${string}`,
queueItem?: [{	filter?: ValueTypes["Contember_EmployeeQueueItemWhere"] | undefined | null},ValueTypes["Contember_EmployeeQueueItem"]],
	includeInTipsCalculation?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmployeeQueueItemMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	totalMinutes?:ValueTypes["Contember_FieldMeta"],
	queueItem?:ValueTypes["Contember_FieldMeta"],
	includeInTipsCalculation?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableEmployeeRole"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableEmployeeRoleMeta"],
	id?:boolean | `@${string}`,
shiftGroups?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
shiftsProfiles?: [{	filter?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ShiftsProfileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ShiftsProfile"]],
shiftsManagers?: [{	filter?: ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ShiftsManagerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ShiftsManager"]],
	name?:boolean | `@${string}`,
timetableTemplates?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
	color?:boolean | `@${string}`,
shiftGroupsByShifts?: [{	by: ValueTypes["Contember_TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
shiftGroupsByQueue?: [{	by: ValueTypes["Contember_TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
timetableTemplatesByStaffOptions?: [{	by: ValueTypes["Contember_TimetableEmployeeRoleTimetableTemplatesByStaffOptionsUniqueWhere"],	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
paginateShiftGroups?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableShiftGroupConnection"]],
paginateShiftsProfiles?: [{	filter?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ShiftsProfileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ShiftsProfileConnection"]],
paginateShiftsManagers?: [{	filter?: ValueTypes["Contember_ShiftsManagerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ShiftsManagerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ShiftsManagerConnection"]],
paginateTimetableTemplates?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableTemplateConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableEmployeeRoleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	shiftGroups?:ValueTypes["Contember_FieldMeta"],
	shiftsProfiles?:ValueTypes["Contember_FieldMeta"],
	shiftsManagers?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	timetableTemplates?:ValueTypes["Contember_FieldMeta"],
	color?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsManager"]: AliasType<{
	_meta?:ValueTypes["Contember_ShiftsManagerMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
managedRoles?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableEmployeeRoleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableEmployeeRole"]],
	createdAt?:boolean | `@${string}`,
paginateManagedRoles?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableEmployeeRoleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableEmployeeRoleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsManagerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	managedRoles?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableEmployeeRoleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TimetableEmployeeRoleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableEmployeeRoleEdge"]: AliasType<{
	node?:ValueTypes["Contember_TimetableEmployeeRole"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplate"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableTemplateMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	startDate?:boolean | `@${string}`,
	period?:boolean | `@${string}`,
	slots?:boolean | `@${string}`,
position?: [{	filter?: ValueTypes["Contember_TimetableShiftPositionWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftPosition"]],
staffOptions?: [{	filter?: ValueTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateStaffOptionsOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableTemplateStaffOptions"]],
	internalName?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
employeeRole?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["Contember_TimetableEmployeeRole"]],
	workdaysOnly?:boolean | `@${string}`,
	monday?:boolean | `@${string}`,
	tuesday?:boolean | `@${string}`,
	wednesday?:boolean | `@${string}`,
	thursday?:boolean | `@${string}`,
	friday?:boolean | `@${string}`,
	saturday?:boolean | `@${string}`,
	sunday?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
paginateStaffOptions?: [{	filter?: ValueTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateStaffOptionsOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableTemplateStaffOptionsConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplateMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	startDate?:ValueTypes["Contember_FieldMeta"],
	period?:ValueTypes["Contember_FieldMeta"],
	slots?:ValueTypes["Contember_FieldMeta"],
	position?:ValueTypes["Contember_FieldMeta"],
	staffOptions?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	employeeRole?:ValueTypes["Contember_FieldMeta"],
	workdaysOnly?:ValueTypes["Contember_FieldMeta"],
	monday?:ValueTypes["Contember_FieldMeta"],
	tuesday?:ValueTypes["Contember_FieldMeta"],
	wednesday?:ValueTypes["Contember_FieldMeta"],
	thursday?:ValueTypes["Contember_FieldMeta"],
	friday?:ValueTypes["Contember_FieldMeta"],
	saturday?:ValueTypes["Contember_FieldMeta"],
	sunday?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftPosition"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableShiftPositionMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	start?:boolean | `@${string}`,
	end?:boolean | `@${string}`,
shiftGroups?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftGroupOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
timetableTemplates?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
	isDynamic?:boolean | `@${string}`,
shiftGroupsByShifts?: [{	by: ValueTypes["Contember_TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
shiftGroupsByQueue?: [{	by: ValueTypes["Contember_TimetableShiftPositionShiftGroupsByQueueUniqueWhere"],	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null},ValueTypes["Contember_TimetableShiftGroup"]],
timetableTemplatesByStaffOptions?: [{	by: ValueTypes["Contember_TimetableShiftPositionTimetableTemplatesByStaffOptionsUniqueWhere"],	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
paginateShiftGroups?: [{	filter?: ValueTypes["Contember_TimetableShiftGroupWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableShiftGroupOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableShiftGroupConnection"]],
paginateTimetableTemplates?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TimetableTemplateOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TimetableTemplateConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftPositionMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	start?:ValueTypes["Contember_FieldMeta"],
	end?:ValueTypes["Contember_FieldMeta"],
	shiftGroups?:ValueTypes["Contember_FieldMeta"],
	timetableTemplates?:ValueTypes["Contember_FieldMeta"],
	isDynamic?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"]: {
	shifts?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftPositionShiftGroupsByQueueUniqueWhere"]: {
	queue?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftPositionTimetableTemplatesByStaffOptionsUniqueWhere"]: {
	staffOptions?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_TimetableShiftGroupConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TimetableShiftGroupEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftGroupEdge"]: AliasType<{
	node?:ValueTypes["Contember_TimetableShiftGroup"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplateConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TimetableTemplateEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplateEdge"]: AliasType<{
	node?:ValueTypes["Contember_TimetableTemplate"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplateStaffOptions"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableTemplateStaffOptionsMeta"],
	id?:boolean | `@${string}`,
	startTime?:boolean | `@${string}`,
	endTime?:boolean | `@${string}`,
timetableTemplate?: [{	filter?: ValueTypes["Contember_TimetableTemplateWhere"] | undefined | null},ValueTypes["Contember_TimetableTemplate"]],
autoAssignedEmployee?: [{	filter?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null},ValueTypes["Contember_ShiftsProfile"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplateStaffOptionsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	startTime?:ValueTypes["Contember_FieldMeta"],
	endTime?:ValueTypes["Contember_FieldMeta"],
	timetableTemplate?:ValueTypes["Contember_FieldMeta"],
	autoAssignedEmployee?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplateStaffOptionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	startTime?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	endTime?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	timetableTemplate?: ValueTypes["Contember_TimetableTemplateOrderBy"] | undefined | null,
	autoAssignedEmployee?: ValueTypes["Contember_ShiftsProfileOrderBy"] | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TimetableTemplateStaffOptionsEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableTemplateStaffOptionsEdge"]: AliasType<{
	node?:ValueTypes["Contember_TimetableTemplateStaffOptions"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"]: {
	shifts?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null
};
	["Contember_TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"]: {
	queue?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null
};
	["Contember_TimetableEmployeeRoleTimetableTemplatesByStaffOptionsUniqueWhere"]: {
	staffOptions?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ShiftsProfileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsProfileEdge"]: AliasType<{
	node?:ValueTypes["Contember_ShiftsProfile"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsManagerConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ShiftsManagerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsManagerEdge"]: AliasType<{
	node?:ValueTypes["Contember_ShiftsManager"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ShiftsProfileQueueItemsByQueueUniqueWhere"]: {
	queue?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileQueueItemsByShiftUniqueWhere"]: {
	shift?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"]: {
	unassignInfo?: ValueTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileQueueItemsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileShiftsByQueueItemUniqueWhere"]: {
	queueItem?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileShiftsBySwapInfoUniqueWhere"]: {
	swapInfo?: ValueTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TimetableShiftEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftEdge"]: AliasType<{
	node?:ValueTypes["Contember_TimetableShift"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftSwapInfo"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableShiftSwapInfoMeta"],
	id?:boolean | `@${string}`,
	assigned?:boolean | `@${string}`,
	requested?:boolean | `@${string}`,
	confirmed?:boolean | `@${string}`,
swappedShiftsProfile?: [{	filter?: ValueTypes["Contember_ShiftsProfileWhere"] | undefined | null},ValueTypes["Contember_ShiftsProfile"]],
shift?: [{	filter?: ValueTypes["Contember_TimetableShiftWhere"] | undefined | null},ValueTypes["Contember_TimetableShift"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftSwapInfoMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	assigned?:ValueTypes["Contember_FieldMeta"],
	requested?:ValueTypes["Contember_FieldMeta"],
	confirmed?:ValueTypes["Contember_FieldMeta"],
	swappedShiftsProfile?:ValueTypes["Contember_FieldMeta"],
	shift?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableShiftGroupShiftsByQueueItemUniqueWhere"]: {
	queueItem?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftGroupShiftsBySwapInfoUniqueWhere"]: {
	swapInfo?: ValueTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined | null
};
	["Contember_TimetableDayNote"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableDayNoteMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
day?: [{	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null},ValueTypes["Contember_TimetableDay"]],
role?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["Contember_TimetableEmployeeRole"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDayNoteMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	day?:ValueTypes["Contember_FieldMeta"],
	role?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDayNoteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	day?: ValueTypes["Contember_TimetableDayOrderBy"] | undefined | null,
	role?: ValueTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined | null
};
	["Contember_TimetableDayMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetableDayMetadataMeta"],
	id?:boolean | `@${string}`,
	numberOfWorkers?:boolean | `@${string}`,
	tipsTotalCents?:boolean | `@${string}`,
	totalMinutes?:boolean | `@${string}`,
	tipsCentsPerMinute?:boolean | `@${string}`,
day?: [{	filter?: ValueTypes["Contember_TimetableDayWhere"] | undefined | null},ValueTypes["Contember_TimetableDay"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDayMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	numberOfWorkers?:ValueTypes["Contember_FieldMeta"],
	tipsTotalCents?:ValueTypes["Contember_FieldMeta"],
	totalMinutes?:ValueTypes["Contember_FieldMeta"],
	tipsCentsPerMinute?:ValueTypes["Contember_FieldMeta"],
	day?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDayShiftGroupsByShiftsUniqueWhere"]: {
	shifts?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null
};
	["Contember_TimetableDayShiftGroupsByQueueUniqueWhere"]: {
	queue?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null
};
	["Contember_TimetableDayNoteConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TimetableDayNoteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDayNoteEdge"]: AliasType<{
	node?:ValueTypes["Contember_TimetableDayNote"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueEmployeeRole"]: AliasType<{
	_meta?:ValueTypes["Contember_VenueEmployeeRoleMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
employeePrivileges?: [{	filter?: ValueTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined | null},ValueTypes["Contember_TimetablesEmployeePrivileges"]],
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
role?: [{	filter?: ValueTypes["Contember_TimetableEmployeeRoleWhere"] | undefined | null},ValueTypes["Contember_TimetableEmployeeRole"]],
	getsTipsShare?:boolean | `@${string}`,
	notesEnabled?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueEmployeeRoleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	employeePrivileges?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	role?:ValueTypes["Contember_FieldMeta"],
	getsTipsShare?:ValueTypes["Contember_FieldMeta"],
	notesEnabled?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetablesEmployeePrivileges"]: AliasType<{
	_meta?:ValueTypes["Contember_TimetablesEmployeePrivilegesMeta"],
	id?:boolean | `@${string}`,
venueEmployeeRole?: [{	filter?: ValueTypes["Contember_VenueEmployeeRoleWhere"] | undefined | null},ValueTypes["Contember_VenueEmployeeRole"]],
	read?:boolean | `@${string}`,
	unassign?:boolean | `@${string}`,
	assign?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetablesEmployeePrivilegesMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	venueEmployeeRole?:ValueTypes["Contember_FieldMeta"],
	read?:ValueTypes["Contember_FieldMeta"],
	unassign?:ValueTypes["Contember_FieldMeta"],
	assign?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueEmployeeRoleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	employeePrivileges?: ValueTypes["Contember_TimetablesEmployeePrivilegesOrderBy"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null,
	role?: ValueTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined | null,
	getsTipsShare?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	notesEnabled?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TimetablesEmployeePrivilegesOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venueEmployeeRole?: ValueTypes["Contember_VenueEmployeeRoleOrderBy"] | undefined | null,
	read?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unassign?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	assign?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PointOfSaleApplicationDevice"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSaleApplicationDeviceMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
identificationCode?: [{	filter?: ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null},ValueTypes["Contember_IdentificationCode"]],
identifier?: [{	filter?: ValueTypes["Contember_DeviceIdentifierWhere"] | undefined | null},ValueTypes["Contember_DeviceIdentifier"]],
terminal?: [{	filter?: ValueTypes["Contember_PointOfSaleTerminalWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleTerminal"]],
receiptPrinter?: [{	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null},ValueTypes["Contember_PointOfSalePrinter"]],
ticketPrinters?: [{	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSalePrinterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSalePrinter"]],
paginateTicketPrinters?: [{	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSalePrinterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSalePrinterConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleApplicationDeviceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	identificationCode?:ValueTypes["Contember_FieldMeta"],
	identifier?:ValueTypes["Contember_FieldMeta"],
	terminal?:ValueTypes["Contember_FieldMeta"],
	receiptPrinter?:ValueTypes["Contember_FieldMeta"],
	ticketPrinters?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationCode"]: AliasType<{
	_meta?:ValueTypes["Contember_IdentificationCodeMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
attempts?: [{	filter?: ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationAttemptOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_IdentificationAttempt"]],
locales?: [{	filter?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationCodeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_IdentificationCodeLocale"]],
	internalName?:boolean | `@${string}`,
applicationDevices?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
localesByLocale?: [{	by: ValueTypes["Contember_IdentificationCodeLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null},ValueTypes["Contember_IdentificationCodeLocale"]],
applicationDevicesByIdentifier?: [{	by: ValueTypes["Contember_IdentificationCodeApplicationDevicesByIdentifierUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
paginateAttempts?: [{	filter?: ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationAttemptOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_IdentificationAttemptConnection"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_IdentificationCodeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationCodeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_IdentificationCodeLocaleConnection"]],
paginateApplicationDevices?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDeviceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationCodeMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	attempts?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	applicationDevices?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationAttempt"]: AliasType<{
	_meta?:ValueTypes["Contember_IdentificationAttemptMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
code?: [{	filter?: ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null},ValueTypes["Contember_IdentificationCode"]],
user?: [{	filter?: ValueTypes["Contember_UserWhere"] | undefined | null},ValueTypes["Contember_User"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationAttemptMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	user?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_User"]: AliasType<{
	_meta?:ValueTypes["Contember_UserMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
	tenantPersonId?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
devices?: [{	filter?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_UserDeviceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_UserDevice"]],
	verifiedAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	deletionRequestedAt?:boolean | `@${string}`,
	deletionExpectedAt?:boolean | `@${string}`,
	deletedAt?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["Contember_UserMetadataWhere"] | undefined | null},ValueTypes["Contember_UserMetadata"]],
identificationAttempts?: [{	filter?: ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationAttemptOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_IdentificationAttempt"]],
roles?: [{	filter?: ValueTypes["Contember_UserRolesWhere"] | undefined | null},ValueTypes["Contember_UserRoles"]],
devicesByDeviceId?: [{	by: ValueTypes["Contember_UserDevicesByDeviceIdUniqueWhere"],	filter?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null},ValueTypes["Contember_UserDevice"]],
paginateDevices?: [{	filter?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_UserDeviceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_UserDeviceConnection"]],
paginateIdentificationAttempts?: [{	filter?: ValueTypes["Contember_IdentificationAttemptWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IdentificationAttemptOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_IdentificationAttemptConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	tenantPersonId?:ValueTypes["Contember_FieldMeta"],
	disabledAt?:ValueTypes["Contember_FieldMeta"],
	devices?:ValueTypes["Contember_FieldMeta"],
	verifiedAt?:ValueTypes["Contember_FieldMeta"],
	email?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	deletionRequestedAt?:ValueTypes["Contember_FieldMeta"],
	deletionExpectedAt?:ValueTypes["Contember_FieldMeta"],
	deletedAt?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
	identificationAttempts?:ValueTypes["Contember_FieldMeta"],
	roles?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserDevice"]: AliasType<{
	_meta?:ValueTypes["Contember_UserDeviceMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	lastActiveAt?:boolean | `@${string}`,
	platform?:boolean | `@${string}`,
	deviceId?:boolean | `@${string}`,
	notificationsEnabled?:boolean | `@${string}`,
user?: [{	filter?: ValueTypes["Contember_UserWhere"] | undefined | null},ValueTypes["Contember_User"]],
	appVersion?:boolean | `@${string}`,
	osVersion?:boolean | `@${string}`,
	apiVersion?:boolean | `@${string}`,
anonymousSession?: [{	filter?: ValueTypes["Contember_AnonymousSessionWhere"] | undefined | null},ValueTypes["Contember_AnonymousSession"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserDeviceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	lastActiveAt?:ValueTypes["Contember_FieldMeta"],
	platform?:ValueTypes["Contember_FieldMeta"],
	deviceId?:ValueTypes["Contember_FieldMeta"],
	notificationsEnabled?:ValueTypes["Contember_FieldMeta"],
	user?:ValueTypes["Contember_FieldMeta"],
	appVersion?:ValueTypes["Contember_FieldMeta"],
	osVersion?:ValueTypes["Contember_FieldMeta"],
	apiVersion?:ValueTypes["Contember_FieldMeta"],
	anonymousSession?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AnonymousSession"]: AliasType<{
	_meta?:ValueTypes["Contember_AnonymousSessionMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	lastActiveAt?:boolean | `@${string}`,
	anonymousSessionKey?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
devices?: [{	filter?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_UserDeviceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_UserDevice"]],
devicesByDeviceId?: [{	by: ValueTypes["Contember_AnonymousSessionDevicesByDeviceIdUniqueWhere"],	filter?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null},ValueTypes["Contember_UserDevice"]],
paginateDevices?: [{	filter?: ValueTypes["Contember_UserDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_UserDeviceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_UserDeviceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AnonymousSessionMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	lastActiveAt?:ValueTypes["Contember_FieldMeta"],
	anonymousSessionKey?:ValueTypes["Contember_FieldMeta"],
	email?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	devices?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserDeviceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	lastActiveAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	platform?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deviceId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	notificationsEnabled?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	user?: ValueTypes["Contember_UserOrderBy"] | undefined | null,
	appVersion?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	osVersion?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	apiVersion?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	anonymousSession?: ValueTypes["Contember_AnonymousSessionOrderBy"] | undefined | null
};
	["Contember_AnonymousSessionDevicesByDeviceIdUniqueWhere"]: {
	deviceId?: string | undefined | null
};
	["Contember_UserDeviceConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_UserDeviceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserDeviceEdge"]: AliasType<{
	node?:ValueTypes["Contember_UserDevice"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_UserMetadataMeta"],
	id?:boolean | `@${string}`,
	userProfileUrl?:boolean | `@${string}`,
	tenantEmail?:boolean | `@${string}`,
	tenantPersonId?:boolean | `@${string}`,
	tenantIdentityId?:boolean | `@${string}`,
	globalRoles?:boolean | `@${string}`,
	projectRoles?:boolean | `@${string}`,
	projectRolesString?:boolean | `@${string}`,
	externalIdentifiers?:boolean | `@${string}`,
	externalIdentifiersString?:boolean | `@${string}`,
	idpSlugs?:boolean | `@${string}`,
	idpSlugsString?:boolean | `@${string}`,
user?: [{	filter?: ValueTypes["Contember_UserWhere"] | undefined | null},ValueTypes["Contember_User"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	userProfileUrl?:ValueTypes["Contember_FieldMeta"],
	tenantEmail?:ValueTypes["Contember_FieldMeta"],
	tenantPersonId?:ValueTypes["Contember_FieldMeta"],
	tenantIdentityId?:ValueTypes["Contember_FieldMeta"],
	globalRoles?:ValueTypes["Contember_FieldMeta"],
	projectRoles?:ValueTypes["Contember_FieldMeta"],
	projectRolesString?:ValueTypes["Contember_FieldMeta"],
	externalIdentifiers?:ValueTypes["Contember_FieldMeta"],
	externalIdentifiersString?:ValueTypes["Contember_FieldMeta"],
	idpSlugs?:ValueTypes["Contember_FieldMeta"],
	idpSlugsString?:ValueTypes["Contember_FieldMeta"],
	user?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationAttemptOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_IdentificationCodeOrderBy"] | undefined | null,
	user?: ValueTypes["Contember_UserOrderBy"] | undefined | null
};
	["Contember_IdentificationCodeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_UserRoles"]: AliasType<{
	_meta?:ValueTypes["Contember_UserRolesMeta"],
	id?:boolean | `@${string}`,
	isCustomer?:boolean | `@${string}`,
	isStaff?:boolean | `@${string}`,
	isBartender?:boolean | `@${string}`,
	isCashier?:boolean | `@${string}`,
	isCourier?:boolean | `@${string}`,
	isCleaner?:boolean | `@${string}`,
	isShiftsManager?:boolean | `@${string}`,
	isInternalBuyer?:boolean | `@${string}`,
	isStocksManager?:boolean | `@${string}`,
	isCourierManager?:boolean | `@${string}`,
	isCashierManager?:boolean | `@${string}`,
	isBusinessToBusinessManager?:boolean | `@${string}`,
	isPicker?:boolean | `@${string}`,
user?: [{	filter?: ValueTypes["Contember_UserWhere"] | undefined | null},ValueTypes["Contember_User"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserRolesMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	isCustomer?:ValueTypes["Contember_FieldMeta"],
	isStaff?:ValueTypes["Contember_FieldMeta"],
	isBartender?:ValueTypes["Contember_FieldMeta"],
	isCashier?:ValueTypes["Contember_FieldMeta"],
	isCourier?:ValueTypes["Contember_FieldMeta"],
	isCleaner?:ValueTypes["Contember_FieldMeta"],
	isShiftsManager?:ValueTypes["Contember_FieldMeta"],
	isInternalBuyer?:ValueTypes["Contember_FieldMeta"],
	isStocksManager?:ValueTypes["Contember_FieldMeta"],
	isCourierManager?:ValueTypes["Contember_FieldMeta"],
	isCashierManager?:ValueTypes["Contember_FieldMeta"],
	isBusinessToBusinessManager?:ValueTypes["Contember_FieldMeta"],
	isPicker?:ValueTypes["Contember_FieldMeta"],
	user?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UserDevicesByDeviceIdUniqueWhere"]: {
	deviceId?: string | undefined | null
};
	["Contember_IdentificationAttemptConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_IdentificationAttemptEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationAttemptEdge"]: AliasType<{
	node?:ValueTypes["Contember_IdentificationAttempt"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationCodeLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_IdentificationCodeLocaleMeta"],
	id?:boolean | `@${string}`,
	successMessage?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_IdentificationCodeWhere"] | undefined | null},ValueTypes["Contember_IdentificationCode"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationCodeLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	successMessage?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationCodeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	successMessage?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_IdentificationCodeOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null,
	identificationCode?: ValueTypes["Contember_IdentificationCodeOrderBy"] | undefined | null,
	identifier?: ValueTypes["Contember_DeviceIdentifierOrderBy"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleTerminalOrderBy"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSalePrinterOrderBy"] | undefined | null
};
	["Contember_DeviceIdentifierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	device?: ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"] | undefined | null,
	updatedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	platform?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	resolution?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	devicePixelRatio?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	updatedByUser?: ValueTypes["Contember_UserOrderBy"] | undefined | null,
	identifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	meta?: ValueTypes["Contember_DeviceIdentifierMetadataOrderBy"] | undefined | null
};
	["Contember_DeviceIdentifierMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	prettyIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deviceIdentifier?: ValueTypes["Contember_DeviceIdentifierOrderBy"] | undefined | null
};
	["Contember_PointOfSaleTerminalOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null,
	ipAddress?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	port?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PointOfSalePrinterOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ipAddress?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null
};
	["Contember_IdentificationCodeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_IdentificationCodeApplicationDevicesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null
};
	["Contember_IdentificationCodeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_IdentificationCodeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IdentificationCodeLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_IdentificationCodeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleApplicationDeviceConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSaleApplicationDeviceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleApplicationDeviceEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSaleApplicationDevice"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeviceIdentifier"]: AliasType<{
	_meta?:ValueTypes["Contember_DeviceIdentifierMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
device?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
	updatedAt?:boolean | `@${string}`,
	platform?:boolean | `@${string}`,
	resolution?:boolean | `@${string}`,
	devicePixelRatio?:boolean | `@${string}`,
updatedByUser?: [{	filter?: ValueTypes["Contember_UserWhere"] | undefined | null},ValueTypes["Contember_User"]],
	identifier?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined | null},ValueTypes["Contember_DeviceIdentifierMetadata"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeviceIdentifierMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	device?:ValueTypes["Contember_FieldMeta"],
	updatedAt?:ValueTypes["Contember_FieldMeta"],
	platform?:ValueTypes["Contember_FieldMeta"],
	resolution?:ValueTypes["Contember_FieldMeta"],
	devicePixelRatio?:ValueTypes["Contember_FieldMeta"],
	updatedByUser?:ValueTypes["Contember_FieldMeta"],
	identifier?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeviceIdentifierMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_DeviceIdentifierMetadataMeta"],
	id?:boolean | `@${string}`,
	prettyIdentifier?:boolean | `@${string}`,
deviceIdentifier?: [{	filter?: ValueTypes["Contember_DeviceIdentifierWhere"] | undefined | null},ValueTypes["Contember_DeviceIdentifier"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeviceIdentifierMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	prettyIdentifier?:ValueTypes["Contember_FieldMeta"],
	deviceIdentifier?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleTerminal"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSaleTerminalMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
applicationDevices?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
	ipAddress?:boolean | `@${string}`,
	port?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
applicationDevicesByIdentifier?: [{	by: ValueTypes["Contember_PointOfSaleTerminalApplicationDevicesByIdentifierUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
paginateApplicationDevices?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDeviceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleTerminalMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	applicationDevices?:ValueTypes["Contember_FieldMeta"],
	ipAddress?:ValueTypes["Contember_FieldMeta"],
	port?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleTerminalApplicationDevicesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null
};
	["Contember_PointOfSalePrinter"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSalePrinterMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	ipAddress?:boolean | `@${string}`,
applicationDevicesReceipt?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
applicationDevicesTicket?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
ticketCategories?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategory"]],
applicationDevicesReceiptByIdentifier?: [{	by: ValueTypes["Contember_PointOfSalePrinterApplicationDevicesReceiptByIdentifierUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDevice"]],
paginateApplicationDevicesReceipt?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDeviceConnection"]],
paginateApplicationDevicesTicket?: [{	filter?: ValueTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleApplicationDeviceConnection"]],
paginateTicketCategories?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSalePrinterMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	ipAddress?:ValueTypes["Contember_FieldMeta"],
	applicationDevicesReceipt?:ValueTypes["Contember_FieldMeta"],
	applicationDevicesTicket?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	ticketCategories?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategory"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSaleCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	slug?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryLocale"]],
products?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProduct"]],
category?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
ticketPrinters?: [{	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSalePrinterOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSalePrinter"]],
localesByLocale?: [{	by: ValueTypes["Contember_PointOfSaleCategoryLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategoryLocale"]],
productsByLocales?: [{	by: ValueTypes["Contember_PointOfSaleCategoryProductsByLocalesUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProduct"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryLocaleConnection"]],
paginateProducts?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProductConnection"]],
paginateTicketPrinters?: [{	filter?: ValueTypes["Contember_PointOfSalePrinterWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSalePrinterOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSalePrinterConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	slug?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	category?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	ticketPrinters?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSaleCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategory"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_PointOfSaleCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	slug?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	category?: ValueTypes["Contember_ProductCategoryOrderBy"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null
};
	["Contember_ProductCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	color?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PointOfSaleCategoryProduct"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSaleCategoryProductMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
category?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategory"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
locales?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryProductLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProductLocale"]],
localesByLocale?: [{	by: ValueTypes["Contember_PointOfSaleCategoryProductLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProductLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PointOfSaleCategoryProductLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProductLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryProductMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	category?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryProductLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_PointOfSaleCategoryProductLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined | null},ValueTypes["Contember_PointOfSaleCategoryProduct"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryProductLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryProductLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryProductOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSaleCategoryProductLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryProductLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSaleCategoryProductLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategory"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductCategoryMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
tags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Tag"]],
parent?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
children?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategory"]],
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	order?:boolean | `@${string}`,
descendants?: [{	filter?: ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryTreeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategoryTree"]],
ancestors?: [{	filter?: ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryTreeOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategoryTree"]],
inheritedTags?: [{	filter?: ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InheritedProductCategoryTagOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_InheritedProductCategoryTag"]],
nestedProducts?: [{	filter?: ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_NestedProductCategoryProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_NestedProductCategoryProduct"]],
childrenByCode?: [{	by: ValueTypes["Contember_ProductCategoryChildrenByCodeUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
childrenByChildren?: [{	by: ValueTypes["Contember_ProductCategoryChildrenByChildrenUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
childrenByDescendants?: [{	by: ValueTypes["Contember_ProductCategoryChildrenByDescendantsUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
childrenByAncestors?: [{	by: ValueTypes["Contember_ProductCategoryChildrenByAncestorsUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
childrenByInheritedTags?: [{	by: ValueTypes["Contember_ProductCategoryChildrenByInheritedTagsUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
childrenByNestedProducts?: [{	by: ValueTypes["Contember_ProductCategoryChildrenByNestedProductsUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
paginateProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateTags?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TagConnection"]],
paginateChildren?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryConnection"]],
paginateDescendants?: [{	filter?: ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryTreeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryTreeConnection"]],
paginateAncestors?: [{	filter?: ValueTypes["Contember_ProductCategoryTreeWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryTreeOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryTreeConnection"]],
paginateInheritedTags?: [{	filter?: ValueTypes["Contember_InheritedProductCategoryTagWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InheritedProductCategoryTagOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_InheritedProductCategoryTagConnection"]],
paginateNestedProducts?: [{	filter?: ValueTypes["Contember_NestedProductCategoryProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_NestedProductCategoryProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_NestedProductCategoryProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	tags?:ValueTypes["Contember_FieldMeta"],
	parent?:ValueTypes["Contember_FieldMeta"],
	children?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	color?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	descendants?:ValueTypes["Contember_FieldMeta"],
	ancestors?:ValueTypes["Contember_FieldMeta"],
	inheritedTags?:ValueTypes["Contember_FieldMeta"],
	nestedProducts?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryTree"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductCategoryTreeMeta"],
	id?:boolean | `@${string}`,
	depth?:boolean | `@${string}`,
ancestor?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
descendant?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryTreeMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	depth?:ValueTypes["Contember_FieldMeta"],
	ancestor?:ValueTypes["Contember_FieldMeta"],
	descendant?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryTreeOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	depth?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ancestor?: ValueTypes["Contember_ProductCategoryOrderBy"] | undefined | null,
	descendant?: ValueTypes["Contember_ProductCategoryOrderBy"] | undefined | null
};
	["Contember_InheritedProductCategoryTag"]: AliasType<{
	_meta?:ValueTypes["Contember_InheritedProductCategoryTagMeta"],
	id?:boolean | `@${string}`,
	depth?:boolean | `@${string}`,
productCategory?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
tag?: [{	filter?: ValueTypes["Contember_TagWhere"] | undefined | null},ValueTypes["Contember_Tag"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InheritedProductCategoryTagMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	depth?:ValueTypes["Contember_FieldMeta"],
	productCategory?:ValueTypes["Contember_FieldMeta"],
	tag?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InheritedProductCategoryTagOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	depth?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryOrderBy"] | undefined | null,
	tag?: ValueTypes["Contember_TagOrderBy"] | undefined | null
};
	["Contember_NestedProductCategoryProduct"]: AliasType<{
	_meta?:ValueTypes["Contember_NestedProductCategoryProductMeta"],
	id?:boolean | `@${string}`,
	depth?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productCategory?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_NestedProductCategoryProductMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	depth?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	productCategory?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_NestedProductCategoryProductOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	depth?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryOrderBy"] | undefined | null
};
	["Contember_ProductCategoryChildrenByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["Contember_ProductCategoryChildrenByChildrenUniqueWhere"]: {
	children?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null
};
	["Contember_ProductCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	children?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	descendants?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	ancestors?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	inheritedTags?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	nestedProducts?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null
};
	["Contember_ProductCategoryTreeUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_InheritedProductCategoryTagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductCategoryChildrenByDescendantsUniqueWhere"]: {
	descendants?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null
};
	["Contember_ProductCategoryChildrenByAncestorsUniqueWhere"]: {
	ancestors?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null
};
	["Contember_ProductCategoryChildrenByInheritedTagsUniqueWhere"]: {
	inheritedTags?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null
};
	["Contember_ProductCategoryChildrenByNestedProductsUniqueWhere"]: {
	nestedProducts?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null
};
	["ProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["ProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ProductEdge"]: AliasType<{
	node?:ValueTypes["Product"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductCategory"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryTreeConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductCategoryTreeEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryTreeEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductCategoryTree"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InheritedProductCategoryTagConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_InheritedProductCategoryTagEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InheritedProductCategoryTagEdge"]: AliasType<{
	node?:ValueTypes["Contember_InheritedProductCategoryTag"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_NestedProductCategoryProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_NestedProductCategoryProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_NestedProductCategoryProductEdge"]: AliasType<{
	node?:ValueTypes["Contember_NestedProductCategoryProduct"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSaleCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSaleCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSaleCategoryProductEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryProductEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSaleCategoryProduct"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSalePrinterConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSalePrinterEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSalePrinterEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSalePrinter"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSalePrinterApplicationDevicesReceiptByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSaleCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleCategoryEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSaleCategory"],
		__typename?: boolean | `@${string}`
}>;
	["OrderMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	state?:ValueTypes["Contember_FieldMeta"],
	fulfilledAt?:ValueTypes["Contember_FieldMeta"],
	canceledAt?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	channel?:ValueTypes["Contember_FieldMeta"],
	discounts?:ValueTypes["Contember_FieldMeta"],
	delivery?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	cart?:ValueTypes["Contember_FieldMeta"],
	events?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	billingAddress?:ValueTypes["Contember_FieldMeta"],
	priceLines?:ValueTypes["Contember_FieldMeta"],
	payments?:ValueTypes["Contember_FieldMeta"],
	seq?:ValueTypes["Contember_FieldMeta"],
	billingSubject?:ValueTypes["Contember_FieldMeta"],
	summary?:ValueTypes["Contember_FieldMeta"],
	doubleshotLegacyId?:ValueTypes["Contember_FieldMeta"],
	gratuity?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNote?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNotes?:ValueTypes["Contember_FieldMeta"],
	doubleshotTotalWeightGrams?:ValueTypes["Contember_FieldMeta"],
	doubleShotLegacyNumber?:ValueTypes["Contember_FieldMeta"],
	doubleshotLastUpdatedAt?:ValueTypes["Contember_FieldMeta"],
	doubleshotAdminUrl?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDiscount"]: AliasType<{
	_meta?:ValueTypes["OrderDiscountMeta"],
	id?:boolean | `@${string}`,
	discountCents?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
	description?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
customerCreditTransactions?: [{	filter?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerCreditTransactionOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerCreditTransaction"]],
voucherRedemption?: [{	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null},ValueTypes["Contember_VoucherRedemption"]],
customerCreditTransactionsByVirtualProductEffect?: [{	by: ValueTypes["Contember_OrderDiscountCustomerCreditTransactionsByVirtualProductEffectUniqueWhere"],	filter?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null},ValueTypes["Contember_CustomerCreditTransaction"]],
paginateCustomerCreditTransactions?: [{	filter?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerCreditTransactionOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerCreditTransactionConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderDiscountMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	discountCents?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	customerCreditTransactions?:ValueTypes["Contember_FieldMeta"],
	voucherRedemption?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerCreditTransaction"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerCreditTransactionMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	amountCents?:boolean | `@${string}`,
customerCredit?: [{	filter?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
orderDiscount?: [{	filter?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
virtualProductEffect?: [{	filter?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null},ValueTypes["Contember_VirtualProductEffect"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerCreditTransactionMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	amountCents?:ValueTypes["Contember_FieldMeta"],
	customerCredit?:ValueTypes["Contember_FieldMeta"],
	orderDiscount?:ValueTypes["Contember_FieldMeta"],
	virtualProductEffect?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerCreditMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	initialCreditCents?:ValueTypes["Contember_FieldMeta"],
	remainingCreditsCents?:ValueTypes["Contember_FieldMeta"],
	expiresAt?:ValueTypes["Contember_FieldMeta"],
	reason?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	transactions?:ValueTypes["Contember_FieldMeta"],
	voucherRedemption?:ValueTypes["Contember_FieldMeta"],
	invoiceUrl?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerCreditTransactionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	amountCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditOrderBy"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountOrderBy"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VirtualProductEffectOrderBy"] | undefined | null
};
	["Contember_CustomerCreditOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	initialCreditCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	remainingCreditsCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	expiresAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	reason?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionOrderBy"] | undefined | null,
	invoiceUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_VoucherRedemptionOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	manualRedemptionNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditOrderBy"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountOrderBy"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherOrderBy"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null
};
	["Contember_OrderDiscountOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	discountCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionOrderBy"] | undefined | null
};
	["Contember_OrderOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	state?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fulfilledAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	canceledAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelOrderBy"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryOrderBy"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	cart?: ValueTypes["Contember_CartOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	billingAddress?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	seq?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	billingSubject?: ValueTypes["Contember_BillingSubjectOrderBy"] | undefined | null,
	summary?: ValueTypes["Contember_OrderSummaryOrderBy"] | undefined | null,
	doubleshotLegacyId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	gratuity?: ValueTypes["Contember_GratuityOrderBy"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null,
	fulfillmentNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	doubleshotTotalWeightGrams?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	doubleShotLegacyNumber?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	doubleshotAdminUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_OrderDeliveryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveredAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	method?: ValueTypes["Contember_DeliveryMethodOrderBy"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null,
	expectedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	state?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	loadingDurationHours?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	dispatchAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	duration?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	window?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	expectedEndAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	processingAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveringAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	failedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_DeliveryWaypointOrderBy"] | undefined | null,
	prepareAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_LiftagoRideOrderBy"] | undefined | null,
	pickedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	address?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	trackingCode?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_DeliveryWaypointOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	estimatedArrival?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	route?: ValueTypes["Contember_DeliveryRouteOrderBy"] | undefined | null,
	sortingOrder?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryOrderBy"] | undefined | null,
	estimatedDistance?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	estimatedDuration?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	originDeparture?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	originAddress?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	transitMode?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_DeliveryRouteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	courier?: ValueTypes["Contember_CourierOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	dispatchAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_LiftagoRideOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	liftagoId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	data?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	updatedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryOrderBy"] | undefined | null
};
	["Contember_CartOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	confirmedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deletedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	handle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	state?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	channel?: ValueTypes["Contember_ChannelOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	billingAddress?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	deliveryOption?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	paymentOption?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	delivery?: ValueTypes["Contember_ChannelDeliveryOrderBy"] | undefined | null,
	payment?: ValueTypes["Contember_ChannelPaymentOrderBy"] | undefined | null,
	billingSubject?: ValueTypes["Contember_BillingSubjectOrderBy"] | undefined | null,
	summary?: ValueTypes["Contember_CartSummaryOrderBy"] | undefined | null
};
	["Contember_CartSummaryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	itemsPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveryPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	totalPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	itemsCount?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	itemsQuantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	checkoutUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	cart?: ValueTypes["Contember_CartOrderBy"] | undefined | null
};
	["Contember_OrderSummaryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	totalPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	discountPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	undiscountedPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	itemsQuantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderNumber?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null
};
	["Contember_GratuityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	amountCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null
};
	["Contember_VoucherOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	activatedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	expiresAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	creditCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	buyer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	owner?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VirtualProductEffectOrderBy"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherRedemptionOrderBy"] | undefined | null
};
	["Contember_VirtualProductEffectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	succeededAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderItem?: ValueTypes["Contember_OrderItemOrderBy"] | undefined | null,
	failedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	revertedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	resultCustomerCreditTransaction?: ValueTypes["Contember_CustomerCreditTransactionOrderBy"] | undefined | null
};
	["Contember_OrderItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unitPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	quantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null,
	pickedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_VoucherRedemption"]: AliasType<{
	_meta?:ValueTypes["Contember_VoucherRedemptionMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	manualRedemptionNote?:boolean | `@${string}`,
customerCredit?: [{	filter?: ValueTypes["Contember_CustomerCreditWhere"] | undefined | null},ValueTypes["CustomerCredit"]],
orderDiscount?: [{	filter?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
voucher?: [{	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null},ValueTypes["Contember_Voucher"]],
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VoucherRedemptionMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	manualRedemptionNote?:ValueTypes["Contember_FieldMeta"],
	customerCredit?:ValueTypes["Contember_FieldMeta"],
	orderDiscount?:ValueTypes["Contember_FieldMeta"],
	voucher?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Voucher"]: AliasType<{
	_meta?:ValueTypes["Contember_VoucherMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	activatedAt?:boolean | `@${string}`,
	expiresAt?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	creditCents?:boolean | `@${string}`,
buyer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
owner?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
virtualProductEffect?: [{	filter?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null},ValueTypes["Contember_VirtualProductEffect"]],
redemption?: [{	filter?: ValueTypes["Contember_VoucherRedemptionWhere"] | undefined | null},ValueTypes["Contember_VoucherRedemption"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VoucherMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	activatedAt?:ValueTypes["Contember_FieldMeta"],
	expiresAt?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	creditCents?:ValueTypes["Contember_FieldMeta"],
	buyer?:ValueTypes["Contember_FieldMeta"],
	owner?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	virtualProductEffect?:ValueTypes["Contember_FieldMeta"],
	redemption?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VirtualProductEffect"]: AliasType<{
	_meta?:ValueTypes["Contember_VirtualProductEffectMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	succeededAt?:boolean | `@${string}`,
orderItem?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
	failedAt?:boolean | `@${string}`,
	revertedAt?:boolean | `@${string}`,
resultCustomerCreditTransaction?: [{	filter?: ValueTypes["Contember_CustomerCreditTransactionWhere"] | undefined | null},ValueTypes["Contember_CustomerCreditTransaction"]],
resultVouchers?: [{	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Voucher"]],
resultVouchersByCode?: [{	by: ValueTypes["Contember_VirtualProductEffectResultVouchersByCodeUniqueWhere"],	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null},ValueTypes["Contember_Voucher"]],
resultVouchersByRedemption?: [{	by: ValueTypes["Contember_VirtualProductEffectResultVouchersByRedemptionUniqueWhere"],	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null},ValueTypes["Contember_Voucher"]],
paginateResultVouchers?: [{	filter?: ValueTypes["Contember_VoucherWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VoucherOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VoucherConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VirtualProductEffectMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	succeededAt?:ValueTypes["Contember_FieldMeta"],
	orderItem?:ValueTypes["Contember_FieldMeta"],
	failedAt?:ValueTypes["Contember_FieldMeta"],
	revertedAt?:ValueTypes["Contember_FieldMeta"],
	resultCustomerCreditTransaction?:ValueTypes["Contember_FieldMeta"],
	resultVouchers?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItem"]: AliasType<{
	_meta?:ValueTypes["OrderItemMeta"],
	id?:boolean | `@${string}`,
	unitPriceCents?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
virtualProductEffects?: [{	filter?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VirtualProductEffectOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_VirtualProductEffect"]],
	pickedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
fulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_FulfillmentNote"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
virtualProductEffectsByResultCustomerCreditTransaction?: [{	by: ValueTypes["Contember_OrderItemVirtualProductEffectsByResultCustomerCreditTransactionUniqueWhere"],	filter?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null},ValueTypes["Contember_VirtualProductEffect"]],
virtualProductEffectsByResultVouchers?: [{	by: ValueTypes["Contember_OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"],	filter?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null},ValueTypes["Contember_VirtualProductEffect"]],
paginateVirtualProductEffects?: [{	filter?: ValueTypes["Contember_VirtualProductEffectWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_VirtualProductEffectOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_VirtualProductEffectConnection"]],
paginateFulfillmentNotes?: [{	filter?: ValueTypes["Contember_FulfillmentNoteWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FulfillmentNoteOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_FulfillmentNoteConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	unitPriceCents?:ValueTypes["Contember_FieldMeta"],
	quantity?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	virtualProductEffects?:ValueTypes["Contember_FieldMeta"],
	pickedAt?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	fulfillmentNotes?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FulfillmentNoteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	highlight?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	emoji?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_OrderItemVirtualProductEffectsByResultCustomerCreditTransactionUniqueWhere"]: {
	resultCustomerCreditTransaction?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null
};
	["Contember_OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"]: {
	resultVouchers?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null
};
	["Contember_VirtualProductEffectConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_VirtualProductEffectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VirtualProductEffectEdge"]: AliasType<{
	node?:ValueTypes["Contember_VirtualProductEffect"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FulfillmentNoteConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_FulfillmentNoteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FulfillmentNoteEdge"]: AliasType<{
	node?:ValueTypes["Contember_FulfillmentNote"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VirtualProductEffectResultVouchersByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["Contember_VirtualProductEffectResultVouchersByRedemptionUniqueWhere"]: {
	redemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_VoucherConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_VoucherEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VoucherEdge"]: AliasType<{
	node?:ValueTypes["Contember_Voucher"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerCreditTransactionsByVirtualProductEffectUniqueWhere"]: {
	virtualProductEffect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreditTransactionConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CustomerCreditTransactionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerCreditTransactionEdge"]: AliasType<{
	node?:ValueTypes["Contember_CustomerCreditTransaction"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderDiscountCustomerCreditTransactionsByVirtualProductEffectUniqueWhere"]: {
	virtualProductEffect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null
};
	["Contember_OrderEvent"]: AliasType<{
	_meta?:ValueTypes["Contember_OrderEventMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
payment?: [{	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
delivery?: [{	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderEventMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	data?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	payment?:ValueTypes["Contember_FieldMeta"],
	delivery?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	data?:ValueTypes["Contember_FieldMeta"],
	paymentCents?:ValueTypes["Contember_FieldMeta"],
	method?:ValueTypes["Contember_FieldMeta"],
	failedAt?:ValueTypes["Contember_FieldMeta"],
	state?:ValueTypes["Contember_FieldMeta"],
	approvedAt?:ValueTypes["Contember_FieldMeta"],
	events?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	invoice?:ValueTypes["Contember_FieldMeta"],
	walletType?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	publicReceiptUrl?:ValueTypes["Contember_FieldMeta"],
	priceCents?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
	otherCurrencyPriceCents?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderEventOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	data?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null,
	payment?: ValueTypes["Contember_OrderPaymentOrderBy"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderDeliveryOrderBy"] | undefined | null
};
	["Contember_OrderPaymentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	data?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	paymentCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	method?: ValueTypes["Contember_PaymentMethodOrderBy"] | undefined | null,
	failedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	state?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	approvedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null,
	invoice?: ValueTypes["Contember_InvoiceOrderBy"] | undefined | null,
	walletType?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	publicReceiptUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentMetadataOrderBy"] | undefined | null,
	otherCurrencyPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_InvoiceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	publicKey?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fakturoidId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fakturoidData?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	legacyUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	address?: ValueTypes["Contember_AddressOrderBy"] | undefined | null,
	publicUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	billingSubject?: ValueTypes["Contember_BillingSubjectOrderBy"] | undefined | null
};
	["Contember_OrderPaymentMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	paymentUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderPayment?: ValueTypes["Contember_OrderPaymentOrderBy"] | undefined | null
};
	["Contember_Invoice"]: AliasType<{
	_meta?:ValueTypes["Contember_InvoiceMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	publicKey?:boolean | `@${string}`,
	fakturoidId?:boolean | `@${string}`,
	fakturoidData?:boolean | `@${string}`,
	legacyUrl?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
orderPayments?: [{	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderPaymentOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["OrderPayment"]],
address?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
	publicUrl?:boolean | `@${string}`,
billingSubject?: [{	filter?: ValueTypes["Contember_BillingSubjectWhere"] | undefined | null},ValueTypes["Contember_BillingSubject"]],
orderPaymentsByEvents?: [{	by: ValueTypes["Contember_InvoiceOrderPaymentsByEventsUniqueWhere"],	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
orderPaymentsByMeta?: [{	by: ValueTypes["Contember_InvoiceOrderPaymentsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
paginateOrderPayments?: [{	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderPaymentOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderPaymentConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InvoiceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	publicKey?:ValueTypes["Contember_FieldMeta"],
	fakturoidId?:ValueTypes["Contember_FieldMeta"],
	fakturoidData?:ValueTypes["Contember_FieldMeta"],
	legacyUrl?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	orderPayments?:ValueTypes["Contember_FieldMeta"],
	address?:ValueTypes["Contember_FieldMeta"],
	publicUrl?:ValueTypes["Contember_FieldMeta"],
	billingSubject?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BillingSubject"]: AliasType<{
	_meta?:ValueTypes["Contember_BillingSubjectMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	fullName?:boolean | `@${string}`,
	addressLine1?:boolean | `@${string}`,
	addressLine2?:boolean | `@${string}`,
	city?:boolean | `@${string}`,
	postalCode?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
	companyName?:boolean | `@${string}`,
	companyIdentifier?:boolean | `@${string}`,
	vatIdentifier?:boolean | `@${string}`,
	externalIdentifier?:boolean | `@${string}`,
	hidden?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	deletedAt?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
	fakturoidSubjectId?:boolean | `@${string}`,
country?: [{	filter?: ValueTypes["Contember_CountryWhere"] | undefined | null},ValueTypes["Contember_Country"]],
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
invoices?: [{	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InvoiceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Invoice"]],
defaultBillingSubjectOfCustomer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
orders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Order"]],
invoicesByPublicKey?: [{	by: ValueTypes["Contember_BillingSubjectInvoicesByPublicKeyUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
invoicesByFakturoidId?: [{	by: ValueTypes["Contember_BillingSubjectInvoicesByFakturoidIdUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
invoicesByOrderPayments?: [{	by: ValueTypes["Contember_BillingSubjectInvoicesByOrderPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null},ValueTypes["Contember_Invoice"]],
ordersBySeq?: [{	by: ValueTypes["Contember_BillingSubjectOrdersBySeqUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleshotLegacyId?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDoubleShotLegacyNumber?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDiscounts?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByDiscountsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByDelivery?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByDeliveryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByItems?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByItemsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByCart?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByCartUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByEvents?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByEventsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPriceLines?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByPriceLinesUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByPayments?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByPaymentsUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersBySummary?: [{	by: ValueTypes["Contember_BillingSubjectOrdersBySummaryUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
ordersByGratuity?: [{	by: ValueTypes["Contember_BillingSubjectOrdersByGratuityUniqueWhere"],	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
paginateInvoices?: [{	filter?: ValueTypes["Contember_InvoiceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_InvoiceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_InvoiceConnection"]],
paginateOrders?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["OrderConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BillingSubjectMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	fullName?:ValueTypes["Contember_FieldMeta"],
	addressLine1?:ValueTypes["Contember_FieldMeta"],
	addressLine2?:ValueTypes["Contember_FieldMeta"],
	city?:ValueTypes["Contember_FieldMeta"],
	postalCode?:ValueTypes["Contember_FieldMeta"],
	email?:ValueTypes["Contember_FieldMeta"],
	phone?:ValueTypes["Contember_FieldMeta"],
	companyName?:ValueTypes["Contember_FieldMeta"],
	companyIdentifier?:ValueTypes["Contember_FieldMeta"],
	vatIdentifier?:ValueTypes["Contember_FieldMeta"],
	externalIdentifier?:ValueTypes["Contember_FieldMeta"],
	hidden?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	deletedAt?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	fakturoidSubjectId?:ValueTypes["Contember_FieldMeta"],
	country?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	invoices?:ValueTypes["Contember_FieldMeta"],
	defaultBillingSubjectOfCustomer?:ValueTypes["Contember_FieldMeta"],
	orders?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Country"]: AliasType<{
	_meta?:ValueTypes["Contember_CountryMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_CountryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BillingSubjectInvoicesByPublicKeyUniqueWhere"]: {
	publicKey?: string | undefined | null
};
	["Contember_BillingSubjectInvoicesByFakturoidIdUniqueWhere"]: {
	fakturoidId?: string | undefined | null
};
	["Contember_BillingSubjectInvoicesByOrderPaymentsUniqueWhere"]: {
	orderPayments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["Contember_BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["Contember_BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["Contember_BillingSubjectOrdersByDiscountsUniqueWhere"]: {
	discounts?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersByCartUniqueWhere"]: {
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersByEventsUniqueWhere"]: {
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersBySummaryUniqueWhere"]: {
	summary?: ValueTypes["Contember_OrderSummaryUniqueWhere"] | undefined | null
};
	["Contember_BillingSubjectOrdersByGratuityUniqueWhere"]: {
	gratuity?: ValueTypes["Contember_GratuityUniqueWhere"] | undefined | null
};
	["Contember_InvoiceConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_InvoiceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InvoiceEdge"]: AliasType<{
	node?:ValueTypes["Contember_Invoice"],
		__typename?: boolean | `@${string}`
}>;
	["OrderConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["OrderEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderEdge"]: AliasType<{
	node?:ValueTypes["Order"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InvoiceOrderPaymentsByEventsUniqueWhere"]: {
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null
};
	["Contember_InvoiceOrderPaymentsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined | null
};
	["OrderPaymentConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["OrderPaymentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPaymentEdge"]: AliasType<{
	node?:ValueTypes["OrderPayment"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderPaymentMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_OrderPaymentMetadataMeta"],
	id?:boolean | `@${string}`,
	paymentUrl?:boolean | `@${string}`,
orderPayment?: [{	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderPaymentMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	paymentUrl?:ValueTypes["Contember_FieldMeta"],
	orderPayment?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderEventConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_OrderEventEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderEventEdge"]: AliasType<{
	node?:ValueTypes["Contember_OrderEvent"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLine"]: AliasType<{
	_meta?:ValueTypes["OrderPriceLineMeta"],
	id?:boolean | `@${string}`,
	unitPriceCents?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
orderItem?: [{	filter?: ValueTypes["Contember_OrderItemWhere"] | undefined | null},ValueTypes["OrderItem"]],
orderDiscount?: [{	filter?: ValueTypes["Contember_OrderDiscountWhere"] | undefined | null},ValueTypes["OrderDiscount"]],
orderPayment?: [{	filter?: ValueTypes["Contember_OrderPaymentWhere"] | undefined | null},ValueTypes["OrderPayment"]],
orderDelivery?: [{	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
	vatRatePermille?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLineMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	unitPriceCents?:ValueTypes["Contember_FieldMeta"],
	quantity?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	orderItem?:ValueTypes["Contember_FieldMeta"],
	orderDiscount?:ValueTypes["Contember_FieldMeta"],
	orderPayment?:ValueTypes["Contember_FieldMeta"],
	orderDelivery?:ValueTypes["Contember_FieldMeta"],
	vatRatePermille?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderPriceLineOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unitPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	quantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderItem?: ValueTypes["Contember_OrderItemOrderBy"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_OrderDiscountOrderBy"] | undefined | null,
	orderPayment?: ValueTypes["Contember_OrderPaymentOrderBy"] | undefined | null,
	orderDelivery?: ValueTypes["Contember_OrderDeliveryOrderBy"] | undefined | null,
	vatRatePermille?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderOrderBy"] | undefined | null
};
	["OrderSummary"]: AliasType<{
	_meta?:ValueTypes["OrderSummaryMeta"],
	id?:boolean | `@${string}`,
	totalPriceCents?:boolean | `@${string}`,
	discountPriceCents?:boolean | `@${string}`,
	undiscountedPriceCents?:boolean | `@${string}`,
	itemsQuantity?:boolean | `@${string}`,
	orderUrl?:boolean | `@${string}`,
	orderNumber?:boolean | `@${string}`,
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
		__typename?: boolean | `@${string}`
}>;
	["OrderSummaryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	totalPriceCents?:ValueTypes["Contember_FieldMeta"],
	discountPriceCents?:ValueTypes["Contember_FieldMeta"],
	undiscountedPriceCents?:ValueTypes["Contember_FieldMeta"],
	itemsQuantity?:ValueTypes["Contember_FieldMeta"],
	orderUrl?:ValueTypes["Contember_FieldMeta"],
	orderNumber?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Gratuity"]: AliasType<{
	_meta?:ValueTypes["Contember_GratuityMeta"],
	id?:boolean | `@${string}`,
	amountCents?:boolean | `@${string}`,
order?: [{	filter?: ValueTypes["Contember_OrderWhere"] | undefined | null},ValueTypes["Order"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GratuityMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	amountCents?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderDiscountsByCustomerCreditTransactionsUniqueWhere"]: {
	customerCreditTransactions?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null
};
	["Contember_OrderDiscountsByVoucherRedemptionUniqueWhere"]: {
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_OrderItemsByVirtualProductEffectsUniqueWhere"]: {
	virtualProductEffects?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null
};
	["Contember_OrderPriceLinesByOrderItemUniqueWhere"]: {
	orderItem?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null
};
	["Contember_OrderPriceLinesByOrderDiscountUniqueWhere"]: {
	orderDiscount?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null
};
	["Contember_OrderPriceLinesByOrderPaymentUniqueWhere"]: {
	orderPayment?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_OrderPriceLinesByOrderDeliveryUniqueWhere"]: {
	orderDelivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_OrderPaymentsByEventsUniqueWhere"]: {
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null
};
	["Contember_OrderPaymentsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined | null
};
	["OrderDiscountConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["OrderDiscountEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDiscountEdge"]: AliasType<{
	node?:ValueTypes["OrderDiscount"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["OrderItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderItemEdge"]: AliasType<{
	node?:ValueTypes["OrderItem"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLineConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["OrderPriceLineEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderPriceLineEdge"]: AliasType<{
	node?:ValueTypes["OrderPriceLine"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Area"]: AliasType<{
	_meta?:ValueTypes["Contember_AreaMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
venue?: [{	filter?: ValueTypes["Contember_VenueWhere"] | undefined | null},ValueTypes["Contember_Venue"]],
tables?: [{	filter?: ValueTypes["Contember_TableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TableOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Table"]],
lines?: [{	filter?: ValueTypes["Contember_LineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_LineOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Line"]],
tablesByCustomerTabs?: [{	by: ValueTypes["Contember_AreaTablesByCustomerTabsUniqueWhere"],	filter?: ValueTypes["Contember_TableWhere"] | undefined | null},ValueTypes["Contember_Table"]],
tablesBySeats?: [{	by: ValueTypes["Contember_AreaTablesBySeatsUniqueWhere"],	filter?: ValueTypes["Contember_TableWhere"] | undefined | null},ValueTypes["Contember_Table"]],
paginateTables?: [{	filter?: ValueTypes["Contember_TableWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TableOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TableConnection"]],
paginateLines?: [{	filter?: ValueTypes["Contember_LineWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_LineOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_LineConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AreaMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	venue?:ValueTypes["Contember_FieldMeta"],
	tables?:ValueTypes["Contember_FieldMeta"],
	lines?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Table"]: AliasType<{
	_meta?:ValueTypes["Contember_TableMeta"],
	id?:boolean | `@${string}`,
	shape?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	positionX?:boolean | `@${string}`,
	positionY?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
customerTabs?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerTab"]],
area?: [{	filter?: ValueTypes["Contember_AreaWhere"] | undefined | null},ValueTypes["Contember_Area"]],
seats?: [{	filter?: ValueTypes["Contember_SeatWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_SeatOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Seat"]],
customerTabsByItems?: [{	by: ValueTypes["Contember_TableCustomerTabsByItemsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
customerTabsByMeta?: [{	by: ValueTypes["Contember_TableCustomerTabsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
seatsByCustomerTabs?: [{	by: ValueTypes["Contember_TableSeatsByCustomerTabsUniqueWhere"],	filter?: ValueTypes["Contember_SeatWhere"] | undefined | null},ValueTypes["Contember_Seat"]],
paginateCustomerTabs?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerTabConnection"]],
paginateSeats?: [{	filter?: ValueTypes["Contember_SeatWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_SeatOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_SeatConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TableMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	shape?:ValueTypes["Contember_FieldMeta"],
	width?:ValueTypes["Contember_FieldMeta"],
	height?:ValueTypes["Contember_FieldMeta"],
	positionX?:ValueTypes["Contember_FieldMeta"],
	positionY?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	color?:ValueTypes["Contember_FieldMeta"],
	customerTabs?:ValueTypes["Contember_FieldMeta"],
	area?:ValueTypes["Contember_FieldMeta"],
	seats?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTab"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerTabMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_CustomerTabItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerTabItem"]],
	name?:boolean | `@${string}`,
meta?: [{	filter?: ValueTypes["Contember_CustomerTabMetadataWhere"] | undefined | null},ValueTypes["Contember_CustomerTabMetadata"]],
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
table?: [{	filter?: ValueTypes["Contember_TableWhere"] | undefined | null},ValueTypes["Contember_Table"]],
seat?: [{	filter?: ValueTypes["Contember_SeatWhere"] | undefined | null},ValueTypes["Contember_Seat"]],
paginateItems?: [{	filter?: ValueTypes["Contember_CustomerTabItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerTabItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	color?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	table?:ValueTypes["Contember_FieldMeta"],
	seat?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabItem"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerTabItemMeta"],
	id?:boolean | `@${string}`,
	addedAt?:boolean | `@${string}`,
tab?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
	paidAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	addedAt?:ValueTypes["Contember_FieldMeta"],
	tab?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	paidAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	addedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	tab?: ValueTypes["Contember_CustomerTabOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	paidAt?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CustomerTabOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	color?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabMetadataOrderBy"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	table?: ValueTypes["Contember_TableOrderBy"] | undefined | null,
	seat?: ValueTypes["Contember_SeatOrderBy"] | undefined | null
};
	["Contember_CustomerTabMetadataOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	totalPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	totalSpentCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unpaidCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customerTab?: ValueTypes["Contember_CustomerTabOrderBy"] | undefined | null
};
	["Contember_TableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	shape?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	width?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	height?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionX?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionY?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	color?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	area?: ValueTypes["Contember_AreaOrderBy"] | undefined | null
};
	["Contember_AreaOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	venue?: ValueTypes["Contember_VenueOrderBy"] | undefined | null
};
	["Contember_SeatOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionX?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionY?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	color?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	table?: ValueTypes["Contember_TableOrderBy"] | undefined | null
};
	["Contember_CustomerTabMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerTabMetadataMeta"],
	id?:boolean | `@${string}`,
	totalPriceCents?:boolean | `@${string}`,
	totalSpentCents?:boolean | `@${string}`,
	unpaidCents?:boolean | `@${string}`,
customerTab?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	totalPriceCents?:ValueTypes["Contember_FieldMeta"],
	totalSpentCents?:ValueTypes["Contember_FieldMeta"],
	unpaidCents?:ValueTypes["Contember_FieldMeta"],
	customerTab?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Seat"]: AliasType<{
	_meta?:ValueTypes["Contember_SeatMeta"],
	id?:boolean | `@${string}`,
	positionX?:boolean | `@${string}`,
	positionY?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
customerTabs?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerTab"]],
table?: [{	filter?: ValueTypes["Contember_TableWhere"] | undefined | null},ValueTypes["Contember_Table"]],
customerTabsByItems?: [{	by: ValueTypes["Contember_SeatCustomerTabsByItemsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
customerTabsByMeta?: [{	by: ValueTypes["Contember_SeatCustomerTabsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null},ValueTypes["Contember_CustomerTab"]],
paginateCustomerTabs?: [{	filter?: ValueTypes["Contember_CustomerTabWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerTabOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerTabConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SeatMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	positionX?:ValueTypes["Contember_FieldMeta"],
	positionY?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	color?:ValueTypes["Contember_FieldMeta"],
	customerTabs?:ValueTypes["Contember_FieldMeta"],
	table?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SeatCustomerTabsByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null
};
	["Contember_SeatCustomerTabsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined | null
};
	["Contember_CustomerTabConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CustomerTabEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabEdge"]: AliasType<{
	node?:ValueTypes["Contember_CustomerTab"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CustomerTabItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerTabItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_CustomerTabItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TableCustomerTabsByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null
};
	["Contember_TableCustomerTabsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined | null
};
	["Contember_TableSeatsByCustomerTabsUniqueWhere"]: {
	customerTabs?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null
};
	["Contember_SeatConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_SeatEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SeatEdge"]: AliasType<{
	node?:ValueTypes["Contember_Seat"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Line"]: AliasType<{
	_meta?:ValueTypes["Contember_LineMeta"],
	id?:boolean | `@${string}`,
	positionStartX?:boolean | `@${string}`,
	positionStartY?:boolean | `@${string}`,
	positionEndX?:boolean | `@${string}`,
	positionEndY?:boolean | `@${string}`,
area?: [{	filter?: ValueTypes["Contember_AreaWhere"] | undefined | null},ValueTypes["Contember_Area"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LineMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	positionStartX?:ValueTypes["Contember_FieldMeta"],
	positionStartY?:ValueTypes["Contember_FieldMeta"],
	positionEndX?:ValueTypes["Contember_FieldMeta"],
	positionEndY?:ValueTypes["Contember_FieldMeta"],
	area?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LineOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionStartX?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionStartY?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionEndX?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	positionEndY?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	area?: ValueTypes["Contember_AreaOrderBy"] | undefined | null
};
	["Contember_AreaTablesByCustomerTabsUniqueWhere"]: {
	customerTabs?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null
};
	["Contember_AreaTablesBySeatsUniqueWhere"]: {
	seats?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null
};
	["Contember_SeatUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customerTabs?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null
};
	["Contember_TableConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TableEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TableEdge"]: AliasType<{
	node?:ValueTypes["Contember_Table"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LineConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_LineEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LineEdge"]: AliasType<{
	node?:ValueTypes["Contember_Line"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueTimetableDaysByDateUniqueWhere"]: {
	date?: ValueTypes["Date"] | undefined | null
};
	["Contember_VenueTimetableDaysByShiftGroupsUniqueWhere"]: {
	shiftGroups?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null
};
	["Contember_VenueTimetableDaysByNotesUniqueWhere"]: {
	notes?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null
};
	["Contember_TimetableDayNoteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_VenueTimetableDaysByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined | null
};
	["Contember_TimetableDayMetadataUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	day?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null
};
	["Contember_TimetableDayUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venue?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	shiftGroups?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	notes?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null,
	meta?: ValueTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined | null
};
	["Contember_VenueUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_DeadlineTemplateUniqueWhere"] | undefined | null,
	timetableDays?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	employeeRoles?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null,
	pointOfSaleApplicationDevices?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	pointOfSaleTerminals?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	pointOfSalePrinters?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	orders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	areas?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	categories?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	channels?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null
};
	["Contember_DeadlineTemplateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venue?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null
};
	["Contember_TimetableTemplateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staffOptions?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null
};
	["Contember_VenueEmployeeRoleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venue?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	role?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	employeePrivileges?: ValueTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined | null
};
	["Contember_TimetableEmployeeRoleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null
};
	["Contember_TimetablesEmployeePrivilegesUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	venueEmployeeRole?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleTerminalUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	applicationDevices?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null
};
	["Contember_PointOfSalePrinterUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	applicationDevicesReceipt?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null
};
	["Contember_AreaUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	tables?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	lines?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null
};
	["Contember_TableUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customerTabs?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	seats?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null
};
	["Contember_LineUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ChannelUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	payments?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	deliveries?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	carts?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_ChannelPaymentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_VenueTimetableTemplatesByStaffOptionsUniqueWhere"]: {
	staffOptions?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null
};
	["Contember_VenueEmployeeRolesByRoleUniqueWhere"]: {
	role?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null
};
	["Contember_VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"]: {
	employeePrivileges?: ValueTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined | null
};
	["Contember_VenuePointOfSaleApplicationDevicesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null
};
	["Contember_VenuePointOfSaleTerminalsByApplicationDevicesUniqueWhere"]: {
	applicationDevices?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null
};
	["Contember_VenuePointOfSalePrintersByApplicationDevicesReceiptUniqueWhere"]: {
	applicationDevicesReceipt?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["Contember_VenueOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["Contember_VenueOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["Contember_VenueOrdersByDiscountsUniqueWhere"]: {
	discounts?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersByCartUniqueWhere"]: {
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersByEventsUniqueWhere"]: {
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersBySummaryUniqueWhere"]: {
	summary?: ValueTypes["Contember_OrderSummaryUniqueWhere"] | undefined | null
};
	["Contember_VenueOrdersByGratuityUniqueWhere"]: {
	gratuity?: ValueTypes["Contember_GratuityUniqueWhere"] | undefined | null
};
	["Contember_VenueAreasByTablesUniqueWhere"]: {
	tables?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null
};
	["Contember_VenueAreasByLinesUniqueWhere"]: {
	lines?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null
};
	["Contember_VenueCategoriesBySlugUniqueWhere"]: {
	slug?: string | undefined | null
};
	["Contember_VenueCategoriesByLocalesUniqueWhere"]: {
	locales?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null
};
	["Contember_VenueCategoriesByProductsUniqueWhere"]: {
	products?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null
};
	["Contember_VenueChannelsByCodeUniqueWhere"]: {
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null
};
	["Contember_VenueChannelsByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null
};
	["Contember_VenueChannelsByDeliveriesUniqueWhere"]: {
	deliveries?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null
};
	["Contember_VenueChannelsByCartsUniqueWhere"]: {
	carts?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_TimetableDayConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TimetableDayEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TimetableDayEdge"]: AliasType<{
	node?:ValueTypes["Contember_TimetableDay"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_StaffConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_StaffEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_StaffEdge"]: AliasType<{
	node?:ValueTypes["Contember_Staff"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueEmployeeRoleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_VenueEmployeeRoleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VenueEmployeeRoleEdge"]: AliasType<{
	node?:ValueTypes["Contember_VenueEmployeeRole"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleTerminalConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSaleTerminalEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleTerminalEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSaleTerminal"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AreaConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_AreaEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AreaEdge"]: AliasType<{
	node?:ValueTypes["Contember_Area"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UnassignReasonConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_UnassignReasonEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_UnassignReasonEdge"]: AliasType<{
	node?:ValueTypes["Contember_UnassignReason"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	quantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	cart?: ValueTypes["Contember_CartOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	updatedAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fulfillmentNote?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CartItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CartItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_CartItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartPriceLine"]: AliasType<{
	_meta?:ValueTypes["Contember_CartPriceLineMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["Contember_CartItemWhere"] | undefined | null},ValueTypes["Contember_CartItem"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
	unitPriceCents?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
	vatRatePermille?:boolean | `@${string}`,
vatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
cart?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartPriceLineMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	item?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	unitPriceCents?:ValueTypes["Contember_FieldMeta"],
	priceCents?:ValueTypes["Contember_FieldMeta"],
	quantity?:ValueTypes["Contember_FieldMeta"],
	vatRatePermille?:ValueTypes["Contember_FieldMeta"],
	vatRate?:ValueTypes["Contember_FieldMeta"],
	cart?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartPriceLineOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	item?: ValueTypes["Contember_CartItemOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	unitPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	quantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatRatePermille?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	vatRate?: ValueTypes["Contember_VatRateOrderBy"] | undefined | null,
	cart?: ValueTypes["Contember_CartOrderBy"] | undefined | null
};
	["Contember_CartSummary"]: AliasType<{
	_meta?:ValueTypes["Contember_CartSummaryMeta"],
	id?:boolean | `@${string}`,
	itemsPriceCents?:boolean | `@${string}`,
	deliveryPriceCents?:boolean | `@${string}`,
	totalPriceCents?:boolean | `@${string}`,
	itemsCount?:boolean | `@${string}`,
	itemsQuantity?:boolean | `@${string}`,
	checkoutUrl?:boolean | `@${string}`,
cart?: [{	filter?: ValueTypes["Contember_CartWhere"] | undefined | null},ValueTypes["Contember_Cart"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartSummaryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	itemsPriceCents?:ValueTypes["Contember_FieldMeta"],
	deliveryPriceCents?:ValueTypes["Contember_FieldMeta"],
	totalPriceCents?:ValueTypes["Contember_FieldMeta"],
	itemsCount?:ValueTypes["Contember_FieldMeta"],
	itemsQuantity?:ValueTypes["Contember_FieldMeta"],
	checkoutUrl?:ValueTypes["Contember_FieldMeta"],
	cart?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartItemsByProductUniqueWhere"]: {
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_CartPriceLineConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CartPriceLineEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartPriceLineEdge"]: AliasType<{
	node?:ValueTypes["Contember_CartPriceLine"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ChannelCartsByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null
};
	["Contember_ChannelCartsByOrderUniqueWhere"]: {
	order?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_ChannelCartsByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["Contember_CartPriceLineUniqueWhere"] | undefined | null
};
	["Contember_ChannelCartsBySummaryUniqueWhere"]: {
	summary?: ValueTypes["Contember_CartSummaryUniqueWhere"] | undefined | null
};
	["ChannelPaymentConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["ChannelPaymentEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelPaymentEdge"]: AliasType<{
	node?:ValueTypes["ChannelPayment"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDeliveryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["ChannelDeliveryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["ChannelDeliveryEdge"]: AliasType<{
	node?:ValueTypes["ChannelDelivery"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PointOfSaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PointOfSaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_PointOfSale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CartEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CartEdge"]: AliasType<{
	node?:ValueTypes["Contember_Cart"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CustomerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerEdge"]: AliasType<{
	node?:ValueTypes["Contember_Customer"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LiftagoRide"]: AliasType<{
	_meta?:ValueTypes["Contember_LiftagoRideMeta"],
	id?:boolean | `@${string}`,
	liftagoId?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
delivery?: [{	filter?: ValueTypes["Contember_OrderDeliveryWhere"] | undefined | null},ValueTypes["OrderDelivery"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LiftagoRideMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	liftagoId?:ValueTypes["Contember_FieldMeta"],
	data?:ValueTypes["Contember_FieldMeta"],
	updatedAt?:ValueTypes["Contember_FieldMeta"],
	delivery?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRouteWaypointsByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_DeliveryWaypointConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_DeliveryWaypointEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryWaypointEdge"]: AliasType<{
	node?:ValueTypes["Contember_DeliveryWaypoint"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CourierRoutesByWaypointsUniqueWhere"]: {
	waypoints?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null
};
	["Contember_DeliveryRouteConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_DeliveryRouteEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRouteEdge"]: AliasType<{
	node?:ValueTypes["Contember_DeliveryRoute"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["DeliveryMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryMethodEdge"]: AliasType<{
	node?:ValueTypes["DeliveryMethod"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Cleaner"]: AliasType<{
	_meta?:ValueTypes["Contember_CleanerMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
	canReachTopShelf?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_CleanerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	canReachTopShelf?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_StocksManager"]: AliasType<{
	_meta?:ValueTypes["Contember_StocksManagerMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
managedProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
	createdAt?:boolean | `@${string}`,
managedProductsByCode?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByCodeUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByStocks?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByStocksUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByGroupPrices?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByGroupPricesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByCartItems?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByCartItemsUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByOrderItems?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByOrderItemsUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByInheritedCategories?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByInheritedCategoriesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByLocales?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByLocalesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByCustomerPrices?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByCustomerPricesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByVirtualProduct?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByVirtualProductUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByMeta?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsByPage?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsByPageUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsBySalesDays?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsBySalesDaysUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsBySalesWeeks?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsBySalesWeeksUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
managedProductsBySalesMonths?: [{	by: ValueTypes["Contember_StocksManagerManagedProductsBySalesMonthsUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
paginateManagedProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_StocksManagerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	managedProducts?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_StocksManagerManagedProductsByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["Contember_StocksManagerManagedProductsByStocksUniqueWhere"]: {
	stocks?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByGroupPricesUniqueWhere"]: {
	groupPrices?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByCartItemsUniqueWhere"]: {
	cartItems?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByOrderItemsUniqueWhere"]: {
	orderItems?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByInheritedCategoriesUniqueWhere"]: {
	inheritedCategories?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByCustomerPricesUniqueWhere"]: {
	customerPrices?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByVirtualProductUniqueWhere"]: {
	virtualProduct?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_ProductMetadataUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsByPageUniqueWhere"]: {
	page?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsBySalesDaysUniqueWhere"]: {
	salesDays?: ValueTypes["Contember_ProductSalesDayUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsBySalesWeeksUniqueWhere"]: {
	salesWeeks?: ValueTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined | null
};
	["Contember_StocksManagerManagedProductsBySalesMonthsUniqueWhere"]: {
	salesMonths?: ValueTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined | null
};
	["Contember_CourierManager"]: AliasType<{
	_meta?:ValueTypes["Contember_CourierManagerMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_CourierManagerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Picker"]: AliasType<{
	_meta?:ValueTypes["Contember_PickerMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
	createdAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_PickerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_RemunerationProfile"]: AliasType<{
	_meta?:ValueTypes["Contember_RemunerationProfileMeta"],
	id?:boolean | `@${string}`,
employmentInformation?: [{	filter?: ValueTypes["Contember_EmploymentInformationWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_EmploymentInformationOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_EmploymentInformation"]],
workMonths?: [{	filter?: ValueTypes["Contember_WorkMonthWhere"] | undefined | null},ValueTypes["Contember_WorkMonth"]],
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
paginateEmploymentInformation?: [{	filter?: ValueTypes["Contember_EmploymentInformationWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_EmploymentInformationOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_EmploymentInformationConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_RemunerationProfileMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	employmentInformation?:ValueTypes["Contember_FieldMeta"],
	workMonths?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmploymentInformation"]: AliasType<{
	_meta?:ValueTypes["Contember_EmploymentInformationMeta"],
	id?:boolean | `@${string}`,
	hourlyRate?:boolean | `@${string}`,
remunerationProfiles?: [{	filter?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null},ValueTypes["Contember_RemunerationProfile"]],
	monthlySalary?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmploymentInformationMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	hourlyRate?:ValueTypes["Contember_FieldMeta"],
	remunerationProfiles?:ValueTypes["Contember_FieldMeta"],
	monthlySalary?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmploymentInformationOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	hourlyRate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	remunerationProfiles?: ValueTypes["Contember_RemunerationProfileOrderBy"] | undefined | null,
	monthlySalary?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_WorkMonth"]: AliasType<{
	_meta?:ValueTypes["Contember_WorkMonthMeta"],
	id?:boolean | `@${string}`,
	year?:boolean | `@${string}`,
	month?:boolean | `@${string}`,
	hoursWorked?:boolean | `@${string}`,
	billableHours?:boolean | `@${string}`,
remunerationProfile?: [{	filter?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_RemunerationProfileOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_RemunerationProfile"]],
remunerationProfileByEmploymentInformation?: [{	by: ValueTypes["Contember_WorkMonthRemunerationProfileByEmploymentInformationUniqueWhere"],	filter?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null},ValueTypes["Contember_RemunerationProfile"]],
remunerationProfileByStaff?: [{	by: ValueTypes["Contember_WorkMonthRemunerationProfileByStaffUniqueWhere"],	filter?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null},ValueTypes["Contember_RemunerationProfile"]],
paginateRemunerationProfile?: [{	filter?: ValueTypes["Contember_RemunerationProfileWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_RemunerationProfileOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_RemunerationProfileConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WorkMonthMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	year?:ValueTypes["Contember_FieldMeta"],
	month?:ValueTypes["Contember_FieldMeta"],
	hoursWorked?:ValueTypes["Contember_FieldMeta"],
	billableHours?:ValueTypes["Contember_FieldMeta"],
	remunerationProfile?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WorkMonthRemunerationProfileByEmploymentInformationUniqueWhere"]: {
	employmentInformation?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null
};
	["Contember_WorkMonthRemunerationProfileByStaffUniqueWhere"]: {
	staff?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null
};
	["Contember_RemunerationProfileConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_RemunerationProfileEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_RemunerationProfileEdge"]: AliasType<{
	node?:ValueTypes["Contember_RemunerationProfile"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmploymentInformationConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_EmploymentInformationEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_EmploymentInformationEdge"]: AliasType<{
	node?:ValueTypes["Contember_EmploymentInformation"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Cashier"]: AliasType<{
	_meta?:ValueTypes["Contember_CashierMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CashierMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InternalBuyer"]: AliasType<{
	_meta?:ValueTypes["Contember_InternalBuyerMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InternalBuyerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SalesManager"]: AliasType<{
	_meta?:ValueTypes["Contember_SalesManagerMeta"],
	id?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
	createdAt?:boolean | `@${string}`,
countries?: [{	filter?: ValueTypes["Contember_CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CountryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Country"]],
paginateCountries?: [{	filter?: ValueTypes["Contember_CountryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CountryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CountryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SalesManagerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	countries?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CountryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CountryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CountryEdge"]: AliasType<{
	node?:ValueTypes["Contember_Country"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CashierManager"]: AliasType<{
	_meta?:ValueTypes["Contember_CashierManagerMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
staff?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CashierManagerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	staff?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessToBusinessManagerManagedBusinessesByCustomerUniqueWhere"]: {
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_BusinessToBusinessManagerManagedBusinessesByBusinessServiceEventsUniqueWhere"]: {
	businessServiceEvents?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null
};
	["Contember_BusinessToBusinessManagerManagedBusinessesByDoubleshotCoffeeEquipmentUniqueWhere"]: {
	doubleshotCoffeeEquipment?: ValueTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined | null
};
	["Contember_BusinessCustomerConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BusinessCustomerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCustomerEdge"]: AliasType<{
	node?:ValueTypes["Contember_BusinessCustomer"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceEvent"]: AliasType<{
	_meta?:ValueTypes["Contember_BusinessServiceEventMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	fulfilledAt?:boolean | `@${string}`,
businessCustomer?: [{	filter?: ValueTypes["Contember_BusinessCustomerWhere"] | undefined | null},ValueTypes["Contember_BusinessCustomer"]],
businessService?: [{	filter?: ValueTypes["Contember_BusinessServiceWhere"] | undefined | null},ValueTypes["Contember_BusinessService"]],
	filledNumberOfPeople?:boolean | `@${string}`,
	filledInvoiced?:boolean | `@${string}`,
	filledEquipment?:boolean | `@${string}`,
	filledMillTamDem?:boolean | `@${string}`,
	filledSOEspresso?:boolean | `@${string}`,
	filledFilterCoffeeInMenu?:boolean | `@${string}`,
	filledWaterMeterStatus?:boolean | `@${string}`,
	filledServiceAction?:boolean | `@${string}`,
	filledInvoiceIdentifier?:boolean | `@${string}`,
	filledNote?:boolean | `@${string}`,
filledSupervisor?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
	filledContact?:boolean | `@${string}`,
	filledPhone?:boolean | `@${string}`,
	filledEmail?:boolean | `@${string}`,
	filledGrindingStonesCondition?:boolean | `@${string}`,
createdBy?: [{	filter?: ValueTypes["Contember_StaffWhere"] | undefined | null},ValueTypes["Contember_Staff"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceEventMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	fulfilledAt?:ValueTypes["Contember_FieldMeta"],
	businessCustomer?:ValueTypes["Contember_FieldMeta"],
	businessService?:ValueTypes["Contember_FieldMeta"],
	filledNumberOfPeople?:ValueTypes["Contember_FieldMeta"],
	filledInvoiced?:ValueTypes["Contember_FieldMeta"],
	filledEquipment?:ValueTypes["Contember_FieldMeta"],
	filledMillTamDem?:ValueTypes["Contember_FieldMeta"],
	filledSOEspresso?:ValueTypes["Contember_FieldMeta"],
	filledFilterCoffeeInMenu?:ValueTypes["Contember_FieldMeta"],
	filledWaterMeterStatus?:ValueTypes["Contember_FieldMeta"],
	filledServiceAction?:ValueTypes["Contember_FieldMeta"],
	filledInvoiceIdentifier?:ValueTypes["Contember_FieldMeta"],
	filledNote?:ValueTypes["Contember_FieldMeta"],
	filledSupervisor?:ValueTypes["Contember_FieldMeta"],
	filledContact?:ValueTypes["Contember_FieldMeta"],
	filledPhone?:ValueTypes["Contember_FieldMeta"],
	filledEmail?:ValueTypes["Contember_FieldMeta"],
	filledGrindingStonesCondition?:ValueTypes["Contember_FieldMeta"],
	createdBy?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessService"]: AliasType<{
	_meta?:ValueTypes["Contember_BusinessServiceMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	requireNote?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessServiceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessServiceLocale"]],
	requireNumberOfPeople?:boolean | `@${string}`,
	requireInvoiced?:boolean | `@${string}`,
	requireEquipment?:boolean | `@${string}`,
	requireMillTamDem?:boolean | `@${string}`,
	requireSOEspresso?:boolean | `@${string}`,
	requireFilterCoffeeInMenu?:boolean | `@${string}`,
	requireWaterMeterStatus?:boolean | `@${string}`,
	requireServiceAction?:boolean | `@${string}`,
	optionsEquipment?:boolean | `@${string}`,
	optionsServiceAction?:boolean | `@${string}`,
	requireInvoiceIdentifier?:boolean | `@${string}`,
	requireSupervisor?:boolean | `@${string}`,
	requireContact?:boolean | `@${string}`,
	requirePhone?:boolean | `@${string}`,
	requireEmail?:boolean | `@${string}`,
	requireGrindingStonesCondition?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["Contember_BusinessServiceLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null},ValueTypes["Contember_BusinessServiceLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_BusinessServiceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessServiceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessServiceLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	requireNote?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	requireNumberOfPeople?:ValueTypes["Contember_FieldMeta"],
	requireInvoiced?:ValueTypes["Contember_FieldMeta"],
	requireEquipment?:ValueTypes["Contember_FieldMeta"],
	requireMillTamDem?:ValueTypes["Contember_FieldMeta"],
	requireSOEspresso?:ValueTypes["Contember_FieldMeta"],
	requireFilterCoffeeInMenu?:ValueTypes["Contember_FieldMeta"],
	requireWaterMeterStatus?:ValueTypes["Contember_FieldMeta"],
	requireServiceAction?:ValueTypes["Contember_FieldMeta"],
	optionsEquipment?:ValueTypes["Contember_FieldMeta"],
	optionsServiceAction?:ValueTypes["Contember_FieldMeta"],
	requireInvoiceIdentifier?:ValueTypes["Contember_FieldMeta"],
	requireSupervisor?:ValueTypes["Contember_FieldMeta"],
	requireContact?:ValueTypes["Contember_FieldMeta"],
	requirePhone?:ValueTypes["Contember_FieldMeta"],
	requireEmail?:ValueTypes["Contember_FieldMeta"],
	requireGrindingStonesCondition?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_BusinessServiceLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	labelNote?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_BusinessServiceWhere"] | undefined | null},ValueTypes["Contember_BusinessService"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
	labelNumberOfPeople?:boolean | `@${string}`,
	labelInvoiced?:boolean | `@${string}`,
	labelEquipment?:boolean | `@${string}`,
	labelMillTamDem?:boolean | `@${string}`,
	labelSOEspresso?:boolean | `@${string}`,
	labelFilterCoffeeInMenu?:boolean | `@${string}`,
	labelWaterMeterStatus?:boolean | `@${string}`,
	labelServiceAction?:boolean | `@${string}`,
	labelInvoiceIdentifier?:boolean | `@${string}`,
	labelSupervisor?:boolean | `@${string}`,
	labelContact?:boolean | `@${string}`,
	labelPhone?:boolean | `@${string}`,
	labelEmail?:boolean | `@${string}`,
	labelGrindingStonesCondition?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	labelNote?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	labelNumberOfPeople?:ValueTypes["Contember_FieldMeta"],
	labelInvoiced?:ValueTypes["Contember_FieldMeta"],
	labelEquipment?:ValueTypes["Contember_FieldMeta"],
	labelMillTamDem?:ValueTypes["Contember_FieldMeta"],
	labelSOEspresso?:ValueTypes["Contember_FieldMeta"],
	labelFilterCoffeeInMenu?:ValueTypes["Contember_FieldMeta"],
	labelWaterMeterStatus?:ValueTypes["Contember_FieldMeta"],
	labelServiceAction?:ValueTypes["Contember_FieldMeta"],
	labelInvoiceIdentifier?:ValueTypes["Contember_FieldMeta"],
	labelSupervisor?:ValueTypes["Contember_FieldMeta"],
	labelContact?:ValueTypes["Contember_FieldMeta"],
	labelPhone?:ValueTypes["Contember_FieldMeta"],
	labelEmail?:ValueTypes["Contember_FieldMeta"],
	labelGrindingStonesCondition?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_BusinessServiceOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	labelNumberOfPeople?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelInvoiced?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelEquipment?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelMillTamDem?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelSOEspresso?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelFilterCoffeeInMenu?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelWaterMeterStatus?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelServiceAction?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelInvoiceIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelSupervisor?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelContact?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelPhone?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelEmail?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	labelGrindingStonesCondition?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_BusinessServiceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireNumberOfPeople?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireInvoiced?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireEquipment?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireMillTamDem?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireSOEspresso?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireFilterCoffeeInMenu?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireWaterMeterStatus?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireServiceAction?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	optionsEquipment?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	optionsServiceAction?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireInvoiceIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireSupervisor?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireContact?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requirePhone?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireEmail?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	requireGrindingStonesCondition?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_BusinessServiceLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_BusinessServiceLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BusinessServiceLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_BusinessServiceLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceEventOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fulfilledAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	businessCustomer?: ValueTypes["Contember_BusinessCustomerOrderBy"] | undefined | null,
	businessService?: ValueTypes["Contember_BusinessServiceOrderBy"] | undefined | null,
	filledNumberOfPeople?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledInvoiced?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledEquipment?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledMillTamDem?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledSOEspresso?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledFilterCoffeeInMenu?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledWaterMeterStatus?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledServiceAction?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledInvoiceIdentifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledSupervisor?: ValueTypes["Contember_StaffOrderBy"] | undefined | null,
	filledContact?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledPhone?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledEmail?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	filledGrindingStonesCondition?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdBy?: ValueTypes["Contember_StaffOrderBy"] | undefined | null
};
	["Contember_DoubleshotCoffeeEquipment"]: AliasType<{
	_meta?:ValueTypes["Contember_DoubleshotCoffeeEquipmentMeta"],
	id?:boolean | `@${string}`,
	espressoMachine?:boolean | `@${string}`,
	espressoGrinder?:boolean | `@${string}`,
	batchBrew?:boolean | `@${string}`,
	batchGrinder?:boolean | `@${string}`,
	waterFilter?:boolean | `@${string}`,
	mechanicalFilter?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_DoubleshotCoffeeEquipmentMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	espressoMachine?:ValueTypes["Contember_FieldMeta"],
	espressoGrinder?:ValueTypes["Contember_FieldMeta"],
	batchBrew?:ValueTypes["Contember_FieldMeta"],
	batchGrinder?:ValueTypes["Contember_FieldMeta"],
	waterFilter?:ValueTypes["Contember_FieldMeta"],
	mechanicalFilter?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceEventConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BusinessServiceEventEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessServiceEventEdge"]: AliasType<{
	node?:ValueTypes["Contember_BusinessServiceEvent"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrence"]: AliasType<{
	_meta?:ValueTypes["Contember_OrderRecurrenceMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderRecurrenceLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_OrderRecurrenceLocale"]],
	order?:boolean | `@${string}`,
businessCategory?: [{	filter?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["Contember_OrderRecurrenceLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null},ValueTypes["Contember_OrderRecurrenceLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_OrderRecurrenceLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_OrderRecurrenceLocaleConnection"]],
paginateBusinessCategory?: [{	filter?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BusinessCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BusinessCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrenceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	businessCategory?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrenceLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_OrderRecurrenceLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_OrderRecurrenceWhere"] | undefined | null},ValueTypes["Contember_OrderRecurrence"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrenceLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrenceLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_OrderRecurrenceOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_OrderRecurrenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_OrderRecurrenceLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_OrderRecurrenceLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrenceLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_OrderRecurrenceLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BusinessCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryEdge"]: AliasType<{
	node?:ValueTypes["Contember_BusinessCategory"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductList"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductListItem"]],
businessCategory?: [{	filter?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null},ValueTypes["Contember_BusinessCategory"]],
paginateItems?: [{	filter?: ValueTypes["Contember_ProductListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	businessCategory?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductListItem"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_ProductListWhere"] | undefined | null},ValueTypes["Contember_ProductList"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
	defaultQuantityForOrder?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductListItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	defaultQuantityForOrder?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_ProductListOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	defaultQuantityForOrder?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductListItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryList"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductCategoryListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategoryListItem"]],
locales?: [{	filter?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryListLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductCategoryListLocale"]],
seasonalProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null},ValueTypes["Contember_BusinessCategory"]],
specificProductsProductCategoriesOfBusinessCategory?: [{	filter?: ValueTypes["Contember_BusinessCategoryWhere"] | undefined | null},ValueTypes["Contember_BusinessCategory"]],
localesByLocale?: [{	by: ValueTypes["Contember_ProductCategoryListLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductCategoryListLocale"]],
paginateItems?: [{	filter?: ValueTypes["Contember_ProductCategoryListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryListItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_ProductCategoryListLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductCategoryListLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductCategoryListLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	seasonalProductCategoriesOfBusinessCategory?:ValueTypes["Contember_FieldMeta"],
	specificProductsProductCategoriesOfBusinessCategory?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListItem"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductCategoryListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null},ValueTypes["Contember_ProductCategoryList"]],
productCategory?: [{	filter?: ValueTypes["Contember_ProductCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductCategory"]],
	showRecipeImages?:boolean | `@${string}`,
	showPackingImages?:boolean | `@${string}`,
	showAllAvailableProducts?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	productCategory?:ValueTypes["Contember_FieldMeta"],
	showRecipeImages?:ValueTypes["Contember_FieldMeta"],
	showPackingImages?:ValueTypes["Contember_FieldMeta"],
	showAllAvailableProducts?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_ProductCategoryListOrderBy"] | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryOrderBy"] | undefined | null,
	showRecipeImages?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	showPackingImages?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	showAllAvailableProducts?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductCategoryListLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductCategoryListLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_ProductCategoryListWhere"] | undefined | null},ValueTypes["Contember_ProductCategoryList"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_ProductCategoryListOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_ProductCategoryListLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductCategoryListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductCategoryListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductCategoryListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductCategoryListLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCategoryListLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductCategoryListLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_BusinessCategoryBusinessCustomersByCustomerUniqueWhere"]: {
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_BusinessCategoryBusinessCustomersByBusinessServiceEventsUniqueWhere"]: {
	businessServiceEvents?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null
};
	["Contember_BusinessCategoryBusinessCustomersByDoubleshotCoffeeEquipmentUniqueWhere"]: {
	doubleshotCoffeeEquipment?: ValueTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined | null
};
	["Contember_BusinessCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BusinessCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BusinessCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_BusinessCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_OrderRecurrenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_OrderRecurrenceEdge"]: AliasType<{
	node?:ValueTypes["Contember_OrderRecurrence"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null},ValueTypes["Contember_ProductIngredient"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredient"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientMeta"],
	id?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredientLocale"]],
	internalName?:boolean | `@${string}`,
categories?: [{	filter?: ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredientCategory"]],
allergens?: [{	filter?: ValueTypes["Contember_AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AllergenOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Allergen"]],
	needsPrepping?:boolean | `@${string}`,
	allowCustomerRating?:boolean | `@${string}`,
customerRatings?: [{	filter?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerIngredientRatingOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerIngredientRating"]],
preferredQuantityUnit?: [{	filter?: ValueTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientItemQuantityUnit"]],
freshingContainer?: [{	filter?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null},ValueTypes["Contember_FreshingContainer"]],
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
suppliers?: [{	filter?: ValueTypes["Contember_IngredientSupplierListWhere"] | undefined | null},ValueTypes["Contember_IngredientSupplierList"]],
	incrementQuantityNumber?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["Contember_ProductIngredientLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientLocale"]],
customerRatingsByCustomer?: [{	by: ValueTypes["Contember_ProductIngredientCustomerRatingsByCustomerUniqueWhere"],	filter?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null},ValueTypes["Contember_CustomerIngredientRating"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_ProductIngredientLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientLocaleConnection"]],
paginateCategories?: [{	filter?: ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientCategoryConnection"]],
paginateAllergens?: [{	filter?: ValueTypes["Contember_AllergenWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AllergenOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_AllergenConnection"]],
paginateCustomerRatings?: [{	filter?: ValueTypes["Contember_CustomerIngredientRatingWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerIngredientRatingOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerIngredientRatingConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	categories?:ValueTypes["Contember_FieldMeta"],
	allergens?:ValueTypes["Contember_FieldMeta"],
	needsPrepping?:ValueTypes["Contember_FieldMeta"],
	allowCustomerRating?:ValueTypes["Contember_FieldMeta"],
	customerRatings?:ValueTypes["Contember_FieldMeta"],
	preferredQuantityUnit?:ValueTypes["Contember_FieldMeta"],
	freshingContainer?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	suppliers?:ValueTypes["Contember_FieldMeta"],
	incrementQuantityNumber?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_ProductIngredientOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	needsPrepping?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	allowCustomerRating?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientItemQuantityUnitOrderBy"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_FreshingContainerOrderBy"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	suppliers?: ValueTypes["Contember_IngredientSupplierListOrderBy"] | undefined | null,
	incrementQuantityNumber?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityUnitOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_IngredientSupplierListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductIngredientCategory"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientCategoryMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
ingredients?: [{	filter?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredient"]],
locales?: [{	filter?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredientCategoryLocale"]],
	order?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["Contember_ProductIngredientCategoryLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientCategoryLocale"]],
paginateIngredients?: [{	filter?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientConnection"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientCategoryLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientCategoryLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	ingredients?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientCategoryLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_ProductIngredientCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientCategory"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientCategoryOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_ProductIngredientCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductIngredientCategoryLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductIngredientEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductIngredient"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductIngredientCategoryLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductIngredientCategoryLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Allergen"]: AliasType<{
	_meta?:ValueTypes["Contember_AllergenMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AllergenLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_AllergenLocale"]],
ingredients?: [{	filter?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredient"]],
	code?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["Contember_AllergenLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null},ValueTypes["Contember_AllergenLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_AllergenLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_AllergenLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_AllergenLocaleConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AllergenMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	ingredients?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AllergenLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_AllergenLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_AllergenWhere"] | undefined | null},ValueTypes["Contember_Allergen"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AllergenLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AllergenLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_AllergenOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_AllergenOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_AllergenLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_AllergenLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_AllergenLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AllergenLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_AllergenLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerIngredientRating"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerIngredientRatingMeta"],
	id?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
rating?: [{	filter?: ValueTypes["Contember_ProductIngredientRatingWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientRating"]],
ingredient?: [{	filter?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null},ValueTypes["Contember_ProductIngredient"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerIngredientRatingMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	rating?:ValueTypes["Contember_FieldMeta"],
	ingredient?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientRating"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientRatingMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientRatingMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	icon?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerIngredientRatingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	rating?: ValueTypes["Contember_ProductIngredientRatingOrderBy"] | undefined | null,
	ingredient?: ValueTypes["Contember_ProductIngredientOrderBy"] | undefined | null
};
	["Contember_ProductIngredientRatingOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	icon?: ValueTypes["Contember_ImageOrderBy"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityUnit"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientItemQuantityUnitMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientItemQuantityUnitMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FreshingContainer"]: AliasType<{
	_meta?:ValueTypes["Contember_FreshingContainerMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	volumeMl?:boolean | `@${string}`,
workspace?: [{	filter?: ValueTypes["Contember_WorkspaceWhere"] | undefined | null},ValueTypes["Contember_Workspace"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FreshingContainerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	volumeMl?:ValueTypes["Contember_FieldMeta"],
	workspace?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Workspace"]: AliasType<{
	_meta?:ValueTypes["Contember_WorkspaceMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
	orderDeadlineTime?:boolean | `@${string}`,
	ordersLoadingDurationHours?:boolean | `@${string}`,
	freshingContainerVolumeMl?:boolean | `@${string}`,
	freshingPrepareOrderByOffsetMinutes?:boolean | `@${string}`,
	checkoutUrlTemplate?:boolean | `@${string}`,
	orderUrlTemplate?:boolean | `@${string}`,
	paymentUrlTemplate?:boolean | `@${string}`,
	customerProfileUrlTemplate?:boolean | `@${string}`,
	userProfileUrlTemplate?:boolean | `@${string}`,
	messengerUrl?:boolean | `@${string}`,
	messengerIosUrl?:boolean | `@${string}`,
	messengerAndroidUrl?:boolean | `@${string}`,
freshingContainers?: [{	filter?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FreshingContainerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_FreshingContainer"]],
openingHours?: [{	filter?: ValueTypes["Contember_WeekHoursWhere"] | undefined | null},ValueTypes["Contember_WeekHours"]],
workingHours?: [{	filter?: ValueTypes["Contember_WeekHoursWhere"] | undefined | null},ValueTypes["Contember_WeekHours"]],
defaultFreshingContainer?: [{	filter?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null},ValueTypes["Contember_FreshingContainer"]],
creditsProduct?: [{	filter?: ValueTypes["Contember_VirtualProductWhere"] | undefined | null},ValueTypes["Contember_VirtualProduct"]],
discountVatRate?: [{	filter?: ValueTypes["Contember_VatRateWhere"] | undefined | null},ValueTypes["VatRate"]],
	name?:boolean | `@${string}`,
	businessAddress?:boolean | `@${string}`,
	migrationTest?:boolean | `@${string}`,
paginateFreshingContainers?: [{	filter?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_FreshingContainerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_FreshingContainerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WorkspaceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	unique?:ValueTypes["Contember_FieldMeta"],
	orderDeadlineTime?:ValueTypes["Contember_FieldMeta"],
	ordersLoadingDurationHours?:ValueTypes["Contember_FieldMeta"],
	freshingContainerVolumeMl?:ValueTypes["Contember_FieldMeta"],
	freshingPrepareOrderByOffsetMinutes?:ValueTypes["Contember_FieldMeta"],
	checkoutUrlTemplate?:ValueTypes["Contember_FieldMeta"],
	orderUrlTemplate?:ValueTypes["Contember_FieldMeta"],
	paymentUrlTemplate?:ValueTypes["Contember_FieldMeta"],
	customerProfileUrlTemplate?:ValueTypes["Contember_FieldMeta"],
	userProfileUrlTemplate?:ValueTypes["Contember_FieldMeta"],
	messengerUrl?:ValueTypes["Contember_FieldMeta"],
	messengerIosUrl?:ValueTypes["Contember_FieldMeta"],
	messengerAndroidUrl?:ValueTypes["Contember_FieldMeta"],
	freshingContainers?:ValueTypes["Contember_FieldMeta"],
	openingHours?:ValueTypes["Contember_FieldMeta"],
	workingHours?:ValueTypes["Contember_FieldMeta"],
	defaultFreshingContainer?:ValueTypes["Contember_FieldMeta"],
	creditsProduct?:ValueTypes["Contember_FieldMeta"],
	discountVatRate?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	businessAddress?:ValueTypes["Contember_FieldMeta"],
	migrationTest?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WeekHours"]: AliasType<{
	_meta?:ValueTypes["Contember_WeekHoursMeta"],
	id?:boolean | `@${string}`,
	mon?:boolean | `@${string}`,
	tue?:boolean | `@${string}`,
	wed?:boolean | `@${string}`,
	thu?:boolean | `@${string}`,
	fri?:boolean | `@${string}`,
	sat?:boolean | `@${string}`,
	sun?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_WeekHoursMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	mon?:ValueTypes["Contember_FieldMeta"],
	tue?:ValueTypes["Contember_FieldMeta"],
	wed?:ValueTypes["Contember_FieldMeta"],
	thu?:ValueTypes["Contember_FieldMeta"],
	fri?:ValueTypes["Contember_FieldMeta"],
	sat?:ValueTypes["Contember_FieldMeta"],
	sun?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VirtualProduct"]: AliasType<{
	_meta?:ValueTypes["Contember_VirtualProductMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
	voucherCreditCents?:boolean | `@${string}`,
	physicalRepresentation?:boolean | `@${string}`,
	recipientEmail?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_VirtualProductMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	voucherCreditCents?:ValueTypes["Contember_FieldMeta"],
	physicalRepresentation?:ValueTypes["Contember_FieldMeta"],
	recipientEmail?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FreshingContainerConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_FreshingContainerEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_FreshingContainerEdge"]: AliasType<{
	node?:ValueTypes["Contember_FreshingContainer"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierList"]: AliasType<{
	_meta?:ValueTypes["Contember_IngredientSupplierListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IngredientSupplierItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_IngredientSupplierItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_IngredientSupplierItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IngredientSupplierItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_IngredientSupplierItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierItem"]: AliasType<{
	_meta?:ValueTypes["Contember_IngredientSupplierItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_IngredientSupplierListWhere"] | undefined | null},ValueTypes["Contember_IngredientSupplierList"]],
supplier?: [{	filter?: ValueTypes["Contember_IngredientSupplierWhere"] | undefined | null},ValueTypes["Contember_IngredientSupplier"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	supplier?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplier"]: AliasType<{
	_meta?:ValueTypes["Contember_IngredientSupplierMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IngredientSupplierLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_IngredientSupplierLocale"]],
localesByLocale?: [{	by: ValueTypes["Contember_IngredientSupplierLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null},ValueTypes["Contember_IngredientSupplierLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_IngredientSupplierLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_IngredientSupplierLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_IngredientSupplierLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_IngredientSupplierLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_IngredientSupplierWhere"] | undefined | null},ValueTypes["Contember_IngredientSupplier"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_IngredientSupplierOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_IngredientSupplierOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_IngredientSupplierLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_IngredientSupplierLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_IngredientSupplierLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_IngredientSupplierLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_IngredientSupplierListOrderBy"] | undefined | null,
	supplier?: ValueTypes["Contember_IngredientSupplierOrderBy"] | undefined | null
};
	["Contember_IngredientSupplierItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_IngredientSupplierItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_IngredientSupplierItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_IngredientSupplierItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientCustomerRatingsByCustomerUniqueWhere"]: {
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductIngredientLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductIngredientLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductIngredientCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientCategoryEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductIngredientCategory"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AllergenConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_AllergenEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AllergenEdge"]: AliasType<{
	node?:ValueTypes["Contember_Allergen"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerIngredientRatingConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CustomerIngredientRatingEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerIngredientRatingEdge"]: AliasType<{
	node?:ValueTypes["Contember_CustomerIngredientRating"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_BlogLocaleMeta"],
	id?:boolean | `@${string}`,
	pageName?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_BlogWhere"] | undefined | null},ValueTypes["Contember_Blog"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	pageName?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	lead?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Blog"]: AliasType<{
	_meta?:ValueTypes["Contember_BlogMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BlogLocale"]],
localesByLocale?: [{	by: ValueTypes["Contember_BlogLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_BlogLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BlogLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	unique?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	pageName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	lead?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_BlogOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_BlogOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unique?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_BlogLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_BlogLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BlogLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_BlogLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_BlogPostLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	availableForWeb?:boolean | `@${string}`,
	availableForMobile?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_BlogPostWhere"] | undefined | null},ValueTypes["Contember_BlogPost"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
cover?: [{	filter?: ValueTypes["Contember_CoverWhere"] | undefined | null},ValueTypes["Contember_Cover"]],
content?: [{	filter?: ValueTypes["Contember_ContentWhere"] | undefined | null},ValueTypes["Contember_Content"]],
link?: [{	filter?: ValueTypes["Contember_LinkableWhere"] | undefined | null},ValueTypes["Contember_Linkable"]],
seo?: [{	filter?: ValueTypes["Contember_SeoWhere"] | undefined | null},ValueTypes["Contember_Seo"]],
products?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
paginateProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	availableForWeb?:ValueTypes["Contember_FieldMeta"],
	availableForMobile?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	cover?:ValueTypes["Contember_FieldMeta"],
	content?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	seo?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPost"]: AliasType<{
	_meta?:ValueTypes["Contember_BlogPostMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogPostLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
localesByLocale?: [{	by: ValueTypes["Contember_BlogPostLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
localesByCover?: [{	by: ValueTypes["Contember_BlogPostLocalesByCoverUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
localesByContent?: [{	by: ValueTypes["Contember_BlogPostLocalesByContentUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
localesByLink?: [{	by: ValueTypes["Contember_BlogPostLocalesByLinkUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
localesBySeo?: [{	by: ValueTypes["Contember_BlogPostLocalesBySeoUniqueWhere"],	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogPostLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BlogPostLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	availableForWeb?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	availableForMobile?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_BlogPostOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	cover?: ValueTypes["Contember_CoverOrderBy"] | undefined | null,
	content?: ValueTypes["Contember_ContentOrderBy"] | undefined | null,
	link?: ValueTypes["Contember_LinkableOrderBy"] | undefined | null,
	seo?: ValueTypes["Contember_SeoOrderBy"] | undefined | null
};
	["Contember_BlogPostOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CoverOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	primaryTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	secondaryTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	medium?: ValueTypes["Contember_MediumOrderBy"] | undefined | null,
	buttonColors?: ValueTypes["Contember_ColorPalleteOrderBy"] | undefined | null
};
	["Contember_MediumOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	colorTheme?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	video?: ValueTypes["Contember_VideoOrderBy"] | undefined | null
};
	["Contember_VideoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	src?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	width?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	height?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	size?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	duration?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	poster?: ValueTypes["Contember_ImageOrderBy"] | undefined | null
};
	["Contember_ColorPalleteOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	background?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	text?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ContentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_LinkableOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	url?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	blogPost?: ValueTypes["Contember_BlogPostLocaleOrderBy"] | undefined | null,
	page?: ValueTypes["Contember_PageLocaleOrderBy"] | undefined | null,
	wikiPage?: ValueTypes["Contember_WikiPageOrderBy"] | undefined | null,
	redirect?: ValueTypes["Contember_RedirectOrderBy"] | undefined | null
};
	["Contember_PageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	link?: ValueTypes["Contember_LinkableOrderBy"] | undefined | null,
	root?: ValueTypes["Contember_PageOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	seo?: ValueTypes["Contember_SeoOrderBy"] | undefined | null,
	content?: ValueTypes["Contember_ContentOrderBy"] | undefined | null,
	cover?: ValueTypes["Contember_CoverOrderBy"] | undefined | null,
	theme?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_SeoOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ogTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ogDescription?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ogImage?: ValueTypes["Contember_ImageOrderBy"] | undefined | null
};
	["Contember_WikiPageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	emoji?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	link?: ValueTypes["Contember_LinkableOrderBy"] | undefined | null,
	parent?: ValueTypes["Contember_WikiPageOrderBy"] | undefined | null,
	content?: ValueTypes["Contember_ContentOrderBy"] | undefined | null
};
	["Contember_RedirectOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	link?: ValueTypes["Contember_LinkableOrderBy"] | undefined | null,
	target?: ValueTypes["Contember_LinkOrderBy"] | undefined | null
};
	["Contember_LinkOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	externalLink?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalLink?: ValueTypes["Contember_LinkableOrderBy"] | undefined | null
};
	["Contember_BlogPostLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_BlogPostLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null
};
	["Contember_BlogPostLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null
};
	["Contember_BlogPostLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null
};
	["Contember_BlogPostLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null
};
	["Contember_BlogPostLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BlogPostLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_BlogPostLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Cover"]: AliasType<{
	_meta?:ValueTypes["Contember_CoverMeta"],
	id?:boolean | `@${string}`,
	primaryTitle?:boolean | `@${string}`,
	secondaryTitle?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["Contember_MediumWhere"] | undefined | null},ValueTypes["Contember_Medium"]],
buttonColors?: [{	filter?: ValueTypes["Contember_ColorPalleteWhere"] | undefined | null},ValueTypes["Contember_ColorPallete"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CoverMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	primaryTitle?:ValueTypes["Contember_FieldMeta"],
	secondaryTitle?:ValueTypes["Contember_FieldMeta"],
	medium?:ValueTypes["Contember_FieldMeta"],
	buttonColors?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Medium"]: AliasType<{
	_meta?:ValueTypes["Contember_MediumMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	colorTheme?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
video?: [{	filter?: ValueTypes["Contember_VideoWhere"] | undefined | null},ValueTypes["Contember_Video"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MediumMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	colorTheme?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	video?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Video"]: AliasType<{
	_meta?:ValueTypes["Contember_VideoMeta"],
	id?:boolean | `@${string}`,
	src?:boolean | `@${string}`,
	width?:boolean | `@${string}`,
	height?:boolean | `@${string}`,
	size?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
poster?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VideoMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	src?:ValueTypes["Contember_FieldMeta"],
	width?:ValueTypes["Contember_FieldMeta"],
	height?:ValueTypes["Contember_FieldMeta"],
	size?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	duration?:ValueTypes["Contember_FieldMeta"],
	poster?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ColorPallete"]: AliasType<{
	_meta?:ValueTypes["Contember_ColorPalleteMeta"],
	id?:boolean | `@${string}`,
	background?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ColorPalleteMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	background?:ValueTypes["Contember_FieldMeta"],
	text?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Content"]: AliasType<{
	_meta?:ValueTypes["Contember_ContentMeta"],
	id?:boolean | `@${string}`,
blocks?: [{	filter?: ValueTypes["Contember_ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ContentBlockOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ContentBlock"]],
blocksByReferences?: [{	by: ValueTypes["Contember_ContentBlocksByReferencesUniqueWhere"],	filter?: ValueTypes["Contember_ContentBlockWhere"] | undefined | null},ValueTypes["Contember_ContentBlock"]],
paginateBlocks?: [{	filter?: ValueTypes["Contember_ContentBlockWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ContentBlockOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ContentBlockConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	blocks?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentBlock"]: AliasType<{
	_meta?:ValueTypes["Contember_ContentBlockMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	json?:boolean | `@${string}`,
content?: [{	filter?: ValueTypes["Contember_ContentWhere"] | undefined | null},ValueTypes["Contember_Content"]],
references?: [{	filter?: ValueTypes["Contember_ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ContentReferenceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ContentReference"]],
referencesByProducts?: [{	by: ValueTypes["Contember_ContentBlockReferencesByProductsUniqueWhere"],	filter?: ValueTypes["Contember_ContentReferenceWhere"] | undefined | null},ValueTypes["Contember_ContentReference"]],
referencesByProductBanners?: [{	by: ValueTypes["Contember_ContentBlockReferencesByProductBannersUniqueWhere"],	filter?: ValueTypes["Contember_ContentReferenceWhere"] | undefined | null},ValueTypes["Contember_ContentReference"]],
referencesByDeliveryRegions?: [{	by: ValueTypes["Contember_ContentBlockReferencesByDeliveryRegionsUniqueWhere"],	filter?: ValueTypes["Contember_ContentReferenceWhere"] | undefined | null},ValueTypes["Contember_ContentReference"]],
paginateReferences?: [{	filter?: ValueTypes["Contember_ContentReferenceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ContentReferenceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ContentReferenceConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	json?:ValueTypes["Contember_FieldMeta"],
	content?:ValueTypes["Contember_FieldMeta"],
	references?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentReference"]: AliasType<{
	_meta?:ValueTypes["Contember_ContentReferenceMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	primaryText?:boolean | `@${string}`,
	secondaryText?:boolean | `@${string}`,
	jsonContent?:boolean | `@${string}`,
block?: [{	filter?: ValueTypes["Contember_ContentBlockWhere"] | undefined | null},ValueTypes["Contember_ContentBlock"]],
colorPallete?: [{	filter?: ValueTypes["Contember_ColorPalleteWhere"] | undefined | null},ValueTypes["Contember_ColorPallete"]],
medium?: [{	filter?: ValueTypes["Contember_MediumWhere"] | undefined | null},ValueTypes["Contember_Medium"]],
link?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
blogPosts?: [{	filter?: ValueTypes["Contember_BlogPostListWhere"] | undefined | null},ValueTypes["Contember_BlogPostList"]],
links?: [{	filter?: ValueTypes["Contember_LinkListWhere"] | undefined | null},ValueTypes["Contember_LinkList"]],
products?: [{	filter?: ValueTypes["Contember_ProductListWhere"] | undefined | null},ValueTypes["Contember_ProductList"]],
	contentSize?:boolean | `@${string}`,
	misc?:boolean | `@${string}`,
hero?: [{	filter?: ValueTypes["Contember_HeroWhere"] | undefined | null},ValueTypes["Contember_Hero"]],
gallery?: [{	filter?: ValueTypes["Contember_GalleryWhere"] | undefined | null},ValueTypes["Contember_Gallery"]],
socialsAndApps?: [{	filter?: ValueTypes["Contember_SocialsAndAppsWhere"] | undefined | null},ValueTypes["Contember_SocialsAndApps"]],
productGrid?: [{	filter?: ValueTypes["Contember_ProductGridWhere"] | undefined | null},ValueTypes["Contember_ProductGrid"]],
productBanners?: [{	filter?: ValueTypes["Contember_ProductBannerListWhere"] | undefined | null},ValueTypes["Contember_ProductBannerList"]],
deliveryRegions?: [{	filter?: ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null},ValueTypes["Contember_DeliveryRegions"]],
textList?: [{	filter?: ValueTypes["Contember_TextListWhere"] | undefined | null},ValueTypes["Contember_TextList"]],
gridTiles?: [{	filter?: ValueTypes["Contember_GridTilesWhere"] | undefined | null},ValueTypes["Contember_GridTiles"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentReferenceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	primaryText?:ValueTypes["Contember_FieldMeta"],
	secondaryText?:ValueTypes["Contember_FieldMeta"],
	jsonContent?:ValueTypes["Contember_FieldMeta"],
	block?:ValueTypes["Contember_FieldMeta"],
	colorPallete?:ValueTypes["Contember_FieldMeta"],
	medium?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	blogPosts?:ValueTypes["Contember_FieldMeta"],
	links?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	contentSize?:ValueTypes["Contember_FieldMeta"],
	misc?:ValueTypes["Contember_FieldMeta"],
	hero?:ValueTypes["Contember_FieldMeta"],
	gallery?:ValueTypes["Contember_FieldMeta"],
	socialsAndApps?:ValueTypes["Contember_FieldMeta"],
	productGrid?:ValueTypes["Contember_FieldMeta"],
	productBanners?:ValueTypes["Contember_FieldMeta"],
	deliveryRegions?:ValueTypes["Contember_FieldMeta"],
	textList?:ValueTypes["Contember_FieldMeta"],
	gridTiles?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Link"]: AliasType<{
	_meta?:ValueTypes["Contember_LinkMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	externalLink?:boolean | `@${string}`,
internalLink?: [{	filter?: ValueTypes["Contember_LinkableWhere"] | undefined | null},ValueTypes["Contember_Linkable"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	externalLink?:ValueTypes["Contember_FieldMeta"],
	internalLink?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Linkable"]: AliasType<{
	_meta?:ValueTypes["Contember_LinkableMeta"],
	id?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
blogPost?: [{	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
page?: [{	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
wikiPage?: [{	filter?: ValueTypes["Contember_WikiPageWhere"] | undefined | null},ValueTypes["Contember_WikiPage"]],
redirect?: [{	filter?: ValueTypes["Contember_RedirectWhere"] | undefined | null},ValueTypes["Contember_Redirect"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkableMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	url?:ValueTypes["Contember_FieldMeta"],
	blogPost?:ValueTypes["Contember_FieldMeta"],
	page?:ValueTypes["Contember_FieldMeta"],
	wikiPage?:ValueTypes["Contember_FieldMeta"],
	redirect?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PageLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_PageLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["Contember_LinkableWhere"] | undefined | null},ValueTypes["Contember_Linkable"]],
root?: [{	filter?: ValueTypes["Contember_PageWhere"] | undefined | null},ValueTypes["Contember_Page"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
seo?: [{	filter?: ValueTypes["Contember_SeoWhere"] | undefined | null},ValueTypes["Contember_Seo"]],
content?: [{	filter?: ValueTypes["Contember_ContentWhere"] | undefined | null},ValueTypes["Contember_Content"]],
cover?: [{	filter?: ValueTypes["Contember_CoverWhere"] | undefined | null},ValueTypes["Contember_Cover"]],
	theme?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_PageLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	seo?:ValueTypes["Contember_FieldMeta"],
	content?:ValueTypes["Contember_FieldMeta"],
	cover?:ValueTypes["Contember_FieldMeta"],
	theme?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Page"]: AliasType<{
	_meta?:ValueTypes["Contember_PageMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PageLocale"]],
localesByLocale?: [{	by: ValueTypes["Contember_PageLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
localesByLink?: [{	by: ValueTypes["Contember_PageLocalesByLinkUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
localesBySeo?: [{	by: ValueTypes["Contember_PageLocalesBySeoUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
localesByContent?: [{	by: ValueTypes["Contember_PageLocalesByContentUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
localesByCover?: [{	by: ValueTypes["Contember_PageLocalesByCoverUniqueWhere"],	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null},ValueTypes["Contember_PageLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_PageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PageLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PageMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_PageLocalesByLinkUniqueWhere"]: {
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null
};
	["Contember_PageLocalesBySeoUniqueWhere"]: {
	seo?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null
};
	["Contember_PageLocalesByContentUniqueWhere"]: {
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null
};
	["Contember_PageLocalesByCoverUniqueWhere"]: {
	cover?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null
};
	["Contember_PageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PageLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_PageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Seo"]: AliasType<{
	_meta?:ValueTypes["Contember_SeoMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	ogTitle?:boolean | `@${string}`,
	ogDescription?:boolean | `@${string}`,
ogImage?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SeoMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	ogTitle?:ValueTypes["Contember_FieldMeta"],
	ogDescription?:ValueTypes["Contember_FieldMeta"],
	ogImage?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WikiPage"]: AliasType<{
	_meta?:ValueTypes["Contember_WikiPageMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	emoji?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["Contember_LinkableWhere"] | undefined | null},ValueTypes["Contember_Linkable"]],
parent?: [{	filter?: ValueTypes["Contember_WikiPageWhere"] | undefined | null},ValueTypes["Contember_WikiPage"]],
children?: [{	filter?: ValueTypes["Contember_WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_WikiPageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_WikiPage"]],
content?: [{	filter?: ValueTypes["Contember_ContentWhere"] | undefined | null},ValueTypes["Contember_Content"]],
childrenByLink?: [{	by: ValueTypes["Contember_WikiPageChildrenByLinkUniqueWhere"],	filter?: ValueTypes["Contember_WikiPageWhere"] | undefined | null},ValueTypes["Contember_WikiPage"]],
childrenByChildren?: [{	by: ValueTypes["Contember_WikiPageChildrenByChildrenUniqueWhere"],	filter?: ValueTypes["Contember_WikiPageWhere"] | undefined | null},ValueTypes["Contember_WikiPage"]],
childrenByContent?: [{	by: ValueTypes["Contember_WikiPageChildrenByContentUniqueWhere"],	filter?: ValueTypes["Contember_WikiPageWhere"] | undefined | null},ValueTypes["Contember_WikiPage"]],
paginateChildren?: [{	filter?: ValueTypes["Contember_WikiPageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_WikiPageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_WikiPageConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WikiPageMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	emoji?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	parent?:ValueTypes["Contember_FieldMeta"],
	children?:ValueTypes["Contember_FieldMeta"],
	content?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WikiPageChildrenByLinkUniqueWhere"]: {
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null
};
	["Contember_WikiPageChildrenByChildrenUniqueWhere"]: {
	children?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null
};
	["Contember_WikiPageChildrenByContentUniqueWhere"]: {
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null
};
	["Contember_WikiPageConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_WikiPageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_WikiPageEdge"]: AliasType<{
	node?:ValueTypes["Contember_WikiPage"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Redirect"]: AliasType<{
	_meta?:ValueTypes["Contember_RedirectMeta"],
	id?:boolean | `@${string}`,
link?: [{	filter?: ValueTypes["Contember_LinkableWhere"] | undefined | null},ValueTypes["Contember_Linkable"]],
target?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_RedirectMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	target?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostList"]: AliasType<{
	_meta?:ValueTypes["Contember_BlogPostListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogPostListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_BlogPostListItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_BlogPostListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_BlogPostListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_BlogPostListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostListItem"]: AliasType<{
	_meta?:ValueTypes["Contember_BlogPostListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_BlogPostListWhere"] | undefined | null},ValueTypes["Contember_BlogPostList"]],
blogPost?: [{	filter?: ValueTypes["Contember_BlogPostLocaleWhere"] | undefined | null},ValueTypes["Contember_BlogPostLocale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostListItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	caption?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	blogPost?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	caption?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_BlogPostListOrderBy"] | undefined | null,
	blogPost?: ValueTypes["Contember_BlogPostLocaleOrderBy"] | undefined | null
};
	["Contember_BlogPostListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_BlogPostListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BlogPostListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BlogPostListItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_BlogPostListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkList"]: AliasType<{
	_meta?:ValueTypes["Contember_LinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_LinkListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_LinkListItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_LinkListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_LinkListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_LinkListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkListItem"]: AliasType<{
	_meta?:ValueTypes["Contember_LinkListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	isPrimary?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_LinkListWhere"] | undefined | null},ValueTypes["Contember_LinkList"]],
link?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkListItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	isPrimary?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isPrimary?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_LinkListOrderBy"] | undefined | null,
	link?: ValueTypes["Contember_LinkOrderBy"] | undefined | null
};
	["Contember_LinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_LinkListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_LinkListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LinkListItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_LinkListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Hero"]: AliasType<{
	_meta?:ValueTypes["Contember_HeroMeta"],
	id?:boolean | `@${string}`,
	showLogo?:boolean | `@${string}`,
	middleContent?:boolean | `@${string}`,
medium?: [{	filter?: ValueTypes["Contember_MediumWhere"] | undefined | null},ValueTypes["Contember_Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_HeroMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	showLogo?:ValueTypes["Contember_FieldMeta"],
	middleContent?:ValueTypes["Contember_FieldMeta"],
	medium?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Gallery"]: AliasType<{
	_meta?:ValueTypes["Contember_GalleryMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_GalleryItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_GalleryItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_GalleryItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_GalleryItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_GalleryItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GalleryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GalleryItem"]: AliasType<{
	_meta?:ValueTypes["Contember_GalleryItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	caption?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_GalleryWhere"] | undefined | null},ValueTypes["Contember_Gallery"]],
medium?: [{	filter?: ValueTypes["Contember_MediumWhere"] | undefined | null},ValueTypes["Contember_Medium"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GalleryItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	caption?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	medium?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GalleryItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	caption?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_GalleryOrderBy"] | undefined | null,
	medium?: ValueTypes["Contember_MediumOrderBy"] | undefined | null
};
	["Contember_GalleryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_GalleryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_GalleryItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GalleryItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_GalleryItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SocialsAndApps"]: AliasType<{
	_meta?:ValueTypes["Contember_SocialsAndAppsMeta"],
	id?:boolean | `@${string}`,
	instagram?:boolean | `@${string}`,
	appStore?:boolean | `@${string}`,
	googlePlay?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_SocialsAndAppsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	instagram?:ValueTypes["Contember_FieldMeta"],
	appStore?:ValueTypes["Contember_FieldMeta"],
	googlePlay?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGrid"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductGridMeta"],
	id?:boolean | `@${string}`,
	isCarousel?:boolean | `@${string}`,
categories?: [{	filter?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGridCategoryOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductGridCategory"]],
	type?:boolean | `@${string}`,
categoriesByItems?: [{	by: ValueTypes["Contember_ProductGridCategoriesByItemsUniqueWhere"],	filter?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductGridCategory"]],
categoriesByImages?: [{	by: ValueTypes["Contember_ProductGridCategoriesByImagesUniqueWhere"],	filter?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductGridCategory"]],
paginateCategories?: [{	filter?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGridCategoryOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductGridCategoryConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	isCarousel?:ValueTypes["Contember_FieldMeta"],
	categories?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridCategory"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductGridCategoryMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_ProductGridWhere"] | undefined | null},ValueTypes["Contember_ProductGrid"]],
items?: [{	filter?: ValueTypes["Contember_ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGridItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductGridItem"]],
images?: [{	filter?: ValueTypes["Contember_ImageListWhere"] | undefined | null},ValueTypes["Contember_ImageList"]],
paginateItems?: [{	filter?: ValueTypes["Contember_ProductGridItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGridItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductGridItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridCategoryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	images?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridItem"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductGridItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	lead?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
medium?: [{	filter?: ValueTypes["Contember_MediumWhere"] | undefined | null},ValueTypes["Contember_Medium"]],
link?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
category?: [{	filter?: ValueTypes["Contember_ProductGridCategoryWhere"] | undefined | null},ValueTypes["Contember_ProductGridCategory"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	lead?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	medium?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	category?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	lead?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	medium?: ValueTypes["Contember_MediumOrderBy"] | undefined | null,
	link?: ValueTypes["Contember_LinkOrderBy"] | undefined | null,
	category?: ValueTypes["Contember_ProductGridCategoryOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductGridCategoryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_ProductGridOrderBy"] | undefined | null,
	images?: ValueTypes["Contember_ImageListOrderBy"] | undefined | null
};
	["Contember_ProductGridOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	isCarousel?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ImageListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ImageList"]: AliasType<{
	_meta?:ValueTypes["Contember_ImageListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ImageListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ImageListItem"]],
itemsByImage?: [{	by: ValueTypes["Contember_ImageListItemsByImageUniqueWhere"],	filter?: ValueTypes["Contember_ImageListItemWhere"] | undefined | null},ValueTypes["Contember_ImageListItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_ImageListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ImageListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ImageListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ImageListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ImageListItem"]: AliasType<{
	_meta?:ValueTypes["Contember_ImageListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_ImageListWhere"] | undefined | null},ValueTypes["Contember_ImageList"]],
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ImageListItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ImageListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_ImageListOrderBy"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null
};
	["Contember_ImageListItemsByImageUniqueWhere"]: {
	image?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null
};
	["Contember_ImageListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ImageListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ImageListItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_ImageListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductGridItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductGridItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridCategoriesByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null
};
	["Contember_ProductGridItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductGridCategoriesByImagesUniqueWhere"]: {
	images?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null
};
	["Contember_ProductGridCategoryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductGridCategoryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGridCategoryEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductGridCategory"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBannerList"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductBannerListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductBannerItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductBannerItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_ProductBannerItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductBannerItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductBannerItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBannerListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBannerItem"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductBannerItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_ProductBannerListWhere"] | undefined | null},ValueTypes["Contember_ProductBannerList"]],
banner?: [{	filter?: ValueTypes["Contember_ProductBannerWhere"] | undefined | null},ValueTypes["Contember_ProductBanner"]],
	size?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBannerItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	banner?:ValueTypes["Contember_FieldMeta"],
	size?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBanner"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductBannerMeta"],
	id?:boolean | `@${string}`,
	beforeTitle?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
button?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
backgroundImage?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
productImage?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBannerMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	beforeTitle?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	label?:ValueTypes["Contember_FieldMeta"],
	button?:ValueTypes["Contember_FieldMeta"],
	backgroundImage?:ValueTypes["Contember_FieldMeta"],
	productImage?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBannerItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_ProductBannerListOrderBy"] | undefined | null,
	banner?: ValueTypes["Contember_ProductBannerOrderBy"] | undefined | null,
	size?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductBannerListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductBannerOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	beforeTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	label?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	button?: ValueTypes["Contember_LinkOrderBy"] | undefined | null,
	backgroundImage?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	productImage?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_ProductBannerItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductBannerItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductBannerItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductBannerItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRegions"]: AliasType<{
	_meta?:ValueTypes["Contember_DeliveryRegionsMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryRegionsItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_DeliveryRegionsItem"]],
itemsByImage?: [{	by: ValueTypes["Contember_DeliveryRegionsItemsByImageUniqueWhere"],	filter?: ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["Contember_DeliveryRegionsItem"]],
itemsByTextList?: [{	by: ValueTypes["Contember_DeliveryRegionsItemsByTextListUniqueWhere"],	filter?: ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["Contember_DeliveryRegionsItem"]],
itemsByGallery?: [{	by: ValueTypes["Contember_DeliveryRegionsItemsByGalleryUniqueWhere"],	filter?: ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null},ValueTypes["Contember_DeliveryRegionsItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_DeliveryRegionsItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_DeliveryRegionsItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_DeliveryRegionsItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRegionsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRegionsItem"]: AliasType<{
	_meta?:ValueTypes["Contember_DeliveryRegionsItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	offerLabel?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null},ValueTypes["Contember_DeliveryRegions"]],
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
textList?: [{	filter?: ValueTypes["Contember_TextListWhere"] | undefined | null},ValueTypes["Contember_TextList"]],
gallery?: [{	filter?: ValueTypes["Contember_ImageListWhere"] | undefined | null},ValueTypes["Contember_ImageList"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRegionsItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	offerLabel?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	textList?:ValueTypes["Contember_FieldMeta"],
	gallery?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TextList"]: AliasType<{
	_meta?:ValueTypes["Contember_TextListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TextItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TextItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_TextItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TextItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TextItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TextListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TextItem"]: AliasType<{
	_meta?:ValueTypes["Contember_TextItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_TextListWhere"] | undefined | null},ValueTypes["Contember_TextList"]],
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_TextItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	text?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TextItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	text?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_TextListOrderBy"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TextListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TextItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TextItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TextItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_TextItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRegionsItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	offerLabel?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_DeliveryRegionsOrderBy"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	textList?: ValueTypes["Contember_TextListOrderBy"] | undefined | null,
	gallery?: ValueTypes["Contember_ImageListOrderBy"] | undefined | null
};
	["Contember_DeliveryRegionsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_DeliveryRegionsItemsByImageUniqueWhere"]: {
	image?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null
};
	["Contember_DeliveryRegionsItemsByTextListUniqueWhere"]: {
	textList?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_DeliveryRegionsItemsByGalleryUniqueWhere"]: {
	gallery?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null
};
	["Contember_DeliveryRegionsItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_DeliveryRegionsItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryRegionsItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_DeliveryRegionsItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GridTiles"]: AliasType<{
	_meta?:ValueTypes["Contember_GridTilesMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_GridTileItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_GridTileItem"]],
itemsByLink?: [{	by: ValueTypes["Contember_GridTilesItemsByLinkUniqueWhere"],	filter?: ValueTypes["Contember_GridTileItemWhere"] | undefined | null},ValueTypes["Contember_GridTileItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_GridTileItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_GridTileItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_GridTileItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GridTilesMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GridTileItem"]: AliasType<{
	_meta?:ValueTypes["Contember_GridTileItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_GridTilesWhere"] | undefined | null},ValueTypes["Contember_GridTiles"]],
medium?: [{	filter?: ValueTypes["Contember_MediumWhere"] | undefined | null},ValueTypes["Contember_Medium"]],
link?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GridTileItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	text?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	medium?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GridTileItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	text?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_GridTilesOrderBy"] | undefined | null,
	medium?: ValueTypes["Contember_MediumOrderBy"] | undefined | null,
	link?: ValueTypes["Contember_LinkOrderBy"] | undefined | null
};
	["Contember_GridTilesOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_GridTilesItemsByLinkUniqueWhere"]: {
	link?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null
};
	["Contember_LinkUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_GridTileItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_GridTileItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GridTileItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_GridTileItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentReferenceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	primaryText?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	secondaryText?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	jsonContent?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	block?: ValueTypes["Contember_ContentBlockOrderBy"] | undefined | null,
	colorPallete?: ValueTypes["Contember_ColorPalleteOrderBy"] | undefined | null,
	medium?: ValueTypes["Contember_MediumOrderBy"] | undefined | null,
	link?: ValueTypes["Contember_LinkOrderBy"] | undefined | null,
	blogPosts?: ValueTypes["Contember_BlogPostListOrderBy"] | undefined | null,
	links?: ValueTypes["Contember_LinkListOrderBy"] | undefined | null,
	products?: ValueTypes["Contember_ProductListOrderBy"] | undefined | null,
	contentSize?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	misc?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	hero?: ValueTypes["Contember_HeroOrderBy"] | undefined | null,
	gallery?: ValueTypes["Contember_GalleryOrderBy"] | undefined | null,
	socialsAndApps?: ValueTypes["Contember_SocialsAndAppsOrderBy"] | undefined | null,
	productGrid?: ValueTypes["Contember_ProductGridOrderBy"] | undefined | null,
	productBanners?: ValueTypes["Contember_ProductBannerListOrderBy"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_DeliveryRegionsOrderBy"] | undefined | null,
	textList?: ValueTypes["Contember_TextListOrderBy"] | undefined | null,
	gridTiles?: ValueTypes["Contember_GridTilesOrderBy"] | undefined | null
};
	["Contember_ContentBlockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	json?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	content?: ValueTypes["Contember_ContentOrderBy"] | undefined | null
};
	["Contember_HeroOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	showLogo?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	middleContent?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	medium?: ValueTypes["Contember_MediumOrderBy"] | undefined | null
};
	["Contember_SocialsAndAppsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	instagram?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	appStore?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	googlePlay?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ContentBlockReferencesByProductsUniqueWhere"]: {
	products?: ValueTypes["Contember_ProductListUniqueWhere"] | undefined | null
};
	["Contember_ContentBlockReferencesByProductBannersUniqueWhere"]: {
	productBanners?: ValueTypes["Contember_ProductBannerListUniqueWhere"] | undefined | null
};
	["Contember_ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
	deliveryRegions?: ValueTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined | null
};
	["Contember_ContentReferenceConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ContentReferenceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentReferenceEdge"]: AliasType<{
	node?:ValueTypes["Contember_ContentReference"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentBlocksByReferencesUniqueWhere"]: {
	references?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null
};
	["Contember_ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ContentBlockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["Contember_ContentBlock"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationCataloguesIdentifier"]: AliasType<{
	_meta?:ValueTypes["Contember_TranslationCataloguesIdentifierMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
catalogueByDomain?: [{	by: ValueTypes["Contember_TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"],	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
catalogueByValues?: [{	by: ValueTypes["Contember_TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"],	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
paginateCatalogue?: [{	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TranslationCatalogueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationCataloguesIdentifierMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	catalogue?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationCatalogue"]: AliasType<{
	_meta?:ValueTypes["Contember_TranslationCatalogueMeta"],
	id?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["Contember_TranslationDomainWhere"] | undefined | null},ValueTypes["Contember_TranslationDomain"]],
fallback?: [{	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
values?: [{	filter?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TranslationValue"]],
identifier?: [{	filter?: ValueTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined | null},ValueTypes["Contember_TranslationCataloguesIdentifier"]],
valuesByKey?: [{	by: ValueTypes["Contember_TranslationCatalogueValuesByKeyUniqueWhere"],	filter?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null},ValueTypes["Contember_TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationCatalogueMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	domain?:ValueTypes["Contember_FieldMeta"],
	fallback?:ValueTypes["Contember_FieldMeta"],
	values?:ValueTypes["Contember_FieldMeta"],
	identifier?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationDomain"]: AliasType<{
	_meta?:ValueTypes["Contember_TranslationDomainMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
catalogues?: [{	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationCatalogueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
keys?: [{	filter?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationKeyOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TranslationKey"]],
cataloguesByIdentifier?: [{	by: ValueTypes["Contember_TranslationDomainCataloguesByIdentifierUniqueWhere"],	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
cataloguesByValues?: [{	by: ValueTypes["Contember_TranslationDomainCataloguesByValuesUniqueWhere"],	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
keysByIdentifier?: [{	by: ValueTypes["Contember_TranslationDomainKeysByIdentifierUniqueWhere"],	filter?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null},ValueTypes["Contember_TranslationKey"]],
keysByValues?: [{	by: ValueTypes["Contember_TranslationDomainKeysByValuesUniqueWhere"],	filter?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null},ValueTypes["Contember_TranslationKey"]],
paginateCatalogues?: [{	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationCatalogueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TranslationCatalogueConnection"]],
paginateKeys?: [{	filter?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationKeyOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TranslationKeyConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationDomainMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	identifier?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	catalogues?:ValueTypes["Contember_FieldMeta"],
	keys?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationCatalogueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationDomainOrderBy"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueOrderBy"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCataloguesIdentifierOrderBy"] | undefined | null
};
	["Contember_TranslationDomainOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	identifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_TranslationKey"]: AliasType<{
	_meta?:ValueTypes["Contember_TranslationKeyMeta"],
	id?:boolean | `@${string}`,
	identifier?:boolean | `@${string}`,
	contentType?:boolean | `@${string}`,
	note?:boolean | `@${string}`,
domain?: [{	filter?: ValueTypes["Contember_TranslationDomainWhere"] | undefined | null},ValueTypes["Contember_TranslationDomain"]],
values?: [{	filter?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationValueOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_TranslationValue"]],
valuesByCatalogue?: [{	by: ValueTypes["Contember_TranslationKeyValuesByCatalogueUniqueWhere"],	filter?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null},ValueTypes["Contember_TranslationValue"]],
paginateValues?: [{	filter?: ValueTypes["Contember_TranslationValueWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_TranslationValueOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_TranslationValueConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationKeyMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	identifier?:ValueTypes["Contember_FieldMeta"],
	contentType?:ValueTypes["Contember_FieldMeta"],
	note?:ValueTypes["Contember_FieldMeta"],
	domain?:ValueTypes["Contember_FieldMeta"],
	values?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationValue"]: AliasType<{
	_meta?:ValueTypes["Contember_TranslationValueMeta"],
	id?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
catalogue?: [{	filter?: ValueTypes["Contember_TranslationCatalogueWhere"] | undefined | null},ValueTypes["Contember_TranslationCatalogue"]],
key?: [{	filter?: ValueTypes["Contember_TranslationKeyWhere"] | undefined | null},ValueTypes["Contember_TranslationKey"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationValueMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	value?:ValueTypes["Contember_FieldMeta"],
	catalogue?:ValueTypes["Contember_FieldMeta"],
	key?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationValueOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	value?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	catalogue?: ValueTypes["Contember_TranslationCatalogueOrderBy"] | undefined | null,
	key?: ValueTypes["Contember_TranslationKeyOrderBy"] | undefined | null
};
	["Contember_TranslationKeyOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	identifier?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	contentType?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	note?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationDomainOrderBy"] | undefined | null
};
	["Contember_TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null
};
	["Contember_TranslationValueConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TranslationValueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationValueEdge"]: AliasType<{
	node?:ValueTypes["Contember_TranslationValue"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?: ValueTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined | null
};
	["Contember_TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null
};
	["Contember_TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?: string | undefined | null
};
	["Contember_TranslationDomainKeysByValuesUniqueWhere"]: {
	values?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null
};
	["Contember_TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TranslationCatalogueEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationCatalogueEdge"]: AliasType<{
	node?:ValueTypes["Contember_TranslationCatalogue"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationKeyConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_TranslationKeyEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationKeyEdge"]: AliasType<{
	node?:ValueTypes["Contember_TranslationKey"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
	domain?: ValueTypes["Contember_TranslationDomainUniqueWhere"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
	values?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null
};
	["Contember_MenuLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuLocaleMeta"],
	id?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_SiteMenuWhere"] | undefined | null},ValueTypes["Contember_SiteMenu"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
items?: [{	filter?: ValueTypes["Contember_MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_MenuItem"]],
secondaryItems?: [{	filter?: ValueTypes["Contember_LinkListWhere"] | undefined | null},ValueTypes["Contember_LinkList"]],
eshopLink?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
itemsBySubitems?: [{	by: ValueTypes["Contember_MenuLocaleItemsBySubitemsUniqueWhere"],	filter?: ValueTypes["Contember_MenuItemWhere"] | undefined | null},ValueTypes["Contember_MenuItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_MenuItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_MenuItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	secondaryItems?:ValueTypes["Contember_FieldMeta"],
	eshopLink?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SiteMenu"]: AliasType<{
	_meta?:ValueTypes["Contember_SiteMenuMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_MenuLocale"]],
localesByLocale?: [{	by: ValueTypes["Contember_SiteMenuLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null},ValueTypes["Contember_MenuLocale"]],
localesByItems?: [{	by: ValueTypes["Contember_SiteMenuLocalesByItemsUniqueWhere"],	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null},ValueTypes["Contember_MenuLocale"]],
localesBySecondaryItems?: [{	by: ValueTypes["Contember_SiteMenuLocalesBySecondaryItemsUniqueWhere"],	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null},ValueTypes["Contember_MenuLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_MenuLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SiteMenuMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	unique?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_SiteMenuOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	secondaryItems?: ValueTypes["Contember_LinkListOrderBy"] | undefined | null,
	eshopLink?: ValueTypes["Contember_LinkOrderBy"] | undefined | null
};
	["Contember_SiteMenuOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unique?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_SiteMenuLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_SiteMenuLocalesByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null
};
	["Contember_SiteMenuLocalesBySecondaryItemsUniqueWhere"]: {
	secondaryItems?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null
};
	["Contember_MenuLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_MenuLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_MenuLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuItem"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
menu?: [{	filter?: ValueTypes["Contember_MenuLocaleWhere"] | undefined | null},ValueTypes["Contember_MenuLocale"]],
link?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
subitems?: [{	filter?: ValueTypes["Contember_MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuSubitemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_MenuSubitem"]],
paginateSubitems?: [{	filter?: ValueTypes["Contember_MenuSubitemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuSubitemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_MenuSubitemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	menu?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	subitems?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuSubitem"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuSubitemMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
item?: [{	filter?: ValueTypes["Contember_MenuItemWhere"] | undefined | null},ValueTypes["Contember_MenuItem"]],
links?: [{	filter?: ValueTypes["Contember_MenuLinkListWhere"] | undefined | null},ValueTypes["Contember_MenuLinkList"]],
products?: [{	filter?: ValueTypes["Contember_MenuProductsWhere"] | undefined | null},ValueTypes["Contember_MenuProducts"]],
delivery?: [{	filter?: ValueTypes["Contember_MenuDeliveryWhere"] | undefined | null},ValueTypes["Contember_MenuDelivery"]],
contact?: [{	filter?: ValueTypes["Contember_MenuContactWhere"] | undefined | null},ValueTypes["Contember_MenuContact"]],
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuSubitemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	item?:ValueTypes["Contember_FieldMeta"],
	links?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	delivery?:ValueTypes["Contember_FieldMeta"],
	contact?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLinkList"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuLinkListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuLinkItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_MenuLinkItem"]],
paginateItems?: [{	filter?: ValueTypes["Contember_MenuLinkItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_MenuLinkItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_MenuLinkItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLinkListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLinkItem"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuLinkItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_MenuLinkListWhere"] | undefined | null},ValueTypes["Contember_MenuLinkList"]],
link?: [{	filter?: ValueTypes["Contember_LinkWhere"] | undefined | null},ValueTypes["Contember_Link"]],
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLinkItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	link?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLinkItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_MenuLinkListOrderBy"] | undefined | null,
	link?: ValueTypes["Contember_LinkOrderBy"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_MenuLinkListOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_MenuLinkItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_MenuLinkItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLinkItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_MenuLinkItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuProducts"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuProductsMeta"],
	id?:boolean | `@${string}`,
links?: [{	filter?: ValueTypes["Contember_MenuLinkListWhere"] | undefined | null},ValueTypes["Contember_MenuLinkList"]],
buttons?: [{	filter?: ValueTypes["Contember_LinkListWhere"] | undefined | null},ValueTypes["Contember_LinkList"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuProductsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	links?:ValueTypes["Contember_FieldMeta"],
	buttons?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuDelivery"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuDeliveryMeta"],
	id?:boolean | `@${string}`,
deliveryRegions?: [{	filter?: ValueTypes["Contember_DeliveryRegionsWhere"] | undefined | null},ValueTypes["Contember_DeliveryRegions"]],
	text?:boolean | `@${string}`,
socialsAndApps?: [{	filter?: ValueTypes["Contember_SocialsAndAppsWhere"] | undefined | null},ValueTypes["Contember_SocialsAndApps"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuDeliveryMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	deliveryRegions?:ValueTypes["Contember_FieldMeta"],
	text?:ValueTypes["Contember_FieldMeta"],
	socialsAndApps?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuContact"]: AliasType<{
	_meta?:ValueTypes["Contember_MenuContactMeta"],
	id?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuContactMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	text?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuSubitemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	item?: ValueTypes["Contember_MenuItemOrderBy"] | undefined | null,
	links?: ValueTypes["Contember_MenuLinkListOrderBy"] | undefined | null,
	products?: ValueTypes["Contember_MenuProductsOrderBy"] | undefined | null,
	delivery?: ValueTypes["Contember_MenuDeliveryOrderBy"] | undefined | null,
	contact?: ValueTypes["Contember_MenuContactOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_MenuItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	menu?: ValueTypes["Contember_MenuLocaleOrderBy"] | undefined | null,
	link?: ValueTypes["Contember_LinkOrderBy"] | undefined | null
};
	["Contember_MenuProductsOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	links?: ValueTypes["Contember_MenuLinkListOrderBy"] | undefined | null,
	buttons?: ValueTypes["Contember_LinkListOrderBy"] | undefined | null
};
	["Contember_MenuDeliveryOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_DeliveryRegionsOrderBy"] | undefined | null,
	text?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	socialsAndApps?: ValueTypes["Contember_SocialsAndAppsOrderBy"] | undefined | null
};
	["Contember_MenuContactOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	text?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	image?: ValueTypes["Contember_ImageOrderBy"] | undefined | null
};
	["Contember_MenuSubitemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_MenuSubitemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuSubitemEdge"]: AliasType<{
	node?:ValueTypes["Contember_MenuSubitem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuLocaleItemsBySubitemsUniqueWhere"]: {
	subitems?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null
};
	["Contember_MenuItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_MenuItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MenuItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_MenuItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductPackingLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	shortName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_ProductPackingOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_ProductLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
featureList?: [{	filter?: ValueTypes["Contember_TextListWhere"] | undefined | null},ValueTypes["Contember_TextList"]],
	printableName?:boolean | `@${string}`,
	firstSticker?:boolean | `@${string}`,
	secondSticker?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	featureList?:ValueTypes["Contember_FieldMeta"],
	printableName?:ValueTypes["Contember_FieldMeta"],
	firstSticker?:ValueTypes["Contember_FieldMeta"],
	secondSticker?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	featureList?: ValueTypes["Contember_TextListOrderBy"] | undefined | null,
	printableName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	firstSticker?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	secondSticker?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductRecipeLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductRecipeLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null},ValueTypes["Contember_ProductRecipe"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductRecipeLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductRecipe"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductRecipeMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
products?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Product"]],
	internalNote?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
image?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
icon?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
ingredients?: [{	filter?: ValueTypes["Contember_ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductIngredientItem"]],
	needsPrepping?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductRecipeLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductRecipeLocale"]],
	gutTuning?:boolean | `@${string}`,
freshingContainer?: [{	filter?: ValueTypes["Contember_FreshingContainerWhere"] | undefined | null},ValueTypes["Contember_FreshingContainer"]],
	description?:boolean | `@${string}`,
nutritionFacts?: [{	filter?: ValueTypes["Contember_NutritionFactsWhere"] | undefined | null},ValueTypes["Contember_NutritionFacts"]],
productsByCode?: [{	by: ValueTypes["Contember_ProductRecipeProductsByCodeUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByStocks?: [{	by: ValueTypes["Contember_ProductRecipeProductsByStocksUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByGroupPrices?: [{	by: ValueTypes["Contember_ProductRecipeProductsByGroupPricesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByCartItems?: [{	by: ValueTypes["Contember_ProductRecipeProductsByCartItemsUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByOrderItems?: [{	by: ValueTypes["Contember_ProductRecipeProductsByOrderItemsUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByInheritedCategories?: [{	by: ValueTypes["Contember_ProductRecipeProductsByInheritedCategoriesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByLocales?: [{	by: ValueTypes["Contember_ProductRecipeProductsByLocalesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByCustomerPrices?: [{	by: ValueTypes["Contember_ProductRecipeProductsByCustomerPricesUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByVirtualProduct?: [{	by: ValueTypes["Contember_ProductRecipeProductsByVirtualProductUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByMeta?: [{	by: ValueTypes["Contember_ProductRecipeProductsByMetaUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsByPage?: [{	by: ValueTypes["Contember_ProductRecipeProductsByPageUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsBySalesDays?: [{	by: ValueTypes["Contember_ProductRecipeProductsBySalesDaysUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsBySalesWeeks?: [{	by: ValueTypes["Contember_ProductRecipeProductsBySalesWeeksUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
productsBySalesMonths?: [{	by: ValueTypes["Contember_ProductRecipeProductsBySalesMonthsUniqueWhere"],	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
ingredientsByQuantity?: [{	by: ValueTypes["Contember_ProductRecipeIngredientsByQuantityUniqueWhere"],	filter?: ValueTypes["Contember_ProductIngredientItemWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientItem"]],
localesByLocale?: [{	by: ValueTypes["Contember_ProductRecipeLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null},ValueTypes["Contember_ProductRecipeLocale"]],
paginateProducts?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["ProductConnection"]],
paginateIngredients?: [{	filter?: ValueTypes["Contember_ProductIngredientItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductIngredientItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductIngredientItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_ProductRecipeLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductRecipeLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductRecipeLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductRecipeMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	products?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
	color?:ValueTypes["Contember_FieldMeta"],
	image?:ValueTypes["Contember_FieldMeta"],
	icon?:ValueTypes["Contember_FieldMeta"],
	ingredients?:ValueTypes["Contember_FieldMeta"],
	needsPrepping?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	gutTuning?:ValueTypes["Contember_FieldMeta"],
	freshingContainer?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	nutritionFacts?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientItem"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	internalOrder?:boolean | `@${string}`,
recipe?: [{	filter?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null},ValueTypes["Contember_ProductRecipe"]],
ingredient?: [{	filter?: ValueTypes["Contember_ProductIngredientWhere"] | undefined | null},ValueTypes["Contember_ProductIngredient"]],
quantity?: [{	filter?: ValueTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientItemQuantity"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	internalOrder?:ValueTypes["Contember_FieldMeta"],
	recipe?:ValueTypes["Contember_FieldMeta"],
	ingredient?:ValueTypes["Contember_FieldMeta"],
	quantity?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientItemQuantity"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductIngredientItemQuantityMeta"],
	id?:boolean | `@${string}`,
unit?: [{	filter?: ValueTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined | null},ValueTypes["Contember_ProductIngredientItemQuantityUnit"]],
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientItemQuantityMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	unit?:ValueTypes["Contember_FieldMeta"],
	value?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalOrder?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductRecipeOrderBy"] | undefined | null,
	ingredient?: ValueTypes["Contember_ProductIngredientOrderBy"] | undefined | null,
	quantity?: ValueTypes["Contember_ProductIngredientItemQuantityOrderBy"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unit?: ValueTypes["Contember_ProductIngredientItemQuantityUnitOrderBy"] | undefined | null,
	value?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductRecipeLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_ProductRecipeOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_NutritionFacts"]: AliasType<{
	_meta?:ValueTypes["Contember_NutritionFactsMeta"],
	id?:boolean | `@${string}`,
	energyKiloJoule?:boolean | `@${string}`,
	energyKiloCalorie?:boolean | `@${string}`,
	fatsGrams?:boolean | `@${string}`,
	fatsOfWhichSaturatedGrams?:boolean | `@${string}`,
	carbohydrateGrams?:boolean | `@${string}`,
	carbohydrateOfWhichSugarsGrams?:boolean | `@${string}`,
	proteinGrams?:boolean | `@${string}`,
	fibreGrams?:boolean | `@${string}`,
	saltGrams?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_NutritionFactsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	energyKiloJoule?:ValueTypes["Contember_FieldMeta"],
	energyKiloCalorie?:ValueTypes["Contember_FieldMeta"],
	fatsGrams?:ValueTypes["Contember_FieldMeta"],
	fatsOfWhichSaturatedGrams?:ValueTypes["Contember_FieldMeta"],
	carbohydrateGrams?:ValueTypes["Contember_FieldMeta"],
	carbohydrateOfWhichSugarsGrams?:ValueTypes["Contember_FieldMeta"],
	proteinGrams?:ValueTypes["Contember_FieldMeta"],
	fibreGrams?:ValueTypes["Contember_FieldMeta"],
	saltGrams?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductRecipeProductsByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["Contember_ProductRecipeProductsByStocksUniqueWhere"]: {
	stocks?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByGroupPricesUniqueWhere"]: {
	groupPrices?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByCartItemsUniqueWhere"]: {
	cartItems?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByOrderItemsUniqueWhere"]: {
	orderItems?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByInheritedCategoriesUniqueWhere"]: {
	inheritedCategories?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByLocalesUniqueWhere"]: {
	locales?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByCustomerPricesUniqueWhere"]: {
	customerPrices?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByVirtualProductUniqueWhere"]: {
	virtualProduct?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_ProductMetadataUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsByPageUniqueWhere"]: {
	page?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsBySalesDaysUniqueWhere"]: {
	salesDays?: ValueTypes["Contember_ProductSalesDayUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsBySalesWeeksUniqueWhere"]: {
	salesWeeks?: ValueTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeProductsBySalesMonthsUniqueWhere"]: {
	salesMonths?: ValueTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeIngredientsByQuantityUniqueWhere"]: {
	quantity?: ValueTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductIngredientItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductIngredientItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductIngredientItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductIngredientItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductRecipeLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductRecipeLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductRecipeLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductRecipeLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryMethodLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_DeliveryMethodLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	trackingCodeUrlTemplate?:boolean | `@${string}`,
	processingTitle?:boolean | `@${string}`,
	processingText?:boolean | `@${string}`,
	processingIconUrl?:boolean | `@${string}`,
	dispatchedTitle?:boolean | `@${string}`,
	dispatchedText?:boolean | `@${string}`,
	dispatchedIconUrl?:boolean | `@${string}`,
	fulfilledTitle?:boolean | `@${string}`,
	fulfilledText?:boolean | `@${string}`,
	fulfilledIconUrl?:boolean | `@${string}`,
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
root?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryMethodLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	trackingCodeUrlTemplate?:ValueTypes["Contember_FieldMeta"],
	processingTitle?:ValueTypes["Contember_FieldMeta"],
	processingText?:ValueTypes["Contember_FieldMeta"],
	processingIconUrl?:ValueTypes["Contember_FieldMeta"],
	dispatchedTitle?:ValueTypes["Contember_FieldMeta"],
	dispatchedText?:ValueTypes["Contember_FieldMeta"],
	dispatchedIconUrl?:ValueTypes["Contember_FieldMeta"],
	fulfilledTitle?:ValueTypes["Contember_FieldMeta"],
	fulfilledText?:ValueTypes["Contember_FieldMeta"],
	fulfilledIconUrl?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryMethodLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	trackingCodeUrlTemplate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	processingTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	processingText?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	processingIconUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	dispatchedTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	dispatchedText?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	dispatchedIconUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fulfilledTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fulfilledText?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fulfilledIconUrl?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	root?: ValueTypes["Contember_DeliveryMethodOrderBy"] | undefined | null
};
	["Contember_PipelineStageLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_PipelineStageLocaleMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null},ValueTypes["Contember_PipelineStage"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStage"]: AliasType<{
	_meta?:ValueTypes["Contember_PipelineStageMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PipelineStageItem"]],
pipeline?: [{	filter?: ValueTypes["Contember_PipelineWhere"] | undefined | null},ValueTypes["Contember_Pipeline"]],
	order?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PipelineStageLocale"]],
sentiment?: [{	filter?: ValueTypes["Contember_PipelineStageSentimentWhere"] | undefined | null},ValueTypes["Contember_PipelineStageSentiment"]],
	type?:boolean | `@${string}`,
forms?: [{	filter?: ValueTypes["Contember_LeadFormWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_LeadFormOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_LeadForm"]],
	showItemsWeight?:boolean | `@${string}`,
	showItemsPrice?:boolean | `@${string}`,
itemsByLead?: [{	by: ValueTypes["Contember_PipelineStageItemsByLeadUniqueWhere"],	filter?: ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null},ValueTypes["Contember_PipelineStageItem"]],
localesByLocale?: [{	by: ValueTypes["Contember_PipelineStageLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null},ValueTypes["Contember_PipelineStageLocale"]],
formsByCode?: [{	by: ValueTypes["Contember_PipelineStageFormsByCodeUniqueWhere"],	filter?: ValueTypes["Contember_LeadFormWhere"] | undefined | null},ValueTypes["Contember_LeadForm"]],
formsByLeads?: [{	by: ValueTypes["Contember_PipelineStageFormsByLeadsUniqueWhere"],	filter?: ValueTypes["Contember_LeadFormWhere"] | undefined | null},ValueTypes["Contember_LeadForm"]],
paginateItems?: [{	filter?: ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PipelineStageItemConnection"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_PipelineStageLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PipelineStageLocaleConnection"]],
paginateForms?: [{	filter?: ValueTypes["Contember_LeadFormWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_LeadFormOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_LeadFormConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	pipeline?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	sentiment?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	forms?:ValueTypes["Contember_FieldMeta"],
	showItemsWeight?:ValueTypes["Contember_FieldMeta"],
	showItemsPrice?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageItem"]: AliasType<{
	_meta?:ValueTypes["Contember_PipelineStageItemMeta"],
	id?:boolean | `@${string}`,
list?: [{	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null},ValueTypes["Contember_PipelineStage"]],
lead?: [{	filter?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null},ValueTypes["Contember_PipelineLead"]],
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
	lead?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineLead"]: AliasType<{
	_meta?:ValueTypes["Contember_PipelineLeadMeta"],
	id?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
stageItem?: [{	filter?: ValueTypes["Contember_PipelineStageItemWhere"] | undefined | null},ValueTypes["Contember_PipelineStageItem"]],
	contactPerson?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
	cafeType?:boolean | `@${string}`,
	offerType?:boolean | `@${string}`,
	ownMachine?:boolean | `@${string}`,
	customerCity?:boolean | `@${string}`,
	companyId?:boolean | `@${string}`,
	customerName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phone?:boolean | `@${string}`,
form?: [{	filter?: ValueTypes["Contember_LeadFormWhere"] | undefined | null},ValueTypes["Contember_LeadForm"]],
	meta?:boolean | `@${string}`,
	multiroasterType?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineLeadMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	createdAt?:ValueTypes["Contember_FieldMeta"],
	stageItem?:ValueTypes["Contember_FieldMeta"],
	contactPerson?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	cafeType?:ValueTypes["Contember_FieldMeta"],
	offerType?:ValueTypes["Contember_FieldMeta"],
	ownMachine?:ValueTypes["Contember_FieldMeta"],
	customerCity?:ValueTypes["Contember_FieldMeta"],
	companyId?:ValueTypes["Contember_FieldMeta"],
	customerName?:ValueTypes["Contember_FieldMeta"],
	email?:ValueTypes["Contember_FieldMeta"],
	phone?:ValueTypes["Contember_FieldMeta"],
	form?:ValueTypes["Contember_FieldMeta"],
	meta?:ValueTypes["Contember_FieldMeta"],
	multiroasterType?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LeadForm"]: AliasType<{
	_meta?:ValueTypes["Contember_LeadFormMeta"],
	id?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
stage?: [{	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null},ValueTypes["Contember_PipelineStage"]],
leads?: [{	filter?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineLeadOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PipelineLead"]],
leadsByStageItem?: [{	by: ValueTypes["Contember_LeadFormLeadsByStageItemUniqueWhere"],	filter?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null},ValueTypes["Contember_PipelineLead"]],
paginateLeads?: [{	filter?: ValueTypes["Contember_PipelineLeadWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineLeadOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PipelineLeadConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LeadFormMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	stage?:ValueTypes["Contember_FieldMeta"],
	leads?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineLeadOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	createdAt?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	stageItem?: ValueTypes["Contember_PipelineStageItemOrderBy"] | undefined | null,
	contactPerson?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	cafeType?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	offerType?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	ownMachine?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customerCity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	companyId?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customerName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	email?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	phone?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	form?: ValueTypes["Contember_LeadFormOrderBy"] | undefined | null,
	meta?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	multiroasterType?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PipelineStageItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	list?: ValueTypes["Contember_PipelineStageOrderBy"] | undefined | null,
	lead?: ValueTypes["Contember_PipelineLeadOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PipelineStageOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	pipeline?: ValueTypes["Contember_PipelineOrderBy"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageSentimentOrderBy"] | undefined | null,
	type?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	showItemsWeight?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	showItemsPrice?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PipelineOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unique?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_PipelineStageSentimentOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	backgroundColor?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	textColor?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_LeadFormOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	stage?: ValueTypes["Contember_PipelineStageOrderBy"] | undefined | null
};
	["Contember_LeadFormLeadsByStageItemUniqueWhere"]: {
	stageItem?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null
};
	["Contember_PipelineLeadConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PipelineLeadEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineLeadEdge"]: AliasType<{
	node?:ValueTypes["Contember_PipelineLead"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Pipeline"]: AliasType<{
	_meta?:ValueTypes["Contember_PipelineMeta"],
	id?:boolean | `@${string}`,
	unique?:boolean | `@${string}`,
stages?: [{	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_PipelineStage"]],
stagesByItems?: [{	by: ValueTypes["Contember_PipelineStagesByItemsUniqueWhere"],	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null},ValueTypes["Contember_PipelineStage"]],
stagesByLocales?: [{	by: ValueTypes["Contember_PipelineStagesByLocalesUniqueWhere"],	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null},ValueTypes["Contember_PipelineStage"]],
stagesByForms?: [{	by: ValueTypes["Contember_PipelineStagesByFormsUniqueWhere"],	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null},ValueTypes["Contember_PipelineStage"]],
paginateStages?: [{	filter?: ValueTypes["Contember_PipelineStageWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_PipelineStageOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_PipelineStageConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	unique?:ValueTypes["Contember_FieldMeta"],
	stages?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStagesByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null
};
	["Contember_PipelineStagesByLocalesUniqueWhere"]: {
	locales?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null
};
	["Contember_PipelineStagesByFormsUniqueWhere"]: {
	forms?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null
};
	["Contember_PipelineStageConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PipelineStageEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageEdge"]: AliasType<{
	node?:ValueTypes["Contember_PipelineStage"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_PipelineStageOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null
};
	["Contember_PipelineStageSentiment"]: AliasType<{
	_meta?:ValueTypes["Contember_PipelineStageSentimentMeta"],
	id?:boolean | `@${string}`,
	internalName?:boolean | `@${string}`,
	backgroundColor?:boolean | `@${string}`,
	textColor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageSentimentMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	internalName?:ValueTypes["Contember_FieldMeta"],
	backgroundColor?:ValueTypes["Contember_FieldMeta"],
	textColor?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageItemsByLeadUniqueWhere"]: {
	lead?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null
};
	["Contember_PipelineStageLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_PipelineStageFormsByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["Contember_PipelineStageFormsByLeadsUniqueWhere"]: {
	leads?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null
};
	["Contember_PipelineStageItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PipelineStageItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_PipelineStageItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PipelineStageLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PipelineStageLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_PipelineStageLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LeadFormConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_LeadFormEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LeadFormEdge"]: AliasType<{
	node?:ValueTypes["Contember_LeadForm"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SubscriptionBoxLocale"]: AliasType<{
	_meta?:ValueTypes["Contember_SubscriptionBoxLocaleMeta"],
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	stickerText?:boolean | `@${string}`,
	descriptionText?:boolean | `@${string}`,
root?: [{	filter?: ValueTypes["Contember_SubscriptionBoxWhere"] | undefined | null},ValueTypes["Contember_SubscriptionBox"]],
locale?: [{	filter?: ValueTypes["Contember_LocaleWhere"] | undefined | null},ValueTypes["Contember_Locale"]],
	secondaryTitle?:boolean | `@${string}`,
texts?: [{	filter?: ValueTypes["Contember_TextListWhere"] | undefined | null},ValueTypes["Contember_TextList"]],
secondaryTexts?: [{	filter?: ValueTypes["Contember_TextListWhere"] | undefined | null},ValueTypes["Contember_TextList"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SubscriptionBoxLocaleMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	title?:ValueTypes["Contember_FieldMeta"],
	stickerText?:ValueTypes["Contember_FieldMeta"],
	descriptionText?:ValueTypes["Contember_FieldMeta"],
	root?:ValueTypes["Contember_FieldMeta"],
	locale?:ValueTypes["Contember_FieldMeta"],
	secondaryTitle?:ValueTypes["Contember_FieldMeta"],
	texts?:ValueTypes["Contember_FieldMeta"],
	secondaryTexts?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SubscriptionBox"]: AliasType<{
	_meta?:ValueTypes["Contember_SubscriptionBoxMeta"],
	id?:boolean | `@${string}`,
locales?: [{	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_SubscriptionBoxLocaleOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
	unique?:boolean | `@${string}`,
localesByLocale?: [{	by: ValueTypes["Contember_SubscriptionBoxLocalesByLocaleUniqueWhere"],	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
localesByTexts?: [{	by: ValueTypes["Contember_SubscriptionBoxLocalesByTextsUniqueWhere"],	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
localesBySecondaryTexts?: [{	by: ValueTypes["Contember_SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"],	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null},ValueTypes["Contember_SubscriptionBoxLocale"]],
paginateLocales?: [{	filter?: ValueTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_SubscriptionBoxLocaleOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_SubscriptionBoxLocaleConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SubscriptionBoxMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	locales?:ValueTypes["Contember_FieldMeta"],
	unique?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SubscriptionBoxLocaleOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	title?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	stickerText?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	descriptionText?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	root?: ValueTypes["Contember_SubscriptionBoxOrderBy"] | undefined | null,
	locale?: ValueTypes["Contember_LocaleOrderBy"] | undefined | null,
	secondaryTitle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	texts?: ValueTypes["Contember_TextListOrderBy"] | undefined | null,
	secondaryTexts?: ValueTypes["Contember_TextListOrderBy"] | undefined | null
};
	["Contember_SubscriptionBoxOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	unique?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_SubscriptionBoxLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_SubscriptionBoxLocalesByTextsUniqueWhere"]: {
	texts?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: {
	secondaryTexts?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_SubscriptionBoxLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_SubscriptionBoxLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_SubscriptionBoxLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_SubscriptionBoxLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_LocaleBusinessCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null
};
	["Contember_LocaleProductCategoryListsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null
};
	["Contember_LocaleProductIngredientsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null
};
	["Contember_LocaleOrderRecurrencesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null
};
	["Contember_LocaleBlogsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_BlogUniqueWhere"] | undefined | null
};
	["Contember_LocaleBlogPostsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_BlogPostUniqueWhere"] | undefined | null
};
	["Contember_LocaleBlogPostsByCoverUniqueWhere"]: {
	cover?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null
};
	["Contember_LocaleBlogPostsByContentUniqueWhere"]: {
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null
};
	["Contember_LocaleBlogPostsByLinkUniqueWhere"]: {
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null
};
	["Contember_LocaleBlogPostsBySeoUniqueWhere"]: {
	seo?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null
};
	["Contember_LocaleMenusByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_SiteMenuUniqueWhere"] | undefined | null
};
	["Contember_LocaleMenusByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null
};
	["Contember_LocaleMenusBySecondaryItemsUniqueWhere"]: {
	secondaryItems?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null
};
	["Contember_LocalePagesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null
};
	["Contember_LocalePagesByLinkUniqueWhere"]: {
	link?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null
};
	["Contember_LocalePagesBySeoUniqueWhere"]: {
	seo?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null
};
	["Contember_LocalePagesByContentUniqueWhere"]: {
	content?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null
};
	["Contember_LocalePagesByCoverUniqueWhere"]: {
	cover?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null
};
	["Contember_LocaleProductIngredientCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null
};
	["Contember_LocaleAllergensByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null
};
	["Contember_LocaleProductPackingsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_ProductPackingUniqueWhere"] | undefined | null
};
	["Contember_LocaleProductsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_LocaleProductsByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_LocaleProductRecipesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null
};
	["Contember_LocalePointOfSaleCategoriesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null
};
	["Contember_LocalePointOfSaleCategoryProductsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null
};
	["Contember_LocaleDeliveryMethodsByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null
};
	["Contember_LocaleIngredientSuppliersByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_IngredientSupplierUniqueWhere"] | undefined | null
};
	["Contember_LocaleIdentificationCodesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null
};
	["Contember_LocalePipelineStagesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null
};
	["Contember_LocaleSubscriptionBoxesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined | null
};
	["Contember_LocaleSubscriptionBoxesByTextsUniqueWhere"]: {
	texts?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: {
	secondaryTexts?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_LocaleBusinessServicesByRootUniqueWhere"]: {
	root?: ValueTypes["Contember_BusinessServiceUniqueWhere"] | undefined | null
};
	["Contember_ProductPackingLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductPackingLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductPackingLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductPackingLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryMethodLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_DeliveryMethodLocaleEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryMethodLocaleEdge"]: AliasType<{
	node?:ValueTypes["Contember_DeliveryMethodLocale"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PreparedPackingQuantity"]: AliasType<{
	_meta?:ValueTypes["Contember_PreparedPackingQuantityMeta"],
	id?:boolean | `@${string}`,
	date?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
recipe?: [{	filter?: ValueTypes["Contember_ProductRecipeWhere"] | undefined | null},ValueTypes["Contember_ProductRecipe"]],
packing?: [{	filter?: ValueTypes["Contember_ProductPackingWhere"] | undefined | null},ValueTypes["Contember_ProductPacking"]],
	packedInCardboard?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_PreparedPackingQuantityMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	date?:ValueTypes["Contember_FieldMeta"],
	quantity?:ValueTypes["Contember_FieldMeta"],
	recipe?:ValueTypes["Contember_FieldMeta"],
	packing?:ValueTypes["Contember_FieldMeta"],
	packedInCardboard?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PreparedPackingQuantityOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	date?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	quantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductRecipeOrderBy"] | undefined | null,
	packing?: ValueTypes["Contember_ProductPackingOrderBy"] | undefined | null,
	packedInCardboard?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductPackingLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_PreparedPackingQuantityConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_PreparedPackingQuantityEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_PreparedPackingQuantityEdge"]: AliasType<{
	node?:ValueTypes["Contember_PreparedPackingQuantity"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductStock"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductStockMeta"],
	id?:boolean | `@${string}`,
	quantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
store?: [{	filter?: ValueTypes["Contember_StoreWhere"] | undefined | null},ValueTypes["Contember_Store"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductStockMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	quantity?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
	store?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Store"]: AliasType<{
	_meta?:ValueTypes["Contember_StoreMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	internalNote?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_StoreMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	description?:ValueTypes["Contember_FieldMeta"],
	internalNote?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductStockOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	quantity?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null,
	store?: ValueTypes["Contember_StoreOrderBy"] | undefined | null
};
	["Contember_StoreOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	code?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	description?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	internalNote?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ProductGroupPrice"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductGroupPriceMeta"],
	id?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
group?: [{	filter?: ValueTypes["Contember_CustomerGroupWhere"] | undefined | null},ValueTypes["Contember_CustomerGroup"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGroupPriceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	priceCents?:ValueTypes["Contember_FieldMeta"],
	group?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerGroup"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerGroupMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
productPrices?: [{	filter?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGroupPriceOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ProductGroupPrice"]],
customers?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_Customer"]],
	discountPermille?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	doubleshotLegacyId?:boolean | `@${string}`,
productPricesByProduct?: [{	by: ValueTypes["Contember_CustomerGroupProductPricesByProductUniqueWhere"],	filter?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null},ValueTypes["Contember_ProductGroupPrice"]],
customersByDoubleshotLegacyId?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByAddresses?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByAddressesUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByDefaultBillingAddress?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByDefaultPaymentMethod?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByDefaultPaymentMethodUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByCredits?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByCreditsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByPaymentMethods?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByPaymentMethodsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByOrders?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByOrdersUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByPermissions?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByPermissionsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByName?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByNameUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByIngredientRatings?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByIngredientRatingsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByBusiness?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByBusinessUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByCarts?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByCartsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByShortcutList?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByShortcutListUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByUser?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByUserUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByInvoices?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByInvoicesUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByInvoicingPlan?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByInvoicingPlanUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByBillingSubjects?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByBillingSubjectsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByOfferedProducts?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByOfferedProductsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByDefaultDeliveryAddress?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByDefaultBillingSubject?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByAnonymousSession?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByAnonymousSessionUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByBoughtVouchers?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByBoughtVouchersUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByOwnsVouchers?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByOwnsVouchersUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByRedeemedVouchers?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByRedeemedVouchersUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersBySpending?: [{	by: ValueTypes["Contember_CustomerGroupCustomersBySpendingUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByCustomerTabs?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByCustomerTabsUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByLead?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByLeadUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
customersByMeta?: [{	by: ValueTypes["Contember_CustomerGroupCustomersByMetaUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
paginateProductPrices?: [{	filter?: ValueTypes["Contember_ProductGroupPriceWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ProductGroupPriceOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ProductGroupPriceConnection"]],
paginateCustomers?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerGroupMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	productPrices?:ValueTypes["Contember_FieldMeta"],
	customers?:ValueTypes["Contember_FieldMeta"],
	discountPermille?:ValueTypes["Contember_FieldMeta"],
	code?:ValueTypes["Contember_FieldMeta"],
	doubleshotLegacyId?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGroupPriceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	group?: ValueTypes["Contember_CustomerGroupOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_CustomerGroupProductPricesByProductUniqueWhere"]: {
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["Contember_CustomerGroupCustomersByAddressesUniqueWhere"]: {
	addresses?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"]: {
	defaultBillingAddress?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByDefaultPaymentMethodUniqueWhere"]: {
	defaultPaymentMethod?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByCreditsUniqueWhere"]: {
	credits?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByPaymentMethodsUniqueWhere"]: {
	paymentMethods?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByOrdersUniqueWhere"]: {
	orders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByPermissionsUniqueWhere"]: {
	permissions?: ValueTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByNameUniqueWhere"]: {
	name?: ValueTypes["Contember_CustomerNameUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByIngredientRatingsUniqueWhere"]: {
	ingredientRatings?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByBusinessUniqueWhere"]: {
	business?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByCartsUniqueWhere"]: {
	carts?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByShortcutListUniqueWhere"]: {
	shortcutList?: ValueTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByUserUniqueWhere"]: {
	user?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByInvoicingPlanUniqueWhere"]: {
	invoicingPlan?: ValueTypes["Contember_InvoicingPlanUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByBillingSubjectsUniqueWhere"]: {
	billingSubjects?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByOfferedProductsUniqueWhere"]: {
	offeredProducts?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"]: {
	defaultDeliveryAddress?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"]: {
	defaultBillingSubject?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByAnonymousSessionUniqueWhere"]: {
	anonymousSession?: ValueTypes["Contember_AnonymousSessionUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByBoughtVouchersUniqueWhere"]: {
	boughtVouchers?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByOwnsVouchersUniqueWhere"]: {
	ownsVouchers?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByRedeemedVouchersUniqueWhere"]: {
	redeemedVouchers?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersBySpendingUniqueWhere"]: {
	spending?: ValueTypes["Contember_CustomerSpendingUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByCustomerTabsUniqueWhere"]: {
	customerTabs?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByLeadUniqueWhere"]: {
	lead?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupCustomersByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_CustomerMetadataUniqueWhere"] | undefined | null
};
	["Contember_ProductGroupPriceConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductGroupPriceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductGroupPriceEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductGroupPrice"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCustomerPrice"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductCustomerPriceMeta"],
	id?:boolean | `@${string}`,
	groupDiscountPermille?:boolean | `@${string}`,
	groupPriceCents?:boolean | `@${string}`,
	fullPriceCents?:boolean | `@${string}`,
	discountedPriceCents?:boolean | `@${string}`,
	priceCents?:boolean | `@${string}`,
	offered?:boolean | `@${string}`,
	notDiscountable?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCustomerPriceMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	groupDiscountPermille?:ValueTypes["Contember_FieldMeta"],
	groupPriceCents?:ValueTypes["Contember_FieldMeta"],
	fullPriceCents?:ValueTypes["Contember_FieldMeta"],
	discountedPriceCents?:ValueTypes["Contember_FieldMeta"],
	priceCents?:ValueTypes["Contember_FieldMeta"],
	offered?:ValueTypes["Contember_FieldMeta"],
	notDiscountable?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCustomerPriceOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	groupDiscountPermille?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	groupPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	fullPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	discountedPriceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCents?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	offered?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	notDiscountable?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerOrderBy"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_ProductMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductMetadataMeta"],
	id?:boolean | `@${string}`,
	archived?:boolean | `@${string}`,
	available?:boolean | `@${string}`,
	availableQuantity?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	archived?:ValueTypes["Contember_FieldMeta"],
	available?:ValueTypes["Contember_FieldMeta"],
	availableQuantity?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesDay"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductSalesDayMeta"],
	id?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	orderDate?:boolean | `@${string}`,
	priceCentsSum?:boolean | `@${string}`,
	sum?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesDayMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	productName?:ValueTypes["Contember_FieldMeta"],
	orderDate?:ValueTypes["Contember_FieldMeta"],
	priceCentsSum?:ValueTypes["Contember_FieldMeta"],
	sum?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesDayOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	productName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderDate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCentsSum?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	sum?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_ProductSalesWeek"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductSalesWeekMeta"],
	id?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	orderDate?:boolean | `@${string}`,
	priceCentsSum?:boolean | `@${string}`,
	sum?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesWeekMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	productName?:ValueTypes["Contember_FieldMeta"],
	orderDate?:ValueTypes["Contember_FieldMeta"],
	priceCentsSum?:ValueTypes["Contember_FieldMeta"],
	sum?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesWeekOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	productName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderDate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCentsSum?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	sum?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_ProductSalesMonth"]: AliasType<{
	_meta?:ValueTypes["Contember_ProductSalesMonthMeta"],
	id?:boolean | `@${string}`,
	productName?:boolean | `@${string}`,
	orderDate?:boolean | `@${string}`,
	priceCentsSum?:boolean | `@${string}`,
	sum?:boolean | `@${string}`,
product?: [{	filter?: ValueTypes["Contember_ProductWhere"] | undefined | null},ValueTypes["Product"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesMonthMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	productName?:ValueTypes["Contember_FieldMeta"],
	orderDate?:ValueTypes["Contember_FieldMeta"],
	priceCentsSum?:ValueTypes["Contember_FieldMeta"],
	sum?:ValueTypes["Contember_FieldMeta"],
	product?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesMonthOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	productName?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	orderDate?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	priceCentsSum?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	sum?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	product?: ValueTypes["Contember_ProductOrderBy"] | undefined | null
};
	["Contember_ProductStocksByStoreUniqueWhere"]: {
	store?: ValueTypes["Contember_StoreUniqueWhere"] | undefined | null
};
	["Contember_ProductGroupPricesByGroupUniqueWhere"]: {
	group?: ValueTypes["Contember_CustomerGroupUniqueWhere"] | undefined | null
};
	["Contember_ProductCartItemsByCartUniqueWhere"]: {
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_ProductOrderItemsByVirtualProductEffectsUniqueWhere"]: {
	virtualProductEffects?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null
};
	["Contember_ProductLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["Contember_ProductLocalesByFeatureListUniqueWhere"]: {
	featureList?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null
};
	["Contember_ProductCustomerPricesByCustomerUniqueWhere"]: {
	customer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_ProductStockConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductStockEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductStockEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductStock"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryZoneConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_DeliveryZoneEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryZoneEdge"]: AliasType<{
	node?:ValueTypes["Contember_DeliveryZone"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCustomerPriceConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductCustomerPriceEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductCustomerPriceEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductCustomerPrice"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesDayConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductSalesDayEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesDayEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductSalesDay"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesWeekConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductSalesWeekEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesWeekEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductSalesWeek"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesMonthConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ProductSalesMonthEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ProductSalesMonthEdge"]: AliasType<{
	node?:ValueTypes["Contember_ProductSalesMonth"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AddressMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_AddressMetadataMeta"],
	id?:boolean | `@${string}`,
	deliveryZoneType?:boolean | `@${string}`,
address?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
deliveryZone?: [{	filter?: ValueTypes["Contember_DeliveryZoneWhere"] | undefined | null},ValueTypes["Contember_DeliveryZone"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AddressMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	deliveryZoneType?:ValueTypes["Contember_FieldMeta"],
	address?:ValueTypes["Contember_FieldMeta"],
	deliveryZone?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryZoneAddressesByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneAddressesByGpsUniqueWhere"]: {
	gps?: ValueTypes["Contember_GpsUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneAddressesByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: {
	billingAddressOfOrders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
	deliveryAddressOfOrderDeliveries?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneAddressesMetadataByAddressUniqueWhere"]: {
	address?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null
};
	["AddressConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["AddressEdge"],
		__typename?: boolean | `@${string}`
}>;
	["AddressEdge"]: AliasType<{
	node?:ValueTypes["Address"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AddressMetadataConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_AddressMetadataEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AddressMetadataEdge"]: AliasType<{
	node?:ValueTypes["Contember_AddressMetadata"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryTimelinePreset"]: AliasType<{
	_meta?:ValueTypes["Contember_DeliveryTimelinePresetMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	duration?:boolean | `@${string}`,
	window?:boolean | `@${string}`,
deliveryMethod?: [{	filter?: ValueTypes["Contember_DeliveryMethodWhere"] | undefined | null},ValueTypes["DeliveryMethod"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryTimelinePresetMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	duration?:ValueTypes["Contember_FieldMeta"],
	window?:ValueTypes["Contember_FieldMeta"],
	deliveryMethod?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_DeliveryMethodLocalesByLocaleUniqueWhere"]: {
	locale?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null
};
	["PaymentMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["PaymentMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["PaymentMethodEdge"]: AliasType<{
	node?:ValueTypes["PaymentMethod"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CourierConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CourierEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CourierEdge"]: AliasType<{
	node?:ValueTypes["Contember_Courier"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerPermissions"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerPermissionsMeta"],
	id?:boolean | `@${string}`,
	canPlaceOrder?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerPermissionsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	canPlaceOrder?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerName"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerNameMeta"],
	id?:boolean | `@${string}`,
	nominative?:boolean | `@${string}`,
	vocative?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
aliases?: [{	filter?: ValueTypes["Contember_CustomerAliasWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerAliasOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_CustomerAlias"]],
paginateAliases?: [{	filter?: ValueTypes["Contember_CustomerAliasWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_CustomerAliasOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_CustomerAliasConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerNameMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	nominative?:ValueTypes["Contember_FieldMeta"],
	vocative?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	aliases?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerAlias"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerAliasMeta"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerAliasMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerAliasOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_CustomerAliasConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CustomerAliasEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerAliasEdge"]: AliasType<{
	node?:ValueTypes["Contember_CustomerAlias"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ApplicationShortcutList"]: AliasType<{
	_meta?:ValueTypes["Contember_ApplicationShortcutListMeta"],
	id?:boolean | `@${string}`,
items?: [{	filter?: ValueTypes["Contember_ApplicationShortcutListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ApplicationShortcutListItemOrderBy"]> | undefined | null,	offset?: number | undefined | null,	limit?: number | undefined | null},ValueTypes["Contember_ApplicationShortcutListItem"]],
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
paginateItems?: [{	filter?: ValueTypes["Contember_ApplicationShortcutListItemWhere"] | undefined | null,	orderBy?: Array<ValueTypes["Contember_ApplicationShortcutListItemOrderBy"]> | undefined | null,	skip?: number | undefined | null,	first?: number | undefined | null},ValueTypes["Contember_ApplicationShortcutListItemConnection"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ApplicationShortcutListMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	items?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ApplicationShortcutListItem"]: AliasType<{
	_meta?:ValueTypes["Contember_ApplicationShortcutListItemMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
application?: [{	filter?: ValueTypes["Contember_ApplicationWhere"] | undefined | null},ValueTypes["Contember_Application"]],
list?: [{	filter?: ValueTypes["Contember_ApplicationShortcutListWhere"] | undefined | null},ValueTypes["Contember_ApplicationShortcutList"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ApplicationShortcutListItemMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	application?:ValueTypes["Contember_FieldMeta"],
	list?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Application"]: AliasType<{
	_meta?:ValueTypes["Contember_ApplicationMeta"],
	id?:boolean | `@${string}`,
	order?:boolean | `@${string}`,
	handle?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
icon?: [{	filter?: ValueTypes["Contember_ImageWhere"] | undefined | null},ValueTypes["Contember_Image"]],
	name?:boolean | `@${string}`,
	short?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_ApplicationMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	order?:ValueTypes["Contember_FieldMeta"],
	handle?:ValueTypes["Contember_FieldMeta"],
	url?:ValueTypes["Contember_FieldMeta"],
	color?:ValueTypes["Contember_FieldMeta"],
	icon?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	short?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ApplicationShortcutListItemOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	application?: ValueTypes["Contember_ApplicationOrderBy"] | undefined | null,
	list?: ValueTypes["Contember_ApplicationShortcutListOrderBy"] | undefined | null
};
	["Contember_ApplicationOrderBy"]: {
	_random?: boolean | undefined | null,
	_randomSeeded?: number | undefined | null,
	id?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	order?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	handle?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	url?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	color?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	icon?: ValueTypes["Contember_ImageOrderBy"] | undefined | null,
	name?: ValueTypes["Contember_OrderDirection"] | undefined | null,
	short?: ValueTypes["Contember_OrderDirection"] | undefined | null
};
	["Contember_ApplicationShortcutListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_ApplicationShortcutListItemEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_ApplicationShortcutListItemEdge"]: AliasType<{
	node?:ValueTypes["Contember_ApplicationShortcutListItem"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InvoicingPlan"]: AliasType<{
	_meta?:ValueTypes["Contember_InvoicingPlanMeta"],
	id?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
preset?: [{	filter?: ValueTypes["Contember_InvoicingPresetWhere"] | undefined | null},ValueTypes["Contember_InvoicingPreset"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InvoicingPlanMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
	preset?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InvoicingPreset"]: AliasType<{
	_meta?:ValueTypes["Contember_InvoicingPresetMeta"],
	id?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	startsAt?:boolean | `@${string}`,
	endsAt?:boolean | `@${string}`,
	intervalOfDays?:boolean | `@${string}`,
invoicingPlan?: [{	filter?: ValueTypes["Contember_InvoicingPlanWhere"] | undefined | null},ValueTypes["Contember_InvoicingPlan"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_InvoicingPresetMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	type?:ValueTypes["Contember_FieldMeta"],
	startsAt?:ValueTypes["Contember_FieldMeta"],
	endsAt?:ValueTypes["Contember_FieldMeta"],
	intervalOfDays?:ValueTypes["Contember_FieldMeta"],
	invoicingPlan?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerSpending"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerSpendingMeta"],
	id?:boolean | `@${string}`,
	totalCents?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerSpendingMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	totalCents?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerMetadata"]: AliasType<{
	_meta?:ValueTypes["Contember_CustomerMetadataMeta"],
	id?:boolean | `@${string}`,
	isBusiness?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	fallbackName?:boolean | `@${string}`,
	remainingCreditsCents?:boolean | `@${string}`,
	ordersTotalCents?:boolean | `@${string}`,
	customerProfileUrl?:boolean | `@${string}`,
customer?: [{	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerMetadataMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	isBusiness?:ValueTypes["Contember_FieldMeta"],
	name?:ValueTypes["Contember_FieldMeta"],
	fallbackName?:ValueTypes["Contember_FieldMeta"],
	remainingCreditsCents?:ValueTypes["Contember_FieldMeta"],
	ordersTotalCents?:ValueTypes["Contember_FieldMeta"],
	customerProfileUrl?:ValueTypes["Contember_FieldMeta"],
	customer?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerAddressesByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null
};
	["Contember_CustomerAddressesByGpsUniqueWhere"]: {
	gps?: ValueTypes["Contember_GpsUniqueWhere"] | undefined | null
};
	["Contember_CustomerAddressesByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null
};
	["Contember_CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: {
	billingAddressOfOrders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
	deliveryAddressOfOrderDeliveries?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreditsByTransactionsUniqueWhere"]: {
	transactions?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreditsByVoucherRedemptionUniqueWhere"]: {
	voucherRedemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["Contember_CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["Contember_CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["Contember_CustomerOrdersByDiscountsUniqueWhere"]: {
	discounts?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersByCartUniqueWhere"]: {
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersByEventsUniqueWhere"]: {
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersBySummaryUniqueWhere"]: {
	summary?: ValueTypes["Contember_OrderSummaryUniqueWhere"] | undefined | null
};
	["Contember_CustomerOrdersByGratuityUniqueWhere"]: {
	gratuity?: ValueTypes["Contember_GratuityUniqueWhere"] | undefined | null
};
	["Contember_CustomerIngredientRatingsByIngredientUniqueWhere"]: {
	ingredient?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null
};
	["Contember_CustomerCartsByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null
};
	["Contember_CustomerCartsByOrderUniqueWhere"]: {
	order?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_CustomerCartsByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["Contember_CartPriceLineUniqueWhere"] | undefined | null
};
	["Contember_CustomerCartsBySummaryUniqueWhere"]: {
	summary?: ValueTypes["Contember_CartSummaryUniqueWhere"] | undefined | null
};
	["Contember_CustomerInvoicesByPublicKeyUniqueWhere"]: {
	publicKey?: string | undefined | null
};
	["Contember_CustomerInvoicesByFakturoidIdUniqueWhere"]: {
	fakturoidId?: string | undefined | null
};
	["Contember_CustomerInvoicesByOrderPaymentsUniqueWhere"]: {
	orderPayments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_CustomerBillingSubjectsByInvoicesUniqueWhere"]: {
	invoices?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null
};
	["Contember_CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"]: {
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerBillingSubjectsByOrdersUniqueWhere"]: {
	orders?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_CustomerOfferedProductsByProductUniqueWhere"]: {
	product?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_CustomerBoughtVouchersByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["Contember_CustomerBoughtVouchersByRedemptionUniqueWhere"]: {
	redemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_CustomerOwnsVouchersByCodeUniqueWhere"]: {
	code?: string | undefined | null
};
	["Contember_CustomerOwnsVouchersByRedemptionUniqueWhere"]: {
	redemption?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null
};
	["Contember_CustomerRedeemedVouchersByCustomerCreditUniqueWhere"]: {
	customerCredit?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null
};
	["Contember_CustomerRedeemedVouchersByOrderDiscountUniqueWhere"]: {
	orderDiscount?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null
};
	["Contember_CustomerRedeemedVouchersByVoucherUniqueWhere"]: {
	voucher?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null
};
	["Contember_CustomerCustomerTabsByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null
};
	["Contember_CustomerCustomerTabsByMetaUniqueWhere"]: {
	meta?: ValueTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined | null
};
	["Contember_CustomerLeadByStageItemUniqueWhere"]: {
	stageItem?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null
};
	["CustomerCreditConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["CustomerCreditEdge"],
		__typename?: boolean | `@${string}`
}>;
	["CustomerCreditEdge"]: AliasType<{
	node?:ValueTypes["CustomerCredit"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerPaymentMethodConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_CustomerPaymentMethodEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_CustomerPaymentMethodEdge"]: AliasType<{
	node?:ValueTypes["Contember_CustomerPaymentMethod"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BillingSubjectConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_BillingSubjectEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_BillingSubjectEdge"]: AliasType<{
	node?:ValueTypes["Contember_BillingSubject"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VoucherRedemptionConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["Contember_VoucherRedemptionEdge"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_VoucherRedemptionEdge"]: AliasType<{
	node?:ValueTypes["Contember_VoucherRedemption"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Gps"]: AliasType<{
	_meta?:ValueTypes["Contember_GpsMeta"],
	id?:boolean | `@${string}`,
	latitude?:boolean | `@${string}`,
	longitude?:boolean | `@${string}`,
address?: [{	filter?: ValueTypes["Contember_AddressWhere"] | undefined | null},ValueTypes["Address"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_GpsMeta"]: AliasType<{
	id?:ValueTypes["Contember_FieldMeta"],
	latitude?:ValueTypes["Contember_FieldMeta"],
	longitude?:ValueTypes["Contember_FieldMeta"],
	address?:ValueTypes["Contember_FieldMeta"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_AddressInvoicesByPublicKeyUniqueWhere"]: {
	publicKey?: string | undefined | null
};
	["Contember_AddressInvoicesByFakturoidIdUniqueWhere"]: {
	fakturoidId?: string | undefined | null
};
	["Contember_AddressInvoicesByOrderPaymentsUniqueWhere"]: {
	orderPayments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersBySeqUniqueWhere"]: {
	seq?: number | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: {
	doubleshotLegacyId?: string | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
	doubleShotLegacyNumber?: string | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByDiscountsUniqueWhere"]: {
	discounts?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: {
	delivery?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByItemsUniqueWhere"]: {
	items?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByCartUniqueWhere"]: {
	cart?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByEventsUniqueWhere"]: {
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"]: {
	priceLines?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: {
	payments?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersBySummaryUniqueWhere"]: {
	summary?: ValueTypes["Contember_OrderSummaryUniqueWhere"] | undefined | null
};
	["Contember_AddressBillingAddressOfOrdersByGratuityUniqueWhere"]: {
	gratuity?: ValueTypes["Contember_GratuityUniqueWhere"] | undefined | null
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: {
	trackingCode?: string | undefined | null
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: {
	order?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"]: {
	deliveryWaypoint?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByEventsUniqueWhere"]: {
	events?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByLiftagoRideUniqueWhere"]: {
	liftagoRide?: ValueTypes["Contember_LiftagoRideUniqueWhere"] | undefined | null
};
	["OrderDeliveryConnection"]: AliasType<{
	pageInfo?:ValueTypes["Contember_PageInfo"],
	edges?:ValueTypes["OrderDeliveryEdge"],
		__typename?: boolean | `@${string}`
}>;
	["OrderDeliveryEdge"]: AliasType<{
	node?:ValueTypes["OrderDelivery"],
		__typename?: boolean | `@${string}`
}>;
	["Contember__ValidationResult"]: AliasType<{
	valid?:boolean | `@${string}`,
	errors?:ValueTypes["Contember__ValidationError"],
		__typename?: boolean | `@${string}`
}>;
	["Contember__ValidationError"]: AliasType<{
	path?:ValueTypes["Contember__PathFragment"],
	message?:ValueTypes["Contember__ValidationMessage"],
		__typename?: boolean | `@${string}`
}>;
	["Contember__PathFragment"]: AliasType<{		["...on Contember__FieldPathFragment"] : ValueTypes["Contember__FieldPathFragment"],
		["...on Contember__IndexPathFragment"] : ValueTypes["Contember__IndexPathFragment"]
		__typename?: boolean | `@${string}`
}>;
	["Contember__FieldPathFragment"]: AliasType<{
	field?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember__IndexPathFragment"]: AliasType<{
	index?:boolean | `@${string}`,
	alias?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember__ValidationMessage"]: AliasType<{
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_AddressCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutAddressesCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutAddressesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null
};
	["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerPaymentMethodCreateInput"] | undefined | null
};
	["Contember_CustomerPaymentMethodCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	caption?: string | undefined | null,
	data?: ValueTypes["Json"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput"] | undefined | null,
	paymentMethod?: ValueTypes["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutPaymentMethodsCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutPaymentMethodsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateAddressesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AddressWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateCountryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null
};
	["Contember_CountryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null
};
	["Contember_CountryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateDeliveryZoneEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutAddressesCreateInput"] | undefined | null
};
	["Contember_DeliveryZoneUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	addresses?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	addressesMetadata?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneWithoutAddressesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	suitableDeliveryMethods?: Array<ValueTypes["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfTags?: Array<ValueTypes["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfProducts?: Array<ValueTypes["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PaymentMethodUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	channels?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null
};
	["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	blockingDispatch?: boolean | undefined | null,
	type?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	channels?: Array<ValueTypes["Contember_PaymentMethodCreateChannelsEntityRelationInput"]> | undefined | null,
	reduceGratuityByPercentage?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PaymentMethodCreateChannelsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutMethodCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelPaymentWithoutMethodCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentCreateChannelEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutPaymentsCreateInput"] | undefined | null
};
	["Contember_ChannelWithoutPaymentsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelCreateDeliveriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryWithoutChannelCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelDeliveryWithoutChannelCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	method?: ValueTypes["Contember_ChannelDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelDeliveryCreateMethodEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodWithoutChannelsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]> | undefined | null,
	deliveryZonesOfTags?: Array<ValueTypes["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfProducts?: Array<ValueTypes["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutDeliveryZoneCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AddressWithoutDeliveryZoneCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	meta?: ValueTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null
};
	["Contember_AddressCreateGpsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_GpsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_GpsWithoutAddressCreateInput"] | undefined | null
};
	["Contember_GpsWithoutAddressCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	latitude?: number | undefined | null,
	longitude?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateInvoicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutAddressCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_InvoiceWithoutAddressCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	customer?: ValueTypes["Contember_InvoiceCreateCustomerEntityRelationInput"] | undefined | null,
	orderPayments?: Array<ValueTypes["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]> | undefined | null,
	publicUrl?: string | undefined | null,
	billingSubject?: ValueTypes["Contember_InvoiceCreateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutInvoicesCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutInvoicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutCustomersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	nestedCategories?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null
};
	["Contember_TagWithoutCustomersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutTagsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductWithoutTagsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreatePackingEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductPackingUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingCreateInput"] | undefined | null
};
	["Contember_ProductPackingCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	image?: ValueTypes["Contember_ProductPackingCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	volumeMl?: number | undefined | null,
	icon?: ValueTypes["Contember_ProductPackingCreateIconEntityRelationInput"] | undefined | null,
	shortName?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductPackingCreateLocalesEntityRelationInput"]> | undefined | null,
	preparedPackingQuantities?: Array<ValueTypes["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"]> | undefined | null,
	isPackedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ImageCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	size?: number | undefined | null,
	type?: string | undefined | null,
	alt?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingCreateIconEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductPackingCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductPackingLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	shortName?: string | undefined | null,
	description?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductPackingLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductPackingsCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutProductPackingsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_BusinessCategoryLocaleCreateRootEntityRelationInput"] | undefined | null,
	title?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutCategoryCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCustomerWithoutCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutBusinessCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutBusinessCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateCreditsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerCreditWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	initialCreditCents?: number | undefined | null,
	remainingCreditsCents?: number | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	reason?: string | undefined | null,
	vatRate?: ValueTypes["Contember_CustomerCreditCreateVatRateEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	transactions?: Array<ValueTypes["Contember_CustomerCreditCreateTransactionsEntityRelationInput"]> | undefined | null,
	voucherRedemption?: ValueTypes["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"] | undefined | null,
	invoiceUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_VatRateUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	preset?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	channelDeliveries?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	channelPayments?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null
};
	["Contember_VatRateCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	ratePermille?: number | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	channelDeliveries?: Array<ValueTypes["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"]> | undefined | null,
	channelPayments?: Array<ValueTypes["Contember_VatRateCreateChannelPaymentsEntityRelationInput"]> | undefined | null,
	preset?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryWithoutVatRateCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelDeliveryWithoutVatRateCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelDeliveryCreateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelDeliveryCreateChannelEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutDeliveriesCreateInput"] | undefined | null
};
	["Contember_ChannelWithoutDeliveriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelCreatePaymentsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutChannelCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelPaymentWithoutChannelCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentCreateMethodEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_PaymentMethodWithoutChannelsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	blockingDispatch?: boolean | undefined | null,
	type?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	reduceGratuityByPercentage?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryWithoutMethodCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelDeliveryWithoutMethodCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelDeliveryCreateChannelEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateWithoutChannelDeliveriesCreateInput"] | undefined | null
};
	["Contember_VatRateWithoutChannelDeliveriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	ratePermille?: number | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	channelPayments?: Array<ValueTypes["Contember_VatRateCreateChannelPaymentsEntityRelationInput"]> | undefined | null,
	preset?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VatRateCreateChannelPaymentsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutVatRateCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelPaymentWithoutVatRateCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutAllowChannelPaymentsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagWithoutAllowChannelPaymentsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreateCustomersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutTagsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerWithoutTagsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	caption?: string | undefined | null,
	data?: ValueTypes["Json"] | undefined | null,
	paymentMethod?: ValueTypes["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodCreateInput"] | undefined | null
};
	["Contember_PaymentMethodCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	blockingDispatch?: boolean | undefined | null,
	type?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	channels?: Array<ValueTypes["Contember_PaymentMethodCreateChannelsEntityRelationInput"]> | undefined | null,
	reduceGratuityByPercentage?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateOrdersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateChannelEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelCreateInput"] | undefined | null
};
	["Contember_ChannelCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelCreatePointOfSalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleWithoutChannelsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	permissions?: ValueTypes["Contember_PointOfSalePermissionsUniqueWhere"] | undefined | null
};
	["Contember_PointOfSalePermissionsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	pointOfSale?: ValueTypes["Contember_PointOfSaleUniqueWhere"] | undefined | null
};
	["Contember_PointOfSaleWithoutChannelsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	permissions?: ValueTypes["Contember_PointOfSaleCreatePermissionsEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCreatePermissionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSalePermissionsUniqueWhere"] | undefined | null
};
	["Contember_ChannelCreateCartsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutChannelCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartWithoutChannelCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CartCreateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_CartCreateOrderEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CartCreateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CartItemWithoutCartCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartItemWithoutCartCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	quantity?: number | undefined | null,
	product?: ValueTypes["Contember_CartItemCreateProductEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartItemCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutCartItemsCreateInput"] | undefined | null
};
	["Contember_ProductWithoutCartItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateRecipeEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeWithoutProductsCreateInput"] | undefined | null
};
	["Contember_ProductRecipeWithoutProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductRecipeCreateImageEntityRelationInput"] | undefined | null,
	icon?: ValueTypes["Contember_ProductRecipeCreateIconEntityRelationInput"] | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductRecipeCreateLocalesEntityRelationInput"]> | undefined | null,
	gutTuning?: number | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	nutritionFacts?: ValueTypes["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductRecipeCreateIconEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemWithoutRecipeCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientItemWithoutRecipeCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	internalOrder?: number | undefined | null,
	ingredient?: ValueTypes["Contember_ProductIngredientItemCreateIngredientEntityRelationInput"] | undefined | null,
	quantity?: ValueTypes["Contember_ProductIngredientItemCreateQuantityEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientItemCreateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductIngredientsCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutProductIngredientsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductCategoryListLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ProductCategoryListCreateItemsEntityRelationInput"]> | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryListItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput"] | undefined | null,
	showRecipeImages?: boolean | undefined | null,
	showPackingImages?: boolean | undefined | null,
	showAllAvailableProducts?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryCreateInput"] | undefined | null
};
	["Contember_ProductCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutCategoriesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductWithoutCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutProductsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagWithoutProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreateCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutTagsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryWithoutTagsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryCreateParentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutChildrenCreateInput"] | undefined | null
};
	["Contember_ProductCategoryWithoutChildrenCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryCreateTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutCategoriesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagWithoutCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelCreateCustomersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutChannelsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerWithoutChannelsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreatePermissionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreateGroupEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerGroupUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerGroupWithoutCustomersCreateInput"] | undefined | null
};
	["Contember_CustomerGroupWithoutCustomersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	productPrices?: Array<ValueTypes["Contember_CustomerGroupCreateProductPricesEntityRelationInput"]> | undefined | null,
	discountPermille?: number | undefined | null,
	code?: string | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerGroupCreateProductPricesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductGroupPriceWithoutGroupCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductGroupPriceWithoutGroupCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	priceCents?: number | undefined | null,
	product?: ValueTypes["Contember_ProductGroupPriceCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGroupPriceCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutGroupPricesCreateInput"] | undefined | null
};
	["Contember_ProductWithoutGroupPricesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutProductsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryWithoutProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryCreateChildrenEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutParentCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryWithoutParentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_ProductCreateStocksEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductStockWithoutProductCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductStockWithoutProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	quantity?: number | undefined | null,
	store?: ValueTypes["Contember_ProductStockCreateStoreEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductStockCreateStoreEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StoreUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StoreCreateInput"] | undefined | null
};
	["Contember_StoreCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductCreateCartItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CartItemWithoutProductCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartItemWithoutProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	quantity?: number | undefined | null,
	cart?: ValueTypes["Contember_CartItemCreateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartItemCreateCartEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutItemsCreateInput"] | undefined | null
};
	["Contember_CartWithoutItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["Contember_CartCreateChannelEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_CartCreateOrderEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CartCreateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreateChannelEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutCartsCreateInput"] | undefined | null
};
	["Contember_ChannelWithoutCartsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutAddTagsOnRegistrationCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagWithoutAddTagsOnRegistrationCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreateNestedCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagCreateDeliveryZonesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]> | undefined | null,
	suitableDeliveryMethods?: Array<ValueTypes["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfProducts?: Array<ValueTypes["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutDeliveryZonesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductWithoutDeliveryZonesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateGroupPricesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductGroupPriceWithoutProductCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductGroupPriceWithoutProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	priceCents?: number | undefined | null,
	group?: ValueTypes["Contember_ProductGroupPriceCreateGroupEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGroupPriceCreateGroupEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerGroupUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerGroupWithoutProductPricesCreateInput"] | undefined | null
};
	["Contember_CustomerGroupWithoutProductPricesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_CustomerGroupCreateCustomersEntityRelationInput"]> | undefined | null,
	discountPermille?: number | undefined | null,
	code?: string | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerGroupCreateCustomersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutGroupCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerWithoutGroupCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreatePhotoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_CustomerCreateNameEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerNameUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerNameWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerNameWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	nominative?: string | undefined | null,
	vocative?: string | undefined | null,
	aliases?: Array<ValueTypes["Contember_CustomerNameCreateAliasesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerNameCreateAliasesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerAliasUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerAliasCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerAliasUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_CustomerAliasCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	rating?: ValueTypes["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientRatingUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientRatingCreateInput"] | undefined | null
};
	["Contember_ProductIngredientRatingUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ProductIngredientRatingCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	name?: string | undefined | null,
	icon?: ValueTypes["Contember_ProductIngredientRatingCreateIconEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientRatingCreateIconEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"] | undefined | null
};
	["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateProductIngredientsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_ProductIngredientWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalNote?: string | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCreateAllergensEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AllergenWithoutIngredientsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AllergenWithoutIngredientsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_AllergenCreateLocalesEntityRelationInput"]> | undefined | null,
	code?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AllergenLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AllergenLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_AllergenLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutAllergensCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutAllergensCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_OrderRecurrenceWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	order?: number | undefined | null,
	businessCategory?: Array<ValueTypes["Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutRecurrencesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCategoryWithoutRecurrencesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCategoryLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	title?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutBusinessCategoriesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutBusinessCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateBlogsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BlogLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	pageName?: string | undefined | null,
	title?: string | undefined | null,
	lead?: string | undefined | null,
	root?: ValueTypes["Contember_BlogLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_BlogWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateBlogPostsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BlogPostLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	availableForWeb?: boolean | undefined | null,
	availableForMobile?: boolean | undefined | null,
	root?: ValueTypes["Contember_BlogPostLocaleCreateRootEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_BlogPostLocaleCreateCoverEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_BlogPostLocaleCreateContentEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_BlogPostLocaleCreateLinkEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_BlogPostLocaleCreateSeoEntityRelationInput"] | undefined | null,
	products?: Array<ValueTypes["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogPostUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_BlogPostWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleCreateCoverEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CoverCreateInput"] | undefined | null
};
	["Contember_CoverCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	primaryTitle?: string | undefined | null,
	secondaryTitle?: string | undefined | null,
	medium?: ValueTypes["Contember_CoverCreateMediumEntityRelationInput"] | undefined | null,
	buttonColors?: ValueTypes["Contember_CoverCreateButtonColorsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CoverCreateMediumEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_MediumUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_MediumCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_MediumType"] | undefined | null,
	colorTheme?: ValueTypes["Contember_ColorTheme"] | undefined | null,
	image?: ValueTypes["Contember_MediumCreateImageEntityRelationInput"] | undefined | null,
	video?: ValueTypes["Contember_MediumCreateVideoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MediumCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MediumCreateVideoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VideoUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VideoCreateInput"] | undefined | null
};
	["Contember_VideoUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_VideoCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	src?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	size?: number | undefined | null,
	type?: string | undefined | null,
	duration?: number | undefined | null,
	poster?: ValueTypes["Contember_VideoCreatePosterEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VideoCreatePosterEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_CoverCreateButtonColorsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ColorPalleteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ColorPalleteCreateInput"] | undefined | null
};
	["Contember_ColorPalleteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_ColorPalleteCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	background?: string | undefined | null,
	text?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleCreateContentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null
};
	["Contember_ContentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	blocks?: Array<ValueTypes["Contember_ContentCreateBlocksEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentCreateBlocksEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ContentBlockUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ContentBlockWithoutContentCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ContentBlockWithoutContentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	json?: string | undefined | null,
	references?: Array<ValueTypes["Contember_ContentBlockCreateReferencesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentBlockCreateReferencesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ContentReferenceWithoutBlockCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ContentReferenceWithoutBlockCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_ContentReferenceType"] | undefined | null,
	primaryText?: string | undefined | null,
	secondaryText?: string | undefined | null,
	jsonContent?: string | undefined | null,
	colorPallete?: ValueTypes["Contember_ContentReferenceCreateColorPalleteEntityRelationInput"] | undefined | null,
	medium?: ValueTypes["Contember_ContentReferenceCreateMediumEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_ContentReferenceCreateLinkEntityRelationInput"] | undefined | null,
	blogPosts?: ValueTypes["Contember_ContentReferenceCreateBlogPostsEntityRelationInput"] | undefined | null,
	links?: ValueTypes["Contember_ContentReferenceCreateLinksEntityRelationInput"] | undefined | null,
	products?: ValueTypes["Contember_ContentReferenceCreateProductsEntityRelationInput"] | undefined | null,
	contentSize?: ValueTypes["Contember_ContentSize"] | undefined | null,
	misc?: string | undefined | null,
	hero?: ValueTypes["Contember_ContentReferenceCreateHeroEntityRelationInput"] | undefined | null,
	gallery?: ValueTypes["Contember_ContentReferenceCreateGalleryEntityRelationInput"] | undefined | null,
	socialsAndApps?: ValueTypes["Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput"] | undefined | null,
	productGrid?: ValueTypes["Contember_ContentReferenceCreateProductGridEntityRelationInput"] | undefined | null,
	productBanners?: ValueTypes["Contember_ContentReferenceCreateProductBannersEntityRelationInput"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput"] | undefined | null,
	textList?: ValueTypes["Contember_ContentReferenceCreateTextListEntityRelationInput"] | undefined | null,
	gridTiles?: ValueTypes["Contember_ContentReferenceCreateGridTilesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentReferenceCreateColorPalleteEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ColorPalleteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ColorPalleteCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateMediumEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_LinkCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_LinkType"] | undefined | null,
	title?: string | undefined | null,
	externalLink?: string | undefined | null,
	internalLink?: ValueTypes["Contember_LinkCreateInternalLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkCreateInternalLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkableCreateInput"] | undefined | null
};
	["Contember_LinkableCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	blogPost?: ValueTypes["Contember_LinkableCreateBlogPostEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_LinkableCreatePageEntityRelationInput"] | undefined | null,
	wikiPage?: ValueTypes["Contember_LinkableCreateWikiPageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkableCreateBlogPostEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostLocaleWithoutLinkCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleWithoutLinkCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	availableForWeb?: boolean | undefined | null,
	availableForMobile?: boolean | undefined | null,
	root?: ValueTypes["Contember_BlogPostLocaleCreateRootEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_BlogPostLocaleCreateCoverEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_BlogPostLocaleCreateContentEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_BlogPostLocaleCreateSeoEntityRelationInput"] | undefined | null,
	products?: Array<ValueTypes["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutBlogPostsCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutBlogPostsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateIdentifierEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCataloguesIdentifierCreateInput"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	catalogue?: Array<ValueTypes["Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueWithoutIdentifierCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationCatalogueWithoutIdentifierCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationCatalogueCreateDomainEntityRelationInput"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueCreateDomainEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationDomainUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationDomainWithoutCataloguesCreateInput"] | undefined | null
};
	["Contember_TranslationDomainWithoutCataloguesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	name?: string | undefined | null,
	keys?: Array<ValueTypes["Contember_TranslationDomainCreateKeysEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationDomainCreateKeysEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationKeyWithoutDomainCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationKeyWithoutDomainCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	contentType?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	note?: string | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationKeyCreateValuesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationKeyCreateValuesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationValueWithoutKeyCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationValueWithoutKeyCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	value?: string | undefined | null,
	catalogue?: ValueTypes["Contember_TranslationValueCreateCatalogueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationValueCreateCatalogueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueWithoutValuesCreateInput"] | undefined | null
};
	["Contember_TranslationCatalogueWithoutValuesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationCatalogueCreateDomainEntityRelationInput"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueCreateInput"] | undefined | null
};
	["Contember_TranslationCatalogueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	domain?: ValueTypes["Contember_TranslationCatalogueCreateDomainEntityRelationInput"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationValueWithoutCatalogueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationValueWithoutCatalogueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	value?: string | undefined | null,
	key?: ValueTypes["Contember_TranslationValueCreateKeyEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationValueCreateKeyEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationKeyWithoutValuesCreateInput"] | undefined | null
};
	["Contember_TranslationKeyWithoutValuesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	contentType?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	note?: string | undefined | null,
	domain?: ValueTypes["Contember_TranslationKeyCreateDomainEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationKeyCreateDomainEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationDomainUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationDomainWithoutKeysCreateInput"] | undefined | null
};
	["Contember_TranslationDomainWithoutKeysCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	identifier?: string | undefined | null,
	name?: string | undefined | null,
	catalogues?: Array<ValueTypes["Contember_TranslationDomainCreateCataloguesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationDomainCreateCataloguesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueWithoutDomainCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationCatalogueWithoutDomainCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateMenusEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_MenuLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	root?: ValueTypes["Contember_MenuLocaleCreateRootEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_MenuLocaleCreateItemsEntityRelationInput"]> | undefined | null,
	secondaryItems?: ValueTypes["Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput"] | undefined | null,
	eshopLink?: ValueTypes["Contember_MenuLocaleCreateEshopLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SiteMenuUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SiteMenuWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_SiteMenuWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLocaleCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuItemWithoutMenuCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_MenuItemWithoutMenuCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	link?: ValueTypes["Contember_MenuItemCreateLinkEntityRelationInput"] | undefined | null,
	subitems?: Array<ValueTypes["Contember_MenuItemCreateSubitemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuItemCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_MenuItemCreateSubitemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuSubitemWithoutItemCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_MenuSubitemWithoutItemCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_MenuSubitemType"] | undefined | null,
	title?: string | undefined | null,
	links?: ValueTypes["Contember_MenuSubitemCreateLinksEntityRelationInput"] | undefined | null,
	products?: ValueTypes["Contember_MenuSubitemCreateProductsEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_MenuSubitemCreateDeliveryEntityRelationInput"] | undefined | null,
	contact?: ValueTypes["Contember_MenuSubitemCreateContactEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuSubitemCreateLinksEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuLinkListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuLinkListCreateInput"] | undefined | null
};
	["Contember_MenuLinkListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_MenuLinkItemUniqueWhere"] | undefined | null
};
	["Contember_MenuLinkItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_MenuLinkListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_MenuLinkListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLinkListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuLinkItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuLinkItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_MenuLinkItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	link?: ValueTypes["Contember_MenuLinkItemCreateLinkEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_MenuLinkItemCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLinkItemCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_MenuLinkItemCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MenuSubitemCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuProductsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuProductsCreateInput"] | undefined | null
};
	["Contember_MenuProductsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	buttons?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null
};
	["Contember_MenuProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	links?: ValueTypes["Contember_MenuProductsCreateLinksEntityRelationInput"] | undefined | null,
	buttons?: ValueTypes["Contember_MenuProductsCreateButtonsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuProductsCreateLinksEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuLinkListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuLinkListCreateInput"] | undefined | null
};
	["Contember_MenuProductsCreateButtonsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null
};
	["Contember_LinkListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_LinkListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkListItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkListItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LinkListItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	isPrimary?: boolean | undefined | null,
	link?: ValueTypes["Contember_LinkListItemCreateLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkListItemCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_MenuSubitemCreateDeliveryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuDeliveryCreateInput"] | undefined | null
};
	["Contember_MenuDeliveryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined | null
};
	["Contember_MenuDeliveryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput"] | undefined | null,
	text?: string | undefined | null,
	socialsAndApps?: ValueTypes["Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRegionsCreateInput"] | undefined | null
};
	["Contember_DeliveryRegionsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_DeliveryRegionsCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRegionsCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRegionsItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryRegionsItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	title?: string | undefined | null,
	offerLabel?: string | undefined | null,
	image?: ValueTypes["Contember_DeliveryRegionsItemCreateImageEntityRelationInput"] | undefined | null,
	textList?: ValueTypes["Contember_DeliveryRegionsItemCreateTextListEntityRelationInput"] | undefined | null,
	gallery?: ValueTypes["Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRegionsItemCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_DeliveryRegionsItemCreateTextListEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_TextListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_TextListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TextListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TextItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TextItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TextItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	text?: string | undefined | null,
	title?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageListCreateInput"] | undefined | null
};
	["Contember_ImageListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ImageListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ImageListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageListItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageListItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ImageListItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	image?: ValueTypes["Contember_ImageListItemCreateImageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ImageListItemCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SocialsAndAppsCreateInput"] | undefined | null
};
	["Contember_SocialsAndAppsUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_SocialsAndAppsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	instagram?: boolean | undefined | null,
	appStore?: boolean | undefined | null,
	googlePlay?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuSubitemCreateContactEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MenuContactUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MenuContactCreateInput"] | undefined | null
};
	["Contember_MenuContactUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_MenuContactCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	text?: string | undefined | null,
	image?: ValueTypes["Contember_MenuContactCreateImageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuContactCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null
};
	["Contember_MenuLocaleCreateEshopLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_LocaleCreatePagesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PageLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PageLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	link?: ValueTypes["Contember_PageLocaleCreateLinkEntityRelationInput"] | undefined | null,
	root?: ValueTypes["Contember_PageLocaleCreateRootEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_PageLocaleCreateSeoEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_PageLocaleCreateContentEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_PageLocaleCreateCoverEntityRelationInput"] | undefined | null,
	theme?: ValueTypes["Contember_PageTheme"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkableWithoutPageCreateInput"] | undefined | null
};
	["Contember_LinkableWithoutPageCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	blogPost?: ValueTypes["Contember_LinkableCreateBlogPostEntityRelationInput"] | undefined | null,
	wikiPage?: ValueTypes["Contember_LinkableCreateWikiPageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkableCreateWikiPageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_WikiPageWithoutLinkCreateInput"] | undefined | null
};
	["Contember_WikiPageWithoutLinkCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	title?: string | undefined | null,
	emoji?: string | undefined | null,
	parent?: ValueTypes["Contember_WikiPageCreateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_WikiPageCreateChildrenEntityRelationInput"]> | undefined | null,
	content?: ValueTypes["Contember_WikiPageCreateContentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WikiPageCreateParentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_WikiPageWithoutChildrenCreateInput"] | undefined | null
};
	["Contember_WikiPageWithoutChildrenCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	title?: string | undefined | null,
	emoji?: string | undefined | null,
	link?: ValueTypes["Contember_WikiPageCreateLinkEntityRelationInput"] | undefined | null,
	parent?: ValueTypes["Contember_WikiPageCreateParentEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_WikiPageCreateContentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WikiPageCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkableWithoutWikiPageCreateInput"] | undefined | null
};
	["Contember_LinkableWithoutWikiPageCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	blogPost?: ValueTypes["Contember_LinkableCreateBlogPostEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_LinkableCreatePageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkableCreatePageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PageLocaleWithoutLinkCreateInput"] | undefined | null
};
	["Contember_PageLocaleWithoutLinkCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	root?: ValueTypes["Contember_PageLocaleCreateRootEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_PageLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_PageLocaleCreateSeoEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_PageLocaleCreateContentEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_PageLocaleCreateCoverEntityRelationInput"] | undefined | null,
	theme?: ValueTypes["Contember_PageTheme"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PageWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_PageWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPagesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutPagesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput"]> | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutCategoriesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientWithoutCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput"] | undefined | null,
	rating?: ValueTypes["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutIngredientRatingsCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutIngredientRatingsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateBusinessEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutMembersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCustomerWithoutMembersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerCreateCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_OrderRecurrenceCreateLocalesEntityRelationInput"]> | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutOrderRecurrencesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutOrderRecurrencesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateAllergensEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AllergenLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AllergenLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_AllergenLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AllergenWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_AllergenWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	ingredients?: Array<ValueTypes["Contember_AllergenCreateIngredientsEntityRelationInput"]> | undefined | null,
	code?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenCreateIngredientsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutAllergensCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientWithoutAllergensCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityUnitUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null
};
	["Contember_ProductIngredientItemQuantityUnitCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FreshingContainerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null
};
	["Contember_FreshingContainerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_FreshingContainerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	volumeMl?: number | undefined | null,
	workspace?: ValueTypes["Contember_FreshingContainerCreateWorkspaceEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FreshingContainerCreateWorkspaceEntityRelationInput"]: {
	connect?: ValueTypes["Contember_WorkspaceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_WorkspaceWithoutFreshingContainersCreateInput"] | undefined | null
};
	["Contember_WorkspaceUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	freshingContainers?: ValueTypes["Contember_FreshingContainerUniqueWhere"] | undefined | null,
	openingHours?: ValueTypes["Contember_WeekHoursUniqueWhere"] | undefined | null,
	workingHours?: ValueTypes["Contember_WeekHoursUniqueWhere"] | undefined | null,
	creditsProduct?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null,
	discountVatRate?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_WeekHoursUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_WorkspaceWithoutFreshingContainersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	orderDeadlineTime?: string | undefined | null,
	ordersLoadingDurationHours?: number | undefined | null,
	freshingContainerVolumeMl?: number | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: number | undefined | null,
	checkoutUrlTemplate?: string | undefined | null,
	orderUrlTemplate?: string | undefined | null,
	paymentUrlTemplate?: string | undefined | null,
	customerProfileUrlTemplate?: string | undefined | null,
	userProfileUrlTemplate?: string | undefined | null,
	messengerUrl?: string | undefined | null,
	messengerIosUrl?: string | undefined | null,
	messengerAndroidUrl?: string | undefined | null,
	openingHours?: ValueTypes["Contember_WorkspaceCreateOpeningHoursEntityRelationInput"] | undefined | null,
	workingHours?: ValueTypes["Contember_WorkspaceCreateWorkingHoursEntityRelationInput"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput"] | undefined | null,
	creditsProduct?: ValueTypes["Contember_WorkspaceCreateCreditsProductEntityRelationInput"] | undefined | null,
	discountVatRate?: ValueTypes["Contember_WorkspaceCreateDiscountVatRateEntityRelationInput"] | undefined | null,
	name?: string | undefined | null,
	businessAddress?: string | undefined | null,
	migrationTest?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WorkspaceCreateOpeningHoursEntityRelationInput"]: {
	connect?: ValueTypes["Contember_WeekHoursUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_WeekHoursCreateInput"] | undefined | null
};
	["Contember_WeekHoursCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	mon?: string | undefined | null,
	tue?: string | undefined | null,
	wed?: string | undefined | null,
	thu?: string | undefined | null,
	fri?: string | undefined | null,
	sat?: string | undefined | null,
	sun?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WorkspaceCreateWorkingHoursEntityRelationInput"]: {
	connect?: ValueTypes["Contember_WeekHoursUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_WeekHoursCreateInput"] | undefined | null
};
	["Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FreshingContainerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null
};
	["Contember_WorkspaceCreateCreditsProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductCreateInput"] | undefined | null
};
	["Contember_VirtualProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	product?: ValueTypes["Contember_VirtualProductCreateProductEntityRelationInput"] | undefined | null,
	voucherCreditCents?: number | undefined | null,
	physicalRepresentation?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	recipientEmail?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VirtualProductCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutVirtualProductCreateInput"] | undefined | null
};
	["Contember_ProductWithoutVirtualProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StocksManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StocksManagerWithoutManagedProductsCreateInput"] | undefined | null
};
	["Contember_StocksManagerWithoutManagedProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_StocksManagerCreateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StocksManagerCreateStaffEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutStocksManagerCreateInput"] | undefined | null
};
	["Contember_StaffWithoutStocksManagerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateBartenderEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BartenderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BartenderWithoutStaffCreateInput"] | undefined | null
};
	["Contember_BartenderWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateCourierEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CourierWithoutStaffCreateInput"] | undefined | null
};
	["Contember_CourierWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	routes?: Array<ValueTypes["Contember_CourierCreateRoutesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_CourierCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	showInPicker?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CourierCreateRoutesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRouteWithoutCourierCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryRouteWithoutCourierCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	waypoints?: Array<ValueTypes["Contember_DeliveryRouteCreateWaypointsEntityRelationInput"]> | undefined | null,
	dispatchAt?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRouteCreateWaypointsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryWaypointWithoutRouteCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryWaypointWithoutRouteCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	estimatedArrival?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	sortingOrder?: number | undefined | null,
	delivery?: ValueTypes["Contember_DeliveryWaypointCreateDeliveryEntityRelationInput"] | undefined | null,
	estimatedDistance?: number | undefined | null,
	estimatedDuration?: number | undefined | null,
	originDeparture?: ValueTypes["DateTime"] | undefined | null,
	originAddress?: string | undefined | null,
	transitMode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryWaypointCreateDeliveryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDeliveryCreateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	events?: Array<ValueTypes["Contember_OrderDeliveryCreateEventsEntityRelationInput"]> | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["Contember_OrderDeliveryCreateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryCreateMethodEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CourierWithoutDeliveryMethodsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CourierWithoutDeliveryMethodsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_CourierCreateStaffEntityRelationInput"] | undefined | null,
	routes?: Array<ValueTypes["Contember_CourierCreateRoutesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	showInPicker?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CourierCreateStaffEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutCourierCreateInput"] | undefined | null
};
	["Contember_StaffWithoutCourierCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateCleanerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CleanerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CleanerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_CleanerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	canReachTopShelf?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateShiftsManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_ShiftsManagerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	managedRoles?: Array<ValueTypes["Contember_ShiftsManagerCreateManagedRolesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsManagerCreateManagedRolesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutTypeCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftGroupWithoutTypeCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftWithoutGroupCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftWithoutGroupCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"] | undefined | null,
	queueItem?: ValueTypes["Contember_TimetableShiftCreateQueueItemEntityRelationInput"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutShiftsCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileWithoutShiftsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined | null,
	staff?: ValueTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueWithoutItemsCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueWithoutItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroup?: ValueTypes["Contember_EmployeeQueueCreateShiftGroupEntityRelationInput"] | undefined | null,
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueCreateShiftGroupEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutQueueCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupWithoutQueueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupCreateDayEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableDayWithoutShiftGroupsCreateInput"] | undefined | null
};
	["Contember_TimetableDayWithoutShiftGroupsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	type?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	tipsCents?: number | undefined | null,
	notes?: Array<ValueTypes["Contember_TimetableDayCreateNotesEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_TimetableDayCreateVenueEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_TimetableDayCreateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayCreateNotesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableDayNoteWithoutDayCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableDayNoteWithoutDayCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	note?: string | undefined | null,
	role?: ValueTypes["Contember_TimetableDayNoteCreateRoleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayNoteCreateRoleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutRolesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ShiftsProfileWithoutRolesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined | null,
	staff?: ValueTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileCreateStaffEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutShiftsProfileCreateInput"] | undefined | null
};
	["Contember_StaffWithoutShiftsProfileCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	managedBusinesses?: Array<ValueTypes["Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutAccountManagerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCustomerWithoutAccountManagerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerCreateMembersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutMemberOfBusinessesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerWithoutMemberOfBusinessesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutOwnersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCustomerWithoutOwnersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"] | undefined | null
};
	["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"] | undefined | null
};
	["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateUserEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutStaffCreateInput"] | undefined | null
};
	["Contember_UserWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	customer?: ValueTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutUserCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutUserCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateCartsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CartCreateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["Contember_CartCreateChannelEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_CartCreateOrderEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutCartCreateInput"] | undefined | null
};
	["Contember_OrderWithoutCartCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutOrdersCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutOrdersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateShortcutListEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ApplicationShortcutListWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_ApplicationShortcutListWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ApplicationShortcutListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ApplicationShortcutListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ApplicationShortcutListItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ApplicationShortcutListItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	application?: ValueTypes["Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ApplicationUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ApplicationCreateInput"] | undefined | null
};
	["Contember_ApplicationUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	handle?: string | undefined | null,
	icon?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null
};
	["Contember_ApplicationCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	handle?: string | undefined | null,
	url?: string | undefined | null,
	color?: string | undefined | null,
	icon?: ValueTypes["Contember_ApplicationCreateIconEntityRelationInput"] | undefined | null,
	name?: string | undefined | null,
	short?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ApplicationCreateIconEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_CustomerCreateUserEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_UserWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserCreateStaffEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutUserCreateInput"] | undefined | null
};
	["Contember_StaffWithoutUserCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateShiftsProfileEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutStaffCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileCreateRolesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsManagerWithoutManagedRolesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ShiftsManagerWithoutManagedRolesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_ShiftsManagerCreateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsManagerCreateStaffEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutShiftsManagerCreateInput"] | undefined | null
};
	["Contember_StaffWithoutShiftsManagerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateStocksManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StocksManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StocksManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StocksManagerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	managedProducts?: Array<ValueTypes["Contember_StocksManagerCreateManagedProductsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StocksManagerCreateManagedProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutManagerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductWithoutManagerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateOrderItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutProductCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderItemWithoutProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderItemCreateOrderEntityRelationInput"] | undefined | null,
	virtualProductEffects?: Array<ValueTypes["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]> | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderItemCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutItemsCreateInput"] | undefined | null
};
	["Contember_OrderWithoutItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateDiscountsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderDiscountWithoutOrderCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderDiscountWithoutOrderCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	discountCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDiscountCreateVatRateEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	type?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	customerCreditTransactions?: Array<ValueTypes["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"]> | undefined | null,
	voucherRedemption?: ValueTypes["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDiscountCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	amountCents?: number | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditWithoutTransactionsCreateInput"] | undefined | null
};
	["Contember_CustomerCreditWithoutTransactionsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	initialCreditCents?: number | undefined | null,
	remainingCreditsCents?: number | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	reason?: string | undefined | null,
	customer?: ValueTypes["Contember_CustomerCreditCreateCustomerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_CustomerCreditCreateVatRateEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"] | undefined | null,
	invoiceUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutCreditsCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutCreditsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateChannelsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutCustomersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelWithoutCustomersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelCreatePublicTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutPublicInChannelsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagWithoutPublicInChannelsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateWithoutChannelPaymentsCreateInput"] | undefined | null
};
	["Contember_VatRateWithoutChannelPaymentsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	ratePermille?: number | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	channelDeliveries?: Array<ValueTypes["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"]> | undefined | null,
	preset?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreateFulfillmentNotesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutTagsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_FulfillmentNoteWithoutTagsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutFulfillmentNotesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductWithoutFulfillmentNotesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCreateDeliveryZonesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]> | undefined | null,
	suitableDeliveryMethods?: Array<ValueTypes["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfTags?: Array<ValueTypes["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutDeliveryZonesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagWithoutDeliveryZonesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagCreatePublicInChannelsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutPublicTagsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelWithoutPublicTagsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_VenueWithoutChannelsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateDeadlineTemplateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeadlineTemplateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeadlineTemplateWithoutVenueCreateInput"] | undefined | null
};
	["Contember_DeadlineTemplateWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	cutoff?: number | undefined | null,
	closed?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateTimetableDaysEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableDayWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableDayWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	type?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableDayCreateShiftGroupsEntityRelationInput"]> | undefined | null,
	tipsCents?: number | undefined | null,
	notes?: Array<ValueTypes["Contember_TimetableDayCreateNotesEntityRelationInput"]> | undefined | null,
	meta?: ValueTypes["Contember_TimetableDayCreateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayCreateShiftGroupsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutDayCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftGroupWithoutDayCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	position?: ValueTypes["Contember_TimetableTemplateCreatePositionEntityRelationInput"] | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	venue?: ValueTypes["Contember_TimetableTemplateCreateVenueEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateCreatePositionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"] | undefined | null
};
	["Contember_TimetableShiftPositionUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	timetableTemplates?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput"]> | undefined | null,
	isDynamic?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutPositionCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftGroupWithoutPositionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueWithoutShiftGroupCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueWithoutShiftGroupCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_EmployeeQueueCreateItemsEntityRelationInput"]> | undefined | null,
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutQueueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_EmployeeQueueItemWithoutQueueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	shiftsProfile?: ValueTypes["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutQueueItemsCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileWithoutQueueItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftWithoutShiftsProfileCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftWithoutShiftsProfileCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	group?: ValueTypes["Contember_TimetableShiftCreateGroupEntityRelationInput"] | undefined | null,
	queueItem?: ValueTypes["Contember_TimetableShiftCreateQueueItemEntityRelationInput"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftCreateGroupEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutShiftsCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupWithoutShiftsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"] | undefined | null
};
	["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	isDynamic?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateWithoutPositionCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableTemplateWithoutPositionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	venue?: ValueTypes["Contember_TimetableTemplateCreateVenueEntityRelationInput"] | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	startTime?: string | undefined | null,
	endTime?: string | undefined | null,
	autoAssignedEmployee?: ValueTypes["Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined | null,
	staff?: ValueTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutTimetableTemplatesCreateInput"] | undefined | null
};
	["Contember_VenueWithoutTimetableTemplatesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateStaffMembersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutVenuesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_StaffWithoutVenuesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreatePhotoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_StaffCreateCourierManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CourierManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CourierManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_CourierManagerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreatePickerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PickerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PickerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_PickerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateRemunerationProfileEntityRelationInput"]: {
	connect?: ValueTypes["Contember_RemunerationProfileUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_RemunerationProfileWithoutStaffCreateInput"] | undefined | null
};
	["Contember_RemunerationProfileWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	employmentInformation?: Array<ValueTypes["Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput"]> | undefined | null,
	workMonths?: ValueTypes["Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	hourlyRate?: number | undefined | null,
	monthlySalary?: number | undefined | null,
	type?: ValueTypes["Contember_EmploymentType"] | undefined | null,
	name?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_WorkMonthUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_WorkMonthWithoutRemunerationProfileCreateInput"] | undefined | null
};
	["Contember_WorkMonthUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_RemunerationProfileUniqueWhere"] | undefined | null
};
	["Contember_WorkMonthWithoutRemunerationProfileCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	year?: number | undefined | null,
	month?: number | undefined | null,
	hoursWorked?: number | undefined | null,
	billableHours?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateCashierEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CashierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CashierWithoutStaffCreateInput"] | undefined | null
};
	["Contember_CashierWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateInternalBuyerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InternalBuyerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_InternalBuyerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_InternalBuyerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateSalesManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SalesManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SalesManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_SalesManagerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	countries?: Array<ValueTypes["Contember_SalesManagerCreateCountriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SalesManagerCreateCountriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_StaffCreateCashierManagerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CashierManagerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CashierManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_CashierManagerWithoutStaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateEmployeeRolesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueEmployeeRoleWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueEmployeeRoleWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	employeePrivileges?: ValueTypes["Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput"] | undefined | null,
	role?: ValueTypes["Contember_VenueEmployeeRoleCreateRoleEntityRelationInput"] | undefined | null,
	getsTipsShare?: boolean | undefined | null,
	notesEnabled?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"] | undefined | null
};
	["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	read?: boolean | undefined | null,
	unassign?: boolean | undefined | null,
	assign?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueEmployeeRoleCreateRoleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined | null
};
	["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	attempts?: Array<ValueTypes["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_IdentificationCodeCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationAttemptWithoutCodeCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IdentificationAttemptWithoutCodeCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	user?: ValueTypes["Contember_IdentificationAttemptCreateUserEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationAttemptCreateUserEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutIdentificationAttemptsCreateInput"] | undefined | null
};
	["Contember_UserWithoutIdentificationAttemptsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined | null,
	roles?: ValueTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserCreateDevicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UserDeviceWithoutUserCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_UserDeviceWithoutUserCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	deviceId?: string | undefined | null,
	notificationsEnabled?: boolean | undefined | null,
	appVersion?: string | undefined | null,
	osVersion?: string | undefined | null,
	apiVersion?: string | undefined | null,
	anonymousSession?: ValueTypes["Contember_UserDeviceCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserDeviceCreateAnonymousSessionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AnonymousSessionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AnonymousSessionWithoutDevicesCreateInput"] | undefined | null
};
	["Contember_AnonymousSessionWithoutDevicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	customer?: ValueTypes["Contember_AnonymousSessionCreateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AnonymousSessionCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutAnonymousSessionCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutAnonymousSessionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateInvoicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_InvoiceWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	orderPayments?: Array<ValueTypes["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]> | undefined | null,
	address?: ValueTypes["Contember_InvoiceCreateAddressEntityRelationInput"] | undefined | null,
	publicUrl?: string | undefined | null,
	billingSubject?: ValueTypes["Contember_InvoiceCreateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderPaymentWithoutInvoiceCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderPaymentWithoutInvoiceCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	data?: ValueTypes["Json"] | undefined | null,
	paymentCents?: number | undefined | null,
	method?: ValueTypes["Contember_OrderPaymentCreateMethodEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	approvedAt?: ValueTypes["DateTime"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderPaymentCreateEventsEntityRelationInput"]> | undefined | null,
	order?: ValueTypes["Contember_OrderPaymentCreateOrderEntityRelationInput"] | undefined | null,
	walletType?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicReceiptUrl?: string | undefined | null,
	priceCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderPaymentCreateVatRateEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentCreateMetaEntityRelationInput"] | undefined | null,
	otherCurrencyPriceCents?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderPaymentCreateMethodEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodCreateInput"] | undefined | null
};
	["Contember_OrderPaymentCreateEventsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderEventWithoutPaymentCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderEventWithoutPaymentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: string | undefined | null,
	data?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_OrderEventCreateOrderEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderEventCreateDeliveryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderEventCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutEventsCreateInput"] | undefined | null
};
	["Contember_OrderWithoutEventsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateDeliveryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutOrderCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryWithoutOrderCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderDeliveryCreateEventsEntityRelationInput"]> | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["Contember_OrderDeliveryCreateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryWaypointWithoutDeliveryCreateInput"] | undefined | null
};
	["Contember_DeliveryWaypointWithoutDeliveryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	estimatedArrival?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	route?: ValueTypes["Contember_DeliveryWaypointCreateRouteEntityRelationInput"] | undefined | null,
	sortingOrder?: number | undefined | null,
	estimatedDistance?: number | undefined | null,
	estimatedDuration?: number | undefined | null,
	originDeparture?: ValueTypes["DateTime"] | undefined | null,
	originAddress?: string | undefined | null,
	transitMode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryWaypointCreateRouteEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRouteWithoutWaypointsCreateInput"] | undefined | null
};
	["Contember_DeliveryRouteWithoutWaypointsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	courier?: ValueTypes["Contember_DeliveryRouteCreateCourierEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	dispatchAt?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRouteCreateCourierEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CourierWithoutRoutesCreateInput"] | undefined | null
};
	["Contember_CourierWithoutRoutesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	staff?: ValueTypes["Contember_CourierCreateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_CourierCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	showInPicker?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CourierCreateDeliveryMethodsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	trackingCodeUrlTemplate?: string | undefined | null,
	processingTitle?: string | undefined | null,
	processingText?: string | undefined | null,
	processingIconUrl?: string | undefined | null,
	dispatchedTitle?: string | undefined | null,
	dispatchedText?: string | undefined | null,
	dispatchedIconUrl?: string | undefined | null,
	fulfilledTitle?: string | undefined | null,
	fulfilledText?: string | undefined | null,
	fulfilledIconUrl?: string | undefined | null,
	locale?: ValueTypes["Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutDeliveryMethodsCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutDeliveryMethodsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateProductPackingsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductPackingLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	shortName?: string | undefined | null,
	description?: string | undefined | null,
	root?: ValueTypes["Contember_ProductPackingLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductPackingUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_ProductPackingWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	image?: ValueTypes["Contember_ProductPackingCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	volumeMl?: number | undefined | null,
	icon?: ValueTypes["Contember_ProductPackingCreateIconEntityRelationInput"] | undefined | null,
	shortName?: string | undefined | null,
	preparedPackingQuantities?: Array<ValueTypes["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"]> | undefined | null,
	isPackedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PreparedPackingQuantityWithoutPackingCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PreparedPackingQuantityWithoutPackingCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	recipe?: ValueTypes["Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput"] | undefined | null,
	packedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeCreateInput"] | undefined | null
};
	["Contember_ProductRecipeCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductRecipeCreateProductsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductRecipeCreateImageEntityRelationInput"] | undefined | null,
	icon?: ValueTypes["Contember_ProductRecipeCreateIconEntityRelationInput"] | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductRecipeCreateLocalesEntityRelationInput"]> | undefined | null,
	gutTuning?: number | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	nutritionFacts?: ValueTypes["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutRecipeCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductWithoutRecipeCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	featureList?: ValueTypes["Contember_ProductLocaleCreateFeatureListEntityRelationInput"] | undefined | null,
	printableName?: string | undefined | null,
	firstSticker?: string | undefined | null,
	secondSticker?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductsCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateProductRecipesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductRecipeLocaleCreateRootEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_ProductRecipeWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductRecipeCreateProductsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductRecipeCreateImageEntityRelationInput"] | undefined | null,
	icon?: ValueTypes["Contember_ProductRecipeCreateIconEntityRelationInput"] | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	gutTuning?: number | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	nutritionFacts?: ValueTypes["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FreshingContainerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null
};
	["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_NutritionFactsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_NutritionFactsCreateInput"] | undefined | null
};
	["Contember_NutritionFactsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	energyKiloJoule?: number | undefined | null,
	energyKiloCalorie?: number | undefined | null,
	fatsGrams?: number | undefined | null,
	fatsOfWhichSaturatedGrams?: number | undefined | null,
	carbohydrateGrams?: number | undefined | null,
	carbohydrateOfWhichSugarsGrams?: number | undefined | null,
	proteinGrams?: number | undefined | null,
	fibreGrams?: number | undefined | null,
	saltGrams?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	slug?: string | undefined | null,
	products?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	product?: ValueTypes["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"] | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_ProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateCustomerPricesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCreateVirtualProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductWithoutProductCreateInput"] | undefined | null
};
	["Contember_VirtualProductWithoutProductCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	voucherCreditCents?: number | undefined | null,
	physicalRepresentation?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	recipientEmail?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductMetadataUniqueWhere"] | undefined | null
};
	["Contember_ProductCreatePageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PageCreateInput"] | undefined | null
};
	["Contember_PageCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locales?: Array<ValueTypes["Contember_PageCreateLocalesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PageLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PageLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	link?: ValueTypes["Contember_PageLocaleCreateLinkEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_PageLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_PageLocaleCreateSeoEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_PageLocaleCreateContentEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_PageLocaleCreateCoverEntityRelationInput"] | undefined | null,
	theme?: ValueTypes["Contember_PageTheme"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleCreateSeoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SeoCreateInput"] | undefined | null
};
	["Contember_SeoCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	description?: string | undefined | null,
	ogTitle?: string | undefined | null,
	ogDescription?: string | undefined | null,
	ogImage?: ValueTypes["Contember_SeoCreateOgImageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeoCreateOgImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_PageLocaleCreateContentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null
};
	["Contember_PageLocaleCreateCoverEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CoverCreateInput"] | undefined | null
};
	["Contember_ProductCreateSalesDaysEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductSalesDayUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCreateSalesWeeksEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCreateSalesMonthsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutProductsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteWithoutProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	note?: string | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutFulfillmentNotesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagWithoutFulfillmentNotesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutFulfillmentNotesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueWithoutFulfillmentNotesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableTemplateWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	position?: ValueTypes["Contember_TimetableTemplateCreatePositionEntityRelationInput"] | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleTerminalWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleTerminalWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	applicationDevices?: Array<ValueTypes["Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput"]> | undefined | null,
	ipAddress?: string | undefined | null,
	port?: number | undefined | null,
	type?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"] | undefined | null
};
	["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSalePrinterWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesReceipt?: Array<ValueTypes["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]> | undefined | null,
	applicationDevicesTicket?: Array<ValueTypes["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]> | undefined | null,
	ticketCategories?: Array<ValueTypes["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeviceIdentifierWithoutDeviceCreateInput"] | undefined | null
};
	["Contember_DeviceIdentifierWithoutDeviceCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	resolution?: string | undefined | null,
	devicePixelRatio?: number | undefined | null,
	updatedByUser?: ValueTypes["Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput"] | undefined | null,
	identifier?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UserCreateInput"] | undefined | null
};
	["Contember_UserCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserMetadataUniqueWhere"] | undefined | null
};
	["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationAttemptWithoutUserCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IdentificationAttemptWithoutUserCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	code?: ValueTypes["Contember_IdentificationAttemptCreateCodeEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationAttemptCreateCodeEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeWithoutAttemptsCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeWithoutAttemptsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	locales?: Array<ValueTypes["Contember_IdentificationCodeCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	applicationDevices?: Array<ValueTypes["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IdentificationCodeLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	successMessage?: string | undefined | null,
	locale?: ValueTypes["Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutIdentificationCodesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutIdentificationCodesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	root?: ValueTypes["Contember_ProductLocaleCreateRootEntityRelationInput"] | undefined | null,
	featureList?: ValueTypes["Contember_ProductLocaleCreateFeatureListEntityRelationInput"] | undefined | null,
	printableName?: string | undefined | null,
	firstSticker?: string | undefined | null,
	secondSticker?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_ProductWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductLocaleCreateFeatureListEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput"] | undefined | null,
	product?: ValueTypes["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutProductsCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	slug?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	locale?: ValueTypes["Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	description?: string | undefined | null,
	trackingCodeUrlTemplate?: string | undefined | null,
	processingTitle?: string | undefined | null,
	processingText?: string | undefined | null,
	processingIconUrl?: string | undefined | null,
	dispatchedTitle?: string | undefined | null,
	dispatchedText?: string | undefined | null,
	dispatchedIconUrl?: string | undefined | null,
	fulfilledTitle?: string | undefined | null,
	fulfilledText?: string | undefined | null,
	fulfilledIconUrl?: string | undefined | null,
	root?: ValueTypes["Contember_DeliveryMethodLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryTimelinePresetUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"] | undefined | null
};
	["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_IngredientSupplierLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IngredientSupplierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_IngredientSupplierWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	successMessage?: string | undefined | null,
	root?: ValueTypes["Contember_IdentificationCodeLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	attempts?: Array<ValueTypes["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	applicationDevices?: Array<ValueTypes["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"] | undefined | null
};
	["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleTerminalCreateVenueEntityRelationInput"] | undefined | null,
	ipAddress?: string | undefined | null,
	port?: number | undefined | null,
	type?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleTerminalCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"] | undefined | null
};
	["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateOrdersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutOrderCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderItemWithoutOrderCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffects?: Array<ValueTypes["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]> | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	product?: ValueTypes["Contember_OrderItemCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductEffectWithoutOrderItemCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VirtualProductEffectWithoutOrderItemCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	succeededAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	revertedAt?: ValueTypes["DateTime"] | undefined | null,
	resultCustomerCreditTransaction?: ValueTypes["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"] | undefined | null,
	resultVouchers?: Array<ValueTypes["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"] | undefined | null
};
	["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	amountCents?: number | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"] | undefined | null
};
	["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	discountCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDiscountCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDiscountCreateOrderEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	type?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDiscountCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutDiscountsCreateInput"] | undefined | null
};
	["Contember_OrderWithoutDiscountsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateCartEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutOrderCreateInput"] | undefined | null
};
	["Contember_CartWithoutOrderCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CartCreateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["Contember_CartCreateChannelEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CartCreateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutCartsCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutCartsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateInvoicingPlanEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InvoicingPlanUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_InvoicingPlanWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_InvoicingPlanWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	preset?: ValueTypes["Contember_InvoicingPlanCreatePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoicingPlanCreatePresetEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InvoicingPresetUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"] | undefined | null
};
	["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null,
	startsAt?: ValueTypes["DateTime"] | undefined | null,
	endsAt?: ValueTypes["DateTime"] | undefined | null,
	intervalOfDays?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BillingSubjectWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectCreateCountryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null
};
	["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutBillingSubjectCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_InvoiceWithoutBillingSubjectCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	customer?: ValueTypes["Contember_InvoiceCreateCustomerEntityRelationInput"] | undefined | null,
	orderPayments?: Array<ValueTypes["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]> | undefined | null,
	address?: ValueTypes["Contember_InvoiceCreateAddressEntityRelationInput"] | undefined | null,
	publicUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceCreateAddressEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutInvoicesCreateInput"] | undefined | null
};
	["Contember_AddressWithoutInvoicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutBillingAddressCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderWithoutBillingAddressCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateEventsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderEventWithoutOrderCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderEventWithoutOrderCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: string | undefined | null,
	data?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	payment?: ValueTypes["Contember_OrderEventCreatePaymentEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderEventCreateDeliveryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderEventCreatePaymentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderPaymentWithoutEventsCreateInput"] | undefined | null
};
	["Contember_OrderPaymentWithoutEventsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	data?: ValueTypes["Json"] | undefined | null,
	paymentCents?: number | undefined | null,
	method?: ValueTypes["Contember_OrderPaymentCreateMethodEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	approvedAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_OrderPaymentCreateOrderEntityRelationInput"] | undefined | null,
	invoice?: ValueTypes["Contember_OrderPaymentCreateInvoiceEntityRelationInput"] | undefined | null,
	walletType?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicReceiptUrl?: string | undefined | null,
	priceCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderPaymentCreateVatRateEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentCreateMetaEntityRelationInput"] | undefined | null,
	otherCurrencyPriceCents?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderPaymentCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutPaymentsCreateInput"] | undefined | null
};
	["Contember_OrderWithoutPaymentsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateBillingAddressEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined | null
};
	["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutAddressCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderDeliveryWithoutAddressCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDeliveryCreateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderDeliveryCreateEventsEntityRelationInput"]> | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryCreateOrderEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutDeliveryCreateInput"] | undefined | null
};
	["Contember_OrderWithoutDeliveryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreatePriceLinesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderCreatePaymentsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderPaymentWithoutOrderCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderPaymentWithoutOrderCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	data?: ValueTypes["Json"] | undefined | null,
	paymentCents?: number | undefined | null,
	method?: ValueTypes["Contember_OrderPaymentCreateMethodEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	approvedAt?: ValueTypes["DateTime"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderPaymentCreateEventsEntityRelationInput"]> | undefined | null,
	invoice?: ValueTypes["Contember_OrderPaymentCreateInvoiceEntityRelationInput"] | undefined | null,
	walletType?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicReceiptUrl?: string | undefined | null,
	priceCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderPaymentCreateVatRateEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentCreateMetaEntityRelationInput"] | undefined | null,
	otherCurrencyPriceCents?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderPaymentCreateInvoiceEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutOrderPaymentsCreateInput"] | undefined | null
};
	["Contember_InvoiceWithoutOrderPaymentsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	customer?: ValueTypes["Contember_InvoiceCreateCustomerEntityRelationInput"] | undefined | null,
	address?: ValueTypes["Contember_InvoiceCreateAddressEntityRelationInput"] | undefined | null,
	publicUrl?: string | undefined | null,
	billingSubject?: ValueTypes["Contember_InvoiceCreateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceCreateBillingSubjectEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutInvoicesCreateInput"] | undefined | null
};
	["Contember_BillingSubjectWithoutInvoicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutBillingSubjectsCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutBillingSubjectsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateOfferedProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined | null
};
	["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"] | undefined | null
};
	["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectCreateOrdersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutBillingSubjectCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderWithoutBillingSubjectCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateSummaryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderSummaryUniqueWhere"] | undefined | null
};
	["Contember_OrderCreateGratuityEntityRelationInput"]: {
	connect?: ValueTypes["Contember_GratuityUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_GratuityWithoutOrderCreateInput"] | undefined | null
};
	["Contember_GratuityWithoutOrderCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	amountCents?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutOrdersCreateInput"] | undefined | null
};
	["Contember_VenueWithoutOrdersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutVenuesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteWithoutVenuesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CartItemWithoutFulfillmentNotesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartItemWithoutFulfillmentNotesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	quantity?: number | undefined | null,
	cart?: ValueTypes["Contember_CartItemCreateCartEntityRelationInput"] | undefined | null,
	product?: ValueTypes["Contember_CartItemCreateProductEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutFulfillmentNotesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderItemWithoutFulfillmentNotesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderItemCreateOrderEntityRelationInput"] | undefined | null,
	virtualProductEffects?: Array<ValueTypes["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]> | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	product?: ValueTypes["Contember_OrderItemCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutOrderItemsCreateInput"] | undefined | null
};
	["Contember_ProductWithoutOrderItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutFulfillmentNotesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderWithoutFulfillmentNotesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateBillingSubjectEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutOrdersCreateInput"] | undefined | null
};
	["Contember_BillingSubjectWithoutOrdersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateAnonymousSessionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AnonymousSessionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AnonymousSessionWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_AnonymousSessionWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	devices?: Array<ValueTypes["Contember_AnonymousSessionCreateDevicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AnonymousSessionCreateDevicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UserDeviceWithoutAnonymousSessionCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_UserDeviceWithoutAnonymousSessionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	deviceId?: string | undefined | null,
	notificationsEnabled?: boolean | undefined | null,
	user?: ValueTypes["Contember_UserDeviceCreateUserEntityRelationInput"] | undefined | null,
	appVersion?: string | undefined | null,
	osVersion?: string | undefined | null,
	apiVersion?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserDeviceCreateUserEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutDevicesCreateInput"] | undefined | null
};
	["Contember_UserWithoutDevicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserCreateRolesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserRolesUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutBuyerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VoucherWithoutBuyerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	owner?: ValueTypes["Contember_VoucherCreateOwnerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherCreateRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherCreateOwnerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutOwnsVouchersCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutOwnsVouchersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VoucherRedemptionWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	customerCredit?: ValueTypes["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"] | undefined | null
};
	["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	initialCreditCents?: number | undefined | null,
	remainingCreditsCents?: number | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	reason?: string | undefined | null,
	customer?: ValueTypes["Contember_CustomerCreditCreateCustomerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_CustomerCreditCreateVatRateEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	transactions?: Array<ValueTypes["Contember_CustomerCreditCreateTransactionsEntityRelationInput"]> | undefined | null,
	invoiceUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditCreateTransactionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	amountCents?: number | undefined | null,
	orderDiscount?: ValueTypes["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"] | undefined | null
};
	["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	succeededAt?: ValueTypes["DateTime"] | undefined | null,
	orderItem?: ValueTypes["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	revertedAt?: ValueTypes["DateTime"] | undefined | null,
	resultVouchers?: Array<ValueTypes["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"] | undefined | null
};
	["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderItemCreateOrderEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	product?: ValueTypes["Contember_OrderItemCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutVirtualProductEffectCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VoucherWithoutVirtualProductEffectCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	buyer?: ValueTypes["Contember_VoucherCreateBuyerEntityRelationInput"] | undefined | null,
	owner?: ValueTypes["Contember_VoucherCreateOwnerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherCreateRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherCreateBuyerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutBoughtVouchersCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutBoughtVouchersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutOwnerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VoucherWithoutOwnerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	buyer?: ValueTypes["Contember_VoucherCreateBuyerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherCreateRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"] | undefined | null
};
	["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	succeededAt?: ValueTypes["DateTime"] | undefined | null,
	orderItem?: ValueTypes["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	revertedAt?: ValueTypes["DateTime"] | undefined | null,
	resultCustomerCreditTransaction?: ValueTypes["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherCreateRedemptionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutVoucherCreateInput"] | undefined | null
};
	["Contember_VoucherRedemptionWithoutVoucherCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	customerCredit?: ValueTypes["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"] | undefined | null
};
	["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	discountCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDiscountCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDiscountCreateOrderEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	type?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	customerCreditTransactions?: Array<ValueTypes["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutRedeemedVouchersCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutRedeemedVouchersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateSpendingEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerSpendingUniqueWhere"] | undefined | null
};
	["Contember_CustomerCreateCustomerTabsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerTabWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	color?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CustomerTabCreateItemsEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabCreateMetaEntityRelationInput"] | undefined | null,
	table?: ValueTypes["Contember_CustomerTabCreateTableEntityRelationInput"] | undefined | null,
	seat?: ValueTypes["Contember_CustomerTabCreateSeatEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerTabCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabItemWithoutTabCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerTabItemWithoutTabCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addedAt?: ValueTypes["DateTime"] | undefined | null,
	product?: ValueTypes["Contember_CustomerTabItemCreateProductEntityRelationInput"] | undefined | null,
	paidAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerTabItemCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_CustomerTabCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined | null
};
	["Contember_CustomerTabCreateTableEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TableWithoutCustomerTabsCreateInput"] | undefined | null
};
	["Contember_TableWithoutCustomerTabsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shape?: ValueTypes["Contember_TableShape"] | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	area?: ValueTypes["Contember_TableCreateAreaEntityRelationInput"] | undefined | null,
	seats?: Array<ValueTypes["Contember_TableCreateSeatsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TableCreateAreaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AreaWithoutTablesCreateInput"] | undefined | null
};
	["Contember_AreaWithoutTablesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	name?: string | undefined | null,
	venue?: ValueTypes["Contember_AreaCreateVenueEntityRelationInput"] | undefined | null,
	lines?: Array<ValueTypes["Contember_AreaCreateLinesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AreaCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutAreasCreateInput"] | undefined | null
};
	["Contember_VenueWithoutAreasCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UnassignReasonWithoutVenuesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_UnassignReasonUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_UnassignReasonWithoutVenuesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	name?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	slug?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesReceipt?: Array<ValueTypes["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]> | undefined | null,
	applicationDevicesTicket?: Array<ValueTypes["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesTicket?: Array<ValueTypes["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"] | undefined | null,
	ticketCategories?: Array<ValueTypes["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutPointOfSalePrintersCreateInput"] | undefined | null
};
	["Contember_VenueWithoutPointOfSalePrintersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateAreasEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AreaWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AreaWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	name?: string | undefined | null,
	tables?: Array<ValueTypes["Contember_AreaCreateTablesEntityRelationInput"]> | undefined | null,
	lines?: Array<ValueTypes["Contember_AreaCreateLinesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AreaCreateTablesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TableWithoutAreaCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TableWithoutAreaCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shape?: ValueTypes["Contember_TableShape"] | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_TableCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	seats?: Array<ValueTypes["Contember_TableCreateSeatsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TableCreateCustomerTabsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabWithoutTableCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerTabWithoutTableCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	color?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CustomerTabCreateItemsEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabCreateMetaEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerTabCreateCustomerEntityRelationInput"] | undefined | null,
	seat?: ValueTypes["Contember_CustomerTabCreateSeatEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerTabCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutCustomerTabsCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutCustomerTabsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateLeadEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineLeadWithoutCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineLeadWithoutCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	stageItem?: ValueTypes["Contember_PipelineLeadCreateStageItemEntityRelationInput"] | undefined | null,
	contactPerson?: string | undefined | null,
	cafeType?: string | undefined | null,
	offerType?: string | undefined | null,
	ownMachine?: boolean | undefined | null,
	customerCity?: string | undefined | null,
	companyId?: string | undefined | null,
	customerName?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	form?: ValueTypes["Contember_PipelineLeadCreateFormEntityRelationInput"] | undefined | null,
	meta?: string | undefined | null,
	multiroasterType?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineLeadCreateStageItemEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageItemWithoutLeadCreateInput"] | undefined | null
};
	["Contember_PipelineStageItemWithoutLeadCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	list?: ValueTypes["Contember_PipelineStageItemCreateListEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageItemCreateListEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageWithoutItemsCreateInput"] | undefined | null
};
	["Contember_PipelineStageWithoutItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	pipeline?: ValueTypes["Contember_PipelineStageCreatePipelineEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	locales?: Array<ValueTypes["Contember_PipelineStageCreateLocalesEntityRelationInput"]> | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageCreateSentimentEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	forms?: Array<ValueTypes["Contember_PipelineStageCreateFormsEntityRelationInput"]> | undefined | null,
	showItemsWeight?: boolean | undefined | null,
	showItemsPrice?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageCreatePipelineEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineWithoutStagesCreateInput"] | undefined | null
};
	["Contember_PipelineUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	stages?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null
};
	["Contember_PipelineWithoutStagesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineStageLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_PipelineStageLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPipelineStagesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutPipelineStagesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	stickerText?: string | undefined | null,
	descriptionText?: string | undefined | null,
	root?: ValueTypes["Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput"] | undefined | null,
	secondaryTitle?: string | undefined | null,
	texts?: ValueTypes["Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput"] | undefined | null,
	secondaryTexts?: ValueTypes["Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SubscriptionBoxWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_SubscriptionBoxWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_LocaleCreateBusinessServicesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	labelNote?: string | undefined | null,
	root?: ValueTypes["Contember_BusinessServiceLocaleCreateRootEntityRelationInput"] | undefined | null,
	labelNumberOfPeople?: string | undefined | null,
	labelInvoiced?: string | undefined | null,
	labelEquipment?: string | undefined | null,
	labelMillTamDem?: string | undefined | null,
	labelSOEspresso?: string | undefined | null,
	labelFilterCoffeeInMenu?: string | undefined | null,
	labelWaterMeterStatus?: string | undefined | null,
	labelServiceAction?: string | undefined | null,
	labelInvoiceIdentifier?: string | undefined | null,
	labelSupervisor?: string | undefined | null,
	labelContact?: string | undefined | null,
	labelPhone?: string | undefined | null,
	labelEmail?: string | undefined | null,
	labelGrindingStonesCondition?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessServiceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_BusinessServiceWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	requireNote?: number | undefined | null,
	requireNumberOfPeople?: number | undefined | null,
	requireInvoiced?: number | undefined | null,
	requireEquipment?: number | undefined | null,
	requireMillTamDem?: number | undefined | null,
	requireSOEspresso?: number | undefined | null,
	requireFilterCoffeeInMenu?: number | undefined | null,
	requireWaterMeterStatus?: number | undefined | null,
	requireServiceAction?: number | undefined | null,
	optionsEquipment?: string | undefined | null,
	optionsServiceAction?: string | undefined | null,
	requireInvoiceIdentifier?: number | undefined | null,
	requireSupervisor?: number | undefined | null,
	requireContact?: number | undefined | null,
	requirePhone?: number | undefined | null,
	requireEmail?: number | undefined | null,
	requireGrindingStonesCondition?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageCreateSentimentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageSentimentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageSentimentCreateInput"] | undefined | null
};
	["Contember_PipelineStageSentimentUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_PipelineStageSentimentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	backgroundColor?: string | undefined | null,
	textColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageCreateFormsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LeadFormWithoutStageCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LeadFormWithoutStageCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	leads?: Array<ValueTypes["Contember_LeadFormCreateLeadsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LeadFormCreateLeadsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineLeadWithoutFormCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineLeadWithoutFormCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	stageItem?: ValueTypes["Contember_PipelineLeadCreateStageItemEntityRelationInput"] | undefined | null,
	contactPerson?: string | undefined | null,
	customer?: ValueTypes["Contember_PipelineLeadCreateCustomerEntityRelationInput"] | undefined | null,
	cafeType?: string | undefined | null,
	offerType?: string | undefined | null,
	ownMachine?: boolean | undefined | null,
	customerCity?: string | undefined | null,
	companyId?: string | undefined | null,
	customerName?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	meta?: string | undefined | null,
	multiroasterType?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineLeadCreateCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutLeadCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutLeadCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerMetadataUniqueWhere"] | undefined | null
};
	["Contember_PipelineLeadCreateFormEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LeadFormWithoutLeadsCreateInput"] | undefined | null
};
	["Contember_LeadFormWithoutLeadsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	code?: string | undefined | null,
	stage?: ValueTypes["Contember_LeadFormCreateStageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LeadFormCreateStageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageWithoutFormsCreateInput"] | undefined | null
};
	["Contember_PipelineStageWithoutFormsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_PipelineStageCreateItemsEntityRelationInput"]> | undefined | null,
	pipeline?: ValueTypes["Contember_PipelineStageCreatePipelineEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	locales?: Array<ValueTypes["Contember_PipelineStageCreateLocalesEntityRelationInput"]> | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageCreateSentimentEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	showItemsWeight?: boolean | undefined | null,
	showItemsPrice?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineStageItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	lead?: ValueTypes["Contember_PipelineStageItemCreateLeadEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageItemCreateLeadEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineLeadWithoutStageItemCreateInput"] | undefined | null
};
	["Contember_PipelineLeadWithoutStageItemCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	contactPerson?: string | undefined | null,
	customer?: ValueTypes["Contember_PipelineLeadCreateCustomerEntityRelationInput"] | undefined | null,
	cafeType?: string | undefined | null,
	offerType?: string | undefined | null,
	ownMachine?: boolean | undefined | null,
	customerCity?: string | undefined | null,
	companyId?: string | undefined | null,
	customerName?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	form?: ValueTypes["Contember_PipelineLeadCreateFormEntityRelationInput"] | undefined | null,
	meta?: string | undefined | null,
	multiroasterType?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerTabCreateSeatEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SeatWithoutCustomerTabsCreateInput"] | undefined | null
};
	["Contember_SeatWithoutCustomerTabsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	table?: ValueTypes["Contember_SeatCreateTableEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeatCreateTableEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TableWithoutSeatsCreateInput"] | undefined | null
};
	["Contember_TableWithoutSeatsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	shape?: ValueTypes["Contember_TableShape"] | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_TableCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	area?: ValueTypes["Contember_TableCreateAreaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TableCreateSeatsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SeatWithoutTableCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_SeatWithoutTableCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_SeatCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeatCreateCustomerTabsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabWithoutSeatCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerTabWithoutSeatCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	color?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CustomerTabCreateItemsEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabCreateMetaEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerTabCreateCustomerEntityRelationInput"] | undefined | null,
	table?: ValueTypes["Contember_CustomerTabCreateTableEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AreaCreateLinesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LineWithoutAreaCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LineWithoutAreaCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	positionStartX?: number | undefined | null,
	positionStartY?: number | undefined | null,
	positionEndX?: number | undefined | null,
	positionEndY?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueCreateChannelsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutVenueCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelWithoutVenueCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	slug?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutCategoriesCreateInput"] | undefined | null
};
	["Contember_VenueWithoutCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutRedemptionCreateInput"] | undefined | null
};
	["Contember_VoucherWithoutRedemptionCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	buyer?: ValueTypes["Contember_VoucherCreateBuyerEntityRelationInput"] | undefined | null,
	owner?: ValueTypes["Contember_VoucherCreateOwnerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutOrdersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteWithoutOrdersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderPaymentCreateVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderPaymentCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined | null
};
	["Contember_OrderDeliveryCreateEventsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderEventWithoutDeliveryCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderEventWithoutDeliveryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	type?: string | undefined | null,
	data?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_OrderEventCreateOrderEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_OrderEventCreatePaymentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LiftagoRideUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LiftagoRideWithoutDeliveryCreateInput"] | undefined | null
};
	["Contember_LiftagoRideWithoutDeliveryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	liftagoId?: string | undefined | null,
	data?: string | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"] | undefined | null
};
	["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderEventCreateDeliveryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutEventsCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryWithoutEventsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDeliveryCreateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"] | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["Contember_OrderDeliveryCreateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryCreateAddressEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined | null
};
	["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreateDeliveryAddressEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null
};
	["Contember_CartCreateBillingAddressEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null
};
	["Contember_CartCreateDeliveryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryCreateInput"] | undefined | null
};
	["Contember_ChannelDeliveryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelDeliveryCreateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelDeliveryCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreatePaymentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentCreateInput"] | undefined | null
};
	["Contember_ChannelPaymentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreateBillingSubjectEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectCreateInput"] | undefined | null
};
	["Contember_BillingSubjectCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartCreatePriceLinesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartPriceLineUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartCreateSummaryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartSummaryUniqueWhere"] | undefined | null
};
	["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"] | undefined | null
};
	["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	customerCredit?: ValueTypes["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesReceipt?: Array<ValueTypes["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"] | undefined | null,
	ticketCategories?: Array<ValueTypes["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleCreatePipelineStagesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageLocaleWithoutLocaleCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineStageLocaleWithoutLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	root?: ValueTypes["Contember_PipelineStageLocaleCreateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageLocaleCreateRootEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_PipelineStageWithoutLocalesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_PipelineStageCreateItemsEntityRelationInput"]> | undefined | null,
	pipeline?: ValueTypes["Contember_PipelineStageCreatePipelineEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageCreateSentimentEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	forms?: Array<ValueTypes["Contember_PipelineStageCreateFormsEntityRelationInput"]> | undefined | null,
	showItemsWeight?: boolean | undefined | null,
	showItemsPrice?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	locale?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductRecipeLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductRecipesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutProductRecipesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateCreateInput"] | undefined | null
};
	["Contember_TimetableTemplateCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	position?: ValueTypes["Contember_TimetableTemplateCreatePositionEntityRelationInput"] | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	venue?: ValueTypes["Contember_TimetableTemplateCreateVenueEntityRelationInput"] | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftCreateQueueItemEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemWithoutShiftCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined | null
};
	["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	hoursMissed?: number | undefined | null,
	legacyReason?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null,
	reason?: ValueTypes["Contember_UnassignedShiftInfoCreateReasonEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UnassignedShiftInfoCreateReasonEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_UnassignReasonCreateInput"] | undefined | null
};
	["Contember_UnassignReasonCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	name?: string | undefined | null,
	venues?: Array<ValueTypes["Contember_UnassignReasonCreateVenuesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UnassignReasonCreateVenuesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"] | undefined | null
};
	["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	requested?: boolean | undefined | null,
	confirmed?: boolean | undefined | null,
	swappedShiftsProfile?: ValueTypes["Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftWithoutQueueItemCreateInput"] | undefined | null
};
	["Contember_TimetableShiftWithoutQueueItemCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	group?: ValueTypes["Contember_TimetableShiftCreateGroupEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayCreateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined | null
};
	["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"] | undefined | null
};
	["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	orderDiscount?: ValueTypes["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffCreateVenuesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutStaffMembersCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueWithoutStaffMembersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerCreateLogoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	businessService?: ValueTypes["Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput"] | undefined | null,
	filledNumberOfPeople?: number | undefined | null,
	filledInvoiced?: boolean | undefined | null,
	filledEquipment?: string | undefined | null,
	filledMillTamDem?: boolean | undefined | null,
	filledSOEspresso?: boolean | undefined | null,
	filledFilterCoffeeInMenu?: boolean | undefined | null,
	filledWaterMeterStatus?: string | undefined | null,
	filledServiceAction?: string | undefined | null,
	filledInvoiceIdentifier?: string | undefined | null,
	filledNote?: string | undefined | null,
	filledSupervisor?: ValueTypes["Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput"] | undefined | null,
	filledContact?: string | undefined | null,
	filledPhone?: boolean | undefined | null,
	filledEmail?: boolean | undefined | null,
	filledGrindingStonesCondition?: string | undefined | null,
	createdBy?: ValueTypes["Contember_BusinessServiceEventCreateCreatedByEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessServiceUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceCreateInput"] | undefined | null
};
	["Contember_BusinessServiceCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	requireNote?: number | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessServiceCreateLocalesEntityRelationInput"]> | undefined | null,
	requireNumberOfPeople?: number | undefined | null,
	requireInvoiced?: number | undefined | null,
	requireEquipment?: number | undefined | null,
	requireMillTamDem?: number | undefined | null,
	requireSOEspresso?: number | undefined | null,
	requireFilterCoffeeInMenu?: number | undefined | null,
	requireWaterMeterStatus?: number | undefined | null,
	requireServiceAction?: number | undefined | null,
	optionsEquipment?: string | undefined | null,
	optionsServiceAction?: string | undefined | null,
	requireInvoiceIdentifier?: number | undefined | null,
	requireSupervisor?: number | undefined | null,
	requireContact?: number | undefined | null,
	requirePhone?: number | undefined | null,
	requireEmail?: number | undefined | null,
	requireGrindingStonesCondition?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessServiceLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	labelNote?: string | undefined | null,
	locale?: ValueTypes["Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	labelNumberOfPeople?: string | undefined | null,
	labelInvoiced?: string | undefined | null,
	labelEquipment?: string | undefined | null,
	labelMillTamDem?: string | undefined | null,
	labelSOEspresso?: string | undefined | null,
	labelFilterCoffeeInMenu?: string | undefined | null,
	labelWaterMeterStatus?: string | undefined | null,
	labelServiceAction?: string | undefined | null,
	labelInvoiceIdentifier?: string | undefined | null,
	labelSupervisor?: string | undefined | null,
	labelContact?: string | undefined | null,
	labelPhone?: string | undefined | null,
	labelEmail?: string | undefined | null,
	labelGrindingStonesCondition?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutBusinessServicesCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutBusinessServicesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffCreateInput"] | undefined | null
};
	["Contember_StaffCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	bartender?: ValueTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceEventCreateCreatedByEntityRelationInput"]: {
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_StaffCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DoubleshotCoffeeEquipmentCreateInput"] | undefined | null
};
	["Contember_DoubleshotCoffeeEquipmentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	espressoMachine?: string | undefined | null,
	espressoGrinder?: string | undefined | null,
	batchBrew?: string | undefined | null,
	batchGrinder?: string | undefined | null,
	waterFilter?: string | undefined | null,
	mechanicalFilter?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayCreateVenueEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutTimetableDaysCreateInput"] | undefined | null
};
	["Contember_VenueWithoutTimetableDaysCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WorkspaceCreateDiscountVatRateEntityRelationInput"]: {
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCreateImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCreateSuppliersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IngredientSupplierListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierListCreateInput"] | undefined | null
};
	["Contember_IngredientSupplierListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_IngredientSupplierListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IngredientSupplierItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	supplier?: ValueTypes["Contember_IngredientSupplierItemCreateSupplierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierItemCreateSupplierEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IngredientSupplierUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierCreateInput"] | undefined | null
};
	["Contember_IngredientSupplierCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_IngredientSupplierCreateLocalesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IngredientSupplierLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutIngredientSuppliersCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutIngredientSuppliersCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductListWithoutBusinessCategoryCreateInput"] | undefined | null
};
	["Contember_ProductListWithoutBusinessCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ProductListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductListItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductListItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductListItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	product?: ValueTypes["Contember_ProductListItemCreateProductEntityRelationInput"] | undefined | null,
	defaultQuantityForOrder?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductListItemCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ProductCategoryListCreateItemsEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCategoryListCreateLocalesEntityRelationInput"]> | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListCreateLocalesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListLocaleWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryListLocaleWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductCategoryListsCreateInput"] | undefined | null
};
	["Contember_LocaleWithoutProductCategoryListsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	businessCategories?: Array<ValueTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ProductCategoryListCreateItemsEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCategoryListCreateLocalesEntityRelationInput"]> | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkableCreateRedirectEntityRelationInput"]: {
	connect?: ValueTypes["Contember_RedirectUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_RedirectWithoutLinkCreateInput"] | undefined | null
};
	["Contember_RedirectWithoutLinkCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	target?: ValueTypes["Contember_RedirectCreateTargetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_RedirectCreateTargetEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_WikiPageCreateContentEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null
};
	["Contember_WikiPageCreateChildrenEntityRelationInput"]: {
	connect?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_WikiPageWithoutParentCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_WikiPageWithoutParentCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	title?: string | undefined | null,
	emoji?: string | undefined | null,
	link?: ValueTypes["Contember_WikiPageCreateLinkEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_WikiPageCreateChildrenEntityRelationInput"]> | undefined | null,
	content?: ValueTypes["Contember_WikiPageCreateContentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleCreateSeoEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SeoCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ContentReferenceCreateBlogPostsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogPostListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostListCreateInput"] | undefined | null
};
	["Contember_BlogPostListUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_BlogPostListItemUniqueWhere"] | undefined | null
};
	["Contember_BlogPostListItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_BlogPostListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_BlogPostListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogPostListItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostListItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BlogPostListItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	caption?: string | undefined | null,
	blogPost?: ValueTypes["Contember_BlogPostListItemCreateBlogPostEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostListItemCreateBlogPostEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostLocaleCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	title?: string | undefined | null,
	availableForWeb?: boolean | undefined | null,
	availableForMobile?: boolean | undefined | null,
	root?: ValueTypes["Contember_BlogPostLocaleCreateRootEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_BlogPostLocaleCreateCoverEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_BlogPostLocaleCreateContentEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_BlogPostLocaleCreateLinkEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_BlogPostLocaleCreateSeoEntityRelationInput"] | undefined | null,
	products?: Array<ValueTypes["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkableWithoutBlogPostCreateInput"] | undefined | null
};
	["Contember_LinkableWithoutBlogPostCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	url?: string | undefined | null,
	page?: ValueTypes["Contember_LinkableCreatePageEntityRelationInput"] | undefined | null,
	wikiPage?: ValueTypes["Contember_LinkableCreateWikiPageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentReferenceCreateLinksEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductListCreateInput"] | undefined | null
};
	["Contember_ProductListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ProductListCreateItemsEntityRelationInput"]> | undefined | null,
	businessCategory?: ValueTypes["Contember_ProductListCreateBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductListCreateBusinessCategoryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentReferenceCreateHeroEntityRelationInput"]: {
	connect?: ValueTypes["Contember_HeroUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_HeroCreateInput"] | undefined | null
};
	["Contember_HeroUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_HeroCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	showLogo?: boolean | undefined | null,
	middleContent?: string | undefined | null,
	medium?: ValueTypes["Contember_HeroCreateMediumEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_HeroCreateMediumEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateGalleryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_GalleryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_GalleryCreateInput"] | undefined | null
};
	["Contember_GalleryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_GalleryItemUniqueWhere"] | undefined | null
};
	["Contember_GalleryItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null
};
	["Contember_GalleryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_GalleryCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GalleryCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_GalleryItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_GalleryItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_GalleryItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	caption?: string | undefined | null,
	medium?: ValueTypes["Contember_GalleryItemCreateMediumEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GalleryItemCreateMediumEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_SocialsAndAppsCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateProductGridEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductGridUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductGridCreateInput"] | undefined | null
};
	["Contember_ProductGridUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	categories?: ValueTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined | null
};
	["Contember_ProductGridCategoryUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null,
	images?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null
};
	["Contember_ProductGridCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	isCarousel?: boolean | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductGridCreateCategoriesEntityRelationInput"]> | undefined | null,
	type?: ValueTypes["Contember_ProductGridType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGridCreateCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductGridCategoryWithoutRootCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductGridCategoryWithoutRootCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	title?: string | undefined | null,
	items?: Array<ValueTypes["Contember_ProductGridCategoryCreateItemsEntityRelationInput"]> | undefined | null,
	images?: ValueTypes["Contember_ProductGridCategoryCreateImagesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGridCategoryCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductGridItemWithoutCategoryCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductGridItemWithoutCategoryCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	title?: string | undefined | null,
	lead?: string | undefined | null,
	product?: ValueTypes["Contember_ProductGridItemCreateProductEntityRelationInput"] | undefined | null,
	medium?: ValueTypes["Contember_ProductGridItemCreateMediumEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_ProductGridItemCreateLinkEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGridItemCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_ProductGridItemCreateMediumEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_ProductGridItemCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_ProductGridCategoryCreateImagesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateProductBannersEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductBannerListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductBannerListCreateInput"] | undefined | null
};
	["Contember_ProductBannerListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_ProductBannerListCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductBannerListCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductBannerItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductBannerItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductBannerItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	banner?: ValueTypes["Contember_ProductBannerItemCreateBannerEntityRelationInput"] | undefined | null,
	size?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductBannerItemCreateBannerEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductBannerUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductBannerCreateInput"] | undefined | null
};
	["Contember_ProductBannerUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	button?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null
};
	["Contember_ProductBannerCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	beforeTitle?: string | undefined | null,
	title?: string | undefined | null,
	label?: string | undefined | null,
	button?: ValueTypes["Contember_ProductBannerCreateButtonEntityRelationInput"] | undefined | null,
	backgroundImage?: ValueTypes["Contember_ProductBannerCreateBackgroundImageEntityRelationInput"] | undefined | null,
	productImage?: ValueTypes["Contember_ProductBannerCreateProductImageEntityRelationInput"] | undefined | null,
	product?: ValueTypes["Contember_ProductBannerCreateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductBannerCreateButtonEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_ProductBannerCreateBackgroundImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductBannerCreateProductImageEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductBannerCreateProductEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRegionsCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateTextListEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceCreateGridTilesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_GridTilesUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_GridTilesCreateInput"] | undefined | null
};
	["Contember_GridTilesUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: ValueTypes["Contember_GridTileItemUniqueWhere"] | undefined | null
};
	["Contember_GridTileItemUniqueWhere"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	link?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null
};
	["Contember_GridTilesCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	items?: Array<ValueTypes["Contember_GridTilesCreateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GridTilesCreateItemsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_GridTileItemUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_GridTileItemWithoutListCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_GridTileItemWithoutListCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	order?: number | undefined | null,
	text?: string | undefined | null,
	medium?: ValueTypes["Contember_GridTileItemCreateMediumEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_GridTileItemCreateLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GridTileItemCreateMediumEntityRelationInput"]: {
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_GridTileItemCreateLinkEntityRelationInput"]: {
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutCartItemsCreateInput"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteWithoutCartItemsCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientItemCreateQuantityEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemQuantityCreateInput"] | undefined | null
};
	["Contember_ProductIngredientItemQuantityCreateInput"]: {
	id?: ValueTypes["UUID"] | undefined | null,
	unit?: ValueTypes["Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput"] | undefined | null,
	value?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined | null
};
	["Contember_AddressUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutAddressesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutAddressesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutAddressesUpdateInput"]: {
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertDefaultBillingAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerUpsertDefaultBillingAddressRelationInput"]: {
	update?: ValueTypes["Contember_AddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerPaymentMethodCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerPaymentMethodUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertDefaultPaymentMethodRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerPaymentMethodUpdateInput"]: {
	caption?: string | undefined | null,
	data?: ValueTypes["Json"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput"] | undefined | null,
	paymentMethod?: ValueTypes["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutPaymentMethodsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutPaymentMethodsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerPaymentMethodUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutPaymentMethodsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateAddressesEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateAddressesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertAddressesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateAddressesRelationInput"]: {
	by?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_AddressWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_AddressWithoutCustomerUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpdateCountryEntityRelationInput"]: {
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CountryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertCountryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CountryUpdateInput"]: {
	code?: string | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpsertCountryRelationInput"]: {
	update?: ValueTypes["Contember_CountryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null
};
	["Contember_AddressUpdateDeliveryZoneEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryZoneWithoutAddressesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneWithoutAddressesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertDeliveryZoneRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryZoneWithoutAddressesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	suitableDeliveryMethods?: Array<ValueTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfTags?: Array<ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfProducts?: Array<ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"] | undefined | null
};
	["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]: {
	create?: ValueTypes["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodUpdatePaymentMethodsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodUpsertPaymentMethodsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodUpdatePaymentMethodsRelationInput"]: {
	by?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"] | undefined | null
};
	["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	blockingDispatch?: boolean | undefined | null,
	type?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	channels?: Array<ValueTypes["Contember_PaymentMethodUpdateChannelsEntityRelationInput"]> | undefined | null,
	reduceGratuityByPercentage?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PaymentMethodUpdateChannelsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelPaymentWithoutMethodCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PaymentMethodUpdateChannelsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PaymentMethodUpsertChannelsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PaymentMethodUpdateChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelPaymentWithoutMethodUpdateInput"] | undefined | null
};
	["Contember_ChannelPaymentWithoutMethodUpdateInput"]: {
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentUpdateChannelEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelWithoutPaymentsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelWithoutPaymentsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelPaymentUpsertChannelRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ChannelWithoutPaymentsUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelUpdateDeliveriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelDeliveryWithoutChannelCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdateDeliveriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertDeliveriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelUpdateDeliveriesRelationInput"]: {
	by?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelDeliveryWithoutChannelUpdateInput"] | undefined | null
};
	["Contember_ChannelDeliveryWithoutChannelUpdateInput"]: {
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	method?: ValueTypes["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodWithoutChannelsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodWithoutChannelsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelDeliveryUpsertMethodRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryMethodWithoutChannelsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"] | undefined | null
};
	["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]> | undefined | null,
	deliveryZonesOfTags?: Array<ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfProducts?: Array<ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressWithoutDeliveryZoneCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneUpdateAddressesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryZoneUpsertAddressesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryZoneUpdateAddressesRelationInput"]: {
	by?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_AddressWithoutDeliveryZoneUpdateInput"] | undefined | null
};
	["Contember_AddressWithoutDeliveryZoneUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	meta?: ValueTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AddressUpdateGpsEntityRelationInput"]: {
	create?: ValueTypes["Contember_GpsWithoutAddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_GpsWithoutAddressUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertGpsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_GpsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_GpsWithoutAddressUpdateInput"]: {
	latitude?: number | undefined | null,
	longitude?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpsertGpsRelationInput"]: {
	update?: ValueTypes["Contember_GpsWithoutAddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_GpsWithoutAddressCreateInput"] | undefined | null
};
	["Contember_AddressUpdateInvoicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_InvoiceWithoutAddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressUpdateInvoicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertInvoicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AddressUpdateInvoicesRelationInput"]: {
	by?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_InvoiceWithoutAddressUpdateInput"] | undefined | null
};
	["Contember_InvoiceWithoutAddressUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	customer?: ValueTypes["Contember_InvoiceUpdateCustomerEntityRelationInput"] | undefined | null,
	orderPayments?: Array<ValueTypes["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]> | undefined | null,
	publicUrl?: string | undefined | null,
	billingSubject?: ValueTypes["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutInvoicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutInvoicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_InvoiceUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutInvoicesUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateTagsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutCustomersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateTagsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertTagsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutCustomersUpdateInput"] | undefined | null
};
	["Contember_TagWithoutCustomersUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutTagsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdateProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductWithoutTagsUpdateInput"] | undefined | null
};
	["Contember_ProductWithoutTagsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdatePackingEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductPackingCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductPackingUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertPackingRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductPackingUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductPackingUpdateInput"]: {
	name?: string | undefined | null,
	image?: ValueTypes["Contember_ProductPackingUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	volumeMl?: number | undefined | null,
	icon?: ValueTypes["Contember_ProductPackingUpdateIconEntityRelationInput"] | undefined | null,
	shortName?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductPackingUpdateLocalesEntityRelationInput"]> | undefined | null,
	preparedPackingQuantities?: Array<ValueTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"]> | undefined | null,
	isPackedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductPackingUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ImageUpdateInput"]: {
	url?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	size?: number | undefined | null,
	type?: string | undefined | null,
	alt?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductPackingUpdateIconEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductPackingUpsertIconRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductPackingUpsertIconRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductPackingUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductPackingLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductPackingUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductPackingUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductPackingUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductPackingLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_ProductPackingLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	shortName?: string | undefined | null,
	description?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutProductPackingsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutProductPackingsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductPackingLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutProductPackingsUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateBusinessCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertBusinessCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateBusinessCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput"] | undefined | null,
	title?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessCategoryWithoutLocalesUpdateInput"]: {
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCustomerWithoutCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryUpdateBusinessCustomersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryUpsertBusinessCustomersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCategoryUpdateBusinessCustomersRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessCustomerWithoutCategoryUpdateInput"] | undefined | null
};
	["Contember_BusinessCustomerWithoutCategoryUpdateInput"]: {
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutBusinessCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutBusinessUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutBusinessUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateCreditsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerCreditWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateCreditsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertCreditsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateCreditsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerCreditWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_CustomerCreditWithoutCustomerUpdateInput"]: {
	initialCreditCents?: number | undefined | null,
	remainingCreditsCents?: number | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	reason?: string | undefined | null,
	vatRate?: ValueTypes["Contember_CustomerCreditUpdateVatRateEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	transactions?: Array<ValueTypes["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"]> | undefined | null,
	voucherRedemption?: ValueTypes["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"] | undefined | null,
	invoiceUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerCreditUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VatRateUpdateInput"]: {
	name?: string | undefined | null,
	ratePermille?: number | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	channelDeliveries?: Array<ValueTypes["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"]> | undefined | null,
	channelPayments?: Array<ValueTypes["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"]> | undefined | null,
	preset?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelDeliveryWithoutVatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateChannelDeliveriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VatRateUpsertChannelDeliveriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VatRateUpdateChannelDeliveriesRelationInput"]: {
	by?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelDeliveryWithoutVatRateUpdateInput"] | undefined | null
};
	["Contember_ChannelDeliveryWithoutVatRateUpdateInput"]: {
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelWithoutDeliveriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelWithoutDeliveriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelDeliveryUpsertChannelRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ChannelWithoutDeliveriesUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelUpdatePaymentsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelPaymentWithoutChannelCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdatePaymentsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertPaymentsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelUpdatePaymentsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelPaymentWithoutChannelUpdateInput"] | undefined | null
};
	["Contember_ChannelPaymentWithoutChannelUpdateInput"]: {
	order?: number | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentUpdateMethodEntityRelationInput"]: {
	create?: ValueTypes["Contember_PaymentMethodWithoutChannelsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PaymentMethodWithoutChannelsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelPaymentUpsertMethodRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PaymentMethodWithoutChannelsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	blockingDispatch?: boolean | undefined | null,
	type?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	reduceGratuityByPercentage?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PaymentMethodUpdateDeliveryMethodsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PaymentMethodUpsertDeliveryMethodsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PaymentMethodUpdateDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"] | undefined | null
};
	["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelDeliveryWithoutMethodCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodUpdateChannelsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodUpsertChannelsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodUpdateChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelDeliveryWithoutMethodUpdateInput"] | undefined | null
};
	["Contember_ChannelDeliveryWithoutMethodUpdateInput"]: {
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateWithoutChannelDeliveriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateWithoutChannelDeliveriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelDeliveryUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_VatRateWithoutChannelDeliveriesUpdateInput"]: {
	name?: string | undefined | null,
	ratePermille?: number | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	channelPayments?: Array<ValueTypes["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"]> | undefined | null,
	preset?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelPaymentWithoutVatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateChannelPaymentsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VatRateUpsertChannelPaymentsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VatRateUpdateChannelPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelPaymentWithoutVatRateUpdateInput"] | undefined | null
};
	["Contember_ChannelPaymentWithoutVatRateUpdateInput"]: {
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutAllowChannelPaymentsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutAllowChannelPaymentsUpdateInput"] | undefined | null
};
	["Contember_TagWithoutAllowChannelPaymentsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagUpdateCustomersEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutTagsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdateCustomersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertCustomersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateCustomersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerWithoutTagsUpdateInput"] | undefined | null
};
	["Contember_CustomerWithoutTagsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdatePaymentMethodsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertPaymentMethodsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdatePaymentMethodsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"]: {
	caption?: string | undefined | null,
	data?: ValueTypes["Json"] | undefined | null,
	paymentMethod?: ValueTypes["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"]: {
	create?: ValueTypes["Contember_PaymentMethodCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PaymentMethodUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PaymentMethodUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	blockingDispatch?: boolean | undefined | null,
	type?: ValueTypes["Contember_PaymentMethodType"] | undefined | null,
	channels?: Array<ValueTypes["Contember_PaymentMethodUpdateChannelsEntityRelationInput"]> | undefined | null,
	reduceGratuityByPercentage?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput"]: {
	update?: ValueTypes["Contember_PaymentMethodUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertPaymentMethodsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateOrdersEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateOrdersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertOrdersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_OrderWithoutCustomerUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateChannelEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertChannelRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ChannelUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleWithoutChannelsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdatePointOfSalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertPointOfSalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelUpdatePointOfSalesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleWithoutChannelsUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleWithoutChannelsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	permissions?: ValueTypes["Contember_PointOfSaleUpdatePermissionsEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleUpdatePermissionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_PointOfSalePermissionsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ChannelUpsertPointOfSalesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleWithoutChannelsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_ChannelUpdateCartsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CartWithoutChannelCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdateCartsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertCartsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelUpdateCartsRelationInput"]: {
	by?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CartWithoutChannelUpdateInput"] | undefined | null
};
	["Contember_CartWithoutChannelUpdateInput"]: {
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CartUpdateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_CartUpdateOrderEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CartUpdateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CartItemWithoutCartCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CartUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CartItemWithoutCartUpdateInput"] | undefined | null
};
	["Contember_CartItemWithoutCartUpdateInput"]: {
	quantity?: number | undefined | null,
	product?: ValueTypes["Contember_CartItemUpdateProductEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartItemUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutCartItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutCartItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartItemUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductWithoutCartItemsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateRecipeEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductRecipeWithoutProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeWithoutProductsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertRecipeRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductRecipeWithoutProductsUpdateInput"]: {
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductRecipeUpdateImageEntityRelationInput"] | undefined | null,
	icon?: ValueTypes["Contember_ProductRecipeUpdateIconEntityRelationInput"] | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductRecipeUpdateLocalesEntityRelationInput"]> | undefined | null,
	gutTuning?: number | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	nutritionFacts?: ValueTypes["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductRecipeUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductRecipeUpdateIconEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeUpsertIconRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductRecipeUpsertIconRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientItemWithoutRecipeCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeUpdateIngredientsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeUpsertIngredientsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductRecipeUpdateIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientItemWithoutRecipeUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientItemWithoutRecipeUpdateInput"]: {
	order?: number | undefined | null,
	internalOrder?: number | undefined | null,
	ingredient?: ValueTypes["Contember_ProductIngredientItemUpdateIngredientEntityRelationInput"] | undefined | null,
	quantity?: ValueTypes["Contember_ProductIngredientItemUpdateQuantityEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientItemUpdateIngredientEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientItemUpsertIngredientRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientUpdateInput"]: {
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutProductIngredientsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutProductIngredientsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutProductIngredientsUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateProductCategoryListsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertProductCategoryListsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateProductCategoryListsRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryListWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryListLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductCategoryListWithoutLocalesUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]> | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryListItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductCategoryListItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_ProductCategoryListItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	productCategory?: ValueTypes["Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput"] | undefined | null,
	showRecipeImages?: boolean | undefined | null,
	showPackingImages?: boolean | undefined | null,
	showAllAvailableProducts?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryListItemUpsertProductCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductCategoryUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryUpdateProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryUpdateProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductWithoutCategoriesUpdateInput"] | undefined | null
};
	["Contember_ProductWithoutCategoriesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateTagsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateTagsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertTagsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutProductsUpdateInput"] | undefined | null
};
	["Contember_TagWithoutProductsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagUpdateCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryWithoutTagsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdateCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductCategoryWithoutTagsUpdateInput"] | undefined | null
};
	["Contember_ProductCategoryWithoutTagsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryUpdateParentEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryWithoutChildrenCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryWithoutChildrenUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryUpsertParentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductCategoryWithoutChildrenUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryUpdateTagsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryUpdateTagsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryUpsertTagsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryUpdateTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutCategoriesUpdateInput"] | undefined | null
};
	["Contember_TagWithoutCategoriesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdateAddTagsOnRegistrationRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertAddTagsOnRegistrationRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateAddTagsOnRegistrationRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"] | undefined | null
};
	["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelUpdateCustomersEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutChannelsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdateCustomersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertCustomersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelUpdateCustomersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerWithoutChannelsUpdateInput"] | undefined | null
};
	["Contember_CustomerWithoutChannelsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdatePermissionsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerUpdateGroupEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerGroupWithoutCustomersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerGroupWithoutCustomersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertGroupRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerGroupUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerGroupWithoutCustomersUpdateInput"]: {
	name?: string | undefined | null,
	productPrices?: Array<ValueTypes["Contember_CustomerGroupUpdateProductPricesEntityRelationInput"]> | undefined | null,
	discountPermille?: number | undefined | null,
	code?: string | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerGroupUpdateProductPricesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductGroupPriceWithoutGroupCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerGroupUpdateProductPricesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerGroupUpsertProductPricesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerGroupUpdateProductPricesRelationInput"]: {
	by?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductGroupPriceWithoutGroupUpdateInput"] | undefined | null
};
	["Contember_ProductGroupPriceWithoutGroupUpdateInput"]: {
	priceCents?: number | undefined | null,
	product?: ValueTypes["Contember_ProductGroupPriceUpdateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGroupPriceUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutGroupPricesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutGroupPricesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGroupPriceUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_ProductWithoutGroupPricesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryWithoutProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductCategoryWithoutProductsUpdateInput"] | undefined | null
};
	["Contember_ProductCategoryWithoutProductsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined | null,
	parent?: ValueTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryWithoutParentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryUpdateChildrenRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryUpsertChildrenRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryUpdateChildrenRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductCategoryWithoutParentUpdateInput"] | undefined | null
};
	["Contember_ProductCategoryWithoutParentUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined | null,
	children?: Array<ValueTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	descendants?: Array<ValueTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined | null,
	ancestors?: Array<ValueTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined | null,
	inheritedTags?: Array<ValueTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined | null,
	nestedProducts?: Array<ValueTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductCategoryUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryUpsertChildrenRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryWithoutParentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutParentCreateInput"] | undefined | null
};
	["Contember_ProductUpsertCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryWithoutProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutProductsCreateInput"] | undefined | null
};
	["Contember_ProductUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_ProductUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_ProductUpdateStocksEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductStockWithoutProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateStocksRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertStocksRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateStocksRelationInput"]: {
	by?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductStockWithoutProductUpdateInput"] | undefined | null
};
	["Contember_ProductStockWithoutProductUpdateInput"]: {
	quantity?: number | undefined | null,
	store?: ValueTypes["Contember_ProductStockUpdateStoreEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductStockUpdateStoreEntityRelationInput"]: {
	create?: ValueTypes["Contember_StoreCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StoreUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductStockUpsertStoreRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StoreUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StoreUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductStockUpsertStoreRelationInput"]: {
	update?: ValueTypes["Contember_StoreUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StoreCreateInput"] | undefined | null
};
	["Contember_ProductUpsertStocksRelationInput"]: {
	by?: ValueTypes["Contember_ProductStockUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductStockWithoutProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductStockWithoutProductCreateInput"] | undefined | null
};
	["Contember_ProductUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductUpdateCartItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CartItemWithoutProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateCartItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertCartItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateCartItemsRelationInput"]: {
	by?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CartItemWithoutProductUpdateInput"] | undefined | null
};
	["Contember_CartItemWithoutProductUpdateInput"]: {
	quantity?: number | undefined | null,
	cart?: ValueTypes["Contember_CartItemUpdateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartItemUpdateCartEntityRelationInput"]: {
	create?: ValueTypes["Contember_CartWithoutItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CartWithoutItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartItemUpsertCartRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CartWithoutItemsUpdateInput"]: {
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["Contember_CartUpdateChannelEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_CartUpdateOrderEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CartUpdateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartUpdateChannelEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelWithoutCartsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelWithoutCartsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertChannelRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ChannelWithoutCartsUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutAddTagsOnRegistrationCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertAddTagsOnRegistrationRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelUpdateAddTagsOnRegistrationRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"] | undefined | null
};
	["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagUpdateNestedCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateDeliveryZonesEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdateDeliveryZonesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertDeliveryZonesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateDeliveryZonesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"] | undefined | null
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]> | undefined | null,
	suitableDeliveryMethods?: Array<ValueTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfProducts?: Array<ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutDeliveryZonesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductWithoutDeliveryZonesUpdateInput"] | undefined | null
};
	["Contember_ProductWithoutDeliveryZonesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateGroupPricesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductGroupPriceWithoutProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateGroupPricesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertGroupPricesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateGroupPricesRelationInput"]: {
	by?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductGroupPriceWithoutProductUpdateInput"] | undefined | null
};
	["Contember_ProductGroupPriceWithoutProductUpdateInput"]: {
	priceCents?: number | undefined | null,
	group?: ValueTypes["Contember_ProductGroupPriceUpdateGroupEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGroupPriceUpdateGroupEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerGroupWithoutProductPricesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerGroupWithoutProductPricesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGroupPriceUpsertGroupRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerGroupUniqueWhere"] | undefined | null
};
	["Contember_CustomerGroupWithoutProductPricesUpdateInput"]: {
	name?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_CustomerGroupUpdateCustomersEntityRelationInput"]> | undefined | null,
	discountPermille?: number | undefined | null,
	code?: string | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerGroupUpdateCustomersEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutGroupCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerGroupUpdateCustomersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerGroupUpsertCustomersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerGroupUpdateCustomersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerWithoutGroupUpdateInput"] | undefined | null
};
	["Contember_CustomerWithoutGroupUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdatePhotoEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertPhotoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerUpsertPhotoRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateNameEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerNameWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerNameWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertNameRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerNameUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerNameWithoutCustomerUpdateInput"]: {
	nominative?: string | undefined | null,
	vocative?: string | undefined | null,
	aliases?: Array<ValueTypes["Contember_CustomerNameUpdateAliasesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerNameUpdateAliasesEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerAliasCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerNameUpdateAliasesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerNameUpsertAliasesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerAliasUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerAliasUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerAliasUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerNameUpdateAliasesRelationInput"]: {
	by?: ValueTypes["Contember_CustomerAliasUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerAliasUpdateInput"] | undefined | null
};
	["Contember_CustomerAliasUpdateInput"]: {
	name?: string | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerNameUpsertAliasesRelationInput"]: {
	by?: ValueTypes["Contember_CustomerAliasUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerAliasUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerAliasCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertNameRelationInput"]: {
	update?: ValueTypes["Contember_CustomerNameWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerNameWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateIngredientRatingsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertIngredientRatingsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateIngredientRatingsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"]: {
	rating?: ValueTypes["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined | null,
	ingredient?: ValueTypes["Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientRatingCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientRatingUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerIngredientRatingUpsertRatingRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientRatingUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientRatingUpdateInput"]: {
	order?: number | undefined | null,
	name?: string | undefined | null,
	icon?: ValueTypes["Contember_ProductIngredientRatingUpdateIconEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientRatingUpdateIconEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientRatingUpsertIconRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientRatingUpsertIconRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_CustomerIngredientRatingUpsertRatingRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientRatingUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientRatingCreateInput"] | undefined | null
};
	["Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerIngredientRatingUpsertIngredientRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"]: {
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientUpdateCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientUpdateCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"]: {
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientCategoryUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientCategoryUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientCategoryUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateProductIngredientsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertProductIngredientsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateProductIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientWithoutLocalesUpdateInput"]: {
	internalNote?: string | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]: {
	create?: ValueTypes["Contember_AllergenWithoutIngredientsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientUpdateAllergensRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertAllergensRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientUpdateAllergensRelationInput"]: {
	by?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_AllergenWithoutIngredientsUpdateInput"] | undefined | null
};
	["Contember_AllergenWithoutIngredientsUpdateInput"]: {
	internalName?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_AllergenUpdateLocalesEntityRelationInput"]> | undefined | null,
	code?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_AllergenLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AllergenUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AllergenUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AllergenUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_AllergenLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_AllergenLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_AllergenLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutAllergensCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutAllergensUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AllergenLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutAllergensUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateOrderRecurrencesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertOrderRecurrencesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateOrderRecurrencesRelationInput"]: {
	by?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderRecurrenceWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderRecurrenceWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderRecurrenceLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderRecurrenceWithoutLocalesUpdateInput"]: {
	internalName?: string | undefined | null,
	order?: number | undefined | null,
	businessCategory?: Array<ValueTypes["Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryWithoutRecurrencesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"] | undefined | null
};
	["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"]: {
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCategoryUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	title?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutBusinessCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutBusinessCategoriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutBusinessCategoriesUpdateInput"]: {
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateBlogsEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateBlogsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertBlogsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateBlogsRelationInput"]: {
	by?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BlogLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_BlogLocaleWithoutLocaleUpdateInput"]: {
	pageName?: string | undefined | null,
	title?: string | undefined | null,
	lead?: string | undefined | null,
	root?: ValueTypes["Contember_BlogLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BlogWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BlogWithoutLocalesUpdateInput"]: {
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_BlogWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertBlogsRelationInput"]: {
	by?: ValueTypes["Contember_BlogLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BlogLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdateBlogPostsEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogPostLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateBlogPostsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertBlogPostsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateBlogPostsRelationInput"]: {
	by?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BlogPostLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_BlogPostLocaleWithoutLocaleUpdateInput"]: {
	title?: string | undefined | null,
	availableForWeb?: boolean | undefined | null,
	availableForMobile?: boolean | undefined | null,
	root?: ValueTypes["Contember_BlogPostLocaleUpdateRootEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_BlogPostLocaleUpdateContentEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"] | undefined | null,
	products?: Array<ValueTypes["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogPostWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogPostUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BlogPostWithoutLocalesUpdateInput"]: {
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_BlogPostWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"]: {
	create?: ValueTypes["Contember_CoverCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CoverUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostLocaleUpsertCoverRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CoverUpdateInput"]: {
	primaryTitle?: string | undefined | null,
	secondaryTitle?: string | undefined | null,
	medium?: ValueTypes["Contember_CoverUpdateMediumEntityRelationInput"] | undefined | null,
	buttonColors?: ValueTypes["Contember_CoverUpdateButtonColorsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CoverUpdateMediumEntityRelationInput"]: {
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CoverUpsertMediumRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MediumUpdateInput"]: {
	type?: ValueTypes["Contember_MediumType"] | undefined | null,
	colorTheme?: ValueTypes["Contember_ColorTheme"] | undefined | null,
	image?: ValueTypes["Contember_MediumUpdateImageEntityRelationInput"] | undefined | null,
	video?: ValueTypes["Contember_MediumUpdateVideoEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MediumUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MediumUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MediumUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MediumUpdateVideoEntityRelationInput"]: {
	create?: ValueTypes["Contember_VideoCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VideoUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MediumUpsertVideoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VideoUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VideoUpdateInput"]: {
	src?: string | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	size?: number | undefined | null,
	type?: string | undefined | null,
	duration?: number | undefined | null,
	poster?: ValueTypes["Contember_VideoUpdatePosterEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VideoUpdatePosterEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VideoUpsertPosterRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VideoUpsertPosterRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MediumUpsertVideoRelationInput"]: {
	update?: ValueTypes["Contember_VideoUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VideoCreateInput"] | undefined | null
};
	["Contember_CoverUpsertMediumRelationInput"]: {
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_CoverUpdateButtonColorsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ColorPalleteCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ColorPalleteUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CoverUpsertButtonColorsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ColorPalleteUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ColorPalleteUpdateInput"]: {
	background?: string | undefined | null,
	text?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CoverUpsertButtonColorsRelationInput"]: {
	update?: ValueTypes["Contember_ColorPalleteUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ColorPalleteCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpsertCoverRelationInput"]: {
	update?: ValueTypes["Contember_CoverUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CoverCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpdateContentEntityRelationInput"]: {
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ContentUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostLocaleUpsertContentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ContentUpdateInput"]: {
	blocks?: Array<ValueTypes["Contember_ContentUpdateBlocksEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentUpdateBlocksEntityRelationInput"]: {
	create?: ValueTypes["Contember_ContentBlockWithoutContentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ContentUpdateBlocksRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentUpsertBlocksRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ContentBlockUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ContentBlockUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ContentBlockUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ContentUpdateBlocksRelationInput"]: {
	by?: ValueTypes["Contember_ContentBlockUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ContentBlockWithoutContentUpdateInput"] | undefined | null
};
	["Contember_ContentBlockWithoutContentUpdateInput"]: {
	order?: number | undefined | null,
	json?: string | undefined | null,
	references?: Array<ValueTypes["Contember_ContentBlockUpdateReferencesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentBlockUpdateReferencesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ContentReferenceWithoutBlockCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ContentBlockUpdateReferencesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentBlockUpsertReferencesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ContentBlockUpdateReferencesRelationInput"]: {
	by?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ContentReferenceWithoutBlockUpdateInput"] | undefined | null
};
	["Contember_ContentReferenceWithoutBlockUpdateInput"]: {
	type?: ValueTypes["Contember_ContentReferenceType"] | undefined | null,
	primaryText?: string | undefined | null,
	secondaryText?: string | undefined | null,
	jsonContent?: string | undefined | null,
	colorPallete?: ValueTypes["Contember_ContentReferenceUpdateColorPalleteEntityRelationInput"] | undefined | null,
	medium?: ValueTypes["Contember_ContentReferenceUpdateMediumEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_ContentReferenceUpdateLinkEntityRelationInput"] | undefined | null,
	blogPosts?: ValueTypes["Contember_ContentReferenceUpdateBlogPostsEntityRelationInput"] | undefined | null,
	links?: ValueTypes["Contember_ContentReferenceUpdateLinksEntityRelationInput"] | undefined | null,
	products?: ValueTypes["Contember_ContentReferenceUpdateProductsEntityRelationInput"] | undefined | null,
	contentSize?: ValueTypes["Contember_ContentSize"] | undefined | null,
	misc?: string | undefined | null,
	hero?: ValueTypes["Contember_ContentReferenceUpdateHeroEntityRelationInput"] | undefined | null,
	gallery?: ValueTypes["Contember_ContentReferenceUpdateGalleryEntityRelationInput"] | undefined | null,
	socialsAndApps?: ValueTypes["Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput"] | undefined | null,
	productGrid?: ValueTypes["Contember_ContentReferenceUpdateProductGridEntityRelationInput"] | undefined | null,
	productBanners?: ValueTypes["Contember_ContentReferenceUpdateProductBannersEntityRelationInput"] | undefined | null,
	deliveryRegions?: ValueTypes["Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput"] | undefined | null,
	textList?: ValueTypes["Contember_ContentReferenceUpdateTextListEntityRelationInput"] | undefined | null,
	gridTiles?: ValueTypes["Contember_ContentReferenceUpdateGridTilesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ContentReferenceUpdateColorPalleteEntityRelationInput"]: {
	create?: ValueTypes["Contember_ColorPalleteCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ColorPalleteUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertColorPalleteRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ColorPalleteUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ContentReferenceUpsertColorPalleteRelationInput"]: {
	update?: ValueTypes["Contember_ColorPalleteUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ColorPalleteCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateMediumEntityRelationInput"]: {
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertMediumRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ContentReferenceUpsertMediumRelationInput"]: {
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LinkUpdateInput"]: {
	type?: ValueTypes["Contember_LinkType"] | undefined | null,
	title?: string | undefined | null,
	externalLink?: string | undefined | null,
	internalLink?: ValueTypes["Contember_LinkUpdateInternalLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkUpdateInternalLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkableCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkableUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LinkUpsertInternalLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LinkableUpdateInput"]: {
	url?: string | undefined | null,
	blogPost?: ValueTypes["Contember_LinkableUpdateBlogPostEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_LinkableUpdatePageEntityRelationInput"] | undefined | null,
	wikiPage?: ValueTypes["Contember_LinkableUpdateWikiPageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkableUpdateBlogPostEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogPostLocaleWithoutLinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostLocaleWithoutLinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LinkableUpsertBlogPostRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BlogPostLocaleWithoutLinkUpdateInput"]: {
	title?: string | undefined | null,
	availableForWeb?: boolean | undefined | null,
	availableForMobile?: boolean | undefined | null,
	root?: ValueTypes["Contember_BlogPostLocaleUpdateRootEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_BlogPostLocaleUpdateContentEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"] | undefined | null,
	products?: Array<ValueTypes["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutBlogPostsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutBlogPostsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutBlogPostsUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateIdentifierEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationCataloguesIdentifierCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCataloguesIdentifierUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertIdentifierRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TranslationCataloguesIdentifierUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	catalogue?: Array<ValueTypes["Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationCatalogueWithoutIdentifierCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput"]: {
	by?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"] | undefined | null
};
	["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"]: {
	domain?: ValueTypes["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationDomainWithoutCataloguesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationDomainWithoutCataloguesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationCatalogueUpsertDomainRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationDomainUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TranslationDomainWithoutCataloguesUpdateInput"]: {
	identifier?: string | undefined | null,
	name?: string | undefined | null,
	keys?: Array<ValueTypes["Contember_TranslationDomainUpdateKeysEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationDomainUpdateKeysEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationKeyWithoutDomainCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationDomainUpdateKeysRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationDomainUpsertKeysRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationDomainUpdateKeysRelationInput"]: {
	by?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TranslationKeyWithoutDomainUpdateInput"] | undefined | null
};
	["Contember_TranslationKeyWithoutDomainUpdateInput"]: {
	identifier?: string | undefined | null,
	contentType?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	note?: string | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationKeyUpdateValuesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationKeyUpdateValuesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationValueWithoutKeyCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationKeyUpdateValuesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationKeyUpsertValuesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationKeyUpdateValuesRelationInput"]: {
	by?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TranslationValueWithoutKeyUpdateInput"] | undefined | null
};
	["Contember_TranslationValueWithoutKeyUpdateInput"]: {
	value?: string | undefined | null,
	catalogue?: ValueTypes["Contember_TranslationValueUpdateCatalogueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationValueUpdateCatalogueEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationCatalogueWithoutValuesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCatalogueWithoutValuesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationValueUpsertCatalogueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TranslationCatalogueWithoutValuesUpdateInput"]: {
	domain?: ValueTypes["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationCatalogueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCatalogueUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationCatalogueUpsertFallbackRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TranslationCatalogueUpdateInput"]: {
	domain?: ValueTypes["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"] | undefined | null,
	fallback?: ValueTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationValueWithoutCatalogueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCatalogueUpdateValuesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationCatalogueUpsertValuesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationCatalogueUpdateValuesRelationInput"]: {
	by?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TranslationValueWithoutCatalogueUpdateInput"] | undefined | null
};
	["Contember_TranslationValueWithoutCatalogueUpdateInput"]: {
	value?: string | undefined | null,
	key?: ValueTypes["Contember_TranslationValueUpdateKeyEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationValueUpdateKeyEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationKeyWithoutValuesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationKeyWithoutValuesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationValueUpsertKeyRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TranslationKeyWithoutValuesUpdateInput"]: {
	identifier?: string | undefined | null,
	contentType?: ValueTypes["Contember_TranslationContentType"] | undefined | null,
	note?: string | undefined | null,
	domain?: ValueTypes["Contember_TranslationKeyUpdateDomainEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationKeyUpdateDomainEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationDomainWithoutKeysCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationDomainWithoutKeysUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationKeyUpsertDomainRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationDomainUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TranslationDomainWithoutKeysUpdateInput"]: {
	identifier?: string | undefined | null,
	name?: string | undefined | null,
	catalogues?: Array<ValueTypes["Contember_TranslationDomainUpdateCataloguesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationDomainUpdateCataloguesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationCatalogueWithoutDomainCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationDomainUpdateCataloguesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationDomainUpsertCataloguesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TranslationDomainUpdateCataloguesRelationInput"]: {
	by?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TranslationCatalogueWithoutDomainUpdateInput"] | undefined | null
};
	["Contember_TranslationCatalogueWithoutDomainUpdateInput"]: {
	fallback?: ValueTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined | null,
	values?: Array<ValueTypes["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"]: {
	create?: ValueTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TranslationCatalogueUpsertIdentifierRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TranslationCatalogueUpsertIdentifierRelationInput"]: {
	update?: ValueTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"] | undefined | null
};
	["Contember_TranslationDomainUpsertCataloguesRelationInput"]: {
	by?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCatalogueWithoutDomainUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueWithoutDomainCreateInput"] | undefined | null
};
	["Contember_TranslationKeyUpsertDomainRelationInput"]: {
	update?: ValueTypes["Contember_TranslationDomainWithoutKeysUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationDomainWithoutKeysCreateInput"] | undefined | null
};
	["Contember_TranslationValueUpsertKeyRelationInput"]: {
	update?: ValueTypes["Contember_TranslationKeyWithoutValuesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationKeyWithoutValuesCreateInput"] | undefined | null
};
	["Contember_TranslationCatalogueUpsertValuesRelationInput"]: {
	by?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TranslationValueWithoutCatalogueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationValueWithoutCatalogueCreateInput"] | undefined | null
};
	["Contember_TranslationCatalogueUpsertFallbackRelationInput"]: {
	update?: ValueTypes["Contember_TranslationCatalogueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueCreateInput"] | undefined | null
};
	["Contember_TranslationValueUpsertCatalogueRelationInput"]: {
	update?: ValueTypes["Contember_TranslationCatalogueWithoutValuesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueWithoutValuesCreateInput"] | undefined | null
};
	["Contember_TranslationKeyUpsertValuesRelationInput"]: {
	by?: ValueTypes["Contember_TranslationValueUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TranslationValueWithoutKeyUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationValueWithoutKeyCreateInput"] | undefined | null
};
	["Contember_TranslationDomainUpsertKeysRelationInput"]: {
	by?: ValueTypes["Contember_TranslationKeyUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TranslationKeyWithoutDomainUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationKeyWithoutDomainCreateInput"] | undefined | null
};
	["Contember_TranslationCatalogueUpsertDomainRelationInput"]: {
	update?: ValueTypes["Contember_TranslationDomainWithoutCataloguesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationDomainWithoutCataloguesCreateInput"] | undefined | null
};
	["Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput"]: {
	by?: ValueTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCatalogueWithoutIdentifierCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertIdentifierRelationInput"]: {
	update?: ValueTypes["Contember_TranslationCataloguesIdentifierUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TranslationCataloguesIdentifierCreateInput"] | undefined | null
};
	["Contember_LocaleUpdateMenusEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateMenusRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertMenusRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateMenusRelationInput"]: {
	by?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_MenuLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_MenuLocaleWithoutLocaleUpdateInput"]: {
	root?: ValueTypes["Contember_MenuLocaleUpdateRootEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_MenuLocaleUpdateItemsEntityRelationInput"]> | undefined | null,
	secondaryItems?: ValueTypes["Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput"] | undefined | null,
	eshopLink?: ValueTypes["Contember_MenuLocaleUpdateEshopLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_SiteMenuWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SiteMenuWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SiteMenuUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SiteMenuWithoutLocalesUpdateInput"]: {
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_SiteMenuWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SiteMenuWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_MenuLocaleUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuItemWithoutMenuCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuLocaleUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuLocaleUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_MenuLocaleUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_MenuItemWithoutMenuUpdateInput"] | undefined | null
};
	["Contember_MenuItemWithoutMenuUpdateInput"]: {
	order?: number | undefined | null,
	link?: ValueTypes["Contember_MenuItemUpdateLinkEntityRelationInput"] | undefined | null,
	subitems?: Array<ValueTypes["Contember_MenuItemUpdateSubitemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuItemUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuItemUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuItemUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_MenuItemUpdateSubitemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuSubitemWithoutItemCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuItemUpdateSubitemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuItemUpsertSubitemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_MenuItemUpdateSubitemsRelationInput"]: {
	by?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_MenuSubitemWithoutItemUpdateInput"] | undefined | null
};
	["Contember_MenuSubitemWithoutItemUpdateInput"]: {
	type?: ValueTypes["Contember_MenuSubitemType"] | undefined | null,
	title?: string | undefined | null,
	links?: ValueTypes["Contember_MenuSubitemUpdateLinksEntityRelationInput"] | undefined | null,
	products?: ValueTypes["Contember_MenuSubitemUpdateProductsEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_MenuSubitemUpdateDeliveryEntityRelationInput"] | undefined | null,
	contact?: ValueTypes["Contember_MenuSubitemUpdateContactEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuSubitemUpdateLinksEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuLinkListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuLinkListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuSubitemUpsertLinksRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuLinkListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuLinkListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_MenuLinkListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLinkListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuLinkItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuLinkListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuLinkListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuLinkItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_MenuLinkItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_MenuLinkItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_MenuLinkListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_MenuLinkItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_MenuLinkItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_MenuLinkItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	link?: ValueTypes["Contember_MenuLinkItemUpdateLinkEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_MenuLinkItemUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuLinkItemUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuLinkItemUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuLinkItemUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_MenuLinkItemUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuLinkItemUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuLinkItemUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MenuLinkListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_MenuLinkItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_MenuLinkItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuLinkItemWithoutListCreateInput"] | undefined | null
};
	["Contember_MenuSubitemUpsertLinksRelationInput"]: {
	update?: ValueTypes["Contember_MenuLinkListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuLinkListCreateInput"] | undefined | null
};
	["Contember_MenuSubitemUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuProductsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuSubitemUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuProductsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuProductsUpdateInput"]: {
	links?: ValueTypes["Contember_MenuProductsUpdateLinksEntityRelationInput"] | undefined | null,
	buttons?: ValueTypes["Contember_MenuProductsUpdateButtonsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuProductsUpdateLinksEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuLinkListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuLinkListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuProductsUpsertLinksRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuLinkListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuProductsUpsertLinksRelationInput"]: {
	update?: ValueTypes["Contember_MenuLinkListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuLinkListCreateInput"] | undefined | null
};
	["Contember_MenuProductsUpdateButtonsEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuProductsUpsertButtonsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LinkListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_LinkListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkListItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LinkListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkListItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_LinkListItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_LinkListItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LinkListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_LinkListItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_LinkListItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_LinkListItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	isPrimary?: boolean | undefined | null,
	link?: ValueTypes["Contember_LinkListItemUpdateLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkListItemUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LinkListItemUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LinkListItemUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_LinkListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_LinkListItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_LinkListItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkListItemWithoutListCreateInput"] | undefined | null
};
	["Contember_MenuProductsUpsertButtonsRelationInput"]: {
	update?: ValueTypes["Contember_LinkListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null
};
	["Contember_MenuSubitemUpsertProductsRelationInput"]: {
	update?: ValueTypes["Contember_MenuProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuProductsCreateInput"] | undefined | null
};
	["Contember_MenuSubitemUpdateDeliveryEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuDeliveryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuDeliveryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuSubitemUpsertDeliveryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuDeliveryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuDeliveryUpdateInput"]: {
	deliveryRegions?: ValueTypes["Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput"] | undefined | null,
	text?: string | undefined | null,
	socialsAndApps?: ValueTypes["Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryRegionsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryRegionsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryRegionsUpdateInput"]: {
	items?: Array<ValueTypes["Contember_DeliveryRegionsUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRegionsUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryRegionsItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryRegionsUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryRegionsUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryRegionsUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryRegionsItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_DeliveryRegionsItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	title?: string | undefined | null,
	offerLabel?: string | undefined | null,
	image?: ValueTypes["Contember_DeliveryRegionsItemUpdateImageEntityRelationInput"] | undefined | null,
	textList?: ValueTypes["Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput"] | undefined | null,
	gallery?: ValueTypes["Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRegionsItemUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryRegionsItemUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryRegionsItemUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput"]: {
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryRegionsItemUpsertTextListRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TextListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_TextListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TextListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TextItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TextListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TextListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TextItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TextItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TextItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TextListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_TextItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TextItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_TextItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	text?: string | undefined | null,
	title?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TextListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_TextItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TextItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TextItemWithoutListCreateInput"] | undefined | null
};
	["Contember_DeliveryRegionsItemUpsertTextListRelationInput"]: {
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryRegionsItemUpsertGalleryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ImageListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ImageListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ImageListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageListItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ImageListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageListItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ImageListItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ImageListItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ImageListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_ImageListItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ImageListItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_ImageListItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	image?: ValueTypes["Contember_ImageListItemUpdateImageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ImageListItemUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ImageListItemUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ImageListItemUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ImageListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_ImageListItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ImageListItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageListItemWithoutListCreateInput"] | undefined | null
};
	["Contember_DeliveryRegionsItemUpsertGalleryRelationInput"]: {
	update?: ValueTypes["Contember_ImageListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageListCreateInput"] | undefined | null
};
	["Contember_DeliveryRegionsUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryRegionsItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRegionsItemWithoutListCreateInput"] | undefined | null
};
	["Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryRegionsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRegionsCreateInput"] | undefined | null
};
	["Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput"]: {
	create?: ValueTypes["Contember_SocialsAndAppsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SocialsAndAppsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SocialsAndAppsUpdateInput"]: {
	instagram?: boolean | undefined | null,
	appStore?: boolean | undefined | null,
	googlePlay?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput"]: {
	update?: ValueTypes["Contember_SocialsAndAppsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SocialsAndAppsCreateInput"] | undefined | null
};
	["Contember_MenuSubitemUpsertDeliveryRelationInput"]: {
	update?: ValueTypes["Contember_MenuDeliveryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuDeliveryCreateInput"] | undefined | null
};
	["Contember_MenuSubitemUpdateContactEntityRelationInput"]: {
	create?: ValueTypes["Contember_MenuContactCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MenuContactUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuSubitemUpsertContactRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MenuContactUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuContactUpdateInput"]: {
	text?: string | undefined | null,
	image?: ValueTypes["Contember_MenuContactUpdateImageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_MenuContactUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuContactUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuContactUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_MenuSubitemUpsertContactRelationInput"]: {
	update?: ValueTypes["Contember_MenuContactUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuContactCreateInput"] | undefined | null
};
	["Contember_MenuItemUpsertSubitemsRelationInput"]: {
	by?: ValueTypes["Contember_MenuSubitemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_MenuSubitemWithoutItemUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuSubitemWithoutItemCreateInput"] | undefined | null
};
	["Contember_MenuLocaleUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_MenuItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_MenuItemWithoutMenuUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuItemWithoutMenuCreateInput"] | undefined | null
};
	["Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuLocaleUpsertSecondaryItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuLocaleUpsertSecondaryItemsRelationInput"]: {
	update?: ValueTypes["Contember_LinkListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null
};
	["Contember_MenuLocaleUpdateEshopLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_MenuLocaleUpsertEshopLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_MenuLocaleUpsertEshopLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertMenusRelationInput"]: {
	by?: ValueTypes["Contember_MenuLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_MenuLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MenuLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdatePagesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PageLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdatePagesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertPagesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdatePagesRelationInput"]: {
	by?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PageLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_PageLocaleWithoutLocaleUpdateInput"]: {
	title?: string | undefined | null,
	link?: ValueTypes["Contember_PageLocaleUpdateLinkEntityRelationInput"] | undefined | null,
	root?: ValueTypes["Contember_PageLocaleUpdateRootEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_PageLocaleUpdateSeoEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_PageLocaleUpdateContentEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_PageLocaleUpdateCoverEntityRelationInput"] | undefined | null,
	theme?: ValueTypes["Contember_PageTheme"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkableWithoutPageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkableWithoutPageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PageLocaleUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LinkableWithoutPageUpdateInput"]: {
	url?: string | undefined | null,
	blogPost?: ValueTypes["Contember_LinkableUpdateBlogPostEntityRelationInput"] | undefined | null,
	wikiPage?: ValueTypes["Contember_LinkableUpdateWikiPageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkableUpdateWikiPageEntityRelationInput"]: {
	create?: ValueTypes["Contember_WikiPageWithoutLinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_WikiPageWithoutLinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LinkableUpsertWikiPageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WikiPageWithoutLinkUpdateInput"]: {
	order?: number | undefined | null,
	title?: string | undefined | null,
	emoji?: string | undefined | null,
	parent?: ValueTypes["Contember_WikiPageUpdateParentEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_WikiPageUpdateChildrenEntityRelationInput"]> | undefined | null,
	content?: ValueTypes["Contember_WikiPageUpdateContentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WikiPageUpdateParentEntityRelationInput"]: {
	create?: ValueTypes["Contember_WikiPageWithoutChildrenCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_WikiPageWithoutChildrenUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WikiPageUpsertParentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WikiPageWithoutChildrenUpdateInput"]: {
	order?: number | undefined | null,
	title?: string | undefined | null,
	emoji?: string | undefined | null,
	link?: ValueTypes["Contember_WikiPageUpdateLinkEntityRelationInput"] | undefined | null,
	parent?: ValueTypes["Contember_WikiPageUpdateParentEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_WikiPageUpdateContentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WikiPageUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkableWithoutWikiPageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkableWithoutWikiPageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WikiPageUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LinkableWithoutWikiPageUpdateInput"]: {
	url?: string | undefined | null,
	blogPost?: ValueTypes["Contember_LinkableUpdateBlogPostEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_LinkableUpdatePageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LinkableUpdatePageEntityRelationInput"]: {
	create?: ValueTypes["Contember_PageLocaleWithoutLinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PageLocaleWithoutLinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LinkableUpsertPageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PageLocaleWithoutLinkUpdateInput"]: {
	title?: string | undefined | null,
	root?: ValueTypes["Contember_PageLocaleUpdateRootEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_PageLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_PageLocaleUpdateSeoEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_PageLocaleUpdateContentEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_PageLocaleUpdateCoverEntityRelationInput"] | undefined | null,
	theme?: ValueTypes["Contember_PageTheme"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_PageWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PageWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PageLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PageWithoutLocalesUpdateInput"]: {
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_PageWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PageWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_PageLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutPagesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutPagesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PageLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutPagesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertProductIngredientCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateProductIngredientCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"]: {
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput"]> | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientWithoutCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientCategoryUpdateIngredientsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientCategoryUpsertIngredientsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientCategoryUpdateIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientWithoutCategoriesUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientWithoutCategoriesUpdateInput"]: {
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	allergens?: Array<ValueTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientUpdateCustomerRatingsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertCustomerRatingsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductIngredientUpdateCustomerRatingsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"] | undefined | null
};
	["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"]: {
	customer?: ValueTypes["Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput"] | undefined | null,
	rating?: ValueTypes["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutIngredientRatingsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutIngredientRatingsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerIngredientRatingUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutIngredientRatingsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateBusinessEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCustomerWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertBusinessRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessCustomerWithoutCustomerUpdateInput"]: {
	name?: string | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerUpdateOwnersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertOwnersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCustomerUpdateOwnersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"] | undefined | null
};
	["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCustomerWithoutMembersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateMemberOfBusinessesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertMemberOfBusinessesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateMemberOfBusinessesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessCustomerWithoutMembersUpdateInput"] | undefined | null
};
	["Contember_BusinessCustomerWithoutMembersUpdateInput"]: {
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"]: {
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryUpdateRecurrencesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryUpsertRecurrencesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCategoryUpdateRecurrencesRelationInput"]: {
	by?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"] | undefined | null
};
	["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"]: {
	internalName?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_OrderRecurrenceUpdateLocalesEntityRelationInput"]> | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderRecurrenceUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderRecurrenceUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderRecurrenceUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutOrderRecurrencesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutOrderRecurrencesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutOrderRecurrencesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateAllergensEntityRelationInput"]: {
	create?: ValueTypes["Contember_AllergenLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateAllergensRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertAllergensRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateAllergensRelationInput"]: {
	by?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_AllergenLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_AllergenLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_AllergenLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_AllergenWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AllergenWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AllergenLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AllergenWithoutLocalesUpdateInput"]: {
	internalName?: string | undefined | null,
	ingredients?: Array<ValueTypes["Contember_AllergenUpdateIngredientsEntityRelationInput"]> | undefined | null,
	code?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AllergenUpdateIngredientsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientWithoutAllergensCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AllergenUpdateIngredientsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AllergenUpsertIngredientsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AllergenUpdateIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductIngredientWithoutAllergensUpdateInput"] | undefined | null
};
	["Contember_ProductIngredientWithoutAllergensUpdateInput"]: {
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	allowCustomerRating?: boolean | undefined | null,
	customerRatings?: Array<ValueTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined | null,
	preferredQuantityUnit?: ValueTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	image?: ValueTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined | null,
	suppliers?: ValueTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined | null,
	incrementQuantityNumber?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientItemQuantityUnitUpdateInput"]: {
	name?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"]: {
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FreshingContainerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertFreshingContainerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FreshingContainerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_FreshingContainerUpdateInput"]: {
	order?: number | undefined | null,
	volumeMl?: number | undefined | null,
	workspace?: ValueTypes["Contember_FreshingContainerUpdateWorkspaceEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FreshingContainerUpdateWorkspaceEntityRelationInput"]: {
	create?: ValueTypes["Contember_WorkspaceWithoutFreshingContainersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_WorkspaceWithoutFreshingContainersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_FreshingContainerUpsertWorkspaceRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_WorkspaceUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WorkspaceWithoutFreshingContainersUpdateInput"]: {
	unique?: ValueTypes["Contember_One"] | undefined | null,
	orderDeadlineTime?: string | undefined | null,
	ordersLoadingDurationHours?: number | undefined | null,
	freshingContainerVolumeMl?: number | undefined | null,
	freshingPrepareOrderByOffsetMinutes?: number | undefined | null,
	checkoutUrlTemplate?: string | undefined | null,
	orderUrlTemplate?: string | undefined | null,
	paymentUrlTemplate?: string | undefined | null,
	customerProfileUrlTemplate?: string | undefined | null,
	userProfileUrlTemplate?: string | undefined | null,
	messengerUrl?: string | undefined | null,
	messengerIosUrl?: string | undefined | null,
	messengerAndroidUrl?: string | undefined | null,
	openingHours?: ValueTypes["Contember_WorkspaceUpdateOpeningHoursEntityRelationInput"] | undefined | null,
	workingHours?: ValueTypes["Contember_WorkspaceUpdateWorkingHoursEntityRelationInput"] | undefined | null,
	defaultFreshingContainer?: ValueTypes["Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput"] | undefined | null,
	creditsProduct?: ValueTypes["Contember_WorkspaceUpdateCreditsProductEntityRelationInput"] | undefined | null,
	discountVatRate?: ValueTypes["Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput"] | undefined | null,
	name?: string | undefined | null,
	businessAddress?: string | undefined | null,
	migrationTest?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WorkspaceUpdateOpeningHoursEntityRelationInput"]: {
	create?: ValueTypes["Contember_WeekHoursCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_WeekHoursUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WorkspaceUpsertOpeningHoursRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_WeekHoursUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WeekHoursUpdateInput"]: {
	mon?: string | undefined | null,
	tue?: string | undefined | null,
	wed?: string | undefined | null,
	thu?: string | undefined | null,
	fri?: string | undefined | null,
	sat?: string | undefined | null,
	sun?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WorkspaceUpsertOpeningHoursRelationInput"]: {
	update?: ValueTypes["Contember_WeekHoursUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_WeekHoursCreateInput"] | undefined | null
};
	["Contember_WorkspaceUpdateWorkingHoursEntityRelationInput"]: {
	create?: ValueTypes["Contember_WeekHoursCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_WeekHoursUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WorkspaceUpsertWorkingHoursRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_WeekHoursUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WorkspaceUpsertWorkingHoursRelationInput"]: {
	update?: ValueTypes["Contember_WeekHoursUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_WeekHoursCreateInput"] | undefined | null
};
	["Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput"]: {
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FreshingContainerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FreshingContainerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput"]: {
	update?: ValueTypes["Contember_FreshingContainerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null
};
	["Contember_WorkspaceUpdateCreditsProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_VirtualProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VirtualProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WorkspaceUpsertCreditsProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VirtualProductUpdateInput"]: {
	type?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	product?: ValueTypes["Contember_VirtualProductUpdateProductEntityRelationInput"] | undefined | null,
	voucherCreditCents?: number | undefined | null,
	physicalRepresentation?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	recipientEmail?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VirtualProductUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutVirtualProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutVirtualProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VirtualProductUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductWithoutVirtualProductUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_StocksManagerWithoutManagedProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StocksManagerWithoutManagedProductsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StocksManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StocksManagerWithoutManagedProductsUpdateInput"]: {
	staff?: ValueTypes["Contember_StocksManagerUpdateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StocksManagerUpdateStaffEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffWithoutStocksManagerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffWithoutStocksManagerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StocksManagerUpsertStaffRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffWithoutStocksManagerUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpdateBartenderEntityRelationInput"]: {
	create?: ValueTypes["Contember_BartenderWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BartenderWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertBartenderRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BartenderUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BartenderWithoutStaffUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertBartenderRelationInput"]: {
	update?: ValueTypes["Contember_BartenderWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BartenderWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateCourierEntityRelationInput"]: {
	create?: ValueTypes["Contember_CourierWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CourierWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertCourierRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CourierWithoutStaffUpdateInput"]: {
	routes?: Array<ValueTypes["Contember_CourierUpdateRoutesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	showInPicker?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CourierUpdateRoutesEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryRouteWithoutCourierCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CourierUpdateRoutesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CourierUpsertRoutesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CourierUpdateRoutesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryRouteWithoutCourierUpdateInput"] | undefined | null
};
	["Contember_DeliveryRouteWithoutCourierUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	waypoints?: Array<ValueTypes["Contember_DeliveryRouteUpdateWaypointsEntityRelationInput"]> | undefined | null,
	dispatchAt?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRouteUpdateWaypointsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryWaypointWithoutRouteCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryRouteUpdateWaypointsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryRouteUpsertWaypointsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryRouteUpdateWaypointsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryWaypointWithoutRouteUpdateInput"] | undefined | null
};
	["Contember_DeliveryWaypointWithoutRouteUpdateInput"]: {
	estimatedArrival?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	sortingOrder?: number | undefined | null,
	delivery?: ValueTypes["Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput"] | undefined | null,
	estimatedDistance?: number | undefined | null,
	estimatedDuration?: number | undefined | null,
	originDeparture?: ValueTypes["DateTime"] | undefined | null,
	originAddress?: string | undefined | null,
	transitMode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryWaypointUpsertDeliveryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"]: {
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDeliveryUpdateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	events?: Array<ValueTypes["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]> | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["Contember_OrderDeliveryUpdateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryUpdateMethodEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDeliveryUpsertMethodRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null
};
	["Contember_DeliveryMethodUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]: {
	create?: ValueTypes["Contember_CourierWithoutDeliveryMethodsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodUpdateCourierHandlersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodUpsertCourierHandlersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodUpdateCourierHandlersRelationInput"]: {
	by?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CourierWithoutDeliveryMethodsUpdateInput"] | undefined | null
};
	["Contember_CourierWithoutDeliveryMethodsUpdateInput"]: {
	staff?: ValueTypes["Contember_CourierUpdateStaffEntityRelationInput"] | undefined | null,
	routes?: Array<ValueTypes["Contember_CourierUpdateRoutesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	showInPicker?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CourierUpdateStaffEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffWithoutCourierCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffWithoutCourierUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CourierUpsertStaffRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffWithoutCourierUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpdateCleanerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CleanerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CleanerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertCleanerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CleanerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CleanerWithoutStaffUpdateInput"]: {
	canReachTopShelf?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertCleanerRelationInput"]: {
	update?: ValueTypes["Contember_CleanerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CleanerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateShiftsManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsManagerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsManagerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertShiftsManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ShiftsManagerWithoutStaffUpdateInput"]: {
	managedRoles?: Array<ValueTypes["Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsManagerUpdateManagedRolesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ShiftsManagerUpsertManagedRolesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ShiftsManagerUpdateManagedRolesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"]: {
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutTypeCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableShiftGroupWithoutTypeUpdateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupWithoutTypeUpdateInput"]: {
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftWithoutGroupCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftGroupUpdateShiftsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftGroupUpsertShiftsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftGroupUpdateShiftsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableShiftWithoutGroupUpdateInput"] | undefined | null
};
	["Contember_TimetableShiftWithoutGroupUpdateInput"]: {
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	queueItem?: ValueTypes["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsProfileWithoutShiftsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileWithoutShiftsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftUpsertShiftsProfileRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ShiftsProfileWithoutShiftsUpdateInput"]: {
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined | null,
	staff?: ValueTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileUpdateQueueItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ShiftsProfileUpsertQueueItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ShiftsProfileUpdateQueueItemsRelationInput"]: {
	by?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"]: {
	create?: ValueTypes["Contember_EmployeeQueueWithoutItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_EmployeeQueueWithoutItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_EmployeeQueueItemUpsertQueueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_EmployeeQueueWithoutItemsUpdateInput"]: {
	shiftGroup?: ValueTypes["Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput"] | undefined | null,
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutQueueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftGroupWithoutQueueUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_EmployeeQueueUpsertShiftGroupRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupWithoutQueueUpdateInput"]: {
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableDayWithoutShiftGroupsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableDayWithoutShiftGroupsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftGroupUpsertDayRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableDayWithoutShiftGroupsUpdateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	type?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	tipsCents?: number | undefined | null,
	notes?: Array<ValueTypes["Contember_TimetableDayUpdateNotesEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_TimetableDayUpdateVenueEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_TimetableDayUpdateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayUpdateNotesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableDayNoteWithoutDayCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableDayUpdateNotesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableDayUpsertNotesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableDayUpdateNotesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableDayNoteWithoutDayUpdateInput"] | undefined | null
};
	["Contember_TimetableDayNoteWithoutDayUpdateInput"]: {
	order?: number | undefined | null,
	note?: string | undefined | null,
	role?: ValueTypes["Contember_TimetableDayNoteUpdateRoleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayNoteUpdateRoleEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableDayNoteUpsertRoleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateInput"]: {
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsProfileWithoutRolesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput"]: {
	by?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ShiftsProfileWithoutRolesUpdateInput"] | undefined | null
};
	["Contember_ShiftsProfileWithoutRolesUpdateInput"]: {
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined | null,
	staff?: ValueTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileUpdateStaffEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffWithoutShiftsProfileCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffWithoutShiftsProfileUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ShiftsProfileUpsertStaffRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffWithoutShiftsProfileUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertBusinessToBusinessManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"]: {
	managedBusinesses?: Array<ValueTypes["Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCustomerWithoutAccountManagerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"] | undefined | null
};
	["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"]: {
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined | null,
	owners?: Array<ValueTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutMemberOfBusinessesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerUpdateMembersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertMembersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCustomerUpdateMembersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"] | undefined | null
};
	["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCustomerWithoutOwnersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertOwnerOfBusinessesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateOwnerOfBusinessesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessCustomerWithoutOwnersUpdateInput"] | undefined | null
};
	["Contember_BusinessCustomerWithoutOwnersUpdateInput"]: {
	name?: string | undefined | null,
	customer?: ValueTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined | null,
	members?: Array<ValueTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined | null,
	accountManager?: ValueTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined | null,
	logo?: ValueTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined | null,
	note?: string | undefined | null,
	businessServiceEvents?: Array<ValueTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined | null,
	doubleshotCoffeeEquipment?: ValueTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertAccountManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"]: {
	staff?: ValueTypes["Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessToBusinessManagerUpsertStaffRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpdateUserEntityRelationInput"]: {
	create?: ValueTypes["Contember_UserWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UserWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertUserRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UserWithoutStaffUpdateInput"]: {
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	customer?: ValueTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutUserCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutUserUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UserUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutUserUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateCartsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CartWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateCartsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertCartsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateCartsRelationInput"]: {
	by?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CartWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_CartWithoutCustomerUpdateInput"]: {
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CartUpdateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["Contember_CartUpdateChannelEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_CartUpdateOrderEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartUpdateOrderEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutCartCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutCartUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertOrderRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderWithoutCartUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutOrdersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutOrdersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutOrdersUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateShortcutListEntityRelationInput"]: {
	create?: ValueTypes["Contember_ApplicationShortcutListWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertShortcutListRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ApplicationShortcutListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ApplicationShortcutListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ApplicationShortcutListItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ApplicationShortcutListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ApplicationShortcutListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ApplicationShortcutListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ApplicationShortcutListItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_ApplicationShortcutListItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	application?: ValueTypes["Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput"]: {
	create?: ValueTypes["Contember_ApplicationCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ApplicationUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ApplicationShortcutListItemUpsertApplicationRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ApplicationUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ApplicationUpdateInput"]: {
	order?: number | undefined | null,
	handle?: string | undefined | null,
	url?: string | undefined | null,
	color?: string | undefined | null,
	icon?: ValueTypes["Contember_ApplicationUpdateIconEntityRelationInput"] | undefined | null,
	name?: string | undefined | null,
	short?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ApplicationUpdateIconEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ApplicationUpsertIconRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ApplicationUpsertIconRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ApplicationShortcutListItemUpsertApplicationRelationInput"]: {
	update?: ValueTypes["Contember_ApplicationUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ApplicationCreateInput"] | undefined | null
};
	["Contember_ApplicationShortcutListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ApplicationShortcutListItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ApplicationShortcutListItemWithoutListCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertShortcutListRelationInput"]: {
	update?: ValueTypes["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ApplicationShortcutListWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateUserEntityRelationInput"]: {
	create?: ValueTypes["Contember_UserWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UserWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertUserRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UserWithoutCustomerUpdateInput"]: {
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserUpdateStaffEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffWithoutUserCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffWithoutUserUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UserUpsertStaffRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffWithoutUserUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpdateShiftsProfileEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsProfileWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertShiftsProfileRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ShiftsProfileWithoutStaffUpdateInput"]: {
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileUpdateRolesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ShiftsProfileUpsertRolesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ShiftsProfileUpdateRolesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"]: {
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsManagerWithoutManagedRolesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput"]: {
	by?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"] | undefined | null
};
	["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"]: {
	staff?: ValueTypes["Contember_ShiftsManagerUpdateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsManagerUpdateStaffEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffWithoutShiftsManagerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffWithoutShiftsManagerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ShiftsManagerUpsertStaffRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffWithoutShiftsManagerUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpdateStocksManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_StocksManagerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StocksManagerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertStocksManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StocksManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StocksManagerWithoutStaffUpdateInput"]: {
	managedProducts?: Array<ValueTypes["Contember_StocksManagerUpdateManagedProductsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StocksManagerUpdateManagedProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutManagerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StocksManagerUpdateManagedProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StocksManagerUpsertManagedProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_StocksManagerUpdateManagedProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductWithoutManagerUpdateInput"] | undefined | null
};
	["Contember_ProductWithoutManagerUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateOrderItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderItemWithoutProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateOrderItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertOrderItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateOrderItemsRelationInput"]: {
	by?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderItemWithoutProductUpdateInput"] | undefined | null
};
	["Contember_OrderItemWithoutProductUpdateInput"]: {
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderItemUpdateOrderEntityRelationInput"] | undefined | null,
	virtualProductEffects?: Array<ValueTypes["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]> | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderItemUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_OrderItemUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderItemUpdateOrderEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderItemUpsertOrderRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderWithoutItemsUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateDiscountsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderDiscountWithoutOrderCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderUpdateDiscountsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertDiscountsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderUpdateDiscountsRelationInput"]: {
	by?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderDiscountWithoutOrderUpdateInput"] | undefined | null
};
	["Contember_OrderDiscountWithoutOrderUpdateInput"]: {
	discountCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDiscountUpdateVatRateEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	type?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	customerCreditTransactions?: Array<ValueTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"]> | undefined | null,
	voucherRedemption?: ValueTypes["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDiscountUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDiscountUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_OrderDiscountUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"] | undefined | null
};
	["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	amountCents?: number | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerCreditWithoutTransactionsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerCreditWithoutTransactionsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerCreditWithoutTransactionsUpdateInput"]: {
	initialCreditCents?: number | undefined | null,
	remainingCreditsCents?: number | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	reason?: string | undefined | null,
	customer?: ValueTypes["Contember_CustomerCreditUpdateCustomerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_CustomerCreditUpdateVatRateEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"] | undefined | null,
	invoiceUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutCreditsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutCreditsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerCreditUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null
};
	["Contember_CustomerWithoutCreditsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateChannelsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelWithoutCustomersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateChannelsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertChannelsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelWithoutCustomersUpdateInput"] | undefined | null
};
	["Contember_ChannelWithoutCustomersUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelUpdatePublicTagsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutPublicInChannelsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelUpdatePublicTagsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertPublicTagsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ChannelUpdatePublicTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutPublicInChannelsUpdateInput"] | undefined | null
};
	["Contember_TagWithoutPublicInChannelsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdateAllowChannelPaymentsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertAllowChannelPaymentsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateAllowChannelPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"] | undefined | null
};
	["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"]: {
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateWithoutChannelPaymentsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateWithoutChannelPaymentsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelPaymentUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_VatRateWithoutChannelPaymentsUpdateInput"]: {
	name?: string | undefined | null,
	ratePermille?: number | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	channelDeliveries?: Array<ValueTypes["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"]> | undefined | null,
	preset?: ValueTypes["Contember_VatRatePreset"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelPaymentUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateWithoutChannelPaymentsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateWithoutChannelPaymentsCreateInput"] | undefined | null
};
	["Contember_TagUpsertAllowChannelPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"] | undefined | null
};
	["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]: {
	create?: ValueTypes["Contember_FulfillmentNoteWithoutTagsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdateFulfillmentNotesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertFulfillmentNotesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdateFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_FulfillmentNoteWithoutTagsUpdateInput"] | undefined | null
};
	["Contember_FulfillmentNoteWithoutTagsUpdateInput"]: {
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutFulfillmentNotesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteUpdateProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_FulfillmentNoteUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteUpdateProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductWithoutFulfillmentNotesUpdateInput"] | undefined | null
};
	["Contember_ProductWithoutFulfillmentNotesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateDeliveryZonesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertDeliveryZonesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateDeliveryZonesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"] | undefined | null
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	type?: ValueTypes["Contember_DeliveryZoneType"] | undefined | null,
	addresses?: Array<ValueTypes["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]> | undefined | null,
	suitableDeliveryMethods?: Array<ValueTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]> | undefined | null,
	deliveryZonesOfTags?: Array<ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]> | undefined | null,
	addressesMetadata?: Array<ValueTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutDeliveryZonesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutDeliveryZonesUpdateInput"] | undefined | null
};
	["Contember_TagWithoutDeliveryZonesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TagUpdatePublicInChannelsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelWithoutPublicTagsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TagUpdatePublicInChannelsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TagUpsertPublicInChannelsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TagUpdatePublicInChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelWithoutPublicTagsUpdateInput"] | undefined | null
};
	["Contember_ChannelWithoutPublicTagsUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ChannelUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutChannelsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutChannelsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ChannelUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutChannelsUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeadlineTemplateWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeadlineTemplateWithoutVenueUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertDeadlineTemplateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeadlineTemplateUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeadlineTemplateWithoutVenueUpdateInput"]: {
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	cutoff?: number | undefined | null,
	closed?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpsertDeadlineTemplateRelationInput"]: {
	update?: ValueTypes["Contember_DeadlineTemplateWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeadlineTemplateWithoutVenueCreateInput"] | undefined | null
};
	["Contember_VenueUpdateTimetableDaysEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableDayWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateTimetableDaysRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertTimetableDaysRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateTimetableDaysRelationInput"]: {
	by?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableDayWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_TimetableDayWithoutVenueUpdateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	type?: ValueTypes["Contember_TimetableDayType"] | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableDayUpdateShiftGroupsEntityRelationInput"]> | undefined | null,
	tipsCents?: number | undefined | null,
	notes?: Array<ValueTypes["Contember_TimetableDayUpdateNotesEntityRelationInput"]> | undefined | null,
	meta?: ValueTypes["Contember_TimetableDayUpdateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayUpdateShiftGroupsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutDayCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableDayUpdateShiftGroupsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableDayUpsertShiftGroupsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableDayUpdateShiftGroupsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableShiftGroupWithoutDayUpdateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupWithoutDayUpdateInput"]: {
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftGroupUpsertTypeRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"]: {
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"] | undefined | null
};
	["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"]: {
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	position?: ValueTypes["Contember_TimetableTemplateUpdatePositionEntityRelationInput"] | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	venue?: ValueTypes["Contember_TimetableTemplateUpdateVenueEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateUpdatePositionEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableTemplateUpsertPositionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"]: {
	name?: string | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	shiftGroups?: Array<ValueTypes["Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput"]> | undefined | null,
	isDynamic?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutPositionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableShiftGroupWithoutPositionUpdateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupWithoutPositionUpdateInput"]: {
	order?: number | undefined | null,
	shifts?: Array<ValueTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"]: {
	create?: ValueTypes["Contember_EmployeeQueueWithoutShiftGroupCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftGroupUpsertQueueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_EmployeeQueueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"]: {
	items?: Array<ValueTypes["Contember_EmployeeQueueUpdateItemsEntityRelationInput"]> | undefined | null,
	contemberBugFix?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutQueueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_EmployeeQueueUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_EmployeeQueueUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_EmployeeQueueUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_EmployeeQueueItemWithoutQueueUpdateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemWithoutQueueUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	shiftsProfile?: ValueTypes["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	shift?: ValueTypes["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsProfileWithoutQueueItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"]: {
	staff?: ValueTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftWithoutShiftsProfileCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileUpdateShiftsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ShiftsProfileUpsertShiftsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ShiftsProfileUpdateShiftsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"] | undefined | null
};
	["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"]: {
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	group?: ValueTypes["Contember_TimetableShiftUpdateGroupEntityRelationInput"] | undefined | null,
	queueItem?: ValueTypes["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftUpdateGroupEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutShiftsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftUpsertGroupRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"]: {
	order?: number | undefined | null,
	day?: ValueTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined | null,
	position?: ValueTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined | null,
	queue?: ValueTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined | null,
	generatedByTemplate?: ValueTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftGroupUpsertPositionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined | null
};
	["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"]: {
	name?: string | undefined | null,
	start?: string | undefined | null,
	end?: string | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	isDynamic?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableTemplateWithoutPositionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableTemplateWithoutPositionUpdateInput"] | undefined | null
};
	["Contember_TimetableTemplateWithoutPositionUpdateInput"]: {
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	venue?: ValueTypes["Contember_TimetableTemplateUpdateVenueEntityRelationInput"] | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableTemplateUpdateStaffOptionsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableTemplateUpsertStaffOptionsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TimetableTemplateUpdateStaffOptionsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"] | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"]: {
	startTime?: string | undefined | null,
	endTime?: string | undefined | null,
	autoAssignedEmployee?: ValueTypes["Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsProfileCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null
};
	["Contember_ShiftsProfileUpdateInput"]: {
	queueItems?: Array<ValueTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined | null,
	staff?: ValueTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined | null,
	roles?: Array<ValueTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined | null,
	shifts?: Array<ValueTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined | null,
	allowSelfUnassigning?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput"]: {
	update?: ValueTypes["Contember_ShiftsProfileUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileCreateInput"] | undefined | null
};
	["Contember_TimetableTemplateUpsertStaffOptionsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"] | undefined | null
};
	["Contember_TimetableTemplateUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutTimetableTemplatesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutTimetableTemplatesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableTemplateUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutTimetableTemplatesUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdateStaffMembersEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffWithoutVenuesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateStaffMembersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertStaffMembersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateStaffMembersRelationInput"]: {
	by?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_StaffWithoutVenuesUpdateInput"] | undefined | null
};
	["Contember_StaffWithoutVenuesUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpdatePhotoEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertPhotoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffUpsertPhotoRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_StaffUpdateCourierManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CourierManagerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CourierManagerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertCourierManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CourierManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CourierManagerWithoutStaffUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertCourierManagerRelationInput"]: {
	update?: ValueTypes["Contember_CourierManagerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CourierManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdatePickerEntityRelationInput"]: {
	create?: ValueTypes["Contember_PickerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PickerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertPickerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PickerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PickerWithoutStaffUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertPickerRelationInput"]: {
	update?: ValueTypes["Contember_PickerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PickerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateRemunerationProfileEntityRelationInput"]: {
	create?: ValueTypes["Contember_RemunerationProfileWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_RemunerationProfileWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertRemunerationProfileRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_RemunerationProfileUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_RemunerationProfileWithoutStaffUpdateInput"]: {
	employmentInformation?: Array<ValueTypes["Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput"]> | undefined | null,
	workMonths?: ValueTypes["Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput"]: {
	create?: ValueTypes["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_RemunerationProfileUpdateEmploymentInformationRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_RemunerationProfileUpsertEmploymentInformationRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_RemunerationProfileUpdateEmploymentInformationRelationInput"]: {
	by?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"] | undefined | null
};
	["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"]: {
	hourlyRate?: number | undefined | null,
	monthlySalary?: number | undefined | null,
	type?: ValueTypes["Contember_EmploymentType"] | undefined | null,
	name?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_RemunerationProfileUpsertEmploymentInformationRelationInput"]: {
	by?: ValueTypes["Contember_EmploymentInformationUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"] | undefined | null
};
	["Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput"]: {
	create?: ValueTypes["Contember_WorkMonthWithoutRemunerationProfileCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_RemunerationProfileUpsertWorkMonthsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_WorkMonthUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"]: {
	year?: number | undefined | null,
	month?: number | undefined | null,
	hoursWorked?: number | undefined | null,
	billableHours?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_RemunerationProfileUpsertWorkMonthsRelationInput"]: {
	update?: ValueTypes["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_WorkMonthWithoutRemunerationProfileCreateInput"] | undefined | null
};
	["Contember_StaffUpsertRemunerationProfileRelationInput"]: {
	update?: ValueTypes["Contember_RemunerationProfileWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_RemunerationProfileWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateCashierEntityRelationInput"]: {
	create?: ValueTypes["Contember_CashierWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CashierWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertCashierRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CashierUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CashierWithoutStaffUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertCashierRelationInput"]: {
	update?: ValueTypes["Contember_CashierWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CashierWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateInternalBuyerEntityRelationInput"]: {
	create?: ValueTypes["Contember_InternalBuyerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_InternalBuyerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertInternalBuyerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_InternalBuyerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_InternalBuyerWithoutStaffUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertInternalBuyerRelationInput"]: {
	update?: ValueTypes["Contember_InternalBuyerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_InternalBuyerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateSalesManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_SalesManagerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SalesManagerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertSalesManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SalesManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SalesManagerWithoutStaffUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	countries?: Array<ValueTypes["Contember_SalesManagerUpdateCountriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SalesManagerUpdateCountriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SalesManagerUpdateCountriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_SalesManagerUpsertCountriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_SalesManagerUpdateCountriesRelationInput"]: {
	by?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CountryUpdateInput"] | undefined | null
};
	["Contember_SalesManagerUpsertCountriesRelationInput"]: {
	by?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CountryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null
};
	["Contember_StaffUpsertSalesManagerRelationInput"]: {
	update?: ValueTypes["Contember_SalesManagerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SalesManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateCashierManagerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CashierManagerWithoutStaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CashierManagerWithoutStaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertCashierManagerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CashierManagerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CashierManagerWithoutStaffUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertCashierManagerRelationInput"]: {
	update?: ValueTypes["Contember_CashierManagerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CashierManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_VenueUpsertStaffMembersRelationInput"]: {
	by?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_StaffWithoutVenuesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutVenuesCreateInput"] | undefined | null
};
	["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueEmployeeRoleWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateEmployeeRolesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertEmployeeRolesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateEmployeeRolesRelationInput"]: {
	by?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"]: {
	order?: number | undefined | null,
	employeePrivileges?: ValueTypes["Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput"] | undefined | null,
	role?: ValueTypes["Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput"] | undefined | null,
	getsTipsShare?: boolean | undefined | null,
	notesEnabled?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"]: {
	read?: boolean | undefined | null,
	unassign?: boolean | undefined | null,
	assign?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput"]: {
	update?: ValueTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"] | undefined | null
};
	["Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueEmployeeRoleUpsertRoleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null
};
	["Contember_VenueEmployeeRoleUpsertRoleRelationInput"]: {
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined | null
};
	["Contember_VenueUpsertEmployeeRolesRelationInput"]: {
	by?: ValueTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueEmployeeRoleWithoutVenueCreateInput"] | undefined | null
};
	["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"]: {
	create?: ValueTypes["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"]: {
	code?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	attempts?: Array<ValueTypes["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"]: {
	create?: ValueTypes["Contember_IdentificationAttemptWithoutCodeCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeUpdateAttemptsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IdentificationCodeUpsertAttemptsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IdentificationCodeUpdateAttemptsRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_IdentificationAttemptWithoutCodeUpdateInput"] | undefined | null
};
	["Contember_IdentificationAttemptWithoutCodeUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	user?: ValueTypes["Contember_IdentificationAttemptUpdateUserEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationAttemptUpdateUserEntityRelationInput"]: {
	create?: ValueTypes["Contember_UserWithoutIdentificationAttemptsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UserWithoutIdentificationAttemptsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IdentificationAttemptUpsertUserRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UserWithoutIdentificationAttemptsUpdateInput"]: {
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined | null,
	roles?: ValueTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserUpdateDevicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_UserDeviceWithoutUserCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UserUpdateDevicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UserUpsertDevicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_UserUpdateDevicesRelationInput"]: {
	by?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_UserDeviceWithoutUserUpdateInput"] | undefined | null
};
	["Contember_UserDeviceWithoutUserUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	deviceId?: string | undefined | null,
	notificationsEnabled?: boolean | undefined | null,
	appVersion?: string | undefined | null,
	osVersion?: string | undefined | null,
	apiVersion?: string | undefined | null,
	anonymousSession?: ValueTypes["Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput"]: {
	create?: ValueTypes["Contember_AnonymousSessionWithoutDevicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AnonymousSessionWithoutDevicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UserDeviceUpsertAnonymousSessionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AnonymousSessionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AnonymousSessionWithoutDevicesUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	customer?: ValueTypes["Contember_AnonymousSessionUpdateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AnonymousSessionUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutAnonymousSessionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutAnonymousSessionUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AnonymousSessionUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutAnonymousSessionUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateInvoicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_InvoiceWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateInvoicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertInvoicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateInvoicesRelationInput"]: {
	by?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_InvoiceWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_InvoiceWithoutCustomerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	orderPayments?: Array<ValueTypes["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]> | undefined | null,
	address?: ValueTypes["Contember_InvoiceUpdateAddressEntityRelationInput"] | undefined | null,
	publicUrl?: string | undefined | null,
	billingSubject?: ValueTypes["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderPaymentWithoutInvoiceCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_InvoiceUpdateOrderPaymentsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_InvoiceUpsertOrderPaymentsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_InvoiceUpdateOrderPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderPaymentWithoutInvoiceUpdateInput"] | undefined | null
};
	["Contember_OrderPaymentWithoutInvoiceUpdateInput"]: {
	data?: ValueTypes["Json"] | undefined | null,
	paymentCents?: number | undefined | null,
	method?: ValueTypes["Contember_OrderPaymentUpdateMethodEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	approvedAt?: ValueTypes["DateTime"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderPaymentUpdateEventsEntityRelationInput"]> | undefined | null,
	order?: ValueTypes["Contember_OrderPaymentUpdateOrderEntityRelationInput"] | undefined | null,
	walletType?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicReceiptUrl?: string | undefined | null,
	priceCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderPaymentUpdateVatRateEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentUpdateMetaEntityRelationInput"] | undefined | null,
	otherCurrencyPriceCents?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderPaymentUpdateMethodEntityRelationInput"]: {
	create?: ValueTypes["Contember_PaymentMethodCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PaymentMethodUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderPaymentUpsertMethodRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null
};
	["Contember_OrderPaymentUpsertMethodRelationInput"]: {
	update?: ValueTypes["Contember_PaymentMethodUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodCreateInput"] | undefined | null
};
	["Contember_OrderPaymentUpdateEventsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderEventWithoutPaymentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderPaymentUpdateEventsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderPaymentUpsertEventsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderPaymentUpdateEventsRelationInput"]: {
	by?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderEventWithoutPaymentUpdateInput"] | undefined | null
};
	["Contember_OrderEventWithoutPaymentUpdateInput"]: {
	type?: string | undefined | null,
	data?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_OrderEventUpdateOrderEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderEventUpdateDeliveryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderEventUpdateOrderEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutEventsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutEventsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderEventUpsertOrderRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderWithoutEventsUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateDeliveryEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderDeliveryWithoutOrderCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderDeliveryWithoutOrderUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertDeliveryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderDeliveryWithoutOrderUpdateInput"]: {
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]> | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["Contember_OrderDeliveryUpdateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDeliveryUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_OrderDeliveryUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryWaypointWithoutDeliveryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"]: {
	estimatedArrival?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	route?: ValueTypes["Contember_DeliveryWaypointUpdateRouteEntityRelationInput"] | undefined | null,
	sortingOrder?: number | undefined | null,
	estimatedDistance?: number | undefined | null,
	estimatedDuration?: number | undefined | null,
	originDeparture?: ValueTypes["DateTime"] | undefined | null,
	originAddress?: string | undefined | null,
	transitMode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryWaypointUpdateRouteEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryRouteWithoutWaypointsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryRouteWithoutWaypointsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryWaypointUpsertRouteRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryRouteWithoutWaypointsUpdateInput"]: {
	courier?: ValueTypes["Contember_DeliveryRouteUpdateCourierEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	dispatchAt?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryRouteUpdateCourierEntityRelationInput"]: {
	create?: ValueTypes["Contember_CourierWithoutRoutesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CourierWithoutRoutesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryRouteUpsertCourierRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CourierWithoutRoutesUpdateInput"]: {
	staff?: ValueTypes["Contember_CourierUpdateStaffEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	showInPicker?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CourierUpdateDeliveryMethodsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CourierUpsertDeliveryMethodsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CourierUpdateDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"] | undefined | null
};
	["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_DeliveryMethodUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	trackingCodeUrlTemplate?: string | undefined | null,
	processingTitle?: string | undefined | null,
	processingText?: string | undefined | null,
	processingIconUrl?: string | undefined | null,
	dispatchedTitle?: string | undefined | null,
	dispatchedText?: string | undefined | null,
	dispatchedIconUrl?: string | undefined | null,
	fulfilledTitle?: string | undefined | null,
	fulfilledText?: string | undefined | null,
	fulfilledIconUrl?: string | undefined | null,
	locale?: ValueTypes["Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutDeliveryMethodsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutDeliveryMethodsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutDeliveryMethodsUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateProductPackingsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductPackingLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateProductPackingsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertProductPackingsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateProductPackingsRelationInput"]: {
	by?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	shortName?: string | undefined | null,
	description?: string | undefined | null,
	root?: ValueTypes["Contember_ProductPackingLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductPackingWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductPackingWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductPackingLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductPackingUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductPackingWithoutLocalesUpdateInput"]: {
	name?: string | undefined | null,
	image?: ValueTypes["Contember_ProductPackingUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	volumeMl?: number | undefined | null,
	icon?: ValueTypes["Contember_ProductPackingUpdateIconEntityRelationInput"] | undefined | null,
	shortName?: string | undefined | null,
	preparedPackingQuantities?: Array<ValueTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"]> | undefined | null,
	isPackedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PreparedPackingQuantityWithoutPackingCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput"]: {
	by?: ValueTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"] | undefined | null
};
	["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"]: {
	date?: ValueTypes["Date"] | undefined | null,
	quantity?: number | undefined | null,
	recipe?: ValueTypes["Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput"] | undefined | null,
	packedInCardboard?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductRecipeCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PreparedPackingQuantityUpsertRecipeRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null
};
	["Contember_ProductRecipeUpdateInput"]: {
	name?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductRecipeUpdateProductsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductRecipeUpdateImageEntityRelationInput"] | undefined | null,
	icon?: ValueTypes["Contember_ProductRecipeUpdateIconEntityRelationInput"] | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductRecipeUpdateLocalesEntityRelationInput"]> | undefined | null,
	gutTuning?: number | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	nutritionFacts?: ValueTypes["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutRecipeCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeUpdateProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductRecipeUpdateProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductWithoutRecipeUpdateInput"] | undefined | null
};
	["Contember_ProductWithoutRecipeUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_ProductLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	featureList?: ValueTypes["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"] | undefined | null,
	printableName?: string | undefined | null,
	firstSticker?: string | undefined | null,
	secondSticker?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutProductsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutProductsUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateProductRecipesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateProductRecipesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertProductRecipesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateProductRecipesRelationInput"]: {
	by?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_ProductRecipeLocaleUpdateRootEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductRecipeWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductRecipeUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductRecipeWithoutLocalesUpdateInput"]: {
	name?: string | undefined | null,
	products?: Array<ValueTypes["Contember_ProductRecipeUpdateProductsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	color?: string | undefined | null,
	image?: ValueTypes["Contember_ProductRecipeUpdateImageEntityRelationInput"] | undefined | null,
	icon?: ValueTypes["Contember_ProductRecipeUpdateIconEntityRelationInput"] | undefined | null,
	ingredients?: Array<ValueTypes["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]> | undefined | null,
	needsPrepping?: boolean | undefined | null,
	gutTuning?: number | undefined | null,
	freshingContainer?: ValueTypes["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	nutritionFacts?: ValueTypes["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"]: {
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FreshingContainerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeUpsertFreshingContainerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FreshingContainerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductRecipeUpsertFreshingContainerRelationInput"]: {
	update?: ValueTypes["Contember_FreshingContainerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null
};
	["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"]: {
	create?: ValueTypes["Contember_NutritionFactsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_NutritionFactsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeUpsertNutritionFactsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_NutritionFactsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_NutritionFactsUpdateInput"]: {
	energyKiloJoule?: number | undefined | null,
	energyKiloCalorie?: number | undefined | null,
	fatsGrams?: number | undefined | null,
	fatsOfWhichSaturatedGrams?: number | undefined | null,
	carbohydrateGrams?: number | undefined | null,
	carbohydrateOfWhichSugarsGrams?: number | undefined | null,
	proteinGrams?: number | undefined | null,
	fibreGrams?: number | undefined | null,
	saltGrams?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeUpsertNutritionFactsRelationInput"]: {
	update?: ValueTypes["Contember_NutritionFactsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_NutritionFactsCreateInput"] | undefined | null
};
	["Contember_ProductRecipeLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_ProductRecipeWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertProductRecipesRelationInput"]: {
	by?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertPointOfSaleCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdatePointOfSaleCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"]: {
	title?: string | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"]: {
	order?: number | undefined | null,
	slug?: string | undefined | null,
	products?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryUpdateProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateProductsRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"]: {
	order?: number | undefined | null,
	product?: ValueTypes["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"] | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryProductUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null
};
	["Contember_ProductUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpdateCustomerPricesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateVirtualProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_VirtualProductWithoutProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VirtualProductWithoutProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertVirtualProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VirtualProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VirtualProductWithoutProductUpdateInput"]: {
	type?: ValueTypes["Contember_VirtualProductType"] | undefined | null,
	voucherCreditCents?: number | undefined | null,
	physicalRepresentation?: ValueTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined | null,
	recipientEmail?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductUpsertVirtualProductRelationInput"]: {
	update?: ValueTypes["Contember_VirtualProductWithoutProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductWithoutProductCreateInput"] | undefined | null
};
	["Contember_ProductUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductUpdatePageEntityRelationInput"]: {
	create?: ValueTypes["Contember_PageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertPageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PageUpdateInput"]: {
	locales?: Array<ValueTypes["Contember_PageUpdateLocalesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PageLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PageUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PageUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PageUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PageLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_PageLocaleWithoutRootUpdateInput"]: {
	title?: string | undefined | null,
	link?: ValueTypes["Contember_PageLocaleUpdateLinkEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_PageLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_PageLocaleUpdateSeoEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_PageLocaleUpdateContentEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_PageLocaleUpdateCoverEntityRelationInput"] | undefined | null,
	theme?: ValueTypes["Contember_PageTheme"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PageLocaleUpdateSeoEntityRelationInput"]: {
	create?: ValueTypes["Contember_SeoCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SeoUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PageLocaleUpsertSeoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SeoUpdateInput"]: {
	title?: string | undefined | null,
	description?: string | undefined | null,
	ogTitle?: string | undefined | null,
	ogDescription?: string | undefined | null,
	ogImage?: ValueTypes["Contember_SeoUpdateOgImageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeoUpdateOgImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_SeoUpsertOgImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SeoUpsertOgImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_PageLocaleUpsertSeoRelationInput"]: {
	update?: ValueTypes["Contember_SeoUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SeoCreateInput"] | undefined | null
};
	["Contember_PageLocaleUpdateContentEntityRelationInput"]: {
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ContentUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PageLocaleUpsertContentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PageLocaleUpsertContentRelationInput"]: {
	update?: ValueTypes["Contember_ContentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null
};
	["Contember_PageLocaleUpdateCoverEntityRelationInput"]: {
	create?: ValueTypes["Contember_CoverCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CoverUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PageLocaleUpsertCoverRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CoverUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PageLocaleUpsertCoverRelationInput"]: {
	update?: ValueTypes["Contember_CoverUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CoverCreateInput"] | undefined | null
};
	["Contember_PageUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PageLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PageLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_ProductUpsertPageRelationInput"]: {
	update?: ValueTypes["Contember_PageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PageCreateInput"] | undefined | null
};
	["Contember_ProductUpdateSalesDaysEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductSalesDayUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductSalesDayUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductSalesDayUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateSalesWeeksEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateSalesMonthsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]: {
	create?: ValueTypes["Contember_FulfillmentNoteWithoutProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateFulfillmentNotesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductUpsertFulfillmentNotesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpdateFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_FulfillmentNoteWithoutProductsUpdateInput"] | undefined | null
};
	["Contember_FulfillmentNoteWithoutProductsUpdateInput"]: {
	note?: string | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TagWithoutFulfillmentNotesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteUpdateTagsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_FulfillmentNoteUpsertTagsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteUpdateTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TagWithoutFulfillmentNotesUpdateInput"] | undefined | null
};
	["Contember_TagWithoutFulfillmentNotesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	customers?: Array<ValueTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	nestedCategories?: Array<ValueTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	publicInChannels?: Array<ValueTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined | null,
	allowChannelPayments?: Array<ValueTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteUpsertTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutFulfillmentNotesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutFulfillmentNotesCreateInput"] | undefined | null
};
	["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutFulfillmentNotesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteUpdateVenuesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_FulfillmentNoteUpsertVenuesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteUpdateVenuesRelationInput"]: {
	by?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VenueWithoutFulfillmentNotesUpdateInput"] | undefined | null
};
	["Contember_VenueWithoutFulfillmentNotesUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableTemplateWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateTimetableTemplatesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertTimetableTemplatesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateTimetableTemplatesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TimetableTemplateWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_TimetableTemplateWithoutVenueUpdateInput"]: {
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	position?: ValueTypes["Contember_TimetableTemplateUpdatePositionEntityRelationInput"] | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableTemplateUpsertEmployeeRoleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"]: {
	shiftGroups?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined | null,
	shiftsProfiles?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined | null,
	shiftsManagers?: Array<ValueTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableTemplateUpsertEmployeeRoleRelationInput"]: {
	update?: ValueTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"] | undefined | null
};
	["Contember_VenueUpsertTimetableTemplatesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableTemplateWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateWithoutVenueCreateInput"] | undefined | null
};
	["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleTerminalWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertPointOfSaleTerminalsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdatePointOfSaleTerminalsRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	applicationDevices?: Array<ValueTypes["Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput"]> | undefined | null,
	ipAddress?: string | undefined | null,
	port?: number | undefined | null,
	type?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdatePointOfSalePrintersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertPointOfSalePrintersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdatePointOfSalePrintersRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSalePrinterWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterWithoutVenueUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesReceipt?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]> | undefined | null,
	applicationDevicesTicket?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]> | undefined | null,
	ticketCategories?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeviceIdentifierWithoutDeviceCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeviceIdentifierWithoutDeviceUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeviceIdentifierWithoutDeviceUpdateInput"]: {
	internalName?: string | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	resolution?: string | undefined | null,
	devicePixelRatio?: number | undefined | null,
	updatedByUser?: ValueTypes["Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput"] | undefined | null,
	identifier?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput"]: {
	create?: ValueTypes["Contember_UserCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UserUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UserUpdateInput"]: {
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	devices?: Array<ValueTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]: {
	create?: ValueTypes["Contember_IdentificationAttemptWithoutUserCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UserUpdateIdentificationAttemptsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UserUpsertIdentificationAttemptsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_UserUpdateIdentificationAttemptsRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_IdentificationAttemptWithoutUserUpdateInput"] | undefined | null
};
	["Contember_IdentificationAttemptWithoutUserUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	code?: ValueTypes["Contember_IdentificationAttemptUpdateCodeEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationAttemptUpdateCodeEntityRelationInput"]: {
	create?: ValueTypes["Contember_IdentificationCodeWithoutAttemptsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeWithoutAttemptsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IdentificationAttemptUpsertCodeRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_IdentificationCodeWithoutAttemptsUpdateInput"]: {
	code?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	locales?: Array<ValueTypes["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	applicationDevices?: Array<ValueTypes["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_IdentificationCodeLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IdentificationCodeUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IdentificationCodeUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"]: {
	successMessage?: string | undefined | null,
	locale?: ValueTypes["Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutIdentificationCodesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutIdentificationCodesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IdentificationCodeLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutIdentificationCodesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_ProductLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	root?: ValueTypes["Contember_ProductLocaleUpdateRootEntityRelationInput"] | undefined | null,
	featureList?: ValueTypes["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"] | undefined | null,
	printableName?: string | undefined | null,
	firstSticker?: string | undefined | null,
	secondSticker?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductWithoutLocalesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	orderItems?: Array<ValueTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_ProductWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"]: {
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductLocaleUpsertFeatureListRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductLocaleUpsertFeatureListRelationInput"]: {
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"]: {
	title?: string | undefined | null,
	root?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"]: {
	order?: number | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput"] | undefined | null,
	product?: ValueTypes["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"]: {
	order?: number | undefined | null,
	slug?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"]: {
	title?: string | undefined | null,
	locale?: ValueTypes["Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateDeliveryMethodsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertDeliveryMethodsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	description?: string | undefined | null,
	trackingCodeUrlTemplate?: string | undefined | null,
	processingTitle?: string | undefined | null,
	processingText?: string | undefined | null,
	processingIconUrl?: string | undefined | null,
	dispatchedTitle?: string | undefined | null,
	dispatchedText?: string | undefined | null,
	dispatchedIconUrl?: string | undefined | null,
	fulfilledTitle?: string | undefined | null,
	fulfilledText?: string | undefined | null,
	fulfilledIconUrl?: string | undefined | null,
	root?: ValueTypes["Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryMethodWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryMethodWithoutLocalesUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	enabled?: boolean | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	requireDeliveryAddress?: boolean | undefined | null,
	suitableForDeliveryZones?: Array<ValueTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined | null,
	courierHandlers?: Array<ValueTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined | null,
	enableDeliveryTracking?: boolean | undefined | null,
	timelinePreset?: ValueTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_DeliveryMethodUpsertTimelinePresetRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryTimelinePresetUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"]: {
	type?: ValueTypes["Contember_DeliveryTimelineType"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_DeliveryMethodUpsertTimelinePresetRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryMethodWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]: {
	create?: ValueTypes["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateIngredientSuppliersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertIngredientSuppliersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateIngredientSuppliersRelationInput"]: {
	by?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_IngredientSupplierWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IngredientSupplierLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IngredientSupplierUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_IngredientSupplierWithoutLocalesUpdateInput"]: {
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_IngredientSupplierWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertIngredientSuppliersRelationInput"]: {
	by?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]: {
	create?: ValueTypes["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateIdentificationCodesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertIdentificationCodesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateIdentificationCodesRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"]: {
	successMessage?: string | undefined | null,
	root?: ValueTypes["Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_IdentificationCodeWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IdentificationCodeLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IdentificationCodeUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_IdentificationCodeWithoutLocalesUpdateInput"]: {
	code?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	attempts?: Array<ValueTypes["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	applicationDevices?: Array<ValueTypes["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeUpdateApplicationDevicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IdentificationCodeUpsertApplicationDevicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IdentificationCodeUpdateApplicationDevicesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput"] | undefined | null,
	ipAddress?: string | undefined | null,
	port?: number | undefined | null,
	type?: ValueTypes["Contember_PointOfSaleTerminalType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleTerminalUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdateOrdersEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateOrdersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertOrdersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_OrderWithoutVenueUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderItemWithoutOrderCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderItemWithoutOrderUpdateInput"] | undefined | null
};
	["Contember_OrderItemWithoutOrderUpdateInput"]: {
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffects?: Array<ValueTypes["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]> | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	product?: ValueTypes["Contember_OrderItemUpdateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]: {
	create?: ValueTypes["Contember_VirtualProductEffectWithoutOrderItemCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderItemUpdateVirtualProductEffectsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderItemUpsertVirtualProductEffectsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderItemUpdateVirtualProductEffectsRelationInput"]: {
	by?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"] | undefined | null
};
	["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	succeededAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	revertedAt?: ValueTypes["DateTime"] | undefined | null,
	resultCustomerCreditTransaction?: ValueTypes["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"] | undefined | null,
	resultVouchers?: Array<ValueTypes["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	amountCents?: number | undefined | null,
	customerCredit?: ValueTypes["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"]: {
	discountCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDiscountUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDiscountUpdateOrderEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	type?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	voucherRedemption?: ValueTypes["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDiscountUpdateOrderEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutDiscountsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutDiscountsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDiscountUpsertOrderRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderWithoutDiscountsUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateCartEntityRelationInput"]: {
	create?: ValueTypes["Contember_CartWithoutOrderCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CartWithoutOrderUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertCartRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CartWithoutOrderUpdateInput"]: {
	confirmedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CartUpdateItemsEntityRelationInput"]> | undefined | null,
	handle?: string | undefined | null,
	state?: ValueTypes["Contember_CartState"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	channel?: ValueTypes["Contember_CartUpdateChannelEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CartUpdateCustomerEntityRelationInput"] | undefined | null,
	deliveryAddress?: ValueTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined | null,
	billingAddress?: ValueTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined | null,
	deliveryOption?: string | undefined | null,
	paymentOption?: string | undefined | null,
	delivery?: ValueTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined | null,
	billingSubject?: ValueTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	summary?: ValueTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutCartsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutCartsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutCartsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"]: {
	create?: ValueTypes["Contember_InvoicingPlanWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_InvoicingPlanWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertInvoicingPlanRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_InvoicingPlanUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_InvoicingPlanWithoutCustomerUpdateInput"]: {
	preset?: ValueTypes["Contember_InvoicingPlanUpdatePresetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoicingPlanUpdatePresetEntityRelationInput"]: {
	create?: ValueTypes["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_InvoicingPlanUpsertPresetRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_InvoicingPresetUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"]: {
	type?: ValueTypes["Contember_InvoicingPresetType"] | undefined | null,
	startsAt?: ValueTypes["DateTime"] | undefined | null,
	endsAt?: ValueTypes["DateTime"] | undefined | null,
	intervalOfDays?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoicingPlanUpsertPresetRelationInput"]: {
	update?: ValueTypes["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertInvoicingPlanRelationInput"]: {
	update?: ValueTypes["Contember_InvoicingPlanWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_InvoicingPlanWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]: {
	create?: ValueTypes["Contember_BillingSubjectWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateBillingSubjectsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertBillingSubjectsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateBillingSubjectsRelationInput"]: {
	by?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BillingSubjectWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_BillingSubjectWithoutCustomerUpdateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectUpdateCountryEntityRelationInput"]: {
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CountryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BillingSubjectUpsertCountryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CountryUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BillingSubjectUpsertCountryRelationInput"]: {
	update?: ValueTypes["Contember_CountryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CountryCreateInput"] | undefined | null
};
	["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_InvoiceWithoutBillingSubjectCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BillingSubjectUpdateInvoicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BillingSubjectUpsertInvoicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BillingSubjectUpdateInvoicesRelationInput"]: {
	by?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_InvoiceWithoutBillingSubjectUpdateInput"] | undefined | null
};
	["Contember_InvoiceWithoutBillingSubjectUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	customer?: ValueTypes["Contember_InvoiceUpdateCustomerEntityRelationInput"] | undefined | null,
	orderPayments?: Array<ValueTypes["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]> | undefined | null,
	address?: ValueTypes["Contember_InvoiceUpdateAddressEntityRelationInput"] | undefined | null,
	publicUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceUpdateAddressEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressWithoutInvoicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressWithoutInvoicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_InvoiceUpsertAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AddressWithoutInvoicesUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutBillingAddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressUpdateBillingAddressOfOrdersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertBillingAddressOfOrdersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AddressUpdateBillingAddressOfOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderWithoutBillingAddressUpdateInput"] | undefined | null
};
	["Contember_OrderWithoutBillingAddressUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateEventsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderEventWithoutOrderCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderUpdateEventsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertEventsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderUpdateEventsRelationInput"]: {
	by?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderEventWithoutOrderUpdateInput"] | undefined | null
};
	["Contember_OrderEventWithoutOrderUpdateInput"]: {
	type?: string | undefined | null,
	data?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	payment?: ValueTypes["Contember_OrderEventUpdatePaymentEntityRelationInput"] | undefined | null,
	delivery?: ValueTypes["Contember_OrderEventUpdateDeliveryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderEventUpdatePaymentEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderPaymentWithoutEventsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderPaymentWithoutEventsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderEventUpsertPaymentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderPaymentWithoutEventsUpdateInput"]: {
	data?: ValueTypes["Json"] | undefined | null,
	paymentCents?: number | undefined | null,
	method?: ValueTypes["Contember_OrderPaymentUpdateMethodEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	approvedAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_OrderPaymentUpdateOrderEntityRelationInput"] | undefined | null,
	invoice?: ValueTypes["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"] | undefined | null,
	walletType?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicReceiptUrl?: string | undefined | null,
	priceCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderPaymentUpdateVatRateEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentUpdateMetaEntityRelationInput"] | undefined | null,
	otherCurrencyPriceCents?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderPaymentUpdateOrderEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutPaymentsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutPaymentsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderPaymentUpsertOrderRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderWithoutPaymentsUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateBillingAddressEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertBillingAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderDeliveryWithoutAddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput"]: {
	by?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderDeliveryWithoutAddressUpdateInput"] | undefined | null
};
	["Contember_OrderDeliveryWithoutAddressUpdateInput"]: {
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDeliveryUpdateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]> | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryUpdateOrderEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutDeliveryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutDeliveryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDeliveryUpsertOrderRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderWithoutDeliveryUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdatePriceLinesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderPriceLineUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderUpdatePaymentsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderPaymentWithoutOrderCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderUpdatePaymentsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertPaymentsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderUpdatePaymentsRelationInput"]: {
	by?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderPaymentWithoutOrderUpdateInput"] | undefined | null
};
	["Contember_OrderPaymentWithoutOrderUpdateInput"]: {
	data?: ValueTypes["Json"] | undefined | null,
	paymentCents?: number | undefined | null,
	method?: ValueTypes["Contember_OrderPaymentUpdateMethodEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderPaymentState"] | undefined | null,
	approvedAt?: ValueTypes["DateTime"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderPaymentUpdateEventsEntityRelationInput"]> | undefined | null,
	invoice?: ValueTypes["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"] | undefined | null,
	walletType?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicReceiptUrl?: string | undefined | null,
	priceCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderPaymentUpdateVatRateEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_OrderPaymentUpdateMetaEntityRelationInput"] | undefined | null,
	otherCurrencyPriceCents?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"]: {
	create?: ValueTypes["Contember_InvoiceWithoutOrderPaymentsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_InvoiceWithoutOrderPaymentsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderPaymentUpsertInvoiceRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_InvoiceWithoutOrderPaymentsUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	publicKey?: string | undefined | null,
	fakturoidId?: string | undefined | null,
	fakturoidData?: string | undefined | null,
	legacyUrl?: string | undefined | null,
	customer?: ValueTypes["Contember_InvoiceUpdateCustomerEntityRelationInput"] | undefined | null,
	address?: ValueTypes["Contember_InvoiceUpdateAddressEntityRelationInput"] | undefined | null,
	publicUrl?: string | undefined | null,
	billingSubject?: ValueTypes["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"]: {
	create?: ValueTypes["Contember_BillingSubjectWithoutInvoicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BillingSubjectWithoutInvoicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_InvoiceUpsertBillingSubjectRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BillingSubjectWithoutInvoicesUpdateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutBillingSubjectsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutBillingSubjectsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BillingSubjectUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutBillingSubjectsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]: {
	connect?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertDefaultDeliveryAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	deliveryAddressOfOrderDeliveries?: Array<ValueTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpsertDefaultDeliveryAddressRelationInput"]: {
	update?: ValueTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"]: {
	create?: ValueTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertDefaultBillingSubjectRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutBillingSubjectCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BillingSubjectUpdateOrdersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BillingSubjectUpsertOrdersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BillingSubjectUpdateOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderWithoutBillingSubjectUpdateInput"] | undefined | null
};
	["Contember_OrderWithoutBillingSubjectUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateSummaryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderSummaryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderUpdateGratuityEntityRelationInput"]: {
	create?: ValueTypes["Contember_GratuityWithoutOrderCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_GratuityWithoutOrderUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertGratuityRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_GratuityUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_GratuityWithoutOrderUpdateInput"]: {
	amountCents?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpsertGratuityRelationInput"]: {
	update?: ValueTypes["Contember_GratuityWithoutOrderUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_GratuityWithoutOrderCreateInput"] | undefined | null
};
	["Contember_OrderUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutOrdersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutOrdersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutOrdersUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]: {
	create?: ValueTypes["Contember_FulfillmentNoteWithoutVenuesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateFulfillmentNotesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertFulfillmentNotesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_FulfillmentNoteWithoutVenuesUpdateInput"] | undefined | null
};
	["Contember_FulfillmentNoteWithoutVenuesUpdateInput"]: {
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CartItemWithoutFulfillmentNotesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteUpdateCartItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_FulfillmentNoteUpsertCartItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteUpdateCartItemsRelationInput"]: {
	by?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CartItemWithoutFulfillmentNotesUpdateInput"] | undefined | null
};
	["Contember_CartItemWithoutFulfillmentNotesUpdateInput"]: {
	quantity?: number | undefined | null,
	cart?: ValueTypes["Contember_CartItemUpdateCartEntityRelationInput"] | undefined | null,
	product?: ValueTypes["Contember_CartItemUpdateProductEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_FulfillmentNoteUpsertCartItemsRelationInput"]: {
	by?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CartItemWithoutFulfillmentNotesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CartItemWithoutFulfillmentNotesCreateInput"] | undefined | null
};
	["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderItemWithoutFulfillmentNotesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteUpdateOrderItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_FulfillmentNoteUpsertOrderItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteUpdateOrderItemsRelationInput"]: {
	by?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"] | undefined | null
};
	["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"]: {
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderItemUpdateOrderEntityRelationInput"] | undefined | null,
	virtualProductEffects?: Array<ValueTypes["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]> | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	product?: ValueTypes["Contember_OrderItemUpdateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductWithoutOrderItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutOrderItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderItemUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductWithoutOrderItemsUpdateInput"]: {
	name?: string | undefined | null,
	code?: string | undefined | null,
	priceCents?: number | undefined | null,
	notDiscountable?: boolean | undefined | null,
	packing?: ValueTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined | null,
	recipe?: ValueTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined | null,
	vatRate?: ValueTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined | null,
	stocks?: Array<ValueTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined | null,
	groupPrices?: Array<ValueTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined | null,
	image?: ValueTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	internalNote?: string | undefined | null,
	cartItems?: Array<ValueTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined | null,
	availability?: ValueTypes["Contember_ProductAvailability"] | undefined | null,
	order?: number | undefined | null,
	manager?: ValueTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined | null,
	maximumQuantityPerOrder?: number | undefined | null,
	inheritedCategories?: Array<ValueTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined | null,
	deliveryZones?: Array<ValueTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined | null,
	archivedAt?: ValueTypes["DateTime"] | undefined | null,
	customerPrices?: Array<ValueTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined | null,
	virtualProduct?: ValueTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined | null,
	page?: ValueTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined | null,
	salesDays?: Array<ValueTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined | null,
	salesWeeks?: Array<ValueTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined | null,
	salesMonths?: Array<ValueTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	hasSubscription?: boolean | undefined | null,
	pointOfSaleTileColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductWithoutOrderItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutOrderItemsCreateInput"] | undefined | null
};
	["Contember_FulfillmentNoteUpsertOrderItemsRelationInput"]: {
	by?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutFulfillmentNotesCreateInput"] | undefined | null
};
	["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderWithoutFulfillmentNotesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteUpdateOrdersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_FulfillmentNoteUpsertOrdersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_FulfillmentNoteUpdateOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderWithoutFulfillmentNotesUpdateInput"] | undefined | null
};
	["Contember_OrderWithoutFulfillmentNotesUpdateInput"]: {
	state?: ValueTypes["Contember_OrderState"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	canceledAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	customer?: ValueTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined | null,
	channel?: ValueTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined | null,
	discounts?: Array<ValueTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined | null,
	delivery?: ValueTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined | null,
	items?: Array<ValueTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined | null,
	internalNote?: string | undefined | null,
	cart?: ValueTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined | null,
	events?: Array<ValueTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddress?: ValueTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined | null,
	priceLines?: Array<ValueTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined | null,
	payments?: Array<ValueTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined | null,
	seq?: number | undefined | null,
	billingSubject?: ValueTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined | null,
	summary?: ValueTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	gratuity?: ValueTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined | null,
	fulfillmentNote?: string | undefined | null,
	doubleshotTotalWeightGrams?: number | undefined | null,
	doubleShotLegacyNumber?: string | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	doubleshotAdminUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpdateBillingSubjectEntityRelationInput"]: {
	create?: ValueTypes["Contember_BillingSubjectWithoutOrdersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BillingSubjectWithoutOrdersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertBillingSubjectRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BillingSubjectWithoutOrdersUpdateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"]: {
	create?: ValueTypes["Contember_AnonymousSessionWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AnonymousSessionWithoutCustomerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertAnonymousSessionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AnonymousSessionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AnonymousSessionWithoutCustomerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	anonymousSessionKey?: string | undefined | null,
	email?: string | undefined | null,
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	devices?: Array<ValueTypes["Contember_AnonymousSessionUpdateDevicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AnonymousSessionUpdateDevicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_UserDeviceWithoutAnonymousSessionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AnonymousSessionUpdateDevicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AnonymousSessionUpsertDevicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AnonymousSessionUpdateDevicesRelationInput"]: {
	by?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"] | undefined | null
};
	["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	lastActiveAt?: ValueTypes["DateTime"] | undefined | null,
	platform?: string | undefined | null,
	deviceId?: string | undefined | null,
	notificationsEnabled?: boolean | undefined | null,
	user?: ValueTypes["Contember_UserDeviceUpdateUserEntityRelationInput"] | undefined | null,
	appVersion?: string | undefined | null,
	osVersion?: string | undefined | null,
	apiVersion?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserDeviceUpdateUserEntityRelationInput"]: {
	create?: ValueTypes["Contember_UserWithoutDevicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UserWithoutDevicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UserDeviceUpsertUserRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UserUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UserWithoutDevicesUpdateInput"]: {
	name?: string | undefined | null,
	internalNote?: string | undefined | null,
	staff?: ValueTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined | null,
	tenantPersonId?: ValueTypes["UUID"] | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	verifiedAt?: ValueTypes["DateTime"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletionRequestedAt?: ValueTypes["DateTime"] | undefined | null,
	deletionExpectedAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	meta?: ValueTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined | null,
	identificationAttempts?: Array<ValueTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined | null,
	roles?: ValueTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UserUpdateRolesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_UserRolesUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UserDeviceUpsertUserRelationInput"]: {
	update?: ValueTypes["Contember_UserWithoutDevicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutDevicesCreateInput"] | undefined | null
};
	["Contember_AnonymousSessionUpsertDevicesRelationInput"]: {
	by?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UserDeviceWithoutAnonymousSessionCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertAnonymousSessionRelationInput"]: {
	update?: ValueTypes["Contember_AnonymousSessionWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AnonymousSessionWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherWithoutBuyerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateBoughtVouchersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertBoughtVouchersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateBoughtVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VoucherWithoutBuyerUpdateInput"] | undefined | null
};
	["Contember_VoucherWithoutBuyerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	owner?: ValueTypes["Contember_VoucherUpdateOwnerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherUpdateRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherUpdateOwnerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutOwnsVouchersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutOwnsVouchersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherUpsertOwnerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutOwnsVouchersUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateRedeemedVouchersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertRedeemedVouchersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateRedeemedVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_VoucherRedemptionWithoutCustomerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	customerCredit?: ValueTypes["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherRedemptionUpsertCustomerCreditRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"]: {
	initialCreditCents?: number | undefined | null,
	remainingCreditsCents?: number | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	reason?: string | undefined | null,
	customer?: ValueTypes["Contember_CustomerCreditUpdateCustomerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_CustomerCreditUpdateVatRateEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	transactions?: Array<ValueTypes["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"]> | undefined | null,
	invoiceUrl?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerCreditUpdateTransactionsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerCreditUpsertTransactionsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerCreditUpdateTransactionsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"] | undefined | null
};
	["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	amountCents?: number | undefined | null,
	orderDiscount?: ValueTypes["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"]: {
	create?: ValueTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	succeededAt?: ValueTypes["DateTime"] | undefined | null,
	orderItem?: ValueTypes["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	revertedAt?: ValueTypes["DateTime"] | undefined | null,
	resultVouchers?: Array<ValueTypes["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VirtualProductEffectUpsertOrderItemRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"]: {
	unitPriceCents?: number | undefined | null,
	quantity?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderItemUpdateOrderEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	product?: ValueTypes["Contember_OrderItemUpdateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]: {
	create?: ValueTypes["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderItemUpdateFulfillmentNotesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderItemUpsertFulfillmentNotesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderItemUpdateFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"] | undefined | null
};
	["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"]: {
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderItemUpsertFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"] | undefined | null
};
	["Contember_VirtualProductEffectUpsertOrderItemRelationInput"]: {
	update?: ValueTypes["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"] | undefined | null
};
	["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherWithoutVirtualProductEffectCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VirtualProductEffectUpdateResultVouchersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VirtualProductEffectUpsertResultVouchersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VirtualProductEffectUpdateResultVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VoucherWithoutVirtualProductEffectUpdateInput"] | undefined | null
};
	["Contember_VoucherWithoutVirtualProductEffectUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	buyer?: ValueTypes["Contember_VoucherUpdateBuyerEntityRelationInput"] | undefined | null,
	owner?: ValueTypes["Contember_VoucherUpdateOwnerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherUpdateRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherUpdateBuyerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutBoughtVouchersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutBoughtVouchersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherUpsertBuyerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutBoughtVouchersUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherWithoutOwnerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateOwnsVouchersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertOwnsVouchersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateOwnsVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VoucherWithoutOwnerUpdateInput"] | undefined | null
};
	["Contember_VoucherWithoutOwnerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	buyer?: ValueTypes["Contember_VoucherUpdateBuyerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"] | undefined | null,
	redemption?: ValueTypes["Contember_VoucherUpdateRedemptionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null
};
	["Contember_VoucherUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"]: {
	create?: ValueTypes["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherUpsertVirtualProductEffectRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	succeededAt?: ValueTypes["DateTime"] | undefined | null,
	orderItem?: ValueTypes["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	revertedAt?: ValueTypes["DateTime"] | undefined | null,
	resultCustomerCreditTransaction?: ValueTypes["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherUpsertVirtualProductEffectRelationInput"]: {
	update?: ValueTypes["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"] | undefined | null
};
	["Contember_VoucherUpdateRedemptionEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherRedemptionWithoutVoucherCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VoucherRedemptionWithoutVoucherUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherUpsertRedemptionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VoucherRedemptionWithoutVoucherUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	customerCredit?: ValueTypes["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"] | undefined | null,
	orderDiscount?: ValueTypes["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherRedemptionUpsertOrderDiscountRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"]: {
	discountCents?: number | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDiscountUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDiscountUpdateOrderEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	type?: ValueTypes["Contember_OrderDiscountType"] | undefined | null,
	customerCreditTransactions?: Array<ValueTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionUpsertOrderDiscountRelationInput"]: {
	update?: ValueTypes["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"] | undefined | null
};
	["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutRedeemedVouchersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutRedeemedVouchersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherRedemptionUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutRedeemedVouchersUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateSpendingEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerSpendingUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerTabWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateCustomerTabsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertCustomerTabsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateCustomerTabsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerTabWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_CustomerTabWithoutCustomerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	color?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CustomerTabUpdateItemsEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabUpdateMetaEntityRelationInput"] | undefined | null,
	table?: ValueTypes["Contember_CustomerTabUpdateTableEntityRelationInput"] | undefined | null,
	seat?: ValueTypes["Contember_CustomerTabUpdateSeatEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerTabUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerTabItemWithoutTabCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerTabUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerTabUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerTabUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerTabItemWithoutTabUpdateInput"] | undefined | null
};
	["Contember_CustomerTabItemWithoutTabUpdateInput"]: {
	addedAt?: ValueTypes["DateTime"] | undefined | null,
	product?: ValueTypes["Contember_CustomerTabItemUpdateProductEntityRelationInput"] | undefined | null,
	paidAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerTabItemUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerTabItemUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerTabItemUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_CustomerTabUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerTabItemWithoutTabUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabItemWithoutTabCreateInput"] | undefined | null
};
	["Contember_CustomerTabUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerTabUpdateTableEntityRelationInput"]: {
	create?: ValueTypes["Contember_TableWithoutCustomerTabsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TableWithoutCustomerTabsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerTabUpsertTableRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TableWithoutCustomerTabsUpdateInput"]: {
	shape?: ValueTypes["Contember_TableShape"] | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	area?: ValueTypes["Contember_TableUpdateAreaEntityRelationInput"] | undefined | null,
	seats?: Array<ValueTypes["Contember_TableUpdateSeatsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TableUpdateAreaEntityRelationInput"]: {
	create?: ValueTypes["Contember_AreaWithoutTablesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AreaWithoutTablesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TableUpsertAreaRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AreaWithoutTablesUpdateInput"]: {
	order?: number | undefined | null,
	name?: string | undefined | null,
	venue?: ValueTypes["Contember_AreaUpdateVenueEntityRelationInput"] | undefined | null,
	lines?: Array<ValueTypes["Contember_AreaUpdateLinesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AreaUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutAreasCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutAreasUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AreaUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutAreasUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]: {
	create?: ValueTypes["Contember_UnassignReasonWithoutVenuesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertEnabledUnassignReasonsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateEnabledUnassignReasonsRelationInput"]: {
	by?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_UnassignReasonWithoutVenuesUpdateInput"] | undefined | null
};
	["Contember_UnassignReasonWithoutVenuesUpdateInput"]: {
	order?: number | undefined | null,
	name?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpsertEnabledUnassignReasonsRelationInput"]: {
	by?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_UnassignReasonWithoutVenuesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UnassignReasonWithoutVenuesCreateInput"] | undefined | null
};
	["Contember_VenueUpdateCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"]: {
	order?: number | undefined | null,
	slug?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined | null,
	ticketPrinters?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryUpsertCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryUpsertCategoryRelationInput"]: {
	update?: ValueTypes["Contember_ProductCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesReceipt?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]> | undefined | null,
	applicationDevicesTicket?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined | null,
	identificationCode?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined | null,
	identifier?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined | null,
	terminal?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined | null,
	receiptPrinter?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesTicket?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"] | undefined | null,
	ticketCategories?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutPointOfSalePrintersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutPointOfSalePrintersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSalePrinterUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutPointOfSalePrintersUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpdateAreasEntityRelationInput"]: {
	create?: ValueTypes["Contember_AreaWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateAreasRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertAreasRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateAreasRelationInput"]: {
	by?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_AreaWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_AreaWithoutVenueUpdateInput"]: {
	order?: number | undefined | null,
	name?: string | undefined | null,
	tables?: Array<ValueTypes["Contember_AreaUpdateTablesEntityRelationInput"]> | undefined | null,
	lines?: Array<ValueTypes["Contember_AreaUpdateLinesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AreaUpdateTablesEntityRelationInput"]: {
	create?: ValueTypes["Contember_TableWithoutAreaCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AreaUpdateTablesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AreaUpsertTablesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AreaUpdateTablesRelationInput"]: {
	by?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_TableWithoutAreaUpdateInput"] | undefined | null
};
	["Contember_TableWithoutAreaUpdateInput"]: {
	shape?: ValueTypes["Contember_TableShape"] | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_TableUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	seats?: Array<ValueTypes["Contember_TableUpdateSeatsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TableUpdateCustomerTabsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerTabWithoutTableCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TableUpdateCustomerTabsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TableUpsertCustomerTabsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TableUpdateCustomerTabsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerTabWithoutTableUpdateInput"] | undefined | null
};
	["Contember_CustomerTabWithoutTableUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	color?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CustomerTabUpdateItemsEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabUpdateMetaEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerTabUpdateCustomerEntityRelationInput"] | undefined | null,
	seat?: ValueTypes["Contember_CustomerTabUpdateSeatEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerTabUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutCustomerTabsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutCustomerTabsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerTabUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutCustomerTabsUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateLeadEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineLeadWithoutCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerUpdateLeadRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerUpsertLeadRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CustomerUpdateLeadRelationInput"]: {
	by?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PipelineLeadWithoutCustomerUpdateInput"] | undefined | null
};
	["Contember_PipelineLeadWithoutCustomerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	stageItem?: ValueTypes["Contember_PipelineLeadUpdateStageItemEntityRelationInput"] | undefined | null,
	contactPerson?: string | undefined | null,
	cafeType?: string | undefined | null,
	offerType?: string | undefined | null,
	ownMachine?: boolean | undefined | null,
	customerCity?: string | undefined | null,
	companyId?: string | undefined | null,
	customerName?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	form?: ValueTypes["Contember_PipelineLeadUpdateFormEntityRelationInput"] | undefined | null,
	meta?: string | undefined | null,
	multiroasterType?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineLeadUpdateStageItemEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageItemWithoutLeadCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageItemWithoutLeadUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineLeadUpsertStageItemRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineStageItemWithoutLeadUpdateInput"]: {
	list?: ValueTypes["Contember_PipelineStageItemUpdateListEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageItemUpdateListEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageWithoutItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageWithoutItemsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageItemUpsertListRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineStageWithoutItemsUpdateInput"]: {
	pipeline?: ValueTypes["Contember_PipelineStageUpdatePipelineEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	locales?: Array<ValueTypes["Contember_PipelineStageUpdateLocalesEntityRelationInput"]> | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageUpdateSentimentEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	forms?: Array<ValueTypes["Contember_PipelineStageUpdateFormsEntityRelationInput"]> | undefined | null,
	showItemsWeight?: boolean | undefined | null,
	showItemsPrice?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageUpdatePipelineEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineWithoutStagesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineWithoutStagesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageUpsertPipelineRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineWithoutStagesUpdateInput"]: {
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageUpsertPipelineRelationInput"]: {
	update?: ValueTypes["Contember_PipelineWithoutStagesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineWithoutStagesCreateInput"] | undefined | null
};
	["Contember_PipelineStageUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineStageUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PipelineStageLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_PipelineStageLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutPipelineStagesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutPipelineStagesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutPipelineStagesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]: {
	create?: ValueTypes["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateSubscriptionBoxesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertSubscriptionBoxesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateSubscriptionBoxesRelationInput"]: {
	by?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"]: {
	title?: string | undefined | null,
	stickerText?: string | undefined | null,
	descriptionText?: string | undefined | null,
	root?: ValueTypes["Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput"] | undefined | null,
	secondaryTitle?: string | undefined | null,
	texts?: ValueTypes["Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput"] | undefined | null,
	secondaryTexts?: ValueTypes["Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_SubscriptionBoxWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SubscriptionBoxWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_SubscriptionBoxLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SubscriptionBoxWithoutLocalesUpdateInput"]: {
	unique?: ValueTypes["Contember_One"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SubscriptionBoxLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_SubscriptionBoxWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SubscriptionBoxWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_SubscriptionBoxLocaleUpsertTextsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SubscriptionBoxLocaleUpsertTextsRelationInput"]: {
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput"]: {
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput"]: {
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertSubscriptionBoxesRelationInput"]: {
	by?: ValueTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdateBusinessServicesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertBusinessServicesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdateBusinessServicesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	labelNote?: string | undefined | null,
	root?: ValueTypes["Contember_BusinessServiceLocaleUpdateRootEntityRelationInput"] | undefined | null,
	labelNumberOfPeople?: string | undefined | null,
	labelInvoiced?: string | undefined | null,
	labelEquipment?: string | undefined | null,
	labelMillTamDem?: string | undefined | null,
	labelSOEspresso?: string | undefined | null,
	labelFilterCoffeeInMenu?: string | undefined | null,
	labelWaterMeterStatus?: string | undefined | null,
	labelServiceAction?: string | undefined | null,
	labelInvoiceIdentifier?: string | undefined | null,
	labelSupervisor?: string | undefined | null,
	labelContact?: string | undefined | null,
	labelPhone?: string | undefined | null,
	labelEmail?: string | undefined | null,
	labelGrindingStonesCondition?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessServiceWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessServiceWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessServiceLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessServiceUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessServiceWithoutLocalesUpdateInput"]: {
	order?: number | undefined | null,
	requireNote?: number | undefined | null,
	requireNumberOfPeople?: number | undefined | null,
	requireInvoiced?: number | undefined | null,
	requireEquipment?: number | undefined | null,
	requireMillTamDem?: number | undefined | null,
	requireSOEspresso?: number | undefined | null,
	requireFilterCoffeeInMenu?: number | undefined | null,
	requireWaterMeterStatus?: number | undefined | null,
	requireServiceAction?: number | undefined | null,
	optionsEquipment?: string | undefined | null,
	optionsServiceAction?: string | undefined | null,
	requireInvoiceIdentifier?: number | undefined | null,
	requireSupervisor?: number | undefined | null,
	requireContact?: number | undefined | null,
	requirePhone?: number | undefined | null,
	requireEmail?: number | undefined | null,
	requireGrindingStonesCondition?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_BusinessServiceWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertBusinessServicesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_PipelineStageLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutPipelineStagesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPipelineStagesCreateInput"] | undefined | null
};
	["Contember_PipelineStageUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_PipelineStageUpdateSentimentEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageSentimentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageSentimentUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageUpsertSentimentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageSentimentUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineStageSentimentUpdateInput"]: {
	internalName?: string | undefined | null,
	backgroundColor?: string | undefined | null,
	textColor?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageUpsertSentimentRelationInput"]: {
	update?: ValueTypes["Contember_PipelineStageSentimentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageSentimentCreateInput"] | undefined | null
};
	["Contember_PipelineStageUpdateFormsEntityRelationInput"]: {
	create?: ValueTypes["Contember_LeadFormWithoutStageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageUpdateFormsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageUpsertFormsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineStageUpdateFormsRelationInput"]: {
	by?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_LeadFormWithoutStageUpdateInput"] | undefined | null
};
	["Contember_LeadFormWithoutStageUpdateInput"]: {
	code?: string | undefined | null,
	leads?: Array<ValueTypes["Contember_LeadFormUpdateLeadsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LeadFormUpdateLeadsEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineLeadWithoutFormCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LeadFormUpdateLeadsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LeadFormUpsertLeadsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LeadFormUpdateLeadsRelationInput"]: {
	by?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PipelineLeadWithoutFormUpdateInput"] | undefined | null
};
	["Contember_PipelineLeadWithoutFormUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	stageItem?: ValueTypes["Contember_PipelineLeadUpdateStageItemEntityRelationInput"] | undefined | null,
	contactPerson?: string | undefined | null,
	customer?: ValueTypes["Contember_PipelineLeadUpdateCustomerEntityRelationInput"] | undefined | null,
	cafeType?: string | undefined | null,
	offerType?: string | undefined | null,
	ownMachine?: boolean | undefined | null,
	customerCity?: string | undefined | null,
	companyId?: string | undefined | null,
	customerName?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	meta?: string | undefined | null,
	multiroasterType?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineLeadUpdateCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutLeadCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutLeadUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineLeadUpsertCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutLeadUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CustomerMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineLeadUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutLeadUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutLeadCreateInput"] | undefined | null
};
	["Contember_LeadFormUpsertLeadsRelationInput"]: {
	by?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PipelineLeadWithoutFormUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineLeadWithoutFormCreateInput"] | undefined | null
};
	["Contember_PipelineStageUpsertFormsRelationInput"]: {
	by?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_LeadFormWithoutStageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LeadFormWithoutStageCreateInput"] | undefined | null
};
	["Contember_PipelineStageItemUpsertListRelationInput"]: {
	update?: ValueTypes["Contember_PipelineStageWithoutItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageWithoutItemsCreateInput"] | undefined | null
};
	["Contember_PipelineLeadUpsertStageItemRelationInput"]: {
	update?: ValueTypes["Contember_PipelineStageItemWithoutLeadUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageItemWithoutLeadCreateInput"] | undefined | null
};
	["Contember_PipelineLeadUpdateFormEntityRelationInput"]: {
	create?: ValueTypes["Contember_LeadFormWithoutLeadsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LeadFormWithoutLeadsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineLeadUpsertFormRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LeadFormUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LeadFormWithoutLeadsUpdateInput"]: {
	code?: string | undefined | null,
	stage?: ValueTypes["Contember_LeadFormUpdateStageEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_LeadFormUpdateStageEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageWithoutFormsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageWithoutFormsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LeadFormUpsertStageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineStageWithoutFormsUpdateInput"]: {
	items?: Array<ValueTypes["Contember_PipelineStageUpdateItemsEntityRelationInput"]> | undefined | null,
	pipeline?: ValueTypes["Contember_PipelineStageUpdatePipelineEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	locales?: Array<ValueTypes["Contember_PipelineStageUpdateLocalesEntityRelationInput"]> | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageUpdateSentimentEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	showItemsWeight?: boolean | undefined | null,
	showItemsPrice?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PipelineStageUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PipelineStageItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_PipelineStageItemWithoutListUpdateInput"]: {
	lead?: ValueTypes["Contember_PipelineStageItemUpdateLeadEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageItemUpdateLeadEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineLeadWithoutStageItemCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineLeadWithoutStageItemUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageItemUpsertLeadRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineLeadWithoutStageItemUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	contactPerson?: string | undefined | null,
	customer?: ValueTypes["Contember_PipelineLeadUpdateCustomerEntityRelationInput"] | undefined | null,
	cafeType?: string | undefined | null,
	offerType?: string | undefined | null,
	ownMachine?: boolean | undefined | null,
	customerCity?: string | undefined | null,
	companyId?: string | undefined | null,
	customerName?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	form?: ValueTypes["Contember_PipelineLeadUpdateFormEntityRelationInput"] | undefined | null,
	meta?: string | undefined | null,
	multiroasterType?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageItemUpsertLeadRelationInput"]: {
	update?: ValueTypes["Contember_PipelineLeadWithoutStageItemUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineLeadWithoutStageItemCreateInput"] | undefined | null
};
	["Contember_PipelineStageUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_PipelineStageItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageItemWithoutListCreateInput"] | undefined | null
};
	["Contember_LeadFormUpsertStageRelationInput"]: {
	update?: ValueTypes["Contember_PipelineStageWithoutFormsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageWithoutFormsCreateInput"] | undefined | null
};
	["Contember_PipelineLeadUpsertFormRelationInput"]: {
	update?: ValueTypes["Contember_LeadFormWithoutLeadsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LeadFormWithoutLeadsCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertLeadRelationInput"]: {
	by?: ValueTypes["Contember_PipelineLeadUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PipelineLeadWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineLeadWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerTabUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutCustomerTabsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutCustomerTabsCreateInput"] | undefined | null
};
	["Contember_CustomerTabUpdateSeatEntityRelationInput"]: {
	create?: ValueTypes["Contember_SeatWithoutCustomerTabsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SeatWithoutCustomerTabsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerTabUpsertSeatRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_SeatWithoutCustomerTabsUpdateInput"]: {
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	table?: ValueTypes["Contember_SeatUpdateTableEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeatUpdateTableEntityRelationInput"]: {
	create?: ValueTypes["Contember_TableWithoutSeatsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TableWithoutSeatsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_SeatUpsertTableRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TableWithoutSeatsUpdateInput"]: {
	shape?: ValueTypes["Contember_TableShape"] | undefined | null,
	width?: number | undefined | null,
	height?: number | undefined | null,
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_TableUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	area?: ValueTypes["Contember_TableUpdateAreaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeatUpsertTableRelationInput"]: {
	update?: ValueTypes["Contember_TableWithoutSeatsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TableWithoutSeatsCreateInput"] | undefined | null
};
	["Contember_CustomerTabUpsertSeatRelationInput"]: {
	update?: ValueTypes["Contember_SeatWithoutCustomerTabsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SeatWithoutCustomerTabsCreateInput"] | undefined | null
};
	["Contember_TableUpsertCustomerTabsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerTabWithoutTableUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabWithoutTableCreateInput"] | undefined | null
};
	["Contember_TableUpdateSeatsEntityRelationInput"]: {
	create?: ValueTypes["Contember_SeatWithoutTableCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TableUpdateSeatsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TableUpsertSeatsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_TableUpdateSeatsRelationInput"]: {
	by?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_SeatWithoutTableUpdateInput"] | undefined | null
};
	["Contember_SeatWithoutTableUpdateInput"]: {
	positionX?: number | undefined | null,
	positionY?: number | undefined | null,
	name?: string | undefined | null,
	color?: string | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_SeatUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeatUpdateCustomerTabsEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerTabWithoutSeatCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SeatUpdateCustomerTabsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_SeatUpsertCustomerTabsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_SeatUpdateCustomerTabsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_CustomerTabWithoutSeatUpdateInput"] | undefined | null
};
	["Contember_CustomerTabWithoutSeatUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	color?: string | undefined | null,
	items?: Array<ValueTypes["Contember_CustomerTabUpdateItemsEntityRelationInput"]> | undefined | null,
	name?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerTabUpdateMetaEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_CustomerTabUpdateCustomerEntityRelationInput"] | undefined | null,
	table?: ValueTypes["Contember_CustomerTabUpdateTableEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_SeatUpsertCustomerTabsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerTabWithoutSeatUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabWithoutSeatCreateInput"] | undefined | null
};
	["Contember_TableUpsertSeatsRelationInput"]: {
	by?: ValueTypes["Contember_SeatUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_SeatWithoutTableUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SeatWithoutTableCreateInput"] | undefined | null
};
	["Contember_AreaUpsertTablesRelationInput"]: {
	by?: ValueTypes["Contember_TableUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TableWithoutAreaUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TableWithoutAreaCreateInput"] | undefined | null
};
	["Contember_AreaUpdateLinesEntityRelationInput"]: {
	create?: ValueTypes["Contember_LineWithoutAreaCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AreaUpdateLinesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AreaUpsertLinesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_AreaUpdateLinesRelationInput"]: {
	by?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_LineWithoutAreaUpdateInput"] | undefined | null
};
	["Contember_LineWithoutAreaUpdateInput"]: {
	positionStartX?: number | undefined | null,
	positionStartY?: number | undefined | null,
	positionEndX?: number | undefined | null,
	positionEndY?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AreaUpsertLinesRelationInput"]: {
	by?: ValueTypes["Contember_LineUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_LineWithoutAreaUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LineWithoutAreaCreateInput"] | undefined | null
};
	["Contember_VenueUpsertAreasRelationInput"]: {
	by?: ValueTypes["Contember_AreaUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_AreaWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AreaWithoutVenueCreateInput"] | undefined | null
};
	["Contember_VenueUpdateChannelsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelWithoutVenueCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueUpdateChannelsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VenueUpsertChannelsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_VenueUpdateChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ChannelWithoutVenueUpdateInput"] | undefined | null
};
	["Contember_ChannelWithoutVenueUpdateInput"]: {
	name?: string | undefined | null,
	disabled?: boolean | undefined | null,
	payments?: Array<ValueTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined | null,
	deliveries?: Array<ValueTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined | null,
	pointOfSales?: Array<ValueTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined | null,
	customers?: Array<ValueTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined | null,
	code?: ValueTypes["Contember_ChannelCode"] | undefined | null,
	addTagsOnRegistration?: Array<ValueTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined | null,
	publicTags?: Array<ValueTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VenueUpsertChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutVenueCreateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutPointOfSalePrintersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutPointOfSalePrintersCreateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"]: {
	order?: number | undefined | null,
	slug?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]> | undefined | null,
	category?: ValueTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined | null,
	venue?: ValueTypes["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutCategoriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutCategoriesUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutCategoriesCreateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput"]: {
	update?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"] | undefined | null
};
	["Contember_VenueUpsertCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutVenueCreateInput"] | undefined | null
};
	["Contember_AreaUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutAreasUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutAreasCreateInput"] | undefined | null
};
	["Contember_TableUpsertAreaRelationInput"]: {
	update?: ValueTypes["Contember_AreaWithoutTablesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AreaWithoutTablesCreateInput"] | undefined | null
};
	["Contember_CustomerTabUpsertTableRelationInput"]: {
	update?: ValueTypes["Contember_TableWithoutCustomerTabsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TableWithoutCustomerTabsCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertCustomerTabsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerTabUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerTabWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerTabWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_VoucherRedemptionUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutRedeemedVouchersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutRedeemedVouchersCreateInput"] | undefined | null
};
	["Contember_VoucherUpsertRedemptionRelationInput"]: {
	update?: ValueTypes["Contember_VoucherRedemptionWithoutVoucherUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutVoucherCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertOwnsVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VoucherWithoutOwnerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutOwnerCreateInput"] | undefined | null
};
	["Contember_VoucherUpsertBuyerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutBoughtVouchersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutBoughtVouchersCreateInput"] | undefined | null
};
	["Contember_VirtualProductEffectUpsertResultVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VoucherWithoutVirtualProductEffectUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutVirtualProductEffectCreateInput"] | undefined | null
};
	["Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput"]: {
	update?: ValueTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"] | undefined | null
};
	["Contember_CustomerCreditUpsertTransactionsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"] | undefined | null
};
	["Contember_VoucherRedemptionUpsertCustomerCreditRelationInput"]: {
	update?: ValueTypes["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"] | undefined | null
};
	["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherWithoutRedemptionCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VoucherWithoutRedemptionUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_VoucherRedemptionUpsertVoucherRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VoucherWithoutRedemptionUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	activatedAt?: ValueTypes["DateTime"] | undefined | null,
	expiresAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	note?: string | undefined | null,
	code?: string | undefined | null,
	type?: ValueTypes["Contember_VoucherType"] | undefined | null,
	creditCents?: number | undefined | null,
	buyer?: ValueTypes["Contember_VoucherUpdateBuyerEntityRelationInput"] | undefined | null,
	owner?: ValueTypes["Contember_VoucherUpdateOwnerEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined | null,
	virtualProductEffect?: ValueTypes["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_VoucherRedemptionUpsertVoucherRelationInput"]: {
	update?: ValueTypes["Contember_VoucherWithoutRedemptionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutRedemptionCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertRedeemedVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_VoucherUpsertOwnerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutOwnsVouchersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutOwnsVouchersCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertBoughtVouchersRelationInput"]: {
	by?: ValueTypes["Contember_VoucherUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VoucherWithoutBuyerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherWithoutBuyerCreateInput"] | undefined | null
};
	["Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"] | undefined | null
};
	["Contember_OrderUpsertBillingSubjectRelationInput"]: {
	update?: ValueTypes["Contember_BillingSubjectWithoutOrdersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutOrdersCreateInput"] | undefined | null
};
	["Contember_FulfillmentNoteUpsertOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutFulfillmentNotesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutFulfillmentNotesCreateInput"] | undefined | null
};
	["Contember_VenueUpsertFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteWithoutVenuesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutVenuesCreateInput"] | undefined | null
};
	["Contember_OrderUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutOrdersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutOrdersCreateInput"] | undefined | null
};
	["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]: {
	create?: ValueTypes["Contember_FulfillmentNoteWithoutOrdersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderUpdateFulfillmentNotesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderUpsertFulfillmentNotesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderUpdateFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_FulfillmentNoteWithoutOrdersUpdateInput"] | undefined | null
};
	["Contember_FulfillmentNoteWithoutOrdersUpdateInput"]: {
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined | null,
	cartItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderUpsertFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteWithoutOrdersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutOrdersCreateInput"] | undefined | null
};
	["Contember_BillingSubjectUpsertOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutBillingSubjectUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutBillingSubjectCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertDefaultBillingSubjectRelationInput"]: {
	update?: ValueTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"] | undefined | null
};
	["Contember_BillingSubjectUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutBillingSubjectsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutBillingSubjectsCreateInput"] | undefined | null
};
	["Contember_InvoiceUpsertBillingSubjectRelationInput"]: {
	update?: ValueTypes["Contember_BillingSubjectWithoutInvoicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutInvoicesCreateInput"] | undefined | null
};
	["Contember_OrderPaymentUpsertInvoiceRelationInput"]: {
	update?: ValueTypes["Contember_InvoiceWithoutOrderPaymentsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutOrderPaymentsCreateInput"] | undefined | null
};
	["Contember_OrderPaymentUpdateVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderPaymentUpsertVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderPaymentUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_OrderPaymentUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderUpsertPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderPaymentWithoutOrderUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderPaymentWithoutOrderCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryUpsertOrderRelationInput"]: {
	update?: ValueTypes["Contember_OrderWithoutDeliveryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutDeliveryCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderEventWithoutDeliveryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderDeliveryUpdateEventsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDeliveryUpsertEventsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_OrderDeliveryUpdateEventsRelationInput"]: {
	by?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_OrderEventWithoutDeliveryUpdateInput"] | undefined | null
};
	["Contember_OrderEventWithoutDeliveryUpdateInput"]: {
	type?: string | undefined | null,
	data?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	order?: ValueTypes["Contember_OrderEventUpdateOrderEntityRelationInput"] | undefined | null,
	payment?: ValueTypes["Contember_OrderEventUpdatePaymentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryUpsertEventsRelationInput"]: {
	by?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderEventWithoutDeliveryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderEventWithoutDeliveryCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"]: {
	create?: ValueTypes["Contember_LiftagoRideWithoutDeliveryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LiftagoRideWithoutDeliveryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDeliveryUpsertLiftagoRideRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LiftagoRideUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LiftagoRideWithoutDeliveryUpdateInput"]: {
	liftagoId?: string | undefined | null,
	data?: string | undefined | null,
	updatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryUpsertLiftagoRideRelationInput"]: {
	update?: ValueTypes["Contember_LiftagoRideWithoutDeliveryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LiftagoRideWithoutDeliveryCreateInput"] | undefined | null
};
	["Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput"]: {
	by?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderDeliveryWithoutAddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutAddressCreateInput"] | undefined | null
};
	["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: {
	create?: ValueTypes["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"] | undefined | null
};
	["Contember_OrderUpsertBillingAddressRelationInput"]: {
	update?: ValueTypes["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined | null
};
	["Contember_OrderPaymentUpsertOrderRelationInput"]: {
	update?: ValueTypes["Contember_OrderWithoutPaymentsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutPaymentsCreateInput"] | undefined | null
};
	["Contember_OrderEventUpsertPaymentRelationInput"]: {
	update?: ValueTypes["Contember_OrderPaymentWithoutEventsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderPaymentWithoutEventsCreateInput"] | undefined | null
};
	["Contember_OrderEventUpdateDeliveryEntityRelationInput"]: {
	create?: ValueTypes["Contember_OrderDeliveryWithoutEventsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_OrderDeliveryWithoutEventsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderEventUpsertDeliveryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_OrderDeliveryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderDeliveryWithoutEventsUpdateInput"]: {
	priceCents?: number | undefined | null,
	deliveredAt?: ValueTypes["DateTime"] | undefined | null,
	method?: ValueTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined | null,
	order?: ValueTypes["Contember_OrderDeliveryUpdateOrderEntityRelationInput"] | undefined | null,
	expectedAt?: ValueTypes["DateTime"] | undefined | null,
	state?: ValueTypes["Contember_OrderDeliveryState"] | undefined | null,
	loadingDurationHours?: number | undefined | null,
	dispatchAt?: ValueTypes["DateTime"] | undefined | null,
	duration?: string | undefined | null,
	window?: string | undefined | null,
	expectedEndAt?: ValueTypes["DateTime"] | undefined | null,
	processingAt?: ValueTypes["DateTime"] | undefined | null,
	deliveringAt?: ValueTypes["DateTime"] | undefined | null,
	failedAt?: ValueTypes["DateTime"] | undefined | null,
	internalNote?: string | undefined | null,
	deliveryWaypoint?: ValueTypes["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"] | undefined | null,
	prepareAt?: ValueTypes["DateTime"] | undefined | null,
	liftagoRide?: ValueTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined | null,
	pickedAt?: ValueTypes["DateTime"] | undefined | null,
	address?: ValueTypes["Contember_OrderDeliveryUpdateAddressEntityRelationInput"] | undefined | null,
	trackingCode?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryUpdateAddressEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDeliveryUpsertAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"]: {
	name?: string | undefined | null,
	firstName?: string | undefined | null,
	lastName?: string | undefined | null,
	addressLine2?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	customer?: ValueTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	country?: ValueTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	googlePlaceId?: string | undefined | null,
	raw?: string | undefined | null,
	formatted?: string | undefined | null,
	geocodeGeneratedAt?: ValueTypes["DateTime"] | undefined | null,
	geocodeResponse?: string | undefined | null,
	deliveryZone?: ValueTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined | null,
	gps?: ValueTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined | null,
	geocodeValid?: boolean | undefined | null,
	fullName?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	invoices?: Array<ValueTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined | null,
	addressLine1?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingAddressOfOrders?: Array<ValueTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddressOfCustomer?: ValueTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDeliveryUpsertAddressRelationInput"]: {
	update?: ValueTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined | null
};
	["Contember_OrderEventUpsertDeliveryRelationInput"]: {
	update?: ValueTypes["Contember_OrderDeliveryWithoutEventsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutEventsCreateInput"] | undefined | null
};
	["Contember_OrderUpsertEventsRelationInput"]: {
	by?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderEventWithoutOrderUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderEventWithoutOrderCreateInput"] | undefined | null
};
	["Contember_AddressUpsertBillingAddressOfOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutBillingAddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutBillingAddressCreateInput"] | undefined | null
};
	["Contember_InvoiceUpsertAddressRelationInput"]: {
	update?: ValueTypes["Contember_AddressWithoutInvoicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutInvoicesCreateInput"] | undefined | null
};
	["Contember_BillingSubjectUpsertInvoicesRelationInput"]: {
	by?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_InvoiceWithoutBillingSubjectUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutBillingSubjectCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertBillingSubjectsRelationInput"]: {
	by?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BillingSubjectWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CartUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutCartsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutCartsCreateInput"] | undefined | null
};
	["Contember_CartUpdateDeliveryAddressEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertDeliveryAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CartUpsertDeliveryAddressRelationInput"]: {
	update?: ValueTypes["Contember_AddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null
};
	["Contember_CartUpdateBillingAddressEntityRelationInput"]: {
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_AddressUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertBillingAddressRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_CartUpsertBillingAddressRelationInput"]: {
	update?: ValueTypes["Contember_AddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressCreateInput"] | undefined | null
};
	["Contember_CartUpdateDeliveryEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelDeliveryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelDeliveryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertDeliveryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ChannelDeliveryUpdateInput"]: {
	order?: number | undefined | null,
	priceCents?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"] | undefined | null,
	defaultVisible?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartUpsertDeliveryRelationInput"]: {
	update?: ValueTypes["Contember_ChannelDeliveryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryCreateInput"] | undefined | null
};
	["Contember_CartUpdatePaymentEntityRelationInput"]: {
	create?: ValueTypes["Contember_ChannelPaymentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ChannelPaymentUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertPaymentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ChannelPaymentUpdateInput"]: {
	order?: number | undefined | null,
	channel?: ValueTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined | null,
	method?: ValueTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined | null,
	vatRate?: ValueTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined | null,
	requireBillingAddress?: boolean | undefined | null,
	provideReceipt?: boolean | undefined | null,
	requireBillingSubject?: boolean | undefined | null,
	allowOnlyForTags?: Array<ValueTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartUpsertPaymentRelationInput"]: {
	update?: ValueTypes["Contember_ChannelPaymentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentCreateInput"] | undefined | null
};
	["Contember_CartUpdateBillingSubjectEntityRelationInput"]: {
	create?: ValueTypes["Contember_BillingSubjectCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BillingSubjectUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartUpsertBillingSubjectRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BillingSubjectUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BillingSubjectUpdateInput"]: {
	name?: string | undefined | null,
	fullName?: string | undefined | null,
	addressLine1?: string | undefined | null,
	addressLine2?: string | undefined | null,
	city?: string | undefined | null,
	postalCode?: string | undefined | null,
	email?: string | undefined | null,
	phone?: string | undefined | null,
	companyName?: string | undefined | null,
	companyIdentifier?: string | undefined | null,
	vatIdentifier?: string | undefined | null,
	externalIdentifier?: string | undefined | null,
	hidden?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	deletedAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	internalNote?: string | undefined | null,
	fakturoidSubjectId?: string | undefined | null,
	country?: ValueTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined | null,
	defaultBillingSubjectOfCustomer?: ValueTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined | null,
	orders?: Array<ValueTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartUpsertBillingSubjectRelationInput"]: {
	update?: ValueTypes["Contember_BillingSubjectUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BillingSubjectCreateInput"] | undefined | null
};
	["Contember_CartUpdatePriceLinesEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartPriceLineUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_CartPriceLineUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_CartPriceLineUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartUpdateSummaryEntityRelationInput"]: {
	connect?: ValueTypes["Contember_CartSummaryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_OrderUpsertCartRelationInput"]: {
	update?: ValueTypes["Contember_CartWithoutOrderUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutOrderCreateInput"] | undefined | null
};
	["Contember_OrderDiscountUpsertOrderRelationInput"]: {
	update?: ValueTypes["Contember_OrderWithoutDiscountsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutDiscountsCreateInput"] | undefined | null
};
	["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_OrderDiscountUpsertVoucherRedemptionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	customerCredit?: ValueTypes["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_OrderDiscountUpsertVoucherRedemptionRelationInput"]: {
	update?: ValueTypes["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"] | undefined | null
};
	["Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput"]: {
	update?: ValueTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"] | undefined | null
};
	["Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput"]: {
	update?: ValueTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"] | undefined | null
};
	["Contember_OrderItemUpsertVirtualProductEffectsRelationInput"]: {
	by?: ValueTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductEffectWithoutOrderItemCreateInput"] | undefined | null
};
	["Contember_OrderUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderItemWithoutOrderUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutOrderCreateInput"] | undefined | null
};
	["Contember_VenueUpsertOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutVenueCreateInput"] | undefined | null
};
	["Contember_PointOfSaleTerminalUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput"]: {
	update?: ValueTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"]: {
	internalName?: string | undefined | null,
	note?: string | undefined | null,
	ipAddress?: string | undefined | null,
	applicationDevicesReceipt?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]> | undefined | null,
	venue?: ValueTypes["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"] | undefined | null,
	ticketCategories?: Array<ValueTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeUpsertApplicationDevicesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_IdentificationCodeWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertIdentificationCodesRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageLocaleWithoutLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleUpdatePipelineStagesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LocaleUpsertPipelineStagesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_LocaleUpdatePipelineStagesRelationInput"]: {
	by?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"] | undefined | null
};
	["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"]: {
	name?: string | undefined | null,
	root?: ValueTypes["Contember_PipelineStageLocaleUpdateRootEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageLocaleUpdateRootEntityRelationInput"]: {
	create?: ValueTypes["Contember_PipelineStageWithoutLocalesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageWithoutLocalesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PipelineStageLocaleUpsertRootRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PipelineStageUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_PipelineStageWithoutLocalesUpdateInput"]: {
	items?: Array<ValueTypes["Contember_PipelineStageUpdateItemsEntityRelationInput"]> | undefined | null,
	pipeline?: ValueTypes["Contember_PipelineStageUpdatePipelineEntityRelationInput"] | undefined | null,
	order?: number | undefined | null,
	sentiment?: ValueTypes["Contember_PipelineStageUpdateSentimentEntityRelationInput"] | undefined | null,
	type?: ValueTypes["Contember_PipelineStageType"] | undefined | null,
	forms?: Array<ValueTypes["Contember_PipelineStageUpdateFormsEntityRelationInput"]> | undefined | null,
	showItemsWeight?: boolean | undefined | null,
	showItemsPrice?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PipelineStageLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_PipelineStageWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertPipelineStagesRelationInput"]: {
	by?: ValueTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PipelineStageLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput"]: {
	update?: ValueTypes["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutProductsCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutIdentificationCodesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutIdentificationCodesCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_IdentificationAttemptUpsertCodeRelationInput"]: {
	update?: ValueTypes["Contember_IdentificationCodeWithoutAttemptsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeWithoutAttemptsCreateInput"] | undefined | null
};
	["Contember_UserUpsertIdentificationAttemptsRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationAttemptWithoutUserUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationAttemptWithoutUserCreateInput"] | undefined | null
};
	["Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput"]: {
	update?: ValueTypes["Contember_UserUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UserCreateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput"]: {
	update?: ValueTypes["Contember_DeviceIdentifierWithoutDeviceUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeviceIdentifierWithoutDeviceCreateInput"] | undefined | null
};
	["Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"] | undefined | null
};
	["Contember_VenueUpsertPointOfSalePrintersRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSalePrinterWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSalePrinterWithoutVenueCreateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"] | undefined | null
};
	["Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"] | undefined | null
};
	["Contember_VenueUpsertPointOfSaleTerminalsRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleTerminalWithoutVenueCreateInput"] | undefined | null
};
	["Contember_FulfillmentNoteUpsertVenuesRelationInput"]: {
	by?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutFulfillmentNotesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutFulfillmentNotesCreateInput"] | undefined | null
};
	["Contember_ProductUpsertFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteWithoutProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutProductsCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"]: {
	title?: string | undefined | null,
	locale?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryUpsertProductsRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"] | undefined | null
};
	["Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertPointOfSaleCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_ProductLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductsCreateInput"] | undefined | null
};
	["Contember_ProductUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_ProductRecipeUpsertProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutRecipeUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutRecipeCreateInput"] | undefined | null
};
	["Contember_ProductRecipeUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductRecipeLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductRecipeUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductRecipeLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_ProductRecipeLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutProductRecipesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutProductRecipesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductRecipeLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutProductRecipesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductRecipeLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutProductRecipesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductRecipesCreateInput"] | undefined | null
};
	["Contember_ProductRecipeUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductRecipeLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_PreparedPackingQuantityUpsertRecipeRelationInput"]: {
	update?: ValueTypes["Contember_ProductRecipeUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeCreateInput"] | undefined | null
};
	["Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput"]: {
	by?: ValueTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PreparedPackingQuantityWithoutPackingCreateInput"] | undefined | null
};
	["Contember_ProductPackingLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_ProductPackingWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertProductPackingsRelationInput"]: {
	by?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutDeliveryMethodsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutDeliveryMethodsCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_CourierUpsertDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"] | undefined | null
};
	["Contember_DeliveryRouteUpsertCourierRelationInput"]: {
	update?: ValueTypes["Contember_CourierWithoutRoutesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CourierWithoutRoutesCreateInput"] | undefined | null
};
	["Contember_DeliveryWaypointUpsertRouteRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryRouteWithoutWaypointsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRouteWithoutWaypointsCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryWaypointWithoutDeliveryCreateInput"] | undefined | null
};
	["Contember_OrderUpsertDeliveryRelationInput"]: {
	update?: ValueTypes["Contember_OrderDeliveryWithoutOrderUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutOrderCreateInput"] | undefined | null
};
	["Contember_OrderEventUpsertOrderRelationInput"]: {
	update?: ValueTypes["Contember_OrderWithoutEventsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutEventsCreateInput"] | undefined | null
};
	["Contember_OrderPaymentUpsertEventsRelationInput"]: {
	by?: ValueTypes["Contember_OrderEventUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderEventWithoutPaymentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderEventWithoutPaymentCreateInput"] | undefined | null
};
	["Contember_InvoiceUpsertOrderPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_OrderPaymentUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderPaymentWithoutInvoiceUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderPaymentWithoutInvoiceCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertInvoicesRelationInput"]: {
	by?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_InvoiceWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_AnonymousSessionUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutAnonymousSessionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutAnonymousSessionCreateInput"] | undefined | null
};
	["Contember_UserDeviceUpsertAnonymousSessionRelationInput"]: {
	update?: ValueTypes["Contember_AnonymousSessionWithoutDevicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AnonymousSessionWithoutDevicesCreateInput"] | undefined | null
};
	["Contember_UserUpsertDevicesRelationInput"]: {
	by?: ValueTypes["Contember_UserDeviceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_UserDeviceWithoutUserUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UserDeviceWithoutUserCreateInput"] | undefined | null
};
	["Contember_IdentificationAttemptUpsertUserRelationInput"]: {
	update?: ValueTypes["Contember_UserWithoutIdentificationAttemptsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutIdentificationAttemptsCreateInput"] | undefined | null
};
	["Contember_IdentificationCodeUpsertAttemptsRelationInput"]: {
	by?: ValueTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_IdentificationAttemptWithoutCodeUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationAttemptWithoutCodeCreateInput"] | undefined | null
};
	["Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput"]: {
	update?: ValueTypes["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"] | undefined | null
};
	["Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput"]: {
	by?: ValueTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"] | undefined | null
};
	["Contember_TimetableTemplateUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutTimetableTemplatesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutTimetableTemplatesCreateInput"] | undefined | null
};
	["Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableTemplateWithoutPositionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateWithoutPositionCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupUpsertPositionRelationInput"]: {
	update?: ValueTypes["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableTemplateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableTemplateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableTemplateUpdateInput"]: {
	order?: number | undefined | null,
	startDate?: ValueTypes["Date"] | undefined | null,
	period?: number | undefined | null,
	slots?: number | undefined | null,
	position?: ValueTypes["Contember_TimetableTemplateUpdatePositionEntityRelationInput"] | undefined | null,
	staffOptions?: Array<ValueTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	venue?: ValueTypes["Contember_TimetableTemplateUpdateVenueEntityRelationInput"] | undefined | null,
	employeeRole?: ValueTypes["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"] | undefined | null,
	workdaysOnly?: boolean | undefined | null,
	monday?: boolean | undefined | null,
	tuesday?: boolean | undefined | null,
	wednesday?: boolean | undefined | null,
	thursday?: boolean | undefined | null,
	friday?: boolean | undefined | null,
	saturday?: boolean | undefined | null,
	sunday?: boolean | undefined | null,
	type?: ValueTypes["Contember_TemplateType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput"]: {
	update?: ValueTypes["Contember_TimetableTemplateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateCreateInput"] | undefined | null
};
	["Contember_TimetableShiftUpsertGroupRelationInput"]: {
	update?: ValueTypes["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutShiftsCreateInput"] | undefined | null
};
	["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"]: {
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftUpsertQueueItemRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemWithoutShiftUpdateInput"]: {
	start?: string | undefined | null,
	end?: string | undefined | null,
	queue?: ValueTypes["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	unassignInfo?: ValueTypes["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined | null,
	meta?: ValueTypes["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"]: {
	create?: ValueTypes["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"]: {
	hoursMissed?: number | undefined | null,
	legacyReason?: ValueTypes["Contember_UnassignedInfoReason"] | undefined | null,
	reason?: ValueTypes["Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput"]: {
	create?: ValueTypes["Contember_UnassignReasonCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UnassignReasonUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UnassignedShiftInfoUpsertReasonRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_UnassignReasonUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_UnassignReasonUpdateInput"]: {
	order?: number | undefined | null,
	name?: string | undefined | null,
	venues?: Array<ValueTypes["Contember_UnassignReasonUpdateVenuesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UnassignReasonUpdateVenuesEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_UnassignReasonUpdateVenuesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_UnassignReasonUpsertVenuesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_UnassignReasonUpdateVenuesRelationInput"]: {
	by?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"] | undefined | null
};
	["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_UnassignReasonUpsertVenuesRelationInput"]: {
	by?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"] | undefined | null
};
	["Contember_UnassignedShiftInfoUpsertReasonRelationInput"]: {
	update?: ValueTypes["Contember_UnassignReasonUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UnassignReasonCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput"]: {
	update?: ValueTypes["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableShiftUpsertQueueItemRelationInput"]: {
	update?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftCreateInput"] | undefined | null
};
	["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftUpsertSwapInfoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"]: {
	assigned?: ValueTypes["Date"] | undefined | null,
	requested?: boolean | undefined | null,
	confirmed?: boolean | undefined | null,
	swappedShiftsProfile?: ValueTypes["Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput"]: {
	create?: ValueTypes["Contember_ShiftsProfileCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput"]: {
	update?: ValueTypes["Contember_ShiftsProfileUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileCreateInput"] | undefined | null
};
	["Contember_TimetableShiftUpsertSwapInfoRelationInput"]: {
	update?: ValueTypes["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileUpsertShiftsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftWithoutShiftsProfileCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput"]: {
	update?: ValueTypes["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutQueueItemsCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"]: {
	create?: ValueTypes["Contember_TimetableShiftWithoutQueueItemCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftWithoutQueueItemUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_EmployeeQueueItemUpsertShiftRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_TimetableShiftWithoutQueueItemUpdateInput"]: {
	locked?: boolean | undefined | null,
	order?: number | undefined | null,
	assigned?: ValueTypes["Date"] | undefined | null,
	group?: ValueTypes["Contember_TimetableShiftUpdateGroupEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	swapInfo?: ValueTypes["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"] | undefined | null,
	isOvertime?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_EmployeeQueueItemUpsertShiftRelationInput"]: {
	update?: ValueTypes["Contember_TimetableShiftWithoutQueueItemUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftWithoutQueueItemCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_EmployeeQueueItemWithoutQueueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutQueueCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupUpsertQueueRelationInput"]: {
	update?: ValueTypes["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueWithoutShiftGroupCreateInput"] | undefined | null
};
	["Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftGroupWithoutPositionUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutPositionCreateInput"] | undefined | null
};
	["Contember_TimetableTemplateUpsertPositionRelationInput"]: {
	update?: ValueTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableTemplateUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupUpsertTypeRelationInput"]: {
	update?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"] | undefined | null
};
	["Contember_TimetableDayUpsertShiftGroupsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftGroupWithoutDayUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutDayCreateInput"] | undefined | null
};
	["Contember_TimetableDayUpdateMetaEntityRelationInput"]: {
	connect?: ValueTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueUpsertTimetableDaysRelationInput"]: {
	by?: ValueTypes["Contember_TimetableDayUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableDayWithoutVenueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableDayWithoutVenueCreateInput"] | undefined | null
};
	["Contember_ChannelUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutChannelsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_TagUpsertPublicInChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelWithoutPublicTagsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutPublicTagsCreateInput"] | undefined | null
};
	["Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutDeliveryZonesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutDeliveryZonesCreateInput"] | undefined | null
};
	["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]: {
	connect?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_AddressMetadataUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductUpsertDeliveryZonesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"] | undefined | null
};
	["Contember_FulfillmentNoteUpsertProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutFulfillmentNotesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutFulfillmentNotesCreateInput"] | undefined | null
};
	["Contember_TagUpsertFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteWithoutTagsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutTagsCreateInput"] | undefined | null
};
	["Contember_ChannelUpsertPublicTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutPublicInChannelsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutPublicInChannelsCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelWithoutCustomersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutCustomersCreateInput"] | undefined | null
};
	["Contember_CustomerCreditUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutCreditsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutCreditsCreateInput"] | undefined | null
};
	["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"]: {
	create?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CustomerCreditUpsertVoucherRedemptionRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	note?: string | undefined | null,
	manualRedemptionNote?: string | undefined | null,
	orderDiscount?: ValueTypes["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"] | undefined | null,
	voucher?: ValueTypes["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"] | undefined | null,
	customer?: ValueTypes["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CustomerCreditUpsertVoucherRedemptionRelationInput"]: {
	update?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"] | undefined | null
};
	["Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput"]: {
	update?: ValueTypes["Contember_CustomerCreditWithoutTransactionsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditWithoutTransactionsCreateInput"] | undefined | null
};
	["Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"] | undefined | null
};
	["Contember_OrderUpsertDiscountsRelationInput"]: {
	by?: ValueTypes["Contember_OrderDiscountUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderDiscountWithoutOrderUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderDiscountWithoutOrderCreateInput"] | undefined | null
};
	["Contember_OrderItemUpsertOrderRelationInput"]: {
	update?: ValueTypes["Contember_OrderWithoutItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutItemsCreateInput"] | undefined | null
};
	["Contember_ProductUpsertOrderItemsRelationInput"]: {
	by?: ValueTypes["Contember_OrderItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderItemWithoutProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderItemWithoutProductCreateInput"] | undefined | null
};
	["Contember_StocksManagerUpsertManagedProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutManagerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutManagerCreateInput"] | undefined | null
};
	["Contember_StaffUpsertStocksManagerRelationInput"]: {
	update?: ValueTypes["Contember_StocksManagerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StocksManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StaffUpdateVenuesEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutStaffMembersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffUpdateVenuesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_StaffUpsertVenuesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_StaffUpdateVenuesRelationInput"]: {
	by?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_VenueWithoutStaffMembersUpdateInput"] | undefined | null
};
	["Contember_VenueWithoutStaffMembersUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableDays?: Array<ValueTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_StaffUpsertVenuesRelationInput"]: {
	by?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutStaffMembersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutStaffMembersCreateInput"] | undefined | null
};
	["Contember_ShiftsManagerUpsertStaffRelationInput"]: {
	update?: ValueTypes["Contember_StaffWithoutShiftsManagerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutShiftsManagerCreateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput"]: {
	by?: ValueTypes["Contember_ShiftsManagerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsManagerWithoutManagedRolesCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileUpsertRolesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"] | undefined | null
};
	["Contember_StaffUpsertShiftsProfileRelationInput"]: {
	update?: ValueTypes["Contember_ShiftsProfileWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutStaffCreateInput"] | undefined | null
};
	["Contember_UserUpsertStaffRelationInput"]: {
	update?: ValueTypes["Contember_StaffWithoutUserUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutUserCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertUserRelationInput"]: {
	update?: ValueTypes["Contember_UserWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_OrderUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutOrdersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutOrdersCreateInput"] | undefined | null
};
	["Contember_CartUpsertOrderRelationInput"]: {
	update?: ValueTypes["Contember_OrderWithoutCartUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutCartCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertCartsRelationInput"]: {
	by?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CartWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_UserUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutUserUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutUserCreateInput"] | undefined | null
};
	["Contember_StaffUpsertUserRelationInput"]: {
	update?: ValueTypes["Contember_UserWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_UserWithoutStaffCreateInput"] | undefined | null
};
	["Contember_BusinessToBusinessManagerUpsertStaffRelationInput"]: {
	update?: ValueTypes["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpsertAccountManagerRelationInput"]: {
	update?: ValueTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpdateLogoEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertLogoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessCustomerUpsertLogoRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput"]: {
	by?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"] | undefined | null
};
	["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"]: {
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	fulfilledAt?: ValueTypes["DateTime"] | undefined | null,
	businessService?: ValueTypes["Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput"] | undefined | null,
	filledNumberOfPeople?: number | undefined | null,
	filledInvoiced?: boolean | undefined | null,
	filledEquipment?: string | undefined | null,
	filledMillTamDem?: boolean | undefined | null,
	filledSOEspresso?: boolean | undefined | null,
	filledFilterCoffeeInMenu?: boolean | undefined | null,
	filledWaterMeterStatus?: string | undefined | null,
	filledServiceAction?: string | undefined | null,
	filledInvoiceIdentifier?: string | undefined | null,
	filledNote?: string | undefined | null,
	filledSupervisor?: ValueTypes["Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput"] | undefined | null,
	filledContact?: string | undefined | null,
	filledPhone?: boolean | undefined | null,
	filledEmail?: boolean | undefined | null,
	filledGrindingStonesCondition?: string | undefined | null,
	createdBy?: ValueTypes["Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessServiceCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessServiceUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessServiceEventUpsertBusinessServiceRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessServiceUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessServiceUpdateInput"]: {
	order?: number | undefined | null,
	requireNote?: number | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessServiceUpdateLocalesEntityRelationInput"]> | undefined | null,
	requireNumberOfPeople?: number | undefined | null,
	requireInvoiced?: number | undefined | null,
	requireEquipment?: number | undefined | null,
	requireMillTamDem?: number | undefined | null,
	requireSOEspresso?: number | undefined | null,
	requireFilterCoffeeInMenu?: number | undefined | null,
	requireWaterMeterStatus?: number | undefined | null,
	requireServiceAction?: number | undefined | null,
	optionsEquipment?: string | undefined | null,
	optionsServiceAction?: string | undefined | null,
	requireInvoiceIdentifier?: number | undefined | null,
	requireSupervisor?: number | undefined | null,
	requireContact?: number | undefined | null,
	requirePhone?: number | undefined | null,
	requireEmail?: number | undefined | null,
	requireGrindingStonesCondition?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessServiceLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessServiceUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessServiceUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BusinessServiceUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BusinessServiceLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_BusinessServiceLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	labelNote?: string | undefined | null,
	locale?: ValueTypes["Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	labelNumberOfPeople?: string | undefined | null,
	labelInvoiced?: string | undefined | null,
	labelEquipment?: string | undefined | null,
	labelMillTamDem?: string | undefined | null,
	labelSOEspresso?: string | undefined | null,
	labelFilterCoffeeInMenu?: string | undefined | null,
	labelWaterMeterStatus?: string | undefined | null,
	labelServiceAction?: string | undefined | null,
	labelInvoiceIdentifier?: string | undefined | null,
	labelSupervisor?: string | undefined | null,
	labelContact?: string | undefined | null,
	labelPhone?: string | undefined | null,
	labelEmail?: string | undefined | null,
	labelGrindingStonesCondition?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutBusinessServicesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutBusinessServicesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessServiceLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutBusinessServicesUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutBusinessServicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutBusinessServicesCreateInput"] | undefined | null
};
	["Contember_BusinessServiceUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessServiceLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_BusinessServiceEventUpsertBusinessServiceRelationInput"]: {
	update?: ValueTypes["Contember_BusinessServiceUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceCreateInput"] | undefined | null
};
	["Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_StaffUpdateInput"]: {
	bartender?: ValueTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined | null,
	courier?: ValueTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined | null,
	cleaner?: ValueTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined | null,
	shiftsManager?: ValueTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined | null,
	businessToBusinessManager?: ValueTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined | null,
	shiftsProfile?: ValueTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined | null,
	stocksManager?: ValueTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined | null,
	courierManager?: ValueTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined | null,
	picker?: ValueTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	remunerationProfile?: ValueTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined | null,
	cashier?: ValueTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined | null,
	venues?: Array<ValueTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined | null,
	internalBuyer?: ValueTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined | null,
	salesManager?: ValueTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined | null,
	cashierManager?: ValueTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput"]: {
	update?: ValueTypes["Contember_StaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffCreateInput"] | undefined | null
};
	["Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput"]: {
	create?: ValueTypes["Contember_StaffCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_StaffUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessServiceEventUpsertCreatedByRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_StaffUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessServiceEventUpsertCreatedByRelationInput"]: {
	update?: ValueTypes["Contember_StaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput"]: {
	by?: ValueTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"]: {
	create?: ValueTypes["Contember_DoubleshotCoffeeEquipmentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DoubleshotCoffeeEquipmentUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_DoubleshotCoffeeEquipmentUpdateInput"]: {
	espressoMachine?: string | undefined | null,
	espressoGrinder?: string | undefined | null,
	batchBrew?: string | undefined | null,
	batchGrinder?: string | undefined | null,
	waterFilter?: string | undefined | null,
	mechanicalFilter?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput"]: {
	update?: ValueTypes["Contember_DoubleshotCoffeeEquipmentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DoubleshotCoffeeEquipmentCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertOwnerOfBusinessesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerWithoutOwnersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutOwnersCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpsertMembersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutMemberOfBusinessesCreateInput"] | undefined | null
};
	["Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutAccountManagerCreateInput"] | undefined | null
};
	["Contember_StaffUpsertBusinessToBusinessManagerRelationInput"]: {
	update?: ValueTypes["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileUpsertStaffRelationInput"]: {
	update?: ValueTypes["Contember_StaffWithoutShiftsProfileUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutShiftsProfileCreateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput"]: {
	by?: ValueTypes["Contember_ShiftsProfileUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ShiftsProfileWithoutRolesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutRolesCreateInput"] | undefined | null
};
	["Contember_TimetableDayNoteUpsertRoleRelationInput"]: {
	update?: ValueTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined | null
};
	["Contember_TimetableDayUpsertNotesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableDayNoteWithoutDayUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableDayNoteWithoutDayCreateInput"] | undefined | null
};
	["Contember_TimetableDayUpdateVenueEntityRelationInput"]: {
	create?: ValueTypes["Contember_VenueWithoutTimetableDaysCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VenueWithoutTimetableDaysUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_TimetableDayUpsertVenueRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VenueUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_VenueWithoutTimetableDaysUpdateInput"]: {
	name?: string | undefined | null,
	deadlineTemplate?: ValueTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined | null,
	timetableTemplates?: Array<ValueTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined | null,
	staffMembers?: Array<ValueTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined | null,
	employeeRoles?: Array<ValueTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined | null,
	pointOfSaleApplicationDevices?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined | null,
	pointOfSaleTerminals?: Array<ValueTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined | null,
	pointOfSalePrinters?: Array<ValueTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined | null,
	fulfillmentNotes?: Array<ValueTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined | null,
	areas?: Array<ValueTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined | null,
	enabledUnassignReasons?: Array<ValueTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined | null,
	categories?: Array<ValueTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined | null,
	channels?: Array<ValueTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined | null,
	address?: string | undefined | null,
	pointOfSalePngBase64ReceiptLogo?: string | undefined | null,
	pointOfSaleInitialView?: ValueTypes["Contember_PointOfSaleInitialView"] | undefined | null,
	pointOfSalePreferPrintReceipt?: boolean | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_TimetableDayUpsertVenueRelationInput"]: {
	update?: ValueTypes["Contember_VenueWithoutTimetableDaysUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VenueWithoutTimetableDaysCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupUpsertDayRelationInput"]: {
	update?: ValueTypes["Contember_TimetableDayWithoutShiftGroupsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableDayWithoutShiftGroupsCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueUpsertShiftGroupRelationInput"]: {
	update?: ValueTypes["Contember_TimetableShiftGroupWithoutQueueUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutQueueCreateInput"] | undefined | null
};
	["Contember_EmployeeQueueItemUpsertQueueRelationInput"]: {
	update?: ValueTypes["Contember_EmployeeQueueWithoutItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueWithoutItemsCreateInput"] | undefined | null
};
	["Contember_ShiftsProfileUpsertQueueItemsRelationInput"]: {
	by?: ValueTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined | null
};
	["Contember_TimetableShiftUpsertShiftsProfileRelationInput"]: {
	update?: ValueTypes["Contember_ShiftsProfileWithoutShiftsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsProfileWithoutShiftsCreateInput"] | undefined | null
};
	["Contember_TimetableShiftGroupUpsertShiftsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftWithoutGroupUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftWithoutGroupCreateInput"] | undefined | null
};
	["Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput"]: {
	by?: ValueTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableShiftGroupWithoutTypeUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableShiftGroupWithoutTypeCreateInput"] | undefined | null
};
	["Contember_ShiftsManagerUpsertManagedRolesRelationInput"]: {
	by?: ValueTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"] | undefined | null
};
	["Contember_StaffUpsertShiftsManagerRelationInput"]: {
	update?: ValueTypes["Contember_ShiftsManagerWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ShiftsManagerWithoutStaffCreateInput"] | undefined | null
};
	["Contember_CourierUpsertStaffRelationInput"]: {
	update?: ValueTypes["Contember_StaffWithoutCourierUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutCourierCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodUpsertCourierHandlersRelationInput"]: {
	by?: ValueTypes["Contember_CourierUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CourierWithoutDeliveryMethodsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CourierWithoutDeliveryMethodsCreateInput"] | undefined | null
};
	["Contember_OrderDeliveryUpsertMethodRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryMethodUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodCreateInput"] | undefined | null
};
	["Contember_DeliveryWaypointUpsertDeliveryRelationInput"]: {
	update?: ValueTypes["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"] | undefined | null
};
	["Contember_DeliveryRouteUpsertWaypointsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryWaypointWithoutRouteUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryWaypointWithoutRouteCreateInput"] | undefined | null
};
	["Contember_CourierUpsertRoutesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryRouteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryRouteWithoutCourierUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRouteWithoutCourierCreateInput"] | undefined | null
};
	["Contember_StaffUpsertCourierRelationInput"]: {
	update?: ValueTypes["Contember_CourierWithoutStaffUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CourierWithoutStaffCreateInput"] | undefined | null
};
	["Contember_StocksManagerUpsertStaffRelationInput"]: {
	update?: ValueTypes["Contember_StaffWithoutStocksManagerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StaffWithoutStocksManagerCreateInput"] | undefined | null
};
	["Contember_ProductUpsertManagerRelationInput"]: {
	update?: ValueTypes["Contember_StocksManagerWithoutManagedProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_StocksManagerWithoutManagedProductsCreateInput"] | undefined | null
};
	["Contember_VirtualProductUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductWithoutVirtualProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutVirtualProductCreateInput"] | undefined | null
};
	["Contember_WorkspaceUpsertCreditsProductRelationInput"]: {
	update?: ValueTypes["Contember_VirtualProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VirtualProductCreateInput"] | undefined | null
};
	["Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput"]: {
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WorkspaceUpsertDiscountVatRateRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_VatRateUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WorkspaceUpsertDiscountVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_FreshingContainerUpsertWorkspaceRelationInput"]: {
	update?: ValueTypes["Contember_WorkspaceWithoutFreshingContainersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_WorkspaceWithoutFreshingContainersCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpsertFreshingContainerRelationInput"]: {
	update?: ValueTypes["Contember_FreshingContainerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FreshingContainerCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpdateImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientUpsertImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"]: {
	create?: ValueTypes["Contember_IngredientSupplierListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientUpsertSuppliersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IngredientSupplierListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_IngredientSupplierListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_IngredientSupplierListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_IngredientSupplierItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IngredientSupplierListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IngredientSupplierListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_IngredientSupplierItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_IngredientSupplierItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	supplier?: ValueTypes["Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput"]: {
	create?: ValueTypes["Contember_IngredientSupplierCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IngredientSupplierItemUpsertSupplierRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IngredientSupplierUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_IngredientSupplierUpdateInput"]: {
	internalName?: string | undefined | null,
	internalNote?: string | undefined | null,
	locales?: Array<ValueTypes["Contember_IngredientSupplierUpdateLocalesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_IngredientSupplierLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IngredientSupplierUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_IngredientSupplierUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutIngredientSuppliersCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutIngredientSuppliersUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_IngredientSupplierLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutIngredientSuppliersUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productCategoryLists?: Array<ValueTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_IngredientSupplierLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutIngredientSuppliersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutIngredientSuppliersCreateInput"] | undefined | null
};
	["Contember_IngredientSupplierUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_IngredientSupplierItemUpsertSupplierRelationInput"]: {
	update?: ValueTypes["Contember_IngredientSupplierUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierCreateInput"] | undefined | null
};
	["Contember_IngredientSupplierListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_IngredientSupplierItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierItemWithoutListCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpsertSuppliersRelationInput"]: {
	update?: ValueTypes["Contember_IngredientSupplierListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_IngredientSupplierListCreateInput"] | undefined | null
};
	["Contember_AllergenUpsertIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientWithoutAllergensUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutAllergensCreateInput"] | undefined | null
};
	["Contember_AllergenLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_AllergenWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AllergenWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertAllergensRelationInput"]: {
	by?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_AllergenLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AllergenLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutOrderRecurrencesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutOrderRecurrencesCreateInput"] | undefined | null
};
	["Contember_OrderRecurrenceUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpsertRecurrencesRelationInput"]: {
	by?: ValueTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductListWithoutBusinessCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductListWithoutBusinessCategoryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryUpsertSpecificProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductListWithoutBusinessCategoryUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ProductListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductListItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductListItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductListItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductListItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductListItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductListItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_ProductListItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	product?: ValueTypes["Contember_ProductListItemUpdateProductEntityRelationInput"] | undefined | null,
	defaultQuantityForOrder?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductListItemUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductListItemUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductListItemUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_ProductListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductListItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductListItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductListItemWithoutListCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpsertSpecificProductsRelationInput"]: {
	update?: ValueTypes["Contember_ProductListWithoutBusinessCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductListWithoutBusinessCategoryCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"]> | undefined | null,
	specificProductsProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryListLocaleWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListUpdateLocalesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryListUpsertLocalesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductCategoryListUpdateLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"] | undefined | null
};
	["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"]: {
	name?: string | undefined | null,
	locale?: ValueTypes["Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput"]: {
	create?: ValueTypes["Contember_LocaleWithoutProductCategoryListsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LocaleWithoutProductCategoryListsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryListLocaleUpsertLocaleRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LocaleUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LocaleWithoutProductCategoryListsUpdateInput"]: {
	businessCategories?: Array<ValueTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined | null,
	productIngredients?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined | null,
	orderRecurrences?: Array<ValueTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined | null,
	blogs?: Array<ValueTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined | null,
	blogPosts?: Array<ValueTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined | null,
	identifier?: ValueTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined | null,
	menus?: Array<ValueTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined | null,
	pages?: Array<ValueTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined | null,
	productIngredientCategories?: Array<ValueTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined | null,
	allergens?: Array<ValueTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined | null,
	productPackings?: Array<ValueTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined | null,
	products?: Array<ValueTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	productRecipes?: Array<ValueTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategories?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined | null,
	pointOfSaleCategoryProducts?: Array<ValueTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined | null,
	deliveryMethods?: Array<ValueTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined | null,
	ingredientSuppliers?: Array<ValueTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined | null,
	identificationCodes?: Array<ValueTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined | null,
	pipelineStages?: Array<ValueTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined | null,
	subscriptionBoxes?: Array<ValueTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined | null,
	businessServices?: Array<ValueTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutProductCategoryListsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductCategoryListsCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"]: {
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput"]: {
	update?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput"]: {
	update?: ValueTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductCategoryListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]> | undefined | null,
	locales?: Array<ValueTypes["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"]> | undefined | null,
	seasonalProductCategoriesOfBusinessCategory?: ValueTypes["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"]: {
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined | null,
	specificProducts?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput"]: {
	update?: ValueTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput"]: {
	update?: ValueTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpsertCategoryRelationInput"]: {
	update?: ValueTypes["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertMemberOfBusinessesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerWithoutMembersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutMembersCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpsertOwnersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertBusinessRelationInput"]: {
	update?: ValueTypes["Contember_BusinessCustomerWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerIngredientRatingUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutIngredientRatingsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutIngredientRatingsCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpsertCustomerRatingsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryUpsertIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientWithoutCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutCategoriesCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertProductIngredientCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_PageLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutPagesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutPagesCreateInput"] | undefined | null
};
	["Contember_LinkableUpsertPageRelationInput"]: {
	update?: ValueTypes["Contember_PageLocaleWithoutLinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PageLocaleWithoutLinkCreateInput"] | undefined | null
};
	["Contember_LinkableUpdateRedirectEntityRelationInput"]: {
	create?: ValueTypes["Contember_RedirectWithoutLinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_RedirectWithoutLinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_LinkableUpsertRedirectRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_RedirectUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_RedirectWithoutLinkUpdateInput"]: {
	target?: ValueTypes["Contember_RedirectUpdateTargetEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_RedirectUpdateTargetEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_RedirectUpsertTargetRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_RedirectUpsertTargetRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_LinkableUpsertRedirectRelationInput"]: {
	update?: ValueTypes["Contember_RedirectWithoutLinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_RedirectWithoutLinkCreateInput"] | undefined | null
};
	["Contember_WikiPageUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkableWithoutWikiPageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkableWithoutWikiPageCreateInput"] | undefined | null
};
	["Contember_WikiPageUpdateContentEntityRelationInput"]: {
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ContentUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WikiPageUpsertContentRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ContentUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_WikiPageUpsertContentRelationInput"]: {
	update?: ValueTypes["Contember_ContentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null
};
	["Contember_WikiPageUpsertParentRelationInput"]: {
	update?: ValueTypes["Contember_WikiPageWithoutChildrenUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_WikiPageWithoutChildrenCreateInput"] | undefined | null
};
	["Contember_WikiPageUpdateChildrenEntityRelationInput"]: {
	create?: ValueTypes["Contember_WikiPageWithoutParentCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_WikiPageUpdateChildrenRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_WikiPageUpsertChildrenRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_WikiPageUpdateChildrenRelationInput"]: {
	by?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_WikiPageWithoutParentUpdateInput"] | undefined | null
};
	["Contember_WikiPageWithoutParentUpdateInput"]: {
	order?: number | undefined | null,
	title?: string | undefined | null,
	emoji?: string | undefined | null,
	link?: ValueTypes["Contember_WikiPageUpdateLinkEntityRelationInput"] | undefined | null,
	children?: Array<ValueTypes["Contember_WikiPageUpdateChildrenEntityRelationInput"]> | undefined | null,
	content?: ValueTypes["Contember_WikiPageUpdateContentEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_WikiPageUpsertChildrenRelationInput"]: {
	by?: ValueTypes["Contember_WikiPageUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_WikiPageWithoutParentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_WikiPageWithoutParentCreateInput"] | undefined | null
};
	["Contember_LinkableUpsertWikiPageRelationInput"]: {
	update?: ValueTypes["Contember_WikiPageWithoutLinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_WikiPageWithoutLinkCreateInput"] | undefined | null
};
	["Contember_PageLocaleUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkableWithoutPageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkableWithoutPageCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertPagesRelationInput"]: {
	by?: ValueTypes["Contember_PageLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PageLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PageLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutBlogPostsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutBlogPostsCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"]: {
	create?: ValueTypes["Contember_SeoCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SeoUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostLocaleUpsertSeoRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SeoUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BlogPostLocaleUpsertSeoRelationInput"]: {
	update?: ValueTypes["Contember_SeoUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SeoCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostLocaleUpdateProductsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostLocaleUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BlogPostLocaleUpdateProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpsertProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_LinkableUpsertBlogPostRelationInput"]: {
	update?: ValueTypes["Contember_BlogPostLocaleWithoutLinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostLocaleWithoutLinkCreateInput"] | undefined | null
};
	["Contember_LinkUpsertInternalLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkableUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkableCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateBlogPostsEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogPostListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertBlogPostsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogPostListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BlogPostListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_BlogPostListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogPostListItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogPostListItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_BlogPostListItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_BlogPostListItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_BlogPostListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_BlogPostListItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_BlogPostListItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_BlogPostListItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	caption?: string | undefined | null,
	blogPost?: ValueTypes["Contember_BlogPostListItemUpdateBlogPostEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostListItemUpdateBlogPostEntityRelationInput"]: {
	create?: ValueTypes["Contember_BlogPostLocaleCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostLocaleUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostListItemUpsertBlogPostRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BlogPostLocaleUpdateInput"]: {
	title?: string | undefined | null,
	availableForWeb?: boolean | undefined | null,
	availableForMobile?: boolean | undefined | null,
	root?: ValueTypes["Contember_BlogPostLocaleUpdateRootEntityRelationInput"] | undefined | null,
	locale?: ValueTypes["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"] | undefined | null,
	cover?: ValueTypes["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"] | undefined | null,
	content?: ValueTypes["Contember_BlogPostLocaleUpdateContentEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"] | undefined | null,
	seo?: ValueTypes["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"] | undefined | null,
	products?: Array<ValueTypes["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkableWithoutBlogPostCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkableWithoutBlogPostUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_BlogPostLocaleUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkableUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_LinkableWithoutBlogPostUpdateInput"]: {
	url?: string | undefined | null,
	page?: ValueTypes["Contember_LinkableUpdatePageEntityRelationInput"] | undefined | null,
	wikiPage?: ValueTypes["Contember_LinkableUpdateWikiPageEntityRelationInput"] | undefined | null,
	redirect?: ValueTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_BlogPostLocaleUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkableWithoutBlogPostUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkableWithoutBlogPostCreateInput"] | undefined | null
};
	["Contember_BlogPostListItemUpsertBlogPostRelationInput"]: {
	update?: ValueTypes["Contember_BlogPostLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostLocaleCreateInput"] | undefined | null
};
	["Contember_BlogPostListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_BlogPostListItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostListItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostListItemWithoutListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertBlogPostsRelationInput"]: {
	update?: ValueTypes["Contember_BlogPostListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateLinksEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertLinksRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ContentReferenceUpsertLinksRelationInput"]: {
	update?: ValueTypes["Contember_LinkListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateProductsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertProductsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ProductListUpdateItemsEntityRelationInput"]> | undefined | null,
	businessCategory?: ValueTypes["Contember_ProductListUpdateBusinessCategoryEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductListUpdateBusinessCategoryEntityRelationInput"]: {
	create?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductListUpsertBusinessCategoryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"]: {
	order?: number | undefined | null,
	visibleToNewCustomers?: boolean | undefined | null,
	locales?: Array<ValueTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined | null,
	internalName?: string | undefined | null,
	businessCustomers?: Array<ValueTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined | null,
	recurrences?: Array<ValueTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined | null,
	seasonalProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined | null,
	specificProductsProductCategories?: ValueTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined | null,
	slug?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductListUpsertBusinessCategoryRelationInput"]: {
	update?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertProductsRelationInput"]: {
	update?: ValueTypes["Contember_ProductListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateHeroEntityRelationInput"]: {
	create?: ValueTypes["Contember_HeroCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_HeroUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertHeroRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_HeroUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_HeroUpdateInput"]: {
	showLogo?: boolean | undefined | null,
	middleContent?: string | undefined | null,
	medium?: ValueTypes["Contember_HeroUpdateMediumEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_HeroUpdateMediumEntityRelationInput"]: {
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_HeroUpsertMediumRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_HeroUpsertMediumRelationInput"]: {
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertHeroRelationInput"]: {
	update?: ValueTypes["Contember_HeroUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_HeroCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateGalleryEntityRelationInput"]: {
	create?: ValueTypes["Contember_GalleryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_GalleryUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertGalleryRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_GalleryUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_GalleryUpdateInput"]: {
	items?: Array<ValueTypes["Contember_GalleryUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GalleryUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_GalleryItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_GalleryUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_GalleryUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_GalleryItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_GalleryItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_GalleryItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_GalleryUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_GalleryItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_GalleryItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_GalleryItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	caption?: string | undefined | null,
	medium?: ValueTypes["Contember_GalleryItemUpdateMediumEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GalleryItemUpdateMediumEntityRelationInput"]: {
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_GalleryItemUpsertMediumRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_GalleryItemUpsertMediumRelationInput"]: {
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_GalleryUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_GalleryItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_GalleryItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_GalleryItemWithoutListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertGalleryRelationInput"]: {
	update?: ValueTypes["Contember_GalleryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_GalleryCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput"]: {
	create?: ValueTypes["Contember_SocialsAndAppsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_SocialsAndAppsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertSocialsAndAppsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ContentReferenceUpsertSocialsAndAppsRelationInput"]: {
	update?: ValueTypes["Contember_SocialsAndAppsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_SocialsAndAppsCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateProductGridEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductGridCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductGridUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertProductGridRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductGridUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductGridUpdateInput"]: {
	isCarousel?: boolean | undefined | null,
	categories?: Array<ValueTypes["Contember_ProductGridUpdateCategoriesEntityRelationInput"]> | undefined | null,
	type?: ValueTypes["Contember_ProductGridType"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGridUpdateCategoriesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductGridCategoryWithoutRootCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductGridUpdateCategoriesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGridUpsertCategoriesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductGridUpdateCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductGridCategoryWithoutRootUpdateInput"] | undefined | null
};
	["Contember_ProductGridCategoryWithoutRootUpdateInput"]: {
	order?: number | undefined | null,
	title?: string | undefined | null,
	items?: Array<ValueTypes["Contember_ProductGridCategoryUpdateItemsEntityRelationInput"]> | undefined | null,
	images?: ValueTypes["Contember_ProductGridCategoryUpdateImagesEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGridCategoryUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductGridItemWithoutCategoryCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductGridCategoryUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGridCategoryUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductGridCategoryUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductGridItemWithoutCategoryUpdateInput"] | undefined | null
};
	["Contember_ProductGridItemWithoutCategoryUpdateInput"]: {
	order?: number | undefined | null,
	title?: string | undefined | null,
	lead?: string | undefined | null,
	product?: ValueTypes["Contember_ProductGridItemUpdateProductEntityRelationInput"] | undefined | null,
	medium?: ValueTypes["Contember_ProductGridItemUpdateMediumEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_ProductGridItemUpdateLinkEntityRelationInput"] | undefined | null,
	description?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductGridItemUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGridItemUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductGridItemUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_ProductGridItemUpdateMediumEntityRelationInput"]: {
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGridItemUpsertMediumRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductGridItemUpsertMediumRelationInput"]: {
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_ProductGridItemUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGridItemUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductGridItemUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_ProductGridCategoryUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductGridItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductGridItemWithoutCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductGridItemWithoutCategoryCreateInput"] | undefined | null
};
	["Contember_ProductGridCategoryUpdateImagesEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductGridCategoryUpsertImagesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductGridCategoryUpsertImagesRelationInput"]: {
	update?: ValueTypes["Contember_ImageListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageListCreateInput"] | undefined | null
};
	["Contember_ProductGridUpsertCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductGridCategoryWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductGridCategoryWithoutRootCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertProductGridRelationInput"]: {
	update?: ValueTypes["Contember_ProductGridUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductGridCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateProductBannersEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductBannerListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductBannerListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertProductBannersRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductBannerListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductBannerListUpdateInput"]: {
	items?: Array<ValueTypes["Contember_ProductBannerListUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductBannerListUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductBannerItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductBannerListUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductBannerListUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductBannerItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_ProductBannerItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_ProductBannerItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_ProductBannerListUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductBannerItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_ProductBannerItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_ProductBannerItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	banner?: ValueTypes["Contember_ProductBannerItemUpdateBannerEntityRelationInput"] | undefined | null,
	size?: ValueTypes["Contember_ProductBannerItemSize"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductBannerItemUpdateBannerEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductBannerCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductBannerUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductBannerItemUpsertBannerRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductBannerUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductBannerUpdateInput"]: {
	beforeTitle?: string | undefined | null,
	title?: string | undefined | null,
	label?: string | undefined | null,
	button?: ValueTypes["Contember_ProductBannerUpdateButtonEntityRelationInput"] | undefined | null,
	backgroundImage?: ValueTypes["Contember_ProductBannerUpdateBackgroundImageEntityRelationInput"] | undefined | null,
	productImage?: ValueTypes["Contember_ProductBannerUpdateProductImageEntityRelationInput"] | undefined | null,
	product?: ValueTypes["Contember_ProductBannerUpdateProductEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductBannerUpdateButtonEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductBannerUpsertButtonRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductBannerUpsertButtonRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_ProductBannerUpdateBackgroundImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductBannerUpsertBackgroundImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductBannerUpsertBackgroundImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductBannerUpdateProductImageEntityRelationInput"]: {
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductBannerUpsertProductImageRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ImageUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductBannerUpsertProductImageRelationInput"]: {
	update?: ValueTypes["Contember_ImageUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ImageCreateInput"] | undefined | null
};
	["Contember_ProductBannerUpdateProductEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductBannerUpsertProductRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductBannerUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCreateInput"] | undefined | null
};
	["Contember_ProductBannerItemUpsertBannerRelationInput"]: {
	update?: ValueTypes["Contember_ProductBannerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductBannerCreateInput"] | undefined | null
};
	["Contember_ProductBannerListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductBannerItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductBannerItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductBannerItemWithoutListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertProductBannersRelationInput"]: {
	update?: ValueTypes["Contember_ProductBannerListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductBannerListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput"]: {
	create?: ValueTypes["Contember_DeliveryRegionsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryRegionsUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertDeliveryRegionsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ContentReferenceUpsertDeliveryRegionsRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryRegionsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryRegionsCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateTextListEntityRelationInput"]: {
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertTextListRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_TextListUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ContentReferenceUpsertTextListRelationInput"]: {
	update?: ValueTypes["Contember_TextListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TextListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpdateGridTilesEntityRelationInput"]: {
	create?: ValueTypes["Contember_GridTilesCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_GridTilesUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ContentReferenceUpsertGridTilesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_GridTilesUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_GridTilesUpdateInput"]: {
	items?: Array<ValueTypes["Contember_GridTilesUpdateItemsEntityRelationInput"]> | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GridTilesUpdateItemsEntityRelationInput"]: {
	create?: ValueTypes["Contember_GridTileItemWithoutListCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_GridTilesUpdateItemsRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_GridTilesUpsertItemsRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_GridTileItemUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_GridTileItemUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_GridTileItemUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_GridTilesUpdateItemsRelationInput"]: {
	by?: ValueTypes["Contember_GridTileItemUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_GridTileItemWithoutListUpdateInput"] | undefined | null
};
	["Contember_GridTileItemWithoutListUpdateInput"]: {
	order?: number | undefined | null,
	text?: string | undefined | null,
	medium?: ValueTypes["Contember_GridTileItemUpdateMediumEntityRelationInput"] | undefined | null,
	link?: ValueTypes["Contember_GridTileItemUpdateLinkEntityRelationInput"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_GridTileItemUpdateMediumEntityRelationInput"]: {
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_GridTileItemUpsertMediumRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_MediumUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_GridTileItemUpsertMediumRelationInput"]: {
	update?: ValueTypes["Contember_MediumUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_MediumCreateInput"] | undefined | null
};
	["Contember_GridTileItemUpdateLinkEntityRelationInput"]: {
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_GridTileItemUpsertLinkRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_LinkUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_GridTileItemUpsertLinkRelationInput"]: {
	update?: ValueTypes["Contember_LinkUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LinkCreateInput"] | undefined | null
};
	["Contember_GridTilesUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_GridTileItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_GridTileItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_GridTileItemWithoutListCreateInput"] | undefined | null
};
	["Contember_ContentReferenceUpsertGridTilesRelationInput"]: {
	update?: ValueTypes["Contember_GridTilesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_GridTilesCreateInput"] | undefined | null
};
	["Contember_ContentBlockUpsertReferencesRelationInput"]: {
	by?: ValueTypes["Contember_ContentReferenceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ContentReferenceWithoutBlockUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ContentReferenceWithoutBlockCreateInput"] | undefined | null
};
	["Contember_ContentUpsertBlocksRelationInput"]: {
	by?: ValueTypes["Contember_ContentBlockUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ContentBlockWithoutContentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ContentBlockWithoutContentCreateInput"] | undefined | null
};
	["Contember_BlogPostLocaleUpsertContentRelationInput"]: {
	update?: ValueTypes["Contember_ContentUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ContentCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertBlogPostsRelationInput"]: {
	by?: ValueTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BlogPostLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BlogPostLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutBusinessCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutBusinessCategoriesCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutRecurrencesCreateInput"] | undefined | null
};
	["Contember_OrderRecurrenceLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_OrderRecurrenceWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertOrderRecurrencesRelationInput"]: {
	by?: ValueTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_AllergenLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutAllergensUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutAllergensCreateInput"] | undefined | null
};
	["Contember_AllergenUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_AllergenLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_AllergenLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AllergenLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpsertAllergensRelationInput"]: {
	by?: ValueTypes["Contember_AllergenUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_AllergenWithoutIngredientsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AllergenWithoutIngredientsCreateInput"] | undefined | null
};
	["Contember_ProductIngredientLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertProductIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"] | undefined | null
};
	["Contember_ProductIngredientCategoryUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpsertCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"] | undefined | null
};
	["Contember_CustomerIngredientRatingUpsertIngredientRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertIngredientRatingsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerGroupUpsertCustomersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutGroupUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutGroupCreateInput"] | undefined | null
};
	["Contember_ProductGroupPriceUpsertGroupRelationInput"]: {
	update?: ValueTypes["Contember_CustomerGroupWithoutProductPricesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerGroupWithoutProductPricesCreateInput"] | undefined | null
};
	["Contember_ProductUpsertGroupPricesRelationInput"]: {
	by?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductGroupPriceWithoutProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductGroupPriceWithoutProductCreateInput"] | undefined | null
};
	["Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutDeliveryZonesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutDeliveryZonesCreateInput"] | undefined | null
};
	["Contember_TagUpsertDeliveryZonesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"] | undefined | null
};
	["Contember_ChannelUpsertAddTagsOnRegistrationRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutAddTagsOnRegistrationCreateInput"] | undefined | null
};
	["Contember_CartUpsertChannelRelationInput"]: {
	update?: ValueTypes["Contember_ChannelWithoutCartsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutCartsCreateInput"] | undefined | null
};
	["Contember_CartItemUpsertCartRelationInput"]: {
	update?: ValueTypes["Contember_CartWithoutItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutItemsCreateInput"] | undefined | null
};
	["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"]: {
	create?: ValueTypes["Contember_FulfillmentNoteWithoutCartItemsCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_CartItemUpdateFulfillmentNotesRelationInput"] | undefined | null,
	upsert?: ValueTypes["Contember_CartItemUpsertFulfillmentNotesRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	disconnect?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	delete?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	alias?: string | undefined | null
};
	["Contember_CartItemUpdateFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	data?: ValueTypes["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"] | undefined | null
};
	["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"]: {
	note?: string | undefined | null,
	products?: Array<ValueTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined | null,
	tags?: Array<ValueTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined | null,
	venues?: Array<ValueTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined | null,
	orderItems?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined | null,
	highlight?: boolean | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	emoji?: string | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_CartItemUpsertFulfillmentNotesRelationInput"]: {
	by?: ValueTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_FulfillmentNoteWithoutCartItemsCreateInput"] | undefined | null
};
	["Contember_ProductUpsertCartItemsRelationInput"]: {
	by?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CartItemWithoutProductUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CartItemWithoutProductCreateInput"] | undefined | null
};
	["Contember_ProductGroupPriceUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductWithoutGroupPricesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutGroupPricesCreateInput"] | undefined | null
};
	["Contember_CustomerGroupUpsertProductPricesRelationInput"]: {
	by?: ValueTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductGroupPriceWithoutGroupUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductGroupPriceWithoutGroupCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertGroupRelationInput"]: {
	update?: ValueTypes["Contember_CustomerGroupWithoutCustomersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerGroupWithoutCustomersCreateInput"] | undefined | null
};
	["Contember_ChannelUpsertCustomersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutChannelsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_TagUpsertAddTagsOnRegistrationRelationInput"]: {
	by?: ValueTypes["Contember_ChannelUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"] | undefined | null
};
	["Contember_ProductCategoryUpsertTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutCategoriesCreateInput"] | undefined | null
};
	["Contember_ProductCategoryUpsertParentRelationInput"]: {
	update?: ValueTypes["Contember_ProductCategoryWithoutChildrenUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutChildrenCreateInput"] | undefined | null
};
	["Contember_TagUpsertCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryWithoutTagsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryWithoutTagsCreateInput"] | undefined | null
};
	["Contember_ProductUpsertTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutProductsCreateInput"] | undefined | null
};
	["Contember_ProductCategoryUpsertProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutCategoriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutCategoriesCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListItemUpsertProductCategoryRelationInput"]: {
	update?: ValueTypes["Contember_ProductCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListItemWithoutListUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListItemWithoutListCreateInput"] | undefined | null
};
	["Contember_ProductCategoryListLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_ProductCategoryListWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertProductCategoryListsRelationInput"]: {
	by?: ValueTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_ProductIngredientLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutProductIngredientsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductIngredientsCreateInput"] | undefined | null
};
	["Contember_ProductIngredientUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_ProductIngredientItemUpsertIngredientRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientCreateInput"] | undefined | null
};
	["Contember_ProductIngredientItemUpdateQuantityEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientItemQuantityCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientItemQuantityUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientItemUpsertQuantityRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined | null,
	disconnect?: boolean | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientItemQuantityUpdateInput"]: {
	unit?: ValueTypes["Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput"] | undefined | null,
	value?: number | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput"]: {
	create?: ValueTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined | null,
	upsert?: ValueTypes["Contember_ProductIngredientItemQuantityUpsertUnitRelationInput"] | undefined | null,
	connect?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined | null,
	delete?: boolean | undefined | null
};
	["Contember_ProductIngredientItemQuantityUpsertUnitRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined | null
};
	["Contember_ProductIngredientItemUpsertQuantityRelationInput"]: {
	update?: ValueTypes["Contember_ProductIngredientItemQuantityUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemQuantityCreateInput"] | undefined | null
};
	["Contember_ProductRecipeUpsertIngredientsRelationInput"]: {
	by?: ValueTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductIngredientItemWithoutRecipeUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductIngredientItemWithoutRecipeCreateInput"] | undefined | null
};
	["Contember_ProductUpsertRecipeRelationInput"]: {
	update?: ValueTypes["Contember_ProductRecipeWithoutProductsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductRecipeWithoutProductsCreateInput"] | undefined | null
};
	["Contember_CartItemUpsertProductRelationInput"]: {
	update?: ValueTypes["Contember_ProductWithoutCartItemsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutCartItemsCreateInput"] | undefined | null
};
	["Contember_CartUpsertItemsRelationInput"]: {
	by?: ValueTypes["Contember_CartItemUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CartItemWithoutCartUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CartItemWithoutCartCreateInput"] | undefined | null
};
	["Contember_ChannelUpsertCartsRelationInput"]: {
	by?: ValueTypes["Contember_CartUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CartWithoutChannelUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CartWithoutChannelCreateInput"] | undefined | null
};
	["Contember_OrderUpsertChannelRelationInput"]: {
	update?: ValueTypes["Contember_ChannelUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertOrdersRelationInput"]: {
	by?: ValueTypes["Contember_OrderUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_OrderWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_OrderWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_TagUpsertCustomersRelationInput"]: {
	by?: ValueTypes["Contember_CustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerWithoutTagsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutTagsCreateInput"] | undefined | null
};
	["Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutAllowChannelPaymentsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutAllowChannelPaymentsCreateInput"] | undefined | null
};
	["Contember_VatRateUpsertChannelPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelPaymentWithoutVatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutVatRateCreateInput"] | undefined | null
};
	["Contember_ChannelDeliveryUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateWithoutChannelDeliveriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateWithoutChannelDeliveriesCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodUpsertChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelDeliveryWithoutMethodUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryWithoutMethodCreateInput"] | undefined | null
};
	["Contember_PaymentMethodUpsertDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"] | undefined | null
};
	["Contember_ChannelPaymentUpsertMethodRelationInput"]: {
	update?: ValueTypes["Contember_PaymentMethodWithoutChannelsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_ChannelUpsertPaymentsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelPaymentWithoutChannelUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutChannelCreateInput"] | undefined | null
};
	["Contember_ChannelDeliveryUpsertChannelRelationInput"]: {
	update?: ValueTypes["Contember_ChannelWithoutDeliveriesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutDeliveriesCreateInput"] | undefined | null
};
	["Contember_VatRateUpsertChannelDeliveriesRelationInput"]: {
	by?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelDeliveryWithoutVatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryWithoutVatRateCreateInput"] | undefined | null
};
	["Contember_CustomerCreditUpsertVatRateRelationInput"]: {
	update?: ValueTypes["Contember_VatRateUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_VatRateCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertCreditsRelationInput"]: {
	by?: ValueTypes["Contember_CustomerCreditUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_CustomerCreditWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerCreditWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_BusinessCustomerUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutBusinessUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutBusinessCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryUpsertBusinessCustomersRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCustomerUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCustomerWithoutCategoryUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCustomerWithoutCategoryCreateInput"] | undefined | null
};
	["Contember_BusinessCategoryLocaleUpsertRootRelationInput"]: {
	update?: ValueTypes["Contember_BusinessCategoryWithoutLocalesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryWithoutLocalesCreateInput"] | undefined | null
};
	["Contember_LocaleUpsertBusinessCategoriesRelationInput"]: {
	by?: ValueTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"] | undefined | null
};
	["Contember_ProductPackingLocaleUpsertLocaleRelationInput"]: {
	update?: ValueTypes["Contember_LocaleWithoutProductPackingsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_LocaleWithoutProductPackingsCreateInput"] | undefined | null
};
	["Contember_ProductPackingUpsertLocalesRelationInput"]: {
	by?: ValueTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductPackingLocaleWithoutRootUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingLocaleWithoutRootCreateInput"] | undefined | null
};
	["Contember_ProductUpsertPackingRelationInput"]: {
	update?: ValueTypes["Contember_ProductPackingUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductPackingCreateInput"] | undefined | null
};
	["Contember_TagUpsertProductsRelationInput"]: {
	by?: ValueTypes["Contember_ProductUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ProductWithoutTagsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ProductWithoutTagsCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertTagsRelationInput"]: {
	by?: ValueTypes["Contember_TagUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_TagWithoutCustomersUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_TagWithoutCustomersCreateInput"] | undefined | null
};
	["Contember_InvoiceUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutInvoicesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutInvoicesCreateInput"] | undefined | null
};
	["Contember_AddressUpsertInvoicesRelationInput"]: {
	by?: ValueTypes["Contember_InvoiceUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_InvoiceWithoutAddressUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_InvoiceWithoutAddressCreateInput"] | undefined | null
};
	["Contember_DeliveryZoneUpsertAddressesRelationInput"]: {
	by?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_AddressWithoutDeliveryZoneUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutDeliveryZoneCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryZoneUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"] | undefined | null
};
	["Contember_ChannelDeliveryUpsertMethodRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryMethodWithoutChannelsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutChannelsCreateInput"] | undefined | null
};
	["Contember_ChannelUpsertDeliveriesRelationInput"]: {
	by?: ValueTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelDeliveryWithoutChannelUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelDeliveryWithoutChannelCreateInput"] | undefined | null
};
	["Contember_ChannelPaymentUpsertChannelRelationInput"]: {
	update?: ValueTypes["Contember_ChannelWithoutPaymentsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelWithoutPaymentsCreateInput"] | undefined | null
};
	["Contember_PaymentMethodUpsertChannelsRelationInput"]: {
	by?: ValueTypes["Contember_ChannelPaymentUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_ChannelPaymentWithoutMethodUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_ChannelPaymentWithoutMethodCreateInput"] | undefined | null
};
	["Contember_DeliveryMethodUpsertPaymentMethodsRelationInput"]: {
	by?: ValueTypes["Contember_PaymentMethodUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"] | undefined | null
};
	["Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput"]: {
	by?: ValueTypes["Contember_DeliveryMethodUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"] | undefined | null
};
	["Contember_AddressUpsertDeliveryZoneRelationInput"]: {
	update?: ValueTypes["Contember_DeliveryZoneWithoutAddressesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_DeliveryZoneWithoutAddressesCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertAddressesRelationInput"]: {
	by?: ValueTypes["Contember_AddressUniqueWhere"] | undefined | null,
	update?: ValueTypes["Contember_AddressWithoutCustomerUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_AddressWithoutCustomerCreateInput"] | undefined | null
};
	["Contember_CustomerPaymentMethodUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutPaymentMethodsUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutPaymentMethodsCreateInput"] | undefined | null
};
	["Contember_CustomerUpsertDefaultPaymentMethodRelationInput"]: {
	update?: ValueTypes["Contember_CustomerPaymentMethodUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerPaymentMethodCreateInput"] | undefined | null
};
	["Contember_AddressUpsertCustomerRelationInput"]: {
	update?: ValueTypes["Contember_CustomerWithoutAddressesUpdateInput"] | undefined | null,
	create?: ValueTypes["Contember_CustomerWithoutAddressesCreateInput"] | undefined | null
};
	["Contember_CustomerUpdateInput"]: {
	addresses?: Array<ValueTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined | null,
	defaultBillingAddress?: ValueTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined | null,
	defaultPaymentMethod?: ValueTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined | null,
	tags?: Array<ValueTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined | null,
	credits?: Array<ValueTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined | null,
	paymentMethods?: Array<ValueTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined | null,
	orders?: Array<ValueTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined | null,
	permissions?: ValueTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined | null,
	group?: ValueTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined | null,
	photo?: ValueTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined | null,
	phone?: string | undefined | null,
	name?: ValueTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined | null,
	internalNote?: string | undefined | null,
	welcomeMessage?: string | undefined | null,
	familiarity?: ValueTypes["Contember_CustomerFamiliarity"] | undefined | null,
	ingredientRatings?: Array<ValueTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined | null,
	business?: ValueTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined | null,
	ownerOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined | null,
	memberOfBusinesses?: Array<ValueTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined | null,
	carts?: Array<ValueTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined | null,
	shortcutList?: ValueTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined | null,
	user?: ValueTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined | null,
	channels?: Array<ValueTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined | null,
	disabledAt?: ValueTypes["DateTime"] | undefined | null,
	invoices?: Array<ValueTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined | null,
	invoicingPlan?: ValueTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined | null,
	email?: string | undefined | null,
	createdAt?: ValueTypes["DateTime"] | undefined | null,
	billingSubjects?: Array<ValueTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined | null,
	offeredProducts?: Array<ValueTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined | null,
	defaultDeliveryAddress?: ValueTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined | null,
	defaultBillingSubject?: ValueTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined | null,
	prefersPackingsWithoutCardboard?: boolean | undefined | null,
	anonymousSession?: ValueTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined | null,
	boughtVouchers?: Array<ValueTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined | null,
	ownsVouchers?: Array<ValueTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined | null,
	redeemedVouchers?: Array<ValueTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined | null,
	spending?: ValueTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined | null,
	customerTabs?: Array<ValueTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined | null,
	lead?: Array<ValueTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined | null,
	doubleshotLegacyId?: string | undefined | null,
	meta?: ValueTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined | null,
	doubleshotLastUpdatedAt?: ValueTypes["DateTime"] | undefined | null,
	_dummy_field_?: boolean | undefined | null
};
	["Contember_QueryTransaction"]: AliasType<{
getCustomer?: [{	by: ValueTypes["Contember_CustomerUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null},ValueTypes["Contember_Customer"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_Info"]: AliasType<{
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember_MutationResult"]:AliasType<{
		ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["Contember__MutationError"];
		['...on Contember_CustomerUpdateResult']?: Omit<ValueTypes["Contember_CustomerUpdateResult"],keyof ValueTypes["Contember_MutationResult"]>;
		__typename?: boolean | `@${string}`
}>;
	["Contember__MutationError"]: AliasType<{
	path?:ValueTypes["Contember__PathFragment"],
	paths?:ValueTypes["Contember__PathFragment"],
	type?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Contember__MutationErrorType"]:Contember__MutationErrorType;
	["Contember_CustomerUpdateResult"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	errors?:ValueTypes["Contember__MutationError"],
	node?:ValueTypes["Contember_Customer"],
	validation?:ValueTypes["Contember__ValidationResult"],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MutationTransaction"]: AliasType<{
updateCustomer?: [{	by: ValueTypes["Contember_CustomerUniqueWhere"],	filter?: ValueTypes["Contember_CustomerWhere"] | undefined | null,	data: ValueTypes["Contember_CustomerUpdateInput"]},ValueTypes["Contember_CustomerUpdateResult"]],
		__typename?: boolean | `@${string}`
}>;
	["Contember_MutationTransactionOptions"]: {
	deferForeignKeyConstraints?: boolean | undefined | null
}
  }

export type ModelTypes = {
    ["Query"]: {
		getInfo?: GraphQLTypes["InfoResponse"] | undefined,
	getCart?: GraphQLTypes["Cart"] | undefined,
	getCartIssues?: GraphQLTypes["CartIssuesResponse"] | undefined,
	getCartDeliveryOptions?: GraphQLTypes["DeliveryOptionsResponse"] | undefined,
	getCartPaymentOptions?: GraphQLTypes["PaymentOptionsResponse"] | undefined,
	getPayment: GraphQLTypes["PaymentResponse"],
	getVoucher: GraphQLTypes["VoucherResponse"],
	getActiveOrder?: GraphQLTypes["OrderInfoResponse"] | undefined,
	listActiveOrder: Array<GraphQLTypes["OrderInfoResponse"]>,
	listCustomerToast: Array<GraphQLTypes["Toast"]>,
	getCustomer?: GraphQLTypes["Contember_Customer"] | undefined,
	transaction?: GraphQLTypes["Contember_QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Contember_Info"] | undefined
};
	["Mutation"]: {
		createCart: GraphQLTypes["UpdateCartResponse"],
	copyCartItems: GraphQLTypes["UpdateCartResponse"],
	setItems: GraphQLTypes["UpdateCartResponse"],
	calculatePosCart: GraphQLTypes["PosCart"],
	deleteCart: GraphQLTypes["DeleteCartResponse"],
	addItemToCart: GraphQLTypes["UpdateCartResponse"],
	setItemQuantityInCart: GraphQLTypes["UpdateCartResponse"],
	setCartDeliveryOption: GraphQLTypes["SetCartDeliveryOptionResponse"],
	setCartPaymentOption: GraphQLTypes["SetCartPaymentOptionResponse"],
	confirmOrder: GraphQLTypes["ConfirmOrderResponse"],
	updateCustomerInfo: GraphQLTypes["UpdateCustomerInfoResponse"],
	addAddress: GraphQLTypes["AddAddressResponse"],
	editAddress: GraphQLTypes["EditAddressResponse"],
	action?: GraphQLTypes["ActionResponse"] | undefined,
	redeemVoucher: GraphQLTypes["RedeemVoucherResponse"],
	updateCustomer: GraphQLTypes["Contember_CustomerUpdateResult"],
	transaction: GraphQLTypes["Contember_MutationTransaction"]
};
	["InfoResponse"]: {
		soonestDeliveryOption?: GraphQLTypes["DeliveryOption"] | undefined
};
	["DeliveryOption"]: {
		id: string,
	selected: boolean,
	delivery: GraphQLTypes["ChannelDelivery"],
	address?: GraphQLTypes["Address"] | undefined,
	timeline?: GraphQLTypes["DeliveryTimeline"] | undefined,
	timelineDescription: string,
	priceCents: number,
	vatRate: GraphQLTypes["VatRate"]
};
	["ChannelDelivery"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["ChannelDeliveryMeta"] | undefined,
	order: number,
	priceCents: number,
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	defaultVisible: boolean
};
	["UUID"]:string;
	["Address"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["AddressMeta"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_Country"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZone"] | undefined,
	meta?: GraphQLTypes["Contember_AddressMetadata"] | undefined,
	gps?: GraphQLTypes["Contember_Gps"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices: Array<GraphQLTypes["Contember_Invoice"]>,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders: Array<GraphQLTypes["Order"]>,
	deliveryAddressOfOrderDeliveries: Array<GraphQLTypes["OrderDelivery"]>,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_Customer"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Contember_Invoice"] | undefined,
	billingAddressOfOrdersBySeq?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDiscounts?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDelivery?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByItems?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByCart?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByEvents?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPriceLines?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPayments?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersBySummary?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByGratuity?: GraphQLTypes["Order"] | undefined,
	deliveryAddressOfOrderDeliveriesByTrackingCode?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByOrder?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByDeliveryWaypoint?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByEvents?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByLiftagoRide?: GraphQLTypes["OrderDelivery"] | undefined,
	paginateInvoices: GraphQLTypes["Contember_InvoiceConnection"],
	paginateBillingAddressOfOrders: GraphQLTypes["OrderConnection"],
	paginateDeliveryAddressOfOrderDeliveries: GraphQLTypes["OrderDeliveryConnection"]
};
	["DeliveryTimeline"]: {
		type: GraphQLTypes["DeliveryTimelineType"],
	prepareAt: string,
	loadingHours?: number | undefined,
	dispatchAt: string,
	duration: string,
	expectedAt: string,
	window: string,
	expectedEndAt?: string | undefined
};
	["DeliveryTimelineType"]: GraphQLTypes["DeliveryTimelineType"];
	["VatRate"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	channelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	paginateChannelDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginateChannelPayments: GraphQLTypes["ChannelPaymentConnection"]
};
	["ChannelCode"]: GraphQLTypes["ChannelCode"];
	["Cart"]: {
		id: GraphQLTypes["UUID"],
	handle: string,
	itemsPriceCents: number,
	deliveryPriceCents: number,
	totalPriceCents: number,
	remainingPriceCents: number,
	itemsCount: number,
	items: Array<GraphQLTypes["CartItem"]>,
	customerId?: GraphQLTypes["UUID"] | undefined,
	channel: GraphQLTypes["ChannelCode"],
	order?: GraphQLTypes["Order"] | undefined,
	deliveryZones: Array<string>,
	deliveryOption?: GraphQLTypes["DeliveryOption"] | undefined,
	paymentOption?: GraphQLTypes["PaymentOption"] | undefined,
	checkoutUrl?: string | undefined
};
	["CartItem"]: {
		itemId: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	productId: GraphQLTypes["UUID"],
	productName: string,
	productCode: string,
	quantity: number,
	availableQuantity: number,
	unitPriceCents: number,
	priceCents: number,
	product: GraphQLTypes["Product"],
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	deliveryZones: Array<string>
};
	["DateTime"]:string;
	["Product"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["ProductMeta"] | undefined,
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["Contember_ProductPacking"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	categories: Array<GraphQLTypes["Contember_ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["Contember_ProductStock"]>,
	groupPrices: Array<GraphQLTypes["Contember_ProductGroupPrice"]>,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems: Array<GraphQLTypes["Contember_CartItem"]>,
	availability: GraphQLTypes["Contember_ProductAvailability"],
	order: number,
	manager?: GraphQLTypes["Contember_StocksManager"] | undefined,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories: Array<GraphQLTypes["Contember_NestedProductCategoryProduct"]>,
	deliveryZones: Array<GraphQLTypes["Contember_DeliveryZone"]>,
	locales: Array<GraphQLTypes["Contember_ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices: Array<GraphQLTypes["Contember_ProductCustomerPrice"]>,
	virtualProduct?: GraphQLTypes["Contember_VirtualProduct"] | undefined,
	meta?: GraphQLTypes["Contember_ProductMetadata"] | undefined,
	page?: GraphQLTypes["Contember_Page"] | undefined,
	salesDays: Array<GraphQLTypes["Contember_ProductSalesDay"]>,
	salesWeeks: Array<GraphQLTypes["Contember_ProductSalesWeek"]>,
	salesMonths: Array<GraphQLTypes["Contember_ProductSalesMonth"]>,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	hasSubscription: boolean,
	pointOfSaleTileColor?: string | undefined,
	stocksByStore?: GraphQLTypes["Contember_ProductStock"] | undefined,
	groupPricesByGroup?: GraphQLTypes["Contember_ProductGroupPrice"] | undefined,
	cartItemsByCart?: GraphQLTypes["Contember_CartItem"] | undefined,
	orderItemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	customerPricesByCustomer?: GraphQLTypes["Contember_ProductCustomerPrice"] | undefined,
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateCategories: GraphQLTypes["Contember_ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["Contember_ProductStockConnection"],
	paginateGroupPrices: GraphQLTypes["Contember_ProductGroupPriceConnection"],
	paginateCartItems: GraphQLTypes["Contember_CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateInheritedCategories: GraphQLTypes["Contember_NestedProductCategoryProductConnection"],
	paginateDeliveryZones: GraphQLTypes["Contember_DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductLocaleConnection"],
	paginateCustomerPrices: GraphQLTypes["Contember_ProductCustomerPriceConnection"],
	paginateSalesDays: GraphQLTypes["Contember_ProductSalesDayConnection"],
	paginateSalesWeeks: GraphQLTypes["Contember_ProductSalesWeekConnection"],
	paginateSalesMonths: GraphQLTypes["Contember_ProductSalesMonthConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["Order"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["OrderMeta"] | undefined,
	state: GraphQLTypes["Contember_OrderState"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	discounts: Array<GraphQLTypes["OrderDiscount"]>,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	items: Array<GraphQLTypes["OrderItem"]>,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_Cart"] | undefined,
	events: Array<GraphQLTypes["Contember_OrderEvent"]>,
	createdAt: GraphQLTypes["DateTime"],
	billingAddress?: GraphQLTypes["Address"] | undefined,
	priceLines: Array<GraphQLTypes["OrderPriceLine"]>,
	payments: Array<GraphQLTypes["OrderPayment"]>,
	seq: number,
	billingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	summary?: GraphQLTypes["OrderSummary"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_Gratuity"] | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	discountsByCustomerCreditTransactions?: GraphQLTypes["OrderDiscount"] | undefined,
	discountsByVoucherRedemption?: GraphQLTypes["OrderDiscount"] | undefined,
	itemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	priceLinesByOrderItem?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDiscount?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderPayment?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDelivery?: GraphQLTypes["OrderPriceLine"] | undefined,
	paymentsByEvents?: GraphQLTypes["OrderPayment"] | undefined,
	paymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateDiscounts: GraphQLTypes["OrderDiscountConnection"],
	paginateItems: GraphQLTypes["OrderItemConnection"],
	paginateEvents: GraphQLTypes["Contember_OrderEventConnection"],
	paginatePriceLines: GraphQLTypes["OrderPriceLineConnection"],
	paginatePayments: GraphQLTypes["OrderPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["PaymentOption"]: {
		id: string,
	payment: GraphQLTypes["ChannelPayment"],
	creditsCents: number,
	priceCents: number,
	vatRate: GraphQLTypes["VatRate"],
	infoAvailableCredits?: number | undefined
};
	["ChannelPayment"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["ChannelPaymentMeta"] | undefined,
	order: number,
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags: Array<GraphQLTypes["Contember_Tag"]>,
	paginateAllowOnlyForTags: GraphQLTypes["Contember_TagConnection"]
};
	["CartIssuesResponse"]: {
		ok: boolean,
	issues: Array<GraphQLTypes["CartIssue"]>
};
	["CartIssue"]: {
		scope: string,
	code: string,
	message: string
};
	["DeliveryOptionsResponse"]: {
		methods: Array<GraphQLTypes["DeliveryMethodOption"]>,
	addresses: Array<GraphQLTypes["DeliveryAddressAddress"]>,
	selected?: GraphQLTypes["DeliveryOption"] | undefined
};
	["DeliveryMethodOption"]: {
		id: GraphQLTypes["UUID"],
	order?: number | undefined,
	selected: boolean,
	delivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	priceCents: number,
	vatRate: GraphQLTypes["VatRate"],
	options: Array<GraphQLTypes["DeliveryOption"]>
};
	["DeliveryAddressAddress"]: {
		id: GraphQLTypes["UUID"],
	selected: boolean,
	address: GraphQLTypes["Address"],
	methods: Array<GraphQLTypes["DeliveryMethodOption"]>
};
	["Date"]:any;
	["PaymentOptionsResponse"]: {
		availableCreditsCents: number,
	credits: Array<GraphQLTypes["CustomerCredit"] | undefined>,
	options: Array<GraphQLTypes["PaymentOption"]>
};
	["CustomerCredit"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["CustomerCreditMeta"] | undefined,
	initialCreditCents: number,
	remainingCreditsCents: number,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	transactions: Array<GraphQLTypes["Contember_CustomerCreditTransaction"]>,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	invoiceUrl?: string | undefined,
	transactionsByVirtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransaction"] | undefined,
	paginateTransactions: GraphQLTypes["Contember_CustomerCreditTransactionConnection"]
};
	["PaymentResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	payment?: GraphQLTypes["OrderPaymentInfo"] | undefined
};
	["OrderPaymentInfo"]: {
		id: GraphQLTypes["UUID"]
};
	["VoucherResponse"]: {
		redeemable: boolean,
	expiredAt?: GraphQLTypes["DateTime"] | undefined,
	redeemedAt?: GraphQLTypes["DateTime"] | undefined,
	creditCents?: number | undefined,
	type?: string | undefined,
	note?: string | undefined
};
	["OrderInfoResponse"]: {
		order: GraphQLTypes["Order"],
	timelineDescription?: string | undefined
};
	["Toast"]: {
		key: string,
	title: string,
	text?: string | undefined,
	icon?: GraphQLTypes["ToastIcon"] | undefined,
	detail?: GraphQLTypes["ToastDetail"] | undefined,
	button?: GraphQLTypes["ToastButton"] | undefined,
	rating?: Array<GraphQLTypes["ToastRating"]> | undefined
};
	["ToastIcon"]: {
		url: string
};
	["ToastDetail"]: {
		order?: GraphQLTypes["Order"] | undefined
};
	["ToastButton"]: {
		caption: string,
	url?: string | undefined,
	iosUrl?: string | undefined,
	androidUrl?: string | undefined,
	webview: boolean
};
	["ToastRating"]: {
		value: GraphQLTypes["ToastRatingValue"],
	url?: string | undefined,
	iosUrl?: string | undefined,
	androidUrl?: string | undefined
};
	["ToastRatingValue"]: GraphQLTypes["ToastRatingValue"];
	["UpdateCartResponse"]: {
		ok: boolean,
	errorCode?: string | undefined,
	cart?: GraphQLTypes["Cart"] | undefined
};
	["CartItemInput"]: GraphQLTypes["CartItemInput"];
	["PosCart"]: {
		customerId?: GraphQLTypes["UUID"] | undefined,
	items: Array<GraphQLTypes["PosItem"]>,
	totalPriceCents: number
};
	["PosItem"]: {
		productId: GraphQLTypes["UUID"],
	quantity: number,
	unitPriceCents: number,
	priceCents: number
};
	["PosCartInput"]: GraphQLTypes["PosCartInput"];
	["PosItemInput"]: GraphQLTypes["PosItemInput"];
	["DeleteCartResponse"]: {
		ok: boolean,
	errorCode?: string | undefined
};
	["SetCartDeliveryOptionResponse"]: {
		ok: boolean,
	errorCode?: string | undefined
};
	["SetCartPaymentOptionResponse"]: {
		ok: boolean,
	errorCode?: string | undefined
};
	["ConfirmOrderResponse"]: {
		ok: boolean,
	errorCode?: string | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	waitingForPayment?: GraphQLTypes["OrderPayment"] | undefined
};
	["OrderPayment"]: {
		id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["OrderPaymentMeta"] | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents: number,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["Contember_OrderPaymentState"],
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	events: Array<GraphQLTypes["Contember_OrderEvent"]>,
	order?: GraphQLTypes["Order"] | undefined,
	invoice?: GraphQLTypes["Contember_Invoice"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentMetadata"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	paginateEvents: GraphQLTypes["Contember_OrderEventConnection"]
};
	["PosFulfillment"]: GraphQLTypes["PosFulfillment"];
	["UpdateCustomerInfoResponse"]: {
		ok: boolean,
	errorCode?: string | undefined,
	errorMessage?: string | undefined
};
	["AddAddressResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	node?: GraphQLTypes["Address"] | undefined
};
	["AddressData"]: GraphQLTypes["AddressData"];
	["EditAddressResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	node?: GraphQLTypes["Address"] | undefined
};
	["ActionResponse"]: {
		ok: boolean,
	data?: string | undefined,
	errorMessage?: string | undefined
};
	["RedeemVoucherResponse"]: {
		ok: boolean,
	message?: string | undefined,
	errorMessage?: string | undefined,
	expiredAt?: GraphQLTypes["DateTime"] | undefined,
	redeemedAt?: GraphQLTypes["DateTime"] | undefined,
	creditCents?: number | undefined,
	type?: string | undefined,
	note?: string | undefined
};
	["AddressMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	firstName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lastName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hidden?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	country?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	googlePlaceId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	raw?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	formatted?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	geocodeResponse?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gps?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	geocodeValid?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fullName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	city?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FieldMeta"]: {
		readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["Contember_Customer"]: {
		_meta?: GraphQLTypes["Contember_CustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addresses: Array<GraphQLTypes["Address"]>,
	defaultBillingAddress?: GraphQLTypes["Address"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethod"] | undefined,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	credits: Array<GraphQLTypes["CustomerCredit"]>,
	paymentMethods: Array<GraphQLTypes["Contember_CustomerPaymentMethod"]>,
	orders: Array<GraphQLTypes["Order"]>,
	permissions?: GraphQLTypes["Contember_CustomerPermissions"] | undefined,
	group?: GraphQLTypes["Contember_CustomerGroup"] | undefined,
	photo?: GraphQLTypes["Contember_Image"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerName"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings: Array<GraphQLTypes["Contember_CustomerIngredientRating"]>,
	business?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	ownerOfBusinesses: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	memberOfBusinesses: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	carts: Array<GraphQLTypes["Contember_Cart"]>,
	shortcutList?: GraphQLTypes["Contember_ApplicationShortcutList"] | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined,
	channels: Array<GraphQLTypes["Contember_Channel"]>,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices: Array<GraphQLTypes["Contember_Invoice"]>,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlan"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects: Array<GraphQLTypes["Contember_BillingSubject"]>,
	offeredProducts: Array<GraphQLTypes["Contember_ProductCustomerPrice"]>,
	defaultDeliveryAddress?: GraphQLTypes["Address"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	prefersPackingsWithoutCardboard: boolean,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSession"] | undefined,
	boughtVouchers: Array<GraphQLTypes["Contember_Voucher"]>,
	ownsVouchers: Array<GraphQLTypes["Contember_Voucher"]>,
	redeemedVouchers: Array<GraphQLTypes["Contember_VoucherRedemption"]>,
	spending?: GraphQLTypes["Contember_CustomerSpending"] | undefined,
	customerTabs: Array<GraphQLTypes["Contember_CustomerTab"]>,
	lead: Array<GraphQLTypes["Contember_PipelineLead"]>,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerMetadata"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	creditsByTransactions?: GraphQLTypes["CustomerCredit"] | undefined,
	creditsByVoucherRedemption?: GraphQLTypes["CustomerCredit"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByEvents?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ordersByGratuity?: GraphQLTypes["Order"] | undefined,
	ingredientRatingsByIngredient?: GraphQLTypes["Contember_CustomerIngredientRating"] | undefined,
	cartsByItems?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Contember_Cart"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Contember_Invoice"] | undefined,
	billingSubjectsByInvoices?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	billingSubjectsByDefaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	billingSubjectsByOrders?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	offeredProductsByProduct?: GraphQLTypes["Contember_ProductCustomerPrice"] | undefined,
	boughtVouchersByCode?: GraphQLTypes["Contember_Voucher"] | undefined,
	boughtVouchersByRedemption?: GraphQLTypes["Contember_Voucher"] | undefined,
	ownsVouchersByCode?: GraphQLTypes["Contember_Voucher"] | undefined,
	ownsVouchersByRedemption?: GraphQLTypes["Contember_Voucher"] | undefined,
	redeemedVouchersByCustomerCredit?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	redeemedVouchersByOrderDiscount?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	redeemedVouchersByVoucher?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	customerTabsByItems?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	customerTabsByMeta?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	leadByStageItem?: GraphQLTypes["Contember_PipelineLead"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateCredits: GraphQLTypes["CustomerCreditConnection"],
	paginatePaymentMethods: GraphQLTypes["Contember_CustomerPaymentMethodConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateIngredientRatings: GraphQLTypes["Contember_CustomerIngredientRatingConnection"],
	paginateOwnerOfBusinesses: GraphQLTypes["Contember_BusinessCustomerConnection"],
	paginateMemberOfBusinesses: GraphQLTypes["Contember_BusinessCustomerConnection"],
	paginateCarts: GraphQLTypes["Contember_CartConnection"],
	paginateChannels: GraphQLTypes["Contember_ChannelConnection"],
	paginateInvoices: GraphQLTypes["Contember_InvoiceConnection"],
	paginateBillingSubjects: GraphQLTypes["Contember_BillingSubjectConnection"],
	paginateOfferedProducts: GraphQLTypes["Contember_ProductCustomerPriceConnection"],
	paginateBoughtVouchers: GraphQLTypes["Contember_VoucherConnection"],
	paginateOwnsVouchers: GraphQLTypes["Contember_VoucherConnection"],
	paginateRedeemedVouchers: GraphQLTypes["Contember_VoucherRedemptionConnection"],
	paginateCustomerTabs: GraphQLTypes["Contember_CustomerTabConnection"],
	paginateLead: GraphQLTypes["Contember_PipelineLeadConnection"]
};
	["Contember_CustomerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addresses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	credits?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	permissions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	group?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	photo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	welcomeMessage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	familiarity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredientRatings?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	business?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ownerOfBusinesses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	memberOfBusinesses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shortcutList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubjects?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offeredProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	boughtVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ownsVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	redeemedVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	spending?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTabs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AddressWhere"]: GraphQLTypes["Contember_AddressWhere"];
	["Contember_UUIDCondition"]: GraphQLTypes["Contember_UUIDCondition"];
	["Contember_StringCondition"]: GraphQLTypes["Contember_StringCondition"];
	["Contember_BooleanCondition"]: GraphQLTypes["Contember_BooleanCondition"];
	["Contember_CustomerWhere"]: GraphQLTypes["Contember_CustomerWhere"];
	["Contember_CustomerPaymentMethodWhere"]: GraphQLTypes["Contember_CustomerPaymentMethodWhere"];
	["Contember_JsonCondition"]: GraphQLTypes["Contember_JsonCondition"];
	["Contember_PaymentMethodWhere"]: GraphQLTypes["Contember_PaymentMethodWhere"];
	["Contember_DeliveryMethodWhere"]: GraphQLTypes["Contember_DeliveryMethodWhere"];
	["Contember_DeliveryZoneWhere"]: GraphQLTypes["Contember_DeliveryZoneWhere"];
	["Contember_DeliveryZoneTypeCondition"]: GraphQLTypes["Contember_DeliveryZoneTypeCondition"];
	["Contember_DeliveryZoneType"]: GraphQLTypes["Contember_DeliveryZoneType"];
	["Contember_TagWhere"]: GraphQLTypes["Contember_TagWhere"];
	["Contember_ProductWhere"]: GraphQLTypes["Contember_ProductWhere"];
	["Contember_IntCondition"]: GraphQLTypes["Contember_IntCondition"];
	["Contember_ProductPackingWhere"]: GraphQLTypes["Contember_ProductPackingWhere"];
	["Contember_ImageWhere"]: GraphQLTypes["Contember_ImageWhere"];
	["Contember_ProductPackingLocaleWhere"]: GraphQLTypes["Contember_ProductPackingLocaleWhere"];
	["Contember_LocaleWhere"]: GraphQLTypes["Contember_LocaleWhere"];
	["Contember_BusinessCategoryLocaleWhere"]: GraphQLTypes["Contember_BusinessCategoryLocaleWhere"];
	["Contember_BusinessCategoryWhere"]: GraphQLTypes["Contember_BusinessCategoryWhere"];
	["Contember_BusinessCustomerWhere"]: GraphQLTypes["Contember_BusinessCustomerWhere"];
	["Contember_BusinessToBusinessManagerWhere"]: GraphQLTypes["Contember_BusinessToBusinessManagerWhere"];
	["Contember_StaffWhere"]: GraphQLTypes["Contember_StaffWhere"];
	["Contember_BartenderWhere"]: GraphQLTypes["Contember_BartenderWhere"];
	["Contember_DateTimeCondition"]: GraphQLTypes["Contember_DateTimeCondition"];
	["Contember_CourierWhere"]: GraphQLTypes["Contember_CourierWhere"];
	["Contember_DeliveryRouteWhere"]: GraphQLTypes["Contember_DeliveryRouteWhere"];
	["Contember_DeliveryWaypointWhere"]: GraphQLTypes["Contember_DeliveryWaypointWhere"];
	["Contember_OrderDeliveryWhere"]: GraphQLTypes["Contember_OrderDeliveryWhere"];
	["Contember_VatRateWhere"]: GraphQLTypes["Contember_VatRateWhere"];
	["Contember_ChannelDeliveryWhere"]: GraphQLTypes["Contember_ChannelDeliveryWhere"];
	["Contember_ChannelWhere"]: GraphQLTypes["Contember_ChannelWhere"];
	["Contember_ChannelPaymentWhere"]: GraphQLTypes["Contember_ChannelPaymentWhere"];
	["Contember_PointOfSaleWhere"]: GraphQLTypes["Contember_PointOfSaleWhere"];
	["Contember_PointOfSalePermissionsWhere"]: GraphQLTypes["Contember_PointOfSalePermissionsWhere"];
	["Contember_CartWhere"]: GraphQLTypes["Contember_CartWhere"];
	["Contember_CartItemWhere"]: GraphQLTypes["Contember_CartItemWhere"];
	["Contember_FulfillmentNoteWhere"]: GraphQLTypes["Contember_FulfillmentNoteWhere"];
	["Contember_VenueWhere"]: GraphQLTypes["Contember_VenueWhere"];
	["Contember_DeadlineTemplateWhere"]: GraphQLTypes["Contember_DeadlineTemplateWhere"];
	["Contember_DateCondition"]: GraphQLTypes["Contember_DateCondition"];
	["Contember_TimetableDayWhere"]: GraphQLTypes["Contember_TimetableDayWhere"];
	["Contember_TimetableDayTypeCondition"]: GraphQLTypes["Contember_TimetableDayTypeCondition"];
	["Contember_TimetableDayType"]: GraphQLTypes["Contember_TimetableDayType"];
	["Contember_TimetableShiftGroupWhere"]: GraphQLTypes["Contember_TimetableShiftGroupWhere"];
	["Contember_TimetableShiftWhere"]: GraphQLTypes["Contember_TimetableShiftWhere"];
	["Contember_ShiftsProfileWhere"]: GraphQLTypes["Contember_ShiftsProfileWhere"];
	["Contember_EmployeeQueueItemWhere"]: GraphQLTypes["Contember_EmployeeQueueItemWhere"];
	["Contember_EmployeeQueueWhere"]: GraphQLTypes["Contember_EmployeeQueueWhere"];
	["Contember_UnassignedShiftInfoWhere"]: GraphQLTypes["Contember_UnassignedShiftInfoWhere"];
	["Contember_UnassignedInfoReasonCondition"]: GraphQLTypes["Contember_UnassignedInfoReasonCondition"];
	["Contember_UnassignedInfoReason"]: GraphQLTypes["Contember_UnassignedInfoReason"];
	["Contember_UnassignReasonWhere"]: GraphQLTypes["Contember_UnassignReasonWhere"];
	["Contember_EmployeeQueueItemMetadataWhere"]: GraphQLTypes["Contember_EmployeeQueueItemMetadataWhere"];
	["Contember_TimetableEmployeeRoleWhere"]: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"];
	["Contember_ShiftsManagerWhere"]: GraphQLTypes["Contember_ShiftsManagerWhere"];
	["Contember_TimetableTemplateWhere"]: GraphQLTypes["Contember_TimetableTemplateWhere"];
	["Contember_TimetableShiftPositionWhere"]: GraphQLTypes["Contember_TimetableShiftPositionWhere"];
	["Contember_TimetableTemplateStaffOptionsWhere"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWhere"];
	["Contember_TemplateTypeCondition"]: GraphQLTypes["Contember_TemplateTypeCondition"];
	["Contember_TemplateType"]: GraphQLTypes["Contember_TemplateType"];
	["Contember_TimetableShiftSwapInfoWhere"]: GraphQLTypes["Contember_TimetableShiftSwapInfoWhere"];
	["Contember_TimetableDayNoteWhere"]: GraphQLTypes["Contember_TimetableDayNoteWhere"];
	["Contember_TimetableDayMetadataWhere"]: GraphQLTypes["Contember_TimetableDayMetadataWhere"];
	["Contember_FloatCondition"]: GraphQLTypes["Contember_FloatCondition"];
	["Contember_VenueEmployeeRoleWhere"]: GraphQLTypes["Contember_VenueEmployeeRoleWhere"];
	["Contember_TimetablesEmployeePrivilegesWhere"]: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWhere"];
	["Contember_PointOfSaleApplicationDeviceWhere"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"];
	["Contember_IdentificationCodeWhere"]: GraphQLTypes["Contember_IdentificationCodeWhere"];
	["Contember_IdentificationAttemptWhere"]: GraphQLTypes["Contember_IdentificationAttemptWhere"];
	["Contember_UserWhere"]: GraphQLTypes["Contember_UserWhere"];
	["Contember_UserDeviceWhere"]: GraphQLTypes["Contember_UserDeviceWhere"];
	["Contember_AnonymousSessionWhere"]: GraphQLTypes["Contember_AnonymousSessionWhere"];
	["Contember_UserMetadataWhere"]: GraphQLTypes["Contember_UserMetadataWhere"];
	["Contember_UserRolesWhere"]: GraphQLTypes["Contember_UserRolesWhere"];
	["Contember_IdentificationCodeLocaleWhere"]: GraphQLTypes["Contember_IdentificationCodeLocaleWhere"];
	["Contember_DeviceIdentifierWhere"]: GraphQLTypes["Contember_DeviceIdentifierWhere"];
	["Contember_DeviceIdentifierMetadataWhere"]: GraphQLTypes["Contember_DeviceIdentifierMetadataWhere"];
	["Contember_PointOfSaleTerminalWhere"]: GraphQLTypes["Contember_PointOfSaleTerminalWhere"];
	["Contember_PointOfSaleTerminalTypeCondition"]: GraphQLTypes["Contember_PointOfSaleTerminalTypeCondition"];
	["Contember_PointOfSaleTerminalType"]: GraphQLTypes["Contember_PointOfSaleTerminalType"];
	["Contember_PointOfSalePrinterWhere"]: GraphQLTypes["Contember_PointOfSalePrinterWhere"];
	["Contember_PointOfSaleCategoryWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryWhere"];
	["Contember_PointOfSaleCategoryLocaleWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWhere"];
	["Contember_PointOfSaleCategoryProductWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryProductWhere"];
	["Contember_PointOfSaleCategoryProductLocaleWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWhere"];
	["Contember_ProductCategoryWhere"]: GraphQLTypes["Contember_ProductCategoryWhere"];
	["Contember_ProductCategoryTreeWhere"]: GraphQLTypes["Contember_ProductCategoryTreeWhere"];
	["Contember_InheritedProductCategoryTagWhere"]: GraphQLTypes["Contember_InheritedProductCategoryTagWhere"];
	["Contember_NestedProductCategoryProductWhere"]: GraphQLTypes["Contember_NestedProductCategoryProductWhere"];
	["Contember_OrderWhere"]: GraphQLTypes["Contember_OrderWhere"];
	["Contember_OrderStateCondition"]: GraphQLTypes["Contember_OrderStateCondition"];
	["Contember_OrderState"]: GraphQLTypes["Contember_OrderState"];
	["Contember_OrderDiscountWhere"]: GraphQLTypes["Contember_OrderDiscountWhere"];
	["Contember_OrderDiscountTypeCondition"]: GraphQLTypes["Contember_OrderDiscountTypeCondition"];
	["Contember_OrderDiscountType"]: GraphQLTypes["Contember_OrderDiscountType"];
	["Contember_CustomerCreditTransactionWhere"]: GraphQLTypes["Contember_CustomerCreditTransactionWhere"];
	["Contember_CustomerCreditWhere"]: GraphQLTypes["Contember_CustomerCreditWhere"];
	["Contember_VoucherRedemptionWhere"]: GraphQLTypes["Contember_VoucherRedemptionWhere"];
	["Contember_VoucherWhere"]: GraphQLTypes["Contember_VoucherWhere"];
	["Contember_VoucherTypeCondition"]: GraphQLTypes["Contember_VoucherTypeCondition"];
	["Contember_VoucherType"]: GraphQLTypes["Contember_VoucherType"];
	["Contember_VirtualProductEffectWhere"]: GraphQLTypes["Contember_VirtualProductEffectWhere"];
	["Contember_OrderItemWhere"]: GraphQLTypes["Contember_OrderItemWhere"];
	["Contember_OrderEventWhere"]: GraphQLTypes["Contember_OrderEventWhere"];
	["Contember_OrderPaymentWhere"]: GraphQLTypes["Contember_OrderPaymentWhere"];
	["Contember_OrderPaymentStateCondition"]: GraphQLTypes["Contember_OrderPaymentStateCondition"];
	["Contember_OrderPaymentState"]: GraphQLTypes["Contember_OrderPaymentState"];
	["Contember_InvoiceWhere"]: GraphQLTypes["Contember_InvoiceWhere"];
	["Contember_BillingSubjectWhere"]: GraphQLTypes["Contember_BillingSubjectWhere"];
	["Contember_CountryWhere"]: GraphQLTypes["Contember_CountryWhere"];
	["Contember_OrderPaymentMetadataWhere"]: GraphQLTypes["Contember_OrderPaymentMetadataWhere"];
	["Contember_OrderPriceLineWhere"]: GraphQLTypes["Contember_OrderPriceLineWhere"];
	["Contember_OrderPriceLineTypeCondition"]: GraphQLTypes["Contember_OrderPriceLineTypeCondition"];
	["Contember_OrderPriceLineType"]: GraphQLTypes["Contember_OrderPriceLineType"];
	["Contember_OrderSummaryWhere"]: GraphQLTypes["Contember_OrderSummaryWhere"];
	["Contember_GratuityWhere"]: GraphQLTypes["Contember_GratuityWhere"];
	["Contember_AreaWhere"]: GraphQLTypes["Contember_AreaWhere"];
	["Contember_TableWhere"]: GraphQLTypes["Contember_TableWhere"];
	["Contember_TableShapeCondition"]: GraphQLTypes["Contember_TableShapeCondition"];
	["Contember_TableShape"]: GraphQLTypes["Contember_TableShape"];
	["Contember_CustomerTabWhere"]: GraphQLTypes["Contember_CustomerTabWhere"];
	["Contember_CustomerTabItemWhere"]: GraphQLTypes["Contember_CustomerTabItemWhere"];
	["Contember_CustomerTabMetadataWhere"]: GraphQLTypes["Contember_CustomerTabMetadataWhere"];
	["Contember_SeatWhere"]: GraphQLTypes["Contember_SeatWhere"];
	["Contember_LineWhere"]: GraphQLTypes["Contember_LineWhere"];
	["Contember_PointOfSaleInitialViewCondition"]: GraphQLTypes["Contember_PointOfSaleInitialViewCondition"];
	["Contember_PointOfSaleInitialView"]: GraphQLTypes["Contember_PointOfSaleInitialView"];
	["Contember_CartStateCondition"]: GraphQLTypes["Contember_CartStateCondition"];
	["Contember_CartState"]: GraphQLTypes["Contember_CartState"];
	["Contember_CartPriceLineWhere"]: GraphQLTypes["Contember_CartPriceLineWhere"];
	["Contember_CartPriceLineTypeCondition"]: GraphQLTypes["Contember_CartPriceLineTypeCondition"];
	["Contember_CartPriceLineType"]: GraphQLTypes["Contember_CartPriceLineType"];
	["Contember_CartSummaryWhere"]: GraphQLTypes["Contember_CartSummaryWhere"];
	["Contember_ChannelCodeCondition"]: GraphQLTypes["Contember_ChannelCodeCondition"];
	["Contember_ChannelCode"]: GraphQLTypes["Contember_ChannelCode"];
	["Contember_VatRatePresetCondition"]: GraphQLTypes["Contember_VatRatePresetCondition"];
	["Contember_VatRatePreset"]: GraphQLTypes["Contember_VatRatePreset"];
	["Contember_OrderDeliveryStateCondition"]: GraphQLTypes["Contember_OrderDeliveryStateCondition"];
	["Contember_OrderDeliveryState"]: GraphQLTypes["Contember_OrderDeliveryState"];
	["Contember_LiftagoRideWhere"]: GraphQLTypes["Contember_LiftagoRideWhere"];
	["Contember_CleanerWhere"]: GraphQLTypes["Contember_CleanerWhere"];
	["Contember_StocksManagerWhere"]: GraphQLTypes["Contember_StocksManagerWhere"];
	["Contember_CourierManagerWhere"]: GraphQLTypes["Contember_CourierManagerWhere"];
	["Contember_PickerWhere"]: GraphQLTypes["Contember_PickerWhere"];
	["Contember_RemunerationProfileWhere"]: GraphQLTypes["Contember_RemunerationProfileWhere"];
	["Contember_EmploymentInformationWhere"]: GraphQLTypes["Contember_EmploymentInformationWhere"];
	["Contember_EmploymentTypeCondition"]: GraphQLTypes["Contember_EmploymentTypeCondition"];
	["Contember_EmploymentType"]: GraphQLTypes["Contember_EmploymentType"];
	["Contember_WorkMonthWhere"]: GraphQLTypes["Contember_WorkMonthWhere"];
	["Contember_CashierWhere"]: GraphQLTypes["Contember_CashierWhere"];
	["Contember_InternalBuyerWhere"]: GraphQLTypes["Contember_InternalBuyerWhere"];
	["Contember_SalesManagerWhere"]: GraphQLTypes["Contember_SalesManagerWhere"];
	["Contember_CashierManagerWhere"]: GraphQLTypes["Contember_CashierManagerWhere"];
	["Contember_BusinessServiceEventWhere"]: GraphQLTypes["Contember_BusinessServiceEventWhere"];
	["Contember_BusinessServiceWhere"]: GraphQLTypes["Contember_BusinessServiceWhere"];
	["Contember_BusinessServiceLocaleWhere"]: GraphQLTypes["Contember_BusinessServiceLocaleWhere"];
	["Contember_DoubleshotCoffeeEquipmentWhere"]: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentWhere"];
	["Contember_OrderRecurrenceWhere"]: GraphQLTypes["Contember_OrderRecurrenceWhere"];
	["Contember_OrderRecurrenceLocaleWhere"]: GraphQLTypes["Contember_OrderRecurrenceLocaleWhere"];
	["Contember_ProductListWhere"]: GraphQLTypes["Contember_ProductListWhere"];
	["Contember_ProductListItemWhere"]: GraphQLTypes["Contember_ProductListItemWhere"];
	["Contember_ProductCategoryListWhere"]: GraphQLTypes["Contember_ProductCategoryListWhere"];
	["Contember_ProductCategoryListItemWhere"]: GraphQLTypes["Contember_ProductCategoryListItemWhere"];
	["Contember_ProductCategoryListLocaleWhere"]: GraphQLTypes["Contember_ProductCategoryListLocaleWhere"];
	["Contember_ProductIngredientLocaleWhere"]: GraphQLTypes["Contember_ProductIngredientLocaleWhere"];
	["Contember_ProductIngredientWhere"]: GraphQLTypes["Contember_ProductIngredientWhere"];
	["Contember_ProductIngredientCategoryWhere"]: GraphQLTypes["Contember_ProductIngredientCategoryWhere"];
	["Contember_ProductIngredientCategoryLocaleWhere"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWhere"];
	["Contember_AllergenWhere"]: GraphQLTypes["Contember_AllergenWhere"];
	["Contember_AllergenLocaleWhere"]: GraphQLTypes["Contember_AllergenLocaleWhere"];
	["Contember_CustomerIngredientRatingWhere"]: GraphQLTypes["Contember_CustomerIngredientRatingWhere"];
	["Contember_ProductIngredientRatingWhere"]: GraphQLTypes["Contember_ProductIngredientRatingWhere"];
	["Contember_ProductIngredientItemQuantityUnitWhere"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitWhere"];
	["Contember_FreshingContainerWhere"]: GraphQLTypes["Contember_FreshingContainerWhere"];
	["Contember_WorkspaceWhere"]: GraphQLTypes["Contember_WorkspaceWhere"];
	["Contember_OneCondition"]: GraphQLTypes["Contember_OneCondition"];
	["Contember_One"]: GraphQLTypes["Contember_One"];
	["Contember_WeekHoursWhere"]: GraphQLTypes["Contember_WeekHoursWhere"];
	["Contember_VirtualProductWhere"]: GraphQLTypes["Contember_VirtualProductWhere"];
	["Contember_VirtualProductTypeCondition"]: GraphQLTypes["Contember_VirtualProductTypeCondition"];
	["Contember_VirtualProductType"]: GraphQLTypes["Contember_VirtualProductType"];
	["Contember_VirtualProductPhysicalRepresentationTypeCondition"]: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"];
	["Contember_VirtualProductPhysicalRepresentationType"]: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"];
	["Contember_IngredientSupplierListWhere"]: GraphQLTypes["Contember_IngredientSupplierListWhere"];
	["Contember_IngredientSupplierItemWhere"]: GraphQLTypes["Contember_IngredientSupplierItemWhere"];
	["Contember_IngredientSupplierWhere"]: GraphQLTypes["Contember_IngredientSupplierWhere"];
	["Contember_IngredientSupplierLocaleWhere"]: GraphQLTypes["Contember_IngredientSupplierLocaleWhere"];
	["Contember_BlogLocaleWhere"]: GraphQLTypes["Contember_BlogLocaleWhere"];
	["Contember_BlogWhere"]: GraphQLTypes["Contember_BlogWhere"];
	["Contember_BlogPostLocaleWhere"]: GraphQLTypes["Contember_BlogPostLocaleWhere"];
	["Contember_BlogPostWhere"]: GraphQLTypes["Contember_BlogPostWhere"];
	["Contember_CoverWhere"]: GraphQLTypes["Contember_CoverWhere"];
	["Contember_MediumWhere"]: GraphQLTypes["Contember_MediumWhere"];
	["Contember_MediumTypeCondition"]: GraphQLTypes["Contember_MediumTypeCondition"];
	["Contember_MediumType"]: GraphQLTypes["Contember_MediumType"];
	["Contember_ColorThemeCondition"]: GraphQLTypes["Contember_ColorThemeCondition"];
	["Contember_ColorTheme"]: GraphQLTypes["Contember_ColorTheme"];
	["Contember_VideoWhere"]: GraphQLTypes["Contember_VideoWhere"];
	["Contember_ColorPalleteWhere"]: GraphQLTypes["Contember_ColorPalleteWhere"];
	["Contember_ContentWhere"]: GraphQLTypes["Contember_ContentWhere"];
	["Contember_ContentBlockWhere"]: GraphQLTypes["Contember_ContentBlockWhere"];
	["Contember_ContentReferenceWhere"]: GraphQLTypes["Contember_ContentReferenceWhere"];
	["Contember_ContentReferenceTypeCondition"]: GraphQLTypes["Contember_ContentReferenceTypeCondition"];
	["Contember_ContentReferenceType"]: GraphQLTypes["Contember_ContentReferenceType"];
	["Contember_LinkWhere"]: GraphQLTypes["Contember_LinkWhere"];
	["Contember_LinkTypeCondition"]: GraphQLTypes["Contember_LinkTypeCondition"];
	["Contember_LinkType"]: GraphQLTypes["Contember_LinkType"];
	["Contember_LinkableWhere"]: GraphQLTypes["Contember_LinkableWhere"];
	["Contember_PageLocaleWhere"]: GraphQLTypes["Contember_PageLocaleWhere"];
	["Contember_PageWhere"]: GraphQLTypes["Contember_PageWhere"];
	["Contember_SeoWhere"]: GraphQLTypes["Contember_SeoWhere"];
	["Contember_PageThemeCondition"]: GraphQLTypes["Contember_PageThemeCondition"];
	["Contember_PageTheme"]: GraphQLTypes["Contember_PageTheme"];
	["Contember_WikiPageWhere"]: GraphQLTypes["Contember_WikiPageWhere"];
	["Contember_RedirectWhere"]: GraphQLTypes["Contember_RedirectWhere"];
	["Contember_BlogPostListWhere"]: GraphQLTypes["Contember_BlogPostListWhere"];
	["Contember_BlogPostListItemWhere"]: GraphQLTypes["Contember_BlogPostListItemWhere"];
	["Contember_LinkListWhere"]: GraphQLTypes["Contember_LinkListWhere"];
	["Contember_LinkListItemWhere"]: GraphQLTypes["Contember_LinkListItemWhere"];
	["Contember_ContentSizeCondition"]: GraphQLTypes["Contember_ContentSizeCondition"];
	["Contember_ContentSize"]: GraphQLTypes["Contember_ContentSize"];
	["Contember_HeroWhere"]: GraphQLTypes["Contember_HeroWhere"];
	["Contember_GalleryWhere"]: GraphQLTypes["Contember_GalleryWhere"];
	["Contember_GalleryItemWhere"]: GraphQLTypes["Contember_GalleryItemWhere"];
	["Contember_SocialsAndAppsWhere"]: GraphQLTypes["Contember_SocialsAndAppsWhere"];
	["Contember_ProductGridWhere"]: GraphQLTypes["Contember_ProductGridWhere"];
	["Contember_ProductGridCategoryWhere"]: GraphQLTypes["Contember_ProductGridCategoryWhere"];
	["Contember_ProductGridItemWhere"]: GraphQLTypes["Contember_ProductGridItemWhere"];
	["Contember_ImageListWhere"]: GraphQLTypes["Contember_ImageListWhere"];
	["Contember_ImageListItemWhere"]: GraphQLTypes["Contember_ImageListItemWhere"];
	["Contember_ProductGridTypeCondition"]: GraphQLTypes["Contember_ProductGridTypeCondition"];
	["Contember_ProductGridType"]: GraphQLTypes["Contember_ProductGridType"];
	["Contember_ProductBannerListWhere"]: GraphQLTypes["Contember_ProductBannerListWhere"];
	["Contember_ProductBannerItemWhere"]: GraphQLTypes["Contember_ProductBannerItemWhere"];
	["Contember_ProductBannerWhere"]: GraphQLTypes["Contember_ProductBannerWhere"];
	["Contember_ProductBannerItemSizeCondition"]: GraphQLTypes["Contember_ProductBannerItemSizeCondition"];
	["Contember_ProductBannerItemSize"]: GraphQLTypes["Contember_ProductBannerItemSize"];
	["Contember_DeliveryRegionsWhere"]: GraphQLTypes["Contember_DeliveryRegionsWhere"];
	["Contember_DeliveryRegionsItemWhere"]: GraphQLTypes["Contember_DeliveryRegionsItemWhere"];
	["Contember_TextListWhere"]: GraphQLTypes["Contember_TextListWhere"];
	["Contember_TextItemWhere"]: GraphQLTypes["Contember_TextItemWhere"];
	["Contember_GridTilesWhere"]: GraphQLTypes["Contember_GridTilesWhere"];
	["Contember_GridTileItemWhere"]: GraphQLTypes["Contember_GridTileItemWhere"];
	["Contember_TranslationCataloguesIdentifierWhere"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierWhere"];
	["Contember_TranslationCatalogueWhere"]: GraphQLTypes["Contember_TranslationCatalogueWhere"];
	["Contember_TranslationDomainWhere"]: GraphQLTypes["Contember_TranslationDomainWhere"];
	["Contember_TranslationKeyWhere"]: GraphQLTypes["Contember_TranslationKeyWhere"];
	["Contember_TranslationContentTypeCondition"]: GraphQLTypes["Contember_TranslationContentTypeCondition"];
	["Contember_TranslationContentType"]: GraphQLTypes["Contember_TranslationContentType"];
	["Contember_TranslationValueWhere"]: GraphQLTypes["Contember_TranslationValueWhere"];
	["Contember_MenuLocaleWhere"]: GraphQLTypes["Contember_MenuLocaleWhere"];
	["Contember_SiteMenuWhere"]: GraphQLTypes["Contember_SiteMenuWhere"];
	["Contember_MenuItemWhere"]: GraphQLTypes["Contember_MenuItemWhere"];
	["Contember_MenuSubitemWhere"]: GraphQLTypes["Contember_MenuSubitemWhere"];
	["Contember_MenuSubitemTypeCondition"]: GraphQLTypes["Contember_MenuSubitemTypeCondition"];
	["Contember_MenuSubitemType"]: GraphQLTypes["Contember_MenuSubitemType"];
	["Contember_MenuLinkListWhere"]: GraphQLTypes["Contember_MenuLinkListWhere"];
	["Contember_MenuLinkItemWhere"]: GraphQLTypes["Contember_MenuLinkItemWhere"];
	["Contember_MenuProductsWhere"]: GraphQLTypes["Contember_MenuProductsWhere"];
	["Contember_MenuDeliveryWhere"]: GraphQLTypes["Contember_MenuDeliveryWhere"];
	["Contember_MenuContactWhere"]: GraphQLTypes["Contember_MenuContactWhere"];
	["Contember_ProductLocaleWhere"]: GraphQLTypes["Contember_ProductLocaleWhere"];
	["Contember_ProductRecipeLocaleWhere"]: GraphQLTypes["Contember_ProductRecipeLocaleWhere"];
	["Contember_ProductRecipeWhere"]: GraphQLTypes["Contember_ProductRecipeWhere"];
	["Contember_ProductIngredientItemWhere"]: GraphQLTypes["Contember_ProductIngredientItemWhere"];
	["Contember_ProductIngredientItemQuantityWhere"]: GraphQLTypes["Contember_ProductIngredientItemQuantityWhere"];
	["Contember_NutritionFactsWhere"]: GraphQLTypes["Contember_NutritionFactsWhere"];
	["Contember_DeliveryMethodLocaleWhere"]: GraphQLTypes["Contember_DeliveryMethodLocaleWhere"];
	["Contember_PipelineStageLocaleWhere"]: GraphQLTypes["Contember_PipelineStageLocaleWhere"];
	["Contember_PipelineStageWhere"]: GraphQLTypes["Contember_PipelineStageWhere"];
	["Contember_PipelineStageItemWhere"]: GraphQLTypes["Contember_PipelineStageItemWhere"];
	["Contember_PipelineLeadWhere"]: GraphQLTypes["Contember_PipelineLeadWhere"];
	["Contember_LeadFormWhere"]: GraphQLTypes["Contember_LeadFormWhere"];
	["Contember_PipelineWhere"]: GraphQLTypes["Contember_PipelineWhere"];
	["Contember_PipelineStageSentimentWhere"]: GraphQLTypes["Contember_PipelineStageSentimentWhere"];
	["Contember_PipelineStageTypeCondition"]: GraphQLTypes["Contember_PipelineStageTypeCondition"];
	["Contember_PipelineStageType"]: GraphQLTypes["Contember_PipelineStageType"];
	["Contember_SubscriptionBoxLocaleWhere"]: GraphQLTypes["Contember_SubscriptionBoxLocaleWhere"];
	["Contember_SubscriptionBoxWhere"]: GraphQLTypes["Contember_SubscriptionBoxWhere"];
	["Contember_PreparedPackingQuantityWhere"]: GraphQLTypes["Contember_PreparedPackingQuantityWhere"];
	["Contember_ProductStockWhere"]: GraphQLTypes["Contember_ProductStockWhere"];
	["Contember_StoreWhere"]: GraphQLTypes["Contember_StoreWhere"];
	["Contember_ProductGroupPriceWhere"]: GraphQLTypes["Contember_ProductGroupPriceWhere"];
	["Contember_CustomerGroupWhere"]: GraphQLTypes["Contember_CustomerGroupWhere"];
	["Contember_ProductAvailabilityCondition"]: GraphQLTypes["Contember_ProductAvailabilityCondition"];
	["Contember_ProductAvailability"]: GraphQLTypes["Contember_ProductAvailability"];
	["Contember_ProductCustomerPriceWhere"]: GraphQLTypes["Contember_ProductCustomerPriceWhere"];
	["Contember_ProductMetadataWhere"]: GraphQLTypes["Contember_ProductMetadataWhere"];
	["Contember_ProductSalesDayWhere"]: GraphQLTypes["Contember_ProductSalesDayWhere"];
	["Contember_ProductSalesWeekWhere"]: GraphQLTypes["Contember_ProductSalesWeekWhere"];
	["Contember_ProductSalesMonthWhere"]: GraphQLTypes["Contember_ProductSalesMonthWhere"];
	["Contember_AddressMetadataWhere"]: GraphQLTypes["Contember_AddressMetadataWhere"];
	["Contember_DeliveryTimelinePresetWhere"]: GraphQLTypes["Contember_DeliveryTimelinePresetWhere"];
	["Contember_DeliveryTimelineTypeCondition"]: GraphQLTypes["Contember_DeliveryTimelineTypeCondition"];
	["Contember_DeliveryTimelineType"]: GraphQLTypes["Contember_DeliveryTimelineType"];
	["Contember_PaymentMethodTypeCondition"]: GraphQLTypes["Contember_PaymentMethodTypeCondition"];
	["Contember_PaymentMethodType"]: GraphQLTypes["Contember_PaymentMethodType"];
	["Contember_CustomerPermissionsWhere"]: GraphQLTypes["Contember_CustomerPermissionsWhere"];
	["Contember_CustomerNameWhere"]: GraphQLTypes["Contember_CustomerNameWhere"];
	["Contember_CustomerAliasWhere"]: GraphQLTypes["Contember_CustomerAliasWhere"];
	["Contember_CustomerFamiliarityCondition"]: GraphQLTypes["Contember_CustomerFamiliarityCondition"];
	["Contember_CustomerFamiliarity"]: GraphQLTypes["Contember_CustomerFamiliarity"];
	["Contember_ApplicationShortcutListWhere"]: GraphQLTypes["Contember_ApplicationShortcutListWhere"];
	["Contember_ApplicationShortcutListItemWhere"]: GraphQLTypes["Contember_ApplicationShortcutListItemWhere"];
	["Contember_ApplicationWhere"]: GraphQLTypes["Contember_ApplicationWhere"];
	["Contember_InvoicingPlanWhere"]: GraphQLTypes["Contember_InvoicingPlanWhere"];
	["Contember_InvoicingPresetWhere"]: GraphQLTypes["Contember_InvoicingPresetWhere"];
	["Contember_InvoicingPresetTypeCondition"]: GraphQLTypes["Contember_InvoicingPresetTypeCondition"];
	["Contember_InvoicingPresetType"]: GraphQLTypes["Contember_InvoicingPresetType"];
	["Contember_CustomerSpendingWhere"]: GraphQLTypes["Contember_CustomerSpendingWhere"];
	["Contember_CustomerMetadataWhere"]: GraphQLTypes["Contember_CustomerMetadataWhere"];
	["Contember_GpsWhere"]: GraphQLTypes["Contember_GpsWhere"];
	["Contember_AddressOrderBy"]: GraphQLTypes["Contember_AddressOrderBy"];
	["Contember_OrderDirection"]: GraphQLTypes["Contember_OrderDirection"];
	["Contember_CustomerOrderBy"]: GraphQLTypes["Contember_CustomerOrderBy"];
	["Contember_CustomerPaymentMethodOrderBy"]: GraphQLTypes["Contember_CustomerPaymentMethodOrderBy"];
	["Contember_PaymentMethodOrderBy"]: GraphQLTypes["Contember_PaymentMethodOrderBy"];
	["Contember_CustomerPermissionsOrderBy"]: GraphQLTypes["Contember_CustomerPermissionsOrderBy"];
	["Contember_CustomerGroupOrderBy"]: GraphQLTypes["Contember_CustomerGroupOrderBy"];
	["Contember_ImageOrderBy"]: GraphQLTypes["Contember_ImageOrderBy"];
	["Contember_CustomerNameOrderBy"]: GraphQLTypes["Contember_CustomerNameOrderBy"];
	["Contember_BusinessCustomerOrderBy"]: GraphQLTypes["Contember_BusinessCustomerOrderBy"];
	["Contember_BusinessCategoryOrderBy"]: GraphQLTypes["Contember_BusinessCategoryOrderBy"];
	["Contember_ProductListOrderBy"]: GraphQLTypes["Contember_ProductListOrderBy"];
	["Contember_ProductCategoryListOrderBy"]: GraphQLTypes["Contember_ProductCategoryListOrderBy"];
	["Contember_BusinessToBusinessManagerOrderBy"]: GraphQLTypes["Contember_BusinessToBusinessManagerOrderBy"];
	["Contember_StaffOrderBy"]: GraphQLTypes["Contember_StaffOrderBy"];
	["Contember_BartenderOrderBy"]: GraphQLTypes["Contember_BartenderOrderBy"];
	["Contember_CourierOrderBy"]: GraphQLTypes["Contember_CourierOrderBy"];
	["Contember_CleanerOrderBy"]: GraphQLTypes["Contember_CleanerOrderBy"];
	["Contember_ShiftsManagerOrderBy"]: GraphQLTypes["Contember_ShiftsManagerOrderBy"];
	["Contember_UserOrderBy"]: GraphQLTypes["Contember_UserOrderBy"];
	["Contember_UserMetadataOrderBy"]: GraphQLTypes["Contember_UserMetadataOrderBy"];
	["Contember_UserRolesOrderBy"]: GraphQLTypes["Contember_UserRolesOrderBy"];
	["Contember_ShiftsProfileOrderBy"]: GraphQLTypes["Contember_ShiftsProfileOrderBy"];
	["Contember_StocksManagerOrderBy"]: GraphQLTypes["Contember_StocksManagerOrderBy"];
	["Contember_CourierManagerOrderBy"]: GraphQLTypes["Contember_CourierManagerOrderBy"];
	["Contember_PickerOrderBy"]: GraphQLTypes["Contember_PickerOrderBy"];
	["Contember_RemunerationProfileOrderBy"]: GraphQLTypes["Contember_RemunerationProfileOrderBy"];
	["Contember_WorkMonthOrderBy"]: GraphQLTypes["Contember_WorkMonthOrderBy"];
	["Contember_CashierOrderBy"]: GraphQLTypes["Contember_CashierOrderBy"];
	["Contember_InternalBuyerOrderBy"]: GraphQLTypes["Contember_InternalBuyerOrderBy"];
	["Contember_SalesManagerOrderBy"]: GraphQLTypes["Contember_SalesManagerOrderBy"];
	["Contember_CashierManagerOrderBy"]: GraphQLTypes["Contember_CashierManagerOrderBy"];
	["Contember_DoubleshotCoffeeEquipmentOrderBy"]: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentOrderBy"];
	["Contember_ApplicationShortcutListOrderBy"]: GraphQLTypes["Contember_ApplicationShortcutListOrderBy"];
	["Contember_InvoicingPlanOrderBy"]: GraphQLTypes["Contember_InvoicingPlanOrderBy"];
	["Contember_InvoicingPresetOrderBy"]: GraphQLTypes["Contember_InvoicingPresetOrderBy"];
	["Contember_BillingSubjectOrderBy"]: GraphQLTypes["Contember_BillingSubjectOrderBy"];
	["Contember_CountryOrderBy"]: GraphQLTypes["Contember_CountryOrderBy"];
	["Contember_AnonymousSessionOrderBy"]: GraphQLTypes["Contember_AnonymousSessionOrderBy"];
	["Contember_CustomerSpendingOrderBy"]: GraphQLTypes["Contember_CustomerSpendingOrderBy"];
	["Contember_CustomerMetadataOrderBy"]: GraphQLTypes["Contember_CustomerMetadataOrderBy"];
	["Contember_DeliveryZoneOrderBy"]: GraphQLTypes["Contember_DeliveryZoneOrderBy"];
	["Contember_AddressMetadataOrderBy"]: GraphQLTypes["Contember_AddressMetadataOrderBy"];
	["Contember_GpsOrderBy"]: GraphQLTypes["Contember_GpsOrderBy"];
	["Contember_CustomerPaymentMethod"]: {
		_meta?: GraphQLTypes["Contember_CustomerPaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	caption: string,
	data?: GraphQLTypes["Json"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	paymentMethod?: GraphQLTypes["PaymentMethod"] | undefined
};
	["Contember_CustomerPaymentMethodMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	caption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Json"]:any;
	["PaymentMethod"]: {
		_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	channels: Array<GraphQLTypes["ChannelPayment"]>,
	reduceGratuityByPercentage: number,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateChannels: GraphQLTypes["ChannelPaymentConnection"]
};
	["PaymentMethodMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
		_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["Contember_DeliveryZone"]>,
	channels: Array<GraphQLTypes["ChannelDelivery"]>,
	courierHandlers: Array<GraphQLTypes["Contember_Courier"]>,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["Contember_DeliveryMethodLocale"]>,
	timelinePreset?: GraphQLTypes["Contember_DeliveryTimelinePreset"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["Contember_DeliveryZoneConnection"],
	paginateChannels: GraphQLTypes["ChannelDeliveryConnection"],
	paginateCourierHandlers: GraphQLTypes["Contember_CourierConnection"],
	paginateLocales: GraphQLTypes["Contember_DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courierHandlers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryZone"]: {
		_meta?: GraphQLTypes["Contember_DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["Contember_DeliveryZoneType"],
	addresses: Array<GraphQLTypes["Address"]>,
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Contember_Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	addressesMetadata: Array<GraphQLTypes["Contember_AddressMetadata"]>,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	addressesMetadataByAddress?: GraphQLTypes["Contember_AddressMetadata"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["Contember_TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"],
	paginateAddressesMetadata: GraphQLTypes["Contember_AddressMetadataConnection"]
};
	["Contember_DeliveryZoneMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addresses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressesMetadata?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryMethodOrderBy"]: GraphQLTypes["Contember_DeliveryMethodOrderBy"];
	["Contember_DeliveryTimelinePresetOrderBy"]: GraphQLTypes["Contember_DeliveryTimelinePresetOrderBy"];
	["Contember_Tag"]: {
		_meta?: GraphQLTypes["Contember_TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	customers: Array<GraphQLTypes["Contember_Customer"]>,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["Contember_ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Contember_Channel"]>,
	nestedCategories: Array<GraphQLTypes["Contember_InheritedProductCategoryTag"]>,
	deliveryZones: Array<GraphQLTypes["Contember_DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Contember_Channel"]>,
	allowChannelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	paginateCustomers: GraphQLTypes["Contember_CustomerConnection"],
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["Contember_ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["Contember_ChannelConnection"],
	paginateNestedCategories: GraphQLTypes["Contember_InheritedProductCategoryTagConnection"],
	paginateDeliveryZones: GraphQLTypes["Contember_DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["Contember_ChannelConnection"],
	paginateAllowChannelPayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["Contember_TagMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nestedCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowChannelPayments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["ProductMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	packing?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipe?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stocks?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	groupPrices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availability?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	manager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	inheritedCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerPrices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	page?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesDays?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesWeeks?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesMonths?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hasSubscription?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductPacking"]: {
		_meta?: GraphQLTypes["Contember_ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Contember_Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["Contember_ProductPackingLocale"]>,
	preparedPackingQuantities: Array<GraphQLTypes["Contember_PreparedPackingQuantity"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["Contember_ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_ProductPackingLocaleConnection"],
	paginatePreparedPackingQuantities: GraphQLTypes["Contember_PreparedPackingQuantityConnection"]
};
	["Contember_ProductPackingMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shortName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Image"]: {
		_meta?: GraphQLTypes["Contember_ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["Contember_ImageMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	url?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	width?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	height?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	size?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	alt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductPackingLocale"]: {
		_meta?: GraphQLTypes["Contember_ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["Contember_ProductPacking"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductPackingLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shortName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Locale"]: {
		_meta?: GraphQLTypes["Contember_LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["Contember_BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["Contember_ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["Contember_ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["Contember_OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["Contember_BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["Contember_BlogPostLocale"]>,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifier"] | undefined,
	menus: Array<GraphQLTypes["Contember_MenuLocale"]>,
	pages: Array<GraphQLTypes["Contember_PageLocale"]>,
	productIngredientCategories: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocale"]>,
	allergens: Array<GraphQLTypes["Contember_AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["Contember_ProductPackingLocale"]>,
	products: Array<GraphQLTypes["Contember_ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["Contember_ProductRecipeLocale"]>,
	pointOfSaleCategories: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocale"]>,
	pointOfSaleCategoryProducts: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"]>,
	deliveryMethods: Array<GraphQLTypes["Contember_DeliveryMethodLocale"]>,
	ingredientSuppliers: Array<GraphQLTypes["Contember_IngredientSupplierLocale"]>,
	identificationCodes: Array<GraphQLTypes["Contember_IdentificationCodeLocale"]>,
	pipelineStages: Array<GraphQLTypes["Contember_PipelineStageLocale"]>,
	subscriptionBoxes: Array<GraphQLTypes["Contember_SubscriptionBoxLocale"]>,
	businessServices: Array<GraphQLTypes["Contember_BusinessServiceLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["Contember_BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["Contember_ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["Contember_ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["Contember_OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["Contember_BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	menusByRoot?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	menusByItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	menusBySecondaryItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["Contember_PageLocale"] | undefined,
	productIngredientCategoriesByRoot?: GraphQLTypes["Contember_ProductIngredientCategoryLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["Contember_AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["Contember_ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["Contember_ProductRecipeLocale"] | undefined,
	pointOfSaleCategoriesByRoot?: GraphQLTypes["Contember_PointOfSaleCategoryLocale"] | undefined,
	pointOfSaleCategoryProductsByRoot?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["Contember_DeliveryMethodLocale"] | undefined,
	ingredientSuppliersByRoot?: GraphQLTypes["Contember_IngredientSupplierLocale"] | undefined,
	identificationCodesByRoot?: GraphQLTypes["Contember_IdentificationCodeLocale"] | undefined,
	pipelineStagesByRoot?: GraphQLTypes["Contember_PipelineStageLocale"] | undefined,
	subscriptionBoxesByRoot?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesByTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesBySecondaryTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	businessServicesByRoot?: GraphQLTypes["Contember_BusinessServiceLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["Contember_BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["Contember_ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["Contember_ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["Contember_OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["Contember_BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["Contember_BlogPostLocaleConnection"],
	paginateMenus: GraphQLTypes["Contember_MenuLocaleConnection"],
	paginatePages: GraphQLTypes["Contember_PageLocaleConnection"],
	paginateProductIngredientCategories: GraphQLTypes["Contember_ProductIngredientCategoryLocaleConnection"],
	paginateAllergens: GraphQLTypes["Contember_AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["Contember_ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["Contember_ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["Contember_ProductRecipeLocaleConnection"],
	paginatePointOfSaleCategories: GraphQLTypes["Contember_PointOfSaleCategoryLocaleConnection"],
	paginatePointOfSaleCategoryProducts: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["Contember_DeliveryMethodLocaleConnection"],
	paginateIngredientSuppliers: GraphQLTypes["Contember_IngredientSupplierLocaleConnection"],
	paginateIdentificationCodes: GraphQLTypes["Contember_IdentificationCodeLocaleConnection"],
	paginatePipelineStages: GraphQLTypes["Contember_PipelineStageLocaleConnection"],
	paginateSubscriptionBoxes: GraphQLTypes["Contember_SubscriptionBoxLocaleConnection"],
	paginateBusinessServices: GraphQLTypes["Contember_BusinessServiceLocaleConnection"]
};
	["Contember_LocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	menus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productIngredientCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allergens?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleCategoryProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredientSuppliers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identificationCodes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pipelineStages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	subscriptionBoxes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessServices?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessCategoryLocale"]: {
		_meta?: GraphQLTypes["Contember_BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	title?: string | undefined
};
	["Contember_BusinessCategoryLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessCategory"]: {
		_meta?: GraphQLTypes["Contember_BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["Contember_BusinessCategoryLocale"]>,
	internalName: string,
	businessCustomers: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	recurrences: Array<GraphQLTypes["Contember_OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["Contember_ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["Contember_BusinessCategoryLocale"] | undefined,
	businessCustomersByCustomer?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	businessCustomersByBusinessServiceEvents?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	businessCustomersByDoubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BusinessCategoryLocaleConnection"],
	paginateBusinessCustomers: GraphQLTypes["Contember_BusinessCustomerConnection"],
	paginateRecurrences: GraphQLTypes["Contember_OrderRecurrenceConnection"]
};
	["Contember_BusinessCategoryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCustomers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	slug?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessCategoryLocaleOrderBy"]: GraphQLTypes["Contember_BusinessCategoryLocaleOrderBy"];
	["Contember_LocaleOrderBy"]: GraphQLTypes["Contember_LocaleOrderBy"];
	["Contember_TranslationCataloguesIdentifierOrderBy"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierOrderBy"];
	["Contember_BusinessCustomer"]: {
		_meta?: GraphQLTypes["Contember_BusinessCustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	owners: Array<GraphQLTypes["Contember_Customer"]>,
	members: Array<GraphQLTypes["Contember_Customer"]>,
	category?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessToBusinessManager"] | undefined,
	logo?: GraphQLTypes["Contember_Image"] | undefined,
	note?: string | undefined,
	businessServiceEvents: Array<GraphQLTypes["Contember_BusinessServiceEvent"]>,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_DoubleshotCoffeeEquipment"] | undefined,
	paginateOwners: GraphQLTypes["Contember_CustomerConnection"],
	paginateMembers: GraphQLTypes["Contember_CustomerConnection"],
	paginateBusinessServiceEvents: GraphQLTypes["Contember_BusinessServiceEventConnection"]
};
	["Contember_BusinessCustomerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	owners?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	members?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	accountManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	logo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessServiceEvents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessToBusinessManager"]: {
		_meta?: GraphQLTypes["Contember_BusinessToBusinessManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	managedBusinesses: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	managedBusinessesByCustomer?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	managedBusinessesByBusinessServiceEvents?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	managedBusinessesByDoubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	paginateManagedBusinesses: GraphQLTypes["Contember_BusinessCustomerConnection"]
};
	["Contember_BusinessToBusinessManagerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	managedBusinesses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Staff"]: {
		_meta?: GraphQLTypes["Contember_StaffMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	bartender?: GraphQLTypes["Contember_Bartender"] | undefined,
	courier?: GraphQLTypes["Contember_Courier"] | undefined,
	cleaner?: GraphQLTypes["Contember_Cleaner"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_ShiftsManager"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_BusinessToBusinessManager"] | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StocksManager"] | undefined,
	photo?: GraphQLTypes["Contember_Image"] | undefined,
	courierManager?: GraphQLTypes["Contember_CourierManager"] | undefined,
	picker?: GraphQLTypes["Contember_Picker"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	cashier?: GraphQLTypes["Contember_Cashier"] | undefined,
	venues: Array<GraphQLTypes["Contember_Venue"]>,
	internalBuyer?: GraphQLTypes["Contember_InternalBuyer"] | undefined,
	salesManager?: GraphQLTypes["Contember_SalesManager"] | undefined,
	cashierManager?: GraphQLTypes["Contember_CashierManager"] | undefined,
	paginateVenues: GraphQLTypes["Contember_VenueConnection"]
};
	["Contember_StaffMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	bartender?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cleaner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stocksManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	photo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	picker?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cashier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venues?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalBuyer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cashierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Bartender"]: {
		_meta?: GraphQLTypes["Contember_BartenderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_BartenderMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Courier"]: {
		_meta?: GraphQLTypes["Contember_CourierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	routes: Array<GraphQLTypes["Contember_DeliveryRoute"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	showInPicker: boolean,
	routesByWaypoints?: GraphQLTypes["Contember_DeliveryRoute"] | undefined,
	paginateRoutes: GraphQLTypes["Contember_DeliveryRouteConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"]
};
	["Contember_CourierMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	routes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showInPicker?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryRoute"]: {
		_meta?: GraphQLTypes["Contember_DeliveryRouteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	courier?: GraphQLTypes["Contember_Courier"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	waypoints: Array<GraphQLTypes["Contember_DeliveryWaypoint"]>,
	dispatchAt: string,
	waypointsByDelivery?: GraphQLTypes["Contember_DeliveryWaypoint"] | undefined,
	paginateWaypoints: GraphQLTypes["Contember_DeliveryWaypointConnection"]
};
	["Contember_DeliveryRouteMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	waypoints?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryWaypoint"]: {
		_meta?: GraphQLTypes["Contember_DeliveryWaypointMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	route?: GraphQLTypes["Contember_DeliveryRoute"] | undefined,
	sortingOrder: number,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined
};
	["Contember_DeliveryWaypointMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	estimatedArrival?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	route?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sortingOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	estimatedDistance?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	estimatedDuration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	originDeparture?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	originAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	transitMode?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderDelivery"]: {
		_meta?: GraphQLTypes["OrderDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["Contember_OrderDeliveryState"],
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_DeliveryWaypoint"] | undefined,
	events: Array<GraphQLTypes["Contember_OrderEvent"]>,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_LiftagoRide"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	trackingCode?: string | undefined,
	paginateEvents: GraphQLTypes["Contember_OrderEventConnection"]
};
	["OrderDeliveryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveredAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expectedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	loadingDurationHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	duration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	window?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expectedEndAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveringAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	events?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	prepareAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	trackingCode?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["VatRateMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channelDeliveries?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channelPayments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preset?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["ChannelDeliveryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultVisible?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Channel"]: {
		_meta?: GraphQLTypes["Contember_ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	payments: Array<GraphQLTypes["ChannelPayment"]>,
	deliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	pointOfSales: Array<GraphQLTypes["Contember_PointOfSale"]>,
	carts: Array<GraphQLTypes["Contember_Cart"]>,
	customers: Array<GraphQLTypes["Contember_Customer"]>,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Contember_Tag"]>,
	publicTags: Array<GraphQLTypes["Contember_Tag"]>,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	cartsByItems?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Contember_Cart"] | undefined,
	paginatePayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginatePointOfSales: GraphQLTypes["Contember_PointOfSaleConnection"],
	paginateCarts: GraphQLTypes["Contember_CartConnection"],
	paginateCustomers: GraphQLTypes["Contember_CustomerConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["Contember_TagConnection"],
	paginatePublicTags: GraphQLTypes["Contember_TagConnection"]
};
	["Contember_ChannelMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	disabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveries?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["ChannelPaymentMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireBillingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	provideReceipt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireBillingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowOnlyForTags?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TagOrderBy"]: GraphQLTypes["Contember_TagOrderBy"];
	["Contember_TagConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TagEdge"]>
};
	["Contember_PageInfo"]: {
		totalCount: number
};
	["Contember_TagEdge"]: {
		node: GraphQLTypes["Contember_Tag"]
};
	["Contember_ChannelPaymentOrderBy"]: GraphQLTypes["Contember_ChannelPaymentOrderBy"];
	["Contember_ChannelOrderBy"]: GraphQLTypes["Contember_ChannelOrderBy"];
	["Contember_VenueOrderBy"]: GraphQLTypes["Contember_VenueOrderBy"];
	["Contember_DeadlineTemplateOrderBy"]: GraphQLTypes["Contember_DeadlineTemplateOrderBy"];
	["Contember_VatRateOrderBy"]: GraphQLTypes["Contember_VatRateOrderBy"];
	["Contember_ChannelDeliveryOrderBy"]: GraphQLTypes["Contember_ChannelDeliveryOrderBy"];
	["Contember_PointOfSale"]: {
		_meta?: GraphQLTypes["Contember_PointOfSaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	code: string,
	channels: Array<GraphQLTypes["Contember_Channel"]>,
	permissions?: GraphQLTypes["Contember_PointOfSalePermissions"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	paginateChannels: GraphQLTypes["Contember_ChannelConnection"]
};
	["Contember_PointOfSaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	permissions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSalePermissions"]: {
		_meta?: GraphQLTypes["Contember_PointOfSalePermissionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	canPlaceOrder?: boolean | undefined,
	pointOfSale?: GraphQLTypes["Contember_PointOfSale"] | undefined
};
	["Contember_PointOfSalePermissionsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ChannelConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ChannelEdge"]>
};
	["Contember_ChannelEdge"]: {
		node: GraphQLTypes["Contember_Channel"]
};
	["Contember_PointOfSaleOrderBy"]: GraphQLTypes["Contember_PointOfSaleOrderBy"];
	["Contember_PointOfSalePermissionsOrderBy"]: GraphQLTypes["Contember_PointOfSalePermissionsOrderBy"];
	["Contember_Cart"]: {
		_meta?: GraphQLTypes["Contember_CartMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items: Array<GraphQLTypes["Contember_CartItem"]>,
	handle?: string | undefined,
	state: GraphQLTypes["Contember_CartState"],
	createdAt: GraphQLTypes["DateTime"],
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	deliveryAddress?: GraphQLTypes["Address"] | undefined,
	billingAddress?: GraphQLTypes["Address"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	payment?: GraphQLTypes["ChannelPayment"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	priceLines: Array<GraphQLTypes["Contember_CartPriceLine"]>,
	summary?: GraphQLTypes["Contember_CartSummary"] | undefined,
	itemsByProduct?: GraphQLTypes["Contember_CartItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_CartItemConnection"],
	paginatePriceLines: GraphQLTypes["Contember_CartPriceLineConnection"]
};
	["Contember_CartMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	confirmedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	handle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryOption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentOption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	summary?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CartItem"]: {
		_meta?: GraphQLTypes["Contember_CartItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	cart?: GraphQLTypes["Contember_Cart"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["Contember_CartItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FulfillmentNote"]: {
		_meta?: GraphQLTypes["Contember_FulfillmentNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	note: string,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	venues: Array<GraphQLTypes["Contember_Venue"]>,
	cartItems: Array<GraphQLTypes["Contember_CartItem"]>,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	orders: Array<GraphQLTypes["Order"]>,
	highlight: boolean,
	createdAt: GraphQLTypes["DateTime"],
	emoji?: string | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateVenues: GraphQLTypes["Contember_VenueConnection"],
	paginateCartItems: GraphQLTypes["Contember_CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["Contember_FulfillmentNoteMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venues?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	highlight?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	emoji?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductOrderBy"]: GraphQLTypes["Contember_ProductOrderBy"];
	["Contember_ProductPackingOrderBy"]: GraphQLTypes["Contember_ProductPackingOrderBy"];
	["Contember_ProductRecipeOrderBy"]: GraphQLTypes["Contember_ProductRecipeOrderBy"];
	["Contember_FreshingContainerOrderBy"]: GraphQLTypes["Contember_FreshingContainerOrderBy"];
	["Contember_WorkspaceOrderBy"]: GraphQLTypes["Contember_WorkspaceOrderBy"];
	["Contember_WeekHoursOrderBy"]: GraphQLTypes["Contember_WeekHoursOrderBy"];
	["Contember_VirtualProductOrderBy"]: GraphQLTypes["Contember_VirtualProductOrderBy"];
	["Contember_NutritionFactsOrderBy"]: GraphQLTypes["Contember_NutritionFactsOrderBy"];
	["Contember_ProductMetadataOrderBy"]: GraphQLTypes["Contember_ProductMetadataOrderBy"];
	["Contember_PageOrderBy"]: GraphQLTypes["Contember_PageOrderBy"];
	["Contember_Venue"]: {
		_meta?: GraphQLTypes["Contember_VenueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	deadlineTemplate?: GraphQLTypes["Contember_DeadlineTemplate"] | undefined,
	timetableDays: Array<GraphQLTypes["Contember_TimetableDay"]>,
	timetableTemplates: Array<GraphQLTypes["Contember_TimetableTemplate"]>,
	staffMembers: Array<GraphQLTypes["Contember_Staff"]>,
	employeeRoles: Array<GraphQLTypes["Contember_VenueEmployeeRole"]>,
	pointOfSaleApplicationDevices: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	pointOfSaleTerminals: Array<GraphQLTypes["Contember_PointOfSaleTerminal"]>,
	pointOfSalePrinters: Array<GraphQLTypes["Contember_PointOfSalePrinter"]>,
	orders: Array<GraphQLTypes["Order"]>,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	areas: Array<GraphQLTypes["Contember_Area"]>,
	enabledUnassignReasons: Array<GraphQLTypes["Contember_UnassignReason"]>,
	categories: Array<GraphQLTypes["Contember_PointOfSaleCategory"]>,
	channels: Array<GraphQLTypes["Contember_Channel"]>,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView: GraphQLTypes["Contember_PointOfSaleInitialView"],
	pointOfSalePreferPrintReceipt: boolean,
	timetableDaysByDate?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableDaysByShiftGroups?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableDaysByNotes?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableDaysByMeta?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableTemplatesByStaffOptions?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	employeeRolesByRole?: GraphQLTypes["Contember_VenueEmployeeRole"] | undefined,
	employeeRolesByEmployeePrivileges?: GraphQLTypes["Contember_VenueEmployeeRole"] | undefined,
	pointOfSaleApplicationDevicesByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	pointOfSaleTerminalsByApplicationDevices?: GraphQLTypes["Contember_PointOfSaleTerminal"] | undefined,
	pointOfSalePrintersByApplicationDevicesReceipt?: GraphQLTypes["Contember_PointOfSalePrinter"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByEvents?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ordersByGratuity?: GraphQLTypes["Order"] | undefined,
	areasByTables?: GraphQLTypes["Contember_Area"] | undefined,
	areasByLines?: GraphQLTypes["Contember_Area"] | undefined,
	categoriesBySlug?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	categoriesByLocales?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	categoriesByProducts?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	channelsByCode?: GraphQLTypes["Contember_Channel"] | undefined,
	channelsByPayments?: GraphQLTypes["Contember_Channel"] | undefined,
	channelsByDeliveries?: GraphQLTypes["Contember_Channel"] | undefined,
	channelsByCarts?: GraphQLTypes["Contember_Channel"] | undefined,
	paginateTimetableDays: GraphQLTypes["Contember_TimetableDayConnection"],
	paginateTimetableTemplates: GraphQLTypes["Contember_TimetableTemplateConnection"],
	paginateStaffMembers: GraphQLTypes["Contember_StaffConnection"],
	paginateEmployeeRoles: GraphQLTypes["Contember_VenueEmployeeRoleConnection"],
	paginatePointOfSaleApplicationDevices: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"],
	paginatePointOfSaleTerminals: GraphQLTypes["Contember_PointOfSaleTerminalConnection"],
	paginatePointOfSalePrinters: GraphQLTypes["Contember_PointOfSalePrinterConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"],
	paginateAreas: GraphQLTypes["Contember_AreaConnection"],
	paginateEnabledUnassignReasons: GraphQLTypes["Contember_UnassignReasonConnection"],
	paginateCategories: GraphQLTypes["Contember_PointOfSaleCategoryConnection"],
	paginateChannels: GraphQLTypes["Contember_ChannelConnection"]
};
	["Contember_VenueMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableDays?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staffMembers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employeeRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleApplicationDevices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleTerminals?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSalePrinters?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	areas?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enabledUnassignReasons?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeadlineTemplate"]: {
		_meta?: GraphQLTypes["Contember_DeadlineTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	startDate: GraphQLTypes["Date"],
	period: number,
	cutoff?: number | undefined,
	closed?: boolean | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined
};
	["Contember_DeadlineTemplateMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	period?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cutoff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	closed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableDay"]: {
		_meta?: GraphQLTypes["Contember_TimetableDayMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	type?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	shiftGroups: Array<GraphQLTypes["Contember_TimetableShiftGroup"]>,
	tipsCents?: number | undefined,
	notes: Array<GraphQLTypes["Contember_TimetableDayNote"]>,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayMetadata"] | undefined,
	shiftGroupsByShifts?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["Contember_TimetableShiftGroupConnection"],
	paginateNotes: GraphQLTypes["Contember_TimetableDayNoteConnection"]
};
	["Contember_TimetableDayMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	date?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tipsCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftGroup"]: {
		_meta?: GraphQLTypes["Contember_TimetableShiftGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	shifts: Array<GraphQLTypes["Contember_TimetableShift"]>,
	day?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	type?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPosition"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueue"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	shiftsBySwapInfo?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	paginateShifts: GraphQLTypes["Contember_TimetableShiftConnection"]
};
	["Contember_TimetableShiftGroupMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shifts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	day?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	position?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShift"]: {
		_meta?: GraphQLTypes["Contember_TimetableShiftMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locked: boolean,
	order: number,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftSwapInfo"] | undefined,
	isOvertime: boolean
};
	["Contember_TimetableShiftMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locked?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	assigned?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	group?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	swapInfo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isOvertime?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ShiftsProfile"]: {
		_meta?: GraphQLTypes["Contember_ShiftsProfileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	queueItems: Array<GraphQLTypes["Contember_EmployeeQueueItem"]>,
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	roles: Array<GraphQLTypes["Contember_TimetableEmployeeRole"]>,
	shifts: Array<GraphQLTypes["Contember_TimetableShift"]>,
	allowSelfUnassigning?: boolean | undefined,
	queueItemsByQueue?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	queueItemsByShift?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	queueItemsByUnassignInfo?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	queueItemsByMeta?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	shiftsBySwapInfo?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	paginateQueueItems: GraphQLTypes["Contember_EmployeeQueueItemConnection"],
	paginateRoles: GraphQLTypes["Contember_TimetableEmployeeRoleConnection"],
	paginateShifts: GraphQLTypes["Contember_TimetableShiftConnection"]
};
	["Contember_ShiftsProfileMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	roles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shifts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmployeeQueueItem"]: {
		_meta?: GraphQLTypes["Contember_EmployeeQueueItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueue"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_UnassignedShiftInfo"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadata"] | undefined
};
	["Contember_EmployeeQueueItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	start?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	end?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shift?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmployeeQueue"]: {
		_meta?: GraphQLTypes["Contember_EmployeeQueueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroup?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	items: Array<GraphQLTypes["Contember_EmployeeQueueItem"]>,
	contemberBugFix?: boolean | undefined,
	itemsByShiftsProfile?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	itemsByShift?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	itemsByUnassignInfo?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	itemsByMeta?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_EmployeeQueueItemConnection"]
};
	["Contember_EmployeeQueueMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroup?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contemberBugFix?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmployeeQueueItemOrderBy"]: GraphQLTypes["Contember_EmployeeQueueItemOrderBy"];
	["Contember_EmployeeQueueOrderBy"]: GraphQLTypes["Contember_EmployeeQueueOrderBy"];
	["Contember_TimetableShiftGroupOrderBy"]: GraphQLTypes["Contember_TimetableShiftGroupOrderBy"];
	["Contember_TimetableDayOrderBy"]: GraphQLTypes["Contember_TimetableDayOrderBy"];
	["Contember_TimetableDayMetadataOrderBy"]: GraphQLTypes["Contember_TimetableDayMetadataOrderBy"];
	["Contember_TimetableEmployeeRoleOrderBy"]: GraphQLTypes["Contember_TimetableEmployeeRoleOrderBy"];
	["Contember_TimetableShiftPositionOrderBy"]: GraphQLTypes["Contember_TimetableShiftPositionOrderBy"];
	["Contember_TimetableTemplateOrderBy"]: GraphQLTypes["Contember_TimetableTemplateOrderBy"];
	["Contember_TimetableShiftOrderBy"]: GraphQLTypes["Contember_TimetableShiftOrderBy"];
	["Contember_TimetableShiftSwapInfoOrderBy"]: GraphQLTypes["Contember_TimetableShiftSwapInfoOrderBy"];
	["Contember_UnassignedShiftInfoOrderBy"]: GraphQLTypes["Contember_UnassignedShiftInfoOrderBy"];
	["Contember_UnassignReasonOrderBy"]: GraphQLTypes["Contember_UnassignReasonOrderBy"];
	["Contember_EmployeeQueueItemMetadataOrderBy"]: GraphQLTypes["Contember_EmployeeQueueItemMetadataOrderBy"];
	["Contember_EmployeeQueueItemsByShiftsProfileUniqueWhere"]: GraphQLTypes["Contember_EmployeeQueueItemsByShiftsProfileUniqueWhere"];
	["Contember_ShiftsProfileUniqueWhere"]: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"];
	["Contember_EmployeeQueueItemUniqueWhere"]: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"];
	["Contember_EmployeeQueueUniqueWhere"]: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"];
	["Contember_TimetableShiftGroupUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"];
	["Contember_TimetableShiftUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftUniqueWhere"];
	["Contember_TimetableShiftSwapInfoUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftSwapInfoUniqueWhere"];
	["Contember_UnassignedShiftInfoUniqueWhere"]: GraphQLTypes["Contember_UnassignedShiftInfoUniqueWhere"];
	["Contember_EmployeeQueueItemMetadataUniqueWhere"]: GraphQLTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"];
	["Contember_StaffUniqueWhere"]: GraphQLTypes["Contember_StaffUniqueWhere"];
	["Contember_BartenderUniqueWhere"]: GraphQLTypes["Contember_BartenderUniqueWhere"];
	["Contember_CourierUniqueWhere"]: GraphQLTypes["Contember_CourierUniqueWhere"];
	["Contember_DeliveryRouteUniqueWhere"]: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"];
	["Contember_DeliveryWaypointUniqueWhere"]: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"];
	["Contember_OrderDeliveryUniqueWhere"]: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"];
	["Contember_OrderUniqueWhere"]: GraphQLTypes["Contember_OrderUniqueWhere"];
	["Contember_OrderDiscountUniqueWhere"]: GraphQLTypes["Contember_OrderDiscountUniqueWhere"];
	["Contember_CustomerCreditTransactionUniqueWhere"]: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"];
	["Contember_VirtualProductEffectUniqueWhere"]: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"];
	["Contember_VoucherUniqueWhere"]: GraphQLTypes["Contember_VoucherUniqueWhere"];
	["Contember_VoucherRedemptionUniqueWhere"]: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"];
	["Contember_CustomerCreditUniqueWhere"]: GraphQLTypes["Contember_CustomerCreditUniqueWhere"];
	["Contember_OrderItemUniqueWhere"]: GraphQLTypes["Contember_OrderItemUniqueWhere"];
	["Contember_CartUniqueWhere"]: GraphQLTypes["Contember_CartUniqueWhere"];
	["Contember_CartItemUniqueWhere"]: GraphQLTypes["Contember_CartItemUniqueWhere"];
	["Contember_ProductUniqueWhere"]: GraphQLTypes["Contember_ProductUniqueWhere"];
	["Contember_ProductStockUniqueWhere"]: GraphQLTypes["Contember_ProductStockUniqueWhere"];
	["Contember_StoreUniqueWhere"]: GraphQLTypes["Contember_StoreUniqueWhere"];
	["Contember_ProductGroupPriceUniqueWhere"]: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"];
	["Contember_CustomerGroupUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupUniqueWhere"];
	["Contember_CustomerUniqueWhere"]: GraphQLTypes["Contember_CustomerUniqueWhere"];
	["Contember_AddressUniqueWhere"]: GraphQLTypes["Contember_AddressUniqueWhere"];
	["Contember_AddressMetadataUniqueWhere"]: GraphQLTypes["Contember_AddressMetadataUniqueWhere"];
	["Contember_GpsUniqueWhere"]: GraphQLTypes["Contember_GpsUniqueWhere"];
	["Contember_InvoiceUniqueWhere"]: GraphQLTypes["Contember_InvoiceUniqueWhere"];
	["Contember_OrderPaymentUniqueWhere"]: GraphQLTypes["Contember_OrderPaymentUniqueWhere"];
	["Contember_OrderEventUniqueWhere"]: GraphQLTypes["Contember_OrderEventUniqueWhere"];
	["Contember_OrderPaymentMetadataUniqueWhere"]: GraphQLTypes["Contember_OrderPaymentMetadataUniqueWhere"];
	["Contember_CustomerPaymentMethodUniqueWhere"]: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"];
	["Contember_CustomerPermissionsUniqueWhere"]: GraphQLTypes["Contember_CustomerPermissionsUniqueWhere"];
	["Contember_CustomerNameUniqueWhere"]: GraphQLTypes["Contember_CustomerNameUniqueWhere"];
	["Contember_CustomerIngredientRatingUniqueWhere"]: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"];
	["Contember_ProductIngredientUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientUniqueWhere"];
	["Contember_ProductIngredientLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"];
	["Contember_LocaleUniqueWhere"]: GraphQLTypes["Contember_LocaleUniqueWhere"];
	["Contember_BusinessCategoryLocaleUniqueWhere"]: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"];
	["Contember_BusinessCategoryUniqueWhere"]: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"];
	["Contember_BusinessCustomerUniqueWhere"]: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"];
	["Contember_BusinessServiceEventUniqueWhere"]: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"];
	["Contember_DoubleshotCoffeeEquipmentUniqueWhere"]: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"];
	["Contember_ProductListUniqueWhere"]: GraphQLTypes["Contember_ProductListUniqueWhere"];
	["Contember_ProductListItemUniqueWhere"]: GraphQLTypes["Contember_ProductListItemUniqueWhere"];
	["Contember_ProductCategoryListUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"];
	["Contember_ProductCategoryListItemUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"];
	["Contember_ProductCategoryListLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"];
	["Contember_OrderRecurrenceLocaleUniqueWhere"]: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"];
	["Contember_OrderRecurrenceUniqueWhere"]: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"];
	["Contember_BlogLocaleUniqueWhere"]: GraphQLTypes["Contember_BlogLocaleUniqueWhere"];
	["Contember_BlogUniqueWhere"]: GraphQLTypes["Contember_BlogUniqueWhere"];
	["Contember_BlogPostLocaleUniqueWhere"]: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"];
	["Contember_BlogPostUniqueWhere"]: GraphQLTypes["Contember_BlogPostUniqueWhere"];
	["Contember_CoverUniqueWhere"]: GraphQLTypes["Contember_CoverUniqueWhere"];
	["Contember_ContentUniqueWhere"]: GraphQLTypes["Contember_ContentUniqueWhere"];
	["Contember_ContentBlockUniqueWhere"]: GraphQLTypes["Contember_ContentBlockUniqueWhere"];
	["Contember_ContentReferenceUniqueWhere"]: GraphQLTypes["Contember_ContentReferenceUniqueWhere"];
	["Contember_ProductBannerListUniqueWhere"]: GraphQLTypes["Contember_ProductBannerListUniqueWhere"];
	["Contember_ProductBannerItemUniqueWhere"]: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"];
	["Contember_DeliveryRegionsUniqueWhere"]: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"];
	["Contember_DeliveryRegionsItemUniqueWhere"]: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"];
	["Contember_ImageUniqueWhere"]: GraphQLTypes["Contember_ImageUniqueWhere"];
	["Contember_TextListUniqueWhere"]: GraphQLTypes["Contember_TextListUniqueWhere"];
	["Contember_TextItemUniqueWhere"]: GraphQLTypes["Contember_TextItemUniqueWhere"];
	["Contember_ImageListUniqueWhere"]: GraphQLTypes["Contember_ImageListUniqueWhere"];
	["Contember_ImageListItemUniqueWhere"]: GraphQLTypes["Contember_ImageListItemUniqueWhere"];
	["Contember_LinkableUniqueWhere"]: GraphQLTypes["Contember_LinkableUniqueWhere"];
	["Contember_PageLocaleUniqueWhere"]: GraphQLTypes["Contember_PageLocaleUniqueWhere"];
	["Contember_PageUniqueWhere"]: GraphQLTypes["Contember_PageUniqueWhere"];
	["Contember_SeoUniqueWhere"]: GraphQLTypes["Contember_SeoUniqueWhere"];
	["Contember_WikiPageUniqueWhere"]: GraphQLTypes["Contember_WikiPageUniqueWhere"];
	["Contember_RedirectUniqueWhere"]: GraphQLTypes["Contember_RedirectUniqueWhere"];
	["Contember_TranslationCataloguesIdentifierUniqueWhere"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"];
	["Contember_TranslationCatalogueUniqueWhere"]: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"];
	["Contember_TranslationDomainUniqueWhere"]: GraphQLTypes["Contember_TranslationDomainUniqueWhere"];
	["Contember_TranslationKeyUniqueWhere"]: GraphQLTypes["Contember_TranslationKeyUniqueWhere"];
	["Contember_TranslationValueUniqueWhere"]: GraphQLTypes["Contember_TranslationValueUniqueWhere"];
	["Contember_MenuLocaleUniqueWhere"]: GraphQLTypes["Contember_MenuLocaleUniqueWhere"];
	["Contember_SiteMenuUniqueWhere"]: GraphQLTypes["Contember_SiteMenuUniqueWhere"];
	["Contember_MenuItemUniqueWhere"]: GraphQLTypes["Contember_MenuItemUniqueWhere"];
	["Contember_MenuSubitemUniqueWhere"]: GraphQLTypes["Contember_MenuSubitemUniqueWhere"];
	["Contember_LinkListUniqueWhere"]: GraphQLTypes["Contember_LinkListUniqueWhere"];
	["Contember_LinkListItemUniqueWhere"]: GraphQLTypes["Contember_LinkListItemUniqueWhere"];
	["Contember_ProductIngredientCategoryLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"];
	["Contember_ProductIngredientCategoryUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"];
	["Contember_AllergenLocaleUniqueWhere"]: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"];
	["Contember_AllergenUniqueWhere"]: GraphQLTypes["Contember_AllergenUniqueWhere"];
	["Contember_ProductPackingLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"];
	["Contember_ProductPackingUniqueWhere"]: GraphQLTypes["Contember_ProductPackingUniqueWhere"];
	["Contember_PreparedPackingQuantityUniqueWhere"]: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"];
	["Contember_ProductRecipeUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeUniqueWhere"];
	["Contember_ProductIngredientItemUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"];
	["Contember_ProductIngredientItemQuantityUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUniqueWhere"];
	["Contember_ProductRecipeLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"];
	["Contember_NutritionFactsUniqueWhere"]: GraphQLTypes["Contember_NutritionFactsUniqueWhere"];
	["Contember_ProductLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductLocaleUniqueWhere"];
	["Contember_PointOfSaleCategoryLocaleUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"];
	["Contember_PointOfSaleCategoryUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"];
	["Contember_PointOfSaleCategoryProductUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"];
	["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"];
	["Contember_DeliveryMethodLocaleUniqueWhere"]: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"];
	["Contember_DeliveryMethodUniqueWhere"]: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"];
	["Contember_ChannelDeliveryUniqueWhere"]: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"];
	["Contember_DeliveryTimelinePresetUniqueWhere"]: GraphQLTypes["Contember_DeliveryTimelinePresetUniqueWhere"];
	["Contember_IngredientSupplierLocaleUniqueWhere"]: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"];
	["Contember_IngredientSupplierUniqueWhere"]: GraphQLTypes["Contember_IngredientSupplierUniqueWhere"];
	["Contember_IdentificationCodeLocaleUniqueWhere"]: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"];
	["Contember_IdentificationCodeUniqueWhere"]: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"];
	["Contember_IdentificationAttemptUniqueWhere"]: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"];
	["Contember_PointOfSaleApplicationDeviceUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"];
	["Contember_DeviceIdentifierUniqueWhere"]: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"];
	["Contember_DeviceIdentifierMetadataUniqueWhere"]: GraphQLTypes["Contember_DeviceIdentifierMetadataUniqueWhere"];
	["Contember_PipelineStageLocaleUniqueWhere"]: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"];
	["Contember_PipelineStageUniqueWhere"]: GraphQLTypes["Contember_PipelineStageUniqueWhere"];
	["Contember_PipelineStageItemUniqueWhere"]: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"];
	["Contember_PipelineLeadUniqueWhere"]: GraphQLTypes["Contember_PipelineLeadUniqueWhere"];
	["Contember_LeadFormUniqueWhere"]: GraphQLTypes["Contember_LeadFormUniqueWhere"];
	["Contember_SubscriptionBoxLocaleUniqueWhere"]: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"];
	["Contember_SubscriptionBoxUniqueWhere"]: GraphQLTypes["Contember_SubscriptionBoxUniqueWhere"];
	["Contember_BusinessServiceLocaleUniqueWhere"]: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"];
	["Contember_BusinessServiceUniqueWhere"]: GraphQLTypes["Contember_BusinessServiceUniqueWhere"];
	["Contember_IngredientSupplierListUniqueWhere"]: GraphQLTypes["Contember_IngredientSupplierListUniqueWhere"];
	["Contember_IngredientSupplierItemUniqueWhere"]: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"];
	["Contember_ApplicationShortcutListUniqueWhere"]: GraphQLTypes["Contember_ApplicationShortcutListUniqueWhere"];
	["Contember_ApplicationShortcutListItemUniqueWhere"]: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"];
	["Contember_UserUniqueWhere"]: GraphQLTypes["Contember_UserUniqueWhere"];
	["Contember_UserDeviceUniqueWhere"]: GraphQLTypes["Contember_UserDeviceUniqueWhere"];
	["Contember_UserMetadataUniqueWhere"]: GraphQLTypes["Contember_UserMetadataUniqueWhere"];
	["Contember_UserRolesUniqueWhere"]: GraphQLTypes["Contember_UserRolesUniqueWhere"];
	["Contember_InvoicingPlanUniqueWhere"]: GraphQLTypes["Contember_InvoicingPlanUniqueWhere"];
	["Contember_InvoicingPresetUniqueWhere"]: GraphQLTypes["Contember_InvoicingPresetUniqueWhere"];
	["Contember_BillingSubjectUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectUniqueWhere"];
	["Contember_ProductCustomerPriceUniqueWhere"]: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"];
	["Contember_AnonymousSessionUniqueWhere"]: GraphQLTypes["Contember_AnonymousSessionUniqueWhere"];
	["Contember_CustomerSpendingUniqueWhere"]: GraphQLTypes["Contember_CustomerSpendingUniqueWhere"];
	["Contember_CustomerTabUniqueWhere"]: GraphQLTypes["Contember_CustomerTabUniqueWhere"];
	["Contember_CustomerTabItemUniqueWhere"]: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"];
	["Contember_CustomerTabMetadataUniqueWhere"]: GraphQLTypes["Contember_CustomerTabMetadataUniqueWhere"];
	["Contember_CustomerMetadataUniqueWhere"]: GraphQLTypes["Contember_CustomerMetadataUniqueWhere"];
	["Contember_NestedProductCategoryProductUniqueWhere"]: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"];
	["Contember_VirtualProductUniqueWhere"]: GraphQLTypes["Contember_VirtualProductUniqueWhere"];
	["Contember_ProductMetadataUniqueWhere"]: GraphQLTypes["Contember_ProductMetadataUniqueWhere"];
	["Contember_ProductSalesDayUniqueWhere"]: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"];
	["Contember_ProductSalesWeekUniqueWhere"]: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"];
	["Contember_ProductSalesMonthUniqueWhere"]: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"];
	["Contember_CartPriceLineUniqueWhere"]: GraphQLTypes["Contember_CartPriceLineUniqueWhere"];
	["Contember_CartSummaryUniqueWhere"]: GraphQLTypes["Contember_CartSummaryUniqueWhere"];
	["Contember_OrderPriceLineUniqueWhere"]: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"];
	["Contember_OrderSummaryUniqueWhere"]: GraphQLTypes["Contember_OrderSummaryUniqueWhere"];
	["Contember_GratuityUniqueWhere"]: GraphQLTypes["Contember_GratuityUniqueWhere"];
	["Contember_LiftagoRideUniqueWhere"]: GraphQLTypes["Contember_LiftagoRideUniqueWhere"];
	["Contember_CleanerUniqueWhere"]: GraphQLTypes["Contember_CleanerUniqueWhere"];
	["Contember_ShiftsManagerUniqueWhere"]: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"];
	["Contember_BusinessToBusinessManagerUniqueWhere"]: GraphQLTypes["Contember_BusinessToBusinessManagerUniqueWhere"];
	["Contember_StocksManagerUniqueWhere"]: GraphQLTypes["Contember_StocksManagerUniqueWhere"];
	["Contember_CourierManagerUniqueWhere"]: GraphQLTypes["Contember_CourierManagerUniqueWhere"];
	["Contember_PickerUniqueWhere"]: GraphQLTypes["Contember_PickerUniqueWhere"];
	["Contember_RemunerationProfileUniqueWhere"]: GraphQLTypes["Contember_RemunerationProfileUniqueWhere"];
	["Contember_EmploymentInformationUniqueWhere"]: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"];
	["Contember_CashierUniqueWhere"]: GraphQLTypes["Contember_CashierUniqueWhere"];
	["Contember_InternalBuyerUniqueWhere"]: GraphQLTypes["Contember_InternalBuyerUniqueWhere"];
	["Contember_SalesManagerUniqueWhere"]: GraphQLTypes["Contember_SalesManagerUniqueWhere"];
	["Contember_CashierManagerUniqueWhere"]: GraphQLTypes["Contember_CashierManagerUniqueWhere"];
	["Contember_EmployeeQueueItemsByShiftUniqueWhere"]: GraphQLTypes["Contember_EmployeeQueueItemsByShiftUniqueWhere"];
	["Contember_EmployeeQueueItemsByUnassignInfoUniqueWhere"]: GraphQLTypes["Contember_EmployeeQueueItemsByUnassignInfoUniqueWhere"];
	["Contember_EmployeeQueueItemsByMetaUniqueWhere"]: GraphQLTypes["Contember_EmployeeQueueItemsByMetaUniqueWhere"];
	["Contember_EmployeeQueueItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_EmployeeQueueItemEdge"]>
};
	["Contember_EmployeeQueueItemEdge"]: {
		node: GraphQLTypes["Contember_EmployeeQueueItem"]
};
	["Contember_UnassignedShiftInfo"]: {
		_meta?: GraphQLTypes["Contember_UnassignedShiftInfoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	hoursMissed?: number | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	legacyReason?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["Contember_UnassignReason"] | undefined
};
	["Contember_UnassignedShiftInfoMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hoursMissed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	legacyReason?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	reason?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UnassignReason"]: {
		_meta?: GraphQLTypes["Contember_UnassignReasonMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	venues: Array<GraphQLTypes["Contember_Venue"]>,
	paginateVenues: GraphQLTypes["Contember_VenueConnection"]
};
	["Contember_UnassignReasonMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venues?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VenueConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VenueEdge"]>
};
	["Contember_VenueEdge"]: {
		node: GraphQLTypes["Contember_Venue"]
};
	["Contember_EmployeeQueueItemMetadata"]: {
		_meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalMinutes: number,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	includeInTipsCalculation: boolean
};
	["Contember_EmployeeQueueItemMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	includeInTipsCalculation?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableEmployeeRole"]: {
		_meta?: GraphQLTypes["Contember_TimetableEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroups: Array<GraphQLTypes["Contember_TimetableShiftGroup"]>,
	shiftsProfiles: Array<GraphQLTypes["Contember_ShiftsProfile"]>,
	shiftsManagers: Array<GraphQLTypes["Contember_ShiftsManager"]>,
	name: string,
	timetableTemplates: Array<GraphQLTypes["Contember_TimetableTemplate"]>,
	color: string,
	shiftGroupsByShifts?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	timetableTemplatesByStaffOptions?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	paginateShiftGroups: GraphQLTypes["Contember_TimetableShiftGroupConnection"],
	paginateShiftsProfiles: GraphQLTypes["Contember_ShiftsProfileConnection"],
	paginateShiftsManagers: GraphQLTypes["Contember_ShiftsManagerConnection"],
	paginateTimetableTemplates: GraphQLTypes["Contember_TimetableTemplateConnection"]
};
	["Contember_TimetableEmployeeRoleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfiles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsManagers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ShiftsManager"]: {
		_meta?: GraphQLTypes["Contember_ShiftsManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	managedRoles: Array<GraphQLTypes["Contember_TimetableEmployeeRole"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	paginateManagedRoles: GraphQLTypes["Contember_TimetableEmployeeRoleConnection"]
};
	["Contember_ShiftsManagerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	managedRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableEmployeeRoleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableEmployeeRoleEdge"]>
};
	["Contember_TimetableEmployeeRoleEdge"]: {
		node: GraphQLTypes["Contember_TimetableEmployeeRole"]
};
	["Contember_TimetableTemplate"]: {
		_meta?: GraphQLTypes["Contember_TimetableTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	startDate: GraphQLTypes["Date"],
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPosition"] | undefined,
	staffOptions: Array<GraphQLTypes["Contember_TimetableTemplateStaffOptions"]>,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday: boolean,
	tuesday: boolean,
	wednesday: boolean,
	thursday: boolean,
	friday: boolean,
	saturday: boolean,
	sunday: boolean,
	type: GraphQLTypes["Contember_TemplateType"],
	paginateStaffOptions: GraphQLTypes["Contember_TimetableTemplateStaffOptionsConnection"]
};
	["Contember_TimetableTemplateMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	period?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	slots?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	position?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staffOptions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employeeRole?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workdaysOnly?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	monday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tuesday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	wednesday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	thursday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	friday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	saturday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sunday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftPosition"]: {
		_meta?: GraphQLTypes["Contember_TimetableShiftPositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	shiftGroups: Array<GraphQLTypes["Contember_TimetableShiftGroup"]>,
	timetableTemplates: Array<GraphQLTypes["Contember_TimetableTemplate"]>,
	isDynamic: boolean,
	shiftGroupsByShifts?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	timetableTemplatesByStaffOptions?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	paginateShiftGroups: GraphQLTypes["Contember_TimetableShiftGroupConnection"],
	paginateTimetableTemplates: GraphQLTypes["Contember_TimetableTemplateConnection"]
};
	["Contember_TimetableShiftPositionMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	start?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	end?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isDynamic?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"];
	["Contember_TimetableShiftPositionShiftGroupsByQueueUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftPositionShiftGroupsByQueueUniqueWhere"];
	["Contember_TimetableShiftPositionTimetableTemplatesByStaffOptionsUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftPositionTimetableTemplatesByStaffOptionsUniqueWhere"];
	["Contember_TimetableTemplateStaffOptionsUniqueWhere"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"];
	["Contember_TimetableShiftGroupConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableShiftGroupEdge"]>
};
	["Contember_TimetableShiftGroupEdge"]: {
		node: GraphQLTypes["Contember_TimetableShiftGroup"]
};
	["Contember_TimetableTemplateConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableTemplateEdge"]>
};
	["Contember_TimetableTemplateEdge"]: {
		node: GraphQLTypes["Contember_TimetableTemplate"]
};
	["Contember_TimetableTemplateStaffOptions"]: {
		_meta?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	startTime: string,
	endTime: string,
	timetableTemplate?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_ShiftsProfile"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startTime?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	endTime?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsOrderBy"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsOrderBy"];
	["Contember_TimetableTemplateStaffOptionsConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableTemplateStaffOptionsEdge"]>
};
	["Contember_TimetableTemplateStaffOptionsEdge"]: {
		node: GraphQLTypes["Contember_TimetableTemplateStaffOptions"]
};
	["Contember_TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"]: GraphQLTypes["Contember_TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"];
	["Contember_TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"]: GraphQLTypes["Contember_TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"];
	["Contember_TimetableEmployeeRoleTimetableTemplatesByStaffOptionsUniqueWhere"]: GraphQLTypes["Contember_TimetableEmployeeRoleTimetableTemplatesByStaffOptionsUniqueWhere"];
	["Contember_ShiftsProfileConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ShiftsProfileEdge"]>
};
	["Contember_ShiftsProfileEdge"]: {
		node: GraphQLTypes["Contember_ShiftsProfile"]
};
	["Contember_ShiftsManagerConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ShiftsManagerEdge"]>
};
	["Contember_ShiftsManagerEdge"]: {
		node: GraphQLTypes["Contember_ShiftsManager"]
};
	["Contember_ShiftsProfileQueueItemsByQueueUniqueWhere"]: GraphQLTypes["Contember_ShiftsProfileQueueItemsByQueueUniqueWhere"];
	["Contember_ShiftsProfileQueueItemsByShiftUniqueWhere"]: GraphQLTypes["Contember_ShiftsProfileQueueItemsByShiftUniqueWhere"];
	["Contember_ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"]: GraphQLTypes["Contember_ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"];
	["Contember_ShiftsProfileQueueItemsByMetaUniqueWhere"]: GraphQLTypes["Contember_ShiftsProfileQueueItemsByMetaUniqueWhere"];
	["Contember_ShiftsProfileShiftsByQueueItemUniqueWhere"]: GraphQLTypes["Contember_ShiftsProfileShiftsByQueueItemUniqueWhere"];
	["Contember_ShiftsProfileShiftsBySwapInfoUniqueWhere"]: GraphQLTypes["Contember_ShiftsProfileShiftsBySwapInfoUniqueWhere"];
	["Contember_TimetableShiftConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableShiftEdge"]>
};
	["Contember_TimetableShiftEdge"]: {
		node: GraphQLTypes["Contember_TimetableShift"]
};
	["Contember_TimetableShiftSwapInfo"]: {
		_meta?: GraphQLTypes["Contember_TimetableShiftSwapInfoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	assigned?: GraphQLTypes["Date"] | undefined,
	requested?: boolean | undefined,
	confirmed?: boolean | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShift"] | undefined
};
	["Contember_TimetableShiftSwapInfoMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	assigned?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requested?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	confirmed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shift?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftGroupShiftsByQueueItemUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftGroupShiftsByQueueItemUniqueWhere"];
	["Contember_TimetableShiftGroupShiftsBySwapInfoUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftGroupShiftsBySwapInfoUniqueWhere"];
	["Contember_TimetableDayNote"]: {
		_meta?: GraphQLTypes["Contember_TimetableDayNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	note: string,
	day?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined
};
	["Contember_TimetableDayNoteMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	day?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	role?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableDayNoteOrderBy"]: GraphQLTypes["Contember_TimetableDayNoteOrderBy"];
	["Contember_TimetableDayMetadata"]: {
		_meta?: GraphQLTypes["Contember_TimetableDayMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	numberOfWorkers: number,
	tipsTotalCents: number,
	totalMinutes: number,
	tipsCentsPerMinute: number,
	day?: GraphQLTypes["Contember_TimetableDay"] | undefined
};
	["Contember_TimetableDayMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	numberOfWorkers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tipsTotalCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	day?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableDayShiftGroupsByShiftsUniqueWhere"]: GraphQLTypes["Contember_TimetableDayShiftGroupsByShiftsUniqueWhere"];
	["Contember_TimetableDayShiftGroupsByQueueUniqueWhere"]: GraphQLTypes["Contember_TimetableDayShiftGroupsByQueueUniqueWhere"];
	["Contember_TimetableDayNoteConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableDayNoteEdge"]>
};
	["Contember_TimetableDayNoteEdge"]: {
		node: GraphQLTypes["Contember_TimetableDayNote"]
};
	["Contember_VenueEmployeeRole"]: {
		_meta?: GraphQLTypes["Contember_VenueEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	employeePrivileges?: GraphQLTypes["Contember_TimetablesEmployeePrivileges"] | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined,
	getsTipsShare: boolean,
	notesEnabled: boolean
};
	["Contember_VenueEmployeeRoleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employeePrivileges?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	role?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	getsTipsShare?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notesEnabled?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetablesEmployeePrivileges"]: {
		_meta?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	venueEmployeeRole?: GraphQLTypes["Contember_VenueEmployeeRole"] | undefined,
	read: boolean,
	unassign: boolean,
	assign: boolean
};
	["Contember_TimetablesEmployeePrivilegesMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venueEmployeeRole?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	read?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unassign?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	assign?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VenueEmployeeRoleOrderBy"]: GraphQLTypes["Contember_VenueEmployeeRoleOrderBy"];
	["Contember_TimetablesEmployeePrivilegesOrderBy"]: GraphQLTypes["Contember_TimetablesEmployeePrivilegesOrderBy"];
	["Contember_PointOfSaleApplicationDevice"]: {
		_meta?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	identificationCode?: GraphQLTypes["Contember_IdentificationCode"] | undefined,
	identifier?: GraphQLTypes["Contember_DeviceIdentifier"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleTerminal"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSalePrinter"] | undefined,
	ticketPrinters: Array<GraphQLTypes["Contember_PointOfSalePrinter"]>,
	paginateTicketPrinters: GraphQLTypes["Contember_PointOfSalePrinterConnection"]
};
	["Contember_PointOfSaleApplicationDeviceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identificationCode?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	terminal?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ticketPrinters?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationCode"]: {
		_meta?: GraphQLTypes["Contember_IdentificationCodeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	createdAt: GraphQLTypes["DateTime"],
	attempts: Array<GraphQLTypes["Contember_IdentificationAttempt"]>,
	locales: Array<GraphQLTypes["Contember_IdentificationCodeLocale"]>,
	internalName?: string | undefined,
	applicationDevices: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	localesByLocale?: GraphQLTypes["Contember_IdentificationCodeLocale"] | undefined,
	applicationDevicesByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	paginateAttempts: GraphQLTypes["Contember_IdentificationAttemptConnection"],
	paginateLocales: GraphQLTypes["Contember_IdentificationCodeLocaleConnection"],
	paginateApplicationDevices: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"]
};
	["Contember_IdentificationCodeMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	attempts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationAttempt"]: {
		_meta?: GraphQLTypes["Contember_IdentificationAttemptMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	code?: GraphQLTypes["Contember_IdentificationCode"] | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined
};
	["Contember_IdentificationAttemptMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_User"]: {
		_meta?: GraphQLTypes["Contember_UserMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	tenantPersonId: GraphQLTypes["UUID"],
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices: Array<GraphQLTypes["Contember_UserDevice"]>,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserMetadata"] | undefined,
	identificationAttempts: Array<GraphQLTypes["Contember_IdentificationAttempt"]>,
	roles?: GraphQLTypes["Contember_UserRoles"] | undefined,
	devicesByDeviceId?: GraphQLTypes["Contember_UserDevice"] | undefined,
	paginateDevices: GraphQLTypes["Contember_UserDeviceConnection"],
	paginateIdentificationAttempts: GraphQLTypes["Contember_IdentificationAttemptConnection"]
};
	["Contember_UserMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	devices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	verifiedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletionRequestedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletionExpectedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identificationAttempts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	roles?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UserDevice"]: {
		_meta?: GraphQLTypes["Contember_UserDeviceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	lastActiveAt: GraphQLTypes["DateTime"],
	platform?: string | undefined,
	deviceId?: string | undefined,
	notificationsEnabled: boolean,
	user?: GraphQLTypes["Contember_User"] | undefined,
	appVersion?: string | undefined,
	osVersion?: string | undefined,
	apiVersion?: string | undefined,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSession"] | undefined
};
	["Contember_UserDeviceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	platform?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deviceId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notificationsEnabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	appVersion?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	osVersion?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	apiVersion?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AnonymousSession"]: {
		_meta?: GraphQLTypes["Contember_AnonymousSessionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey: string,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	devices: Array<GraphQLTypes["Contember_UserDevice"]>,
	devicesByDeviceId?: GraphQLTypes["Contember_UserDevice"] | undefined,
	paginateDevices: GraphQLTypes["Contember_UserDeviceConnection"]
};
	["Contember_AnonymousSessionMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	anonymousSessionKey?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	devices?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UserDeviceOrderBy"]: GraphQLTypes["Contember_UserDeviceOrderBy"];
	["Contember_AnonymousSessionDevicesByDeviceIdUniqueWhere"]: GraphQLTypes["Contember_AnonymousSessionDevicesByDeviceIdUniqueWhere"];
	["Contember_UserDeviceConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_UserDeviceEdge"]>
};
	["Contember_UserDeviceEdge"]: {
		node: GraphQLTypes["Contember_UserDevice"]
};
	["Contember_UserMetadata"]: {
		_meta?: GraphQLTypes["Contember_UserMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	userProfileUrl: string,
	tenantEmail?: string | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	tenantIdentityId?: GraphQLTypes["UUID"] | undefined,
	globalRoles?: GraphQLTypes["Json"] | undefined,
	projectRoles?: GraphQLTypes["Json"] | undefined,
	projectRolesString?: string | undefined,
	externalIdentifiers?: GraphQLTypes["Json"] | undefined,
	externalIdentifiersString?: string | undefined,
	idpSlugs?: GraphQLTypes["Json"] | undefined,
	idpSlugsString?: string | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined
};
	["Contember_UserMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	userProfileUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantIdentityId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	globalRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	projectRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	projectRolesString?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifiers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifiersString?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	idpSlugs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	idpSlugsString?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationAttemptOrderBy"]: GraphQLTypes["Contember_IdentificationAttemptOrderBy"];
	["Contember_IdentificationCodeOrderBy"]: GraphQLTypes["Contember_IdentificationCodeOrderBy"];
	["Contember_UserRoles"]: {
		_meta?: GraphQLTypes["Contember_UserRolesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCustomer: boolean,
	isStaff: boolean,
	isBartender: boolean,
	isCashier: boolean,
	isCourier: boolean,
	isCleaner: boolean,
	isShiftsManager: boolean,
	isInternalBuyer: boolean,
	isStocksManager: boolean,
	isCourierManager: boolean,
	isCashierManager: boolean,
	isBusinessToBusinessManager: boolean,
	isPicker: boolean,
	user?: GraphQLTypes["Contember_User"] | undefined
};
	["Contember_UserRolesMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isStaff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isBartender?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCashier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCourier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCleaner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isShiftsManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isInternalBuyer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isStocksManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCourierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCashierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isBusinessToBusinessManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isPicker?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UserDevicesByDeviceIdUniqueWhere"]: GraphQLTypes["Contember_UserDevicesByDeviceIdUniqueWhere"];
	["Contember_IdentificationAttemptConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IdentificationAttemptEdge"]>
};
	["Contember_IdentificationAttemptEdge"]: {
		node: GraphQLTypes["Contember_IdentificationAttempt"]
};
	["Contember_IdentificationCodeLocale"]: {
		_meta?: GraphQLTypes["Contember_IdentificationCodeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	successMessage?: string | undefined,
	root?: GraphQLTypes["Contember_IdentificationCode"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_IdentificationCodeLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	successMessage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationCodeLocaleOrderBy"]: GraphQLTypes["Contember_IdentificationCodeLocaleOrderBy"];
	["Contember_PointOfSaleApplicationDeviceOrderBy"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceOrderBy"];
	["Contember_DeviceIdentifierOrderBy"]: GraphQLTypes["Contember_DeviceIdentifierOrderBy"];
	["Contember_DeviceIdentifierMetadataOrderBy"]: GraphQLTypes["Contember_DeviceIdentifierMetadataOrderBy"];
	["Contember_PointOfSaleTerminalOrderBy"]: GraphQLTypes["Contember_PointOfSaleTerminalOrderBy"];
	["Contember_PointOfSalePrinterOrderBy"]: GraphQLTypes["Contember_PointOfSalePrinterOrderBy"];
	["Contember_IdentificationCodeLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_IdentificationCodeLocalesByLocaleUniqueWhere"];
	["Contember_IdentificationCodeApplicationDevicesByIdentifierUniqueWhere"]: GraphQLTypes["Contember_IdentificationCodeApplicationDevicesByIdentifierUniqueWhere"];
	["Contember_IdentificationCodeLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IdentificationCodeLocaleEdge"]>
};
	["Contember_IdentificationCodeLocaleEdge"]: {
		node: GraphQLTypes["Contember_IdentificationCodeLocale"]
};
	["Contember_PointOfSaleApplicationDeviceConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceEdge"]>
};
	["Contember_PointOfSaleApplicationDeviceEdge"]: {
		node: GraphQLTypes["Contember_PointOfSaleApplicationDevice"]
};
	["Contember_DeviceIdentifier"]: {
		_meta?: GraphQLTypes["Contember_DeviceIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	device?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	updatedAt: GraphQLTypes["DateTime"],
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	updatedByUser?: GraphQLTypes["Contember_User"] | undefined,
	identifier: string,
	meta?: GraphQLTypes["Contember_DeviceIdentifierMetadata"] | undefined
};
	["Contember_DeviceIdentifierMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	device?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	platform?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	resolution?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	devicePixelRatio?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedByUser?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeviceIdentifierMetadata"]: {
		_meta?: GraphQLTypes["Contember_DeviceIdentifierMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	prettyIdentifier: string,
	deviceIdentifier?: GraphQLTypes["Contember_DeviceIdentifier"] | undefined
};
	["Contember_DeviceIdentifierMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	prettyIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deviceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleTerminal"]: {
		_meta?: GraphQLTypes["Contember_PointOfSaleTerminalMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	applicationDevices: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	ipAddress?: string | undefined,
	port?: number | undefined,
	type: GraphQLTypes["Contember_PointOfSaleTerminalType"],
	applicationDevicesByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	paginateApplicationDevices: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"]
};
	["Contember_PointOfSaleTerminalMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ipAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	port?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleTerminalApplicationDevicesByIdentifierUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleTerminalApplicationDevicesByIdentifierUniqueWhere"];
	["Contember_PointOfSalePrinter"]: {
		_meta?: GraphQLTypes["Contember_PointOfSalePrinterMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	note?: string | undefined,
	ipAddress: string,
	applicationDevicesReceipt: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	applicationDevicesTicket: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	ticketCategories: Array<GraphQLTypes["Contember_PointOfSaleCategory"]>,
	applicationDevicesReceiptByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	paginateApplicationDevicesReceipt: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"],
	paginateApplicationDevicesTicket: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"],
	paginateTicketCategories: GraphQLTypes["Contember_PointOfSaleCategoryConnection"]
};
	["Contember_PointOfSalePrinterMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ipAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevicesReceipt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevicesTicket?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ticketCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategory"]: {
		_meta?: GraphQLTypes["Contember_PointOfSaleCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	slug: string,
	locales: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocale"]>,
	products: Array<GraphQLTypes["Contember_PointOfSaleCategoryProduct"]>,
	category?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	ticketPrinters: Array<GraphQLTypes["Contember_PointOfSalePrinter"]>,
	localesByLocale?: GraphQLTypes["Contember_PointOfSaleCategoryLocale"] | undefined,
	productsByLocales?: GraphQLTypes["Contember_PointOfSaleCategoryProduct"] | undefined,
	paginateLocales: GraphQLTypes["Contember_PointOfSaleCategoryLocaleConnection"],
	paginateProducts: GraphQLTypes["Contember_PointOfSaleCategoryProductConnection"],
	paginateTicketPrinters: GraphQLTypes["Contember_PointOfSalePrinterConnection"]
};
	["Contember_PointOfSaleCategoryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	slug?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ticketPrinters?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryLocale"]: {
		_meta?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title: string,
	root?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleOrderBy"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleOrderBy"];
	["Contember_PointOfSaleCategoryOrderBy"]: GraphQLTypes["Contember_PointOfSaleCategoryOrderBy"];
	["Contember_ProductCategoryOrderBy"]: GraphQLTypes["Contember_ProductCategoryOrderBy"];
	["Contember_PointOfSaleCategoryProduct"]: {
		_meta?: GraphQLTypes["Contember_PointOfSaleCategoryProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	category?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	locales: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleConnection"]
};
	["Contember_PointOfSaleCategoryProductMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocale"]: {
		_meta?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title: string,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryProduct"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleOrderBy"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleOrderBy"];
	["Contember_PointOfSaleCategoryProductOrderBy"]: GraphQLTypes["Contember_PointOfSaleCategoryProductOrderBy"];
	["Contember_PointOfSaleCategoryProductLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocalesByLocaleUniqueWhere"];
	["Contember_PointOfSaleCategoryProductLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleEdge"]>
};
	["Contember_PointOfSaleCategoryProductLocaleEdge"]: {
		node: GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"]
};
	["Contember_ProductCategory"]: {
		_meta?: GraphQLTypes["Contember_ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	parent?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	children: Array<GraphQLTypes["Contember_ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	order: number,
	descendants: Array<GraphQLTypes["Contember_ProductCategoryTree"]>,
	ancestors: Array<GraphQLTypes["Contember_ProductCategoryTree"]>,
	inheritedTags: Array<GraphQLTypes["Contember_InheritedProductCategoryTag"]>,
	nestedProducts: Array<GraphQLTypes["Contember_NestedProductCategoryProduct"]>,
	childrenByCode?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByDescendants?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByAncestors?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByInheritedTags?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByNestedProducts?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateChildren: GraphQLTypes["Contember_ProductCategoryConnection"],
	paginateDescendants: GraphQLTypes["Contember_ProductCategoryTreeConnection"],
	paginateAncestors: GraphQLTypes["Contember_ProductCategoryTreeConnection"],
	paginateInheritedTags: GraphQLTypes["Contember_InheritedProductCategoryTagConnection"],
	paginateNestedProducts: GraphQLTypes["Contember_NestedProductCategoryProductConnection"]
};
	["Contember_ProductCategoryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	parent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	children?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	descendants?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ancestors?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	inheritedTags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nestedProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryTree"]: {
		_meta?: GraphQLTypes["Contember_ProductCategoryTreeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	depth: number,
	ancestor?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	descendant?: GraphQLTypes["Contember_ProductCategory"] | undefined
};
	["Contember_ProductCategoryTreeMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	depth?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ancestor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	descendant?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryTreeOrderBy"]: GraphQLTypes["Contember_ProductCategoryTreeOrderBy"];
	["Contember_InheritedProductCategoryTag"]: {
		_meta?: GraphQLTypes["Contember_InheritedProductCategoryTagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	depth: number,
	productCategory?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	tag?: GraphQLTypes["Contember_Tag"] | undefined
};
	["Contember_InheritedProductCategoryTagMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	depth?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tag?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_InheritedProductCategoryTagOrderBy"]: GraphQLTypes["Contember_InheritedProductCategoryTagOrderBy"];
	["Contember_NestedProductCategoryProduct"]: {
		_meta?: GraphQLTypes["Contember_NestedProductCategoryProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	depth: number,
	product?: GraphQLTypes["Product"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategory"] | undefined
};
	["Contember_NestedProductCategoryProductMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	depth?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_NestedProductCategoryProductOrderBy"]: GraphQLTypes["Contember_NestedProductCategoryProductOrderBy"];
	["Contember_ProductCategoryChildrenByCodeUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryChildrenByCodeUniqueWhere"];
	["Contember_ProductCategoryChildrenByChildrenUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryChildrenByChildrenUniqueWhere"];
	["Contember_ProductCategoryUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryUniqueWhere"];
	["Contember_ProductCategoryTreeUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"];
	["Contember_InheritedProductCategoryTagUniqueWhere"]: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"];
	["Contember_ProductCategoryChildrenByDescendantsUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryChildrenByDescendantsUniqueWhere"];
	["Contember_ProductCategoryChildrenByAncestorsUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryChildrenByAncestorsUniqueWhere"];
	["Contember_ProductCategoryChildrenByInheritedTagsUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryChildrenByInheritedTagsUniqueWhere"];
	["Contember_ProductCategoryChildrenByNestedProductsUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryChildrenByNestedProductsUniqueWhere"];
	["ProductConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
		node: GraphQLTypes["Product"]
};
	["Contember_ProductCategoryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryEdge"]>
};
	["Contember_ProductCategoryEdge"]: {
		node: GraphQLTypes["Contember_ProductCategory"]
};
	["Contember_ProductCategoryTreeConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryTreeEdge"]>
};
	["Contember_ProductCategoryTreeEdge"]: {
		node: GraphQLTypes["Contember_ProductCategoryTree"]
};
	["Contember_InheritedProductCategoryTagConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_InheritedProductCategoryTagEdge"]>
};
	["Contember_InheritedProductCategoryTagEdge"]: {
		node: GraphQLTypes["Contember_InheritedProductCategoryTag"]
};
	["Contember_NestedProductCategoryProductConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_NestedProductCategoryProductEdge"]>
};
	["Contember_NestedProductCategoryProductEdge"]: {
		node: GraphQLTypes["Contember_NestedProductCategoryProduct"]
};
	["Contember_PointOfSaleCategoryLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryLocalesByLocaleUniqueWhere"];
	["Contember_PointOfSaleCategoryProductsByLocalesUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleCategoryProductsByLocalesUniqueWhere"];
	["Contember_PointOfSaleCategoryLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocaleEdge"]>
};
	["Contember_PointOfSaleCategoryLocaleEdge"]: {
		node: GraphQLTypes["Contember_PointOfSaleCategoryLocale"]
};
	["Contember_PointOfSaleCategoryProductConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductEdge"]>
};
	["Contember_PointOfSaleCategoryProductEdge"]: {
		node: GraphQLTypes["Contember_PointOfSaleCategoryProduct"]
};
	["Contember_PointOfSalePrinterConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSalePrinterEdge"]>
};
	["Contember_PointOfSalePrinterEdge"]: {
		node: GraphQLTypes["Contember_PointOfSalePrinter"]
};
	["Contember_PointOfSalePrinterApplicationDevicesReceiptByIdentifierUniqueWhere"]: GraphQLTypes["Contember_PointOfSalePrinterApplicationDevicesReceiptByIdentifierUniqueWhere"];
	["Contember_PointOfSaleCategoryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryEdge"]>
};
	["Contember_PointOfSaleCategoryEdge"]: {
		node: GraphQLTypes["Contember_PointOfSaleCategory"]
};
	["OrderMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canceledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discounts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	events?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seq?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	summary?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gratuity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderDiscount"]: {
		_meta?: GraphQLTypes["OrderDiscountMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	discountCents: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	description?: string | undefined,
	type: GraphQLTypes["Contember_OrderDiscountType"],
	customerCreditTransactions: Array<GraphQLTypes["Contember_CustomerCreditTransaction"]>,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	customerCreditTransactionsByVirtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransaction"] | undefined,
	paginateCustomerCreditTransactions: GraphQLTypes["Contember_CustomerCreditTransactionConnection"]
};
	["OrderDiscountMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCreditTransactions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerCreditTransaction"]: {
		_meta?: GraphQLTypes["Contember_CustomerCreditTransactionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	amountCents: number,
	customerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined
};
	["Contember_CustomerCreditTransactionMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	amountCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCredit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["CustomerCreditMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	initialCreditCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	reason?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	transactions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoiceUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerCreditTransactionOrderBy"]: GraphQLTypes["Contember_CustomerCreditTransactionOrderBy"];
	["Contember_CustomerCreditOrderBy"]: GraphQLTypes["Contember_CustomerCreditOrderBy"];
	["Contember_VoucherRedemptionOrderBy"]: GraphQLTypes["Contember_VoucherRedemptionOrderBy"];
	["Contember_OrderDiscountOrderBy"]: GraphQLTypes["Contember_OrderDiscountOrderBy"];
	["Contember_OrderOrderBy"]: GraphQLTypes["Contember_OrderOrderBy"];
	["Contember_OrderDeliveryOrderBy"]: GraphQLTypes["Contember_OrderDeliveryOrderBy"];
	["Contember_DeliveryWaypointOrderBy"]: GraphQLTypes["Contember_DeliveryWaypointOrderBy"];
	["Contember_DeliveryRouteOrderBy"]: GraphQLTypes["Contember_DeliveryRouteOrderBy"];
	["Contember_LiftagoRideOrderBy"]: GraphQLTypes["Contember_LiftagoRideOrderBy"];
	["Contember_CartOrderBy"]: GraphQLTypes["Contember_CartOrderBy"];
	["Contember_CartSummaryOrderBy"]: GraphQLTypes["Contember_CartSummaryOrderBy"];
	["Contember_OrderSummaryOrderBy"]: GraphQLTypes["Contember_OrderSummaryOrderBy"];
	["Contember_GratuityOrderBy"]: GraphQLTypes["Contember_GratuityOrderBy"];
	["Contember_VoucherOrderBy"]: GraphQLTypes["Contember_VoucherOrderBy"];
	["Contember_VirtualProductEffectOrderBy"]: GraphQLTypes["Contember_VirtualProductEffectOrderBy"];
	["Contember_OrderItemOrderBy"]: GraphQLTypes["Contember_OrderItemOrderBy"];
	["Contember_VoucherRedemption"]: {
		_meta?: GraphQLTypes["Contember_VoucherRedemptionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	voucher?: GraphQLTypes["Contember_Voucher"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_VoucherRedemptionMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	manualRedemptionNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCredit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucher?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Voucher"]: {
		_meta?: GraphQLTypes["Contember_VoucherMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code: string,
	type: GraphQLTypes["Contember_VoucherType"],
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_Customer"] | undefined,
	owner?: GraphQLTypes["Contember_Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherRedemption"] | undefined
};
	["Contember_VoucherMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	activatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	creditCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	buyer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	owner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	redemption?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VirtualProductEffect"]: {
		_meta?: GraphQLTypes["Contember_VirtualProductEffectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_CustomerCreditTransaction"] | undefined,
	resultVouchers: Array<GraphQLTypes["Contember_Voucher"]>,
	resultVouchersByCode?: GraphQLTypes["Contember_Voucher"] | undefined,
	resultVouchersByRedemption?: GraphQLTypes["Contember_Voucher"] | undefined,
	paginateResultVouchers: GraphQLTypes["Contember_VoucherConnection"]
};
	["Contember_VirtualProductEffectMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	succeededAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	revertedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	resultVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderItem"]: {
		_meta?: GraphQLTypes["OrderItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	virtualProductEffects: Array<GraphQLTypes["Contember_VirtualProductEffect"]>,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	product?: GraphQLTypes["Product"] | undefined,
	virtualProductEffectsByResultCustomerCreditTransaction?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined,
	virtualProductEffectsByResultVouchers?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined,
	paginateVirtualProductEffects: GraphQLTypes["Contember_VirtualProductEffectConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["OrderItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProductEffects?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FulfillmentNoteOrderBy"]: GraphQLTypes["Contember_FulfillmentNoteOrderBy"];
	["Contember_OrderItemVirtualProductEffectsByResultCustomerCreditTransactionUniqueWhere"]: GraphQLTypes["Contember_OrderItemVirtualProductEffectsByResultCustomerCreditTransactionUniqueWhere"];
	["Contember_OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"]: GraphQLTypes["Contember_OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"];
	["Contember_VirtualProductEffectConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VirtualProductEffectEdge"]>
};
	["Contember_VirtualProductEffectEdge"]: {
		node: GraphQLTypes["Contember_VirtualProductEffect"]
};
	["Contember_FulfillmentNoteConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_FulfillmentNoteEdge"]>
};
	["Contember_FulfillmentNoteEdge"]: {
		node: GraphQLTypes["Contember_FulfillmentNote"]
};
	["Contember_VirtualProductEffectResultVouchersByCodeUniqueWhere"]: GraphQLTypes["Contember_VirtualProductEffectResultVouchersByCodeUniqueWhere"];
	["Contember_VirtualProductEffectResultVouchersByRedemptionUniqueWhere"]: GraphQLTypes["Contember_VirtualProductEffectResultVouchersByRedemptionUniqueWhere"];
	["Contember_VoucherConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VoucherEdge"]>
};
	["Contember_VoucherEdge"]: {
		node: GraphQLTypes["Contember_Voucher"]
};
	["Contember_CustomerCreditTransactionsByVirtualProductEffectUniqueWhere"]: GraphQLTypes["Contember_CustomerCreditTransactionsByVirtualProductEffectUniqueWhere"];
	["Contember_CustomerCreditTransactionConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerCreditTransactionEdge"]>
};
	["Contember_CustomerCreditTransactionEdge"]: {
		node: GraphQLTypes["Contember_CustomerCreditTransaction"]
};
	["Contember_OrderDiscountCustomerCreditTransactionsByVirtualProductEffectUniqueWhere"]: GraphQLTypes["Contember_OrderDiscountCustomerCreditTransactionsByVirtualProductEffectUniqueWhere"];
	["Contember_OrderEvent"]: {
		_meta?: GraphQLTypes["Contember_OrderEventMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: string,
	data?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	order?: GraphQLTypes["Order"] | undefined,
	payment?: GraphQLTypes["OrderPayment"] | undefined,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined
};
	["Contember_OrderEventMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderPaymentMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	approvedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	events?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoice?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	walletType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicReceiptUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderEventOrderBy"]: GraphQLTypes["Contember_OrderEventOrderBy"];
	["Contember_OrderPaymentOrderBy"]: GraphQLTypes["Contember_OrderPaymentOrderBy"];
	["Contember_InvoiceOrderBy"]: GraphQLTypes["Contember_InvoiceOrderBy"];
	["Contember_OrderPaymentMetadataOrderBy"]: GraphQLTypes["Contember_OrderPaymentMetadataOrderBy"];
	["Contember_Invoice"]: {
		_meta?: GraphQLTypes["Contember_InvoiceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	publicKey: string,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	orderPayments: Array<GraphQLTypes["OrderPayment"]>,
	address?: GraphQLTypes["Address"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	orderPaymentsByEvents?: GraphQLTypes["OrderPayment"] | undefined,
	orderPaymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateOrderPayments: GraphQLTypes["OrderPaymentConnection"]
};
	["Contember_InvoiceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicKey?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidData?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	legacyUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderPayments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BillingSubject"]: {
		_meta?: GraphQLTypes["Contember_BillingSubjectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_Country"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	invoices: Array<GraphQLTypes["Contember_Invoice"]>,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_Customer"] | undefined,
	orders: Array<GraphQLTypes["Order"]>,
	invoicesByPublicKey?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Contember_Invoice"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByEvents?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ordersByGratuity?: GraphQLTypes["Order"] | undefined,
	paginateInvoices: GraphQLTypes["Contember_InvoiceConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["Contember_BillingSubjectMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fullName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	city?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hidden?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	country?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Country"]: {
		_meta?: GraphQLTypes["Contember_CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	order: number
};
	["Contember_CountryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BillingSubjectInvoicesByPublicKeyUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectInvoicesByPublicKeyUniqueWhere"];
	["Contember_BillingSubjectInvoicesByFakturoidIdUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectInvoicesByFakturoidIdUniqueWhere"];
	["Contember_BillingSubjectInvoicesByOrderPaymentsUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectInvoicesByOrderPaymentsUniqueWhere"];
	["Contember_BillingSubjectOrdersBySeqUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersBySeqUniqueWhere"];
	["Contember_BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"];
	["Contember_BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["Contember_BillingSubjectOrdersByDiscountsUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByDiscountsUniqueWhere"];
	["Contember_BillingSubjectOrdersByDeliveryUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByDeliveryUniqueWhere"];
	["Contember_BillingSubjectOrdersByItemsUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByItemsUniqueWhere"];
	["Contember_BillingSubjectOrdersByCartUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByCartUniqueWhere"];
	["Contember_BillingSubjectOrdersByEventsUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByEventsUniqueWhere"];
	["Contember_BillingSubjectOrdersByPriceLinesUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByPriceLinesUniqueWhere"];
	["Contember_BillingSubjectOrdersByPaymentsUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByPaymentsUniqueWhere"];
	["Contember_BillingSubjectOrdersBySummaryUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersBySummaryUniqueWhere"];
	["Contember_BillingSubjectOrdersByGratuityUniqueWhere"]: GraphQLTypes["Contember_BillingSubjectOrdersByGratuityUniqueWhere"];
	["Contember_InvoiceConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_InvoiceEdge"]>
};
	["Contember_InvoiceEdge"]: {
		node: GraphQLTypes["Contember_Invoice"]
};
	["OrderConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderEdge"]>
};
	["OrderEdge"]: {
		node: GraphQLTypes["Order"]
};
	["Contember_InvoiceOrderPaymentsByEventsUniqueWhere"]: GraphQLTypes["Contember_InvoiceOrderPaymentsByEventsUniqueWhere"];
	["Contember_InvoiceOrderPaymentsByMetaUniqueWhere"]: GraphQLTypes["Contember_InvoiceOrderPaymentsByMetaUniqueWhere"];
	["OrderPaymentConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentEdge"]>
};
	["OrderPaymentEdge"]: {
		node: GraphQLTypes["OrderPayment"]
};
	["Contember_OrderPaymentMetadata"]: {
		_meta?: GraphQLTypes["Contember_OrderPaymentMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	paymentUrl: string,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined
};
	["Contember_OrderPaymentMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderEventConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_OrderEventEdge"]>
};
	["Contember_OrderEventEdge"]: {
		node: GraphQLTypes["Contember_OrderEvent"]
};
	["OrderPriceLine"]: {
		_meta?: GraphQLTypes["OrderPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	type?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	orderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderPriceLineMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDelivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderPriceLineOrderBy"]: GraphQLTypes["Contember_OrderPriceLineOrderBy"];
	["OrderSummary"]: {
		_meta?: GraphQLTypes["OrderSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalPriceCents: number,
	discountPriceCents: number,
	undiscountedPriceCents: number,
	itemsQuantity: number,
	orderUrl: string,
	orderNumber: string,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderSummaryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderNumber?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Gratuity"]: {
		_meta?: GraphQLTypes["Contember_GratuityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	amountCents: number,
	order?: GraphQLTypes["Order"] | undefined
};
	["Contember_GratuityMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	amountCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderDiscountsByCustomerCreditTransactionsUniqueWhere"]: GraphQLTypes["Contember_OrderDiscountsByCustomerCreditTransactionsUniqueWhere"];
	["Contember_OrderDiscountsByVoucherRedemptionUniqueWhere"]: GraphQLTypes["Contember_OrderDiscountsByVoucherRedemptionUniqueWhere"];
	["Contember_OrderItemsByVirtualProductEffectsUniqueWhere"]: GraphQLTypes["Contember_OrderItemsByVirtualProductEffectsUniqueWhere"];
	["Contember_OrderPriceLinesByOrderItemUniqueWhere"]: GraphQLTypes["Contember_OrderPriceLinesByOrderItemUniqueWhere"];
	["Contember_OrderPriceLinesByOrderDiscountUniqueWhere"]: GraphQLTypes["Contember_OrderPriceLinesByOrderDiscountUniqueWhere"];
	["Contember_OrderPriceLinesByOrderPaymentUniqueWhere"]: GraphQLTypes["Contember_OrderPriceLinesByOrderPaymentUniqueWhere"];
	["Contember_OrderPriceLinesByOrderDeliveryUniqueWhere"]: GraphQLTypes["Contember_OrderPriceLinesByOrderDeliveryUniqueWhere"];
	["Contember_OrderPaymentsByEventsUniqueWhere"]: GraphQLTypes["Contember_OrderPaymentsByEventsUniqueWhere"];
	["Contember_OrderPaymentsByMetaUniqueWhere"]: GraphQLTypes["Contember_OrderPaymentsByMetaUniqueWhere"];
	["OrderDiscountConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderDiscountEdge"]>
};
	["OrderDiscountEdge"]: {
		node: GraphQLTypes["OrderDiscount"]
};
	["OrderItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderItemEdge"]>
};
	["OrderItemEdge"]: {
		node: GraphQLTypes["OrderItem"]
};
	["OrderPriceLineConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderPriceLineEdge"]>
};
	["OrderPriceLineEdge"]: {
		node: GraphQLTypes["OrderPriceLine"]
};
	["Contember_Area"]: {
		_meta?: GraphQLTypes["Contember_AreaMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	tables: Array<GraphQLTypes["Contember_Table"]>,
	lines: Array<GraphQLTypes["Contember_Line"]>,
	tablesByCustomerTabs?: GraphQLTypes["Contember_Table"] | undefined,
	tablesBySeats?: GraphQLTypes["Contember_Table"] | undefined,
	paginateTables: GraphQLTypes["Contember_TableConnection"],
	paginateLines: GraphQLTypes["Contember_LineConnection"]
};
	["Contember_AreaMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tables?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lines?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Table"]: {
		_meta?: GraphQLTypes["Contember_TableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shape: GraphQLTypes["Contember_TableShape"],
	width: number,
	height: number,
	positionX: number,
	positionY: number,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs: Array<GraphQLTypes["Contember_CustomerTab"]>,
	area?: GraphQLTypes["Contember_Area"] | undefined,
	seats: Array<GraphQLTypes["Contember_Seat"]>,
	customerTabsByItems?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	customerTabsByMeta?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	seatsByCustomerTabs?: GraphQLTypes["Contember_Seat"] | undefined,
	paginateCustomerTabs: GraphQLTypes["Contember_CustomerTabConnection"],
	paginateSeats: GraphQLTypes["Contember_SeatConnection"]
};
	["Contember_TableMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shape?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	width?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	height?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTabs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	area?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seats?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerTab"]: {
		_meta?: GraphQLTypes["Contember_CustomerTabMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	color?: string | undefined,
	items: Array<GraphQLTypes["Contember_CustomerTabItem"]>,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabMetadata"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	table?: GraphQLTypes["Contember_Table"] | undefined,
	seat?: GraphQLTypes["Contember_Seat"] | undefined,
	paginateItems: GraphQLTypes["Contember_CustomerTabItemConnection"]
};
	["Contember_CustomerTabMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	table?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seat?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerTabItem"]: {
		_meta?: GraphQLTypes["Contember_CustomerTabItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addedAt: GraphQLTypes["DateTime"],
	tab?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	paidAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_CustomerTabItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tab?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paidAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerTabItemOrderBy"]: GraphQLTypes["Contember_CustomerTabItemOrderBy"];
	["Contember_CustomerTabOrderBy"]: GraphQLTypes["Contember_CustomerTabOrderBy"];
	["Contember_CustomerTabMetadataOrderBy"]: GraphQLTypes["Contember_CustomerTabMetadataOrderBy"];
	["Contember_TableOrderBy"]: GraphQLTypes["Contember_TableOrderBy"];
	["Contember_AreaOrderBy"]: GraphQLTypes["Contember_AreaOrderBy"];
	["Contember_SeatOrderBy"]: GraphQLTypes["Contember_SeatOrderBy"];
	["Contember_CustomerTabMetadata"]: {
		_meta?: GraphQLTypes["Contember_CustomerTabMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalPriceCents: number,
	totalSpentCents: number,
	unpaidCents: number,
	customerTab?: GraphQLTypes["Contember_CustomerTab"] | undefined
};
	["Contember_CustomerTabMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalSpentCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unpaidCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTab?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Seat"]: {
		_meta?: GraphQLTypes["Contember_SeatMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	positionX: number,
	positionY: number,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs: Array<GraphQLTypes["Contember_CustomerTab"]>,
	table?: GraphQLTypes["Contember_Table"] | undefined,
	customerTabsByItems?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	customerTabsByMeta?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	paginateCustomerTabs: GraphQLTypes["Contember_CustomerTabConnection"]
};
	["Contember_SeatMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTabs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	table?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SeatCustomerTabsByItemsUniqueWhere"]: GraphQLTypes["Contember_SeatCustomerTabsByItemsUniqueWhere"];
	["Contember_SeatCustomerTabsByMetaUniqueWhere"]: GraphQLTypes["Contember_SeatCustomerTabsByMetaUniqueWhere"];
	["Contember_CustomerTabConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerTabEdge"]>
};
	["Contember_CustomerTabEdge"]: {
		node: GraphQLTypes["Contember_CustomerTab"]
};
	["Contember_CustomerTabItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerTabItemEdge"]>
};
	["Contember_CustomerTabItemEdge"]: {
		node: GraphQLTypes["Contember_CustomerTabItem"]
};
	["Contember_TableCustomerTabsByItemsUniqueWhere"]: GraphQLTypes["Contember_TableCustomerTabsByItemsUniqueWhere"];
	["Contember_TableCustomerTabsByMetaUniqueWhere"]: GraphQLTypes["Contember_TableCustomerTabsByMetaUniqueWhere"];
	["Contember_TableSeatsByCustomerTabsUniqueWhere"]: GraphQLTypes["Contember_TableSeatsByCustomerTabsUniqueWhere"];
	["Contember_SeatConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_SeatEdge"]>
};
	["Contember_SeatEdge"]: {
		node: GraphQLTypes["Contember_Seat"]
};
	["Contember_Line"]: {
		_meta?: GraphQLTypes["Contember_LineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	positionStartX: number,
	positionStartY: number,
	positionEndX: number,
	positionEndY: number,
	area?: GraphQLTypes["Contember_Area"] | undefined
};
	["Contember_LineMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionStartX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionStartY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionEndX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionEndY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	area?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LineOrderBy"]: GraphQLTypes["Contember_LineOrderBy"];
	["Contember_AreaTablesByCustomerTabsUniqueWhere"]: GraphQLTypes["Contember_AreaTablesByCustomerTabsUniqueWhere"];
	["Contember_AreaTablesBySeatsUniqueWhere"]: GraphQLTypes["Contember_AreaTablesBySeatsUniqueWhere"];
	["Contember_SeatUniqueWhere"]: GraphQLTypes["Contember_SeatUniqueWhere"];
	["Contember_TableConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TableEdge"]>
};
	["Contember_TableEdge"]: {
		node: GraphQLTypes["Contember_Table"]
};
	["Contember_LineConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_LineEdge"]>
};
	["Contember_LineEdge"]: {
		node: GraphQLTypes["Contember_Line"]
};
	["Contember_VenueTimetableDaysByDateUniqueWhere"]: GraphQLTypes["Contember_VenueTimetableDaysByDateUniqueWhere"];
	["Contember_VenueTimetableDaysByShiftGroupsUniqueWhere"]: GraphQLTypes["Contember_VenueTimetableDaysByShiftGroupsUniqueWhere"];
	["Contember_VenueTimetableDaysByNotesUniqueWhere"]: GraphQLTypes["Contember_VenueTimetableDaysByNotesUniqueWhere"];
	["Contember_TimetableDayNoteUniqueWhere"]: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"];
	["Contember_VenueTimetableDaysByMetaUniqueWhere"]: GraphQLTypes["Contember_VenueTimetableDaysByMetaUniqueWhere"];
	["Contember_TimetableDayMetadataUniqueWhere"]: GraphQLTypes["Contember_TimetableDayMetadataUniqueWhere"];
	["Contember_TimetableDayUniqueWhere"]: GraphQLTypes["Contember_TimetableDayUniqueWhere"];
	["Contember_VenueUniqueWhere"]: GraphQLTypes["Contember_VenueUniqueWhere"];
	["Contember_DeadlineTemplateUniqueWhere"]: GraphQLTypes["Contember_DeadlineTemplateUniqueWhere"];
	["Contember_TimetableTemplateUniqueWhere"]: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"];
	["Contember_VenueEmployeeRoleUniqueWhere"]: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"];
	["Contember_TimetableEmployeeRoleUniqueWhere"]: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"];
	["Contember_TimetablesEmployeePrivilegesUniqueWhere"]: GraphQLTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"];
	["Contember_PointOfSaleTerminalUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"];
	["Contember_PointOfSalePrinterUniqueWhere"]: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"];
	["Contember_AreaUniqueWhere"]: GraphQLTypes["Contember_AreaUniqueWhere"];
	["Contember_TableUniqueWhere"]: GraphQLTypes["Contember_TableUniqueWhere"];
	["Contember_LineUniqueWhere"]: GraphQLTypes["Contember_LineUniqueWhere"];
	["Contember_ChannelUniqueWhere"]: GraphQLTypes["Contember_ChannelUniqueWhere"];
	["Contember_ChannelPaymentUniqueWhere"]: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"];
	["Contember_VenueTimetableTemplatesByStaffOptionsUniqueWhere"]: GraphQLTypes["Contember_VenueTimetableTemplatesByStaffOptionsUniqueWhere"];
	["Contember_VenueEmployeeRolesByRoleUniqueWhere"]: GraphQLTypes["Contember_VenueEmployeeRolesByRoleUniqueWhere"];
	["Contember_VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"]: GraphQLTypes["Contember_VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"];
	["Contember_VenuePointOfSaleApplicationDevicesByIdentifierUniqueWhere"]: GraphQLTypes["Contember_VenuePointOfSaleApplicationDevicesByIdentifierUniqueWhere"];
	["Contember_VenuePointOfSaleTerminalsByApplicationDevicesUniqueWhere"]: GraphQLTypes["Contember_VenuePointOfSaleTerminalsByApplicationDevicesUniqueWhere"];
	["Contember_VenuePointOfSalePrintersByApplicationDevicesReceiptUniqueWhere"]: GraphQLTypes["Contember_VenuePointOfSalePrintersByApplicationDevicesReceiptUniqueWhere"];
	["Contember_VenueOrdersBySeqUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersBySeqUniqueWhere"];
	["Contember_VenueOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByDoubleshotLegacyIdUniqueWhere"];
	["Contember_VenueOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["Contember_VenueOrdersByDiscountsUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByDiscountsUniqueWhere"];
	["Contember_VenueOrdersByDeliveryUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByDeliveryUniqueWhere"];
	["Contember_VenueOrdersByItemsUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByItemsUniqueWhere"];
	["Contember_VenueOrdersByCartUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByCartUniqueWhere"];
	["Contember_VenueOrdersByEventsUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByEventsUniqueWhere"];
	["Contember_VenueOrdersByPriceLinesUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByPriceLinesUniqueWhere"];
	["Contember_VenueOrdersByPaymentsUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByPaymentsUniqueWhere"];
	["Contember_VenueOrdersBySummaryUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersBySummaryUniqueWhere"];
	["Contember_VenueOrdersByGratuityUniqueWhere"]: GraphQLTypes["Contember_VenueOrdersByGratuityUniqueWhere"];
	["Contember_VenueAreasByTablesUniqueWhere"]: GraphQLTypes["Contember_VenueAreasByTablesUniqueWhere"];
	["Contember_VenueAreasByLinesUniqueWhere"]: GraphQLTypes["Contember_VenueAreasByLinesUniqueWhere"];
	["Contember_VenueCategoriesBySlugUniqueWhere"]: GraphQLTypes["Contember_VenueCategoriesBySlugUniqueWhere"];
	["Contember_VenueCategoriesByLocalesUniqueWhere"]: GraphQLTypes["Contember_VenueCategoriesByLocalesUniqueWhere"];
	["Contember_VenueCategoriesByProductsUniqueWhere"]: GraphQLTypes["Contember_VenueCategoriesByProductsUniqueWhere"];
	["Contember_VenueChannelsByCodeUniqueWhere"]: GraphQLTypes["Contember_VenueChannelsByCodeUniqueWhere"];
	["Contember_VenueChannelsByPaymentsUniqueWhere"]: GraphQLTypes["Contember_VenueChannelsByPaymentsUniqueWhere"];
	["Contember_VenueChannelsByDeliveriesUniqueWhere"]: GraphQLTypes["Contember_VenueChannelsByDeliveriesUniqueWhere"];
	["Contember_VenueChannelsByCartsUniqueWhere"]: GraphQLTypes["Contember_VenueChannelsByCartsUniqueWhere"];
	["Contember_TimetableDayConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableDayEdge"]>
};
	["Contember_TimetableDayEdge"]: {
		node: GraphQLTypes["Contember_TimetableDay"]
};
	["Contember_StaffConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_StaffEdge"]>
};
	["Contember_StaffEdge"]: {
		node: GraphQLTypes["Contember_Staff"]
};
	["Contember_VenueEmployeeRoleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VenueEmployeeRoleEdge"]>
};
	["Contember_VenueEmployeeRoleEdge"]: {
		node: GraphQLTypes["Contember_VenueEmployeeRole"]
};
	["Contember_PointOfSaleTerminalConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleTerminalEdge"]>
};
	["Contember_PointOfSaleTerminalEdge"]: {
		node: GraphQLTypes["Contember_PointOfSaleTerminal"]
};
	["Contember_AreaConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AreaEdge"]>
};
	["Contember_AreaEdge"]: {
		node: GraphQLTypes["Contember_Area"]
};
	["Contember_UnassignReasonConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_UnassignReasonEdge"]>
};
	["Contember_UnassignReasonEdge"]: {
		node: GraphQLTypes["Contember_UnassignReason"]
};
	["Contember_CartItemOrderBy"]: GraphQLTypes["Contember_CartItemOrderBy"];
	["Contember_CartItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CartItemEdge"]>
};
	["Contember_CartItemEdge"]: {
		node: GraphQLTypes["Contember_CartItem"]
};
	["Contember_CartPriceLine"]: {
		_meta?: GraphQLTypes["Contember_CartPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_CartPriceLineType"] | undefined,
	item?: GraphQLTypes["Contember_CartItem"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	unitPriceCents: number,
	priceCents: number,
	quantity: number,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	cart?: GraphQLTypes["Contember_Cart"] | undefined
};
	["Contember_CartPriceLineMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	item?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CartPriceLineOrderBy"]: GraphQLTypes["Contember_CartPriceLineOrderBy"];
	["Contember_CartSummary"]: {
		_meta?: GraphQLTypes["Contember_CartSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	itemsPriceCents: number,
	deliveryPriceCents: number,
	totalPriceCents: number,
	itemsCount: number,
	itemsQuantity: number,
	checkoutUrl: string,
	cart?: GraphQLTypes["Contember_Cart"] | undefined
};
	["Contember_CartSummaryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsCount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	checkoutUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CartItemsByProductUniqueWhere"]: GraphQLTypes["Contember_CartItemsByProductUniqueWhere"];
	["Contember_CartPriceLineConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CartPriceLineEdge"]>
};
	["Contember_CartPriceLineEdge"]: {
		node: GraphQLTypes["Contember_CartPriceLine"]
};
	["Contember_ChannelCartsByItemsUniqueWhere"]: GraphQLTypes["Contember_ChannelCartsByItemsUniqueWhere"];
	["Contember_ChannelCartsByOrderUniqueWhere"]: GraphQLTypes["Contember_ChannelCartsByOrderUniqueWhere"];
	["Contember_ChannelCartsByPriceLinesUniqueWhere"]: GraphQLTypes["Contember_ChannelCartsByPriceLinesUniqueWhere"];
	["Contember_ChannelCartsBySummaryUniqueWhere"]: GraphQLTypes["Contember_ChannelCartsBySummaryUniqueWhere"];
	["ChannelPaymentConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["ChannelPaymentEdge"]>
};
	["ChannelPaymentEdge"]: {
		node: GraphQLTypes["ChannelPayment"]
};
	["ChannelDeliveryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["ChannelDeliveryEdge"]>
};
	["ChannelDeliveryEdge"]: {
		node: GraphQLTypes["ChannelDelivery"]
};
	["Contember_PointOfSaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleEdge"]>
};
	["Contember_PointOfSaleEdge"]: {
		node: GraphQLTypes["Contember_PointOfSale"]
};
	["Contember_CartConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CartEdge"]>
};
	["Contember_CartEdge"]: {
		node: GraphQLTypes["Contember_Cart"]
};
	["Contember_CustomerConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerEdge"]>
};
	["Contember_CustomerEdge"]: {
		node: GraphQLTypes["Contember_Customer"]
};
	["Contember_LiftagoRide"]: {
		_meta?: GraphQLTypes["Contember_LiftagoRideMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	liftagoId?: string | undefined,
	data?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined
};
	["Contember_LiftagoRideMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	liftagoId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryRouteWaypointsByDeliveryUniqueWhere"]: GraphQLTypes["Contember_DeliveryRouteWaypointsByDeliveryUniqueWhere"];
	["Contember_DeliveryWaypointConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryWaypointEdge"]>
};
	["Contember_DeliveryWaypointEdge"]: {
		node: GraphQLTypes["Contember_DeliveryWaypoint"]
};
	["Contember_CourierRoutesByWaypointsUniqueWhere"]: GraphQLTypes["Contember_CourierRoutesByWaypointsUniqueWhere"];
	["Contember_DeliveryRouteConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryRouteEdge"]>
};
	["Contember_DeliveryRouteEdge"]: {
		node: GraphQLTypes["Contember_DeliveryRoute"]
};
	["DeliveryMethodConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
		node: GraphQLTypes["DeliveryMethod"]
};
	["Contember_Cleaner"]: {
		_meta?: GraphQLTypes["Contember_CleanerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	canReachTopShelf: boolean,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_CleanerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canReachTopShelf?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_StocksManager"]: {
		_meta?: GraphQLTypes["Contember_StocksManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	managedProducts: Array<GraphQLTypes["Product"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	managedProductsByCode?: GraphQLTypes["Product"] | undefined,
	managedProductsByStocks?: GraphQLTypes["Product"] | undefined,
	managedProductsByGroupPrices?: GraphQLTypes["Product"] | undefined,
	managedProductsByCartItems?: GraphQLTypes["Product"] | undefined,
	managedProductsByOrderItems?: GraphQLTypes["Product"] | undefined,
	managedProductsByInheritedCategories?: GraphQLTypes["Product"] | undefined,
	managedProductsByLocales?: GraphQLTypes["Product"] | undefined,
	managedProductsByCustomerPrices?: GraphQLTypes["Product"] | undefined,
	managedProductsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	managedProductsByMeta?: GraphQLTypes["Product"] | undefined,
	managedProductsByPage?: GraphQLTypes["Product"] | undefined,
	managedProductsBySalesDays?: GraphQLTypes["Product"] | undefined,
	managedProductsBySalesWeeks?: GraphQLTypes["Product"] | undefined,
	managedProductsBySalesMonths?: GraphQLTypes["Product"] | undefined,
	paginateManagedProducts: GraphQLTypes["ProductConnection"]
};
	["Contember_StocksManagerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	managedProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_StocksManagerManagedProductsByCodeUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByCodeUniqueWhere"];
	["Contember_StocksManagerManagedProductsByStocksUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByStocksUniqueWhere"];
	["Contember_StocksManagerManagedProductsByGroupPricesUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByGroupPricesUniqueWhere"];
	["Contember_StocksManagerManagedProductsByCartItemsUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByCartItemsUniqueWhere"];
	["Contember_StocksManagerManagedProductsByOrderItemsUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByOrderItemsUniqueWhere"];
	["Contember_StocksManagerManagedProductsByInheritedCategoriesUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByInheritedCategoriesUniqueWhere"];
	["Contember_StocksManagerManagedProductsByLocalesUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByLocalesUniqueWhere"];
	["Contember_StocksManagerManagedProductsByCustomerPricesUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByCustomerPricesUniqueWhere"];
	["Contember_StocksManagerManagedProductsByVirtualProductUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByVirtualProductUniqueWhere"];
	["Contember_StocksManagerManagedProductsByMetaUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByMetaUniqueWhere"];
	["Contember_StocksManagerManagedProductsByPageUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsByPageUniqueWhere"];
	["Contember_StocksManagerManagedProductsBySalesDaysUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsBySalesDaysUniqueWhere"];
	["Contember_StocksManagerManagedProductsBySalesWeeksUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsBySalesWeeksUniqueWhere"];
	["Contember_StocksManagerManagedProductsBySalesMonthsUniqueWhere"]: GraphQLTypes["Contember_StocksManagerManagedProductsBySalesMonthsUniqueWhere"];
	["Contember_CourierManager"]: {
		_meta?: GraphQLTypes["Contember_CourierManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_CourierManagerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Picker"]: {
		_meta?: GraphQLTypes["Contember_PickerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_PickerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_RemunerationProfile"]: {
		_meta?: GraphQLTypes["Contember_RemunerationProfileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	employmentInformation: Array<GraphQLTypes["Contember_EmploymentInformation"]>,
	workMonths?: GraphQLTypes["Contember_WorkMonth"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	paginateEmploymentInformation: GraphQLTypes["Contember_EmploymentInformationConnection"]
};
	["Contember_RemunerationProfileMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employmentInformation?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workMonths?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmploymentInformation"]: {
		_meta?: GraphQLTypes["Contember_EmploymentInformationMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	hourlyRate?: number | undefined,
	remunerationProfiles?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	monthlySalary?: number | undefined,
	type: GraphQLTypes["Contember_EmploymentType"],
	name: string
};
	["Contember_EmploymentInformationMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hourlyRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remunerationProfiles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	monthlySalary?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmploymentInformationOrderBy"]: GraphQLTypes["Contember_EmploymentInformationOrderBy"];
	["Contember_WorkMonth"]: {
		_meta?: GraphQLTypes["Contember_WorkMonthMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	year?: number | undefined,
	month?: number | undefined,
	hoursWorked?: number | undefined,
	billableHours?: number | undefined,
	remunerationProfile: Array<GraphQLTypes["Contember_RemunerationProfile"]>,
	remunerationProfileByEmploymentInformation?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	remunerationProfileByStaff?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	paginateRemunerationProfile: GraphQLTypes["Contember_RemunerationProfileConnection"]
};
	["Contember_WorkMonthMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	year?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	month?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hoursWorked?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billableHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WorkMonthRemunerationProfileByEmploymentInformationUniqueWhere"]: GraphQLTypes["Contember_WorkMonthRemunerationProfileByEmploymentInformationUniqueWhere"];
	["Contember_WorkMonthRemunerationProfileByStaffUniqueWhere"]: GraphQLTypes["Contember_WorkMonthRemunerationProfileByStaffUniqueWhere"];
	["Contember_RemunerationProfileConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_RemunerationProfileEdge"]>
};
	["Contember_RemunerationProfileEdge"]: {
		node: GraphQLTypes["Contember_RemunerationProfile"]
};
	["Contember_EmploymentInformationConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_EmploymentInformationEdge"]>
};
	["Contember_EmploymentInformationEdge"]: {
		node: GraphQLTypes["Contember_EmploymentInformation"]
};
	["Contember_Cashier"]: {
		_meta?: GraphQLTypes["Contember_CashierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_CashierMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_InternalBuyer"]: {
		_meta?: GraphQLTypes["Contember_InternalBuyerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_InternalBuyerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SalesManager"]: {
		_meta?: GraphQLTypes["Contember_SalesManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	countries: Array<GraphQLTypes["Contember_Country"]>,
	paginateCountries: GraphQLTypes["Contember_CountryConnection"]
};
	["Contember_SalesManagerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	countries?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CountryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CountryEdge"]>
};
	["Contember_CountryEdge"]: {
		node: GraphQLTypes["Contember_Country"]
};
	["Contember_CashierManager"]: {
		_meta?: GraphQLTypes["Contember_CashierManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_CashierManagerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessToBusinessManagerManagedBusinessesByCustomerUniqueWhere"]: GraphQLTypes["Contember_BusinessToBusinessManagerManagedBusinessesByCustomerUniqueWhere"];
	["Contember_BusinessToBusinessManagerManagedBusinessesByBusinessServiceEventsUniqueWhere"]: GraphQLTypes["Contember_BusinessToBusinessManagerManagedBusinessesByBusinessServiceEventsUniqueWhere"];
	["Contember_BusinessToBusinessManagerManagedBusinessesByDoubleshotCoffeeEquipmentUniqueWhere"]: GraphQLTypes["Contember_BusinessToBusinessManagerManagedBusinessesByDoubleshotCoffeeEquipmentUniqueWhere"];
	["Contember_BusinessCustomerConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessCustomerEdge"]>
};
	["Contember_BusinessCustomerEdge"]: {
		node: GraphQLTypes["Contember_BusinessCustomer"]
};
	["Contember_BusinessServiceEvent"]: {
		_meta?: GraphQLTypes["Contember_BusinessServiceEventMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	businessCustomer?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	businessService?: GraphQLTypes["Contember_BusinessService"] | undefined,
	filledNumberOfPeople?: number | undefined,
	filledInvoiced?: boolean | undefined,
	filledEquipment?: string | undefined,
	filledMillTamDem?: boolean | undefined,
	filledSOEspresso?: boolean | undefined,
	filledFilterCoffeeInMenu?: boolean | undefined,
	filledWaterMeterStatus?: string | undefined,
	filledServiceAction?: string | undefined,
	filledInvoiceIdentifier?: string | undefined,
	filledNote?: string | undefined,
	filledSupervisor?: GraphQLTypes["Contember_Staff"] | undefined,
	filledContact?: string | undefined,
	filledPhone?: boolean | undefined,
	filledEmail?: boolean | undefined,
	filledGrindingStonesCondition?: string | undefined,
	createdBy?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_BusinessServiceEventMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessService?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledNumberOfPeople?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledInvoiced?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledMillTamDem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledSOEspresso?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledFilterCoffeeInMenu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledWaterMeterStatus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledInvoiceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledSupervisor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledContact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledPhone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledGrindingStonesCondition?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdBy?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessService"]: {
		_meta?: GraphQLTypes["Contember_BusinessServiceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	requireNote?: number | undefined,
	locales: Array<GraphQLTypes["Contember_BusinessServiceLocale"]>,
	requireNumberOfPeople?: number | undefined,
	requireInvoiced?: number | undefined,
	requireEquipment?: number | undefined,
	requireMillTamDem?: number | undefined,
	requireSOEspresso?: number | undefined,
	requireFilterCoffeeInMenu?: number | undefined,
	requireWaterMeterStatus?: number | undefined,
	requireServiceAction?: number | undefined,
	optionsEquipment?: string | undefined,
	optionsServiceAction?: string | undefined,
	requireInvoiceIdentifier?: number | undefined,
	requireSupervisor?: number | undefined,
	requireContact?: number | undefined,
	requirePhone?: number | undefined,
	requireEmail?: number | undefined,
	requireGrindingStonesCondition?: number | undefined,
	localesByLocale?: GraphQLTypes["Contember_BusinessServiceLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BusinessServiceLocaleConnection"]
};
	["Contember_BusinessServiceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireNumberOfPeople?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireInvoiced?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireMillTamDem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireSOEspresso?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireFilterCoffeeInMenu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireWaterMeterStatus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	optionsEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	optionsServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireInvoiceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireSupervisor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireContact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requirePhone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireGrindingStonesCondition?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessServiceLocale"]: {
		_meta?: GraphQLTypes["Contember_BusinessServiceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	labelNote?: string | undefined,
	root?: GraphQLTypes["Contember_BusinessService"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	labelNumberOfPeople?: string | undefined,
	labelInvoiced?: string | undefined,
	labelEquipment?: string | undefined,
	labelMillTamDem?: string | undefined,
	labelSOEspresso?: string | undefined,
	labelFilterCoffeeInMenu?: string | undefined,
	labelWaterMeterStatus?: string | undefined,
	labelServiceAction?: string | undefined,
	labelInvoiceIdentifier?: string | undefined,
	labelSupervisor?: string | undefined,
	labelContact?: string | undefined,
	labelPhone?: string | undefined,
	labelEmail?: string | undefined,
	labelGrindingStonesCondition?: string | undefined
};
	["Contember_BusinessServiceLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelNumberOfPeople?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelInvoiced?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelMillTamDem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelSOEspresso?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelFilterCoffeeInMenu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelWaterMeterStatus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelInvoiceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelSupervisor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelContact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelPhone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelGrindingStonesCondition?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessServiceLocaleOrderBy"]: GraphQLTypes["Contember_BusinessServiceLocaleOrderBy"];
	["Contember_BusinessServiceOrderBy"]: GraphQLTypes["Contember_BusinessServiceOrderBy"];
	["Contember_BusinessServiceLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_BusinessServiceLocalesByLocaleUniqueWhere"];
	["Contember_BusinessServiceLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessServiceLocaleEdge"]>
};
	["Contember_BusinessServiceLocaleEdge"]: {
		node: GraphQLTypes["Contember_BusinessServiceLocale"]
};
	["Contember_BusinessServiceEventOrderBy"]: GraphQLTypes["Contember_BusinessServiceEventOrderBy"];
	["Contember_DoubleshotCoffeeEquipment"]: {
		_meta?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	espressoMachine?: string | undefined,
	espressoGrinder?: string | undefined,
	batchBrew?: string | undefined,
	batchGrinder?: string | undefined,
	waterFilter?: string | undefined,
	mechanicalFilter?: string | undefined
};
	["Contember_DoubleshotCoffeeEquipmentMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	espressoMachine?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	espressoGrinder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	batchBrew?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	batchGrinder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	waterFilter?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	mechanicalFilter?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessServiceEventConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessServiceEventEdge"]>
};
	["Contember_BusinessServiceEventEdge"]: {
		node: GraphQLTypes["Contember_BusinessServiceEvent"]
};
	["Contember_OrderRecurrence"]: {
		_meta?: GraphQLTypes["Contember_OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["Contember_OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["Contember_BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["Contember_OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["Contember_BusinessCategoryConnection"]
};
	["Contember_OrderRecurrenceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderRecurrenceLocale"]: {
		_meta?: GraphQLTypes["Contember_OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_OrderRecurrenceLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderRecurrenceLocaleOrderBy"]: GraphQLTypes["Contember_OrderRecurrenceLocaleOrderBy"];
	["Contember_OrderRecurrenceOrderBy"]: GraphQLTypes["Contember_OrderRecurrenceOrderBy"];
	["Contember_OrderRecurrenceLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_OrderRecurrenceLocalesByLocaleUniqueWhere"];
	["Contember_OrderRecurrenceLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_OrderRecurrenceLocaleEdge"]>
};
	["Contember_OrderRecurrenceLocaleEdge"]: {
		node: GraphQLTypes["Contember_OrderRecurrenceLocale"]
};
	["Contember_BusinessCategoryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessCategoryEdge"]>
};
	["Contember_BusinessCategoryEdge"]: {
		node: GraphQLTypes["Contember_BusinessCategory"]
};
	["Contember_ProductList"]: {
		_meta?: GraphQLTypes["Contember_ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ProductListItem"]>,
	businessCategory?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["Contember_ProductListItemConnection"]
};
	["Contember_ProductListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductListItem"]: {
		_meta?: GraphQLTypes["Contember_ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["Contember_ProductListItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductListItemOrderBy"]: GraphQLTypes["Contember_ProductListItemOrderBy"];
	["Contember_ProductListItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductListItemEdge"]>
};
	["Contember_ProductListItemEdge"]: {
		node: GraphQLTypes["Contember_ProductListItem"]
};
	["Contember_ProductCategoryList"]: {
		_meta?: GraphQLTypes["Contember_ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["Contember_ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["Contember_ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductCategoryListLocaleConnection"]
};
	["Contember_ProductCategoryListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryListItem"]: {
		_meta?: GraphQLTypes["Contember_ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["Contember_ProductCategoryListItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryListItemOrderBy"]: GraphQLTypes["Contember_ProductCategoryListItemOrderBy"];
	["Contember_ProductCategoryListLocale"]: {
		_meta?: GraphQLTypes["Contember_ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductCategoryListLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryListLocaleOrderBy"]: GraphQLTypes["Contember_ProductCategoryListLocaleOrderBy"];
	["Contember_ProductCategoryListLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductCategoryListLocalesByLocaleUniqueWhere"];
	["Contember_ProductCategoryListItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryListItemEdge"]>
};
	["Contember_ProductCategoryListItemEdge"]: {
		node: GraphQLTypes["Contember_ProductCategoryListItem"]
};
	["Contember_ProductCategoryListLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryListLocaleEdge"]>
};
	["Contember_ProductCategoryListLocaleEdge"]: {
		node: GraphQLTypes["Contember_ProductCategoryListLocale"]
};
	["Contember_BusinessCategoryLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_BusinessCategoryLocalesByLocaleUniqueWhere"];
	["Contember_BusinessCategoryBusinessCustomersByCustomerUniqueWhere"]: GraphQLTypes["Contember_BusinessCategoryBusinessCustomersByCustomerUniqueWhere"];
	["Contember_BusinessCategoryBusinessCustomersByBusinessServiceEventsUniqueWhere"]: GraphQLTypes["Contember_BusinessCategoryBusinessCustomersByBusinessServiceEventsUniqueWhere"];
	["Contember_BusinessCategoryBusinessCustomersByDoubleshotCoffeeEquipmentUniqueWhere"]: GraphQLTypes["Contember_BusinessCategoryBusinessCustomersByDoubleshotCoffeeEquipmentUniqueWhere"];
	["Contember_BusinessCategoryLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessCategoryLocaleEdge"]>
};
	["Contember_BusinessCategoryLocaleEdge"]: {
		node: GraphQLTypes["Contember_BusinessCategoryLocale"]
};
	["Contember_OrderRecurrenceConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_OrderRecurrenceEdge"]>
};
	["Contember_OrderRecurrenceEdge"]: {
		node: GraphQLTypes["Contember_OrderRecurrence"]
};
	["Contember_ProductIngredientLocale"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductIngredientLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredient"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["Contember_ProductIngredientLocale"]>,
	internalName: string,
	categories: Array<GraphQLTypes["Contember_ProductIngredientCategory"]>,
	allergens: Array<GraphQLTypes["Contember_Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	customerRatings: Array<GraphQLTypes["Contember_CustomerIngredientRating"]>,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnit"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainer"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	suppliers?: GraphQLTypes["Contember_IngredientSupplierList"] | undefined,
	incrementQuantityNumber: number,
	localesByLocale?: GraphQLTypes["Contember_ProductIngredientLocale"] | undefined,
	customerRatingsByCustomer?: GraphQLTypes["Contember_CustomerIngredientRating"] | undefined,
	paginateLocales: GraphQLTypes["Contember_ProductIngredientLocaleConnection"],
	paginateCategories: GraphQLTypes["Contember_ProductIngredientCategoryConnection"],
	paginateAllergens: GraphQLTypes["Contember_AllergenConnection"],
	paginateCustomerRatings: GraphQLTypes["Contember_CustomerIngredientRatingConnection"]
};
	["Contember_ProductIngredientMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allergens?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerRatings?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	suppliers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientLocaleOrderBy"]: GraphQLTypes["Contember_ProductIngredientLocaleOrderBy"];
	["Contember_ProductIngredientOrderBy"]: GraphQLTypes["Contember_ProductIngredientOrderBy"];
	["Contember_ProductIngredientItemQuantityUnitOrderBy"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitOrderBy"];
	["Contember_IngredientSupplierListOrderBy"]: GraphQLTypes["Contember_IngredientSupplierListOrderBy"];
	["Contember_ProductIngredientCategory"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	ingredients: Array<GraphQLTypes["Contember_ProductIngredient"]>,
	locales: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocale"]>,
	order: number,
	localesByLocale?: GraphQLTypes["Contember_ProductIngredientCategoryLocale"] | undefined,
	paginateIngredients: GraphQLTypes["Contember_ProductIngredientConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductIngredientCategoryLocaleConnection"]
};
	["Contember_ProductIngredientCategoryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientCategoryLocale"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductIngredientCategory"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleOrderBy"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleOrderBy"];
	["Contember_ProductIngredientCategoryOrderBy"]: GraphQLTypes["Contember_ProductIngredientCategoryOrderBy"];
	["Contember_ProductIngredientCategoryLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientCategoryLocalesByLocaleUniqueWhere"];
	["Contember_ProductIngredientConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientEdge"]>
};
	["Contember_ProductIngredientEdge"]: {
		node: GraphQLTypes["Contember_ProductIngredient"]
};
	["Contember_ProductIngredientCategoryLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocaleEdge"]>
};
	["Contember_ProductIngredientCategoryLocaleEdge"]: {
		node: GraphQLTypes["Contember_ProductIngredientCategoryLocale"]
};
	["Contember_Allergen"]: {
		_meta?: GraphQLTypes["Contember_AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["Contember_AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["Contember_ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["Contember_AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["Contember_ProductIngredientConnection"]
};
	["Contember_AllergenMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AllergenLocale"]: {
		_meta?: GraphQLTypes["Contember_AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Contember_Allergen"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_AllergenLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AllergenLocaleOrderBy"]: GraphQLTypes["Contember_AllergenLocaleOrderBy"];
	["Contember_AllergenOrderBy"]: GraphQLTypes["Contember_AllergenOrderBy"];
	["Contember_AllergenLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_AllergenLocalesByLocaleUniqueWhere"];
	["Contember_AllergenLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AllergenLocaleEdge"]>
};
	["Contember_AllergenLocaleEdge"]: {
		node: GraphQLTypes["Contember_AllergenLocale"]
};
	["Contember_CustomerIngredientRating"]: {
		_meta?: GraphQLTypes["Contember_CustomerIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	rating?: GraphQLTypes["Contember_ProductIngredientRating"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredient"] | undefined
};
	["Contember_CustomerIngredientRatingMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	rating?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientRating"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_ProductIngredientRatingMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerIngredientRatingOrderBy"]: GraphQLTypes["Contember_CustomerIngredientRatingOrderBy"];
	["Contember_ProductIngredientRatingOrderBy"]: GraphQLTypes["Contember_ProductIngredientRatingOrderBy"];
	["Contember_ProductIngredientItemQuantityUnit"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string
};
	["Contember_ProductIngredientItemQuantityUnitMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FreshingContainer"]: {
		_meta?: GraphQLTypes["Contember_FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	volumeMl: number,
	workspace?: GraphQLTypes["Contember_Workspace"] | undefined
};
	["Contember_FreshingContainerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workspace?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Workspace"]: {
		_meta?: GraphQLTypes["Contember_WorkspaceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	freshingContainerVolumeMl: number,
	freshingPrepareOrderByOffsetMinutes: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingContainers: Array<GraphQLTypes["Contember_FreshingContainer"]>,
	openingHours?: GraphQLTypes["Contember_WeekHours"] | undefined,
	workingHours?: GraphQLTypes["Contember_WeekHours"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_FreshingContainer"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	name: string,
	businessAddress?: string | undefined,
	migrationTest?: string | undefined,
	paginateFreshingContainers: GraphQLTypes["Contember_FreshingContainerConnection"]
};
	["Contember_WorkspaceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	migrationTest?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WeekHours"]: {
		_meta?: GraphQLTypes["Contember_WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["Contember_WeekHoursMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	mon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	wed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	thu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fri?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sat?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sun?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VirtualProduct"]: {
		_meta?: GraphQLTypes["Contember_VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["Contember_VirtualProductMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FreshingContainerConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_FreshingContainerEdge"]>
};
	["Contember_FreshingContainerEdge"]: {
		node: GraphQLTypes["Contember_FreshingContainer"]
};
	["Contember_IngredientSupplierList"]: {
		_meta?: GraphQLTypes["Contember_IngredientSupplierListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_IngredientSupplierItem"]>,
	paginateItems: GraphQLTypes["Contember_IngredientSupplierItemConnection"]
};
	["Contember_IngredientSupplierListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplierItem"]: {
		_meta?: GraphQLTypes["Contember_IngredientSupplierItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_IngredientSupplierList"] | undefined,
	supplier?: GraphQLTypes["Contember_IngredientSupplier"] | undefined
};
	["Contember_IngredientSupplierItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	supplier?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplier"]: {
		_meta?: GraphQLTypes["Contember_IngredientSupplierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["Contember_IngredientSupplierLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_IngredientSupplierLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_IngredientSupplierLocaleConnection"]
};
	["Contember_IngredientSupplierMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplierLocale"]: {
		_meta?: GraphQLTypes["Contember_IngredientSupplierLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_IngredientSupplier"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_IngredientSupplierLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplierLocaleOrderBy"]: GraphQLTypes["Contember_IngredientSupplierLocaleOrderBy"];
	["Contember_IngredientSupplierOrderBy"]: GraphQLTypes["Contember_IngredientSupplierOrderBy"];
	["Contember_IngredientSupplierLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_IngredientSupplierLocalesByLocaleUniqueWhere"];
	["Contember_IngredientSupplierLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IngredientSupplierLocaleEdge"]>
};
	["Contember_IngredientSupplierLocaleEdge"]: {
		node: GraphQLTypes["Contember_IngredientSupplierLocale"]
};
	["Contember_IngredientSupplierItemOrderBy"]: GraphQLTypes["Contember_IngredientSupplierItemOrderBy"];
	["Contember_IngredientSupplierItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IngredientSupplierItemEdge"]>
};
	["Contember_IngredientSupplierItemEdge"]: {
		node: GraphQLTypes["Contember_IngredientSupplierItem"]
};
	["Contember_ProductIngredientLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientLocalesByLocaleUniqueWhere"];
	["Contember_ProductIngredientCustomerRatingsByCustomerUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientCustomerRatingsByCustomerUniqueWhere"];
	["Contember_ProductIngredientLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientLocaleEdge"]>
};
	["Contember_ProductIngredientLocaleEdge"]: {
		node: GraphQLTypes["Contember_ProductIngredientLocale"]
};
	["Contember_ProductIngredientCategoryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientCategoryEdge"]>
};
	["Contember_ProductIngredientCategoryEdge"]: {
		node: GraphQLTypes["Contember_ProductIngredientCategory"]
};
	["Contember_AllergenConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AllergenEdge"]>
};
	["Contember_AllergenEdge"]: {
		node: GraphQLTypes["Contember_Allergen"]
};
	["Contember_CustomerIngredientRatingConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerIngredientRatingEdge"]>
};
	["Contember_CustomerIngredientRatingEdge"]: {
		node: GraphQLTypes["Contember_CustomerIngredientRating"]
};
	["Contember_BlogLocale"]: {
		_meta?: GraphQLTypes["Contember_BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Contember_Blog"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_BlogLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pageName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Blog"]: {
		_meta?: GraphQLTypes["Contember_BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	locales: Array<GraphQLTypes["Contember_BlogLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BlogLocaleConnection"]
};
	["Contember_BlogMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogLocaleOrderBy"]: GraphQLTypes["Contember_BlogLocaleOrderBy"];
	["Contember_BlogOrderBy"]: GraphQLTypes["Contember_BlogOrderBy"];
	["Contember_BlogLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_BlogLocalesByLocaleUniqueWhere"];
	["Contember_BlogLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BlogLocaleEdge"]>
};
	["Contember_BlogLocaleEdge"]: {
		node: GraphQLTypes["Contember_BlogLocale"]
};
	["Contember_BlogPostLocale"]: {
		_meta?: GraphQLTypes["Contember_BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPost"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	cover?: GraphQLTypes["Contember_Cover"] | undefined,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	seo?: GraphQLTypes["Contember_Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["Contember_BlogPostLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cover?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPost"]: {
		_meta?: GraphQLTypes["Contember_BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["Contember_BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BlogPostLocaleConnection"]
};
	["Contember_BlogPostMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostLocaleOrderBy"]: GraphQLTypes["Contember_BlogPostLocaleOrderBy"];
	["Contember_BlogPostOrderBy"]: GraphQLTypes["Contember_BlogPostOrderBy"];
	["Contember_CoverOrderBy"]: GraphQLTypes["Contember_CoverOrderBy"];
	["Contember_MediumOrderBy"]: GraphQLTypes["Contember_MediumOrderBy"];
	["Contember_VideoOrderBy"]: GraphQLTypes["Contember_VideoOrderBy"];
	["Contember_ColorPalleteOrderBy"]: GraphQLTypes["Contember_ColorPalleteOrderBy"];
	["Contember_ContentOrderBy"]: GraphQLTypes["Contember_ContentOrderBy"];
	["Contember_LinkableOrderBy"]: GraphQLTypes["Contember_LinkableOrderBy"];
	["Contember_PageLocaleOrderBy"]: GraphQLTypes["Contember_PageLocaleOrderBy"];
	["Contember_SeoOrderBy"]: GraphQLTypes["Contember_SeoOrderBy"];
	["Contember_WikiPageOrderBy"]: GraphQLTypes["Contember_WikiPageOrderBy"];
	["Contember_RedirectOrderBy"]: GraphQLTypes["Contember_RedirectOrderBy"];
	["Contember_LinkOrderBy"]: GraphQLTypes["Contember_LinkOrderBy"];
	["Contember_BlogPostLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_BlogPostLocalesByLocaleUniqueWhere"];
	["Contember_BlogPostLocalesByCoverUniqueWhere"]: GraphQLTypes["Contember_BlogPostLocalesByCoverUniqueWhere"];
	["Contember_BlogPostLocalesByContentUniqueWhere"]: GraphQLTypes["Contember_BlogPostLocalesByContentUniqueWhere"];
	["Contember_BlogPostLocalesByLinkUniqueWhere"]: GraphQLTypes["Contember_BlogPostLocalesByLinkUniqueWhere"];
	["Contember_BlogPostLocalesBySeoUniqueWhere"]: GraphQLTypes["Contember_BlogPostLocalesBySeoUniqueWhere"];
	["Contember_BlogPostLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BlogPostLocaleEdge"]>
};
	["Contember_BlogPostLocaleEdge"]: {
		node: GraphQLTypes["Contember_BlogPostLocale"]
};
	["Contember_Cover"]: {
		_meta?: GraphQLTypes["Contember_CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	buttonColors?: GraphQLTypes["Contember_ColorPallete"] | undefined
};
	["Contember_CoverMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Medium"]: {
		_meta?: GraphQLTypes["Contember_MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_MediumType"] | undefined,
	colorTheme?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	video?: GraphQLTypes["Contember_Video"] | undefined
};
	["Contember_MediumMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	video?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Video"]: {
		_meta?: GraphQLTypes["Contember_VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_VideoMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	src?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	width?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	height?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	size?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	duration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	poster?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ColorPallete"]: {
		_meta?: GraphQLTypes["Contember_ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["Contember_ColorPalleteMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	background?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Content"]: {
		_meta?: GraphQLTypes["Contember_ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["Contember_ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["Contember_ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["Contember_ContentBlockConnection"]
};
	["Contember_ContentMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blocks?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ContentBlock"]: {
		_meta?: GraphQLTypes["Contember_ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	references: Array<GraphQLTypes["Contember_ContentReference"]>,
	referencesByProducts?: GraphQLTypes["Contember_ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["Contember_ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["Contember_ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["Contember_ContentReferenceConnection"]
};
	["Contember_ContentBlockMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	json?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	references?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ContentReference"]: {
		_meta?: GraphQLTypes["Contember_ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["Contember_ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["Contember_ColorPallete"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	blogPosts?: GraphQLTypes["Contember_BlogPostList"] | undefined,
	links?: GraphQLTypes["Contember_LinkList"] | undefined,
	products?: GraphQLTypes["Contember_ProductList"] | undefined,
	contentSize?: GraphQLTypes["Contember_ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Contember_Hero"] | undefined,
	gallery?: GraphQLTypes["Contember_Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["Contember_ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["Contember_ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegions"] | undefined,
	textList?: GraphQLTypes["Contember_TextList"] | undefined,
	gridTiles?: GraphQLTypes["Contember_GridTiles"] | undefined
};
	["Contember_ContentReferenceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	block?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	links?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	misc?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hero?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gallery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	textList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gridTiles?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Link"]: {
		_meta?: GraphQLTypes["Contember_LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Contember_Linkable"] | undefined
};
	["Contember_LinkMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Linkable"]: {
		_meta?: GraphQLTypes["Contember_LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	page?: GraphQLTypes["Contember_PageLocale"] | undefined,
	wikiPage?: GraphQLTypes["Contember_WikiPage"] | undefined,
	redirect?: GraphQLTypes["Contember_Redirect"] | undefined
};
	["Contember_LinkableMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	url?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	page?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	wikiPage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	redirect?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PageLocale"]: {
		_meta?: GraphQLTypes["Contember_PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	root?: GraphQLTypes["Contember_Page"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	seo?: GraphQLTypes["Contember_Seo"] | undefined,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	cover?: GraphQLTypes["Contember_Cover"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined
};
	["Contember_PageLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cover?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	theme?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Page"]: {
		_meta?: GraphQLTypes["Contember_PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["Contember_PageLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["Contember_PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_PageLocaleConnection"]
};
	["Contember_PageMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PageLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_PageLocalesByLocaleUniqueWhere"];
	["Contember_PageLocalesByLinkUniqueWhere"]: GraphQLTypes["Contember_PageLocalesByLinkUniqueWhere"];
	["Contember_PageLocalesBySeoUniqueWhere"]: GraphQLTypes["Contember_PageLocalesBySeoUniqueWhere"];
	["Contember_PageLocalesByContentUniqueWhere"]: GraphQLTypes["Contember_PageLocalesByContentUniqueWhere"];
	["Contember_PageLocalesByCoverUniqueWhere"]: GraphQLTypes["Contember_PageLocalesByCoverUniqueWhere"];
	["Contember_PageLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PageLocaleEdge"]>
};
	["Contember_PageLocaleEdge"]: {
		node: GraphQLTypes["Contember_PageLocale"]
};
	["Contember_Seo"]: {
		_meta?: GraphQLTypes["Contember_SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_SeoMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WikiPage"]: {
		_meta?: GraphQLTypes["Contember_WikiPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	parent?: GraphQLTypes["Contember_WikiPage"] | undefined,
	children: Array<GraphQLTypes["Contember_WikiPage"]>,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	childrenByLink?: GraphQLTypes["Contember_WikiPage"] | undefined,
	childrenByChildren?: GraphQLTypes["Contember_WikiPage"] | undefined,
	childrenByContent?: GraphQLTypes["Contember_WikiPage"] | undefined,
	paginateChildren: GraphQLTypes["Contember_WikiPageConnection"]
};
	["Contember_WikiPageMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	emoji?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	parent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	children?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WikiPageChildrenByLinkUniqueWhere"]: GraphQLTypes["Contember_WikiPageChildrenByLinkUniqueWhere"];
	["Contember_WikiPageChildrenByChildrenUniqueWhere"]: GraphQLTypes["Contember_WikiPageChildrenByChildrenUniqueWhere"];
	["Contember_WikiPageChildrenByContentUniqueWhere"]: GraphQLTypes["Contember_WikiPageChildrenByContentUniqueWhere"];
	["Contember_WikiPageConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_WikiPageEdge"]>
};
	["Contember_WikiPageEdge"]: {
		node: GraphQLTypes["Contember_WikiPage"]
};
	["Contember_Redirect"]: {
		_meta?: GraphQLTypes["Contember_RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	target?: GraphQLTypes["Contember_Link"] | undefined
};
	["Contember_RedirectMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	target?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostList"]: {
		_meta?: GraphQLTypes["Contember_BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_BlogPostListItem"]>,
	paginateItems: GraphQLTypes["Contember_BlogPostListItemConnection"]
};
	["Contember_BlogPostListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostListItem"]: {
		_meta?: GraphQLTypes["Contember_BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Contember_BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostLocale"] | undefined
};
	["Contember_BlogPostListItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	caption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostListItemOrderBy"]: GraphQLTypes["Contember_BlogPostListItemOrderBy"];
	["Contember_BlogPostListOrderBy"]: GraphQLTypes["Contember_BlogPostListOrderBy"];
	["Contember_BlogPostListItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BlogPostListItemEdge"]>
};
	["Contember_BlogPostListItemEdge"]: {
		node: GraphQLTypes["Contember_BlogPostListItem"]
};
	["Contember_LinkList"]: {
		_meta?: GraphQLTypes["Contember_LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_LinkListItem"]>,
	paginateItems: GraphQLTypes["Contember_LinkListItemConnection"]
};
	["Contember_LinkListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LinkListItem"]: {
		_meta?: GraphQLTypes["Contember_LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["Contember_LinkList"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined
};
	["Contember_LinkListItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LinkListItemOrderBy"]: GraphQLTypes["Contember_LinkListItemOrderBy"];
	["Contember_LinkListOrderBy"]: GraphQLTypes["Contember_LinkListOrderBy"];
	["Contember_LinkListItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_LinkListItemEdge"]>
};
	["Contember_LinkListItemEdge"]: {
		node: GraphQLTypes["Contember_LinkListItem"]
};
	["Contember_Hero"]: {
		_meta?: GraphQLTypes["Contember_HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined
};
	["Contember_HeroMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Gallery"]: {
		_meta?: GraphQLTypes["Contember_GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_GalleryItem"]>,
	paginateItems: GraphQLTypes["Contember_GalleryItemConnection"]
};
	["Contember_GalleryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GalleryItem"]: {
		_meta?: GraphQLTypes["Contember_GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Contember_Gallery"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined
};
	["Contember_GalleryItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	caption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GalleryItemOrderBy"]: GraphQLTypes["Contember_GalleryItemOrderBy"];
	["Contember_GalleryOrderBy"]: GraphQLTypes["Contember_GalleryOrderBy"];
	["Contember_GalleryItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_GalleryItemEdge"]>
};
	["Contember_GalleryItemEdge"]: {
		node: GraphQLTypes["Contember_GalleryItem"]
};
	["Contember_SocialsAndApps"]: {
		_meta?: GraphQLTypes["Contember_SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["Contember_SocialsAndAppsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	instagram?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	appStore?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGrid"]: {
		_meta?: GraphQLTypes["Contember_ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["Contember_ProductGridCategory"]>,
	type?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["Contember_ProductGridCategory"] | undefined,
	categoriesByImages?: GraphQLTypes["Contember_ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["Contember_ProductGridCategoryConnection"]
};
	["Contember_ProductGridMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGridCategory"]: {
		_meta?: GraphQLTypes["Contember_ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["Contember_ProductGrid"] | undefined,
	items: Array<GraphQLTypes["Contember_ProductGridItem"]>,
	images?: GraphQLTypes["Contember_ImageList"] | undefined,
	paginateItems: GraphQLTypes["Contember_ProductGridItemConnection"]
};
	["Contember_ProductGridCategoryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	images?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGridItem"]: {
		_meta?: GraphQLTypes["Contember_ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	category?: GraphQLTypes["Contember_ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["Contember_ProductGridItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGridItemOrderBy"]: GraphQLTypes["Contember_ProductGridItemOrderBy"];
	["Contember_ProductGridCategoryOrderBy"]: GraphQLTypes["Contember_ProductGridCategoryOrderBy"];
	["Contember_ProductGridOrderBy"]: GraphQLTypes["Contember_ProductGridOrderBy"];
	["Contember_ImageListOrderBy"]: GraphQLTypes["Contember_ImageListOrderBy"];
	["Contember_ImageList"]: {
		_meta?: GraphQLTypes["Contember_ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ImageListItem"]>,
	itemsByImage?: GraphQLTypes["Contember_ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_ImageListItemConnection"]
};
	["Contember_ImageListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ImageListItem"]: {
		_meta?: GraphQLTypes["Contember_ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ImageList"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_ImageListItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ImageListItemOrderBy"]: GraphQLTypes["Contember_ImageListItemOrderBy"];
	["Contember_ImageListItemsByImageUniqueWhere"]: GraphQLTypes["Contember_ImageListItemsByImageUniqueWhere"];
	["Contember_ImageListItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ImageListItemEdge"]>
};
	["Contember_ImageListItemEdge"]: {
		node: GraphQLTypes["Contember_ImageListItem"]
};
	["Contember_ProductGridItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductGridItemEdge"]>
};
	["Contember_ProductGridItemEdge"]: {
		node: GraphQLTypes["Contember_ProductGridItem"]
};
	["Contember_ProductGridCategoriesByItemsUniqueWhere"]: GraphQLTypes["Contember_ProductGridCategoriesByItemsUniqueWhere"];
	["Contember_ProductGridItemUniqueWhere"]: GraphQLTypes["Contember_ProductGridItemUniqueWhere"];
	["Contember_ProductGridCategoriesByImagesUniqueWhere"]: GraphQLTypes["Contember_ProductGridCategoriesByImagesUniqueWhere"];
	["Contember_ProductGridCategoryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductGridCategoryEdge"]>
};
	["Contember_ProductGridCategoryEdge"]: {
		node: GraphQLTypes["Contember_ProductGridCategory"]
};
	["Contember_ProductBannerList"]: {
		_meta?: GraphQLTypes["Contember_ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ProductBannerItem"]>,
	paginateItems: GraphQLTypes["Contember_ProductBannerItemConnection"]
};
	["Contember_ProductBannerListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductBannerItem"]: {
		_meta?: GraphQLTypes["Contember_ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ProductBannerList"] | undefined,
	banner?: GraphQLTypes["Contember_ProductBanner"] | undefined,
	size?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined
};
	["Contember_ProductBannerItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	banner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	size?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductBanner"]: {
		_meta?: GraphQLTypes["Contember_ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Contember_Link"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_Image"] | undefined,
	productImage?: GraphQLTypes["Contember_Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductBannerMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	label?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	button?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productImage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductBannerItemOrderBy"]: GraphQLTypes["Contember_ProductBannerItemOrderBy"];
	["Contember_ProductBannerListOrderBy"]: GraphQLTypes["Contember_ProductBannerListOrderBy"];
	["Contember_ProductBannerOrderBy"]: GraphQLTypes["Contember_ProductBannerOrderBy"];
	["Contember_ProductBannerItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductBannerItemEdge"]>
};
	["Contember_ProductBannerItemEdge"]: {
		node: GraphQLTypes["Contember_ProductBannerItem"]
};
	["Contember_DeliveryRegions"]: {
		_meta?: GraphQLTypes["Contember_DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["Contember_DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["Contember_DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["Contember_DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_DeliveryRegionsItemConnection"]
};
	["Contember_DeliveryRegionsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryRegionsItem"]: {
		_meta?: GraphQLTypes["Contember_DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["Contember_DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	textList?: GraphQLTypes["Contember_TextList"] | undefined,
	gallery?: GraphQLTypes["Contember_ImageList"] | undefined
};
	["Contember_DeliveryRegionsItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	textList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gallery?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TextList"]: {
		_meta?: GraphQLTypes["Contember_TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_TextItem"]>,
	paginateItems: GraphQLTypes["Contember_TextItemConnection"]
};
	["Contember_TextListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TextItem"]: {
		_meta?: GraphQLTypes["Contember_TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["Contember_TextList"] | undefined,
	title?: string | undefined
};
	["Contember_TextItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TextItemOrderBy"]: GraphQLTypes["Contember_TextItemOrderBy"];
	["Contember_TextListOrderBy"]: GraphQLTypes["Contember_TextListOrderBy"];
	["Contember_TextItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TextItemEdge"]>
};
	["Contember_TextItemEdge"]: {
		node: GraphQLTypes["Contember_TextItem"]
};
	["Contember_DeliveryRegionsItemOrderBy"]: GraphQLTypes["Contember_DeliveryRegionsItemOrderBy"];
	["Contember_DeliveryRegionsOrderBy"]: GraphQLTypes["Contember_DeliveryRegionsOrderBy"];
	["Contember_DeliveryRegionsItemsByImageUniqueWhere"]: GraphQLTypes["Contember_DeliveryRegionsItemsByImageUniqueWhere"];
	["Contember_DeliveryRegionsItemsByTextListUniqueWhere"]: GraphQLTypes["Contember_DeliveryRegionsItemsByTextListUniqueWhere"];
	["Contember_DeliveryRegionsItemsByGalleryUniqueWhere"]: GraphQLTypes["Contember_DeliveryRegionsItemsByGalleryUniqueWhere"];
	["Contember_DeliveryRegionsItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryRegionsItemEdge"]>
};
	["Contember_DeliveryRegionsItemEdge"]: {
		node: GraphQLTypes["Contember_DeliveryRegionsItem"]
};
	["Contember_GridTiles"]: {
		_meta?: GraphQLTypes["Contember_GridTilesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_GridTileItem"]>,
	itemsByLink?: GraphQLTypes["Contember_GridTileItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_GridTileItemConnection"]
};
	["Contember_GridTilesMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GridTileItem"]: {
		_meta?: GraphQLTypes["Contember_GridTileItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["Contember_GridTiles"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined
};
	["Contember_GridTileItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GridTileItemOrderBy"]: GraphQLTypes["Contember_GridTileItemOrderBy"];
	["Contember_GridTilesOrderBy"]: GraphQLTypes["Contember_GridTilesOrderBy"];
	["Contember_GridTilesItemsByLinkUniqueWhere"]: GraphQLTypes["Contember_GridTilesItemsByLinkUniqueWhere"];
	["Contember_LinkUniqueWhere"]: GraphQLTypes["Contember_LinkUniqueWhere"];
	["Contember_GridTileItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_GridTileItemEdge"]>
};
	["Contember_GridTileItemEdge"]: {
		node: GraphQLTypes["Contember_GridTileItem"]
};
	["Contember_ContentReferenceOrderBy"]: GraphQLTypes["Contember_ContentReferenceOrderBy"];
	["Contember_ContentBlockOrderBy"]: GraphQLTypes["Contember_ContentBlockOrderBy"];
	["Contember_HeroOrderBy"]: GraphQLTypes["Contember_HeroOrderBy"];
	["Contember_SocialsAndAppsOrderBy"]: GraphQLTypes["Contember_SocialsAndAppsOrderBy"];
	["Contember_ContentBlockReferencesByProductsUniqueWhere"]: GraphQLTypes["Contember_ContentBlockReferencesByProductsUniqueWhere"];
	["Contember_ContentBlockReferencesByProductBannersUniqueWhere"]: GraphQLTypes["Contember_ContentBlockReferencesByProductBannersUniqueWhere"];
	["Contember_ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: GraphQLTypes["Contember_ContentBlockReferencesByDeliveryRegionsUniqueWhere"];
	["Contember_ContentReferenceConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ContentReferenceEdge"]>
};
	["Contember_ContentReferenceEdge"]: {
		node: GraphQLTypes["Contember_ContentReference"]
};
	["Contember_ContentBlocksByReferencesUniqueWhere"]: GraphQLTypes["Contember_ContentBlocksByReferencesUniqueWhere"];
	["Contember_ContentBlockConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ContentBlockEdge"]>
};
	["Contember_ContentBlockEdge"]: {
		node: GraphQLTypes["Contember_ContentBlock"]
};
	["Contember_TranslationCataloguesIdentifier"]: {
		_meta?: GraphQLTypes["Contember_TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["Contember_TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["Contember_TranslationCatalogueConnection"]
};
	["Contember_TranslationCataloguesIdentifierMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationCatalogue"]: {
		_meta?: GraphQLTypes["Contember_TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["Contember_TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["Contember_TranslationValue"]>,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["Contember_TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["Contember_TranslationValueConnection"]
};
	["Contember_TranslationCatalogueMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	domain?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fallback?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	values?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationDomain"]: {
		_meta?: GraphQLTypes["Contember_TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["Contember_TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["Contember_TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["Contember_TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["Contember_TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["Contember_TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["Contember_TranslationKeyConnection"]
};
	["Contember_TranslationDomainMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	keys?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationCatalogueOrderBy"]: GraphQLTypes["Contember_TranslationCatalogueOrderBy"];
	["Contember_TranslationDomainOrderBy"]: GraphQLTypes["Contember_TranslationDomainOrderBy"];
	["Contember_TranslationKey"]: {
		_meta?: GraphQLTypes["Contember_TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["Contember_TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["Contember_TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["Contember_TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["Contember_TranslationValueConnection"]
};
	["Contember_TranslationKeyMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contentType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	domain?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	values?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationValue"]: {
		_meta?: GraphQLTypes["Contember_TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["Contember_TranslationKey"] | undefined
};
	["Contember_TranslationValueMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	value?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	key?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationValueOrderBy"]: GraphQLTypes["Contember_TranslationValueOrderBy"];
	["Contember_TranslationKeyOrderBy"]: GraphQLTypes["Contember_TranslationKeyOrderBy"];
	["Contember_TranslationKeyValuesByCatalogueUniqueWhere"]: GraphQLTypes["Contember_TranslationKeyValuesByCatalogueUniqueWhere"];
	["Contember_TranslationValueConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TranslationValueEdge"]>
};
	["Contember_TranslationValueEdge"]: {
		node: GraphQLTypes["Contember_TranslationValue"]
};
	["Contember_TranslationDomainCataloguesByIdentifierUniqueWhere"]: GraphQLTypes["Contember_TranslationDomainCataloguesByIdentifierUniqueWhere"];
	["Contember_TranslationDomainCataloguesByValuesUniqueWhere"]: GraphQLTypes["Contember_TranslationDomainCataloguesByValuesUniqueWhere"];
	["Contember_TranslationDomainKeysByIdentifierUniqueWhere"]: GraphQLTypes["Contember_TranslationDomainKeysByIdentifierUniqueWhere"];
	["Contember_TranslationDomainKeysByValuesUniqueWhere"]: GraphQLTypes["Contember_TranslationDomainKeysByValuesUniqueWhere"];
	["Contember_TranslationCatalogueConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TranslationCatalogueEdge"]>
};
	["Contember_TranslationCatalogueEdge"]: {
		node: GraphQLTypes["Contember_TranslationCatalogue"]
};
	["Contember_TranslationKeyConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TranslationKeyEdge"]>
};
	["Contember_TranslationKeyEdge"]: {
		node: GraphQLTypes["Contember_TranslationKey"]
};
	["Contember_TranslationCatalogueValuesByKeyUniqueWhere"]: GraphQLTypes["Contember_TranslationCatalogueValuesByKeyUniqueWhere"];
	["Contember_TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"];
	["Contember_TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"];
	["Contember_MenuLocale"]: {
		_meta?: GraphQLTypes["Contember_MenuLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["Contember_SiteMenu"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	items: Array<GraphQLTypes["Contember_MenuItem"]>,
	secondaryItems?: GraphQLTypes["Contember_LinkList"] | undefined,
	eshopLink?: GraphQLTypes["Contember_Link"] | undefined,
	itemsBySubitems?: GraphQLTypes["Contember_MenuItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_MenuItemConnection"]
};
	["Contember_MenuLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	eshopLink?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SiteMenu"]: {
		_meta?: GraphQLTypes["Contember_SiteMenuMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	locales: Array<GraphQLTypes["Contember_MenuLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	localesByItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	localesBySecondaryItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_MenuLocaleConnection"]
};
	["Contember_SiteMenuMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLocaleOrderBy"]: GraphQLTypes["Contember_MenuLocaleOrderBy"];
	["Contember_SiteMenuOrderBy"]: GraphQLTypes["Contember_SiteMenuOrderBy"];
	["Contember_SiteMenuLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_SiteMenuLocalesByLocaleUniqueWhere"];
	["Contember_SiteMenuLocalesByItemsUniqueWhere"]: GraphQLTypes["Contember_SiteMenuLocalesByItemsUniqueWhere"];
	["Contember_SiteMenuLocalesBySecondaryItemsUniqueWhere"]: GraphQLTypes["Contember_SiteMenuLocalesBySecondaryItemsUniqueWhere"];
	["Contember_MenuLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuLocaleEdge"]>
};
	["Contember_MenuLocaleEdge"]: {
		node: GraphQLTypes["Contember_MenuLocale"]
};
	["Contember_MenuItem"]: {
		_meta?: GraphQLTypes["Contember_MenuItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	menu?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	subitems: Array<GraphQLTypes["Contember_MenuSubitem"]>,
	paginateSubitems: GraphQLTypes["Contember_MenuSubitemConnection"]
};
	["Contember_MenuItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	menu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	subitems?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuSubitem"]: {
		_meta?: GraphQLTypes["Contember_MenuSubitemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	title?: string | undefined,
	item?: GraphQLTypes["Contember_MenuItem"] | undefined,
	links?: GraphQLTypes["Contember_MenuLinkList"] | undefined,
	products?: GraphQLTypes["Contember_MenuProducts"] | undefined,
	delivery?: GraphQLTypes["Contember_MenuDelivery"] | undefined,
	contact?: GraphQLTypes["Contember_MenuContact"] | undefined,
	order: number
};
	["Contember_MenuSubitemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	item?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	links?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLinkList"]: {
		_meta?: GraphQLTypes["Contember_MenuLinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_MenuLinkItem"]>,
	paginateItems: GraphQLTypes["Contember_MenuLinkItemConnection"]
};
	["Contember_MenuLinkListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLinkItem"]: {
		_meta?: GraphQLTypes["Contember_MenuLinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_MenuLinkList"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	description?: string | undefined
};
	["Contember_MenuLinkItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLinkItemOrderBy"]: GraphQLTypes["Contember_MenuLinkItemOrderBy"];
	["Contember_MenuLinkListOrderBy"]: GraphQLTypes["Contember_MenuLinkListOrderBy"];
	["Contember_MenuLinkItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuLinkItemEdge"]>
};
	["Contember_MenuLinkItemEdge"]: {
		node: GraphQLTypes["Contember_MenuLinkItem"]
};
	["Contember_MenuProducts"]: {
		_meta?: GraphQLTypes["Contember_MenuProductsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	links?: GraphQLTypes["Contember_MenuLinkList"] | undefined,
	buttons?: GraphQLTypes["Contember_LinkList"] | undefined
};
	["Contember_MenuProductsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	links?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	buttons?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuDelivery"]: {
		_meta?: GraphQLTypes["Contember_MenuDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegions"] | undefined,
	text?: string | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndApps"] | undefined
};
	["Contember_MenuDeliveryMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuContact"]: {
		_meta?: GraphQLTypes["Contember_MenuContactMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	text?: string | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_MenuContactMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuSubitemOrderBy"]: GraphQLTypes["Contember_MenuSubitemOrderBy"];
	["Contember_MenuItemOrderBy"]: GraphQLTypes["Contember_MenuItemOrderBy"];
	["Contember_MenuProductsOrderBy"]: GraphQLTypes["Contember_MenuProductsOrderBy"];
	["Contember_MenuDeliveryOrderBy"]: GraphQLTypes["Contember_MenuDeliveryOrderBy"];
	["Contember_MenuContactOrderBy"]: GraphQLTypes["Contember_MenuContactOrderBy"];
	["Contember_MenuSubitemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuSubitemEdge"]>
};
	["Contember_MenuSubitemEdge"]: {
		node: GraphQLTypes["Contember_MenuSubitem"]
};
	["Contember_MenuLocaleItemsBySubitemsUniqueWhere"]: GraphQLTypes["Contember_MenuLocaleItemsBySubitemsUniqueWhere"];
	["Contember_MenuItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuItemEdge"]>
};
	["Contember_MenuItemEdge"]: {
		node: GraphQLTypes["Contember_MenuItem"]
};
	["Contember_ProductPackingLocaleOrderBy"]: GraphQLTypes["Contember_ProductPackingLocaleOrderBy"];
	["Contember_ProductLocale"]: {
		_meta?: GraphQLTypes["Contember_ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	featureList?: GraphQLTypes["Contember_TextList"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined
};
	["Contember_ProductLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	featureList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	printableName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	firstSticker?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondSticker?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductLocaleOrderBy"]: GraphQLTypes["Contember_ProductLocaleOrderBy"];
	["Contember_ProductRecipeLocale"]: {
		_meta?: GraphQLTypes["Contember_ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	description?: string | undefined
};
	["Contember_ProductRecipeLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductRecipe"]: {
		_meta?: GraphQLTypes["Contember_ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	icon?: GraphQLTypes["Contember_Image"] | undefined,
	ingredients: Array<GraphQLTypes["Contember_ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["Contember_ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainer"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_NutritionFacts"] | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByGroupPrices?: GraphQLTypes["Product"] | undefined,
	productsByCartItems?: GraphQLTypes["Product"] | undefined,
	productsByOrderItems?: GraphQLTypes["Product"] | undefined,
	productsByInheritedCategories?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByCustomerPrices?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	productsBySalesDays?: GraphQLTypes["Product"] | undefined,
	productsBySalesWeeks?: GraphQLTypes["Product"] | undefined,
	productsBySalesMonths?: GraphQLTypes["Product"] | undefined,
	ingredientsByQuantity?: GraphQLTypes["Contember_ProductIngredientItem"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["Contember_ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductRecipeLocaleConnection"]
};
	["Contember_ProductRecipeMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nutritionFacts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientItem"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredient"] | undefined,
	quantity?: GraphQLTypes["Contember_ProductIngredientItemQuantity"] | undefined
};
	["Contember_ProductIngredientItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipe?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientItemQuantity"]: {
		_meta?: GraphQLTypes["Contember_ProductIngredientItemQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnit"] | undefined,
	value: number
};
	["Contember_ProductIngredientItemQuantityMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	value?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientItemOrderBy"]: GraphQLTypes["Contember_ProductIngredientItemOrderBy"];
	["Contember_ProductIngredientItemQuantityOrderBy"]: GraphQLTypes["Contember_ProductIngredientItemQuantityOrderBy"];
	["Contember_ProductRecipeLocaleOrderBy"]: GraphQLTypes["Contember_ProductRecipeLocaleOrderBy"];
	["Contember_NutritionFacts"]: {
		_meta?: GraphQLTypes["Contember_NutritionFactsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	energyKiloJoule?: number | undefined,
	energyKiloCalorie?: number | undefined,
	fatsGrams?: number | undefined,
	fatsOfWhichSaturatedGrams?: number | undefined,
	carbohydrateGrams?: number | undefined,
	carbohydrateOfWhichSugarsGrams?: number | undefined,
	proteinGrams?: number | undefined,
	fibreGrams?: number | undefined,
	saltGrams?: number | undefined
};
	["Contember_NutritionFactsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	energyKiloJoule?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	energyKiloCalorie?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fatsGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fatsOfWhichSaturatedGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carbohydrateGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carbohydrateOfWhichSugarsGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	proteinGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fibreGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	saltGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductRecipeProductsByCodeUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByCodeUniqueWhere"];
	["Contember_ProductRecipeProductsByStocksUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByStocksUniqueWhere"];
	["Contember_ProductRecipeProductsByGroupPricesUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByGroupPricesUniqueWhere"];
	["Contember_ProductRecipeProductsByCartItemsUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByCartItemsUniqueWhere"];
	["Contember_ProductRecipeProductsByOrderItemsUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByOrderItemsUniqueWhere"];
	["Contember_ProductRecipeProductsByInheritedCategoriesUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByInheritedCategoriesUniqueWhere"];
	["Contember_ProductRecipeProductsByLocalesUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByLocalesUniqueWhere"];
	["Contember_ProductRecipeProductsByCustomerPricesUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByCustomerPricesUniqueWhere"];
	["Contember_ProductRecipeProductsByVirtualProductUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByVirtualProductUniqueWhere"];
	["Contember_ProductRecipeProductsByMetaUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByMetaUniqueWhere"];
	["Contember_ProductRecipeProductsByPageUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsByPageUniqueWhere"];
	["Contember_ProductRecipeProductsBySalesDaysUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsBySalesDaysUniqueWhere"];
	["Contember_ProductRecipeProductsBySalesWeeksUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsBySalesWeeksUniqueWhere"];
	["Contember_ProductRecipeProductsBySalesMonthsUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeProductsBySalesMonthsUniqueWhere"];
	["Contember_ProductRecipeIngredientsByQuantityUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeIngredientsByQuantityUniqueWhere"];
	["Contember_ProductRecipeLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductRecipeLocalesByLocaleUniqueWhere"];
	["Contember_ProductIngredientItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientItemEdge"]>
};
	["Contember_ProductIngredientItemEdge"]: {
		node: GraphQLTypes["Contember_ProductIngredientItem"]
};
	["Contember_ProductRecipeLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductRecipeLocaleEdge"]>
};
	["Contember_ProductRecipeLocaleEdge"]: {
		node: GraphQLTypes["Contember_ProductRecipeLocale"]
};
	["Contember_DeliveryMethodLocale"]: {
		_meta?: GraphQLTypes["Contember_DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["Contember_DeliveryMethodLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryMethodLocaleOrderBy"]: GraphQLTypes["Contember_DeliveryMethodLocaleOrderBy"];
	["Contember_PipelineStageLocale"]: {
		_meta?: GraphQLTypes["Contember_PipelineStageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_PipelineStageLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStage"]: {
		_meta?: GraphQLTypes["Contember_PipelineStageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_PipelineStageItem"]>,
	pipeline?: GraphQLTypes["Contember_Pipeline"] | undefined,
	order: number,
	locales: Array<GraphQLTypes["Contember_PipelineStageLocale"]>,
	sentiment?: GraphQLTypes["Contember_PipelineStageSentiment"] | undefined,
	type: GraphQLTypes["Contember_PipelineStageType"],
	forms: Array<GraphQLTypes["Contember_LeadForm"]>,
	showItemsWeight: boolean,
	showItemsPrice: boolean,
	itemsByLead?: GraphQLTypes["Contember_PipelineStageItem"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_PipelineStageLocale"] | undefined,
	formsByCode?: GraphQLTypes["Contember_LeadForm"] | undefined,
	formsByLeads?: GraphQLTypes["Contember_LeadForm"] | undefined,
	paginateItems: GraphQLTypes["Contember_PipelineStageItemConnection"],
	paginateLocales: GraphQLTypes["Contember_PipelineStageLocaleConnection"],
	paginateForms: GraphQLTypes["Contember_LeadFormConnection"]
};
	["Contember_PipelineStageMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pipeline?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sentiment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	forms?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showItemsWeight?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showItemsPrice?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStageItem"]: {
		_meta?: GraphQLTypes["Contember_PipelineStageItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	list?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineLead"] | undefined,
	order: number
};
	["Contember_PipelineStageItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineLead"]: {
		_meta?: GraphQLTypes["Contember_PipelineLeadMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	stageItem?: GraphQLTypes["Contember_PipelineStageItem"] | undefined,
	contactPerson?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine: boolean,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	form?: GraphQLTypes["Contember_LeadForm"] | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined
};
	["Contember_PipelineLeadMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stageItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contactPerson?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cafeType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offerType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ownMachine?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	form?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	multiroasterType?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LeadForm"]: {
		_meta?: GraphQLTypes["Contember_LeadFormMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	stage?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	leads: Array<GraphQLTypes["Contember_PipelineLead"]>,
	leadsByStageItem?: GraphQLTypes["Contember_PipelineLead"] | undefined,
	paginateLeads: GraphQLTypes["Contember_PipelineLeadConnection"]
};
	["Contember_LeadFormMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	leads?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineLeadOrderBy"]: GraphQLTypes["Contember_PipelineLeadOrderBy"];
	["Contember_PipelineStageItemOrderBy"]: GraphQLTypes["Contember_PipelineStageItemOrderBy"];
	["Contember_PipelineStageOrderBy"]: GraphQLTypes["Contember_PipelineStageOrderBy"];
	["Contember_PipelineOrderBy"]: GraphQLTypes["Contember_PipelineOrderBy"];
	["Contember_PipelineStageSentimentOrderBy"]: GraphQLTypes["Contember_PipelineStageSentimentOrderBy"];
	["Contember_LeadFormOrderBy"]: GraphQLTypes["Contember_LeadFormOrderBy"];
	["Contember_LeadFormLeadsByStageItemUniqueWhere"]: GraphQLTypes["Contember_LeadFormLeadsByStageItemUniqueWhere"];
	["Contember_PipelineLeadConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineLeadEdge"]>
};
	["Contember_PipelineLeadEdge"]: {
		node: GraphQLTypes["Contember_PipelineLead"]
};
	["Contember_Pipeline"]: {
		_meta?: GraphQLTypes["Contember_PipelineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	stages: Array<GraphQLTypes["Contember_PipelineStage"]>,
	stagesByItems?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	stagesByLocales?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	stagesByForms?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	paginateStages: GraphQLTypes["Contember_PipelineStageConnection"]
};
	["Contember_PipelineMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stages?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStagesByItemsUniqueWhere"]: GraphQLTypes["Contember_PipelineStagesByItemsUniqueWhere"];
	["Contember_PipelineStagesByLocalesUniqueWhere"]: GraphQLTypes["Contember_PipelineStagesByLocalesUniqueWhere"];
	["Contember_PipelineStagesByFormsUniqueWhere"]: GraphQLTypes["Contember_PipelineStagesByFormsUniqueWhere"];
	["Contember_PipelineStageConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineStageEdge"]>
};
	["Contember_PipelineStageEdge"]: {
		node: GraphQLTypes["Contember_PipelineStage"]
};
	["Contember_PipelineStageLocaleOrderBy"]: GraphQLTypes["Contember_PipelineStageLocaleOrderBy"];
	["Contember_PipelineStageSentiment"]: {
		_meta?: GraphQLTypes["Contember_PipelineStageSentimentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	backgroundColor?: string | undefined,
	textColor?: string | undefined
};
	["Contember_PipelineStageSentimentMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	backgroundColor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	textColor?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStageItemsByLeadUniqueWhere"]: GraphQLTypes["Contember_PipelineStageItemsByLeadUniqueWhere"];
	["Contember_PipelineStageLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_PipelineStageLocalesByLocaleUniqueWhere"];
	["Contember_PipelineStageFormsByCodeUniqueWhere"]: GraphQLTypes["Contember_PipelineStageFormsByCodeUniqueWhere"];
	["Contember_PipelineStageFormsByLeadsUniqueWhere"]: GraphQLTypes["Contember_PipelineStageFormsByLeadsUniqueWhere"];
	["Contember_PipelineStageItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineStageItemEdge"]>
};
	["Contember_PipelineStageItemEdge"]: {
		node: GraphQLTypes["Contember_PipelineStageItem"]
};
	["Contember_PipelineStageLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineStageLocaleEdge"]>
};
	["Contember_PipelineStageLocaleEdge"]: {
		node: GraphQLTypes["Contember_PipelineStageLocale"]
};
	["Contember_LeadFormConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_LeadFormEdge"]>
};
	["Contember_LeadFormEdge"]: {
		node: GraphQLTypes["Contember_LeadForm"]
};
	["Contember_SubscriptionBoxLocale"]: {
		_meta?: GraphQLTypes["Contember_SubscriptionBoxLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["Contember_SubscriptionBox"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["Contember_TextList"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_TextList"] | undefined
};
	["Contember_SubscriptionBoxLocaleMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stickerText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	descriptionText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	texts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SubscriptionBox"]: {
		_meta?: GraphQLTypes["Contember_SubscriptionBoxMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["Contember_SubscriptionBoxLocale"]>,
	unique: GraphQLTypes["Contember_One"],
	localesByLocale?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	localesByTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	localesBySecondaryTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_SubscriptionBoxLocaleConnection"]
};
	["Contember_SubscriptionBoxMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SubscriptionBoxLocaleOrderBy"]: GraphQLTypes["Contember_SubscriptionBoxLocaleOrderBy"];
	["Contember_SubscriptionBoxOrderBy"]: GraphQLTypes["Contember_SubscriptionBoxOrderBy"];
	["Contember_SubscriptionBoxLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_SubscriptionBoxLocalesByLocaleUniqueWhere"];
	["Contember_SubscriptionBoxLocalesByTextsUniqueWhere"]: GraphQLTypes["Contember_SubscriptionBoxLocalesByTextsUniqueWhere"];
	["Contember_SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: GraphQLTypes["Contember_SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"];
	["Contember_SubscriptionBoxLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_SubscriptionBoxLocaleEdge"]>
};
	["Contember_SubscriptionBoxLocaleEdge"]: {
		node: GraphQLTypes["Contember_SubscriptionBoxLocale"]
};
	["Contember_LocaleBusinessCategoriesByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleBusinessCategoriesByRootUniqueWhere"];
	["Contember_LocaleProductCategoryListsByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleProductCategoryListsByRootUniqueWhere"];
	["Contember_LocaleProductIngredientsByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleProductIngredientsByRootUniqueWhere"];
	["Contember_LocaleOrderRecurrencesByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleOrderRecurrencesByRootUniqueWhere"];
	["Contember_LocaleBlogsByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleBlogsByRootUniqueWhere"];
	["Contember_LocaleBlogPostsByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleBlogPostsByRootUniqueWhere"];
	["Contember_LocaleBlogPostsByCoverUniqueWhere"]: GraphQLTypes["Contember_LocaleBlogPostsByCoverUniqueWhere"];
	["Contember_LocaleBlogPostsByContentUniqueWhere"]: GraphQLTypes["Contember_LocaleBlogPostsByContentUniqueWhere"];
	["Contember_LocaleBlogPostsByLinkUniqueWhere"]: GraphQLTypes["Contember_LocaleBlogPostsByLinkUniqueWhere"];
	["Contember_LocaleBlogPostsBySeoUniqueWhere"]: GraphQLTypes["Contember_LocaleBlogPostsBySeoUniqueWhere"];
	["Contember_LocaleMenusByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleMenusByRootUniqueWhere"];
	["Contember_LocaleMenusByItemsUniqueWhere"]: GraphQLTypes["Contember_LocaleMenusByItemsUniqueWhere"];
	["Contember_LocaleMenusBySecondaryItemsUniqueWhere"]: GraphQLTypes["Contember_LocaleMenusBySecondaryItemsUniqueWhere"];
	["Contember_LocalePagesByRootUniqueWhere"]: GraphQLTypes["Contember_LocalePagesByRootUniqueWhere"];
	["Contember_LocalePagesByLinkUniqueWhere"]: GraphQLTypes["Contember_LocalePagesByLinkUniqueWhere"];
	["Contember_LocalePagesBySeoUniqueWhere"]: GraphQLTypes["Contember_LocalePagesBySeoUniqueWhere"];
	["Contember_LocalePagesByContentUniqueWhere"]: GraphQLTypes["Contember_LocalePagesByContentUniqueWhere"];
	["Contember_LocalePagesByCoverUniqueWhere"]: GraphQLTypes["Contember_LocalePagesByCoverUniqueWhere"];
	["Contember_LocaleProductIngredientCategoriesByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleProductIngredientCategoriesByRootUniqueWhere"];
	["Contember_LocaleAllergensByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleAllergensByRootUniqueWhere"];
	["Contember_LocaleProductPackingsByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleProductPackingsByRootUniqueWhere"];
	["Contember_LocaleProductsByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleProductsByRootUniqueWhere"];
	["Contember_LocaleProductsByFeatureListUniqueWhere"]: GraphQLTypes["Contember_LocaleProductsByFeatureListUniqueWhere"];
	["Contember_LocaleProductRecipesByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleProductRecipesByRootUniqueWhere"];
	["Contember_LocalePointOfSaleCategoriesByRootUniqueWhere"]: GraphQLTypes["Contember_LocalePointOfSaleCategoriesByRootUniqueWhere"];
	["Contember_LocalePointOfSaleCategoryProductsByRootUniqueWhere"]: GraphQLTypes["Contember_LocalePointOfSaleCategoryProductsByRootUniqueWhere"];
	["Contember_LocaleDeliveryMethodsByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleDeliveryMethodsByRootUniqueWhere"];
	["Contember_LocaleIngredientSuppliersByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleIngredientSuppliersByRootUniqueWhere"];
	["Contember_LocaleIdentificationCodesByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleIdentificationCodesByRootUniqueWhere"];
	["Contember_LocalePipelineStagesByRootUniqueWhere"]: GraphQLTypes["Contember_LocalePipelineStagesByRootUniqueWhere"];
	["Contember_LocaleSubscriptionBoxesByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleSubscriptionBoxesByRootUniqueWhere"];
	["Contember_LocaleSubscriptionBoxesByTextsUniqueWhere"]: GraphQLTypes["Contember_LocaleSubscriptionBoxesByTextsUniqueWhere"];
	["Contember_LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: GraphQLTypes["Contember_LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"];
	["Contember_LocaleBusinessServicesByRootUniqueWhere"]: GraphQLTypes["Contember_LocaleBusinessServicesByRootUniqueWhere"];
	["Contember_ProductPackingLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductPackingLocaleEdge"]>
};
	["Contember_ProductPackingLocaleEdge"]: {
		node: GraphQLTypes["Contember_ProductPackingLocale"]
};
	["Contember_ProductLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductLocaleEdge"]>
};
	["Contember_ProductLocaleEdge"]: {
		node: GraphQLTypes["Contember_ProductLocale"]
};
	["Contember_DeliveryMethodLocaleConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryMethodLocaleEdge"]>
};
	["Contember_DeliveryMethodLocaleEdge"]: {
		node: GraphQLTypes["Contember_DeliveryMethodLocale"]
};
	["Contember_PreparedPackingQuantity"]: {
		_meta?: GraphQLTypes["Contember_PreparedPackingQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	packing?: GraphQLTypes["Contember_ProductPacking"] | undefined,
	packedInCardboard: boolean
};
	["Contember_PreparedPackingQuantityMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	date?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipe?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	packing?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	packedInCardboard?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PreparedPackingQuantityOrderBy"]: GraphQLTypes["Contember_PreparedPackingQuantityOrderBy"];
	["Contember_ProductPackingLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductPackingLocalesByLocaleUniqueWhere"];
	["Contember_PreparedPackingQuantityConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PreparedPackingQuantityEdge"]>
};
	["Contember_PreparedPackingQuantityEdge"]: {
		node: GraphQLTypes["Contember_PreparedPackingQuantity"]
};
	["Contember_ProductStock"]: {
		_meta?: GraphQLTypes["Contember_ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Contember_Store"] | undefined
};
	["Contember_ProductStockMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	store?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Store"]: {
		_meta?: GraphQLTypes["Contember_StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["Contember_StoreMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductStockOrderBy"]: GraphQLTypes["Contember_ProductStockOrderBy"];
	["Contember_StoreOrderBy"]: GraphQLTypes["Contember_StoreOrderBy"];
	["Contember_ProductGroupPrice"]: {
		_meta?: GraphQLTypes["Contember_ProductGroupPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	group?: GraphQLTypes["Contember_CustomerGroup"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductGroupPriceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	group?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerGroup"]: {
		_meta?: GraphQLTypes["Contember_CustomerGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	productPrices: Array<GraphQLTypes["Contember_ProductGroupPrice"]>,
	customers: Array<GraphQLTypes["Contember_Customer"]>,
	discountPermille?: number | undefined,
	code?: string | undefined,
	doubleshotLegacyId?: string | undefined,
	productPricesByProduct?: GraphQLTypes["Contember_ProductGroupPrice"] | undefined,
	customersByDoubleshotLegacyId?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByAddresses?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultBillingAddress?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultPaymentMethod?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByCredits?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByPaymentMethods?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByOrders?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByPermissions?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByName?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByIngredientRatings?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByBusiness?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByCarts?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByShortcutList?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByUser?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByInvoices?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByInvoicingPlan?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByBillingSubjects?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByOfferedProducts?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultDeliveryAddress?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultBillingSubject?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByAnonymousSession?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByBoughtVouchers?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByOwnsVouchers?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByRedeemedVouchers?: GraphQLTypes["Contember_Customer"] | undefined,
	customersBySpending?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByCustomerTabs?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByLead?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByMeta?: GraphQLTypes["Contember_Customer"] | undefined,
	paginateProductPrices: GraphQLTypes["Contember_ProductGroupPriceConnection"],
	paginateCustomers: GraphQLTypes["Contember_CustomerConnection"]
};
	["Contember_CustomerGroupMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productPrices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountPermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGroupPriceOrderBy"]: GraphQLTypes["Contember_ProductGroupPriceOrderBy"];
	["Contember_CustomerGroupProductPricesByProductUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupProductPricesByProductUniqueWhere"];
	["Contember_CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"];
	["Contember_CustomerGroupCustomersByAddressesUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByAddressesUniqueWhere"];
	["Contember_CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"];
	["Contember_CustomerGroupCustomersByDefaultPaymentMethodUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByDefaultPaymentMethodUniqueWhere"];
	["Contember_CustomerGroupCustomersByCreditsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByCreditsUniqueWhere"];
	["Contember_CustomerGroupCustomersByPaymentMethodsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByPaymentMethodsUniqueWhere"];
	["Contember_CustomerGroupCustomersByOrdersUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByOrdersUniqueWhere"];
	["Contember_CustomerGroupCustomersByPermissionsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByPermissionsUniqueWhere"];
	["Contember_CustomerGroupCustomersByNameUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByNameUniqueWhere"];
	["Contember_CustomerGroupCustomersByIngredientRatingsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByIngredientRatingsUniqueWhere"];
	["Contember_CustomerGroupCustomersByBusinessUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByBusinessUniqueWhere"];
	["Contember_CustomerGroupCustomersByCartsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByCartsUniqueWhere"];
	["Contember_CustomerGroupCustomersByShortcutListUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByShortcutListUniqueWhere"];
	["Contember_CustomerGroupCustomersByUserUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByUserUniqueWhere"];
	["Contember_CustomerGroupCustomersByInvoicesUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByInvoicesUniqueWhere"];
	["Contember_CustomerGroupCustomersByInvoicingPlanUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByInvoicingPlanUniqueWhere"];
	["Contember_CustomerGroupCustomersByBillingSubjectsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByBillingSubjectsUniqueWhere"];
	["Contember_CustomerGroupCustomersByOfferedProductsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByOfferedProductsUniqueWhere"];
	["Contember_CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"];
	["Contember_CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"];
	["Contember_CustomerGroupCustomersByAnonymousSessionUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByAnonymousSessionUniqueWhere"];
	["Contember_CustomerGroupCustomersByBoughtVouchersUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByBoughtVouchersUniqueWhere"];
	["Contember_CustomerGroupCustomersByOwnsVouchersUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByOwnsVouchersUniqueWhere"];
	["Contember_CustomerGroupCustomersByRedeemedVouchersUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByRedeemedVouchersUniqueWhere"];
	["Contember_CustomerGroupCustomersBySpendingUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersBySpendingUniqueWhere"];
	["Contember_CustomerGroupCustomersByCustomerTabsUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByCustomerTabsUniqueWhere"];
	["Contember_CustomerGroupCustomersByLeadUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByLeadUniqueWhere"];
	["Contember_CustomerGroupCustomersByMetaUniqueWhere"]: GraphQLTypes["Contember_CustomerGroupCustomersByMetaUniqueWhere"];
	["Contember_ProductGroupPriceConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductGroupPriceEdge"]>
};
	["Contember_ProductGroupPriceEdge"]: {
		node: GraphQLTypes["Contember_ProductGroupPrice"]
};
	["Contember_ProductCustomerPrice"]: {
		_meta?: GraphQLTypes["Contember_ProductCustomerPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	groupDiscountPermille?: number | undefined,
	groupPriceCents?: number | undefined,
	fullPriceCents: number,
	discountedPriceCents?: number | undefined,
	priceCents: number,
	offered: boolean,
	notDiscountable: boolean,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductCustomerPriceMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	groupDiscountPermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	groupPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fullPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountedPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offered?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCustomerPriceOrderBy"]: GraphQLTypes["Contember_ProductCustomerPriceOrderBy"];
	["Contember_ProductMetadata"]: {
		_meta?: GraphQLTypes["Contember_ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	archived?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	available?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesDay"]: {
		_meta?: GraphQLTypes["Contember_ProductSalesDayMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	productName: string,
	orderDate?: GraphQLTypes["Date"] | undefined,
	priceCentsSum: number,
	sum: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductSalesDayMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesDayOrderBy"]: GraphQLTypes["Contember_ProductSalesDayOrderBy"];
	["Contember_ProductSalesWeek"]: {
		_meta?: GraphQLTypes["Contember_ProductSalesWeekMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	productName: string,
	orderDate?: GraphQLTypes["Date"] | undefined,
	priceCentsSum: number,
	sum: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductSalesWeekMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesWeekOrderBy"]: GraphQLTypes["Contember_ProductSalesWeekOrderBy"];
	["Contember_ProductSalesMonth"]: {
		_meta?: GraphQLTypes["Contember_ProductSalesMonthMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	productName: string,
	orderDate?: GraphQLTypes["Date"] | undefined,
	priceCentsSum: number,
	sum: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductSalesMonthMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesMonthOrderBy"]: GraphQLTypes["Contember_ProductSalesMonthOrderBy"];
	["Contember_ProductStocksByStoreUniqueWhere"]: GraphQLTypes["Contember_ProductStocksByStoreUniqueWhere"];
	["Contember_ProductGroupPricesByGroupUniqueWhere"]: GraphQLTypes["Contember_ProductGroupPricesByGroupUniqueWhere"];
	["Contember_ProductCartItemsByCartUniqueWhere"]: GraphQLTypes["Contember_ProductCartItemsByCartUniqueWhere"];
	["Contember_ProductOrderItemsByVirtualProductEffectsUniqueWhere"]: GraphQLTypes["Contember_ProductOrderItemsByVirtualProductEffectsUniqueWhere"];
	["Contember_ProductLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_ProductLocalesByLocaleUniqueWhere"];
	["Contember_ProductLocalesByFeatureListUniqueWhere"]: GraphQLTypes["Contember_ProductLocalesByFeatureListUniqueWhere"];
	["Contember_ProductCustomerPricesByCustomerUniqueWhere"]: GraphQLTypes["Contember_ProductCustomerPricesByCustomerUniqueWhere"];
	["Contember_ProductStockConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductStockEdge"]>
};
	["Contember_ProductStockEdge"]: {
		node: GraphQLTypes["Contember_ProductStock"]
};
	["Contember_DeliveryZoneConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryZoneEdge"]>
};
	["Contember_DeliveryZoneEdge"]: {
		node: GraphQLTypes["Contember_DeliveryZone"]
};
	["Contember_ProductCustomerPriceConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCustomerPriceEdge"]>
};
	["Contember_ProductCustomerPriceEdge"]: {
		node: GraphQLTypes["Contember_ProductCustomerPrice"]
};
	["Contember_ProductSalesDayConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductSalesDayEdge"]>
};
	["Contember_ProductSalesDayEdge"]: {
		node: GraphQLTypes["Contember_ProductSalesDay"]
};
	["Contember_ProductSalesWeekConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductSalesWeekEdge"]>
};
	["Contember_ProductSalesWeekEdge"]: {
		node: GraphQLTypes["Contember_ProductSalesWeek"]
};
	["Contember_ProductSalesMonthConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductSalesMonthEdge"]>
};
	["Contember_ProductSalesMonthEdge"]: {
		node: GraphQLTypes["Contember_ProductSalesMonth"]
};
	["Contember_AddressMetadata"]: {
		_meta?: GraphQLTypes["Contember_AddressMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryZoneType?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZone"] | undefined
};
	["Contember_AddressMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZoneType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryZoneAddressesByMetaUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneAddressesByMetaUniqueWhere"];
	["Contember_DeliveryZoneAddressesByGpsUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneAddressesByGpsUniqueWhere"];
	["Contember_DeliveryZoneAddressesByInvoicesUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneAddressesByInvoicesUniqueWhere"];
	["Contember_DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"];
	["Contember_DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"];
	["Contember_DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"];
	["Contember_DeliveryZoneAddressesMetadataByAddressUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneAddressesMetadataByAddressUniqueWhere"];
	["AddressConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["AddressEdge"]>
};
	["AddressEdge"]: {
		node: GraphQLTypes["Address"]
};
	["Contember_AddressMetadataConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AddressMetadataEdge"]>
};
	["Contember_AddressMetadataEdge"]: {
		node: GraphQLTypes["Contember_AddressMetadata"]
};
	["Contember_DeliveryTimelinePreset"]: {
		_meta?: GraphQLTypes["Contember_DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["Contember_DeliveryTimelinePresetMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	duration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	window?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryMethodLocalesByLocaleUniqueWhere"]: GraphQLTypes["Contember_DeliveryMethodLocalesByLocaleUniqueWhere"];
	["PaymentMethodConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
		node: GraphQLTypes["PaymentMethod"]
};
	["Contember_CourierConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CourierEdge"]>
};
	["Contember_CourierEdge"]: {
		node: GraphQLTypes["Contember_Courier"]
};
	["Contember_CustomerPermissions"]: {
		_meta?: GraphQLTypes["Contember_CustomerPermissionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	canPlaceOrder?: boolean | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_CustomerPermissionsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerName"]: {
		_meta?: GraphQLTypes["Contember_CustomerNameMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	nominative: string,
	vocative?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	aliases: Array<GraphQLTypes["Contember_CustomerAlias"]>,
	paginateAliases: GraphQLTypes["Contember_CustomerAliasConnection"]
};
	["Contember_CustomerNameMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nominative?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vocative?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	aliases?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerAlias"]: {
		_meta?: GraphQLTypes["Contember_CustomerAliasMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	order: number
};
	["Contember_CustomerAliasMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerAliasOrderBy"]: GraphQLTypes["Contember_CustomerAliasOrderBy"];
	["Contember_CustomerAliasConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerAliasEdge"]>
};
	["Contember_CustomerAliasEdge"]: {
		node: GraphQLTypes["Contember_CustomerAlias"]
};
	["Contember_ApplicationShortcutList"]: {
		_meta?: GraphQLTypes["Contember_ApplicationShortcutListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ApplicationShortcutListItem"]>,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	paginateItems: GraphQLTypes["Contember_ApplicationShortcutListItemConnection"]
};
	["Contember_ApplicationShortcutListMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ApplicationShortcutListItem"]: {
		_meta?: GraphQLTypes["Contember_ApplicationShortcutListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	application?: GraphQLTypes["Contember_Application"] | undefined,
	list?: GraphQLTypes["Contember_ApplicationShortcutList"] | undefined
};
	["Contember_ApplicationShortcutListItemMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	application?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Application"]: {
		_meta?: GraphQLTypes["Contember_ApplicationMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	handle?: string | undefined,
	url?: string | undefined,
	color?: string | undefined,
	icon?: GraphQLTypes["Contember_Image"] | undefined,
	name?: string | undefined,
	short?: string | undefined
};
	["Contember_ApplicationMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	handle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	url?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	short?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ApplicationShortcutListItemOrderBy"]: GraphQLTypes["Contember_ApplicationShortcutListItemOrderBy"];
	["Contember_ApplicationOrderBy"]: GraphQLTypes["Contember_ApplicationOrderBy"];
	["Contember_ApplicationShortcutListItemConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ApplicationShortcutListItemEdge"]>
};
	["Contember_ApplicationShortcutListItemEdge"]: {
		node: GraphQLTypes["Contember_ApplicationShortcutListItem"]
};
	["Contember_InvoicingPlan"]: {
		_meta?: GraphQLTypes["Contember_InvoicingPlanMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	preset?: GraphQLTypes["Contember_InvoicingPreset"] | undefined
};
	["Contember_InvoicingPlanMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preset?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_InvoicingPreset"]: {
		_meta?: GraphQLTypes["Contember_InvoicingPresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	startsAt?: GraphQLTypes["DateTime"] | undefined,
	endsAt?: GraphQLTypes["DateTime"] | undefined,
	intervalOfDays?: number | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlan"] | undefined
};
	["Contember_InvoicingPresetMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startsAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	endsAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	intervalOfDays?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerSpending"]: {
		_meta?: GraphQLTypes["Contember_CustomerSpendingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalCents: number,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_CustomerSpendingMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerMetadata"]: {
		_meta?: GraphQLTypes["Contember_CustomerMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isBusiness: boolean,
	name?: string | undefined,
	fallbackName: string,
	remainingCreditsCents: number,
	ordersTotalCents: number,
	customerProfileUrl: string,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_CustomerMetadataMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isBusiness?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fallbackName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ordersTotalCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerProfileUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerAddressesByMetaUniqueWhere"]: GraphQLTypes["Contember_CustomerAddressesByMetaUniqueWhere"];
	["Contember_CustomerAddressesByGpsUniqueWhere"]: GraphQLTypes["Contember_CustomerAddressesByGpsUniqueWhere"];
	["Contember_CustomerAddressesByInvoicesUniqueWhere"]: GraphQLTypes["Contember_CustomerAddressesByInvoicesUniqueWhere"];
	["Contember_CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: GraphQLTypes["Contember_CustomerAddressesByBillingAddressOfOrdersUniqueWhere"];
	["Contember_CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: GraphQLTypes["Contember_CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"];
	["Contember_CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: GraphQLTypes["Contember_CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"];
	["Contember_CustomerCreditsByTransactionsUniqueWhere"]: GraphQLTypes["Contember_CustomerCreditsByTransactionsUniqueWhere"];
	["Contember_CustomerCreditsByVoucherRedemptionUniqueWhere"]: GraphQLTypes["Contember_CustomerCreditsByVoucherRedemptionUniqueWhere"];
	["Contember_CustomerOrdersBySeqUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersBySeqUniqueWhere"];
	["Contember_CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByDoubleshotLegacyIdUniqueWhere"];
	["Contember_CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["Contember_CustomerOrdersByDiscountsUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByDiscountsUniqueWhere"];
	["Contember_CustomerOrdersByDeliveryUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByDeliveryUniqueWhere"];
	["Contember_CustomerOrdersByItemsUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByItemsUniqueWhere"];
	["Contember_CustomerOrdersByCartUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByCartUniqueWhere"];
	["Contember_CustomerOrdersByEventsUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByEventsUniqueWhere"];
	["Contember_CustomerOrdersByPriceLinesUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByPriceLinesUniqueWhere"];
	["Contember_CustomerOrdersByPaymentsUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByPaymentsUniqueWhere"];
	["Contember_CustomerOrdersBySummaryUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersBySummaryUniqueWhere"];
	["Contember_CustomerOrdersByGratuityUniqueWhere"]: GraphQLTypes["Contember_CustomerOrdersByGratuityUniqueWhere"];
	["Contember_CustomerIngredientRatingsByIngredientUniqueWhere"]: GraphQLTypes["Contember_CustomerIngredientRatingsByIngredientUniqueWhere"];
	["Contember_CustomerCartsByItemsUniqueWhere"]: GraphQLTypes["Contember_CustomerCartsByItemsUniqueWhere"];
	["Contember_CustomerCartsByOrderUniqueWhere"]: GraphQLTypes["Contember_CustomerCartsByOrderUniqueWhere"];
	["Contember_CustomerCartsByPriceLinesUniqueWhere"]: GraphQLTypes["Contember_CustomerCartsByPriceLinesUniqueWhere"];
	["Contember_CustomerCartsBySummaryUniqueWhere"]: GraphQLTypes["Contember_CustomerCartsBySummaryUniqueWhere"];
	["Contember_CustomerInvoicesByPublicKeyUniqueWhere"]: GraphQLTypes["Contember_CustomerInvoicesByPublicKeyUniqueWhere"];
	["Contember_CustomerInvoicesByFakturoidIdUniqueWhere"]: GraphQLTypes["Contember_CustomerInvoicesByFakturoidIdUniqueWhere"];
	["Contember_CustomerInvoicesByOrderPaymentsUniqueWhere"]: GraphQLTypes["Contember_CustomerInvoicesByOrderPaymentsUniqueWhere"];
	["Contember_CustomerBillingSubjectsByInvoicesUniqueWhere"]: GraphQLTypes["Contember_CustomerBillingSubjectsByInvoicesUniqueWhere"];
	["Contember_CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"]: GraphQLTypes["Contember_CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"];
	["Contember_CustomerBillingSubjectsByOrdersUniqueWhere"]: GraphQLTypes["Contember_CustomerBillingSubjectsByOrdersUniqueWhere"];
	["Contember_CustomerOfferedProductsByProductUniqueWhere"]: GraphQLTypes["Contember_CustomerOfferedProductsByProductUniqueWhere"];
	["Contember_CustomerBoughtVouchersByCodeUniqueWhere"]: GraphQLTypes["Contember_CustomerBoughtVouchersByCodeUniqueWhere"];
	["Contember_CustomerBoughtVouchersByRedemptionUniqueWhere"]: GraphQLTypes["Contember_CustomerBoughtVouchersByRedemptionUniqueWhere"];
	["Contember_CustomerOwnsVouchersByCodeUniqueWhere"]: GraphQLTypes["Contember_CustomerOwnsVouchersByCodeUniqueWhere"];
	["Contember_CustomerOwnsVouchersByRedemptionUniqueWhere"]: GraphQLTypes["Contember_CustomerOwnsVouchersByRedemptionUniqueWhere"];
	["Contember_CustomerRedeemedVouchersByCustomerCreditUniqueWhere"]: GraphQLTypes["Contember_CustomerRedeemedVouchersByCustomerCreditUniqueWhere"];
	["Contember_CustomerRedeemedVouchersByOrderDiscountUniqueWhere"]: GraphQLTypes["Contember_CustomerRedeemedVouchersByOrderDiscountUniqueWhere"];
	["Contember_CustomerRedeemedVouchersByVoucherUniqueWhere"]: GraphQLTypes["Contember_CustomerRedeemedVouchersByVoucherUniqueWhere"];
	["Contember_CustomerCustomerTabsByItemsUniqueWhere"]: GraphQLTypes["Contember_CustomerCustomerTabsByItemsUniqueWhere"];
	["Contember_CustomerCustomerTabsByMetaUniqueWhere"]: GraphQLTypes["Contember_CustomerCustomerTabsByMetaUniqueWhere"];
	["Contember_CustomerLeadByStageItemUniqueWhere"]: GraphQLTypes["Contember_CustomerLeadByStageItemUniqueWhere"];
	["CustomerCreditConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["CustomerCreditEdge"]>
};
	["CustomerCreditEdge"]: {
		node: GraphQLTypes["CustomerCredit"]
};
	["Contember_CustomerPaymentMethodConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerPaymentMethodEdge"]>
};
	["Contember_CustomerPaymentMethodEdge"]: {
		node: GraphQLTypes["Contember_CustomerPaymentMethod"]
};
	["Contember_BillingSubjectConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BillingSubjectEdge"]>
};
	["Contember_BillingSubjectEdge"]: {
		node: GraphQLTypes["Contember_BillingSubject"]
};
	["Contember_VoucherRedemptionConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VoucherRedemptionEdge"]>
};
	["Contember_VoucherRedemptionEdge"]: {
		node: GraphQLTypes["Contember_VoucherRedemption"]
};
	["Contember_Gps"]: {
		_meta?: GraphQLTypes["Contember_GpsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	latitude: number,
	longitude: number,
	address?: GraphQLTypes["Address"] | undefined
};
	["Contember_GpsMeta"]: {
		id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	latitude?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	longitude?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AddressInvoicesByPublicKeyUniqueWhere"]: GraphQLTypes["Contember_AddressInvoicesByPublicKeyUniqueWhere"];
	["Contember_AddressInvoicesByFakturoidIdUniqueWhere"]: GraphQLTypes["Contember_AddressInvoicesByFakturoidIdUniqueWhere"];
	["Contember_AddressInvoicesByOrderPaymentsUniqueWhere"]: GraphQLTypes["Contember_AddressInvoicesByOrderPaymentsUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersBySeqUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersBySeqUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByDiscountsUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByDiscountsUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByDeliveryUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByItemsUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByItemsUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByCartUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByCartUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByEventsUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByEventsUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByPaymentsUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersBySummaryUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersBySummaryUniqueWhere"];
	["Contember_AddressBillingAddressOfOrdersByGratuityUniqueWhere"]: GraphQLTypes["Contember_AddressBillingAddressOfOrdersByGratuityUniqueWhere"];
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: GraphQLTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"];
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: GraphQLTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"];
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"]: GraphQLTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"];
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByEventsUniqueWhere"]: GraphQLTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByEventsUniqueWhere"];
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByLiftagoRideUniqueWhere"]: GraphQLTypes["Contember_AddressDeliveryAddressOfOrderDeliveriesByLiftagoRideUniqueWhere"];
	["OrderDeliveryConnection"]: {
		pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderDeliveryEdge"]>
};
	["OrderDeliveryEdge"]: {
		node: GraphQLTypes["OrderDelivery"]
};
	["Contember__ValidationResult"]: {
		valid: boolean,
	errors: Array<GraphQLTypes["Contember__ValidationError"]>
};
	["Contember__ValidationError"]: {
		path: Array<GraphQLTypes["Contember__PathFragment"]>,
	message: GraphQLTypes["Contember__ValidationMessage"]
};
	["Contember__PathFragment"]:ModelTypes["Contember__FieldPathFragment"] | ModelTypes["Contember__IndexPathFragment"];
	["Contember__FieldPathFragment"]: {
		field: string
};
	["Contember__IndexPathFragment"]: {
		index: number,
	alias?: string | undefined
};
	["Contember__ValidationMessage"]: {
		text: string
};
	["Contember_AddressCreateInput"]: GraphQLTypes["Contember_AddressCreateInput"];
	["Contember_AddressCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutAddressesCreateInput"]: GraphQLTypes["Contember_CustomerWithoutAddressesCreateInput"];
	["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"];
	["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"];
	["Contember_CustomerPaymentMethodCreateInput"]: GraphQLTypes["Contember_CustomerPaymentMethodCreateInput"];
	["Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutPaymentMethodsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutPaymentMethodsCreateInput"];
	["Contember_CustomerCreateAddressesEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"];
	["Contember_AddressWithoutCustomerCreateInput"]: GraphQLTypes["Contember_AddressWithoutCustomerCreateInput"];
	["Contember_AddressCreateCountryEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"];
	["Contember_CountryUniqueWhere"]: GraphQLTypes["Contember_CountryUniqueWhere"];
	["Contember_CountryCreateInput"]: GraphQLTypes["Contember_CountryCreateInput"];
	["Contember_AddressCreateDeliveryZoneEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"];
	["Contember_DeliveryZoneUniqueWhere"]: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"];
	["Contember_DeliveryZoneWithoutAddressesCreateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutAddressesCreateInput"];
	["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"];
	["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"];
	["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"];
	["Contember_PaymentMethodUniqueWhere"]: GraphQLTypes["Contember_PaymentMethodUniqueWhere"];
	["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"]: GraphQLTypes["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"];
	["Contember_PaymentMethodCreateChannelsEntityRelationInput"]: GraphQLTypes["Contember_PaymentMethodCreateChannelsEntityRelationInput"];
	["Contember_ChannelPaymentWithoutMethodCreateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutMethodCreateInput"];
	["Contember_ChannelPaymentCreateChannelEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"];
	["Contember_ChannelWithoutPaymentsCreateInput"]: GraphQLTypes["Contember_ChannelWithoutPaymentsCreateInput"];
	["Contember_ChannelCreateDeliveriesEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"];
	["Contember_ChannelDeliveryWithoutChannelCreateInput"]: GraphQLTypes["Contember_ChannelDeliveryWithoutChannelCreateInput"];
	["Contember_ChannelDeliveryCreateMethodEntityRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryCreateMethodEntityRelationInput"];
	["Contember_DeliveryMethodWithoutChannelsCreateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutChannelsCreateInput"];
	["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"];
	["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"];
	["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneCreateAddressesEntityRelationInput"];
	["Contember_AddressWithoutDeliveryZoneCreateInput"]: GraphQLTypes["Contember_AddressWithoutDeliveryZoneCreateInput"];
	["Contember_AddressCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"];
	["Contember_AddressCreateGpsEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"];
	["Contember_GpsWithoutAddressCreateInput"]: GraphQLTypes["Contember_GpsWithoutAddressCreateInput"];
	["Contember_AddressCreateInvoicesEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateInvoicesEntityRelationInput"];
	["Contember_InvoiceWithoutAddressCreateInput"]: GraphQLTypes["Contember_InvoiceWithoutAddressCreateInput"];
	["Contember_InvoiceCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_InvoiceCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutInvoicesCreateInput"]: GraphQLTypes["Contember_CustomerWithoutInvoicesCreateInput"];
	["Contember_CustomerCreateTagsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"];
	["Contember_TagUniqueWhere"]: GraphQLTypes["Contember_TagUniqueWhere"];
	["Contember_TagWithoutCustomersCreateInput"]: GraphQLTypes["Contember_TagWithoutCustomersCreateInput"];
	["Contember_TagCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"];
	["Contember_ProductWithoutTagsCreateInput"]: GraphQLTypes["Contember_ProductWithoutTagsCreateInput"];
	["Contember_ProductCreatePackingEntityRelationInput"]: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"];
	["Contember_ProductPackingCreateInput"]: GraphQLTypes["Contember_ProductPackingCreateInput"];
	["Contember_ProductPackingCreateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingCreateImageEntityRelationInput"];
	["Contember_ImageCreateInput"]: GraphQLTypes["Contember_ImageCreateInput"];
	["Contember_ProductPackingCreateIconEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingCreateIconEntityRelationInput"];
	["Contember_ProductPackingCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingCreateLocalesEntityRelationInput"];
	["Contember_ProductPackingLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_ProductPackingLocaleWithoutRootCreateInput"];
	["Contember_ProductPackingLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductPackingsCreateInput"]: GraphQLTypes["Contember_LocaleWithoutProductPackingsCreateInput"];
	["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"];
	["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"];
	["Contember_BusinessCategoryLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleCreateRootEntityRelationInput"];
	["Contember_BusinessCategoryWithoutLocalesCreateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutLocalesCreateInput"];
	["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"];
	["Contember_BusinessCustomerWithoutCategoryCreateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutCategoryCreateInput"];
	["Contember_BusinessCustomerCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutBusinessCreateInput"]: GraphQLTypes["Contember_CustomerWithoutBusinessCreateInput"];
	["Contember_CustomerCreateCreditsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"];
	["Contember_CustomerCreditWithoutCustomerCreateInput"]: GraphQLTypes["Contember_CustomerCreditWithoutCustomerCreateInput"];
	["Contember_CustomerCreditCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditCreateVatRateEntityRelationInput"];
	["Contember_VatRateUniqueWhere"]: GraphQLTypes["Contember_VatRateUniqueWhere"];
	["Contember_VatRateCreateInput"]: GraphQLTypes["Contember_VatRateCreateInput"];
	["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"]: GraphQLTypes["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"];
	["Contember_ChannelDeliveryWithoutVatRateCreateInput"]: GraphQLTypes["Contember_ChannelDeliveryWithoutVatRateCreateInput"];
	["Contember_ChannelDeliveryCreateChannelEntityRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryCreateChannelEntityRelationInput"];
	["Contember_ChannelWithoutDeliveriesCreateInput"]: GraphQLTypes["Contember_ChannelWithoutDeliveriesCreateInput"];
	["Contember_ChannelCreatePaymentsEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"];
	["Contember_ChannelPaymentWithoutChannelCreateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutChannelCreateInput"];
	["Contember_ChannelPaymentCreateMethodEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"];
	["Contember_PaymentMethodWithoutChannelsCreateInput"]: GraphQLTypes["Contember_PaymentMethodWithoutChannelsCreateInput"];
	["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"];
	["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"];
	["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"];
	["Contember_ChannelDeliveryWithoutMethodCreateInput"]: GraphQLTypes["Contember_ChannelDeliveryWithoutMethodCreateInput"];
	["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"];
	["Contember_VatRateWithoutChannelDeliveriesCreateInput"]: GraphQLTypes["Contember_VatRateWithoutChannelDeliveriesCreateInput"];
	["Contember_VatRateCreateChannelPaymentsEntityRelationInput"]: GraphQLTypes["Contember_VatRateCreateChannelPaymentsEntityRelationInput"];
	["Contember_ChannelPaymentWithoutVatRateCreateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutVatRateCreateInput"];
	["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"];
	["Contember_TagWithoutAllowChannelPaymentsCreateInput"]: GraphQLTypes["Contember_TagWithoutAllowChannelPaymentsCreateInput"];
	["Contember_TagCreateCustomersEntityRelationInput"]: GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"];
	["Contember_CustomerWithoutTagsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutTagsCreateInput"];
	["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"];
	["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"]: GraphQLTypes["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"];
	["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"]: GraphQLTypes["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"];
	["Contember_PaymentMethodCreateInput"]: GraphQLTypes["Contember_PaymentMethodCreateInput"];
	["Contember_CustomerCreateOrdersEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"];
	["Contember_OrderWithoutCustomerCreateInput"]: GraphQLTypes["Contember_OrderWithoutCustomerCreateInput"];
	["Contember_OrderCreateChannelEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"];
	["Contember_ChannelCreateInput"]: GraphQLTypes["Contember_ChannelCreateInput"];
	["Contember_ChannelCreatePointOfSalesEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"];
	["Contember_PointOfSaleUniqueWhere"]: GraphQLTypes["Contember_PointOfSaleUniqueWhere"];
	["Contember_PointOfSalePermissionsUniqueWhere"]: GraphQLTypes["Contember_PointOfSalePermissionsUniqueWhere"];
	["Contember_PointOfSaleWithoutChannelsCreateInput"]: GraphQLTypes["Contember_PointOfSaleWithoutChannelsCreateInput"];
	["Contember_PointOfSaleCreatePermissionsEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCreatePermissionsEntityRelationInput"];
	["Contember_ChannelCreateCartsEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"];
	["Contember_CartWithoutChannelCreateInput"]: GraphQLTypes["Contember_CartWithoutChannelCreateInput"];
	["Contember_CartCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_CartCreateItemsEntityRelationInput"];
	["Contember_CartItemWithoutCartCreateInput"]: GraphQLTypes["Contember_CartItemWithoutCartCreateInput"];
	["Contember_CartItemCreateProductEntityRelationInput"]: GraphQLTypes["Contember_CartItemCreateProductEntityRelationInput"];
	["Contember_ProductWithoutCartItemsCreateInput"]: GraphQLTypes["Contember_ProductWithoutCartItemsCreateInput"];
	["Contember_ProductCreateRecipeEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"];
	["Contember_ProductRecipeWithoutProductsCreateInput"]: GraphQLTypes["Contember_ProductRecipeWithoutProductsCreateInput"];
	["Contember_ProductRecipeCreateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeCreateImageEntityRelationInput"];
	["Contember_ProductRecipeCreateIconEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeCreateIconEntityRelationInput"];
	["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeCreateIngredientsEntityRelationInput"];
	["Contember_ProductIngredientItemWithoutRecipeCreateInput"]: GraphQLTypes["Contember_ProductIngredientItemWithoutRecipeCreateInput"];
	["Contember_ProductIngredientItemCreateIngredientEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemCreateIngredientEntityRelationInput"];
	["Contember_ProductIngredientCreateInput"]: GraphQLTypes["Contember_ProductIngredientCreateInput"];
	["Contember_ProductIngredientCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"];
	["Contember_ProductIngredientLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_ProductIngredientLocaleWithoutRootCreateInput"];
	["Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductIngredientsCreateInput"]: GraphQLTypes["Contember_LocaleWithoutProductIngredientsCreateInput"];
	["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"];
	["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"];
	["Contember_ProductCategoryListLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleCreateRootEntityRelationInput"];
	["Contember_ProductCategoryListWithoutLocalesCreateInput"]: GraphQLTypes["Contember_ProductCategoryListWithoutLocalesCreateInput"];
	["Contember_ProductCategoryListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListCreateItemsEntityRelationInput"];
	["Contember_ProductCategoryListItemWithoutListCreateInput"]: GraphQLTypes["Contember_ProductCategoryListItemWithoutListCreateInput"];
	["Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput"];
	["Contember_ProductCategoryCreateInput"]: GraphQLTypes["Contember_ProductCategoryCreateInput"];
	["Contember_ProductCategoryCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"];
	["Contember_ProductWithoutCategoriesCreateInput"]: GraphQLTypes["Contember_ProductWithoutCategoriesCreateInput"];
	["Contember_ProductCreateTagsEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"];
	["Contember_TagWithoutProductsCreateInput"]: GraphQLTypes["Contember_TagWithoutProductsCreateInput"];
	["Contember_TagCreateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"];
	["Contember_ProductCategoryWithoutTagsCreateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutTagsCreateInput"];
	["Contember_ProductCategoryCreateParentEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateParentEntityRelationInput"];
	["Contember_ProductCategoryWithoutChildrenCreateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutChildrenCreateInput"];
	["Contember_ProductCategoryCreateTagsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"];
	["Contember_TagWithoutCategoriesCreateInput"]: GraphQLTypes["Contember_TagWithoutCategoriesCreateInput"];
	["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]: GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"];
	["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"]: GraphQLTypes["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"];
	["Contember_ChannelCreateCustomersEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"];
	["Contember_CustomerWithoutChannelsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutChannelsCreateInput"];
	["Contember_CustomerCreatePermissionsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"];
	["Contember_CustomerCreateGroupEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"];
	["Contember_CustomerGroupWithoutCustomersCreateInput"]: GraphQLTypes["Contember_CustomerGroupWithoutCustomersCreateInput"];
	["Contember_CustomerGroupCreateProductPricesEntityRelationInput"]: GraphQLTypes["Contember_CustomerGroupCreateProductPricesEntityRelationInput"];
	["Contember_ProductGroupPriceWithoutGroupCreateInput"]: GraphQLTypes["Contember_ProductGroupPriceWithoutGroupCreateInput"];
	["Contember_ProductGroupPriceCreateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductGroupPriceCreateProductEntityRelationInput"];
	["Contember_ProductWithoutGroupPricesCreateInput"]: GraphQLTypes["Contember_ProductWithoutGroupPricesCreateInput"];
	["Contember_ProductCreateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"];
	["Contember_ProductCategoryWithoutProductsCreateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutProductsCreateInput"];
	["Contember_ProductCategoryCreateChildrenEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"];
	["Contember_ProductCategoryWithoutParentCreateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutParentCreateInput"];
	["Contember_ProductCategoryCreateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateImageEntityRelationInput"];
	["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"];
	["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"];
	["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"];
	["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"];
	["Contember_ProductCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"];
	["Contember_ProductCreateStocksEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"];
	["Contember_ProductStockWithoutProductCreateInput"]: GraphQLTypes["Contember_ProductStockWithoutProductCreateInput"];
	["Contember_ProductStockCreateStoreEntityRelationInput"]: GraphQLTypes["Contember_ProductStockCreateStoreEntityRelationInput"];
	["Contember_StoreCreateInput"]: GraphQLTypes["Contember_StoreCreateInput"];
	["Contember_ProductCreateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"];
	["Contember_ProductCreateCartItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"];
	["Contember_CartItemWithoutProductCreateInput"]: GraphQLTypes["Contember_CartItemWithoutProductCreateInput"];
	["Contember_CartItemCreateCartEntityRelationInput"]: GraphQLTypes["Contember_CartItemCreateCartEntityRelationInput"];
	["Contember_CartWithoutItemsCreateInput"]: GraphQLTypes["Contember_CartWithoutItemsCreateInput"];
	["Contember_CartCreateChannelEntityRelationInput"]: GraphQLTypes["Contember_CartCreateChannelEntityRelationInput"];
	["Contember_ChannelWithoutCartsCreateInput"]: GraphQLTypes["Contember_ChannelWithoutCartsCreateInput"];
	["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"];
	["Contember_TagWithoutAddTagsOnRegistrationCreateInput"]: GraphQLTypes["Contember_TagWithoutAddTagsOnRegistrationCreateInput"];
	["Contember_TagCreateNestedCategoriesEntityRelationInput"]: GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"];
	["Contember_TagCreateDeliveryZonesEntityRelationInput"]: GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"];
	["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"];
	["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"];
	["Contember_ProductWithoutDeliveryZonesCreateInput"]: GraphQLTypes["Contember_ProductWithoutDeliveryZonesCreateInput"];
	["Contember_ProductCreateGroupPricesEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"];
	["Contember_ProductGroupPriceWithoutProductCreateInput"]: GraphQLTypes["Contember_ProductGroupPriceWithoutProductCreateInput"];
	["Contember_ProductGroupPriceCreateGroupEntityRelationInput"]: GraphQLTypes["Contember_ProductGroupPriceCreateGroupEntityRelationInput"];
	["Contember_CustomerGroupWithoutProductPricesCreateInput"]: GraphQLTypes["Contember_CustomerGroupWithoutProductPricesCreateInput"];
	["Contember_CustomerGroupCreateCustomersEntityRelationInput"]: GraphQLTypes["Contember_CustomerGroupCreateCustomersEntityRelationInput"];
	["Contember_CustomerWithoutGroupCreateInput"]: GraphQLTypes["Contember_CustomerWithoutGroupCreateInput"];
	["Contember_CustomerCreatePhotoEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"];
	["Contember_CustomerCreateNameEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"];
	["Contember_CustomerNameWithoutCustomerCreateInput"]: GraphQLTypes["Contember_CustomerNameWithoutCustomerCreateInput"];
	["Contember_CustomerNameCreateAliasesEntityRelationInput"]: GraphQLTypes["Contember_CustomerNameCreateAliasesEntityRelationInput"];
	["Contember_CustomerAliasUniqueWhere"]: GraphQLTypes["Contember_CustomerAliasUniqueWhere"];
	["Contember_CustomerAliasCreateInput"]: GraphQLTypes["Contember_CustomerAliasCreateInput"];
	["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"];
	["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"]: GraphQLTypes["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"];
	["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"];
	["Contember_ProductIngredientRatingUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientRatingUniqueWhere"];
	["Contember_ProductIngredientRatingCreateInput"]: GraphQLTypes["Contember_ProductIngredientRatingCreateInput"];
	["Contember_ProductIngredientRatingCreateIconEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientRatingCreateIconEntityRelationInput"];
	["Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput"];
	["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"];
	["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"];
	["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"];
	["Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput"];
	["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"];
	["Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"];
	["Contember_LocaleCreateProductIngredientsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"];
	["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"];
	["Contember_ProductIngredientLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientLocaleCreateRootEntityRelationInput"];
	["Contember_ProductIngredientWithoutLocalesCreateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutLocalesCreateInput"];
	["Contember_ProductIngredientCreateAllergensEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"];
	["Contember_AllergenWithoutIngredientsCreateInput"]: GraphQLTypes["Contember_AllergenWithoutIngredientsCreateInput"];
	["Contember_AllergenCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_AllergenCreateLocalesEntityRelationInput"];
	["Contember_AllergenLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_AllergenLocaleWithoutRootCreateInput"];
	["Contember_AllergenLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_AllergenLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutAllergensCreateInput"]: GraphQLTypes["Contember_LocaleWithoutAllergensCreateInput"];
	["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"];
	["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"];
	["Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput"];
	["Contember_OrderRecurrenceWithoutLocalesCreateInput"]: GraphQLTypes["Contember_OrderRecurrenceWithoutLocalesCreateInput"];
	["Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutRecurrencesCreateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutRecurrencesCreateInput"];
	["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"];
	["Contember_BusinessCategoryLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutRootCreateInput"];
	["Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutBusinessCategoriesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutBusinessCategoriesCreateInput"];
	["Contember_LocaleCreateBlogsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"];
	["Contember_BlogLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_BlogLocaleWithoutLocaleCreateInput"];
	["Contember_BlogLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_BlogLocaleCreateRootEntityRelationInput"];
	["Contember_BlogWithoutLocalesCreateInput"]: GraphQLTypes["Contember_BlogWithoutLocalesCreateInput"];
	["Contember_LocaleCreateBlogPostsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"];
	["Contember_BlogPostLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_BlogPostLocaleWithoutLocaleCreateInput"];
	["Contember_BlogPostLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateRootEntityRelationInput"];
	["Contember_BlogPostWithoutLocalesCreateInput"]: GraphQLTypes["Contember_BlogPostWithoutLocalesCreateInput"];
	["Contember_BlogPostLocaleCreateCoverEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateCoverEntityRelationInput"];
	["Contember_CoverCreateInput"]: GraphQLTypes["Contember_CoverCreateInput"];
	["Contember_CoverCreateMediumEntityRelationInput"]: GraphQLTypes["Contember_CoverCreateMediumEntityRelationInput"];
	["Contember_MediumUniqueWhere"]: GraphQLTypes["Contember_MediumUniqueWhere"];
	["Contember_MediumCreateInput"]: GraphQLTypes["Contember_MediumCreateInput"];
	["Contember_MediumCreateImageEntityRelationInput"]: GraphQLTypes["Contember_MediumCreateImageEntityRelationInput"];
	["Contember_MediumCreateVideoEntityRelationInput"]: GraphQLTypes["Contember_MediumCreateVideoEntityRelationInput"];
	["Contember_VideoUniqueWhere"]: GraphQLTypes["Contember_VideoUniqueWhere"];
	["Contember_VideoCreateInput"]: GraphQLTypes["Contember_VideoCreateInput"];
	["Contember_VideoCreatePosterEntityRelationInput"]: GraphQLTypes["Contember_VideoCreatePosterEntityRelationInput"];
	["Contember_CoverCreateButtonColorsEntityRelationInput"]: GraphQLTypes["Contember_CoverCreateButtonColorsEntityRelationInput"];
	["Contember_ColorPalleteUniqueWhere"]: GraphQLTypes["Contember_ColorPalleteUniqueWhere"];
	["Contember_ColorPalleteCreateInput"]: GraphQLTypes["Contember_ColorPalleteCreateInput"];
	["Contember_BlogPostLocaleCreateContentEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateContentEntityRelationInput"];
	["Contember_ContentCreateInput"]: GraphQLTypes["Contember_ContentCreateInput"];
	["Contember_ContentCreateBlocksEntityRelationInput"]: GraphQLTypes["Contember_ContentCreateBlocksEntityRelationInput"];
	["Contember_ContentBlockWithoutContentCreateInput"]: GraphQLTypes["Contember_ContentBlockWithoutContentCreateInput"];
	["Contember_ContentBlockCreateReferencesEntityRelationInput"]: GraphQLTypes["Contember_ContentBlockCreateReferencesEntityRelationInput"];
	["Contember_ContentReferenceWithoutBlockCreateInput"]: GraphQLTypes["Contember_ContentReferenceWithoutBlockCreateInput"];
	["Contember_ContentReferenceCreateColorPalleteEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateColorPalleteEntityRelationInput"];
	["Contember_ContentReferenceCreateMediumEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateMediumEntityRelationInput"];
	["Contember_ContentReferenceCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateLinkEntityRelationInput"];
	["Contember_LinkCreateInput"]: GraphQLTypes["Contember_LinkCreateInput"];
	["Contember_LinkCreateInternalLinkEntityRelationInput"]: GraphQLTypes["Contember_LinkCreateInternalLinkEntityRelationInput"];
	["Contember_LinkableCreateInput"]: GraphQLTypes["Contember_LinkableCreateInput"];
	["Contember_LinkableCreateBlogPostEntityRelationInput"]: GraphQLTypes["Contember_LinkableCreateBlogPostEntityRelationInput"];
	["Contember_BlogPostLocaleWithoutLinkCreateInput"]: GraphQLTypes["Contember_BlogPostLocaleWithoutLinkCreateInput"];
	["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutBlogPostsCreateInput"]: GraphQLTypes["Contember_LocaleWithoutBlogPostsCreateInput"];
	["Contember_LocaleCreateIdentifierEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"];
	["Contember_TranslationCataloguesIdentifierCreateInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierCreateInput"];
	["Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput"];
	["Contember_TranslationCatalogueWithoutIdentifierCreateInput"]: GraphQLTypes["Contember_TranslationCatalogueWithoutIdentifierCreateInput"];
	["Contember_TranslationCatalogueCreateDomainEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueCreateDomainEntityRelationInput"];
	["Contember_TranslationDomainWithoutCataloguesCreateInput"]: GraphQLTypes["Contember_TranslationDomainWithoutCataloguesCreateInput"];
	["Contember_TranslationDomainCreateKeysEntityRelationInput"]: GraphQLTypes["Contember_TranslationDomainCreateKeysEntityRelationInput"];
	["Contember_TranslationKeyWithoutDomainCreateInput"]: GraphQLTypes["Contember_TranslationKeyWithoutDomainCreateInput"];
	["Contember_TranslationKeyCreateValuesEntityRelationInput"]: GraphQLTypes["Contember_TranslationKeyCreateValuesEntityRelationInput"];
	["Contember_TranslationValueWithoutKeyCreateInput"]: GraphQLTypes["Contember_TranslationValueWithoutKeyCreateInput"];
	["Contember_TranslationValueCreateCatalogueEntityRelationInput"]: GraphQLTypes["Contember_TranslationValueCreateCatalogueEntityRelationInput"];
	["Contember_TranslationCatalogueWithoutValuesCreateInput"]: GraphQLTypes["Contember_TranslationCatalogueWithoutValuesCreateInput"];
	["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"];
	["Contember_TranslationCatalogueCreateInput"]: GraphQLTypes["Contember_TranslationCatalogueCreateInput"];
	["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueCreateValuesEntityRelationInput"];
	["Contember_TranslationValueWithoutCatalogueCreateInput"]: GraphQLTypes["Contember_TranslationValueWithoutCatalogueCreateInput"];
	["Contember_TranslationValueCreateKeyEntityRelationInput"]: GraphQLTypes["Contember_TranslationValueCreateKeyEntityRelationInput"];
	["Contember_TranslationKeyWithoutValuesCreateInput"]: GraphQLTypes["Contember_TranslationKeyWithoutValuesCreateInput"];
	["Contember_TranslationKeyCreateDomainEntityRelationInput"]: GraphQLTypes["Contember_TranslationKeyCreateDomainEntityRelationInput"];
	["Contember_TranslationDomainWithoutKeysCreateInput"]: GraphQLTypes["Contember_TranslationDomainWithoutKeysCreateInput"];
	["Contember_TranslationDomainCreateCataloguesEntityRelationInput"]: GraphQLTypes["Contember_TranslationDomainCreateCataloguesEntityRelationInput"];
	["Contember_TranslationCatalogueWithoutDomainCreateInput"]: GraphQLTypes["Contember_TranslationCatalogueWithoutDomainCreateInput"];
	["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"];
	["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"];
	["Contember_LocaleCreateMenusEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"];
	["Contember_MenuLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_MenuLocaleWithoutLocaleCreateInput"];
	["Contember_MenuLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleCreateRootEntityRelationInput"];
	["Contember_SiteMenuWithoutLocalesCreateInput"]: GraphQLTypes["Contember_SiteMenuWithoutLocalesCreateInput"];
	["Contember_MenuLocaleCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleCreateItemsEntityRelationInput"];
	["Contember_MenuItemWithoutMenuCreateInput"]: GraphQLTypes["Contember_MenuItemWithoutMenuCreateInput"];
	["Contember_MenuItemCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_MenuItemCreateLinkEntityRelationInput"];
	["Contember_MenuItemCreateSubitemsEntityRelationInput"]: GraphQLTypes["Contember_MenuItemCreateSubitemsEntityRelationInput"];
	["Contember_MenuSubitemWithoutItemCreateInput"]: GraphQLTypes["Contember_MenuSubitemWithoutItemCreateInput"];
	["Contember_MenuSubitemCreateLinksEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemCreateLinksEntityRelationInput"];
	["Contember_MenuLinkListUniqueWhere"]: GraphQLTypes["Contember_MenuLinkListUniqueWhere"];
	["Contember_MenuLinkItemUniqueWhere"]: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"];
	["Contember_MenuLinkListCreateInput"]: GraphQLTypes["Contember_MenuLinkListCreateInput"];
	["Contember_MenuLinkListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_MenuLinkListCreateItemsEntityRelationInput"];
	["Contember_MenuLinkItemWithoutListCreateInput"]: GraphQLTypes["Contember_MenuLinkItemWithoutListCreateInput"];
	["Contember_MenuLinkItemCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_MenuLinkItemCreateLinkEntityRelationInput"];
	["Contember_MenuLinkItemCreateImageEntityRelationInput"]: GraphQLTypes["Contember_MenuLinkItemCreateImageEntityRelationInput"];
	["Contember_MenuSubitemCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemCreateProductsEntityRelationInput"];
	["Contember_MenuProductsUniqueWhere"]: GraphQLTypes["Contember_MenuProductsUniqueWhere"];
	["Contember_MenuProductsCreateInput"]: GraphQLTypes["Contember_MenuProductsCreateInput"];
	["Contember_MenuProductsCreateLinksEntityRelationInput"]: GraphQLTypes["Contember_MenuProductsCreateLinksEntityRelationInput"];
	["Contember_MenuProductsCreateButtonsEntityRelationInput"]: GraphQLTypes["Contember_MenuProductsCreateButtonsEntityRelationInput"];
	["Contember_LinkListCreateInput"]: GraphQLTypes["Contember_LinkListCreateInput"];
	["Contember_LinkListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_LinkListCreateItemsEntityRelationInput"];
	["Contember_LinkListItemWithoutListCreateInput"]: GraphQLTypes["Contember_LinkListItemWithoutListCreateInput"];
	["Contember_LinkListItemCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_LinkListItemCreateLinkEntityRelationInput"];
	["Contember_MenuSubitemCreateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemCreateDeliveryEntityRelationInput"];
	["Contember_MenuDeliveryUniqueWhere"]: GraphQLTypes["Contember_MenuDeliveryUniqueWhere"];
	["Contember_MenuDeliveryCreateInput"]: GraphQLTypes["Contember_MenuDeliveryCreateInput"];
	["Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput"]: GraphQLTypes["Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput"];
	["Contember_DeliveryRegionsCreateInput"]: GraphQLTypes["Contember_DeliveryRegionsCreateInput"];
	["Contember_DeliveryRegionsCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsCreateItemsEntityRelationInput"];
	["Contember_DeliveryRegionsItemWithoutListCreateInput"]: GraphQLTypes["Contember_DeliveryRegionsItemWithoutListCreateInput"];
	["Contember_DeliveryRegionsItemCreateImageEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemCreateImageEntityRelationInput"];
	["Contember_DeliveryRegionsItemCreateTextListEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemCreateTextListEntityRelationInput"];
	["Contember_TextListCreateInput"]: GraphQLTypes["Contember_TextListCreateInput"];
	["Contember_TextListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_TextListCreateItemsEntityRelationInput"];
	["Contember_TextItemWithoutListCreateInput"]: GraphQLTypes["Contember_TextItemWithoutListCreateInput"];
	["Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput"];
	["Contember_ImageListCreateInput"]: GraphQLTypes["Contember_ImageListCreateInput"];
	["Contember_ImageListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_ImageListCreateItemsEntityRelationInput"];
	["Contember_ImageListItemWithoutListCreateInput"]: GraphQLTypes["Contember_ImageListItemWithoutListCreateInput"];
	["Contember_ImageListItemCreateImageEntityRelationInput"]: GraphQLTypes["Contember_ImageListItemCreateImageEntityRelationInput"];
	["Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput"]: GraphQLTypes["Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput"];
	["Contember_SocialsAndAppsUniqueWhere"]: GraphQLTypes["Contember_SocialsAndAppsUniqueWhere"];
	["Contember_SocialsAndAppsCreateInput"]: GraphQLTypes["Contember_SocialsAndAppsCreateInput"];
	["Contember_MenuSubitemCreateContactEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemCreateContactEntityRelationInput"];
	["Contember_MenuContactUniqueWhere"]: GraphQLTypes["Contember_MenuContactUniqueWhere"];
	["Contember_MenuContactCreateInput"]: GraphQLTypes["Contember_MenuContactCreateInput"];
	["Contember_MenuContactCreateImageEntityRelationInput"]: GraphQLTypes["Contember_MenuContactCreateImageEntityRelationInput"];
	["Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput"];
	["Contember_MenuLocaleCreateEshopLinkEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleCreateEshopLinkEntityRelationInput"];
	["Contember_LocaleCreatePagesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"];
	["Contember_PageLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_PageLocaleWithoutLocaleCreateInput"];
	["Contember_PageLocaleCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleCreateLinkEntityRelationInput"];
	["Contember_LinkableWithoutPageCreateInput"]: GraphQLTypes["Contember_LinkableWithoutPageCreateInput"];
	["Contember_LinkableCreateWikiPageEntityRelationInput"]: GraphQLTypes["Contember_LinkableCreateWikiPageEntityRelationInput"];
	["Contember_WikiPageWithoutLinkCreateInput"]: GraphQLTypes["Contember_WikiPageWithoutLinkCreateInput"];
	["Contember_WikiPageCreateParentEntityRelationInput"]: GraphQLTypes["Contember_WikiPageCreateParentEntityRelationInput"];
	["Contember_WikiPageWithoutChildrenCreateInput"]: GraphQLTypes["Contember_WikiPageWithoutChildrenCreateInput"];
	["Contember_WikiPageCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_WikiPageCreateLinkEntityRelationInput"];
	["Contember_LinkableWithoutWikiPageCreateInput"]: GraphQLTypes["Contember_LinkableWithoutWikiPageCreateInput"];
	["Contember_LinkableCreatePageEntityRelationInput"]: GraphQLTypes["Contember_LinkableCreatePageEntityRelationInput"];
	["Contember_PageLocaleWithoutLinkCreateInput"]: GraphQLTypes["Contember_PageLocaleWithoutLinkCreateInput"];
	["Contember_PageLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleCreateRootEntityRelationInput"];
	["Contember_PageWithoutLocalesCreateInput"]: GraphQLTypes["Contember_PageWithoutLocalesCreateInput"];
	["Contember_PageLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPagesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutPagesCreateInput"];
	["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"];
	["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"];
	["Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput"];
	["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"];
	["Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput"];
	["Contember_ProductIngredientWithoutCategoriesCreateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutCategoriesCreateInput"];
	["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"];
	["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"]: GraphQLTypes["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"];
	["Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutIngredientRatingsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutIngredientRatingsCreateInput"];
	["Contember_CustomerCreateBusinessEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"];
	["Contember_BusinessCustomerWithoutCustomerCreateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutCustomerCreateInput"];
	["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"];
	["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"]: GraphQLTypes["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"];
	["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"];
	["Contember_BusinessCustomerWithoutMembersCreateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutMembersCreateInput"];
	["Contember_BusinessCustomerCreateCategoryEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"];
	["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"];
	["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"]: GraphQLTypes["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"];
	["Contember_OrderRecurrenceCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceCreateLocalesEntityRelationInput"];
	["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"];
	["Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutOrderRecurrencesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutOrderRecurrencesCreateInput"];
	["Contember_LocaleCreateAllergensEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"];
	["Contember_AllergenLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_AllergenLocaleWithoutLocaleCreateInput"];
	["Contember_AllergenLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_AllergenLocaleCreateRootEntityRelationInput"];
	["Contember_AllergenWithoutLocalesCreateInput"]: GraphQLTypes["Contember_AllergenWithoutLocalesCreateInput"];
	["Contember_AllergenCreateIngredientsEntityRelationInput"]: GraphQLTypes["Contember_AllergenCreateIngredientsEntityRelationInput"];
	["Contember_ProductIngredientWithoutAllergensCreateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutAllergensCreateInput"];
	["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"];
	["Contember_ProductIngredientItemQuantityUnitUniqueWhere"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"];
	["Contember_ProductIngredientItemQuantityUnitCreateInput"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"];
	["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"];
	["Contember_FreshingContainerUniqueWhere"]: GraphQLTypes["Contember_FreshingContainerUniqueWhere"];
	["Contember_FreshingContainerCreateInput"]: GraphQLTypes["Contember_FreshingContainerCreateInput"];
	["Contember_FreshingContainerCreateWorkspaceEntityRelationInput"]: GraphQLTypes["Contember_FreshingContainerCreateWorkspaceEntityRelationInput"];
	["Contember_WorkspaceUniqueWhere"]: GraphQLTypes["Contember_WorkspaceUniqueWhere"];
	["Contember_WeekHoursUniqueWhere"]: GraphQLTypes["Contember_WeekHoursUniqueWhere"];
	["Contember_WorkspaceWithoutFreshingContainersCreateInput"]: GraphQLTypes["Contember_WorkspaceWithoutFreshingContainersCreateInput"];
	["Contember_WorkspaceCreateOpeningHoursEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceCreateOpeningHoursEntityRelationInput"];
	["Contember_WeekHoursCreateInput"]: GraphQLTypes["Contember_WeekHoursCreateInput"];
	["Contember_WorkspaceCreateWorkingHoursEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceCreateWorkingHoursEntityRelationInput"];
	["Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput"];
	["Contember_WorkspaceCreateCreditsProductEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceCreateCreditsProductEntityRelationInput"];
	["Contember_VirtualProductCreateInput"]: GraphQLTypes["Contember_VirtualProductCreateInput"];
	["Contember_VirtualProductCreateProductEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductCreateProductEntityRelationInput"];
	["Contember_ProductWithoutVirtualProductCreateInput"]: GraphQLTypes["Contember_ProductWithoutVirtualProductCreateInput"];
	["Contember_ProductCreateManagerEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"];
	["Contember_StocksManagerWithoutManagedProductsCreateInput"]: GraphQLTypes["Contember_StocksManagerWithoutManagedProductsCreateInput"];
	["Contember_StocksManagerCreateStaffEntityRelationInput"]: GraphQLTypes["Contember_StocksManagerCreateStaffEntityRelationInput"];
	["Contember_StaffWithoutStocksManagerCreateInput"]: GraphQLTypes["Contember_StaffWithoutStocksManagerCreateInput"];
	["Contember_StaffCreateBartenderEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"];
	["Contember_BartenderWithoutStaffCreateInput"]: GraphQLTypes["Contember_BartenderWithoutStaffCreateInput"];
	["Contember_StaffCreateCourierEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"];
	["Contember_CourierWithoutStaffCreateInput"]: GraphQLTypes["Contember_CourierWithoutStaffCreateInput"];
	["Contember_CourierCreateRoutesEntityRelationInput"]: GraphQLTypes["Contember_CourierCreateRoutesEntityRelationInput"];
	["Contember_DeliveryRouteWithoutCourierCreateInput"]: GraphQLTypes["Contember_DeliveryRouteWithoutCourierCreateInput"];
	["Contember_DeliveryRouteCreateWaypointsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRouteCreateWaypointsEntityRelationInput"];
	["Contember_DeliveryWaypointWithoutRouteCreateInput"]: GraphQLTypes["Contember_DeliveryWaypointWithoutRouteCreateInput"];
	["Contember_DeliveryWaypointCreateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_DeliveryWaypointCreateDeliveryEntityRelationInput"];
	["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"];
	["Contember_OrderDeliveryCreateMethodEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"];
	["Contember_DeliveryMethodCreateInput"]: GraphQLTypes["Contember_DeliveryMethodCreateInput"];
	["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"];
	["Contember_CourierWithoutDeliveryMethodsCreateInput"]: GraphQLTypes["Contember_CourierWithoutDeliveryMethodsCreateInput"];
	["Contember_CourierCreateStaffEntityRelationInput"]: GraphQLTypes["Contember_CourierCreateStaffEntityRelationInput"];
	["Contember_StaffWithoutCourierCreateInput"]: GraphQLTypes["Contember_StaffWithoutCourierCreateInput"];
	["Contember_StaffCreateCleanerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"];
	["Contember_CleanerWithoutStaffCreateInput"]: GraphQLTypes["Contember_CleanerWithoutStaffCreateInput"];
	["Contember_StaffCreateShiftsManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"];
	["Contember_ShiftsManagerWithoutStaffCreateInput"]: GraphQLTypes["Contember_ShiftsManagerWithoutStaffCreateInput"];
	["Contember_ShiftsManagerCreateManagedRolesEntityRelationInput"]: GraphQLTypes["Contember_ShiftsManagerCreateManagedRolesEntityRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"];
	["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"];
	["Contember_TimetableShiftGroupWithoutTypeCreateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutTypeCreateInput"];
	["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"];
	["Contember_TimetableShiftWithoutGroupCreateInput"]: GraphQLTypes["Contember_TimetableShiftWithoutGroupCreateInput"];
	["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"];
	["Contember_ShiftsProfileWithoutShiftsCreateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutShiftsCreateInput"];
	["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"];
	["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"]: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"];
	["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"];
	["Contember_EmployeeQueueWithoutItemsCreateInput"]: GraphQLTypes["Contember_EmployeeQueueWithoutItemsCreateInput"];
	["Contember_EmployeeQueueCreateShiftGroupEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueCreateShiftGroupEntityRelationInput"];
	["Contember_TimetableShiftGroupWithoutQueueCreateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutQueueCreateInput"];
	["Contember_TimetableShiftGroupCreateDayEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"];
	["Contember_TimetableDayWithoutShiftGroupsCreateInput"]: GraphQLTypes["Contember_TimetableDayWithoutShiftGroupsCreateInput"];
	["Contember_TimetableDayCreateNotesEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayCreateNotesEntityRelationInput"];
	["Contember_TimetableDayNoteWithoutDayCreateInput"]: GraphQLTypes["Contember_TimetableDayNoteWithoutDayCreateInput"];
	["Contember_TimetableDayNoteCreateRoleEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayNoteCreateRoleEntityRelationInput"];
	["Contember_TimetableEmployeeRoleCreateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleCreateInput"];
	["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"];
	["Contember_ShiftsProfileWithoutRolesCreateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutRolesCreateInput"];
	["Contember_ShiftsProfileCreateStaffEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"];
	["Contember_StaffWithoutShiftsProfileCreateInput"]: GraphQLTypes["Contember_StaffWithoutShiftsProfileCreateInput"];
	["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"];
	["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"];
	["Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput"];
	["Contember_BusinessCustomerWithoutAccountManagerCreateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutAccountManagerCreateInput"];
	["Contember_BusinessCustomerCreateMembersEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"];
	["Contember_CustomerWithoutMemberOfBusinessesCreateInput"]: GraphQLTypes["Contember_CustomerWithoutMemberOfBusinessesCreateInput"];
	["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"];
	["Contember_BusinessCustomerWithoutOwnersCreateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutOwnersCreateInput"];
	["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"];
	["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"];
	["Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput"];
	["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"]: GraphQLTypes["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"];
	["Contember_StaffCreateUserEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"];
	["Contember_UserWithoutStaffCreateInput"]: GraphQLTypes["Contember_UserWithoutStaffCreateInput"];
	["Contember_UserCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_UserCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutUserCreateInput"]: GraphQLTypes["Contember_CustomerWithoutUserCreateInput"];
	["Contember_CustomerCreateCartsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"];
	["Contember_CartWithoutCustomerCreateInput"]: GraphQLTypes["Contember_CartWithoutCustomerCreateInput"];
	["Contember_CartCreateOrderEntityRelationInput"]: GraphQLTypes["Contember_CartCreateOrderEntityRelationInput"];
	["Contember_OrderWithoutCartCreateInput"]: GraphQLTypes["Contember_OrderWithoutCartCreateInput"];
	["Contember_OrderCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutOrdersCreateInput"]: GraphQLTypes["Contember_CustomerWithoutOrdersCreateInput"];
	["Contember_CustomerCreateShortcutListEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"];
	["Contember_ApplicationShortcutListWithoutCustomerCreateInput"]: GraphQLTypes["Contember_ApplicationShortcutListWithoutCustomerCreateInput"];
	["Contember_ApplicationShortcutListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_ApplicationShortcutListCreateItemsEntityRelationInput"];
	["Contember_ApplicationShortcutListItemWithoutListCreateInput"]: GraphQLTypes["Contember_ApplicationShortcutListItemWithoutListCreateInput"];
	["Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput"]: GraphQLTypes["Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput"];
	["Contember_ApplicationUniqueWhere"]: GraphQLTypes["Contember_ApplicationUniqueWhere"];
	["Contember_ApplicationCreateInput"]: GraphQLTypes["Contember_ApplicationCreateInput"];
	["Contember_ApplicationCreateIconEntityRelationInput"]: GraphQLTypes["Contember_ApplicationCreateIconEntityRelationInput"];
	["Contember_CustomerCreateUserEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"];
	["Contember_UserWithoutCustomerCreateInput"]: GraphQLTypes["Contember_UserWithoutCustomerCreateInput"];
	["Contember_UserCreateStaffEntityRelationInput"]: GraphQLTypes["Contember_UserCreateStaffEntityRelationInput"];
	["Contember_StaffWithoutUserCreateInput"]: GraphQLTypes["Contember_StaffWithoutUserCreateInput"];
	["Contember_StaffCreateShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"];
	["Contember_ShiftsProfileWithoutStaffCreateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutStaffCreateInput"];
	["Contember_ShiftsProfileCreateRolesEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"];
	["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"];
	["Contember_ShiftsManagerWithoutManagedRolesCreateInput"]: GraphQLTypes["Contember_ShiftsManagerWithoutManagedRolesCreateInput"];
	["Contember_ShiftsManagerCreateStaffEntityRelationInput"]: GraphQLTypes["Contember_ShiftsManagerCreateStaffEntityRelationInput"];
	["Contember_StaffWithoutShiftsManagerCreateInput"]: GraphQLTypes["Contember_StaffWithoutShiftsManagerCreateInput"];
	["Contember_StaffCreateStocksManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"];
	["Contember_StocksManagerWithoutStaffCreateInput"]: GraphQLTypes["Contember_StocksManagerWithoutStaffCreateInput"];
	["Contember_StocksManagerCreateManagedProductsEntityRelationInput"]: GraphQLTypes["Contember_StocksManagerCreateManagedProductsEntityRelationInput"];
	["Contember_ProductWithoutManagerCreateInput"]: GraphQLTypes["Contember_ProductWithoutManagerCreateInput"];
	["Contember_ProductCreateOrderItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"];
	["Contember_OrderItemWithoutProductCreateInput"]: GraphQLTypes["Contember_OrderItemWithoutProductCreateInput"];
	["Contember_OrderItemCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderItemCreateVatRateEntityRelationInput"];
	["Contember_OrderItemCreateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderItemCreateOrderEntityRelationInput"];
	["Contember_OrderWithoutItemsCreateInput"]: GraphQLTypes["Contember_OrderWithoutItemsCreateInput"];
	["Contember_OrderCreateDiscountsEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"];
	["Contember_OrderDiscountWithoutOrderCreateInput"]: GraphQLTypes["Contember_OrderDiscountWithoutOrderCreateInput"];
	["Contember_OrderDiscountCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountCreateVatRateEntityRelationInput"];
	["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"];
	["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"]: GraphQLTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"];
	["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"];
	["Contember_CustomerCreditWithoutTransactionsCreateInput"]: GraphQLTypes["Contember_CustomerCreditWithoutTransactionsCreateInput"];
	["Contember_CustomerCreditCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutCreditsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutCreditsCreateInput"];
	["Contember_CustomerCreateChannelsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"];
	["Contember_ChannelWithoutCustomersCreateInput"]: GraphQLTypes["Contember_ChannelWithoutCustomersCreateInput"];
	["Contember_ChannelCreatePublicTagsEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"];
	["Contember_TagWithoutPublicInChannelsCreateInput"]: GraphQLTypes["Contember_TagWithoutPublicInChannelsCreateInput"];
	["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]: GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"];
	["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"];
	["Contember_ChannelPaymentCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"];
	["Contember_VatRateWithoutChannelPaymentsCreateInput"]: GraphQLTypes["Contember_VatRateWithoutChannelPaymentsCreateInput"];
	["Contember_TagCreateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"];
	["Contember_FulfillmentNoteUniqueWhere"]: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"];
	["Contember_FulfillmentNoteWithoutTagsCreateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutTagsCreateInput"];
	["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"];
	["Contember_ProductWithoutFulfillmentNotesCreateInput"]: GraphQLTypes["Contember_ProductWithoutFulfillmentNotesCreateInput"];
	["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"];
	["Contember_ProductCreateDeliveryZonesEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"];
	["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"];
	["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"];
	["Contember_TagWithoutDeliveryZonesCreateInput"]: GraphQLTypes["Contember_TagWithoutDeliveryZonesCreateInput"];
	["Contember_TagCreatePublicInChannelsEntityRelationInput"]: GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"];
	["Contember_ChannelWithoutPublicTagsCreateInput"]: GraphQLTypes["Contember_ChannelWithoutPublicTagsCreateInput"];
	["Contember_ChannelCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutChannelsCreateInput"]: GraphQLTypes["Contember_VenueWithoutChannelsCreateInput"];
	["Contember_VenueCreateDeadlineTemplateEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"];
	["Contember_DeadlineTemplateWithoutVenueCreateInput"]: GraphQLTypes["Contember_DeadlineTemplateWithoutVenueCreateInput"];
	["Contember_VenueCreateTimetableDaysEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"];
	["Contember_TimetableDayWithoutVenueCreateInput"]: GraphQLTypes["Contember_TimetableDayWithoutVenueCreateInput"];
	["Contember_TimetableDayCreateShiftGroupsEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayCreateShiftGroupsEntityRelationInput"];
	["Contember_TimetableShiftGroupWithoutDayCreateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutDayCreateInput"];
	["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"];
	["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"];
	["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"]: GraphQLTypes["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"];
	["Contember_TimetableTemplateCreatePositionEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateCreatePositionEntityRelationInput"];
	["Contember_TimetableShiftPositionUniqueWhere"]: GraphQLTypes["Contember_TimetableShiftPositionUniqueWhere"];
	["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"]: GraphQLTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"];
	["Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput"];
	["Contember_TimetableShiftGroupWithoutPositionCreateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutPositionCreateInput"];
	["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"];
	["Contember_EmployeeQueueWithoutShiftGroupCreateInput"]: GraphQLTypes["Contember_EmployeeQueueWithoutShiftGroupCreateInput"];
	["Contember_EmployeeQueueCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueCreateItemsEntityRelationInput"];
	["Contember_EmployeeQueueItemWithoutQueueCreateInput"]: GraphQLTypes["Contember_EmployeeQueueItemWithoutQueueCreateInput"];
	["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"];
	["Contember_ShiftsProfileWithoutQueueItemsCreateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutQueueItemsCreateInput"];
	["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"];
	["Contember_TimetableShiftWithoutShiftsProfileCreateInput"]: GraphQLTypes["Contember_TimetableShiftWithoutShiftsProfileCreateInput"];
	["Contember_TimetableShiftCreateGroupEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftCreateGroupEntityRelationInput"];
	["Contember_TimetableShiftGroupWithoutShiftsCreateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutShiftsCreateInput"];
	["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"];
	["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"]: GraphQLTypes["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"];
	["Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput"];
	["Contember_TimetableTemplateWithoutPositionCreateInput"]: GraphQLTypes["Contember_TimetableTemplateWithoutPositionCreateInput"];
	["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"];
	["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"];
	["Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput"];
	["Contember_ShiftsProfileCreateInput"]: GraphQLTypes["Contember_ShiftsProfileCreateInput"];
	["Contember_TimetableTemplateCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutTimetableTemplatesCreateInput"]: GraphQLTypes["Contember_VenueWithoutTimetableTemplatesCreateInput"];
	["Contember_VenueCreateStaffMembersEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"];
	["Contember_StaffWithoutVenuesCreateInput"]: GraphQLTypes["Contember_StaffWithoutVenuesCreateInput"];
	["Contember_StaffCreatePhotoEntityRelationInput"]: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"];
	["Contember_StaffCreateCourierManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"];
	["Contember_CourierManagerWithoutStaffCreateInput"]: GraphQLTypes["Contember_CourierManagerWithoutStaffCreateInput"];
	["Contember_StaffCreatePickerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"];
	["Contember_PickerWithoutStaffCreateInput"]: GraphQLTypes["Contember_PickerWithoutStaffCreateInput"];
	["Contember_StaffCreateRemunerationProfileEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"];
	["Contember_RemunerationProfileWithoutStaffCreateInput"]: GraphQLTypes["Contember_RemunerationProfileWithoutStaffCreateInput"];
	["Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput"]: GraphQLTypes["Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput"];
	["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"]: GraphQLTypes["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"];
	["Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput"]: GraphQLTypes["Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput"];
	["Contember_WorkMonthUniqueWhere"]: GraphQLTypes["Contember_WorkMonthUniqueWhere"];
	["Contember_WorkMonthWithoutRemunerationProfileCreateInput"]: GraphQLTypes["Contember_WorkMonthWithoutRemunerationProfileCreateInput"];
	["Contember_StaffCreateCashierEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"];
	["Contember_CashierWithoutStaffCreateInput"]: GraphQLTypes["Contember_CashierWithoutStaffCreateInput"];
	["Contember_StaffCreateInternalBuyerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"];
	["Contember_InternalBuyerWithoutStaffCreateInput"]: GraphQLTypes["Contember_InternalBuyerWithoutStaffCreateInput"];
	["Contember_StaffCreateSalesManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"];
	["Contember_SalesManagerWithoutStaffCreateInput"]: GraphQLTypes["Contember_SalesManagerWithoutStaffCreateInput"];
	["Contember_SalesManagerCreateCountriesEntityRelationInput"]: GraphQLTypes["Contember_SalesManagerCreateCountriesEntityRelationInput"];
	["Contember_StaffCreateCashierManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"];
	["Contember_CashierManagerWithoutStaffCreateInput"]: GraphQLTypes["Contember_CashierManagerWithoutStaffCreateInput"];
	["Contember_VenueCreateEmployeeRolesEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"];
	["Contember_VenueEmployeeRoleWithoutVenueCreateInput"]: GraphQLTypes["Contember_VenueEmployeeRoleWithoutVenueCreateInput"];
	["Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput"]: GraphQLTypes["Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput"];
	["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"]: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"];
	["Contember_VenueEmployeeRoleCreateRoleEntityRelationInput"]: GraphQLTypes["Contember_VenueEmployeeRoleCreateRoleEntityRelationInput"];
	["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]: GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"];
	["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"];
	["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"]: GraphQLTypes["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"];
	["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"];
	["Contember_IdentificationAttemptWithoutCodeCreateInput"]: GraphQLTypes["Contember_IdentificationAttemptWithoutCodeCreateInput"];
	["Contember_IdentificationAttemptCreateUserEntityRelationInput"]: GraphQLTypes["Contember_IdentificationAttemptCreateUserEntityRelationInput"];
	["Contember_UserWithoutIdentificationAttemptsCreateInput"]: GraphQLTypes["Contember_UserWithoutIdentificationAttemptsCreateInput"];
	["Contember_UserCreateDevicesEntityRelationInput"]: GraphQLTypes["Contember_UserCreateDevicesEntityRelationInput"];
	["Contember_UserDeviceWithoutUserCreateInput"]: GraphQLTypes["Contember_UserDeviceWithoutUserCreateInput"];
	["Contember_UserDeviceCreateAnonymousSessionEntityRelationInput"]: GraphQLTypes["Contember_UserDeviceCreateAnonymousSessionEntityRelationInput"];
	["Contember_AnonymousSessionWithoutDevicesCreateInput"]: GraphQLTypes["Contember_AnonymousSessionWithoutDevicesCreateInput"];
	["Contember_AnonymousSessionCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_AnonymousSessionCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutAnonymousSessionCreateInput"]: GraphQLTypes["Contember_CustomerWithoutAnonymousSessionCreateInput"];
	["Contember_CustomerCreateInvoicesEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"];
	["Contember_InvoiceWithoutCustomerCreateInput"]: GraphQLTypes["Contember_InvoiceWithoutCustomerCreateInput"];
	["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]: GraphQLTypes["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"];
	["Contember_OrderPaymentWithoutInvoiceCreateInput"]: GraphQLTypes["Contember_OrderPaymentWithoutInvoiceCreateInput"];
	["Contember_OrderPaymentCreateMethodEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentCreateMethodEntityRelationInput"];
	["Contember_OrderPaymentCreateEventsEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentCreateEventsEntityRelationInput"];
	["Contember_OrderEventWithoutPaymentCreateInput"]: GraphQLTypes["Contember_OrderEventWithoutPaymentCreateInput"];
	["Contember_OrderEventCreateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderEventCreateOrderEntityRelationInput"];
	["Contember_OrderWithoutEventsCreateInput"]: GraphQLTypes["Contember_OrderWithoutEventsCreateInput"];
	["Contember_OrderCreateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"];
	["Contember_OrderDeliveryWithoutOrderCreateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutOrderCreateInput"];
	["Contember_OrderDeliveryCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"];
	["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"];
	["Contember_DeliveryWaypointWithoutDeliveryCreateInput"]: GraphQLTypes["Contember_DeliveryWaypointWithoutDeliveryCreateInput"];
	["Contember_DeliveryWaypointCreateRouteEntityRelationInput"]: GraphQLTypes["Contember_DeliveryWaypointCreateRouteEntityRelationInput"];
	["Contember_DeliveryRouteWithoutWaypointsCreateInput"]: GraphQLTypes["Contember_DeliveryRouteWithoutWaypointsCreateInput"];
	["Contember_DeliveryRouteCreateCourierEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRouteCreateCourierEntityRelationInput"];
	["Contember_CourierWithoutRoutesCreateInput"]: GraphQLTypes["Contember_CourierWithoutRoutesCreateInput"];
	["Contember_CourierCreateDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_CourierCreateDeliveryMethodsEntityRelationInput"];
	["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"];
	["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"];
	["Contember_DeliveryMethodLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutRootCreateInput"];
	["Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutDeliveryMethodsCreateInput"]: GraphQLTypes["Contember_LocaleWithoutDeliveryMethodsCreateInput"];
	["Contember_LocaleCreateProductPackingsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"];
	["Contember_ProductPackingLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_ProductPackingLocaleWithoutLocaleCreateInput"];
	["Contember_ProductPackingLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingLocaleCreateRootEntityRelationInput"];
	["Contember_ProductPackingWithoutLocalesCreateInput"]: GraphQLTypes["Contember_ProductPackingWithoutLocalesCreateInput"];
	["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"];
	["Contember_PreparedPackingQuantityWithoutPackingCreateInput"]: GraphQLTypes["Contember_PreparedPackingQuantityWithoutPackingCreateInput"];
	["Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput"]: GraphQLTypes["Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput"];
	["Contember_ProductRecipeCreateInput"]: GraphQLTypes["Contember_ProductRecipeCreateInput"];
	["Contember_ProductRecipeCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeCreateProductsEntityRelationInput"];
	["Contember_ProductWithoutRecipeCreateInput"]: GraphQLTypes["Contember_ProductWithoutRecipeCreateInput"];
	["Contember_ProductCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"];
	["Contember_ProductLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_ProductLocaleWithoutRootCreateInput"];
	["Contember_ProductLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductsCreateInput"]: GraphQLTypes["Contember_LocaleWithoutProductsCreateInput"];
	["Contember_LocaleCreateProductRecipesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"];
	["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"];
	["Contember_ProductRecipeLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeLocaleCreateRootEntityRelationInput"];
	["Contember_ProductRecipeWithoutLocalesCreateInput"]: GraphQLTypes["Contember_ProductRecipeWithoutLocalesCreateInput"];
	["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"];
	["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"];
	["Contember_NutritionFactsCreateInput"]: GraphQLTypes["Contember_NutritionFactsCreateInput"];
	["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"];
	["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"];
	["Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput"];
	["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"];
	["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"];
	["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"];
	["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"];
	["Contember_ProductCreateInput"]: GraphQLTypes["Contember_ProductCreateInput"];
	["Contember_ProductCreateCustomerPricesEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"];
	["Contember_ProductCreateVirtualProductEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"];
	["Contember_VirtualProductWithoutProductCreateInput"]: GraphQLTypes["Contember_VirtualProductWithoutProductCreateInput"];
	["Contember_ProductCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"];
	["Contember_ProductCreatePageEntityRelationInput"]: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"];
	["Contember_PageCreateInput"]: GraphQLTypes["Contember_PageCreateInput"];
	["Contember_PageCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PageCreateLocalesEntityRelationInput"];
	["Contember_PageLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_PageLocaleWithoutRootCreateInput"];
	["Contember_PageLocaleCreateSeoEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleCreateSeoEntityRelationInput"];
	["Contember_SeoCreateInput"]: GraphQLTypes["Contember_SeoCreateInput"];
	["Contember_SeoCreateOgImageEntityRelationInput"]: GraphQLTypes["Contember_SeoCreateOgImageEntityRelationInput"];
	["Contember_PageLocaleCreateContentEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleCreateContentEntityRelationInput"];
	["Contember_PageLocaleCreateCoverEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleCreateCoverEntityRelationInput"];
	["Contember_ProductCreateSalesDaysEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"];
	["Contember_ProductCreateSalesWeeksEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"];
	["Contember_ProductCreateSalesMonthsEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"];
	["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"];
	["Contember_FulfillmentNoteWithoutProductsCreateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutProductsCreateInput"];
	["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"];
	["Contember_TagWithoutFulfillmentNotesCreateInput"]: GraphQLTypes["Contember_TagWithoutFulfillmentNotesCreateInput"];
	["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"];
	["Contember_VenueWithoutFulfillmentNotesCreateInput"]: GraphQLTypes["Contember_VenueWithoutFulfillmentNotesCreateInput"];
	["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"];
	["Contember_TimetableTemplateWithoutVenueCreateInput"]: GraphQLTypes["Contember_TimetableTemplateWithoutVenueCreateInput"];
	["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"];
	["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]: GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"];
	["Contember_PointOfSaleTerminalWithoutVenueCreateInput"]: GraphQLTypes["Contember_PointOfSaleTerminalWithoutVenueCreateInput"];
	["Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"];
	["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"]: GraphQLTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"];
	["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]: GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"];
	["Contember_PointOfSalePrinterWithoutVenueCreateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutVenueCreateInput"];
	["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"];
	["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"];
	["Contember_DeviceIdentifierWithoutDeviceCreateInput"]: GraphQLTypes["Contember_DeviceIdentifierWithoutDeviceCreateInput"];
	["Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput"]: GraphQLTypes["Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput"];
	["Contember_UserCreateInput"]: GraphQLTypes["Contember_UserCreateInput"];
	["Contember_UserCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_UserCreateMetaEntityRelationInput"];
	["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]: GraphQLTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"];
	["Contember_IdentificationAttemptWithoutUserCreateInput"]: GraphQLTypes["Contember_IdentificationAttemptWithoutUserCreateInput"];
	["Contember_IdentificationAttemptCreateCodeEntityRelationInput"]: GraphQLTypes["Contember_IdentificationAttemptCreateCodeEntityRelationInput"];
	["Contember_IdentificationCodeWithoutAttemptsCreateInput"]: GraphQLTypes["Contember_IdentificationCodeWithoutAttemptsCreateInput"];
	["Contember_IdentificationCodeCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeCreateLocalesEntityRelationInput"];
	["Contember_IdentificationCodeLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutRootCreateInput"];
	["Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutIdentificationCodesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutIdentificationCodesCreateInput"];
	["Contember_LocaleCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"];
	["Contember_ProductLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_ProductLocaleWithoutLocaleCreateInput"];
	["Contember_ProductLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductLocaleCreateRootEntityRelationInput"];
	["Contember_ProductWithoutLocalesCreateInput"]: GraphQLTypes["Contember_ProductWithoutLocalesCreateInput"];
	["Contember_ProductLocaleCreateFeatureListEntityRelationInput"]: GraphQLTypes["Contember_ProductLocaleCreateFeatureListEntityRelationInput"];
	["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"];
	["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"];
	["Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput"];
	["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"];
	["Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput"];
	["Contember_PointOfSaleCategoryWithoutProductsCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutProductsCreateInput"];
	["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"];
	["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"];
	["Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"];
	["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"];
	["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"];
	["Contember_DeliveryMethodLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleCreateRootEntityRelationInput"];
	["Contember_DeliveryMethodWithoutLocalesCreateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutLocalesCreateInput"];
	["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"];
	["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"]: GraphQLTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"];
	["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"];
	["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"];
	["Contember_IngredientSupplierLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleCreateRootEntityRelationInput"];
	["Contember_IngredientSupplierWithoutLocalesCreateInput"]: GraphQLTypes["Contember_IngredientSupplierWithoutLocalesCreateInput"];
	["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"];
	["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"];
	["Contember_IdentificationCodeLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleCreateRootEntityRelationInput"];
	["Contember_IdentificationCodeWithoutLocalesCreateInput"]: GraphQLTypes["Contember_IdentificationCodeWithoutLocalesCreateInput"];
	["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"];
	["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"];
	["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"]: GraphQLTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"];
	["Contember_PointOfSaleTerminalCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleTerminalCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"]: GraphQLTypes["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"];
	["Contember_VenueCreateOrdersEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"];
	["Contember_OrderWithoutVenueCreateInput"]: GraphQLTypes["Contember_OrderWithoutVenueCreateInput"];
	["Contember_OrderCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"];
	["Contember_OrderItemWithoutOrderCreateInput"]: GraphQLTypes["Contember_OrderItemWithoutOrderCreateInput"];
	["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]: GraphQLTypes["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"];
	["Contember_VirtualProductEffectWithoutOrderItemCreateInput"]: GraphQLTypes["Contember_VirtualProductEffectWithoutOrderItemCreateInput"];
	["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"];
	["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"]: GraphQLTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"];
	["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"];
	["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"]: GraphQLTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"];
	["Contember_OrderDiscountCreateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountCreateOrderEntityRelationInput"];
	["Contember_OrderWithoutDiscountsCreateInput"]: GraphQLTypes["Contember_OrderWithoutDiscountsCreateInput"];
	["Contember_OrderCreateCartEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"];
	["Contember_CartWithoutOrderCreateInput"]: GraphQLTypes["Contember_CartWithoutOrderCreateInput"];
	["Contember_CartCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CartCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutCartsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutCartsCreateInput"];
	["Contember_CustomerCreateInvoicingPlanEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"];
	["Contember_InvoicingPlanWithoutCustomerCreateInput"]: GraphQLTypes["Contember_InvoicingPlanWithoutCustomerCreateInput"];
	["Contember_InvoicingPlanCreatePresetEntityRelationInput"]: GraphQLTypes["Contember_InvoicingPlanCreatePresetEntityRelationInput"];
	["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"]: GraphQLTypes["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"];
	["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"];
	["Contember_BillingSubjectWithoutCustomerCreateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutCustomerCreateInput"];
	["Contember_BillingSubjectCreateCountryEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"];
	["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"];
	["Contember_InvoiceWithoutBillingSubjectCreateInput"]: GraphQLTypes["Contember_InvoiceWithoutBillingSubjectCreateInput"];
	["Contember_InvoiceCreateAddressEntityRelationInput"]: GraphQLTypes["Contember_InvoiceCreateAddressEntityRelationInput"];
	["Contember_AddressWithoutInvoicesCreateInput"]: GraphQLTypes["Contember_AddressWithoutInvoicesCreateInput"];
	["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"];
	["Contember_OrderWithoutBillingAddressCreateInput"]: GraphQLTypes["Contember_OrderWithoutBillingAddressCreateInput"];
	["Contember_OrderCreateEventsEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"];
	["Contember_OrderEventWithoutOrderCreateInput"]: GraphQLTypes["Contember_OrderEventWithoutOrderCreateInput"];
	["Contember_OrderEventCreatePaymentEntityRelationInput"]: GraphQLTypes["Contember_OrderEventCreatePaymentEntityRelationInput"];
	["Contember_OrderPaymentWithoutEventsCreateInput"]: GraphQLTypes["Contember_OrderPaymentWithoutEventsCreateInput"];
	["Contember_OrderPaymentCreateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentCreateOrderEntityRelationInput"];
	["Contember_OrderWithoutPaymentsCreateInput"]: GraphQLTypes["Contember_OrderWithoutPaymentsCreateInput"];
	["Contember_OrderCreateBillingAddressEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"];
	["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"]: GraphQLTypes["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"];
	["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"];
	["Contember_OrderDeliveryWithoutAddressCreateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutAddressCreateInput"];
	["Contember_OrderDeliveryCreateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryCreateOrderEntityRelationInput"];
	["Contember_OrderWithoutDeliveryCreateInput"]: GraphQLTypes["Contember_OrderWithoutDeliveryCreateInput"];
	["Contember_OrderCreatePriceLinesEntityRelationInput"]: GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"];
	["Contember_OrderCreatePaymentsEntityRelationInput"]: GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"];
	["Contember_OrderPaymentWithoutOrderCreateInput"]: GraphQLTypes["Contember_OrderPaymentWithoutOrderCreateInput"];
	["Contember_OrderPaymentCreateInvoiceEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentCreateInvoiceEntityRelationInput"];
	["Contember_InvoiceWithoutOrderPaymentsCreateInput"]: GraphQLTypes["Contember_InvoiceWithoutOrderPaymentsCreateInput"];
	["Contember_InvoiceCreateBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_InvoiceCreateBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectWithoutInvoicesCreateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutInvoicesCreateInput"];
	["Contember_BillingSubjectCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutBillingSubjectsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutBillingSubjectsCreateInput"];
	["Contember_CustomerCreateOfferedProductsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"];
	["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"];
	["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"]: GraphQLTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"];
	["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"];
	["Contember_BillingSubjectCreateOrdersEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"];
	["Contember_OrderWithoutBillingSubjectCreateInput"]: GraphQLTypes["Contember_OrderWithoutBillingSubjectCreateInput"];
	["Contember_OrderCreateSummaryEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"];
	["Contember_OrderCreateGratuityEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"];
	["Contember_GratuityWithoutOrderCreateInput"]: GraphQLTypes["Contember_GratuityWithoutOrderCreateInput"];
	["Contember_OrderCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutOrdersCreateInput"]: GraphQLTypes["Contember_VenueWithoutOrdersCreateInput"];
	["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"];
	["Contember_FulfillmentNoteWithoutVenuesCreateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutVenuesCreateInput"];
	["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"];
	["Contember_CartItemWithoutFulfillmentNotesCreateInput"]: GraphQLTypes["Contember_CartItemWithoutFulfillmentNotesCreateInput"];
	["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"];
	["Contember_OrderItemWithoutFulfillmentNotesCreateInput"]: GraphQLTypes["Contember_OrderItemWithoutFulfillmentNotesCreateInput"];
	["Contember_OrderItemCreateProductEntityRelationInput"]: GraphQLTypes["Contember_OrderItemCreateProductEntityRelationInput"];
	["Contember_ProductWithoutOrderItemsCreateInput"]: GraphQLTypes["Contember_ProductWithoutOrderItemsCreateInput"];
	["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"];
	["Contember_OrderWithoutFulfillmentNotesCreateInput"]: GraphQLTypes["Contember_OrderWithoutFulfillmentNotesCreateInput"];
	["Contember_OrderCreateBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectWithoutOrdersCreateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutOrdersCreateInput"];
	["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"];
	["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"]: GraphQLTypes["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"];
	["Contember_CustomerCreateAnonymousSessionEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"];
	["Contember_AnonymousSessionWithoutCustomerCreateInput"]: GraphQLTypes["Contember_AnonymousSessionWithoutCustomerCreateInput"];
	["Contember_AnonymousSessionCreateDevicesEntityRelationInput"]: GraphQLTypes["Contember_AnonymousSessionCreateDevicesEntityRelationInput"];
	["Contember_UserDeviceWithoutAnonymousSessionCreateInput"]: GraphQLTypes["Contember_UserDeviceWithoutAnonymousSessionCreateInput"];
	["Contember_UserDeviceCreateUserEntityRelationInput"]: GraphQLTypes["Contember_UserDeviceCreateUserEntityRelationInput"];
	["Contember_UserWithoutDevicesCreateInput"]: GraphQLTypes["Contember_UserWithoutDevicesCreateInput"];
	["Contember_UserCreateRolesEntityRelationInput"]: GraphQLTypes["Contember_UserCreateRolesEntityRelationInput"];
	["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"];
	["Contember_VoucherWithoutBuyerCreateInput"]: GraphQLTypes["Contember_VoucherWithoutBuyerCreateInput"];
	["Contember_VoucherCreateOwnerEntityRelationInput"]: GraphQLTypes["Contember_VoucherCreateOwnerEntityRelationInput"];
	["Contember_CustomerWithoutOwnsVouchersCreateInput"]: GraphQLTypes["Contember_CustomerWithoutOwnsVouchersCreateInput"];
	["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"];
	["Contember_VoucherRedemptionWithoutCustomerCreateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreateInput"];
	["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"];
	["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"]: GraphQLTypes["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"];
	["Contember_CustomerCreditCreateTransactionsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditCreateTransactionsEntityRelationInput"];
	["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"]: GraphQLTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"];
	["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"];
	["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"]: GraphQLTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"];
	["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"];
	["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"]: GraphQLTypes["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"];
	["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"];
	["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"];
	["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"];
	["Contember_VoucherWithoutVirtualProductEffectCreateInput"]: GraphQLTypes["Contember_VoucherWithoutVirtualProductEffectCreateInput"];
	["Contember_VoucherCreateBuyerEntityRelationInput"]: GraphQLTypes["Contember_VoucherCreateBuyerEntityRelationInput"];
	["Contember_CustomerWithoutBoughtVouchersCreateInput"]: GraphQLTypes["Contember_CustomerWithoutBoughtVouchersCreateInput"];
	["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"];
	["Contember_VoucherWithoutOwnerCreateInput"]: GraphQLTypes["Contember_VoucherWithoutOwnerCreateInput"];
	["Contember_VoucherCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_VoucherCreateVatRateEntityRelationInput"];
	["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"]: GraphQLTypes["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"];
	["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"]: GraphQLTypes["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"];
	["Contember_VoucherCreateRedemptionEntityRelationInput"]: GraphQLTypes["Contember_VoucherCreateRedemptionEntityRelationInput"];
	["Contember_VoucherRedemptionWithoutVoucherCreateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutVoucherCreateInput"];
	["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"];
	["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"]: GraphQLTypes["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"];
	["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutRedeemedVouchersCreateInput"]: GraphQLTypes["Contember_CustomerWithoutRedeemedVouchersCreateInput"];
	["Contember_CustomerCreateSpendingEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"];
	["Contember_CustomerCreateCustomerTabsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"];
	["Contember_CustomerTabWithoutCustomerCreateInput"]: GraphQLTypes["Contember_CustomerTabWithoutCustomerCreateInput"];
	["Contember_CustomerTabCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabCreateItemsEntityRelationInput"];
	["Contember_CustomerTabItemWithoutTabCreateInput"]: GraphQLTypes["Contember_CustomerTabItemWithoutTabCreateInput"];
	["Contember_CustomerTabItemCreateProductEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabItemCreateProductEntityRelationInput"];
	["Contember_CustomerTabCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabCreateMetaEntityRelationInput"];
	["Contember_CustomerTabCreateTableEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabCreateTableEntityRelationInput"];
	["Contember_TableWithoutCustomerTabsCreateInput"]: GraphQLTypes["Contember_TableWithoutCustomerTabsCreateInput"];
	["Contember_TableCreateAreaEntityRelationInput"]: GraphQLTypes["Contember_TableCreateAreaEntityRelationInput"];
	["Contember_AreaWithoutTablesCreateInput"]: GraphQLTypes["Contember_AreaWithoutTablesCreateInput"];
	["Contember_AreaCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_AreaCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutAreasCreateInput"]: GraphQLTypes["Contember_VenueWithoutAreasCreateInput"];
	["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"];
	["Contember_UnassignReasonUniqueWhere"]: GraphQLTypes["Contember_UnassignReasonUniqueWhere"];
	["Contember_UnassignReasonWithoutVenuesCreateInput"]: GraphQLTypes["Contember_UnassignReasonWithoutVenuesCreateInput"];
	["Contember_VenueCreateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"];
	["Contember_PointOfSaleCategoryWithoutVenueCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutVenueCreateInput"];
	["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"];
	["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"];
	["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"];
	["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"];
	["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"];
	["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"];
	["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutPointOfSalePrintersCreateInput"]: GraphQLTypes["Contember_VenueWithoutPointOfSalePrintersCreateInput"];
	["Contember_VenueCreateAreasEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"];
	["Contember_AreaWithoutVenueCreateInput"]: GraphQLTypes["Contember_AreaWithoutVenueCreateInput"];
	["Contember_AreaCreateTablesEntityRelationInput"]: GraphQLTypes["Contember_AreaCreateTablesEntityRelationInput"];
	["Contember_TableWithoutAreaCreateInput"]: GraphQLTypes["Contember_TableWithoutAreaCreateInput"];
	["Contember_TableCreateCustomerTabsEntityRelationInput"]: GraphQLTypes["Contember_TableCreateCustomerTabsEntityRelationInput"];
	["Contember_CustomerTabWithoutTableCreateInput"]: GraphQLTypes["Contember_CustomerTabWithoutTableCreateInput"];
	["Contember_CustomerTabCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutCustomerTabsCreateInput"]: GraphQLTypes["Contember_CustomerWithoutCustomerTabsCreateInput"];
	["Contember_CustomerCreateLeadEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"];
	["Contember_PipelineLeadWithoutCustomerCreateInput"]: GraphQLTypes["Contember_PipelineLeadWithoutCustomerCreateInput"];
	["Contember_PipelineLeadCreateStageItemEntityRelationInput"]: GraphQLTypes["Contember_PipelineLeadCreateStageItemEntityRelationInput"];
	["Contember_PipelineStageItemWithoutLeadCreateInput"]: GraphQLTypes["Contember_PipelineStageItemWithoutLeadCreateInput"];
	["Contember_PipelineStageItemCreateListEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageItemCreateListEntityRelationInput"];
	["Contember_PipelineStageWithoutItemsCreateInput"]: GraphQLTypes["Contember_PipelineStageWithoutItemsCreateInput"];
	["Contember_PipelineStageCreatePipelineEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageCreatePipelineEntityRelationInput"];
	["Contember_PipelineUniqueWhere"]: GraphQLTypes["Contember_PipelineUniqueWhere"];
	["Contember_PipelineWithoutStagesCreateInput"]: GraphQLTypes["Contember_PipelineWithoutStagesCreateInput"];
	["Contember_PipelineStageCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageCreateLocalesEntityRelationInput"];
	["Contember_PipelineStageLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_PipelineStageLocaleWithoutRootCreateInput"];
	["Contember_PipelineStageLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPipelineStagesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutPipelineStagesCreateInput"];
	["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"];
	["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"];
	["Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput"];
	["Contember_SubscriptionBoxWithoutLocalesCreateInput"]: GraphQLTypes["Contember_SubscriptionBoxWithoutLocalesCreateInput"];
	["Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput"];
	["Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput"];
	["Contember_LocaleCreateBusinessServicesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"];
	["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"];
	["Contember_BusinessServiceLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceLocaleCreateRootEntityRelationInput"];
	["Contember_BusinessServiceWithoutLocalesCreateInput"]: GraphQLTypes["Contember_BusinessServiceWithoutLocalesCreateInput"];
	["Contember_PipelineStageCreateSentimentEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageCreateSentimentEntityRelationInput"];
	["Contember_PipelineStageSentimentUniqueWhere"]: GraphQLTypes["Contember_PipelineStageSentimentUniqueWhere"];
	["Contember_PipelineStageSentimentCreateInput"]: GraphQLTypes["Contember_PipelineStageSentimentCreateInput"];
	["Contember_PipelineStageCreateFormsEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageCreateFormsEntityRelationInput"];
	["Contember_LeadFormWithoutStageCreateInput"]: GraphQLTypes["Contember_LeadFormWithoutStageCreateInput"];
	["Contember_LeadFormCreateLeadsEntityRelationInput"]: GraphQLTypes["Contember_LeadFormCreateLeadsEntityRelationInput"];
	["Contember_PipelineLeadWithoutFormCreateInput"]: GraphQLTypes["Contember_PipelineLeadWithoutFormCreateInput"];
	["Contember_PipelineLeadCreateCustomerEntityRelationInput"]: GraphQLTypes["Contember_PipelineLeadCreateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutLeadCreateInput"]: GraphQLTypes["Contember_CustomerWithoutLeadCreateInput"];
	["Contember_CustomerCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"];
	["Contember_PipelineLeadCreateFormEntityRelationInput"]: GraphQLTypes["Contember_PipelineLeadCreateFormEntityRelationInput"];
	["Contember_LeadFormWithoutLeadsCreateInput"]: GraphQLTypes["Contember_LeadFormWithoutLeadsCreateInput"];
	["Contember_LeadFormCreateStageEntityRelationInput"]: GraphQLTypes["Contember_LeadFormCreateStageEntityRelationInput"];
	["Contember_PipelineStageWithoutFormsCreateInput"]: GraphQLTypes["Contember_PipelineStageWithoutFormsCreateInput"];
	["Contember_PipelineStageCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageCreateItemsEntityRelationInput"];
	["Contember_PipelineStageItemWithoutListCreateInput"]: GraphQLTypes["Contember_PipelineStageItemWithoutListCreateInput"];
	["Contember_PipelineStageItemCreateLeadEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageItemCreateLeadEntityRelationInput"];
	["Contember_PipelineLeadWithoutStageItemCreateInput"]: GraphQLTypes["Contember_PipelineLeadWithoutStageItemCreateInput"];
	["Contember_CustomerTabCreateSeatEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabCreateSeatEntityRelationInput"];
	["Contember_SeatWithoutCustomerTabsCreateInput"]: GraphQLTypes["Contember_SeatWithoutCustomerTabsCreateInput"];
	["Contember_SeatCreateTableEntityRelationInput"]: GraphQLTypes["Contember_SeatCreateTableEntityRelationInput"];
	["Contember_TableWithoutSeatsCreateInput"]: GraphQLTypes["Contember_TableWithoutSeatsCreateInput"];
	["Contember_TableCreateSeatsEntityRelationInput"]: GraphQLTypes["Contember_TableCreateSeatsEntityRelationInput"];
	["Contember_SeatWithoutTableCreateInput"]: GraphQLTypes["Contember_SeatWithoutTableCreateInput"];
	["Contember_SeatCreateCustomerTabsEntityRelationInput"]: GraphQLTypes["Contember_SeatCreateCustomerTabsEntityRelationInput"];
	["Contember_CustomerTabWithoutSeatCreateInput"]: GraphQLTypes["Contember_CustomerTabWithoutSeatCreateInput"];
	["Contember_AreaCreateLinesEntityRelationInput"]: GraphQLTypes["Contember_AreaCreateLinesEntityRelationInput"];
	["Contember_LineWithoutAreaCreateInput"]: GraphQLTypes["Contember_LineWithoutAreaCreateInput"];
	["Contember_VenueCreateChannelsEntityRelationInput"]: GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"];
	["Contember_ChannelWithoutVenueCreateInput"]: GraphQLTypes["Contember_ChannelWithoutVenueCreateInput"];
	["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"];
	["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"];
	["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutCategoriesCreateInput"]: GraphQLTypes["Contember_VenueWithoutCategoriesCreateInput"];
	["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"];
	["Contember_VoucherWithoutRedemptionCreateInput"]: GraphQLTypes["Contember_VoucherWithoutRedemptionCreateInput"];
	["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"];
	["Contember_FulfillmentNoteWithoutOrdersCreateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutOrdersCreateInput"];
	["Contember_OrderPaymentCreateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentCreateVatRateEntityRelationInput"];
	["Contember_OrderPaymentCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentCreateMetaEntityRelationInput"];
	["Contember_OrderDeliveryCreateEventsEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryCreateEventsEntityRelationInput"];
	["Contember_OrderEventWithoutDeliveryCreateInput"]: GraphQLTypes["Contember_OrderEventWithoutDeliveryCreateInput"];
	["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"];
	["Contember_LiftagoRideWithoutDeliveryCreateInput"]: GraphQLTypes["Contember_LiftagoRideWithoutDeliveryCreateInput"];
	["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: GraphQLTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"];
	["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"]: GraphQLTypes["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"];
	["Contember_OrderEventCreateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_OrderEventCreateDeliveryEntityRelationInput"];
	["Contember_OrderDeliveryWithoutEventsCreateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutEventsCreateInput"];
	["Contember_OrderDeliveryCreateAddressEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryCreateAddressEntityRelationInput"];
	["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"]: GraphQLTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"];
	["Contember_CartCreateDeliveryAddressEntityRelationInput"]: GraphQLTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"];
	["Contember_CartCreateBillingAddressEntityRelationInput"]: GraphQLTypes["Contember_CartCreateBillingAddressEntityRelationInput"];
	["Contember_CartCreateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_CartCreateDeliveryEntityRelationInput"];
	["Contember_ChannelDeliveryCreateInput"]: GraphQLTypes["Contember_ChannelDeliveryCreateInput"];
	["Contember_CartCreatePaymentEntityRelationInput"]: GraphQLTypes["Contember_CartCreatePaymentEntityRelationInput"];
	["Contember_ChannelPaymentCreateInput"]: GraphQLTypes["Contember_ChannelPaymentCreateInput"];
	["Contember_CartCreateBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_CartCreateBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectCreateInput"]: GraphQLTypes["Contember_BillingSubjectCreateInput"];
	["Contember_CartCreatePriceLinesEntityRelationInput"]: GraphQLTypes["Contember_CartCreatePriceLinesEntityRelationInput"];
	["Contember_CartCreateSummaryEntityRelationInput"]: GraphQLTypes["Contember_CartCreateSummaryEntityRelationInput"];
	["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"];
	["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"];
	["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"];
	["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"];
	["Contember_LocaleCreatePipelineStagesEntityRelationInput"]: GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"];
	["Contember_PipelineStageLocaleWithoutLocaleCreateInput"]: GraphQLTypes["Contember_PipelineStageLocaleWithoutLocaleCreateInput"];
	["Contember_PipelineStageLocaleCreateRootEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageLocaleCreateRootEntityRelationInput"];
	["Contember_PipelineStageWithoutLocalesCreateInput"]: GraphQLTypes["Contember_PipelineStageWithoutLocalesCreateInput"];
	["Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput"];
	["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"];
	["Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"]: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"];
	["Contember_ProductRecipeCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeCreateLocalesEntityRelationInput"];
	["Contember_ProductRecipeLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_ProductRecipeLocaleWithoutRootCreateInput"];
	["Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductRecipesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutProductRecipesCreateInput"];
	["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"];
	["Contember_TimetableTemplateCreateInput"]: GraphQLTypes["Contember_TimetableTemplateCreateInput"];
	["Contember_TimetableShiftCreateQueueItemEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftCreateQueueItemEntityRelationInput"];
	["Contember_EmployeeQueueItemWithoutShiftCreateInput"]: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftCreateInput"];
	["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"];
	["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"]: GraphQLTypes["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"];
	["Contember_UnassignedShiftInfoCreateReasonEntityRelationInput"]: GraphQLTypes["Contember_UnassignedShiftInfoCreateReasonEntityRelationInput"];
	["Contember_UnassignReasonCreateInput"]: GraphQLTypes["Contember_UnassignReasonCreateInput"];
	["Contember_UnassignReasonCreateVenuesEntityRelationInput"]: GraphQLTypes["Contember_UnassignReasonCreateVenuesEntityRelationInput"];
	["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"]: GraphQLTypes["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"];
	["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"];
	["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"];
	["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"]: GraphQLTypes["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"];
	["Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput"];
	["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"];
	["Contember_TimetableShiftWithoutQueueItemCreateInput"]: GraphQLTypes["Contember_TimetableShiftWithoutQueueItemCreateInput"];
	["Contember_TimetableDayCreateMetaEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayCreateMetaEntityRelationInput"];
	["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"];
	["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"];
	["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"];
	["Contember_StaffCreateVenuesEntityRelationInput"]: GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"];
	["Contember_VenueWithoutStaffMembersCreateInput"]: GraphQLTypes["Contember_VenueWithoutStaffMembersCreateInput"];
	["Contember_BusinessCustomerCreateLogoEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"];
	["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"];
	["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"]: GraphQLTypes["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"];
	["Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput"];
	["Contember_BusinessServiceCreateInput"]: GraphQLTypes["Contember_BusinessServiceCreateInput"];
	["Contember_BusinessServiceCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceCreateLocalesEntityRelationInput"];
	["Contember_BusinessServiceLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_BusinessServiceLocaleWithoutRootCreateInput"];
	["Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutBusinessServicesCreateInput"]: GraphQLTypes["Contember_LocaleWithoutBusinessServicesCreateInput"];
	["Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput"];
	["Contember_StaffCreateInput"]: GraphQLTypes["Contember_StaffCreateInput"];
	["Contember_BusinessServiceEventCreateCreatedByEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventCreateCreatedByEntityRelationInput"];
	["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"];
	["Contember_DoubleshotCoffeeEquipmentCreateInput"]: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentCreateInput"];
	["Contember_TimetableDayCreateVenueEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayCreateVenueEntityRelationInput"];
	["Contember_VenueWithoutTimetableDaysCreateInput"]: GraphQLTypes["Contember_VenueWithoutTimetableDaysCreateInput"];
	["Contember_WorkspaceCreateDiscountVatRateEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceCreateDiscountVatRateEntityRelationInput"];
	["Contember_ProductIngredientCreateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreateImageEntityRelationInput"];
	["Contember_ProductIngredientCreateSuppliersEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"];
	["Contember_IngredientSupplierListCreateInput"]: GraphQLTypes["Contember_IngredientSupplierListCreateInput"];
	["Contember_IngredientSupplierListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierListCreateItemsEntityRelationInput"];
	["Contember_IngredientSupplierItemWithoutListCreateInput"]: GraphQLTypes["Contember_IngredientSupplierItemWithoutListCreateInput"];
	["Contember_IngredientSupplierItemCreateSupplierEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierItemCreateSupplierEntityRelationInput"];
	["Contember_IngredientSupplierCreateInput"]: GraphQLTypes["Contember_IngredientSupplierCreateInput"];
	["Contember_IngredientSupplierCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierCreateLocalesEntityRelationInput"];
	["Contember_IngredientSupplierLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutRootCreateInput"];
	["Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutIngredientSuppliersCreateInput"]: GraphQLTypes["Contember_LocaleWithoutIngredientSuppliersCreateInput"];
	["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"];
	["Contember_ProductListWithoutBusinessCategoryCreateInput"]: GraphQLTypes["Contember_ProductListWithoutBusinessCategoryCreateInput"];
	["Contember_ProductListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductListCreateItemsEntityRelationInput"];
	["Contember_ProductListItemWithoutListCreateInput"]: GraphQLTypes["Contember_ProductListItemWithoutListCreateInput"];
	["Contember_ProductListItemCreateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductListItemCreateProductEntityRelationInput"];
	["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"];
	["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"]: GraphQLTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"];
	["Contember_ProductCategoryListCreateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListCreateLocalesEntityRelationInput"];
	["Contember_ProductCategoryListLocaleWithoutRootCreateInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutRootCreateInput"];
	["Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductCategoryListsCreateInput"]: GraphQLTypes["Contember_LocaleWithoutProductCategoryListsCreateInput"];
	["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"];
	["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"];
	["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"]: GraphQLTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"];
	["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"];
	["Contember_LinkableCreateRedirectEntityRelationInput"]: GraphQLTypes["Contember_LinkableCreateRedirectEntityRelationInput"];
	["Contember_RedirectWithoutLinkCreateInput"]: GraphQLTypes["Contember_RedirectWithoutLinkCreateInput"];
	["Contember_RedirectCreateTargetEntityRelationInput"]: GraphQLTypes["Contember_RedirectCreateTargetEntityRelationInput"];
	["Contember_WikiPageCreateContentEntityRelationInput"]: GraphQLTypes["Contember_WikiPageCreateContentEntityRelationInput"];
	["Contember_WikiPageCreateChildrenEntityRelationInput"]: GraphQLTypes["Contember_WikiPageCreateChildrenEntityRelationInput"];
	["Contember_WikiPageWithoutParentCreateInput"]: GraphQLTypes["Contember_WikiPageWithoutParentCreateInput"];
	["Contember_BlogPostLocaleCreateSeoEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateSeoEntityRelationInput"];
	["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateProductsEntityRelationInput"];
	["Contember_ContentReferenceCreateBlogPostsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateBlogPostsEntityRelationInput"];
	["Contember_BlogPostListUniqueWhere"]: GraphQLTypes["Contember_BlogPostListUniqueWhere"];
	["Contember_BlogPostListItemUniqueWhere"]: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"];
	["Contember_BlogPostListCreateInput"]: GraphQLTypes["Contember_BlogPostListCreateInput"];
	["Contember_BlogPostListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_BlogPostListCreateItemsEntityRelationInput"];
	["Contember_BlogPostListItemWithoutListCreateInput"]: GraphQLTypes["Contember_BlogPostListItemWithoutListCreateInput"];
	["Contember_BlogPostListItemCreateBlogPostEntityRelationInput"]: GraphQLTypes["Contember_BlogPostListItemCreateBlogPostEntityRelationInput"];
	["Contember_BlogPostLocaleCreateInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateInput"];
	["Contember_BlogPostLocaleCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleCreateLinkEntityRelationInput"];
	["Contember_LinkableWithoutBlogPostCreateInput"]: GraphQLTypes["Contember_LinkableWithoutBlogPostCreateInput"];
	["Contember_ContentReferenceCreateLinksEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateLinksEntityRelationInput"];
	["Contember_ContentReferenceCreateProductsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateProductsEntityRelationInput"];
	["Contember_ProductListCreateInput"]: GraphQLTypes["Contember_ProductListCreateInput"];
	["Contember_ProductListCreateBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductListCreateBusinessCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"];
	["Contember_ContentReferenceCreateHeroEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateHeroEntityRelationInput"];
	["Contember_HeroUniqueWhere"]: GraphQLTypes["Contember_HeroUniqueWhere"];
	["Contember_HeroCreateInput"]: GraphQLTypes["Contember_HeroCreateInput"];
	["Contember_HeroCreateMediumEntityRelationInput"]: GraphQLTypes["Contember_HeroCreateMediumEntityRelationInput"];
	["Contember_ContentReferenceCreateGalleryEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateGalleryEntityRelationInput"];
	["Contember_GalleryUniqueWhere"]: GraphQLTypes["Contember_GalleryUniqueWhere"];
	["Contember_GalleryItemUniqueWhere"]: GraphQLTypes["Contember_GalleryItemUniqueWhere"];
	["Contember_GalleryCreateInput"]: GraphQLTypes["Contember_GalleryCreateInput"];
	["Contember_GalleryCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_GalleryCreateItemsEntityRelationInput"];
	["Contember_GalleryItemWithoutListCreateInput"]: GraphQLTypes["Contember_GalleryItemWithoutListCreateInput"];
	["Contember_GalleryItemCreateMediumEntityRelationInput"]: GraphQLTypes["Contember_GalleryItemCreateMediumEntityRelationInput"];
	["Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput"];
	["Contember_ContentReferenceCreateProductGridEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateProductGridEntityRelationInput"];
	["Contember_ProductGridUniqueWhere"]: GraphQLTypes["Contember_ProductGridUniqueWhere"];
	["Contember_ProductGridCategoryUniqueWhere"]: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"];
	["Contember_ProductGridCreateInput"]: GraphQLTypes["Contember_ProductGridCreateInput"];
	["Contember_ProductGridCreateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductGridCreateCategoriesEntityRelationInput"];
	["Contember_ProductGridCategoryWithoutRootCreateInput"]: GraphQLTypes["Contember_ProductGridCategoryWithoutRootCreateInput"];
	["Contember_ProductGridCategoryCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductGridCategoryCreateItemsEntityRelationInput"];
	["Contember_ProductGridItemWithoutCategoryCreateInput"]: GraphQLTypes["Contember_ProductGridItemWithoutCategoryCreateInput"];
	["Contember_ProductGridItemCreateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductGridItemCreateProductEntityRelationInput"];
	["Contember_ProductGridItemCreateMediumEntityRelationInput"]: GraphQLTypes["Contember_ProductGridItemCreateMediumEntityRelationInput"];
	["Contember_ProductGridItemCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_ProductGridItemCreateLinkEntityRelationInput"];
	["Contember_ProductGridCategoryCreateImagesEntityRelationInput"]: GraphQLTypes["Contember_ProductGridCategoryCreateImagesEntityRelationInput"];
	["Contember_ContentReferenceCreateProductBannersEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateProductBannersEntityRelationInput"];
	["Contember_ProductBannerListCreateInput"]: GraphQLTypes["Contember_ProductBannerListCreateInput"];
	["Contember_ProductBannerListCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerListCreateItemsEntityRelationInput"];
	["Contember_ProductBannerItemWithoutListCreateInput"]: GraphQLTypes["Contember_ProductBannerItemWithoutListCreateInput"];
	["Contember_ProductBannerItemCreateBannerEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerItemCreateBannerEntityRelationInput"];
	["Contember_ProductBannerUniqueWhere"]: GraphQLTypes["Contember_ProductBannerUniqueWhere"];
	["Contember_ProductBannerCreateInput"]: GraphQLTypes["Contember_ProductBannerCreateInput"];
	["Contember_ProductBannerCreateButtonEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerCreateButtonEntityRelationInput"];
	["Contember_ProductBannerCreateBackgroundImageEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerCreateBackgroundImageEntityRelationInput"];
	["Contember_ProductBannerCreateProductImageEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerCreateProductImageEntityRelationInput"];
	["Contember_ProductBannerCreateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerCreateProductEntityRelationInput"];
	["Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput"];
	["Contember_ContentReferenceCreateTextListEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateTextListEntityRelationInput"];
	["Contember_ContentReferenceCreateGridTilesEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceCreateGridTilesEntityRelationInput"];
	["Contember_GridTilesUniqueWhere"]: GraphQLTypes["Contember_GridTilesUniqueWhere"];
	["Contember_GridTileItemUniqueWhere"]: GraphQLTypes["Contember_GridTileItemUniqueWhere"];
	["Contember_GridTilesCreateInput"]: GraphQLTypes["Contember_GridTilesCreateInput"];
	["Contember_GridTilesCreateItemsEntityRelationInput"]: GraphQLTypes["Contember_GridTilesCreateItemsEntityRelationInput"];
	["Contember_GridTileItemWithoutListCreateInput"]: GraphQLTypes["Contember_GridTileItemWithoutListCreateInput"];
	["Contember_GridTileItemCreateMediumEntityRelationInput"]: GraphQLTypes["Contember_GridTileItemCreateMediumEntityRelationInput"];
	["Contember_GridTileItemCreateLinkEntityRelationInput"]: GraphQLTypes["Contember_GridTileItemCreateLinkEntityRelationInput"];
	["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"];
	["Contember_FulfillmentNoteWithoutCartItemsCreateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutCartItemsCreateInput"];
	["Contember_ProductIngredientItemCreateQuantityEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemCreateQuantityEntityRelationInput"];
	["Contember_ProductIngredientItemQuantityCreateInput"]: GraphQLTypes["Contember_ProductIngredientItemQuantityCreateInput"];
	["Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput"];
	["Contember_AddressUpdateInput"]: GraphQLTypes["Contember_AddressUpdateInput"];
	["Contember_AddressUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutAddressesUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutAddressesUpdateInput"];
	["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"];
	["Contember_CustomerUpsertDefaultBillingAddressRelationInput"]: GraphQLTypes["Contember_CustomerUpsertDefaultBillingAddressRelationInput"];
	["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"];
	["Contember_CustomerPaymentMethodUpdateInput"]: GraphQLTypes["Contember_CustomerPaymentMethodUpdateInput"];
	["Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutPaymentMethodsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutPaymentMethodsUpdateInput"];
	["Contember_CustomerUpdateAddressesEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"];
	["Contember_CustomerUpdateAddressesRelationInput"]: GraphQLTypes["Contember_CustomerUpdateAddressesRelationInput"];
	["Contember_AddressWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_AddressWithoutCustomerUpdateInput"];
	["Contember_AddressUpdateCountryEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"];
	["Contember_CountryUpdateInput"]: GraphQLTypes["Contember_CountryUpdateInput"];
	["Contember_AddressUpsertCountryRelationInput"]: GraphQLTypes["Contember_AddressUpsertCountryRelationInput"];
	["Contember_AddressUpdateDeliveryZoneEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"];
	["Contember_DeliveryZoneWithoutAddressesUpdateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutAddressesUpdateInput"];
	["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"];
	["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput"];
	["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"];
	["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"];
	["Contember_DeliveryMethodUpdatePaymentMethodsRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsRelationInput"];
	["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"]: GraphQLTypes["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"];
	["Contember_PaymentMethodUpdateChannelsEntityRelationInput"]: GraphQLTypes["Contember_PaymentMethodUpdateChannelsEntityRelationInput"];
	["Contember_PaymentMethodUpdateChannelsRelationInput"]: GraphQLTypes["Contember_PaymentMethodUpdateChannelsRelationInput"];
	["Contember_ChannelPaymentWithoutMethodUpdateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutMethodUpdateInput"];
	["Contember_ChannelPaymentUpdateChannelEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"];
	["Contember_ChannelWithoutPaymentsUpdateInput"]: GraphQLTypes["Contember_ChannelWithoutPaymentsUpdateInput"];
	["Contember_ChannelUpdateDeliveriesEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"];
	["Contember_ChannelUpdateDeliveriesRelationInput"]: GraphQLTypes["Contember_ChannelUpdateDeliveriesRelationInput"];
	["Contember_ChannelDeliveryWithoutChannelUpdateInput"]: GraphQLTypes["Contember_ChannelDeliveryWithoutChannelUpdateInput"];
	["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"];
	["Contember_DeliveryMethodWithoutChannelsUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutChannelsUpdateInput"];
	["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"];
	["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput"];
	["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"];
	["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"];
	["Contember_DeliveryZoneUpdateAddressesRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateAddressesRelationInput"];
	["Contember_AddressWithoutDeliveryZoneUpdateInput"]: GraphQLTypes["Contember_AddressWithoutDeliveryZoneUpdateInput"];
	["Contember_AddressUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"];
	["Contember_AddressUpdateGpsEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"];
	["Contember_GpsWithoutAddressUpdateInput"]: GraphQLTypes["Contember_GpsWithoutAddressUpdateInput"];
	["Contember_AddressUpsertGpsRelationInput"]: GraphQLTypes["Contember_AddressUpsertGpsRelationInput"];
	["Contember_AddressUpdateInvoicesEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateInvoicesEntityRelationInput"];
	["Contember_AddressUpdateInvoicesRelationInput"]: GraphQLTypes["Contember_AddressUpdateInvoicesRelationInput"];
	["Contember_InvoiceWithoutAddressUpdateInput"]: GraphQLTypes["Contember_InvoiceWithoutAddressUpdateInput"];
	["Contember_InvoiceUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_InvoiceUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutInvoicesUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutInvoicesUpdateInput"];
	["Contember_CustomerUpdateTagsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"];
	["Contember_CustomerUpdateTagsRelationInput"]: GraphQLTypes["Contember_CustomerUpdateTagsRelationInput"];
	["Contember_TagWithoutCustomersUpdateInput"]: GraphQLTypes["Contember_TagWithoutCustomersUpdateInput"];
	["Contember_TagUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"];
	["Contember_TagUpdateProductsRelationInput"]: GraphQLTypes["Contember_TagUpdateProductsRelationInput"];
	["Contember_ProductWithoutTagsUpdateInput"]: GraphQLTypes["Contember_ProductWithoutTagsUpdateInput"];
	["Contember_ProductUpdatePackingEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"];
	["Contember_ProductPackingUpdateInput"]: GraphQLTypes["Contember_ProductPackingUpdateInput"];
	["Contember_ProductPackingUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingUpdateImageEntityRelationInput"];
	["Contember_ImageUpdateInput"]: GraphQLTypes["Contember_ImageUpdateInput"];
	["Contember_ProductPackingUpsertImageRelationInput"]: GraphQLTypes["Contember_ProductPackingUpsertImageRelationInput"];
	["Contember_ProductPackingUpdateIconEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingUpdateIconEntityRelationInput"];
	["Contember_ProductPackingUpsertIconRelationInput"]: GraphQLTypes["Contember_ProductPackingUpsertIconRelationInput"];
	["Contember_ProductPackingUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingUpdateLocalesEntityRelationInput"];
	["Contember_ProductPackingUpdateLocalesRelationInput"]: GraphQLTypes["Contember_ProductPackingUpdateLocalesRelationInput"];
	["Contember_ProductPackingLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_ProductPackingLocaleWithoutRootUpdateInput"];
	["Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductPackingsUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutProductPackingsUpdateInput"];
	["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"];
	["Contember_LocaleUpdateBusinessCategoriesRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesRelationInput"];
	["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"];
	["Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput"];
	["Contember_BusinessCategoryWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutLocalesUpdateInput"];
	["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"];
	["Contember_BusinessCategoryUpdateBusinessCustomersRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersRelationInput"];
	["Contember_BusinessCustomerWithoutCategoryUpdateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutCategoryUpdateInput"];
	["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutBusinessUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutBusinessUpdateInput"];
	["Contember_CustomerUpdateCreditsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"];
	["Contember_CustomerUpdateCreditsRelationInput"]: GraphQLTypes["Contember_CustomerUpdateCreditsRelationInput"];
	["Contember_CustomerCreditWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_CustomerCreditWithoutCustomerUpdateInput"];
	["Contember_CustomerCreditUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpdateVatRateEntityRelationInput"];
	["Contember_VatRateUpdateInput"]: GraphQLTypes["Contember_VatRateUpdateInput"];
	["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"]: GraphQLTypes["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"];
	["Contember_VatRateUpdateChannelDeliveriesRelationInput"]: GraphQLTypes["Contember_VatRateUpdateChannelDeliveriesRelationInput"];
	["Contember_ChannelDeliveryWithoutVatRateUpdateInput"]: GraphQLTypes["Contember_ChannelDeliveryWithoutVatRateUpdateInput"];
	["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"];
	["Contember_ChannelWithoutDeliveriesUpdateInput"]: GraphQLTypes["Contember_ChannelWithoutDeliveriesUpdateInput"];
	["Contember_ChannelUpdatePaymentsEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"];
	["Contember_ChannelUpdatePaymentsRelationInput"]: GraphQLTypes["Contember_ChannelUpdatePaymentsRelationInput"];
	["Contember_ChannelPaymentWithoutChannelUpdateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutChannelUpdateInput"];
	["Contember_ChannelPaymentUpdateMethodEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"];
	["Contember_PaymentMethodWithoutChannelsUpdateInput"]: GraphQLTypes["Contember_PaymentMethodWithoutChannelsUpdateInput"];
	["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"];
	["Contember_PaymentMethodUpdateDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_PaymentMethodUpdateDeliveryMethodsRelationInput"];
	["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"];
	["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"];
	["Contember_DeliveryMethodUpdateChannelsRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateChannelsRelationInput"];
	["Contember_ChannelDeliveryWithoutMethodUpdateInput"]: GraphQLTypes["Contember_ChannelDeliveryWithoutMethodUpdateInput"];
	["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"];
	["Contember_VatRateWithoutChannelDeliveriesUpdateInput"]: GraphQLTypes["Contember_VatRateWithoutChannelDeliveriesUpdateInput"];
	["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"]: GraphQLTypes["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"];
	["Contember_VatRateUpdateChannelPaymentsRelationInput"]: GraphQLTypes["Contember_VatRateUpdateChannelPaymentsRelationInput"];
	["Contember_ChannelPaymentWithoutVatRateUpdateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutVatRateUpdateInput"];
	["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"];
	["Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput"];
	["Contember_TagWithoutAllowChannelPaymentsUpdateInput"]: GraphQLTypes["Contember_TagWithoutAllowChannelPaymentsUpdateInput"];
	["Contember_TagUpdateCustomersEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"];
	["Contember_TagUpdateCustomersRelationInput"]: GraphQLTypes["Contember_TagUpdateCustomersRelationInput"];
	["Contember_CustomerWithoutTagsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutTagsUpdateInput"];
	["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"];
	["Contember_CustomerUpdatePaymentMethodsRelationInput"]: GraphQLTypes["Contember_CustomerUpdatePaymentMethodsRelationInput"];
	["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"];
	["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"]: GraphQLTypes["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"];
	["Contember_PaymentMethodUpdateInput"]: GraphQLTypes["Contember_PaymentMethodUpdateInput"];
	["Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput"]: GraphQLTypes["Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput"];
	["Contember_CustomerUpsertPaymentMethodsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertPaymentMethodsRelationInput"];
	["Contember_CustomerUpdateOrdersEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"];
	["Contember_CustomerUpdateOrdersRelationInput"]: GraphQLTypes["Contember_CustomerUpdateOrdersRelationInput"];
	["Contember_OrderWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_OrderWithoutCustomerUpdateInput"];
	["Contember_OrderUpdateChannelEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"];
	["Contember_ChannelUpdateInput"]: GraphQLTypes["Contember_ChannelUpdateInput"];
	["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"];
	["Contember_ChannelUpdatePointOfSalesRelationInput"]: GraphQLTypes["Contember_ChannelUpdatePointOfSalesRelationInput"];
	["Contember_PointOfSaleWithoutChannelsUpdateInput"]: GraphQLTypes["Contember_PointOfSaleWithoutChannelsUpdateInput"];
	["Contember_PointOfSaleUpdatePermissionsEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleUpdatePermissionsEntityRelationInput"];
	["Contember_ChannelUpsertPointOfSalesRelationInput"]: GraphQLTypes["Contember_ChannelUpsertPointOfSalesRelationInput"];
	["Contember_ChannelUpdateCartsEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"];
	["Contember_ChannelUpdateCartsRelationInput"]: GraphQLTypes["Contember_ChannelUpdateCartsRelationInput"];
	["Contember_CartWithoutChannelUpdateInput"]: GraphQLTypes["Contember_CartWithoutChannelUpdateInput"];
	["Contember_CartUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateItemsEntityRelationInput"];
	["Contember_CartUpdateItemsRelationInput"]: GraphQLTypes["Contember_CartUpdateItemsRelationInput"];
	["Contember_CartItemWithoutCartUpdateInput"]: GraphQLTypes["Contember_CartItemWithoutCartUpdateInput"];
	["Contember_CartItemUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_CartItemUpdateProductEntityRelationInput"];
	["Contember_ProductWithoutCartItemsUpdateInput"]: GraphQLTypes["Contember_ProductWithoutCartItemsUpdateInput"];
	["Contember_ProductUpdateRecipeEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"];
	["Contember_ProductRecipeWithoutProductsUpdateInput"]: GraphQLTypes["Contember_ProductRecipeWithoutProductsUpdateInput"];
	["Contember_ProductRecipeUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateImageEntityRelationInput"];
	["Contember_ProductRecipeUpsertImageRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpsertImageRelationInput"];
	["Contember_ProductRecipeUpdateIconEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateIconEntityRelationInput"];
	["Contember_ProductRecipeUpsertIconRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpsertIconRelationInput"];
	["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"];
	["Contember_ProductRecipeUpdateIngredientsRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateIngredientsRelationInput"];
	["Contember_ProductIngredientItemWithoutRecipeUpdateInput"]: GraphQLTypes["Contember_ProductIngredientItemWithoutRecipeUpdateInput"];
	["Contember_ProductIngredientItemUpdateIngredientEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemUpdateIngredientEntityRelationInput"];
	["Contember_ProductIngredientUpdateInput"]: GraphQLTypes["Contember_ProductIngredientUpdateInput"];
	["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"];
	["Contember_ProductIngredientUpdateLocalesRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateLocalesRelationInput"];
	["Contember_ProductIngredientLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_ProductIngredientLocaleWithoutRootUpdateInput"];
	["Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductIngredientsUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutProductIngredientsUpdateInput"];
	["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"];
	["Contember_LocaleUpdateProductCategoryListsRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductCategoryListsRelationInput"];
	["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"];
	["Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput"];
	["Contember_ProductCategoryListWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_ProductCategoryListWithoutLocalesUpdateInput"];
	["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpdateItemsEntityRelationInput"];
	["Contember_ProductCategoryListUpdateItemsRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpdateItemsRelationInput"];
	["Contember_ProductCategoryListItemWithoutListUpdateInput"]: GraphQLTypes["Contember_ProductCategoryListItemWithoutListUpdateInput"];
	["Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput"];
	["Contember_ProductCategoryUpdateInput"]: GraphQLTypes["Contember_ProductCategoryUpdateInput"];
	["Contember_ProductCategoryUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"];
	["Contember_ProductCategoryUpdateProductsRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateProductsRelationInput"];
	["Contember_ProductWithoutCategoriesUpdateInput"]: GraphQLTypes["Contember_ProductWithoutCategoriesUpdateInput"];
	["Contember_ProductUpdateTagsEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"];
	["Contember_ProductUpdateTagsRelationInput"]: GraphQLTypes["Contember_ProductUpdateTagsRelationInput"];
	["Contember_TagWithoutProductsUpdateInput"]: GraphQLTypes["Contember_TagWithoutProductsUpdateInput"];
	["Contember_TagUpdateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"];
	["Contember_TagUpdateCategoriesRelationInput"]: GraphQLTypes["Contember_TagUpdateCategoriesRelationInput"];
	["Contember_ProductCategoryWithoutTagsUpdateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutTagsUpdateInput"];
	["Contember_ProductCategoryUpdateParentEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"];
	["Contember_ProductCategoryWithoutChildrenUpdateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutChildrenUpdateInput"];
	["Contember_ProductCategoryUpdateTagsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"];
	["Contember_ProductCategoryUpdateTagsRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateTagsRelationInput"];
	["Contember_TagWithoutCategoriesUpdateInput"]: GraphQLTypes["Contember_TagWithoutCategoriesUpdateInput"];
	["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"];
	["Contember_TagUpdateAddTagsOnRegistrationRelationInput"]: GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationRelationInput"];
	["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"]: GraphQLTypes["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"];
	["Contember_ChannelUpdateCustomersEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"];
	["Contember_ChannelUpdateCustomersRelationInput"]: GraphQLTypes["Contember_ChannelUpdateCustomersRelationInput"];
	["Contember_CustomerWithoutChannelsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutChannelsUpdateInput"];
	["Contember_CustomerUpdatePermissionsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"];
	["Contember_CustomerUpdateGroupEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"];
	["Contember_CustomerGroupWithoutCustomersUpdateInput"]: GraphQLTypes["Contember_CustomerGroupWithoutCustomersUpdateInput"];
	["Contember_CustomerGroupUpdateProductPricesEntityRelationInput"]: GraphQLTypes["Contember_CustomerGroupUpdateProductPricesEntityRelationInput"];
	["Contember_CustomerGroupUpdateProductPricesRelationInput"]: GraphQLTypes["Contember_CustomerGroupUpdateProductPricesRelationInput"];
	["Contember_ProductGroupPriceWithoutGroupUpdateInput"]: GraphQLTypes["Contember_ProductGroupPriceWithoutGroupUpdateInput"];
	["Contember_ProductGroupPriceUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductGroupPriceUpdateProductEntityRelationInput"];
	["Contember_ProductWithoutGroupPricesUpdateInput"]: GraphQLTypes["Contember_ProductWithoutGroupPricesUpdateInput"];
	["Contember_ProductUpdateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"];
	["Contember_ProductUpdateCategoriesRelationInput"]: GraphQLTypes["Contember_ProductUpdateCategoriesRelationInput"];
	["Contember_ProductCategoryWithoutProductsUpdateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutProductsUpdateInput"];
	["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"];
	["Contember_ProductCategoryUpdateChildrenRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateChildrenRelationInput"];
	["Contember_ProductCategoryWithoutParentUpdateInput"]: GraphQLTypes["Contember_ProductCategoryWithoutParentUpdateInput"];
	["Contember_ProductCategoryUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"];
	["Contember_ProductCategoryUpsertImageRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpsertImageRelationInput"];
	["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"];
	["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"];
	["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"];
	["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"];
	["Contember_ProductCategoryUpsertChildrenRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpsertChildrenRelationInput"];
	["Contember_ProductUpsertCategoriesRelationInput"]: GraphQLTypes["Contember_ProductUpsertCategoriesRelationInput"];
	["Contember_ProductUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"];
	["Contember_ProductUpsertVatRateRelationInput"]: GraphQLTypes["Contember_ProductUpsertVatRateRelationInput"];
	["Contember_ProductUpdateStocksEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"];
	["Contember_ProductUpdateStocksRelationInput"]: GraphQLTypes["Contember_ProductUpdateStocksRelationInput"];
	["Contember_ProductStockWithoutProductUpdateInput"]: GraphQLTypes["Contember_ProductStockWithoutProductUpdateInput"];
	["Contember_ProductStockUpdateStoreEntityRelationInput"]: GraphQLTypes["Contember_ProductStockUpdateStoreEntityRelationInput"];
	["Contember_StoreUpdateInput"]: GraphQLTypes["Contember_StoreUpdateInput"];
	["Contember_ProductStockUpsertStoreRelationInput"]: GraphQLTypes["Contember_ProductStockUpsertStoreRelationInput"];
	["Contember_ProductUpsertStocksRelationInput"]: GraphQLTypes["Contember_ProductUpsertStocksRelationInput"];
	["Contember_ProductUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"];
	["Contember_ProductUpsertImageRelationInput"]: GraphQLTypes["Contember_ProductUpsertImageRelationInput"];
	["Contember_ProductUpdateCartItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"];
	["Contember_ProductUpdateCartItemsRelationInput"]: GraphQLTypes["Contember_ProductUpdateCartItemsRelationInput"];
	["Contember_CartItemWithoutProductUpdateInput"]: GraphQLTypes["Contember_CartItemWithoutProductUpdateInput"];
	["Contember_CartItemUpdateCartEntityRelationInput"]: GraphQLTypes["Contember_CartItemUpdateCartEntityRelationInput"];
	["Contember_CartWithoutItemsUpdateInput"]: GraphQLTypes["Contember_CartWithoutItemsUpdateInput"];
	["Contember_CartUpdateChannelEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateChannelEntityRelationInput"];
	["Contember_ChannelWithoutCartsUpdateInput"]: GraphQLTypes["Contember_ChannelWithoutCartsUpdateInput"];
	["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"];
	["Contember_ChannelUpdateAddTagsOnRegistrationRelationInput"]: GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationRelationInput"];
	["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"]: GraphQLTypes["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"];
	["Contember_TagUpdateNestedCategoriesEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"];
	["Contember_TagUpdateDeliveryZonesEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"];
	["Contember_TagUpdateDeliveryZonesRelationInput"]: GraphQLTypes["Contember_TagUpdateDeliveryZonesRelationInput"];
	["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"];
	["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"];
	["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput"];
	["Contember_ProductWithoutDeliveryZonesUpdateInput"]: GraphQLTypes["Contember_ProductWithoutDeliveryZonesUpdateInput"];
	["Contember_ProductUpdateGroupPricesEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"];
	["Contember_ProductUpdateGroupPricesRelationInput"]: GraphQLTypes["Contember_ProductUpdateGroupPricesRelationInput"];
	["Contember_ProductGroupPriceWithoutProductUpdateInput"]: GraphQLTypes["Contember_ProductGroupPriceWithoutProductUpdateInput"];
	["Contember_ProductGroupPriceUpdateGroupEntityRelationInput"]: GraphQLTypes["Contember_ProductGroupPriceUpdateGroupEntityRelationInput"];
	["Contember_CustomerGroupWithoutProductPricesUpdateInput"]: GraphQLTypes["Contember_CustomerGroupWithoutProductPricesUpdateInput"];
	["Contember_CustomerGroupUpdateCustomersEntityRelationInput"]: GraphQLTypes["Contember_CustomerGroupUpdateCustomersEntityRelationInput"];
	["Contember_CustomerGroupUpdateCustomersRelationInput"]: GraphQLTypes["Contember_CustomerGroupUpdateCustomersRelationInput"];
	["Contember_CustomerWithoutGroupUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutGroupUpdateInput"];
	["Contember_CustomerUpdatePhotoEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"];
	["Contember_CustomerUpsertPhotoRelationInput"]: GraphQLTypes["Contember_CustomerUpsertPhotoRelationInput"];
	["Contember_CustomerUpdateNameEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"];
	["Contember_CustomerNameWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_CustomerNameWithoutCustomerUpdateInput"];
	["Contember_CustomerNameUpdateAliasesEntityRelationInput"]: GraphQLTypes["Contember_CustomerNameUpdateAliasesEntityRelationInput"];
	["Contember_CustomerNameUpdateAliasesRelationInput"]: GraphQLTypes["Contember_CustomerNameUpdateAliasesRelationInput"];
	["Contember_CustomerAliasUpdateInput"]: GraphQLTypes["Contember_CustomerAliasUpdateInput"];
	["Contember_CustomerNameUpsertAliasesRelationInput"]: GraphQLTypes["Contember_CustomerNameUpsertAliasesRelationInput"];
	["Contember_CustomerUpsertNameRelationInput"]: GraphQLTypes["Contember_CustomerUpsertNameRelationInput"];
	["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"];
	["Contember_CustomerUpdateIngredientRatingsRelationInput"]: GraphQLTypes["Contember_CustomerUpdateIngredientRatingsRelationInput"];
	["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"];
	["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"];
	["Contember_ProductIngredientRatingUpdateInput"]: GraphQLTypes["Contember_ProductIngredientRatingUpdateInput"];
	["Contember_ProductIngredientRatingUpdateIconEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientRatingUpdateIconEntityRelationInput"];
	["Contember_ProductIngredientRatingUpsertIconRelationInput"]: GraphQLTypes["Contember_ProductIngredientRatingUpsertIconRelationInput"];
	["Contember_CustomerIngredientRatingUpsertRatingRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingUpsertRatingRelationInput"];
	["Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput"];
	["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"];
	["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"];
	["Contember_ProductIngredientUpdateCategoriesRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateCategoriesRelationInput"];
	["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"];
	["Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput"];
	["Contember_ProductIngredientCategoryUpdateLocalesRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryUpdateLocalesRelationInput"];
	["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"];
	["Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"];
	["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"];
	["Contember_LocaleUpdateProductIngredientsRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductIngredientsRelationInput"];
	["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"];
	["Contember_ProductIngredientLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientLocaleUpdateRootEntityRelationInput"];
	["Contember_ProductIngredientWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutLocalesUpdateInput"];
	["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"];
	["Contember_ProductIngredientUpdateAllergensRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateAllergensRelationInput"];
	["Contember_AllergenWithoutIngredientsUpdateInput"]: GraphQLTypes["Contember_AllergenWithoutIngredientsUpdateInput"];
	["Contember_AllergenUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_AllergenUpdateLocalesEntityRelationInput"];
	["Contember_AllergenUpdateLocalesRelationInput"]: GraphQLTypes["Contember_AllergenUpdateLocalesRelationInput"];
	["Contember_AllergenLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_AllergenLocaleWithoutRootUpdateInput"];
	["Contember_AllergenLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_AllergenLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutAllergensUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutAllergensUpdateInput"];
	["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"];
	["Contember_LocaleUpdateOrderRecurrencesRelationInput"]: GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesRelationInput"];
	["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"];
	["Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput"];
	["Contember_OrderRecurrenceWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_OrderRecurrenceWithoutLocalesUpdateInput"];
	["Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput"];
	["Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput"];
	["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"];
	["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"];
	["Contember_BusinessCategoryUpdateLocalesRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateLocalesRelationInput"];
	["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"];
	["Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutBusinessCategoriesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutBusinessCategoriesUpdateInput"];
	["Contember_LocaleUpdateBlogsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"];
	["Contember_LocaleUpdateBlogsRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBlogsRelationInput"];
	["Contember_BlogLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_BlogLocaleWithoutLocaleUpdateInput"];
	["Contember_BlogLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_BlogLocaleUpdateRootEntityRelationInput"];
	["Contember_BlogWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_BlogWithoutLocalesUpdateInput"];
	["Contember_BlogLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_BlogLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertBlogsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertBlogsRelationInput"];
	["Contember_LocaleUpdateBlogPostsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"];
	["Contember_LocaleUpdateBlogPostsRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBlogPostsRelationInput"];
	["Contember_BlogPostLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_BlogPostLocaleWithoutLocaleUpdateInput"];
	["Contember_BlogPostLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateRootEntityRelationInput"];
	["Contember_BlogPostWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_BlogPostWithoutLocalesUpdateInput"];
	["Contember_BlogPostLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpsertRootRelationInput"];
	["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"];
	["Contember_CoverUpdateInput"]: GraphQLTypes["Contember_CoverUpdateInput"];
	["Contember_CoverUpdateMediumEntityRelationInput"]: GraphQLTypes["Contember_CoverUpdateMediumEntityRelationInput"];
	["Contember_MediumUpdateInput"]: GraphQLTypes["Contember_MediumUpdateInput"];
	["Contember_MediumUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_MediumUpdateImageEntityRelationInput"];
	["Contember_MediumUpsertImageRelationInput"]: GraphQLTypes["Contember_MediumUpsertImageRelationInput"];
	["Contember_MediumUpdateVideoEntityRelationInput"]: GraphQLTypes["Contember_MediumUpdateVideoEntityRelationInput"];
	["Contember_VideoUpdateInput"]: GraphQLTypes["Contember_VideoUpdateInput"];
	["Contember_VideoUpdatePosterEntityRelationInput"]: GraphQLTypes["Contember_VideoUpdatePosterEntityRelationInput"];
	["Contember_VideoUpsertPosterRelationInput"]: GraphQLTypes["Contember_VideoUpsertPosterRelationInput"];
	["Contember_MediumUpsertVideoRelationInput"]: GraphQLTypes["Contember_MediumUpsertVideoRelationInput"];
	["Contember_CoverUpsertMediumRelationInput"]: GraphQLTypes["Contember_CoverUpsertMediumRelationInput"];
	["Contember_CoverUpdateButtonColorsEntityRelationInput"]: GraphQLTypes["Contember_CoverUpdateButtonColorsEntityRelationInput"];
	["Contember_ColorPalleteUpdateInput"]: GraphQLTypes["Contember_ColorPalleteUpdateInput"];
	["Contember_CoverUpsertButtonColorsRelationInput"]: GraphQLTypes["Contember_CoverUpsertButtonColorsRelationInput"];
	["Contember_BlogPostLocaleUpsertCoverRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpsertCoverRelationInput"];
	["Contember_BlogPostLocaleUpdateContentEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateContentEntityRelationInput"];
	["Contember_ContentUpdateInput"]: GraphQLTypes["Contember_ContentUpdateInput"];
	["Contember_ContentUpdateBlocksEntityRelationInput"]: GraphQLTypes["Contember_ContentUpdateBlocksEntityRelationInput"];
	["Contember_ContentUpdateBlocksRelationInput"]: GraphQLTypes["Contember_ContentUpdateBlocksRelationInput"];
	["Contember_ContentBlockWithoutContentUpdateInput"]: GraphQLTypes["Contember_ContentBlockWithoutContentUpdateInput"];
	["Contember_ContentBlockUpdateReferencesEntityRelationInput"]: GraphQLTypes["Contember_ContentBlockUpdateReferencesEntityRelationInput"];
	["Contember_ContentBlockUpdateReferencesRelationInput"]: GraphQLTypes["Contember_ContentBlockUpdateReferencesRelationInput"];
	["Contember_ContentReferenceWithoutBlockUpdateInput"]: GraphQLTypes["Contember_ContentReferenceWithoutBlockUpdateInput"];
	["Contember_ContentReferenceUpdateColorPalleteEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateColorPalleteEntityRelationInput"];
	["Contember_ContentReferenceUpsertColorPalleteRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertColorPalleteRelationInput"];
	["Contember_ContentReferenceUpdateMediumEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateMediumEntityRelationInput"];
	["Contember_ContentReferenceUpsertMediumRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertMediumRelationInput"];
	["Contember_ContentReferenceUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateLinkEntityRelationInput"];
	["Contember_LinkUpdateInput"]: GraphQLTypes["Contember_LinkUpdateInput"];
	["Contember_LinkUpdateInternalLinkEntityRelationInput"]: GraphQLTypes["Contember_LinkUpdateInternalLinkEntityRelationInput"];
	["Contember_LinkableUpdateInput"]: GraphQLTypes["Contember_LinkableUpdateInput"];
	["Contember_LinkableUpdateBlogPostEntityRelationInput"]: GraphQLTypes["Contember_LinkableUpdateBlogPostEntityRelationInput"];
	["Contember_BlogPostLocaleWithoutLinkUpdateInput"]: GraphQLTypes["Contember_BlogPostLocaleWithoutLinkUpdateInput"];
	["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutBlogPostsUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutBlogPostsUpdateInput"];
	["Contember_LocaleUpdateIdentifierEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"];
	["Contember_TranslationCataloguesIdentifierUpdateInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpdateInput"];
	["Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput"];
	["Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput"];
	["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"]: GraphQLTypes["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"];
	["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"];
	["Contember_TranslationDomainWithoutCataloguesUpdateInput"]: GraphQLTypes["Contember_TranslationDomainWithoutCataloguesUpdateInput"];
	["Contember_TranslationDomainUpdateKeysEntityRelationInput"]: GraphQLTypes["Contember_TranslationDomainUpdateKeysEntityRelationInput"];
	["Contember_TranslationDomainUpdateKeysRelationInput"]: GraphQLTypes["Contember_TranslationDomainUpdateKeysRelationInput"];
	["Contember_TranslationKeyWithoutDomainUpdateInput"]: GraphQLTypes["Contember_TranslationKeyWithoutDomainUpdateInput"];
	["Contember_TranslationKeyUpdateValuesEntityRelationInput"]: GraphQLTypes["Contember_TranslationKeyUpdateValuesEntityRelationInput"];
	["Contember_TranslationKeyUpdateValuesRelationInput"]: GraphQLTypes["Contember_TranslationKeyUpdateValuesRelationInput"];
	["Contember_TranslationValueWithoutKeyUpdateInput"]: GraphQLTypes["Contember_TranslationValueWithoutKeyUpdateInput"];
	["Contember_TranslationValueUpdateCatalogueEntityRelationInput"]: GraphQLTypes["Contember_TranslationValueUpdateCatalogueEntityRelationInput"];
	["Contember_TranslationCatalogueWithoutValuesUpdateInput"]: GraphQLTypes["Contember_TranslationCatalogueWithoutValuesUpdateInput"];
	["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"];
	["Contember_TranslationCatalogueUpdateInput"]: GraphQLTypes["Contember_TranslationCatalogueUpdateInput"];
	["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"];
	["Contember_TranslationCatalogueUpdateValuesRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpdateValuesRelationInput"];
	["Contember_TranslationValueWithoutCatalogueUpdateInput"]: GraphQLTypes["Contember_TranslationValueWithoutCatalogueUpdateInput"];
	["Contember_TranslationValueUpdateKeyEntityRelationInput"]: GraphQLTypes["Contember_TranslationValueUpdateKeyEntityRelationInput"];
	["Contember_TranslationKeyWithoutValuesUpdateInput"]: GraphQLTypes["Contember_TranslationKeyWithoutValuesUpdateInput"];
	["Contember_TranslationKeyUpdateDomainEntityRelationInput"]: GraphQLTypes["Contember_TranslationKeyUpdateDomainEntityRelationInput"];
	["Contember_TranslationDomainWithoutKeysUpdateInput"]: GraphQLTypes["Contember_TranslationDomainWithoutKeysUpdateInput"];
	["Contember_TranslationDomainUpdateCataloguesEntityRelationInput"]: GraphQLTypes["Contember_TranslationDomainUpdateCataloguesEntityRelationInput"];
	["Contember_TranslationDomainUpdateCataloguesRelationInput"]: GraphQLTypes["Contember_TranslationDomainUpdateCataloguesRelationInput"];
	["Contember_TranslationCatalogueWithoutDomainUpdateInput"]: GraphQLTypes["Contember_TranslationCatalogueWithoutDomainUpdateInput"];
	["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"];
	["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"];
	["Contember_TranslationCatalogueUpsertIdentifierRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpsertIdentifierRelationInput"];
	["Contember_TranslationDomainUpsertCataloguesRelationInput"]: GraphQLTypes["Contember_TranslationDomainUpsertCataloguesRelationInput"];
	["Contember_TranslationKeyUpsertDomainRelationInput"]: GraphQLTypes["Contember_TranslationKeyUpsertDomainRelationInput"];
	["Contember_TranslationValueUpsertKeyRelationInput"]: GraphQLTypes["Contember_TranslationValueUpsertKeyRelationInput"];
	["Contember_TranslationCatalogueUpsertValuesRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpsertValuesRelationInput"];
	["Contember_TranslationCatalogueUpsertFallbackRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpsertFallbackRelationInput"];
	["Contember_TranslationValueUpsertCatalogueRelationInput"]: GraphQLTypes["Contember_TranslationValueUpsertCatalogueRelationInput"];
	["Contember_TranslationKeyUpsertValuesRelationInput"]: GraphQLTypes["Contember_TranslationKeyUpsertValuesRelationInput"];
	["Contember_TranslationDomainUpsertKeysRelationInput"]: GraphQLTypes["Contember_TranslationDomainUpsertKeysRelationInput"];
	["Contember_TranslationCatalogueUpsertDomainRelationInput"]: GraphQLTypes["Contember_TranslationCatalogueUpsertDomainRelationInput"];
	["Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput"]: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput"];
	["Contember_LocaleUpsertIdentifierRelationInput"]: GraphQLTypes["Contember_LocaleUpsertIdentifierRelationInput"];
	["Contember_LocaleUpdateMenusEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"];
	["Contember_LocaleUpdateMenusRelationInput"]: GraphQLTypes["Contember_LocaleUpdateMenusRelationInput"];
	["Contember_MenuLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_MenuLocaleWithoutLocaleUpdateInput"];
	["Contember_MenuLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpdateRootEntityRelationInput"];
	["Contember_SiteMenuWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_SiteMenuWithoutLocalesUpdateInput"];
	["Contember_MenuLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpsertRootRelationInput"];
	["Contember_MenuLocaleUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpdateItemsEntityRelationInput"];
	["Contember_MenuLocaleUpdateItemsRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpdateItemsRelationInput"];
	["Contember_MenuItemWithoutMenuUpdateInput"]: GraphQLTypes["Contember_MenuItemWithoutMenuUpdateInput"];
	["Contember_MenuItemUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_MenuItemUpdateLinkEntityRelationInput"];
	["Contember_MenuItemUpsertLinkRelationInput"]: GraphQLTypes["Contember_MenuItemUpsertLinkRelationInput"];
	["Contember_MenuItemUpdateSubitemsEntityRelationInput"]: GraphQLTypes["Contember_MenuItemUpdateSubitemsEntityRelationInput"];
	["Contember_MenuItemUpdateSubitemsRelationInput"]: GraphQLTypes["Contember_MenuItemUpdateSubitemsRelationInput"];
	["Contember_MenuSubitemWithoutItemUpdateInput"]: GraphQLTypes["Contember_MenuSubitemWithoutItemUpdateInput"];
	["Contember_MenuSubitemUpdateLinksEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpdateLinksEntityRelationInput"];
	["Contember_MenuLinkListUpdateInput"]: GraphQLTypes["Contember_MenuLinkListUpdateInput"];
	["Contember_MenuLinkListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_MenuLinkListUpdateItemsEntityRelationInput"];
	["Contember_MenuLinkListUpdateItemsRelationInput"]: GraphQLTypes["Contember_MenuLinkListUpdateItemsRelationInput"];
	["Contember_MenuLinkItemWithoutListUpdateInput"]: GraphQLTypes["Contember_MenuLinkItemWithoutListUpdateInput"];
	["Contember_MenuLinkItemUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_MenuLinkItemUpdateLinkEntityRelationInput"];
	["Contember_MenuLinkItemUpsertLinkRelationInput"]: GraphQLTypes["Contember_MenuLinkItemUpsertLinkRelationInput"];
	["Contember_MenuLinkItemUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_MenuLinkItemUpdateImageEntityRelationInput"];
	["Contember_MenuLinkItemUpsertImageRelationInput"]: GraphQLTypes["Contember_MenuLinkItemUpsertImageRelationInput"];
	["Contember_MenuLinkListUpsertItemsRelationInput"]: GraphQLTypes["Contember_MenuLinkListUpsertItemsRelationInput"];
	["Contember_MenuSubitemUpsertLinksRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpsertLinksRelationInput"];
	["Contember_MenuSubitemUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpdateProductsEntityRelationInput"];
	["Contember_MenuProductsUpdateInput"]: GraphQLTypes["Contember_MenuProductsUpdateInput"];
	["Contember_MenuProductsUpdateLinksEntityRelationInput"]: GraphQLTypes["Contember_MenuProductsUpdateLinksEntityRelationInput"];
	["Contember_MenuProductsUpsertLinksRelationInput"]: GraphQLTypes["Contember_MenuProductsUpsertLinksRelationInput"];
	["Contember_MenuProductsUpdateButtonsEntityRelationInput"]: GraphQLTypes["Contember_MenuProductsUpdateButtonsEntityRelationInput"];
	["Contember_LinkListUpdateInput"]: GraphQLTypes["Contember_LinkListUpdateInput"];
	["Contember_LinkListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_LinkListUpdateItemsEntityRelationInput"];
	["Contember_LinkListUpdateItemsRelationInput"]: GraphQLTypes["Contember_LinkListUpdateItemsRelationInput"];
	["Contember_LinkListItemWithoutListUpdateInput"]: GraphQLTypes["Contember_LinkListItemWithoutListUpdateInput"];
	["Contember_LinkListItemUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_LinkListItemUpdateLinkEntityRelationInput"];
	["Contember_LinkListItemUpsertLinkRelationInput"]: GraphQLTypes["Contember_LinkListItemUpsertLinkRelationInput"];
	["Contember_LinkListUpsertItemsRelationInput"]: GraphQLTypes["Contember_LinkListUpsertItemsRelationInput"];
	["Contember_MenuProductsUpsertButtonsRelationInput"]: GraphQLTypes["Contember_MenuProductsUpsertButtonsRelationInput"];
	["Contember_MenuSubitemUpsertProductsRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpsertProductsRelationInput"];
	["Contember_MenuSubitemUpdateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpdateDeliveryEntityRelationInput"];
	["Contember_MenuDeliveryUpdateInput"]: GraphQLTypes["Contember_MenuDeliveryUpdateInput"];
	["Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput"]: GraphQLTypes["Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput"];
	["Contember_DeliveryRegionsUpdateInput"]: GraphQLTypes["Contember_DeliveryRegionsUpdateInput"];
	["Contember_DeliveryRegionsUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsUpdateItemsEntityRelationInput"];
	["Contember_DeliveryRegionsUpdateItemsRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsUpdateItemsRelationInput"];
	["Contember_DeliveryRegionsItemWithoutListUpdateInput"]: GraphQLTypes["Contember_DeliveryRegionsItemWithoutListUpdateInput"];
	["Contember_DeliveryRegionsItemUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemUpdateImageEntityRelationInput"];
	["Contember_DeliveryRegionsItemUpsertImageRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemUpsertImageRelationInput"];
	["Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput"];
	["Contember_TextListUpdateInput"]: GraphQLTypes["Contember_TextListUpdateInput"];
	["Contember_TextListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_TextListUpdateItemsEntityRelationInput"];
	["Contember_TextListUpdateItemsRelationInput"]: GraphQLTypes["Contember_TextListUpdateItemsRelationInput"];
	["Contember_TextItemWithoutListUpdateInput"]: GraphQLTypes["Contember_TextItemWithoutListUpdateInput"];
	["Contember_TextListUpsertItemsRelationInput"]: GraphQLTypes["Contember_TextListUpsertItemsRelationInput"];
	["Contember_DeliveryRegionsItemUpsertTextListRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemUpsertTextListRelationInput"];
	["Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput"];
	["Contember_ImageListUpdateInput"]: GraphQLTypes["Contember_ImageListUpdateInput"];
	["Contember_ImageListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_ImageListUpdateItemsEntityRelationInput"];
	["Contember_ImageListUpdateItemsRelationInput"]: GraphQLTypes["Contember_ImageListUpdateItemsRelationInput"];
	["Contember_ImageListItemWithoutListUpdateInput"]: GraphQLTypes["Contember_ImageListItemWithoutListUpdateInput"];
	["Contember_ImageListItemUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_ImageListItemUpdateImageEntityRelationInput"];
	["Contember_ImageListItemUpsertImageRelationInput"]: GraphQLTypes["Contember_ImageListItemUpsertImageRelationInput"];
	["Contember_ImageListUpsertItemsRelationInput"]: GraphQLTypes["Contember_ImageListUpsertItemsRelationInput"];
	["Contember_DeliveryRegionsItemUpsertGalleryRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsItemUpsertGalleryRelationInput"];
	["Contember_DeliveryRegionsUpsertItemsRelationInput"]: GraphQLTypes["Contember_DeliveryRegionsUpsertItemsRelationInput"];
	["Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput"]: GraphQLTypes["Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput"];
	["Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput"]: GraphQLTypes["Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput"];
	["Contember_SocialsAndAppsUpdateInput"]: GraphQLTypes["Contember_SocialsAndAppsUpdateInput"];
	["Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput"]: GraphQLTypes["Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput"];
	["Contember_MenuSubitemUpsertDeliveryRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpsertDeliveryRelationInput"];
	["Contember_MenuSubitemUpdateContactEntityRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpdateContactEntityRelationInput"];
	["Contember_MenuContactUpdateInput"]: GraphQLTypes["Contember_MenuContactUpdateInput"];
	["Contember_MenuContactUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_MenuContactUpdateImageEntityRelationInput"];
	["Contember_MenuContactUpsertImageRelationInput"]: GraphQLTypes["Contember_MenuContactUpsertImageRelationInput"];
	["Contember_MenuSubitemUpsertContactRelationInput"]: GraphQLTypes["Contember_MenuSubitemUpsertContactRelationInput"];
	["Contember_MenuItemUpsertSubitemsRelationInput"]: GraphQLTypes["Contember_MenuItemUpsertSubitemsRelationInput"];
	["Contember_MenuLocaleUpsertItemsRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpsertItemsRelationInput"];
	["Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput"];
	["Contember_MenuLocaleUpsertSecondaryItemsRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpsertSecondaryItemsRelationInput"];
	["Contember_MenuLocaleUpdateEshopLinkEntityRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpdateEshopLinkEntityRelationInput"];
	["Contember_MenuLocaleUpsertEshopLinkRelationInput"]: GraphQLTypes["Contember_MenuLocaleUpsertEshopLinkRelationInput"];
	["Contember_LocaleUpsertMenusRelationInput"]: GraphQLTypes["Contember_LocaleUpsertMenusRelationInput"];
	["Contember_LocaleUpdatePagesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"];
	["Contember_LocaleUpdatePagesRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePagesRelationInput"];
	["Contember_PageLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_PageLocaleWithoutLocaleUpdateInput"];
	["Contember_PageLocaleUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleUpdateLinkEntityRelationInput"];
	["Contember_LinkableWithoutPageUpdateInput"]: GraphQLTypes["Contember_LinkableWithoutPageUpdateInput"];
	["Contember_LinkableUpdateWikiPageEntityRelationInput"]: GraphQLTypes["Contember_LinkableUpdateWikiPageEntityRelationInput"];
	["Contember_WikiPageWithoutLinkUpdateInput"]: GraphQLTypes["Contember_WikiPageWithoutLinkUpdateInput"];
	["Contember_WikiPageUpdateParentEntityRelationInput"]: GraphQLTypes["Contember_WikiPageUpdateParentEntityRelationInput"];
	["Contember_WikiPageWithoutChildrenUpdateInput"]: GraphQLTypes["Contember_WikiPageWithoutChildrenUpdateInput"];
	["Contember_WikiPageUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_WikiPageUpdateLinkEntityRelationInput"];
	["Contember_LinkableWithoutWikiPageUpdateInput"]: GraphQLTypes["Contember_LinkableWithoutWikiPageUpdateInput"];
	["Contember_LinkableUpdatePageEntityRelationInput"]: GraphQLTypes["Contember_LinkableUpdatePageEntityRelationInput"];
	["Contember_PageLocaleWithoutLinkUpdateInput"]: GraphQLTypes["Contember_PageLocaleWithoutLinkUpdateInput"];
	["Contember_PageLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleUpdateRootEntityRelationInput"];
	["Contember_PageWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_PageWithoutLocalesUpdateInput"];
	["Contember_PageLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_PageLocaleUpsertRootRelationInput"];
	["Contember_PageLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPagesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutPagesUpdateInput"];
	["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"];
	["Contember_LocaleUpdateProductIngredientCategoriesRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesRelationInput"];
	["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"];
	["Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput"];
	["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"];
	["Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput"];
	["Contember_ProductIngredientCategoryUpdateIngredientsRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryUpdateIngredientsRelationInput"];
	["Contember_ProductIngredientWithoutCategoriesUpdateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutCategoriesUpdateInput"];
	["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"];
	["Contember_ProductIngredientUpdateCustomerRatingsRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateCustomerRatingsRelationInput"];
	["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"]: GraphQLTypes["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"];
	["Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutIngredientRatingsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutIngredientRatingsUpdateInput"];
	["Contember_CustomerUpdateBusinessEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"];
	["Contember_BusinessCustomerWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutCustomerUpdateInput"];
	["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"];
	["Contember_BusinessCustomerUpdateOwnersRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateOwnersRelationInput"];
	["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"];
	["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"];
	["Contember_CustomerUpdateMemberOfBusinessesRelationInput"]: GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesRelationInput"];
	["Contember_BusinessCustomerWithoutMembersUpdateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutMembersUpdateInput"];
	["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"];
	["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"];
	["Contember_BusinessCategoryUpdateRecurrencesRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesRelationInput"];
	["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"]: GraphQLTypes["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"];
	["Contember_OrderRecurrenceUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceUpdateLocalesEntityRelationInput"];
	["Contember_OrderRecurrenceUpdateLocalesRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceUpdateLocalesRelationInput"];
	["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"];
	["Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutOrderRecurrencesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutOrderRecurrencesUpdateInput"];
	["Contember_LocaleUpdateAllergensEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"];
	["Contember_LocaleUpdateAllergensRelationInput"]: GraphQLTypes["Contember_LocaleUpdateAllergensRelationInput"];
	["Contember_AllergenLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_AllergenLocaleWithoutLocaleUpdateInput"];
	["Contember_AllergenLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_AllergenLocaleUpdateRootEntityRelationInput"];
	["Contember_AllergenWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_AllergenWithoutLocalesUpdateInput"];
	["Contember_AllergenUpdateIngredientsEntityRelationInput"]: GraphQLTypes["Contember_AllergenUpdateIngredientsEntityRelationInput"];
	["Contember_AllergenUpdateIngredientsRelationInput"]: GraphQLTypes["Contember_AllergenUpdateIngredientsRelationInput"];
	["Contember_ProductIngredientWithoutAllergensUpdateInput"]: GraphQLTypes["Contember_ProductIngredientWithoutAllergensUpdateInput"];
	["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"];
	["Contember_ProductIngredientItemQuantityUnitUpdateInput"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"];
	["Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput"];
	["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"];
	["Contember_FreshingContainerUpdateInput"]: GraphQLTypes["Contember_FreshingContainerUpdateInput"];
	["Contember_FreshingContainerUpdateWorkspaceEntityRelationInput"]: GraphQLTypes["Contember_FreshingContainerUpdateWorkspaceEntityRelationInput"];
	["Contember_WorkspaceWithoutFreshingContainersUpdateInput"]: GraphQLTypes["Contember_WorkspaceWithoutFreshingContainersUpdateInput"];
	["Contember_WorkspaceUpdateOpeningHoursEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceUpdateOpeningHoursEntityRelationInput"];
	["Contember_WeekHoursUpdateInput"]: GraphQLTypes["Contember_WeekHoursUpdateInput"];
	["Contember_WorkspaceUpsertOpeningHoursRelationInput"]: GraphQLTypes["Contember_WorkspaceUpsertOpeningHoursRelationInput"];
	["Contember_WorkspaceUpdateWorkingHoursEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceUpdateWorkingHoursEntityRelationInput"];
	["Contember_WorkspaceUpsertWorkingHoursRelationInput"]: GraphQLTypes["Contember_WorkspaceUpsertWorkingHoursRelationInput"];
	["Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput"];
	["Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput"]: GraphQLTypes["Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput"];
	["Contember_WorkspaceUpdateCreditsProductEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceUpdateCreditsProductEntityRelationInput"];
	["Contember_VirtualProductUpdateInput"]: GraphQLTypes["Contember_VirtualProductUpdateInput"];
	["Contember_VirtualProductUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductUpdateProductEntityRelationInput"];
	["Contember_ProductWithoutVirtualProductUpdateInput"]: GraphQLTypes["Contember_ProductWithoutVirtualProductUpdateInput"];
	["Contember_ProductUpdateManagerEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"];
	["Contember_StocksManagerWithoutManagedProductsUpdateInput"]: GraphQLTypes["Contember_StocksManagerWithoutManagedProductsUpdateInput"];
	["Contember_StocksManagerUpdateStaffEntityRelationInput"]: GraphQLTypes["Contember_StocksManagerUpdateStaffEntityRelationInput"];
	["Contember_StaffWithoutStocksManagerUpdateInput"]: GraphQLTypes["Contember_StaffWithoutStocksManagerUpdateInput"];
	["Contember_StaffUpdateBartenderEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"];
	["Contember_BartenderWithoutStaffUpdateInput"]: GraphQLTypes["Contember_BartenderWithoutStaffUpdateInput"];
	["Contember_StaffUpsertBartenderRelationInput"]: GraphQLTypes["Contember_StaffUpsertBartenderRelationInput"];
	["Contember_StaffUpdateCourierEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"];
	["Contember_CourierWithoutStaffUpdateInput"]: GraphQLTypes["Contember_CourierWithoutStaffUpdateInput"];
	["Contember_CourierUpdateRoutesEntityRelationInput"]: GraphQLTypes["Contember_CourierUpdateRoutesEntityRelationInput"];
	["Contember_CourierUpdateRoutesRelationInput"]: GraphQLTypes["Contember_CourierUpdateRoutesRelationInput"];
	["Contember_DeliveryRouteWithoutCourierUpdateInput"]: GraphQLTypes["Contember_DeliveryRouteWithoutCourierUpdateInput"];
	["Contember_DeliveryRouteUpdateWaypointsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRouteUpdateWaypointsEntityRelationInput"];
	["Contember_DeliveryRouteUpdateWaypointsRelationInput"]: GraphQLTypes["Contember_DeliveryRouteUpdateWaypointsRelationInput"];
	["Contember_DeliveryWaypointWithoutRouteUpdateInput"]: GraphQLTypes["Contember_DeliveryWaypointWithoutRouteUpdateInput"];
	["Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput"];
	["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"];
	["Contember_OrderDeliveryUpdateMethodEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"];
	["Contember_DeliveryMethodUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateInput"];
	["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"];
	["Contember_DeliveryMethodUpdateCourierHandlersRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersRelationInput"];
	["Contember_CourierWithoutDeliveryMethodsUpdateInput"]: GraphQLTypes["Contember_CourierWithoutDeliveryMethodsUpdateInput"];
	["Contember_CourierUpdateStaffEntityRelationInput"]: GraphQLTypes["Contember_CourierUpdateStaffEntityRelationInput"];
	["Contember_StaffWithoutCourierUpdateInput"]: GraphQLTypes["Contember_StaffWithoutCourierUpdateInput"];
	["Contember_StaffUpdateCleanerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"];
	["Contember_CleanerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_CleanerWithoutStaffUpdateInput"];
	["Contember_StaffUpsertCleanerRelationInput"]: GraphQLTypes["Contember_StaffUpsertCleanerRelationInput"];
	["Contember_StaffUpdateShiftsManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"];
	["Contember_ShiftsManagerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_ShiftsManagerWithoutStaffUpdateInput"];
	["Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput"]: GraphQLTypes["Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput"];
	["Contember_ShiftsManagerUpdateManagedRolesRelationInput"]: GraphQLTypes["Contember_ShiftsManagerUpdateManagedRolesRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"];
	["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"];
	["Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput"];
	["Contember_TimetableShiftGroupWithoutTypeUpdateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutTypeUpdateInput"];
	["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"];
	["Contember_TimetableShiftGroupUpdateShiftsRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpdateShiftsRelationInput"];
	["Contember_TimetableShiftWithoutGroupUpdateInput"]: GraphQLTypes["Contember_TimetableShiftWithoutGroupUpdateInput"];
	["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"];
	["Contember_ShiftsProfileWithoutShiftsUpdateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutShiftsUpdateInput"];
	["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"];
	["Contember_ShiftsProfileUpdateQueueItemsRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateQueueItemsRelationInput"];
	["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"]: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"];
	["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"];
	["Contember_EmployeeQueueWithoutItemsUpdateInput"]: GraphQLTypes["Contember_EmployeeQueueWithoutItemsUpdateInput"];
	["Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput"];
	["Contember_TimetableShiftGroupWithoutQueueUpdateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutQueueUpdateInput"];
	["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"];
	["Contember_TimetableDayWithoutShiftGroupsUpdateInput"]: GraphQLTypes["Contember_TimetableDayWithoutShiftGroupsUpdateInput"];
	["Contember_TimetableDayUpdateNotesEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayUpdateNotesEntityRelationInput"];
	["Contember_TimetableDayUpdateNotesRelationInput"]: GraphQLTypes["Contember_TimetableDayUpdateNotesRelationInput"];
	["Contember_TimetableDayNoteWithoutDayUpdateInput"]: GraphQLTypes["Contember_TimetableDayNoteWithoutDayUpdateInput"];
	["Contember_TimetableDayNoteUpdateRoleEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayNoteUpdateRoleEntityRelationInput"];
	["Contember_TimetableEmployeeRoleUpdateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateInput"];
	["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"];
	["Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput"];
	["Contember_ShiftsProfileWithoutRolesUpdateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutRolesUpdateInput"];
	["Contember_ShiftsProfileUpdateStaffEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"];
	["Contember_StaffWithoutShiftsProfileUpdateInput"]: GraphQLTypes["Contember_StaffWithoutShiftsProfileUpdateInput"];
	["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"];
	["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"];
	["Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput"];
	["Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput"];
	["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"];
	["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"];
	["Contember_BusinessCustomerUpdateMembersRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateMembersRelationInput"];
	["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"];
	["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"];
	["Contember_CustomerUpdateOwnerOfBusinessesRelationInput"]: GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesRelationInput"];
	["Contember_BusinessCustomerWithoutOwnersUpdateInput"]: GraphQLTypes["Contember_BusinessCustomerWithoutOwnersUpdateInput"];
	["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"];
	["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"];
	["Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput"];
	["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"]: GraphQLTypes["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"];
	["Contember_StaffUpdateUserEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"];
	["Contember_UserWithoutStaffUpdateInput"]: GraphQLTypes["Contember_UserWithoutStaffUpdateInput"];
	["Contember_UserUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_UserUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutUserUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutUserUpdateInput"];
	["Contember_CustomerUpdateCartsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"];
	["Contember_CustomerUpdateCartsRelationInput"]: GraphQLTypes["Contember_CustomerUpdateCartsRelationInput"];
	["Contember_CartWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_CartWithoutCustomerUpdateInput"];
	["Contember_CartUpdateOrderEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateOrderEntityRelationInput"];
	["Contember_OrderWithoutCartUpdateInput"]: GraphQLTypes["Contember_OrderWithoutCartUpdateInput"];
	["Contember_OrderUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutOrdersUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutOrdersUpdateInput"];
	["Contember_CustomerUpdateShortcutListEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"];
	["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"];
	["Contember_ApplicationShortcutListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_ApplicationShortcutListUpdateItemsEntityRelationInput"];
	["Contember_ApplicationShortcutListUpdateItemsRelationInput"]: GraphQLTypes["Contember_ApplicationShortcutListUpdateItemsRelationInput"];
	["Contember_ApplicationShortcutListItemWithoutListUpdateInput"]: GraphQLTypes["Contember_ApplicationShortcutListItemWithoutListUpdateInput"];
	["Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput"]: GraphQLTypes["Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput"];
	["Contember_ApplicationUpdateInput"]: GraphQLTypes["Contember_ApplicationUpdateInput"];
	["Contember_ApplicationUpdateIconEntityRelationInput"]: GraphQLTypes["Contember_ApplicationUpdateIconEntityRelationInput"];
	["Contember_ApplicationUpsertIconRelationInput"]: GraphQLTypes["Contember_ApplicationUpsertIconRelationInput"];
	["Contember_ApplicationShortcutListItemUpsertApplicationRelationInput"]: GraphQLTypes["Contember_ApplicationShortcutListItemUpsertApplicationRelationInput"];
	["Contember_ApplicationShortcutListUpsertItemsRelationInput"]: GraphQLTypes["Contember_ApplicationShortcutListUpsertItemsRelationInput"];
	["Contember_CustomerUpsertShortcutListRelationInput"]: GraphQLTypes["Contember_CustomerUpsertShortcutListRelationInput"];
	["Contember_CustomerUpdateUserEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"];
	["Contember_UserWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_UserWithoutCustomerUpdateInput"];
	["Contember_UserUpdateStaffEntityRelationInput"]: GraphQLTypes["Contember_UserUpdateStaffEntityRelationInput"];
	["Contember_StaffWithoutUserUpdateInput"]: GraphQLTypes["Contember_StaffWithoutUserUpdateInput"];
	["Contember_StaffUpdateShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"];
	["Contember_ShiftsProfileWithoutStaffUpdateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutStaffUpdateInput"];
	["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"];
	["Contember_ShiftsProfileUpdateRolesRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateRolesRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"];
	["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"];
	["Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput"];
	["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"]: GraphQLTypes["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"];
	["Contember_ShiftsManagerUpdateStaffEntityRelationInput"]: GraphQLTypes["Contember_ShiftsManagerUpdateStaffEntityRelationInput"];
	["Contember_StaffWithoutShiftsManagerUpdateInput"]: GraphQLTypes["Contember_StaffWithoutShiftsManagerUpdateInput"];
	["Contember_StaffUpdateStocksManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"];
	["Contember_StocksManagerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_StocksManagerWithoutStaffUpdateInput"];
	["Contember_StocksManagerUpdateManagedProductsEntityRelationInput"]: GraphQLTypes["Contember_StocksManagerUpdateManagedProductsEntityRelationInput"];
	["Contember_StocksManagerUpdateManagedProductsRelationInput"]: GraphQLTypes["Contember_StocksManagerUpdateManagedProductsRelationInput"];
	["Contember_ProductWithoutManagerUpdateInput"]: GraphQLTypes["Contember_ProductWithoutManagerUpdateInput"];
	["Contember_ProductUpdateOrderItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"];
	["Contember_ProductUpdateOrderItemsRelationInput"]: GraphQLTypes["Contember_ProductUpdateOrderItemsRelationInput"];
	["Contember_OrderItemWithoutProductUpdateInput"]: GraphQLTypes["Contember_OrderItemWithoutProductUpdateInput"];
	["Contember_OrderItemUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"];
	["Contember_OrderItemUpsertVatRateRelationInput"]: GraphQLTypes["Contember_OrderItemUpsertVatRateRelationInput"];
	["Contember_OrderItemUpdateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderItemUpdateOrderEntityRelationInput"];
	["Contember_OrderWithoutItemsUpdateInput"]: GraphQLTypes["Contember_OrderWithoutItemsUpdateInput"];
	["Contember_OrderUpdateDiscountsEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"];
	["Contember_OrderUpdateDiscountsRelationInput"]: GraphQLTypes["Contember_OrderUpdateDiscountsRelationInput"];
	["Contember_OrderDiscountWithoutOrderUpdateInput"]: GraphQLTypes["Contember_OrderDiscountWithoutOrderUpdateInput"];
	["Contember_OrderDiscountUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpdateVatRateEntityRelationInput"];
	["Contember_OrderDiscountUpsertVatRateRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpsertVatRateRelationInput"];
	["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"];
	["Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput"];
	["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"]: GraphQLTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"];
	["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"];
	["Contember_CustomerCreditWithoutTransactionsUpdateInput"]: GraphQLTypes["Contember_CustomerCreditWithoutTransactionsUpdateInput"];
	["Contember_CustomerCreditUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutCreditsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutCreditsUpdateInput"];
	["Contember_CustomerUpdateChannelsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"];
	["Contember_CustomerUpdateChannelsRelationInput"]: GraphQLTypes["Contember_CustomerUpdateChannelsRelationInput"];
	["Contember_ChannelWithoutCustomersUpdateInput"]: GraphQLTypes["Contember_ChannelWithoutCustomersUpdateInput"];
	["Contember_ChannelUpdatePublicTagsEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"];
	["Contember_ChannelUpdatePublicTagsRelationInput"]: GraphQLTypes["Contember_ChannelUpdatePublicTagsRelationInput"];
	["Contember_TagWithoutPublicInChannelsUpdateInput"]: GraphQLTypes["Contember_TagWithoutPublicInChannelsUpdateInput"];
	["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"];
	["Contember_TagUpdateAllowChannelPaymentsRelationInput"]: GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsRelationInput"];
	["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"]: GraphQLTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"];
	["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"];
	["Contember_VatRateWithoutChannelPaymentsUpdateInput"]: GraphQLTypes["Contember_VatRateWithoutChannelPaymentsUpdateInput"];
	["Contember_ChannelPaymentUpsertVatRateRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpsertVatRateRelationInput"];
	["Contember_TagUpsertAllowChannelPaymentsRelationInput"]: GraphQLTypes["Contember_TagUpsertAllowChannelPaymentsRelationInput"];
	["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"];
	["Contember_TagUpdateFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_TagUpdateFulfillmentNotesRelationInput"];
	["Contember_FulfillmentNoteWithoutTagsUpdateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutTagsUpdateInput"];
	["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"];
	["Contember_FulfillmentNoteUpdateProductsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateProductsRelationInput"];
	["Contember_ProductWithoutFulfillmentNotesUpdateInput"]: GraphQLTypes["Contember_ProductWithoutFulfillmentNotesUpdateInput"];
	["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"];
	["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"];
	["Contember_ProductUpdateDeliveryZonesRelationInput"]: GraphQLTypes["Contember_ProductUpdateDeliveryZonesRelationInput"];
	["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"]: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"];
	["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"];
	["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput"];
	["Contember_TagWithoutDeliveryZonesUpdateInput"]: GraphQLTypes["Contember_TagWithoutDeliveryZonesUpdateInput"];
	["Contember_TagUpdatePublicInChannelsEntityRelationInput"]: GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"];
	["Contember_TagUpdatePublicInChannelsRelationInput"]: GraphQLTypes["Contember_TagUpdatePublicInChannelsRelationInput"];
	["Contember_ChannelWithoutPublicTagsUpdateInput"]: GraphQLTypes["Contember_ChannelWithoutPublicTagsUpdateInput"];
	["Contember_ChannelUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutChannelsUpdateInput"]: GraphQLTypes["Contember_VenueWithoutChannelsUpdateInput"];
	["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"];
	["Contember_DeadlineTemplateWithoutVenueUpdateInput"]: GraphQLTypes["Contember_DeadlineTemplateWithoutVenueUpdateInput"];
	["Contember_VenueUpsertDeadlineTemplateRelationInput"]: GraphQLTypes["Contember_VenueUpsertDeadlineTemplateRelationInput"];
	["Contember_VenueUpdateTimetableDaysEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"];
	["Contember_VenueUpdateTimetableDaysRelationInput"]: GraphQLTypes["Contember_VenueUpdateTimetableDaysRelationInput"];
	["Contember_TimetableDayWithoutVenueUpdateInput"]: GraphQLTypes["Contember_TimetableDayWithoutVenueUpdateInput"];
	["Contember_TimetableDayUpdateShiftGroupsEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayUpdateShiftGroupsEntityRelationInput"];
	["Contember_TimetableDayUpdateShiftGroupsRelationInput"]: GraphQLTypes["Contember_TimetableDayUpdateShiftGroupsRelationInput"];
	["Contember_TimetableShiftGroupWithoutDayUpdateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutDayUpdateInput"];
	["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"];
	["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"];
	["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput"];
	["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"]: GraphQLTypes["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"];
	["Contember_TimetableTemplateUpdatePositionEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpdatePositionEntityRelationInput"];
	["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"]: GraphQLTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"];
	["Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput"];
	["Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput"];
	["Contember_TimetableShiftGroupWithoutPositionUpdateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutPositionUpdateInput"];
	["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"];
	["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"]: GraphQLTypes["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"];
	["Contember_EmployeeQueueUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueUpdateItemsEntityRelationInput"];
	["Contember_EmployeeQueueUpdateItemsRelationInput"]: GraphQLTypes["Contember_EmployeeQueueUpdateItemsRelationInput"];
	["Contember_EmployeeQueueItemWithoutQueueUpdateInput"]: GraphQLTypes["Contember_EmployeeQueueItemWithoutQueueUpdateInput"];
	["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"];
	["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"]: GraphQLTypes["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"];
	["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"];
	["Contember_ShiftsProfileUpdateShiftsRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateShiftsRelationInput"];
	["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"]: GraphQLTypes["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"];
	["Contember_TimetableShiftUpdateGroupEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpdateGroupEntityRelationInput"];
	["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"]: GraphQLTypes["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"];
	["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"];
	["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"]: GraphQLTypes["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"];
	["Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput"];
	["Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput"];
	["Contember_TimetableTemplateWithoutPositionUpdateInput"]: GraphQLTypes["Contember_TimetableTemplateWithoutPositionUpdateInput"];
	["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"];
	["Contember_TimetableTemplateUpdateStaffOptionsRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpdateStaffOptionsRelationInput"];
	["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"];
	["Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput"];
	["Contember_ShiftsProfileUpdateInput"]: GraphQLTypes["Contember_ShiftsProfileUpdateInput"];
	["Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput"]: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput"];
	["Contember_TimetableTemplateUpsertStaffOptionsRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpsertStaffOptionsRelationInput"];
	["Contember_TimetableTemplateUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutTimetableTemplatesUpdateInput"]: GraphQLTypes["Contember_VenueWithoutTimetableTemplatesUpdateInput"];
	["Contember_VenueUpdateStaffMembersEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"];
	["Contember_VenueUpdateStaffMembersRelationInput"]: GraphQLTypes["Contember_VenueUpdateStaffMembersRelationInput"];
	["Contember_StaffWithoutVenuesUpdateInput"]: GraphQLTypes["Contember_StaffWithoutVenuesUpdateInput"];
	["Contember_StaffUpdatePhotoEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"];
	["Contember_StaffUpsertPhotoRelationInput"]: GraphQLTypes["Contember_StaffUpsertPhotoRelationInput"];
	["Contember_StaffUpdateCourierManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"];
	["Contember_CourierManagerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_CourierManagerWithoutStaffUpdateInput"];
	["Contember_StaffUpsertCourierManagerRelationInput"]: GraphQLTypes["Contember_StaffUpsertCourierManagerRelationInput"];
	["Contember_StaffUpdatePickerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"];
	["Contember_PickerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_PickerWithoutStaffUpdateInput"];
	["Contember_StaffUpsertPickerRelationInput"]: GraphQLTypes["Contember_StaffUpsertPickerRelationInput"];
	["Contember_StaffUpdateRemunerationProfileEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"];
	["Contember_RemunerationProfileWithoutStaffUpdateInput"]: GraphQLTypes["Contember_RemunerationProfileWithoutStaffUpdateInput"];
	["Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput"]: GraphQLTypes["Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput"];
	["Contember_RemunerationProfileUpdateEmploymentInformationRelationInput"]: GraphQLTypes["Contember_RemunerationProfileUpdateEmploymentInformationRelationInput"];
	["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"]: GraphQLTypes["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"];
	["Contember_RemunerationProfileUpsertEmploymentInformationRelationInput"]: GraphQLTypes["Contember_RemunerationProfileUpsertEmploymentInformationRelationInput"];
	["Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput"]: GraphQLTypes["Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput"];
	["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"]: GraphQLTypes["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"];
	["Contember_RemunerationProfileUpsertWorkMonthsRelationInput"]: GraphQLTypes["Contember_RemunerationProfileUpsertWorkMonthsRelationInput"];
	["Contember_StaffUpsertRemunerationProfileRelationInput"]: GraphQLTypes["Contember_StaffUpsertRemunerationProfileRelationInput"];
	["Contember_StaffUpdateCashierEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"];
	["Contember_CashierWithoutStaffUpdateInput"]: GraphQLTypes["Contember_CashierWithoutStaffUpdateInput"];
	["Contember_StaffUpsertCashierRelationInput"]: GraphQLTypes["Contember_StaffUpsertCashierRelationInput"];
	["Contember_StaffUpdateInternalBuyerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"];
	["Contember_InternalBuyerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_InternalBuyerWithoutStaffUpdateInput"];
	["Contember_StaffUpsertInternalBuyerRelationInput"]: GraphQLTypes["Contember_StaffUpsertInternalBuyerRelationInput"];
	["Contember_StaffUpdateSalesManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"];
	["Contember_SalesManagerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_SalesManagerWithoutStaffUpdateInput"];
	["Contember_SalesManagerUpdateCountriesEntityRelationInput"]: GraphQLTypes["Contember_SalesManagerUpdateCountriesEntityRelationInput"];
	["Contember_SalesManagerUpdateCountriesRelationInput"]: GraphQLTypes["Contember_SalesManagerUpdateCountriesRelationInput"];
	["Contember_SalesManagerUpsertCountriesRelationInput"]: GraphQLTypes["Contember_SalesManagerUpsertCountriesRelationInput"];
	["Contember_StaffUpsertSalesManagerRelationInput"]: GraphQLTypes["Contember_StaffUpsertSalesManagerRelationInput"];
	["Contember_StaffUpdateCashierManagerEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"];
	["Contember_CashierManagerWithoutStaffUpdateInput"]: GraphQLTypes["Contember_CashierManagerWithoutStaffUpdateInput"];
	["Contember_StaffUpsertCashierManagerRelationInput"]: GraphQLTypes["Contember_StaffUpsertCashierManagerRelationInput"];
	["Contember_VenueUpsertStaffMembersRelationInput"]: GraphQLTypes["Contember_VenueUpsertStaffMembersRelationInput"];
	["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"];
	["Contember_VenueUpdateEmployeeRolesRelationInput"]: GraphQLTypes["Contember_VenueUpdateEmployeeRolesRelationInput"];
	["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"]: GraphQLTypes["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"];
	["Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput"]: GraphQLTypes["Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput"];
	["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"]: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"];
	["Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput"]: GraphQLTypes["Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput"];
	["Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput"]: GraphQLTypes["Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput"];
	["Contember_VenueEmployeeRoleUpsertRoleRelationInput"]: GraphQLTypes["Contember_VenueEmployeeRoleUpsertRoleRelationInput"];
	["Contember_VenueUpsertEmployeeRolesRelationInput"]: GraphQLTypes["Contember_VenueUpsertEmployeeRolesRelationInput"];
	["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"];
	["Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput"]: GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"];
	["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"];
	["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"]: GraphQLTypes["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"];
	["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"];
	["Contember_IdentificationCodeUpdateAttemptsRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpdateAttemptsRelationInput"];
	["Contember_IdentificationAttemptWithoutCodeUpdateInput"]: GraphQLTypes["Contember_IdentificationAttemptWithoutCodeUpdateInput"];
	["Contember_IdentificationAttemptUpdateUserEntityRelationInput"]: GraphQLTypes["Contember_IdentificationAttemptUpdateUserEntityRelationInput"];
	["Contember_UserWithoutIdentificationAttemptsUpdateInput"]: GraphQLTypes["Contember_UserWithoutIdentificationAttemptsUpdateInput"];
	["Contember_UserUpdateDevicesEntityRelationInput"]: GraphQLTypes["Contember_UserUpdateDevicesEntityRelationInput"];
	["Contember_UserUpdateDevicesRelationInput"]: GraphQLTypes["Contember_UserUpdateDevicesRelationInput"];
	["Contember_UserDeviceWithoutUserUpdateInput"]: GraphQLTypes["Contember_UserDeviceWithoutUserUpdateInput"];
	["Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput"]: GraphQLTypes["Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput"];
	["Contember_AnonymousSessionWithoutDevicesUpdateInput"]: GraphQLTypes["Contember_AnonymousSessionWithoutDevicesUpdateInput"];
	["Contember_AnonymousSessionUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_AnonymousSessionUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutAnonymousSessionUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutAnonymousSessionUpdateInput"];
	["Contember_CustomerUpdateInvoicesEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"];
	["Contember_CustomerUpdateInvoicesRelationInput"]: GraphQLTypes["Contember_CustomerUpdateInvoicesRelationInput"];
	["Contember_InvoiceWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_InvoiceWithoutCustomerUpdateInput"];
	["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]: GraphQLTypes["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"];
	["Contember_InvoiceUpdateOrderPaymentsRelationInput"]: GraphQLTypes["Contember_InvoiceUpdateOrderPaymentsRelationInput"];
	["Contember_OrderPaymentWithoutInvoiceUpdateInput"]: GraphQLTypes["Contember_OrderPaymentWithoutInvoiceUpdateInput"];
	["Contember_OrderPaymentUpdateMethodEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpdateMethodEntityRelationInput"];
	["Contember_OrderPaymentUpsertMethodRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpsertMethodRelationInput"];
	["Contember_OrderPaymentUpdateEventsEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpdateEventsEntityRelationInput"];
	["Contember_OrderPaymentUpdateEventsRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpdateEventsRelationInput"];
	["Contember_OrderEventWithoutPaymentUpdateInput"]: GraphQLTypes["Contember_OrderEventWithoutPaymentUpdateInput"];
	["Contember_OrderEventUpdateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderEventUpdateOrderEntityRelationInput"];
	["Contember_OrderWithoutEventsUpdateInput"]: GraphQLTypes["Contember_OrderWithoutEventsUpdateInput"];
	["Contember_OrderUpdateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"];
	["Contember_OrderDeliveryWithoutOrderUpdateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutOrderUpdateInput"];
	["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"];
	["Contember_OrderDeliveryUpsertVatRateRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpsertVatRateRelationInput"];
	["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"];
	["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"]: GraphQLTypes["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"];
	["Contember_DeliveryWaypointUpdateRouteEntityRelationInput"]: GraphQLTypes["Contember_DeliveryWaypointUpdateRouteEntityRelationInput"];
	["Contember_DeliveryRouteWithoutWaypointsUpdateInput"]: GraphQLTypes["Contember_DeliveryRouteWithoutWaypointsUpdateInput"];
	["Contember_DeliveryRouteUpdateCourierEntityRelationInput"]: GraphQLTypes["Contember_DeliveryRouteUpdateCourierEntityRelationInput"];
	["Contember_CourierWithoutRoutesUpdateInput"]: GraphQLTypes["Contember_CourierWithoutRoutesUpdateInput"];
	["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"];
	["Contember_CourierUpdateDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_CourierUpdateDeliveryMethodsRelationInput"];
	["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"];
	["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"];
	["Contember_DeliveryMethodUpdateLocalesRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateLocalesRelationInput"];
	["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"];
	["Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutDeliveryMethodsUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutDeliveryMethodsUpdateInput"];
	["Contember_LocaleUpdateProductPackingsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"];
	["Contember_LocaleUpdateProductPackingsRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductPackingsRelationInput"];
	["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"];
	["Contember_ProductPackingLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingLocaleUpdateRootEntityRelationInput"];
	["Contember_ProductPackingWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_ProductPackingWithoutLocalesUpdateInput"];
	["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"]: GraphQLTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"];
	["Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput"]: GraphQLTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput"];
	["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"]: GraphQLTypes["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"];
	["Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput"]: GraphQLTypes["Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput"];
	["Contember_ProductRecipeUpdateInput"]: GraphQLTypes["Contember_ProductRecipeUpdateInput"];
	["Contember_ProductRecipeUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateProductsEntityRelationInput"];
	["Contember_ProductRecipeUpdateProductsRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateProductsRelationInput"];
	["Contember_ProductWithoutRecipeUpdateInput"]: GraphQLTypes["Contember_ProductWithoutRecipeUpdateInput"];
	["Contember_ProductUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"];
	["Contember_ProductUpdateLocalesRelationInput"]: GraphQLTypes["Contember_ProductUpdateLocalesRelationInput"];
	["Contember_ProductLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_ProductLocaleWithoutRootUpdateInput"];
	["Contember_ProductLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductsUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutProductsUpdateInput"];
	["Contember_LocaleUpdateProductRecipesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"];
	["Contember_LocaleUpdateProductRecipesRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductRecipesRelationInput"];
	["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"];
	["Contember_ProductRecipeLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeLocaleUpdateRootEntityRelationInput"];
	["Contember_ProductRecipeWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_ProductRecipeWithoutLocalesUpdateInput"];
	["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"];
	["Contember_ProductRecipeUpsertFreshingContainerRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpsertFreshingContainerRelationInput"];
	["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"];
	["Contember_NutritionFactsUpdateInput"]: GraphQLTypes["Contember_NutritionFactsUpdateInput"];
	["Contember_ProductRecipeUpsertNutritionFactsRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpsertNutritionFactsRelationInput"];
	["Contember_ProductRecipeLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_ProductRecipeLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertProductRecipesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertProductRecipesRelationInput"];
	["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"];
	["Contember_LocaleUpdatePointOfSaleCategoriesRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesRelationInput"];
	["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"];
	["Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput"];
	["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"];
	["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"];
	["Contember_PointOfSaleCategoryUpdateProductsRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateProductsRelationInput"];
	["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"];
	["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"];
	["Contember_ProductUpdateInput"]: GraphQLTypes["Contember_ProductUpdateInput"];
	["Contember_ProductUpdateCustomerPricesEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"];
	["Contember_ProductUpdateVirtualProductEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"];
	["Contember_VirtualProductWithoutProductUpdateInput"]: GraphQLTypes["Contember_VirtualProductWithoutProductUpdateInput"];
	["Contember_ProductUpsertVirtualProductRelationInput"]: GraphQLTypes["Contember_ProductUpsertVirtualProductRelationInput"];
	["Contember_ProductUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"];
	["Contember_ProductUpdatePageEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"];
	["Contember_PageUpdateInput"]: GraphQLTypes["Contember_PageUpdateInput"];
	["Contember_PageUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PageUpdateLocalesEntityRelationInput"];
	["Contember_PageUpdateLocalesRelationInput"]: GraphQLTypes["Contember_PageUpdateLocalesRelationInput"];
	["Contember_PageLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_PageLocaleWithoutRootUpdateInput"];
	["Contember_PageLocaleUpdateSeoEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleUpdateSeoEntityRelationInput"];
	["Contember_SeoUpdateInput"]: GraphQLTypes["Contember_SeoUpdateInput"];
	["Contember_SeoUpdateOgImageEntityRelationInput"]: GraphQLTypes["Contember_SeoUpdateOgImageEntityRelationInput"];
	["Contember_SeoUpsertOgImageRelationInput"]: GraphQLTypes["Contember_SeoUpsertOgImageRelationInput"];
	["Contember_PageLocaleUpsertSeoRelationInput"]: GraphQLTypes["Contember_PageLocaleUpsertSeoRelationInput"];
	["Contember_PageLocaleUpdateContentEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleUpdateContentEntityRelationInput"];
	["Contember_PageLocaleUpsertContentRelationInput"]: GraphQLTypes["Contember_PageLocaleUpsertContentRelationInput"];
	["Contember_PageLocaleUpdateCoverEntityRelationInput"]: GraphQLTypes["Contember_PageLocaleUpdateCoverEntityRelationInput"];
	["Contember_PageLocaleUpsertCoverRelationInput"]: GraphQLTypes["Contember_PageLocaleUpsertCoverRelationInput"];
	["Contember_PageUpsertLocalesRelationInput"]: GraphQLTypes["Contember_PageUpsertLocalesRelationInput"];
	["Contember_ProductUpsertPageRelationInput"]: GraphQLTypes["Contember_ProductUpsertPageRelationInput"];
	["Contember_ProductUpdateSalesDaysEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"];
	["Contember_ProductUpdateSalesWeeksEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"];
	["Contember_ProductUpdateSalesMonthsEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"];
	["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"];
	["Contember_ProductUpdateFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_ProductUpdateFulfillmentNotesRelationInput"];
	["Contember_FulfillmentNoteWithoutProductsUpdateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutProductsUpdateInput"];
	["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"];
	["Contember_FulfillmentNoteUpdateTagsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateTagsRelationInput"];
	["Contember_TagWithoutFulfillmentNotesUpdateInput"]: GraphQLTypes["Contember_TagWithoutFulfillmentNotesUpdateInput"];
	["Contember_FulfillmentNoteUpsertTagsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpsertTagsRelationInput"];
	["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"];
	["Contember_FulfillmentNoteUpdateVenuesRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesRelationInput"];
	["Contember_VenueWithoutFulfillmentNotesUpdateInput"]: GraphQLTypes["Contember_VenueWithoutFulfillmentNotesUpdateInput"];
	["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"];
	["Contember_VenueUpdateTimetableTemplatesRelationInput"]: GraphQLTypes["Contember_VenueUpdateTimetableTemplatesRelationInput"];
	["Contember_TimetableTemplateWithoutVenueUpdateInput"]: GraphQLTypes["Contember_TimetableTemplateWithoutVenueUpdateInput"];
	["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"];
	["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"];
	["Contember_TimetableTemplateUpsertEmployeeRoleRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpsertEmployeeRoleRelationInput"];
	["Contember_VenueUpsertTimetableTemplatesRelationInput"]: GraphQLTypes["Contember_VenueUpsertTimetableTemplatesRelationInput"];
	["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"];
	["Contember_VenueUpdatePointOfSaleTerminalsRelationInput"]: GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsRelationInput"];
	["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"]: GraphQLTypes["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"];
	["Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput"];
	["Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput"]: GraphQLTypes["Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"];
	["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"]: GraphQLTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"];
	["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"];
	["Contember_VenueUpdatePointOfSalePrintersRelationInput"]: GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersRelationInput"];
	["Contember_PointOfSalePrinterWithoutVenueUpdateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutVenueUpdateInput"];
	["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"];
	["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"];
	["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"];
	["Contember_DeviceIdentifierWithoutDeviceUpdateInput"]: GraphQLTypes["Contember_DeviceIdentifierWithoutDeviceUpdateInput"];
	["Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput"]: GraphQLTypes["Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput"];
	["Contember_UserUpdateInput"]: GraphQLTypes["Contember_UserUpdateInput"];
	["Contember_UserUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_UserUpdateMetaEntityRelationInput"];
	["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]: GraphQLTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"];
	["Contember_UserUpdateIdentificationAttemptsRelationInput"]: GraphQLTypes["Contember_UserUpdateIdentificationAttemptsRelationInput"];
	["Contember_IdentificationAttemptWithoutUserUpdateInput"]: GraphQLTypes["Contember_IdentificationAttemptWithoutUserUpdateInput"];
	["Contember_IdentificationAttemptUpdateCodeEntityRelationInput"]: GraphQLTypes["Contember_IdentificationAttemptUpdateCodeEntityRelationInput"];
	["Contember_IdentificationCodeWithoutAttemptsUpdateInput"]: GraphQLTypes["Contember_IdentificationCodeWithoutAttemptsUpdateInput"];
	["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"];
	["Contember_IdentificationCodeUpdateLocalesRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpdateLocalesRelationInput"];
	["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"];
	["Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutIdentificationCodesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutIdentificationCodesUpdateInput"];
	["Contember_LocaleUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"];
	["Contember_LocaleUpdateProductsRelationInput"]: GraphQLTypes["Contember_LocaleUpdateProductsRelationInput"];
	["Contember_ProductLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_ProductLocaleWithoutLocaleUpdateInput"];
	["Contember_ProductLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_ProductLocaleUpdateRootEntityRelationInput"];
	["Contember_ProductWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_ProductWithoutLocalesUpdateInput"];
	["Contember_ProductLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_ProductLocaleUpsertRootRelationInput"];
	["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"]: GraphQLTypes["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"];
	["Contember_ProductLocaleUpsertFeatureListRelationInput"]: GraphQLTypes["Contember_ProductLocaleUpsertFeatureListRelationInput"];
	["Contember_LocaleUpsertProductsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertProductsRelationInput"];
	["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"];
	["Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput"];
	["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"];
	["Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput"];
	["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"];
	["Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput"];
	["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"];
	["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"];
	["Contember_PointOfSaleCategoryUpdateLocalesRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateLocalesRelationInput"];
	["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"];
	["Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"];
	["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"];
	["Contember_LocaleUpdateDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsRelationInput"];
	["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"];
	["Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput"];
	["Contember_DeliveryMethodWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_DeliveryMethodWithoutLocalesUpdateInput"];
	["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"];
	["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"]: GraphQLTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"];
	["Contember_DeliveryMethodUpsertTimelinePresetRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpsertTimelinePresetRelationInput"];
	["Contember_DeliveryMethodLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertDeliveryMethodsRelationInput"];
	["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"];
	["Contember_LocaleUpdateIngredientSuppliersRelationInput"]: GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersRelationInput"];
	["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"];
	["Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput"];
	["Contember_IngredientSupplierWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_IngredientSupplierWithoutLocalesUpdateInput"];
	["Contember_IngredientSupplierLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertIngredientSuppliersRelationInput"]: GraphQLTypes["Contember_LocaleUpsertIngredientSuppliersRelationInput"];
	["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"];
	["Contember_LocaleUpdateIdentificationCodesRelationInput"]: GraphQLTypes["Contember_LocaleUpdateIdentificationCodesRelationInput"];
	["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"];
	["Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput"];
	["Contember_IdentificationCodeWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_IdentificationCodeWithoutLocalesUpdateInput"];
	["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"];
	["Contember_IdentificationCodeUpdateApplicationDevicesRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpdateApplicationDevicesRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"];
	["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"];
	["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"]: GraphQLTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"];
	["Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"]: GraphQLTypes["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"];
	["Contember_VenueUpdateOrdersEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"];
	["Contember_VenueUpdateOrdersRelationInput"]: GraphQLTypes["Contember_VenueUpdateOrdersRelationInput"];
	["Contember_OrderWithoutVenueUpdateInput"]: GraphQLTypes["Contember_OrderWithoutVenueUpdateInput"];
	["Contember_OrderUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"];
	["Contember_OrderUpdateItemsRelationInput"]: GraphQLTypes["Contember_OrderUpdateItemsRelationInput"];
	["Contember_OrderItemWithoutOrderUpdateInput"]: GraphQLTypes["Contember_OrderItemWithoutOrderUpdateInput"];
	["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]: GraphQLTypes["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"];
	["Contember_OrderItemUpdateVirtualProductEffectsRelationInput"]: GraphQLTypes["Contember_OrderItemUpdateVirtualProductEffectsRelationInput"];
	["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"]: GraphQLTypes["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"];
	["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"];
	["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"]: GraphQLTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"];
	["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"];
	["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"]: GraphQLTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"];
	["Contember_OrderDiscountUpdateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpdateOrderEntityRelationInput"];
	["Contember_OrderWithoutDiscountsUpdateInput"]: GraphQLTypes["Contember_OrderWithoutDiscountsUpdateInput"];
	["Contember_OrderUpdateCartEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"];
	["Contember_CartWithoutOrderUpdateInput"]: GraphQLTypes["Contember_CartWithoutOrderUpdateInput"];
	["Contember_CartUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutCartsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutCartsUpdateInput"];
	["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"];
	["Contember_InvoicingPlanWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_InvoicingPlanWithoutCustomerUpdateInput"];
	["Contember_InvoicingPlanUpdatePresetEntityRelationInput"]: GraphQLTypes["Contember_InvoicingPlanUpdatePresetEntityRelationInput"];
	["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"]: GraphQLTypes["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"];
	["Contember_InvoicingPlanUpsertPresetRelationInput"]: GraphQLTypes["Contember_InvoicingPlanUpsertPresetRelationInput"];
	["Contember_CustomerUpsertInvoicingPlanRelationInput"]: GraphQLTypes["Contember_CustomerUpsertInvoicingPlanRelationInput"];
	["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"];
	["Contember_CustomerUpdateBillingSubjectsRelationInput"]: GraphQLTypes["Contember_CustomerUpdateBillingSubjectsRelationInput"];
	["Contember_BillingSubjectWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutCustomerUpdateInput"];
	["Contember_BillingSubjectUpdateCountryEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"];
	["Contember_BillingSubjectUpsertCountryRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpsertCountryRelationInput"];
	["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"];
	["Contember_BillingSubjectUpdateInvoicesRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpdateInvoicesRelationInput"];
	["Contember_InvoiceWithoutBillingSubjectUpdateInput"]: GraphQLTypes["Contember_InvoiceWithoutBillingSubjectUpdateInput"];
	["Contember_InvoiceUpdateAddressEntityRelationInput"]: GraphQLTypes["Contember_InvoiceUpdateAddressEntityRelationInput"];
	["Contember_AddressWithoutInvoicesUpdateInput"]: GraphQLTypes["Contember_AddressWithoutInvoicesUpdateInput"];
	["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"];
	["Contember_AddressUpdateBillingAddressOfOrdersRelationInput"]: GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersRelationInput"];
	["Contember_OrderWithoutBillingAddressUpdateInput"]: GraphQLTypes["Contember_OrderWithoutBillingAddressUpdateInput"];
	["Contember_OrderUpdateEventsEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"];
	["Contember_OrderUpdateEventsRelationInput"]: GraphQLTypes["Contember_OrderUpdateEventsRelationInput"];
	["Contember_OrderEventWithoutOrderUpdateInput"]: GraphQLTypes["Contember_OrderEventWithoutOrderUpdateInput"];
	["Contember_OrderEventUpdatePaymentEntityRelationInput"]: GraphQLTypes["Contember_OrderEventUpdatePaymentEntityRelationInput"];
	["Contember_OrderPaymentWithoutEventsUpdateInput"]: GraphQLTypes["Contember_OrderPaymentWithoutEventsUpdateInput"];
	["Contember_OrderPaymentUpdateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpdateOrderEntityRelationInput"];
	["Contember_OrderWithoutPaymentsUpdateInput"]: GraphQLTypes["Contember_OrderWithoutPaymentsUpdateInput"];
	["Contember_OrderUpdateBillingAddressEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"];
	["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"]: GraphQLTypes["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"];
	["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"];
	["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput"]: GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput"];
	["Contember_OrderDeliveryWithoutAddressUpdateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutAddressUpdateInput"];
	["Contember_OrderDeliveryUpdateOrderEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateOrderEntityRelationInput"];
	["Contember_OrderWithoutDeliveryUpdateInput"]: GraphQLTypes["Contember_OrderWithoutDeliveryUpdateInput"];
	["Contember_OrderUpdatePriceLinesEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"];
	["Contember_OrderUpdatePaymentsEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"];
	["Contember_OrderUpdatePaymentsRelationInput"]: GraphQLTypes["Contember_OrderUpdatePaymentsRelationInput"];
	["Contember_OrderPaymentWithoutOrderUpdateInput"]: GraphQLTypes["Contember_OrderPaymentWithoutOrderUpdateInput"];
	["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"];
	["Contember_InvoiceWithoutOrderPaymentsUpdateInput"]: GraphQLTypes["Contember_InvoiceWithoutOrderPaymentsUpdateInput"];
	["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectWithoutInvoicesUpdateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutInvoicesUpdateInput"];
	["Contember_BillingSubjectUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutBillingSubjectsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutBillingSubjectsUpdateInput"];
	["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"];
	["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"];
	["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"]: GraphQLTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"];
	["Contember_CustomerUpsertDefaultDeliveryAddressRelationInput"]: GraphQLTypes["Contember_CustomerUpsertDefaultDeliveryAddressRelationInput"];
	["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"];
	["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"];
	["Contember_BillingSubjectUpdateOrdersRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpdateOrdersRelationInput"];
	["Contember_OrderWithoutBillingSubjectUpdateInput"]: GraphQLTypes["Contember_OrderWithoutBillingSubjectUpdateInput"];
	["Contember_OrderUpdateSummaryEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"];
	["Contember_OrderUpdateGratuityEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"];
	["Contember_GratuityWithoutOrderUpdateInput"]: GraphQLTypes["Contember_GratuityWithoutOrderUpdateInput"];
	["Contember_OrderUpsertGratuityRelationInput"]: GraphQLTypes["Contember_OrderUpsertGratuityRelationInput"];
	["Contember_OrderUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutOrdersUpdateInput"]: GraphQLTypes["Contember_VenueWithoutOrdersUpdateInput"];
	["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"];
	["Contember_VenueUpdateFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_VenueUpdateFulfillmentNotesRelationInput"];
	["Contember_FulfillmentNoteWithoutVenuesUpdateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutVenuesUpdateInput"];
	["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"];
	["Contember_FulfillmentNoteUpdateCartItemsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsRelationInput"];
	["Contember_CartItemWithoutFulfillmentNotesUpdateInput"]: GraphQLTypes["Contember_CartItemWithoutFulfillmentNotesUpdateInput"];
	["Contember_FulfillmentNoteUpsertCartItemsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpsertCartItemsRelationInput"];
	["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"];
	["Contember_FulfillmentNoteUpdateOrderItemsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsRelationInput"];
	["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"]: GraphQLTypes["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"];
	["Contember_OrderItemUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_OrderItemUpdateProductEntityRelationInput"];
	["Contember_ProductWithoutOrderItemsUpdateInput"]: GraphQLTypes["Contember_ProductWithoutOrderItemsUpdateInput"];
	["Contember_OrderItemUpsertProductRelationInput"]: GraphQLTypes["Contember_OrderItemUpsertProductRelationInput"];
	["Contember_FulfillmentNoteUpsertOrderItemsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpsertOrderItemsRelationInput"];
	["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"];
	["Contember_FulfillmentNoteUpdateOrdersRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersRelationInput"];
	["Contember_OrderWithoutFulfillmentNotesUpdateInput"]: GraphQLTypes["Contember_OrderWithoutFulfillmentNotesUpdateInput"];
	["Contember_OrderUpdateBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectWithoutOrdersUpdateInput"]: GraphQLTypes["Contember_BillingSubjectWithoutOrdersUpdateInput"];
	["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"];
	["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"];
	["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"];
	["Contember_AnonymousSessionWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_AnonymousSessionWithoutCustomerUpdateInput"];
	["Contember_AnonymousSessionUpdateDevicesEntityRelationInput"]: GraphQLTypes["Contember_AnonymousSessionUpdateDevicesEntityRelationInput"];
	["Contember_AnonymousSessionUpdateDevicesRelationInput"]: GraphQLTypes["Contember_AnonymousSessionUpdateDevicesRelationInput"];
	["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"]: GraphQLTypes["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"];
	["Contember_UserDeviceUpdateUserEntityRelationInput"]: GraphQLTypes["Contember_UserDeviceUpdateUserEntityRelationInput"];
	["Contember_UserWithoutDevicesUpdateInput"]: GraphQLTypes["Contember_UserWithoutDevicesUpdateInput"];
	["Contember_UserUpdateRolesEntityRelationInput"]: GraphQLTypes["Contember_UserUpdateRolesEntityRelationInput"];
	["Contember_UserDeviceUpsertUserRelationInput"]: GraphQLTypes["Contember_UserDeviceUpsertUserRelationInput"];
	["Contember_AnonymousSessionUpsertDevicesRelationInput"]: GraphQLTypes["Contember_AnonymousSessionUpsertDevicesRelationInput"];
	["Contember_CustomerUpsertAnonymousSessionRelationInput"]: GraphQLTypes["Contember_CustomerUpsertAnonymousSessionRelationInput"];
	["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"];
	["Contember_CustomerUpdateBoughtVouchersRelationInput"]: GraphQLTypes["Contember_CustomerUpdateBoughtVouchersRelationInput"];
	["Contember_VoucherWithoutBuyerUpdateInput"]: GraphQLTypes["Contember_VoucherWithoutBuyerUpdateInput"];
	["Contember_VoucherUpdateOwnerEntityRelationInput"]: GraphQLTypes["Contember_VoucherUpdateOwnerEntityRelationInput"];
	["Contember_CustomerWithoutOwnsVouchersUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutOwnsVouchersUpdateInput"];
	["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"];
	["Contember_CustomerUpdateRedeemedVouchersRelationInput"]: GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersRelationInput"];
	["Contember_VoucherRedemptionWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerUpdateInput"];
	["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"];
	["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"]: GraphQLTypes["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"];
	["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"];
	["Contember_CustomerCreditUpdateTransactionsRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpdateTransactionsRelationInput"];
	["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"]: GraphQLTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"];
	["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"];
	["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"]: GraphQLTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"];
	["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"];
	["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"]: GraphQLTypes["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"];
	["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"];
	["Contember_OrderItemUpdateFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_OrderItemUpdateFulfillmentNotesRelationInput"];
	["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"];
	["Contember_OrderItemUpsertFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_OrderItemUpsertFulfillmentNotesRelationInput"];
	["Contember_VirtualProductEffectUpsertOrderItemRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectUpsertOrderItemRelationInput"];
	["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"];
	["Contember_VirtualProductEffectUpdateResultVouchersRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectUpdateResultVouchersRelationInput"];
	["Contember_VoucherWithoutVirtualProductEffectUpdateInput"]: GraphQLTypes["Contember_VoucherWithoutVirtualProductEffectUpdateInput"];
	["Contember_VoucherUpdateBuyerEntityRelationInput"]: GraphQLTypes["Contember_VoucherUpdateBuyerEntityRelationInput"];
	["Contember_CustomerWithoutBoughtVouchersUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutBoughtVouchersUpdateInput"];
	["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"];
	["Contember_CustomerUpdateOwnsVouchersRelationInput"]: GraphQLTypes["Contember_CustomerUpdateOwnsVouchersRelationInput"];
	["Contember_VoucherWithoutOwnerUpdateInput"]: GraphQLTypes["Contember_VoucherWithoutOwnerUpdateInput"];
	["Contember_VoucherUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_VoucherUpdateVatRateEntityRelationInput"];
	["Contember_VoucherUpsertVatRateRelationInput"]: GraphQLTypes["Contember_VoucherUpsertVatRateRelationInput"];
	["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"]: GraphQLTypes["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"];
	["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"]: GraphQLTypes["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"];
	["Contember_VoucherUpsertVirtualProductEffectRelationInput"]: GraphQLTypes["Contember_VoucherUpsertVirtualProductEffectRelationInput"];
	["Contember_VoucherUpdateRedemptionEntityRelationInput"]: GraphQLTypes["Contember_VoucherUpdateRedemptionEntityRelationInput"];
	["Contember_VoucherRedemptionWithoutVoucherUpdateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutVoucherUpdateInput"];
	["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"];
	["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"]: GraphQLTypes["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"];
	["Contember_VoucherRedemptionUpsertOrderDiscountRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpsertOrderDiscountRelationInput"];
	["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutRedeemedVouchersUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutRedeemedVouchersUpdateInput"];
	["Contember_CustomerUpdateSpendingEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"];
	["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"];
	["Contember_CustomerUpdateCustomerTabsRelationInput"]: GraphQLTypes["Contember_CustomerUpdateCustomerTabsRelationInput"];
	["Contember_CustomerTabWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_CustomerTabWithoutCustomerUpdateInput"];
	["Contember_CustomerTabUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabUpdateItemsEntityRelationInput"];
	["Contember_CustomerTabUpdateItemsRelationInput"]: GraphQLTypes["Contember_CustomerTabUpdateItemsRelationInput"];
	["Contember_CustomerTabItemWithoutTabUpdateInput"]: GraphQLTypes["Contember_CustomerTabItemWithoutTabUpdateInput"];
	["Contember_CustomerTabItemUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabItemUpdateProductEntityRelationInput"];
	["Contember_CustomerTabItemUpsertProductRelationInput"]: GraphQLTypes["Contember_CustomerTabItemUpsertProductRelationInput"];
	["Contember_CustomerTabUpsertItemsRelationInput"]: GraphQLTypes["Contember_CustomerTabUpsertItemsRelationInput"];
	["Contember_CustomerTabUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabUpdateMetaEntityRelationInput"];
	["Contember_CustomerTabUpdateTableEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabUpdateTableEntityRelationInput"];
	["Contember_TableWithoutCustomerTabsUpdateInput"]: GraphQLTypes["Contember_TableWithoutCustomerTabsUpdateInput"];
	["Contember_TableUpdateAreaEntityRelationInput"]: GraphQLTypes["Contember_TableUpdateAreaEntityRelationInput"];
	["Contember_AreaWithoutTablesUpdateInput"]: GraphQLTypes["Contember_AreaWithoutTablesUpdateInput"];
	["Contember_AreaUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_AreaUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutAreasUpdateInput"]: GraphQLTypes["Contember_VenueWithoutAreasUpdateInput"];
	["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"];
	["Contember_VenueUpdateEnabledUnassignReasonsRelationInput"]: GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsRelationInput"];
	["Contember_UnassignReasonWithoutVenuesUpdateInput"]: GraphQLTypes["Contember_UnassignReasonWithoutVenuesUpdateInput"];
	["Contember_VenueUpsertEnabledUnassignReasonsRelationInput"]: GraphQLTypes["Contember_VenueUpsertEnabledUnassignReasonsRelationInput"];
	["Contember_VenueUpdateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"];
	["Contember_VenueUpdateCategoriesRelationInput"]: GraphQLTypes["Contember_VenueUpdateCategoriesRelationInput"];
	["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"];
	["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"];
	["Contember_PointOfSaleCategoryUpsertCategoryRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpsertCategoryRelationInput"];
	["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"];
	["Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput"];
	["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"];
	["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"];
	["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput"];
	["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"];
	["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"];
	["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"];
	["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutPointOfSalePrintersUpdateInput"]: GraphQLTypes["Contember_VenueWithoutPointOfSalePrintersUpdateInput"];
	["Contember_VenueUpdateAreasEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"];
	["Contember_VenueUpdateAreasRelationInput"]: GraphQLTypes["Contember_VenueUpdateAreasRelationInput"];
	["Contember_AreaWithoutVenueUpdateInput"]: GraphQLTypes["Contember_AreaWithoutVenueUpdateInput"];
	["Contember_AreaUpdateTablesEntityRelationInput"]: GraphQLTypes["Contember_AreaUpdateTablesEntityRelationInput"];
	["Contember_AreaUpdateTablesRelationInput"]: GraphQLTypes["Contember_AreaUpdateTablesRelationInput"];
	["Contember_TableWithoutAreaUpdateInput"]: GraphQLTypes["Contember_TableWithoutAreaUpdateInput"];
	["Contember_TableUpdateCustomerTabsEntityRelationInput"]: GraphQLTypes["Contember_TableUpdateCustomerTabsEntityRelationInput"];
	["Contember_TableUpdateCustomerTabsRelationInput"]: GraphQLTypes["Contember_TableUpdateCustomerTabsRelationInput"];
	["Contember_CustomerTabWithoutTableUpdateInput"]: GraphQLTypes["Contember_CustomerTabWithoutTableUpdateInput"];
	["Contember_CustomerTabUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutCustomerTabsUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutCustomerTabsUpdateInput"];
	["Contember_CustomerUpdateLeadEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"];
	["Contember_CustomerUpdateLeadRelationInput"]: GraphQLTypes["Contember_CustomerUpdateLeadRelationInput"];
	["Contember_PipelineLeadWithoutCustomerUpdateInput"]: GraphQLTypes["Contember_PipelineLeadWithoutCustomerUpdateInput"];
	["Contember_PipelineLeadUpdateStageItemEntityRelationInput"]: GraphQLTypes["Contember_PipelineLeadUpdateStageItemEntityRelationInput"];
	["Contember_PipelineStageItemWithoutLeadUpdateInput"]: GraphQLTypes["Contember_PipelineStageItemWithoutLeadUpdateInput"];
	["Contember_PipelineStageItemUpdateListEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageItemUpdateListEntityRelationInput"];
	["Contember_PipelineStageWithoutItemsUpdateInput"]: GraphQLTypes["Contember_PipelineStageWithoutItemsUpdateInput"];
	["Contember_PipelineStageUpdatePipelineEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdatePipelineEntityRelationInput"];
	["Contember_PipelineWithoutStagesUpdateInput"]: GraphQLTypes["Contember_PipelineWithoutStagesUpdateInput"];
	["Contember_PipelineStageUpsertPipelineRelationInput"]: GraphQLTypes["Contember_PipelineStageUpsertPipelineRelationInput"];
	["Contember_PipelineStageUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdateLocalesEntityRelationInput"];
	["Contember_PipelineStageUpdateLocalesRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdateLocalesRelationInput"];
	["Contember_PipelineStageLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_PipelineStageLocaleWithoutRootUpdateInput"];
	["Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPipelineStagesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutPipelineStagesUpdateInput"];
	["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"];
	["Contember_LocaleUpdateSubscriptionBoxesRelationInput"]: GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesRelationInput"];
	["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"];
	["Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput"];
	["Contember_SubscriptionBoxWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_SubscriptionBoxWithoutLocalesUpdateInput"];
	["Contember_SubscriptionBoxLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleUpsertRootRelationInput"];
	["Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput"];
	["Contember_SubscriptionBoxLocaleUpsertTextsRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleUpsertTextsRelationInput"];
	["Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput"];
	["Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput"]: GraphQLTypes["Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput"];
	["Contember_LocaleUpsertSubscriptionBoxesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertSubscriptionBoxesRelationInput"];
	["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"];
	["Contember_LocaleUpdateBusinessServicesRelationInput"]: GraphQLTypes["Contember_LocaleUpdateBusinessServicesRelationInput"];
	["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"];
	["Contember_BusinessServiceLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceLocaleUpdateRootEntityRelationInput"];
	["Contember_BusinessServiceWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_BusinessServiceWithoutLocalesUpdateInput"];
	["Contember_BusinessServiceLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_BusinessServiceLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertBusinessServicesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertBusinessServicesRelationInput"];
	["Contember_PipelineStageLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_PipelineStageLocaleUpsertLocaleRelationInput"];
	["Contember_PipelineStageUpsertLocalesRelationInput"]: GraphQLTypes["Contember_PipelineStageUpsertLocalesRelationInput"];
	["Contember_PipelineStageUpdateSentimentEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdateSentimentEntityRelationInput"];
	["Contember_PipelineStageSentimentUpdateInput"]: GraphQLTypes["Contember_PipelineStageSentimentUpdateInput"];
	["Contember_PipelineStageUpsertSentimentRelationInput"]: GraphQLTypes["Contember_PipelineStageUpsertSentimentRelationInput"];
	["Contember_PipelineStageUpdateFormsEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdateFormsEntityRelationInput"];
	["Contember_PipelineStageUpdateFormsRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdateFormsRelationInput"];
	["Contember_LeadFormWithoutStageUpdateInput"]: GraphQLTypes["Contember_LeadFormWithoutStageUpdateInput"];
	["Contember_LeadFormUpdateLeadsEntityRelationInput"]: GraphQLTypes["Contember_LeadFormUpdateLeadsEntityRelationInput"];
	["Contember_LeadFormUpdateLeadsRelationInput"]: GraphQLTypes["Contember_LeadFormUpdateLeadsRelationInput"];
	["Contember_PipelineLeadWithoutFormUpdateInput"]: GraphQLTypes["Contember_PipelineLeadWithoutFormUpdateInput"];
	["Contember_PipelineLeadUpdateCustomerEntityRelationInput"]: GraphQLTypes["Contember_PipelineLeadUpdateCustomerEntityRelationInput"];
	["Contember_CustomerWithoutLeadUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutLeadUpdateInput"];
	["Contember_CustomerUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"];
	["Contember_PipelineLeadUpsertCustomerRelationInput"]: GraphQLTypes["Contember_PipelineLeadUpsertCustomerRelationInput"];
	["Contember_LeadFormUpsertLeadsRelationInput"]: GraphQLTypes["Contember_LeadFormUpsertLeadsRelationInput"];
	["Contember_PipelineStageUpsertFormsRelationInput"]: GraphQLTypes["Contember_PipelineStageUpsertFormsRelationInput"];
	["Contember_PipelineStageItemUpsertListRelationInput"]: GraphQLTypes["Contember_PipelineStageItemUpsertListRelationInput"];
	["Contember_PipelineLeadUpsertStageItemRelationInput"]: GraphQLTypes["Contember_PipelineLeadUpsertStageItemRelationInput"];
	["Contember_PipelineLeadUpdateFormEntityRelationInput"]: GraphQLTypes["Contember_PipelineLeadUpdateFormEntityRelationInput"];
	["Contember_LeadFormWithoutLeadsUpdateInput"]: GraphQLTypes["Contember_LeadFormWithoutLeadsUpdateInput"];
	["Contember_LeadFormUpdateStageEntityRelationInput"]: GraphQLTypes["Contember_LeadFormUpdateStageEntityRelationInput"];
	["Contember_PipelineStageWithoutFormsUpdateInput"]: GraphQLTypes["Contember_PipelineStageWithoutFormsUpdateInput"];
	["Contember_PipelineStageUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdateItemsEntityRelationInput"];
	["Contember_PipelineStageUpdateItemsRelationInput"]: GraphQLTypes["Contember_PipelineStageUpdateItemsRelationInput"];
	["Contember_PipelineStageItemWithoutListUpdateInput"]: GraphQLTypes["Contember_PipelineStageItemWithoutListUpdateInput"];
	["Contember_PipelineStageItemUpdateLeadEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageItemUpdateLeadEntityRelationInput"];
	["Contember_PipelineLeadWithoutStageItemUpdateInput"]: GraphQLTypes["Contember_PipelineLeadWithoutStageItemUpdateInput"];
	["Contember_PipelineStageItemUpsertLeadRelationInput"]: GraphQLTypes["Contember_PipelineStageItemUpsertLeadRelationInput"];
	["Contember_PipelineStageUpsertItemsRelationInput"]: GraphQLTypes["Contember_PipelineStageUpsertItemsRelationInput"];
	["Contember_LeadFormUpsertStageRelationInput"]: GraphQLTypes["Contember_LeadFormUpsertStageRelationInput"];
	["Contember_PipelineLeadUpsertFormRelationInput"]: GraphQLTypes["Contember_PipelineLeadUpsertFormRelationInput"];
	["Contember_CustomerUpsertLeadRelationInput"]: GraphQLTypes["Contember_CustomerUpsertLeadRelationInput"];
	["Contember_CustomerTabUpsertCustomerRelationInput"]: GraphQLTypes["Contember_CustomerTabUpsertCustomerRelationInput"];
	["Contember_CustomerTabUpdateSeatEntityRelationInput"]: GraphQLTypes["Contember_CustomerTabUpdateSeatEntityRelationInput"];
	["Contember_SeatWithoutCustomerTabsUpdateInput"]: GraphQLTypes["Contember_SeatWithoutCustomerTabsUpdateInput"];
	["Contember_SeatUpdateTableEntityRelationInput"]: GraphQLTypes["Contember_SeatUpdateTableEntityRelationInput"];
	["Contember_TableWithoutSeatsUpdateInput"]: GraphQLTypes["Contember_TableWithoutSeatsUpdateInput"];
	["Contember_SeatUpsertTableRelationInput"]: GraphQLTypes["Contember_SeatUpsertTableRelationInput"];
	["Contember_CustomerTabUpsertSeatRelationInput"]: GraphQLTypes["Contember_CustomerTabUpsertSeatRelationInput"];
	["Contember_TableUpsertCustomerTabsRelationInput"]: GraphQLTypes["Contember_TableUpsertCustomerTabsRelationInput"];
	["Contember_TableUpdateSeatsEntityRelationInput"]: GraphQLTypes["Contember_TableUpdateSeatsEntityRelationInput"];
	["Contember_TableUpdateSeatsRelationInput"]: GraphQLTypes["Contember_TableUpdateSeatsRelationInput"];
	["Contember_SeatWithoutTableUpdateInput"]: GraphQLTypes["Contember_SeatWithoutTableUpdateInput"];
	["Contember_SeatUpdateCustomerTabsEntityRelationInput"]: GraphQLTypes["Contember_SeatUpdateCustomerTabsEntityRelationInput"];
	["Contember_SeatUpdateCustomerTabsRelationInput"]: GraphQLTypes["Contember_SeatUpdateCustomerTabsRelationInput"];
	["Contember_CustomerTabWithoutSeatUpdateInput"]: GraphQLTypes["Contember_CustomerTabWithoutSeatUpdateInput"];
	["Contember_SeatUpsertCustomerTabsRelationInput"]: GraphQLTypes["Contember_SeatUpsertCustomerTabsRelationInput"];
	["Contember_TableUpsertSeatsRelationInput"]: GraphQLTypes["Contember_TableUpsertSeatsRelationInput"];
	["Contember_AreaUpsertTablesRelationInput"]: GraphQLTypes["Contember_AreaUpsertTablesRelationInput"];
	["Contember_AreaUpdateLinesEntityRelationInput"]: GraphQLTypes["Contember_AreaUpdateLinesEntityRelationInput"];
	["Contember_AreaUpdateLinesRelationInput"]: GraphQLTypes["Contember_AreaUpdateLinesRelationInput"];
	["Contember_LineWithoutAreaUpdateInput"]: GraphQLTypes["Contember_LineWithoutAreaUpdateInput"];
	["Contember_AreaUpsertLinesRelationInput"]: GraphQLTypes["Contember_AreaUpsertLinesRelationInput"];
	["Contember_VenueUpsertAreasRelationInput"]: GraphQLTypes["Contember_VenueUpsertAreasRelationInput"];
	["Contember_VenueUpdateChannelsEntityRelationInput"]: GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"];
	["Contember_VenueUpdateChannelsRelationInput"]: GraphQLTypes["Contember_VenueUpdateChannelsRelationInput"];
	["Contember_ChannelWithoutVenueUpdateInput"]: GraphQLTypes["Contember_ChannelWithoutVenueUpdateInput"];
	["Contember_VenueUpsertChannelsRelationInput"]: GraphQLTypes["Contember_VenueUpsertChannelsRelationInput"];
	["Contember_PointOfSalePrinterUpsertVenueRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpsertVenueRelationInput"];
	["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"];
	["Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput"];
	["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"];
	["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutCategoriesUpdateInput"]: GraphQLTypes["Contember_VenueWithoutCategoriesUpdateInput"];
	["Contember_PointOfSaleCategoryUpsertVenueRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpsertVenueRelationInput"];
	["Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput"];
	["Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput"];
	["Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput"];
	["Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput"];
	["Contember_VenueUpsertCategoriesRelationInput"]: GraphQLTypes["Contember_VenueUpsertCategoriesRelationInput"];
	["Contember_AreaUpsertVenueRelationInput"]: GraphQLTypes["Contember_AreaUpsertVenueRelationInput"];
	["Contember_TableUpsertAreaRelationInput"]: GraphQLTypes["Contember_TableUpsertAreaRelationInput"];
	["Contember_CustomerTabUpsertTableRelationInput"]: GraphQLTypes["Contember_CustomerTabUpsertTableRelationInput"];
	["Contember_CustomerUpsertCustomerTabsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertCustomerTabsRelationInput"];
	["Contember_VoucherRedemptionUpsertCustomerRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpsertCustomerRelationInput"];
	["Contember_VoucherUpsertRedemptionRelationInput"]: GraphQLTypes["Contember_VoucherUpsertRedemptionRelationInput"];
	["Contember_CustomerUpsertOwnsVouchersRelationInput"]: GraphQLTypes["Contember_CustomerUpsertOwnsVouchersRelationInput"];
	["Contember_VoucherUpsertBuyerRelationInput"]: GraphQLTypes["Contember_VoucherUpsertBuyerRelationInput"];
	["Contember_VirtualProductEffectUpsertResultVouchersRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectUpsertResultVouchersRelationInput"];
	["Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput"];
	["Contember_CustomerCreditUpsertTransactionsRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpsertTransactionsRelationInput"];
	["Contember_VoucherRedemptionUpsertCustomerCreditRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpsertCustomerCreditRelationInput"];
	["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"];
	["Contember_VoucherWithoutRedemptionUpdateInput"]: GraphQLTypes["Contember_VoucherWithoutRedemptionUpdateInput"];
	["Contember_VoucherRedemptionUpsertVoucherRelationInput"]: GraphQLTypes["Contember_VoucherRedemptionUpsertVoucherRelationInput"];
	["Contember_CustomerUpsertRedeemedVouchersRelationInput"]: GraphQLTypes["Contember_CustomerUpsertRedeemedVouchersRelationInput"];
	["Contember_VoucherUpsertOwnerRelationInput"]: GraphQLTypes["Contember_VoucherUpsertOwnerRelationInput"];
	["Contember_CustomerUpsertBoughtVouchersRelationInput"]: GraphQLTypes["Contember_CustomerUpsertBoughtVouchersRelationInput"];
	["Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput"];
	["Contember_OrderUpsertBillingSubjectRelationInput"]: GraphQLTypes["Contember_OrderUpsertBillingSubjectRelationInput"];
	["Contember_FulfillmentNoteUpsertOrdersRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpsertOrdersRelationInput"];
	["Contember_VenueUpsertFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_VenueUpsertFulfillmentNotesRelationInput"];
	["Contember_OrderUpsertVenueRelationInput"]: GraphQLTypes["Contember_OrderUpsertVenueRelationInput"];
	["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"];
	["Contember_OrderUpdateFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_OrderUpdateFulfillmentNotesRelationInput"];
	["Contember_FulfillmentNoteWithoutOrdersUpdateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutOrdersUpdateInput"];
	["Contember_OrderUpsertFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_OrderUpsertFulfillmentNotesRelationInput"];
	["Contember_BillingSubjectUpsertOrdersRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpsertOrdersRelationInput"];
	["Contember_CustomerUpsertDefaultBillingSubjectRelationInput"]: GraphQLTypes["Contember_CustomerUpsertDefaultBillingSubjectRelationInput"];
	["Contember_BillingSubjectUpsertCustomerRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpsertCustomerRelationInput"];
	["Contember_InvoiceUpsertBillingSubjectRelationInput"]: GraphQLTypes["Contember_InvoiceUpsertBillingSubjectRelationInput"];
	["Contember_OrderPaymentUpsertInvoiceRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpsertInvoiceRelationInput"];
	["Contember_OrderPaymentUpdateVatRateEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpdateVatRateEntityRelationInput"];
	["Contember_OrderPaymentUpsertVatRateRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpsertVatRateRelationInput"];
	["Contember_OrderPaymentUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpdateMetaEntityRelationInput"];
	["Contember_OrderUpsertPaymentsRelationInput"]: GraphQLTypes["Contember_OrderUpsertPaymentsRelationInput"];
	["Contember_OrderDeliveryUpsertOrderRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpsertOrderRelationInput"];
	["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateEventsEntityRelationInput"];
	["Contember_OrderDeliveryUpdateEventsRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateEventsRelationInput"];
	["Contember_OrderEventWithoutDeliveryUpdateInput"]: GraphQLTypes["Contember_OrderEventWithoutDeliveryUpdateInput"];
	["Contember_OrderDeliveryUpsertEventsRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpsertEventsRelationInput"];
	["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"];
	["Contember_LiftagoRideWithoutDeliveryUpdateInput"]: GraphQLTypes["Contember_LiftagoRideWithoutDeliveryUpdateInput"];
	["Contember_OrderDeliveryUpsertLiftagoRideRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpsertLiftagoRideRelationInput"];
	["Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput"]: GraphQLTypes["Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput"];
	["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: GraphQLTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"];
	["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"]: GraphQLTypes["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"];
	["Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput"]: GraphQLTypes["Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput"];
	["Contember_OrderUpsertBillingAddressRelationInput"]: GraphQLTypes["Contember_OrderUpsertBillingAddressRelationInput"];
	["Contember_OrderPaymentUpsertOrderRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpsertOrderRelationInput"];
	["Contember_OrderEventUpsertPaymentRelationInput"]: GraphQLTypes["Contember_OrderEventUpsertPaymentRelationInput"];
	["Contember_OrderEventUpdateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_OrderEventUpdateDeliveryEntityRelationInput"];
	["Contember_OrderDeliveryWithoutEventsUpdateInput"]: GraphQLTypes["Contember_OrderDeliveryWithoutEventsUpdateInput"];
	["Contember_OrderDeliveryUpdateAddressEntityRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpdateAddressEntityRelationInput"];
	["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"]: GraphQLTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"];
	["Contember_OrderDeliveryUpsertAddressRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpsertAddressRelationInput"];
	["Contember_OrderEventUpsertDeliveryRelationInput"]: GraphQLTypes["Contember_OrderEventUpsertDeliveryRelationInput"];
	["Contember_OrderUpsertEventsRelationInput"]: GraphQLTypes["Contember_OrderUpsertEventsRelationInput"];
	["Contember_AddressUpsertBillingAddressOfOrdersRelationInput"]: GraphQLTypes["Contember_AddressUpsertBillingAddressOfOrdersRelationInput"];
	["Contember_InvoiceUpsertAddressRelationInput"]: GraphQLTypes["Contember_InvoiceUpsertAddressRelationInput"];
	["Contember_BillingSubjectUpsertInvoicesRelationInput"]: GraphQLTypes["Contember_BillingSubjectUpsertInvoicesRelationInput"];
	["Contember_CustomerUpsertBillingSubjectsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertBillingSubjectsRelationInput"];
	["Contember_CartUpsertCustomerRelationInput"]: GraphQLTypes["Contember_CartUpsertCustomerRelationInput"];
	["Contember_CartUpdateDeliveryAddressEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"];
	["Contember_CartUpsertDeliveryAddressRelationInput"]: GraphQLTypes["Contember_CartUpsertDeliveryAddressRelationInput"];
	["Contember_CartUpdateBillingAddressEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateBillingAddressEntityRelationInput"];
	["Contember_CartUpsertBillingAddressRelationInput"]: GraphQLTypes["Contember_CartUpsertBillingAddressRelationInput"];
	["Contember_CartUpdateDeliveryEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateDeliveryEntityRelationInput"];
	["Contember_ChannelDeliveryUpdateInput"]: GraphQLTypes["Contember_ChannelDeliveryUpdateInput"];
	["Contember_CartUpsertDeliveryRelationInput"]: GraphQLTypes["Contember_CartUpsertDeliveryRelationInput"];
	["Contember_CartUpdatePaymentEntityRelationInput"]: GraphQLTypes["Contember_CartUpdatePaymentEntityRelationInput"];
	["Contember_ChannelPaymentUpdateInput"]: GraphQLTypes["Contember_ChannelPaymentUpdateInput"];
	["Contember_CartUpsertPaymentRelationInput"]: GraphQLTypes["Contember_CartUpsertPaymentRelationInput"];
	["Contember_CartUpdateBillingSubjectEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"];
	["Contember_BillingSubjectUpdateInput"]: GraphQLTypes["Contember_BillingSubjectUpdateInput"];
	["Contember_CartUpsertBillingSubjectRelationInput"]: GraphQLTypes["Contember_CartUpsertBillingSubjectRelationInput"];
	["Contember_CartUpdatePriceLinesEntityRelationInput"]: GraphQLTypes["Contember_CartUpdatePriceLinesEntityRelationInput"];
	["Contember_CartUpdateSummaryEntityRelationInput"]: GraphQLTypes["Contember_CartUpdateSummaryEntityRelationInput"];
	["Contember_OrderUpsertCartRelationInput"]: GraphQLTypes["Contember_OrderUpsertCartRelationInput"];
	["Contember_OrderDiscountUpsertOrderRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpsertOrderRelationInput"];
	["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"];
	["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"];
	["Contember_OrderDiscountUpsertVoucherRedemptionRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpsertVoucherRedemptionRelationInput"];
	["Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput"];
	["Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput"]: GraphQLTypes["Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput"];
	["Contember_OrderItemUpsertVirtualProductEffectsRelationInput"]: GraphQLTypes["Contember_OrderItemUpsertVirtualProductEffectsRelationInput"];
	["Contember_OrderUpsertItemsRelationInput"]: GraphQLTypes["Contember_OrderUpsertItemsRelationInput"];
	["Contember_VenueUpsertOrdersRelationInput"]: GraphQLTypes["Contember_VenueUpsertOrdersRelationInput"];
	["Contember_PointOfSaleTerminalUpsertVenueRelationInput"]: GraphQLTypes["Contember_PointOfSaleTerminalUpsertVenueRelationInput"];
	["Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput"];
	["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"];
	["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput"];
	["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"]: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"];
	["Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput"];
	["Contember_IdentificationCodeUpsertApplicationDevicesRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpsertApplicationDevicesRelationInput"];
	["Contember_IdentificationCodeLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertIdentificationCodesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertIdentificationCodesRelationInput"];
	["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"];
	["Contember_LocaleUpdatePipelineStagesRelationInput"]: GraphQLTypes["Contember_LocaleUpdatePipelineStagesRelationInput"];
	["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"]: GraphQLTypes["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"];
	["Contember_PipelineStageLocaleUpdateRootEntityRelationInput"]: GraphQLTypes["Contember_PipelineStageLocaleUpdateRootEntityRelationInput"];
	["Contember_PipelineStageWithoutLocalesUpdateInput"]: GraphQLTypes["Contember_PipelineStageWithoutLocalesUpdateInput"];
	["Contember_PipelineStageLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_PipelineStageLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertPipelineStagesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertPipelineStagesRelationInput"];
	["Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput"];
	["Contember_PointOfSaleCategoryUpsertLocalesRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpsertLocalesRelationInput"];
	["Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput"];
	["Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput"];
	["Contember_IdentificationCodeLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_IdentificationCodeLocaleUpsertLocaleRelationInput"];
	["Contember_IdentificationCodeUpsertLocalesRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpsertLocalesRelationInput"];
	["Contember_IdentificationAttemptUpsertCodeRelationInput"]: GraphQLTypes["Contember_IdentificationAttemptUpsertCodeRelationInput"];
	["Contember_UserUpsertIdentificationAttemptsRelationInput"]: GraphQLTypes["Contember_UserUpsertIdentificationAttemptsRelationInput"];
	["Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput"]: GraphQLTypes["Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput"];
	["Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput"];
	["Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput"]: GraphQLTypes["Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput"];
	["Contember_VenueUpsertPointOfSalePrintersRelationInput"]: GraphQLTypes["Contember_VenueUpsertPointOfSalePrintersRelationInput"];
	["Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput"];
	["Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput"]: GraphQLTypes["Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput"];
	["Contember_VenueUpsertPointOfSaleTerminalsRelationInput"]: GraphQLTypes["Contember_VenueUpsertPointOfSaleTerminalsRelationInput"];
	["Contember_FulfillmentNoteUpsertVenuesRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpsertVenuesRelationInput"];
	["Contember_ProductUpsertFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_ProductUpsertFulfillmentNotesRelationInput"];
	["Contember_PointOfSaleCategoryProductUpsertProductRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUpsertProductRelationInput"];
	["Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput"];
	["Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput"];
	["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"];
	["Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"];
	["Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput"];
	["Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput"];
	["Contember_PointOfSaleCategoryUpsertProductsRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryUpsertProductsRelationInput"];
	["Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertPointOfSaleCategoriesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertPointOfSaleCategoriesRelationInput"];
	["Contember_ProductLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_ProductLocaleUpsertLocaleRelationInput"];
	["Contember_ProductUpsertLocalesRelationInput"]: GraphQLTypes["Contember_ProductUpsertLocalesRelationInput"];
	["Contember_ProductRecipeUpsertProductsRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpsertProductsRelationInput"];
	["Contember_ProductRecipeUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateLocalesEntityRelationInput"];
	["Contember_ProductRecipeUpdateLocalesRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpdateLocalesRelationInput"];
	["Contember_ProductRecipeLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_ProductRecipeLocaleWithoutRootUpdateInput"];
	["Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductRecipesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutProductRecipesUpdateInput"];
	["Contember_ProductRecipeLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_ProductRecipeLocaleUpsertLocaleRelationInput"];
	["Contember_ProductRecipeUpsertLocalesRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpsertLocalesRelationInput"];
	["Contember_PreparedPackingQuantityUpsertRecipeRelationInput"]: GraphQLTypes["Contember_PreparedPackingQuantityUpsertRecipeRelationInput"];
	["Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput"]: GraphQLTypes["Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput"];
	["Contember_ProductPackingLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_ProductPackingLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertProductPackingsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertProductPackingsRelationInput"];
	["Contember_DeliveryMethodLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_DeliveryMethodLocaleUpsertLocaleRelationInput"];
	["Contember_DeliveryMethodUpsertLocalesRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpsertLocalesRelationInput"];
	["Contember_CourierUpsertDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_CourierUpsertDeliveryMethodsRelationInput"];
	["Contember_DeliveryRouteUpsertCourierRelationInput"]: GraphQLTypes["Contember_DeliveryRouteUpsertCourierRelationInput"];
	["Contember_DeliveryWaypointUpsertRouteRelationInput"]: GraphQLTypes["Contember_DeliveryWaypointUpsertRouteRelationInput"];
	["Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput"];
	["Contember_OrderUpsertDeliveryRelationInput"]: GraphQLTypes["Contember_OrderUpsertDeliveryRelationInput"];
	["Contember_OrderEventUpsertOrderRelationInput"]: GraphQLTypes["Contember_OrderEventUpsertOrderRelationInput"];
	["Contember_OrderPaymentUpsertEventsRelationInput"]: GraphQLTypes["Contember_OrderPaymentUpsertEventsRelationInput"];
	["Contember_InvoiceUpsertOrderPaymentsRelationInput"]: GraphQLTypes["Contember_InvoiceUpsertOrderPaymentsRelationInput"];
	["Contember_CustomerUpsertInvoicesRelationInput"]: GraphQLTypes["Contember_CustomerUpsertInvoicesRelationInput"];
	["Contember_AnonymousSessionUpsertCustomerRelationInput"]: GraphQLTypes["Contember_AnonymousSessionUpsertCustomerRelationInput"];
	["Contember_UserDeviceUpsertAnonymousSessionRelationInput"]: GraphQLTypes["Contember_UserDeviceUpsertAnonymousSessionRelationInput"];
	["Contember_UserUpsertDevicesRelationInput"]: GraphQLTypes["Contember_UserUpsertDevicesRelationInput"];
	["Contember_IdentificationAttemptUpsertUserRelationInput"]: GraphQLTypes["Contember_IdentificationAttemptUpsertUserRelationInput"];
	["Contember_IdentificationCodeUpsertAttemptsRelationInput"]: GraphQLTypes["Contember_IdentificationCodeUpsertAttemptsRelationInput"];
	["Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput"]: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput"];
	["Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput"]: GraphQLTypes["Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput"];
	["Contember_TimetableTemplateUpsertVenueRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpsertVenueRelationInput"];
	["Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput"];
	["Contember_TimetableShiftGroupUpsertPositionRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpsertPositionRelationInput"];
	["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"];
	["Contember_TimetableTemplateUpdateInput"]: GraphQLTypes["Contember_TimetableTemplateUpdateInput"];
	["Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput"];
	["Contember_TimetableShiftUpsertGroupRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpsertGroupRelationInput"];
	["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"];
	["Contember_EmployeeQueueItemWithoutShiftUpdateInput"]: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftUpdateInput"];
	["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"];
	["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"]: GraphQLTypes["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"];
	["Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput"]: GraphQLTypes["Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput"];
	["Contember_UnassignReasonUpdateInput"]: GraphQLTypes["Contember_UnassignReasonUpdateInput"];
	["Contember_UnassignReasonUpdateVenuesEntityRelationInput"]: GraphQLTypes["Contember_UnassignReasonUpdateVenuesEntityRelationInput"];
	["Contember_UnassignReasonUpdateVenuesRelationInput"]: GraphQLTypes["Contember_UnassignReasonUpdateVenuesRelationInput"];
	["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"]: GraphQLTypes["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"];
	["Contember_UnassignReasonUpsertVenuesRelationInput"]: GraphQLTypes["Contember_UnassignReasonUpsertVenuesRelationInput"];
	["Contember_UnassignedShiftInfoUpsertReasonRelationInput"]: GraphQLTypes["Contember_UnassignedShiftInfoUpsertReasonRelationInput"];
	["Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput"];
	["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"];
	["Contember_TimetableShiftUpsertQueueItemRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpsertQueueItemRelationInput"];
	["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"];
	["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"]: GraphQLTypes["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"];
	["Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput"]: GraphQLTypes["Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput"];
	["Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput"]: GraphQLTypes["Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput"];
	["Contember_TimetableShiftUpsertSwapInfoRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpsertSwapInfoRelationInput"];
	["Contember_ShiftsProfileUpsertShiftsRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpsertShiftsRelationInput"];
	["Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput"];
	["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"];
	["Contember_TimetableShiftWithoutQueueItemUpdateInput"]: GraphQLTypes["Contember_TimetableShiftWithoutQueueItemUpdateInput"];
	["Contember_EmployeeQueueItemUpsertShiftRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpsertShiftRelationInput"];
	["Contember_EmployeeQueueUpsertItemsRelationInput"]: GraphQLTypes["Contember_EmployeeQueueUpsertItemsRelationInput"];
	["Contember_TimetableShiftGroupUpsertQueueRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpsertQueueRelationInput"];
	["Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput"]: GraphQLTypes["Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput"];
	["Contember_TimetableTemplateUpsertPositionRelationInput"]: GraphQLTypes["Contember_TimetableTemplateUpsertPositionRelationInput"];
	["Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput"];
	["Contember_TimetableShiftGroupUpsertTypeRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpsertTypeRelationInput"];
	["Contember_TimetableDayUpsertShiftGroupsRelationInput"]: GraphQLTypes["Contember_TimetableDayUpsertShiftGroupsRelationInput"];
	["Contember_TimetableDayUpdateMetaEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayUpdateMetaEntityRelationInput"];
	["Contember_VenueUpsertTimetableDaysRelationInput"]: GraphQLTypes["Contember_VenueUpsertTimetableDaysRelationInput"];
	["Contember_ChannelUpsertVenueRelationInput"]: GraphQLTypes["Contember_ChannelUpsertVenueRelationInput"];
	["Contember_TagUpsertPublicInChannelsRelationInput"]: GraphQLTypes["Contember_TagUpsertPublicInChannelsRelationInput"];
	["Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput"];
	["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"];
	["Contember_ProductUpsertDeliveryZonesRelationInput"]: GraphQLTypes["Contember_ProductUpsertDeliveryZonesRelationInput"];
	["Contember_FulfillmentNoteUpsertProductsRelationInput"]: GraphQLTypes["Contember_FulfillmentNoteUpsertProductsRelationInput"];
	["Contember_TagUpsertFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_TagUpsertFulfillmentNotesRelationInput"];
	["Contember_ChannelUpsertPublicTagsRelationInput"]: GraphQLTypes["Contember_ChannelUpsertPublicTagsRelationInput"];
	["Contember_CustomerUpsertChannelsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertChannelsRelationInput"];
	["Contember_CustomerCreditUpsertCustomerRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpsertCustomerRelationInput"];
	["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"];
	["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"]: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"];
	["Contember_CustomerCreditUpsertVoucherRedemptionRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpsertVoucherRedemptionRelationInput"];
	["Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput"]: GraphQLTypes["Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput"];
	["Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput"]: GraphQLTypes["Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput"];
	["Contember_OrderUpsertDiscountsRelationInput"]: GraphQLTypes["Contember_OrderUpsertDiscountsRelationInput"];
	["Contember_OrderItemUpsertOrderRelationInput"]: GraphQLTypes["Contember_OrderItemUpsertOrderRelationInput"];
	["Contember_ProductUpsertOrderItemsRelationInput"]: GraphQLTypes["Contember_ProductUpsertOrderItemsRelationInput"];
	["Contember_StocksManagerUpsertManagedProductsRelationInput"]: GraphQLTypes["Contember_StocksManagerUpsertManagedProductsRelationInput"];
	["Contember_StaffUpsertStocksManagerRelationInput"]: GraphQLTypes["Contember_StaffUpsertStocksManagerRelationInput"];
	["Contember_StaffUpdateVenuesEntityRelationInput"]: GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"];
	["Contember_StaffUpdateVenuesRelationInput"]: GraphQLTypes["Contember_StaffUpdateVenuesRelationInput"];
	["Contember_VenueWithoutStaffMembersUpdateInput"]: GraphQLTypes["Contember_VenueWithoutStaffMembersUpdateInput"];
	["Contember_StaffUpsertVenuesRelationInput"]: GraphQLTypes["Contember_StaffUpsertVenuesRelationInput"];
	["Contember_ShiftsManagerUpsertStaffRelationInput"]: GraphQLTypes["Contember_ShiftsManagerUpsertStaffRelationInput"];
	["Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput"];
	["Contember_ShiftsProfileUpsertRolesRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpsertRolesRelationInput"];
	["Contember_StaffUpsertShiftsProfileRelationInput"]: GraphQLTypes["Contember_StaffUpsertShiftsProfileRelationInput"];
	["Contember_UserUpsertStaffRelationInput"]: GraphQLTypes["Contember_UserUpsertStaffRelationInput"];
	["Contember_CustomerUpsertUserRelationInput"]: GraphQLTypes["Contember_CustomerUpsertUserRelationInput"];
	["Contember_OrderUpsertCustomerRelationInput"]: GraphQLTypes["Contember_OrderUpsertCustomerRelationInput"];
	["Contember_CartUpsertOrderRelationInput"]: GraphQLTypes["Contember_CartUpsertOrderRelationInput"];
	["Contember_CustomerUpsertCartsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertCartsRelationInput"];
	["Contember_UserUpsertCustomerRelationInput"]: GraphQLTypes["Contember_UserUpsertCustomerRelationInput"];
	["Contember_StaffUpsertUserRelationInput"]: GraphQLTypes["Contember_StaffUpsertUserRelationInput"];
	["Contember_BusinessToBusinessManagerUpsertStaffRelationInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerUpsertStaffRelationInput"];
	["Contember_BusinessCustomerUpsertAccountManagerRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertAccountManagerRelationInput"];
	["Contember_BusinessCustomerUpdateLogoEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"];
	["Contember_BusinessCustomerUpsertLogoRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertLogoRelationInput"];
	["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"];
	["Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput"];
	["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"]: GraphQLTypes["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"];
	["Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput"];
	["Contember_BusinessServiceUpdateInput"]: GraphQLTypes["Contember_BusinessServiceUpdateInput"];
	["Contember_BusinessServiceUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceUpdateLocalesEntityRelationInput"];
	["Contember_BusinessServiceUpdateLocalesRelationInput"]: GraphQLTypes["Contember_BusinessServiceUpdateLocalesRelationInput"];
	["Contember_BusinessServiceLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_BusinessServiceLocaleWithoutRootUpdateInput"];
	["Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutBusinessServicesUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutBusinessServicesUpdateInput"];
	["Contember_BusinessServiceLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_BusinessServiceLocaleUpsertLocaleRelationInput"];
	["Contember_BusinessServiceUpsertLocalesRelationInput"]: GraphQLTypes["Contember_BusinessServiceUpsertLocalesRelationInput"];
	["Contember_BusinessServiceEventUpsertBusinessServiceRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventUpsertBusinessServiceRelationInput"];
	["Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput"];
	["Contember_StaffUpdateInput"]: GraphQLTypes["Contember_StaffUpdateInput"];
	["Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput"];
	["Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput"];
	["Contember_BusinessServiceEventUpsertCreatedByRelationInput"]: GraphQLTypes["Contember_BusinessServiceEventUpsertCreatedByRelationInput"];
	["Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput"];
	["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"];
	["Contember_DoubleshotCoffeeEquipmentUpdateInput"]: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUpdateInput"];
	["Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput"];
	["Contember_CustomerUpsertOwnerOfBusinessesRelationInput"]: GraphQLTypes["Contember_CustomerUpsertOwnerOfBusinessesRelationInput"];
	["Contember_BusinessCustomerUpsertMembersRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertMembersRelationInput"];
	["Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput"]: GraphQLTypes["Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput"];
	["Contember_StaffUpsertBusinessToBusinessManagerRelationInput"]: GraphQLTypes["Contember_StaffUpsertBusinessToBusinessManagerRelationInput"];
	["Contember_ShiftsProfileUpsertStaffRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpsertStaffRelationInput"];
	["Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput"];
	["Contember_TimetableDayNoteUpsertRoleRelationInput"]: GraphQLTypes["Contember_TimetableDayNoteUpsertRoleRelationInput"];
	["Contember_TimetableDayUpsertNotesRelationInput"]: GraphQLTypes["Contember_TimetableDayUpsertNotesRelationInput"];
	["Contember_TimetableDayUpdateVenueEntityRelationInput"]: GraphQLTypes["Contember_TimetableDayUpdateVenueEntityRelationInput"];
	["Contember_VenueWithoutTimetableDaysUpdateInput"]: GraphQLTypes["Contember_VenueWithoutTimetableDaysUpdateInput"];
	["Contember_TimetableDayUpsertVenueRelationInput"]: GraphQLTypes["Contember_TimetableDayUpsertVenueRelationInput"];
	["Contember_TimetableShiftGroupUpsertDayRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpsertDayRelationInput"];
	["Contember_EmployeeQueueUpsertShiftGroupRelationInput"]: GraphQLTypes["Contember_EmployeeQueueUpsertShiftGroupRelationInput"];
	["Contember_EmployeeQueueItemUpsertQueueRelationInput"]: GraphQLTypes["Contember_EmployeeQueueItemUpsertQueueRelationInput"];
	["Contember_ShiftsProfileUpsertQueueItemsRelationInput"]: GraphQLTypes["Contember_ShiftsProfileUpsertQueueItemsRelationInput"];
	["Contember_TimetableShiftUpsertShiftsProfileRelationInput"]: GraphQLTypes["Contember_TimetableShiftUpsertShiftsProfileRelationInput"];
	["Contember_TimetableShiftGroupUpsertShiftsRelationInput"]: GraphQLTypes["Contember_TimetableShiftGroupUpsertShiftsRelationInput"];
	["Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput"]: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput"];
	["Contember_ShiftsManagerUpsertManagedRolesRelationInput"]: GraphQLTypes["Contember_ShiftsManagerUpsertManagedRolesRelationInput"];
	["Contember_StaffUpsertShiftsManagerRelationInput"]: GraphQLTypes["Contember_StaffUpsertShiftsManagerRelationInput"];
	["Contember_CourierUpsertStaffRelationInput"]: GraphQLTypes["Contember_CourierUpsertStaffRelationInput"];
	["Contember_DeliveryMethodUpsertCourierHandlersRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpsertCourierHandlersRelationInput"];
	["Contember_OrderDeliveryUpsertMethodRelationInput"]: GraphQLTypes["Contember_OrderDeliveryUpsertMethodRelationInput"];
	["Contember_DeliveryWaypointUpsertDeliveryRelationInput"]: GraphQLTypes["Contember_DeliveryWaypointUpsertDeliveryRelationInput"];
	["Contember_DeliveryRouteUpsertWaypointsRelationInput"]: GraphQLTypes["Contember_DeliveryRouteUpsertWaypointsRelationInput"];
	["Contember_CourierUpsertRoutesRelationInput"]: GraphQLTypes["Contember_CourierUpsertRoutesRelationInput"];
	["Contember_StaffUpsertCourierRelationInput"]: GraphQLTypes["Contember_StaffUpsertCourierRelationInput"];
	["Contember_StocksManagerUpsertStaffRelationInput"]: GraphQLTypes["Contember_StocksManagerUpsertStaffRelationInput"];
	["Contember_ProductUpsertManagerRelationInput"]: GraphQLTypes["Contember_ProductUpsertManagerRelationInput"];
	["Contember_VirtualProductUpsertProductRelationInput"]: GraphQLTypes["Contember_VirtualProductUpsertProductRelationInput"];
	["Contember_WorkspaceUpsertCreditsProductRelationInput"]: GraphQLTypes["Contember_WorkspaceUpsertCreditsProductRelationInput"];
	["Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput"]: GraphQLTypes["Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput"];
	["Contember_WorkspaceUpsertDiscountVatRateRelationInput"]: GraphQLTypes["Contember_WorkspaceUpsertDiscountVatRateRelationInput"];
	["Contember_FreshingContainerUpsertWorkspaceRelationInput"]: GraphQLTypes["Contember_FreshingContainerUpsertWorkspaceRelationInput"];
	["Contember_ProductIngredientUpsertFreshingContainerRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertFreshingContainerRelationInput"];
	["Contember_ProductIngredientUpdateImageEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"];
	["Contember_ProductIngredientUpsertImageRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertImageRelationInput"];
	["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"];
	["Contember_IngredientSupplierListUpdateInput"]: GraphQLTypes["Contember_IngredientSupplierListUpdateInput"];
	["Contember_IngredientSupplierListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierListUpdateItemsEntityRelationInput"];
	["Contember_IngredientSupplierListUpdateItemsRelationInput"]: GraphQLTypes["Contember_IngredientSupplierListUpdateItemsRelationInput"];
	["Contember_IngredientSupplierItemWithoutListUpdateInput"]: GraphQLTypes["Contember_IngredientSupplierItemWithoutListUpdateInput"];
	["Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput"];
	["Contember_IngredientSupplierUpdateInput"]: GraphQLTypes["Contember_IngredientSupplierUpdateInput"];
	["Contember_IngredientSupplierUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierUpdateLocalesEntityRelationInput"];
	["Contember_IngredientSupplierUpdateLocalesRelationInput"]: GraphQLTypes["Contember_IngredientSupplierUpdateLocalesRelationInput"];
	["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"];
	["Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutIngredientSuppliersUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutIngredientSuppliersUpdateInput"];
	["Contember_IngredientSupplierLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_IngredientSupplierLocaleUpsertLocaleRelationInput"];
	["Contember_IngredientSupplierUpsertLocalesRelationInput"]: GraphQLTypes["Contember_IngredientSupplierUpsertLocalesRelationInput"];
	["Contember_IngredientSupplierItemUpsertSupplierRelationInput"]: GraphQLTypes["Contember_IngredientSupplierItemUpsertSupplierRelationInput"];
	["Contember_IngredientSupplierListUpsertItemsRelationInput"]: GraphQLTypes["Contember_IngredientSupplierListUpsertItemsRelationInput"];
	["Contember_ProductIngredientUpsertSuppliersRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertSuppliersRelationInput"];
	["Contember_AllergenUpsertIngredientsRelationInput"]: GraphQLTypes["Contember_AllergenUpsertIngredientsRelationInput"];
	["Contember_AllergenLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_AllergenLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertAllergensRelationInput"]: GraphQLTypes["Contember_LocaleUpsertAllergensRelationInput"];
	["Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput"];
	["Contember_OrderRecurrenceUpsertLocalesRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceUpsertLocalesRelationInput"];
	["Contember_BusinessCategoryUpsertRecurrencesRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpsertRecurrencesRelationInput"];
	["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"];
	["Contember_ProductListWithoutBusinessCategoryUpdateInput"]: GraphQLTypes["Contember_ProductListWithoutBusinessCategoryUpdateInput"];
	["Contember_ProductListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductListUpdateItemsEntityRelationInput"];
	["Contember_ProductListUpdateItemsRelationInput"]: GraphQLTypes["Contember_ProductListUpdateItemsRelationInput"];
	["Contember_ProductListItemWithoutListUpdateInput"]: GraphQLTypes["Contember_ProductListItemWithoutListUpdateInput"];
	["Contember_ProductListItemUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductListItemUpdateProductEntityRelationInput"];
	["Contember_ProductListItemUpsertProductRelationInput"]: GraphQLTypes["Contember_ProductListItemUpsertProductRelationInput"];
	["Contember_ProductListUpsertItemsRelationInput"]: GraphQLTypes["Contember_ProductListUpsertItemsRelationInput"];
	["Contember_BusinessCategoryUpsertSpecificProductsRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpsertSpecificProductsRelationInput"];
	["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"];
	["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"]: GraphQLTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"];
	["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"];
	["Contember_ProductCategoryListUpdateLocalesRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpdateLocalesRelationInput"];
	["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"];
	["Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput"];
	["Contember_LocaleWithoutProductCategoryListsUpdateInput"]: GraphQLTypes["Contember_LocaleWithoutProductCategoryListsUpdateInput"];
	["Contember_ProductCategoryListLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleUpsertLocaleRelationInput"];
	["Contember_ProductCategoryListUpsertLocalesRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpsertLocalesRelationInput"];
	["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"];
	["Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput"];
	["Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput"];
	["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"];
	["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"]: GraphQLTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"];
	["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"];
	["Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput"];
	["Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput"];
	["Contember_BusinessCustomerUpsertCategoryRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertCategoryRelationInput"];
	["Contember_CustomerUpsertMemberOfBusinessesRelationInput"]: GraphQLTypes["Contember_CustomerUpsertMemberOfBusinessesRelationInput"];
	["Contember_BusinessCustomerUpsertOwnersRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertOwnersRelationInput"];
	["Contember_CustomerUpsertBusinessRelationInput"]: GraphQLTypes["Contember_CustomerUpsertBusinessRelationInput"];
	["Contember_CustomerIngredientRatingUpsertCustomerRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingUpsertCustomerRelationInput"];
	["Contember_ProductIngredientUpsertCustomerRatingsRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertCustomerRatingsRelationInput"];
	["Contember_ProductIngredientCategoryUpsertIngredientsRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryUpsertIngredientsRelationInput"];
	["Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertProductIngredientCategoriesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertProductIngredientCategoriesRelationInput"];
	["Contember_PageLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_PageLocaleUpsertLocaleRelationInput"];
	["Contember_LinkableUpsertPageRelationInput"]: GraphQLTypes["Contember_LinkableUpsertPageRelationInput"];
	["Contember_LinkableUpdateRedirectEntityRelationInput"]: GraphQLTypes["Contember_LinkableUpdateRedirectEntityRelationInput"];
	["Contember_RedirectWithoutLinkUpdateInput"]: GraphQLTypes["Contember_RedirectWithoutLinkUpdateInput"];
	["Contember_RedirectUpdateTargetEntityRelationInput"]: GraphQLTypes["Contember_RedirectUpdateTargetEntityRelationInput"];
	["Contember_RedirectUpsertTargetRelationInput"]: GraphQLTypes["Contember_RedirectUpsertTargetRelationInput"];
	["Contember_LinkableUpsertRedirectRelationInput"]: GraphQLTypes["Contember_LinkableUpsertRedirectRelationInput"];
	["Contember_WikiPageUpsertLinkRelationInput"]: GraphQLTypes["Contember_WikiPageUpsertLinkRelationInput"];
	["Contember_WikiPageUpdateContentEntityRelationInput"]: GraphQLTypes["Contember_WikiPageUpdateContentEntityRelationInput"];
	["Contember_WikiPageUpsertContentRelationInput"]: GraphQLTypes["Contember_WikiPageUpsertContentRelationInput"];
	["Contember_WikiPageUpsertParentRelationInput"]: GraphQLTypes["Contember_WikiPageUpsertParentRelationInput"];
	["Contember_WikiPageUpdateChildrenEntityRelationInput"]: GraphQLTypes["Contember_WikiPageUpdateChildrenEntityRelationInput"];
	["Contember_WikiPageUpdateChildrenRelationInput"]: GraphQLTypes["Contember_WikiPageUpdateChildrenRelationInput"];
	["Contember_WikiPageWithoutParentUpdateInput"]: GraphQLTypes["Contember_WikiPageWithoutParentUpdateInput"];
	["Contember_WikiPageUpsertChildrenRelationInput"]: GraphQLTypes["Contember_WikiPageUpsertChildrenRelationInput"];
	["Contember_LinkableUpsertWikiPageRelationInput"]: GraphQLTypes["Contember_LinkableUpsertWikiPageRelationInput"];
	["Contember_PageLocaleUpsertLinkRelationInput"]: GraphQLTypes["Contember_PageLocaleUpsertLinkRelationInput"];
	["Contember_LocaleUpsertPagesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertPagesRelationInput"];
	["Contember_BlogPostLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpsertLocaleRelationInput"];
	["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"];
	["Contember_BlogPostLocaleUpsertSeoRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpsertSeoRelationInput"];
	["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"];
	["Contember_BlogPostLocaleUpdateProductsRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateProductsRelationInput"];
	["Contember_BlogPostLocaleUpsertProductsRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpsertProductsRelationInput"];
	["Contember_LinkableUpsertBlogPostRelationInput"]: GraphQLTypes["Contember_LinkableUpsertBlogPostRelationInput"];
	["Contember_LinkUpsertInternalLinkRelationInput"]: GraphQLTypes["Contember_LinkUpsertInternalLinkRelationInput"];
	["Contember_ContentReferenceUpsertLinkRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertLinkRelationInput"];
	["Contember_ContentReferenceUpdateBlogPostsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateBlogPostsEntityRelationInput"];
	["Contember_BlogPostListUpdateInput"]: GraphQLTypes["Contember_BlogPostListUpdateInput"];
	["Contember_BlogPostListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_BlogPostListUpdateItemsEntityRelationInput"];
	["Contember_BlogPostListUpdateItemsRelationInput"]: GraphQLTypes["Contember_BlogPostListUpdateItemsRelationInput"];
	["Contember_BlogPostListItemWithoutListUpdateInput"]: GraphQLTypes["Contember_BlogPostListItemWithoutListUpdateInput"];
	["Contember_BlogPostListItemUpdateBlogPostEntityRelationInput"]: GraphQLTypes["Contember_BlogPostListItemUpdateBlogPostEntityRelationInput"];
	["Contember_BlogPostLocaleUpdateInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateInput"];
	["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"];
	["Contember_LinkableWithoutBlogPostUpdateInput"]: GraphQLTypes["Contember_LinkableWithoutBlogPostUpdateInput"];
	["Contember_BlogPostLocaleUpsertLinkRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpsertLinkRelationInput"];
	["Contember_BlogPostListItemUpsertBlogPostRelationInput"]: GraphQLTypes["Contember_BlogPostListItemUpsertBlogPostRelationInput"];
	["Contember_BlogPostListUpsertItemsRelationInput"]: GraphQLTypes["Contember_BlogPostListUpsertItemsRelationInput"];
	["Contember_ContentReferenceUpsertBlogPostsRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertBlogPostsRelationInput"];
	["Contember_ContentReferenceUpdateLinksEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateLinksEntityRelationInput"];
	["Contember_ContentReferenceUpsertLinksRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertLinksRelationInput"];
	["Contember_ContentReferenceUpdateProductsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateProductsEntityRelationInput"];
	["Contember_ProductListUpdateInput"]: GraphQLTypes["Contember_ProductListUpdateInput"];
	["Contember_ProductListUpdateBusinessCategoryEntityRelationInput"]: GraphQLTypes["Contember_ProductListUpdateBusinessCategoryEntityRelationInput"];
	["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"]: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"];
	["Contember_ProductListUpsertBusinessCategoryRelationInput"]: GraphQLTypes["Contember_ProductListUpsertBusinessCategoryRelationInput"];
	["Contember_ContentReferenceUpsertProductsRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertProductsRelationInput"];
	["Contember_ContentReferenceUpdateHeroEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateHeroEntityRelationInput"];
	["Contember_HeroUpdateInput"]: GraphQLTypes["Contember_HeroUpdateInput"];
	["Contember_HeroUpdateMediumEntityRelationInput"]: GraphQLTypes["Contember_HeroUpdateMediumEntityRelationInput"];
	["Contember_HeroUpsertMediumRelationInput"]: GraphQLTypes["Contember_HeroUpsertMediumRelationInput"];
	["Contember_ContentReferenceUpsertHeroRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertHeroRelationInput"];
	["Contember_ContentReferenceUpdateGalleryEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateGalleryEntityRelationInput"];
	["Contember_GalleryUpdateInput"]: GraphQLTypes["Contember_GalleryUpdateInput"];
	["Contember_GalleryUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_GalleryUpdateItemsEntityRelationInput"];
	["Contember_GalleryUpdateItemsRelationInput"]: GraphQLTypes["Contember_GalleryUpdateItemsRelationInput"];
	["Contember_GalleryItemWithoutListUpdateInput"]: GraphQLTypes["Contember_GalleryItemWithoutListUpdateInput"];
	["Contember_GalleryItemUpdateMediumEntityRelationInput"]: GraphQLTypes["Contember_GalleryItemUpdateMediumEntityRelationInput"];
	["Contember_GalleryItemUpsertMediumRelationInput"]: GraphQLTypes["Contember_GalleryItemUpsertMediumRelationInput"];
	["Contember_GalleryUpsertItemsRelationInput"]: GraphQLTypes["Contember_GalleryUpsertItemsRelationInput"];
	["Contember_ContentReferenceUpsertGalleryRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertGalleryRelationInput"];
	["Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput"];
	["Contember_ContentReferenceUpsertSocialsAndAppsRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertSocialsAndAppsRelationInput"];
	["Contember_ContentReferenceUpdateProductGridEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateProductGridEntityRelationInput"];
	["Contember_ProductGridUpdateInput"]: GraphQLTypes["Contember_ProductGridUpdateInput"];
	["Contember_ProductGridUpdateCategoriesEntityRelationInput"]: GraphQLTypes["Contember_ProductGridUpdateCategoriesEntityRelationInput"];
	["Contember_ProductGridUpdateCategoriesRelationInput"]: GraphQLTypes["Contember_ProductGridUpdateCategoriesRelationInput"];
	["Contember_ProductGridCategoryWithoutRootUpdateInput"]: GraphQLTypes["Contember_ProductGridCategoryWithoutRootUpdateInput"];
	["Contember_ProductGridCategoryUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductGridCategoryUpdateItemsEntityRelationInput"];
	["Contember_ProductGridCategoryUpdateItemsRelationInput"]: GraphQLTypes["Contember_ProductGridCategoryUpdateItemsRelationInput"];
	["Contember_ProductGridItemWithoutCategoryUpdateInput"]: GraphQLTypes["Contember_ProductGridItemWithoutCategoryUpdateInput"];
	["Contember_ProductGridItemUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductGridItemUpdateProductEntityRelationInput"];
	["Contember_ProductGridItemUpsertProductRelationInput"]: GraphQLTypes["Contember_ProductGridItemUpsertProductRelationInput"];
	["Contember_ProductGridItemUpdateMediumEntityRelationInput"]: GraphQLTypes["Contember_ProductGridItemUpdateMediumEntityRelationInput"];
	["Contember_ProductGridItemUpsertMediumRelationInput"]: GraphQLTypes["Contember_ProductGridItemUpsertMediumRelationInput"];
	["Contember_ProductGridItemUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_ProductGridItemUpdateLinkEntityRelationInput"];
	["Contember_ProductGridItemUpsertLinkRelationInput"]: GraphQLTypes["Contember_ProductGridItemUpsertLinkRelationInput"];
	["Contember_ProductGridCategoryUpsertItemsRelationInput"]: GraphQLTypes["Contember_ProductGridCategoryUpsertItemsRelationInput"];
	["Contember_ProductGridCategoryUpdateImagesEntityRelationInput"]: GraphQLTypes["Contember_ProductGridCategoryUpdateImagesEntityRelationInput"];
	["Contember_ProductGridCategoryUpsertImagesRelationInput"]: GraphQLTypes["Contember_ProductGridCategoryUpsertImagesRelationInput"];
	["Contember_ProductGridUpsertCategoriesRelationInput"]: GraphQLTypes["Contember_ProductGridUpsertCategoriesRelationInput"];
	["Contember_ContentReferenceUpsertProductGridRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertProductGridRelationInput"];
	["Contember_ContentReferenceUpdateProductBannersEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateProductBannersEntityRelationInput"];
	["Contember_ProductBannerListUpdateInput"]: GraphQLTypes["Contember_ProductBannerListUpdateInput"];
	["Contember_ProductBannerListUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerListUpdateItemsEntityRelationInput"];
	["Contember_ProductBannerListUpdateItemsRelationInput"]: GraphQLTypes["Contember_ProductBannerListUpdateItemsRelationInput"];
	["Contember_ProductBannerItemWithoutListUpdateInput"]: GraphQLTypes["Contember_ProductBannerItemWithoutListUpdateInput"];
	["Contember_ProductBannerItemUpdateBannerEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerItemUpdateBannerEntityRelationInput"];
	["Contember_ProductBannerUpdateInput"]: GraphQLTypes["Contember_ProductBannerUpdateInput"];
	["Contember_ProductBannerUpdateButtonEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerUpdateButtonEntityRelationInput"];
	["Contember_ProductBannerUpsertButtonRelationInput"]: GraphQLTypes["Contember_ProductBannerUpsertButtonRelationInput"];
	["Contember_ProductBannerUpdateBackgroundImageEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerUpdateBackgroundImageEntityRelationInput"];
	["Contember_ProductBannerUpsertBackgroundImageRelationInput"]: GraphQLTypes["Contember_ProductBannerUpsertBackgroundImageRelationInput"];
	["Contember_ProductBannerUpdateProductImageEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerUpdateProductImageEntityRelationInput"];
	["Contember_ProductBannerUpsertProductImageRelationInput"]: GraphQLTypes["Contember_ProductBannerUpsertProductImageRelationInput"];
	["Contember_ProductBannerUpdateProductEntityRelationInput"]: GraphQLTypes["Contember_ProductBannerUpdateProductEntityRelationInput"];
	["Contember_ProductBannerUpsertProductRelationInput"]: GraphQLTypes["Contember_ProductBannerUpsertProductRelationInput"];
	["Contember_ProductBannerItemUpsertBannerRelationInput"]: GraphQLTypes["Contember_ProductBannerItemUpsertBannerRelationInput"];
	["Contember_ProductBannerListUpsertItemsRelationInput"]: GraphQLTypes["Contember_ProductBannerListUpsertItemsRelationInput"];
	["Contember_ContentReferenceUpsertProductBannersRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertProductBannersRelationInput"];
	["Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput"];
	["Contember_ContentReferenceUpsertDeliveryRegionsRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertDeliveryRegionsRelationInput"];
	["Contember_ContentReferenceUpdateTextListEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateTextListEntityRelationInput"];
	["Contember_ContentReferenceUpsertTextListRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertTextListRelationInput"];
	["Contember_ContentReferenceUpdateGridTilesEntityRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpdateGridTilesEntityRelationInput"];
	["Contember_GridTilesUpdateInput"]: GraphQLTypes["Contember_GridTilesUpdateInput"];
	["Contember_GridTilesUpdateItemsEntityRelationInput"]: GraphQLTypes["Contember_GridTilesUpdateItemsEntityRelationInput"];
	["Contember_GridTilesUpdateItemsRelationInput"]: GraphQLTypes["Contember_GridTilesUpdateItemsRelationInput"];
	["Contember_GridTileItemWithoutListUpdateInput"]: GraphQLTypes["Contember_GridTileItemWithoutListUpdateInput"];
	["Contember_GridTileItemUpdateMediumEntityRelationInput"]: GraphQLTypes["Contember_GridTileItemUpdateMediumEntityRelationInput"];
	["Contember_GridTileItemUpsertMediumRelationInput"]: GraphQLTypes["Contember_GridTileItemUpsertMediumRelationInput"];
	["Contember_GridTileItemUpdateLinkEntityRelationInput"]: GraphQLTypes["Contember_GridTileItemUpdateLinkEntityRelationInput"];
	["Contember_GridTileItemUpsertLinkRelationInput"]: GraphQLTypes["Contember_GridTileItemUpsertLinkRelationInput"];
	["Contember_GridTilesUpsertItemsRelationInput"]: GraphQLTypes["Contember_GridTilesUpsertItemsRelationInput"];
	["Contember_ContentReferenceUpsertGridTilesRelationInput"]: GraphQLTypes["Contember_ContentReferenceUpsertGridTilesRelationInput"];
	["Contember_ContentBlockUpsertReferencesRelationInput"]: GraphQLTypes["Contember_ContentBlockUpsertReferencesRelationInput"];
	["Contember_ContentUpsertBlocksRelationInput"]: GraphQLTypes["Contember_ContentUpsertBlocksRelationInput"];
	["Contember_BlogPostLocaleUpsertContentRelationInput"]: GraphQLTypes["Contember_BlogPostLocaleUpsertContentRelationInput"];
	["Contember_LocaleUpsertBlogPostsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertBlogPostsRelationInput"];
	["Contember_BusinessCategoryLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleUpsertLocaleRelationInput"];
	["Contember_BusinessCategoryUpsertLocalesRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpsertLocalesRelationInput"];
	["Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput"];
	["Contember_OrderRecurrenceLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_OrderRecurrenceLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertOrderRecurrencesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertOrderRecurrencesRelationInput"];
	["Contember_AllergenLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_AllergenLocaleUpsertLocaleRelationInput"];
	["Contember_AllergenUpsertLocalesRelationInput"]: GraphQLTypes["Contember_AllergenUpsertLocalesRelationInput"];
	["Contember_ProductIngredientUpsertAllergensRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertAllergensRelationInput"];
	["Contember_ProductIngredientLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_ProductIngredientLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertProductIngredientsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertProductIngredientsRelationInput"];
	["Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput"];
	["Contember_ProductIngredientCategoryUpsertLocalesRelationInput"]: GraphQLTypes["Contember_ProductIngredientCategoryUpsertLocalesRelationInput"];
	["Contember_ProductIngredientUpsertCategoriesRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertCategoriesRelationInput"];
	["Contember_CustomerIngredientRatingUpsertIngredientRelationInput"]: GraphQLTypes["Contember_CustomerIngredientRatingUpsertIngredientRelationInput"];
	["Contember_CustomerUpsertIngredientRatingsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertIngredientRatingsRelationInput"];
	["Contember_CustomerGroupUpsertCustomersRelationInput"]: GraphQLTypes["Contember_CustomerGroupUpsertCustomersRelationInput"];
	["Contember_ProductGroupPriceUpsertGroupRelationInput"]: GraphQLTypes["Contember_ProductGroupPriceUpsertGroupRelationInput"];
	["Contember_ProductUpsertGroupPricesRelationInput"]: GraphQLTypes["Contember_ProductUpsertGroupPricesRelationInput"];
	["Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput"];
	["Contember_TagUpsertDeliveryZonesRelationInput"]: GraphQLTypes["Contember_TagUpsertDeliveryZonesRelationInput"];
	["Contember_ChannelUpsertAddTagsOnRegistrationRelationInput"]: GraphQLTypes["Contember_ChannelUpsertAddTagsOnRegistrationRelationInput"];
	["Contember_CartUpsertChannelRelationInput"]: GraphQLTypes["Contember_CartUpsertChannelRelationInput"];
	["Contember_CartItemUpsertCartRelationInput"]: GraphQLTypes["Contember_CartItemUpsertCartRelationInput"];
	["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"]: GraphQLTypes["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"];
	["Contember_CartItemUpdateFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_CartItemUpdateFulfillmentNotesRelationInput"];
	["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"]: GraphQLTypes["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"];
	["Contember_CartItemUpsertFulfillmentNotesRelationInput"]: GraphQLTypes["Contember_CartItemUpsertFulfillmentNotesRelationInput"];
	["Contember_ProductUpsertCartItemsRelationInput"]: GraphQLTypes["Contember_ProductUpsertCartItemsRelationInput"];
	["Contember_ProductGroupPriceUpsertProductRelationInput"]: GraphQLTypes["Contember_ProductGroupPriceUpsertProductRelationInput"];
	["Contember_CustomerGroupUpsertProductPricesRelationInput"]: GraphQLTypes["Contember_CustomerGroupUpsertProductPricesRelationInput"];
	["Contember_CustomerUpsertGroupRelationInput"]: GraphQLTypes["Contember_CustomerUpsertGroupRelationInput"];
	["Contember_ChannelUpsertCustomersRelationInput"]: GraphQLTypes["Contember_ChannelUpsertCustomersRelationInput"];
	["Contember_TagUpsertAddTagsOnRegistrationRelationInput"]: GraphQLTypes["Contember_TagUpsertAddTagsOnRegistrationRelationInput"];
	["Contember_ProductCategoryUpsertTagsRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpsertTagsRelationInput"];
	["Contember_ProductCategoryUpsertParentRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpsertParentRelationInput"];
	["Contember_TagUpsertCategoriesRelationInput"]: GraphQLTypes["Contember_TagUpsertCategoriesRelationInput"];
	["Contember_ProductUpsertTagsRelationInput"]: GraphQLTypes["Contember_ProductUpsertTagsRelationInput"];
	["Contember_ProductCategoryUpsertProductsRelationInput"]: GraphQLTypes["Contember_ProductCategoryUpsertProductsRelationInput"];
	["Contember_ProductCategoryListItemUpsertProductCategoryRelationInput"]: GraphQLTypes["Contember_ProductCategoryListItemUpsertProductCategoryRelationInput"];
	["Contember_ProductCategoryListUpsertItemsRelationInput"]: GraphQLTypes["Contember_ProductCategoryListUpsertItemsRelationInput"];
	["Contember_ProductCategoryListLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_ProductCategoryListLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertProductCategoryListsRelationInput"]: GraphQLTypes["Contember_LocaleUpsertProductCategoryListsRelationInput"];
	["Contember_ProductIngredientLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_ProductIngredientLocaleUpsertLocaleRelationInput"];
	["Contember_ProductIngredientUpsertLocalesRelationInput"]: GraphQLTypes["Contember_ProductIngredientUpsertLocalesRelationInput"];
	["Contember_ProductIngredientItemUpsertIngredientRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemUpsertIngredientRelationInput"];
	["Contember_ProductIngredientItemUpdateQuantityEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemUpdateQuantityEntityRelationInput"];
	["Contember_ProductIngredientItemQuantityUpdateInput"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUpdateInput"];
	["Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput"];
	["Contember_ProductIngredientItemQuantityUpsertUnitRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemQuantityUpsertUnitRelationInput"];
	["Contember_ProductIngredientItemUpsertQuantityRelationInput"]: GraphQLTypes["Contember_ProductIngredientItemUpsertQuantityRelationInput"];
	["Contember_ProductRecipeUpsertIngredientsRelationInput"]: GraphQLTypes["Contember_ProductRecipeUpsertIngredientsRelationInput"];
	["Contember_ProductUpsertRecipeRelationInput"]: GraphQLTypes["Contember_ProductUpsertRecipeRelationInput"];
	["Contember_CartItemUpsertProductRelationInput"]: GraphQLTypes["Contember_CartItemUpsertProductRelationInput"];
	["Contember_CartUpsertItemsRelationInput"]: GraphQLTypes["Contember_CartUpsertItemsRelationInput"];
	["Contember_ChannelUpsertCartsRelationInput"]: GraphQLTypes["Contember_ChannelUpsertCartsRelationInput"];
	["Contember_OrderUpsertChannelRelationInput"]: GraphQLTypes["Contember_OrderUpsertChannelRelationInput"];
	["Contember_CustomerUpsertOrdersRelationInput"]: GraphQLTypes["Contember_CustomerUpsertOrdersRelationInput"];
	["Contember_TagUpsertCustomersRelationInput"]: GraphQLTypes["Contember_TagUpsertCustomersRelationInput"];
	["Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput"];
	["Contember_VatRateUpsertChannelPaymentsRelationInput"]: GraphQLTypes["Contember_VatRateUpsertChannelPaymentsRelationInput"];
	["Contember_ChannelDeliveryUpsertVatRateRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryUpsertVatRateRelationInput"];
	["Contember_DeliveryMethodUpsertChannelsRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpsertChannelsRelationInput"];
	["Contember_PaymentMethodUpsertDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_PaymentMethodUpsertDeliveryMethodsRelationInput"];
	["Contember_ChannelPaymentUpsertMethodRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpsertMethodRelationInput"];
	["Contember_ChannelUpsertPaymentsRelationInput"]: GraphQLTypes["Contember_ChannelUpsertPaymentsRelationInput"];
	["Contember_ChannelDeliveryUpsertChannelRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryUpsertChannelRelationInput"];
	["Contember_VatRateUpsertChannelDeliveriesRelationInput"]: GraphQLTypes["Contember_VatRateUpsertChannelDeliveriesRelationInput"];
	["Contember_CustomerCreditUpsertVatRateRelationInput"]: GraphQLTypes["Contember_CustomerCreditUpsertVatRateRelationInput"];
	["Contember_CustomerUpsertCreditsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertCreditsRelationInput"];
	["Contember_BusinessCustomerUpsertCustomerRelationInput"]: GraphQLTypes["Contember_BusinessCustomerUpsertCustomerRelationInput"];
	["Contember_BusinessCategoryUpsertBusinessCustomersRelationInput"]: GraphQLTypes["Contember_BusinessCategoryUpsertBusinessCustomersRelationInput"];
	["Contember_BusinessCategoryLocaleUpsertRootRelationInput"]: GraphQLTypes["Contember_BusinessCategoryLocaleUpsertRootRelationInput"];
	["Contember_LocaleUpsertBusinessCategoriesRelationInput"]: GraphQLTypes["Contember_LocaleUpsertBusinessCategoriesRelationInput"];
	["Contember_ProductPackingLocaleUpsertLocaleRelationInput"]: GraphQLTypes["Contember_ProductPackingLocaleUpsertLocaleRelationInput"];
	["Contember_ProductPackingUpsertLocalesRelationInput"]: GraphQLTypes["Contember_ProductPackingUpsertLocalesRelationInput"];
	["Contember_ProductUpsertPackingRelationInput"]: GraphQLTypes["Contember_ProductUpsertPackingRelationInput"];
	["Contember_TagUpsertProductsRelationInput"]: GraphQLTypes["Contember_TagUpsertProductsRelationInput"];
	["Contember_CustomerUpsertTagsRelationInput"]: GraphQLTypes["Contember_CustomerUpsertTagsRelationInput"];
	["Contember_InvoiceUpsertCustomerRelationInput"]: GraphQLTypes["Contember_InvoiceUpsertCustomerRelationInput"];
	["Contember_AddressUpsertInvoicesRelationInput"]: GraphQLTypes["Contember_AddressUpsertInvoicesRelationInput"];
	["Contember_DeliveryZoneUpsertAddressesRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpsertAddressesRelationInput"];
	["Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput"];
	["Contember_ChannelDeliveryUpsertMethodRelationInput"]: GraphQLTypes["Contember_ChannelDeliveryUpsertMethodRelationInput"];
	["Contember_ChannelUpsertDeliveriesRelationInput"]: GraphQLTypes["Contember_ChannelUpsertDeliveriesRelationInput"];
	["Contember_ChannelPaymentUpsertChannelRelationInput"]: GraphQLTypes["Contember_ChannelPaymentUpsertChannelRelationInput"];
	["Contember_PaymentMethodUpsertChannelsRelationInput"]: GraphQLTypes["Contember_PaymentMethodUpsertChannelsRelationInput"];
	["Contember_DeliveryMethodUpsertPaymentMethodsRelationInput"]: GraphQLTypes["Contember_DeliveryMethodUpsertPaymentMethodsRelationInput"];
	["Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput"]: GraphQLTypes["Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput"];
	["Contember_AddressUpsertDeliveryZoneRelationInput"]: GraphQLTypes["Contember_AddressUpsertDeliveryZoneRelationInput"];
	["Contember_CustomerUpsertAddressesRelationInput"]: GraphQLTypes["Contember_CustomerUpsertAddressesRelationInput"];
	["Contember_CustomerPaymentMethodUpsertCustomerRelationInput"]: GraphQLTypes["Contember_CustomerPaymentMethodUpsertCustomerRelationInput"];
	["Contember_CustomerUpsertDefaultPaymentMethodRelationInput"]: GraphQLTypes["Contember_CustomerUpsertDefaultPaymentMethodRelationInput"];
	["Contember_AddressUpsertCustomerRelationInput"]: GraphQLTypes["Contember_AddressUpsertCustomerRelationInput"];
	["Contember_CustomerUpdateInput"]: GraphQLTypes["Contember_CustomerUpdateInput"];
	["Contember_QueryTransaction"]: {
		getCustomer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_Info"]: {
		description?: string | undefined
};
	["Contember_MutationResult"]: ModelTypes["Contember_CustomerUpdateResult"];
	["Contember__MutationError"]: {
		path: Array<GraphQLTypes["Contember__PathFragment"]>,
	paths: Array<Array<GraphQLTypes["Contember__PathFragment"]>>,
	type: GraphQLTypes["Contember__MutationErrorType"],
	message?: string | undefined
};
	["Contember__MutationErrorType"]: GraphQLTypes["Contember__MutationErrorType"];
	["Contember_CustomerUpdateResult"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["Contember__MutationError"]>,
	node?: GraphQLTypes["Contember_Customer"] | undefined,
	validation: GraphQLTypes["Contember__ValidationResult"]
};
	["Contember_MutationTransaction"]: {
		updateCustomer: GraphQLTypes["Contember_CustomerUpdateResult"]
};
	["Contember_MutationTransactionOptions"]: GraphQLTypes["Contember_MutationTransactionOptions"]
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	getInfo?: GraphQLTypes["InfoResponse"] | undefined,
	getCart?: GraphQLTypes["Cart"] | undefined,
	getCartIssues?: GraphQLTypes["CartIssuesResponse"] | undefined,
	getCartDeliveryOptions?: GraphQLTypes["DeliveryOptionsResponse"] | undefined,
	getCartPaymentOptions?: GraphQLTypes["PaymentOptionsResponse"] | undefined,
	getPayment: GraphQLTypes["PaymentResponse"],
	getVoucher: GraphQLTypes["VoucherResponse"],
	getActiveOrder?: GraphQLTypes["OrderInfoResponse"] | undefined,
	listActiveOrder: Array<GraphQLTypes["OrderInfoResponse"]>,
	listCustomerToast: Array<GraphQLTypes["Toast"]>,
	getCustomer?: GraphQLTypes["Contember_Customer"] | undefined,
	transaction?: GraphQLTypes["Contember_QueryTransaction"] | undefined,
	_info?: GraphQLTypes["Contember_Info"] | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	createCart: GraphQLTypes["UpdateCartResponse"],
	copyCartItems: GraphQLTypes["UpdateCartResponse"],
	setItems: GraphQLTypes["UpdateCartResponse"],
	calculatePosCart: GraphQLTypes["PosCart"],
	deleteCart: GraphQLTypes["DeleteCartResponse"],
	addItemToCart: GraphQLTypes["UpdateCartResponse"],
	setItemQuantityInCart: GraphQLTypes["UpdateCartResponse"],
	setCartDeliveryOption: GraphQLTypes["SetCartDeliveryOptionResponse"],
	setCartPaymentOption: GraphQLTypes["SetCartPaymentOptionResponse"],
	confirmOrder: GraphQLTypes["ConfirmOrderResponse"],
	updateCustomerInfo: GraphQLTypes["UpdateCustomerInfoResponse"],
	addAddress: GraphQLTypes["AddAddressResponse"],
	editAddress: GraphQLTypes["EditAddressResponse"],
	action?: GraphQLTypes["ActionResponse"] | undefined,
	redeemVoucher: GraphQLTypes["RedeemVoucherResponse"],
	updateCustomer: GraphQLTypes["Contember_CustomerUpdateResult"],
	transaction: GraphQLTypes["Contember_MutationTransaction"]
};
	["InfoResponse"]: {
	__typename: "InfoResponse",
	soonestDeliveryOption?: GraphQLTypes["DeliveryOption"] | undefined
};
	["DeliveryOption"]: {
	__typename: "DeliveryOption",
	id: string,
	selected: boolean,
	delivery: GraphQLTypes["ChannelDelivery"],
	address?: GraphQLTypes["Address"] | undefined,
	timeline?: GraphQLTypes["DeliveryTimeline"] | undefined,
	timelineDescription: string,
	priceCents: number,
	vatRate: GraphQLTypes["VatRate"]
};
	["ChannelDelivery"]: {
	__typename: "ChannelDelivery",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["ChannelDeliveryMeta"] | undefined,
	order: number,
	priceCents: number,
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	defaultVisible: boolean
};
	["UUID"]: string;
	["Address"]: {
	__typename: "Address",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["AddressMeta"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_Country"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZone"] | undefined,
	meta?: GraphQLTypes["Contember_AddressMetadata"] | undefined,
	gps?: GraphQLTypes["Contember_Gps"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices: Array<GraphQLTypes["Contember_Invoice"]>,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders: Array<GraphQLTypes["Order"]>,
	deliveryAddressOfOrderDeliveries: Array<GraphQLTypes["OrderDelivery"]>,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_Customer"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Contember_Invoice"] | undefined,
	billingAddressOfOrdersBySeq?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDiscounts?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByDelivery?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByItems?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByCart?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByEvents?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPriceLines?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByPayments?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersBySummary?: GraphQLTypes["Order"] | undefined,
	billingAddressOfOrdersByGratuity?: GraphQLTypes["Order"] | undefined,
	deliveryAddressOfOrderDeliveriesByTrackingCode?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByOrder?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByDeliveryWaypoint?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByEvents?: GraphQLTypes["OrderDelivery"] | undefined,
	deliveryAddressOfOrderDeliveriesByLiftagoRide?: GraphQLTypes["OrderDelivery"] | undefined,
	paginateInvoices: GraphQLTypes["Contember_InvoiceConnection"],
	paginateBillingAddressOfOrders: GraphQLTypes["OrderConnection"],
	paginateDeliveryAddressOfOrderDeliveries: GraphQLTypes["OrderDeliveryConnection"]
};
	["DeliveryTimeline"]: {
	__typename: "DeliveryTimeline",
	type: GraphQLTypes["DeliveryTimelineType"],
	prepareAt: string,
	loadingHours?: number | undefined,
	dispatchAt: string,
	duration: string,
	expectedAt: string,
	window: string,
	expectedEndAt?: string | undefined
};
	["DeliveryTimelineType"]: DeliveryTimelineType;
	["VatRate"]: {
	__typename: "VatRate",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["VatRateMeta"] | undefined,
	name: string,
	ratePermille: number,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	channelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	paginateChannelDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginateChannelPayments: GraphQLTypes["ChannelPaymentConnection"]
};
	["ChannelCode"]: ChannelCode;
	["Cart"]: {
	__typename: "Cart",
	id: GraphQLTypes["UUID"],
	handle: string,
	itemsPriceCents: number,
	deliveryPriceCents: number,
	totalPriceCents: number,
	remainingPriceCents: number,
	itemsCount: number,
	items: Array<GraphQLTypes["CartItem"]>,
	customerId?: GraphQLTypes["UUID"] | undefined,
	channel: GraphQLTypes["ChannelCode"],
	order?: GraphQLTypes["Order"] | undefined,
	deliveryZones: Array<string>,
	deliveryOption?: GraphQLTypes["DeliveryOption"] | undefined,
	paymentOption?: GraphQLTypes["PaymentOption"] | undefined,
	checkoutUrl?: string | undefined
};
	["CartItem"]: {
	__typename: "CartItem",
	itemId: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	productId: GraphQLTypes["UUID"],
	productName: string,
	productCode: string,
	quantity: number,
	availableQuantity: number,
	unitPriceCents: number,
	priceCents: number,
	product: GraphQLTypes["Product"],
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	deliveryZones: Array<string>
};
	["DateTime"]: string;
	["Product"]: {
	__typename: "Product",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["ProductMeta"] | undefined,
	name: string,
	code: string,
	priceCents: number,
	notDiscountable: boolean,
	packing?: GraphQLTypes["Contember_ProductPacking"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	categories: Array<GraphQLTypes["Contember_ProductCategory"]>,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	stocks: Array<GraphQLTypes["Contember_ProductStock"]>,
	groupPrices: Array<GraphQLTypes["Contember_ProductGroupPrice"]>,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems: Array<GraphQLTypes["Contember_CartItem"]>,
	availability: GraphQLTypes["Contember_ProductAvailability"],
	order: number,
	manager?: GraphQLTypes["Contember_StocksManager"] | undefined,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories: Array<GraphQLTypes["Contember_NestedProductCategoryProduct"]>,
	deliveryZones: Array<GraphQLTypes["Contember_DeliveryZone"]>,
	locales: Array<GraphQLTypes["Contember_ProductLocale"]>,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices: Array<GraphQLTypes["Contember_ProductCustomerPrice"]>,
	virtualProduct?: GraphQLTypes["Contember_VirtualProduct"] | undefined,
	meta?: GraphQLTypes["Contember_ProductMetadata"] | undefined,
	page?: GraphQLTypes["Contember_Page"] | undefined,
	salesDays: Array<GraphQLTypes["Contember_ProductSalesDay"]>,
	salesWeeks: Array<GraphQLTypes["Contember_ProductSalesWeek"]>,
	salesMonths: Array<GraphQLTypes["Contember_ProductSalesMonth"]>,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	hasSubscription: boolean,
	pointOfSaleTileColor?: string | undefined,
	stocksByStore?: GraphQLTypes["Contember_ProductStock"] | undefined,
	groupPricesByGroup?: GraphQLTypes["Contember_ProductGroupPrice"] | undefined,
	cartItemsByCart?: GraphQLTypes["Contember_CartItem"] | undefined,
	orderItemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	localesByFeatureList?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	customerPricesByCustomer?: GraphQLTypes["Contember_ProductCustomerPrice"] | undefined,
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateCategories: GraphQLTypes["Contember_ProductCategoryConnection"],
	paginateStocks: GraphQLTypes["Contember_ProductStockConnection"],
	paginateGroupPrices: GraphQLTypes["Contember_ProductGroupPriceConnection"],
	paginateCartItems: GraphQLTypes["Contember_CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateInheritedCategories: GraphQLTypes["Contember_NestedProductCategoryProductConnection"],
	paginateDeliveryZones: GraphQLTypes["Contember_DeliveryZoneConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductLocaleConnection"],
	paginateCustomerPrices: GraphQLTypes["Contember_ProductCustomerPriceConnection"],
	paginateSalesDays: GraphQLTypes["Contember_ProductSalesDayConnection"],
	paginateSalesWeeks: GraphQLTypes["Contember_ProductSalesWeekConnection"],
	paginateSalesMonths: GraphQLTypes["Contember_ProductSalesMonthConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["Order"]: {
	__typename: "Order",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["OrderMeta"] | undefined,
	state: GraphQLTypes["Contember_OrderState"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	discounts: Array<GraphQLTypes["OrderDiscount"]>,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	items: Array<GraphQLTypes["OrderItem"]>,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_Cart"] | undefined,
	events: Array<GraphQLTypes["Contember_OrderEvent"]>,
	createdAt: GraphQLTypes["DateTime"],
	billingAddress?: GraphQLTypes["Address"] | undefined,
	priceLines: Array<GraphQLTypes["OrderPriceLine"]>,
	payments: Array<GraphQLTypes["OrderPayment"]>,
	seq: number,
	billingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	summary?: GraphQLTypes["OrderSummary"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_Gratuity"] | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	discountsByCustomerCreditTransactions?: GraphQLTypes["OrderDiscount"] | undefined,
	discountsByVoucherRedemption?: GraphQLTypes["OrderDiscount"] | undefined,
	itemsByVirtualProductEffects?: GraphQLTypes["OrderItem"] | undefined,
	priceLinesByOrderItem?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDiscount?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderPayment?: GraphQLTypes["OrderPriceLine"] | undefined,
	priceLinesByOrderDelivery?: GraphQLTypes["OrderPriceLine"] | undefined,
	paymentsByEvents?: GraphQLTypes["OrderPayment"] | undefined,
	paymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateDiscounts: GraphQLTypes["OrderDiscountConnection"],
	paginateItems: GraphQLTypes["OrderItemConnection"],
	paginateEvents: GraphQLTypes["Contember_OrderEventConnection"],
	paginatePriceLines: GraphQLTypes["OrderPriceLineConnection"],
	paginatePayments: GraphQLTypes["OrderPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["PaymentOption"]: {
	__typename: "PaymentOption",
	id: string,
	payment: GraphQLTypes["ChannelPayment"],
	creditsCents: number,
	priceCents: number,
	vatRate: GraphQLTypes["VatRate"],
	infoAvailableCredits?: number | undefined
};
	["ChannelPayment"]: {
	__typename: "ChannelPayment",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["ChannelPaymentMeta"] | undefined,
	order: number,
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags: Array<GraphQLTypes["Contember_Tag"]>,
	paginateAllowOnlyForTags: GraphQLTypes["Contember_TagConnection"]
};
	["CartIssuesResponse"]: {
	__typename: "CartIssuesResponse",
	ok: boolean,
	issues: Array<GraphQLTypes["CartIssue"]>
};
	["CartIssue"]: {
	__typename: "CartIssue",
	scope: string,
	code: string,
	message: string
};
	["DeliveryOptionsResponse"]: {
	__typename: "DeliveryOptionsResponse",
	methods: Array<GraphQLTypes["DeliveryMethodOption"]>,
	addresses: Array<GraphQLTypes["DeliveryAddressAddress"]>,
	selected?: GraphQLTypes["DeliveryOption"] | undefined
};
	["DeliveryMethodOption"]: {
	__typename: "DeliveryMethodOption",
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	selected: boolean,
	delivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	priceCents: number,
	vatRate: GraphQLTypes["VatRate"],
	options: Array<GraphQLTypes["DeliveryOption"]>
};
	["DeliveryAddressAddress"]: {
	__typename: "DeliveryAddressAddress",
	id: GraphQLTypes["UUID"],
	selected: boolean,
	address: GraphQLTypes["Address"],
	methods: Array<GraphQLTypes["DeliveryMethodOption"]>
};
	["Date"]: any;
	["PaymentOptionsResponse"]: {
	__typename: "PaymentOptionsResponse",
	availableCreditsCents: number,
	credits: Array<GraphQLTypes["CustomerCredit"] | undefined>,
	options: Array<GraphQLTypes["PaymentOption"]>
};
	["CustomerCredit"]: {
	__typename: "CustomerCredit",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["CustomerCreditMeta"] | undefined,
	initialCreditCents: number,
	remainingCreditsCents: number,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	transactions: Array<GraphQLTypes["Contember_CustomerCreditTransaction"]>,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	invoiceUrl?: string | undefined,
	transactionsByVirtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransaction"] | undefined,
	paginateTransactions: GraphQLTypes["Contember_CustomerCreditTransactionConnection"]
};
	["PaymentResponse"]: {
	__typename: "PaymentResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	payment?: GraphQLTypes["OrderPaymentInfo"] | undefined
};
	["OrderPaymentInfo"]: {
	__typename: "OrderPaymentInfo",
	id: GraphQLTypes["UUID"]
};
	["VoucherResponse"]: {
	__typename: "VoucherResponse",
	redeemable: boolean,
	expiredAt?: GraphQLTypes["DateTime"] | undefined,
	redeemedAt?: GraphQLTypes["DateTime"] | undefined,
	creditCents?: number | undefined,
	type?: string | undefined,
	note?: string | undefined
};
	["OrderInfoResponse"]: {
	__typename: "OrderInfoResponse",
	order: GraphQLTypes["Order"],
	timelineDescription?: string | undefined
};
	["Toast"]: {
	__typename: "Toast",
	key: string,
	title: string,
	text?: string | undefined,
	icon?: GraphQLTypes["ToastIcon"] | undefined,
	detail?: GraphQLTypes["ToastDetail"] | undefined,
	button?: GraphQLTypes["ToastButton"] | undefined,
	rating?: Array<GraphQLTypes["ToastRating"]> | undefined
};
	["ToastIcon"]: {
	__typename: "ToastIcon",
	url: string
};
	["ToastDetail"]: {
	__typename: "ToastDetail",
	order?: GraphQLTypes["Order"] | undefined
};
	["ToastButton"]: {
	__typename: "ToastButton",
	caption: string,
	url?: string | undefined,
	iosUrl?: string | undefined,
	androidUrl?: string | undefined,
	webview: boolean
};
	["ToastRating"]: {
	__typename: "ToastRating",
	value: GraphQLTypes["ToastRatingValue"],
	url?: string | undefined,
	iosUrl?: string | undefined,
	androidUrl?: string | undefined
};
	["ToastRatingValue"]: ToastRatingValue;
	["UpdateCartResponse"]: {
	__typename: "UpdateCartResponse",
	ok: boolean,
	errorCode?: string | undefined,
	cart?: GraphQLTypes["Cart"] | undefined
};
	["CartItemInput"]: {
		productId: GraphQLTypes["UUID"],
	quantity: number
};
	["PosCart"]: {
	__typename: "PosCart",
	customerId?: GraphQLTypes["UUID"] | undefined,
	items: Array<GraphQLTypes["PosItem"]>,
	totalPriceCents: number
};
	["PosItem"]: {
	__typename: "PosItem",
	productId: GraphQLTypes["UUID"],
	quantity: number,
	unitPriceCents: number,
	priceCents: number
};
	["PosCartInput"]: {
		customerId?: GraphQLTypes["UUID"] | undefined,
	items: Array<GraphQLTypes["PosItemInput"]>
};
	["PosItemInput"]: {
		productId: GraphQLTypes["UUID"],
	quantity: number
};
	["DeleteCartResponse"]: {
	__typename: "DeleteCartResponse",
	ok: boolean,
	errorCode?: string | undefined
};
	["SetCartDeliveryOptionResponse"]: {
	__typename: "SetCartDeliveryOptionResponse",
	ok: boolean,
	errorCode?: string | undefined
};
	["SetCartPaymentOptionResponse"]: {
	__typename: "SetCartPaymentOptionResponse",
	ok: boolean,
	errorCode?: string | undefined
};
	["ConfirmOrderResponse"]: {
	__typename: "ConfirmOrderResponse",
	ok: boolean,
	errorCode?: string | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	waitingForPayment?: GraphQLTypes["OrderPayment"] | undefined
};
	["OrderPayment"]: {
	__typename: "OrderPayment",
	id: GraphQLTypes["UUID"],
	_meta?: GraphQLTypes["OrderPaymentMeta"] | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents: number,
	method?: GraphQLTypes["PaymentMethod"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["Contember_OrderPaymentState"],
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	events: Array<GraphQLTypes["Contember_OrderEvent"]>,
	order?: GraphQLTypes["Order"] | undefined,
	invoice?: GraphQLTypes["Contember_Invoice"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentMetadata"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	paginateEvents: GraphQLTypes["Contember_OrderEventConnection"]
};
	["PosFulfillment"]: {
		paidAt?: GraphQLTypes["DateTime"] | undefined,
	paymentMethodCode: string,
	paymentCents: number,
	gratuityCents?: number | undefined,
	deviceIdentifier?: string | undefined,
	items?: Array<GraphQLTypes["PosItemInput"]> | undefined
};
	["UpdateCustomerInfoResponse"]: {
	__typename: "UpdateCustomerInfoResponse",
	ok: boolean,
	errorCode?: string | undefined,
	errorMessage?: string | undefined
};
	["AddAddressResponse"]: {
	__typename: "AddAddressResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	node?: GraphQLTypes["Address"] | undefined
};
	["AddressData"]: {
		raw: string,
	name?: string | undefined,
	email?: string | undefined,
	fullName?: string | undefined,
	phone?: string | undefined,
	note?: string | undefined,
	setIsDefaultDeliveryAddress?: boolean | undefined
};
	["EditAddressResponse"]: {
	__typename: "EditAddressResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	node?: GraphQLTypes["Address"] | undefined
};
	["ActionResponse"]: {
	__typename: "ActionResponse",
	ok: boolean,
	data?: string | undefined,
	errorMessage?: string | undefined
};
	["RedeemVoucherResponse"]: {
	__typename: "RedeemVoucherResponse",
	ok: boolean,
	message?: string | undefined,
	errorMessage?: string | undefined,
	expiredAt?: GraphQLTypes["DateTime"] | undefined,
	redeemedAt?: GraphQLTypes["DateTime"] | undefined,
	creditCents?: number | undefined,
	type?: string | undefined,
	note?: string | undefined
};
	["AddressMeta"]: {
	__typename: "AddressMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	firstName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lastName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hidden?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	country?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	googlePlaceId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	raw?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	formatted?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	geocodeResponse?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gps?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	geocodeValid?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fullName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	city?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FieldMeta"]: {
	__typename: "Contember_FieldMeta",
	readable?: boolean | undefined,
	updatable?: boolean | undefined
};
	["Contember_Customer"]: {
	__typename: "Contember_Customer",
	_meta?: GraphQLTypes["Contember_CustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addresses: Array<GraphQLTypes["Address"]>,
	defaultBillingAddress?: GraphQLTypes["Address"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethod"] | undefined,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	credits: Array<GraphQLTypes["CustomerCredit"]>,
	paymentMethods: Array<GraphQLTypes["Contember_CustomerPaymentMethod"]>,
	orders: Array<GraphQLTypes["Order"]>,
	permissions?: GraphQLTypes["Contember_CustomerPermissions"] | undefined,
	group?: GraphQLTypes["Contember_CustomerGroup"] | undefined,
	photo?: GraphQLTypes["Contember_Image"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerName"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings: Array<GraphQLTypes["Contember_CustomerIngredientRating"]>,
	business?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	ownerOfBusinesses: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	memberOfBusinesses: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	carts: Array<GraphQLTypes["Contember_Cart"]>,
	shortcutList?: GraphQLTypes["Contember_ApplicationShortcutList"] | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined,
	channels: Array<GraphQLTypes["Contember_Channel"]>,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices: Array<GraphQLTypes["Contember_Invoice"]>,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlan"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects: Array<GraphQLTypes["Contember_BillingSubject"]>,
	offeredProducts: Array<GraphQLTypes["Contember_ProductCustomerPrice"]>,
	defaultDeliveryAddress?: GraphQLTypes["Address"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	prefersPackingsWithoutCardboard: boolean,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSession"] | undefined,
	boughtVouchers: Array<GraphQLTypes["Contember_Voucher"]>,
	ownsVouchers: Array<GraphQLTypes["Contember_Voucher"]>,
	redeemedVouchers: Array<GraphQLTypes["Contember_VoucherRedemption"]>,
	spending?: GraphQLTypes["Contember_CustomerSpending"] | undefined,
	customerTabs: Array<GraphQLTypes["Contember_CustomerTab"]>,
	lead: Array<GraphQLTypes["Contember_PipelineLead"]>,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerMetadata"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	creditsByTransactions?: GraphQLTypes["CustomerCredit"] | undefined,
	creditsByVoucherRedemption?: GraphQLTypes["CustomerCredit"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByEvents?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ordersByGratuity?: GraphQLTypes["Order"] | undefined,
	ingredientRatingsByIngredient?: GraphQLTypes["Contember_CustomerIngredientRating"] | undefined,
	cartsByItems?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Contember_Cart"] | undefined,
	invoicesByPublicKey?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Contember_Invoice"] | undefined,
	billingSubjectsByInvoices?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	billingSubjectsByDefaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	billingSubjectsByOrders?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	offeredProductsByProduct?: GraphQLTypes["Contember_ProductCustomerPrice"] | undefined,
	boughtVouchersByCode?: GraphQLTypes["Contember_Voucher"] | undefined,
	boughtVouchersByRedemption?: GraphQLTypes["Contember_Voucher"] | undefined,
	ownsVouchersByCode?: GraphQLTypes["Contember_Voucher"] | undefined,
	ownsVouchersByRedemption?: GraphQLTypes["Contember_Voucher"] | undefined,
	redeemedVouchersByCustomerCredit?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	redeemedVouchersByOrderDiscount?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	redeemedVouchersByVoucher?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	customerTabsByItems?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	customerTabsByMeta?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	leadByStageItem?: GraphQLTypes["Contember_PipelineLead"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateCredits: GraphQLTypes["CustomerCreditConnection"],
	paginatePaymentMethods: GraphQLTypes["Contember_CustomerPaymentMethodConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateIngredientRatings: GraphQLTypes["Contember_CustomerIngredientRatingConnection"],
	paginateOwnerOfBusinesses: GraphQLTypes["Contember_BusinessCustomerConnection"],
	paginateMemberOfBusinesses: GraphQLTypes["Contember_BusinessCustomerConnection"],
	paginateCarts: GraphQLTypes["Contember_CartConnection"],
	paginateChannels: GraphQLTypes["Contember_ChannelConnection"],
	paginateInvoices: GraphQLTypes["Contember_InvoiceConnection"],
	paginateBillingSubjects: GraphQLTypes["Contember_BillingSubjectConnection"],
	paginateOfferedProducts: GraphQLTypes["Contember_ProductCustomerPriceConnection"],
	paginateBoughtVouchers: GraphQLTypes["Contember_VoucherConnection"],
	paginateOwnsVouchers: GraphQLTypes["Contember_VoucherConnection"],
	paginateRedeemedVouchers: GraphQLTypes["Contember_VoucherRedemptionConnection"],
	paginateCustomerTabs: GraphQLTypes["Contember_CustomerTabConnection"],
	paginateLead: GraphQLTypes["Contember_PipelineLeadConnection"]
};
	["Contember_CustomerMeta"]: {
	__typename: "Contember_CustomerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addresses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	credits?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	permissions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	group?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	photo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	welcomeMessage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	familiarity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredientRatings?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	business?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ownerOfBusinesses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	memberOfBusinesses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shortcutList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubjects?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offeredProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	boughtVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ownsVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	redeemedVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	spending?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTabs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AddressWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	firstName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	lastName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	addressLine2?: GraphQLTypes["Contember_StringCondition"] | undefined,
	email?: GraphQLTypes["Contember_StringCondition"] | undefined,
	phone?: GraphQLTypes["Contember_StringCondition"] | undefined,
	companyName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	hidden?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	country?: GraphQLTypes["Contember_CountryWhere"] | undefined,
	deletedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	googlePlaceId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	raw?: GraphQLTypes["Contember_StringCondition"] | undefined,
	formatted?: GraphQLTypes["Contember_StringCondition"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	geocodeResponse?: GraphQLTypes["Contember_StringCondition"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined,
	meta?: GraphQLTypes["Contember_AddressMetadataWhere"] | undefined,
	gps?: GraphQLTypes["Contember_GpsWhere"] | undefined,
	geocodeValid?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	fullName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	invoices?: GraphQLTypes["Contember_InvoiceWhere"] | undefined,
	addressLine1?: GraphQLTypes["Contember_StringCondition"] | undefined,
	city?: GraphQLTypes["Contember_StringCondition"] | undefined,
	postalCode?: GraphQLTypes["Contember_StringCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_AddressWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_AddressWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_AddressWhere"] | undefined
};
	["Contember_UUIDCondition"]: {
		and?: Array<GraphQLTypes["Contember_UUIDCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_UUIDCondition"]> | undefined,
	not?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["UUID"] | undefined,
	notEq?: GraphQLTypes["UUID"] | undefined,
	in?: Array<GraphQLTypes["UUID"]> | undefined,
	notIn?: Array<GraphQLTypes["UUID"]> | undefined,
	lt?: GraphQLTypes["UUID"] | undefined,
	lte?: GraphQLTypes["UUID"] | undefined,
	gt?: GraphQLTypes["UUID"] | undefined,
	gte?: GraphQLTypes["UUID"] | undefined
};
	["Contember_StringCondition"]: {
		and?: Array<GraphQLTypes["Contember_StringCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_StringCondition"]> | undefined,
	not?: GraphQLTypes["Contember_StringCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: string | undefined,
	notEq?: string | undefined,
	in?: Array<string> | undefined,
	notIn?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	contains?: string | undefined,
	startsWith?: string | undefined,
	endsWith?: string | undefined,
	containsCI?: string | undefined,
	startsWithCI?: string | undefined,
	endsWithCI?: string | undefined
};
	["Contember_BooleanCondition"]: {
		and?: Array<GraphQLTypes["Contember_BooleanCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_BooleanCondition"]> | undefined,
	not?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: boolean | undefined,
	notEq?: boolean | undefined,
	in?: Array<boolean> | undefined,
	notIn?: Array<boolean> | undefined,
	lt?: boolean | undefined,
	lte?: boolean | undefined,
	gt?: boolean | undefined,
	gte?: boolean | undefined
};
	["Contember_CustomerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	addresses?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodWhere"] | undefined,
	tags?: GraphQLTypes["Contember_TagWhere"] | undefined,
	credits?: GraphQLTypes["Contember_CustomerCreditWhere"] | undefined,
	paymentMethods?: GraphQLTypes["Contember_CustomerPaymentMethodWhere"] | undefined,
	orders?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	permissions?: GraphQLTypes["Contember_CustomerPermissionsWhere"] | undefined,
	group?: GraphQLTypes["Contember_CustomerGroupWhere"] | undefined,
	photo?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	phone?: GraphQLTypes["Contember_StringCondition"] | undefined,
	name?: GraphQLTypes["Contember_CustomerNameWhere"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	welcomeMessage?: GraphQLTypes["Contember_StringCondition"] | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarityCondition"] | undefined,
	ingredientRatings?: GraphQLTypes["Contember_CustomerIngredientRatingWhere"] | undefined,
	business?: GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined,
	ownerOfBusinesses?: GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined,
	memberOfBusinesses?: GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined,
	carts?: GraphQLTypes["Contember_CartWhere"] | undefined,
	shortcutList?: GraphQLTypes["Contember_ApplicationShortcutListWhere"] | undefined,
	user?: GraphQLTypes["Contember_UserWhere"] | undefined,
	channels?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	disabledAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	invoices?: GraphQLTypes["Contember_InvoiceWhere"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlanWhere"] | undefined,
	email?: GraphQLTypes["Contember_StringCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	billingSubjects?: GraphQLTypes["Contember_BillingSubjectWhere"] | undefined,
	offeredProducts?: GraphQLTypes["Contember_ProductCustomerPriceWhere"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_BillingSubjectWhere"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSessionWhere"] | undefined,
	boughtVouchers?: GraphQLTypes["Contember_VoucherWhere"] | undefined,
	ownsVouchers?: GraphQLTypes["Contember_VoucherWhere"] | undefined,
	redeemedVouchers?: GraphQLTypes["Contember_VoucherRedemptionWhere"] | undefined,
	spending?: GraphQLTypes["Contember_CustomerSpendingWhere"] | undefined,
	customerTabs?: GraphQLTypes["Contember_CustomerTabWhere"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineLeadWhere"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	meta?: GraphQLTypes["Contember_CustomerMetadataWhere"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerWhere"] | undefined
};
	["Contember_CustomerPaymentMethodWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	caption?: GraphQLTypes["Contember_StringCondition"] | undefined,
	data?: GraphQLTypes["Contember_JsonCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_PaymentMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerPaymentMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerPaymentMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerPaymentMethodWhere"] | undefined
};
	["Contember_JsonCondition"]: {
		and?: Array<GraphQLTypes["Contember_JsonCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_JsonCondition"]> | undefined,
	not?: GraphQLTypes["Contember_JsonCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined
};
	["Contember_PaymentMethodWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	enabled?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	blockingDispatch?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	type?: GraphQLTypes["Contember_PaymentMethodTypeCondition"] | undefined,
	channels?: GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PaymentMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PaymentMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PaymentMethodWhere"] | undefined
};
	["Contember_DeliveryMethodWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	enabled?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	paymentMethods?: GraphQLTypes["Contember_PaymentMethodWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined,
	channels?: GraphQLTypes["Contember_ChannelDeliveryWhere"] | undefined,
	courierHandlers?: GraphQLTypes["Contember_CourierWhere"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	locales?: GraphQLTypes["Contember_DeliveryMethodLocaleWhere"] | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryTimelinePresetWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined
};
	["Contember_DeliveryZoneWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneTypeCondition"] | undefined,
	addresses?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["Contember_TagWhere"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	addressesMetadata?: GraphQLTypes["Contember_AddressMetadataWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined
};
	["Contember_DeliveryZoneTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_DeliveryZoneTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryZoneTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_DeliveryZoneTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	notEq?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	in?: Array<GraphQLTypes["Contember_DeliveryZoneType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_DeliveryZoneType"]> | undefined,
	lt?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	lte?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	gt?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	gte?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined
};
	["Contember_DeliveryZoneType"]: Contember_DeliveryZoneType;
	["Contember_TagWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customers?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	products?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	categories?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	nestedCategories?: GraphQLTypes["Contember_InheritedProductCategoryTagWhere"] | undefined,
	deliveryZones?: GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined,
	publicInChannels?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	allowChannelPayments?: GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TagWhere"] | undefined
};
	["Contember_ProductWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	priceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	packing?: GraphQLTypes["Contember_ProductPackingWhere"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipeWhere"] | undefined,
	tags?: GraphQLTypes["Contember_TagWhere"] | undefined,
	categories?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	stocks?: GraphQLTypes["Contember_ProductStockWhere"] | undefined,
	groupPrices?: GraphQLTypes["Contember_ProductGroupPriceWhere"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	cartItems?: GraphQLTypes["Contember_CartItemWhere"] | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailabilityCondition"] | undefined,
	order?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	manager?: GraphQLTypes["Contember_StocksManagerWhere"] | undefined,
	orderItems?: GraphQLTypes["Contember_OrderItemWhere"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["Contember_IntCondition"] | undefined,
	inheritedCategories?: GraphQLTypes["Contember_NestedProductCategoryProductWhere"] | undefined,
	deliveryZones?: GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined,
	locales?: GraphQLTypes["Contember_ProductLocaleWhere"] | undefined,
	archivedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	customerPrices?: GraphQLTypes["Contember_ProductCustomerPriceWhere"] | undefined,
	virtualProduct?: GraphQLTypes["Contember_VirtualProductWhere"] | undefined,
	meta?: GraphQLTypes["Contember_ProductMetadataWhere"] | undefined,
	page?: GraphQLTypes["Contember_PageWhere"] | undefined,
	salesDays?: GraphQLTypes["Contember_ProductSalesDayWhere"] | undefined,
	salesWeeks?: GraphQLTypes["Contember_ProductSalesWeekWhere"] | undefined,
	salesMonths?: GraphQLTypes["Contember_ProductSalesMonthWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined,
	hasSubscription?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductWhere"] | undefined
};
	["Contember_IntCondition"]: {
		and?: Array<GraphQLTypes["Contember_IntCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_IntCondition"]> | undefined,
	not?: GraphQLTypes["Contember_IntCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["Contember_ProductPackingWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	volumeMl?: GraphQLTypes["Contember_IntCondition"] | undefined,
	icon?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	shortName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	locales?: GraphQLTypes["Contember_ProductPackingLocaleWhere"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["Contember_PreparedPackingQuantityWhere"] | undefined,
	isPackedInCardboard?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductPackingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductPackingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductPackingWhere"] | undefined
};
	["Contember_ImageWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	url?: GraphQLTypes["Contember_StringCondition"] | undefined,
	width?: GraphQLTypes["Contember_IntCondition"] | undefined,
	height?: GraphQLTypes["Contember_IntCondition"] | undefined,
	size?: GraphQLTypes["Contember_IntCondition"] | undefined,
	type?: GraphQLTypes["Contember_StringCondition"] | undefined,
	alt?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ImageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ImageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ImageWhere"] | undefined
};
	["Contember_ProductPackingLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	shortName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_ProductPackingWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductPackingLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductPackingLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductPackingLocaleWhere"] | undefined
};
	["Contember_LocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	businessCategories?: GraphQLTypes["Contember_BusinessCategoryLocaleWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["Contember_ProductCategoryListLocaleWhere"] | undefined,
	productIngredients?: GraphQLTypes["Contember_ProductIngredientLocaleWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined,
	blogs?: GraphQLTypes["Contember_BlogLocaleWhere"] | undefined,
	blogPosts?: GraphQLTypes["Contember_BlogPostLocaleWhere"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined,
	menus?: GraphQLTypes["Contember_MenuLocaleWhere"] | undefined,
	pages?: GraphQLTypes["Contember_PageLocaleWhere"] | undefined,
	productIngredientCategories?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined,
	allergens?: GraphQLTypes["Contember_AllergenLocaleWhere"] | undefined,
	productPackings?: GraphQLTypes["Contember_ProductPackingLocaleWhere"] | undefined,
	products?: GraphQLTypes["Contember_ProductLocaleWhere"] | undefined,
	productRecipes?: GraphQLTypes["Contember_ProductRecipeLocaleWhere"] | undefined,
	pointOfSaleCategories?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined,
	pointOfSaleCategoryProducts?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_DeliveryMethodLocaleWhere"] | undefined,
	ingredientSuppliers?: GraphQLTypes["Contember_IngredientSupplierLocaleWhere"] | undefined,
	identificationCodes?: GraphQLTypes["Contember_IdentificationCodeLocaleWhere"] | undefined,
	pipelineStages?: GraphQLTypes["Contember_PipelineStageLocaleWhere"] | undefined,
	subscriptionBoxes?: GraphQLTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined,
	businessServices?: GraphQLTypes["Contember_BusinessServiceLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LocaleWhere"] | undefined
};
	["Contember_BusinessCategoryLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_BusinessCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BusinessCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BusinessCategoryLocaleWhere"] | undefined
};
	["Contember_BusinessCategoryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	locales?: GraphQLTypes["Contember_BusinessCategoryLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	businessCustomers?: GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined,
	recurrences?: GraphQLTypes["Contember_OrderRecurrenceWhere"] | undefined,
	specificProducts?: GraphQLTypes["Contember_ProductListWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_ProductCategoryListWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_ProductCategoryListWhere"] | undefined,
	slug?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined
};
	["Contember_BusinessCustomerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	owners?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	members?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	category?: GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessToBusinessManagerWhere"] | undefined,
	logo?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	businessServiceEvents?: GraphQLTypes["Contember_BusinessServiceEventWhere"] | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined
};
	["Contember_BusinessToBusinessManagerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	managedBusinesses?: GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_BusinessToBusinessManagerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BusinessToBusinessManagerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BusinessToBusinessManagerWhere"] | undefined
};
	["Contember_StaffWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	bartender?: GraphQLTypes["Contember_BartenderWhere"] | undefined,
	courier?: GraphQLTypes["Contember_CourierWhere"] | undefined,
	cleaner?: GraphQLTypes["Contember_CleanerWhere"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_ShiftsManagerWhere"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_BusinessToBusinessManagerWhere"] | undefined,
	user?: GraphQLTypes["Contember_UserWhere"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StocksManagerWhere"] | undefined,
	photo?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	courierManager?: GraphQLTypes["Contember_CourierManagerWhere"] | undefined,
	picker?: GraphQLTypes["Contember_PickerWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_RemunerationProfileWhere"] | undefined,
	cashier?: GraphQLTypes["Contember_CashierWhere"] | undefined,
	venues?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	internalBuyer?: GraphQLTypes["Contember_InternalBuyerWhere"] | undefined,
	salesManager?: GraphQLTypes["Contember_SalesManagerWhere"] | undefined,
	cashierManager?: GraphQLTypes["Contember_CashierManagerWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_StaffWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_StaffWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_StaffWhere"] | undefined
};
	["Contember_BartenderWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_BartenderWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BartenderWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BartenderWhere"] | undefined
};
	["Contember_DateTimeCondition"]: {
		and?: Array<GraphQLTypes["Contember_DateTimeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_DateTimeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["DateTime"] | undefined,
	notEq?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_CourierWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	routes?: GraphQLTypes["Contember_DeliveryRouteWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined,
	showInPicker?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CourierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CourierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CourierWhere"] | undefined
};
	["Contember_DeliveryRouteWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	courier?: GraphQLTypes["Contember_CourierWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	waypoints?: GraphQLTypes["Contember_DeliveryWaypointWhere"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryRouteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryRouteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryRouteWhere"] | undefined
};
	["Contember_DeliveryWaypointWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	estimatedArrival?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	route?: GraphQLTypes["Contember_DeliveryRouteWhere"] | undefined,
	sortingOrder?: GraphQLTypes["Contember_IntCondition"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined,
	estimatedDistance?: GraphQLTypes["Contember_IntCondition"] | undefined,
	estimatedDuration?: GraphQLTypes["Contember_IntCondition"] | undefined,
	originDeparture?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	originAddress?: GraphQLTypes["Contember_StringCondition"] | undefined,
	transitMode?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryWaypointWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryWaypointWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryWaypointWhere"] | undefined
};
	["Contember_OrderDeliveryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	priceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	deliveredAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	method?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	expectedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryStateCondition"] | undefined,
	loadingDurationHours?: GraphQLTypes["Contember_IntCondition"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	duration?: GraphQLTypes["Contember_StringCondition"] | undefined,
	window?: GraphQLTypes["Contember_StringCondition"] | undefined,
	expectedEndAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	processingAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	deliveringAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	failedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_DeliveryWaypointWhere"] | undefined,
	events?: GraphQLTypes["Contember_OrderEventWhere"] | undefined,
	prepareAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_LiftagoRideWhere"] | undefined,
	pickedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	address?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	trackingCode?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined
};
	["Contember_VatRateWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ratePermille?: GraphQLTypes["Contember_IntCondition"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	channelDeliveries?: GraphQLTypes["Contember_ChannelDeliveryWhere"] | undefined,
	channelPayments?: GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined,
	preset?: GraphQLTypes["Contember_VatRatePresetCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_VatRateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VatRateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VatRateWhere"] | undefined
};
	["Contember_ChannelDeliveryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	priceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	method?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	defaultVisible?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ChannelDeliveryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ChannelDeliveryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ChannelDeliveryWhere"] | undefined
};
	["Contember_ChannelWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	disabled?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	payments?: GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined,
	deliveries?: GraphQLTypes["Contember_ChannelDeliveryWhere"] | undefined,
	pointOfSales?: GraphQLTypes["Contember_PointOfSaleWhere"] | undefined,
	carts?: GraphQLTypes["Contember_CartWhere"] | undefined,
	customers?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	code?: GraphQLTypes["Contember_ChannelCodeCondition"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["Contember_TagWhere"] | undefined,
	publicTags?: GraphQLTypes["Contember_TagWhere"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ChannelWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ChannelWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ChannelWhere"] | undefined
};
	["Contember_ChannelPaymentWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	method?: GraphQLTypes["Contember_PaymentMethodWhere"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	requireBillingAddress?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	provideReceipt?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	requireBillingSubject?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	allowOnlyForTags?: GraphQLTypes["Contember_TagWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined
};
	["Contember_PointOfSaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	channels?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	permissions?: GraphQLTypes["Contember_PointOfSalePermissionsWhere"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleWhere"] | undefined
};
	["Contember_PointOfSalePermissionsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	pointOfSale?: GraphQLTypes["Contember_PointOfSaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSalePermissionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSalePermissionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSalePermissionsWhere"] | undefined
};
	["Contember_CartWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	confirmedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	deletedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	items?: GraphQLTypes["Contember_CartItemWhere"] | undefined,
	handle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	state?: GraphQLTypes["Contember_CartStateCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	billingAddress?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	deliveryOption?: GraphQLTypes["Contember_StringCondition"] | undefined,
	paymentOption?: GraphQLTypes["Contember_StringCondition"] | undefined,
	delivery?: GraphQLTypes["Contember_ChannelDeliveryWhere"] | undefined,
	payment?: GraphQLTypes["Contember_ChannelPaymentWhere"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubjectWhere"] | undefined,
	priceLines?: GraphQLTypes["Contember_CartPriceLineWhere"] | undefined,
	summary?: GraphQLTypes["Contember_CartSummaryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CartWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CartWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CartWhere"] | undefined
};
	["Contember_CartItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	quantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	cart?: GraphQLTypes["Contember_CartWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	updatedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CartItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CartItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CartItemWhere"] | undefined
};
	["Contember_FulfillmentNoteWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	products?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	tags?: GraphQLTypes["Contember_TagWhere"] | undefined,
	venues?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	cartItems?: GraphQLTypes["Contember_CartItemWhere"] | undefined,
	orderItems?: GraphQLTypes["Contember_OrderItemWhere"] | undefined,
	orders?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	highlight?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	emoji?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined
};
	["Contember_VenueWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_DeadlineTemplateWhere"] | undefined,
	timetableDays?: GraphQLTypes["Contember_TimetableDayWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined,
	staffMembers?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	employeeRoles?: GraphQLTypes["Contember_VenueEmployeeRoleWhere"] | undefined,
	pointOfSaleApplicationDevices?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined,
	pointOfSaleTerminals?: GraphQLTypes["Contember_PointOfSaleTerminalWhere"] | undefined,
	pointOfSalePrinters?: GraphQLTypes["Contember_PointOfSalePrinterWhere"] | undefined,
	orders?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined,
	areas?: GraphQLTypes["Contember_AreaWhere"] | undefined,
	enabledUnassignReasons?: GraphQLTypes["Contember_UnassignReasonWhere"] | undefined,
	categories?: GraphQLTypes["Contember_PointOfSaleCategoryWhere"] | undefined,
	channels?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	address?: GraphQLTypes["Contember_StringCondition"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["Contember_StringCondition"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialViewCondition"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_VenueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VenueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VenueWhere"] | undefined
};
	["Contember_DeadlineTemplateWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	startDate?: GraphQLTypes["Contember_DateCondition"] | undefined,
	period?: GraphQLTypes["Contember_IntCondition"] | undefined,
	cutoff?: GraphQLTypes["Contember_IntCondition"] | undefined,
	closed?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeadlineTemplateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeadlineTemplateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeadlineTemplateWhere"] | undefined
};
	["Contember_DateCondition"]: {
		and?: Array<GraphQLTypes["Contember_DateCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_DateCondition"]> | undefined,
	not?: GraphQLTypes["Contember_DateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Date"] | undefined,
	notEq?: GraphQLTypes["Date"] | undefined,
	in?: Array<GraphQLTypes["Date"]> | undefined,
	notIn?: Array<GraphQLTypes["Date"]> | undefined,
	lt?: GraphQLTypes["Date"] | undefined,
	lte?: GraphQLTypes["Date"] | undefined,
	gt?: GraphQLTypes["Date"] | undefined,
	gte?: GraphQLTypes["Date"] | undefined
};
	["Contember_TimetableDayWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	date?: GraphQLTypes["Contember_DateCondition"] | undefined,
	type?: GraphQLTypes["Contember_TimetableDayTypeCondition"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined,
	tipsCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	notes?: GraphQLTypes["Contember_TimetableDayNoteWhere"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayMetadataWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableDayWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableDayWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableDayWhere"] | undefined
};
	["Contember_TimetableDayTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_TimetableDayTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableDayTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_TimetableDayTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	notEq?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	in?: Array<GraphQLTypes["Contember_TimetableDayType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_TimetableDayType"]> | undefined,
	lt?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	lte?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	gt?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	gte?: GraphQLTypes["Contember_TimetableDayType"] | undefined
};
	["Contember_TimetableDayType"]: Contember_TimetableDayType;
	["Contember_TimetableShiftGroupWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	shifts?: GraphQLTypes["Contember_TimetableShiftWhere"] | undefined,
	day?: GraphQLTypes["Contember_TimetableDayWhere"] | undefined,
	type?: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPositionWhere"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueWhere"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined
};
	["Contember_TimetableShiftWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	locked?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	assigned?: GraphQLTypes["Contember_DateCondition"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined,
	isOvertime?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableShiftWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableShiftWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableShiftWhere"] | undefined
};
	["Contember_ShiftsProfileWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	queueItems?: GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	roles?: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined,
	shifts?: GraphQLTypes["Contember_TimetableShiftWhere"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined
};
	["Contember_EmployeeQueueItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	start?: GraphQLTypes["Contember_StringCondition"] | undefined,
	end?: GraphQLTypes["Contember_StringCondition"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueWhere"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShiftWhere"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_UnassignedShiftInfoWhere"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined
};
	["Contember_EmployeeQueueWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	shiftGroup?: GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined,
	items?: GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined,
	contemberBugFix?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_EmployeeQueueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_EmployeeQueueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_EmployeeQueueWhere"] | undefined
};
	["Contember_UnassignedShiftInfoWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	hoursMissed?: GraphQLTypes["Contember_IntCondition"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined,
	legacyReason?: GraphQLTypes["Contember_UnassignedInfoReasonCondition"] | undefined,
	reason?: GraphQLTypes["Contember_UnassignReasonWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_UnassignedShiftInfoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_UnassignedShiftInfoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_UnassignedShiftInfoWhere"] | undefined
};
	["Contember_UnassignedInfoReasonCondition"]: {
		and?: Array<GraphQLTypes["Contember_UnassignedInfoReasonCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_UnassignedInfoReasonCondition"]> | undefined,
	not?: GraphQLTypes["Contember_UnassignedInfoReasonCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	notEq?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	in?: Array<GraphQLTypes["Contember_UnassignedInfoReason"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_UnassignedInfoReason"]> | undefined,
	lt?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	lte?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	gt?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	gte?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined
};
	["Contember_UnassignedInfoReason"]: Contember_UnassignedInfoReason;
	["Contember_UnassignReasonWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	venues?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_UnassignReasonWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_UnassignReasonWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_UnassignReasonWhere"] | undefined
};
	["Contember_EmployeeQueueItemMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_IntCondition"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemWhere"] | undefined,
	includeInTipsCalculation?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_EmployeeQueueItemMetadataWhere"] | undefined
};
	["Contember_TimetableEmployeeRoleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined,
	shiftsProfiles?: GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined,
	shiftsManagers?: GraphQLTypes["Contember_ShiftsManagerWhere"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined,
	color?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined
};
	["Contember_ShiftsManagerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	managedRoles?: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ShiftsManagerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ShiftsManagerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ShiftsManagerWhere"] | undefined
};
	["Contember_TimetableTemplateWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	startDate?: GraphQLTypes["Contember_DateCondition"] | undefined,
	period?: GraphQLTypes["Contember_IntCondition"] | undefined,
	slots?: GraphQLTypes["Contember_IntCondition"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPositionWhere"] | undefined,
	staffOptions?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined,
	workdaysOnly?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	monday?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	tuesday?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	wednesday?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	thursday?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	friday?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	saturday?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	sunday?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	type?: GraphQLTypes["Contember_TemplateTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined
};
	["Contember_TimetableShiftPositionWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	start?: GraphQLTypes["Contember_StringCondition"] | undefined,
	end?: GraphQLTypes["Contember_StringCondition"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_TimetableShiftGroupWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined,
	isDynamic?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableShiftPositionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableShiftPositionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableShiftPositionWhere"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	startTime?: GraphQLTypes["Contember_StringCondition"] | undefined,
	endTime?: GraphQLTypes["Contember_StringCondition"] | undefined,
	timetableTemplate?: GraphQLTypes["Contember_TimetableTemplateWhere"] | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWhere"] | undefined
};
	["Contember_TemplateTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_TemplateTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_TemplateTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_TemplateTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_TemplateType"] | undefined,
	notEq?: GraphQLTypes["Contember_TemplateType"] | undefined,
	in?: Array<GraphQLTypes["Contember_TemplateType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_TemplateType"]> | undefined,
	lt?: GraphQLTypes["Contember_TemplateType"] | undefined,
	lte?: GraphQLTypes["Contember_TemplateType"] | undefined,
	gt?: GraphQLTypes["Contember_TemplateType"] | undefined,
	gte?: GraphQLTypes["Contember_TemplateType"] | undefined
};
	["Contember_TemplateType"]: Contember_TemplateType;
	["Contember_TimetableShiftSwapInfoWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	assigned?: GraphQLTypes["Contember_DateCondition"] | undefined,
	requested?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	confirmed?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_ShiftsProfileWhere"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShiftWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableShiftSwapInfoWhere"] | undefined
};
	["Contember_TimetableDayNoteWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	day?: GraphQLTypes["Contember_TimetableDayWhere"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableDayNoteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableDayNoteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableDayNoteWhere"] | undefined
};
	["Contember_TimetableDayMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	numberOfWorkers?: GraphQLTypes["Contember_IntCondition"] | undefined,
	tipsTotalCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_IntCondition"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	day?: GraphQLTypes["Contember_TimetableDayWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetableDayMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetableDayMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetableDayMetadataWhere"] | undefined
};
	["Contember_FloatCondition"]: {
		and?: Array<GraphQLTypes["Contember_FloatCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_FloatCondition"]> | undefined,
	not?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: number | undefined,
	notEq?: number | undefined,
	in?: Array<number> | undefined,
	notIn?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined
};
	["Contember_VenueEmployeeRoleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	employeePrivileges?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRoleWhere"] | undefined,
	getsTipsShare?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	notesEnabled?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_VenueEmployeeRoleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VenueEmployeeRoleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VenueEmployeeRoleWhere"] | undefined
};
	["Contember_TimetablesEmployeePrivilegesWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	venueEmployeeRole?: GraphQLTypes["Contember_VenueEmployeeRoleWhere"] | undefined,
	read?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	unassign?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	assign?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWhere"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	identificationCode?: GraphQLTypes["Contember_IdentificationCodeWhere"] | undefined,
	identifier?: GraphQLTypes["Contember_DeviceIdentifierWhere"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleTerminalWhere"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSalePrinterWhere"] | undefined,
	ticketPrinters?: GraphQLTypes["Contember_PointOfSalePrinterWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined
};
	["Contember_IdentificationCodeWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	attempts?: GraphQLTypes["Contember_IdentificationAttemptWhere"] | undefined,
	locales?: GraphQLTypes["Contember_IdentificationCodeLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_IdentificationCodeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_IdentificationCodeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_IdentificationCodeWhere"] | undefined
};
	["Contember_IdentificationAttemptWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	code?: GraphQLTypes["Contember_IdentificationCodeWhere"] | undefined,
	user?: GraphQLTypes["Contember_UserWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_IdentificationAttemptWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_IdentificationAttemptWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_IdentificationAttemptWhere"] | undefined
};
	["Contember_UserWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	disabledAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	devices?: GraphQLTypes["Contember_UserDeviceWhere"] | undefined,
	verifiedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	email?: GraphQLTypes["Contember_StringCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	deletionRequestedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	deletionExpectedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	deletedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	meta?: GraphQLTypes["Contember_UserMetadataWhere"] | undefined,
	identificationAttempts?: GraphQLTypes["Contember_IdentificationAttemptWhere"] | undefined,
	roles?: GraphQLTypes["Contember_UserRolesWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_UserWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_UserWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_UserWhere"] | undefined
};
	["Contember_UserDeviceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	platform?: GraphQLTypes["Contember_StringCondition"] | undefined,
	deviceId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	notificationsEnabled?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	user?: GraphQLTypes["Contember_UserWhere"] | undefined,
	appVersion?: GraphQLTypes["Contember_StringCondition"] | undefined,
	osVersion?: GraphQLTypes["Contember_StringCondition"] | undefined,
	apiVersion?: GraphQLTypes["Contember_StringCondition"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSessionWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_UserDeviceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_UserDeviceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_UserDeviceWhere"] | undefined
};
	["Contember_AnonymousSessionWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	anonymousSessionKey?: GraphQLTypes["Contember_StringCondition"] | undefined,
	email?: GraphQLTypes["Contember_StringCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	devices?: GraphQLTypes["Contember_UserDeviceWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_AnonymousSessionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_AnonymousSessionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_AnonymousSessionWhere"] | undefined
};
	["Contember_UserMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	userProfileUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	tenantEmail?: GraphQLTypes["Contember_StringCondition"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	tenantIdentityId?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	globalRoles?: GraphQLTypes["Contember_JsonCondition"] | undefined,
	projectRoles?: GraphQLTypes["Contember_JsonCondition"] | undefined,
	projectRolesString?: GraphQLTypes["Contember_StringCondition"] | undefined,
	externalIdentifiers?: GraphQLTypes["Contember_JsonCondition"] | undefined,
	externalIdentifiersString?: GraphQLTypes["Contember_StringCondition"] | undefined,
	idpSlugs?: GraphQLTypes["Contember_JsonCondition"] | undefined,
	idpSlugsString?: GraphQLTypes["Contember_StringCondition"] | undefined,
	user?: GraphQLTypes["Contember_UserWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_UserMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_UserMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_UserMetadataWhere"] | undefined
};
	["Contember_UserRolesWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	isCustomer?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isStaff?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isBartender?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isCashier?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isCourier?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isCleaner?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isShiftsManager?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isInternalBuyer?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isStocksManager?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isCourierManager?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isCashierManager?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isBusinessToBusinessManager?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	isPicker?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	user?: GraphQLTypes["Contember_UserWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_UserRolesWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_UserRolesWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_UserRolesWhere"] | undefined
};
	["Contember_IdentificationCodeLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	successMessage?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_IdentificationCodeWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_IdentificationCodeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_IdentificationCodeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_IdentificationCodeLocaleWhere"] | undefined
};
	["Contember_DeviceIdentifierWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	device?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined,
	updatedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	platform?: GraphQLTypes["Contember_StringCondition"] | undefined,
	resolution?: GraphQLTypes["Contember_StringCondition"] | undefined,
	devicePixelRatio?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	updatedByUser?: GraphQLTypes["Contember_UserWhere"] | undefined,
	identifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	meta?: GraphQLTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeviceIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeviceIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeviceIdentifierWhere"] | undefined
};
	["Contember_DeviceIdentifierMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	prettyIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	deviceIdentifier?: GraphQLTypes["Contember_DeviceIdentifierWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeviceIdentifierMetadataWhere"] | undefined
};
	["Contember_PointOfSaleTerminalWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined,
	ipAddress?: GraphQLTypes["Contember_StringCondition"] | undefined,
	port?: GraphQLTypes["Contember_IntCondition"] | undefined,
	type?: GraphQLTypes["Contember_PointOfSaleTerminalTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSaleTerminalWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleTerminalWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleTerminalWhere"] | undefined
};
	["Contember_PointOfSaleTerminalTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_PointOfSaleTerminalTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleTerminalTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleTerminalTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	notEq?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	in?: Array<GraphQLTypes["Contember_PointOfSaleTerminalType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_PointOfSaleTerminalType"]> | undefined,
	lt?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	lte?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	gt?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	gte?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined
};
	["Contember_PointOfSaleTerminalType"]: Contember_PointOfSaleTerminalType;
	["Contember_PointOfSalePrinterWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ipAddress?: GraphQLTypes["Contember_StringCondition"] | undefined,
	applicationDevicesReceipt?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined,
	applicationDevicesTicket?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWhere"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	ticketCategories?: GraphQLTypes["Contember_PointOfSaleCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSalePrinterWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSalePrinterWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSalePrinterWhere"] | undefined
};
	["Contember_PointOfSaleCategoryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	slug?: GraphQLTypes["Contember_StringCondition"] | undefined,
	locales?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined,
	products?: GraphQLTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined,
	category?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	ticketPrinters?: GraphQLTypes["Contember_PointOfSalePrinterWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSaleCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleCategoryWhere"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWhere"] | undefined
};
	["Contember_PointOfSaleCategoryProductWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	locales?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryProductWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWhere"] | undefined
};
	["Contember_ProductCategoryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	products?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	tags?: GraphQLTypes["Contember_TagWhere"] | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	children?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	color?: GraphQLTypes["Contember_StringCondition"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	order?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	descendants?: GraphQLTypes["Contember_ProductCategoryTreeWhere"] | undefined,
	ancestors?: GraphQLTypes["Contember_ProductCategoryTreeWhere"] | undefined,
	inheritedTags?: GraphQLTypes["Contember_InheritedProductCategoryTagWhere"] | undefined,
	nestedProducts?: GraphQLTypes["Contember_NestedProductCategoryProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined
};
	["Contember_ProductCategoryTreeWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	depth?: GraphQLTypes["Contember_IntCondition"] | undefined,
	ancestor?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	descendant?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductCategoryTreeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductCategoryTreeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductCategoryTreeWhere"] | undefined
};
	["Contember_InheritedProductCategoryTagWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	depth?: GraphQLTypes["Contember_IntCondition"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	tag?: GraphQLTypes["Contember_TagWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_InheritedProductCategoryTagWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_InheritedProductCategoryTagWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_InheritedProductCategoryTagWhere"] | undefined
};
	["Contember_NestedProductCategoryProductWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	depth?: GraphQLTypes["Contember_IntCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_NestedProductCategoryProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_NestedProductCategoryProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_NestedProductCategoryProductWhere"] | undefined
};
	["Contember_OrderWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	state?: GraphQLTypes["Contember_OrderStateCondition"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	canceledAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelWhere"] | undefined,
	discounts?: GraphQLTypes["Contember_OrderDiscountWhere"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined,
	items?: GraphQLTypes["Contember_OrderItemWhere"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	cart?: GraphQLTypes["Contember_CartWhere"] | undefined,
	events?: GraphQLTypes["Contember_OrderEventWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	billingAddress?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	priceLines?: GraphQLTypes["Contember_OrderPriceLineWhere"] | undefined,
	payments?: GraphQLTypes["Contember_OrderPaymentWhere"] | undefined,
	seq?: GraphQLTypes["Contember_IntCondition"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubjectWhere"] | undefined,
	summary?: GraphQLTypes["Contember_OrderSummaryWhere"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	gratuity?: GraphQLTypes["Contember_GratuityWhere"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["Contember_IntCondition"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["Contember_StringCondition"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderWhere"] | undefined
};
	["Contember_OrderStateCondition"]: {
		and?: Array<GraphQLTypes["Contember_OrderStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderStateCondition"]> | undefined,
	not?: GraphQLTypes["Contember_OrderStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_OrderState"] | undefined,
	notEq?: GraphQLTypes["Contember_OrderState"] | undefined,
	in?: Array<GraphQLTypes["Contember_OrderState"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_OrderState"]> | undefined,
	lt?: GraphQLTypes["Contember_OrderState"] | undefined,
	lte?: GraphQLTypes["Contember_OrderState"] | undefined,
	gt?: GraphQLTypes["Contember_OrderState"] | undefined,
	gte?: GraphQLTypes["Contember_OrderState"] | undefined
};
	["Contember_OrderState"]: Contember_OrderState;
	["Contember_OrderDiscountWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	discountCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	type?: GraphQLTypes["Contember_OrderDiscountTypeCondition"] | undefined,
	customerCreditTransactions?: GraphQLTypes["Contember_CustomerCreditTransactionWhere"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderDiscountWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderDiscountWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderDiscountWhere"] | undefined
};
	["Contember_OrderDiscountTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_OrderDiscountTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderDiscountTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_OrderDiscountTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	notEq?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	in?: Array<GraphQLTypes["Contember_OrderDiscountType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_OrderDiscountType"]> | undefined,
	lt?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	lte?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	gt?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	gte?: GraphQLTypes["Contember_OrderDiscountType"] | undefined
};
	["Contember_OrderDiscountType"]: Contember_OrderDiscountType;
	["Contember_CustomerCreditTransactionWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	amountCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditWhere"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountWhere"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffectWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerCreditTransactionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerCreditTransactionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerCreditTransactionWhere"] | undefined
};
	["Contember_CustomerCreditWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	initialCreditCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	expiresAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	reason?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	transactions?: GraphQLTypes["Contember_CustomerCreditTransactionWhere"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionWhere"] | undefined,
	invoiceUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerCreditWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerCreditWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerCreditWhere"] | undefined
};
	["Contember_VoucherRedemptionWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	manualRedemptionNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditWhere"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountWhere"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_VoucherRedemptionWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VoucherRedemptionWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VoucherRedemptionWhere"] | undefined
};
	["Contember_VoucherWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	activatedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	expiresAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	type?: GraphQLTypes["Contember_VoucherTypeCondition"] | undefined,
	creditCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	buyer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	owner?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffectWhere"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherRedemptionWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_VoucherWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VoucherWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VoucherWhere"] | undefined
};
	["Contember_VoucherTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_VoucherTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_VoucherTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_VoucherTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_VoucherType"] | undefined,
	notEq?: GraphQLTypes["Contember_VoucherType"] | undefined,
	in?: Array<GraphQLTypes["Contember_VoucherType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_VoucherType"]> | undefined,
	lt?: GraphQLTypes["Contember_VoucherType"] | undefined,
	lte?: GraphQLTypes["Contember_VoucherType"] | undefined,
	gt?: GraphQLTypes["Contember_VoucherType"] | undefined,
	gte?: GraphQLTypes["Contember_VoucherType"] | undefined
};
	["Contember_VoucherType"]: Contember_VoucherType;
	["Contember_VirtualProductEffectWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	succeededAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	orderItem?: GraphQLTypes["Contember_OrderItemWhere"] | undefined,
	failedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	revertedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_CustomerCreditTransactionWhere"] | undefined,
	resultVouchers?: GraphQLTypes["Contember_VoucherWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_VirtualProductEffectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VirtualProductEffectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VirtualProductEffectWhere"] | undefined
};
	["Contember_OrderItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	quantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	virtualProductEffects?: GraphQLTypes["Contember_VirtualProductEffectWhere"] | undefined,
	pickedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FulfillmentNoteWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderItemWhere"] | undefined
};
	["Contember_OrderEventWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_StringCondition"] | undefined,
	data?: GraphQLTypes["Contember_StringCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	payment?: GraphQLTypes["Contember_OrderPaymentWhere"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderEventWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderEventWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderEventWhere"] | undefined
};
	["Contember_OrderPaymentWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	data?: GraphQLTypes["Contember_JsonCondition"] | undefined,
	paymentCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	method?: GraphQLTypes["Contember_PaymentMethodWhere"] | undefined,
	failedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	state?: GraphQLTypes["Contember_OrderPaymentStateCondition"] | undefined,
	approvedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	events?: GraphQLTypes["Contember_OrderEventWhere"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	invoice?: GraphQLTypes["Contember_InvoiceWhere"] | undefined,
	walletType?: GraphQLTypes["Contember_StringCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	publicReceiptUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	priceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentMetadataWhere"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderPaymentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderPaymentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderPaymentWhere"] | undefined
};
	["Contember_OrderPaymentStateCondition"]: {
		and?: Array<GraphQLTypes["Contember_OrderPaymentStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderPaymentStateCondition"]> | undefined,
	not?: GraphQLTypes["Contember_OrderPaymentStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	notEq?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	in?: Array<GraphQLTypes["Contember_OrderPaymentState"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_OrderPaymentState"]> | undefined,
	lt?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	lte?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	gt?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	gte?: GraphQLTypes["Contember_OrderPaymentState"] | undefined
};
	["Contember_OrderPaymentState"]: Contember_OrderPaymentState;
	["Contember_InvoiceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	publicKey?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fakturoidId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fakturoidData?: GraphQLTypes["Contember_StringCondition"] | undefined,
	legacyUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	orderPayments?: GraphQLTypes["Contember_OrderPaymentWhere"] | undefined,
	address?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	publicUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubjectWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_InvoiceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_InvoiceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_InvoiceWhere"] | undefined
};
	["Contember_BillingSubjectWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fullName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	addressLine1?: GraphQLTypes["Contember_StringCondition"] | undefined,
	addressLine2?: GraphQLTypes["Contember_StringCondition"] | undefined,
	city?: GraphQLTypes["Contember_StringCondition"] | undefined,
	postalCode?: GraphQLTypes["Contember_StringCondition"] | undefined,
	email?: GraphQLTypes["Contember_StringCondition"] | undefined,
	phone?: GraphQLTypes["Contember_StringCondition"] | undefined,
	companyName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	hidden?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	deletedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	country?: GraphQLTypes["Contember_CountryWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	invoices?: GraphQLTypes["Contember_InvoiceWhere"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	orders?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BillingSubjectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BillingSubjectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BillingSubjectWhere"] | undefined
};
	["Contember_CountryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CountryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CountryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CountryWhere"] | undefined
};
	["Contember_OrderPaymentMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	paymentUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	orderPayment?: GraphQLTypes["Contember_OrderPaymentWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderPaymentMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderPaymentMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderPaymentMetadataWhere"] | undefined
};
	["Contember_OrderPriceLineWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	quantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	type?: GraphQLTypes["Contember_OrderPriceLineTypeCondition"] | undefined,
	orderItem?: GraphQLTypes["Contember_OrderItemWhere"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountWhere"] | undefined,
	orderPayment?: GraphQLTypes["Contember_OrderPaymentWhere"] | undefined,
	orderDelivery?: GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_IntCondition"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderPriceLineWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderPriceLineWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderPriceLineWhere"] | undefined
};
	["Contember_OrderPriceLineTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_OrderPriceLineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderPriceLineTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_OrderPriceLineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined,
	notEq?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined,
	in?: Array<GraphQLTypes["Contember_OrderPriceLineType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_OrderPriceLineType"]> | undefined,
	lt?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined,
	lte?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined,
	gt?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined,
	gte?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined
};
	["Contember_OrderPriceLineType"]: Contember_OrderPriceLineType;
	["Contember_OrderSummaryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	discountPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	orderUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	orderNumber?: GraphQLTypes["Contember_StringCondition"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderSummaryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderSummaryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderSummaryWhere"] | undefined
};
	["Contember_GratuityWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	amountCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	order?: GraphQLTypes["Contember_OrderWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_GratuityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_GratuityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_GratuityWhere"] | undefined
};
	["Contember_AreaWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	venue?: GraphQLTypes["Contember_VenueWhere"] | undefined,
	tables?: GraphQLTypes["Contember_TableWhere"] | undefined,
	lines?: GraphQLTypes["Contember_LineWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_AreaWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_AreaWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_AreaWhere"] | undefined
};
	["Contember_TableWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	shape?: GraphQLTypes["Contember_TableShapeCondition"] | undefined,
	width?: GraphQLTypes["Contember_IntCondition"] | undefined,
	height?: GraphQLTypes["Contember_IntCondition"] | undefined,
	positionX?: GraphQLTypes["Contember_IntCondition"] | undefined,
	positionY?: GraphQLTypes["Contember_IntCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	color?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customerTabs?: GraphQLTypes["Contember_CustomerTabWhere"] | undefined,
	area?: GraphQLTypes["Contember_AreaWhere"] | undefined,
	seats?: GraphQLTypes["Contember_SeatWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TableWhere"] | undefined
};
	["Contember_TableShapeCondition"]: {
		and?: Array<GraphQLTypes["Contember_TableShapeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_TableShapeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_TableShapeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_TableShape"] | undefined,
	notEq?: GraphQLTypes["Contember_TableShape"] | undefined,
	in?: Array<GraphQLTypes["Contember_TableShape"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_TableShape"]> | undefined,
	lt?: GraphQLTypes["Contember_TableShape"] | undefined,
	lte?: GraphQLTypes["Contember_TableShape"] | undefined,
	gt?: GraphQLTypes["Contember_TableShape"] | undefined,
	gte?: GraphQLTypes["Contember_TableShape"] | undefined
};
	["Contember_TableShape"]: Contember_TableShape;
	["Contember_CustomerTabWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	color?: GraphQLTypes["Contember_StringCondition"] | undefined,
	items?: GraphQLTypes["Contember_CustomerTabItemWhere"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabMetadataWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	table?: GraphQLTypes["Contember_TableWhere"] | undefined,
	seat?: GraphQLTypes["Contember_SeatWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerTabWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerTabWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerTabWhere"] | undefined
};
	["Contember_CustomerTabItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	addedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	tab?: GraphQLTypes["Contember_CustomerTabWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	paidAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerTabItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerTabItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerTabItemWhere"] | undefined
};
	["Contember_CustomerTabMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	totalSpentCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	unpaidCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	customerTab?: GraphQLTypes["Contember_CustomerTabWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerTabMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerTabMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerTabMetadataWhere"] | undefined
};
	["Contember_SeatWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	positionX?: GraphQLTypes["Contember_IntCondition"] | undefined,
	positionY?: GraphQLTypes["Contember_IntCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	color?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customerTabs?: GraphQLTypes["Contember_CustomerTabWhere"] | undefined,
	table?: GraphQLTypes["Contember_TableWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_SeatWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_SeatWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_SeatWhere"] | undefined
};
	["Contember_LineWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	positionStartX?: GraphQLTypes["Contember_IntCondition"] | undefined,
	positionStartY?: GraphQLTypes["Contember_IntCondition"] | undefined,
	positionEndX?: GraphQLTypes["Contember_IntCondition"] | undefined,
	positionEndY?: GraphQLTypes["Contember_IntCondition"] | undefined,
	area?: GraphQLTypes["Contember_AreaWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LineWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LineWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LineWhere"] | undefined
};
	["Contember_PointOfSaleInitialViewCondition"]: {
		and?: Array<GraphQLTypes["Contember_PointOfSaleInitialViewCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_PointOfSaleInitialViewCondition"]> | undefined,
	not?: GraphQLTypes["Contember_PointOfSaleInitialViewCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	notEq?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	in?: Array<GraphQLTypes["Contember_PointOfSaleInitialView"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_PointOfSaleInitialView"]> | undefined,
	lt?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	lte?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	gt?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	gte?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined
};
	["Contember_PointOfSaleInitialView"]: Contember_PointOfSaleInitialView;
	["Contember_CartStateCondition"]: {
		and?: Array<GraphQLTypes["Contember_CartStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_CartStateCondition"]> | undefined,
	not?: GraphQLTypes["Contember_CartStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_CartState"] | undefined,
	notEq?: GraphQLTypes["Contember_CartState"] | undefined,
	in?: Array<GraphQLTypes["Contember_CartState"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_CartState"]> | undefined,
	lt?: GraphQLTypes["Contember_CartState"] | undefined,
	lte?: GraphQLTypes["Contember_CartState"] | undefined,
	gt?: GraphQLTypes["Contember_CartState"] | undefined,
	gte?: GraphQLTypes["Contember_CartState"] | undefined
};
	["Contember_CartState"]: Contember_CartState;
	["Contember_CartPriceLineWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_CartPriceLineTypeCondition"] | undefined,
	item?: GraphQLTypes["Contember_CartItemWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	priceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	quantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_IntCondition"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	cart?: GraphQLTypes["Contember_CartWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CartPriceLineWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CartPriceLineWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CartPriceLineWhere"] | undefined
};
	["Contember_CartPriceLineTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_CartPriceLineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_CartPriceLineTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_CartPriceLineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_CartPriceLineType"] | undefined,
	notEq?: GraphQLTypes["Contember_CartPriceLineType"] | undefined,
	in?: Array<GraphQLTypes["Contember_CartPriceLineType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_CartPriceLineType"]> | undefined,
	lt?: GraphQLTypes["Contember_CartPriceLineType"] | undefined,
	lte?: GraphQLTypes["Contember_CartPriceLineType"] | undefined,
	gt?: GraphQLTypes["Contember_CartPriceLineType"] | undefined,
	gte?: GraphQLTypes["Contember_CartPriceLineType"] | undefined
};
	["Contember_CartPriceLineType"]: Contember_CartPriceLineType;
	["Contember_CartSummaryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	itemsPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	deliveryPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	itemsCount?: GraphQLTypes["Contember_IntCondition"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	checkoutUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	cart?: GraphQLTypes["Contember_CartWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CartSummaryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CartSummaryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CartSummaryWhere"] | undefined
};
	["Contember_ChannelCodeCondition"]: {
		and?: Array<GraphQLTypes["Contember_ChannelCodeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_ChannelCodeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_ChannelCodeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	notEq?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	in?: Array<GraphQLTypes["Contember_ChannelCode"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_ChannelCode"]> | undefined,
	lt?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	lte?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	gt?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	gte?: GraphQLTypes["Contember_ChannelCode"] | undefined
};
	["Contember_ChannelCode"]: Contember_ChannelCode;
	["Contember_VatRatePresetCondition"]: {
		and?: Array<GraphQLTypes["Contember_VatRatePresetCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_VatRatePresetCondition"]> | undefined,
	not?: GraphQLTypes["Contember_VatRatePresetCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	notEq?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	in?: Array<GraphQLTypes["Contember_VatRatePreset"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_VatRatePreset"]> | undefined,
	lt?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	lte?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	gt?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	gte?: GraphQLTypes["Contember_VatRatePreset"] | undefined
};
	["Contember_VatRatePreset"]: Contember_VatRatePreset;
	["Contember_OrderDeliveryStateCondition"]: {
		and?: Array<GraphQLTypes["Contember_OrderDeliveryStateCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderDeliveryStateCondition"]> | undefined,
	not?: GraphQLTypes["Contember_OrderDeliveryStateCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	notEq?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	in?: Array<GraphQLTypes["Contember_OrderDeliveryState"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_OrderDeliveryState"]> | undefined,
	lt?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	lte?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	gt?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	gte?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined
};
	["Contember_OrderDeliveryState"]: Contember_OrderDeliveryState;
	["Contember_LiftagoRideWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	liftagoId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	data?: GraphQLTypes["Contember_StringCondition"] | undefined,
	updatedAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LiftagoRideWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LiftagoRideWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LiftagoRideWhere"] | undefined
};
	["Contember_CleanerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	canReachTopShelf?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CleanerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CleanerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CleanerWhere"] | undefined
};
	["Contember_StocksManagerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	managedProducts?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_StocksManagerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_StocksManagerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_StocksManagerWhere"] | undefined
};
	["Contember_CourierManagerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CourierManagerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CourierManagerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CourierManagerWhere"] | undefined
};
	["Contember_PickerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PickerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PickerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PickerWhere"] | undefined
};
	["Contember_RemunerationProfileWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	employmentInformation?: GraphQLTypes["Contember_EmploymentInformationWhere"] | undefined,
	workMonths?: GraphQLTypes["Contember_WorkMonthWhere"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_RemunerationProfileWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_RemunerationProfileWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_RemunerationProfileWhere"] | undefined
};
	["Contember_EmploymentInformationWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	hourlyRate?: GraphQLTypes["Contember_IntCondition"] | undefined,
	remunerationProfiles?: GraphQLTypes["Contember_RemunerationProfileWhere"] | undefined,
	monthlySalary?: GraphQLTypes["Contember_IntCondition"] | undefined,
	type?: GraphQLTypes["Contember_EmploymentTypeCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_EmploymentInformationWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_EmploymentInformationWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_EmploymentInformationWhere"] | undefined
};
	["Contember_EmploymentTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_EmploymentTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_EmploymentTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_EmploymentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_EmploymentType"] | undefined,
	notEq?: GraphQLTypes["Contember_EmploymentType"] | undefined,
	in?: Array<GraphQLTypes["Contember_EmploymentType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_EmploymentType"]> | undefined,
	lt?: GraphQLTypes["Contember_EmploymentType"] | undefined,
	lte?: GraphQLTypes["Contember_EmploymentType"] | undefined,
	gt?: GraphQLTypes["Contember_EmploymentType"] | undefined,
	gte?: GraphQLTypes["Contember_EmploymentType"] | undefined
};
	["Contember_EmploymentType"]: Contember_EmploymentType;
	["Contember_WorkMonthWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	year?: GraphQLTypes["Contember_IntCondition"] | undefined,
	month?: GraphQLTypes["Contember_IntCondition"] | undefined,
	hoursWorked?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	billableHours?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_RemunerationProfileWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_WorkMonthWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_WorkMonthWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_WorkMonthWhere"] | undefined
};
	["Contember_CashierWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CashierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CashierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CashierWhere"] | undefined
};
	["Contember_InternalBuyerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_InternalBuyerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_InternalBuyerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_InternalBuyerWhere"] | undefined
};
	["Contember_SalesManagerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	countries?: GraphQLTypes["Contember_CountryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_SalesManagerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_SalesManagerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_SalesManagerWhere"] | undefined
};
	["Contember_CashierManagerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	staff?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CashierManagerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CashierManagerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CashierManagerWhere"] | undefined
};
	["Contember_BusinessServiceEventWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	businessCustomer?: GraphQLTypes["Contember_BusinessCustomerWhere"] | undefined,
	businessService?: GraphQLTypes["Contember_BusinessServiceWhere"] | undefined,
	filledNumberOfPeople?: GraphQLTypes["Contember_IntCondition"] | undefined,
	filledInvoiced?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	filledEquipment?: GraphQLTypes["Contember_StringCondition"] | undefined,
	filledMillTamDem?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	filledSOEspresso?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	filledFilterCoffeeInMenu?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	filledWaterMeterStatus?: GraphQLTypes["Contember_StringCondition"] | undefined,
	filledServiceAction?: GraphQLTypes["Contember_StringCondition"] | undefined,
	filledInvoiceIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	filledNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	filledSupervisor?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	filledContact?: GraphQLTypes["Contember_StringCondition"] | undefined,
	filledPhone?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	filledEmail?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	filledGrindingStonesCondition?: GraphQLTypes["Contember_StringCondition"] | undefined,
	createdBy?: GraphQLTypes["Contember_StaffWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BusinessServiceEventWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BusinessServiceEventWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BusinessServiceEventWhere"] | undefined
};
	["Contember_BusinessServiceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireNote?: GraphQLTypes["Contember_IntCondition"] | undefined,
	locales?: GraphQLTypes["Contember_BusinessServiceLocaleWhere"] | undefined,
	requireNumberOfPeople?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireInvoiced?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireEquipment?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireMillTamDem?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireSOEspresso?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireFilterCoffeeInMenu?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireWaterMeterStatus?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireServiceAction?: GraphQLTypes["Contember_IntCondition"] | undefined,
	optionsEquipment?: GraphQLTypes["Contember_StringCondition"] | undefined,
	optionsServiceAction?: GraphQLTypes["Contember_StringCondition"] | undefined,
	requireInvoiceIdentifier?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireSupervisor?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireContact?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requirePhone?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireEmail?: GraphQLTypes["Contember_IntCondition"] | undefined,
	requireGrindingStonesCondition?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_BusinessServiceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BusinessServiceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BusinessServiceWhere"] | undefined
};
	["Contember_BusinessServiceLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_BusinessServiceWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	labelNumberOfPeople?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelInvoiced?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelEquipment?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelMillTamDem?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelSOEspresso?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelFilterCoffeeInMenu?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelWaterMeterStatus?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelServiceAction?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelInvoiceIdentifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelSupervisor?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelContact?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelPhone?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelEmail?: GraphQLTypes["Contember_StringCondition"] | undefined,
	labelGrindingStonesCondition?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_BusinessServiceLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BusinessServiceLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BusinessServiceLocaleWhere"] | undefined
};
	["Contember_DoubleshotCoffeeEquipmentWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	espressoMachine?: GraphQLTypes["Contember_StringCondition"] | undefined,
	espressoGrinder?: GraphQLTypes["Contember_StringCondition"] | undefined,
	batchBrew?: GraphQLTypes["Contember_StringCondition"] | undefined,
	batchGrinder?: GraphQLTypes["Contember_StringCondition"] | undefined,
	waterFilter?: GraphQLTypes["Contember_StringCondition"] | undefined,
	mechanicalFilter?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentWhere"] | undefined
};
	["Contember_OrderRecurrenceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	locales?: GraphQLTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	businessCategory?: GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderRecurrenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderRecurrenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderRecurrenceWhere"] | undefined
};
	["Contember_OrderRecurrenceLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_OrderRecurrenceWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_OrderRecurrenceLocaleWhere"] | undefined
};
	["Contember_ProductListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_ProductListItemWhere"] | undefined,
	businessCategory?: GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductListWhere"] | undefined
};
	["Contember_ProductListItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	list?: GraphQLTypes["Contember_ProductListWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductListItemWhere"] | undefined
};
	["Contember_ProductCategoryListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_ProductCategoryListItemWhere"] | undefined,
	locales?: GraphQLTypes["Contember_ProductCategoryListLocaleWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategoryWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductCategoryListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductCategoryListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductCategoryListWhere"] | undefined
};
	["Contember_ProductCategoryListItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	list?: GraphQLTypes["Contember_ProductCategoryListWhere"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryWhere"] | undefined,
	showRecipeImages?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	showPackingImages?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductCategoryListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductCategoryListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductCategoryListItemWhere"] | undefined
};
	["Contember_ProductCategoryListLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_ProductCategoryListWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductCategoryListLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductCategoryListLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductCategoryListLocaleWhere"] | undefined
};
	["Contember_ProductIngredientLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientLocaleWhere"] | undefined
};
	["Contember_ProductIngredientWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	locales?: GraphQLTypes["Contember_ProductIngredientLocaleWhere"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	categories?: GraphQLTypes["Contember_ProductIngredientCategoryWhere"] | undefined,
	allergens?: GraphQLTypes["Contember_AllergenWhere"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	allowCustomerRating?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	customerRatings?: GraphQLTypes["Contember_CustomerIngredientRatingWhere"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainerWhere"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	suppliers?: GraphQLTypes["Contember_IngredientSupplierListWhere"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientWhere"] | undefined
};
	["Contember_ProductIngredientCategoryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ingredients?: GraphQLTypes["Contember_ProductIngredientWhere"] | undefined,
	locales?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientCategoryWhere"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientCategoryWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWhere"] | undefined
};
	["Contember_AllergenWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	locales?: GraphQLTypes["Contember_AllergenLocaleWhere"] | undefined,
	ingredients?: GraphQLTypes["Contember_ProductIngredientWhere"] | undefined,
	code?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_AllergenWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_AllergenWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_AllergenWhere"] | undefined
};
	["Contember_AllergenLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_AllergenWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_AllergenLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_AllergenLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_AllergenLocaleWhere"] | undefined
};
	["Contember_CustomerIngredientRatingWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	rating?: GraphQLTypes["Contember_ProductIngredientRatingWhere"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredientWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerIngredientRatingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerIngredientRatingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerIngredientRatingWhere"] | undefined
};
	["Contember_ProductIngredientRatingWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	icon?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientRatingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientRatingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientRatingWhere"] | undefined
};
	["Contember_ProductIngredientItemQuantityUnitWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined
};
	["Contember_FreshingContainerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	volumeMl?: GraphQLTypes["Contember_IntCondition"] | undefined,
	workspace?: GraphQLTypes["Contember_WorkspaceWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_FreshingContainerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_FreshingContainerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_FreshingContainerWhere"] | undefined
};
	["Contember_WorkspaceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	unique?: GraphQLTypes["Contember_OneCondition"] | undefined,
	orderDeadlineTime?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["Contember_IntCondition"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["Contember_IntCondition"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["Contember_IntCondition"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["Contember_StringCondition"] | undefined,
	orderUrlTemplate?: GraphQLTypes["Contember_StringCondition"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["Contember_StringCondition"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["Contember_StringCondition"] | undefined,
	messengerUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	messengerIosUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	freshingContainers?: GraphQLTypes["Contember_FreshingContainerWhere"] | undefined,
	openingHours?: GraphQLTypes["Contember_WeekHoursWhere"] | undefined,
	workingHours?: GraphQLTypes["Contember_WeekHoursWhere"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_FreshingContainerWhere"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_VirtualProductWhere"] | undefined,
	discountVatRate?: GraphQLTypes["Contember_VatRateWhere"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	businessAddress?: GraphQLTypes["Contember_StringCondition"] | undefined,
	migrationTest?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_WorkspaceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_WorkspaceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_WorkspaceWhere"] | undefined
};
	["Contember_OneCondition"]: {
		and?: Array<GraphQLTypes["Contember_OneCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_OneCondition"]> | undefined,
	not?: GraphQLTypes["Contember_OneCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_One"] | undefined,
	notEq?: GraphQLTypes["Contember_One"] | undefined,
	in?: Array<GraphQLTypes["Contember_One"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_One"]> | undefined,
	lt?: GraphQLTypes["Contember_One"] | undefined,
	lte?: GraphQLTypes["Contember_One"] | undefined,
	gt?: GraphQLTypes["Contember_One"] | undefined,
	gte?: GraphQLTypes["Contember_One"] | undefined
};
	["Contember_One"]: Contember_One;
	["Contember_WeekHoursWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	mon?: GraphQLTypes["Contember_StringCondition"] | undefined,
	tue?: GraphQLTypes["Contember_StringCondition"] | undefined,
	wed?: GraphQLTypes["Contember_StringCondition"] | undefined,
	thu?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fri?: GraphQLTypes["Contember_StringCondition"] | undefined,
	sat?: GraphQLTypes["Contember_StringCondition"] | undefined,
	sun?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_WeekHoursWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_WeekHoursWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_WeekHoursWhere"] | undefined
};
	["Contember_VirtualProductWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_VirtualProductTypeCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	voucherCreditCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	recipientEmail?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_VirtualProductWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VirtualProductWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VirtualProductWhere"] | undefined
};
	["Contember_VirtualProductTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_VirtualProductTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_VirtualProductTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_VirtualProductTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	notEq?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	in?: Array<GraphQLTypes["Contember_VirtualProductType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_VirtualProductType"]> | undefined,
	lt?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	lte?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	gt?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	gte?: GraphQLTypes["Contember_VirtualProductType"] | undefined
};
	["Contember_VirtualProductType"]: Contember_VirtualProductType;
	["Contember_VirtualProductPhysicalRepresentationTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	notEq?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	in?: Array<GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"]> | undefined,
	lt?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	lte?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	gt?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	gte?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined
};
	["Contember_VirtualProductPhysicalRepresentationType"]: Contember_VirtualProductPhysicalRepresentationType;
	["Contember_IngredientSupplierListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_IngredientSupplierItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_IngredientSupplierListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_IngredientSupplierListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_IngredientSupplierListWhere"] | undefined
};
	["Contember_IngredientSupplierItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	list?: GraphQLTypes["Contember_IngredientSupplierListWhere"] | undefined,
	supplier?: GraphQLTypes["Contember_IngredientSupplierWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_IngredientSupplierItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_IngredientSupplierItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_IngredientSupplierItemWhere"] | undefined
};
	["Contember_IngredientSupplierWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	locales?: GraphQLTypes["Contember_IngredientSupplierLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_IngredientSupplierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_IngredientSupplierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_IngredientSupplierWhere"] | undefined
};
	["Contember_IngredientSupplierLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_IngredientSupplierWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_IngredientSupplierLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_IngredientSupplierLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_IngredientSupplierLocaleWhere"] | undefined
};
	["Contember_BlogLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	pageName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	lead?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_BlogWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BlogLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BlogLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BlogLocaleWhere"] | undefined
};
	["Contember_BlogWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	unique?: GraphQLTypes["Contember_OneCondition"] | undefined,
	locales?: GraphQLTypes["Contember_BlogLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BlogWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BlogWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BlogWhere"] | undefined
};
	["Contember_BlogPostLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	availableForWeb?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	availableForMobile?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	root?: GraphQLTypes["Contember_BlogPostWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	cover?: GraphQLTypes["Contember_CoverWhere"] | undefined,
	content?: GraphQLTypes["Contember_ContentWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkableWhere"] | undefined,
	seo?: GraphQLTypes["Contember_SeoWhere"] | undefined,
	products?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BlogPostLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BlogPostLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BlogPostLocaleWhere"] | undefined
};
	["Contember_BlogPostWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	locales?: GraphQLTypes["Contember_BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BlogPostWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BlogPostWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BlogPostWhere"] | undefined
};
	["Contember_CoverWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	primaryTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	medium?: GraphQLTypes["Contember_MediumWhere"] | undefined,
	buttonColors?: GraphQLTypes["Contember_ColorPalleteWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CoverWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CoverWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CoverWhere"] | undefined
};
	["Contember_MediumWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_MediumTypeCondition"] | undefined,
	colorTheme?: GraphQLTypes["Contember_ColorThemeCondition"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	video?: GraphQLTypes["Contember_VideoWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_MediumWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MediumWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MediumWhere"] | undefined
};
	["Contember_MediumTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_MediumTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_MediumTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_MediumTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_MediumType"] | undefined,
	notEq?: GraphQLTypes["Contember_MediumType"] | undefined,
	in?: Array<GraphQLTypes["Contember_MediumType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_MediumType"]> | undefined,
	lt?: GraphQLTypes["Contember_MediumType"] | undefined,
	lte?: GraphQLTypes["Contember_MediumType"] | undefined,
	gt?: GraphQLTypes["Contember_MediumType"] | undefined,
	gte?: GraphQLTypes["Contember_MediumType"] | undefined
};
	["Contember_MediumType"]: Contember_MediumType;
	["Contember_ColorThemeCondition"]: {
		and?: Array<GraphQLTypes["Contember_ColorThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_ColorThemeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_ColorThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	notEq?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	in?: Array<GraphQLTypes["Contember_ColorTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_ColorTheme"]> | undefined,
	lt?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	lte?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	gt?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	gte?: GraphQLTypes["Contember_ColorTheme"] | undefined
};
	["Contember_ColorTheme"]: Contember_ColorTheme;
	["Contember_VideoWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	src?: GraphQLTypes["Contember_StringCondition"] | undefined,
	width?: GraphQLTypes["Contember_IntCondition"] | undefined,
	height?: GraphQLTypes["Contember_IntCondition"] | undefined,
	size?: GraphQLTypes["Contember_IntCondition"] | undefined,
	type?: GraphQLTypes["Contember_StringCondition"] | undefined,
	duration?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	poster?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_VideoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_VideoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_VideoWhere"] | undefined
};
	["Contember_ColorPalleteWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	background?: GraphQLTypes["Contember_StringCondition"] | undefined,
	text?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ColorPalleteWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ColorPalleteWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ColorPalleteWhere"] | undefined
};
	["Contember_ContentWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	blocks?: GraphQLTypes["Contember_ContentBlockWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ContentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ContentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ContentWhere"] | undefined
};
	["Contember_ContentBlockWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	json?: GraphQLTypes["Contember_StringCondition"] | undefined,
	content?: GraphQLTypes["Contember_ContentWhere"] | undefined,
	references?: GraphQLTypes["Contember_ContentReferenceWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ContentBlockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ContentBlockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ContentBlockWhere"] | undefined
};
	["Contember_ContentReferenceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_ContentReferenceTypeCondition"] | undefined,
	primaryText?: GraphQLTypes["Contember_StringCondition"] | undefined,
	secondaryText?: GraphQLTypes["Contember_StringCondition"] | undefined,
	jsonContent?: GraphQLTypes["Contember_StringCondition"] | undefined,
	block?: GraphQLTypes["Contember_ContentBlockWhere"] | undefined,
	colorPallete?: GraphQLTypes["Contember_ColorPalleteWhere"] | undefined,
	medium?: GraphQLTypes["Contember_MediumWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	blogPosts?: GraphQLTypes["Contember_BlogPostListWhere"] | undefined,
	links?: GraphQLTypes["Contember_LinkListWhere"] | undefined,
	products?: GraphQLTypes["Contember_ProductListWhere"] | undefined,
	contentSize?: GraphQLTypes["Contember_ContentSizeCondition"] | undefined,
	misc?: GraphQLTypes["Contember_StringCondition"] | undefined,
	hero?: GraphQLTypes["Contember_HeroWhere"] | undefined,
	gallery?: GraphQLTypes["Contember_GalleryWhere"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndAppsWhere"] | undefined,
	productGrid?: GraphQLTypes["Contember_ProductGridWhere"] | undefined,
	productBanners?: GraphQLTypes["Contember_ProductBannerListWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegionsWhere"] | undefined,
	textList?: GraphQLTypes["Contember_TextListWhere"] | undefined,
	gridTiles?: GraphQLTypes["Contember_GridTilesWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ContentReferenceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ContentReferenceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ContentReferenceWhere"] | undefined
};
	["Contember_ContentReferenceTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_ContentReferenceTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_ContentReferenceTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_ContentReferenceTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_ContentReferenceType"] | undefined,
	notEq?: GraphQLTypes["Contember_ContentReferenceType"] | undefined,
	in?: Array<GraphQLTypes["Contember_ContentReferenceType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_ContentReferenceType"]> | undefined,
	lt?: GraphQLTypes["Contember_ContentReferenceType"] | undefined,
	lte?: GraphQLTypes["Contember_ContentReferenceType"] | undefined,
	gt?: GraphQLTypes["Contember_ContentReferenceType"] | undefined,
	gte?: GraphQLTypes["Contember_ContentReferenceType"] | undefined
};
	["Contember_ContentReferenceType"]: Contember_ContentReferenceType;
	["Contember_LinkWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_LinkTypeCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	externalLink?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalLink?: GraphQLTypes["Contember_LinkableWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LinkWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LinkWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LinkWhere"] | undefined
};
	["Contember_LinkTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_LinkTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_LinkTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_LinkTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_LinkType"] | undefined,
	notEq?: GraphQLTypes["Contember_LinkType"] | undefined,
	in?: Array<GraphQLTypes["Contember_LinkType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_LinkType"]> | undefined,
	lt?: GraphQLTypes["Contember_LinkType"] | undefined,
	lte?: GraphQLTypes["Contember_LinkType"] | undefined,
	gt?: GraphQLTypes["Contember_LinkType"] | undefined,
	gte?: GraphQLTypes["Contember_LinkType"] | undefined
};
	["Contember_LinkType"]: Contember_LinkType;
	["Contember_LinkableWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	url?: GraphQLTypes["Contember_StringCondition"] | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostLocaleWhere"] | undefined,
	page?: GraphQLTypes["Contember_PageLocaleWhere"] | undefined,
	wikiPage?: GraphQLTypes["Contember_WikiPageWhere"] | undefined,
	redirect?: GraphQLTypes["Contember_RedirectWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LinkableWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LinkableWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LinkableWhere"] | undefined
};
	["Contember_PageLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	link?: GraphQLTypes["Contember_LinkableWhere"] | undefined,
	root?: GraphQLTypes["Contember_PageWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	seo?: GraphQLTypes["Contember_SeoWhere"] | undefined,
	content?: GraphQLTypes["Contember_ContentWhere"] | undefined,
	cover?: GraphQLTypes["Contember_CoverWhere"] | undefined,
	theme?: GraphQLTypes["Contember_PageThemeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PageLocaleWhere"] | undefined
};
	["Contember_PageWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	locales?: GraphQLTypes["Contember_PageLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PageWhere"] | undefined
};
	["Contember_SeoWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ogTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ogDescription?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ogImage?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_SeoWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_SeoWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_SeoWhere"] | undefined
};
	["Contember_PageThemeCondition"]: {
		and?: Array<GraphQLTypes["Contember_PageThemeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_PageThemeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_PageThemeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_PageTheme"] | undefined,
	notEq?: GraphQLTypes["Contember_PageTheme"] | undefined,
	in?: Array<GraphQLTypes["Contember_PageTheme"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_PageTheme"]> | undefined,
	lt?: GraphQLTypes["Contember_PageTheme"] | undefined,
	lte?: GraphQLTypes["Contember_PageTheme"] | undefined,
	gt?: GraphQLTypes["Contember_PageTheme"] | undefined,
	gte?: GraphQLTypes["Contember_PageTheme"] | undefined
};
	["Contember_PageTheme"]: Contember_PageTheme;
	["Contember_WikiPageWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	emoji?: GraphQLTypes["Contember_StringCondition"] | undefined,
	link?: GraphQLTypes["Contember_LinkableWhere"] | undefined,
	parent?: GraphQLTypes["Contember_WikiPageWhere"] | undefined,
	children?: GraphQLTypes["Contember_WikiPageWhere"] | undefined,
	content?: GraphQLTypes["Contember_ContentWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_WikiPageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_WikiPageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_WikiPageWhere"] | undefined
};
	["Contember_RedirectWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	link?: GraphQLTypes["Contember_LinkableWhere"] | undefined,
	target?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_RedirectWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_RedirectWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_RedirectWhere"] | undefined
};
	["Contember_BlogPostListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_BlogPostListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BlogPostListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BlogPostListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BlogPostListWhere"] | undefined
};
	["Contember_BlogPostListItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	caption?: GraphQLTypes["Contember_StringCondition"] | undefined,
	list?: GraphQLTypes["Contember_BlogPostListWhere"] | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_BlogPostListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_BlogPostListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_BlogPostListItemWhere"] | undefined
};
	["Contember_LinkListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_LinkListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LinkListWhere"] | undefined
};
	["Contember_LinkListItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	isPrimary?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	list?: GraphQLTypes["Contember_LinkListWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LinkListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LinkListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LinkListItemWhere"] | undefined
};
	["Contember_ContentSizeCondition"]: {
		and?: Array<GraphQLTypes["Contember_ContentSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_ContentSizeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_ContentSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_ContentSize"] | undefined,
	notEq?: GraphQLTypes["Contember_ContentSize"] | undefined,
	in?: Array<GraphQLTypes["Contember_ContentSize"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_ContentSize"]> | undefined,
	lt?: GraphQLTypes["Contember_ContentSize"] | undefined,
	lte?: GraphQLTypes["Contember_ContentSize"] | undefined,
	gt?: GraphQLTypes["Contember_ContentSize"] | undefined,
	gte?: GraphQLTypes["Contember_ContentSize"] | undefined
};
	["Contember_ContentSize"]: Contember_ContentSize;
	["Contember_HeroWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	showLogo?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	middleContent?: GraphQLTypes["Contember_StringCondition"] | undefined,
	medium?: GraphQLTypes["Contember_MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_HeroWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_HeroWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_HeroWhere"] | undefined
};
	["Contember_GalleryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_GalleryItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_GalleryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_GalleryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_GalleryWhere"] | undefined
};
	["Contember_GalleryItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	caption?: GraphQLTypes["Contember_StringCondition"] | undefined,
	list?: GraphQLTypes["Contember_GalleryWhere"] | undefined,
	medium?: GraphQLTypes["Contember_MediumWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_GalleryItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_GalleryItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_GalleryItemWhere"] | undefined
};
	["Contember_SocialsAndAppsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	instagram?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	appStore?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	googlePlay?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_SocialsAndAppsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_SocialsAndAppsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_SocialsAndAppsWhere"] | undefined
};
	["Contember_ProductGridWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	isCarousel?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	categories?: GraphQLTypes["Contember_ProductGridCategoryWhere"] | undefined,
	type?: GraphQLTypes["Contember_ProductGridTypeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductGridWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductGridWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductGridWhere"] | undefined
};
	["Contember_ProductGridCategoryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_ProductGridWhere"] | undefined,
	items?: GraphQLTypes["Contember_ProductGridItemWhere"] | undefined,
	images?: GraphQLTypes["Contember_ImageListWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductGridCategoryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductGridCategoryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductGridCategoryWhere"] | undefined
};
	["Contember_ProductGridItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	lead?: GraphQLTypes["Contember_StringCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	medium?: GraphQLTypes["Contember_MediumWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	category?: GraphQLTypes["Contember_ProductGridCategoryWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductGridItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductGridItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductGridItemWhere"] | undefined
};
	["Contember_ImageListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_ImageListItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ImageListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ImageListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ImageListWhere"] | undefined
};
	["Contember_ImageListItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	list?: GraphQLTypes["Contember_ImageListWhere"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ImageListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ImageListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ImageListItemWhere"] | undefined
};
	["Contember_ProductGridTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_ProductGridTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductGridTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_ProductGridTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	notEq?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	in?: Array<GraphQLTypes["Contember_ProductGridType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_ProductGridType"]> | undefined,
	lt?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	lte?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	gt?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	gte?: GraphQLTypes["Contember_ProductGridType"] | undefined
};
	["Contember_ProductGridType"]: Contember_ProductGridType;
	["Contember_ProductBannerListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_ProductBannerItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductBannerListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductBannerListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductBannerListWhere"] | undefined
};
	["Contember_ProductBannerItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	list?: GraphQLTypes["Contember_ProductBannerListWhere"] | undefined,
	banner?: GraphQLTypes["Contember_ProductBannerWhere"] | undefined,
	size?: GraphQLTypes["Contember_ProductBannerItemSizeCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductBannerItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductBannerItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductBannerItemWhere"] | undefined
};
	["Contember_ProductBannerWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	beforeTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	label?: GraphQLTypes["Contember_StringCondition"] | undefined,
	button?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	productImage?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductBannerWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductBannerWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductBannerWhere"] | undefined
};
	["Contember_ProductBannerItemSizeCondition"]: {
		and?: Array<GraphQLTypes["Contember_ProductBannerItemSizeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductBannerItemSizeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_ProductBannerItemSizeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined,
	notEq?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined,
	in?: Array<GraphQLTypes["Contember_ProductBannerItemSize"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_ProductBannerItemSize"]> | undefined,
	lt?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined,
	lte?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined,
	gt?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined,
	gte?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined
};
	["Contember_ProductBannerItemSize"]: Contember_ProductBannerItemSize;
	["Contember_DeliveryRegionsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_DeliveryRegionsItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryRegionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryRegionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryRegionsWhere"] | undefined
};
	["Contember_DeliveryRegionsItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	offerLabel?: GraphQLTypes["Contember_StringCondition"] | undefined,
	list?: GraphQLTypes["Contember_DeliveryRegionsWhere"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	textList?: GraphQLTypes["Contember_TextListWhere"] | undefined,
	gallery?: GraphQLTypes["Contember_ImageListWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryRegionsItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryRegionsItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryRegionsItemWhere"] | undefined
};
	["Contember_TextListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_TextItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TextListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TextListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TextListWhere"] | undefined
};
	["Contember_TextItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	text?: GraphQLTypes["Contember_StringCondition"] | undefined,
	list?: GraphQLTypes["Contember_TextListWhere"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_TextItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TextItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TextItemWhere"] | undefined
};
	["Contember_GridTilesWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_GridTileItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_GridTilesWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_GridTilesWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_GridTilesWhere"] | undefined
};
	["Contember_GridTileItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	text?: GraphQLTypes["Contember_StringCondition"] | undefined,
	list?: GraphQLTypes["Contember_GridTilesWhere"] | undefined,
	medium?: GraphQLTypes["Contember_MediumWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_GridTileItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_GridTileItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_GridTileItemWhere"] | undefined
};
	["Contember_TranslationCataloguesIdentifierWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	catalogue?: GraphQLTypes["Contember_TranslationCatalogueWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined
};
	["Contember_TranslationCatalogueWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomainWhere"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueWhere"] | undefined,
	values?: GraphQLTypes["Contember_TranslationValueWhere"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TranslationCatalogueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TranslationCatalogueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TranslationCatalogueWhere"] | undefined
};
	["Contember_TranslationDomainWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	catalogues?: GraphQLTypes["Contember_TranslationCatalogueWhere"] | undefined,
	keys?: GraphQLTypes["Contember_TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TranslationDomainWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TranslationDomainWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TranslationDomainWhere"] | undefined
};
	["Contember_TranslationKeyWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	identifier?: GraphQLTypes["Contember_StringCondition"] | undefined,
	contentType?: GraphQLTypes["Contember_TranslationContentTypeCondition"] | undefined,
	note?: GraphQLTypes["Contember_StringCondition"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomainWhere"] | undefined,
	values?: GraphQLTypes["Contember_TranslationValueWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TranslationKeyWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TranslationKeyWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TranslationKeyWhere"] | undefined
};
	["Contember_TranslationContentTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_TranslationContentTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_TranslationContentTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_TranslationContentTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	notEq?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	in?: Array<GraphQLTypes["Contember_TranslationContentType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_TranslationContentType"]> | undefined,
	lt?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	lte?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	gt?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	gte?: GraphQLTypes["Contember_TranslationContentType"] | undefined
};
	["Contember_TranslationContentType"]: Contember_TranslationContentType;
	["Contember_TranslationValueWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	value?: GraphQLTypes["Contember_StringCondition"] | undefined,
	catalogue?: GraphQLTypes["Contember_TranslationCatalogueWhere"] | undefined,
	key?: GraphQLTypes["Contember_TranslationKeyWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_TranslationValueWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_TranslationValueWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_TranslationValueWhere"] | undefined
};
	["Contember_MenuLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	root?: GraphQLTypes["Contember_SiteMenuWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	items?: GraphQLTypes["Contember_MenuItemWhere"] | undefined,
	secondaryItems?: GraphQLTypes["Contember_LinkListWhere"] | undefined,
	eshopLink?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuLocaleWhere"] | undefined
};
	["Contember_SiteMenuWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	unique?: GraphQLTypes["Contember_OneCondition"] | undefined,
	locales?: GraphQLTypes["Contember_MenuLocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_SiteMenuWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_SiteMenuWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_SiteMenuWhere"] | undefined
};
	["Contember_MenuItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	menu?: GraphQLTypes["Contember_MenuLocaleWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	subitems?: GraphQLTypes["Contember_MenuSubitemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuItemWhere"] | undefined
};
	["Contember_MenuSubitemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_MenuSubitemTypeCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	item?: GraphQLTypes["Contember_MenuItemWhere"] | undefined,
	links?: GraphQLTypes["Contember_MenuLinkListWhere"] | undefined,
	products?: GraphQLTypes["Contember_MenuProductsWhere"] | undefined,
	delivery?: GraphQLTypes["Contember_MenuDeliveryWhere"] | undefined,
	contact?: GraphQLTypes["Contember_MenuContactWhere"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuSubitemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuSubitemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuSubitemWhere"] | undefined
};
	["Contember_MenuSubitemTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_MenuSubitemTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuSubitemTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_MenuSubitemTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	notEq?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	in?: Array<GraphQLTypes["Contember_MenuSubitemType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_MenuSubitemType"]> | undefined,
	lt?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	lte?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	gt?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	gte?: GraphQLTypes["Contember_MenuSubitemType"] | undefined
};
	["Contember_MenuSubitemType"]: Contember_MenuSubitemType;
	["Contember_MenuLinkListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_MenuLinkItemWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuLinkListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuLinkListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuLinkListWhere"] | undefined
};
	["Contember_MenuLinkItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	list?: GraphQLTypes["Contember_MenuLinkListWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkWhere"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuLinkItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuLinkItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuLinkItemWhere"] | undefined
};
	["Contember_MenuProductsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	links?: GraphQLTypes["Contember_MenuLinkListWhere"] | undefined,
	buttons?: GraphQLTypes["Contember_LinkListWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuProductsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuProductsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuProductsWhere"] | undefined
};
	["Contember_MenuDeliveryWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegionsWhere"] | undefined,
	text?: GraphQLTypes["Contember_StringCondition"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndAppsWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuDeliveryWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuDeliveryWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuDeliveryWhere"] | undefined
};
	["Contember_MenuContactWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	text?: GraphQLTypes["Contember_StringCondition"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_MenuContactWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_MenuContactWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_MenuContactWhere"] | undefined
};
	["Contember_ProductLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	featureList?: GraphQLTypes["Contember_TextListWhere"] | undefined,
	printableName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	firstSticker?: GraphQLTypes["Contember_StringCondition"] | undefined,
	secondSticker?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductLocaleWhere"] | undefined
};
	["Contember_ProductRecipeLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_ProductRecipeWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductRecipeLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductRecipeLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductRecipeLocaleWhere"] | undefined
};
	["Contember_ProductRecipeWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	products?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	color?: GraphQLTypes["Contember_StringCondition"] | undefined,
	image?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	icon?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	ingredients?: GraphQLTypes["Contember_ProductIngredientItemWhere"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	locales?: GraphQLTypes["Contember_ProductRecipeLocaleWhere"] | undefined,
	gutTuning?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainerWhere"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	nutritionFacts?: GraphQLTypes["Contember_NutritionFactsWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductRecipeWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductRecipeWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductRecipeWhere"] | undefined
};
	["Contember_ProductIngredientItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	internalOrder?: GraphQLTypes["Contember_IntCondition"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipeWhere"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredientWhere"] | undefined,
	quantity?: GraphQLTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientItemWhere"] | undefined
};
	["Contember_ProductIngredientItemQuantityWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	unit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitWhere"] | undefined,
	value?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductIngredientItemQuantityWhere"] | undefined
};
	["Contember_NutritionFactsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	energyKiloJoule?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	energyKiloCalorie?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	fatsGrams?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	fatsOfWhichSaturatedGrams?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	carbohydrateGrams?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	carbohydrateOfWhichSugarsGrams?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	proteinGrams?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	fibreGrams?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	saltGrams?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_NutritionFactsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_NutritionFactsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_NutritionFactsWhere"] | undefined
};
	["Contember_DeliveryMethodLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["Contember_StringCondition"] | undefined,
	processingTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	processingText?: GraphQLTypes["Contember_StringCondition"] | undefined,
	processingIconUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	dispatchedTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	dispatchedText?: GraphQLTypes["Contember_StringCondition"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fulfilledTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fulfilledText?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	root?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryMethodLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryMethodLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryMethodLocaleWhere"] | undefined
};
	["Contember_PipelineStageLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_PipelineStageWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PipelineStageLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PipelineStageLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PipelineStageLocaleWhere"] | undefined
};
	["Contember_PipelineStageWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_PipelineStageItemWhere"] | undefined,
	pipeline?: GraphQLTypes["Contember_PipelineWhere"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	locales?: GraphQLTypes["Contember_PipelineStageLocaleWhere"] | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageSentimentWhere"] | undefined,
	type?: GraphQLTypes["Contember_PipelineStageTypeCondition"] | undefined,
	forms?: GraphQLTypes["Contember_LeadFormWhere"] | undefined,
	showItemsWeight?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	showItemsPrice?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PipelineStageWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PipelineStageWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PipelineStageWhere"] | undefined
};
	["Contember_PipelineStageItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	list?: GraphQLTypes["Contember_PipelineStageWhere"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineLeadWhere"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PipelineStageItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PipelineStageItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PipelineStageItemWhere"] | undefined
};
	["Contember_PipelineLeadWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	createdAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	stageItem?: GraphQLTypes["Contember_PipelineStageItemWhere"] | undefined,
	contactPerson?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	cafeType?: GraphQLTypes["Contember_StringCondition"] | undefined,
	offerType?: GraphQLTypes["Contember_StringCondition"] | undefined,
	ownMachine?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	customerCity?: GraphQLTypes["Contember_StringCondition"] | undefined,
	companyId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customerName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	email?: GraphQLTypes["Contember_StringCondition"] | undefined,
	phone?: GraphQLTypes["Contember_StringCondition"] | undefined,
	form?: GraphQLTypes["Contember_LeadFormWhere"] | undefined,
	meta?: GraphQLTypes["Contember_StringCondition"] | undefined,
	multiroasterType?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PipelineLeadWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PipelineLeadWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PipelineLeadWhere"] | undefined
};
	["Contember_LeadFormWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	stage?: GraphQLTypes["Contember_PipelineStageWhere"] | undefined,
	leads?: GraphQLTypes["Contember_PipelineLeadWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_LeadFormWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_LeadFormWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_LeadFormWhere"] | undefined
};
	["Contember_PipelineWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	unique?: GraphQLTypes["Contember_OneCondition"] | undefined,
	stages?: GraphQLTypes["Contember_PipelineStageWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_PipelineWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PipelineWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PipelineWhere"] | undefined
};
	["Contember_PipelineStageSentimentWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	internalName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	backgroundColor?: GraphQLTypes["Contember_StringCondition"] | undefined,
	textColor?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PipelineStageSentimentWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PipelineStageSentimentWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PipelineStageSentimentWhere"] | undefined
};
	["Contember_PipelineStageTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_PipelineStageTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_PipelineStageTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_PipelineStageTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	notEq?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	in?: Array<GraphQLTypes["Contember_PipelineStageType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_PipelineStageType"]> | undefined,
	lt?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	lte?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	gt?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	gte?: GraphQLTypes["Contember_PipelineStageType"] | undefined
};
	["Contember_PipelineStageType"]: Contember_PipelineStageType;
	["Contember_SubscriptionBoxLocaleWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	title?: GraphQLTypes["Contember_StringCondition"] | undefined,
	stickerText?: GraphQLTypes["Contember_StringCondition"] | undefined,
	descriptionText?: GraphQLTypes["Contember_StringCondition"] | undefined,
	root?: GraphQLTypes["Contember_SubscriptionBoxWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleWhere"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	texts?: GraphQLTypes["Contember_TextListWhere"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_TextListWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined
};
	["Contember_SubscriptionBoxWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	locales?: GraphQLTypes["Contember_SubscriptionBoxLocaleWhere"] | undefined,
	unique?: GraphQLTypes["Contember_OneCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_SubscriptionBoxWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_SubscriptionBoxWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_SubscriptionBoxWhere"] | undefined
};
	["Contember_PreparedPackingQuantityWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	date?: GraphQLTypes["Contember_DateCondition"] | undefined,
	quantity?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipeWhere"] | undefined,
	packing?: GraphQLTypes["Contember_ProductPackingWhere"] | undefined,
	packedInCardboard?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_PreparedPackingQuantityWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_PreparedPackingQuantityWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_PreparedPackingQuantityWhere"] | undefined
};
	["Contember_ProductStockWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	quantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	store?: GraphQLTypes["Contember_StoreWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductStockWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductStockWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductStockWhere"] | undefined
};
	["Contember_StoreWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	description?: GraphQLTypes["Contember_StringCondition"] | undefined,
	internalNote?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_StoreWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_StoreWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_StoreWhere"] | undefined
};
	["Contember_ProductGroupPriceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	priceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	group?: GraphQLTypes["Contember_CustomerGroupWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductGroupPriceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductGroupPriceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductGroupPriceWhere"] | undefined
};
	["Contember_CustomerGroupWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	productPrices?: GraphQLTypes["Contember_ProductGroupPriceWhere"] | undefined,
	customers?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	discountPermille?: GraphQLTypes["Contember_IntCondition"] | undefined,
	code?: GraphQLTypes["Contember_StringCondition"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerGroupWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerGroupWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerGroupWhere"] | undefined
};
	["Contember_ProductAvailabilityCondition"]: {
		and?: Array<GraphQLTypes["Contember_ProductAvailabilityCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductAvailabilityCondition"]> | undefined,
	not?: GraphQLTypes["Contember_ProductAvailabilityCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	notEq?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	in?: Array<GraphQLTypes["Contember_ProductAvailability"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_ProductAvailability"]> | undefined,
	lt?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	lte?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	gt?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	gte?: GraphQLTypes["Contember_ProductAvailability"] | undefined
};
	["Contember_ProductAvailability"]: Contember_ProductAvailability;
	["Contember_ProductCustomerPriceWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	groupDiscountPermille?: GraphQLTypes["Contember_IntCondition"] | undefined,
	groupPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	fullPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	discountedPriceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	priceCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	offered?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductCustomerPriceWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductCustomerPriceWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductCustomerPriceWhere"] | undefined
};
	["Contember_ProductMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	archived?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	available?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	availableQuantity?: GraphQLTypes["Contember_IntCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductMetadataWhere"] | undefined
};
	["Contember_ProductSalesDayWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	productName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	orderDate?: GraphQLTypes["Contember_DateCondition"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_IntCondition"] | undefined,
	sum?: GraphQLTypes["Contember_IntCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductSalesDayWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductSalesDayWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductSalesDayWhere"] | undefined
};
	["Contember_ProductSalesWeekWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	productName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	orderDate?: GraphQLTypes["Contember_DateCondition"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_IntCondition"] | undefined,
	sum?: GraphQLTypes["Contember_IntCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductSalesWeekWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductSalesWeekWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductSalesWeekWhere"] | undefined
};
	["Contember_ProductSalesMonthWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	productName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	orderDate?: GraphQLTypes["Contember_DateCondition"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_IntCondition"] | undefined,
	sum?: GraphQLTypes["Contember_IntCondition"] | undefined,
	product?: GraphQLTypes["Contember_ProductWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ProductSalesMonthWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ProductSalesMonthWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ProductSalesMonthWhere"] | undefined
};
	["Contember_AddressMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	deliveryZoneType?: GraphQLTypes["Contember_DeliveryZoneTypeCondition"] | undefined,
	address?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZoneWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_AddressMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_AddressMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_AddressMetadataWhere"] | undefined
};
	["Contember_DeliveryTimelinePresetWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_DeliveryTimelineTypeCondition"] | undefined,
	duration?: GraphQLTypes["Contember_StringCondition"] | undefined,
	window?: GraphQLTypes["Contember_StringCondition"] | undefined,
	deliveryMethod?: GraphQLTypes["Contember_DeliveryMethodWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_DeliveryTimelinePresetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryTimelinePresetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_DeliveryTimelinePresetWhere"] | undefined
};
	["Contember_DeliveryTimelineTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_DeliveryTimelineTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_DeliveryTimelineTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_DeliveryTimelineTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined,
	notEq?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined,
	in?: Array<GraphQLTypes["Contember_DeliveryTimelineType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_DeliveryTimelineType"]> | undefined,
	lt?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined,
	lte?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined,
	gt?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined,
	gte?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined
};
	["Contember_DeliveryTimelineType"]: Contember_DeliveryTimelineType;
	["Contember_PaymentMethodTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_PaymentMethodTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_PaymentMethodTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_PaymentMethodTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	notEq?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	in?: Array<GraphQLTypes["Contember_PaymentMethodType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_PaymentMethodType"]> | undefined,
	lt?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	lte?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	gt?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	gte?: GraphQLTypes["Contember_PaymentMethodType"] | undefined
};
	["Contember_PaymentMethodType"]: Contember_PaymentMethodType;
	["Contember_CustomerPermissionsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerPermissionsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerPermissionsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerPermissionsWhere"] | undefined
};
	["Contember_CustomerNameWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	nominative?: GraphQLTypes["Contember_StringCondition"] | undefined,
	vocative?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	aliases?: GraphQLTypes["Contember_CustomerAliasWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerNameWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerNameWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerNameWhere"] | undefined
};
	["Contember_CustomerAliasWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerAliasWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerAliasWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerAliasWhere"] | undefined
};
	["Contember_CustomerFamiliarityCondition"]: {
		and?: Array<GraphQLTypes["Contember_CustomerFamiliarityCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerFamiliarityCondition"]> | undefined,
	not?: GraphQLTypes["Contember_CustomerFamiliarityCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	notEq?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	in?: Array<GraphQLTypes["Contember_CustomerFamiliarity"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_CustomerFamiliarity"]> | undefined,
	lt?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	lte?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	gt?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	gte?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined
};
	["Contember_CustomerFamiliarity"]: Contember_CustomerFamiliarity;
	["Contember_ApplicationShortcutListWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	items?: GraphQLTypes["Contember_ApplicationShortcutListItemWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ApplicationShortcutListWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ApplicationShortcutListWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ApplicationShortcutListWhere"] | undefined
};
	["Contember_ApplicationShortcutListItemWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	application?: GraphQLTypes["Contember_ApplicationWhere"] | undefined,
	list?: GraphQLTypes["Contember_ApplicationShortcutListWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_ApplicationShortcutListItemWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ApplicationShortcutListItemWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ApplicationShortcutListItemWhere"] | undefined
};
	["Contember_ApplicationWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	order?: GraphQLTypes["Contember_IntCondition"] | undefined,
	handle?: GraphQLTypes["Contember_StringCondition"] | undefined,
	url?: GraphQLTypes["Contember_StringCondition"] | undefined,
	color?: GraphQLTypes["Contember_StringCondition"] | undefined,
	icon?: GraphQLTypes["Contember_ImageWhere"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	short?: GraphQLTypes["Contember_StringCondition"] | undefined,
	and?: Array<GraphQLTypes["Contember_ApplicationWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_ApplicationWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_ApplicationWhere"] | undefined
};
	["Contember_InvoicingPlanWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	preset?: GraphQLTypes["Contember_InvoicingPresetWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_InvoicingPlanWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_InvoicingPlanWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_InvoicingPlanWhere"] | undefined
};
	["Contember_InvoicingPresetWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	type?: GraphQLTypes["Contember_InvoicingPresetTypeCondition"] | undefined,
	startsAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	endsAt?: GraphQLTypes["Contember_DateTimeCondition"] | undefined,
	intervalOfDays?: GraphQLTypes["Contember_IntCondition"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlanWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_InvoicingPresetWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_InvoicingPresetWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_InvoicingPresetWhere"] | undefined
};
	["Contember_InvoicingPresetTypeCondition"]: {
		and?: Array<GraphQLTypes["Contember_InvoicingPresetTypeCondition"]> | undefined,
	or?: Array<GraphQLTypes["Contember_InvoicingPresetTypeCondition"]> | undefined,
	not?: GraphQLTypes["Contember_InvoicingPresetTypeCondition"] | undefined,
	null?: boolean | undefined,
	isNull?: boolean | undefined,
	eq?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	notEq?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	in?: Array<GraphQLTypes["Contember_InvoicingPresetType"]> | undefined,
	notIn?: Array<GraphQLTypes["Contember_InvoicingPresetType"]> | undefined,
	lt?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	lte?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	gt?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	gte?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined
};
	["Contember_InvoicingPresetType"]: Contember_InvoicingPresetType;
	["Contember_CustomerSpendingWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	totalCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerSpendingWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerSpendingWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerSpendingWhere"] | undefined
};
	["Contember_CustomerMetadataWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	isBusiness?: GraphQLTypes["Contember_BooleanCondition"] | undefined,
	name?: GraphQLTypes["Contember_StringCondition"] | undefined,
	fallbackName?: GraphQLTypes["Contember_StringCondition"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	ordersTotalCents?: GraphQLTypes["Contember_IntCondition"] | undefined,
	customerProfileUrl?: GraphQLTypes["Contember_StringCondition"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_CustomerMetadataWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_CustomerMetadataWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_CustomerMetadataWhere"] | undefined
};
	["Contember_GpsWhere"]: {
		id?: GraphQLTypes["Contember_UUIDCondition"] | undefined,
	latitude?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	longitude?: GraphQLTypes["Contember_FloatCondition"] | undefined,
	address?: GraphQLTypes["Contember_AddressWhere"] | undefined,
	and?: Array<GraphQLTypes["Contember_GpsWhere"] | undefined> | undefined,
	or?: Array<GraphQLTypes["Contember_GpsWhere"] | undefined> | undefined,
	not?: GraphQLTypes["Contember_GpsWhere"] | undefined
};
	["Contember_AddressOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	firstName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	lastName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	addressLine2?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	email?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	phone?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	companyName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	hidden?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	country?: GraphQLTypes["Contember_CountryOrderBy"] | undefined,
	deletedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	googlePlaceId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	raw?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	formatted?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	geocodeGeneratedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	geocodeResponse?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZoneOrderBy"] | undefined,
	meta?: GraphQLTypes["Contember_AddressMetadataOrderBy"] | undefined,
	gps?: GraphQLTypes["Contember_GpsOrderBy"] | undefined,
	geocodeValid?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fullName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	addressLine1?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	city?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	postalCode?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_OrderDirection"]: Contember_OrderDirection;
	["Contember_CustomerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodOrderBy"] | undefined,
	permissions?: GraphQLTypes["Contember_CustomerPermissionsOrderBy"] | undefined,
	group?: GraphQLTypes["Contember_CustomerGroupOrderBy"] | undefined,
	photo?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	phone?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_CustomerNameOrderBy"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	welcomeMessage?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	familiarity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	business?: GraphQLTypes["Contember_BusinessCustomerOrderBy"] | undefined,
	shortcutList?: GraphQLTypes["Contember_ApplicationShortcutListOrderBy"] | undefined,
	user?: GraphQLTypes["Contember_UserOrderBy"] | undefined,
	disabledAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlanOrderBy"] | undefined,
	email?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_BillingSubjectOrderBy"] | undefined,
	prefersPackingsWithoutCardboard?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSessionOrderBy"] | undefined,
	spending?: GraphQLTypes["Contember_CustomerSpendingOrderBy"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	meta?: GraphQLTypes["Contember_CustomerMetadataOrderBy"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CustomerPaymentMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	caption?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	data?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_PaymentMethodOrderBy"] | undefined
};
	["Contember_PaymentMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	enabled?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	blockingDispatch?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CustomerPermissionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_CustomerGroupOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	discountPermille?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ImageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	url?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	width?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	height?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	size?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	alt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CustomerNameOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	nominative?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vocative?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_BusinessCustomerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	category?: GraphQLTypes["Contember_BusinessCategoryOrderBy"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessToBusinessManagerOrderBy"] | undefined,
	logo?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentOrderBy"] | undefined
};
	["Contember_BusinessCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	specificProducts?: GraphQLTypes["Contember_ProductListOrderBy"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_ProductCategoryListOrderBy"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_ProductCategoryListOrderBy"] | undefined,
	slug?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	businessCategory?: GraphQLTypes["Contember_BusinessCategoryOrderBy"] | undefined
};
	["Contember_ProductCategoryListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategoryOrderBy"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategoryOrderBy"] | undefined
};
	["Contember_BusinessToBusinessManagerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_StaffOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	bartender?: GraphQLTypes["Contember_BartenderOrderBy"] | undefined,
	courier?: GraphQLTypes["Contember_CourierOrderBy"] | undefined,
	cleaner?: GraphQLTypes["Contember_CleanerOrderBy"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_ShiftsManagerOrderBy"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_BusinessToBusinessManagerOrderBy"] | undefined,
	user?: GraphQLTypes["Contember_UserOrderBy"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileOrderBy"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StocksManagerOrderBy"] | undefined,
	photo?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	courierManager?: GraphQLTypes["Contember_CourierManagerOrderBy"] | undefined,
	picker?: GraphQLTypes["Contember_PickerOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_RemunerationProfileOrderBy"] | undefined,
	cashier?: GraphQLTypes["Contember_CashierOrderBy"] | undefined,
	internalBuyer?: GraphQLTypes["Contember_InternalBuyerOrderBy"] | undefined,
	salesManager?: GraphQLTypes["Contember_SalesManagerOrderBy"] | undefined,
	cashierManager?: GraphQLTypes["Contember_CashierManagerOrderBy"] | undefined
};
	["Contember_BartenderOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CourierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	showInPicker?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CleanerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	canReachTopShelf?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ShiftsManagerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_UserOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	disabledAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	verifiedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	email?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deletionRequestedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deletionExpectedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deletedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	meta?: GraphQLTypes["Contember_UserMetadataOrderBy"] | undefined,
	roles?: GraphQLTypes["Contember_UserRolesOrderBy"] | undefined
};
	["Contember_UserMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	userProfileUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tenantEmail?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tenantIdentityId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	globalRoles?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	projectRoles?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	projectRolesString?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	externalIdentifiers?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	externalIdentifiersString?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	idpSlugs?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	idpSlugsString?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	user?: GraphQLTypes["Contember_UserOrderBy"] | undefined
};
	["Contember_UserRolesOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isCustomer?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isStaff?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isBartender?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isCashier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isCourier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isCleaner?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isShiftsManager?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isInternalBuyer?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isStocksManager?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isCourierManager?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isCashierManager?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isBusinessToBusinessManager?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isPicker?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	user?: GraphQLTypes["Contember_UserOrderBy"] | undefined
};
	["Contember_ShiftsProfileOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_StocksManagerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CourierManagerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PickerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_RemunerationProfileOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	workMonths?: GraphQLTypes["Contember_WorkMonthOrderBy"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined
};
	["Contember_WorkMonthOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	year?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	month?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	hoursWorked?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	billableHours?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CashierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined
};
	["Contember_InternalBuyerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined
};
	["Contember_SalesManagerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CashierManagerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	staff?: GraphQLTypes["Contember_StaffOrderBy"] | undefined
};
	["Contember_DoubleshotCoffeeEquipmentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	espressoMachine?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	espressoGrinder?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	batchBrew?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	batchGrinder?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	waterFilter?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	mechanicalFilter?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ApplicationShortcutListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_InvoicingPlanOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	preset?: GraphQLTypes["Contember_InvoicingPresetOrderBy"] | undefined
};
	["Contember_InvoicingPresetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	startsAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	endsAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	intervalOfDays?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlanOrderBy"] | undefined
};
	["Contember_BillingSubjectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fullName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	addressLine1?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	addressLine2?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	city?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	postalCode?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	email?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	phone?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	companyName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	hidden?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deletedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	country?: GraphQLTypes["Contember_CountryOrderBy"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_CountryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_AnonymousSessionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	anonymousSessionKey?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	email?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_CustomerSpendingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	totalCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_CustomerMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isBusiness?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fallbackName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ordersTotalCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customerProfileUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_DeliveryZoneOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_AddressMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveryZoneType?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	address?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZoneOrderBy"] | undefined
};
	["Contember_GpsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	latitude?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	longitude?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	address?: GraphQLTypes["Contember_AddressOrderBy"] | undefined
};
	["Contember_CustomerPaymentMethod"]: {
	__typename: "Contember_CustomerPaymentMethod",
	_meta?: GraphQLTypes["Contember_CustomerPaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	caption: string,
	data?: GraphQLTypes["Json"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	paymentMethod?: GraphQLTypes["PaymentMethod"] | undefined
};
	["Contember_CustomerPaymentMethodMeta"]: {
	__typename: "Contember_CustomerPaymentMethodMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	caption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Json"]: any;
	["PaymentMethod"]: {
	__typename: "PaymentMethod",
	_meta?: GraphQLTypes["PaymentMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch: boolean,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	channels: Array<GraphQLTypes["ChannelPayment"]>,
	reduceGratuityByPercentage: number,
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateChannels: GraphQLTypes["ChannelPaymentConnection"]
};
	["PaymentMethodMeta"]: {
	__typename: "PaymentMethodMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blockingDispatch?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	reduceGratuityByPercentage?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["DeliveryMethod"]: {
	__typename: "DeliveryMethod",
	_meta?: GraphQLTypes["DeliveryMethodMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	enabled: boolean,
	paymentMethods: Array<GraphQLTypes["PaymentMethod"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress: boolean,
	suitableForDeliveryZones: Array<GraphQLTypes["Contember_DeliveryZone"]>,
	channels: Array<GraphQLTypes["ChannelDelivery"]>,
	courierHandlers: Array<GraphQLTypes["Contember_Courier"]>,
	enableDeliveryTracking: boolean,
	locales: Array<GraphQLTypes["Contember_DeliveryMethodLocale"]>,
	timelinePreset?: GraphQLTypes["Contember_DeliveryTimelinePreset"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_DeliveryMethodLocale"] | undefined,
	paginatePaymentMethods: GraphQLTypes["PaymentMethodConnection"],
	paginateSuitableForDeliveryZones: GraphQLTypes["Contember_DeliveryZoneConnection"],
	paginateChannels: GraphQLTypes["ChannelDeliveryConnection"],
	paginateCourierHandlers: GraphQLTypes["Contember_CourierConnection"],
	paginateLocales: GraphQLTypes["Contember_DeliveryMethodLocaleConnection"]
};
	["DeliveryMethodMeta"]: {
	__typename: "DeliveryMethodMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	suitableForDeliveryZones?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courierHandlers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timelinePreset?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryZone"]: {
	__typename: "Contember_DeliveryZone",
	_meta?: GraphQLTypes["Contember_DeliveryZoneMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type: GraphQLTypes["Contember_DeliveryZoneType"],
	addresses: Array<GraphQLTypes["Address"]>,
	suitableDeliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	deliveryZonesOfTags: Array<GraphQLTypes["Contember_Tag"]>,
	deliveryZonesOfProducts: Array<GraphQLTypes["Product"]>,
	addressesMetadata: Array<GraphQLTypes["Contember_AddressMetadata"]>,
	addressesByMeta?: GraphQLTypes["Address"] | undefined,
	addressesByGps?: GraphQLTypes["Address"] | undefined,
	addressesByInvoices?: GraphQLTypes["Address"] | undefined,
	addressesByBillingAddressOfOrders?: GraphQLTypes["Address"] | undefined,
	addressesByDeliveryAddressOfOrderDeliveries?: GraphQLTypes["Address"] | undefined,
	addressesByDefaultDeliveryAddressOfCustomer?: GraphQLTypes["Address"] | undefined,
	addressesMetadataByAddress?: GraphQLTypes["Contember_AddressMetadata"] | undefined,
	paginateAddresses: GraphQLTypes["AddressConnection"],
	paginateSuitableDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"],
	paginateDeliveryZonesOfTags: GraphQLTypes["Contember_TagConnection"],
	paginateDeliveryZonesOfProducts: GraphQLTypes["ProductConnection"],
	paginateAddressesMetadata: GraphQLTypes["Contember_AddressMetadataConnection"]
};
	["Contember_DeliveryZoneMeta"]: {
	__typename: "Contember_DeliveryZoneMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addresses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	suitableDeliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZonesOfTags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZonesOfProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressesMetadata?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryMethodOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	enabled?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireDeliveryAddress?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	enableDeliveryTracking?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryTimelinePresetOrderBy"] | undefined
};
	["Contember_DeliveryTimelinePresetOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	duration?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	window?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveryMethod?: GraphQLTypes["Contember_DeliveryMethodOrderBy"] | undefined
};
	["Contember_Tag"]: {
	__typename: "Contember_Tag",
	_meta?: GraphQLTypes["Contember_TagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	customers: Array<GraphQLTypes["Contember_Customer"]>,
	products: Array<GraphQLTypes["Product"]>,
	categories: Array<GraphQLTypes["Contember_ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Contember_Channel"]>,
	nestedCategories: Array<GraphQLTypes["Contember_InheritedProductCategoryTag"]>,
	deliveryZones: Array<GraphQLTypes["Contember_DeliveryZone"]>,
	publicInChannels: Array<GraphQLTypes["Contember_Channel"]>,
	allowChannelPayments: Array<GraphQLTypes["ChannelPayment"]>,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	paginateCustomers: GraphQLTypes["Contember_CustomerConnection"],
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateCategories: GraphQLTypes["Contember_ProductCategoryConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["Contember_ChannelConnection"],
	paginateNestedCategories: GraphQLTypes["Contember_InheritedProductCategoryTagConnection"],
	paginateDeliveryZones: GraphQLTypes["Contember_DeliveryZoneConnection"],
	paginatePublicInChannels: GraphQLTypes["Contember_ChannelConnection"],
	paginateAllowChannelPayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["Contember_TagMeta"]: {
	__typename: "Contember_TagMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nestedCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicInChannels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowChannelPayments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["ProductMeta"]: {
	__typename: "ProductMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	packing?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipe?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stocks?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	groupPrices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availability?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	manager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	inheritedCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZones?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	archivedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerPrices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProduct?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	page?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesDays?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesWeeks?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesMonths?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hasSubscription?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductPacking"]: {
	__typename: "Contember_ProductPacking",
	_meta?: GraphQLTypes["Contember_ProductPackingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Contember_Image"] | undefined,
	shortName?: string | undefined,
	locales: Array<GraphQLTypes["Contember_ProductPackingLocale"]>,
	preparedPackingQuantities: Array<GraphQLTypes["Contember_PreparedPackingQuantity"]>,
	isPackedInCardboard: boolean,
	localesByLocale?: GraphQLTypes["Contember_ProductPackingLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_ProductPackingLocaleConnection"],
	paginatePreparedPackingQuantities: GraphQLTypes["Contember_PreparedPackingQuantityConnection"]
};
	["Contember_ProductPackingMeta"]: {
	__typename: "Contember_ProductPackingMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shortName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isPackedInCardboard?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Image"]: {
	__typename: "Contember_Image",
	_meta?: GraphQLTypes["Contember_ImageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined
};
	["Contember_ImageMeta"]: {
	__typename: "Contember_ImageMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	url?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	width?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	height?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	size?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	alt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductPackingLocale"]: {
	__typename: "Contember_ProductPackingLocale",
	_meta?: GraphQLTypes["Contember_ProductPackingLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["Contember_ProductPacking"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductPackingLocaleMeta"]: {
	__typename: "Contember_ProductPackingLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shortName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Locale"]: {
	__typename: "Contember_Locale",
	_meta?: GraphQLTypes["Contember_LocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	businessCategories: Array<GraphQLTypes["Contember_BusinessCategoryLocale"]>,
	productCategoryLists: Array<GraphQLTypes["Contember_ProductCategoryListLocale"]>,
	productIngredients: Array<GraphQLTypes["Contember_ProductIngredientLocale"]>,
	orderRecurrences: Array<GraphQLTypes["Contember_OrderRecurrenceLocale"]>,
	blogs: Array<GraphQLTypes["Contember_BlogLocale"]>,
	blogPosts: Array<GraphQLTypes["Contember_BlogPostLocale"]>,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifier"] | undefined,
	menus: Array<GraphQLTypes["Contember_MenuLocale"]>,
	pages: Array<GraphQLTypes["Contember_PageLocale"]>,
	productIngredientCategories: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocale"]>,
	allergens: Array<GraphQLTypes["Contember_AllergenLocale"]>,
	productPackings: Array<GraphQLTypes["Contember_ProductPackingLocale"]>,
	products: Array<GraphQLTypes["Contember_ProductLocale"]>,
	productRecipes: Array<GraphQLTypes["Contember_ProductRecipeLocale"]>,
	pointOfSaleCategories: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocale"]>,
	pointOfSaleCategoryProducts: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"]>,
	deliveryMethods: Array<GraphQLTypes["Contember_DeliveryMethodLocale"]>,
	ingredientSuppliers: Array<GraphQLTypes["Contember_IngredientSupplierLocale"]>,
	identificationCodes: Array<GraphQLTypes["Contember_IdentificationCodeLocale"]>,
	pipelineStages: Array<GraphQLTypes["Contember_PipelineStageLocale"]>,
	subscriptionBoxes: Array<GraphQLTypes["Contember_SubscriptionBoxLocale"]>,
	businessServices: Array<GraphQLTypes["Contember_BusinessServiceLocale"]>,
	businessCategoriesByRoot?: GraphQLTypes["Contember_BusinessCategoryLocale"] | undefined,
	productCategoryListsByRoot?: GraphQLTypes["Contember_ProductCategoryListLocale"] | undefined,
	productIngredientsByRoot?: GraphQLTypes["Contember_ProductIngredientLocale"] | undefined,
	orderRecurrencesByRoot?: GraphQLTypes["Contember_OrderRecurrenceLocale"] | undefined,
	blogsByRoot?: GraphQLTypes["Contember_BlogLocale"] | undefined,
	blogPostsByRoot?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsByCover?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsByContent?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsByLink?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	blogPostsBySeo?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	menusByRoot?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	menusByItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	menusBySecondaryItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	pagesByRoot?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesByLink?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesBySeo?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesByContent?: GraphQLTypes["Contember_PageLocale"] | undefined,
	pagesByCover?: GraphQLTypes["Contember_PageLocale"] | undefined,
	productIngredientCategoriesByRoot?: GraphQLTypes["Contember_ProductIngredientCategoryLocale"] | undefined,
	allergensByRoot?: GraphQLTypes["Contember_AllergenLocale"] | undefined,
	productPackingsByRoot?: GraphQLTypes["Contember_ProductPackingLocale"] | undefined,
	productsByRoot?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	productsByFeatureList?: GraphQLTypes["Contember_ProductLocale"] | undefined,
	productRecipesByRoot?: GraphQLTypes["Contember_ProductRecipeLocale"] | undefined,
	pointOfSaleCategoriesByRoot?: GraphQLTypes["Contember_PointOfSaleCategoryLocale"] | undefined,
	pointOfSaleCategoryProductsByRoot?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"] | undefined,
	deliveryMethodsByRoot?: GraphQLTypes["Contember_DeliveryMethodLocale"] | undefined,
	ingredientSuppliersByRoot?: GraphQLTypes["Contember_IngredientSupplierLocale"] | undefined,
	identificationCodesByRoot?: GraphQLTypes["Contember_IdentificationCodeLocale"] | undefined,
	pipelineStagesByRoot?: GraphQLTypes["Contember_PipelineStageLocale"] | undefined,
	subscriptionBoxesByRoot?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesByTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	subscriptionBoxesBySecondaryTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	businessServicesByRoot?: GraphQLTypes["Contember_BusinessServiceLocale"] | undefined,
	paginateBusinessCategories: GraphQLTypes["Contember_BusinessCategoryLocaleConnection"],
	paginateProductCategoryLists: GraphQLTypes["Contember_ProductCategoryListLocaleConnection"],
	paginateProductIngredients: GraphQLTypes["Contember_ProductIngredientLocaleConnection"],
	paginateOrderRecurrences: GraphQLTypes["Contember_OrderRecurrenceLocaleConnection"],
	paginateBlogs: GraphQLTypes["Contember_BlogLocaleConnection"],
	paginateBlogPosts: GraphQLTypes["Contember_BlogPostLocaleConnection"],
	paginateMenus: GraphQLTypes["Contember_MenuLocaleConnection"],
	paginatePages: GraphQLTypes["Contember_PageLocaleConnection"],
	paginateProductIngredientCategories: GraphQLTypes["Contember_ProductIngredientCategoryLocaleConnection"],
	paginateAllergens: GraphQLTypes["Contember_AllergenLocaleConnection"],
	paginateProductPackings: GraphQLTypes["Contember_ProductPackingLocaleConnection"],
	paginateProducts: GraphQLTypes["Contember_ProductLocaleConnection"],
	paginateProductRecipes: GraphQLTypes["Contember_ProductRecipeLocaleConnection"],
	paginatePointOfSaleCategories: GraphQLTypes["Contember_PointOfSaleCategoryLocaleConnection"],
	paginatePointOfSaleCategoryProducts: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleConnection"],
	paginateDeliveryMethods: GraphQLTypes["Contember_DeliveryMethodLocaleConnection"],
	paginateIngredientSuppliers: GraphQLTypes["Contember_IngredientSupplierLocaleConnection"],
	paginateIdentificationCodes: GraphQLTypes["Contember_IdentificationCodeLocaleConnection"],
	paginatePipelineStages: GraphQLTypes["Contember_PipelineStageLocaleConnection"],
	paginateSubscriptionBoxes: GraphQLTypes["Contember_SubscriptionBoxLocaleConnection"],
	paginateBusinessServices: GraphQLTypes["Contember_BusinessServiceLocaleConnection"]
};
	["Contember_LocaleMeta"]: {
	__typename: "Contember_LocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategoryLists?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productIngredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderRecurrences?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	menus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productIngredientCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allergens?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productPackings?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productRecipes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleCategoryProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredientSuppliers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identificationCodes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pipelineStages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	subscriptionBoxes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessServices?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessCategoryLocale"]: {
	__typename: "Contember_BusinessCategoryLocale",
	_meta?: GraphQLTypes["Contember_BusinessCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	title?: string | undefined
};
	["Contember_BusinessCategoryLocaleMeta"]: {
	__typename: "Contember_BusinessCategoryLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessCategory"]: {
	__typename: "Contember_BusinessCategory",
	_meta?: GraphQLTypes["Contember_BusinessCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	visibleToNewCustomers: boolean,
	locales: Array<GraphQLTypes["Contember_BusinessCategoryLocale"]>,
	internalName: string,
	businessCustomers: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	recurrences: Array<GraphQLTypes["Contember_OrderRecurrence"]>,
	specificProducts?: GraphQLTypes["Contember_ProductList"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	slug: string,
	localesByLocale?: GraphQLTypes["Contember_BusinessCategoryLocale"] | undefined,
	businessCustomersByCustomer?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	businessCustomersByBusinessServiceEvents?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	businessCustomersByDoubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BusinessCategoryLocaleConnection"],
	paginateBusinessCustomers: GraphQLTypes["Contember_BusinessCustomerConnection"],
	paginateRecurrences: GraphQLTypes["Contember_OrderRecurrenceConnection"]
};
	["Contember_BusinessCategoryMeta"]: {
	__typename: "Contember_BusinessCategoryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	visibleToNewCustomers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCustomers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recurrences?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	specificProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	slug?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_BusinessCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_LocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["Contember_TranslationCataloguesIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_BusinessCustomer"]: {
	__typename: "Contember_BusinessCustomer",
	_meta?: GraphQLTypes["Contember_BusinessCustomerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	owners: Array<GraphQLTypes["Contember_Customer"]>,
	members: Array<GraphQLTypes["Contember_Customer"]>,
	category?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessToBusinessManager"] | undefined,
	logo?: GraphQLTypes["Contember_Image"] | undefined,
	note?: string | undefined,
	businessServiceEvents: Array<GraphQLTypes["Contember_BusinessServiceEvent"]>,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_DoubleshotCoffeeEquipment"] | undefined,
	paginateOwners: GraphQLTypes["Contember_CustomerConnection"],
	paginateMembers: GraphQLTypes["Contember_CustomerConnection"],
	paginateBusinessServiceEvents: GraphQLTypes["Contember_BusinessServiceEventConnection"]
};
	["Contember_BusinessCustomerMeta"]: {
	__typename: "Contember_BusinessCustomerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	owners?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	members?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	accountManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	logo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessServiceEvents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessToBusinessManager"]: {
	__typename: "Contember_BusinessToBusinessManager",
	_meta?: GraphQLTypes["Contember_BusinessToBusinessManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	managedBusinesses: Array<GraphQLTypes["Contember_BusinessCustomer"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	managedBusinessesByCustomer?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	managedBusinessesByBusinessServiceEvents?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	managedBusinessesByDoubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	paginateManagedBusinesses: GraphQLTypes["Contember_BusinessCustomerConnection"]
};
	["Contember_BusinessToBusinessManagerMeta"]: {
	__typename: "Contember_BusinessToBusinessManagerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	managedBusinesses?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Staff"]: {
	__typename: "Contember_Staff",
	_meta?: GraphQLTypes["Contember_StaffMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	bartender?: GraphQLTypes["Contember_Bartender"] | undefined,
	courier?: GraphQLTypes["Contember_Courier"] | undefined,
	cleaner?: GraphQLTypes["Contember_Cleaner"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_ShiftsManager"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_BusinessToBusinessManager"] | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StocksManager"] | undefined,
	photo?: GraphQLTypes["Contember_Image"] | undefined,
	courierManager?: GraphQLTypes["Contember_CourierManager"] | undefined,
	picker?: GraphQLTypes["Contember_Picker"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	cashier?: GraphQLTypes["Contember_Cashier"] | undefined,
	venues: Array<GraphQLTypes["Contember_Venue"]>,
	internalBuyer?: GraphQLTypes["Contember_InternalBuyer"] | undefined,
	salesManager?: GraphQLTypes["Contember_SalesManager"] | undefined,
	cashierManager?: GraphQLTypes["Contember_CashierManager"] | undefined,
	paginateVenues: GraphQLTypes["Contember_VenueConnection"]
};
	["Contember_StaffMeta"]: {
	__typename: "Contember_StaffMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	bartender?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cleaner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stocksManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	photo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	picker?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cashier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venues?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalBuyer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	salesManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cashierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Bartender"]: {
	__typename: "Contember_Bartender",
	_meta?: GraphQLTypes["Contember_BartenderMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_BartenderMeta"]: {
	__typename: "Contember_BartenderMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Courier"]: {
	__typename: "Contember_Courier",
	_meta?: GraphQLTypes["Contember_CourierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	routes: Array<GraphQLTypes["Contember_DeliveryRoute"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryMethods: Array<GraphQLTypes["DeliveryMethod"]>,
	showInPicker: boolean,
	routesByWaypoints?: GraphQLTypes["Contember_DeliveryRoute"] | undefined,
	paginateRoutes: GraphQLTypes["Contember_DeliveryRouteConnection"],
	paginateDeliveryMethods: GraphQLTypes["DeliveryMethodConnection"]
};
	["Contember_CourierMeta"]: {
	__typename: "Contember_CourierMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	routes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showInPicker?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryRoute"]: {
	__typename: "Contember_DeliveryRoute",
	_meta?: GraphQLTypes["Contember_DeliveryRouteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	courier?: GraphQLTypes["Contember_Courier"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	waypoints: Array<GraphQLTypes["Contember_DeliveryWaypoint"]>,
	dispatchAt: string,
	waypointsByDelivery?: GraphQLTypes["Contember_DeliveryWaypoint"] | undefined,
	paginateWaypoints: GraphQLTypes["Contember_DeliveryWaypointConnection"]
};
	["Contember_DeliveryRouteMeta"]: {
	__typename: "Contember_DeliveryRouteMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	courier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	waypoints?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryWaypoint"]: {
	__typename: "Contember_DeliveryWaypoint",
	_meta?: GraphQLTypes["Contember_DeliveryWaypointMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	route?: GraphQLTypes["Contember_DeliveryRoute"] | undefined,
	sortingOrder: number,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined
};
	["Contember_DeliveryWaypointMeta"]: {
	__typename: "Contember_DeliveryWaypointMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	estimatedArrival?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	route?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sortingOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	estimatedDistance?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	estimatedDuration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	originDeparture?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	originAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	transitMode?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderDelivery"]: {
	__typename: "OrderDelivery",
	_meta?: GraphQLTypes["OrderDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["DeliveryMethod"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state: GraphQLTypes["Contember_OrderDeliveryState"],
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_DeliveryWaypoint"] | undefined,
	events: Array<GraphQLTypes["Contember_OrderEvent"]>,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_LiftagoRide"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	trackingCode?: string | undefined,
	paginateEvents: GraphQLTypes["Contember_OrderEventConnection"]
};
	["OrderDeliveryMeta"]: {
	__typename: "OrderDeliveryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveredAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expectedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	loadingDurationHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	duration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	window?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expectedEndAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveringAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	events?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	prepareAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	trackingCode?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["VatRateMeta"]: {
	__typename: "VatRateMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ratePermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channelDeliveries?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channelPayments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preset?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["ChannelDeliveryMeta"]: {
	__typename: "ChannelDeliveryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultVisible?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Channel"]: {
	__typename: "Contember_Channel",
	_meta?: GraphQLTypes["Contember_ChannelMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	disabled: boolean,
	payments: Array<GraphQLTypes["ChannelPayment"]>,
	deliveries: Array<GraphQLTypes["ChannelDelivery"]>,
	pointOfSales: Array<GraphQLTypes["Contember_PointOfSale"]>,
	carts: Array<GraphQLTypes["Contember_Cart"]>,
	customers: Array<GraphQLTypes["Contember_Customer"]>,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration: Array<GraphQLTypes["Contember_Tag"]>,
	publicTags: Array<GraphQLTypes["Contember_Tag"]>,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	cartsByItems?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByOrder?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsByPriceLines?: GraphQLTypes["Contember_Cart"] | undefined,
	cartsBySummary?: GraphQLTypes["Contember_Cart"] | undefined,
	paginatePayments: GraphQLTypes["ChannelPaymentConnection"],
	paginateDeliveries: GraphQLTypes["ChannelDeliveryConnection"],
	paginatePointOfSales: GraphQLTypes["Contember_PointOfSaleConnection"],
	paginateCarts: GraphQLTypes["Contember_CartConnection"],
	paginateCustomers: GraphQLTypes["Contember_CustomerConnection"],
	paginateAddTagsOnRegistration: GraphQLTypes["Contember_TagConnection"],
	paginatePublicTags: GraphQLTypes["Contember_TagConnection"]
};
	["Contember_ChannelMeta"]: {
	__typename: "Contember_ChannelMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	disabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveries?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addTagsOnRegistration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicTags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["ChannelPaymentMeta"]: {
	__typename: "ChannelPaymentMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireBillingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	provideReceipt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireBillingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowOnlyForTags?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TagConnection"]: {
	__typename: "Contember_TagConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TagEdge"]>
};
	["Contember_PageInfo"]: {
	__typename: "Contember_PageInfo",
	totalCount: number
};
	["Contember_TagEdge"]: {
	__typename: "Contember_TagEdge",
	node: GraphQLTypes["Contember_Tag"]
};
	["Contember_ChannelPaymentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelOrderBy"] | undefined,
	method?: GraphQLTypes["Contember_PaymentMethodOrderBy"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	requireBillingAddress?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	provideReceipt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireBillingSubject?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ChannelOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	disabled?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined
};
	["Contember_VenueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_DeadlineTemplateOrderBy"] | undefined,
	address?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_DeadlineTemplateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	startDate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	period?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	cutoff?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	closed?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined
};
	["Contember_VatRateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ratePermille?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	preset?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ChannelDeliveryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelOrderBy"] | undefined,
	method?: GraphQLTypes["Contember_DeliveryMethodOrderBy"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	defaultVisible?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PointOfSale"]: {
	__typename: "Contember_PointOfSale",
	_meta?: GraphQLTypes["Contember_PointOfSaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	code: string,
	channels: Array<GraphQLTypes["Contember_Channel"]>,
	permissions?: GraphQLTypes["Contember_PointOfSalePermissions"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	paginateChannels: GraphQLTypes["Contember_ChannelConnection"]
};
	["Contember_PointOfSaleMeta"]: {
	__typename: "Contember_PointOfSaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	permissions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSalePermissions"]: {
	__typename: "Contember_PointOfSalePermissions",
	_meta?: GraphQLTypes["Contember_PointOfSalePermissionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	canPlaceOrder?: boolean | undefined,
	pointOfSale?: GraphQLTypes["Contember_PointOfSale"] | undefined
};
	["Contember_PointOfSalePermissionsMeta"]: {
	__typename: "Contember_PointOfSalePermissionsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ChannelConnection"]: {
	__typename: "Contember_ChannelConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ChannelEdge"]>
};
	["Contember_ChannelEdge"]: {
	__typename: "Contember_ChannelEdge",
	node: GraphQLTypes["Contember_Channel"]
};
	["Contember_PointOfSaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	permissions?: GraphQLTypes["Contember_PointOfSalePermissionsOrderBy"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PointOfSalePermissionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	pointOfSale?: GraphQLTypes["Contember_PointOfSaleOrderBy"] | undefined
};
	["Contember_Cart"]: {
	__typename: "Contember_Cart",
	_meta?: GraphQLTypes["Contember_CartMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items: Array<GraphQLTypes["Contember_CartItem"]>,
	handle?: string | undefined,
	state: GraphQLTypes["Contember_CartState"],
	createdAt: GraphQLTypes["DateTime"],
	channel?: GraphQLTypes["Contember_Channel"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	deliveryAddress?: GraphQLTypes["Address"] | undefined,
	billingAddress?: GraphQLTypes["Address"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["ChannelDelivery"] | undefined,
	payment?: GraphQLTypes["ChannelPayment"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	priceLines: Array<GraphQLTypes["Contember_CartPriceLine"]>,
	summary?: GraphQLTypes["Contember_CartSummary"] | undefined,
	itemsByProduct?: GraphQLTypes["Contember_CartItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_CartItemConnection"],
	paginatePriceLines: GraphQLTypes["Contember_CartPriceLineConnection"]
};
	["Contember_CartMeta"]: {
	__typename: "Contember_CartMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	confirmedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	handle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryOption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentOption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	summary?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CartItem"]: {
	__typename: "Contember_CartItem",
	_meta?: GraphQLTypes["Contember_CartItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	cart?: GraphQLTypes["Contember_Cart"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	fulfillmentNote?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["Contember_CartItemMeta"]: {
	__typename: "Contember_CartItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FulfillmentNote"]: {
	__typename: "Contember_FulfillmentNote",
	_meta?: GraphQLTypes["Contember_FulfillmentNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	note: string,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	venues: Array<GraphQLTypes["Contember_Venue"]>,
	cartItems: Array<GraphQLTypes["Contember_CartItem"]>,
	orderItems: Array<GraphQLTypes["OrderItem"]>,
	orders: Array<GraphQLTypes["Order"]>,
	highlight: boolean,
	createdAt: GraphQLTypes["DateTime"],
	emoji?: string | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateVenues: GraphQLTypes["Contember_VenueConnection"],
	paginateCartItems: GraphQLTypes["Contember_CartItemConnection"],
	paginateOrderItems: GraphQLTypes["OrderItemConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["Contember_FulfillmentNoteMeta"]: {
	__typename: "Contember_FulfillmentNoteMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venues?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cartItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	highlight?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	emoji?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	packing?: GraphQLTypes["Contember_ProductPackingOrderBy"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipeOrderBy"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	availability?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	manager?: GraphQLTypes["Contember_StocksManagerOrderBy"] | undefined,
	maximumQuantityPerOrder?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	archivedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	virtualProduct?: GraphQLTypes["Contember_VirtualProductOrderBy"] | undefined,
	meta?: GraphQLTypes["Contember_ProductMetadataOrderBy"] | undefined,
	page?: GraphQLTypes["Contember_PageOrderBy"] | undefined,
	hasSubscription?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	pointOfSaleTileColor?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductPackingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	volumeMl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	icon?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	shortName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isPackedInCardboard?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductRecipeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	color?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	icon?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	gutTuning?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainerOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	nutritionFacts?: GraphQLTypes["Contember_NutritionFactsOrderBy"] | undefined
};
	["Contember_FreshingContainerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	volumeMl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	workspace?: GraphQLTypes["Contember_WorkspaceOrderBy"] | undefined
};
	["Contember_WorkspaceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unique?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderDeadlineTime?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderUrlTemplate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	messengerUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	messengerIosUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	openingHours?: GraphQLTypes["Contember_WeekHoursOrderBy"] | undefined,
	workingHours?: GraphQLTypes["Contember_WeekHoursOrderBy"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_FreshingContainerOrderBy"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_VirtualProductOrderBy"] | undefined,
	discountVatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	businessAddress?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	migrationTest?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_WeekHoursOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	mon?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tue?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	wed?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	thu?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fri?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	sat?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	sun?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_VirtualProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	voucherCreditCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	recipientEmail?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_NutritionFactsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	energyKiloJoule?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	energyKiloCalorie?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fatsGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fatsOfWhichSaturatedGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	carbohydrateGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	carbohydrateOfWhichSugarsGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	proteinGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fibreGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	saltGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	archived?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	available?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	availableQuantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_PageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_Venue"]: {
	__typename: "Contember_Venue",
	_meta?: GraphQLTypes["Contember_VenueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	deadlineTemplate?: GraphQLTypes["Contember_DeadlineTemplate"] | undefined,
	timetableDays: Array<GraphQLTypes["Contember_TimetableDay"]>,
	timetableTemplates: Array<GraphQLTypes["Contember_TimetableTemplate"]>,
	staffMembers: Array<GraphQLTypes["Contember_Staff"]>,
	employeeRoles: Array<GraphQLTypes["Contember_VenueEmployeeRole"]>,
	pointOfSaleApplicationDevices: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	pointOfSaleTerminals: Array<GraphQLTypes["Contember_PointOfSaleTerminal"]>,
	pointOfSalePrinters: Array<GraphQLTypes["Contember_PointOfSalePrinter"]>,
	orders: Array<GraphQLTypes["Order"]>,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	areas: Array<GraphQLTypes["Contember_Area"]>,
	enabledUnassignReasons: Array<GraphQLTypes["Contember_UnassignReason"]>,
	categories: Array<GraphQLTypes["Contember_PointOfSaleCategory"]>,
	channels: Array<GraphQLTypes["Contember_Channel"]>,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView: GraphQLTypes["Contember_PointOfSaleInitialView"],
	pointOfSalePreferPrintReceipt: boolean,
	timetableDaysByDate?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableDaysByShiftGroups?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableDaysByNotes?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableDaysByMeta?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	timetableTemplatesByStaffOptions?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	employeeRolesByRole?: GraphQLTypes["Contember_VenueEmployeeRole"] | undefined,
	employeeRolesByEmployeePrivileges?: GraphQLTypes["Contember_VenueEmployeeRole"] | undefined,
	pointOfSaleApplicationDevicesByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	pointOfSaleTerminalsByApplicationDevices?: GraphQLTypes["Contember_PointOfSaleTerminal"] | undefined,
	pointOfSalePrintersByApplicationDevicesReceipt?: GraphQLTypes["Contember_PointOfSalePrinter"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByEvents?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ordersByGratuity?: GraphQLTypes["Order"] | undefined,
	areasByTables?: GraphQLTypes["Contember_Area"] | undefined,
	areasByLines?: GraphQLTypes["Contember_Area"] | undefined,
	categoriesBySlug?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	categoriesByLocales?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	categoriesByProducts?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	channelsByCode?: GraphQLTypes["Contember_Channel"] | undefined,
	channelsByPayments?: GraphQLTypes["Contember_Channel"] | undefined,
	channelsByDeliveries?: GraphQLTypes["Contember_Channel"] | undefined,
	channelsByCarts?: GraphQLTypes["Contember_Channel"] | undefined,
	paginateTimetableDays: GraphQLTypes["Contember_TimetableDayConnection"],
	paginateTimetableTemplates: GraphQLTypes["Contember_TimetableTemplateConnection"],
	paginateStaffMembers: GraphQLTypes["Contember_StaffConnection"],
	paginateEmployeeRoles: GraphQLTypes["Contember_VenueEmployeeRoleConnection"],
	paginatePointOfSaleApplicationDevices: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"],
	paginatePointOfSaleTerminals: GraphQLTypes["Contember_PointOfSaleTerminalConnection"],
	paginatePointOfSalePrinters: GraphQLTypes["Contember_PointOfSalePrinterConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"],
	paginateAreas: GraphQLTypes["Contember_AreaConnection"],
	paginateEnabledUnassignReasons: GraphQLTypes["Contember_UnassignReasonConnection"],
	paginateCategories: GraphQLTypes["Contember_PointOfSaleCategoryConnection"],
	paginateChannels: GraphQLTypes["Contember_ChannelConnection"]
};
	["Contember_VenueMeta"]: {
	__typename: "Contember_VenueMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableDays?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staffMembers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employeeRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleApplicationDevices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleTerminals?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSalePrinters?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	areas?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	enabledUnassignReasons?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channels?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSalePngBase64ReceiptLogo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pointOfSalePreferPrintReceipt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeadlineTemplate"]: {
	__typename: "Contember_DeadlineTemplate",
	_meta?: GraphQLTypes["Contember_DeadlineTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	startDate: GraphQLTypes["Date"],
	period: number,
	cutoff?: number | undefined,
	closed?: boolean | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined
};
	["Contember_DeadlineTemplateMeta"]: {
	__typename: "Contember_DeadlineTemplateMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	period?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cutoff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	closed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableDay"]: {
	__typename: "Contember_TimetableDay",
	_meta?: GraphQLTypes["Contember_TimetableDayMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	type?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	shiftGroups: Array<GraphQLTypes["Contember_TimetableShiftGroup"]>,
	tipsCents?: number | undefined,
	notes: Array<GraphQLTypes["Contember_TimetableDayNote"]>,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayMetadata"] | undefined,
	shiftGroupsByShifts?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	paginateShiftGroups: GraphQLTypes["Contember_TimetableShiftGroupConnection"],
	paginateNotes: GraphQLTypes["Contember_TimetableDayNoteConnection"]
};
	["Contember_TimetableDayMeta"]: {
	__typename: "Contember_TimetableDayMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	date?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tipsCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftGroup"]: {
	__typename: "Contember_TimetableShiftGroup",
	_meta?: GraphQLTypes["Contember_TimetableShiftGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	shifts: Array<GraphQLTypes["Contember_TimetableShift"]>,
	day?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	type?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPosition"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueue"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	shiftsBySwapInfo?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	paginateShifts: GraphQLTypes["Contember_TimetableShiftConnection"]
};
	["Contember_TimetableShiftGroupMeta"]: {
	__typename: "Contember_TimetableShiftGroupMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shifts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	day?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	position?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShift"]: {
	__typename: "Contember_TimetableShift",
	_meta?: GraphQLTypes["Contember_TimetableShiftMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locked: boolean,
	order: number,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftSwapInfo"] | undefined,
	isOvertime: boolean
};
	["Contember_TimetableShiftMeta"]: {
	__typename: "Contember_TimetableShiftMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locked?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	assigned?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	group?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	swapInfo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isOvertime?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ShiftsProfile"]: {
	__typename: "Contember_ShiftsProfile",
	_meta?: GraphQLTypes["Contember_ShiftsProfileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	queueItems: Array<GraphQLTypes["Contember_EmployeeQueueItem"]>,
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	roles: Array<GraphQLTypes["Contember_TimetableEmployeeRole"]>,
	shifts: Array<GraphQLTypes["Contember_TimetableShift"]>,
	allowSelfUnassigning?: boolean | undefined,
	queueItemsByQueue?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	queueItemsByShift?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	queueItemsByUnassignInfo?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	queueItemsByMeta?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	shiftsByQueueItem?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	shiftsBySwapInfo?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	paginateQueueItems: GraphQLTypes["Contember_EmployeeQueueItemConnection"],
	paginateRoles: GraphQLTypes["Contember_TimetableEmployeeRoleConnection"],
	paginateShifts: GraphQLTypes["Contember_TimetableShiftConnection"]
};
	["Contember_ShiftsProfileMeta"]: {
	__typename: "Contember_ShiftsProfileMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	roles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shifts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowSelfUnassigning?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmployeeQueueItem"]: {
	__typename: "Contember_EmployeeQueueItem",
	_meta?: GraphQLTypes["Contember_EmployeeQueueItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueue"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShift"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_UnassignedShiftInfo"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadata"] | undefined
};
	["Contember_EmployeeQueueItemMeta"]: {
	__typename: "Contember_EmployeeQueueItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	start?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	end?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shift?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmployeeQueue"]: {
	__typename: "Contember_EmployeeQueue",
	_meta?: GraphQLTypes["Contember_EmployeeQueueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroup?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	items: Array<GraphQLTypes["Contember_EmployeeQueueItem"]>,
	contemberBugFix?: boolean | undefined,
	itemsByShiftsProfile?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	itemsByShift?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	itemsByUnassignInfo?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	itemsByMeta?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_EmployeeQueueItemConnection"]
};
	["Contember_EmployeeQueueMeta"]: {
	__typename: "Contember_EmployeeQueueMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroup?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contemberBugFix?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmployeeQueueItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	start?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	end?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueOrderBy"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileOrderBy"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShiftOrderBy"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_UnassignedShiftInfoOrderBy"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadataOrderBy"] | undefined
};
	["Contember_EmployeeQueueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	shiftGroup?: GraphQLTypes["Contember_TimetableShiftGroupOrderBy"] | undefined,
	contemberBugFix?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TimetableShiftGroupOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	day?: GraphQLTypes["Contember_TimetableDayOrderBy"] | undefined,
	type?: GraphQLTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPositionOrderBy"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueOrderBy"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableTemplateOrderBy"] | undefined
};
	["Contember_TimetableDayOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	date?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tipsCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayMetadataOrderBy"] | undefined
};
	["Contember_TimetableDayMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	numberOfWorkers?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tipsTotalCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	day?: GraphQLTypes["Contember_TimetableDayOrderBy"] | undefined
};
	["Contember_TimetableEmployeeRoleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	color?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TimetableShiftPositionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	start?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	end?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isDynamic?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TimetableTemplateOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	startDate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	period?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	slots?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPositionOrderBy"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined,
	workdaysOnly?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	monday?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tuesday?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	wednesday?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	thursday?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	friday?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	saturday?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	sunday?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TimetableShiftOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	locked?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	assigned?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftGroupOrderBy"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileOrderBy"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemOrderBy"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftSwapInfoOrderBy"] | undefined,
	isOvertime?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TimetableShiftSwapInfoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	assigned?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requested?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	confirmed?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_ShiftsProfileOrderBy"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShiftOrderBy"] | undefined
};
	["Contember_UnassignedShiftInfoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	hoursMissed?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemOrderBy"] | undefined,
	legacyReason?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	reason?: GraphQLTypes["Contember_UnassignReasonOrderBy"] | undefined
};
	["Contember_UnassignReasonOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_EmployeeQueueItemMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemOrderBy"] | undefined,
	includeInTipsCalculation?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_EmployeeQueueItemsByShiftsProfileUniqueWhere"]: {
		shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItems?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	shifts?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined
};
	["Contember_EmployeeQueueItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined
};
	["Contember_EmployeeQueueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroup?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	items?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined
};
	["Contember_TimetableShiftGroupUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shifts?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined
};
	["Contember_TimetableShiftUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined
};
	["Contember_TimetableShiftSwapInfoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined
};
	["Contember_UnassignedShiftInfoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined
};
	["Contember_EmployeeQueueItemMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined
};
	["Contember_StaffUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_BartenderUniqueWhere"] | undefined,
	courier?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	cleaner?: GraphQLTypes["Contember_CleanerUniqueWhere"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined,
	user?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StocksManagerUniqueWhere"] | undefined,
	courierManager?: GraphQLTypes["Contember_CourierManagerUniqueWhere"] | undefined,
	picker?: GraphQLTypes["Contember_PickerUniqueWhere"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_RemunerationProfileUniqueWhere"] | undefined,
	cashier?: GraphQLTypes["Contember_CashierUniqueWhere"] | undefined,
	internalBuyer?: GraphQLTypes["Contember_InternalBuyerUniqueWhere"] | undefined,
	salesManager?: GraphQLTypes["Contember_SalesManagerUniqueWhere"] | undefined,
	cashierManager?: GraphQLTypes["Contember_CashierManagerUniqueWhere"] | undefined
};
	["Contember_BartenderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_CourierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	routes?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined
};
	["Contember_DeliveryRouteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	waypoints?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined
};
	["Contember_DeliveryWaypointUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_OrderDeliveryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	trackingCode?: string | undefined,
	order?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_LiftagoRideUniqueWhere"] | undefined
};
	["Contember_OrderUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	seq?: number | undefined,
	doubleshotLegacyId?: string | undefined,
	doubleShotLegacyNumber?: string | undefined,
	discounts?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	items?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	priceLines?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined,
	payments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	summary?: GraphQLTypes["Contember_OrderSummaryUniqueWhere"] | undefined,
	gratuity?: GraphQLTypes["Contember_GratuityUniqueWhere"] | undefined
};
	["Contember_OrderDiscountUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customerCreditTransactions?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_CustomerCreditTransactionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined
};
	["Contember_VirtualProductEffectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	resultVouchers?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined
};
	["Contember_VoucherUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	redemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_VoucherRedemptionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined
};
	["Contember_CustomerCreditUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	transactions?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_OrderItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	virtualProductEffects?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined
};
	["Contember_CartUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	order?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	priceLines?: GraphQLTypes["Contember_CartPriceLineUniqueWhere"] | undefined,
	summary?: GraphQLTypes["Contember_CartSummaryUniqueWhere"] | undefined
};
	["Contember_CartItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_ProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	stocks?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined,
	groupPrices?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	cartItems?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	orderItems?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	inheritedCategories?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	locales?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	customerPrices?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	virtualProduct?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_ProductMetadataUniqueWhere"] | undefined,
	page?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined,
	salesDays?: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"] | undefined,
	salesWeeks?: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined,
	salesMonths?: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined
};
	["Contember_ProductStockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	store?: GraphQLTypes["Contember_StoreUniqueWhere"] | undefined
};
	["Contember_StoreUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["Contember_ProductGroupPriceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	group?: GraphQLTypes["Contember_CustomerGroupUniqueWhere"] | undefined,
	product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_CustomerGroupUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	doubleshotLegacyId?: string | undefined,
	productPrices?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	customers?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_CustomerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	doubleshotLegacyId?: string | undefined,
	addresses?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	credits?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	paymentMethods?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	orders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	permissions?: GraphQLTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined,
	name?: GraphQLTypes["Contember_CustomerNameUniqueWhere"] | undefined,
	ingredientRatings?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	business?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	carts?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	shortcutList?: GraphQLTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined,
	user?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	invoices?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlanUniqueWhere"] | undefined,
	billingSubjects?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	offeredProducts?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSessionUniqueWhere"] | undefined,
	boughtVouchers?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	ownsVouchers?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	redeemedVouchers?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	spending?: GraphQLTypes["Contember_CustomerSpendingUniqueWhere"] | undefined,
	customerTabs?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_CustomerMetadataUniqueWhere"] | undefined
};
	["Contember_AddressUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	meta?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined,
	gps?: GraphQLTypes["Contember_GpsUniqueWhere"] | undefined,
	invoices?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	billingAddressOfOrders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	deliveryAddressOfOrderDeliveries?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_AddressMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	address?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined
};
	["Contember_GpsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	address?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined
};
	["Contember_InvoiceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	orderPayments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_OrderPaymentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined
};
	["Contember_OrderEventUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_OrderPaymentMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	orderPayment?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_CustomerPaymentMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_CustomerPermissionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_CustomerNameUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_CustomerIngredientRatingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined
};
	["Contember_ProductIngredientUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	customerRatings?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	suppliers?: GraphQLTypes["Contember_IngredientSupplierListUniqueWhere"] | undefined
};
	["Contember_ProductIngredientLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_LocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	productCategoryLists?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	productIngredients?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	orderRecurrences?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	blogs?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined,
	blogPosts?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	menus?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined,
	pages?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	productIngredientCategories?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	allergens?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	productPackings?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	products?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	productRecipes?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	pointOfSaleCategories?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	pointOfSaleCategoryProducts?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	deliveryMethods?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	ingredientSuppliers?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	identificationCodes?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	pipelineStages?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	subscriptionBoxes?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined,
	businessServices?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined
};
	["Contember_BusinessCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_BusinessCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locales?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	businessCustomers?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	specificProducts?: GraphQLTypes["Contember_ProductListUniqueWhere"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined
};
	["Contember_BusinessCustomerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	businessServiceEvents?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined
};
	["Contember_BusinessServiceEventUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_DoubleshotCoffeeEquipmentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_ProductListItemUniqueWhere"] | undefined,
	businessCategory?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined
};
	["Contember_ProductListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductCategoryListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined
};
	["Contember_ProductCategoryListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductCategoryListLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_OrderRecurrenceLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_OrderRecurrenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined
};
	["Contember_BlogLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_BlogUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_BlogUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	locales?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined
};
	["Contember_BlogPostLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_BlogPostUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	cover?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined,
	content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined
};
	["Contember_BlogPostUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined
};
	["Contember_CoverUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ContentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: GraphQLTypes["Contember_ContentBlockUniqueWhere"] | undefined
};
	["Contember_ContentBlockUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	references?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined
};
	["Contember_ContentReferenceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["Contember_ProductListUniqueWhere"] | undefined,
	productBanners?: GraphQLTypes["Contember_ProductBannerListUniqueWhere"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined
};
	["Contember_ProductBannerListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"] | undefined
};
	["Contember_ProductBannerItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_DeliveryRegionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined
};
	["Contember_DeliveryRegionsItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	textList?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	gallery?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined
};
	["Contember_ImageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_TextListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_TextItemUniqueWhere"] | undefined
};
	["Contember_TextItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ImageListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_ImageListItemUniqueWhere"] | undefined
};
	["Contember_ImageListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	image?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined
};
	["Contember_LinkableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	page?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	wikiPage?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	redirect?: GraphQLTypes["Contember_RedirectUniqueWhere"] | undefined
};
	["Contember_PageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	seo?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined,
	content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	cover?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined
};
	["Contember_PageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined
};
	["Contember_SeoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	ogImage?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined
};
	["Contember_WikiPageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	children?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined
};
	["Contember_RedirectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined
};
	["Contember_TranslationCataloguesIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	catalogue?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined
};
	["Contember_TranslationCatalogueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomainUniqueWhere"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	values?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined
};
	["Contember_TranslationDomainUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	catalogues?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	keys?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined
};
	["Contember_TranslationKeyUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomainUniqueWhere"] | undefined,
	identifier?: string | undefined,
	values?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined
};
	["Contember_TranslationValueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	catalogue?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	key?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined
};
	["Contember_MenuLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_SiteMenuUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	items?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined,
	secondaryItems?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined
};
	["Contember_SiteMenuUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	locales?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined
};
	["Contember_MenuItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	subitems?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined
};
	["Contember_MenuSubitemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_LinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_LinkListItemUniqueWhere"] | undefined
};
	["Contember_LinkListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_ProductIngredientCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined
};
	["Contember_AllergenLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_AllergenUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: number | undefined,
	locales?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined
};
	["Contember_ProductPackingLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_ProductPackingUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_ProductPackingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	preparedPackingQuantities?: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined
};
	["Contember_PreparedPackingQuantityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined,
	packing?: GraphQLTypes["Contember_ProductPackingUniqueWhere"] | undefined,
	packedInCardboard?: boolean | undefined
};
	["Contember_ProductRecipeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	products?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	ingredients?: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	nutritionFacts?: GraphQLTypes["Contember_NutritionFactsUniqueWhere"] | undefined
};
	["Contember_ProductIngredientItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	quantity?: GraphQLTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined
};
	["Contember_ProductIngredientItemQuantityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductRecipeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_NutritionFactsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	featureList?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	slug?: string | undefined,
	locales?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	products?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_DeliveryMethodLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	root?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined
};
	["Contember_DeliveryMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	channels?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	locales?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryTimelinePresetUniqueWhere"] | undefined
};
	["Contember_ChannelDeliveryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_DeliveryTimelinePresetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deliveryMethod?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined
};
	["Contember_IngredientSupplierLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_IngredientSupplierUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_IngredientSupplierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined
};
	["Contember_IdentificationCodeLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_IdentificationCodeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	attempts?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	locales?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined
};
	["Contember_IdentificationAttemptUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined
};
	["Contember_DeviceIdentifierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	device?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_DeviceIdentifierMetadataUniqueWhere"] | undefined
};
	["Contember_DeviceIdentifierMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deviceIdentifier?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined
};
	["Contember_PipelineStageLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_PipelineStageUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	locales?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	forms?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined
};
	["Contember_PipelineStageItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined
};
	["Contember_PipelineLeadUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	stageItem?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined
};
	["Contember_LeadFormUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	leads?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined
};
	["Contember_SubscriptionBoxLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	texts?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_SubscriptionBoxUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	locales?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined
};
	["Contember_BusinessServiceLocaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_BusinessServiceUniqueWhere"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_BusinessServiceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined
};
	["Contember_IngredientSupplierListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined
};
	["Contember_IngredientSupplierItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ApplicationShortcutListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_ApplicationShortcutListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_UserUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	devices?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_UserMetadataUniqueWhere"] | undefined,
	identificationAttempts?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	roles?: GraphQLTypes["Contember_UserRolesUniqueWhere"] | undefined
};
	["Contember_UserDeviceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deviceId?: string | undefined
};
	["Contember_UserMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	tenantEmail?: string | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	tenantIdentityId?: GraphQLTypes["UUID"] | undefined,
	user?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined
};
	["Contember_UserRolesUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	user?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined
};
	["Contember_InvoicingPlanUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	preset?: GraphQLTypes["Contember_InvoicingPresetUniqueWhere"] | undefined
};
	["Contember_InvoicingPresetUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlanUniqueWhere"] | undefined
};
	["Contember_BillingSubjectUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	invoices?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	orders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_ProductCustomerPriceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_AnonymousSessionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	anonymousSessionKey?: string | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	devices?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined
};
	["Contember_CustomerSpendingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_CustomerTabUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined
};
	["Contember_CustomerTabItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_CustomerTabMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customerTab?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined
};
	["Contember_CustomerMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_NestedProductCategoryProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_VirtualProductUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_ProductMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_ProductSalesDayUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductSalesWeekUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductSalesMonthUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_CartPriceLineUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_CartSummaryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_OrderPriceLineUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	orderItem?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	orderPayment?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	orderDelivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_OrderSummaryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_GratuityUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_LiftagoRideUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_CleanerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_ShiftsManagerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_BusinessToBusinessManagerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	managedBusinesses?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined
};
	["Contember_StocksManagerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	managedProducts?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_CourierManagerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_PickerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_RemunerationProfileUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	employmentInformation?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_EmploymentInformationUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_CashierUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_InternalBuyerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_SalesManagerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_CashierManagerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_EmployeeQueueItemsByShiftUniqueWhere"]: {
		shift?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined
};
	["Contember_EmployeeQueueItemsByUnassignInfoUniqueWhere"]: {
		unassignInfo?: GraphQLTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined
};
	["Contember_EmployeeQueueItemsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined
};
	["Contember_EmployeeQueueItemConnection"]: {
	__typename: "Contember_EmployeeQueueItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_EmployeeQueueItemEdge"]>
};
	["Contember_EmployeeQueueItemEdge"]: {
	__typename: "Contember_EmployeeQueueItemEdge",
	node: GraphQLTypes["Contember_EmployeeQueueItem"]
};
	["Contember_UnassignedShiftInfo"]: {
	__typename: "Contember_UnassignedShiftInfo",
	_meta?: GraphQLTypes["Contember_UnassignedShiftInfoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	hoursMissed?: number | undefined,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	legacyReason?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["Contember_UnassignReason"] | undefined
};
	["Contember_UnassignedShiftInfoMeta"]: {
	__typename: "Contember_UnassignedShiftInfoMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hoursMissed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	legacyReason?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	reason?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UnassignReason"]: {
	__typename: "Contember_UnassignReason",
	_meta?: GraphQLTypes["Contember_UnassignReasonMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	venues: Array<GraphQLTypes["Contember_Venue"]>,
	paginateVenues: GraphQLTypes["Contember_VenueConnection"]
};
	["Contember_UnassignReasonMeta"]: {
	__typename: "Contember_UnassignReasonMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venues?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VenueConnection"]: {
	__typename: "Contember_VenueConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VenueEdge"]>
};
	["Contember_VenueEdge"]: {
	__typename: "Contember_VenueEdge",
	node: GraphQLTypes["Contember_Venue"]
};
	["Contember_EmployeeQueueItemMetadata"]: {
	__typename: "Contember_EmployeeQueueItemMetadata",
	_meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalMinutes: number,
	queueItem?: GraphQLTypes["Contember_EmployeeQueueItem"] | undefined,
	includeInTipsCalculation: boolean
};
	["Contember_EmployeeQueueItemMetadataMeta"]: {
	__typename: "Contember_EmployeeQueueItemMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	queueItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	includeInTipsCalculation?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableEmployeeRole"]: {
	__typename: "Contember_TimetableEmployeeRole",
	_meta?: GraphQLTypes["Contember_TimetableEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shiftGroups: Array<GraphQLTypes["Contember_TimetableShiftGroup"]>,
	shiftsProfiles: Array<GraphQLTypes["Contember_ShiftsProfile"]>,
	shiftsManagers: Array<GraphQLTypes["Contember_ShiftsManager"]>,
	name: string,
	timetableTemplates: Array<GraphQLTypes["Contember_TimetableTemplate"]>,
	color: string,
	shiftGroupsByShifts?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	timetableTemplatesByStaffOptions?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	paginateShiftGroups: GraphQLTypes["Contember_TimetableShiftGroupConnection"],
	paginateShiftsProfiles: GraphQLTypes["Contember_ShiftsProfileConnection"],
	paginateShiftsManagers: GraphQLTypes["Contember_ShiftsManagerConnection"],
	paginateTimetableTemplates: GraphQLTypes["Contember_TimetableTemplateConnection"]
};
	["Contember_TimetableEmployeeRoleMeta"]: {
	__typename: "Contember_TimetableEmployeeRoleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsProfiles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftsManagers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ShiftsManager"]: {
	__typename: "Contember_ShiftsManager",
	_meta?: GraphQLTypes["Contember_ShiftsManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	managedRoles: Array<GraphQLTypes["Contember_TimetableEmployeeRole"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	paginateManagedRoles: GraphQLTypes["Contember_TimetableEmployeeRoleConnection"]
};
	["Contember_ShiftsManagerMeta"]: {
	__typename: "Contember_ShiftsManagerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	managedRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableEmployeeRoleConnection"]: {
	__typename: "Contember_TimetableEmployeeRoleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableEmployeeRoleEdge"]>
};
	["Contember_TimetableEmployeeRoleEdge"]: {
	__typename: "Contember_TimetableEmployeeRoleEdge",
	node: GraphQLTypes["Contember_TimetableEmployeeRole"]
};
	["Contember_TimetableTemplate"]: {
	__typename: "Contember_TimetableTemplate",
	_meta?: GraphQLTypes["Contember_TimetableTemplateMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	startDate: GraphQLTypes["Date"],
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftPosition"] | undefined,
	staffOptions: Array<GraphQLTypes["Contember_TimetableTemplateStaffOptions"]>,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday: boolean,
	tuesday: boolean,
	wednesday: boolean,
	thursday: boolean,
	friday: boolean,
	saturday: boolean,
	sunday: boolean,
	type: GraphQLTypes["Contember_TemplateType"],
	paginateStaffOptions: GraphQLTypes["Contember_TimetableTemplateStaffOptionsConnection"]
};
	["Contember_TimetableTemplateMeta"]: {
	__typename: "Contember_TimetableTemplateMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	period?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	slots?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	position?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staffOptions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employeeRole?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workdaysOnly?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	monday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tuesday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	wednesday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	thursday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	friday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	saturday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sunday?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftPosition"]: {
	__typename: "Contember_TimetableShiftPosition",
	_meta?: GraphQLTypes["Contember_TimetableShiftPositionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	shiftGroups: Array<GraphQLTypes["Contember_TimetableShiftGroup"]>,
	timetableTemplates: Array<GraphQLTypes["Contember_TimetableTemplate"]>,
	isDynamic: boolean,
	shiftGroupsByShifts?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	shiftGroupsByQueue?: GraphQLTypes["Contember_TimetableShiftGroup"] | undefined,
	timetableTemplatesByStaffOptions?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	paginateShiftGroups: GraphQLTypes["Contember_TimetableShiftGroupConnection"],
	paginateTimetableTemplates: GraphQLTypes["Contember_TimetableTemplateConnection"]
};
	["Contember_TimetableShiftPositionMeta"]: {
	__typename: "Contember_TimetableShiftPositionMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	start?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	end?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isDynamic?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftPositionShiftGroupsByShiftsUniqueWhere"]: {
		shifts?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined
};
	["Contember_TimetableShiftPositionShiftGroupsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined
};
	["Contember_TimetableShiftPositionTimetableTemplatesByStaffOptionsUniqueWhere"]: {
		staffOptions?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_TimetableShiftGroupConnection"]: {
	__typename: "Contember_TimetableShiftGroupConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableShiftGroupEdge"]>
};
	["Contember_TimetableShiftGroupEdge"]: {
	__typename: "Contember_TimetableShiftGroupEdge",
	node: GraphQLTypes["Contember_TimetableShiftGroup"]
};
	["Contember_TimetableTemplateConnection"]: {
	__typename: "Contember_TimetableTemplateConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableTemplateEdge"]>
};
	["Contember_TimetableTemplateEdge"]: {
	__typename: "Contember_TimetableTemplateEdge",
	node: GraphQLTypes["Contember_TimetableTemplate"]
};
	["Contember_TimetableTemplateStaffOptions"]: {
	__typename: "Contember_TimetableTemplateStaffOptions",
	_meta?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	startTime: string,
	endTime: string,
	timetableTemplate?: GraphQLTypes["Contember_TimetableTemplate"] | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_ShiftsProfile"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsMeta"]: {
	__typename: "Contember_TimetableTemplateStaffOptionsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startTime?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	endTime?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	timetableTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	startTime?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	endTime?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	timetableTemplate?: GraphQLTypes["Contember_TimetableTemplateOrderBy"] | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_ShiftsProfileOrderBy"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsConnection"]: {
	__typename: "Contember_TimetableTemplateStaffOptionsConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableTemplateStaffOptionsEdge"]>
};
	["Contember_TimetableTemplateStaffOptionsEdge"]: {
	__typename: "Contember_TimetableTemplateStaffOptionsEdge",
	node: GraphQLTypes["Contember_TimetableTemplateStaffOptions"]
};
	["Contember_TimetableEmployeeRoleShiftGroupsByShiftsUniqueWhere"]: {
		shifts?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined
};
	["Contember_TimetableEmployeeRoleShiftGroupsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined
};
	["Contember_TimetableEmployeeRoleTimetableTemplatesByStaffOptionsUniqueWhere"]: {
		staffOptions?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileConnection"]: {
	__typename: "Contember_ShiftsProfileConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ShiftsProfileEdge"]>
};
	["Contember_ShiftsProfileEdge"]: {
	__typename: "Contember_ShiftsProfileEdge",
	node: GraphQLTypes["Contember_ShiftsProfile"]
};
	["Contember_ShiftsManagerConnection"]: {
	__typename: "Contember_ShiftsManagerConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ShiftsManagerEdge"]>
};
	["Contember_ShiftsManagerEdge"]: {
	__typename: "Contember_ShiftsManagerEdge",
	node: GraphQLTypes["Contember_ShiftsManager"]
};
	["Contember_ShiftsProfileQueueItemsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileQueueItemsByShiftUniqueWhere"]: {
		shift?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileQueueItemsByUnassignInfoUniqueWhere"]: {
		unassignInfo?: GraphQLTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileQueueItemsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileShiftsByQueueItemUniqueWhere"]: {
		queueItem?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileShiftsBySwapInfoUniqueWhere"]: {
		swapInfo?: GraphQLTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined
};
	["Contember_TimetableShiftConnection"]: {
	__typename: "Contember_TimetableShiftConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableShiftEdge"]>
};
	["Contember_TimetableShiftEdge"]: {
	__typename: "Contember_TimetableShiftEdge",
	node: GraphQLTypes["Contember_TimetableShift"]
};
	["Contember_TimetableShiftSwapInfo"]: {
	__typename: "Contember_TimetableShiftSwapInfo",
	_meta?: GraphQLTypes["Contember_TimetableShiftSwapInfoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	assigned?: GraphQLTypes["Date"] | undefined,
	requested?: boolean | undefined,
	confirmed?: boolean | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_ShiftsProfile"] | undefined,
	shift?: GraphQLTypes["Contember_TimetableShift"] | undefined
};
	["Contember_TimetableShiftSwapInfoMeta"]: {
	__typename: "Contember_TimetableShiftSwapInfoMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	assigned?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requested?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	confirmed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shift?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableShiftGroupShiftsByQueueItemUniqueWhere"]: {
		queueItem?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined
};
	["Contember_TimetableShiftGroupShiftsBySwapInfoUniqueWhere"]: {
		swapInfo?: GraphQLTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined
};
	["Contember_TimetableDayNote"]: {
	__typename: "Contember_TimetableDayNote",
	_meta?: GraphQLTypes["Contember_TimetableDayNoteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	note: string,
	day?: GraphQLTypes["Contember_TimetableDay"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined
};
	["Contember_TimetableDayNoteMeta"]: {
	__typename: "Contember_TimetableDayNoteMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	day?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	role?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableDayNoteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	day?: GraphQLTypes["Contember_TimetableDayOrderBy"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined
};
	["Contember_TimetableDayMetadata"]: {
	__typename: "Contember_TimetableDayMetadata",
	_meta?: GraphQLTypes["Contember_TimetableDayMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	numberOfWorkers: number,
	tipsTotalCents: number,
	totalMinutes: number,
	tipsCentsPerMinute: number,
	day?: GraphQLTypes["Contember_TimetableDay"] | undefined
};
	["Contember_TimetableDayMetadataMeta"]: {
	__typename: "Contember_TimetableDayMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	numberOfWorkers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tipsTotalCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalMinutes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tipsCentsPerMinute?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	day?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetableDayShiftGroupsByShiftsUniqueWhere"]: {
		shifts?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined
};
	["Contember_TimetableDayShiftGroupsByQueueUniqueWhere"]: {
		queue?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined
};
	["Contember_TimetableDayNoteConnection"]: {
	__typename: "Contember_TimetableDayNoteConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableDayNoteEdge"]>
};
	["Contember_TimetableDayNoteEdge"]: {
	__typename: "Contember_TimetableDayNoteEdge",
	node: GraphQLTypes["Contember_TimetableDayNote"]
};
	["Contember_VenueEmployeeRole"]: {
	__typename: "Contember_VenueEmployeeRole",
	_meta?: GraphQLTypes["Contember_VenueEmployeeRoleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	employeePrivileges?: GraphQLTypes["Contember_TimetablesEmployeePrivileges"] | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRole"] | undefined,
	getsTipsShare: boolean,
	notesEnabled: boolean
};
	["Contember_VenueEmployeeRoleMeta"]: {
	__typename: "Contember_VenueEmployeeRoleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employeePrivileges?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	role?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	getsTipsShare?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notesEnabled?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TimetablesEmployeePrivileges"]: {
	__typename: "Contember_TimetablesEmployeePrivileges",
	_meta?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	venueEmployeeRole?: GraphQLTypes["Contember_VenueEmployeeRole"] | undefined,
	read: boolean,
	unassign: boolean,
	assign: boolean
};
	["Contember_TimetablesEmployeePrivilegesMeta"]: {
	__typename: "Contember_TimetablesEmployeePrivilegesMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venueEmployeeRole?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	read?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unassign?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	assign?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VenueEmployeeRoleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	employeePrivileges?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesOrderBy"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRoleOrderBy"] | undefined,
	getsTipsShare?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	notesEnabled?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TimetablesEmployeePrivilegesOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venueEmployeeRole?: GraphQLTypes["Contember_VenueEmployeeRoleOrderBy"] | undefined,
	read?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unassign?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	assign?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PointOfSaleApplicationDevice"]: {
	__typename: "Contember_PointOfSaleApplicationDevice",
	_meta?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	identificationCode?: GraphQLTypes["Contember_IdentificationCode"] | undefined,
	identifier?: GraphQLTypes["Contember_DeviceIdentifier"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleTerminal"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSalePrinter"] | undefined,
	ticketPrinters: Array<GraphQLTypes["Contember_PointOfSalePrinter"]>,
	paginateTicketPrinters: GraphQLTypes["Contember_PointOfSalePrinterConnection"]
};
	["Contember_PointOfSaleApplicationDeviceMeta"]: {
	__typename: "Contember_PointOfSaleApplicationDeviceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identificationCode?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	terminal?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ticketPrinters?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationCode"]: {
	__typename: "Contember_IdentificationCode",
	_meta?: GraphQLTypes["Contember_IdentificationCodeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	createdAt: GraphQLTypes["DateTime"],
	attempts: Array<GraphQLTypes["Contember_IdentificationAttempt"]>,
	locales: Array<GraphQLTypes["Contember_IdentificationCodeLocale"]>,
	internalName?: string | undefined,
	applicationDevices: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	localesByLocale?: GraphQLTypes["Contember_IdentificationCodeLocale"] | undefined,
	applicationDevicesByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	paginateAttempts: GraphQLTypes["Contember_IdentificationAttemptConnection"],
	paginateLocales: GraphQLTypes["Contember_IdentificationCodeLocaleConnection"],
	paginateApplicationDevices: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"]
};
	["Contember_IdentificationCodeMeta"]: {
	__typename: "Contember_IdentificationCodeMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	attempts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationAttempt"]: {
	__typename: "Contember_IdentificationAttempt",
	_meta?: GraphQLTypes["Contember_IdentificationAttemptMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	code?: GraphQLTypes["Contember_IdentificationCode"] | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined
};
	["Contember_IdentificationAttemptMeta"]: {
	__typename: "Contember_IdentificationAttemptMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_User"]: {
	__typename: "Contember_User",
	_meta?: GraphQLTypes["Contember_UserMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	tenantPersonId: GraphQLTypes["UUID"],
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices: Array<GraphQLTypes["Contember_UserDevice"]>,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserMetadata"] | undefined,
	identificationAttempts: Array<GraphQLTypes["Contember_IdentificationAttempt"]>,
	roles?: GraphQLTypes["Contember_UserRoles"] | undefined,
	devicesByDeviceId?: GraphQLTypes["Contember_UserDevice"] | undefined,
	paginateDevices: GraphQLTypes["Contember_UserDeviceConnection"],
	paginateIdentificationAttempts: GraphQLTypes["Contember_IdentificationAttemptConnection"]
};
	["Contember_UserMeta"]: {
	__typename: "Contember_UserMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	disabledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	devices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	verifiedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletionRequestedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletionExpectedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identificationAttempts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	roles?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UserDevice"]: {
	__typename: "Contember_UserDevice",
	_meta?: GraphQLTypes["Contember_UserDeviceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	lastActiveAt: GraphQLTypes["DateTime"],
	platform?: string | undefined,
	deviceId?: string | undefined,
	notificationsEnabled: boolean,
	user?: GraphQLTypes["Contember_User"] | undefined,
	appVersion?: string | undefined,
	osVersion?: string | undefined,
	apiVersion?: string | undefined,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSession"] | undefined
};
	["Contember_UserDeviceMeta"]: {
	__typename: "Contember_UserDeviceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	platform?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deviceId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notificationsEnabled?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	appVersion?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	osVersion?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	apiVersion?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AnonymousSession"]: {
	__typename: "Contember_AnonymousSession",
	_meta?: GraphQLTypes["Contember_AnonymousSessionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey: string,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	devices: Array<GraphQLTypes["Contember_UserDevice"]>,
	devicesByDeviceId?: GraphQLTypes["Contember_UserDevice"] | undefined,
	paginateDevices: GraphQLTypes["Contember_UserDeviceConnection"]
};
	["Contember_AnonymousSessionMeta"]: {
	__typename: "Contember_AnonymousSessionMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	anonymousSessionKey?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	devices?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UserDeviceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	lastActiveAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	platform?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deviceId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	notificationsEnabled?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	user?: GraphQLTypes["Contember_UserOrderBy"] | undefined,
	appVersion?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	osVersion?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	apiVersion?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	anonymousSession?: GraphQLTypes["Contember_AnonymousSessionOrderBy"] | undefined
};
	["Contember_AnonymousSessionDevicesByDeviceIdUniqueWhere"]: {
		deviceId?: string | undefined
};
	["Contember_UserDeviceConnection"]: {
	__typename: "Contember_UserDeviceConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_UserDeviceEdge"]>
};
	["Contember_UserDeviceEdge"]: {
	__typename: "Contember_UserDeviceEdge",
	node: GraphQLTypes["Contember_UserDevice"]
};
	["Contember_UserMetadata"]: {
	__typename: "Contember_UserMetadata",
	_meta?: GraphQLTypes["Contember_UserMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	userProfileUrl: string,
	tenantEmail?: string | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	tenantIdentityId?: GraphQLTypes["UUID"] | undefined,
	globalRoles?: GraphQLTypes["Json"] | undefined,
	projectRoles?: GraphQLTypes["Json"] | undefined,
	projectRolesString?: string | undefined,
	externalIdentifiers?: GraphQLTypes["Json"] | undefined,
	externalIdentifiersString?: string | undefined,
	idpSlugs?: GraphQLTypes["Json"] | undefined,
	idpSlugsString?: string | undefined,
	user?: GraphQLTypes["Contember_User"] | undefined
};
	["Contember_UserMetadataMeta"]: {
	__typename: "Contember_UserMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	userProfileUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantPersonId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tenantIdentityId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	globalRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	projectRoles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	projectRolesString?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifiers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifiersString?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	idpSlugs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	idpSlugsString?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationAttemptOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_IdentificationCodeOrderBy"] | undefined,
	user?: GraphQLTypes["Contember_UserOrderBy"] | undefined
};
	["Contember_IdentificationCodeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_UserRoles"]: {
	__typename: "Contember_UserRoles",
	_meta?: GraphQLTypes["Contember_UserRolesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCustomer: boolean,
	isStaff: boolean,
	isBartender: boolean,
	isCashier: boolean,
	isCourier: boolean,
	isCleaner: boolean,
	isShiftsManager: boolean,
	isInternalBuyer: boolean,
	isStocksManager: boolean,
	isCourierManager: boolean,
	isCashierManager: boolean,
	isBusinessToBusinessManager: boolean,
	isPicker: boolean,
	user?: GraphQLTypes["Contember_User"] | undefined
};
	["Contember_UserRolesMeta"]: {
	__typename: "Contember_UserRolesMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isStaff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isBartender?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCashier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCourier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCleaner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isShiftsManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isInternalBuyer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isStocksManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCourierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCashierManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isBusinessToBusinessManager?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isPicker?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	user?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_UserDevicesByDeviceIdUniqueWhere"]: {
		deviceId?: string | undefined
};
	["Contember_IdentificationAttemptConnection"]: {
	__typename: "Contember_IdentificationAttemptConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IdentificationAttemptEdge"]>
};
	["Contember_IdentificationAttemptEdge"]: {
	__typename: "Contember_IdentificationAttemptEdge",
	node: GraphQLTypes["Contember_IdentificationAttempt"]
};
	["Contember_IdentificationCodeLocale"]: {
	__typename: "Contember_IdentificationCodeLocale",
	_meta?: GraphQLTypes["Contember_IdentificationCodeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	successMessage?: string | undefined,
	root?: GraphQLTypes["Contember_IdentificationCode"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_IdentificationCodeLocaleMeta"]: {
	__typename: "Contember_IdentificationCodeLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	successMessage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IdentificationCodeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	successMessage?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_IdentificationCodeOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined,
	identificationCode?: GraphQLTypes["Contember_IdentificationCodeOrderBy"] | undefined,
	identifier?: GraphQLTypes["Contember_DeviceIdentifierOrderBy"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleTerminalOrderBy"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSalePrinterOrderBy"] | undefined
};
	["Contember_DeviceIdentifierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	device?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceOrderBy"] | undefined,
	updatedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	platform?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	resolution?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	devicePixelRatio?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	updatedByUser?: GraphQLTypes["Contember_UserOrderBy"] | undefined,
	identifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	meta?: GraphQLTypes["Contember_DeviceIdentifierMetadataOrderBy"] | undefined
};
	["Contember_DeviceIdentifierMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	prettyIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deviceIdentifier?: GraphQLTypes["Contember_DeviceIdentifierOrderBy"] | undefined
};
	["Contember_PointOfSaleTerminalOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined,
	ipAddress?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	port?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PointOfSalePrinterOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ipAddress?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined
};
	["Contember_IdentificationCodeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_IdentificationCodeApplicationDevicesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined
};
	["Contember_IdentificationCodeLocaleConnection"]: {
	__typename: "Contember_IdentificationCodeLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IdentificationCodeLocaleEdge"]>
};
	["Contember_IdentificationCodeLocaleEdge"]: {
	__typename: "Contember_IdentificationCodeLocaleEdge",
	node: GraphQLTypes["Contember_IdentificationCodeLocale"]
};
	["Contember_PointOfSaleApplicationDeviceConnection"]: {
	__typename: "Contember_PointOfSaleApplicationDeviceConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceEdge"]>
};
	["Contember_PointOfSaleApplicationDeviceEdge"]: {
	__typename: "Contember_PointOfSaleApplicationDeviceEdge",
	node: GraphQLTypes["Contember_PointOfSaleApplicationDevice"]
};
	["Contember_DeviceIdentifier"]: {
	__typename: "Contember_DeviceIdentifier",
	_meta?: GraphQLTypes["Contember_DeviceIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	device?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	updatedAt: GraphQLTypes["DateTime"],
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	updatedByUser?: GraphQLTypes["Contember_User"] | undefined,
	identifier: string,
	meta?: GraphQLTypes["Contember_DeviceIdentifierMetadata"] | undefined
};
	["Contember_DeviceIdentifierMeta"]: {
	__typename: "Contember_DeviceIdentifierMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	device?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	platform?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	resolution?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	devicePixelRatio?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedByUser?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeviceIdentifierMetadata"]: {
	__typename: "Contember_DeviceIdentifierMetadata",
	_meta?: GraphQLTypes["Contember_DeviceIdentifierMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	prettyIdentifier: string,
	deviceIdentifier?: GraphQLTypes["Contember_DeviceIdentifier"] | undefined
};
	["Contember_DeviceIdentifierMetadataMeta"]: {
	__typename: "Contember_DeviceIdentifierMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	prettyIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deviceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleTerminal"]: {
	__typename: "Contember_PointOfSaleTerminal",
	_meta?: GraphQLTypes["Contember_PointOfSaleTerminalMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	applicationDevices: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	ipAddress?: string | undefined,
	port?: number | undefined,
	type: GraphQLTypes["Contember_PointOfSaleTerminalType"],
	applicationDevicesByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	paginateApplicationDevices: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"]
};
	["Contember_PointOfSaleTerminalMeta"]: {
	__typename: "Contember_PointOfSaleTerminalMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ipAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	port?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleTerminalApplicationDevicesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined
};
	["Contember_PointOfSalePrinter"]: {
	__typename: "Contember_PointOfSalePrinter",
	_meta?: GraphQLTypes["Contember_PointOfSalePrinterMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName?: string | undefined,
	note?: string | undefined,
	ipAddress: string,
	applicationDevicesReceipt: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	applicationDevicesTicket: Array<GraphQLTypes["Contember_PointOfSaleApplicationDevice"]>,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	ticketCategories: Array<GraphQLTypes["Contember_PointOfSaleCategory"]>,
	applicationDevicesReceiptByIdentifier?: GraphQLTypes["Contember_PointOfSaleApplicationDevice"] | undefined,
	paginateApplicationDevicesReceipt: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"],
	paginateApplicationDevicesTicket: GraphQLTypes["Contember_PointOfSaleApplicationDeviceConnection"],
	paginateTicketCategories: GraphQLTypes["Contember_PointOfSaleCategoryConnection"]
};
	["Contember_PointOfSalePrinterMeta"]: {
	__typename: "Contember_PointOfSalePrinterMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ipAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevicesReceipt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	applicationDevicesTicket?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ticketCategories?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategory"]: {
	__typename: "Contember_PointOfSaleCategory",
	_meta?: GraphQLTypes["Contember_PointOfSaleCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	slug: string,
	locales: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocale"]>,
	products: Array<GraphQLTypes["Contember_PointOfSaleCategoryProduct"]>,
	category?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	ticketPrinters: Array<GraphQLTypes["Contember_PointOfSalePrinter"]>,
	localesByLocale?: GraphQLTypes["Contember_PointOfSaleCategoryLocale"] | undefined,
	productsByLocales?: GraphQLTypes["Contember_PointOfSaleCategoryProduct"] | undefined,
	paginateLocales: GraphQLTypes["Contember_PointOfSaleCategoryLocaleConnection"],
	paginateProducts: GraphQLTypes["Contember_PointOfSaleCategoryProductConnection"],
	paginateTicketPrinters: GraphQLTypes["Contember_PointOfSalePrinterConnection"]
};
	["Contember_PointOfSaleCategoryMeta"]: {
	__typename: "Contember_PointOfSaleCategoryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	slug?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ticketPrinters?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryLocale"]: {
	__typename: "Contember_PointOfSaleCategoryLocale",
	_meta?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title: string,
	root?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleMeta"]: {
	__typename: "Contember_PointOfSaleCategoryLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_PointOfSaleCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	slug?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	category?: GraphQLTypes["Contember_ProductCategoryOrderBy"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined
};
	["Contember_ProductCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	color?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PointOfSaleCategoryProduct"]: {
	__typename: "Contember_PointOfSaleCategoryProduct",
	_meta?: GraphQLTypes["Contember_PointOfSaleCategoryProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	category?: GraphQLTypes["Contember_PointOfSaleCategory"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	locales: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleConnection"]
};
	["Contember_PointOfSaleCategoryProductMeta"]: {
	__typename: "Contember_PointOfSaleCategoryProductMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocale"]: {
	__typename: "Contember_PointOfSaleCategoryProductLocale",
	_meta?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title: string,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryProduct"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleMeta"]: {
	__typename: "Contember_PointOfSaleCategoryProductLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryProductOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_PointOfSaleCategoryProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleConnection"]: {
	__typename: "Contember_PointOfSaleCategoryProductLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleEdge"]>
};
	["Contember_PointOfSaleCategoryProductLocaleEdge"]: {
	__typename: "Contember_PointOfSaleCategoryProductLocaleEdge",
	node: GraphQLTypes["Contember_PointOfSaleCategoryProductLocale"]
};
	["Contember_ProductCategory"]: {
	__typename: "Contember_ProductCategory",
	_meta?: GraphQLTypes["Contember_ProductCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code?: string | undefined,
	products: Array<GraphQLTypes["Product"]>,
	tags: Array<GraphQLTypes["Contember_Tag"]>,
	parent?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	children: Array<GraphQLTypes["Contember_ProductCategory"]>,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	order: number,
	descendants: Array<GraphQLTypes["Contember_ProductCategoryTree"]>,
	ancestors: Array<GraphQLTypes["Contember_ProductCategoryTree"]>,
	inheritedTags: Array<GraphQLTypes["Contember_InheritedProductCategoryTag"]>,
	nestedProducts: Array<GraphQLTypes["Contember_NestedProductCategoryProduct"]>,
	childrenByCode?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByChildren?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByDescendants?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByAncestors?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByInheritedTags?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	childrenByNestedProducts?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateTags: GraphQLTypes["Contember_TagConnection"],
	paginateChildren: GraphQLTypes["Contember_ProductCategoryConnection"],
	paginateDescendants: GraphQLTypes["Contember_ProductCategoryTreeConnection"],
	paginateAncestors: GraphQLTypes["Contember_ProductCategoryTreeConnection"],
	paginateInheritedTags: GraphQLTypes["Contember_InheritedProductCategoryTagConnection"],
	paginateNestedProducts: GraphQLTypes["Contember_NestedProductCategoryProductConnection"]
};
	["Contember_ProductCategoryMeta"]: {
	__typename: "Contember_ProductCategoryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	parent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	children?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	descendants?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ancestors?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	inheritedTags?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nestedProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryTree"]: {
	__typename: "Contember_ProductCategoryTree",
	_meta?: GraphQLTypes["Contember_ProductCategoryTreeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	depth: number,
	ancestor?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	descendant?: GraphQLTypes["Contember_ProductCategory"] | undefined
};
	["Contember_ProductCategoryTreeMeta"]: {
	__typename: "Contember_ProductCategoryTreeMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	depth?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ancestor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	descendant?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryTreeOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	depth?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ancestor?: GraphQLTypes["Contember_ProductCategoryOrderBy"] | undefined,
	descendant?: GraphQLTypes["Contember_ProductCategoryOrderBy"] | undefined
};
	["Contember_InheritedProductCategoryTag"]: {
	__typename: "Contember_InheritedProductCategoryTag",
	_meta?: GraphQLTypes["Contember_InheritedProductCategoryTagMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	depth: number,
	productCategory?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	tag?: GraphQLTypes["Contember_Tag"] | undefined
};
	["Contember_InheritedProductCategoryTagMeta"]: {
	__typename: "Contember_InheritedProductCategoryTagMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	depth?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tag?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_InheritedProductCategoryTagOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	depth?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryOrderBy"] | undefined,
	tag?: GraphQLTypes["Contember_TagOrderBy"] | undefined
};
	["Contember_NestedProductCategoryProduct"]: {
	__typename: "Contember_NestedProductCategoryProduct",
	_meta?: GraphQLTypes["Contember_NestedProductCategoryProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	depth: number,
	product?: GraphQLTypes["Product"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategory"] | undefined
};
	["Contember_NestedProductCategoryProductMeta"]: {
	__typename: "Contember_NestedProductCategoryProductMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	depth?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_NestedProductCategoryProductOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	depth?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryOrderBy"] | undefined
};
	["Contember_ProductCategoryChildrenByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["Contember_ProductCategoryChildrenByChildrenUniqueWhere"]: {
		children?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined
};
	["Contember_ProductCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	children?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	descendants?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	ancestors?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	inheritedTags?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	nestedProducts?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined
};
	["Contember_ProductCategoryTreeUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_InheritedProductCategoryTagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductCategoryChildrenByDescendantsUniqueWhere"]: {
		descendants?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined
};
	["Contember_ProductCategoryChildrenByAncestorsUniqueWhere"]: {
		ancestors?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined
};
	["Contember_ProductCategoryChildrenByInheritedTagsUniqueWhere"]: {
		inheritedTags?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined
};
	["Contember_ProductCategoryChildrenByNestedProductsUniqueWhere"]: {
		nestedProducts?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined
};
	["ProductConnection"]: {
	__typename: "ProductConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["ProductEdge"]>
};
	["ProductEdge"]: {
	__typename: "ProductEdge",
	node: GraphQLTypes["Product"]
};
	["Contember_ProductCategoryConnection"]: {
	__typename: "Contember_ProductCategoryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryEdge"]>
};
	["Contember_ProductCategoryEdge"]: {
	__typename: "Contember_ProductCategoryEdge",
	node: GraphQLTypes["Contember_ProductCategory"]
};
	["Contember_ProductCategoryTreeConnection"]: {
	__typename: "Contember_ProductCategoryTreeConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryTreeEdge"]>
};
	["Contember_ProductCategoryTreeEdge"]: {
	__typename: "Contember_ProductCategoryTreeEdge",
	node: GraphQLTypes["Contember_ProductCategoryTree"]
};
	["Contember_InheritedProductCategoryTagConnection"]: {
	__typename: "Contember_InheritedProductCategoryTagConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_InheritedProductCategoryTagEdge"]>
};
	["Contember_InheritedProductCategoryTagEdge"]: {
	__typename: "Contember_InheritedProductCategoryTagEdge",
	node: GraphQLTypes["Contember_InheritedProductCategoryTag"]
};
	["Contember_NestedProductCategoryProductConnection"]: {
	__typename: "Contember_NestedProductCategoryProductConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_NestedProductCategoryProductEdge"]>
};
	["Contember_NestedProductCategoryProductEdge"]: {
	__typename: "Contember_NestedProductCategoryProductEdge",
	node: GraphQLTypes["Contember_NestedProductCategoryProduct"]
};
	["Contember_PointOfSaleCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryProductsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleConnection"]: {
	__typename: "Contember_PointOfSaleCategoryLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryLocaleEdge"]>
};
	["Contember_PointOfSaleCategoryLocaleEdge"]: {
	__typename: "Contember_PointOfSaleCategoryLocaleEdge",
	node: GraphQLTypes["Contember_PointOfSaleCategoryLocale"]
};
	["Contember_PointOfSaleCategoryProductConnection"]: {
	__typename: "Contember_PointOfSaleCategoryProductConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductEdge"]>
};
	["Contember_PointOfSaleCategoryProductEdge"]: {
	__typename: "Contember_PointOfSaleCategoryProductEdge",
	node: GraphQLTypes["Contember_PointOfSaleCategoryProduct"]
};
	["Contember_PointOfSalePrinterConnection"]: {
	__typename: "Contember_PointOfSalePrinterConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSalePrinterEdge"]>
};
	["Contember_PointOfSalePrinterEdge"]: {
	__typename: "Contember_PointOfSalePrinterEdge",
	node: GraphQLTypes["Contember_PointOfSalePrinter"]
};
	["Contember_PointOfSalePrinterApplicationDevicesReceiptByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined
};
	["Contember_PointOfSaleCategoryConnection"]: {
	__typename: "Contember_PointOfSaleCategoryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleCategoryEdge"]>
};
	["Contember_PointOfSaleCategoryEdge"]: {
	__typename: "Contember_PointOfSaleCategoryEdge",
	node: GraphQLTypes["Contember_PointOfSaleCategory"]
};
	["OrderMeta"]: {
	__typename: "OrderMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canceledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	channel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discounts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	events?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceLines?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seq?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	summary?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gratuity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderDiscount"]: {
	__typename: "OrderDiscount",
	_meta?: GraphQLTypes["OrderDiscountMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	discountCents: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	description?: string | undefined,
	type: GraphQLTypes["Contember_OrderDiscountType"],
	customerCreditTransactions: Array<GraphQLTypes["Contember_CustomerCreditTransaction"]>,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemption"] | undefined,
	customerCreditTransactionsByVirtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransaction"] | undefined,
	paginateCustomerCreditTransactions: GraphQLTypes["Contember_CustomerCreditTransactionConnection"]
};
	["OrderDiscountMeta"]: {
	__typename: "OrderDiscountMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCreditTransactions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerCreditTransaction"]: {
	__typename: "Contember_CustomerCreditTransaction",
	_meta?: GraphQLTypes["Contember_CustomerCreditTransactionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	amountCents: number,
	customerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined
};
	["Contember_CustomerCreditTransactionMeta"]: {
	__typename: "Contember_CustomerCreditTransactionMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	amountCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCredit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["CustomerCreditMeta"]: {
	__typename: "CustomerCreditMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	initialCreditCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	reason?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	transactions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoiceUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerCreditTransactionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	amountCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditOrderBy"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountOrderBy"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffectOrderBy"] | undefined
};
	["Contember_CustomerCreditOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	initialCreditCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	expiresAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	reason?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionOrderBy"] | undefined,
	invoiceUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_VoucherRedemptionOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	manualRedemptionNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditOrderBy"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountOrderBy"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherOrderBy"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined
};
	["Contember_OrderDiscountOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	discountCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionOrderBy"] | undefined
};
	["Contember_OrderOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	state?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	canceledAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelOrderBy"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryOrderBy"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	cart?: GraphQLTypes["Contember_CartOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	billingAddress?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	seq?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubjectOrderBy"] | undefined,
	summary?: GraphQLTypes["Contember_OrderSummaryOrderBy"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	gratuity?: GraphQLTypes["Contember_GratuityOrderBy"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	doubleshotTotalWeightGrams?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	doubleShotLegacyNumber?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	doubleshotAdminUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_OrderDeliveryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveredAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	method?: GraphQLTypes["Contember_DeliveryMethodOrderBy"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined,
	expectedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	state?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	loadingDurationHours?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	duration?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	window?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	expectedEndAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	processingAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveringAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	failedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_DeliveryWaypointOrderBy"] | undefined,
	prepareAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_LiftagoRideOrderBy"] | undefined,
	pickedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	address?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	trackingCode?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_DeliveryWaypointOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	estimatedArrival?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	route?: GraphQLTypes["Contember_DeliveryRouteOrderBy"] | undefined,
	sortingOrder?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryOrderBy"] | undefined,
	estimatedDistance?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	estimatedDuration?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	originDeparture?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	originAddress?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	transitMode?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_DeliveryRouteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	courier?: GraphQLTypes["Contember_CourierOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	dispatchAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_LiftagoRideOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	liftagoId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	data?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	updatedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryOrderBy"] | undefined
};
	["Contember_CartOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	confirmedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deletedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	handle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	state?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	channel?: GraphQLTypes["Contember_ChannelOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	billingAddress?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	deliveryOption?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	paymentOption?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	delivery?: GraphQLTypes["Contember_ChannelDeliveryOrderBy"] | undefined,
	payment?: GraphQLTypes["Contember_ChannelPaymentOrderBy"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubjectOrderBy"] | undefined,
	summary?: GraphQLTypes["Contember_CartSummaryOrderBy"] | undefined
};
	["Contember_CartSummaryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	itemsPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveryPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	itemsCount?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	checkoutUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	cart?: GraphQLTypes["Contember_CartOrderBy"] | undefined
};
	["Contember_OrderSummaryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	discountPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderNumber?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined
};
	["Contember_GratuityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	amountCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined
};
	["Contember_VoucherOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	activatedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	expiresAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	creditCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	buyer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	owner?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffectOrderBy"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherRedemptionOrderBy"] | undefined
};
	["Contember_VirtualProductEffectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	succeededAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderItem?: GraphQLTypes["Contember_OrderItemOrderBy"] | undefined,
	failedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	revertedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_CustomerCreditTransactionOrderBy"] | undefined
};
	["Contember_OrderItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	quantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined,
	pickedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_VoucherRedemption"]: {
	__typename: "Contember_VoucherRedemption",
	_meta?: GraphQLTypes["Contember_VoucherRedemptionMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["CustomerCredit"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	voucher?: GraphQLTypes["Contember_Voucher"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_VoucherRedemptionMeta"]: {
	__typename: "Contember_VoucherRedemptionMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	manualRedemptionNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCredit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucher?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Voucher"]: {
	__typename: "Contember_Voucher",
	_meta?: GraphQLTypes["Contember_VoucherMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code: string,
	type: GraphQLTypes["Contember_VoucherType"],
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_Customer"] | undefined,
	owner?: GraphQLTypes["Contember_Customer"] | undefined,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherRedemption"] | undefined
};
	["Contember_VoucherMeta"]: {
	__typename: "Contember_VoucherMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	activatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	expiresAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	creditCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	buyer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	owner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	redemption?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VirtualProductEffect"]: {
	__typename: "Contember_VirtualProductEffect",
	_meta?: GraphQLTypes["Contember_VirtualProductEffectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_CustomerCreditTransaction"] | undefined,
	resultVouchers: Array<GraphQLTypes["Contember_Voucher"]>,
	resultVouchersByCode?: GraphQLTypes["Contember_Voucher"] | undefined,
	resultVouchersByRedemption?: GraphQLTypes["Contember_Voucher"] | undefined,
	paginateResultVouchers: GraphQLTypes["Contember_VoucherConnection"]
};
	["Contember_VirtualProductEffectMeta"]: {
	__typename: "Contember_VirtualProductEffectMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	succeededAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	revertedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	resultVouchers?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderItem"]: {
	__typename: "OrderItem",
	_meta?: GraphQLTypes["OrderItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined,
	virtualProductEffects: Array<GraphQLTypes["Contember_VirtualProductEffect"]>,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes: Array<GraphQLTypes["Contember_FulfillmentNote"]>,
	product?: GraphQLTypes["Product"] | undefined,
	virtualProductEffectsByResultCustomerCreditTransaction?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined,
	virtualProductEffectsByResultVouchers?: GraphQLTypes["Contember_VirtualProductEffect"] | undefined,
	paginateVirtualProductEffects: GraphQLTypes["Contember_VirtualProductEffectConnection"],
	paginateFulfillmentNotes: GraphQLTypes["Contember_FulfillmentNoteConnection"]
};
	["OrderItemMeta"]: {
	__typename: "OrderItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	virtualProductEffects?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pickedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfillmentNotes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FulfillmentNoteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	highlight?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	emoji?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_OrderItemVirtualProductEffectsByResultCustomerCreditTransactionUniqueWhere"]: {
		resultCustomerCreditTransaction?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined
};
	["Contember_OrderItemVirtualProductEffectsByResultVouchersUniqueWhere"]: {
		resultVouchers?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined
};
	["Contember_VirtualProductEffectConnection"]: {
	__typename: "Contember_VirtualProductEffectConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VirtualProductEffectEdge"]>
};
	["Contember_VirtualProductEffectEdge"]: {
	__typename: "Contember_VirtualProductEffectEdge",
	node: GraphQLTypes["Contember_VirtualProductEffect"]
};
	["Contember_FulfillmentNoteConnection"]: {
	__typename: "Contember_FulfillmentNoteConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_FulfillmentNoteEdge"]>
};
	["Contember_FulfillmentNoteEdge"]: {
	__typename: "Contember_FulfillmentNoteEdge",
	node: GraphQLTypes["Contember_FulfillmentNote"]
};
	["Contember_VirtualProductEffectResultVouchersByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["Contember_VirtualProductEffectResultVouchersByRedemptionUniqueWhere"]: {
		redemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_VoucherConnection"]: {
	__typename: "Contember_VoucherConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VoucherEdge"]>
};
	["Contember_VoucherEdge"]: {
	__typename: "Contember_VoucherEdge",
	node: GraphQLTypes["Contember_Voucher"]
};
	["Contember_CustomerCreditTransactionsByVirtualProductEffectUniqueWhere"]: {
		virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined
};
	["Contember_CustomerCreditTransactionConnection"]: {
	__typename: "Contember_CustomerCreditTransactionConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerCreditTransactionEdge"]>
};
	["Contember_CustomerCreditTransactionEdge"]: {
	__typename: "Contember_CustomerCreditTransactionEdge",
	node: GraphQLTypes["Contember_CustomerCreditTransaction"]
};
	["Contember_OrderDiscountCustomerCreditTransactionsByVirtualProductEffectUniqueWhere"]: {
		virtualProductEffect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined
};
	["Contember_OrderEvent"]: {
	__typename: "Contember_OrderEvent",
	_meta?: GraphQLTypes["Contember_OrderEventMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: string,
	data?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	order?: GraphQLTypes["Order"] | undefined,
	payment?: GraphQLTypes["OrderPayment"] | undefined,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined
};
	["Contember_OrderEventMeta"]: {
	__typename: "Contember_OrderEventMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	payment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["OrderPaymentMeta"]: {
	__typename: "OrderPaymentMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	method?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	failedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	state?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	approvedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	events?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoice?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	walletType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicReceiptUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderEventOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	data?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined,
	payment?: GraphQLTypes["Contember_OrderPaymentOrderBy"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderDeliveryOrderBy"] | undefined
};
	["Contember_OrderPaymentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	data?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	paymentCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	method?: GraphQLTypes["Contember_PaymentMethodOrderBy"] | undefined,
	failedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	state?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	approvedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined,
	invoice?: GraphQLTypes["Contember_InvoiceOrderBy"] | undefined,
	walletType?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	publicReceiptUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentMetadataOrderBy"] | undefined,
	otherCurrencyPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_InvoiceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	publicKey?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fakturoidId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fakturoidData?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	legacyUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	address?: GraphQLTypes["Contember_AddressOrderBy"] | undefined,
	publicUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubjectOrderBy"] | undefined
};
	["Contember_OrderPaymentMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	paymentUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderPayment?: GraphQLTypes["Contember_OrderPaymentOrderBy"] | undefined
};
	["Contember_Invoice"]: {
	__typename: "Contember_Invoice",
	_meta?: GraphQLTypes["Contember_InvoiceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	publicKey: string,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	orderPayments: Array<GraphQLTypes["OrderPayment"]>,
	address?: GraphQLTypes["Address"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_BillingSubject"] | undefined,
	orderPaymentsByEvents?: GraphQLTypes["OrderPayment"] | undefined,
	orderPaymentsByMeta?: GraphQLTypes["OrderPayment"] | undefined,
	paginateOrderPayments: GraphQLTypes["OrderPaymentConnection"]
};
	["Contember_InvoiceMeta"]: {
	__typename: "Contember_InvoiceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicKey?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidData?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	legacyUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderPayments?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	publicUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billingSubject?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BillingSubject"]: {
	__typename: "Contember_BillingSubject",
	_meta?: GraphQLTypes["Contember_BillingSubjectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden: boolean,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_Country"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	invoices: Array<GraphQLTypes["Contember_Invoice"]>,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_Customer"] | undefined,
	orders: Array<GraphQLTypes["Order"]>,
	invoicesByPublicKey?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByFakturoidId?: GraphQLTypes["Contember_Invoice"] | undefined,
	invoicesByOrderPayments?: GraphQLTypes["Contember_Invoice"] | undefined,
	ordersBySeq?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleshotLegacyId?: GraphQLTypes["Order"] | undefined,
	ordersByDoubleShotLegacyNumber?: GraphQLTypes["Order"] | undefined,
	ordersByDiscounts?: GraphQLTypes["Order"] | undefined,
	ordersByDelivery?: GraphQLTypes["Order"] | undefined,
	ordersByItems?: GraphQLTypes["Order"] | undefined,
	ordersByCart?: GraphQLTypes["Order"] | undefined,
	ordersByEvents?: GraphQLTypes["Order"] | undefined,
	ordersByPriceLines?: GraphQLTypes["Order"] | undefined,
	ordersByPayments?: GraphQLTypes["Order"] | undefined,
	ordersBySummary?: GraphQLTypes["Order"] | undefined,
	ordersByGratuity?: GraphQLTypes["Order"] | undefined,
	paginateInvoices: GraphQLTypes["Contember_InvoiceConnection"],
	paginateOrders: GraphQLTypes["OrderConnection"]
};
	["Contember_BillingSubjectMeta"]: {
	__typename: "Contember_BillingSubjectMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fullName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine1?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addressLine2?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	city?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	postalCode?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hidden?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deletedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fakturoidSubjectId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	country?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orders?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Country"]: {
	__typename: "Contember_Country",
	_meta?: GraphQLTypes["Contember_CountryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	order: number
};
	["Contember_CountryMeta"]: {
	__typename: "Contember_CountryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BillingSubjectInvoicesByPublicKeyUniqueWhere"]: {
		publicKey?: string | undefined
};
	["Contember_BillingSubjectInvoicesByFakturoidIdUniqueWhere"]: {
		fakturoidId?: string | undefined
};
	["Contember_BillingSubjectInvoicesByOrderPaymentsUniqueWhere"]: {
		orderPayments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["Contember_BillingSubjectOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["Contember_BillingSubjectOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["Contember_BillingSubjectOrdersByDiscountsUniqueWhere"]: {
		discounts?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersByCartUniqueWhere"]: {
		cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersByEventsUniqueWhere"]: {
		events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["Contember_OrderSummaryUniqueWhere"] | undefined
};
	["Contember_BillingSubjectOrdersByGratuityUniqueWhere"]: {
		gratuity?: GraphQLTypes["Contember_GratuityUniqueWhere"] | undefined
};
	["Contember_InvoiceConnection"]: {
	__typename: "Contember_InvoiceConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_InvoiceEdge"]>
};
	["Contember_InvoiceEdge"]: {
	__typename: "Contember_InvoiceEdge",
	node: GraphQLTypes["Contember_Invoice"]
};
	["OrderConnection"]: {
	__typename: "OrderConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderEdge"]>
};
	["OrderEdge"]: {
	__typename: "OrderEdge",
	node: GraphQLTypes["Order"]
};
	["Contember_InvoiceOrderPaymentsByEventsUniqueWhere"]: {
		events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined
};
	["Contember_InvoiceOrderPaymentsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined
};
	["OrderPaymentConnection"]: {
	__typename: "OrderPaymentConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderPaymentEdge"]>
};
	["OrderPaymentEdge"]: {
	__typename: "OrderPaymentEdge",
	node: GraphQLTypes["OrderPayment"]
};
	["Contember_OrderPaymentMetadata"]: {
	__typename: "Contember_OrderPaymentMetadata",
	_meta?: GraphQLTypes["Contember_OrderPaymentMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	paymentUrl: string,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined
};
	["Contember_OrderPaymentMetadataMeta"]: {
	__typename: "Contember_OrderPaymentMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderEventConnection"]: {
	__typename: "Contember_OrderEventConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_OrderEventEdge"]>
};
	["Contember_OrderEventEdge"]: {
	__typename: "Contember_OrderEventEdge",
	node: GraphQLTypes["Contember_OrderEvent"]
};
	["OrderPriceLine"]: {
	__typename: "OrderPriceLine",
	_meta?: GraphQLTypes["OrderPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unitPriceCents: number,
	quantity: number,
	type?: GraphQLTypes["Contember_OrderPriceLineType"] | undefined,
	orderItem?: GraphQLTypes["OrderItem"] | undefined,
	orderDiscount?: GraphQLTypes["OrderDiscount"] | undefined,
	orderPayment?: GraphQLTypes["OrderPayment"] | undefined,
	orderDelivery?: GraphQLTypes["OrderDelivery"] | undefined,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderPriceLineMeta"]: {
	__typename: "OrderPriceLineMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderPayment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDelivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderPriceLineOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	quantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderItem?: GraphQLTypes["Contember_OrderItemOrderBy"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_OrderDiscountOrderBy"] | undefined,
	orderPayment?: GraphQLTypes["Contember_OrderPaymentOrderBy"] | undefined,
	orderDelivery?: GraphQLTypes["Contember_OrderDeliveryOrderBy"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderOrderBy"] | undefined
};
	["OrderSummary"]: {
	__typename: "OrderSummary",
	_meta?: GraphQLTypes["OrderSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalPriceCents: number,
	discountPriceCents: number,
	undiscountedPriceCents: number,
	itemsQuantity: number,
	orderUrl: string,
	orderNumber: string,
	order?: GraphQLTypes["Order"] | undefined
};
	["OrderSummaryMeta"]: {
	__typename: "OrderSummaryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	undiscountedPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderNumber?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Gratuity"]: {
	__typename: "Contember_Gratuity",
	_meta?: GraphQLTypes["Contember_GratuityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	amountCents: number,
	order?: GraphQLTypes["Order"] | undefined
};
	["Contember_GratuityMeta"]: {
	__typename: "Contember_GratuityMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	amountCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderDiscountsByCustomerCreditTransactionsUniqueWhere"]: {
		customerCreditTransactions?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined
};
	["Contember_OrderDiscountsByVoucherRedemptionUniqueWhere"]: {
		voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_OrderItemsByVirtualProductEffectsUniqueWhere"]: {
		virtualProductEffects?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined
};
	["Contember_OrderPriceLinesByOrderItemUniqueWhere"]: {
		orderItem?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined
};
	["Contember_OrderPriceLinesByOrderDiscountUniqueWhere"]: {
		orderDiscount?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined
};
	["Contember_OrderPriceLinesByOrderPaymentUniqueWhere"]: {
		orderPayment?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_OrderPriceLinesByOrderDeliveryUniqueWhere"]: {
		orderDelivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_OrderPaymentsByEventsUniqueWhere"]: {
		events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined
};
	["Contember_OrderPaymentsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined
};
	["OrderDiscountConnection"]: {
	__typename: "OrderDiscountConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderDiscountEdge"]>
};
	["OrderDiscountEdge"]: {
	__typename: "OrderDiscountEdge",
	node: GraphQLTypes["OrderDiscount"]
};
	["OrderItemConnection"]: {
	__typename: "OrderItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderItemEdge"]>
};
	["OrderItemEdge"]: {
	__typename: "OrderItemEdge",
	node: GraphQLTypes["OrderItem"]
};
	["OrderPriceLineConnection"]: {
	__typename: "OrderPriceLineConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderPriceLineEdge"]>
};
	["OrderPriceLineEdge"]: {
	__typename: "OrderPriceLineEdge",
	node: GraphQLTypes["OrderPriceLine"]
};
	["Contember_Area"]: {
	__typename: "Contember_Area",
	_meta?: GraphQLTypes["Contember_AreaMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name?: string | undefined,
	venue?: GraphQLTypes["Contember_Venue"] | undefined,
	tables: Array<GraphQLTypes["Contember_Table"]>,
	lines: Array<GraphQLTypes["Contember_Line"]>,
	tablesByCustomerTabs?: GraphQLTypes["Contember_Table"] | undefined,
	tablesBySeats?: GraphQLTypes["Contember_Table"] | undefined,
	paginateTables: GraphQLTypes["Contember_TableConnection"],
	paginateLines: GraphQLTypes["Contember_LineConnection"]
};
	["Contember_AreaMeta"]: {
	__typename: "Contember_AreaMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	venue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tables?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lines?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Table"]: {
	__typename: "Contember_Table",
	_meta?: GraphQLTypes["Contember_TableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	shape: GraphQLTypes["Contember_TableShape"],
	width: number,
	height: number,
	positionX: number,
	positionY: number,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs: Array<GraphQLTypes["Contember_CustomerTab"]>,
	area?: GraphQLTypes["Contember_Area"] | undefined,
	seats: Array<GraphQLTypes["Contember_Seat"]>,
	customerTabsByItems?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	customerTabsByMeta?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	seatsByCustomerTabs?: GraphQLTypes["Contember_Seat"] | undefined,
	paginateCustomerTabs: GraphQLTypes["Contember_CustomerTabConnection"],
	paginateSeats: GraphQLTypes["Contember_SeatConnection"]
};
	["Contember_TableMeta"]: {
	__typename: "Contember_TableMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	shape?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	width?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	height?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTabs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	area?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seats?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerTab"]: {
	__typename: "Contember_CustomerTab",
	_meta?: GraphQLTypes["Contember_CustomerTabMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	color?: string | undefined,
	items: Array<GraphQLTypes["Contember_CustomerTabItem"]>,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabMetadata"] | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	table?: GraphQLTypes["Contember_Table"] | undefined,
	seat?: GraphQLTypes["Contember_Seat"] | undefined,
	paginateItems: GraphQLTypes["Contember_CustomerTabItemConnection"]
};
	["Contember_CustomerTabMeta"]: {
	__typename: "Contember_CustomerTabMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	table?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seat?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerTabItem"]: {
	__typename: "Contember_CustomerTabItem",
	_meta?: GraphQLTypes["Contember_CustomerTabItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	addedAt: GraphQLTypes["DateTime"],
	tab?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	paidAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_CustomerTabItemMeta"]: {
	__typename: "Contember_CustomerTabItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	addedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tab?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paidAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerTabItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	addedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	tab?: GraphQLTypes["Contember_CustomerTabOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	paidAt?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CustomerTabOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	color?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabMetadataOrderBy"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	table?: GraphQLTypes["Contember_TableOrderBy"] | undefined,
	seat?: GraphQLTypes["Contember_SeatOrderBy"] | undefined
};
	["Contember_CustomerTabMetadataOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	totalSpentCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unpaidCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customerTab?: GraphQLTypes["Contember_CustomerTabOrderBy"] | undefined
};
	["Contember_TableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	shape?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	width?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	height?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionX?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionY?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	color?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	area?: GraphQLTypes["Contember_AreaOrderBy"] | undefined
};
	["Contember_AreaOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	venue?: GraphQLTypes["Contember_VenueOrderBy"] | undefined
};
	["Contember_SeatOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionX?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionY?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	color?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	table?: GraphQLTypes["Contember_TableOrderBy"] | undefined
};
	["Contember_CustomerTabMetadata"]: {
	__typename: "Contember_CustomerTabMetadata",
	_meta?: GraphQLTypes["Contember_CustomerTabMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalPriceCents: number,
	totalSpentCents: number,
	unpaidCents: number,
	customerTab?: GraphQLTypes["Contember_CustomerTab"] | undefined
};
	["Contember_CustomerTabMetadataMeta"]: {
	__typename: "Contember_CustomerTabMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalSpentCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unpaidCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTab?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Seat"]: {
	__typename: "Contember_Seat",
	_meta?: GraphQLTypes["Contember_SeatMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	positionX: number,
	positionY: number,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs: Array<GraphQLTypes["Contember_CustomerTab"]>,
	table?: GraphQLTypes["Contember_Table"] | undefined,
	customerTabsByItems?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	customerTabsByMeta?: GraphQLTypes["Contember_CustomerTab"] | undefined,
	paginateCustomerTabs: GraphQLTypes["Contember_CustomerTabConnection"]
};
	["Contember_SeatMeta"]: {
	__typename: "Contember_SeatMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerTabs?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	table?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SeatCustomerTabsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined
};
	["Contember_SeatCustomerTabsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined
};
	["Contember_CustomerTabConnection"]: {
	__typename: "Contember_CustomerTabConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerTabEdge"]>
};
	["Contember_CustomerTabEdge"]: {
	__typename: "Contember_CustomerTabEdge",
	node: GraphQLTypes["Contember_CustomerTab"]
};
	["Contember_CustomerTabItemConnection"]: {
	__typename: "Contember_CustomerTabItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerTabItemEdge"]>
};
	["Contember_CustomerTabItemEdge"]: {
	__typename: "Contember_CustomerTabItemEdge",
	node: GraphQLTypes["Contember_CustomerTabItem"]
};
	["Contember_TableCustomerTabsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined
};
	["Contember_TableCustomerTabsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined
};
	["Contember_TableSeatsByCustomerTabsUniqueWhere"]: {
		customerTabs?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined
};
	["Contember_SeatConnection"]: {
	__typename: "Contember_SeatConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_SeatEdge"]>
};
	["Contember_SeatEdge"]: {
	__typename: "Contember_SeatEdge",
	node: GraphQLTypes["Contember_Seat"]
};
	["Contember_Line"]: {
	__typename: "Contember_Line",
	_meta?: GraphQLTypes["Contember_LineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	positionStartX: number,
	positionStartY: number,
	positionEndX: number,
	positionEndY: number,
	area?: GraphQLTypes["Contember_Area"] | undefined
};
	["Contember_LineMeta"]: {
	__typename: "Contember_LineMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionStartX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionStartY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionEndX?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	positionEndY?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	area?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LineOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionStartX?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionStartY?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionEndX?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	positionEndY?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	area?: GraphQLTypes["Contember_AreaOrderBy"] | undefined
};
	["Contember_AreaTablesByCustomerTabsUniqueWhere"]: {
		customerTabs?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined
};
	["Contember_AreaTablesBySeatsUniqueWhere"]: {
		seats?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined
};
	["Contember_SeatUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customerTabs?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined
};
	["Contember_TableConnection"]: {
	__typename: "Contember_TableConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TableEdge"]>
};
	["Contember_TableEdge"]: {
	__typename: "Contember_TableEdge",
	node: GraphQLTypes["Contember_Table"]
};
	["Contember_LineConnection"]: {
	__typename: "Contember_LineConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_LineEdge"]>
};
	["Contember_LineEdge"]: {
	__typename: "Contember_LineEdge",
	node: GraphQLTypes["Contember_Line"]
};
	["Contember_VenueTimetableDaysByDateUniqueWhere"]: {
		date?: GraphQLTypes["Date"] | undefined
};
	["Contember_VenueTimetableDaysByShiftGroupsUniqueWhere"]: {
		shiftGroups?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined
};
	["Contember_VenueTimetableDaysByNotesUniqueWhere"]: {
		notes?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined
};
	["Contember_TimetableDayNoteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_VenueTimetableDaysByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined
};
	["Contember_TimetableDayMetadataUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	day?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined
};
	["Contember_TimetableDayUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venue?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	notes?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined
};
	["Contember_VenueUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_DeadlineTemplateUniqueWhere"] | undefined,
	timetableDays?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	employeeRoles?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined,
	pointOfSaleApplicationDevices?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	pointOfSaleTerminals?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	pointOfSalePrinters?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	orders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	areas?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	categories?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	channels?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined
};
	["Contember_DeadlineTemplateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venue?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined
};
	["Contember_TimetableTemplateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staffOptions?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined
};
	["Contember_VenueEmployeeRoleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venue?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	role?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	employeePrivileges?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined
};
	["Contember_TimetableEmployeeRoleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined
};
	["Contember_TimetablesEmployeePrivilegesUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	venueEmployeeRole?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined
};
	["Contember_PointOfSaleTerminalUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	applicationDevices?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined
};
	["Contember_PointOfSalePrinterUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	applicationDevicesReceipt?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined
};
	["Contember_AreaUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	tables?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	lines?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined
};
	["Contember_TableUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customerTabs?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	seats?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined
};
	["Contember_LineUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ChannelUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	payments?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	deliveries?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	carts?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_ChannelPaymentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_VenueTimetableTemplatesByStaffOptionsUniqueWhere"]: {
		staffOptions?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined
};
	["Contember_VenueEmployeeRolesByRoleUniqueWhere"]: {
		role?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined
};
	["Contember_VenueEmployeeRolesByEmployeePrivilegesUniqueWhere"]: {
		employeePrivileges?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined
};
	["Contember_VenuePointOfSaleApplicationDevicesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined
};
	["Contember_VenuePointOfSaleTerminalsByApplicationDevicesUniqueWhere"]: {
		applicationDevices?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined
};
	["Contember_VenuePointOfSalePrintersByApplicationDevicesReceiptUniqueWhere"]: {
		applicationDevicesReceipt?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined
};
	["Contember_VenueOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["Contember_VenueOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["Contember_VenueOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["Contember_VenueOrdersByDiscountsUniqueWhere"]: {
		discounts?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined
};
	["Contember_VenueOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_VenueOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined
};
	["Contember_VenueOrdersByCartUniqueWhere"]: {
		cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_VenueOrdersByEventsUniqueWhere"]: {
		events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined
};
	["Contember_VenueOrdersByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined
};
	["Contember_VenueOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_VenueOrdersBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["Contember_OrderSummaryUniqueWhere"] | undefined
};
	["Contember_VenueOrdersByGratuityUniqueWhere"]: {
		gratuity?: GraphQLTypes["Contember_GratuityUniqueWhere"] | undefined
};
	["Contember_VenueAreasByTablesUniqueWhere"]: {
		tables?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined
};
	["Contember_VenueAreasByLinesUniqueWhere"]: {
		lines?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined
};
	["Contember_VenueCategoriesBySlugUniqueWhere"]: {
		slug?: string | undefined
};
	["Contember_VenueCategoriesByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined
};
	["Contember_VenueCategoriesByProductsUniqueWhere"]: {
		products?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined
};
	["Contember_VenueChannelsByCodeUniqueWhere"]: {
		code?: GraphQLTypes["Contember_ChannelCode"] | undefined
};
	["Contember_VenueChannelsByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined
};
	["Contember_VenueChannelsByDeliveriesUniqueWhere"]: {
		deliveries?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined
};
	["Contember_VenueChannelsByCartsUniqueWhere"]: {
		carts?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_TimetableDayConnection"]: {
	__typename: "Contember_TimetableDayConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TimetableDayEdge"]>
};
	["Contember_TimetableDayEdge"]: {
	__typename: "Contember_TimetableDayEdge",
	node: GraphQLTypes["Contember_TimetableDay"]
};
	["Contember_StaffConnection"]: {
	__typename: "Contember_StaffConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_StaffEdge"]>
};
	["Contember_StaffEdge"]: {
	__typename: "Contember_StaffEdge",
	node: GraphQLTypes["Contember_Staff"]
};
	["Contember_VenueEmployeeRoleConnection"]: {
	__typename: "Contember_VenueEmployeeRoleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VenueEmployeeRoleEdge"]>
};
	["Contember_VenueEmployeeRoleEdge"]: {
	__typename: "Contember_VenueEmployeeRoleEdge",
	node: GraphQLTypes["Contember_VenueEmployeeRole"]
};
	["Contember_PointOfSaleTerminalConnection"]: {
	__typename: "Contember_PointOfSaleTerminalConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleTerminalEdge"]>
};
	["Contember_PointOfSaleTerminalEdge"]: {
	__typename: "Contember_PointOfSaleTerminalEdge",
	node: GraphQLTypes["Contember_PointOfSaleTerminal"]
};
	["Contember_AreaConnection"]: {
	__typename: "Contember_AreaConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AreaEdge"]>
};
	["Contember_AreaEdge"]: {
	__typename: "Contember_AreaEdge",
	node: GraphQLTypes["Contember_Area"]
};
	["Contember_UnassignReasonConnection"]: {
	__typename: "Contember_UnassignReasonConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_UnassignReasonEdge"]>
};
	["Contember_UnassignReasonEdge"]: {
	__typename: "Contember_UnassignReasonEdge",
	node: GraphQLTypes["Contember_UnassignReason"]
};
	["Contember_CartItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	quantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	cart?: GraphQLTypes["Contember_CartOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	updatedAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fulfillmentNote?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CartItemConnection"]: {
	__typename: "Contember_CartItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CartItemEdge"]>
};
	["Contember_CartItemEdge"]: {
	__typename: "Contember_CartItemEdge",
	node: GraphQLTypes["Contember_CartItem"]
};
	["Contember_CartPriceLine"]: {
	__typename: "Contember_CartPriceLine",
	_meta?: GraphQLTypes["Contember_CartPriceLineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_CartPriceLineType"] | undefined,
	item?: GraphQLTypes["Contember_CartItem"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	unitPriceCents: number,
	priceCents: number,
	quantity: number,
	vatRatePermille: number,
	vatRate?: GraphQLTypes["VatRate"] | undefined,
	cart?: GraphQLTypes["Contember_Cart"] | undefined
};
	["Contember_CartPriceLineMeta"]: {
	__typename: "Contember_CartPriceLineMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	item?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CartPriceLineOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	item?: GraphQLTypes["Contember_CartItemOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	unitPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	quantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatRatePermille?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	vatRate?: GraphQLTypes["Contember_VatRateOrderBy"] | undefined,
	cart?: GraphQLTypes["Contember_CartOrderBy"] | undefined
};
	["Contember_CartSummary"]: {
	__typename: "Contember_CartSummary",
	_meta?: GraphQLTypes["Contember_CartSummaryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	itemsPriceCents: number,
	deliveryPriceCents: number,
	totalPriceCents: number,
	itemsCount: number,
	itemsQuantity: number,
	checkoutUrl: string,
	cart?: GraphQLTypes["Contember_Cart"] | undefined
};
	["Contember_CartSummaryMeta"]: {
	__typename: "Contember_CartSummaryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsCount?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	itemsQuantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	checkoutUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cart?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CartItemsByProductUniqueWhere"]: {
		product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_CartPriceLineConnection"]: {
	__typename: "Contember_CartPriceLineConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CartPriceLineEdge"]>
};
	["Contember_CartPriceLineEdge"]: {
	__typename: "Contember_CartPriceLineEdge",
	node: GraphQLTypes["Contember_CartPriceLine"]
};
	["Contember_ChannelCartsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined
};
	["Contember_ChannelCartsByOrderUniqueWhere"]: {
		order?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_ChannelCartsByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["Contember_CartPriceLineUniqueWhere"] | undefined
};
	["Contember_ChannelCartsBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["Contember_CartSummaryUniqueWhere"] | undefined
};
	["ChannelPaymentConnection"]: {
	__typename: "ChannelPaymentConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["ChannelPaymentEdge"]>
};
	["ChannelPaymentEdge"]: {
	__typename: "ChannelPaymentEdge",
	node: GraphQLTypes["ChannelPayment"]
};
	["ChannelDeliveryConnection"]: {
	__typename: "ChannelDeliveryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["ChannelDeliveryEdge"]>
};
	["ChannelDeliveryEdge"]: {
	__typename: "ChannelDeliveryEdge",
	node: GraphQLTypes["ChannelDelivery"]
};
	["Contember_PointOfSaleConnection"]: {
	__typename: "Contember_PointOfSaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PointOfSaleEdge"]>
};
	["Contember_PointOfSaleEdge"]: {
	__typename: "Contember_PointOfSaleEdge",
	node: GraphQLTypes["Contember_PointOfSale"]
};
	["Contember_CartConnection"]: {
	__typename: "Contember_CartConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CartEdge"]>
};
	["Contember_CartEdge"]: {
	__typename: "Contember_CartEdge",
	node: GraphQLTypes["Contember_Cart"]
};
	["Contember_CustomerConnection"]: {
	__typename: "Contember_CustomerConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerEdge"]>
};
	["Contember_CustomerEdge"]: {
	__typename: "Contember_CustomerEdge",
	node: GraphQLTypes["Contember_Customer"]
};
	["Contember_LiftagoRide"]: {
	__typename: "Contember_LiftagoRide",
	_meta?: GraphQLTypes["Contember_LiftagoRideMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	liftagoId?: string | undefined,
	data?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	delivery?: GraphQLTypes["OrderDelivery"] | undefined
};
	["Contember_LiftagoRideMeta"]: {
	__typename: "Contember_LiftagoRideMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	liftagoId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	data?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	updatedAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryRouteWaypointsByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_DeliveryWaypointConnection"]: {
	__typename: "Contember_DeliveryWaypointConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryWaypointEdge"]>
};
	["Contember_DeliveryWaypointEdge"]: {
	__typename: "Contember_DeliveryWaypointEdge",
	node: GraphQLTypes["Contember_DeliveryWaypoint"]
};
	["Contember_CourierRoutesByWaypointsUniqueWhere"]: {
		waypoints?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined
};
	["Contember_DeliveryRouteConnection"]: {
	__typename: "Contember_DeliveryRouteConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryRouteEdge"]>
};
	["Contember_DeliveryRouteEdge"]: {
	__typename: "Contember_DeliveryRouteEdge",
	node: GraphQLTypes["Contember_DeliveryRoute"]
};
	["DeliveryMethodConnection"]: {
	__typename: "DeliveryMethodConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["DeliveryMethodEdge"]>
};
	["DeliveryMethodEdge"]: {
	__typename: "DeliveryMethodEdge",
	node: GraphQLTypes["DeliveryMethod"]
};
	["Contember_Cleaner"]: {
	__typename: "Contember_Cleaner",
	_meta?: GraphQLTypes["Contember_CleanerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	canReachTopShelf: boolean,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_CleanerMeta"]: {
	__typename: "Contember_CleanerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canReachTopShelf?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_StocksManager"]: {
	__typename: "Contember_StocksManager",
	_meta?: GraphQLTypes["Contember_StocksManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	managedProducts: Array<GraphQLTypes["Product"]>,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	managedProductsByCode?: GraphQLTypes["Product"] | undefined,
	managedProductsByStocks?: GraphQLTypes["Product"] | undefined,
	managedProductsByGroupPrices?: GraphQLTypes["Product"] | undefined,
	managedProductsByCartItems?: GraphQLTypes["Product"] | undefined,
	managedProductsByOrderItems?: GraphQLTypes["Product"] | undefined,
	managedProductsByInheritedCategories?: GraphQLTypes["Product"] | undefined,
	managedProductsByLocales?: GraphQLTypes["Product"] | undefined,
	managedProductsByCustomerPrices?: GraphQLTypes["Product"] | undefined,
	managedProductsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	managedProductsByMeta?: GraphQLTypes["Product"] | undefined,
	managedProductsByPage?: GraphQLTypes["Product"] | undefined,
	managedProductsBySalesDays?: GraphQLTypes["Product"] | undefined,
	managedProductsBySalesWeeks?: GraphQLTypes["Product"] | undefined,
	managedProductsBySalesMonths?: GraphQLTypes["Product"] | undefined,
	paginateManagedProducts: GraphQLTypes["ProductConnection"]
};
	["Contember_StocksManagerMeta"]: {
	__typename: "Contember_StocksManagerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	managedProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_StocksManagerManagedProductsByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["Contember_StocksManagerManagedProductsByStocksUniqueWhere"]: {
		stocks?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByGroupPricesUniqueWhere"]: {
		groupPrices?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByCartItemsUniqueWhere"]: {
		cartItems?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByOrderItemsUniqueWhere"]: {
		orderItems?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByInheritedCategoriesUniqueWhere"]: {
		inheritedCategories?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByCustomerPricesUniqueWhere"]: {
		customerPrices?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByVirtualProductUniqueWhere"]: {
		virtualProduct?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_ProductMetadataUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsByPageUniqueWhere"]: {
		page?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsBySalesDaysUniqueWhere"]: {
		salesDays?: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsBySalesWeeksUniqueWhere"]: {
		salesWeeks?: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined
};
	["Contember_StocksManagerManagedProductsBySalesMonthsUniqueWhere"]: {
		salesMonths?: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined
};
	["Contember_CourierManager"]: {
	__typename: "Contember_CourierManager",
	_meta?: GraphQLTypes["Contember_CourierManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_CourierManagerMeta"]: {
	__typename: "Contember_CourierManagerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Picker"]: {
	__typename: "Contember_Picker",
	_meta?: GraphQLTypes["Contember_PickerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined
};
	["Contember_PickerMeta"]: {
	__typename: "Contember_PickerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_RemunerationProfile"]: {
	__typename: "Contember_RemunerationProfile",
	_meta?: GraphQLTypes["Contember_RemunerationProfileMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	employmentInformation: Array<GraphQLTypes["Contember_EmploymentInformation"]>,
	workMonths?: GraphQLTypes["Contember_WorkMonth"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	paginateEmploymentInformation: GraphQLTypes["Contember_EmploymentInformationConnection"]
};
	["Contember_RemunerationProfileMeta"]: {
	__typename: "Contember_RemunerationProfileMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	employmentInformation?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workMonths?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmploymentInformation"]: {
	__typename: "Contember_EmploymentInformation",
	_meta?: GraphQLTypes["Contember_EmploymentInformationMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	hourlyRate?: number | undefined,
	remunerationProfiles?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	monthlySalary?: number | undefined,
	type: GraphQLTypes["Contember_EmploymentType"],
	name: string
};
	["Contember_EmploymentInformationMeta"]: {
	__typename: "Contember_EmploymentInformationMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hourlyRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remunerationProfiles?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	monthlySalary?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_EmploymentInformationOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	hourlyRate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	remunerationProfiles?: GraphQLTypes["Contember_RemunerationProfileOrderBy"] | undefined,
	monthlySalary?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_WorkMonth"]: {
	__typename: "Contember_WorkMonth",
	_meta?: GraphQLTypes["Contember_WorkMonthMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	year?: number | undefined,
	month?: number | undefined,
	hoursWorked?: number | undefined,
	billableHours?: number | undefined,
	remunerationProfile: Array<GraphQLTypes["Contember_RemunerationProfile"]>,
	remunerationProfileByEmploymentInformation?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	remunerationProfileByStaff?: GraphQLTypes["Contember_RemunerationProfile"] | undefined,
	paginateRemunerationProfile: GraphQLTypes["Contember_RemunerationProfileConnection"]
};
	["Contember_WorkMonthMeta"]: {
	__typename: "Contember_WorkMonthMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	year?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	month?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hoursWorked?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	billableHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WorkMonthRemunerationProfileByEmploymentInformationUniqueWhere"]: {
		employmentInformation?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined
};
	["Contember_WorkMonthRemunerationProfileByStaffUniqueWhere"]: {
		staff?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined
};
	["Contember_RemunerationProfileConnection"]: {
	__typename: "Contember_RemunerationProfileConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_RemunerationProfileEdge"]>
};
	["Contember_RemunerationProfileEdge"]: {
	__typename: "Contember_RemunerationProfileEdge",
	node: GraphQLTypes["Contember_RemunerationProfile"]
};
	["Contember_EmploymentInformationConnection"]: {
	__typename: "Contember_EmploymentInformationConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_EmploymentInformationEdge"]>
};
	["Contember_EmploymentInformationEdge"]: {
	__typename: "Contember_EmploymentInformationEdge",
	node: GraphQLTypes["Contember_EmploymentInformation"]
};
	["Contember_Cashier"]: {
	__typename: "Contember_Cashier",
	_meta?: GraphQLTypes["Contember_CashierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_CashierMeta"]: {
	__typename: "Contember_CashierMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_InternalBuyer"]: {
	__typename: "Contember_InternalBuyer",
	_meta?: GraphQLTypes["Contember_InternalBuyerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_InternalBuyerMeta"]: {
	__typename: "Contember_InternalBuyerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SalesManager"]: {
	__typename: "Contember_SalesManager",
	_meta?: GraphQLTypes["Contember_SalesManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	staff?: GraphQLTypes["Contember_Staff"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	countries: Array<GraphQLTypes["Contember_Country"]>,
	paginateCountries: GraphQLTypes["Contember_CountryConnection"]
};
	["Contember_SalesManagerMeta"]: {
	__typename: "Contember_SalesManagerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	countries?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CountryConnection"]: {
	__typename: "Contember_CountryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CountryEdge"]>
};
	["Contember_CountryEdge"]: {
	__typename: "Contember_CountryEdge",
	node: GraphQLTypes["Contember_Country"]
};
	["Contember_CashierManager"]: {
	__typename: "Contember_CashierManager",
	_meta?: GraphQLTypes["Contember_CashierManagerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	staff?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_CashierManagerMeta"]: {
	__typename: "Contember_CashierManagerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	staff?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessToBusinessManagerManagedBusinessesByCustomerUniqueWhere"]: {
		customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_BusinessToBusinessManagerManagedBusinessesByBusinessServiceEventsUniqueWhere"]: {
		businessServiceEvents?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined
};
	["Contember_BusinessToBusinessManagerManagedBusinessesByDoubleshotCoffeeEquipmentUniqueWhere"]: {
		doubleshotCoffeeEquipment?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined
};
	["Contember_BusinessCustomerConnection"]: {
	__typename: "Contember_BusinessCustomerConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessCustomerEdge"]>
};
	["Contember_BusinessCustomerEdge"]: {
	__typename: "Contember_BusinessCustomerEdge",
	node: GraphQLTypes["Contember_BusinessCustomer"]
};
	["Contember_BusinessServiceEvent"]: {
	__typename: "Contember_BusinessServiceEvent",
	_meta?: GraphQLTypes["Contember_BusinessServiceEventMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	businessCustomer?: GraphQLTypes["Contember_BusinessCustomer"] | undefined,
	businessService?: GraphQLTypes["Contember_BusinessService"] | undefined,
	filledNumberOfPeople?: number | undefined,
	filledInvoiced?: boolean | undefined,
	filledEquipment?: string | undefined,
	filledMillTamDem?: boolean | undefined,
	filledSOEspresso?: boolean | undefined,
	filledFilterCoffeeInMenu?: boolean | undefined,
	filledWaterMeterStatus?: string | undefined,
	filledServiceAction?: string | undefined,
	filledInvoiceIdentifier?: string | undefined,
	filledNote?: string | undefined,
	filledSupervisor?: GraphQLTypes["Contember_Staff"] | undefined,
	filledContact?: string | undefined,
	filledPhone?: boolean | undefined,
	filledEmail?: boolean | undefined,
	filledGrindingStonesCondition?: string | undefined,
	createdBy?: GraphQLTypes["Contember_Staff"] | undefined
};
	["Contember_BusinessServiceEventMeta"]: {
	__typename: "Contember_BusinessServiceEventMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCustomer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessService?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledNumberOfPeople?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledInvoiced?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledMillTamDem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledSOEspresso?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledFilterCoffeeInMenu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledWaterMeterStatus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledInvoiceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledSupervisor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledContact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledPhone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	filledGrindingStonesCondition?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdBy?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessService"]: {
	__typename: "Contember_BusinessService",
	_meta?: GraphQLTypes["Contember_BusinessServiceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	requireNote?: number | undefined,
	locales: Array<GraphQLTypes["Contember_BusinessServiceLocale"]>,
	requireNumberOfPeople?: number | undefined,
	requireInvoiced?: number | undefined,
	requireEquipment?: number | undefined,
	requireMillTamDem?: number | undefined,
	requireSOEspresso?: number | undefined,
	requireFilterCoffeeInMenu?: number | undefined,
	requireWaterMeterStatus?: number | undefined,
	requireServiceAction?: number | undefined,
	optionsEquipment?: string | undefined,
	optionsServiceAction?: string | undefined,
	requireInvoiceIdentifier?: number | undefined,
	requireSupervisor?: number | undefined,
	requireContact?: number | undefined,
	requirePhone?: number | undefined,
	requireEmail?: number | undefined,
	requireGrindingStonesCondition?: number | undefined,
	localesByLocale?: GraphQLTypes["Contember_BusinessServiceLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BusinessServiceLocaleConnection"]
};
	["Contember_BusinessServiceMeta"]: {
	__typename: "Contember_BusinessServiceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireNumberOfPeople?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireInvoiced?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireMillTamDem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireSOEspresso?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireFilterCoffeeInMenu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireWaterMeterStatus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	optionsEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	optionsServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireInvoiceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireSupervisor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireContact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requirePhone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	requireGrindingStonesCondition?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessServiceLocale"]: {
	__typename: "Contember_BusinessServiceLocale",
	_meta?: GraphQLTypes["Contember_BusinessServiceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	labelNote?: string | undefined,
	root?: GraphQLTypes["Contember_BusinessService"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	labelNumberOfPeople?: string | undefined,
	labelInvoiced?: string | undefined,
	labelEquipment?: string | undefined,
	labelMillTamDem?: string | undefined,
	labelSOEspresso?: string | undefined,
	labelFilterCoffeeInMenu?: string | undefined,
	labelWaterMeterStatus?: string | undefined,
	labelServiceAction?: string | undefined,
	labelInvoiceIdentifier?: string | undefined,
	labelSupervisor?: string | undefined,
	labelContact?: string | undefined,
	labelPhone?: string | undefined,
	labelEmail?: string | undefined,
	labelGrindingStonesCondition?: string | undefined
};
	["Contember_BusinessServiceLocaleMeta"]: {
	__typename: "Contember_BusinessServiceLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelNumberOfPeople?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelInvoiced?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelEquipment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelMillTamDem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelSOEspresso?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelFilterCoffeeInMenu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelWaterMeterStatus?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelServiceAction?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelInvoiceIdentifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelSupervisor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelContact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelPhone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	labelGrindingStonesCondition?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessServiceLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_BusinessServiceOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	labelNumberOfPeople?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelInvoiced?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelEquipment?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelMillTamDem?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelSOEspresso?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelFilterCoffeeInMenu?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelWaterMeterStatus?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelServiceAction?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelInvoiceIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelSupervisor?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelContact?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelPhone?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelEmail?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	labelGrindingStonesCondition?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_BusinessServiceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireNumberOfPeople?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireInvoiced?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireEquipment?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireMillTamDem?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireSOEspresso?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireFilterCoffeeInMenu?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireWaterMeterStatus?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireServiceAction?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	optionsEquipment?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	optionsServiceAction?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireInvoiceIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireSupervisor?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireContact?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requirePhone?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireEmail?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	requireGrindingStonesCondition?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_BusinessServiceLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_BusinessServiceLocaleConnection"]: {
	__typename: "Contember_BusinessServiceLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessServiceLocaleEdge"]>
};
	["Contember_BusinessServiceLocaleEdge"]: {
	__typename: "Contember_BusinessServiceLocaleEdge",
	node: GraphQLTypes["Contember_BusinessServiceLocale"]
};
	["Contember_BusinessServiceEventOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fulfilledAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	businessCustomer?: GraphQLTypes["Contember_BusinessCustomerOrderBy"] | undefined,
	businessService?: GraphQLTypes["Contember_BusinessServiceOrderBy"] | undefined,
	filledNumberOfPeople?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledInvoiced?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledEquipment?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledMillTamDem?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledSOEspresso?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledFilterCoffeeInMenu?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledWaterMeterStatus?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledServiceAction?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledInvoiceIdentifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledSupervisor?: GraphQLTypes["Contember_StaffOrderBy"] | undefined,
	filledContact?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledPhone?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledEmail?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	filledGrindingStonesCondition?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdBy?: GraphQLTypes["Contember_StaffOrderBy"] | undefined
};
	["Contember_DoubleshotCoffeeEquipment"]: {
	__typename: "Contember_DoubleshotCoffeeEquipment",
	_meta?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	espressoMachine?: string | undefined,
	espressoGrinder?: string | undefined,
	batchBrew?: string | undefined,
	batchGrinder?: string | undefined,
	waterFilter?: string | undefined,
	mechanicalFilter?: string | undefined
};
	["Contember_DoubleshotCoffeeEquipmentMeta"]: {
	__typename: "Contember_DoubleshotCoffeeEquipmentMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	espressoMachine?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	espressoGrinder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	batchBrew?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	batchGrinder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	waterFilter?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	mechanicalFilter?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BusinessServiceEventConnection"]: {
	__typename: "Contember_BusinessServiceEventConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessServiceEventEdge"]>
};
	["Contember_BusinessServiceEventEdge"]: {
	__typename: "Contember_BusinessServiceEventEdge",
	node: GraphQLTypes["Contember_BusinessServiceEvent"]
};
	["Contember_OrderRecurrence"]: {
	__typename: "Contember_OrderRecurrence",
	_meta?: GraphQLTypes["Contember_OrderRecurrenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["Contember_OrderRecurrenceLocale"]>,
	order: number,
	businessCategory: Array<GraphQLTypes["Contember_BusinessCategory"]>,
	localesByLocale?: GraphQLTypes["Contember_OrderRecurrenceLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_OrderRecurrenceLocaleConnection"],
	paginateBusinessCategory: GraphQLTypes["Contember_BusinessCategoryConnection"]
};
	["Contember_OrderRecurrenceMeta"]: {
	__typename: "Contember_OrderRecurrenceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderRecurrenceLocale"]: {
	__typename: "Contember_OrderRecurrenceLocale",
	_meta?: GraphQLTypes["Contember_OrderRecurrenceLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_OrderRecurrence"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_OrderRecurrenceLocaleMeta"]: {
	__typename: "Contember_OrderRecurrenceLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_OrderRecurrenceLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_OrderRecurrenceOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_OrderRecurrenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_OrderRecurrenceLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_OrderRecurrenceLocaleConnection"]: {
	__typename: "Contember_OrderRecurrenceLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_OrderRecurrenceLocaleEdge"]>
};
	["Contember_OrderRecurrenceLocaleEdge"]: {
	__typename: "Contember_OrderRecurrenceLocaleEdge",
	node: GraphQLTypes["Contember_OrderRecurrenceLocale"]
};
	["Contember_BusinessCategoryConnection"]: {
	__typename: "Contember_BusinessCategoryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessCategoryEdge"]>
};
	["Contember_BusinessCategoryEdge"]: {
	__typename: "Contember_BusinessCategoryEdge",
	node: GraphQLTypes["Contember_BusinessCategory"]
};
	["Contember_ProductList"]: {
	__typename: "Contember_ProductList",
	_meta?: GraphQLTypes["Contember_ProductListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ProductListItem"]>,
	businessCategory?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	paginateItems: GraphQLTypes["Contember_ProductListItemConnection"]
};
	["Contember_ProductListMeta"]: {
	__typename: "Contember_ProductListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductListItem"]: {
	__typename: "Contember_ProductListItem",
	_meta?: GraphQLTypes["Contember_ProductListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ProductList"] | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	defaultQuantityForOrder?: number | undefined
};
	["Contember_ProductListItemMeta"]: {
	__typename: "Contember_ProductListItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_ProductListOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	defaultQuantityForOrder?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductListItemConnection"]: {
	__typename: "Contember_ProductListItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductListItemEdge"]>
};
	["Contember_ProductListItemEdge"]: {
	__typename: "Contember_ProductListItemEdge",
	node: GraphQLTypes["Contember_ProductListItem"]
};
	["Contember_ProductCategoryList"]: {
	__typename: "Contember_ProductCategoryList",
	_meta?: GraphQLTypes["Contember_ProductCategoryListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ProductCategoryListItem"]>,
	locales: Array<GraphQLTypes["Contember_ProductCategoryListLocale"]>,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_BusinessCategory"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_ProductCategoryListLocale"] | undefined,
	paginateItems: GraphQLTypes["Contember_ProductCategoryListItemConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductCategoryListLocaleConnection"]
};
	["Contember_ProductCategoryListMeta"]: {
	__typename: "Contember_ProductCategoryListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryListItem"]: {
	__typename: "Contember_ProductCategoryListItem",
	_meta?: GraphQLTypes["Contember_ProductCategoryListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategory"] | undefined,
	showRecipeImages: boolean,
	showPackingImages: boolean,
	showAllAvailableProducts: boolean
};
	["Contember_ProductCategoryListItemMeta"]: {
	__typename: "Contember_ProductCategoryListItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productCategory?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showRecipeImages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showPackingImages?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_ProductCategoryListOrderBy"] | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryOrderBy"] | undefined,
	showRecipeImages?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	showPackingImages?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	showAllAvailableProducts?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductCategoryListLocale"]: {
	__typename: "Contember_ProductCategoryListLocale",
	_meta?: GraphQLTypes["Contember_ProductCategoryListLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductCategoryList"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductCategoryListLocaleMeta"]: {
	__typename: "Contember_ProductCategoryListLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCategoryListLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_ProductCategoryListOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_ProductCategoryListLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_ProductCategoryListItemConnection"]: {
	__typename: "Contember_ProductCategoryListItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryListItemEdge"]>
};
	["Contember_ProductCategoryListItemEdge"]: {
	__typename: "Contember_ProductCategoryListItemEdge",
	node: GraphQLTypes["Contember_ProductCategoryListItem"]
};
	["Contember_ProductCategoryListLocaleConnection"]: {
	__typename: "Contember_ProductCategoryListLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCategoryListLocaleEdge"]>
};
	["Contember_ProductCategoryListLocaleEdge"]: {
	__typename: "Contember_ProductCategoryListLocaleEdge",
	node: GraphQLTypes["Contember_ProductCategoryListLocale"]
};
	["Contember_BusinessCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_BusinessCategoryBusinessCustomersByCustomerUniqueWhere"]: {
		customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_BusinessCategoryBusinessCustomersByBusinessServiceEventsUniqueWhere"]: {
		businessServiceEvents?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined
};
	["Contember_BusinessCategoryBusinessCustomersByDoubleshotCoffeeEquipmentUniqueWhere"]: {
		doubleshotCoffeeEquipment?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined
};
	["Contember_BusinessCategoryLocaleConnection"]: {
	__typename: "Contember_BusinessCategoryLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BusinessCategoryLocaleEdge"]>
};
	["Contember_BusinessCategoryLocaleEdge"]: {
	__typename: "Contember_BusinessCategoryLocaleEdge",
	node: GraphQLTypes["Contember_BusinessCategoryLocale"]
};
	["Contember_OrderRecurrenceConnection"]: {
	__typename: "Contember_OrderRecurrenceConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_OrderRecurrenceEdge"]>
};
	["Contember_OrderRecurrenceEdge"]: {
	__typename: "Contember_OrderRecurrenceEdge",
	node: GraphQLTypes["Contember_OrderRecurrence"]
};
	["Contember_ProductIngredientLocale"]: {
	__typename: "Contember_ProductIngredientLocale",
	_meta?: GraphQLTypes["Contember_ProductIngredientLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductIngredient"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductIngredientLocaleMeta"]: {
	__typename: "Contember_ProductIngredientLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredient"]: {
	__typename: "Contember_ProductIngredient",
	_meta?: GraphQLTypes["Contember_ProductIngredientMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["Contember_ProductIngredientLocale"]>,
	internalName: string,
	categories: Array<GraphQLTypes["Contember_ProductIngredientCategory"]>,
	allergens: Array<GraphQLTypes["Contember_Allergen"]>,
	needsPrepping: boolean,
	allowCustomerRating?: boolean | undefined,
	customerRatings: Array<GraphQLTypes["Contember_CustomerIngredientRating"]>,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnit"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainer"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	suppliers?: GraphQLTypes["Contember_IngredientSupplierList"] | undefined,
	incrementQuantityNumber: number,
	localesByLocale?: GraphQLTypes["Contember_ProductIngredientLocale"] | undefined,
	customerRatingsByCustomer?: GraphQLTypes["Contember_CustomerIngredientRating"] | undefined,
	paginateLocales: GraphQLTypes["Contember_ProductIngredientLocaleConnection"],
	paginateCategories: GraphQLTypes["Contember_ProductIngredientCategoryConnection"],
	paginateAllergens: GraphQLTypes["Contember_AllergenConnection"],
	paginateCustomerRatings: GraphQLTypes["Contember_CustomerIngredientRatingConnection"]
};
	["Contember_ProductIngredientMeta"]: {
	__typename: "Contember_ProductIngredientMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allergens?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	allowCustomerRating?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerRatings?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	suppliers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_ProductIngredientOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	allowCustomerRating?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitOrderBy"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainerOrderBy"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	suppliers?: GraphQLTypes["Contember_IngredientSupplierListOrderBy"] | undefined,
	incrementQuantityNumber?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductIngredientItemQuantityUnitOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_IngredientSupplierListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductIngredientCategory"]: {
	__typename: "Contember_ProductIngredientCategory",
	_meta?: GraphQLTypes["Contember_ProductIngredientCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	ingredients: Array<GraphQLTypes["Contember_ProductIngredient"]>,
	locales: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocale"]>,
	order: number,
	localesByLocale?: GraphQLTypes["Contember_ProductIngredientCategoryLocale"] | undefined,
	paginateIngredients: GraphQLTypes["Contember_ProductIngredientConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductIngredientCategoryLocaleConnection"]
};
	["Contember_ProductIngredientCategoryMeta"]: {
	__typename: "Contember_ProductIngredientCategoryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientCategoryLocale"]: {
	__typename: "Contember_ProductIngredientCategoryLocale",
	_meta?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductIngredientCategory"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleMeta"]: {
	__typename: "Contember_ProductIngredientCategoryLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientCategoryOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_ProductIngredientCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductIngredientCategoryLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_ProductIngredientConnection"]: {
	__typename: "Contember_ProductIngredientConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientEdge"]>
};
	["Contember_ProductIngredientEdge"]: {
	__typename: "Contember_ProductIngredientEdge",
	node: GraphQLTypes["Contember_ProductIngredient"]
};
	["Contember_ProductIngredientCategoryLocaleConnection"]: {
	__typename: "Contember_ProductIngredientCategoryLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientCategoryLocaleEdge"]>
};
	["Contember_ProductIngredientCategoryLocaleEdge"]: {
	__typename: "Contember_ProductIngredientCategoryLocaleEdge",
	node: GraphQLTypes["Contember_ProductIngredientCategoryLocale"]
};
	["Contember_Allergen"]: {
	__typename: "Contember_Allergen",
	_meta?: GraphQLTypes["Contember_AllergenMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	locales: Array<GraphQLTypes["Contember_AllergenLocale"]>,
	ingredients: Array<GraphQLTypes["Contember_ProductIngredient"]>,
	code: number,
	localesByLocale?: GraphQLTypes["Contember_AllergenLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_AllergenLocaleConnection"],
	paginateIngredients: GraphQLTypes["Contember_ProductIngredientConnection"]
};
	["Contember_AllergenMeta"]: {
	__typename: "Contember_AllergenMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AllergenLocale"]: {
	__typename: "Contember_AllergenLocale",
	_meta?: GraphQLTypes["Contember_AllergenLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	root?: GraphQLTypes["Contember_Allergen"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_AllergenLocaleMeta"]: {
	__typename: "Contember_AllergenLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AllergenLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_AllergenOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_AllergenOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_AllergenLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_AllergenLocaleConnection"]: {
	__typename: "Contember_AllergenLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AllergenLocaleEdge"]>
};
	["Contember_AllergenLocaleEdge"]: {
	__typename: "Contember_AllergenLocaleEdge",
	node: GraphQLTypes["Contember_AllergenLocale"]
};
	["Contember_CustomerIngredientRating"]: {
	__typename: "Contember_CustomerIngredientRating",
	_meta?: GraphQLTypes["Contember_CustomerIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	rating?: GraphQLTypes["Contember_ProductIngredientRating"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredient"] | undefined
};
	["Contember_CustomerIngredientRatingMeta"]: {
	__typename: "Contember_CustomerIngredientRatingMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	rating?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientRating"]: {
	__typename: "Contember_ProductIngredientRating",
	_meta?: GraphQLTypes["Contember_ProductIngredientRatingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	name: string,
	icon?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_ProductIngredientRatingMeta"]: {
	__typename: "Contember_ProductIngredientRatingMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerIngredientRatingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	rating?: GraphQLTypes["Contember_ProductIngredientRatingOrderBy"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredientOrderBy"] | undefined
};
	["Contember_ProductIngredientRatingOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	icon?: GraphQLTypes["Contember_ImageOrderBy"] | undefined
};
	["Contember_ProductIngredientItemQuantityUnit"]: {
	__typename: "Contember_ProductIngredientItemQuantityUnit",
	_meta?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string
};
	["Contember_ProductIngredientItemQuantityUnitMeta"]: {
	__typename: "Contember_ProductIngredientItemQuantityUnitMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FreshingContainer"]: {
	__typename: "Contember_FreshingContainer",
	_meta?: GraphQLTypes["Contember_FreshingContainerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	volumeMl: number,
	workspace?: GraphQLTypes["Contember_Workspace"] | undefined
};
	["Contember_FreshingContainerMeta"]: {
	__typename: "Contember_FreshingContainerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	volumeMl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workspace?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Workspace"]: {
	__typename: "Contember_Workspace",
	_meta?: GraphQLTypes["Contember_WorkspaceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours: number,
	freshingContainerVolumeMl: number,
	freshingPrepareOrderByOffsetMinutes: number,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	freshingContainers: Array<GraphQLTypes["Contember_FreshingContainer"]>,
	openingHours?: GraphQLTypes["Contember_WeekHours"] | undefined,
	workingHours?: GraphQLTypes["Contember_WeekHours"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_FreshingContainer"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_VirtualProduct"] | undefined,
	discountVatRate?: GraphQLTypes["VatRate"] | undefined,
	name: string,
	businessAddress?: string | undefined,
	migrationTest?: string | undefined,
	paginateFreshingContainers: GraphQLTypes["Contember_FreshingContainerConnection"]
};
	["Contember_WorkspaceMeta"]: {
	__typename: "Contember_WorkspaceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDeadlineTime?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ordersLoadingDurationHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainerVolumeMl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingPrepareOrderByOffsetMinutes?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	checkoutUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	paymentUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerProfileUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	userProfileUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	messengerUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	messengerIosUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	messengerAndroidUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	openingHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	workingHours?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountVatRate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	businessAddress?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	migrationTest?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WeekHours"]: {
	__typename: "Contember_WeekHours",
	_meta?: GraphQLTypes["Contember_WeekHoursMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined
};
	["Contember_WeekHoursMeta"]: {
	__typename: "Contember_WeekHoursMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	mon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	tue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	wed?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	thu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fri?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sat?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sun?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_VirtualProduct"]: {
	__typename: "Contember_VirtualProduct",
	_meta?: GraphQLTypes["Contember_VirtualProductMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_VirtualProductType"],
	product?: GraphQLTypes["Product"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"],
	recipientEmail?: boolean | undefined
};
	["Contember_VirtualProductMeta"]: {
	__typename: "Contember_VirtualProductMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	voucherCreditCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipientEmail?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_FreshingContainerConnection"]: {
	__typename: "Contember_FreshingContainerConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_FreshingContainerEdge"]>
};
	["Contember_FreshingContainerEdge"]: {
	__typename: "Contember_FreshingContainerEdge",
	node: GraphQLTypes["Contember_FreshingContainer"]
};
	["Contember_IngredientSupplierList"]: {
	__typename: "Contember_IngredientSupplierList",
	_meta?: GraphQLTypes["Contember_IngredientSupplierListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_IngredientSupplierItem"]>,
	paginateItems: GraphQLTypes["Contember_IngredientSupplierItemConnection"]
};
	["Contember_IngredientSupplierListMeta"]: {
	__typename: "Contember_IngredientSupplierListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplierItem"]: {
	__typename: "Contember_IngredientSupplierItem",
	_meta?: GraphQLTypes["Contember_IngredientSupplierItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_IngredientSupplierList"] | undefined,
	supplier?: GraphQLTypes["Contember_IngredientSupplier"] | undefined
};
	["Contember_IngredientSupplierItemMeta"]: {
	__typename: "Contember_IngredientSupplierItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	supplier?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplier"]: {
	__typename: "Contember_IngredientSupplier",
	_meta?: GraphQLTypes["Contember_IngredientSupplierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	internalNote?: string | undefined,
	locales: Array<GraphQLTypes["Contember_IngredientSupplierLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_IngredientSupplierLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_IngredientSupplierLocaleConnection"]
};
	["Contember_IngredientSupplierMeta"]: {
	__typename: "Contember_IngredientSupplierMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplierLocale"]: {
	__typename: "Contember_IngredientSupplierLocale",
	_meta?: GraphQLTypes["Contember_IngredientSupplierLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_IngredientSupplier"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_IngredientSupplierLocaleMeta"]: {
	__typename: "Contember_IngredientSupplierLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_IngredientSupplierLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_IngredientSupplierOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_IngredientSupplierOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_IngredientSupplierLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_IngredientSupplierLocaleConnection"]: {
	__typename: "Contember_IngredientSupplierLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IngredientSupplierLocaleEdge"]>
};
	["Contember_IngredientSupplierLocaleEdge"]: {
	__typename: "Contember_IngredientSupplierLocaleEdge",
	node: GraphQLTypes["Contember_IngredientSupplierLocale"]
};
	["Contember_IngredientSupplierItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_IngredientSupplierListOrderBy"] | undefined,
	supplier?: GraphQLTypes["Contember_IngredientSupplierOrderBy"] | undefined
};
	["Contember_IngredientSupplierItemConnection"]: {
	__typename: "Contember_IngredientSupplierItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_IngredientSupplierItemEdge"]>
};
	["Contember_IngredientSupplierItemEdge"]: {
	__typename: "Contember_IngredientSupplierItemEdge",
	node: GraphQLTypes["Contember_IngredientSupplierItem"]
};
	["Contember_ProductIngredientLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_ProductIngredientCustomerRatingsByCustomerUniqueWhere"]: {
		customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_ProductIngredientLocaleConnection"]: {
	__typename: "Contember_ProductIngredientLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientLocaleEdge"]>
};
	["Contember_ProductIngredientLocaleEdge"]: {
	__typename: "Contember_ProductIngredientLocaleEdge",
	node: GraphQLTypes["Contember_ProductIngredientLocale"]
};
	["Contember_ProductIngredientCategoryConnection"]: {
	__typename: "Contember_ProductIngredientCategoryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientCategoryEdge"]>
};
	["Contember_ProductIngredientCategoryEdge"]: {
	__typename: "Contember_ProductIngredientCategoryEdge",
	node: GraphQLTypes["Contember_ProductIngredientCategory"]
};
	["Contember_AllergenConnection"]: {
	__typename: "Contember_AllergenConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AllergenEdge"]>
};
	["Contember_AllergenEdge"]: {
	__typename: "Contember_AllergenEdge",
	node: GraphQLTypes["Contember_Allergen"]
};
	["Contember_CustomerIngredientRatingConnection"]: {
	__typename: "Contember_CustomerIngredientRatingConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerIngredientRatingEdge"]>
};
	["Contember_CustomerIngredientRatingEdge"]: {
	__typename: "Contember_CustomerIngredientRatingEdge",
	node: GraphQLTypes["Contember_CustomerIngredientRating"]
};
	["Contember_BlogLocale"]: {
	__typename: "Contember_BlogLocale",
	_meta?: GraphQLTypes["Contember_BlogLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Contember_Blog"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_BlogLocaleMeta"]: {
	__typename: "Contember_BlogLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pageName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Blog"]: {
	__typename: "Contember_Blog",
	_meta?: GraphQLTypes["Contember_BlogMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	locales: Array<GraphQLTypes["Contember_BlogLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_BlogLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BlogLocaleConnection"]
};
	["Contember_BlogMeta"]: {
	__typename: "Contember_BlogMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	pageName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	lead?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_BlogOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_BlogOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unique?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_BlogLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_BlogLocaleConnection"]: {
	__typename: "Contember_BlogLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BlogLocaleEdge"]>
};
	["Contember_BlogLocaleEdge"]: {
	__typename: "Contember_BlogLocaleEdge",
	node: GraphQLTypes["Contember_BlogLocale"]
};
	["Contember_BlogPostLocale"]: {
	__typename: "Contember_BlogPostLocale",
	_meta?: GraphQLTypes["Contember_BlogPostLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPost"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	cover?: GraphQLTypes["Contember_Cover"] | undefined,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	seo?: GraphQLTypes["Contember_Seo"] | undefined,
	products: Array<GraphQLTypes["Product"]>,
	paginateProducts: GraphQLTypes["ProductConnection"]
};
	["Contember_BlogPostLocaleMeta"]: {
	__typename: "Contember_BlogPostLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availableForWeb?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availableForMobile?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cover?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPost"]: {
	__typename: "Contember_BlogPost",
	_meta?: GraphQLTypes["Contember_BlogPostMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	locales: Array<GraphQLTypes["Contember_BlogPostLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesByCover?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesByContent?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesByLink?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	localesBySeo?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_BlogPostLocaleConnection"]
};
	["Contember_BlogPostMeta"]: {
	__typename: "Contember_BlogPostMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	availableForWeb?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	availableForMobile?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_BlogPostOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	cover?: GraphQLTypes["Contember_CoverOrderBy"] | undefined,
	content?: GraphQLTypes["Contember_ContentOrderBy"] | undefined,
	link?: GraphQLTypes["Contember_LinkableOrderBy"] | undefined,
	seo?: GraphQLTypes["Contember_SeoOrderBy"] | undefined
};
	["Contember_BlogPostOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CoverOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	primaryTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	medium?: GraphQLTypes["Contember_MediumOrderBy"] | undefined,
	buttonColors?: GraphQLTypes["Contember_ColorPalleteOrderBy"] | undefined
};
	["Contember_MediumOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	colorTheme?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	video?: GraphQLTypes["Contember_VideoOrderBy"] | undefined
};
	["Contember_VideoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	src?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	width?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	height?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	size?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	duration?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	poster?: GraphQLTypes["Contember_ImageOrderBy"] | undefined
};
	["Contember_ColorPalleteOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	background?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	text?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ContentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_LinkableOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	url?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostLocaleOrderBy"] | undefined,
	page?: GraphQLTypes["Contember_PageLocaleOrderBy"] | undefined,
	wikiPage?: GraphQLTypes["Contember_WikiPageOrderBy"] | undefined,
	redirect?: GraphQLTypes["Contember_RedirectOrderBy"] | undefined
};
	["Contember_PageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	link?: GraphQLTypes["Contember_LinkableOrderBy"] | undefined,
	root?: GraphQLTypes["Contember_PageOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	seo?: GraphQLTypes["Contember_SeoOrderBy"] | undefined,
	content?: GraphQLTypes["Contember_ContentOrderBy"] | undefined,
	cover?: GraphQLTypes["Contember_CoverOrderBy"] | undefined,
	theme?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_SeoOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ogTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ogDescription?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ogImage?: GraphQLTypes["Contember_ImageOrderBy"] | undefined
};
	["Contember_WikiPageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	emoji?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	link?: GraphQLTypes["Contember_LinkableOrderBy"] | undefined,
	parent?: GraphQLTypes["Contember_WikiPageOrderBy"] | undefined,
	content?: GraphQLTypes["Contember_ContentOrderBy"] | undefined
};
	["Contember_RedirectOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	link?: GraphQLTypes["Contember_LinkableOrderBy"] | undefined,
	target?: GraphQLTypes["Contember_LinkOrderBy"] | undefined
};
	["Contember_LinkOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	externalLink?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalLink?: GraphQLTypes["Contember_LinkableOrderBy"] | undefined
};
	["Contember_BlogPostLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_BlogPostLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined
};
	["Contember_BlogPostLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined
};
	["Contember_BlogPostLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined
};
	["Contember_BlogPostLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined
};
	["Contember_BlogPostLocaleConnection"]: {
	__typename: "Contember_BlogPostLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BlogPostLocaleEdge"]>
};
	["Contember_BlogPostLocaleEdge"]: {
	__typename: "Contember_BlogPostLocaleEdge",
	node: GraphQLTypes["Contember_BlogPostLocale"]
};
	["Contember_Cover"]: {
	__typename: "Contember_Cover",
	_meta?: GraphQLTypes["Contember_CoverMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	buttonColors?: GraphQLTypes["Contember_ColorPallete"] | undefined
};
	["Contember_CoverMeta"]: {
	__typename: "Contember_CoverMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	primaryTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	buttonColors?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Medium"]: {
	__typename: "Contember_Medium",
	_meta?: GraphQLTypes["Contember_MediumMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_MediumType"] | undefined,
	colorTheme?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	video?: GraphQLTypes["Contember_Video"] | undefined
};
	["Contember_MediumMeta"]: {
	__typename: "Contember_MediumMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	colorTheme?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	video?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Video"]: {
	__typename: "Contember_Video",
	_meta?: GraphQLTypes["Contember_VideoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_VideoMeta"]: {
	__typename: "Contember_VideoMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	src?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	width?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	height?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	size?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	duration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	poster?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ColorPallete"]: {
	__typename: "Contember_ColorPallete",
	_meta?: GraphQLTypes["Contember_ColorPalleteMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	background?: string | undefined,
	text?: string | undefined
};
	["Contember_ColorPalleteMeta"]: {
	__typename: "Contember_ColorPalleteMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	background?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Content"]: {
	__typename: "Contember_Content",
	_meta?: GraphQLTypes["Contember_ContentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	blocks: Array<GraphQLTypes["Contember_ContentBlock"]>,
	blocksByReferences?: GraphQLTypes["Contember_ContentBlock"] | undefined,
	paginateBlocks: GraphQLTypes["Contember_ContentBlockConnection"]
};
	["Contember_ContentMeta"]: {
	__typename: "Contember_ContentMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blocks?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ContentBlock"]: {
	__typename: "Contember_ContentBlock",
	_meta?: GraphQLTypes["Contember_ContentBlockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	json: string,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	references: Array<GraphQLTypes["Contember_ContentReference"]>,
	referencesByProducts?: GraphQLTypes["Contember_ContentReference"] | undefined,
	referencesByProductBanners?: GraphQLTypes["Contember_ContentReference"] | undefined,
	referencesByDeliveryRegions?: GraphQLTypes["Contember_ContentReference"] | undefined,
	paginateReferences: GraphQLTypes["Contember_ContentReferenceConnection"]
};
	["Contember_ContentBlockMeta"]: {
	__typename: "Contember_ContentBlockMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	json?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	references?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ContentReference"]: {
	__typename: "Contember_ContentReference",
	_meta?: GraphQLTypes["Contember_ContentReferenceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_ContentReferenceType"],
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	block?: GraphQLTypes["Contember_ContentBlock"] | undefined,
	colorPallete?: GraphQLTypes["Contember_ColorPallete"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	blogPosts?: GraphQLTypes["Contember_BlogPostList"] | undefined,
	links?: GraphQLTypes["Contember_LinkList"] | undefined,
	products?: GraphQLTypes["Contember_ProductList"] | undefined,
	contentSize?: GraphQLTypes["Contember_ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Contember_Hero"] | undefined,
	gallery?: GraphQLTypes["Contember_Gallery"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndApps"] | undefined,
	productGrid?: GraphQLTypes["Contember_ProductGrid"] | undefined,
	productBanners?: GraphQLTypes["Contember_ProductBannerList"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegions"] | undefined,
	textList?: GraphQLTypes["Contember_TextList"] | undefined,
	gridTiles?: GraphQLTypes["Contember_GridTiles"] | undefined
};
	["Contember_ContentReferenceMeta"]: {
	__typename: "Contember_ContentReferenceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	primaryText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	jsonContent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	block?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	colorPallete?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPosts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	links?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contentSize?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	misc?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	hero?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gallery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productGrid?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productBanners?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	textList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gridTiles?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Link"]: {
	__typename: "Contember_Link",
	_meta?: GraphQLTypes["Contember_LinkMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_LinkType"],
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Contember_Linkable"] | undefined
};
	["Contember_LinkMeta"]: {
	__typename: "Contember_LinkMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	externalLink?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalLink?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Linkable"]: {
	__typename: "Contember_Linkable",
	_meta?: GraphQLTypes["Contember_LinkableMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	url: string,
	blogPost?: GraphQLTypes["Contember_BlogPostLocale"] | undefined,
	page?: GraphQLTypes["Contember_PageLocale"] | undefined,
	wikiPage?: GraphQLTypes["Contember_WikiPage"] | undefined,
	redirect?: GraphQLTypes["Contember_Redirect"] | undefined
};
	["Contember_LinkableMeta"]: {
	__typename: "Contember_LinkableMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	url?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	page?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	wikiPage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	redirect?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PageLocale"]: {
	__typename: "Contember_PageLocale",
	_meta?: GraphQLTypes["Contember_PageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	root?: GraphQLTypes["Contember_Page"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	seo?: GraphQLTypes["Contember_Seo"] | undefined,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	cover?: GraphQLTypes["Contember_Cover"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined
};
	["Contember_PageLocaleMeta"]: {
	__typename: "Contember_PageLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	seo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cover?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	theme?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Page"]: {
	__typename: "Contember_Page",
	_meta?: GraphQLTypes["Contember_PageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["Contember_PageLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesByLink?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesBySeo?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesByContent?: GraphQLTypes["Contember_PageLocale"] | undefined,
	localesByCover?: GraphQLTypes["Contember_PageLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_PageLocaleConnection"]
};
	["Contember_PageMeta"]: {
	__typename: "Contember_PageMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_PageLocalesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined
};
	["Contember_PageLocalesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined
};
	["Contember_PageLocalesByContentUniqueWhere"]: {
		content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined
};
	["Contember_PageLocalesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined
};
	["Contember_PageLocaleConnection"]: {
	__typename: "Contember_PageLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PageLocaleEdge"]>
};
	["Contember_PageLocaleEdge"]: {
	__typename: "Contember_PageLocaleEdge",
	node: GraphQLTypes["Contember_PageLocale"]
};
	["Contember_Seo"]: {
	__typename: "Contember_Seo",
	_meta?: GraphQLTypes["Contember_SeoMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_SeoMeta"]: {
	__typename: "Contember_SeoMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ogTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ogDescription?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ogImage?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WikiPage"]: {
	__typename: "Contember_WikiPage",
	_meta?: GraphQLTypes["Contember_WikiPageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	parent?: GraphQLTypes["Contember_WikiPage"] | undefined,
	children: Array<GraphQLTypes["Contember_WikiPage"]>,
	content?: GraphQLTypes["Contember_Content"] | undefined,
	childrenByLink?: GraphQLTypes["Contember_WikiPage"] | undefined,
	childrenByChildren?: GraphQLTypes["Contember_WikiPage"] | undefined,
	childrenByContent?: GraphQLTypes["Contember_WikiPage"] | undefined,
	paginateChildren: GraphQLTypes["Contember_WikiPageConnection"]
};
	["Contember_WikiPageMeta"]: {
	__typename: "Contember_WikiPageMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	emoji?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	parent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	children?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	content?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_WikiPageChildrenByLinkUniqueWhere"]: {
		link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined
};
	["Contember_WikiPageChildrenByChildrenUniqueWhere"]: {
		children?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined
};
	["Contember_WikiPageChildrenByContentUniqueWhere"]: {
		content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined
};
	["Contember_WikiPageConnection"]: {
	__typename: "Contember_WikiPageConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_WikiPageEdge"]>
};
	["Contember_WikiPageEdge"]: {
	__typename: "Contember_WikiPageEdge",
	node: GraphQLTypes["Contember_WikiPage"]
};
	["Contember_Redirect"]: {
	__typename: "Contember_Redirect",
	_meta?: GraphQLTypes["Contember_RedirectMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	link?: GraphQLTypes["Contember_Linkable"] | undefined,
	target?: GraphQLTypes["Contember_Link"] | undefined
};
	["Contember_RedirectMeta"]: {
	__typename: "Contember_RedirectMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	target?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostList"]: {
	__typename: "Contember_BlogPostList",
	_meta?: GraphQLTypes["Contember_BlogPostListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_BlogPostListItem"]>,
	paginateItems: GraphQLTypes["Contember_BlogPostListItemConnection"]
};
	["Contember_BlogPostListMeta"]: {
	__typename: "Contember_BlogPostListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostListItem"]: {
	__typename: "Contember_BlogPostListItem",
	_meta?: GraphQLTypes["Contember_BlogPostListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Contember_BlogPostList"] | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostLocale"] | undefined
};
	["Contember_BlogPostListItemMeta"]: {
	__typename: "Contember_BlogPostListItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	caption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	blogPost?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_BlogPostListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	caption?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_BlogPostListOrderBy"] | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostLocaleOrderBy"] | undefined
};
	["Contember_BlogPostListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_BlogPostListItemConnection"]: {
	__typename: "Contember_BlogPostListItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BlogPostListItemEdge"]>
};
	["Contember_BlogPostListItemEdge"]: {
	__typename: "Contember_BlogPostListItemEdge",
	node: GraphQLTypes["Contember_BlogPostListItem"]
};
	["Contember_LinkList"]: {
	__typename: "Contember_LinkList",
	_meta?: GraphQLTypes["Contember_LinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_LinkListItem"]>,
	paginateItems: GraphQLTypes["Contember_LinkListItemConnection"]
};
	["Contember_LinkListMeta"]: {
	__typename: "Contember_LinkListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LinkListItem"]: {
	__typename: "Contember_LinkListItem",
	_meta?: GraphQLTypes["Contember_LinkListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	isPrimary?: boolean | undefined,
	list?: GraphQLTypes["Contember_LinkList"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined
};
	["Contember_LinkListItemMeta"]: {
	__typename: "Contember_LinkListItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isPrimary?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LinkListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isPrimary?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_LinkListOrderBy"] | undefined,
	link?: GraphQLTypes["Contember_LinkOrderBy"] | undefined
};
	["Contember_LinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_LinkListItemConnection"]: {
	__typename: "Contember_LinkListItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_LinkListItemEdge"]>
};
	["Contember_LinkListItemEdge"]: {
	__typename: "Contember_LinkListItemEdge",
	node: GraphQLTypes["Contember_LinkListItem"]
};
	["Contember_Hero"]: {
	__typename: "Contember_Hero",
	_meta?: GraphQLTypes["Contember_HeroMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined
};
	["Contember_HeroMeta"]: {
	__typename: "Contember_HeroMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showLogo?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	middleContent?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Gallery"]: {
	__typename: "Contember_Gallery",
	_meta?: GraphQLTypes["Contember_GalleryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_GalleryItem"]>,
	paginateItems: GraphQLTypes["Contember_GalleryItemConnection"]
};
	["Contember_GalleryMeta"]: {
	__typename: "Contember_GalleryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GalleryItem"]: {
	__typename: "Contember_GalleryItem",
	_meta?: GraphQLTypes["Contember_GalleryItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	caption?: string | undefined,
	list?: GraphQLTypes["Contember_Gallery"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined
};
	["Contember_GalleryItemMeta"]: {
	__typename: "Contember_GalleryItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	caption?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GalleryItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	caption?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_GalleryOrderBy"] | undefined,
	medium?: GraphQLTypes["Contember_MediumOrderBy"] | undefined
};
	["Contember_GalleryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_GalleryItemConnection"]: {
	__typename: "Contember_GalleryItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_GalleryItemEdge"]>
};
	["Contember_GalleryItemEdge"]: {
	__typename: "Contember_GalleryItemEdge",
	node: GraphQLTypes["Contember_GalleryItem"]
};
	["Contember_SocialsAndApps"]: {
	__typename: "Contember_SocialsAndApps",
	_meta?: GraphQLTypes["Contember_SocialsAndAppsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined
};
	["Contember_SocialsAndAppsMeta"]: {
	__typename: "Contember_SocialsAndAppsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	instagram?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	appStore?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	googlePlay?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGrid"]: {
	__typename: "Contember_ProductGrid",
	_meta?: GraphQLTypes["Contember_ProductGridMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isCarousel?: boolean | undefined,
	categories: Array<GraphQLTypes["Contember_ProductGridCategory"]>,
	type?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	categoriesByItems?: GraphQLTypes["Contember_ProductGridCategory"] | undefined,
	categoriesByImages?: GraphQLTypes["Contember_ProductGridCategory"] | undefined,
	paginateCategories: GraphQLTypes["Contember_ProductGridCategoryConnection"]
};
	["Contember_ProductGridMeta"]: {
	__typename: "Contember_ProductGridMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isCarousel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	categories?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGridCategory"]: {
	__typename: "Contember_ProductGridCategory",
	_meta?: GraphQLTypes["Contember_ProductGridCategoryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	root?: GraphQLTypes["Contember_ProductGrid"] | undefined,
	items: Array<GraphQLTypes["Contember_ProductGridItem"]>,
	images?: GraphQLTypes["Contember_ImageList"] | undefined,
	paginateItems: GraphQLTypes["Contember_ProductGridItemConnection"]
};
	["Contember_ProductGridCategoryMeta"]: {
	__typename: "Contember_ProductGridCategoryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	images?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGridItem"]: {
	__typename: "Contember_ProductGridItem",
	_meta?: GraphQLTypes["Contember_ProductGridItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Product"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	category?: GraphQLTypes["Contember_ProductGridCategory"] | undefined,
	description?: string | undefined
};
	["Contember_ProductGridItemMeta"]: {
	__typename: "Contember_ProductGridItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	category?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGridItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	lead?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	medium?: GraphQLTypes["Contember_MediumOrderBy"] | undefined,
	link?: GraphQLTypes["Contember_LinkOrderBy"] | undefined,
	category?: GraphQLTypes["Contember_ProductGridCategoryOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductGridCategoryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_ProductGridOrderBy"] | undefined,
	images?: GraphQLTypes["Contember_ImageListOrderBy"] | undefined
};
	["Contember_ProductGridOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	isCarousel?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ImageListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ImageList"]: {
	__typename: "Contember_ImageList",
	_meta?: GraphQLTypes["Contember_ImageListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ImageListItem"]>,
	itemsByImage?: GraphQLTypes["Contember_ImageListItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_ImageListItemConnection"]
};
	["Contember_ImageListMeta"]: {
	__typename: "Contember_ImageListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ImageListItem"]: {
	__typename: "Contember_ImageListItem",
	_meta?: GraphQLTypes["Contember_ImageListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ImageList"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_ImageListItemMeta"]: {
	__typename: "Contember_ImageListItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ImageListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_ImageListOrderBy"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined
};
	["Contember_ImageListItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined
};
	["Contember_ImageListItemConnection"]: {
	__typename: "Contember_ImageListItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ImageListItemEdge"]>
};
	["Contember_ImageListItemEdge"]: {
	__typename: "Contember_ImageListItemEdge",
	node: GraphQLTypes["Contember_ImageListItem"]
};
	["Contember_ProductGridItemConnection"]: {
	__typename: "Contember_ProductGridItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductGridItemEdge"]>
};
	["Contember_ProductGridItemEdge"]: {
	__typename: "Contember_ProductGridItemEdge",
	node: GraphQLTypes["Contember_ProductGridItem"]
};
	["Contember_ProductGridCategoriesByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined
};
	["Contember_ProductGridItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductGridCategoriesByImagesUniqueWhere"]: {
		images?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined
};
	["Contember_ProductGridCategoryConnection"]: {
	__typename: "Contember_ProductGridCategoryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductGridCategoryEdge"]>
};
	["Contember_ProductGridCategoryEdge"]: {
	__typename: "Contember_ProductGridCategoryEdge",
	node: GraphQLTypes["Contember_ProductGridCategory"]
};
	["Contember_ProductBannerList"]: {
	__typename: "Contember_ProductBannerList",
	_meta?: GraphQLTypes["Contember_ProductBannerListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ProductBannerItem"]>,
	paginateItems: GraphQLTypes["Contember_ProductBannerItemConnection"]
};
	["Contember_ProductBannerListMeta"]: {
	__typename: "Contember_ProductBannerListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductBannerItem"]: {
	__typename: "Contember_ProductBannerItem",
	_meta?: GraphQLTypes["Contember_ProductBannerItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_ProductBannerList"] | undefined,
	banner?: GraphQLTypes["Contember_ProductBanner"] | undefined,
	size?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined
};
	["Contember_ProductBannerItemMeta"]: {
	__typename: "Contember_ProductBannerItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	banner?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	size?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductBanner"]: {
	__typename: "Contember_ProductBanner",
	_meta?: GraphQLTypes["Contember_ProductBannerMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Contember_Link"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_Image"] | undefined,
	productImage?: GraphQLTypes["Contember_Image"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductBannerMeta"]: {
	__typename: "Contember_ProductBannerMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	beforeTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	label?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	button?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productImage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductBannerItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_ProductBannerListOrderBy"] | undefined,
	banner?: GraphQLTypes["Contember_ProductBannerOrderBy"] | undefined,
	size?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductBannerListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductBannerOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	beforeTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	label?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	button?: GraphQLTypes["Contember_LinkOrderBy"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	productImage?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_ProductBannerItemConnection"]: {
	__typename: "Contember_ProductBannerItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductBannerItemEdge"]>
};
	["Contember_ProductBannerItemEdge"]: {
	__typename: "Contember_ProductBannerItemEdge",
	node: GraphQLTypes["Contember_ProductBannerItem"]
};
	["Contember_DeliveryRegions"]: {
	__typename: "Contember_DeliveryRegions",
	_meta?: GraphQLTypes["Contember_DeliveryRegionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_DeliveryRegionsItem"]>,
	itemsByImage?: GraphQLTypes["Contember_DeliveryRegionsItem"] | undefined,
	itemsByTextList?: GraphQLTypes["Contember_DeliveryRegionsItem"] | undefined,
	itemsByGallery?: GraphQLTypes["Contember_DeliveryRegionsItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_DeliveryRegionsItemConnection"]
};
	["Contember_DeliveryRegionsMeta"]: {
	__typename: "Contember_DeliveryRegionsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryRegionsItem"]: {
	__typename: "Contember_DeliveryRegionsItem",
	_meta?: GraphQLTypes["Contember_DeliveryRegionsItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	title?: string | undefined,
	offerLabel?: string | undefined,
	list?: GraphQLTypes["Contember_DeliveryRegions"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	textList?: GraphQLTypes["Contember_TextList"] | undefined,
	gallery?: GraphQLTypes["Contember_ImageList"] | undefined
};
	["Contember_DeliveryRegionsItemMeta"]: {
	__typename: "Contember_DeliveryRegionsItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offerLabel?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	textList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gallery?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TextList"]: {
	__typename: "Contember_TextList",
	_meta?: GraphQLTypes["Contember_TextListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_TextItem"]>,
	paginateItems: GraphQLTypes["Contember_TextItemConnection"]
};
	["Contember_TextListMeta"]: {
	__typename: "Contember_TextListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TextItem"]: {
	__typename: "Contember_TextItem",
	_meta?: GraphQLTypes["Contember_TextItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["Contember_TextList"] | undefined,
	title?: string | undefined
};
	["Contember_TextItemMeta"]: {
	__typename: "Contember_TextItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TextItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	text?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_TextListOrderBy"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TextListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TextItemConnection"]: {
	__typename: "Contember_TextItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TextItemEdge"]>
};
	["Contember_TextItemEdge"]: {
	__typename: "Contember_TextItemEdge",
	node: GraphQLTypes["Contember_TextItem"]
};
	["Contember_DeliveryRegionsItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	offerLabel?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_DeliveryRegionsOrderBy"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	textList?: GraphQLTypes["Contember_TextListOrderBy"] | undefined,
	gallery?: GraphQLTypes["Contember_ImageListOrderBy"] | undefined
};
	["Contember_DeliveryRegionsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_DeliveryRegionsItemsByImageUniqueWhere"]: {
		image?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined
};
	["Contember_DeliveryRegionsItemsByTextListUniqueWhere"]: {
		textList?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_DeliveryRegionsItemsByGalleryUniqueWhere"]: {
		gallery?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined
};
	["Contember_DeliveryRegionsItemConnection"]: {
	__typename: "Contember_DeliveryRegionsItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryRegionsItemEdge"]>
};
	["Contember_DeliveryRegionsItemEdge"]: {
	__typename: "Contember_DeliveryRegionsItemEdge",
	node: GraphQLTypes["Contember_DeliveryRegionsItem"]
};
	["Contember_GridTiles"]: {
	__typename: "Contember_GridTiles",
	_meta?: GraphQLTypes["Contember_GridTilesMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_GridTileItem"]>,
	itemsByLink?: GraphQLTypes["Contember_GridTileItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_GridTileItemConnection"]
};
	["Contember_GridTilesMeta"]: {
	__typename: "Contember_GridTilesMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GridTileItem"]: {
	__typename: "Contember_GridTileItem",
	_meta?: GraphQLTypes["Contember_GridTileItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	text?: string | undefined,
	list?: GraphQLTypes["Contember_GridTiles"] | undefined,
	medium?: GraphQLTypes["Contember_Medium"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined
};
	["Contember_GridTileItemMeta"]: {
	__typename: "Contember_GridTileItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	medium?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_GridTileItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	text?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_GridTilesOrderBy"] | undefined,
	medium?: GraphQLTypes["Contember_MediumOrderBy"] | undefined,
	link?: GraphQLTypes["Contember_LinkOrderBy"] | undefined
};
	["Contember_GridTilesOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_GridTilesItemsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined
};
	["Contember_LinkUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_GridTileItemConnection"]: {
	__typename: "Contember_GridTileItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_GridTileItemEdge"]>
};
	["Contember_GridTileItemEdge"]: {
	__typename: "Contember_GridTileItemEdge",
	node: GraphQLTypes["Contember_GridTileItem"]
};
	["Contember_ContentReferenceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	primaryText?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	secondaryText?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	jsonContent?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	block?: GraphQLTypes["Contember_ContentBlockOrderBy"] | undefined,
	colorPallete?: GraphQLTypes["Contember_ColorPalleteOrderBy"] | undefined,
	medium?: GraphQLTypes["Contember_MediumOrderBy"] | undefined,
	link?: GraphQLTypes["Contember_LinkOrderBy"] | undefined,
	blogPosts?: GraphQLTypes["Contember_BlogPostListOrderBy"] | undefined,
	links?: GraphQLTypes["Contember_LinkListOrderBy"] | undefined,
	products?: GraphQLTypes["Contember_ProductListOrderBy"] | undefined,
	contentSize?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	misc?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	hero?: GraphQLTypes["Contember_HeroOrderBy"] | undefined,
	gallery?: GraphQLTypes["Contember_GalleryOrderBy"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndAppsOrderBy"] | undefined,
	productGrid?: GraphQLTypes["Contember_ProductGridOrderBy"] | undefined,
	productBanners?: GraphQLTypes["Contember_ProductBannerListOrderBy"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegionsOrderBy"] | undefined,
	textList?: GraphQLTypes["Contember_TextListOrderBy"] | undefined,
	gridTiles?: GraphQLTypes["Contember_GridTilesOrderBy"] | undefined
};
	["Contember_ContentBlockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	json?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	content?: GraphQLTypes["Contember_ContentOrderBy"] | undefined
};
	["Contember_HeroOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	showLogo?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	middleContent?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	medium?: GraphQLTypes["Contember_MediumOrderBy"] | undefined
};
	["Contember_SocialsAndAppsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	instagram?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	appStore?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	googlePlay?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ContentBlockReferencesByProductsUniqueWhere"]: {
		products?: GraphQLTypes["Contember_ProductListUniqueWhere"] | undefined
};
	["Contember_ContentBlockReferencesByProductBannersUniqueWhere"]: {
		productBanners?: GraphQLTypes["Contember_ProductBannerListUniqueWhere"] | undefined
};
	["Contember_ContentBlockReferencesByDeliveryRegionsUniqueWhere"]: {
		deliveryRegions?: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined
};
	["Contember_ContentReferenceConnection"]: {
	__typename: "Contember_ContentReferenceConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ContentReferenceEdge"]>
};
	["Contember_ContentReferenceEdge"]: {
	__typename: "Contember_ContentReferenceEdge",
	node: GraphQLTypes["Contember_ContentReference"]
};
	["Contember_ContentBlocksByReferencesUniqueWhere"]: {
		references?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined
};
	["Contember_ContentBlockConnection"]: {
	__typename: "Contember_ContentBlockConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ContentBlockEdge"]>
};
	["Contember_ContentBlockEdge"]: {
	__typename: "Contember_ContentBlockEdge",
	node: GraphQLTypes["Contember_ContentBlock"]
};
	["Contember_TranslationCataloguesIdentifier"]: {
	__typename: "Contember_TranslationCataloguesIdentifier",
	_meta?: GraphQLTypes["Contember_TranslationCataloguesIdentifierMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	catalogue: Array<GraphQLTypes["Contember_TranslationCatalogue"]>,
	catalogueByDomain?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	catalogueByValues?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	paginateCatalogue: GraphQLTypes["Contember_TranslationCatalogueConnection"]
};
	["Contember_TranslationCataloguesIdentifierMeta"]: {
	__typename: "Contember_TranslationCataloguesIdentifierMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationCatalogue"]: {
	__typename: "Contember_TranslationCatalogue",
	_meta?: GraphQLTypes["Contember_TranslationCatalogueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	domain?: GraphQLTypes["Contember_TranslationDomain"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	values: Array<GraphQLTypes["Contember_TranslationValue"]>,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifier"] | undefined,
	valuesByKey?: GraphQLTypes["Contember_TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["Contember_TranslationValueConnection"]
};
	["Contember_TranslationCatalogueMeta"]: {
	__typename: "Contember_TranslationCatalogueMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	domain?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fallback?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	values?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationDomain"]: {
	__typename: "Contember_TranslationDomain",
	_meta?: GraphQLTypes["Contember_TranslationDomainMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	name: string,
	catalogues: Array<GraphQLTypes["Contember_TranslationCatalogue"]>,
	keys: Array<GraphQLTypes["Contember_TranslationKey"]>,
	cataloguesByIdentifier?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	cataloguesByValues?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	keysByIdentifier?: GraphQLTypes["Contember_TranslationKey"] | undefined,
	keysByValues?: GraphQLTypes["Contember_TranslationKey"] | undefined,
	paginateCatalogues: GraphQLTypes["Contember_TranslationCatalogueConnection"],
	paginateKeys: GraphQLTypes["Contember_TranslationKeyConnection"]
};
	["Contember_TranslationDomainMeta"]: {
	__typename: "Contember_TranslationDomainMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	catalogues?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	keys?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationCatalogueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomainOrderBy"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueOrderBy"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifierOrderBy"] | undefined
};
	["Contember_TranslationDomainOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	identifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_TranslationKey"]: {
	__typename: "Contember_TranslationKey",
	_meta?: GraphQLTypes["Contember_TranslationKeyMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	identifier: string,
	contentType: GraphQLTypes["Contember_TranslationContentType"],
	note?: string | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomain"] | undefined,
	values: Array<GraphQLTypes["Contember_TranslationValue"]>,
	valuesByCatalogue?: GraphQLTypes["Contember_TranslationValue"] | undefined,
	paginateValues: GraphQLTypes["Contember_TranslationValueConnection"]
};
	["Contember_TranslationKeyMeta"]: {
	__typename: "Contember_TranslationKeyMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	identifier?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contentType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	note?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	domain?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	values?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationValue"]: {
	__typename: "Contember_TranslationValue",
	_meta?: GraphQLTypes["Contember_TranslationValueMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	value: string,
	catalogue?: GraphQLTypes["Contember_TranslationCatalogue"] | undefined,
	key?: GraphQLTypes["Contember_TranslationKey"] | undefined
};
	["Contember_TranslationValueMeta"]: {
	__typename: "Contember_TranslationValueMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	value?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	catalogue?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	key?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_TranslationValueOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	value?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	catalogue?: GraphQLTypes["Contember_TranslationCatalogueOrderBy"] | undefined,
	key?: GraphQLTypes["Contember_TranslationKeyOrderBy"] | undefined
};
	["Contember_TranslationKeyOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	identifier?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	contentType?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	note?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationDomainOrderBy"] | undefined
};
	["Contember_TranslationKeyValuesByCatalogueUniqueWhere"]: {
		catalogue?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined
};
	["Contember_TranslationValueConnection"]: {
	__typename: "Contember_TranslationValueConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TranslationValueEdge"]>
};
	["Contember_TranslationValueEdge"]: {
	__typename: "Contember_TranslationValueEdge",
	node: GraphQLTypes["Contember_TranslationValue"]
};
	["Contember_TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
		identifier?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined
};
	["Contember_TranslationDomainCataloguesByValuesUniqueWhere"]: {
		values?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined
};
	["Contember_TranslationDomainKeysByIdentifierUniqueWhere"]: {
		identifier?: string | undefined
};
	["Contember_TranslationDomainKeysByValuesUniqueWhere"]: {
		values?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined
};
	["Contember_TranslationCatalogueConnection"]: {
	__typename: "Contember_TranslationCatalogueConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TranslationCatalogueEdge"]>
};
	["Contember_TranslationCatalogueEdge"]: {
	__typename: "Contember_TranslationCatalogueEdge",
	node: GraphQLTypes["Contember_TranslationCatalogue"]
};
	["Contember_TranslationKeyConnection"]: {
	__typename: "Contember_TranslationKeyConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_TranslationKeyEdge"]>
};
	["Contember_TranslationKeyEdge"]: {
	__typename: "Contember_TranslationKeyEdge",
	node: GraphQLTypes["Contember_TranslationKey"]
};
	["Contember_TranslationCatalogueValuesByKeyUniqueWhere"]: {
		key?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined
};
	["Contember_TranslationCataloguesIdentifierCatalogueByDomainUniqueWhere"]: {
		domain?: GraphQLTypes["Contember_TranslationDomainUniqueWhere"] | undefined
};
	["Contember_TranslationCataloguesIdentifierCatalogueByValuesUniqueWhere"]: {
		values?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined
};
	["Contember_MenuLocale"]: {
	__typename: "Contember_MenuLocale",
	_meta?: GraphQLTypes["Contember_MenuLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	root?: GraphQLTypes["Contember_SiteMenu"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	items: Array<GraphQLTypes["Contember_MenuItem"]>,
	secondaryItems?: GraphQLTypes["Contember_LinkList"] | undefined,
	eshopLink?: GraphQLTypes["Contember_Link"] | undefined,
	itemsBySubitems?: GraphQLTypes["Contember_MenuItem"] | undefined,
	paginateItems: GraphQLTypes["Contember_MenuItemConnection"]
};
	["Contember_MenuLocaleMeta"]: {
	__typename: "Contember_MenuLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryItems?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	eshopLink?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SiteMenu"]: {
	__typename: "Contember_SiteMenu",
	_meta?: GraphQLTypes["Contember_SiteMenuMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	locales: Array<GraphQLTypes["Contember_MenuLocale"]>,
	localesByLocale?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	localesByItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	localesBySecondaryItems?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_MenuLocaleConnection"]
};
	["Contember_SiteMenuMeta"]: {
	__typename: "Contember_SiteMenuMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_SiteMenuOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	secondaryItems?: GraphQLTypes["Contember_LinkListOrderBy"] | undefined,
	eshopLink?: GraphQLTypes["Contember_LinkOrderBy"] | undefined
};
	["Contember_SiteMenuOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unique?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_SiteMenuLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_SiteMenuLocalesByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined
};
	["Contember_SiteMenuLocalesBySecondaryItemsUniqueWhere"]: {
		secondaryItems?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined
};
	["Contember_MenuLocaleConnection"]: {
	__typename: "Contember_MenuLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuLocaleEdge"]>
};
	["Contember_MenuLocaleEdge"]: {
	__typename: "Contember_MenuLocaleEdge",
	node: GraphQLTypes["Contember_MenuLocale"]
};
	["Contember_MenuItem"]: {
	__typename: "Contember_MenuItem",
	_meta?: GraphQLTypes["Contember_MenuItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	menu?: GraphQLTypes["Contember_MenuLocale"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	subitems: Array<GraphQLTypes["Contember_MenuSubitem"]>,
	paginateSubitems: GraphQLTypes["Contember_MenuSubitemConnection"]
};
	["Contember_MenuItemMeta"]: {
	__typename: "Contember_MenuItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	menu?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	subitems?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuSubitem"]: {
	__typename: "Contember_MenuSubitem",
	_meta?: GraphQLTypes["Contember_MenuSubitemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	title?: string | undefined,
	item?: GraphQLTypes["Contember_MenuItem"] | undefined,
	links?: GraphQLTypes["Contember_MenuLinkList"] | undefined,
	products?: GraphQLTypes["Contember_MenuProducts"] | undefined,
	delivery?: GraphQLTypes["Contember_MenuDelivery"] | undefined,
	contact?: GraphQLTypes["Contember_MenuContact"] | undefined,
	order: number
};
	["Contember_MenuSubitemMeta"]: {
	__typename: "Contember_MenuSubitemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	item?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	links?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	delivery?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contact?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLinkList"]: {
	__typename: "Contember_MenuLinkList",
	_meta?: GraphQLTypes["Contember_MenuLinkListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_MenuLinkItem"]>,
	paginateItems: GraphQLTypes["Contember_MenuLinkItemConnection"]
};
	["Contember_MenuLinkListMeta"]: {
	__typename: "Contember_MenuLinkListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLinkItem"]: {
	__typename: "Contember_MenuLinkItem",
	_meta?: GraphQLTypes["Contember_MenuLinkItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	list?: GraphQLTypes["Contember_MenuLinkList"] | undefined,
	link?: GraphQLTypes["Contember_Link"] | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	description?: string | undefined
};
	["Contember_MenuLinkItemMeta"]: {
	__typename: "Contember_MenuLinkItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	link?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuLinkItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_MenuLinkListOrderBy"] | undefined,
	link?: GraphQLTypes["Contember_LinkOrderBy"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_MenuLinkListOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_MenuLinkItemConnection"]: {
	__typename: "Contember_MenuLinkItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuLinkItemEdge"]>
};
	["Contember_MenuLinkItemEdge"]: {
	__typename: "Contember_MenuLinkItemEdge",
	node: GraphQLTypes["Contember_MenuLinkItem"]
};
	["Contember_MenuProducts"]: {
	__typename: "Contember_MenuProducts",
	_meta?: GraphQLTypes["Contember_MenuProductsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	links?: GraphQLTypes["Contember_MenuLinkList"] | undefined,
	buttons?: GraphQLTypes["Contember_LinkList"] | undefined
};
	["Contember_MenuProductsMeta"]: {
	__typename: "Contember_MenuProductsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	links?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	buttons?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuDelivery"]: {
	__typename: "Contember_MenuDelivery",
	_meta?: GraphQLTypes["Contember_MenuDeliveryMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegions"] | undefined,
	text?: string | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndApps"] | undefined
};
	["Contember_MenuDeliveryMeta"]: {
	__typename: "Contember_MenuDeliveryMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuContact"]: {
	__typename: "Contember_MenuContact",
	_meta?: GraphQLTypes["Contember_MenuContactMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	text?: string | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined
};
	["Contember_MenuContactMeta"]: {
	__typename: "Contember_MenuContactMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	text?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_MenuSubitemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	item?: GraphQLTypes["Contember_MenuItemOrderBy"] | undefined,
	links?: GraphQLTypes["Contember_MenuLinkListOrderBy"] | undefined,
	products?: GraphQLTypes["Contember_MenuProductsOrderBy"] | undefined,
	delivery?: GraphQLTypes["Contember_MenuDeliveryOrderBy"] | undefined,
	contact?: GraphQLTypes["Contember_MenuContactOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_MenuItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	menu?: GraphQLTypes["Contember_MenuLocaleOrderBy"] | undefined,
	link?: GraphQLTypes["Contember_LinkOrderBy"] | undefined
};
	["Contember_MenuProductsOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	links?: GraphQLTypes["Contember_MenuLinkListOrderBy"] | undefined,
	buttons?: GraphQLTypes["Contember_LinkListOrderBy"] | undefined
};
	["Contember_MenuDeliveryOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegionsOrderBy"] | undefined,
	text?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_SocialsAndAppsOrderBy"] | undefined
};
	["Contember_MenuContactOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	text?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	image?: GraphQLTypes["Contember_ImageOrderBy"] | undefined
};
	["Contember_MenuSubitemConnection"]: {
	__typename: "Contember_MenuSubitemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuSubitemEdge"]>
};
	["Contember_MenuSubitemEdge"]: {
	__typename: "Contember_MenuSubitemEdge",
	node: GraphQLTypes["Contember_MenuSubitem"]
};
	["Contember_MenuLocaleItemsBySubitemsUniqueWhere"]: {
		subitems?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined
};
	["Contember_MenuItemConnection"]: {
	__typename: "Contember_MenuItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_MenuItemEdge"]>
};
	["Contember_MenuItemEdge"]: {
	__typename: "Contember_MenuItemEdge",
	node: GraphQLTypes["Contember_MenuItem"]
};
	["Contember_ProductPackingLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	shortName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_ProductPackingOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_ProductLocale"]: {
	__typename: "Contember_ProductLocale",
	_meta?: GraphQLTypes["Contember_ProductLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	root?: GraphQLTypes["Product"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	featureList?: GraphQLTypes["Contember_TextList"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined
};
	["Contember_ProductLocaleMeta"]: {
	__typename: "Contember_ProductLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	featureList?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	printableName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	firstSticker?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondSticker?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	featureList?: GraphQLTypes["Contember_TextListOrderBy"] | undefined,
	printableName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	firstSticker?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	secondSticker?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductRecipeLocale"]: {
	__typename: "Contember_ProductRecipeLocale",
	_meta?: GraphQLTypes["Contember_ProductRecipeLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	description?: string | undefined
};
	["Contember_ProductRecipeLocaleMeta"]: {
	__typename: "Contember_ProductRecipeLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductRecipe"]: {
	__typename: "Contember_ProductRecipe",
	_meta?: GraphQLTypes["Contember_ProductRecipeMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	products: Array<GraphQLTypes["Product"]>,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_Image"] | undefined,
	icon?: GraphQLTypes["Contember_Image"] | undefined,
	ingredients: Array<GraphQLTypes["Contember_ProductIngredientItem"]>,
	needsPrepping: boolean,
	locales: Array<GraphQLTypes["Contember_ProductRecipeLocale"]>,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_FreshingContainer"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_NutritionFacts"] | undefined,
	productsByCode?: GraphQLTypes["Product"] | undefined,
	productsByStocks?: GraphQLTypes["Product"] | undefined,
	productsByGroupPrices?: GraphQLTypes["Product"] | undefined,
	productsByCartItems?: GraphQLTypes["Product"] | undefined,
	productsByOrderItems?: GraphQLTypes["Product"] | undefined,
	productsByInheritedCategories?: GraphQLTypes["Product"] | undefined,
	productsByLocales?: GraphQLTypes["Product"] | undefined,
	productsByCustomerPrices?: GraphQLTypes["Product"] | undefined,
	productsByVirtualProduct?: GraphQLTypes["Product"] | undefined,
	productsByMeta?: GraphQLTypes["Product"] | undefined,
	productsByPage?: GraphQLTypes["Product"] | undefined,
	productsBySalesDays?: GraphQLTypes["Product"] | undefined,
	productsBySalesWeeks?: GraphQLTypes["Product"] | undefined,
	productsBySalesMonths?: GraphQLTypes["Product"] | undefined,
	ingredientsByQuantity?: GraphQLTypes["Contember_ProductIngredientItem"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_ProductRecipeLocale"] | undefined,
	paginateProducts: GraphQLTypes["ProductConnection"],
	paginateIngredients: GraphQLTypes["Contember_ProductIngredientItemConnection"],
	paginateLocales: GraphQLTypes["Contember_ProductRecipeLocaleConnection"]
};
	["Contember_ProductRecipeMeta"]: {
	__typename: "Contember_ProductRecipeMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	products?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	image?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredients?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	needsPrepping?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	gutTuning?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nutritionFacts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientItem"]: {
	__typename: "Contember_ProductIngredientItem",
	_meta?: GraphQLTypes["Contember_ProductIngredientItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	internalOrder: number,
	recipe?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredient"] | undefined,
	quantity?: GraphQLTypes["Contember_ProductIngredientItemQuantity"] | undefined
};
	["Contember_ProductIngredientItemMeta"]: {
	__typename: "Contember_ProductIngredientItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipe?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ingredient?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientItemQuantity"]: {
	__typename: "Contember_ProductIngredientItemQuantity",
	_meta?: GraphQLTypes["Contember_ProductIngredientItemQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnit"] | undefined,
	value: number
};
	["Contember_ProductIngredientItemQuantityMeta"]: {
	__typename: "Contember_ProductIngredientItemQuantityMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unit?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	value?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductIngredientItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalOrder?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipeOrderBy"] | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredientOrderBy"] | undefined,
	quantity?: GraphQLTypes["Contember_ProductIngredientItemQuantityOrderBy"] | undefined
};
	["Contember_ProductIngredientItemQuantityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitOrderBy"] | undefined,
	value?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductRecipeLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_ProductRecipeOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_NutritionFacts"]: {
	__typename: "Contember_NutritionFacts",
	_meta?: GraphQLTypes["Contember_NutritionFactsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	energyKiloJoule?: number | undefined,
	energyKiloCalorie?: number | undefined,
	fatsGrams?: number | undefined,
	fatsOfWhichSaturatedGrams?: number | undefined,
	carbohydrateGrams?: number | undefined,
	carbohydrateOfWhichSugarsGrams?: number | undefined,
	proteinGrams?: number | undefined,
	fibreGrams?: number | undefined,
	saltGrams?: number | undefined
};
	["Contember_NutritionFactsMeta"]: {
	__typename: "Contember_NutritionFactsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	energyKiloJoule?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	energyKiloCalorie?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fatsGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fatsOfWhichSaturatedGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carbohydrateGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	carbohydrateOfWhichSugarsGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	proteinGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fibreGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	saltGrams?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductRecipeProductsByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["Contember_ProductRecipeProductsByStocksUniqueWhere"]: {
		stocks?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByGroupPricesUniqueWhere"]: {
		groupPrices?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByCartItemsUniqueWhere"]: {
		cartItems?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByOrderItemsUniqueWhere"]: {
		orderItems?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByInheritedCategoriesUniqueWhere"]: {
		inheritedCategories?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByCustomerPricesUniqueWhere"]: {
		customerPrices?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByVirtualProductUniqueWhere"]: {
		virtualProduct?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_ProductMetadataUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsByPageUniqueWhere"]: {
		page?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsBySalesDaysUniqueWhere"]: {
		salesDays?: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsBySalesWeeksUniqueWhere"]: {
		salesWeeks?: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined
};
	["Contember_ProductRecipeProductsBySalesMonthsUniqueWhere"]: {
		salesMonths?: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined
};
	["Contember_ProductRecipeIngredientsByQuantityUniqueWhere"]: {
		quantity?: GraphQLTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined
};
	["Contember_ProductRecipeLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_ProductIngredientItemConnection"]: {
	__typename: "Contember_ProductIngredientItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductIngredientItemEdge"]>
};
	["Contember_ProductIngredientItemEdge"]: {
	__typename: "Contember_ProductIngredientItemEdge",
	node: GraphQLTypes["Contember_ProductIngredientItem"]
};
	["Contember_ProductRecipeLocaleConnection"]: {
	__typename: "Contember_ProductRecipeLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductRecipeLocaleEdge"]>
};
	["Contember_ProductRecipeLocaleEdge"]: {
	__typename: "Contember_ProductRecipeLocaleEdge",
	node: GraphQLTypes["Contember_ProductRecipeLocale"]
};
	["Contember_DeliveryMethodLocale"]: {
	__typename: "Contember_DeliveryMethodLocale",
	_meta?: GraphQLTypes["Contember_DeliveryMethodLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	root?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["Contember_DeliveryMethodLocaleMeta"]: {
	__typename: "Contember_DeliveryMethodLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	processingIconUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchedTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchedText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryMethodLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	trackingCodeUrlTemplate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	processingTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	processingText?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	processingIconUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	dispatchedTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	dispatchedText?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	dispatchedIconUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fulfilledTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fulfilledText?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fulfilledIconUrl?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	root?: GraphQLTypes["Contember_DeliveryMethodOrderBy"] | undefined
};
	["Contember_PipelineStageLocale"]: {
	__typename: "Contember_PipelineStageLocale",
	_meta?: GraphQLTypes["Contember_PipelineStageLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	root?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined
};
	["Contember_PipelineStageLocaleMeta"]: {
	__typename: "Contember_PipelineStageLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStage"]: {
	__typename: "Contember_PipelineStage",
	_meta?: GraphQLTypes["Contember_PipelineStageMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_PipelineStageItem"]>,
	pipeline?: GraphQLTypes["Contember_Pipeline"] | undefined,
	order: number,
	locales: Array<GraphQLTypes["Contember_PipelineStageLocale"]>,
	sentiment?: GraphQLTypes["Contember_PipelineStageSentiment"] | undefined,
	type: GraphQLTypes["Contember_PipelineStageType"],
	forms: Array<GraphQLTypes["Contember_LeadForm"]>,
	showItemsWeight: boolean,
	showItemsPrice: boolean,
	itemsByLead?: GraphQLTypes["Contember_PipelineStageItem"] | undefined,
	localesByLocale?: GraphQLTypes["Contember_PipelineStageLocale"] | undefined,
	formsByCode?: GraphQLTypes["Contember_LeadForm"] | undefined,
	formsByLeads?: GraphQLTypes["Contember_LeadForm"] | undefined,
	paginateItems: GraphQLTypes["Contember_PipelineStageItemConnection"],
	paginateLocales: GraphQLTypes["Contember_PipelineStageLocaleConnection"],
	paginateForms: GraphQLTypes["Contember_LeadFormConnection"]
};
	["Contember_PipelineStageMeta"]: {
	__typename: "Contember_PipelineStageMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	pipeline?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sentiment?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	forms?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showItemsWeight?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	showItemsPrice?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStageItem"]: {
	__typename: "Contember_PipelineStageItem",
	_meta?: GraphQLTypes["Contember_PipelineStageItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	list?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineLead"] | undefined,
	order: number
};
	["Contember_PipelineStageItemMeta"]: {
	__typename: "Contember_PipelineStageItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	lead?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineLead"]: {
	__typename: "Contember_PipelineLead",
	_meta?: GraphQLTypes["Contember_PipelineLeadMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	stageItem?: GraphQLTypes["Contember_PipelineStageItem"] | undefined,
	contactPerson?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine: boolean,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	form?: GraphQLTypes["Contember_LeadForm"] | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined
};
	["Contember_PipelineLeadMeta"]: {
	__typename: "Contember_PipelineLeadMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	createdAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stageItem?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	contactPerson?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	cafeType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offerType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ownMachine?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerCity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	companyId?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	email?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	phone?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	form?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	meta?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	multiroasterType?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_LeadForm"]: {
	__typename: "Contember_LeadForm",
	_meta?: GraphQLTypes["Contember_LeadFormMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	code: string,
	stage?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	leads: Array<GraphQLTypes["Contember_PipelineLead"]>,
	leadsByStageItem?: GraphQLTypes["Contember_PipelineLead"] | undefined,
	paginateLeads: GraphQLTypes["Contember_PipelineLeadConnection"]
};
	["Contember_LeadFormMeta"]: {
	__typename: "Contember_LeadFormMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stage?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	leads?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineLeadOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	createdAt?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	stageItem?: GraphQLTypes["Contember_PipelineStageItemOrderBy"] | undefined,
	contactPerson?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	cafeType?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	offerType?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	ownMachine?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customerCity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	companyId?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customerName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	email?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	phone?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	form?: GraphQLTypes["Contember_LeadFormOrderBy"] | undefined,
	meta?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	multiroasterType?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PipelineStageItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	list?: GraphQLTypes["Contember_PipelineStageOrderBy"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineLeadOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PipelineStageOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	pipeline?: GraphQLTypes["Contember_PipelineOrderBy"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageSentimentOrderBy"] | undefined,
	type?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	showItemsWeight?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	showItemsPrice?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PipelineOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unique?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_PipelineStageSentimentOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	backgroundColor?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	textColor?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_LeadFormOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	stage?: GraphQLTypes["Contember_PipelineStageOrderBy"] | undefined
};
	["Contember_LeadFormLeadsByStageItemUniqueWhere"]: {
		stageItem?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined
};
	["Contember_PipelineLeadConnection"]: {
	__typename: "Contember_PipelineLeadConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineLeadEdge"]>
};
	["Contember_PipelineLeadEdge"]: {
	__typename: "Contember_PipelineLeadEdge",
	node: GraphQLTypes["Contember_PipelineLead"]
};
	["Contember_Pipeline"]: {
	__typename: "Contember_Pipeline",
	_meta?: GraphQLTypes["Contember_PipelineMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	unique: GraphQLTypes["Contember_One"],
	stages: Array<GraphQLTypes["Contember_PipelineStage"]>,
	stagesByItems?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	stagesByLocales?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	stagesByForms?: GraphQLTypes["Contember_PipelineStage"] | undefined,
	paginateStages: GraphQLTypes["Contember_PipelineStageConnection"]
};
	["Contember_PipelineMeta"]: {
	__typename: "Contember_PipelineMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stages?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStagesByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined
};
	["Contember_PipelineStagesByLocalesUniqueWhere"]: {
		locales?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined
};
	["Contember_PipelineStagesByFormsUniqueWhere"]: {
		forms?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined
};
	["Contember_PipelineStageConnection"]: {
	__typename: "Contember_PipelineStageConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineStageEdge"]>
};
	["Contember_PipelineStageEdge"]: {
	__typename: "Contember_PipelineStageEdge",
	node: GraphQLTypes["Contember_PipelineStage"]
};
	["Contember_PipelineStageLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_PipelineStageOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined
};
	["Contember_PipelineStageSentiment"]: {
	__typename: "Contember_PipelineStageSentiment",
	_meta?: GraphQLTypes["Contember_PipelineStageSentimentMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	internalName: string,
	backgroundColor?: string | undefined,
	textColor?: string | undefined
};
	["Contember_PipelineStageSentimentMeta"]: {
	__typename: "Contember_PipelineStageSentimentMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	backgroundColor?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	textColor?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PipelineStageItemsByLeadUniqueWhere"]: {
		lead?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined
};
	["Contember_PipelineStageLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_PipelineStageFormsByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["Contember_PipelineStageFormsByLeadsUniqueWhere"]: {
		leads?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined
};
	["Contember_PipelineStageItemConnection"]: {
	__typename: "Contember_PipelineStageItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineStageItemEdge"]>
};
	["Contember_PipelineStageItemEdge"]: {
	__typename: "Contember_PipelineStageItemEdge",
	node: GraphQLTypes["Contember_PipelineStageItem"]
};
	["Contember_PipelineStageLocaleConnection"]: {
	__typename: "Contember_PipelineStageLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PipelineStageLocaleEdge"]>
};
	["Contember_PipelineStageLocaleEdge"]: {
	__typename: "Contember_PipelineStageLocaleEdge",
	node: GraphQLTypes["Contember_PipelineStageLocale"]
};
	["Contember_LeadFormConnection"]: {
	__typename: "Contember_LeadFormConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_LeadFormEdge"]>
};
	["Contember_LeadFormEdge"]: {
	__typename: "Contember_LeadFormEdge",
	node: GraphQLTypes["Contember_LeadForm"]
};
	["Contember_SubscriptionBoxLocale"]: {
	__typename: "Contember_SubscriptionBoxLocale",
	_meta?: GraphQLTypes["Contember_SubscriptionBoxLocaleMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["Contember_SubscriptionBox"] | undefined,
	locale?: GraphQLTypes["Contember_Locale"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["Contember_TextList"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_TextList"] | undefined
};
	["Contember_SubscriptionBoxLocaleMeta"]: {
	__typename: "Contember_SubscriptionBoxLocaleMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	title?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	stickerText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	descriptionText?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	root?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locale?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	texts?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SubscriptionBox"]: {
	__typename: "Contember_SubscriptionBox",
	_meta?: GraphQLTypes["Contember_SubscriptionBoxMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	locales: Array<GraphQLTypes["Contember_SubscriptionBoxLocale"]>,
	unique: GraphQLTypes["Contember_One"],
	localesByLocale?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	localesByTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	localesBySecondaryTexts?: GraphQLTypes["Contember_SubscriptionBoxLocale"] | undefined,
	paginateLocales: GraphQLTypes["Contember_SubscriptionBoxLocaleConnection"]
};
	["Contember_SubscriptionBoxMeta"]: {
	__typename: "Contember_SubscriptionBoxMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	locales?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	unique?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_SubscriptionBoxLocaleOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	title?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	stickerText?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	descriptionText?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	root?: GraphQLTypes["Contember_SubscriptionBoxOrderBy"] | undefined,
	locale?: GraphQLTypes["Contember_LocaleOrderBy"] | undefined,
	secondaryTitle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	texts?: GraphQLTypes["Contember_TextListOrderBy"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_TextListOrderBy"] | undefined
};
	["Contember_SubscriptionBoxOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	unique?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_SubscriptionBoxLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_SubscriptionBoxLocalesByTextsUniqueWhere"]: {
		texts?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_SubscriptionBoxLocalesBySecondaryTextsUniqueWhere"]: {
		secondaryTexts?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_SubscriptionBoxLocaleConnection"]: {
	__typename: "Contember_SubscriptionBoxLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_SubscriptionBoxLocaleEdge"]>
};
	["Contember_SubscriptionBoxLocaleEdge"]: {
	__typename: "Contember_SubscriptionBoxLocaleEdge",
	node: GraphQLTypes["Contember_SubscriptionBoxLocale"]
};
	["Contember_LocaleBusinessCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined
};
	["Contember_LocaleProductCategoryListsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined
};
	["Contember_LocaleProductIngredientsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined
};
	["Contember_LocaleOrderRecurrencesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined
};
	["Contember_LocaleBlogsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_BlogUniqueWhere"] | undefined
};
	["Contember_LocaleBlogPostsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_BlogPostUniqueWhere"] | undefined
};
	["Contember_LocaleBlogPostsByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined
};
	["Contember_LocaleBlogPostsByContentUniqueWhere"]: {
		content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined
};
	["Contember_LocaleBlogPostsByLinkUniqueWhere"]: {
		link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined
};
	["Contember_LocaleBlogPostsBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined
};
	["Contember_LocaleMenusByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_SiteMenuUniqueWhere"] | undefined
};
	["Contember_LocaleMenusByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined
};
	["Contember_LocaleMenusBySecondaryItemsUniqueWhere"]: {
		secondaryItems?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined
};
	["Contember_LocalePagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined
};
	["Contember_LocalePagesByLinkUniqueWhere"]: {
		link?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined
};
	["Contember_LocalePagesBySeoUniqueWhere"]: {
		seo?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined
};
	["Contember_LocalePagesByContentUniqueWhere"]: {
		content?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined
};
	["Contember_LocalePagesByCoverUniqueWhere"]: {
		cover?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined
};
	["Contember_LocaleProductIngredientCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined
};
	["Contember_LocaleAllergensByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined
};
	["Contember_LocaleProductPackingsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_ProductPackingUniqueWhere"] | undefined
};
	["Contember_LocaleProductsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_LocaleProductsByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_LocaleProductRecipesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined
};
	["Contember_LocalePointOfSaleCategoriesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined
};
	["Contember_LocalePointOfSaleCategoryProductsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined
};
	["Contember_LocaleDeliveryMethodsByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined
};
	["Contember_LocaleIngredientSuppliersByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_IngredientSupplierUniqueWhere"] | undefined
};
	["Contember_LocaleIdentificationCodesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined
};
	["Contember_LocalePipelineStagesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined
};
	["Contember_LocaleSubscriptionBoxesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined
};
	["Contember_LocaleSubscriptionBoxesByTextsUniqueWhere"]: {
		texts?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_LocaleSubscriptionBoxesBySecondaryTextsUniqueWhere"]: {
		secondaryTexts?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_LocaleBusinessServicesByRootUniqueWhere"]: {
		root?: GraphQLTypes["Contember_BusinessServiceUniqueWhere"] | undefined
};
	["Contember_ProductPackingLocaleConnection"]: {
	__typename: "Contember_ProductPackingLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductPackingLocaleEdge"]>
};
	["Contember_ProductPackingLocaleEdge"]: {
	__typename: "Contember_ProductPackingLocaleEdge",
	node: GraphQLTypes["Contember_ProductPackingLocale"]
};
	["Contember_ProductLocaleConnection"]: {
	__typename: "Contember_ProductLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductLocaleEdge"]>
};
	["Contember_ProductLocaleEdge"]: {
	__typename: "Contember_ProductLocaleEdge",
	node: GraphQLTypes["Contember_ProductLocale"]
};
	["Contember_DeliveryMethodLocaleConnection"]: {
	__typename: "Contember_DeliveryMethodLocaleConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryMethodLocaleEdge"]>
};
	["Contember_DeliveryMethodLocaleEdge"]: {
	__typename: "Contember_DeliveryMethodLocaleEdge",
	node: GraphQLTypes["Contember_DeliveryMethodLocale"]
};
	["Contember_PreparedPackingQuantity"]: {
	__typename: "Contember_PreparedPackingQuantity",
	_meta?: GraphQLTypes["Contember_PreparedPackingQuantityMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	date: GraphQLTypes["Date"],
	quantity?: number | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipe"] | undefined,
	packing?: GraphQLTypes["Contember_ProductPacking"] | undefined,
	packedInCardboard: boolean
};
	["Contember_PreparedPackingQuantityMeta"]: {
	__typename: "Contember_PreparedPackingQuantityMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	date?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	recipe?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	packing?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	packedInCardboard?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_PreparedPackingQuantityOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	date?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	quantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductRecipeOrderBy"] | undefined,
	packing?: GraphQLTypes["Contember_ProductPackingOrderBy"] | undefined,
	packedInCardboard?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductPackingLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_PreparedPackingQuantityConnection"]: {
	__typename: "Contember_PreparedPackingQuantityConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_PreparedPackingQuantityEdge"]>
};
	["Contember_PreparedPackingQuantityEdge"]: {
	__typename: "Contember_PreparedPackingQuantityEdge",
	node: GraphQLTypes["Contember_PreparedPackingQuantity"]
};
	["Contember_ProductStock"]: {
	__typename: "Contember_ProductStock",
	_meta?: GraphQLTypes["Contember_ProductStockMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	quantity: number,
	product?: GraphQLTypes["Product"] | undefined,
	store?: GraphQLTypes["Contember_Store"] | undefined
};
	["Contember_ProductStockMeta"]: {
	__typename: "Contember_ProductStockMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	quantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	store?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Store"]: {
	__typename: "Contember_Store",
	_meta?: GraphQLTypes["Contember_StoreMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	code: string,
	description?: string | undefined,
	internalNote?: string | undefined
};
	["Contember_StoreMeta"]: {
	__typename: "Contember_StoreMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	description?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	internalNote?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductStockOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	quantity?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined,
	store?: GraphQLTypes["Contember_StoreOrderBy"] | undefined
};
	["Contember_StoreOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	code?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	description?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	internalNote?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ProductGroupPrice"]: {
	__typename: "Contember_ProductGroupPrice",
	_meta?: GraphQLTypes["Contember_ProductGroupPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	priceCents: number,
	group?: GraphQLTypes["Contember_CustomerGroup"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductGroupPriceMeta"]: {
	__typename: "Contember_ProductGroupPriceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	group?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerGroup"]: {
	__typename: "Contember_CustomerGroup",
	_meta?: GraphQLTypes["Contember_CustomerGroupMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name: string,
	productPrices: Array<GraphQLTypes["Contember_ProductGroupPrice"]>,
	customers: Array<GraphQLTypes["Contember_Customer"]>,
	discountPermille?: number | undefined,
	code?: string | undefined,
	doubleshotLegacyId?: string | undefined,
	productPricesByProduct?: GraphQLTypes["Contember_ProductGroupPrice"] | undefined,
	customersByDoubleshotLegacyId?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByAddresses?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultBillingAddress?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultPaymentMethod?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByCredits?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByPaymentMethods?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByOrders?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByPermissions?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByName?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByIngredientRatings?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByBusiness?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByCarts?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByShortcutList?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByUser?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByInvoices?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByInvoicingPlan?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByBillingSubjects?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByOfferedProducts?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultDeliveryAddress?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByDefaultBillingSubject?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByAnonymousSession?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByBoughtVouchers?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByOwnsVouchers?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByRedeemedVouchers?: GraphQLTypes["Contember_Customer"] | undefined,
	customersBySpending?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByCustomerTabs?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByLead?: GraphQLTypes["Contember_Customer"] | undefined,
	customersByMeta?: GraphQLTypes["Contember_Customer"] | undefined,
	paginateProductPrices: GraphQLTypes["Contember_ProductGroupPriceConnection"],
	paginateCustomers: GraphQLTypes["Contember_CustomerConnection"]
};
	["Contember_CustomerGroupMeta"]: {
	__typename: "Contember_CustomerGroupMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productPrices?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customers?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountPermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	code?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	doubleshotLegacyId?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductGroupPriceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	group?: GraphQLTypes["Contember_CustomerGroupOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_CustomerGroupProductPricesByProductUniqueWhere"]: {
		product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["Contember_CustomerGroupCustomersByAddressesUniqueWhere"]: {
		addresses?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByDefaultBillingAddressUniqueWhere"]: {
		defaultBillingAddress?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByDefaultPaymentMethodUniqueWhere"]: {
		defaultPaymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByCreditsUniqueWhere"]: {
		credits?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByPaymentMethodsUniqueWhere"]: {
		paymentMethods?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByOrdersUniqueWhere"]: {
		orders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByPermissionsUniqueWhere"]: {
		permissions?: GraphQLTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByNameUniqueWhere"]: {
		name?: GraphQLTypes["Contember_CustomerNameUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByIngredientRatingsUniqueWhere"]: {
		ingredientRatings?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByBusinessUniqueWhere"]: {
		business?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByCartsUniqueWhere"]: {
		carts?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByShortcutListUniqueWhere"]: {
		shortcutList?: GraphQLTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByUserUniqueWhere"]: {
		user?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByInvoicingPlanUniqueWhere"]: {
		invoicingPlan?: GraphQLTypes["Contember_InvoicingPlanUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByBillingSubjectsUniqueWhere"]: {
		billingSubjects?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByOfferedProductsUniqueWhere"]: {
		offeredProducts?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByDefaultDeliveryAddressUniqueWhere"]: {
		defaultDeliveryAddress?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByDefaultBillingSubjectUniqueWhere"]: {
		defaultBillingSubject?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByAnonymousSessionUniqueWhere"]: {
		anonymousSession?: GraphQLTypes["Contember_AnonymousSessionUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByBoughtVouchersUniqueWhere"]: {
		boughtVouchers?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByOwnsVouchersUniqueWhere"]: {
		ownsVouchers?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByRedeemedVouchersUniqueWhere"]: {
		redeemedVouchers?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersBySpendingUniqueWhere"]: {
		spending?: GraphQLTypes["Contember_CustomerSpendingUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByCustomerTabsUniqueWhere"]: {
		customerTabs?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByLeadUniqueWhere"]: {
		lead?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined
};
	["Contember_CustomerGroupCustomersByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_CustomerMetadataUniqueWhere"] | undefined
};
	["Contember_ProductGroupPriceConnection"]: {
	__typename: "Contember_ProductGroupPriceConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductGroupPriceEdge"]>
};
	["Contember_ProductGroupPriceEdge"]: {
	__typename: "Contember_ProductGroupPriceEdge",
	node: GraphQLTypes["Contember_ProductGroupPrice"]
};
	["Contember_ProductCustomerPrice"]: {
	__typename: "Contember_ProductCustomerPrice",
	_meta?: GraphQLTypes["Contember_ProductCustomerPriceMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	groupDiscountPermille?: number | undefined,
	groupPriceCents?: number | undefined,
	fullPriceCents: number,
	discountedPriceCents?: number | undefined,
	priceCents: number,
	offered: boolean,
	notDiscountable: boolean,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductCustomerPriceMeta"]: {
	__typename: "Contember_ProductCustomerPriceMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	groupDiscountPermille?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	groupPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fullPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	discountedPriceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	offered?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductCustomerPriceOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	groupDiscountPermille?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	groupPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	fullPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	discountedPriceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCents?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	offered?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	notDiscountable?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerOrderBy"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_ProductMetadata"]: {
	__typename: "Contember_ProductMetadata",
	_meta?: GraphQLTypes["Contember_ProductMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	archived: boolean,
	available: boolean,
	availableQuantity: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductMetadataMeta"]: {
	__typename: "Contember_ProductMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	archived?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	available?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	availableQuantity?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesDay"]: {
	__typename: "Contember_ProductSalesDay",
	_meta?: GraphQLTypes["Contember_ProductSalesDayMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	productName: string,
	orderDate?: GraphQLTypes["Date"] | undefined,
	priceCentsSum: number,
	sum: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductSalesDayMeta"]: {
	__typename: "Contember_ProductSalesDayMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesDayOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	productName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderDate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	sum?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_ProductSalesWeek"]: {
	__typename: "Contember_ProductSalesWeek",
	_meta?: GraphQLTypes["Contember_ProductSalesWeekMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	productName: string,
	orderDate?: GraphQLTypes["Date"] | undefined,
	priceCentsSum: number,
	sum: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductSalesWeekMeta"]: {
	__typename: "Contember_ProductSalesWeekMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesWeekOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	productName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderDate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	sum?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_ProductSalesMonth"]: {
	__typename: "Contember_ProductSalesMonth",
	_meta?: GraphQLTypes["Contember_ProductSalesMonthMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	productName: string,
	orderDate?: GraphQLTypes["Date"] | undefined,
	priceCentsSum: number,
	sum: number,
	product?: GraphQLTypes["Product"] | undefined
};
	["Contember_ProductSalesMonthMeta"]: {
	__typename: "Contember_ProductSalesMonthMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	productName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	orderDate?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	sum?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	product?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ProductSalesMonthOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	productName?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	orderDate?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	priceCentsSum?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	sum?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	product?: GraphQLTypes["Contember_ProductOrderBy"] | undefined
};
	["Contember_ProductStocksByStoreUniqueWhere"]: {
		store?: GraphQLTypes["Contember_StoreUniqueWhere"] | undefined
};
	["Contember_ProductGroupPricesByGroupUniqueWhere"]: {
		group?: GraphQLTypes["Contember_CustomerGroupUniqueWhere"] | undefined
};
	["Contember_ProductCartItemsByCartUniqueWhere"]: {
		cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_ProductOrderItemsByVirtualProductEffectsUniqueWhere"]: {
		virtualProductEffects?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined
};
	["Contember_ProductLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["Contember_ProductLocalesByFeatureListUniqueWhere"]: {
		featureList?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined
};
	["Contember_ProductCustomerPricesByCustomerUniqueWhere"]: {
		customer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_ProductStockConnection"]: {
	__typename: "Contember_ProductStockConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductStockEdge"]>
};
	["Contember_ProductStockEdge"]: {
	__typename: "Contember_ProductStockEdge",
	node: GraphQLTypes["Contember_ProductStock"]
};
	["Contember_DeliveryZoneConnection"]: {
	__typename: "Contember_DeliveryZoneConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_DeliveryZoneEdge"]>
};
	["Contember_DeliveryZoneEdge"]: {
	__typename: "Contember_DeliveryZoneEdge",
	node: GraphQLTypes["Contember_DeliveryZone"]
};
	["Contember_ProductCustomerPriceConnection"]: {
	__typename: "Contember_ProductCustomerPriceConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductCustomerPriceEdge"]>
};
	["Contember_ProductCustomerPriceEdge"]: {
	__typename: "Contember_ProductCustomerPriceEdge",
	node: GraphQLTypes["Contember_ProductCustomerPrice"]
};
	["Contember_ProductSalesDayConnection"]: {
	__typename: "Contember_ProductSalesDayConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductSalesDayEdge"]>
};
	["Contember_ProductSalesDayEdge"]: {
	__typename: "Contember_ProductSalesDayEdge",
	node: GraphQLTypes["Contember_ProductSalesDay"]
};
	["Contember_ProductSalesWeekConnection"]: {
	__typename: "Contember_ProductSalesWeekConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductSalesWeekEdge"]>
};
	["Contember_ProductSalesWeekEdge"]: {
	__typename: "Contember_ProductSalesWeekEdge",
	node: GraphQLTypes["Contember_ProductSalesWeek"]
};
	["Contember_ProductSalesMonthConnection"]: {
	__typename: "Contember_ProductSalesMonthConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ProductSalesMonthEdge"]>
};
	["Contember_ProductSalesMonthEdge"]: {
	__typename: "Contember_ProductSalesMonthEdge",
	node: GraphQLTypes["Contember_ProductSalesMonth"]
};
	["Contember_AddressMetadata"]: {
	__typename: "Contember_AddressMetadata",
	_meta?: GraphQLTypes["Contember_AddressMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	deliveryZoneType?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	address?: GraphQLTypes["Address"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_DeliveryZone"] | undefined
};
	["Contember_AddressMetadataMeta"]: {
	__typename: "Contember_AddressMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZoneType?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryZone?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryZoneAddressesByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneAddressesByGpsUniqueWhere"]: {
		gps?: GraphQLTypes["Contember_GpsUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneAddressesByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneAddressesByBillingAddressOfOrdersUniqueWhere"]: {
		billingAddressOfOrders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
		deliveryAddressOfOrderDeliveries?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
		defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneAddressesMetadataByAddressUniqueWhere"]: {
		address?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined
};
	["AddressConnection"]: {
	__typename: "AddressConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["AddressEdge"]>
};
	["AddressEdge"]: {
	__typename: "AddressEdge",
	node: GraphQLTypes["Address"]
};
	["Contember_AddressMetadataConnection"]: {
	__typename: "Contember_AddressMetadataConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_AddressMetadataEdge"]>
};
	["Contember_AddressMetadataEdge"]: {
	__typename: "Contember_AddressMetadataEdge",
	node: GraphQLTypes["Contember_AddressMetadata"]
};
	["Contember_DeliveryTimelinePreset"]: {
	__typename: "Contember_DeliveryTimelinePreset",
	_meta?: GraphQLTypes["Contember_DeliveryTimelinePresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type: GraphQLTypes["Contember_DeliveryTimelineType"],
	duration?: string | undefined,
	window?: string | undefined,
	deliveryMethod?: GraphQLTypes["DeliveryMethod"] | undefined
};
	["Contember_DeliveryTimelinePresetMeta"]: {
	__typename: "Contember_DeliveryTimelinePresetMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	duration?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	window?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	deliveryMethod?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_DeliveryMethodLocalesByLocaleUniqueWhere"]: {
		locale?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined
};
	["PaymentMethodConnection"]: {
	__typename: "PaymentMethodConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["PaymentMethodEdge"]>
};
	["PaymentMethodEdge"]: {
	__typename: "PaymentMethodEdge",
	node: GraphQLTypes["PaymentMethod"]
};
	["Contember_CourierConnection"]: {
	__typename: "Contember_CourierConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CourierEdge"]>
};
	["Contember_CourierEdge"]: {
	__typename: "Contember_CourierEdge",
	node: GraphQLTypes["Contember_Courier"]
};
	["Contember_CustomerPermissions"]: {
	__typename: "Contember_CustomerPermissions",
	_meta?: GraphQLTypes["Contember_CustomerPermissionsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	canPlaceOrder?: boolean | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_CustomerPermissionsMeta"]: {
	__typename: "Contember_CustomerPermissionsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	canPlaceOrder?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerName"]: {
	__typename: "Contember_CustomerName",
	_meta?: GraphQLTypes["Contember_CustomerNameMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	nominative: string,
	vocative?: string | undefined,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	aliases: Array<GraphQLTypes["Contember_CustomerAlias"]>,
	paginateAliases: GraphQLTypes["Contember_CustomerAliasConnection"]
};
	["Contember_CustomerNameMeta"]: {
	__typename: "Contember_CustomerNameMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	nominative?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	vocative?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	aliases?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerAlias"]: {
	__typename: "Contember_CustomerAlias",
	_meta?: GraphQLTypes["Contember_CustomerAliasMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	name?: string | undefined,
	order: number
};
	["Contember_CustomerAliasMeta"]: {
	__typename: "Contember_CustomerAliasMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerAliasOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_CustomerAliasConnection"]: {
	__typename: "Contember_CustomerAliasConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerAliasEdge"]>
};
	["Contember_CustomerAliasEdge"]: {
	__typename: "Contember_CustomerAliasEdge",
	node: GraphQLTypes["Contember_CustomerAlias"]
};
	["Contember_ApplicationShortcutList"]: {
	__typename: "Contember_ApplicationShortcutList",
	_meta?: GraphQLTypes["Contember_ApplicationShortcutListMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	items: Array<GraphQLTypes["Contember_ApplicationShortcutListItem"]>,
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	paginateItems: GraphQLTypes["Contember_ApplicationShortcutListItemConnection"]
};
	["Contember_ApplicationShortcutListMeta"]: {
	__typename: "Contember_ApplicationShortcutListMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	items?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ApplicationShortcutListItem"]: {
	__typename: "Contember_ApplicationShortcutListItem",
	_meta?: GraphQLTypes["Contember_ApplicationShortcutListItemMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order?: number | undefined,
	application?: GraphQLTypes["Contember_Application"] | undefined,
	list?: GraphQLTypes["Contember_ApplicationShortcutList"] | undefined
};
	["Contember_ApplicationShortcutListItemMeta"]: {
	__typename: "Contember_ApplicationShortcutListItemMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	application?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	list?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_Application"]: {
	__typename: "Contember_Application",
	_meta?: GraphQLTypes["Contember_ApplicationMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	order: number,
	handle?: string | undefined,
	url?: string | undefined,
	color?: string | undefined,
	icon?: GraphQLTypes["Contember_Image"] | undefined,
	name?: string | undefined,
	short?: string | undefined
};
	["Contember_ApplicationMeta"]: {
	__typename: "Contember_ApplicationMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	order?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	handle?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	url?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	color?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	icon?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	short?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_ApplicationShortcutListItemOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	application?: GraphQLTypes["Contember_ApplicationOrderBy"] | undefined,
	list?: GraphQLTypes["Contember_ApplicationShortcutListOrderBy"] | undefined
};
	["Contember_ApplicationOrderBy"]: {
		_random?: boolean | undefined,
	_randomSeeded?: number | undefined,
	id?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	order?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	handle?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	url?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	color?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	icon?: GraphQLTypes["Contember_ImageOrderBy"] | undefined,
	name?: GraphQLTypes["Contember_OrderDirection"] | undefined,
	short?: GraphQLTypes["Contember_OrderDirection"] | undefined
};
	["Contember_ApplicationShortcutListItemConnection"]: {
	__typename: "Contember_ApplicationShortcutListItemConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_ApplicationShortcutListItemEdge"]>
};
	["Contember_ApplicationShortcutListItemEdge"]: {
	__typename: "Contember_ApplicationShortcutListItemEdge",
	node: GraphQLTypes["Contember_ApplicationShortcutListItem"]
};
	["Contember_InvoicingPlan"]: {
	__typename: "Contember_InvoicingPlan",
	_meta?: GraphQLTypes["Contember_InvoicingPlanMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	customer?: GraphQLTypes["Contember_Customer"] | undefined,
	preset?: GraphQLTypes["Contember_InvoicingPreset"] | undefined
};
	["Contember_InvoicingPlanMeta"]: {
	__typename: "Contember_InvoicingPlanMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	preset?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_InvoicingPreset"]: {
	__typename: "Contember_InvoicingPreset",
	_meta?: GraphQLTypes["Contember_InvoicingPresetMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	type?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	startsAt?: GraphQLTypes["DateTime"] | undefined,
	endsAt?: GraphQLTypes["DateTime"] | undefined,
	intervalOfDays?: number | undefined,
	invoicingPlan?: GraphQLTypes["Contember_InvoicingPlan"] | undefined
};
	["Contember_InvoicingPresetMeta"]: {
	__typename: "Contember_InvoicingPresetMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	type?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	startsAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	endsAt?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	intervalOfDays?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerSpending"]: {
	__typename: "Contember_CustomerSpending",
	_meta?: GraphQLTypes["Contember_CustomerSpendingMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	totalCents: number,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_CustomerSpendingMeta"]: {
	__typename: "Contember_CustomerSpendingMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	totalCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerMetadata"]: {
	__typename: "Contember_CustomerMetadata",
	_meta?: GraphQLTypes["Contember_CustomerMetadataMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	isBusiness: boolean,
	name?: string | undefined,
	fallbackName: string,
	remainingCreditsCents: number,
	ordersTotalCents: number,
	customerProfileUrl: string,
	customer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_CustomerMetadataMeta"]: {
	__typename: "Contember_CustomerMetadataMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	isBusiness?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	name?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	fallbackName?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	remainingCreditsCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	ordersTotalCents?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customerProfileUrl?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	customer?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_CustomerAddressesByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined
};
	["Contember_CustomerAddressesByGpsUniqueWhere"]: {
		gps?: GraphQLTypes["Contember_GpsUniqueWhere"] | undefined
};
	["Contember_CustomerAddressesByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined
};
	["Contember_CustomerAddressesByBillingAddressOfOrdersUniqueWhere"]: {
		billingAddressOfOrders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_CustomerAddressesByDeliveryAddressOfOrderDeliveriesUniqueWhere"]: {
		deliveryAddressOfOrderDeliveries?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_CustomerAddressesByDefaultDeliveryAddressOfCustomerUniqueWhere"]: {
		defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_CustomerCreditsByTransactionsUniqueWhere"]: {
		transactions?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined
};
	["Contember_CustomerCreditsByVoucherRedemptionUniqueWhere"]: {
		voucherRedemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["Contember_CustomerOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["Contember_CustomerOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["Contember_CustomerOrdersByDiscountsUniqueWhere"]: {
		discounts?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersByCartUniqueWhere"]: {
		cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersByEventsUniqueWhere"]: {
		events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["Contember_OrderSummaryUniqueWhere"] | undefined
};
	["Contember_CustomerOrdersByGratuityUniqueWhere"]: {
		gratuity?: GraphQLTypes["Contember_GratuityUniqueWhere"] | undefined
};
	["Contember_CustomerIngredientRatingsByIngredientUniqueWhere"]: {
		ingredient?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined
};
	["Contember_CustomerCartsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined
};
	["Contember_CustomerCartsByOrderUniqueWhere"]: {
		order?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_CustomerCartsByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["Contember_CartPriceLineUniqueWhere"] | undefined
};
	["Contember_CustomerCartsBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["Contember_CartSummaryUniqueWhere"] | undefined
};
	["Contember_CustomerInvoicesByPublicKeyUniqueWhere"]: {
		publicKey?: string | undefined
};
	["Contember_CustomerInvoicesByFakturoidIdUniqueWhere"]: {
		fakturoidId?: string | undefined
};
	["Contember_CustomerInvoicesByOrderPaymentsUniqueWhere"]: {
		orderPayments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_CustomerBillingSubjectsByInvoicesUniqueWhere"]: {
		invoices?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined
};
	["Contember_CustomerBillingSubjectsByDefaultBillingSubjectOfCustomerUniqueWhere"]: {
		defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_CustomerBillingSubjectsByOrdersUniqueWhere"]: {
		orders?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_CustomerOfferedProductsByProductUniqueWhere"]: {
		product?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_CustomerBoughtVouchersByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["Contember_CustomerBoughtVouchersByRedemptionUniqueWhere"]: {
		redemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_CustomerOwnsVouchersByCodeUniqueWhere"]: {
		code?: string | undefined
};
	["Contember_CustomerOwnsVouchersByRedemptionUniqueWhere"]: {
		redemption?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined
};
	["Contember_CustomerRedeemedVouchersByCustomerCreditUniqueWhere"]: {
		customerCredit?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined
};
	["Contember_CustomerRedeemedVouchersByOrderDiscountUniqueWhere"]: {
		orderDiscount?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined
};
	["Contember_CustomerRedeemedVouchersByVoucherUniqueWhere"]: {
		voucher?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined
};
	["Contember_CustomerCustomerTabsByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined
};
	["Contember_CustomerCustomerTabsByMetaUniqueWhere"]: {
		meta?: GraphQLTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined
};
	["Contember_CustomerLeadByStageItemUniqueWhere"]: {
		stageItem?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined
};
	["CustomerCreditConnection"]: {
	__typename: "CustomerCreditConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["CustomerCreditEdge"]>
};
	["CustomerCreditEdge"]: {
	__typename: "CustomerCreditEdge",
	node: GraphQLTypes["CustomerCredit"]
};
	["Contember_CustomerPaymentMethodConnection"]: {
	__typename: "Contember_CustomerPaymentMethodConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_CustomerPaymentMethodEdge"]>
};
	["Contember_CustomerPaymentMethodEdge"]: {
	__typename: "Contember_CustomerPaymentMethodEdge",
	node: GraphQLTypes["Contember_CustomerPaymentMethod"]
};
	["Contember_BillingSubjectConnection"]: {
	__typename: "Contember_BillingSubjectConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_BillingSubjectEdge"]>
};
	["Contember_BillingSubjectEdge"]: {
	__typename: "Contember_BillingSubjectEdge",
	node: GraphQLTypes["Contember_BillingSubject"]
};
	["Contember_VoucherRedemptionConnection"]: {
	__typename: "Contember_VoucherRedemptionConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["Contember_VoucherRedemptionEdge"]>
};
	["Contember_VoucherRedemptionEdge"]: {
	__typename: "Contember_VoucherRedemptionEdge",
	node: GraphQLTypes["Contember_VoucherRedemption"]
};
	["Contember_Gps"]: {
	__typename: "Contember_Gps",
	_meta?: GraphQLTypes["Contember_GpsMeta"] | undefined,
	id: GraphQLTypes["UUID"],
	latitude: number,
	longitude: number,
	address?: GraphQLTypes["Address"] | undefined
};
	["Contember_GpsMeta"]: {
	__typename: "Contember_GpsMeta",
	id?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	latitude?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	longitude?: GraphQLTypes["Contember_FieldMeta"] | undefined,
	address?: GraphQLTypes["Contember_FieldMeta"] | undefined
};
	["Contember_AddressInvoicesByPublicKeyUniqueWhere"]: {
		publicKey?: string | undefined
};
	["Contember_AddressInvoicesByFakturoidIdUniqueWhere"]: {
		fakturoidId?: string | undefined
};
	["Contember_AddressInvoicesByOrderPaymentsUniqueWhere"]: {
		orderPayments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersBySeqUniqueWhere"]: {
		seq?: number | undefined
};
	["Contember_AddressBillingAddressOfOrdersByDoubleshotLegacyIdUniqueWhere"]: {
		doubleshotLegacyId?: string | undefined
};
	["Contember_AddressBillingAddressOfOrdersByDoubleShotLegacyNumberUniqueWhere"]: {
		doubleShotLegacyNumber?: string | undefined
};
	["Contember_AddressBillingAddressOfOrdersByDiscountsUniqueWhere"]: {
		discounts?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersByDeliveryUniqueWhere"]: {
		delivery?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersByItemsUniqueWhere"]: {
		items?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersByCartUniqueWhere"]: {
		cart?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersByEventsUniqueWhere"]: {
		events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersByPriceLinesUniqueWhere"]: {
		priceLines?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersByPaymentsUniqueWhere"]: {
		payments?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersBySummaryUniqueWhere"]: {
		summary?: GraphQLTypes["Contember_OrderSummaryUniqueWhere"] | undefined
};
	["Contember_AddressBillingAddressOfOrdersByGratuityUniqueWhere"]: {
		gratuity?: GraphQLTypes["Contember_GratuityUniqueWhere"] | undefined
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByTrackingCodeUniqueWhere"]: {
		trackingCode?: string | undefined
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByOrderUniqueWhere"]: {
		order?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByDeliveryWaypointUniqueWhere"]: {
		deliveryWaypoint?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByEventsUniqueWhere"]: {
		events?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined
};
	["Contember_AddressDeliveryAddressOfOrderDeliveriesByLiftagoRideUniqueWhere"]: {
		liftagoRide?: GraphQLTypes["Contember_LiftagoRideUniqueWhere"] | undefined
};
	["OrderDeliveryConnection"]: {
	__typename: "OrderDeliveryConnection",
	pageInfo: GraphQLTypes["Contember_PageInfo"],
	edges: Array<GraphQLTypes["OrderDeliveryEdge"]>
};
	["OrderDeliveryEdge"]: {
	__typename: "OrderDeliveryEdge",
	node: GraphQLTypes["OrderDelivery"]
};
	["Contember__ValidationResult"]: {
	__typename: "Contember__ValidationResult",
	valid: boolean,
	errors: Array<GraphQLTypes["Contember__ValidationError"]>
};
	["Contember__ValidationError"]: {
	__typename: "Contember__ValidationError",
	path: Array<GraphQLTypes["Contember__PathFragment"]>,
	message: GraphQLTypes["Contember__ValidationMessage"]
};
	["Contember__PathFragment"]:{
        	__typename:"Contember__FieldPathFragment" | "Contember__IndexPathFragment"
        	['...on Contember__FieldPathFragment']: '__union' & GraphQLTypes["Contember__FieldPathFragment"];
	['...on Contember__IndexPathFragment']: '__union' & GraphQLTypes["Contember__IndexPathFragment"];
};
	["Contember__FieldPathFragment"]: {
	__typename: "Contember__FieldPathFragment",
	field: string
};
	["Contember__IndexPathFragment"]: {
	__typename: "Contember__IndexPathFragment",
	index: number,
	alias?: string | undefined
};
	["Contember__ValidationMessage"]: {
	__typename: "Contember__ValidationMessage",
	text: string
};
	["Contember_AddressCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutAddressesCreateInput"] | undefined
};
	["Contember_CustomerWithoutAddressesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined
};
	["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerPaymentMethodCreateInput"] | undefined
};
	["Contember_CustomerPaymentMethodCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	caption?: string | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerPaymentMethodCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutPaymentMethodsCreateInput"] | undefined
};
	["Contember_CustomerWithoutPaymentMethodsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateAddressesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_AddressWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined
};
	["Contember_CountryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined
};
	["Contember_CountryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateDeliveryZoneEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutAddressesCreateInput"] | undefined
};
	["Contember_DeliveryZoneUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	addresses?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	addressesMetadata?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneWithoutAddressesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	suitableDeliveryMethods?: Array<GraphQLTypes["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]> | undefined,
	deliveryZonesOfTags?: Array<GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]> | undefined,
	deliveryZonesOfProducts?: Array<GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PaymentMethodUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	channels?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined
};
	["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch?: boolean | undefined,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	channels?: Array<GraphQLTypes["Contember_PaymentMethodCreateChannelsEntityRelationInput"]> | undefined,
	reduceGratuityByPercentage?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PaymentMethodCreateChannelsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutMethodCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelPaymentWithoutMethodCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentCreateChannelEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutPaymentsCreateInput"] | undefined
};
	["Contember_ChannelWithoutPaymentsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelCreateDeliveriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryWithoutChannelCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelDeliveryWithoutChannelCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	priceCents?: number | undefined,
	method?: GraphQLTypes["Contember_ChannelDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelDeliveryCreateMethodEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutChannelsCreateInput"] | undefined
};
	["Contember_DeliveryMethodWithoutChannelsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]> | undefined,
	deliveryZonesOfTags?: Array<GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]> | undefined,
	deliveryZonesOfProducts?: Array<GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutDeliveryZoneCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_AddressWithoutDeliveryZoneCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	meta?: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined
};
	["Contember_AddressCreateGpsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_GpsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_GpsWithoutAddressCreateInput"] | undefined
};
	["Contember_GpsWithoutAddressCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	latitude?: number | undefined,
	longitude?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateInvoicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutAddressCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_InvoiceWithoutAddressCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_InvoiceCreateCustomerEntityRelationInput"] | undefined,
	orderPayments?: Array<GraphQLTypes["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]> | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_InvoiceCreateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutInvoicesCreateInput"] | undefined
};
	["Contember_CustomerWithoutInvoicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutCustomersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	nestedCategories?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined
};
	["Contember_TagWithoutCustomersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutTagsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductWithoutTagsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreatePackingEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductPackingUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingCreateInput"] | undefined
};
	["Contember_ProductPackingCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	image?: GraphQLTypes["Contember_ProductPackingCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Contember_ProductPackingCreateIconEntityRelationInput"] | undefined,
	shortName?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductPackingCreateLocalesEntityRelationInput"]> | undefined,
	preparedPackingQuantities?: Array<GraphQLTypes["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"]> | undefined,
	isPackedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ImageCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingCreateIconEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductPackingCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductPackingLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	shortName?: string | undefined,
	description?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductPackingLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductPackingsCreateInput"] | undefined
};
	["Contember_LocaleWithoutProductPackingsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_BusinessCategoryLocaleCreateRootEntityRelationInput"] | undefined,
	title?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutLocalesCreateInput"] | undefined
};
	["Contember_BusinessCategoryWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutCategoryCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCustomerWithoutCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutBusinessCreateInput"] | undefined
};
	["Contember_CustomerWithoutBusinessCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateCreditsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerCreditWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	initialCreditCents?: number | undefined,
	remainingCreditsCents?: number | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	vatRate?: GraphQLTypes["Contember_CustomerCreditCreateVatRateEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	transactions?: Array<GraphQLTypes["Contember_CustomerCreditCreateTransactionsEntityRelationInput"]> | undefined,
	voucherRedemption?: GraphQLTypes["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"] | undefined,
	invoiceUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_VatRateUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	channelDeliveries?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	channelPayments?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined
};
	["Contember_VatRateCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	ratePermille?: number | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries?: Array<GraphQLTypes["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"]> | undefined,
	channelPayments?: Array<GraphQLTypes["Contember_VatRateCreateChannelPaymentsEntityRelationInput"]> | undefined,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryWithoutVatRateCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelDeliveryWithoutVatRateCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	priceCents?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelDeliveryCreateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelDeliveryCreateMethodEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelDeliveryCreateChannelEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutDeliveriesCreateInput"] | undefined
};
	["Contember_ChannelWithoutDeliveriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelCreatePaymentsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutChannelCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelPaymentWithoutChannelCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentCreateMethodEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodWithoutChannelsCreateInput"] | undefined
};
	["Contember_PaymentMethodWithoutChannelsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch?: boolean | undefined,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	reduceGratuityByPercentage?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryWithoutMethodCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelDeliveryWithoutMethodCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	priceCents?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelDeliveryCreateChannelEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateWithoutChannelDeliveriesCreateInput"] | undefined
};
	["Contember_VatRateWithoutChannelDeliveriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	ratePermille?: number | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelPayments?: Array<GraphQLTypes["Contember_VatRateCreateChannelPaymentsEntityRelationInput"]> | undefined,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VatRateCreateChannelPaymentsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutVatRateCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelPaymentWithoutVatRateCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutAllowChannelPaymentsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagWithoutAllowChannelPaymentsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreateCustomersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutTagsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerWithoutTagsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	caption?: string | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerPaymentMethodCreatePaymentMethodEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodCreateInput"] | undefined
};
	["Contember_PaymentMethodCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_PaymentMethodCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch?: boolean | undefined,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	channels?: Array<GraphQLTypes["Contember_PaymentMethodCreateChannelsEntityRelationInput"]> | undefined,
	reduceGratuityByPercentage?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateOrdersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateChannelEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelCreateInput"] | undefined
};
	["Contember_ChannelCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelCreatePointOfSalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleWithoutChannelsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	permissions?: GraphQLTypes["Contember_PointOfSalePermissionsUniqueWhere"] | undefined
};
	["Contember_PointOfSalePermissionsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	pointOfSale?: GraphQLTypes["Contember_PointOfSaleUniqueWhere"] | undefined
};
	["Contember_PointOfSaleWithoutChannelsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	permissions?: GraphQLTypes["Contember_PointOfSaleCreatePermissionsEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCreatePermissionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSalePermissionsUniqueWhere"] | undefined
};
	["Contember_ChannelCreateCartsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutChannelCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CartWithoutChannelCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CartCreateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_CartCreateOrderEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CartCreateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CartItemWithoutCartCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CartItemWithoutCartCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	quantity?: number | undefined,
	product?: GraphQLTypes["Contember_CartItemCreateProductEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartItemCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutCartItemsCreateInput"] | undefined
};
	["Contember_ProductWithoutCartItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateRecipeEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeWithoutProductsCreateInput"] | undefined
};
	["Contember_ProductRecipeWithoutProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductRecipeCreateImageEntityRelationInput"] | undefined,
	icon?: GraphQLTypes["Contember_ProductRecipeCreateIconEntityRelationInput"] | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductRecipeCreateLocalesEntityRelationInput"]> | undefined,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductRecipeCreateIconEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemWithoutRecipeCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientItemWithoutRecipeCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	internalOrder?: number | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredientItemCreateIngredientEntityRelationInput"] | undefined,
	quantity?: GraphQLTypes["Contember_ProductIngredientItemCreateQuantityEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientItemCreateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCreateInput"] | undefined
};
	["Contember_ProductIngredientCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductIngredientsCreateInput"] | undefined
};
	["Contember_LocaleWithoutProductIngredientsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductCategoryListLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListWithoutLocalesCreateInput"] | undefined
};
	["Contember_ProductCategoryListWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ProductCategoryListCreateItemsEntityRelationInput"]> | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryListItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput"] | undefined,
	showRecipeImages?: boolean | undefined,
	showPackingImages?: boolean | undefined,
	showAllAvailableProducts?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListItemCreateProductCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryCreateInput"] | undefined
};
	["Contember_ProductCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutCategoriesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductWithoutCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutProductsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagWithoutProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreateCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutTagsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryWithoutTagsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryCreateParentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutChildrenCreateInput"] | undefined
};
	["Contember_ProductCategoryWithoutChildrenCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryCreateTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutCategoriesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagWithoutCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelCreateCustomersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutChannelsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerWithoutChannelsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreatePermissionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined
};
	["Contember_CustomerCreateGroupEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerGroupUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerGroupWithoutCustomersCreateInput"] | undefined
};
	["Contember_CustomerGroupWithoutCustomersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	productPrices?: Array<GraphQLTypes["Contember_CustomerGroupCreateProductPricesEntityRelationInput"]> | undefined,
	discountPermille?: number | undefined,
	code?: string | undefined,
	doubleshotLegacyId?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerGroupCreateProductPricesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductGroupPriceWithoutGroupCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductGroupPriceWithoutGroupCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	priceCents?: number | undefined,
	product?: GraphQLTypes["Contember_ProductGroupPriceCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGroupPriceCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutGroupPricesCreateInput"] | undefined
};
	["Contember_ProductWithoutGroupPricesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutProductsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryWithoutProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryCreateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryCreateChildrenEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutParentCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryWithoutParentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryCreateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryCreateTagsEntityRelationInput"]> | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryCreateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryCreateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductCategoryCreateDescendantsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryCreateAncestorsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryCreateInheritedTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryCreateNestedProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_ProductCreateStocksEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductStockWithoutProductCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductStockWithoutProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	quantity?: number | undefined,
	store?: GraphQLTypes["Contember_ProductStockCreateStoreEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductStockCreateStoreEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StoreUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StoreCreateInput"] | undefined
};
	["Contember_StoreCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductCreateCartItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CartItemWithoutProductCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CartItemWithoutProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	quantity?: number | undefined,
	cart?: GraphQLTypes["Contember_CartItemCreateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartItemCreateCartEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutItemsCreateInput"] | undefined
};
	["Contember_CartWithoutItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["Contember_CartCreateChannelEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_CartCreateOrderEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CartCreateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreateChannelEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutCartsCreateInput"] | undefined
};
	["Contember_ChannelWithoutCartsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutAddTagsOnRegistrationCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagWithoutAddTagsOnRegistrationCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreateNestedCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagCreateDeliveryZonesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]> | undefined,
	suitableDeliveryMethods?: Array<GraphQLTypes["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]> | undefined,
	deliveryZonesOfProducts?: Array<GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneCreateDeliveryZonesOfProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutDeliveryZonesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductWithoutDeliveryZonesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateGroupPricesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductGroupPriceWithoutProductCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductGroupPriceWithoutProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	priceCents?: number | undefined,
	group?: GraphQLTypes["Contember_ProductGroupPriceCreateGroupEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGroupPriceCreateGroupEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerGroupUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerGroupWithoutProductPricesCreateInput"] | undefined
};
	["Contember_CustomerGroupWithoutProductPricesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_CustomerGroupCreateCustomersEntityRelationInput"]> | undefined,
	discountPermille?: number | undefined,
	code?: string | undefined,
	doubleshotLegacyId?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerGroupCreateCustomersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutGroupCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerWithoutGroupCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreatePhotoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_CustomerCreateNameEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerNameUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerNameWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerNameWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	nominative?: string | undefined,
	vocative?: string | undefined,
	aliases?: Array<GraphQLTypes["Contember_CustomerNameCreateAliasesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerNameCreateAliasesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerAliasUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerAliasCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerAliasUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_CustomerAliasCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	rating?: GraphQLTypes["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientRatingUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientRatingCreateInput"] | undefined
};
	["Contember_ProductIngredientRatingUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ProductIngredientRatingCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	name?: string | undefined,
	icon?: GraphQLTypes["Contember_ProductIngredientRatingCreateIconEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientRatingCreateIconEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_CustomerIngredientRatingCreateIngredientEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"] | undefined
};
	["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput"]> | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"] | undefined
};
	["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateProductIngredientsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutLocalesCreateInput"] | undefined
};
	["Contember_ProductIngredientWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalNote?: string | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCreateAllergensEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AllergenWithoutIngredientsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_AllergenWithoutIngredientsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_AllergenCreateLocalesEntityRelationInput"]> | undefined,
	code?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AllergenLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_AllergenLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_AllergenLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutAllergensCreateInput"] | undefined
};
	["Contember_LocaleWithoutAllergensCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceWithoutLocalesCreateInput"] | undefined
};
	["Contember_OrderRecurrenceWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	order?: number | undefined,
	businessCategory?: Array<GraphQLTypes["Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceCreateBusinessCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutRecurrencesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCategoryWithoutRecurrencesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCategoryLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput"] | undefined,
	title?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutBusinessCategoriesCreateInput"] | undefined
};
	["Contember_LocaleWithoutBusinessCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateBlogsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BlogLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Contember_BlogLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogWithoutLocalesCreateInput"] | undefined
};
	["Contember_BlogWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateBlogPostsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BlogPostLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPostLocaleCreateRootEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_BlogPostLocaleCreateCoverEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_BlogPostLocaleCreateContentEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_BlogPostLocaleCreateLinkEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_BlogPostLocaleCreateSeoEntityRelationInput"] | undefined,
	products?: Array<GraphQLTypes["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogPostUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostWithoutLocalesCreateInput"] | undefined
};
	["Contember_BlogPostWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleCreateCoverEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CoverCreateInput"] | undefined
};
	["Contember_CoverCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Contember_CoverCreateMediumEntityRelationInput"] | undefined,
	buttonColors?: GraphQLTypes["Contember_CoverCreateButtonColorsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CoverCreateMediumEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_MediumUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_MediumCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_MediumType"] | undefined,
	colorTheme?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	image?: GraphQLTypes["Contember_MediumCreateImageEntityRelationInput"] | undefined,
	video?: GraphQLTypes["Contember_MediumCreateVideoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MediumCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MediumCreateVideoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VideoUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VideoCreateInput"] | undefined
};
	["Contember_VideoUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_VideoCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Contember_VideoCreatePosterEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VideoCreatePosterEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_CoverCreateButtonColorsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ColorPalleteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ColorPalleteCreateInput"] | undefined
};
	["Contember_ColorPalleteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_ColorPalleteCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	background?: string | undefined,
	text?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleCreateContentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined
};
	["Contember_ContentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	blocks?: Array<GraphQLTypes["Contember_ContentCreateBlocksEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentCreateBlocksEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ContentBlockUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ContentBlockWithoutContentCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ContentBlockWithoutContentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	json?: string | undefined,
	references?: Array<GraphQLTypes["Contember_ContentBlockCreateReferencesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentBlockCreateReferencesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ContentReferenceWithoutBlockCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ContentReferenceWithoutBlockCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_ContentReferenceType"] | undefined,
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	colorPallete?: GraphQLTypes["Contember_ContentReferenceCreateColorPalleteEntityRelationInput"] | undefined,
	medium?: GraphQLTypes["Contember_ContentReferenceCreateMediumEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_ContentReferenceCreateLinkEntityRelationInput"] | undefined,
	blogPosts?: GraphQLTypes["Contember_ContentReferenceCreateBlogPostsEntityRelationInput"] | undefined,
	links?: GraphQLTypes["Contember_ContentReferenceCreateLinksEntityRelationInput"] | undefined,
	products?: GraphQLTypes["Contember_ContentReferenceCreateProductsEntityRelationInput"] | undefined,
	contentSize?: GraphQLTypes["Contember_ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Contember_ContentReferenceCreateHeroEntityRelationInput"] | undefined,
	gallery?: GraphQLTypes["Contember_ContentReferenceCreateGalleryEntityRelationInput"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput"] | undefined,
	productGrid?: GraphQLTypes["Contember_ContentReferenceCreateProductGridEntityRelationInput"] | undefined,
	productBanners?: GraphQLTypes["Contember_ContentReferenceCreateProductBannersEntityRelationInput"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput"] | undefined,
	textList?: GraphQLTypes["Contember_ContentReferenceCreateTextListEntityRelationInput"] | undefined,
	gridTiles?: GraphQLTypes["Contember_ContentReferenceCreateGridTilesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentReferenceCreateColorPalleteEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ColorPalleteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ColorPalleteCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateMediumEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_LinkCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_LinkType"] | undefined,
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Contember_LinkCreateInternalLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkCreateInternalLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkableCreateInput"] | undefined
};
	["Contember_LinkableCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	blogPost?: GraphQLTypes["Contember_LinkableCreateBlogPostEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_LinkableCreatePageEntityRelationInput"] | undefined,
	wikiPage?: GraphQLTypes["Contember_LinkableCreateWikiPageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkableCreateBlogPostEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostLocaleWithoutLinkCreateInput"] | undefined
};
	["Contember_BlogPostLocaleWithoutLinkCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPostLocaleCreateRootEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_BlogPostLocaleCreateCoverEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_BlogPostLocaleCreateContentEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_BlogPostLocaleCreateSeoEntityRelationInput"] | undefined,
	products?: Array<GraphQLTypes["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutBlogPostsCreateInput"] | undefined
};
	["Contember_LocaleWithoutBlogPostsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateIdentifierEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCataloguesIdentifierCreateInput"] | undefined
};
	["Contember_TranslationCataloguesIdentifierCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	catalogue?: Array<GraphQLTypes["Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCataloguesIdentifierCreateCatalogueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueWithoutIdentifierCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationCatalogueWithoutIdentifierCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationCatalogueCreateDomainEntityRelationInput"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueCreateDomainEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationDomainUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationDomainWithoutCataloguesCreateInput"] | undefined
};
	["Contember_TranslationDomainWithoutCataloguesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	name?: string | undefined,
	keys?: Array<GraphQLTypes["Contember_TranslationDomainCreateKeysEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationDomainCreateKeysEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationKeyWithoutDomainCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationKeyWithoutDomainCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	contentType?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	note?: string | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationKeyCreateValuesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationKeyCreateValuesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationValueWithoutKeyCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationValueWithoutKeyCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	value?: string | undefined,
	catalogue?: GraphQLTypes["Contember_TranslationValueCreateCatalogueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationValueCreateCatalogueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueWithoutValuesCreateInput"] | undefined
};
	["Contember_TranslationCatalogueWithoutValuesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationCatalogueCreateDomainEntityRelationInput"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueCreateInput"] | undefined
};
	["Contember_TranslationCatalogueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	domain?: GraphQLTypes["Contember_TranslationCatalogueCreateDomainEntityRelationInput"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationValueWithoutCatalogueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationValueWithoutCatalogueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	value?: string | undefined,
	key?: GraphQLTypes["Contember_TranslationValueCreateKeyEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationValueCreateKeyEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationKeyWithoutValuesCreateInput"] | undefined
};
	["Contember_TranslationKeyWithoutValuesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	contentType?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	note?: string | undefined,
	domain?: GraphQLTypes["Contember_TranslationKeyCreateDomainEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationKeyCreateDomainEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationDomainUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationDomainWithoutKeysCreateInput"] | undefined
};
	["Contember_TranslationDomainWithoutKeysCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	identifier?: string | undefined,
	name?: string | undefined,
	catalogues?: Array<GraphQLTypes["Contember_TranslationDomainCreateCataloguesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationDomainCreateCataloguesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueWithoutDomainCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationCatalogueWithoutDomainCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueCreateFallbackEntityRelationInput"] | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationCatalogueCreateValuesEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueCreateIdentifierEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"] | undefined
};
	["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateMenusEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_MenuLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	root?: GraphQLTypes["Contember_MenuLocaleCreateRootEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_MenuLocaleCreateItemsEntityRelationInput"]> | undefined,
	secondaryItems?: GraphQLTypes["Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput"] | undefined,
	eshopLink?: GraphQLTypes["Contember_MenuLocaleCreateEshopLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SiteMenuUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SiteMenuWithoutLocalesCreateInput"] | undefined
};
	["Contember_SiteMenuWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLocaleCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuItemWithoutMenuCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_MenuItemWithoutMenuCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	link?: GraphQLTypes["Contember_MenuItemCreateLinkEntityRelationInput"] | undefined,
	subitems?: Array<GraphQLTypes["Contember_MenuItemCreateSubitemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuItemCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_MenuItemCreateSubitemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuSubitemWithoutItemCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_MenuSubitemWithoutItemCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	title?: string | undefined,
	links?: GraphQLTypes["Contember_MenuSubitemCreateLinksEntityRelationInput"] | undefined,
	products?: GraphQLTypes["Contember_MenuSubitemCreateProductsEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_MenuSubitemCreateDeliveryEntityRelationInput"] | undefined,
	contact?: GraphQLTypes["Contember_MenuSubitemCreateContactEntityRelationInput"] | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuSubitemCreateLinksEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuLinkListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuLinkListCreateInput"] | undefined
};
	["Contember_MenuLinkListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"] | undefined
};
	["Contember_MenuLinkItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_MenuLinkListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_MenuLinkListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLinkListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuLinkItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_MenuLinkItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	link?: GraphQLTypes["Contember_MenuLinkItemCreateLinkEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_MenuLinkItemCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLinkItemCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_MenuLinkItemCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MenuSubitemCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuProductsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuProductsCreateInput"] | undefined
};
	["Contember_MenuProductsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	buttons?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined
};
	["Contember_MenuProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	links?: GraphQLTypes["Contember_MenuProductsCreateLinksEntityRelationInput"] | undefined,
	buttons?: GraphQLTypes["Contember_MenuProductsCreateButtonsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuProductsCreateLinksEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuLinkListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuLinkListCreateInput"] | undefined
};
	["Contember_MenuProductsCreateButtonsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined
};
	["Contember_LinkListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_LinkListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkListItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkListItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_LinkListItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	isPrimary?: boolean | undefined,
	link?: GraphQLTypes["Contember_LinkListItemCreateLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkListItemCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_MenuSubitemCreateDeliveryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuDeliveryCreateInput"] | undefined
};
	["Contember_MenuDeliveryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined
};
	["Contember_MenuDeliveryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput"] | undefined,
	text?: string | undefined,
	socialsAndApps?: GraphQLTypes["Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuDeliveryCreateDeliveryRegionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRegionsCreateInput"] | undefined
};
	["Contember_DeliveryRegionsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_DeliveryRegionsCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRegionsCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRegionsItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryRegionsItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	title?: string | undefined,
	offerLabel?: string | undefined,
	image?: GraphQLTypes["Contember_DeliveryRegionsItemCreateImageEntityRelationInput"] | undefined,
	textList?: GraphQLTypes["Contember_DeliveryRegionsItemCreateTextListEntityRelationInput"] | undefined,
	gallery?: GraphQLTypes["Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRegionsItemCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_DeliveryRegionsItemCreateTextListEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_TextListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_TextListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TextListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TextItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TextItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TextItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	text?: string | undefined,
	title?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRegionsItemCreateGalleryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageListCreateInput"] | undefined
};
	["Contember_ImageListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ImageListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ImageListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageListItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageListItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ImageListItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	image?: GraphQLTypes["Contember_ImageListItemCreateImageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ImageListItemCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MenuDeliveryCreateSocialsAndAppsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SocialsAndAppsCreateInput"] | undefined
};
	["Contember_SocialsAndAppsUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_SocialsAndAppsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuSubitemCreateContactEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MenuContactUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MenuContactCreateInput"] | undefined
};
	["Contember_MenuContactUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_MenuContactCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	text?: string | undefined,
	image?: GraphQLTypes["Contember_MenuContactCreateImageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuContactCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MenuLocaleCreateSecondaryItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined
};
	["Contember_MenuLocaleCreateEshopLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_LocaleCreatePagesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PageLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PageLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	link?: GraphQLTypes["Contember_PageLocaleCreateLinkEntityRelationInput"] | undefined,
	root?: GraphQLTypes["Contember_PageLocaleCreateRootEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_PageLocaleCreateSeoEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_PageLocaleCreateContentEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_PageLocaleCreateCoverEntityRelationInput"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkableWithoutPageCreateInput"] | undefined
};
	["Contember_LinkableWithoutPageCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	blogPost?: GraphQLTypes["Contember_LinkableCreateBlogPostEntityRelationInput"] | undefined,
	wikiPage?: GraphQLTypes["Contember_LinkableCreateWikiPageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkableCreateWikiPageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_WikiPageWithoutLinkCreateInput"] | undefined
};
	["Contember_WikiPageWithoutLinkCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	title?: string | undefined,
	emoji?: string | undefined,
	parent?: GraphQLTypes["Contember_WikiPageCreateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_WikiPageCreateChildrenEntityRelationInput"]> | undefined,
	content?: GraphQLTypes["Contember_WikiPageCreateContentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WikiPageCreateParentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_WikiPageWithoutChildrenCreateInput"] | undefined
};
	["Contember_WikiPageWithoutChildrenCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Contember_WikiPageCreateLinkEntityRelationInput"] | undefined,
	parent?: GraphQLTypes["Contember_WikiPageCreateParentEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_WikiPageCreateContentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WikiPageCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkableWithoutWikiPageCreateInput"] | undefined
};
	["Contember_LinkableWithoutWikiPageCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	blogPost?: GraphQLTypes["Contember_LinkableCreateBlogPostEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_LinkableCreatePageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkableCreatePageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PageLocaleWithoutLinkCreateInput"] | undefined
};
	["Contember_PageLocaleWithoutLinkCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	root?: GraphQLTypes["Contember_PageLocaleCreateRootEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_PageLocaleCreateLocaleEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_PageLocaleCreateSeoEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_PageLocaleCreateContentEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_PageLocaleCreateCoverEntityRelationInput"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PageWithoutLocalesCreateInput"] | undefined
};
	["Contember_PageWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPagesCreateInput"] | undefined
};
	["Contember_LocaleWithoutPagesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"] | undefined
};
	["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	internalNote?: string | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput"]> | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryCreateIngredientsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutCategoriesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientWithoutCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientCreateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput"] | undefined,
	rating?: GraphQLTypes["Contember_CustomerIngredientRatingCreateRatingEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerIngredientRatingCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutIngredientRatingsCreateInput"] | undefined
};
	["Contember_CustomerWithoutIngredientRatingsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateBusinessEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutCustomerCreateInput"] | undefined
};
	["Contember_BusinessCustomerWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutMembersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCustomerWithoutMembersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerCreateCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"] | undefined
};
	["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_OrderRecurrenceCreateLocalesEntityRelationInput"]> | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutOrderRecurrencesCreateInput"] | undefined
};
	["Contember_LocaleWithoutOrderRecurrencesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateAllergensEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AllergenLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_AllergenLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_AllergenLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AllergenWithoutLocalesCreateInput"] | undefined
};
	["Contember_AllergenWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	ingredients?: Array<GraphQLTypes["Contember_AllergenCreateIngredientsEntityRelationInput"]> | undefined,
	code?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenCreateIngredientsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutAllergensCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientWithoutAllergensCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientCreateCategoriesEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientCreateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientCreateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientCreateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCreatePreferredQuantityUnitEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined
};
	["Contember_ProductIngredientItemQuantityUnitUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined
};
	["Contember_ProductIngredientItemQuantityUnitCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCreateFreshingContainerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FreshingContainerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined
};
	["Contember_FreshingContainerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_FreshingContainerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	volumeMl?: number | undefined,
	workspace?: GraphQLTypes["Contember_FreshingContainerCreateWorkspaceEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FreshingContainerCreateWorkspaceEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_WorkspaceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_WorkspaceWithoutFreshingContainersCreateInput"] | undefined
};
	["Contember_WorkspaceUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	freshingContainers?: GraphQLTypes["Contember_FreshingContainerUniqueWhere"] | undefined,
	openingHours?: GraphQLTypes["Contember_WeekHoursUniqueWhere"] | undefined,
	workingHours?: GraphQLTypes["Contember_WeekHoursUniqueWhere"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined,
	discountVatRate?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_WeekHoursUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_WorkspaceWithoutFreshingContainersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours?: number | undefined,
	freshingContainerVolumeMl?: number | undefined,
	freshingPrepareOrderByOffsetMinutes?: number | undefined,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	openingHours?: GraphQLTypes["Contember_WorkspaceCreateOpeningHoursEntityRelationInput"] | undefined,
	workingHours?: GraphQLTypes["Contember_WorkspaceCreateWorkingHoursEntityRelationInput"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_WorkspaceCreateCreditsProductEntityRelationInput"] | undefined,
	discountVatRate?: GraphQLTypes["Contember_WorkspaceCreateDiscountVatRateEntityRelationInput"] | undefined,
	name?: string | undefined,
	businessAddress?: string | undefined,
	migrationTest?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WorkspaceCreateOpeningHoursEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_WeekHoursUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_WeekHoursCreateInput"] | undefined
};
	["Contember_WeekHoursCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WorkspaceCreateWorkingHoursEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_WeekHoursUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_WeekHoursCreateInput"] | undefined
};
	["Contember_WorkspaceCreateDefaultFreshingContainerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FreshingContainerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined
};
	["Contember_WorkspaceCreateCreditsProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductCreateInput"] | undefined
};
	["Contember_VirtualProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	product?: GraphQLTypes["Contember_VirtualProductCreateProductEntityRelationInput"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	recipientEmail?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VirtualProductCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutVirtualProductCreateInput"] | undefined
};
	["Contember_ProductWithoutVirtualProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StocksManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StocksManagerWithoutManagedProductsCreateInput"] | undefined
};
	["Contember_StocksManagerWithoutManagedProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_StocksManagerCreateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StocksManagerCreateStaffEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutStocksManagerCreateInput"] | undefined
};
	["Contember_StaffWithoutStocksManagerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateBartenderEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BartenderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BartenderWithoutStaffCreateInput"] | undefined
};
	["Contember_BartenderWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateCourierEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CourierWithoutStaffCreateInput"] | undefined
};
	["Contember_CourierWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	routes?: Array<GraphQLTypes["Contember_CourierCreateRoutesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_CourierCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	showInPicker?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CourierCreateRoutesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRouteWithoutCourierCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryRouteWithoutCourierCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	waypoints?: Array<GraphQLTypes["Contember_DeliveryRouteCreateWaypointsEntityRelationInput"]> | undefined,
	dispatchAt?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRouteCreateWaypointsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryWaypointWithoutRouteCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryWaypointWithoutRouteCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	sortingOrder?: number | undefined,
	delivery?: GraphQLTypes["Contember_DeliveryWaypointCreateDeliveryEntityRelationInput"] | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryWaypointCreateDeliveryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"] | undefined
};
	["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDeliveryCreateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	events?: Array<GraphQLTypes["Contember_OrderDeliveryCreateEventsEntityRelationInput"]> | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Contember_OrderDeliveryCreateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryCreateMethodEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodCreateInput"] | undefined
};
	["Contember_DeliveryMethodCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CourierWithoutDeliveryMethodsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CourierWithoutDeliveryMethodsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_CourierCreateStaffEntityRelationInput"] | undefined,
	routes?: Array<GraphQLTypes["Contember_CourierCreateRoutesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	showInPicker?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CourierCreateStaffEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutCourierCreateInput"] | undefined
};
	["Contember_StaffWithoutCourierCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateCleanerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CleanerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CleanerWithoutStaffCreateInput"] | undefined
};
	["Contember_CleanerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	canReachTopShelf?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateShiftsManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_ShiftsManagerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	managedRoles?: Array<GraphQLTypes["Contember_ShiftsManagerCreateManagedRolesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsManagerCreateManagedRolesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutTypeCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftGroupWithoutTypeCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftWithoutGroupCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftWithoutGroupCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"] | undefined,
	queueItem?: GraphQLTypes["Contember_TimetableShiftCreateQueueItemEntityRelationInput"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutShiftsCreateInput"] | undefined
};
	["Contember_ShiftsProfileWithoutShiftsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined,
	staff?: GraphQLTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueWithoutItemsCreateInput"] | undefined
};
	["Contember_EmployeeQueueWithoutItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroup?: GraphQLTypes["Contember_EmployeeQueueCreateShiftGroupEntityRelationInput"] | undefined,
	contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueCreateShiftGroupEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutQueueCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupWithoutQueueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupCreateDayEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableDayWithoutShiftGroupsCreateInput"] | undefined
};
	["Contember_TimetableDayWithoutShiftGroupsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	type?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	tipsCents?: number | undefined,
	notes?: Array<GraphQLTypes["Contember_TimetableDayCreateNotesEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_TimetableDayCreateVenueEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayCreateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayCreateNotesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableDayNoteWithoutDayCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableDayNoteWithoutDayCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	note?: string | undefined,
	role?: GraphQLTypes["Contember_TimetableDayNoteCreateRoleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayNoteCreateRoleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutRolesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ShiftsProfileWithoutRolesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined,
	staff?: GraphQLTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileCreateStaffEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutShiftsProfileCreateInput"] | undefined
};
	["Contember_StaffWithoutShiftsProfileCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	managedBusinesses?: Array<GraphQLTypes["Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessToBusinessManagerCreateManagedBusinessesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutAccountManagerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCustomerWithoutAccountManagerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerCreateOwnersEntityRelationInput"]> | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerCreateMembersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutMemberOfBusinessesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerWithoutMemberOfBusinessesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutOwnersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCustomerWithoutOwnersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerCreateCustomerEntityRelationInput"] | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerCreateMembersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerCreateCategoryEntityRelationInput"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerCreateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerCreateAccountManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"] | undefined
};
	["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessToBusinessManagerCreateStaffEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"] | undefined
};
	["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateUserEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutStaffCreateInput"] | undefined
};
	["Contember_UserWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutUserCreateInput"] | undefined
};
	["Contember_CustomerWithoutUserCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateCartsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CartWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CartCreateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["Contember_CartCreateChannelEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_CartCreateOrderEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutCartCreateInput"] | undefined
};
	["Contember_OrderWithoutCartCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutOrdersCreateInput"] | undefined
};
	["Contember_CustomerWithoutOrdersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateShortcutListEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ApplicationShortcutListWithoutCustomerCreateInput"] | undefined
};
	["Contember_ApplicationShortcutListWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ApplicationShortcutListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ApplicationShortcutListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ApplicationShortcutListItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ApplicationShortcutListItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	application?: GraphQLTypes["Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ApplicationShortcutListItemCreateApplicationEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ApplicationUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ApplicationCreateInput"] | undefined
};
	["Contember_ApplicationUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	handle?: string | undefined,
	icon?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined
};
	["Contember_ApplicationCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	handle?: string | undefined,
	url?: string | undefined,
	color?: string | undefined,
	icon?: GraphQLTypes["Contember_ApplicationCreateIconEntityRelationInput"] | undefined,
	name?: string | undefined,
	short?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ApplicationCreateIconEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_CustomerCreateUserEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutCustomerCreateInput"] | undefined
};
	["Contember_UserWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserCreateStaffEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutUserCreateInput"] | undefined
};
	["Contember_StaffWithoutUserCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateShiftsProfileEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutStaffCreateInput"] | undefined
};
	["Contember_ShiftsProfileWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileCreateRolesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsManagerWithoutManagedRolesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ShiftsManagerWithoutManagedRolesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_ShiftsManagerCreateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsManagerCreateStaffEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutShiftsManagerCreateInput"] | undefined
};
	["Contember_StaffWithoutShiftsManagerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateStocksManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StocksManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StocksManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_StocksManagerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	managedProducts?: Array<GraphQLTypes["Contember_StocksManagerCreateManagedProductsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StocksManagerCreateManagedProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutManagerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductWithoutManagerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateOrderItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutProductCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderItemWithoutProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderItemCreateOrderEntityRelationInput"] | undefined,
	virtualProductEffects?: Array<GraphQLTypes["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]> | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderItemCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutItemsCreateInput"] | undefined
};
	["Contember_OrderWithoutItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateDiscountsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderDiscountWithoutOrderCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderDiscountWithoutOrderCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	discountCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDiscountCreateVatRateEntityRelationInput"] | undefined,
	description?: string | undefined,
	type?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	customerCreditTransactions?: Array<GraphQLTypes["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"]> | undefined,
	voucherRedemption?: GraphQLTypes["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDiscountCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	amountCents?: number | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditWithoutTransactionsCreateInput"] | undefined
};
	["Contember_CustomerCreditWithoutTransactionsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	initialCreditCents?: number | undefined,
	remainingCreditsCents?: number | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Contember_CustomerCreditCreateCustomerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_CustomerCreditCreateVatRateEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"] | undefined,
	invoiceUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutCreditsCreateInput"] | undefined
};
	["Contember_CustomerWithoutCreditsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateChannelsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutCustomersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelWithoutCustomersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelCreatePublicTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutPublicInChannelsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagWithoutPublicInChannelsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateWithoutChannelPaymentsCreateInput"] | undefined
};
	["Contember_VatRateWithoutChannelPaymentsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	ratePermille?: number | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries?: Array<GraphQLTypes["Contember_VatRateCreateChannelDeliveriesEntityRelationInput"]> | undefined,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreateFulfillmentNotesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutTagsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_FulfillmentNoteWithoutTagsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutFulfillmentNotesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductWithoutFulfillmentNotesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCreateDeliveryZonesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_DeliveryZoneCreateAddressesEntityRelationInput"]> | undefined,
	suitableDeliveryMethods?: Array<GraphQLTypes["Contember_DeliveryZoneCreateSuitableDeliveryMethodsEntityRelationInput"]> | undefined,
	deliveryZonesOfTags?: Array<GraphQLTypes["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneCreateDeliveryZonesOfTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutDeliveryZonesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagWithoutDeliveryZonesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagCreatePublicInChannelsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutPublicTagsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelWithoutPublicTagsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutChannelsCreateInput"] | undefined
};
	["Contember_VenueWithoutChannelsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateDeadlineTemplateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeadlineTemplateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeadlineTemplateWithoutVenueCreateInput"] | undefined
};
	["Contember_DeadlineTemplateWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	cutoff?: number | undefined,
	closed?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateTimetableDaysEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableDayWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableDayWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	type?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableDayCreateShiftGroupsEntityRelationInput"]> | undefined,
	tipsCents?: number | undefined,
	notes?: Array<GraphQLTypes["Contember_TimetableDayCreateNotesEntityRelationInput"]> | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayCreateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayCreateShiftGroupsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutDayCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftGroupWithoutDayCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleCreateTimetableTemplatesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableTemplateCreatePositionEntityRelationInput"] | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_TimetableTemplateCreateVenueEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateCreatePositionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"] | undefined
};
	["Contember_TimetableShiftPositionUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	timetableTemplates?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined
};
	["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput"]> | undefined,
	isDynamic?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftPositionCreateShiftGroupsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutPositionCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftGroupWithoutPositionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupCreateShiftsEntityRelationInput"]> | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueWithoutShiftGroupCreateInput"] | undefined
};
	["Contember_EmployeeQueueWithoutShiftGroupCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_EmployeeQueueCreateItemsEntityRelationInput"]> | undefined,
	contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutQueueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_EmployeeQueueItemWithoutQueueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	start?: string | undefined,
	end?: string | undefined,
	shiftsProfile?: GraphQLTypes["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutQueueItemsCreateInput"] | undefined
};
	["Contember_ShiftsProfileWithoutQueueItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftWithoutShiftsProfileCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftWithoutShiftsProfileCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftCreateGroupEntityRelationInput"] | undefined,
	queueItem?: GraphQLTypes["Contember_TimetableShiftCreateQueueItemEntityRelationInput"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftCreateGroupEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutShiftsCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupWithoutShiftsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupCreateDayEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupCreateTypeEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupCreateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupCreatePositionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"] | undefined
};
	["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	isDynamic?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftPositionCreateTimetableTemplatesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateWithoutPositionCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableTemplateWithoutPositionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_TimetableTemplateCreateVenueEntityRelationInput"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	startTime?: string | undefined,
	endTime?: string | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateStaffOptionsCreateAutoAssignedEmployeeEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileCreateInput"] | undefined
};
	["Contember_ShiftsProfileCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileCreateQueueItemsEntityRelationInput"]> | undefined,
	staff?: GraphQLTypes["Contember_ShiftsProfileCreateStaffEntityRelationInput"] | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileCreateRolesEntityRelationInput"]> | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileCreateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutTimetableTemplatesCreateInput"] | undefined
};
	["Contember_VenueWithoutTimetableTemplatesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateStaffMembersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutVenuesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_StaffWithoutVenuesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreatePhotoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_StaffCreateCourierManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CourierManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CourierManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_CourierManagerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreatePickerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PickerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PickerWithoutStaffCreateInput"] | undefined
};
	["Contember_PickerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateRemunerationProfileEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_RemunerationProfileUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_RemunerationProfileWithoutStaffCreateInput"] | undefined
};
	["Contember_RemunerationProfileWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	employmentInformation?: Array<GraphQLTypes["Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput"]> | undefined,
	workMonths?: GraphQLTypes["Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_RemunerationProfileCreateEmploymentInformationEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	hourlyRate?: number | undefined,
	monthlySalary?: number | undefined,
	type?: GraphQLTypes["Contember_EmploymentType"] | undefined,
	name?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_RemunerationProfileCreateWorkMonthsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_WorkMonthUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_WorkMonthWithoutRemunerationProfileCreateInput"] | undefined
};
	["Contember_WorkMonthUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_RemunerationProfileUniqueWhere"] | undefined
};
	["Contember_WorkMonthWithoutRemunerationProfileCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	year?: number | undefined,
	month?: number | undefined,
	hoursWorked?: number | undefined,
	billableHours?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateCashierEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CashierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CashierWithoutStaffCreateInput"] | undefined
};
	["Contember_CashierWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateInternalBuyerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InternalBuyerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_InternalBuyerWithoutStaffCreateInput"] | undefined
};
	["Contember_InternalBuyerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateSalesManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SalesManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SalesManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_SalesManagerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	countries?: Array<GraphQLTypes["Contember_SalesManagerCreateCountriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SalesManagerCreateCountriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_StaffCreateCashierManagerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CashierManagerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CashierManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_CashierManagerWithoutStaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateEmployeeRolesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueEmployeeRoleWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueEmployeeRoleWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	employeePrivileges?: GraphQLTypes["Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput"] | undefined,
	role?: GraphQLTypes["Contember_VenueEmployeeRoleCreateRoleEntityRelationInput"] | undefined,
	getsTipsShare?: boolean | undefined,
	notesEnabled?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueEmployeeRoleCreateEmployeePrivilegesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"] | undefined
};
	["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	read?: boolean | undefined,
	unassign?: boolean | undefined,
	assign?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueEmployeeRoleCreateRoleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined
};
	["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"] | undefined
};
	["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	attempts?: Array<GraphQLTypes["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_IdentificationCodeCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationAttemptWithoutCodeCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_IdentificationAttemptWithoutCodeCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	user?: GraphQLTypes["Contember_IdentificationAttemptCreateUserEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationAttemptCreateUserEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutIdentificationAttemptsCreateInput"] | undefined
};
	["Contember_UserWithoutIdentificationAttemptsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined,
	roles?: GraphQLTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserCreateDevicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UserDeviceWithoutUserCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_UserDeviceWithoutUserCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	deviceId?: string | undefined,
	notificationsEnabled?: boolean | undefined,
	appVersion?: string | undefined,
	osVersion?: string | undefined,
	apiVersion?: string | undefined,
	anonymousSession?: GraphQLTypes["Contember_UserDeviceCreateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserDeviceCreateAnonymousSessionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AnonymousSessionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AnonymousSessionWithoutDevicesCreateInput"] | undefined
};
	["Contember_AnonymousSessionWithoutDevicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Contember_AnonymousSessionCreateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AnonymousSessionCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutAnonymousSessionCreateInput"] | undefined
};
	["Contember_CustomerWithoutAnonymousSessionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateInvoicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_InvoiceWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	orderPayments?: Array<GraphQLTypes["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]> | undefined,
	address?: GraphQLTypes["Contember_InvoiceCreateAddressEntityRelationInput"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_InvoiceCreateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderPaymentWithoutInvoiceCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderPaymentWithoutInvoiceCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents?: number | undefined,
	method?: GraphQLTypes["Contember_OrderPaymentCreateMethodEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderPaymentCreateEventsEntityRelationInput"]> | undefined,
	order?: GraphQLTypes["Contember_OrderPaymentCreateOrderEntityRelationInput"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderPaymentCreateVatRateEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentCreateMetaEntityRelationInput"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderPaymentCreateMethodEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodCreateInput"] | undefined
};
	["Contember_OrderPaymentCreateEventsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderEventWithoutPaymentCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderEventWithoutPaymentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: string | undefined,
	data?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_OrderEventCreateOrderEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderEventCreateDeliveryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderEventCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutEventsCreateInput"] | undefined
};
	["Contember_OrderWithoutEventsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateDeliveryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutOrderCreateInput"] | undefined
};
	["Contember_OrderDeliveryWithoutOrderCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderDeliveryCreateEventsEntityRelationInput"]> | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Contember_OrderDeliveryCreateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryWaypointWithoutDeliveryCreateInput"] | undefined
};
	["Contember_DeliveryWaypointWithoutDeliveryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	route?: GraphQLTypes["Contember_DeliveryWaypointCreateRouteEntityRelationInput"] | undefined,
	sortingOrder?: number | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryWaypointCreateRouteEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRouteWithoutWaypointsCreateInput"] | undefined
};
	["Contember_DeliveryRouteWithoutWaypointsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	courier?: GraphQLTypes["Contember_DeliveryRouteCreateCourierEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	dispatchAt?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRouteCreateCourierEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CourierWithoutRoutesCreateInput"] | undefined
};
	["Contember_CourierWithoutRoutesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	staff?: GraphQLTypes["Contember_CourierCreateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_CourierCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	showInPicker?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CourierCreateDeliveryMethodsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutDeliveryMethodsCreateInput"] | undefined
};
	["Contember_LocaleWithoutDeliveryMethodsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateProductPackingsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductPackingLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["Contember_ProductPackingLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductPackingUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingWithoutLocalesCreateInput"] | undefined
};
	["Contember_ProductPackingWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	image?: GraphQLTypes["Contember_ProductPackingCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Contember_ProductPackingCreateIconEntityRelationInput"] | undefined,
	shortName?: string | undefined,
	preparedPackingQuantities?: Array<GraphQLTypes["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"]> | undefined,
	isPackedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingCreatePreparedPackingQuantitiesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PreparedPackingQuantityWithoutPackingCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PreparedPackingQuantityWithoutPackingCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	recipe?: GraphQLTypes["Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput"] | undefined,
	packedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PreparedPackingQuantityCreateRecipeEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeCreateInput"] | undefined
};
	["Contember_ProductRecipeCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductRecipeCreateProductsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductRecipeCreateImageEntityRelationInput"] | undefined,
	icon?: GraphQLTypes["Contember_ProductRecipeCreateIconEntityRelationInput"] | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductRecipeCreateLocalesEntityRelationInput"]> | undefined,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutRecipeCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductWithoutRecipeCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductLocaleCreateLocaleEntityRelationInput"] | undefined,
	featureList?: GraphQLTypes["Contember_ProductLocaleCreateFeatureListEntityRelationInput"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductsCreateInput"] | undefined
};
	["Contember_LocaleWithoutProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateProductRecipesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductRecipeLocaleCreateRootEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeWithoutLocalesCreateInput"] | undefined
};
	["Contember_ProductRecipeWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductRecipeCreateProductsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductRecipeCreateImageEntityRelationInput"] | undefined,
	icon?: GraphQLTypes["Contember_ProductRecipeCreateIconEntityRelationInput"] | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductRecipeCreateIngredientsEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeCreateFreshingContainerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FreshingContainerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined
};
	["Contember_ProductRecipeCreateNutritionFactsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_NutritionFactsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_NutritionFactsCreateInput"] | undefined
};
	["Contember_NutritionFactsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	energyKiloJoule?: number | undefined,
	energyKiloCalorie?: number | undefined,
	fatsGrams?: number | undefined,
	fatsOfWhichSaturatedGrams?: number | undefined,
	carbohydrateGrams?: number | undefined,
	carbohydrateOfWhichSugarsGrams?: number | undefined,
	proteinGrams?: number | undefined,
	fibreGrams?: number | undefined,
	saltGrams?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	slug?: string | undefined,
	products?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	product?: GraphQLTypes["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"] | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_ProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateCustomerPricesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCreateVirtualProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductWithoutProductCreateInput"] | undefined
};
	["Contember_VirtualProductWithoutProductCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	recipientEmail?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductMetadataUniqueWhere"] | undefined
};
	["Contember_ProductCreatePageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PageCreateInput"] | undefined
};
	["Contember_PageCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locales?: Array<GraphQLTypes["Contember_PageCreateLocalesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PageLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PageLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	link?: GraphQLTypes["Contember_PageLocaleCreateLinkEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_PageLocaleCreateLocaleEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_PageLocaleCreateSeoEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_PageLocaleCreateContentEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_PageLocaleCreateCoverEntityRelationInput"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleCreateSeoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SeoCreateInput"] | undefined
};
	["Contember_SeoCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Contember_SeoCreateOgImageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeoCreateOgImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_PageLocaleCreateContentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined
};
	["Contember_PageLocaleCreateCoverEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CoverCreateInput"] | undefined
};
	["Contember_ProductCreateSalesDaysEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCreateSalesWeeksEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCreateSalesMonthsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutProductsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteWithoutProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	note?: string | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutFulfillmentNotesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TagWithoutFulfillmentNotesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagCreateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagCreateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagCreateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagCreateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagCreateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagCreatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagCreateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutFulfillmentNotesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueWithoutFulfillmentNotesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableTemplateWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableTemplateCreatePositionEntityRelationInput"] | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsProfilesEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleCreateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleTerminalWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	applicationDevices?: Array<GraphQLTypes["Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput"]> | undefined,
	ipAddress?: string | undefined,
	port?: number | undefined,
	type?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleTerminalCreateApplicationDevicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"] | undefined
};
	["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSalePrinterWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesReceipt?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]> | undefined,
	applicationDevicesTicket?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]> | undefined,
	ticketCategories?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeviceIdentifierWithoutDeviceCreateInput"] | undefined
};
	["Contember_DeviceIdentifierWithoutDeviceCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	updatedByUser?: GraphQLTypes["Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput"] | undefined,
	identifier?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeviceIdentifierCreateUpdatedByUserEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UserCreateInput"] | undefined
};
	["Contember_UserCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserCreateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserMetadataUniqueWhere"] | undefined
};
	["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationAttemptWithoutUserCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_IdentificationAttemptWithoutUserCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	code?: GraphQLTypes["Contember_IdentificationAttemptCreateCodeEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationAttemptCreateCodeEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeWithoutAttemptsCreateInput"] | undefined
};
	["Contember_IdentificationCodeWithoutAttemptsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	locales?: Array<GraphQLTypes["Contember_IdentificationCodeCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	applicationDevices?: Array<GraphQLTypes["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_IdentificationCodeLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	successMessage?: string | undefined,
	locale?: GraphQLTypes["Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutIdentificationCodesCreateInput"] | undefined
};
	["Contember_LocaleWithoutIdentificationCodesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["Contember_ProductLocaleCreateRootEntityRelationInput"] | undefined,
	featureList?: GraphQLTypes["Contember_ProductLocaleCreateFeatureListEntityRelationInput"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutLocalesCreateInput"] | undefined
};
	["Contember_ProductWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductCreateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductLocaleCreateFeatureListEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput"] | undefined,
	product?: GraphQLTypes["Contember_PointOfSaleCategoryProductCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductCreateCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutProductsCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryWithoutProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	slug?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	locale?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"] | undefined
};
	["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	root?: GraphQLTypes["Contember_DeliveryMethodLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutLocalesCreateInput"] | undefined
};
	["Contember_DeliveryMethodWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodCreatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodCreateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodCreateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodCreateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodCreateTimelinePresetEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryTimelinePresetUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"] | undefined
};
	["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_IngredientSupplierLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IngredientSupplierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierWithoutLocalesCreateInput"] | undefined
};
	["Contember_IngredientSupplierWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	internalNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	successMessage?: string | undefined,
	root?: GraphQLTypes["Contember_IdentificationCodeLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeWithoutLocalesCreateInput"] | undefined
};
	["Contember_IdentificationCodeWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	attempts?: Array<GraphQLTypes["Contember_IdentificationCodeCreateAttemptsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	applicationDevices?: Array<GraphQLTypes["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeCreateApplicationDevicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"] | undefined
};
	["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleTerminalCreateVenueEntityRelationInput"] | undefined,
	ipAddress?: string | undefined,
	port?: number | undefined,
	type?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleTerminalCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"] | undefined
};
	["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateOrdersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutOrderCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderItemWithoutOrderCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined,
	virtualProductEffects?: Array<GraphQLTypes["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]> | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	product?: GraphQLTypes["Contember_OrderItemCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductEffectWithoutOrderItemCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VirtualProductEffectWithoutOrderItemCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"] | undefined,
	resultVouchers?: Array<GraphQLTypes["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"] | undefined
};
	["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	amountCents?: number | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditTransactionCreateCustomerCreditEntityRelationInput"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"] | undefined
};
	["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	discountCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDiscountCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDiscountCreateOrderEntityRelationInput"] | undefined,
	description?: string | undefined,
	type?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDiscountCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutDiscountsCreateInput"] | undefined
};
	["Contember_OrderWithoutDiscountsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateCartEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutOrderCreateInput"] | undefined
};
	["Contember_CartWithoutOrderCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CartCreateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["Contember_CartCreateChannelEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CartCreateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartCreateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartCreateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartCreateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartCreatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartCreateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartCreatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartCreateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutCartsCreateInput"] | undefined
};
	["Contember_CustomerWithoutCartsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateInvoicingPlanEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InvoicingPlanUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_InvoicingPlanWithoutCustomerCreateInput"] | undefined
};
	["Contember_InvoicingPlanWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	preset?: GraphQLTypes["Contember_InvoicingPlanCreatePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoicingPlanCreatePresetEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InvoicingPresetUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"] | undefined
};
	["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	startsAt?: GraphQLTypes["DateTime"] | undefined,
	endsAt?: GraphQLTypes["DateTime"] | undefined,
	intervalOfDays?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BillingSubjectWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectCreateCountryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined
};
	["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutBillingSubjectCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_InvoiceWithoutBillingSubjectCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_InvoiceCreateCustomerEntityRelationInput"] | undefined,
	orderPayments?: Array<GraphQLTypes["Contember_InvoiceCreateOrderPaymentsEntityRelationInput"]> | undefined,
	address?: GraphQLTypes["Contember_InvoiceCreateAddressEntityRelationInput"] | undefined,
	publicUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceCreateAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutInvoicesCreateInput"] | undefined
};
	["Contember_AddressWithoutInvoicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutBillingAddressCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderWithoutBillingAddressCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateEventsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderEventWithoutOrderCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderEventWithoutOrderCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: string | undefined,
	data?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	payment?: GraphQLTypes["Contember_OrderEventCreatePaymentEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderEventCreateDeliveryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderEventCreatePaymentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderPaymentWithoutEventsCreateInput"] | undefined
};
	["Contember_OrderPaymentWithoutEventsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents?: number | undefined,
	method?: GraphQLTypes["Contember_OrderPaymentCreateMethodEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_OrderPaymentCreateOrderEntityRelationInput"] | undefined,
	invoice?: GraphQLTypes["Contember_OrderPaymentCreateInvoiceEntityRelationInput"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderPaymentCreateVatRateEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentCreateMetaEntityRelationInput"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderPaymentCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutPaymentsCreateInput"] | undefined
};
	["Contember_OrderWithoutPaymentsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateBillingAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined
};
	["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutAddressCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderDeliveryWithoutAddressCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDeliveryCreateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderDeliveryCreateEventsEntityRelationInput"]> | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryCreateOrderEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutDeliveryCreateInput"] | undefined
};
	["Contember_OrderWithoutDeliveryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreatePriceLinesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderCreatePaymentsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderPaymentWithoutOrderCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderPaymentWithoutOrderCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	paymentCents?: number | undefined,
	method?: GraphQLTypes["Contember_OrderPaymentCreateMethodEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderPaymentCreateEventsEntityRelationInput"]> | undefined,
	invoice?: GraphQLTypes["Contember_OrderPaymentCreateInvoiceEntityRelationInput"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderPaymentCreateVatRateEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentCreateMetaEntityRelationInput"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderPaymentCreateInvoiceEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutOrderPaymentsCreateInput"] | undefined
};
	["Contember_InvoiceWithoutOrderPaymentsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_InvoiceCreateCustomerEntityRelationInput"] | undefined,
	address?: GraphQLTypes["Contember_InvoiceCreateAddressEntityRelationInput"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_InvoiceCreateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceCreateBillingSubjectEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutInvoicesCreateInput"] | undefined
};
	["Contember_BillingSubjectWithoutInvoicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutBillingSubjectsCreateInput"] | undefined
};
	["Contember_CustomerWithoutBillingSubjectsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateOfferedProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined
};
	["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressCreateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"] | undefined
};
	["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectCreateOrdersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutBillingSubjectCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderWithoutBillingSubjectCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateSummaryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderSummaryUniqueWhere"] | undefined
};
	["Contember_OrderCreateGratuityEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_GratuityUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_GratuityWithoutOrderCreateInput"] | undefined
};
	["Contember_GratuityWithoutOrderCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	amountCents?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutOrdersCreateInput"] | undefined
};
	["Contember_VenueWithoutOrdersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutVenuesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteWithoutVenuesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CartItemWithoutFulfillmentNotesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CartItemWithoutFulfillmentNotesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	quantity?: number | undefined,
	cart?: GraphQLTypes["Contember_CartItemCreateCartEntityRelationInput"] | undefined,
	product?: GraphQLTypes["Contember_CartItemCreateProductEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutFulfillmentNotesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderItemWithoutFulfillmentNotesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderItemCreateOrderEntityRelationInput"] | undefined,
	virtualProductEffects?: Array<GraphQLTypes["Contember_OrderItemCreateVirtualProductEffectsEntityRelationInput"]> | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	product?: GraphQLTypes["Contember_OrderItemCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutOrderItemsCreateInput"] | undefined
};
	["Contember_ProductWithoutOrderItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductCreatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductCreateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCreateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductCreateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductCreateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductCreateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductCreateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductCreateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductCreateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductCreateManagerEntityRelationInput"] | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductCreateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductCreateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCreateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductCreateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductCreateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductCreateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductCreatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductCreateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductCreateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductCreateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutFulfillmentNotesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderWithoutFulfillmentNotesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderCreateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderCreateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderCreateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderCreateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderCreateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderCreateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderCreateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderCreateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderCreatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderCreatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderCreateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderCreateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderCreateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderCreateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateBillingSubjectEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutOrdersCreateInput"] | undefined
};
	["Contember_BillingSubjectWithoutOrdersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"] | undefined
};
	["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateAnonymousSessionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AnonymousSessionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AnonymousSessionWithoutCustomerCreateInput"] | undefined
};
	["Contember_AnonymousSessionWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	devices?: Array<GraphQLTypes["Contember_AnonymousSessionCreateDevicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AnonymousSessionCreateDevicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UserDeviceWithoutAnonymousSessionCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_UserDeviceWithoutAnonymousSessionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	deviceId?: string | undefined,
	notificationsEnabled?: boolean | undefined,
	user?: GraphQLTypes["Contember_UserDeviceCreateUserEntityRelationInput"] | undefined,
	appVersion?: string | undefined,
	osVersion?: string | undefined,
	apiVersion?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserDeviceCreateUserEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutDevicesCreateInput"] | undefined
};
	["Contember_UserWithoutDevicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserCreateStaffEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_UserCreateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserCreateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserCreateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserCreateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserCreateRolesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserRolesUniqueWhere"] | undefined
};
	["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutBuyerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VoucherWithoutBuyerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	owner?: GraphQLTypes["Contember_VoucherCreateOwnerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherCreateRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherCreateOwnerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutOwnsVouchersCreateInput"] | undefined
};
	["Contember_CustomerWithoutOwnsVouchersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VoucherRedemptionWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"] | undefined
};
	["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	initialCreditCents?: number | undefined,
	remainingCreditsCents?: number | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Contember_CustomerCreditCreateCustomerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_CustomerCreditCreateVatRateEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	transactions?: Array<GraphQLTypes["Contember_CustomerCreditCreateTransactionsEntityRelationInput"]> | undefined,
	invoiceUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditCreateTransactionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	amountCents?: number | undefined,
	orderDiscount?: GraphQLTypes["Contember_CustomerCreditTransactionCreateOrderDiscountEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditTransactionCreateVirtualProductEffectEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"] | undefined
};
	["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultVouchers?: Array<GraphQLTypes["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"] | undefined
};
	["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderItemCreateOrderEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	product?: GraphQLTypes["Contember_OrderItemCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemCreateFulfillmentNotesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VirtualProductEffectCreateResultVouchersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutVirtualProductEffectCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VoucherWithoutVirtualProductEffectCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_VoucherCreateBuyerEntityRelationInput"] | undefined,
	owner?: GraphQLTypes["Contember_VoucherCreateOwnerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherCreateRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherCreateBuyerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutBoughtVouchersCreateInput"] | undefined
};
	["Contember_CustomerWithoutBoughtVouchersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutOwnerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VoucherWithoutOwnerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_VoucherCreateBuyerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherCreateRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"] | undefined
};
	["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["Contember_VirtualProductEffectCreateOrderItemEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_VirtualProductEffectCreateResultCustomerCreditTransactionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherCreateRedemptionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutVoucherCreateInput"] | undefined
};
	["Contember_VoucherRedemptionWithoutVoucherCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"] | undefined
};
	["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	discountCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDiscountCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDiscountCreateOrderEntityRelationInput"] | undefined,
	description?: string | undefined,
	type?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	customerCreditTransactions?: Array<GraphQLTypes["Contember_OrderDiscountCreateCustomerCreditTransactionsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutRedeemedVouchersCreateInput"] | undefined
};
	["Contember_CustomerWithoutRedeemedVouchersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateSpendingEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerSpendingUniqueWhere"] | undefined
};
	["Contember_CustomerCreateCustomerTabsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerTabWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	color?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CustomerTabCreateItemsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabCreateMetaEntityRelationInput"] | undefined,
	table?: GraphQLTypes["Contember_CustomerTabCreateTableEntityRelationInput"] | undefined,
	seat?: GraphQLTypes["Contember_CustomerTabCreateSeatEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerTabCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabItemWithoutTabCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerTabItemWithoutTabCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addedAt?: GraphQLTypes["DateTime"] | undefined,
	product?: GraphQLTypes["Contember_CustomerTabItemCreateProductEntityRelationInput"] | undefined,
	paidAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerTabItemCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_CustomerTabCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined
};
	["Contember_CustomerTabCreateTableEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TableWithoutCustomerTabsCreateInput"] | undefined
};
	["Contember_TableWithoutCustomerTabsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shape?: GraphQLTypes["Contember_TableShape"] | undefined,
	width?: number | undefined,
	height?: number | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	area?: GraphQLTypes["Contember_TableCreateAreaEntityRelationInput"] | undefined,
	seats?: Array<GraphQLTypes["Contember_TableCreateSeatsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TableCreateAreaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AreaWithoutTablesCreateInput"] | undefined
};
	["Contember_AreaWithoutTablesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	name?: string | undefined,
	venue?: GraphQLTypes["Contember_AreaCreateVenueEntityRelationInput"] | undefined,
	lines?: Array<GraphQLTypes["Contember_AreaCreateLinesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AreaCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutAreasCreateInput"] | undefined
};
	["Contember_VenueWithoutAreasCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UnassignReasonWithoutVenuesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_UnassignReasonUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_UnassignReasonWithoutVenuesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	name?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	slug?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryCreateTicketPrintersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesReceipt?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]> | undefined,
	applicationDevicesTicket?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateVenueEntityRelationInput"] | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateTerminalEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceCreateReceiptPrinterEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"] | undefined
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesTicket?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesTicketEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"] | undefined,
	ticketCategories?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutPointOfSalePrintersCreateInput"] | undefined
};
	["Contember_VenueWithoutPointOfSalePrintersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateAreasEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AreaWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_AreaWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	name?: string | undefined,
	tables?: Array<GraphQLTypes["Contember_AreaCreateTablesEntityRelationInput"]> | undefined,
	lines?: Array<GraphQLTypes["Contember_AreaCreateLinesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AreaCreateTablesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TableWithoutAreaCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_TableWithoutAreaCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shape?: GraphQLTypes["Contember_TableShape"] | undefined,
	width?: number | undefined,
	height?: number | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_TableCreateCustomerTabsEntityRelationInput"]> | undefined,
	seats?: Array<GraphQLTypes["Contember_TableCreateSeatsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TableCreateCustomerTabsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabWithoutTableCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerTabWithoutTableCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	color?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CustomerTabCreateItemsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabCreateMetaEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerTabCreateCustomerEntityRelationInput"] | undefined,
	seat?: GraphQLTypes["Contember_CustomerTabCreateSeatEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerTabCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutCustomerTabsCreateInput"] | undefined
};
	["Contember_CustomerWithoutCustomerTabsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateLeadEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineLeadWithoutCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineLeadWithoutCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	stageItem?: GraphQLTypes["Contember_PipelineLeadCreateStageItemEntityRelationInput"] | undefined,
	contactPerson?: string | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine?: boolean | undefined,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	form?: GraphQLTypes["Contember_PipelineLeadCreateFormEntityRelationInput"] | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineLeadCreateStageItemEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageItemWithoutLeadCreateInput"] | undefined
};
	["Contember_PipelineStageItemWithoutLeadCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	list?: GraphQLTypes["Contember_PipelineStageItemCreateListEntityRelationInput"] | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageItemCreateListEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageWithoutItemsCreateInput"] | undefined
};
	["Contember_PipelineStageWithoutItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	pipeline?: GraphQLTypes["Contember_PipelineStageCreatePipelineEntityRelationInput"] | undefined,
	order?: number | undefined,
	locales?: Array<GraphQLTypes["Contember_PipelineStageCreateLocalesEntityRelationInput"]> | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageCreateSentimentEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	forms?: Array<GraphQLTypes["Contember_PipelineStageCreateFormsEntityRelationInput"]> | undefined,
	showItemsWeight?: boolean | undefined,
	showItemsPrice?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageCreatePipelineEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineWithoutStagesCreateInput"] | undefined
};
	["Contember_PipelineUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	stages?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined
};
	["Contember_PipelineWithoutStagesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineStageLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_PipelineStageLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPipelineStagesCreateInput"] | undefined
};
	["Contember_LocaleWithoutPipelineStagesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SubscriptionBoxLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SubscriptionBoxWithoutLocalesCreateInput"] | undefined
};
	["Contember_SubscriptionBoxWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SubscriptionBoxLocaleCreateTextsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_SubscriptionBoxLocaleCreateSecondaryTextsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_LocaleCreateBusinessServicesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	labelNote?: string | undefined,
	root?: GraphQLTypes["Contember_BusinessServiceLocaleCreateRootEntityRelationInput"] | undefined,
	labelNumberOfPeople?: string | undefined,
	labelInvoiced?: string | undefined,
	labelEquipment?: string | undefined,
	labelMillTamDem?: string | undefined,
	labelSOEspresso?: string | undefined,
	labelFilterCoffeeInMenu?: string | undefined,
	labelWaterMeterStatus?: string | undefined,
	labelServiceAction?: string | undefined,
	labelInvoiceIdentifier?: string | undefined,
	labelSupervisor?: string | undefined,
	labelContact?: string | undefined,
	labelPhone?: string | undefined,
	labelEmail?: string | undefined,
	labelGrindingStonesCondition?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessServiceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceWithoutLocalesCreateInput"] | undefined
};
	["Contember_BusinessServiceWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	requireNote?: number | undefined,
	requireNumberOfPeople?: number | undefined,
	requireInvoiced?: number | undefined,
	requireEquipment?: number | undefined,
	requireMillTamDem?: number | undefined,
	requireSOEspresso?: number | undefined,
	requireFilterCoffeeInMenu?: number | undefined,
	requireWaterMeterStatus?: number | undefined,
	requireServiceAction?: number | undefined,
	optionsEquipment?: string | undefined,
	optionsServiceAction?: string | undefined,
	requireInvoiceIdentifier?: number | undefined,
	requireSupervisor?: number | undefined,
	requireContact?: number | undefined,
	requirePhone?: number | undefined,
	requireEmail?: number | undefined,
	requireGrindingStonesCondition?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageCreateSentimentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageSentimentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageSentimentCreateInput"] | undefined
};
	["Contember_PipelineStageSentimentUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_PipelineStageSentimentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	backgroundColor?: string | undefined,
	textColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageCreateFormsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LeadFormWithoutStageCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_LeadFormWithoutStageCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	leads?: Array<GraphQLTypes["Contember_LeadFormCreateLeadsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LeadFormCreateLeadsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineLeadWithoutFormCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineLeadWithoutFormCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	stageItem?: GraphQLTypes["Contember_PipelineLeadCreateStageItemEntityRelationInput"] | undefined,
	contactPerson?: string | undefined,
	customer?: GraphQLTypes["Contember_PipelineLeadCreateCustomerEntityRelationInput"] | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine?: boolean | undefined,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineLeadCreateCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutLeadCreateInput"] | undefined
};
	["Contember_CustomerWithoutLeadCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerCreateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerMetadataUniqueWhere"] | undefined
};
	["Contember_PipelineLeadCreateFormEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LeadFormWithoutLeadsCreateInput"] | undefined
};
	["Contember_LeadFormWithoutLeadsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	code?: string | undefined,
	stage?: GraphQLTypes["Contember_LeadFormCreateStageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LeadFormCreateStageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageWithoutFormsCreateInput"] | undefined
};
	["Contember_PipelineStageWithoutFormsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_PipelineStageCreateItemsEntityRelationInput"]> | undefined,
	pipeline?: GraphQLTypes["Contember_PipelineStageCreatePipelineEntityRelationInput"] | undefined,
	order?: number | undefined,
	locales?: Array<GraphQLTypes["Contember_PipelineStageCreateLocalesEntityRelationInput"]> | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageCreateSentimentEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	showItemsWeight?: boolean | undefined,
	showItemsPrice?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineStageItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	lead?: GraphQLTypes["Contember_PipelineStageItemCreateLeadEntityRelationInput"] | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageItemCreateLeadEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineLeadWithoutStageItemCreateInput"] | undefined
};
	["Contember_PipelineLeadWithoutStageItemCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	contactPerson?: string | undefined,
	customer?: GraphQLTypes["Contember_PipelineLeadCreateCustomerEntityRelationInput"] | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine?: boolean | undefined,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	form?: GraphQLTypes["Contember_PipelineLeadCreateFormEntityRelationInput"] | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerTabCreateSeatEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SeatWithoutCustomerTabsCreateInput"] | undefined
};
	["Contember_SeatWithoutCustomerTabsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	table?: GraphQLTypes["Contember_SeatCreateTableEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeatCreateTableEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TableWithoutSeatsCreateInput"] | undefined
};
	["Contember_TableWithoutSeatsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	shape?: GraphQLTypes["Contember_TableShape"] | undefined,
	width?: number | undefined,
	height?: number | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_TableCreateCustomerTabsEntityRelationInput"]> | undefined,
	area?: GraphQLTypes["Contember_TableCreateAreaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TableCreateSeatsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SeatWithoutTableCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_SeatWithoutTableCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_SeatCreateCustomerTabsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeatCreateCustomerTabsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabWithoutSeatCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerTabWithoutSeatCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	color?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CustomerTabCreateItemsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabCreateMetaEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerTabCreateCustomerEntityRelationInput"] | undefined,
	table?: GraphQLTypes["Contember_CustomerTabCreateTableEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AreaCreateLinesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LineWithoutAreaCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_LineWithoutAreaCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	positionStartX?: number | undefined,
	positionStartY?: number | undefined,
	positionEndX?: number | undefined,
	positionEndY?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueCreateChannelsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutVenueCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelWithoutVenueCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelCreatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelCreateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelCreatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelCreateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelCreateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelCreateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelCreatePublicTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	slug?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateLocalesEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_PointOfSaleCategoryCreateProductsEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryCreateCategoryEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutCategoriesCreateInput"] | undefined
};
	["Contember_VenueWithoutCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutRedemptionCreateInput"] | undefined
};
	["Contember_VoucherWithoutRedemptionCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_VoucherCreateBuyerEntityRelationInput"] | undefined,
	owner?: GraphQLTypes["Contember_VoucherCreateOwnerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherCreateVatRateEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VoucherCreateVirtualProductEffectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderCreateFulfillmentNotesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrdersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteWithoutOrdersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderPaymentCreateVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderPaymentCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined
};
	["Contember_OrderDeliveryCreateEventsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderEventWithoutDeliveryCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderEventWithoutDeliveryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	type?: string | undefined,
	data?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_OrderEventCreateOrderEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_OrderEventCreatePaymentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LiftagoRideUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LiftagoRideWithoutDeliveryCreateInput"] | undefined
};
	["Contember_LiftagoRideWithoutDeliveryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	liftagoId?: string | undefined,
	data?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"] | undefined
};
	["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_CustomerCreateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerCreateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerCreateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerCreateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerCreateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerCreatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerCreateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerCreatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerCreateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerCreatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerCreateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerCreateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerCreateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerCreateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerCreateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerCreateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerCreateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerCreateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerCreateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerCreateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerCreateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerCreateOfferedProductsEntityRelationInput"]> | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerCreateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerCreateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerCreateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerCreateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerCreateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerCreateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerCreateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerCreateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerCreateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderEventCreateDeliveryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutEventsCreateInput"] | undefined
};
	["Contember_OrderDeliveryWithoutEventsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryCreateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDeliveryCreateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_OrderDeliveryCreateDeliveryWaypointEntityRelationInput"] | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryCreateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Contember_OrderDeliveryCreateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryCreateAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined
};
	["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressCreateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressCreateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressCreateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressCreateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressCreateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressCreateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressCreateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressCreateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreateDeliveryAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined
};
	["Contember_CartCreateBillingAddressEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined
};
	["Contember_CartCreateDeliveryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryCreateInput"] | undefined
};
	["Contember_ChannelDeliveryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	priceCents?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelDeliveryCreateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelDeliveryCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelDeliveryCreateVatRateEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreatePaymentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentCreateInput"] | undefined
};
	["Contember_ChannelPaymentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentCreateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentCreateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentCreateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentCreateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreateBillingSubjectEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectCreateInput"] | undefined
};
	["Contember_BillingSubjectCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectCreateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectCreateCustomerEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectCreateInvoicesEntityRelationInput"]> | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectCreateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectCreateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartCreatePriceLinesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartPriceLineUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CartCreateSummaryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartSummaryUniqueWhere"] | undefined
};
	["Contember_OrderDiscountCreateVoucherRedemptionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"] | undefined
};
	["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerCreditEntityRelationInput"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceCreateTicketPrintersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesReceipt?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateApplicationDevicesReceiptEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_PointOfSalePrinterCreateVenueEntityRelationInput"] | undefined,
	ticketCategories?: Array<GraphQLTypes["Contember_PointOfSalePrinterCreateTicketCategoriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleCreatePipelineStagesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageLocaleWithoutLocaleCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineStageLocaleWithoutLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	root?: GraphQLTypes["Contember_PipelineStageLocaleCreateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageLocaleCreateRootEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageWithoutLocalesCreateInput"] | undefined
};
	["Contember_PipelineStageWithoutLocalesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_PipelineStageCreateItemsEntityRelationInput"]> | undefined,
	pipeline?: GraphQLTypes["Contember_PipelineStageCreatePipelineEntityRelationInput"] | undefined,
	order?: number | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageCreateSentimentEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	forms?: Array<GraphQLTypes["Contember_PipelineStageCreateFormsEntityRelationInput"]> | undefined,
	showItemsWeight?: boolean | undefined,
	showItemsPrice?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	locale?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"] | undefined
};
	["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductRecipeLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductRecipesCreateInput"] | undefined
};
	["Contember_LocaleWithoutProductRecipesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupCreateGeneratedByTemplateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateCreateInput"] | undefined
};
	["Contember_TimetableTemplateCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableTemplateCreatePositionEntityRelationInput"] | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateCreateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_TimetableTemplateCreateVenueEntityRelationInput"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableTemplateCreateEmployeeRoleEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftCreateQueueItemEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftCreateInput"] | undefined
};
	["Contember_EmployeeQueueItemWithoutShiftCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueItemCreateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_EmployeeQueueItemCreateShiftsProfileEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemCreateUnassignInfoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined
};
	["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	hoursMissed?: number | undefined,
	legacyReason?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["Contember_UnassignedShiftInfoCreateReasonEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UnassignedShiftInfoCreateReasonEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_UnassignReasonCreateInput"] | undefined
};
	["Contember_UnassignReasonCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	name?: string | undefined,
	venues?: Array<GraphQLTypes["Contember_UnassignReasonCreateVenuesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UnassignReasonCreateVenuesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined
};
	["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"] | undefined
};
	["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	requested?: boolean | undefined,
	confirmed?: boolean | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftSwapInfoCreateSwappedShiftsProfileEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileCreateInput"] | undefined
};
	["Contember_EmployeeQueueItemCreateShiftEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftWithoutQueueItemCreateInput"] | undefined
};
	["Contember_TimetableShiftWithoutQueueItemCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftCreateGroupEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_TimetableShiftCreateShiftsProfileEntityRelationInput"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftCreateSwapInfoEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayCreateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined
};
	["Contember_DeliveryZoneCreateAddressesMetadataEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerCreditCreateVoucherRedemptionEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"] | undefined
};
	["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	orderDiscount?: GraphQLTypes["Contember_VoucherRedemptionCreateOrderDiscountEntityRelationInput"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherRedemptionCreateVoucherEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_VoucherRedemptionCreateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffCreateVenuesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutStaffMembersCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueWithoutStaffMembersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueCreateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerCreateLogoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_BusinessCustomerCreateBusinessServiceEventsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	businessService?: GraphQLTypes["Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput"] | undefined,
	filledNumberOfPeople?: number | undefined,
	filledInvoiced?: boolean | undefined,
	filledEquipment?: string | undefined,
	filledMillTamDem?: boolean | undefined,
	filledSOEspresso?: boolean | undefined,
	filledFilterCoffeeInMenu?: boolean | undefined,
	filledWaterMeterStatus?: string | undefined,
	filledServiceAction?: string | undefined,
	filledInvoiceIdentifier?: string | undefined,
	filledNote?: string | undefined,
	filledSupervisor?: GraphQLTypes["Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput"] | undefined,
	filledContact?: string | undefined,
	filledPhone?: boolean | undefined,
	filledEmail?: boolean | undefined,
	filledGrindingStonesCondition?: string | undefined,
	createdBy?: GraphQLTypes["Contember_BusinessServiceEventCreateCreatedByEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceEventCreateBusinessServiceEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessServiceUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceCreateInput"] | undefined
};
	["Contember_BusinessServiceCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	requireNote?: number | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessServiceCreateLocalesEntityRelationInput"]> | undefined,
	requireNumberOfPeople?: number | undefined,
	requireInvoiced?: number | undefined,
	requireEquipment?: number | undefined,
	requireMillTamDem?: number | undefined,
	requireSOEspresso?: number | undefined,
	requireFilterCoffeeInMenu?: number | undefined,
	requireWaterMeterStatus?: number | undefined,
	requireServiceAction?: number | undefined,
	optionsEquipment?: string | undefined,
	optionsServiceAction?: string | undefined,
	requireInvoiceIdentifier?: number | undefined,
	requireSupervisor?: number | undefined,
	requireContact?: number | undefined,
	requirePhone?: number | undefined,
	requireEmail?: number | undefined,
	requireGrindingStonesCondition?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessServiceLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	labelNote?: string | undefined,
	locale?: GraphQLTypes["Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput"] | undefined,
	labelNumberOfPeople?: string | undefined,
	labelInvoiced?: string | undefined,
	labelEquipment?: string | undefined,
	labelMillTamDem?: string | undefined,
	labelSOEspresso?: string | undefined,
	labelFilterCoffeeInMenu?: string | undefined,
	labelWaterMeterStatus?: string | undefined,
	labelServiceAction?: string | undefined,
	labelInvoiceIdentifier?: string | undefined,
	labelSupervisor?: string | undefined,
	labelContact?: string | undefined,
	labelPhone?: string | undefined,
	labelEmail?: string | undefined,
	labelGrindingStonesCondition?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutBusinessServicesCreateInput"] | undefined
};
	["Contember_LocaleWithoutBusinessServicesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceEventCreateFilledSupervisorEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffCreateInput"] | undefined
};
	["Contember_StaffCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	bartender?: GraphQLTypes["Contember_StaffCreateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffCreateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffCreateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffCreateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffCreateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffCreateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffCreateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffCreateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffCreatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffCreateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffCreatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffCreateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffCreateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffCreateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffCreateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffCreateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffCreateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceEventCreateCreatedByEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_StaffCreateInput"] | undefined
};
	["Contember_BusinessCustomerCreateDoubleshotCoffeeEquipmentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentCreateInput"] | undefined
};
	["Contember_DoubleshotCoffeeEquipmentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	espressoMachine?: string | undefined,
	espressoGrinder?: string | undefined,
	batchBrew?: string | undefined,
	batchGrinder?: string | undefined,
	waterFilter?: string | undefined,
	mechanicalFilter?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayCreateVenueEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutTimetableDaysCreateInput"] | undefined
};
	["Contember_VenueWithoutTimetableDaysCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueCreateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueCreateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueCreateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueCreateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueCreatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueCreatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueCreateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueCreateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueCreateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueCreateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueCreateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueCreateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WorkspaceCreateDiscountVatRateEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_ProductIngredientCreateImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductIngredientCreateSuppliersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IngredientSupplierListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierListCreateInput"] | undefined
};
	["Contember_IngredientSupplierListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_IngredientSupplierListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_IngredientSupplierItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	supplier?: GraphQLTypes["Contember_IngredientSupplierItemCreateSupplierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierItemCreateSupplierEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IngredientSupplierUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierCreateInput"] | undefined
};
	["Contember_IngredientSupplierCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	internalName?: string | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_IngredientSupplierCreateLocalesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_IngredientSupplierLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutIngredientSuppliersCreateInput"] | undefined
};
	["Contember_LocaleWithoutIngredientSuppliersCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleCreateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductListWithoutBusinessCategoryCreateInput"] | undefined
};
	["Contember_ProductListWithoutBusinessCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ProductListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductListItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductListItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductListItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	product?: GraphQLTypes["Contember_ProductListItemCreateProductEntityRelationInput"] | undefined,
	defaultQuantityForOrder?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductListItemCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"] | undefined
};
	["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ProductCategoryListCreateItemsEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCategoryListCreateLocalesEntityRelationInput"]> | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListCreateLocalesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryListLocaleWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListLocaleCreateLocaleEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductCategoryListsCreateInput"] | undefined
};
	["Contember_LocaleWithoutProductCategoryListsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	businessCategories?: Array<GraphQLTypes["Contember_LocaleCreateBusinessCategoriesEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleCreateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleCreateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleCreateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleCreateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleCreateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleCreatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleCreateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleCreateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleCreateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleCreateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleCreateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleCreatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleCreateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleCreateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleCreateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleCreatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleCreateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleCreateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListCreateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"] | undefined
};
	["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"] | undefined
};
	["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ProductCategoryListCreateItemsEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCategoryListCreateLocalesEntityRelationInput"]> | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListCreateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"] | undefined
};
	["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkableCreateRedirectEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_RedirectUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_RedirectWithoutLinkCreateInput"] | undefined
};
	["Contember_RedirectWithoutLinkCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	target?: GraphQLTypes["Contember_RedirectCreateTargetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_RedirectCreateTargetEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_WikiPageCreateContentEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined
};
	["Contember_WikiPageCreateChildrenEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_WikiPageWithoutParentCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_WikiPageWithoutParentCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Contember_WikiPageCreateLinkEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_WikiPageCreateChildrenEntityRelationInput"]> | undefined,
	content?: GraphQLTypes["Contember_WikiPageCreateContentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleCreateSeoEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SeoCreateInput"] | undefined
};
	["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ContentReferenceCreateBlogPostsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogPostListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostListCreateInput"] | undefined
};
	["Contember_BlogPostListUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"] | undefined
};
	["Contember_BlogPostListItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_BlogPostListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_BlogPostListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostListItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_BlogPostListItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	caption?: string | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostListItemCreateBlogPostEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostListItemCreateBlogPostEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostLocaleCreateInput"] | undefined
};
	["Contember_BlogPostLocaleCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPostLocaleCreateRootEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_BlogPostLocaleCreateLocaleEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_BlogPostLocaleCreateCoverEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_BlogPostLocaleCreateContentEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_BlogPostLocaleCreateLinkEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_BlogPostLocaleCreateSeoEntityRelationInput"] | undefined,
	products?: Array<GraphQLTypes["Contember_BlogPostLocaleCreateProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkableWithoutBlogPostCreateInput"] | undefined
};
	["Contember_LinkableWithoutBlogPostCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	url?: string | undefined,
	page?: GraphQLTypes["Contember_LinkableCreatePageEntityRelationInput"] | undefined,
	wikiPage?: GraphQLTypes["Contember_LinkableCreateWikiPageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableCreateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentReferenceCreateLinksEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductListCreateInput"] | undefined
};
	["Contember_ProductListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ProductListCreateItemsEntityRelationInput"]> | undefined,
	businessCategory?: GraphQLTypes["Contember_ProductListCreateBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductListCreateBusinessCategoryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"] | undefined
};
	["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryCreateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryCreateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryCreateRecurrencesEntityRelationInput"]> | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryCreateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentReferenceCreateHeroEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_HeroUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_HeroCreateInput"] | undefined
};
	["Contember_HeroUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_HeroCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Contember_HeroCreateMediumEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_HeroCreateMediumEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateGalleryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_GalleryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_GalleryCreateInput"] | undefined
};
	["Contember_GalleryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_GalleryItemUniqueWhere"] | undefined
};
	["Contember_GalleryItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined
};
	["Contember_GalleryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_GalleryCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GalleryCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_GalleryItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_GalleryItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_GalleryItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	caption?: string | undefined,
	medium?: GraphQLTypes["Contember_GalleryItemCreateMediumEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GalleryItemCreateMediumEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateSocialsAndAppsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_SocialsAndAppsCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateProductGridEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductGridUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductGridCreateInput"] | undefined
};
	["Contember_ProductGridUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	categories?: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined
};
	["Contember_ProductGridCategoryUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined,
	images?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined
};
	["Contember_ProductGridCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	isCarousel?: boolean | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductGridCreateCategoriesEntityRelationInput"]> | undefined,
	type?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGridCreateCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductGridCategoryWithoutRootCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductGridCategoryWithoutRootCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	title?: string | undefined,
	items?: Array<GraphQLTypes["Contember_ProductGridCategoryCreateItemsEntityRelationInput"]> | undefined,
	images?: GraphQLTypes["Contember_ProductGridCategoryCreateImagesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGridCategoryCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductGridItemWithoutCategoryCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductGridItemWithoutCategoryCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Contember_ProductGridItemCreateProductEntityRelationInput"] | undefined,
	medium?: GraphQLTypes["Contember_ProductGridItemCreateMediumEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_ProductGridItemCreateLinkEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGridItemCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_ProductGridItemCreateMediumEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_ProductGridItemCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_ProductGridCategoryCreateImagesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageListCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateProductBannersEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductBannerListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductBannerListCreateInput"] | undefined
};
	["Contember_ProductBannerListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_ProductBannerListCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductBannerListCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductBannerItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductBannerItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	banner?: GraphQLTypes["Contember_ProductBannerItemCreateBannerEntityRelationInput"] | undefined,
	size?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductBannerItemCreateBannerEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductBannerUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductBannerCreateInput"] | undefined
};
	["Contember_ProductBannerUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	button?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined
};
	["Contember_ProductBannerCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Contember_ProductBannerCreateButtonEntityRelationInput"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_ProductBannerCreateBackgroundImageEntityRelationInput"] | undefined,
	productImage?: GraphQLTypes["Contember_ProductBannerCreateProductImageEntityRelationInput"] | undefined,
	product?: GraphQLTypes["Contember_ProductBannerCreateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductBannerCreateButtonEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_ProductBannerCreateBackgroundImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductBannerCreateProductImageEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductBannerCreateProductEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateDeliveryRegionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRegionsCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateTextListEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_ContentReferenceCreateGridTilesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_GridTilesUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_GridTilesCreateInput"] | undefined
};
	["Contember_GridTilesUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: GraphQLTypes["Contember_GridTileItemUniqueWhere"] | undefined
};
	["Contember_GridTileItemUniqueWhere"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	link?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined
};
	["Contember_GridTilesCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	items?: Array<GraphQLTypes["Contember_GridTilesCreateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GridTilesCreateItemsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_GridTileItemUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_GridTileItemWithoutListCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_GridTileItemWithoutListCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	order?: number | undefined,
	text?: string | undefined,
	medium?: GraphQLTypes["Contember_GridTileItemCreateMediumEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_GridTileItemCreateLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GridTileItemCreateMediumEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_GridTileItemCreateLinkEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_CartItemCreateFulfillmentNotesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutCartItemsCreateInput"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteWithoutCartItemsCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateVenuesEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteCreateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientItemCreateQuantityEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemQuantityCreateInput"] | undefined
};
	["Contember_ProductIngredientItemQuantityCreateInput"]: {
		id?: GraphQLTypes["UUID"] | undefined,
	unit?: GraphQLTypes["Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput"] | undefined,
	value?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientItemQuantityCreateUnitEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined
};
	["Contember_AddressUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutAddressesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutAddressesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutAddressesUpdateInput"]: {
		defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertDefaultBillingAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerUpsertDefaultBillingAddressRelationInput"]: {
		update?: GraphQLTypes["Contember_AddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined
};
	["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerPaymentMethodCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerPaymentMethodUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertDefaultPaymentMethodRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerPaymentMethodUpdateInput"]: {
		caption?: string | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerPaymentMethodUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutPaymentMethodsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutPaymentMethodsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerPaymentMethodUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutPaymentMethodsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateAddressesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateAddressesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertAddressesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateAddressesRelationInput"]: {
		by?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_AddressWithoutCustomerUpdateInput"] | undefined
};
	["Contember_AddressWithoutCustomerUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpdateCountryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CountryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertCountryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_CountryUpdateInput"]: {
		code?: string | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpsertCountryRelationInput"]: {
		update?: GraphQLTypes["Contember_CountryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined
};
	["Contember_AddressUpdateDeliveryZoneEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryZoneWithoutAddressesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneWithoutAddressesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertDeliveryZoneRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryZoneWithoutAddressesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	suitableDeliveryMethods?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]> | undefined,
	deliveryZonesOfTags?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]> | undefined,
	deliveryZonesOfProducts?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"] | undefined
};
	["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodUpsertPaymentMethodsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodUpdatePaymentMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"] | undefined
};
	["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch?: boolean | undefined,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	channels?: Array<GraphQLTypes["Contember_PaymentMethodUpdateChannelsEntityRelationInput"]> | undefined,
	reduceGratuityByPercentage?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PaymentMethodUpdateChannelsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelPaymentWithoutMethodCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PaymentMethodUpdateChannelsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PaymentMethodUpsertChannelsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PaymentMethodUpdateChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelPaymentWithoutMethodUpdateInput"] | undefined
};
	["Contember_ChannelPaymentWithoutMethodUpdateInput"]: {
		order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentUpdateChannelEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelWithoutPaymentsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelWithoutPaymentsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelPaymentUpsertChannelRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ChannelWithoutPaymentsUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelUpdateDeliveriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelDeliveryWithoutChannelCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdateDeliveriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertDeliveriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelUpdateDeliveriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelDeliveryWithoutChannelUpdateInput"] | undefined
};
	["Contember_ChannelDeliveryWithoutChannelUpdateInput"]: {
		order?: number | undefined,
	priceCents?: number | undefined,
	method?: GraphQLTypes["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodWithoutChannelsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodWithoutChannelsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelDeliveryUpsertMethodRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryMethodWithoutChannelsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"] | undefined
};
	["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]> | undefined,
	deliveryZonesOfTags?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]> | undefined,
	deliveryZonesOfProducts?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressWithoutDeliveryZoneCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneUpdateAddressesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryZoneUpsertAddressesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryZoneUpdateAddressesRelationInput"]: {
		by?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_AddressWithoutDeliveryZoneUpdateInput"] | undefined
};
	["Contember_AddressWithoutDeliveryZoneUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	meta?: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_AddressUpdateGpsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_GpsWithoutAddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_GpsWithoutAddressUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertGpsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_GpsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_GpsWithoutAddressUpdateInput"]: {
		latitude?: number | undefined,
	longitude?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpsertGpsRelationInput"]: {
		update?: GraphQLTypes["Contember_GpsWithoutAddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_GpsWithoutAddressCreateInput"] | undefined
};
	["Contember_AddressUpdateInvoicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_InvoiceWithoutAddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressUpdateInvoicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertInvoicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AddressUpdateInvoicesRelationInput"]: {
		by?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_InvoiceWithoutAddressUpdateInput"] | undefined
};
	["Contember_InvoiceWithoutAddressUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_InvoiceUpdateCustomerEntityRelationInput"] | undefined,
	orderPayments?: Array<GraphQLTypes["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]> | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutInvoicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutInvoicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_InvoiceUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutInvoicesUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateTagsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutCustomersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateTagsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertTagsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutCustomersUpdateInput"] | undefined
};
	["Contember_TagWithoutCustomersUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutTagsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdateProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductWithoutTagsUpdateInput"] | undefined
};
	["Contember_ProductWithoutTagsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdatePackingEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductPackingCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductPackingUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertPackingRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductPackingUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductPackingUpdateInput"]: {
		name?: string | undefined,
	image?: GraphQLTypes["Contember_ProductPackingUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Contember_ProductPackingUpdateIconEntityRelationInput"] | undefined,
	shortName?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductPackingUpdateLocalesEntityRelationInput"]> | undefined,
	preparedPackingQuantities?: Array<GraphQLTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"]> | undefined,
	isPackedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductPackingUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ImageUpdateInput"]: {
		url?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	alt?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductPackingUpdateIconEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductPackingUpsertIconRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductPackingUpsertIconRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductPackingUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductPackingLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductPackingUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductPackingUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductPackingUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductPackingLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_ProductPackingLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	shortName?: string | undefined,
	description?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutProductPackingsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutProductPackingsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductPackingLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutProductPackingsUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertBusinessCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateBusinessCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput"] | undefined,
	title?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessCategoryWithoutLocalesUpdateInput"]: {
		order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCustomerWithoutCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryUpsertBusinessCustomersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCategoryUpdateBusinessCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessCustomerWithoutCategoryUpdateInput"] | undefined
};
	["Contember_BusinessCustomerWithoutCategoryUpdateInput"]: {
		name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutBusinessCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutBusinessUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutBusinessUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateCreditsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerCreditWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateCreditsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertCreditsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateCreditsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerCreditWithoutCustomerUpdateInput"] | undefined
};
	["Contember_CustomerCreditWithoutCustomerUpdateInput"]: {
		initialCreditCents?: number | undefined,
	remainingCreditsCents?: number | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	vatRate?: GraphQLTypes["Contember_CustomerCreditUpdateVatRateEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	transactions?: Array<GraphQLTypes["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"]> | undefined,
	voucherRedemption?: GraphQLTypes["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"] | undefined,
	invoiceUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerCreditUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VatRateUpdateInput"]: {
		name?: string | undefined,
	ratePermille?: number | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries?: Array<GraphQLTypes["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"]> | undefined,
	channelPayments?: Array<GraphQLTypes["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"]> | undefined,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelDeliveryWithoutVatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateChannelDeliveriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VatRateUpsertChannelDeliveriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VatRateUpdateChannelDeliveriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelDeliveryWithoutVatRateUpdateInput"] | undefined
};
	["Contember_ChannelDeliveryWithoutVatRateUpdateInput"]: {
		order?: number | undefined,
	priceCents?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelWithoutDeliveriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelWithoutDeliveriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelDeliveryUpsertChannelRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ChannelWithoutDeliveriesUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelUpdatePaymentsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelPaymentWithoutChannelCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdatePaymentsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertPaymentsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelUpdatePaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelPaymentWithoutChannelUpdateInput"] | undefined
};
	["Contember_ChannelPaymentWithoutChannelUpdateInput"]: {
		order?: number | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentUpdateMethodEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PaymentMethodWithoutChannelsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PaymentMethodWithoutChannelsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelPaymentUpsertMethodRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PaymentMethodWithoutChannelsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch?: boolean | undefined,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	reduceGratuityByPercentage?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PaymentMethodUpdateDeliveryMethodsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PaymentMethodUpsertDeliveryMethodsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PaymentMethodUpdateDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"] | undefined
};
	["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelDeliveryWithoutMethodCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodUpdateChannelsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodUpsertChannelsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodUpdateChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelDeliveryWithoutMethodUpdateInput"] | undefined
};
	["Contember_ChannelDeliveryWithoutMethodUpdateInput"]: {
		order?: number | undefined,
	priceCents?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateWithoutChannelDeliveriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateWithoutChannelDeliveriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelDeliveryUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_VatRateWithoutChannelDeliveriesUpdateInput"]: {
		name?: string | undefined,
	ratePermille?: number | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelPayments?: Array<GraphQLTypes["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"]> | undefined,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VatRateUpdateChannelPaymentsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelPaymentWithoutVatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateChannelPaymentsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VatRateUpsertChannelPaymentsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VatRateUpdateChannelPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelPaymentWithoutVatRateUpdateInput"] | undefined
};
	["Contember_ChannelPaymentWithoutVatRateUpdateInput"]: {
		order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutAllowChannelPaymentsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelPaymentUpdateAllowOnlyForTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutAllowChannelPaymentsUpdateInput"] | undefined
};
	["Contember_TagWithoutAllowChannelPaymentsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagUpdateCustomersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutTagsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdateCustomersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertCustomersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerWithoutTagsUpdateInput"] | undefined
};
	["Contember_CustomerWithoutTagsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdatePaymentMethodsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertPaymentMethodsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdatePaymentMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"] | undefined
};
	["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"]: {
		caption?: string | undefined,
	data?: GraphQLTypes["Json"] | undefined,
	paymentMethod?: GraphQLTypes["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerPaymentMethodUpdatePaymentMethodEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PaymentMethodCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PaymentMethodUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PaymentMethodUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_PaymentMethodUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	blockingDispatch?: boolean | undefined,
	type?: GraphQLTypes["Contember_PaymentMethodType"] | undefined,
	channels?: Array<GraphQLTypes["Contember_PaymentMethodUpdateChannelsEntityRelationInput"]> | undefined,
	reduceGratuityByPercentage?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerPaymentMethodUpsertPaymentMethodRelationInput"]: {
		update?: GraphQLTypes["Contember_PaymentMethodUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodCreateInput"] | undefined
};
	["Contember_CustomerUpsertPaymentMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerPaymentMethodUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerPaymentMethodWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerPaymentMethodWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerUpdateOrdersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateOrdersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertOrdersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderWithoutCustomerUpdateInput"] | undefined
};
	["Contember_OrderWithoutCustomerUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateChannelEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertChannelRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ChannelUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleWithoutChannelsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdatePointOfSalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertPointOfSalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelUpdatePointOfSalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleWithoutChannelsUpdateInput"] | undefined
};
	["Contember_PointOfSaleWithoutChannelsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	permissions?: GraphQLTypes["Contember_PointOfSaleUpdatePermissionsEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleUpdatePermissionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_PointOfSalePermissionsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ChannelUpsertPointOfSalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleWithoutChannelsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleWithoutChannelsCreateInput"] | undefined
};
	["Contember_ChannelUpdateCartsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CartWithoutChannelCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdateCartsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertCartsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelUpdateCartsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CartWithoutChannelUpdateInput"] | undefined
};
	["Contember_CartWithoutChannelUpdateInput"]: {
		confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CartUpdateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_CartUpdateOrderEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CartUpdateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CartItemWithoutCartCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CartUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CartUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CartItemWithoutCartUpdateInput"] | undefined
};
	["Contember_CartItemWithoutCartUpdateInput"]: {
		quantity?: number | undefined,
	product?: GraphQLTypes["Contember_CartItemUpdateProductEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartItemUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutCartItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutCartItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartItemUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductWithoutCartItemsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateRecipeEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductRecipeWithoutProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeWithoutProductsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertRecipeRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductRecipeWithoutProductsUpdateInput"]: {
		name?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductRecipeUpdateImageEntityRelationInput"] | undefined,
	icon?: GraphQLTypes["Contember_ProductRecipeUpdateIconEntityRelationInput"] | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductRecipeUpdateLocalesEntityRelationInput"]> | undefined,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductRecipeUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductRecipeUpdateIconEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeUpsertIconRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductRecipeUpsertIconRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientItemWithoutRecipeCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeUpdateIngredientsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeUpsertIngredientsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductRecipeUpdateIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientItemWithoutRecipeUpdateInput"] | undefined
};
	["Contember_ProductIngredientItemWithoutRecipeUpdateInput"]: {
		order?: number | undefined,
	internalOrder?: number | undefined,
	ingredient?: GraphQLTypes["Contember_ProductIngredientItemUpdateIngredientEntityRelationInput"] | undefined,
	quantity?: GraphQLTypes["Contember_ProductIngredientItemUpdateQuantityEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientItemUpdateIngredientEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientItemUpsertIngredientRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientUpdateInput"]: {
		internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_ProductIngredientLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutProductIngredientsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutProductIngredientsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutProductIngredientsUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateProductCategoryListsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertProductCategoryListsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateProductCategoryListsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryListWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryListLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductCategoryListWithoutLocalesUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]> | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryListItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductCategoryListItemWithoutListUpdateInput"] | undefined
};
	["Contember_ProductCategoryListItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	productCategory?: GraphQLTypes["Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput"] | undefined,
	showRecipeImages?: boolean | undefined,
	showPackingImages?: boolean | undefined,
	showAllAvailableProducts?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListItemUpdateProductCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryListItemUpsertProductCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductCategoryUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryUpdateProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryUpdateProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductWithoutCategoriesUpdateInput"] | undefined
};
	["Contember_ProductWithoutCategoriesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateTagsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateTagsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertTagsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutProductsUpdateInput"] | undefined
};
	["Contember_TagWithoutProductsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagUpdateCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryWithoutTagsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdateCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductCategoryWithoutTagsUpdateInput"] | undefined
};
	["Contember_ProductCategoryWithoutTagsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryUpdateParentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryWithoutChildrenCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryWithoutChildrenUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryUpsertParentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductCategoryWithoutChildrenUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryUpdateTagsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryUpdateTagsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryUpsertTagsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryUpdateTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutCategoriesUpdateInput"] | undefined
};
	["Contember_TagWithoutCategoriesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertAddTagsOnRegistrationRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateAddTagsOnRegistrationRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"] | undefined
};
	["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelUpdateCustomersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutChannelsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdateCustomersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertCustomersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelUpdateCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerWithoutChannelsUpdateInput"] | undefined
};
	["Contember_CustomerWithoutChannelsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdatePermissionsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerPermissionsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerUpdateGroupEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerGroupWithoutCustomersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerGroupWithoutCustomersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertGroupRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerGroupUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerGroupWithoutCustomersUpdateInput"]: {
		name?: string | undefined,
	productPrices?: Array<GraphQLTypes["Contember_CustomerGroupUpdateProductPricesEntityRelationInput"]> | undefined,
	discountPermille?: number | undefined,
	code?: string | undefined,
	doubleshotLegacyId?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerGroupUpdateProductPricesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductGroupPriceWithoutGroupCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerGroupUpdateProductPricesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerGroupUpsertProductPricesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerGroupUpdateProductPricesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductGroupPriceWithoutGroupUpdateInput"] | undefined
};
	["Contember_ProductGroupPriceWithoutGroupUpdateInput"]: {
		priceCents?: number | undefined,
	product?: GraphQLTypes["Contember_ProductGroupPriceUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGroupPriceUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutGroupPricesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutGroupPricesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGroupPriceUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_ProductWithoutGroupPricesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryWithoutProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductCategoryWithoutProductsUpdateInput"] | undefined
};
	["Contember_ProductCategoryWithoutProductsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined,
	parent?: GraphQLTypes["Contember_ProductCategoryUpdateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryWithoutParentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryUpdateChildrenRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryUpsertChildrenRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryUpdateChildrenRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductCategoryWithoutParentUpdateInput"] | undefined
};
	["Contember_ProductCategoryWithoutParentUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductCategoryUpdateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateTagsEntityRelationInput"]> | undefined,
	children?: Array<GraphQLTypes["Contember_ProductCategoryUpdateChildrenEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductCategoryUpdateImageEntityRelationInput"] | undefined,
	order?: number | undefined,
	descendants?: Array<GraphQLTypes["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]> | undefined,
	ancestors?: Array<GraphQLTypes["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]> | undefined,
	inheritedTags?: Array<GraphQLTypes["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]> | undefined,
	nestedProducts?: Array<GraphQLTypes["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductCategoryUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductCategoryUpdateDescendantsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryUpdateAncestorsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryTreeUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryUpdateInheritedTagsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryUpdateNestedProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryUpsertChildrenRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryWithoutParentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutParentCreateInput"] | undefined
};
	["Contember_ProductUpsertCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryWithoutProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutProductsCreateInput"] | undefined
};
	["Contember_ProductUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_ProductUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_ProductUpdateStocksEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductStockWithoutProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateStocksRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertStocksRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateStocksRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductStockWithoutProductUpdateInput"] | undefined
};
	["Contember_ProductStockWithoutProductUpdateInput"]: {
		quantity?: number | undefined,
	store?: GraphQLTypes["Contember_ProductStockUpdateStoreEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductStockUpdateStoreEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StoreCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StoreUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductStockUpsertStoreRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StoreUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_StoreUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductStockUpsertStoreRelationInput"]: {
		update?: GraphQLTypes["Contember_StoreUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StoreCreateInput"] | undefined
};
	["Contember_ProductUpsertStocksRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductStockUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductStockWithoutProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductStockWithoutProductCreateInput"] | undefined
};
	["Contember_ProductUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductUpdateCartItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CartItemWithoutProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateCartItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertCartItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateCartItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CartItemWithoutProductUpdateInput"] | undefined
};
	["Contember_CartItemWithoutProductUpdateInput"]: {
		quantity?: number | undefined,
	cart?: GraphQLTypes["Contember_CartItemUpdateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartItemUpdateCartEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CartWithoutItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CartWithoutItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartItemUpsertCartRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CartWithoutItemsUpdateInput"]: {
		confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["Contember_CartUpdateChannelEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_CartUpdateOrderEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CartUpdateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartUpdateChannelEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelWithoutCartsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelWithoutCartsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertChannelRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ChannelWithoutCartsUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutAddTagsOnRegistrationCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertAddTagsOnRegistrationRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelUpdateAddTagsOnRegistrationRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"] | undefined
};
	["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagUpdateNestedCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_InheritedProductCategoryTagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateDeliveryZonesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdateDeliveryZonesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertDeliveryZonesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateDeliveryZonesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"] | undefined
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]> | undefined,
	suitableDeliveryMethods?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]> | undefined,
	deliveryZonesOfProducts?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutDeliveryZonesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductWithoutDeliveryZonesUpdateInput"] | undefined
};
	["Contember_ProductWithoutDeliveryZonesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateGroupPricesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductGroupPriceWithoutProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateGroupPricesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertGroupPricesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateGroupPricesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductGroupPriceWithoutProductUpdateInput"] | undefined
};
	["Contember_ProductGroupPriceWithoutProductUpdateInput"]: {
		priceCents?: number | undefined,
	group?: GraphQLTypes["Contember_ProductGroupPriceUpdateGroupEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGroupPriceUpdateGroupEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerGroupWithoutProductPricesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerGroupWithoutProductPricesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGroupPriceUpsertGroupRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerGroupUniqueWhere"] | undefined
};
	["Contember_CustomerGroupWithoutProductPricesUpdateInput"]: {
		name?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_CustomerGroupUpdateCustomersEntityRelationInput"]> | undefined,
	discountPermille?: number | undefined,
	code?: string | undefined,
	doubleshotLegacyId?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerGroupUpdateCustomersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutGroupCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerGroupUpdateCustomersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerGroupUpsertCustomersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerGroupUpdateCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerWithoutGroupUpdateInput"] | undefined
};
	["Contember_CustomerWithoutGroupUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdatePhotoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertPhotoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerUpsertPhotoRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_CustomerUpdateNameEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerNameWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerNameWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertNameRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerNameUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerNameWithoutCustomerUpdateInput"]: {
		nominative?: string | undefined,
	vocative?: string | undefined,
	aliases?: Array<GraphQLTypes["Contember_CustomerNameUpdateAliasesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerNameUpdateAliasesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerAliasCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerNameUpdateAliasesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerNameUpsertAliasesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerAliasUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerAliasUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerAliasUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerNameUpdateAliasesRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerAliasUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerAliasUpdateInput"] | undefined
};
	["Contember_CustomerAliasUpdateInput"]: {
		name?: string | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerNameUpsertAliasesRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerAliasUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerAliasUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerAliasCreateInput"] | undefined
};
	["Contember_CustomerUpsertNameRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerNameWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerNameWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateIngredientRatingsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertIngredientRatingsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateIngredientRatingsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined
};
	["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"]: {
		rating?: GraphQLTypes["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined,
	ingredient?: GraphQLTypes["Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientRatingCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientRatingUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerIngredientRatingUpsertRatingRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientRatingUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientRatingUpdateInput"]: {
		order?: number | undefined,
	name?: string | undefined,
	icon?: GraphQLTypes["Contember_ProductIngredientRatingUpdateIconEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientRatingUpdateIconEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientRatingUpsertIconRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientRatingUpsertIconRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_CustomerIngredientRatingUpsertRatingRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientRatingUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientRatingCreateInput"] | undefined
};
	["Contember_CustomerIngredientRatingUpdateIngredientEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerIngredientRatingUpsertIngredientRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"]: {
		internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientUpdateCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientUpdateCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"] | undefined
};
	["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"]: {
		internalName?: string | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientCategoryUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientCategoryUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientCategoryUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateProductIngredientsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertProductIngredientsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateProductIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientWithoutLocalesUpdateInput"]: {
		internalNote?: string | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AllergenWithoutIngredientsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientUpdateAllergensRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertAllergensRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientUpdateAllergensRelationInput"]: {
		by?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_AllergenWithoutIngredientsUpdateInput"] | undefined
};
	["Contember_AllergenWithoutIngredientsUpdateInput"]: {
		internalName?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_AllergenUpdateLocalesEntityRelationInput"]> | undefined,
	code?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AllergenLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AllergenUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AllergenUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AllergenUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_AllergenLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_AllergenLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_AllergenLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutAllergensCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutAllergensUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AllergenLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutAllergensUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertOrderRecurrencesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateOrderRecurrencesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderRecurrenceWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderRecurrenceWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderRecurrenceLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderRecurrenceWithoutLocalesUpdateInput"]: {
		internalName?: string | undefined,
	order?: number | undefined,
	businessCategory?: Array<GraphQLTypes["Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceUpdateBusinessCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryWithoutRecurrencesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderRecurrenceUpdateBusinessCategoryRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"] | undefined
};
	["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"]: {
		order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCategoryUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput"] | undefined,
	title?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutBusinessCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutBusinessCategoriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutBusinessCategoriesUpdateInput"]: {
		productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateBlogsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateBlogsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertBlogsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateBlogsRelationInput"]: {
		by?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BlogLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_BlogLocaleWithoutLocaleUpdateInput"]: {
		pageName?: string | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	root?: GraphQLTypes["Contember_BlogLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BlogWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_BlogWithoutLocalesUpdateInput"]: {
		unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_BlogWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertBlogsRelationInput"]: {
		by?: GraphQLTypes["Contember_BlogLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BlogLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdateBlogPostsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogPostLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateBlogPostsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertBlogPostsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateBlogPostsRelationInput"]: {
		by?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BlogPostLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_BlogPostLocaleWithoutLocaleUpdateInput"]: {
		title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPostLocaleUpdateRootEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_BlogPostLocaleUpdateContentEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"] | undefined,
	products?: Array<GraphQLTypes["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogPostWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogPostUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_BlogPostWithoutLocalesUpdateInput"]: {
		order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_BlogPostWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostWithoutLocalesCreateInput"] | undefined
};
	["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CoverCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CoverUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostLocaleUpsertCoverRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CoverUpdateInput"]: {
		primaryTitle?: string | undefined,
	secondaryTitle?: string | undefined,
	medium?: GraphQLTypes["Contember_CoverUpdateMediumEntityRelationInput"] | undefined,
	buttonColors?: GraphQLTypes["Contember_CoverUpdateButtonColorsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CoverUpdateMediumEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CoverUpsertMediumRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MediumUpdateInput"]: {
		type?: GraphQLTypes["Contember_MediumType"] | undefined,
	colorTheme?: GraphQLTypes["Contember_ColorTheme"] | undefined,
	image?: GraphQLTypes["Contember_MediumUpdateImageEntityRelationInput"] | undefined,
	video?: GraphQLTypes["Contember_MediumUpdateVideoEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MediumUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MediumUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MediumUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MediumUpdateVideoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VideoCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VideoUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MediumUpsertVideoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VideoUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VideoUpdateInput"]: {
		src?: string | undefined,
	width?: number | undefined,
	height?: number | undefined,
	size?: number | undefined,
	type?: string | undefined,
	duration?: number | undefined,
	poster?: GraphQLTypes["Contember_VideoUpdatePosterEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VideoUpdatePosterEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VideoUpsertPosterRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VideoUpsertPosterRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MediumUpsertVideoRelationInput"]: {
		update?: GraphQLTypes["Contember_VideoUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VideoCreateInput"] | undefined
};
	["Contember_CoverUpsertMediumRelationInput"]: {
		update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_CoverUpdateButtonColorsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ColorPalleteCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ColorPalleteUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CoverUpsertButtonColorsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ColorPalleteUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ColorPalleteUpdateInput"]: {
		background?: string | undefined,
	text?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CoverUpsertButtonColorsRelationInput"]: {
		update?: GraphQLTypes["Contember_ColorPalleteUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ColorPalleteCreateInput"] | undefined
};
	["Contember_BlogPostLocaleUpsertCoverRelationInput"]: {
		update?: GraphQLTypes["Contember_CoverUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CoverCreateInput"] | undefined
};
	["Contember_BlogPostLocaleUpdateContentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ContentUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostLocaleUpsertContentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ContentUpdateInput"]: {
		blocks?: Array<GraphQLTypes["Contember_ContentUpdateBlocksEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentUpdateBlocksEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ContentBlockWithoutContentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ContentUpdateBlocksRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentUpsertBlocksRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ContentBlockUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ContentBlockUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ContentBlockUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ContentUpdateBlocksRelationInput"]: {
		by?: GraphQLTypes["Contember_ContentBlockUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ContentBlockWithoutContentUpdateInput"] | undefined
};
	["Contember_ContentBlockWithoutContentUpdateInput"]: {
		order?: number | undefined,
	json?: string | undefined,
	references?: Array<GraphQLTypes["Contember_ContentBlockUpdateReferencesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentBlockUpdateReferencesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ContentReferenceWithoutBlockCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ContentBlockUpdateReferencesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentBlockUpsertReferencesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ContentBlockUpdateReferencesRelationInput"]: {
		by?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ContentReferenceWithoutBlockUpdateInput"] | undefined
};
	["Contember_ContentReferenceWithoutBlockUpdateInput"]: {
		type?: GraphQLTypes["Contember_ContentReferenceType"] | undefined,
	primaryText?: string | undefined,
	secondaryText?: string | undefined,
	jsonContent?: string | undefined,
	colorPallete?: GraphQLTypes["Contember_ContentReferenceUpdateColorPalleteEntityRelationInput"] | undefined,
	medium?: GraphQLTypes["Contember_ContentReferenceUpdateMediumEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_ContentReferenceUpdateLinkEntityRelationInput"] | undefined,
	blogPosts?: GraphQLTypes["Contember_ContentReferenceUpdateBlogPostsEntityRelationInput"] | undefined,
	links?: GraphQLTypes["Contember_ContentReferenceUpdateLinksEntityRelationInput"] | undefined,
	products?: GraphQLTypes["Contember_ContentReferenceUpdateProductsEntityRelationInput"] | undefined,
	contentSize?: GraphQLTypes["Contember_ContentSize"] | undefined,
	misc?: string | undefined,
	hero?: GraphQLTypes["Contember_ContentReferenceUpdateHeroEntityRelationInput"] | undefined,
	gallery?: GraphQLTypes["Contember_ContentReferenceUpdateGalleryEntityRelationInput"] | undefined,
	socialsAndApps?: GraphQLTypes["Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput"] | undefined,
	productGrid?: GraphQLTypes["Contember_ContentReferenceUpdateProductGridEntityRelationInput"] | undefined,
	productBanners?: GraphQLTypes["Contember_ContentReferenceUpdateProductBannersEntityRelationInput"] | undefined,
	deliveryRegions?: GraphQLTypes["Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput"] | undefined,
	textList?: GraphQLTypes["Contember_ContentReferenceUpdateTextListEntityRelationInput"] | undefined,
	gridTiles?: GraphQLTypes["Contember_ContentReferenceUpdateGridTilesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ContentReferenceUpdateColorPalleteEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ColorPalleteCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ColorPalleteUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertColorPalleteRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ColorPalleteUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ContentReferenceUpsertColorPalleteRelationInput"]: {
		update?: GraphQLTypes["Contember_ColorPalleteUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ColorPalleteCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateMediumEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertMediumRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ContentReferenceUpsertMediumRelationInput"]: {
		update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LinkUpdateInput"]: {
		type?: GraphQLTypes["Contember_LinkType"] | undefined,
	title?: string | undefined,
	externalLink?: string | undefined,
	internalLink?: GraphQLTypes["Contember_LinkUpdateInternalLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkUpdateInternalLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkableCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkableUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LinkUpsertInternalLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LinkableUpdateInput"]: {
		url?: string | undefined,
	blogPost?: GraphQLTypes["Contember_LinkableUpdateBlogPostEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_LinkableUpdatePageEntityRelationInput"] | undefined,
	wikiPage?: GraphQLTypes["Contember_LinkableUpdateWikiPageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkableUpdateBlogPostEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogPostLocaleWithoutLinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostLocaleWithoutLinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LinkableUpsertBlogPostRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BlogPostLocaleWithoutLinkUpdateInput"]: {
		title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPostLocaleUpdateRootEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_BlogPostLocaleUpdateContentEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"] | undefined,
	products?: Array<GraphQLTypes["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutBlogPostsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutBlogPostsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutBlogPostsUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateIdentifierEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationCataloguesIdentifierCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertIdentifierRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TranslationCataloguesIdentifierUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	catalogue?: Array<GraphQLTypes["Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCataloguesIdentifierUpdateCatalogueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationCatalogueWithoutIdentifierCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationCataloguesIdentifierUpdateCatalogueRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"] | undefined
};
	["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"]: {
		domain?: GraphQLTypes["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationDomainWithoutCataloguesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationDomainWithoutCataloguesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationCatalogueUpsertDomainRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationDomainUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_TranslationDomainWithoutCataloguesUpdateInput"]: {
		identifier?: string | undefined,
	name?: string | undefined,
	keys?: Array<GraphQLTypes["Contember_TranslationDomainUpdateKeysEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationDomainUpdateKeysEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationKeyWithoutDomainCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationDomainUpdateKeysRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationDomainUpsertKeysRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationDomainUpdateKeysRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TranslationKeyWithoutDomainUpdateInput"] | undefined
};
	["Contember_TranslationKeyWithoutDomainUpdateInput"]: {
		identifier?: string | undefined,
	contentType?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	note?: string | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationKeyUpdateValuesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationKeyUpdateValuesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationValueWithoutKeyCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationKeyUpdateValuesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationKeyUpsertValuesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationKeyUpdateValuesRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TranslationValueWithoutKeyUpdateInput"] | undefined
};
	["Contember_TranslationValueWithoutKeyUpdateInput"]: {
		value?: string | undefined,
	catalogue?: GraphQLTypes["Contember_TranslationValueUpdateCatalogueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationValueUpdateCatalogueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationCatalogueWithoutValuesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCatalogueWithoutValuesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationValueUpsertCatalogueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_TranslationCatalogueWithoutValuesUpdateInput"]: {
		domain?: GraphQLTypes["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationCatalogueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCatalogueUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationCatalogueUpsertFallbackRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TranslationCatalogueUpdateInput"]: {
		domain?: GraphQLTypes["Contember_TranslationCatalogueUpdateDomainEntityRelationInput"] | undefined,
	fallback?: GraphQLTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationValueWithoutCatalogueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCatalogueUpdateValuesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationCatalogueUpsertValuesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationCatalogueUpdateValuesRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TranslationValueWithoutCatalogueUpdateInput"] | undefined
};
	["Contember_TranslationValueWithoutCatalogueUpdateInput"]: {
		value?: string | undefined,
	key?: GraphQLTypes["Contember_TranslationValueUpdateKeyEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationValueUpdateKeyEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationKeyWithoutValuesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationKeyWithoutValuesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationValueUpsertKeyRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_TranslationKeyWithoutValuesUpdateInput"]: {
		identifier?: string | undefined,
	contentType?: GraphQLTypes["Contember_TranslationContentType"] | undefined,
	note?: string | undefined,
	domain?: GraphQLTypes["Contember_TranslationKeyUpdateDomainEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationKeyUpdateDomainEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationDomainWithoutKeysCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationDomainWithoutKeysUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationKeyUpsertDomainRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationDomainUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_TranslationDomainWithoutKeysUpdateInput"]: {
		identifier?: string | undefined,
	name?: string | undefined,
	catalogues?: Array<GraphQLTypes["Contember_TranslationDomainUpdateCataloguesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationDomainUpdateCataloguesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationCatalogueWithoutDomainCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationDomainUpdateCataloguesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationDomainUpsertCataloguesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TranslationDomainUpdateCataloguesRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TranslationCatalogueWithoutDomainUpdateInput"] | undefined
};
	["Contember_TranslationCatalogueWithoutDomainUpdateInput"]: {
		fallback?: GraphQLTypes["Contember_TranslationCatalogueUpdateFallbackEntityRelationInput"] | undefined,
	values?: Array<GraphQLTypes["Contember_TranslationCatalogueUpdateValuesEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueUpdateIdentifierEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TranslationCatalogueUpsertIdentifierRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TranslationCatalogueUpsertIdentifierRelationInput"]: {
		update?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCataloguesIdentifierWithoutCatalogueCreateInput"] | undefined
};
	["Contember_TranslationDomainUpsertCataloguesRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCatalogueWithoutDomainUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueWithoutDomainCreateInput"] | undefined
};
	["Contember_TranslationKeyUpsertDomainRelationInput"]: {
		update?: GraphQLTypes["Contember_TranslationDomainWithoutKeysUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationDomainWithoutKeysCreateInput"] | undefined
};
	["Contember_TranslationValueUpsertKeyRelationInput"]: {
		update?: GraphQLTypes["Contember_TranslationKeyWithoutValuesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationKeyWithoutValuesCreateInput"] | undefined
};
	["Contember_TranslationCatalogueUpsertValuesRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TranslationValueWithoutCatalogueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationValueWithoutCatalogueCreateInput"] | undefined
};
	["Contember_TranslationCatalogueUpsertFallbackRelationInput"]: {
		update?: GraphQLTypes["Contember_TranslationCatalogueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueCreateInput"] | undefined
};
	["Contember_TranslationValueUpsertCatalogueRelationInput"]: {
		update?: GraphQLTypes["Contember_TranslationCatalogueWithoutValuesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueWithoutValuesCreateInput"] | undefined
};
	["Contember_TranslationKeyUpsertValuesRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationValueUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TranslationValueWithoutKeyUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationValueWithoutKeyCreateInput"] | undefined
};
	["Contember_TranslationDomainUpsertKeysRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationKeyUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TranslationKeyWithoutDomainUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationKeyWithoutDomainCreateInput"] | undefined
};
	["Contember_TranslationCatalogueUpsertDomainRelationInput"]: {
		update?: GraphQLTypes["Contember_TranslationDomainWithoutCataloguesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationDomainWithoutCataloguesCreateInput"] | undefined
};
	["Contember_TranslationCataloguesIdentifierUpsertCatalogueRelationInput"]: {
		by?: GraphQLTypes["Contember_TranslationCatalogueUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TranslationCatalogueWithoutIdentifierUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCatalogueWithoutIdentifierCreateInput"] | undefined
};
	["Contember_LocaleUpsertIdentifierRelationInput"]: {
		update?: GraphQLTypes["Contember_TranslationCataloguesIdentifierUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TranslationCataloguesIdentifierCreateInput"] | undefined
};
	["Contember_LocaleUpdateMenusEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateMenusRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertMenusRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateMenusRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_MenuLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_MenuLocaleWithoutLocaleUpdateInput"]: {
		root?: GraphQLTypes["Contember_MenuLocaleUpdateRootEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_MenuLocaleUpdateItemsEntityRelationInput"]> | undefined,
	secondaryItems?: GraphQLTypes["Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput"] | undefined,
	eshopLink?: GraphQLTypes["Contember_MenuLocaleUpdateEshopLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SiteMenuWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SiteMenuWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SiteMenuUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_SiteMenuWithoutLocalesUpdateInput"]: {
		unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_SiteMenuWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SiteMenuWithoutLocalesCreateInput"] | undefined
};
	["Contember_MenuLocaleUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuItemWithoutMenuCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuLocaleUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuLocaleUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_MenuLocaleUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_MenuItemWithoutMenuUpdateInput"] | undefined
};
	["Contember_MenuItemWithoutMenuUpdateInput"]: {
		order?: number | undefined,
	link?: GraphQLTypes["Contember_MenuItemUpdateLinkEntityRelationInput"] | undefined,
	subitems?: Array<GraphQLTypes["Contember_MenuItemUpdateSubitemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuItemUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuItemUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuItemUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_MenuItemUpdateSubitemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuSubitemWithoutItemCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuItemUpdateSubitemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuItemUpsertSubitemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_MenuItemUpdateSubitemsRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_MenuSubitemWithoutItemUpdateInput"] | undefined
};
	["Contember_MenuSubitemWithoutItemUpdateInput"]: {
		type?: GraphQLTypes["Contember_MenuSubitemType"] | undefined,
	title?: string | undefined,
	links?: GraphQLTypes["Contember_MenuSubitemUpdateLinksEntityRelationInput"] | undefined,
	products?: GraphQLTypes["Contember_MenuSubitemUpdateProductsEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_MenuSubitemUpdateDeliveryEntityRelationInput"] | undefined,
	contact?: GraphQLTypes["Contember_MenuSubitemUpdateContactEntityRelationInput"] | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuSubitemUpdateLinksEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuLinkListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuLinkListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuSubitemUpsertLinksRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuLinkListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuLinkListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_MenuLinkListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLinkListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuLinkItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuLinkListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuLinkListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_MenuLinkListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_MenuLinkItemWithoutListUpdateInput"] | undefined
};
	["Contember_MenuLinkItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	link?: GraphQLTypes["Contember_MenuLinkItemUpdateLinkEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_MenuLinkItemUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuLinkItemUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuLinkItemUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuLinkItemUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_MenuLinkItemUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuLinkItemUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuLinkItemUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MenuLinkListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuLinkItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_MenuLinkItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuLinkItemWithoutListCreateInput"] | undefined
};
	["Contember_MenuSubitemUpsertLinksRelationInput"]: {
		update?: GraphQLTypes["Contember_MenuLinkListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuLinkListCreateInput"] | undefined
};
	["Contember_MenuSubitemUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuProductsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuSubitemUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuProductsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuProductsUpdateInput"]: {
		links?: GraphQLTypes["Contember_MenuProductsUpdateLinksEntityRelationInput"] | undefined,
	buttons?: GraphQLTypes["Contember_MenuProductsUpdateButtonsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuProductsUpdateLinksEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuLinkListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuLinkListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuProductsUpsertLinksRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuLinkListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuProductsUpsertLinksRelationInput"]: {
		update?: GraphQLTypes["Contember_MenuLinkListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuLinkListCreateInput"] | undefined
};
	["Contember_MenuProductsUpdateButtonsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuProductsUpsertButtonsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LinkListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_LinkListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkListItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LinkListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkListItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_LinkListItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_LinkListItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LinkListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_LinkListItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_LinkListItemWithoutListUpdateInput"] | undefined
};
	["Contember_LinkListItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	isPrimary?: boolean | undefined,
	link?: GraphQLTypes["Contember_LinkListItemUpdateLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkListItemUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LinkListItemUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LinkListItemUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_LinkListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_LinkListItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_LinkListItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkListItemWithoutListCreateInput"] | undefined
};
	["Contember_MenuProductsUpsertButtonsRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined
};
	["Contember_MenuSubitemUpsertProductsRelationInput"]: {
		update?: GraphQLTypes["Contember_MenuProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuProductsCreateInput"] | undefined
};
	["Contember_MenuSubitemUpdateDeliveryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuDeliveryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuDeliveryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuSubitemUpsertDeliveryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuDeliveryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuDeliveryUpdateInput"]: {
		deliveryRegions?: GraphQLTypes["Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput"] | undefined,
	text?: string | undefined,
	socialsAndApps?: GraphQLTypes["Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuDeliveryUpdateDeliveryRegionsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryRegionsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryRegionsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryRegionsUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_DeliveryRegionsUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRegionsUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryRegionsItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryRegionsUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryRegionsUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryRegionsUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryRegionsItemWithoutListUpdateInput"] | undefined
};
	["Contember_DeliveryRegionsItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	title?: string | undefined,
	offerLabel?: string | undefined,
	image?: GraphQLTypes["Contember_DeliveryRegionsItemUpdateImageEntityRelationInput"] | undefined,
	textList?: GraphQLTypes["Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput"] | undefined,
	gallery?: GraphQLTypes["Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRegionsItemUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryRegionsItemUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryRegionsItemUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_DeliveryRegionsItemUpdateTextListEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryRegionsItemUpsertTextListRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TextListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_TextListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TextListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TextItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TextListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TextListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TextItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TextItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TextItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TextListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_TextItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TextItemWithoutListUpdateInput"] | undefined
};
	["Contember_TextItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	text?: string | undefined,
	title?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TextListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_TextItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TextItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TextItemWithoutListCreateInput"] | undefined
};
	["Contember_DeliveryRegionsItemUpsertTextListRelationInput"]: {
		update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_DeliveryRegionsItemUpdateGalleryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryRegionsItemUpsertGalleryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ImageListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ImageListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ImageListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageListItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ImageListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageListItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ImageListItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ImageListItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ImageListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ImageListItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ImageListItemWithoutListUpdateInput"] | undefined
};
	["Contember_ImageListItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	image?: GraphQLTypes["Contember_ImageListItemUpdateImageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ImageListItemUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ImageListItemUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ImageListItemUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ImageListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ImageListItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ImageListItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageListItemWithoutListCreateInput"] | undefined
};
	["Contember_DeliveryRegionsItemUpsertGalleryRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageListCreateInput"] | undefined
};
	["Contember_DeliveryRegionsUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryRegionsItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryRegionsItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRegionsItemWithoutListCreateInput"] | undefined
};
	["Contember_MenuDeliveryUpsertDeliveryRegionsRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryRegionsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRegionsCreateInput"] | undefined
};
	["Contember_MenuDeliveryUpdateSocialsAndAppsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SocialsAndAppsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SocialsAndAppsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_SocialsAndAppsUpdateInput"]: {
		instagram?: boolean | undefined,
	appStore?: boolean | undefined,
	googlePlay?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuDeliveryUpsertSocialsAndAppsRelationInput"]: {
		update?: GraphQLTypes["Contember_SocialsAndAppsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SocialsAndAppsCreateInput"] | undefined
};
	["Contember_MenuSubitemUpsertDeliveryRelationInput"]: {
		update?: GraphQLTypes["Contember_MenuDeliveryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuDeliveryCreateInput"] | undefined
};
	["Contember_MenuSubitemUpdateContactEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MenuContactCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MenuContactUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuSubitemUpsertContactRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MenuContactUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuContactUpdateInput"]: {
		text?: string | undefined,
	image?: GraphQLTypes["Contember_MenuContactUpdateImageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_MenuContactUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuContactUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuContactUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_MenuSubitemUpsertContactRelationInput"]: {
		update?: GraphQLTypes["Contember_MenuContactUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuContactCreateInput"] | undefined
};
	["Contember_MenuItemUpsertSubitemsRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuSubitemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_MenuSubitemWithoutItemUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuSubitemWithoutItemCreateInput"] | undefined
};
	["Contember_MenuLocaleUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_MenuItemWithoutMenuUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuItemWithoutMenuCreateInput"] | undefined
};
	["Contember_MenuLocaleUpdateSecondaryItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuLocaleUpsertSecondaryItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuLocaleUpsertSecondaryItemsRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined
};
	["Contember_MenuLocaleUpdateEshopLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_MenuLocaleUpsertEshopLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_MenuLocaleUpsertEshopLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_LocaleUpsertMenusRelationInput"]: {
		by?: GraphQLTypes["Contember_MenuLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_MenuLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MenuLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdatePagesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PageLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdatePagesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertPagesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdatePagesRelationInput"]: {
		by?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PageLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_PageLocaleWithoutLocaleUpdateInput"]: {
		title?: string | undefined,
	link?: GraphQLTypes["Contember_PageLocaleUpdateLinkEntityRelationInput"] | undefined,
	root?: GraphQLTypes["Contember_PageLocaleUpdateRootEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_PageLocaleUpdateSeoEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_PageLocaleUpdateContentEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_PageLocaleUpdateCoverEntityRelationInput"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkableWithoutPageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkableWithoutPageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PageLocaleUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LinkableWithoutPageUpdateInput"]: {
		url?: string | undefined,
	blogPost?: GraphQLTypes["Contember_LinkableUpdateBlogPostEntityRelationInput"] | undefined,
	wikiPage?: GraphQLTypes["Contember_LinkableUpdateWikiPageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkableUpdateWikiPageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_WikiPageWithoutLinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_WikiPageWithoutLinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LinkableUpsertWikiPageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WikiPageWithoutLinkUpdateInput"]: {
		order?: number | undefined,
	title?: string | undefined,
	emoji?: string | undefined,
	parent?: GraphQLTypes["Contember_WikiPageUpdateParentEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_WikiPageUpdateChildrenEntityRelationInput"]> | undefined,
	content?: GraphQLTypes["Contember_WikiPageUpdateContentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WikiPageUpdateParentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_WikiPageWithoutChildrenCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_WikiPageWithoutChildrenUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WikiPageUpsertParentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WikiPageWithoutChildrenUpdateInput"]: {
		order?: number | undefined,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Contember_WikiPageUpdateLinkEntityRelationInput"] | undefined,
	parent?: GraphQLTypes["Contember_WikiPageUpdateParentEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_WikiPageUpdateContentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WikiPageUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkableWithoutWikiPageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkableWithoutWikiPageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WikiPageUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LinkableWithoutWikiPageUpdateInput"]: {
		url?: string | undefined,
	blogPost?: GraphQLTypes["Contember_LinkableUpdateBlogPostEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_LinkableUpdatePageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LinkableUpdatePageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PageLocaleWithoutLinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PageLocaleWithoutLinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LinkableUpsertPageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PageLocaleWithoutLinkUpdateInput"]: {
		title?: string | undefined,
	root?: GraphQLTypes["Contember_PageLocaleUpdateRootEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_PageLocaleUpdateLocaleEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_PageLocaleUpdateSeoEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_PageLocaleUpdateContentEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_PageLocaleUpdateCoverEntityRelationInput"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PageWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PageWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PageLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PageWithoutLocalesUpdateInput"]: {
		_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_PageWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PageWithoutLocalesCreateInput"] | undefined
};
	["Contember_PageLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutPagesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutPagesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PageLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutPagesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertProductIngredientCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateProductIngredientCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"]: {
		internalName?: string | undefined,
	internalNote?: string | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput"]> | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientCategoryUpdateIngredientsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientWithoutCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientCategoryUpdateIngredientsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientCategoryUpsertIngredientsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientCategoryUpdateIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientWithoutCategoriesUpdateInput"] | undefined
};
	["Contember_ProductIngredientWithoutCategoriesUpdateInput"]: {
		internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	allergens?: Array<GraphQLTypes["Contember_ProductIngredientUpdateAllergensEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientUpdateCustomerRatingsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertCustomerRatingsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductIngredientUpdateCustomerRatingsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"] | undefined
};
	["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"]: {
		customer?: GraphQLTypes["Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput"] | undefined,
	rating?: GraphQLTypes["Contember_CustomerIngredientRatingUpdateRatingEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerIngredientRatingUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutIngredientRatingsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutIngredientRatingsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerIngredientRatingUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutIngredientRatingsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateBusinessEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCustomerWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertBusinessRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessCustomerWithoutCustomerUpdateInput"]: {
		name?: string | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerUpdateOwnersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertOwnersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCustomerUpdateOwnersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"] | undefined
};
	["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCustomerWithoutMembersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertMemberOfBusinessesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateMemberOfBusinessesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessCustomerWithoutMembersUpdateInput"] | undefined
};
	["Contember_BusinessCustomerWithoutMembersUpdateInput"]: {
		name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"]: {
		order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryUpsertRecurrencesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCategoryUpdateRecurrencesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"] | undefined
};
	["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"]: {
		internalName?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_OrderRecurrenceUpdateLocalesEntityRelationInput"]> | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderRecurrenceUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderRecurrenceUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderRecurrenceUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderRecurrenceLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutOrderRecurrencesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutOrderRecurrencesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutOrderRecurrencesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateAllergensEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AllergenLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateAllergensRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertAllergensRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateAllergensRelationInput"]: {
		by?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_AllergenLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_AllergenLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_AllergenLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AllergenWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AllergenWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AllergenLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_AllergenWithoutLocalesUpdateInput"]: {
		internalName?: string | undefined,
	ingredients?: Array<GraphQLTypes["Contember_AllergenUpdateIngredientsEntityRelationInput"]> | undefined,
	code?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AllergenUpdateIngredientsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientWithoutAllergensCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AllergenUpdateIngredientsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AllergenUpsertIngredientsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AllergenUpdateIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductIngredientWithoutAllergensUpdateInput"] | undefined
};
	["Contember_ProductIngredientWithoutAllergensUpdateInput"]: {
		internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductIngredientUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCategoriesEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	allowCustomerRating?: boolean | undefined,
	customerRatings?: Array<GraphQLTypes["Contember_ProductIngredientUpdateCustomerRatingsEntityRelationInput"]> | undefined,
	preferredQuantityUnit?: GraphQLTypes["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"] | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"] | undefined,
	image?: GraphQLTypes["Contember_ProductIngredientUpdateImageEntityRelationInput"] | undefined,
	suppliers?: GraphQLTypes["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"] | undefined,
	incrementQuantityNumber?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientUpdatePreferredQuantityUnitEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientItemQuantityUnitUpdateInput"]: {
		name?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientUpsertPreferredQuantityUnitRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined
};
	["Contember_ProductIngredientUpdateFreshingContainerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FreshingContainerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertFreshingContainerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FreshingContainerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_FreshingContainerUpdateInput"]: {
		order?: number | undefined,
	volumeMl?: number | undefined,
	workspace?: GraphQLTypes["Contember_FreshingContainerUpdateWorkspaceEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FreshingContainerUpdateWorkspaceEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_WorkspaceWithoutFreshingContainersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_WorkspaceWithoutFreshingContainersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_FreshingContainerUpsertWorkspaceRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_WorkspaceUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WorkspaceWithoutFreshingContainersUpdateInput"]: {
		unique?: GraphQLTypes["Contember_One"] | undefined,
	orderDeadlineTime?: string | undefined,
	ordersLoadingDurationHours?: number | undefined,
	freshingContainerVolumeMl?: number | undefined,
	freshingPrepareOrderByOffsetMinutes?: number | undefined,
	checkoutUrlTemplate?: string | undefined,
	orderUrlTemplate?: string | undefined,
	paymentUrlTemplate?: string | undefined,
	customerProfileUrlTemplate?: string | undefined,
	userProfileUrlTemplate?: string | undefined,
	messengerUrl?: string | undefined,
	messengerIosUrl?: string | undefined,
	messengerAndroidUrl?: string | undefined,
	openingHours?: GraphQLTypes["Contember_WorkspaceUpdateOpeningHoursEntityRelationInput"] | undefined,
	workingHours?: GraphQLTypes["Contember_WorkspaceUpdateWorkingHoursEntityRelationInput"] | undefined,
	defaultFreshingContainer?: GraphQLTypes["Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput"] | undefined,
	creditsProduct?: GraphQLTypes["Contember_WorkspaceUpdateCreditsProductEntityRelationInput"] | undefined,
	discountVatRate?: GraphQLTypes["Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput"] | undefined,
	name?: string | undefined,
	businessAddress?: string | undefined,
	migrationTest?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WorkspaceUpdateOpeningHoursEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_WeekHoursCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_WeekHoursUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WorkspaceUpsertOpeningHoursRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_WeekHoursUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WeekHoursUpdateInput"]: {
		mon?: string | undefined,
	tue?: string | undefined,
	wed?: string | undefined,
	thu?: string | undefined,
	fri?: string | undefined,
	sat?: string | undefined,
	sun?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WorkspaceUpsertOpeningHoursRelationInput"]: {
		update?: GraphQLTypes["Contember_WeekHoursUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_WeekHoursCreateInput"] | undefined
};
	["Contember_WorkspaceUpdateWorkingHoursEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_WeekHoursCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_WeekHoursUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WorkspaceUpsertWorkingHoursRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_WeekHoursUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WorkspaceUpsertWorkingHoursRelationInput"]: {
		update?: GraphQLTypes["Contember_WeekHoursUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_WeekHoursCreateInput"] | undefined
};
	["Contember_WorkspaceUpdateDefaultFreshingContainerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FreshingContainerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FreshingContainerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WorkspaceUpsertDefaultFreshingContainerRelationInput"]: {
		update?: GraphQLTypes["Contember_FreshingContainerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined
};
	["Contember_WorkspaceUpdateCreditsProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VirtualProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VirtualProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WorkspaceUpsertCreditsProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VirtualProductUpdateInput"]: {
		type?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	product?: GraphQLTypes["Contember_VirtualProductUpdateProductEntityRelationInput"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	recipientEmail?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VirtualProductUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutVirtualProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutVirtualProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VirtualProductUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductWithoutVirtualProductUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StocksManagerWithoutManagedProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StocksManagerWithoutManagedProductsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StocksManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StocksManagerWithoutManagedProductsUpdateInput"]: {
		staff?: GraphQLTypes["Contember_StocksManagerUpdateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StocksManagerUpdateStaffEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffWithoutStocksManagerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffWithoutStocksManagerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StocksManagerUpsertStaffRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffWithoutStocksManagerUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpdateBartenderEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BartenderWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BartenderWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertBartenderRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BartenderUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BartenderWithoutStaffUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertBartenderRelationInput"]: {
		update?: GraphQLTypes["Contember_BartenderWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BartenderWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateCourierEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CourierWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CourierWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertCourierRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CourierWithoutStaffUpdateInput"]: {
		routes?: Array<GraphQLTypes["Contember_CourierUpdateRoutesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	showInPicker?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CourierUpdateRoutesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryRouteWithoutCourierCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CourierUpdateRoutesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CourierUpsertRoutesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CourierUpdateRoutesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryRouteWithoutCourierUpdateInput"] | undefined
};
	["Contember_DeliveryRouteWithoutCourierUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	waypoints?: Array<GraphQLTypes["Contember_DeliveryRouteUpdateWaypointsEntityRelationInput"]> | undefined,
	dispatchAt?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRouteUpdateWaypointsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryWaypointWithoutRouteCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryRouteUpdateWaypointsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryRouteUpsertWaypointsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryRouteUpdateWaypointsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryWaypointWithoutRouteUpdateInput"] | undefined
};
	["Contember_DeliveryWaypointWithoutRouteUpdateInput"]: {
		estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	sortingOrder?: number | undefined,
	delivery?: GraphQLTypes["Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput"] | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryWaypointUpdateDeliveryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryWaypointUpsertDeliveryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"]: {
		priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDeliveryUpdateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	events?: Array<GraphQLTypes["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]> | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Contember_OrderDeliveryUpdateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryUpdateMethodEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDeliveryUpsertMethodRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined
};
	["Contember_DeliveryMethodUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CourierWithoutDeliveryMethodsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodUpsertCourierHandlersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodUpdateCourierHandlersRelationInput"]: {
		by?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CourierWithoutDeliveryMethodsUpdateInput"] | undefined
};
	["Contember_CourierWithoutDeliveryMethodsUpdateInput"]: {
		staff?: GraphQLTypes["Contember_CourierUpdateStaffEntityRelationInput"] | undefined,
	routes?: Array<GraphQLTypes["Contember_CourierUpdateRoutesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	showInPicker?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CourierUpdateStaffEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffWithoutCourierCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffWithoutCourierUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CourierUpsertStaffRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffWithoutCourierUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpdateCleanerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CleanerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CleanerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertCleanerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CleanerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CleanerWithoutStaffUpdateInput"]: {
		canReachTopShelf?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertCleanerRelationInput"]: {
		update?: GraphQLTypes["Contember_CleanerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CleanerWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateShiftsManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsManagerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsManagerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertShiftsManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ShiftsManagerWithoutStaffUpdateInput"]: {
		managedRoles?: Array<GraphQLTypes["Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsManagerUpdateManagedRolesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsManagerUpdateManagedRolesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ShiftsManagerUpsertManagedRolesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ShiftsManagerUpdateManagedRolesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"]: {
		shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutTypeCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableEmployeeRoleUpdateShiftGroupsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableShiftGroupWithoutTypeUpdateInput"] | undefined
};
	["Contember_TimetableShiftGroupWithoutTypeUpdateInput"]: {
		order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftWithoutGroupCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftGroupUpdateShiftsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftGroupUpsertShiftsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftGroupUpdateShiftsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableShiftWithoutGroupUpdateInput"] | undefined
};
	["Contember_TimetableShiftWithoutGroupUpdateInput"]: {
		locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined,
	queueItem?: GraphQLTypes["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsProfileWithoutShiftsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileWithoutShiftsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftUpsertShiftsProfileRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ShiftsProfileWithoutShiftsUpdateInput"]: {
		queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined,
	staff?: GraphQLTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileUpdateQueueItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ShiftsProfileUpsertQueueItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ShiftsProfileUpdateQueueItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined
};
	["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_EmployeeQueueWithoutItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_EmployeeQueueWithoutItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_EmployeeQueueItemUpsertQueueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_EmployeeQueueWithoutItemsUpdateInput"]: {
		shiftGroup?: GraphQLTypes["Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput"] | undefined,
	contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueUpdateShiftGroupEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutQueueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftGroupWithoutQueueUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_EmployeeQueueUpsertShiftGroupRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableShiftGroupWithoutQueueUpdateInput"]: {
		order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableDayWithoutShiftGroupsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableDayWithoutShiftGroupsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftGroupUpsertDayRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableDayWithoutShiftGroupsUpdateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	type?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	tipsCents?: number | undefined,
	notes?: Array<GraphQLTypes["Contember_TimetableDayUpdateNotesEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_TimetableDayUpdateVenueEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayUpdateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayUpdateNotesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableDayNoteWithoutDayCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableDayUpdateNotesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableDayUpsertNotesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableDayUpdateNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableDayNoteWithoutDayUpdateInput"] | undefined
};
	["Contember_TimetableDayNoteWithoutDayUpdateInput"]: {
		order?: number | undefined,
	note?: string | undefined,
	role?: GraphQLTypes["Contember_TimetableDayNoteUpdateRoleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayNoteUpdateRoleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableDayNoteUpsertRoleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined
};
	["Contember_TimetableEmployeeRoleUpdateInput"]: {
		shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsProfileWithoutRolesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableEmployeeRoleUpdateShiftsProfilesRelationInput"]: {
		by?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ShiftsProfileWithoutRolesUpdateInput"] | undefined
};
	["Contember_ShiftsProfileWithoutRolesUpdateInput"]: {
		queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined,
	staff?: GraphQLTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileUpdateStaffEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffWithoutShiftsProfileCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffWithoutShiftsProfileUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ShiftsProfileUpsertStaffRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffWithoutShiftsProfileUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertBusinessToBusinessManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"]: {
		managedBusinesses?: Array<GraphQLTypes["Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessToBusinessManagerUpdateManagedBusinessesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCustomerWithoutAccountManagerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessToBusinessManagerUpdateManagedBusinessesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"] | undefined
};
	["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"]: {
		name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined,
	owners?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateOwnersEntityRelationInput"]> | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutMemberOfBusinessesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerUpdateMembersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertMembersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCustomerUpdateMembersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"] | undefined
};
	["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCustomerWithoutOwnersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertOwnerOfBusinessesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateOwnerOfBusinessesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessCustomerWithoutOwnersUpdateInput"] | undefined
};
	["Contember_BusinessCustomerWithoutOwnersUpdateInput"]: {
		name?: string | undefined,
	customer?: GraphQLTypes["Contember_BusinessCustomerUpdateCustomerEntityRelationInput"] | undefined,
	members?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateMembersEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_BusinessCustomerUpdateCategoryEntityRelationInput"] | undefined,
	accountManager?: GraphQLTypes["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"] | undefined,
	logo?: GraphQLTypes["Contember_BusinessCustomerUpdateLogoEntityRelationInput"] | undefined,
	note?: string | undefined,
	businessServiceEvents?: Array<GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]> | undefined,
	doubleshotCoffeeEquipment?: GraphQLTypes["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerUpdateAccountManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertAccountManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessToBusinessManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"]: {
		staff?: GraphQLTypes["Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessToBusinessManagerUpdateStaffEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessToBusinessManagerUpsertStaffRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpdateUserEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UserWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UserWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertUserRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UserWithoutStaffUpdateInput"]: {
		name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutUserCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutUserUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UserUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutUserUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateCartsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CartWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateCartsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertCartsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateCartsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CartWithoutCustomerUpdateInput"] | undefined
};
	["Contember_CartWithoutCustomerUpdateInput"]: {
		confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CartUpdateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["Contember_CartUpdateChannelEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_CartUpdateOrderEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartUpdateOrderEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutCartCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutCartUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertOrderRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderWithoutCartUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutOrdersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutOrdersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutOrdersUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateShortcutListEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ApplicationShortcutListWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertShortcutListRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ApplicationShortcutListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ApplicationShortcutListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ApplicationShortcutListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ApplicationShortcutListItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ApplicationShortcutListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ApplicationShortcutListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ApplicationShortcutListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ApplicationShortcutListItemWithoutListUpdateInput"] | undefined
};
	["Contember_ApplicationShortcutListItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	application?: GraphQLTypes["Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ApplicationShortcutListItemUpdateApplicationEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ApplicationCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ApplicationUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ApplicationShortcutListItemUpsertApplicationRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ApplicationUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ApplicationUpdateInput"]: {
		order?: number | undefined,
	handle?: string | undefined,
	url?: string | undefined,
	color?: string | undefined,
	icon?: GraphQLTypes["Contember_ApplicationUpdateIconEntityRelationInput"] | undefined,
	name?: string | undefined,
	short?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ApplicationUpdateIconEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ApplicationUpsertIconRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ApplicationUpsertIconRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ApplicationShortcutListItemUpsertApplicationRelationInput"]: {
		update?: GraphQLTypes["Contember_ApplicationUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ApplicationCreateInput"] | undefined
};
	["Contember_ApplicationShortcutListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ApplicationShortcutListItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ApplicationShortcutListItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ApplicationShortcutListItemWithoutListCreateInput"] | undefined
};
	["Contember_CustomerUpsertShortcutListRelationInput"]: {
		update?: GraphQLTypes["Contember_ApplicationShortcutListWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ApplicationShortcutListWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerUpdateUserEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UserWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UserWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertUserRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UserWithoutCustomerUpdateInput"]: {
		name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserUpdateStaffEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffWithoutUserCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffWithoutUserUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UserUpsertStaffRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffWithoutUserUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpdateShiftsProfileEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsProfileWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertShiftsProfileRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ShiftsProfileWithoutStaffUpdateInput"]: {
		queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileUpdateRolesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ShiftsProfileUpsertRolesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ShiftsProfileUpdateRolesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"]: {
		shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsManagerWithoutManagedRolesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableEmployeeRoleUpdateShiftsManagersRelationInput"]: {
		by?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"] | undefined
};
	["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"]: {
		staff?: GraphQLTypes["Contember_ShiftsManagerUpdateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsManagerUpdateStaffEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffWithoutShiftsManagerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffWithoutShiftsManagerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ShiftsManagerUpsertStaffRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffWithoutShiftsManagerUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpdateStocksManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StocksManagerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StocksManagerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertStocksManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StocksManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StocksManagerWithoutStaffUpdateInput"]: {
		managedProducts?: Array<GraphQLTypes["Contember_StocksManagerUpdateManagedProductsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StocksManagerUpdateManagedProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutManagerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StocksManagerUpdateManagedProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StocksManagerUpsertManagedProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_StocksManagerUpdateManagedProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductWithoutManagerUpdateInput"] | undefined
};
	["Contember_ProductWithoutManagerUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateOrderItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderItemWithoutProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateOrderItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertOrderItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateOrderItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderItemWithoutProductUpdateInput"] | undefined
};
	["Contember_OrderItemWithoutProductUpdateInput"]: {
		unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderItemUpdateOrderEntityRelationInput"] | undefined,
	virtualProductEffects?: Array<GraphQLTypes["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]> | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderItemUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_OrderItemUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderItemUpdateOrderEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderItemUpsertOrderRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderWithoutItemsUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateDiscountsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderDiscountWithoutOrderCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderUpdateDiscountsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertDiscountsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderUpdateDiscountsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderDiscountWithoutOrderUpdateInput"] | undefined
};
	["Contember_OrderDiscountWithoutOrderUpdateInput"]: {
		discountCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDiscountUpdateVatRateEntityRelationInput"] | undefined,
	description?: string | undefined,
	type?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	customerCreditTransactions?: Array<GraphQLTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"]> | undefined,
	voucherRedemption?: GraphQLTypes["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDiscountUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDiscountUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_OrderDiscountUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderDiscountUpdateCustomerCreditTransactionsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"] | undefined
};
	["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	amountCents?: number | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerCreditWithoutTransactionsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerCreditWithoutTransactionsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerCreditWithoutTransactionsUpdateInput"]: {
		initialCreditCents?: number | undefined,
	remainingCreditsCents?: number | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Contember_CustomerCreditUpdateCustomerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_CustomerCreditUpdateVatRateEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"] | undefined,
	invoiceUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutCreditsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutCreditsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerCreditUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined
};
	["Contember_CustomerWithoutCreditsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateChannelsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelWithoutCustomersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateChannelsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertChannelsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelWithoutCustomersUpdateInput"] | undefined
};
	["Contember_ChannelWithoutCustomersUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelUpdatePublicTagsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutPublicInChannelsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelUpdatePublicTagsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertPublicTagsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ChannelUpdatePublicTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutPublicInChannelsUpdateInput"] | undefined
};
	["Contember_TagWithoutPublicInChannelsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertAllowChannelPaymentsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateAllowChannelPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"] | undefined
};
	["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"]: {
		order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateWithoutChannelPaymentsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateWithoutChannelPaymentsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelPaymentUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_VatRateWithoutChannelPaymentsUpdateInput"]: {
		name?: string | undefined,
	ratePermille?: number | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	channelDeliveries?: Array<GraphQLTypes["Contember_VatRateUpdateChannelDeliveriesEntityRelationInput"]> | undefined,
	preset?: GraphQLTypes["Contember_VatRatePreset"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelPaymentUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateWithoutChannelPaymentsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateWithoutChannelPaymentsCreateInput"] | undefined
};
	["Contember_TagUpsertAllowChannelPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutAllowOnlyForTagsCreateInput"] | undefined
};
	["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FulfillmentNoteWithoutTagsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdateFulfillmentNotesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertFulfillmentNotesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdateFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_FulfillmentNoteWithoutTagsUpdateInput"] | undefined
};
	["Contember_FulfillmentNoteWithoutTagsUpdateInput"]: {
		note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutFulfillmentNotesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteUpdateProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_FulfillmentNoteUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteUpdateProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductWithoutFulfillmentNotesUpdateInput"] | undefined
};
	["Contember_ProductWithoutFulfillmentNotesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_NestedProductCategoryProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateDeliveryZonesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertDeliveryZonesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateDeliveryZonesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"] | undefined
};
	["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	type?: GraphQLTypes["Contember_DeliveryZoneType"] | undefined,
	addresses?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateAddressesEntityRelationInput"]> | undefined,
	suitableDeliveryMethods?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateSuitableDeliveryMethodsEntityRelationInput"]> | undefined,
	deliveryZonesOfTags?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]> | undefined,
	addressesMetadata?: Array<GraphQLTypes["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutDeliveryZonesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryZoneUpdateDeliveryZonesOfTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutDeliveryZonesUpdateInput"] | undefined
};
	["Contember_TagWithoutDeliveryZonesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_TagUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TagUpdatePublicInChannelsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelWithoutPublicTagsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TagUpdatePublicInChannelsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TagUpsertPublicInChannelsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TagUpdatePublicInChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelWithoutPublicTagsUpdateInput"] | undefined
};
	["Contember_ChannelWithoutPublicTagsUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_ChannelUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ChannelUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutChannelsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutChannelsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ChannelUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutChannelsUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeadlineTemplateWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeadlineTemplateWithoutVenueUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertDeadlineTemplateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeadlineTemplateUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeadlineTemplateWithoutVenueUpdateInput"]: {
		startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	cutoff?: number | undefined,
	closed?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpsertDeadlineTemplateRelationInput"]: {
		update?: GraphQLTypes["Contember_DeadlineTemplateWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeadlineTemplateWithoutVenueCreateInput"] | undefined
};
	["Contember_VenueUpdateTimetableDaysEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableDayWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateTimetableDaysRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertTimetableDaysRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateTimetableDaysRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableDayWithoutVenueUpdateInput"] | undefined
};
	["Contember_TimetableDayWithoutVenueUpdateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	type?: GraphQLTypes["Contember_TimetableDayType"] | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableDayUpdateShiftGroupsEntityRelationInput"]> | undefined,
	tipsCents?: number | undefined,
	notes?: Array<GraphQLTypes["Contember_TimetableDayUpdateNotesEntityRelationInput"]> | undefined,
	meta?: GraphQLTypes["Contember_TimetableDayUpdateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayUpdateShiftGroupsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutDayCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableDayUpdateShiftGroupsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableDayUpsertShiftGroupsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableDayUpdateShiftGroupsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableShiftGroupWithoutDayUpdateInput"] | undefined
};
	["Contember_TimetableShiftGroupWithoutDayUpdateInput"]: {
		order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftGroupUpsertTypeRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"]: {
		shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableEmployeeRoleUpdateTimetableTemplatesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"] | undefined
};
	["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"]: {
		order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableTemplateUpdatePositionEntityRelationInput"] | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_TimetableTemplateUpdateVenueEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateUpdatePositionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableTemplateUpsertPositionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"]: {
		name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	shiftGroups?: Array<GraphQLTypes["Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput"]> | undefined,
	isDynamic?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftPositionUpdateShiftGroupsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutPositionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftPositionUpdateShiftGroupsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableShiftGroupWithoutPositionUpdateInput"] | undefined
};
	["Contember_TimetableShiftGroupWithoutPositionUpdateInput"]: {
		order?: number | undefined,
	shifts?: Array<GraphQLTypes["Contember_TimetableShiftGroupUpdateShiftsEntityRelationInput"]> | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_EmployeeQueueWithoutShiftGroupCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftGroupUpsertQueueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_EmployeeQueueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_EmployeeQueueUpdateItemsEntityRelationInput"]> | undefined,
	contemberBugFix?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutQueueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_EmployeeQueueUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_EmployeeQueueUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_EmployeeQueueUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_EmployeeQueueItemWithoutQueueUpdateInput"] | undefined
};
	["Contember_EmployeeQueueItemWithoutQueueUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	shiftsProfile?: GraphQLTypes["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined,
	shift?: GraphQLTypes["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsProfileWithoutQueueItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"]: {
		staff?: GraphQLTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftWithoutShiftsProfileCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileUpdateShiftsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ShiftsProfileUpsertShiftsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ShiftsProfileUpdateShiftsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"] | undefined
};
	["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"]: {
		locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftUpdateGroupEntityRelationInput"] | undefined,
	queueItem?: GraphQLTypes["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftUpdateGroupEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutShiftsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftUpsertGroupRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"]: {
		order?: number | undefined,
	day?: GraphQLTypes["Contember_TimetableShiftGroupUpdateDayEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_TimetableShiftGroupUpdateTypeEntityRelationInput"] | undefined,
	position?: GraphQLTypes["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"] | undefined,
	queue?: GraphQLTypes["Contember_TimetableShiftGroupUpdateQueueEntityRelationInput"] | undefined,
	generatedByTemplate?: GraphQLTypes["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupUpdatePositionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftGroupUpsertPositionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftPositionUniqueWhere"] | undefined
};
	["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"]: {
		name?: string | undefined,
	start?: string | undefined,
	end?: string | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	isDynamic?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftPositionUpdateTimetableTemplatesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableTemplateWithoutPositionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableShiftPositionUpdateTimetableTemplatesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableTemplateWithoutPositionUpdateInput"] | undefined
};
	["Contember_TimetableTemplateWithoutPositionUpdateInput"]: {
		order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_TimetableTemplateUpdateVenueEntityRelationInput"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableTemplateUpdateStaffOptionsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableTemplateUpsertStaffOptionsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TimetableTemplateUpdateStaffOptionsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"] | undefined
};
	["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"]: {
		startTime?: string | undefined,
	endTime?: string | undefined,
	autoAssignedEmployee?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateStaffOptionsUpdateAutoAssignedEmployeeEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsProfileCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined
};
	["Contember_ShiftsProfileUpdateInput"]: {
		queueItems?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateQueueItemsEntityRelationInput"]> | undefined,
	staff?: GraphQLTypes["Contember_ShiftsProfileUpdateStaffEntityRelationInput"] | undefined,
	roles?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateRolesEntityRelationInput"]> | undefined,
	shifts?: Array<GraphQLTypes["Contember_ShiftsProfileUpdateShiftsEntityRelationInput"]> | undefined,
	allowSelfUnassigning?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateStaffOptionsUpsertAutoAssignedEmployeeRelationInput"]: {
		update?: GraphQLTypes["Contember_ShiftsProfileUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileCreateInput"] | undefined
};
	["Contember_TimetableTemplateUpsertStaffOptionsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateStaffOptionsWithoutTimetableTemplateCreateInput"] | undefined
};
	["Contember_TimetableTemplateUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutTimetableTemplatesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutTimetableTemplatesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableTemplateUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutTimetableTemplatesUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdateStaffMembersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffWithoutVenuesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateStaffMembersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertStaffMembersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateStaffMembersRelationInput"]: {
		by?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_StaffWithoutVenuesUpdateInput"] | undefined
};
	["Contember_StaffWithoutVenuesUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpdatePhotoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertPhotoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffUpsertPhotoRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_StaffUpdateCourierManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CourierManagerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CourierManagerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertCourierManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CourierManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CourierManagerWithoutStaffUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertCourierManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_CourierManagerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CourierManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdatePickerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PickerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PickerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertPickerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PickerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PickerWithoutStaffUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertPickerRelationInput"]: {
		update?: GraphQLTypes["Contember_PickerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PickerWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateRemunerationProfileEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_RemunerationProfileWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_RemunerationProfileWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertRemunerationProfileRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_RemunerationProfileUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_RemunerationProfileWithoutStaffUpdateInput"]: {
		employmentInformation?: Array<GraphQLTypes["Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput"]> | undefined,
	workMonths?: GraphQLTypes["Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_RemunerationProfileUpdateEmploymentInformationEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_RemunerationProfileUpdateEmploymentInformationRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_RemunerationProfileUpsertEmploymentInformationRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_RemunerationProfileUpdateEmploymentInformationRelationInput"]: {
		by?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"] | undefined
};
	["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"]: {
		hourlyRate?: number | undefined,
	monthlySalary?: number | undefined,
	type?: GraphQLTypes["Contember_EmploymentType"] | undefined,
	name?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_RemunerationProfileUpsertEmploymentInformationRelationInput"]: {
		by?: GraphQLTypes["Contember_EmploymentInformationUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_EmploymentInformationWithoutRemunerationProfilesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_EmploymentInformationWithoutRemunerationProfilesCreateInput"] | undefined
};
	["Contember_RemunerationProfileUpdateWorkMonthsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_WorkMonthWithoutRemunerationProfileCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_RemunerationProfileUpsertWorkMonthsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_WorkMonthUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"]: {
		year?: number | undefined,
	month?: number | undefined,
	hoursWorked?: number | undefined,
	billableHours?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_RemunerationProfileUpsertWorkMonthsRelationInput"]: {
		update?: GraphQLTypes["Contember_WorkMonthWithoutRemunerationProfileUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_WorkMonthWithoutRemunerationProfileCreateInput"] | undefined
};
	["Contember_StaffUpsertRemunerationProfileRelationInput"]: {
		update?: GraphQLTypes["Contember_RemunerationProfileWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_RemunerationProfileWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateCashierEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CashierWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CashierWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertCashierRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CashierUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CashierWithoutStaffUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertCashierRelationInput"]: {
		update?: GraphQLTypes["Contember_CashierWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CashierWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateInternalBuyerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_InternalBuyerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_InternalBuyerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertInternalBuyerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_InternalBuyerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_InternalBuyerWithoutStaffUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertInternalBuyerRelationInput"]: {
		update?: GraphQLTypes["Contember_InternalBuyerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_InternalBuyerWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateSalesManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SalesManagerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SalesManagerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertSalesManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SalesManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_SalesManagerWithoutStaffUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	countries?: Array<GraphQLTypes["Contember_SalesManagerUpdateCountriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SalesManagerUpdateCountriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SalesManagerUpdateCountriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_SalesManagerUpsertCountriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_SalesManagerUpdateCountriesRelationInput"]: {
		by?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CountryUpdateInput"] | undefined
};
	["Contember_SalesManagerUpsertCountriesRelationInput"]: {
		by?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CountryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined
};
	["Contember_StaffUpsertSalesManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_SalesManagerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SalesManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateCashierManagerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CashierManagerWithoutStaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CashierManagerWithoutStaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertCashierManagerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CashierManagerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CashierManagerWithoutStaffUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertCashierManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_CashierManagerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CashierManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_VenueUpsertStaffMembersRelationInput"]: {
		by?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_StaffWithoutVenuesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutVenuesCreateInput"] | undefined
};
	["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueEmployeeRoleWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateEmployeeRolesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertEmployeeRolesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateEmployeeRolesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"] | undefined
};
	["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"]: {
		order?: number | undefined,
	employeePrivileges?: GraphQLTypes["Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput"] | undefined,
	role?: GraphQLTypes["Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput"] | undefined,
	getsTipsShare?: boolean | undefined,
	notesEnabled?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueEmployeeRoleUpdateEmployeePrivilegesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"]: {
		read?: boolean | undefined,
	unassign?: boolean | undefined,
	assign?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueEmployeeRoleUpsertEmployeePrivilegesRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetablesEmployeePrivilegesWithoutVenueEmployeeRoleCreateInput"] | undefined
};
	["Contember_VenueEmployeeRoleUpdateRoleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueEmployeeRoleUpsertRoleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined
};
	["Contember_VenueEmployeeRoleUpsertRoleRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined
};
	["Contember_VenueUpsertEmployeeRolesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueEmployeeRoleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VenueEmployeeRoleWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueEmployeeRoleWithoutVenueCreateInput"] | undefined
};
	["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdatePointOfSaleApplicationDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"]: {
		code?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	attempts?: Array<GraphQLTypes["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IdentificationAttemptWithoutCodeCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeUpdateAttemptsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IdentificationCodeUpsertAttemptsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_IdentificationCodeUpdateAttemptsRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_IdentificationAttemptWithoutCodeUpdateInput"] | undefined
};
	["Contember_IdentificationAttemptWithoutCodeUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	user?: GraphQLTypes["Contember_IdentificationAttemptUpdateUserEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationAttemptUpdateUserEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UserWithoutIdentificationAttemptsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UserWithoutIdentificationAttemptsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IdentificationAttemptUpsertUserRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_UserWithoutIdentificationAttemptsUpdateInput"]: {
		name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined,
	roles?: GraphQLTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserUpdateDevicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UserDeviceWithoutUserCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UserUpdateDevicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UserUpsertDevicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_UserUpdateDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_UserDeviceWithoutUserUpdateInput"] | undefined
};
	["Contember_UserDeviceWithoutUserUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	deviceId?: string | undefined,
	notificationsEnabled?: boolean | undefined,
	appVersion?: string | undefined,
	osVersion?: string | undefined,
	apiVersion?: string | undefined,
	anonymousSession?: GraphQLTypes["Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserDeviceUpdateAnonymousSessionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AnonymousSessionWithoutDevicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AnonymousSessionWithoutDevicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UserDeviceUpsertAnonymousSessionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AnonymousSessionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_AnonymousSessionWithoutDevicesUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	customer?: GraphQLTypes["Contember_AnonymousSessionUpdateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AnonymousSessionUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutAnonymousSessionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutAnonymousSessionUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AnonymousSessionUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutAnonymousSessionUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateInvoicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_InvoiceWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateInvoicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertInvoicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateInvoicesRelationInput"]: {
		by?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_InvoiceWithoutCustomerUpdateInput"] | undefined
};
	["Contember_InvoiceWithoutCustomerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	orderPayments?: Array<GraphQLTypes["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]> | undefined,
	address?: GraphQLTypes["Contember_InvoiceUpdateAddressEntityRelationInput"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderPaymentWithoutInvoiceCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_InvoiceUpdateOrderPaymentsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_InvoiceUpsertOrderPaymentsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_InvoiceUpdateOrderPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderPaymentWithoutInvoiceUpdateInput"] | undefined
};
	["Contember_OrderPaymentWithoutInvoiceUpdateInput"]: {
		data?: GraphQLTypes["Json"] | undefined,
	paymentCents?: number | undefined,
	method?: GraphQLTypes["Contember_OrderPaymentUpdateMethodEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderPaymentUpdateEventsEntityRelationInput"]> | undefined,
	order?: GraphQLTypes["Contember_OrderPaymentUpdateOrderEntityRelationInput"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderPaymentUpdateVatRateEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentUpdateMetaEntityRelationInput"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderPaymentUpdateMethodEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PaymentMethodCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PaymentMethodUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderPaymentUpsertMethodRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined
};
	["Contember_OrderPaymentUpsertMethodRelationInput"]: {
		update?: GraphQLTypes["Contember_PaymentMethodUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodCreateInput"] | undefined
};
	["Contember_OrderPaymentUpdateEventsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderEventWithoutPaymentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderPaymentUpdateEventsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderPaymentUpsertEventsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderPaymentUpdateEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderEventWithoutPaymentUpdateInput"] | undefined
};
	["Contember_OrderEventWithoutPaymentUpdateInput"]: {
		type?: string | undefined,
	data?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_OrderEventUpdateOrderEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderEventUpdateDeliveryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderEventUpdateOrderEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutEventsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutEventsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderEventUpsertOrderRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderWithoutEventsUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateDeliveryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderDeliveryWithoutOrderCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderDeliveryWithoutOrderUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertDeliveryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderDeliveryWithoutOrderUpdateInput"]: {
		priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]> | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Contember_OrderDeliveryUpdateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDeliveryUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_OrderDeliveryUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryWaypointWithoutDeliveryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"]: {
		estimatedArrival?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	route?: GraphQLTypes["Contember_DeliveryWaypointUpdateRouteEntityRelationInput"] | undefined,
	sortingOrder?: number | undefined,
	estimatedDistance?: number | undefined,
	estimatedDuration?: number | undefined,
	originDeparture?: GraphQLTypes["DateTime"] | undefined,
	originAddress?: string | undefined,
	transitMode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryWaypointUpdateRouteEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryRouteWithoutWaypointsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryRouteWithoutWaypointsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryWaypointUpsertRouteRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryRouteWithoutWaypointsUpdateInput"]: {
		courier?: GraphQLTypes["Contember_DeliveryRouteUpdateCourierEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	dispatchAt?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryRouteUpdateCourierEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CourierWithoutRoutesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CourierWithoutRoutesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryRouteUpsertCourierRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CourierWithoutRoutesUpdateInput"]: {
		staff?: GraphQLTypes["Contember_CourierUpdateStaffEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	showInPicker?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CourierUpdateDeliveryMethodsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CourierUpdateDeliveryMethodsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CourierUpsertDeliveryMethodsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CourierUpdateDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"] | undefined
};
	["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]> | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_DeliveryMethodUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	locale?: GraphQLTypes["Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutDeliveryMethodsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutDeliveryMethodsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutDeliveryMethodsUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateProductPackingsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductPackingLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateProductPackingsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertProductPackingsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateProductPackingsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	shortName?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["Contember_ProductPackingLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductPackingWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductPackingWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductPackingLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductPackingUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductPackingWithoutLocalesUpdateInput"]: {
		name?: string | undefined,
	image?: GraphQLTypes["Contember_ProductPackingUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	volumeMl?: number | undefined,
	icon?: GraphQLTypes["Contember_ProductPackingUpdateIconEntityRelationInput"] | undefined,
	shortName?: string | undefined,
	preparedPackingQuantities?: Array<GraphQLTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"]> | undefined,
	isPackedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductPackingUpdatePreparedPackingQuantitiesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PreparedPackingQuantityWithoutPackingCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductPackingUpdatePreparedPackingQuantitiesRelationInput"]: {
		by?: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"] | undefined
};
	["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"]: {
		date?: GraphQLTypes["Date"] | undefined,
	quantity?: number | undefined,
	recipe?: GraphQLTypes["Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput"] | undefined,
	packedInCardboard?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PreparedPackingQuantityUpdateRecipeEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductRecipeCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PreparedPackingQuantityUpsertRecipeRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined
};
	["Contember_ProductRecipeUpdateInput"]: {
		name?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductRecipeUpdateProductsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductRecipeUpdateImageEntityRelationInput"] | undefined,
	icon?: GraphQLTypes["Contember_ProductRecipeUpdateIconEntityRelationInput"] | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductRecipeUpdateLocalesEntityRelationInput"]> | undefined,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutRecipeCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeUpdateProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductRecipeUpdateProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductWithoutRecipeUpdateInput"] | undefined
};
	["Contember_ProductWithoutRecipeUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_ProductLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductLocaleUpdateLocaleEntityRelationInput"] | undefined,
	featureList?: GraphQLTypes["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutProductsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutProductsUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateProductRecipesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateProductRecipesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertProductRecipesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateProductRecipesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_ProductRecipeLocaleUpdateRootEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductRecipeWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductRecipeUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductRecipeWithoutLocalesUpdateInput"]: {
		name?: string | undefined,
	products?: Array<GraphQLTypes["Contember_ProductRecipeUpdateProductsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	color?: string | undefined,
	image?: GraphQLTypes["Contember_ProductRecipeUpdateImageEntityRelationInput"] | undefined,
	icon?: GraphQLTypes["Contember_ProductRecipeUpdateIconEntityRelationInput"] | undefined,
	ingredients?: Array<GraphQLTypes["Contember_ProductRecipeUpdateIngredientsEntityRelationInput"]> | undefined,
	needsPrepping?: boolean | undefined,
	gutTuning?: number | undefined,
	freshingContainer?: GraphQLTypes["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"] | undefined,
	description?: string | undefined,
	nutritionFacts?: GraphQLTypes["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeUpdateFreshingContainerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FreshingContainerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeUpsertFreshingContainerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FreshingContainerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductRecipeUpsertFreshingContainerRelationInput"]: {
		update?: GraphQLTypes["Contember_FreshingContainerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined
};
	["Contember_ProductRecipeUpdateNutritionFactsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_NutritionFactsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_NutritionFactsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeUpsertNutritionFactsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_NutritionFactsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_NutritionFactsUpdateInput"]: {
		energyKiloJoule?: number | undefined,
	energyKiloCalorie?: number | undefined,
	fatsGrams?: number | undefined,
	fatsOfWhichSaturatedGrams?: number | undefined,
	carbohydrateGrams?: number | undefined,
	carbohydrateOfWhichSugarsGrams?: number | undefined,
	proteinGrams?: number | undefined,
	fibreGrams?: number | undefined,
	saltGrams?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeUpsertNutritionFactsRelationInput"]: {
		update?: GraphQLTypes["Contember_NutritionFactsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_NutritionFactsCreateInput"] | undefined
};
	["Contember_ProductRecipeLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductRecipeWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertProductRecipesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertPointOfSaleCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdatePointOfSaleCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"]: {
		title?: string | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"]: {
		order?: number | undefined,
	slug?: string | undefined,
	products?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryUpdateProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"]: {
		order?: number | undefined,
	product?: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"] | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryProductUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined
};
	["Contember_ProductUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpdateCustomerPricesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateVirtualProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VirtualProductWithoutProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VirtualProductWithoutProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertVirtualProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VirtualProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VirtualProductWithoutProductUpdateInput"]: {
		type?: GraphQLTypes["Contember_VirtualProductType"] | undefined,
	voucherCreditCents?: number | undefined,
	physicalRepresentation?: GraphQLTypes["Contember_VirtualProductPhysicalRepresentationType"] | undefined,
	recipientEmail?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductUpsertVirtualProductRelationInput"]: {
		update?: GraphQLTypes["Contember_VirtualProductWithoutProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductWithoutProductCreateInput"] | undefined
};
	["Contember_ProductUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductUpdatePageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertPageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PageUpdateInput"]: {
		locales?: Array<GraphQLTypes["Contember_PageUpdateLocalesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PageLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PageUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PageUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PageUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PageLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_PageLocaleWithoutRootUpdateInput"]: {
		title?: string | undefined,
	link?: GraphQLTypes["Contember_PageLocaleUpdateLinkEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_PageLocaleUpdateLocaleEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_PageLocaleUpdateSeoEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_PageLocaleUpdateContentEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_PageLocaleUpdateCoverEntityRelationInput"] | undefined,
	theme?: GraphQLTypes["Contember_PageTheme"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PageLocaleUpdateSeoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SeoCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SeoUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PageLocaleUpsertSeoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_SeoUpdateInput"]: {
		title?: string | undefined,
	description?: string | undefined,
	ogTitle?: string | undefined,
	ogDescription?: string | undefined,
	ogImage?: GraphQLTypes["Contember_SeoUpdateOgImageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeoUpdateOgImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_SeoUpsertOgImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_SeoUpsertOgImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_PageLocaleUpsertSeoRelationInput"]: {
		update?: GraphQLTypes["Contember_SeoUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SeoCreateInput"] | undefined
};
	["Contember_PageLocaleUpdateContentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ContentUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PageLocaleUpsertContentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PageLocaleUpsertContentRelationInput"]: {
		update?: GraphQLTypes["Contember_ContentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined
};
	["Contember_PageLocaleUpdateCoverEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CoverCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CoverUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PageLocaleUpsertCoverRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CoverUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PageLocaleUpsertCoverRelationInput"]: {
		update?: GraphQLTypes["Contember_CoverUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CoverCreateInput"] | undefined
};
	["Contember_PageUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PageLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PageLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_ProductUpsertPageRelationInput"]: {
		update?: GraphQLTypes["Contember_PageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PageCreateInput"] | undefined
};
	["Contember_ProductUpdateSalesDaysEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductSalesDayUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateSalesWeeksEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductSalesWeekUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateSalesMonthsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductSalesMonthUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FulfillmentNoteWithoutProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateFulfillmentNotesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductUpsertFulfillmentNotesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpdateFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_FulfillmentNoteWithoutProductsUpdateInput"] | undefined
};
	["Contember_FulfillmentNoteWithoutProductsUpdateInput"]: {
		note?: string | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TagWithoutFulfillmentNotesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteUpdateTagsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_FulfillmentNoteUpsertTagsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteUpdateTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TagWithoutFulfillmentNotesUpdateInput"] | undefined
};
	["Contember_TagWithoutFulfillmentNotesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	customers?: Array<GraphQLTypes["Contember_TagUpdateCustomersEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_TagUpdateProductsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_TagUpdateCategoriesEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_TagUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	nestedCategories?: Array<GraphQLTypes["Contember_TagUpdateNestedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_TagUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	publicInChannels?: Array<GraphQLTypes["Contember_TagUpdatePublicInChannelsEntityRelationInput"]> | undefined,
	allowChannelPayments?: Array<GraphQLTypes["Contember_TagUpdateAllowChannelPaymentsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteUpsertTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutFulfillmentNotesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutFulfillmentNotesCreateInput"] | undefined
};
	["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutFulfillmentNotesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_FulfillmentNoteUpsertVenuesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteUpdateVenuesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VenueWithoutFulfillmentNotesUpdateInput"] | undefined
};
	["Contember_VenueWithoutFulfillmentNotesUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableTemplateWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateTimetableTemplatesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertTimetableTemplatesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateTimetableTemplatesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TimetableTemplateWithoutVenueUpdateInput"] | undefined
};
	["Contember_TimetableTemplateWithoutVenueUpdateInput"]: {
		order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableTemplateUpdatePositionEntityRelationInput"] | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableTemplateUpsertEmployeeRoleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"]: {
		shiftGroups?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftGroupsEntityRelationInput"]> | undefined,
	shiftsProfiles?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsProfilesEntityRelationInput"]> | undefined,
	shiftsManagers?: Array<GraphQLTypes["Contember_TimetableEmployeeRoleUpdateShiftsManagersEntityRelationInput"]> | undefined,
	name?: string | undefined,
	color?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableTemplateUpsertEmployeeRoleRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutTimetableTemplatesCreateInput"] | undefined
};
	["Contember_VenueUpsertTimetableTemplatesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableTemplateWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateWithoutVenueCreateInput"] | undefined
};
	["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertPointOfSaleTerminalsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdatePointOfSaleTerminalsRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"] | undefined
};
	["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	applicationDevices?: Array<GraphQLTypes["Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput"]> | undefined,
	ipAddress?: string | undefined,
	port?: number | undefined,
	type?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleTerminalUpdateApplicationDevicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleTerminalUpdateApplicationDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertPointOfSalePrintersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdatePointOfSalePrintersRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSalePrinterWithoutVenueUpdateInput"] | undefined
};
	["Contember_PointOfSalePrinterWithoutVenueUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesReceipt?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]> | undefined,
	applicationDevicesTicket?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]> | undefined,
	ticketCategories?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeviceIdentifierWithoutDeviceCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeviceIdentifierWithoutDeviceUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeviceIdentifierUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeviceIdentifierWithoutDeviceUpdateInput"]: {
		internalName?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	resolution?: string | undefined,
	devicePixelRatio?: number | undefined,
	updatedByUser?: GraphQLTypes["Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput"] | undefined,
	identifier?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeviceIdentifierUpdateUpdatedByUserEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UserCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UserUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UserUpdateInput"]: {
		name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	devices?: Array<GraphQLTypes["Contember_UserUpdateDevicesEntityRelationInput"]> | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IdentificationAttemptWithoutUserCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UserUpdateIdentificationAttemptsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UserUpsertIdentificationAttemptsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_UserUpdateIdentificationAttemptsRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_IdentificationAttemptWithoutUserUpdateInput"] | undefined
};
	["Contember_IdentificationAttemptWithoutUserUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	code?: GraphQLTypes["Contember_IdentificationAttemptUpdateCodeEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationAttemptUpdateCodeEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IdentificationCodeWithoutAttemptsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeWithoutAttemptsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IdentificationAttemptUpsertCodeRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_IdentificationCodeWithoutAttemptsUpdateInput"]: {
		code?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	locales?: Array<GraphQLTypes["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	applicationDevices?: Array<GraphQLTypes["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IdentificationCodeUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_IdentificationCodeUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"]: {
		successMessage?: string | undefined,
	locale?: GraphQLTypes["Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutIdentificationCodesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutIdentificationCodesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IdentificationCodeLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutIdentificationCodesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_ProductLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	root?: GraphQLTypes["Contember_ProductLocaleUpdateRootEntityRelationInput"] | undefined,
	featureList?: GraphQLTypes["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"] | undefined,
	printableName?: string | undefined,
	firstSticker?: string | undefined,
	secondSticker?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductWithoutLocalesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	orderItems?: Array<GraphQLTypes["Contember_ProductUpdateOrderItemsEntityRelationInput"]> | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutLocalesCreateInput"] | undefined
};
	["Contember_ProductLocaleUpdateFeatureListEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductLocaleUpsertFeatureListRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductLocaleUpsertFeatureListRelationInput"]: {
		update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_LocaleUpsertProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdatePointOfSaleCategoryProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"]: {
		title?: string | undefined,
	root?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"]: {
		order?: number | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput"] | undefined,
	product?: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductUpdateCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"]: {
		order?: number | undefined,
	slug?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"]: {
		title?: string | undefined,
	locale?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertDeliveryMethodsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	description?: string | undefined,
	trackingCodeUrlTemplate?: string | undefined,
	processingTitle?: string | undefined,
	processingText?: string | undefined,
	processingIconUrl?: string | undefined,
	dispatchedTitle?: string | undefined,
	dispatchedText?: string | undefined,
	dispatchedIconUrl?: string | undefined,
	fulfilledTitle?: string | undefined,
	fulfilledText?: string | undefined,
	fulfilledIconUrl?: string | undefined,
	root?: GraphQLTypes["Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryMethodWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryMethodWithoutLocalesUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	enabled?: boolean | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_DeliveryMethodUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	requireDeliveryAddress?: boolean | undefined,
	suitableForDeliveryZones?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateSuitableForDeliveryZonesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateChannelsEntityRelationInput"]> | undefined,
	courierHandlers?: Array<GraphQLTypes["Contember_DeliveryMethodUpdateCourierHandlersEntityRelationInput"]> | undefined,
	enableDeliveryTracking?: boolean | undefined,
	timelinePreset?: GraphQLTypes["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodUpdateTimelinePresetEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_DeliveryMethodUpsertTimelinePresetRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryTimelinePresetUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"]: {
		type?: GraphQLTypes["Contember_DeliveryTimelineType"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_DeliveryMethodUpsertTimelinePresetRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryTimelinePresetWithoutDeliveryMethodCreateInput"] | undefined
};
	["Contember_DeliveryMethodLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryMethodWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertIngredientSuppliersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateIngredientSuppliersRelationInput"]: {
		by?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IngredientSupplierWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IngredientSupplierLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IngredientSupplierUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_IngredientSupplierWithoutLocalesUpdateInput"]: {
		internalName?: string | undefined,
	internalNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_IngredientSupplierWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertIngredientSuppliersRelationInput"]: {
		by?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateIdentificationCodesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertIdentificationCodesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateIdentificationCodesRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"]: {
		successMessage?: string | undefined,
	root?: GraphQLTypes["Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IdentificationCodeWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IdentificationCodeLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IdentificationCodeUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_IdentificationCodeWithoutLocalesUpdateInput"]: {
		code?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	attempts?: Array<GraphQLTypes["Contember_IdentificationCodeUpdateAttemptsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	applicationDevices?: Array<GraphQLTypes["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IdentificationCodeUpdateApplicationDevicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeUpdateApplicationDevicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IdentificationCodeUpsertApplicationDevicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_IdentificationCodeUpdateApplicationDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput"] | undefined,
	ipAddress?: string | undefined,
	port?: number | undefined,
	type?: GraphQLTypes["Contember_PointOfSaleTerminalType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleTerminalUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleTerminalUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdateOrdersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateOrdersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertOrdersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderWithoutVenueUpdateInput"] | undefined
};
	["Contember_OrderWithoutVenueUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderItemWithoutOrderCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderItemWithoutOrderUpdateInput"] | undefined
};
	["Contember_OrderItemWithoutOrderUpdateInput"]: {
		unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined,
	virtualProductEffects?: Array<GraphQLTypes["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]> | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	product?: GraphQLTypes["Contember_OrderItemUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VirtualProductEffectWithoutOrderItemCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderItemUpdateVirtualProductEffectsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderItemUpsertVirtualProductEffectsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderItemUpdateVirtualProductEffectsRelationInput"]: {
		by?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"] | undefined
};
	["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"] | undefined,
	resultVouchers?: Array<GraphQLTypes["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	amountCents?: number | undefined,
	customerCredit?: GraphQLTypes["Contember_CustomerCreditTransactionUpdateCustomerCreditEntityRelationInput"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"]: {
		discountCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDiscountUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDiscountUpdateOrderEntityRelationInput"] | undefined,
	description?: string | undefined,
	type?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	voucherRedemption?: GraphQLTypes["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDiscountUpdateOrderEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutDiscountsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutDiscountsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDiscountUpsertOrderRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderWithoutDiscountsUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateCartEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CartWithoutOrderCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CartWithoutOrderUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertCartRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CartWithoutOrderUpdateInput"]: {
		confirmedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CartUpdateItemsEntityRelationInput"]> | undefined,
	handle?: string | undefined,
	state?: GraphQLTypes["Contember_CartState"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	channel?: GraphQLTypes["Contember_CartUpdateChannelEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CartUpdateCustomerEntityRelationInput"] | undefined,
	deliveryAddress?: GraphQLTypes["Contember_CartUpdateDeliveryAddressEntityRelationInput"] | undefined,
	billingAddress?: GraphQLTypes["Contember_CartUpdateBillingAddressEntityRelationInput"] | undefined,
	deliveryOption?: string | undefined,
	paymentOption?: string | undefined,
	delivery?: GraphQLTypes["Contember_CartUpdateDeliveryEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_CartUpdatePaymentEntityRelationInput"] | undefined,
	billingSubject?: GraphQLTypes["Contember_CartUpdateBillingSubjectEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_CartUpdatePriceLinesEntityRelationInput"]> | undefined,
	summary?: GraphQLTypes["Contember_CartUpdateSummaryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutCartsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutCartsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutCartsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_InvoicingPlanWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_InvoicingPlanWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertInvoicingPlanRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_InvoicingPlanUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_InvoicingPlanWithoutCustomerUpdateInput"]: {
		preset?: GraphQLTypes["Contember_InvoicingPlanUpdatePresetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoicingPlanUpdatePresetEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_InvoicingPlanUpsertPresetRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_InvoicingPresetUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"]: {
		type?: GraphQLTypes["Contember_InvoicingPresetType"] | undefined,
	startsAt?: GraphQLTypes["DateTime"] | undefined,
	endsAt?: GraphQLTypes["DateTime"] | undefined,
	intervalOfDays?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoicingPlanUpsertPresetRelationInput"]: {
		update?: GraphQLTypes["Contember_InvoicingPresetWithoutInvoicingPlanUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_InvoicingPresetWithoutInvoicingPlanCreateInput"] | undefined
};
	["Contember_CustomerUpsertInvoicingPlanRelationInput"]: {
		update?: GraphQLTypes["Contember_InvoicingPlanWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_InvoicingPlanWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BillingSubjectWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateBillingSubjectsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertBillingSubjectsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateBillingSubjectsRelationInput"]: {
		by?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BillingSubjectWithoutCustomerUpdateInput"] | undefined
};
	["Contember_BillingSubjectWithoutCustomerUpdateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectUpdateCountryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CountryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BillingSubjectUpsertCountryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CountryUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_BillingSubjectUpsertCountryRelationInput"]: {
		update?: GraphQLTypes["Contember_CountryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CountryCreateInput"] | undefined
};
	["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_InvoiceWithoutBillingSubjectCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BillingSubjectUpdateInvoicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BillingSubjectUpsertInvoicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BillingSubjectUpdateInvoicesRelationInput"]: {
		by?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_InvoiceWithoutBillingSubjectUpdateInput"] | undefined
};
	["Contember_InvoiceWithoutBillingSubjectUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_InvoiceUpdateCustomerEntityRelationInput"] | undefined,
	orderPayments?: Array<GraphQLTypes["Contember_InvoiceUpdateOrderPaymentsEntityRelationInput"]> | undefined,
	address?: GraphQLTypes["Contember_InvoiceUpdateAddressEntityRelationInput"] | undefined,
	publicUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceUpdateAddressEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressWithoutInvoicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressWithoutInvoicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_InvoiceUpsertAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_AddressWithoutInvoicesUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutBillingAddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertBillingAddressOfOrdersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AddressUpdateBillingAddressOfOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderWithoutBillingAddressUpdateInput"] | undefined
};
	["Contember_OrderWithoutBillingAddressUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateEventsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderEventWithoutOrderCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderUpdateEventsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertEventsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderUpdateEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderEventWithoutOrderUpdateInput"] | undefined
};
	["Contember_OrderEventWithoutOrderUpdateInput"]: {
		type?: string | undefined,
	data?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	payment?: GraphQLTypes["Contember_OrderEventUpdatePaymentEntityRelationInput"] | undefined,
	delivery?: GraphQLTypes["Contember_OrderEventUpdateDeliveryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderEventUpdatePaymentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderPaymentWithoutEventsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderPaymentWithoutEventsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderEventUpsertPaymentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderPaymentWithoutEventsUpdateInput"]: {
		data?: GraphQLTypes["Json"] | undefined,
	paymentCents?: number | undefined,
	method?: GraphQLTypes["Contember_OrderPaymentUpdateMethodEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_OrderPaymentUpdateOrderEntityRelationInput"] | undefined,
	invoice?: GraphQLTypes["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderPaymentUpdateVatRateEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentUpdateMetaEntityRelationInput"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderPaymentUpdateOrderEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutPaymentsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutPaymentsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderPaymentUpsertOrderRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderWithoutPaymentsUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateBillingAddressEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertBillingAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderDeliveryWithoutAddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderDeliveryWithoutAddressUpdateInput"] | undefined
};
	["Contember_OrderDeliveryWithoutAddressUpdateInput"]: {
		priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDeliveryUpdateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]> | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryUpdateOrderEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutDeliveryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutDeliveryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDeliveryUpsertOrderRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderWithoutDeliveryUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdatePriceLinesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderPriceLineUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderUpdatePaymentsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderPaymentWithoutOrderCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderUpdatePaymentsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertPaymentsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderUpdatePaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderPaymentWithoutOrderUpdateInput"] | undefined
};
	["Contember_OrderPaymentWithoutOrderUpdateInput"]: {
		data?: GraphQLTypes["Json"] | undefined,
	paymentCents?: number | undefined,
	method?: GraphQLTypes["Contember_OrderPaymentUpdateMethodEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderPaymentState"] | undefined,
	approvedAt?: GraphQLTypes["DateTime"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderPaymentUpdateEventsEntityRelationInput"]> | undefined,
	invoice?: GraphQLTypes["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"] | undefined,
	walletType?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicReceiptUrl?: string | undefined,
	priceCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderPaymentUpdateVatRateEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_OrderPaymentUpdateMetaEntityRelationInput"] | undefined,
	otherCurrencyPriceCents?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderPaymentUpdateInvoiceEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_InvoiceWithoutOrderPaymentsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_InvoiceWithoutOrderPaymentsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderPaymentUpsertInvoiceRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_InvoiceWithoutOrderPaymentsUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	publicKey?: string | undefined,
	fakturoidId?: string | undefined,
	fakturoidData?: string | undefined,
	legacyUrl?: string | undefined,
	customer?: GraphQLTypes["Contember_InvoiceUpdateCustomerEntityRelationInput"] | undefined,
	address?: GraphQLTypes["Contember_InvoiceUpdateAddressEntityRelationInput"] | undefined,
	publicUrl?: string | undefined,
	billingSubject?: GraphQLTypes["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_InvoiceUpdateBillingSubjectEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BillingSubjectWithoutInvoicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BillingSubjectWithoutInvoicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_InvoiceUpsertBillingSubjectRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BillingSubjectWithoutInvoicesUpdateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutBillingSubjectsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutBillingSubjectsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BillingSubjectUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutBillingSubjectsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCustomerPriceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertDefaultDeliveryAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	deliveryAddressOfOrderDeliveries?: Array<GraphQLTypes["Contember_AddressUpdateDeliveryAddressOfOrderDeliveriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpsertDefaultDeliveryAddressRelationInput"]: {
		update?: GraphQLTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutDefaultDeliveryAddressOfCustomerCreateInput"] | undefined
};
	["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertDefaultBillingSubjectRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutBillingSubjectCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BillingSubjectUpdateOrdersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BillingSubjectUpsertOrdersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BillingSubjectUpdateOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderWithoutBillingSubjectUpdateInput"] | undefined
};
	["Contember_OrderWithoutBillingSubjectUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateSummaryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderSummaryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderUpdateGratuityEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_GratuityWithoutOrderCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_GratuityWithoutOrderUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertGratuityRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_GratuityUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_GratuityWithoutOrderUpdateInput"]: {
		amountCents?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpsertGratuityRelationInput"]: {
		update?: GraphQLTypes["Contember_GratuityWithoutOrderUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_GratuityWithoutOrderCreateInput"] | undefined
};
	["Contember_OrderUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutOrdersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutOrdersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutOrdersUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FulfillmentNoteWithoutVenuesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateFulfillmentNotesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertFulfillmentNotesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_FulfillmentNoteWithoutVenuesUpdateInput"] | undefined
};
	["Contember_FulfillmentNoteWithoutVenuesUpdateInput"]: {
		note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CartItemWithoutFulfillmentNotesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_FulfillmentNoteUpsertCartItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteUpdateCartItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CartItemWithoutFulfillmentNotesUpdateInput"] | undefined
};
	["Contember_CartItemWithoutFulfillmentNotesUpdateInput"]: {
		quantity?: number | undefined,
	cart?: GraphQLTypes["Contember_CartItemUpdateCartEntityRelationInput"] | undefined,
	product?: GraphQLTypes["Contember_CartItemUpdateProductEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	fulfillmentNote?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_FulfillmentNoteUpsertCartItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CartItemWithoutFulfillmentNotesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CartItemWithoutFulfillmentNotesCreateInput"] | undefined
};
	["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderItemWithoutFulfillmentNotesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_FulfillmentNoteUpsertOrderItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteUpdateOrderItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"] | undefined
};
	["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"]: {
		unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderItemUpdateOrderEntityRelationInput"] | undefined,
	virtualProductEffects?: Array<GraphQLTypes["Contember_OrderItemUpdateVirtualProductEffectsEntityRelationInput"]> | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	product?: GraphQLTypes["Contember_OrderItemUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductWithoutOrderItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutOrderItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderItemUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductWithoutOrderItemsUpdateInput"]: {
		name?: string | undefined,
	code?: string | undefined,
	priceCents?: number | undefined,
	notDiscountable?: boolean | undefined,
	packing?: GraphQLTypes["Contember_ProductUpdatePackingEntityRelationInput"] | undefined,
	recipe?: GraphQLTypes["Contember_ProductUpdateRecipeEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_ProductUpdateTagsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductUpdateCategoriesEntityRelationInput"]> | undefined,
	vatRate?: GraphQLTypes["Contember_ProductUpdateVatRateEntityRelationInput"] | undefined,
	stocks?: Array<GraphQLTypes["Contember_ProductUpdateStocksEntityRelationInput"]> | undefined,
	groupPrices?: Array<GraphQLTypes["Contember_ProductUpdateGroupPricesEntityRelationInput"]> | undefined,
	image?: GraphQLTypes["Contember_ProductUpdateImageEntityRelationInput"] | undefined,
	description?: string | undefined,
	internalNote?: string | undefined,
	cartItems?: Array<GraphQLTypes["Contember_ProductUpdateCartItemsEntityRelationInput"]> | undefined,
	availability?: GraphQLTypes["Contember_ProductAvailability"] | undefined,
	order?: number | undefined,
	manager?: GraphQLTypes["Contember_ProductUpdateManagerEntityRelationInput"] | undefined,
	maximumQuantityPerOrder?: number | undefined,
	inheritedCategories?: Array<GraphQLTypes["Contember_ProductUpdateInheritedCategoriesEntityRelationInput"]> | undefined,
	deliveryZones?: Array<GraphQLTypes["Contember_ProductUpdateDeliveryZonesEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductUpdateLocalesEntityRelationInput"]> | undefined,
	archivedAt?: GraphQLTypes["DateTime"] | undefined,
	customerPrices?: Array<GraphQLTypes["Contember_ProductUpdateCustomerPricesEntityRelationInput"]> | undefined,
	virtualProduct?: GraphQLTypes["Contember_ProductUpdateVirtualProductEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_ProductUpdateMetaEntityRelationInput"] | undefined,
	page?: GraphQLTypes["Contember_ProductUpdatePageEntityRelationInput"] | undefined,
	salesDays?: Array<GraphQLTypes["Contember_ProductUpdateSalesDaysEntityRelationInput"]> | undefined,
	salesWeeks?: Array<GraphQLTypes["Contember_ProductUpdateSalesWeeksEntityRelationInput"]> | undefined,
	salesMonths?: Array<GraphQLTypes["Contember_ProductUpdateSalesMonthsEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_ProductUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	hasSubscription?: boolean | undefined,
	pointOfSaleTileColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductWithoutOrderItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutOrderItemsCreateInput"] | undefined
};
	["Contember_FulfillmentNoteUpsertOrderItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderItemWithoutFulfillmentNotesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutFulfillmentNotesCreateInput"] | undefined
};
	["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderWithoutFulfillmentNotesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_FulfillmentNoteUpsertOrdersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_FulfillmentNoteUpdateOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderWithoutFulfillmentNotesUpdateInput"] | undefined
};
	["Contember_OrderWithoutFulfillmentNotesUpdateInput"]: {
		state?: GraphQLTypes["Contember_OrderState"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	canceledAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	customer?: GraphQLTypes["Contember_OrderUpdateCustomerEntityRelationInput"] | undefined,
	channel?: GraphQLTypes["Contember_OrderUpdateChannelEntityRelationInput"] | undefined,
	discounts?: Array<GraphQLTypes["Contember_OrderUpdateDiscountsEntityRelationInput"]> | undefined,
	delivery?: GraphQLTypes["Contember_OrderUpdateDeliveryEntityRelationInput"] | undefined,
	items?: Array<GraphQLTypes["Contember_OrderUpdateItemsEntityRelationInput"]> | undefined,
	internalNote?: string | undefined,
	cart?: GraphQLTypes["Contember_OrderUpdateCartEntityRelationInput"] | undefined,
	events?: Array<GraphQLTypes["Contember_OrderUpdateEventsEntityRelationInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddress?: GraphQLTypes["Contember_OrderUpdateBillingAddressEntityRelationInput"] | undefined,
	priceLines?: Array<GraphQLTypes["Contember_OrderUpdatePriceLinesEntityRelationInput"]> | undefined,
	payments?: Array<GraphQLTypes["Contember_OrderUpdatePaymentsEntityRelationInput"]> | undefined,
	seq?: number | undefined,
	billingSubject?: GraphQLTypes["Contember_OrderUpdateBillingSubjectEntityRelationInput"] | undefined,
	summary?: GraphQLTypes["Contember_OrderUpdateSummaryEntityRelationInput"] | undefined,
	doubleshotLegacyId?: string | undefined,
	gratuity?: GraphQLTypes["Contember_OrderUpdateGratuityEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_OrderUpdateVenueEntityRelationInput"] | undefined,
	fulfillmentNote?: string | undefined,
	doubleshotTotalWeightGrams?: number | undefined,
	doubleShotLegacyNumber?: string | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	doubleshotAdminUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpdateBillingSubjectEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BillingSubjectWithoutOrdersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BillingSubjectWithoutOrdersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertBillingSubjectRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BillingSubjectWithoutOrdersUpdateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AnonymousSessionWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AnonymousSessionWithoutCustomerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertAnonymousSessionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AnonymousSessionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_AnonymousSessionWithoutCustomerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	anonymousSessionKey?: string | undefined,
	email?: string | undefined,
	name?: string | undefined,
	internalNote?: string | undefined,
	devices?: Array<GraphQLTypes["Contember_AnonymousSessionUpdateDevicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AnonymousSessionUpdateDevicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UserDeviceWithoutAnonymousSessionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AnonymousSessionUpdateDevicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AnonymousSessionUpsertDevicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AnonymousSessionUpdateDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"] | undefined
};
	["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	lastActiveAt?: GraphQLTypes["DateTime"] | undefined,
	platform?: string | undefined,
	deviceId?: string | undefined,
	notificationsEnabled?: boolean | undefined,
	user?: GraphQLTypes["Contember_UserDeviceUpdateUserEntityRelationInput"] | undefined,
	appVersion?: string | undefined,
	osVersion?: string | undefined,
	apiVersion?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserDeviceUpdateUserEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UserWithoutDevicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UserWithoutDevicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UserDeviceUpsertUserRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UserUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UserWithoutDevicesUpdateInput"]: {
		name?: string | undefined,
	internalNote?: string | undefined,
	staff?: GraphQLTypes["Contember_UserUpdateStaffEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_UserUpdateCustomerEntityRelationInput"] | undefined,
	tenantPersonId?: GraphQLTypes["UUID"] | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	verifiedAt?: GraphQLTypes["DateTime"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	meta?: GraphQLTypes["Contember_UserUpdateMetaEntityRelationInput"] | undefined,
	identificationAttempts?: Array<GraphQLTypes["Contember_UserUpdateIdentificationAttemptsEntityRelationInput"]> | undefined,
	roles?: GraphQLTypes["Contember_UserUpdateRolesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UserUpdateRolesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_UserRolesUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UserDeviceUpsertUserRelationInput"]: {
		update?: GraphQLTypes["Contember_UserWithoutDevicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutDevicesCreateInput"] | undefined
};
	["Contember_AnonymousSessionUpsertDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_UserDeviceWithoutAnonymousSessionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UserDeviceWithoutAnonymousSessionCreateInput"] | undefined
};
	["Contember_CustomerUpsertAnonymousSessionRelationInput"]: {
		update?: GraphQLTypes["Contember_AnonymousSessionWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AnonymousSessionWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherWithoutBuyerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateBoughtVouchersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertBoughtVouchersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateBoughtVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VoucherWithoutBuyerUpdateInput"] | undefined
};
	["Contember_VoucherWithoutBuyerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	owner?: GraphQLTypes["Contember_VoucherUpdateOwnerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherUpdateRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherUpdateOwnerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutOwnsVouchersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutOwnsVouchersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherUpsertOwnerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutOwnsVouchersUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertRedeemedVouchersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateRedeemedVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerUpdateInput"] | undefined
};
	["Contember_VoucherRedemptionWithoutCustomerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherRedemptionUpsertCustomerCreditRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"]: {
		initialCreditCents?: number | undefined,
	remainingCreditsCents?: number | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	reason?: string | undefined,
	customer?: GraphQLTypes["Contember_CustomerCreditUpdateCustomerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_CustomerCreditUpdateVatRateEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	transactions?: Array<GraphQLTypes["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"]> | undefined,
	invoiceUrl?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditUpdateTransactionsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerCreditUpdateTransactionsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerCreditUpsertTransactionsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerCreditUpdateTransactionsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"] | undefined
};
	["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	amountCents?: number | undefined,
	orderDiscount?: GraphQLTypes["Contember_CustomerCreditTransactionUpdateOrderDiscountEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditTransactionUpdateVirtualProductEffectEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultVouchers?: Array<GraphQLTypes["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VirtualProductEffectUpsertOrderItemRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"]: {
		unitPriceCents?: number | undefined,
	quantity?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderItemUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderItemUpdateOrderEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	product?: GraphQLTypes["Contember_OrderItemUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemUpdateFulfillmentNotesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderItemUpdateFulfillmentNotesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderItemUpsertFulfillmentNotesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderItemUpdateFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"] | undefined
};
	["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"]: {
		note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderItemUpsertFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrderItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrderItemsCreateInput"] | undefined
};
	["Contember_VirtualProductEffectUpsertOrderItemRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderItemWithoutVirtualProductEffectsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutVirtualProductEffectsCreateInput"] | undefined
};
	["Contember_VirtualProductEffectUpdateResultVouchersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherWithoutVirtualProductEffectCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VirtualProductEffectUpdateResultVouchersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VirtualProductEffectUpsertResultVouchersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VirtualProductEffectUpdateResultVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VoucherWithoutVirtualProductEffectUpdateInput"] | undefined
};
	["Contember_VoucherWithoutVirtualProductEffectUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_VoucherUpdateBuyerEntityRelationInput"] | undefined,
	owner?: GraphQLTypes["Contember_VoucherUpdateOwnerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherUpdateRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherUpdateBuyerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutBoughtVouchersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutBoughtVouchersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherUpsertBuyerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutBoughtVouchersUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherWithoutOwnerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateOwnsVouchersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertOwnsVouchersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateOwnsVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VoucherWithoutOwnerUpdateInput"] | undefined
};
	["Contember_VoucherWithoutOwnerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_VoucherUpdateBuyerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"] | undefined,
	redemption?: GraphQLTypes["Contember_VoucherUpdateRedemptionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined
};
	["Contember_VoucherUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherUpsertVirtualProductEffectRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	succeededAt?: GraphQLTypes["DateTime"] | undefined,
	orderItem?: GraphQLTypes["Contember_VirtualProductEffectUpdateOrderItemEntityRelationInput"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	revertedAt?: GraphQLTypes["DateTime"] | undefined,
	resultCustomerCreditTransaction?: GraphQLTypes["Contember_VirtualProductEffectUpdateResultCustomerCreditTransactionEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherUpsertVirtualProductEffectRelationInput"]: {
		update?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultVouchersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultVouchersCreateInput"] | undefined
};
	["Contember_VoucherUpdateRedemptionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherRedemptionWithoutVoucherCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VoucherRedemptionWithoutVoucherUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherUpsertRedemptionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VoucherRedemptionWithoutVoucherUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"] | undefined,
	orderDiscount?: GraphQLTypes["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherRedemptionUpsertOrderDiscountRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"]: {
		discountCents?: number | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDiscountUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDiscountUpdateOrderEntityRelationInput"] | undefined,
	description?: string | undefined,
	type?: GraphQLTypes["Contember_OrderDiscountType"] | undefined,
	customerCreditTransactions?: Array<GraphQLTypes["Contember_OrderDiscountUpdateCustomerCreditTransactionsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionUpsertOrderDiscountRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderDiscountWithoutVoucherRedemptionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderDiscountWithoutVoucherRedemptionCreateInput"] | undefined
};
	["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutRedeemedVouchersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutRedeemedVouchersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherRedemptionUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutRedeemedVouchersUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateSpendingEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerSpendingUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerTabWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateCustomerTabsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertCustomerTabsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateCustomerTabsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerTabWithoutCustomerUpdateInput"] | undefined
};
	["Contember_CustomerTabWithoutCustomerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	color?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CustomerTabUpdateItemsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabUpdateMetaEntityRelationInput"] | undefined,
	table?: GraphQLTypes["Contember_CustomerTabUpdateTableEntityRelationInput"] | undefined,
	seat?: GraphQLTypes["Contember_CustomerTabUpdateSeatEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerTabUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerTabItemWithoutTabCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerTabUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerTabUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerTabUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerTabItemWithoutTabUpdateInput"] | undefined
};
	["Contember_CustomerTabItemWithoutTabUpdateInput"]: {
		addedAt?: GraphQLTypes["DateTime"] | undefined,
	product?: GraphQLTypes["Contember_CustomerTabItemUpdateProductEntityRelationInput"] | undefined,
	paidAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerTabItemUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerTabItemUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerTabItemUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_CustomerTabUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerTabItemWithoutTabUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabItemWithoutTabCreateInput"] | undefined
};
	["Contember_CustomerTabUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerTabMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerTabUpdateTableEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TableWithoutCustomerTabsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TableWithoutCustomerTabsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerTabUpsertTableRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TableWithoutCustomerTabsUpdateInput"]: {
		shape?: GraphQLTypes["Contember_TableShape"] | undefined,
	width?: number | undefined,
	height?: number | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	area?: GraphQLTypes["Contember_TableUpdateAreaEntityRelationInput"] | undefined,
	seats?: Array<GraphQLTypes["Contember_TableUpdateSeatsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TableUpdateAreaEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AreaWithoutTablesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AreaWithoutTablesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TableUpsertAreaRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_AreaWithoutTablesUpdateInput"]: {
		order?: number | undefined,
	name?: string | undefined,
	venue?: GraphQLTypes["Contember_AreaUpdateVenueEntityRelationInput"] | undefined,
	lines?: Array<GraphQLTypes["Contember_AreaUpdateLinesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AreaUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutAreasCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutAreasUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AreaUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutAreasUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UnassignReasonWithoutVenuesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertEnabledUnassignReasonsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateEnabledUnassignReasonsRelationInput"]: {
		by?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_UnassignReasonWithoutVenuesUpdateInput"] | undefined
};
	["Contember_UnassignReasonWithoutVenuesUpdateInput"]: {
		order?: number | undefined,
	name?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpsertEnabledUnassignReasonsRelationInput"]: {
		by?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_UnassignReasonWithoutVenuesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UnassignReasonWithoutVenuesCreateInput"] | undefined
};
	["Contember_VenueUpdateCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"] | undefined
};
	["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"]: {
		order?: number | undefined,
	slug?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined,
	ticketPrinters?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryUpsertCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PointOfSaleCategoryUpsertCategoryRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryUpdateTicketPrintersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryUpdateTicketPrintersRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"] | undefined
};
	["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesReceipt?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]> | undefined,
	applicationDevicesTicket?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateVenueEntityRelationInput"] | undefined,
	identificationCode?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentificationCodeEntityRelationInput"] | undefined,
	identifier?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateIdentifierEntityRelationInput"] | undefined,
	terminal?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTerminalEntityRelationInput"] | undefined,
	receiptPrinter?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpdateReceiptPrinterEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesTicket?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesTicketEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"] | undefined,
	ticketCategories?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutPointOfSalePrintersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutPointOfSalePrintersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSalePrinterUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutPointOfSalePrintersUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpdateAreasEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AreaWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateAreasRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertAreasRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateAreasRelationInput"]: {
		by?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_AreaWithoutVenueUpdateInput"] | undefined
};
	["Contember_AreaWithoutVenueUpdateInput"]: {
		order?: number | undefined,
	name?: string | undefined,
	tables?: Array<GraphQLTypes["Contember_AreaUpdateTablesEntityRelationInput"]> | undefined,
	lines?: Array<GraphQLTypes["Contember_AreaUpdateLinesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AreaUpdateTablesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TableWithoutAreaCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AreaUpdateTablesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AreaUpsertTablesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AreaUpdateTablesRelationInput"]: {
		by?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_TableWithoutAreaUpdateInput"] | undefined
};
	["Contember_TableWithoutAreaUpdateInput"]: {
		shape?: GraphQLTypes["Contember_TableShape"] | undefined,
	width?: number | undefined,
	height?: number | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_TableUpdateCustomerTabsEntityRelationInput"]> | undefined,
	seats?: Array<GraphQLTypes["Contember_TableUpdateSeatsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TableUpdateCustomerTabsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerTabWithoutTableCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TableUpdateCustomerTabsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TableUpsertCustomerTabsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TableUpdateCustomerTabsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerTabWithoutTableUpdateInput"] | undefined
};
	["Contember_CustomerTabWithoutTableUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	color?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CustomerTabUpdateItemsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabUpdateMetaEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerTabUpdateCustomerEntityRelationInput"] | undefined,
	seat?: GraphQLTypes["Contember_CustomerTabUpdateSeatEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerTabUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutCustomerTabsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutCustomerTabsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerTabUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutCustomerTabsUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateLeadEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineLeadWithoutCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerUpdateLeadRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerUpsertLeadRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CustomerUpdateLeadRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PipelineLeadWithoutCustomerUpdateInput"] | undefined
};
	["Contember_PipelineLeadWithoutCustomerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	stageItem?: GraphQLTypes["Contember_PipelineLeadUpdateStageItemEntityRelationInput"] | undefined,
	contactPerson?: string | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine?: boolean | undefined,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	form?: GraphQLTypes["Contember_PipelineLeadUpdateFormEntityRelationInput"] | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineLeadUpdateStageItemEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageItemWithoutLeadCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageItemWithoutLeadUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineLeadUpsertStageItemRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineStageItemWithoutLeadUpdateInput"]: {
		list?: GraphQLTypes["Contember_PipelineStageItemUpdateListEntityRelationInput"] | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageItemUpdateListEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageWithoutItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageWithoutItemsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageItemUpsertListRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineStageWithoutItemsUpdateInput"]: {
		pipeline?: GraphQLTypes["Contember_PipelineStageUpdatePipelineEntityRelationInput"] | undefined,
	order?: number | undefined,
	locales?: Array<GraphQLTypes["Contember_PipelineStageUpdateLocalesEntityRelationInput"]> | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageUpdateSentimentEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	forms?: Array<GraphQLTypes["Contember_PipelineStageUpdateFormsEntityRelationInput"]> | undefined,
	showItemsWeight?: boolean | undefined,
	showItemsPrice?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageUpdatePipelineEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineWithoutStagesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineWithoutStagesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageUpsertPipelineRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineWithoutStagesUpdateInput"]: {
		unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageUpsertPipelineRelationInput"]: {
		update?: GraphQLTypes["Contember_PipelineWithoutStagesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineWithoutStagesCreateInput"] | undefined
};
	["Contember_PipelineStageUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineStageUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PipelineStageLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_PipelineStageLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutPipelineStagesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutPipelineStagesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutPipelineStagesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertSubscriptionBoxesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateSubscriptionBoxesRelationInput"]: {
		by?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"]: {
		title?: string | undefined,
	stickerText?: string | undefined,
	descriptionText?: string | undefined,
	root?: GraphQLTypes["Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput"] | undefined,
	secondaryTitle?: string | undefined,
	texts?: GraphQLTypes["Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput"] | undefined,
	secondaryTexts?: GraphQLTypes["Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SubscriptionBoxLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SubscriptionBoxWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SubscriptionBoxWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_SubscriptionBoxLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SubscriptionBoxUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_SubscriptionBoxWithoutLocalesUpdateInput"]: {
		unique?: GraphQLTypes["Contember_One"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SubscriptionBoxLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_SubscriptionBoxWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SubscriptionBoxWithoutLocalesCreateInput"] | undefined
};
	["Contember_SubscriptionBoxLocaleUpdateTextsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_SubscriptionBoxLocaleUpsertTextsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_SubscriptionBoxLocaleUpsertTextsRelationInput"]: {
		update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_SubscriptionBoxLocaleUpdateSecondaryTextsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_SubscriptionBoxLocaleUpsertSecondaryTextsRelationInput"]: {
		update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_LocaleUpsertSubscriptionBoxesRelationInput"]: {
		by?: GraphQLTypes["Contember_SubscriptionBoxLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_SubscriptionBoxLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SubscriptionBoxLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdateBusinessServicesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertBusinessServicesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdateBusinessServicesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	labelNote?: string | undefined,
	root?: GraphQLTypes["Contember_BusinessServiceLocaleUpdateRootEntityRelationInput"] | undefined,
	labelNumberOfPeople?: string | undefined,
	labelInvoiced?: string | undefined,
	labelEquipment?: string | undefined,
	labelMillTamDem?: string | undefined,
	labelSOEspresso?: string | undefined,
	labelFilterCoffeeInMenu?: string | undefined,
	labelWaterMeterStatus?: string | undefined,
	labelServiceAction?: string | undefined,
	labelInvoiceIdentifier?: string | undefined,
	labelSupervisor?: string | undefined,
	labelContact?: string | undefined,
	labelPhone?: string | undefined,
	labelEmail?: string | undefined,
	labelGrindingStonesCondition?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessServiceWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessServiceWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessServiceLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessServiceUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessServiceWithoutLocalesUpdateInput"]: {
		order?: number | undefined,
	requireNote?: number | undefined,
	requireNumberOfPeople?: number | undefined,
	requireInvoiced?: number | undefined,
	requireEquipment?: number | undefined,
	requireMillTamDem?: number | undefined,
	requireSOEspresso?: number | undefined,
	requireFilterCoffeeInMenu?: number | undefined,
	requireWaterMeterStatus?: number | undefined,
	requireServiceAction?: number | undefined,
	optionsEquipment?: string | undefined,
	optionsServiceAction?: string | undefined,
	requireInvoiceIdentifier?: number | undefined,
	requireSupervisor?: number | undefined,
	requireContact?: number | undefined,
	requirePhone?: number | undefined,
	requireEmail?: number | undefined,
	requireGrindingStonesCondition?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessServiceWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertBusinessServicesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_PipelineStageLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutPipelineStagesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPipelineStagesCreateInput"] | undefined
};
	["Contember_PipelineStageUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_PipelineStageUpdateSentimentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageSentimentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageSentimentUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageUpsertSentimentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageSentimentUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineStageSentimentUpdateInput"]: {
		internalName?: string | undefined,
	backgroundColor?: string | undefined,
	textColor?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageUpsertSentimentRelationInput"]: {
		update?: GraphQLTypes["Contember_PipelineStageSentimentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageSentimentCreateInput"] | undefined
};
	["Contember_PipelineStageUpdateFormsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LeadFormWithoutStageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageUpdateFormsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageUpsertFormsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineStageUpdateFormsRelationInput"]: {
		by?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_LeadFormWithoutStageUpdateInput"] | undefined
};
	["Contember_LeadFormWithoutStageUpdateInput"]: {
		code?: string | undefined,
	leads?: Array<GraphQLTypes["Contember_LeadFormUpdateLeadsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LeadFormUpdateLeadsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineLeadWithoutFormCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LeadFormUpdateLeadsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LeadFormUpsertLeadsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LeadFormUpdateLeadsRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PipelineLeadWithoutFormUpdateInput"] | undefined
};
	["Contember_PipelineLeadWithoutFormUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	stageItem?: GraphQLTypes["Contember_PipelineLeadUpdateStageItemEntityRelationInput"] | undefined,
	contactPerson?: string | undefined,
	customer?: GraphQLTypes["Contember_PipelineLeadUpdateCustomerEntityRelationInput"] | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine?: boolean | undefined,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineLeadUpdateCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutLeadCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutLeadUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineLeadUpsertCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutLeadUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CustomerMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineLeadUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutLeadUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutLeadCreateInput"] | undefined
};
	["Contember_LeadFormUpsertLeadsRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PipelineLeadWithoutFormUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineLeadWithoutFormCreateInput"] | undefined
};
	["Contember_PipelineStageUpsertFormsRelationInput"]: {
		by?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_LeadFormWithoutStageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LeadFormWithoutStageCreateInput"] | undefined
};
	["Contember_PipelineStageItemUpsertListRelationInput"]: {
		update?: GraphQLTypes["Contember_PipelineStageWithoutItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageWithoutItemsCreateInput"] | undefined
};
	["Contember_PipelineLeadUpsertStageItemRelationInput"]: {
		update?: GraphQLTypes["Contember_PipelineStageItemWithoutLeadUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageItemWithoutLeadCreateInput"] | undefined
};
	["Contember_PipelineLeadUpdateFormEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LeadFormWithoutLeadsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LeadFormWithoutLeadsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineLeadUpsertFormRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LeadFormUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LeadFormWithoutLeadsUpdateInput"]: {
		code?: string | undefined,
	stage?: GraphQLTypes["Contember_LeadFormUpdateStageEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_LeadFormUpdateStageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageWithoutFormsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageWithoutFormsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LeadFormUpsertStageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineStageWithoutFormsUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_PipelineStageUpdateItemsEntityRelationInput"]> | undefined,
	pipeline?: GraphQLTypes["Contember_PipelineStageUpdatePipelineEntityRelationInput"] | undefined,
	order?: number | undefined,
	locales?: Array<GraphQLTypes["Contember_PipelineStageUpdateLocalesEntityRelationInput"]> | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageUpdateSentimentEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	showItemsWeight?: boolean | undefined,
	showItemsPrice?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PipelineStageUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PipelineStageItemWithoutListUpdateInput"] | undefined
};
	["Contember_PipelineStageItemWithoutListUpdateInput"]: {
		lead?: GraphQLTypes["Contember_PipelineStageItemUpdateLeadEntityRelationInput"] | undefined,
	order?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageItemUpdateLeadEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineLeadWithoutStageItemCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineLeadWithoutStageItemUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageItemUpsertLeadRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineLeadWithoutStageItemUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	contactPerson?: string | undefined,
	customer?: GraphQLTypes["Contember_PipelineLeadUpdateCustomerEntityRelationInput"] | undefined,
	cafeType?: string | undefined,
	offerType?: string | undefined,
	ownMachine?: boolean | undefined,
	customerCity?: string | undefined,
	companyId?: string | undefined,
	customerName?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	form?: GraphQLTypes["Contember_PipelineLeadUpdateFormEntityRelationInput"] | undefined,
	meta?: string | undefined,
	multiroasterType?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageItemUpsertLeadRelationInput"]: {
		update?: GraphQLTypes["Contember_PipelineLeadWithoutStageItemUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineLeadWithoutStageItemCreateInput"] | undefined
};
	["Contember_PipelineStageUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineStageItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageItemWithoutListCreateInput"] | undefined
};
	["Contember_LeadFormUpsertStageRelationInput"]: {
		update?: GraphQLTypes["Contember_PipelineStageWithoutFormsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageWithoutFormsCreateInput"] | undefined
};
	["Contember_PipelineLeadUpsertFormRelationInput"]: {
		update?: GraphQLTypes["Contember_LeadFormWithoutLeadsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LeadFormWithoutLeadsCreateInput"] | undefined
};
	["Contember_CustomerUpsertLeadRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineLeadUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PipelineLeadWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineLeadWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerTabUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutCustomerTabsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutCustomerTabsCreateInput"] | undefined
};
	["Contember_CustomerTabUpdateSeatEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SeatWithoutCustomerTabsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SeatWithoutCustomerTabsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerTabUpsertSeatRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_SeatWithoutCustomerTabsUpdateInput"]: {
		positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	table?: GraphQLTypes["Contember_SeatUpdateTableEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeatUpdateTableEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TableWithoutSeatsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TableWithoutSeatsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_SeatUpsertTableRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_TableWithoutSeatsUpdateInput"]: {
		shape?: GraphQLTypes["Contember_TableShape"] | undefined,
	width?: number | undefined,
	height?: number | undefined,
	positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_TableUpdateCustomerTabsEntityRelationInput"]> | undefined,
	area?: GraphQLTypes["Contember_TableUpdateAreaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeatUpsertTableRelationInput"]: {
		update?: GraphQLTypes["Contember_TableWithoutSeatsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TableWithoutSeatsCreateInput"] | undefined
};
	["Contember_CustomerTabUpsertSeatRelationInput"]: {
		update?: GraphQLTypes["Contember_SeatWithoutCustomerTabsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SeatWithoutCustomerTabsCreateInput"] | undefined
};
	["Contember_TableUpsertCustomerTabsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerTabWithoutTableUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabWithoutTableCreateInput"] | undefined
};
	["Contember_TableUpdateSeatsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SeatWithoutTableCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TableUpdateSeatsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TableUpsertSeatsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_TableUpdateSeatsRelationInput"]: {
		by?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_SeatWithoutTableUpdateInput"] | undefined
};
	["Contember_SeatWithoutTableUpdateInput"]: {
		positionX?: number | undefined,
	positionY?: number | undefined,
	name?: string | undefined,
	color?: string | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_SeatUpdateCustomerTabsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeatUpdateCustomerTabsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerTabWithoutSeatCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SeatUpdateCustomerTabsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_SeatUpsertCustomerTabsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_SeatUpdateCustomerTabsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_CustomerTabWithoutSeatUpdateInput"] | undefined
};
	["Contember_CustomerTabWithoutSeatUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	color?: string | undefined,
	items?: Array<GraphQLTypes["Contember_CustomerTabUpdateItemsEntityRelationInput"]> | undefined,
	name?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerTabUpdateMetaEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_CustomerTabUpdateCustomerEntityRelationInput"] | undefined,
	table?: GraphQLTypes["Contember_CustomerTabUpdateTableEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_SeatUpsertCustomerTabsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerTabWithoutSeatUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabWithoutSeatCreateInput"] | undefined
};
	["Contember_TableUpsertSeatsRelationInput"]: {
		by?: GraphQLTypes["Contember_SeatUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_SeatWithoutTableUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SeatWithoutTableCreateInput"] | undefined
};
	["Contember_AreaUpsertTablesRelationInput"]: {
		by?: GraphQLTypes["Contember_TableUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TableWithoutAreaUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TableWithoutAreaCreateInput"] | undefined
};
	["Contember_AreaUpdateLinesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LineWithoutAreaCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AreaUpdateLinesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AreaUpsertLinesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_AreaUpdateLinesRelationInput"]: {
		by?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_LineWithoutAreaUpdateInput"] | undefined
};
	["Contember_LineWithoutAreaUpdateInput"]: {
		positionStartX?: number | undefined,
	positionStartY?: number | undefined,
	positionEndX?: number | undefined,
	positionEndY?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AreaUpsertLinesRelationInput"]: {
		by?: GraphQLTypes["Contember_LineUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_LineWithoutAreaUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LineWithoutAreaCreateInput"] | undefined
};
	["Contember_VenueUpsertAreasRelationInput"]: {
		by?: GraphQLTypes["Contember_AreaUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_AreaWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AreaWithoutVenueCreateInput"] | undefined
};
	["Contember_VenueUpdateChannelsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelWithoutVenueCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueUpdateChannelsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VenueUpsertChannelsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_VenueUpdateChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ChannelWithoutVenueUpdateInput"] | undefined
};
	["Contember_ChannelWithoutVenueUpdateInput"]: {
		name?: string | undefined,
	disabled?: boolean | undefined,
	payments?: Array<GraphQLTypes["Contember_ChannelUpdatePaymentsEntityRelationInput"]> | undefined,
	deliveries?: Array<GraphQLTypes["Contember_ChannelUpdateDeliveriesEntityRelationInput"]> | undefined,
	pointOfSales?: Array<GraphQLTypes["Contember_ChannelUpdatePointOfSalesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_ChannelUpdateCartsEntityRelationInput"]> | undefined,
	customers?: Array<GraphQLTypes["Contember_ChannelUpdateCustomersEntityRelationInput"]> | undefined,
	code?: GraphQLTypes["Contember_ChannelCode"] | undefined,
	addTagsOnRegistration?: Array<GraphQLTypes["Contember_ChannelUpdateAddTagsOnRegistrationEntityRelationInput"]> | undefined,
	publicTags?: Array<GraphQLTypes["Contember_ChannelUpdatePublicTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VenueUpsertChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutVenueCreateInput"] | undefined
};
	["Contember_PointOfSalePrinterUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutPointOfSalePrintersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutPointOfSalePrintersCreateInput"] | undefined
};
	["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSalePrinterUpdateTicketCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"] | undefined
};
	["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"]: {
		order?: number | undefined,
	slug?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_PointOfSaleCategoryUpdateProductsEntityRelationInput"]> | undefined,
	category?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateCategoryEntityRelationInput"] | undefined,
	venue?: GraphQLTypes["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutCategoriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutCategoriesUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutCategoriesCreateInput"] | undefined
};
	["Contember_PointOfSalePrinterUpsertTicketCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutTicketPrintersCreateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpsertReceiptPrinterRelationInput"]: {
		update?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesReceiptCreateInput"] | undefined
};
	["Contember_PointOfSalePrinterUpsertApplicationDevicesTicketRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTicketPrintersCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryUpsertTicketPrintersRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutTicketCategoriesCreateInput"] | undefined
};
	["Contember_VenueUpsertCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutVenueCreateInput"] | undefined
};
	["Contember_AreaUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutAreasUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutAreasCreateInput"] | undefined
};
	["Contember_TableUpsertAreaRelationInput"]: {
		update?: GraphQLTypes["Contember_AreaWithoutTablesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AreaWithoutTablesCreateInput"] | undefined
};
	["Contember_CustomerTabUpsertTableRelationInput"]: {
		update?: GraphQLTypes["Contember_TableWithoutCustomerTabsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TableWithoutCustomerTabsCreateInput"] | undefined
};
	["Contember_CustomerUpsertCustomerTabsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerTabUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerTabWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerTabWithoutCustomerCreateInput"] | undefined
};
	["Contember_VoucherRedemptionUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutRedeemedVouchersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutRedeemedVouchersCreateInput"] | undefined
};
	["Contember_VoucherUpsertRedemptionRelationInput"]: {
		update?: GraphQLTypes["Contember_VoucherRedemptionWithoutVoucherUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutVoucherCreateInput"] | undefined
};
	["Contember_CustomerUpsertOwnsVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VoucherWithoutOwnerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutOwnerCreateInput"] | undefined
};
	["Contember_VoucherUpsertBuyerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutBoughtVouchersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutBoughtVouchersCreateInput"] | undefined
};
	["Contember_VirtualProductEffectUpsertResultVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VoucherWithoutVirtualProductEffectUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutVirtualProductEffectCreateInput"] | undefined
};
	["Contember_CustomerCreditTransactionUpsertVirtualProductEffectRelationInput"]: {
		update?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductEffectWithoutResultCustomerCreditTransactionCreateInput"] | undefined
};
	["Contember_CustomerCreditUpsertTransactionsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutCustomerCreditCreateInput"] | undefined
};
	["Contember_VoucherRedemptionUpsertCustomerCreditRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerCreditWithoutVoucherRedemptionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditWithoutVoucherRedemptionCreateInput"] | undefined
};
	["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherWithoutRedemptionCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VoucherWithoutRedemptionUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_VoucherRedemptionUpsertVoucherRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VoucherWithoutRedemptionUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	activatedAt?: GraphQLTypes["DateTime"] | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	note?: string | undefined,
	code?: string | undefined,
	type?: GraphQLTypes["Contember_VoucherType"] | undefined,
	creditCents?: number | undefined,
	buyer?: GraphQLTypes["Contember_VoucherUpdateBuyerEntityRelationInput"] | undefined,
	owner?: GraphQLTypes["Contember_VoucherUpdateOwnerEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_VoucherUpdateVatRateEntityRelationInput"] | undefined,
	virtualProductEffect?: GraphQLTypes["Contember_VoucherUpdateVirtualProductEffectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_VoucherRedemptionUpsertVoucherRelationInput"]: {
		update?: GraphQLTypes["Contember_VoucherWithoutRedemptionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutRedemptionCreateInput"] | undefined
};
	["Contember_CustomerUpsertRedeemedVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreateInput"] | undefined
};
	["Contember_VoucherUpsertOwnerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutOwnsVouchersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutOwnsVouchersCreateInput"] | undefined
};
	["Contember_CustomerUpsertBoughtVouchersRelationInput"]: {
		by?: GraphQLTypes["Contember_VoucherUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VoucherWithoutBuyerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherWithoutBuyerCreateInput"] | undefined
};
	["Contember_BillingSubjectUpsertDefaultBillingSubjectOfCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutDefaultBillingSubjectUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutDefaultBillingSubjectCreateInput"] | undefined
};
	["Contember_OrderUpsertBillingSubjectRelationInput"]: {
		update?: GraphQLTypes["Contember_BillingSubjectWithoutOrdersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutOrdersCreateInput"] | undefined
};
	["Contember_FulfillmentNoteUpsertOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutFulfillmentNotesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutFulfillmentNotesCreateInput"] | undefined
};
	["Contember_VenueUpsertFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteWithoutVenuesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutVenuesCreateInput"] | undefined
};
	["Contember_OrderUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutOrdersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutOrdersCreateInput"] | undefined
};
	["Contember_OrderUpdateFulfillmentNotesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrdersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderUpdateFulfillmentNotesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderUpsertFulfillmentNotesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderUpdateFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrdersUpdateInput"] | undefined
};
	["Contember_FulfillmentNoteWithoutOrdersUpdateInput"]: {
		note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined,
	cartItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateCartItemsEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderUpsertFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrdersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutOrdersCreateInput"] | undefined
};
	["Contember_BillingSubjectUpsertOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutBillingSubjectUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutBillingSubjectCreateInput"] | undefined
};
	["Contember_CustomerUpsertDefaultBillingSubjectRelationInput"]: {
		update?: GraphQLTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutDefaultBillingSubjectOfCustomerCreateInput"] | undefined
};
	["Contember_BillingSubjectUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutBillingSubjectsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutBillingSubjectsCreateInput"] | undefined
};
	["Contember_InvoiceUpsertBillingSubjectRelationInput"]: {
		update?: GraphQLTypes["Contember_BillingSubjectWithoutInvoicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutInvoicesCreateInput"] | undefined
};
	["Contember_OrderPaymentUpsertInvoiceRelationInput"]: {
		update?: GraphQLTypes["Contember_InvoiceWithoutOrderPaymentsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutOrderPaymentsCreateInput"] | undefined
};
	["Contember_OrderPaymentUpdateVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderPaymentUpsertVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderPaymentUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_OrderPaymentUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_OrderPaymentMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderUpsertPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderPaymentWithoutOrderUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderPaymentWithoutOrderCreateInput"] | undefined
};
	["Contember_OrderDeliveryUpsertOrderRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderWithoutDeliveryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutDeliveryCreateInput"] | undefined
};
	["Contember_OrderDeliveryUpdateEventsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderEventWithoutDeliveryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderDeliveryUpdateEventsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDeliveryUpsertEventsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_OrderDeliveryUpdateEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_OrderEventWithoutDeliveryUpdateInput"] | undefined
};
	["Contember_OrderEventWithoutDeliveryUpdateInput"]: {
		type?: string | undefined,
	data?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	order?: GraphQLTypes["Contember_OrderEventUpdateOrderEntityRelationInput"] | undefined,
	payment?: GraphQLTypes["Contember_OrderEventUpdatePaymentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryUpsertEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderEventWithoutDeliveryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderEventWithoutDeliveryCreateInput"] | undefined
};
	["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LiftagoRideWithoutDeliveryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LiftagoRideWithoutDeliveryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDeliveryUpsertLiftagoRideRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LiftagoRideUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LiftagoRideWithoutDeliveryUpdateInput"]: {
		liftagoId?: string | undefined,
	data?: string | undefined,
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryUpsertLiftagoRideRelationInput"]: {
		update?: GraphQLTypes["Contember_LiftagoRideWithoutDeliveryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LiftagoRideWithoutDeliveryCreateInput"] | undefined
};
	["Contember_AddressUpsertDeliveryAddressOfOrderDeliveriesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderDeliveryWithoutAddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutAddressCreateInput"] | undefined
};
	["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_AddressUpsertDefaultDeliveryAddressOfCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutDefaultDeliveryAddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutDefaultDeliveryAddressCreateInput"] | undefined
};
	["Contember_OrderUpsertBillingAddressRelationInput"]: {
		update?: GraphQLTypes["Contember_AddressWithoutBillingAddressOfOrdersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutBillingAddressOfOrdersCreateInput"] | undefined
};
	["Contember_OrderPaymentUpsertOrderRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderWithoutPaymentsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutPaymentsCreateInput"] | undefined
};
	["Contember_OrderEventUpsertPaymentRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderPaymentWithoutEventsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderPaymentWithoutEventsCreateInput"] | undefined
};
	["Contember_OrderEventUpdateDeliveryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_OrderDeliveryWithoutEventsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_OrderDeliveryWithoutEventsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderEventUpsertDeliveryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_OrderDeliveryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderDeliveryWithoutEventsUpdateInput"]: {
		priceCents?: number | undefined,
	deliveredAt?: GraphQLTypes["DateTime"] | undefined,
	method?: GraphQLTypes["Contember_OrderDeliveryUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_OrderDeliveryUpdateVatRateEntityRelationInput"] | undefined,
	order?: GraphQLTypes["Contember_OrderDeliveryUpdateOrderEntityRelationInput"] | undefined,
	expectedAt?: GraphQLTypes["DateTime"] | undefined,
	state?: GraphQLTypes["Contember_OrderDeliveryState"] | undefined,
	loadingDurationHours?: number | undefined,
	dispatchAt?: GraphQLTypes["DateTime"] | undefined,
	duration?: string | undefined,
	window?: string | undefined,
	expectedEndAt?: GraphQLTypes["DateTime"] | undefined,
	processingAt?: GraphQLTypes["DateTime"] | undefined,
	deliveringAt?: GraphQLTypes["DateTime"] | undefined,
	failedAt?: GraphQLTypes["DateTime"] | undefined,
	internalNote?: string | undefined,
	deliveryWaypoint?: GraphQLTypes["Contember_OrderDeliveryUpdateDeliveryWaypointEntityRelationInput"] | undefined,
	prepareAt?: GraphQLTypes["DateTime"] | undefined,
	liftagoRide?: GraphQLTypes["Contember_OrderDeliveryUpdateLiftagoRideEntityRelationInput"] | undefined,
	pickedAt?: GraphQLTypes["DateTime"] | undefined,
	address?: GraphQLTypes["Contember_OrderDeliveryUpdateAddressEntityRelationInput"] | undefined,
	trackingCode?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryUpdateAddressEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDeliveryUpsertAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"]: {
		name?: string | undefined,
	firstName?: string | undefined,
	lastName?: string | undefined,
	addressLine2?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	customer?: GraphQLTypes["Contember_AddressUpdateCustomerEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	country?: GraphQLTypes["Contember_AddressUpdateCountryEntityRelationInput"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	googlePlaceId?: string | undefined,
	raw?: string | undefined,
	formatted?: string | undefined,
	geocodeGeneratedAt?: GraphQLTypes["DateTime"] | undefined,
	geocodeResponse?: string | undefined,
	deliveryZone?: GraphQLTypes["Contember_AddressUpdateDeliveryZoneEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_AddressUpdateMetaEntityRelationInput"] | undefined,
	gps?: GraphQLTypes["Contember_AddressUpdateGpsEntityRelationInput"] | undefined,
	geocodeValid?: boolean | undefined,
	fullName?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	invoices?: Array<GraphQLTypes["Contember_AddressUpdateInvoicesEntityRelationInput"]> | undefined,
	addressLine1?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingAddressOfOrders?: Array<GraphQLTypes["Contember_AddressUpdateBillingAddressOfOrdersEntityRelationInput"]> | undefined,
	defaultDeliveryAddressOfCustomer?: GraphQLTypes["Contember_AddressUpdateDefaultDeliveryAddressOfCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDeliveryUpsertAddressRelationInput"]: {
		update?: GraphQLTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutDeliveryAddressOfOrderDeliveriesCreateInput"] | undefined
};
	["Contember_OrderEventUpsertDeliveryRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderDeliveryWithoutEventsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutEventsCreateInput"] | undefined
};
	["Contember_OrderUpsertEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderEventWithoutOrderUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderEventWithoutOrderCreateInput"] | undefined
};
	["Contember_AddressUpsertBillingAddressOfOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutBillingAddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutBillingAddressCreateInput"] | undefined
};
	["Contember_InvoiceUpsertAddressRelationInput"]: {
		update?: GraphQLTypes["Contember_AddressWithoutInvoicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutInvoicesCreateInput"] | undefined
};
	["Contember_BillingSubjectUpsertInvoicesRelationInput"]: {
		by?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_InvoiceWithoutBillingSubjectUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutBillingSubjectCreateInput"] | undefined
};
	["Contember_CustomerUpsertBillingSubjectsRelationInput"]: {
		by?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BillingSubjectWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectWithoutCustomerCreateInput"] | undefined
};
	["Contember_CartUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutCartsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutCartsCreateInput"] | undefined
};
	["Contember_CartUpdateDeliveryAddressEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertDeliveryAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CartUpsertDeliveryAddressRelationInput"]: {
		update?: GraphQLTypes["Contember_AddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined
};
	["Contember_CartUpdateBillingAddressEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_AddressUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertBillingAddressRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_CartUpsertBillingAddressRelationInput"]: {
		update?: GraphQLTypes["Contember_AddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressCreateInput"] | undefined
};
	["Contember_CartUpdateDeliveryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelDeliveryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelDeliveryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertDeliveryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ChannelDeliveryUpdateInput"]: {
		order?: number | undefined,
	priceCents?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelDeliveryUpdateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelDeliveryUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelDeliveryUpdateVatRateEntityRelationInput"] | undefined,
	defaultVisible?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartUpsertDeliveryRelationInput"]: {
		update?: GraphQLTypes["Contember_ChannelDeliveryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryCreateInput"] | undefined
};
	["Contember_CartUpdatePaymentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ChannelPaymentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ChannelPaymentUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertPaymentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ChannelPaymentUpdateInput"]: {
		order?: number | undefined,
	channel?: GraphQLTypes["Contember_ChannelPaymentUpdateChannelEntityRelationInput"] | undefined,
	method?: GraphQLTypes["Contember_ChannelPaymentUpdateMethodEntityRelationInput"] | undefined,
	vatRate?: GraphQLTypes["Contember_ChannelPaymentUpdateVatRateEntityRelationInput"] | undefined,
	requireBillingAddress?: boolean | undefined,
	provideReceipt?: boolean | undefined,
	requireBillingSubject?: boolean | undefined,
	allowOnlyForTags?: Array<GraphQLTypes["Contember_ChannelPaymentUpdateAllowOnlyForTagsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartUpsertPaymentRelationInput"]: {
		update?: GraphQLTypes["Contember_ChannelPaymentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentCreateInput"] | undefined
};
	["Contember_CartUpdateBillingSubjectEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BillingSubjectCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BillingSubjectUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartUpsertBillingSubjectRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BillingSubjectUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BillingSubjectUpdateInput"]: {
		name?: string | undefined,
	fullName?: string | undefined,
	addressLine1?: string | undefined,
	addressLine2?: string | undefined,
	city?: string | undefined,
	postalCode?: string | undefined,
	email?: string | undefined,
	phone?: string | undefined,
	companyName?: string | undefined,
	companyIdentifier?: string | undefined,
	vatIdentifier?: string | undefined,
	externalIdentifier?: string | undefined,
	hidden?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	deletedAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	internalNote?: string | undefined,
	fakturoidSubjectId?: string | undefined,
	country?: GraphQLTypes["Contember_BillingSubjectUpdateCountryEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_BillingSubjectUpdateCustomerEntityRelationInput"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_BillingSubjectUpdateInvoicesEntityRelationInput"]> | undefined,
	defaultBillingSubjectOfCustomer?: GraphQLTypes["Contember_BillingSubjectUpdateDefaultBillingSubjectOfCustomerEntityRelationInput"] | undefined,
	orders?: Array<GraphQLTypes["Contember_BillingSubjectUpdateOrdersEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartUpsertBillingSubjectRelationInput"]: {
		update?: GraphQLTypes["Contember_BillingSubjectUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BillingSubjectCreateInput"] | undefined
};
	["Contember_CartUpdatePriceLinesEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartPriceLineUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_CartPriceLineUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_CartPriceLineUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CartUpdateSummaryEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_CartSummaryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_OrderUpsertCartRelationInput"]: {
		update?: GraphQLTypes["Contember_CartWithoutOrderUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutOrderCreateInput"] | undefined
};
	["Contember_OrderDiscountUpsertOrderRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderWithoutDiscountsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutDiscountsCreateInput"] | undefined
};
	["Contember_OrderDiscountUpdateVoucherRedemptionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_OrderDiscountUpsertVoucherRedemptionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	customerCredit?: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerCreditEntityRelationInput"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_OrderDiscountUpsertVoucherRedemptionRelationInput"]: {
		update?: GraphQLTypes["Contember_VoucherRedemptionWithoutOrderDiscountUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutOrderDiscountCreateInput"] | undefined
};
	["Contember_CustomerCreditTransactionUpsertOrderDiscountRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderDiscountWithoutCustomerCreditTransactionsCreateInput"] | undefined
};
	["Contember_VirtualProductEffectUpsertResultCustomerCreditTransactionRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutVirtualProductEffectCreateInput"] | undefined
};
	["Contember_OrderItemUpsertVirtualProductEffectsRelationInput"]: {
		by?: GraphQLTypes["Contember_VirtualProductEffectUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VirtualProductEffectWithoutOrderItemUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductEffectWithoutOrderItemCreateInput"] | undefined
};
	["Contember_OrderUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderItemWithoutOrderUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutOrderCreateInput"] | undefined
};
	["Contember_VenueUpsertOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutVenueCreateInput"] | undefined
};
	["Contember_PointOfSaleTerminalUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutPointOfSaleTerminalsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutPointOfSaleTerminalsCreateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpsertTerminalRelationInput"]: {
		update?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutApplicationDevicesCreateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpdateTicketPrintersRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"] | undefined
};
	["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"]: {
		internalName?: string | undefined,
	note?: string | undefined,
	ipAddress?: string | undefined,
	applicationDevicesReceipt?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateApplicationDevicesReceiptEntityRelationInput"]> | undefined,
	venue?: GraphQLTypes["Contember_PointOfSalePrinterUpdateVenueEntityRelationInput"] | undefined,
	ticketCategories?: Array<GraphQLTypes["Contember_PointOfSalePrinterUpdateTicketCategoriesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpsertTicketPrintersRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutApplicationDevicesTicketCreateInput"] | undefined
};
	["Contember_IdentificationCodeUpsertApplicationDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutIdentificationCodeCreateInput"] | undefined
};
	["Contember_IdentificationCodeLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_IdentificationCodeWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertIdentificationCodesRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageLocaleWithoutLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleUpdatePipelineStagesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LocaleUpsertPipelineStagesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_LocaleUpdatePipelineStagesRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"] | undefined
};
	["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"]: {
		name?: string | undefined,
	root?: GraphQLTypes["Contember_PipelineStageLocaleUpdateRootEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageLocaleUpdateRootEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PipelineStageWithoutLocalesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageWithoutLocalesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PipelineStageLocaleUpsertRootRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PipelineStageUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_PipelineStageWithoutLocalesUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_PipelineStageUpdateItemsEntityRelationInput"]> | undefined,
	pipeline?: GraphQLTypes["Contember_PipelineStageUpdatePipelineEntityRelationInput"] | undefined,
	order?: number | undefined,
	sentiment?: GraphQLTypes["Contember_PipelineStageUpdateSentimentEntityRelationInput"] | undefined,
	type?: GraphQLTypes["Contember_PipelineStageType"] | undefined,
	forms?: Array<GraphQLTypes["Contember_PipelineStageUpdateFormsEntityRelationInput"]> | undefined,
	showItemsWeight?: boolean | undefined,
	showItemsPrice?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PipelineStageLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_PipelineStageWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertPipelineStagesRelationInput"]: {
		by?: GraphQLTypes["Contember_PipelineStageLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PipelineStageLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PipelineStageLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoriesCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductUpsertCategoryRelationInput"]: {
		update?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutProductsCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertPointOfSaleCategoryProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_IdentificationCodeLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutIdentificationCodesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutIdentificationCodesCreateInput"] | undefined
};
	["Contember_IdentificationCodeUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationCodeLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_IdentificationAttemptUpsertCodeRelationInput"]: {
		update?: GraphQLTypes["Contember_IdentificationCodeWithoutAttemptsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeWithoutAttemptsCreateInput"] | undefined
};
	["Contember_UserUpsertIdentificationAttemptsRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationAttemptWithoutUserUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationAttemptWithoutUserCreateInput"] | undefined
};
	["Contember_DeviceIdentifierUpsertUpdatedByUserRelationInput"]: {
		update?: GraphQLTypes["Contember_UserUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UserCreateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpsertIdentifierRelationInput"]: {
		update?: GraphQLTypes["Contember_DeviceIdentifierWithoutDeviceUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeviceIdentifierWithoutDeviceCreateInput"] | undefined
};
	["Contember_PointOfSalePrinterUpsertApplicationDevicesReceiptRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutReceiptPrinterCreateInput"] | undefined
};
	["Contember_VenueUpsertPointOfSalePrintersRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSalePrinterUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSalePrinterWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSalePrinterWithoutVenueCreateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutPointOfSaleApplicationDevicesCreateInput"] | undefined
};
	["Contember_PointOfSaleTerminalUpsertApplicationDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutTerminalCreateInput"] | undefined
};
	["Contember_VenueUpsertPointOfSaleTerminalsRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleTerminalUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleTerminalWithoutVenueCreateInput"] | undefined
};
	["Contember_FulfillmentNoteUpsertVenuesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutFulfillmentNotesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutFulfillmentNotesCreateInput"] | undefined
};
	["Contember_ProductUpsertFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteWithoutProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutProductsCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_PointOfSaleCategoryProductUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"]: {
		title?: string | undefined,
	locale?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_PointOfSaleCategoryProductLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPointOfSaleCategoryProductsCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryProductUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryUpsertProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryProductWithoutCategoryCreateInput"] | undefined
};
	["Contember_PointOfSaleCategoryLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertPointOfSaleCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleCategoryLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_ProductLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductsCreateInput"] | undefined
};
	["Contember_ProductUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_ProductRecipeUpsertProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutRecipeUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutRecipeCreateInput"] | undefined
};
	["Contember_ProductRecipeUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductRecipeUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_ProductRecipeLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutProductRecipesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutProductRecipesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductRecipeLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutProductRecipesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductRecipeLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutProductRecipesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductRecipesCreateInput"] | undefined
};
	["Contember_ProductRecipeUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductRecipeLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_PreparedPackingQuantityUpsertRecipeRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductRecipeUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeCreateInput"] | undefined
};
	["Contember_ProductPackingUpsertPreparedPackingQuantitiesRelationInput"]: {
		by?: GraphQLTypes["Contember_PreparedPackingQuantityUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PreparedPackingQuantityWithoutPackingUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PreparedPackingQuantityWithoutPackingCreateInput"] | undefined
};
	["Contember_ProductPackingLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductPackingWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertProductPackingsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductPackingLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_DeliveryMethodLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutDeliveryMethodsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutDeliveryMethodsCreateInput"] | undefined
};
	["Contember_DeliveryMethodUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_CourierUpsertDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodWithoutCourierHandlersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutCourierHandlersCreateInput"] | undefined
};
	["Contember_DeliveryRouteUpsertCourierRelationInput"]: {
		update?: GraphQLTypes["Contember_CourierWithoutRoutesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CourierWithoutRoutesCreateInput"] | undefined
};
	["Contember_DeliveryWaypointUpsertRouteRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryRouteWithoutWaypointsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRouteWithoutWaypointsCreateInput"] | undefined
};
	["Contember_OrderDeliveryUpsertDeliveryWaypointRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryWaypointWithoutDeliveryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryWaypointWithoutDeliveryCreateInput"] | undefined
};
	["Contember_OrderUpsertDeliveryRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderDeliveryWithoutOrderUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutOrderCreateInput"] | undefined
};
	["Contember_OrderEventUpsertOrderRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderWithoutEventsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutEventsCreateInput"] | undefined
};
	["Contember_OrderPaymentUpsertEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderEventUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderEventWithoutPaymentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderEventWithoutPaymentCreateInput"] | undefined
};
	["Contember_InvoiceUpsertOrderPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderPaymentUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderPaymentWithoutInvoiceUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderPaymentWithoutInvoiceCreateInput"] | undefined
};
	["Contember_CustomerUpsertInvoicesRelationInput"]: {
		by?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_InvoiceWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutCustomerCreateInput"] | undefined
};
	["Contember_AnonymousSessionUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutAnonymousSessionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutAnonymousSessionCreateInput"] | undefined
};
	["Contember_UserDeviceUpsertAnonymousSessionRelationInput"]: {
		update?: GraphQLTypes["Contember_AnonymousSessionWithoutDevicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AnonymousSessionWithoutDevicesCreateInput"] | undefined
};
	["Contember_UserUpsertDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_UserDeviceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_UserDeviceWithoutUserUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UserDeviceWithoutUserCreateInput"] | undefined
};
	["Contember_IdentificationAttemptUpsertUserRelationInput"]: {
		update?: GraphQLTypes["Contember_UserWithoutIdentificationAttemptsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutIdentificationAttemptsCreateInput"] | undefined
};
	["Contember_IdentificationCodeUpsertAttemptsRelationInput"]: {
		by?: GraphQLTypes["Contember_IdentificationAttemptUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_IdentificationAttemptWithoutCodeUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationAttemptWithoutCodeCreateInput"] | undefined
};
	["Contember_PointOfSaleApplicationDeviceUpsertIdentificationCodeRelationInput"]: {
		update?: GraphQLTypes["Contember_IdentificationCodeWithoutApplicationDevicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IdentificationCodeWithoutApplicationDevicesCreateInput"] | undefined
};
	["Contember_VenueUpsertPointOfSaleApplicationDevicesRelationInput"]: {
		by?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PointOfSaleApplicationDeviceWithoutVenueCreateInput"] | undefined
};
	["Contember_TimetableTemplateUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutTimetableTemplatesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutTimetableTemplatesCreateInput"] | undefined
};
	["Contember_TimetableShiftPositionUpsertTimetableTemplatesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableTemplateWithoutPositionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateWithoutPositionCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupUpsertPositionRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableShiftPositionWithoutShiftGroupsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftPositionWithoutShiftGroupsCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupUpdateGeneratedByTemplateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableTemplateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableTemplateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableTemplateUpdateInput"]: {
		order?: number | undefined,
	startDate?: GraphQLTypes["Date"] | undefined,
	period?: number | undefined,
	slots?: number | undefined,
	position?: GraphQLTypes["Contember_TimetableTemplateUpdatePositionEntityRelationInput"] | undefined,
	staffOptions?: Array<GraphQLTypes["Contember_TimetableTemplateUpdateStaffOptionsEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	venue?: GraphQLTypes["Contember_TimetableTemplateUpdateVenueEntityRelationInput"] | undefined,
	employeeRole?: GraphQLTypes["Contember_TimetableTemplateUpdateEmployeeRoleEntityRelationInput"] | undefined,
	workdaysOnly?: boolean | undefined,
	monday?: boolean | undefined,
	tuesday?: boolean | undefined,
	wednesday?: boolean | undefined,
	thursday?: boolean | undefined,
	friday?: boolean | undefined,
	saturday?: boolean | undefined,
	sunday?: boolean | undefined,
	type?: GraphQLTypes["Contember_TemplateType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftGroupUpsertGeneratedByTemplateRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableTemplateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateCreateInput"] | undefined
};
	["Contember_TimetableShiftUpsertGroupRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableShiftGroupWithoutShiftsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutShiftsCreateInput"] | undefined
};
	["Contember_TimetableShiftUpdateQueueItemEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftUpsertQueueItemRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_EmployeeQueueItemWithoutShiftUpdateInput"]: {
		start?: string | undefined,
	end?: string | undefined,
	queue?: GraphQLTypes["Contember_EmployeeQueueItemUpdateQueueEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_EmployeeQueueItemUpdateShiftsProfileEntityRelationInput"] | undefined,
	unassignInfo?: GraphQLTypes["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"] | undefined,
	meta?: GraphQLTypes["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemUpdateUnassignInfoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UnassignedShiftInfoUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"]: {
		hoursMissed?: number | undefined,
	legacyReason?: GraphQLTypes["Contember_UnassignedInfoReason"] | undefined,
	reason?: GraphQLTypes["Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UnassignedShiftInfoUpdateReasonEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_UnassignReasonCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UnassignReasonUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UnassignedShiftInfoUpsertReasonRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_UnassignReasonUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_UnassignReasonUpdateInput"]: {
		order?: number | undefined,
	name?: string | undefined,
	venues?: Array<GraphQLTypes["Contember_UnassignReasonUpdateVenuesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UnassignReasonUpdateVenuesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_UnassignReasonUpdateVenuesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_UnassignReasonUpsertVenuesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_UnassignReasonUpdateVenuesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"] | undefined
};
	["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_UnassignReasonUpsertVenuesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutEnabledUnassignReasonsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutEnabledUnassignReasonsCreateInput"] | undefined
};
	["Contember_UnassignedShiftInfoUpsertReasonRelationInput"]: {
		update?: GraphQLTypes["Contember_UnassignReasonUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UnassignReasonCreateInput"] | undefined
};
	["Contember_EmployeeQueueItemUpsertUnassignInfoRelationInput"]: {
		update?: GraphQLTypes["Contember_UnassignedShiftInfoWithoutQueueItemUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UnassignedShiftInfoWithoutQueueItemCreateInput"] | undefined
};
	["Contember_EmployeeQueueItemUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_EmployeeQueueItemMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableShiftUpsertQueueItemRelationInput"]: {
		update?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftCreateInput"] | undefined
};
	["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftUpsertSwapInfoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftSwapInfoUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"]: {
		assigned?: GraphQLTypes["Date"] | undefined,
	requested?: boolean | undefined,
	confirmed?: boolean | undefined,
	swappedShiftsProfile?: GraphQLTypes["Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableShiftSwapInfoUpdateSwappedShiftsProfileEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ShiftsProfileCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableShiftSwapInfoUpsertSwappedShiftsProfileRelationInput"]: {
		update?: GraphQLTypes["Contember_ShiftsProfileUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileCreateInput"] | undefined
};
	["Contember_TimetableShiftUpsertSwapInfoRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableShiftSwapInfoWithoutShiftUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftSwapInfoWithoutShiftCreateInput"] | undefined
};
	["Contember_ShiftsProfileUpsertShiftsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftWithoutShiftsProfileUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftWithoutShiftsProfileCreateInput"] | undefined
};
	["Contember_EmployeeQueueItemUpsertShiftsProfileRelationInput"]: {
		update?: GraphQLTypes["Contember_ShiftsProfileWithoutQueueItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutQueueItemsCreateInput"] | undefined
};
	["Contember_EmployeeQueueItemUpdateShiftEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TimetableShiftWithoutQueueItemCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftWithoutQueueItemUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_EmployeeQueueItemUpsertShiftRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_TimetableShiftWithoutQueueItemUpdateInput"]: {
		locked?: boolean | undefined,
	order?: number | undefined,
	assigned?: GraphQLTypes["Date"] | undefined,
	group?: GraphQLTypes["Contember_TimetableShiftUpdateGroupEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_TimetableShiftUpdateShiftsProfileEntityRelationInput"] | undefined,
	swapInfo?: GraphQLTypes["Contember_TimetableShiftUpdateSwapInfoEntityRelationInput"] | undefined,
	isOvertime?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_EmployeeQueueItemUpsertShiftRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableShiftWithoutQueueItemUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftWithoutQueueItemCreateInput"] | undefined
};
	["Contember_EmployeeQueueUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_EmployeeQueueItemWithoutQueueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutQueueCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupUpsertQueueRelationInput"]: {
		update?: GraphQLTypes["Contember_EmployeeQueueWithoutShiftGroupUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueWithoutShiftGroupCreateInput"] | undefined
};
	["Contember_TimetableShiftPositionUpsertShiftGroupsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftGroupWithoutPositionUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutPositionCreateInput"] | undefined
};
	["Contember_TimetableTemplateUpsertPositionRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftPositionWithoutTimetableTemplatesCreateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleUpsertTimetableTemplatesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableTemplateUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableTemplateWithoutEmployeeRoleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableTemplateWithoutEmployeeRoleCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupUpsertTypeRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftGroupsCreateInput"] | undefined
};
	["Contember_TimetableDayUpsertShiftGroupsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftGroupWithoutDayUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutDayCreateInput"] | undefined
};
	["Contember_TimetableDayUpdateMetaEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_TimetableDayMetadataUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueUpsertTimetableDaysRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableDayUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableDayWithoutVenueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableDayWithoutVenueCreateInput"] | undefined
};
	["Contember_ChannelUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutChannelsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutChannelsCreateInput"] | undefined
};
	["Contember_TagUpsertPublicInChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelWithoutPublicTagsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutPublicTagsCreateInput"] | undefined
};
	["Contember_DeliveryZoneUpsertDeliveryZonesOfTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutDeliveryZonesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutDeliveryZonesCreateInput"] | undefined
};
	["Contember_DeliveryZoneUpdateAddressesMetadataEntityRelationInput"]: {
		connect?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_AddressMetadataUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductUpsertDeliveryZonesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfProductsCreateInput"] | undefined
};
	["Contember_FulfillmentNoteUpsertProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutFulfillmentNotesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutFulfillmentNotesCreateInput"] | undefined
};
	["Contember_TagUpsertFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteWithoutTagsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutTagsCreateInput"] | undefined
};
	["Contember_ChannelUpsertPublicTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutPublicInChannelsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutPublicInChannelsCreateInput"] | undefined
};
	["Contember_CustomerUpsertChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelWithoutCustomersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutCustomersCreateInput"] | undefined
};
	["Contember_CustomerCreditUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutCreditsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutCreditsCreateInput"] | undefined
};
	["Contember_CustomerCreditUpdateVoucherRedemptionEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CustomerCreditUpsertVoucherRedemptionRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VoucherRedemptionUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	note?: string | undefined,
	manualRedemptionNote?: string | undefined,
	orderDiscount?: GraphQLTypes["Contember_VoucherRedemptionUpdateOrderDiscountEntityRelationInput"] | undefined,
	voucher?: GraphQLTypes["Contember_VoucherRedemptionUpdateVoucherEntityRelationInput"] | undefined,
	customer?: GraphQLTypes["Contember_VoucherRedemptionUpdateCustomerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CustomerCreditUpsertVoucherRedemptionRelationInput"]: {
		update?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreditUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VoucherRedemptionWithoutCustomerCreditCreateInput"] | undefined
};
	["Contember_CustomerCreditTransactionUpsertCustomerCreditRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerCreditWithoutTransactionsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditWithoutTransactionsCreateInput"] | undefined
};
	["Contember_OrderDiscountUpsertCustomerCreditTransactionsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerCreditTransactionUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditTransactionWithoutOrderDiscountCreateInput"] | undefined
};
	["Contember_OrderUpsertDiscountsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderDiscountUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderDiscountWithoutOrderUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderDiscountWithoutOrderCreateInput"] | undefined
};
	["Contember_OrderItemUpsertOrderRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderWithoutItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutItemsCreateInput"] | undefined
};
	["Contember_ProductUpsertOrderItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderItemWithoutProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderItemWithoutProductCreateInput"] | undefined
};
	["Contember_StocksManagerUpsertManagedProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutManagerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutManagerCreateInput"] | undefined
};
	["Contember_StaffUpsertStocksManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_StocksManagerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StocksManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_StaffUpdateVenuesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutStaffMembersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffUpdateVenuesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_StaffUpsertVenuesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_StaffUpdateVenuesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_VenueWithoutStaffMembersUpdateInput"] | undefined
};
	["Contember_VenueWithoutStaffMembersUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableDays?: Array<GraphQLTypes["Contember_VenueUpdateTimetableDaysEntityRelationInput"]> | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_StaffUpsertVenuesRelationInput"]: {
		by?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutStaffMembersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutStaffMembersCreateInput"] | undefined
};
	["Contember_ShiftsManagerUpsertStaffRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffWithoutShiftsManagerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutShiftsManagerCreateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleUpsertShiftsManagersRelationInput"]: {
		by?: GraphQLTypes["Contember_ShiftsManagerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsManagerWithoutManagedRolesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsManagerWithoutManagedRolesCreateInput"] | undefined
};
	["Contember_ShiftsProfileUpsertRolesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsProfilesCreateInput"] | undefined
};
	["Contember_StaffUpsertShiftsProfileRelationInput"]: {
		update?: GraphQLTypes["Contember_ShiftsProfileWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutStaffCreateInput"] | undefined
};
	["Contember_UserUpsertStaffRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffWithoutUserUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutUserCreateInput"] | undefined
};
	["Contember_CustomerUpsertUserRelationInput"]: {
		update?: GraphQLTypes["Contember_UserWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutCustomerCreateInput"] | undefined
};
	["Contember_OrderUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutOrdersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutOrdersCreateInput"] | undefined
};
	["Contember_CartUpsertOrderRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderWithoutCartUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutCartCreateInput"] | undefined
};
	["Contember_CustomerUpsertCartsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CartWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutCustomerCreateInput"] | undefined
};
	["Contember_UserUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutUserUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutUserCreateInput"] | undefined
};
	["Contember_StaffUpsertUserRelationInput"]: {
		update?: GraphQLTypes["Contember_UserWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_UserWithoutStaffCreateInput"] | undefined
};
	["Contember_BusinessToBusinessManagerUpsertStaffRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffWithoutBusinessToBusinessManagerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutBusinessToBusinessManagerCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpsertAccountManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutManagedBusinessesCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpdateLogoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertLogoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessCustomerUpsertLogoRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpdateBusinessServiceEventsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessCustomerUpdateBusinessServiceEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"] | undefined
};
	["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"]: {
		createdAt?: GraphQLTypes["DateTime"] | undefined,
	fulfilledAt?: GraphQLTypes["DateTime"] | undefined,
	businessService?: GraphQLTypes["Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput"] | undefined,
	filledNumberOfPeople?: number | undefined,
	filledInvoiced?: boolean | undefined,
	filledEquipment?: string | undefined,
	filledMillTamDem?: boolean | undefined,
	filledSOEspresso?: boolean | undefined,
	filledFilterCoffeeInMenu?: boolean | undefined,
	filledWaterMeterStatus?: string | undefined,
	filledServiceAction?: string | undefined,
	filledInvoiceIdentifier?: string | undefined,
	filledNote?: string | undefined,
	filledSupervisor?: GraphQLTypes["Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput"] | undefined,
	filledContact?: string | undefined,
	filledPhone?: boolean | undefined,
	filledEmail?: boolean | undefined,
	filledGrindingStonesCondition?: string | undefined,
	createdBy?: GraphQLTypes["Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceEventUpdateBusinessServiceEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessServiceCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessServiceUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessServiceEventUpsertBusinessServiceRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessServiceUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessServiceUpdateInput"]: {
		order?: number | undefined,
	requireNote?: number | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessServiceUpdateLocalesEntityRelationInput"]> | undefined,
	requireNumberOfPeople?: number | undefined,
	requireInvoiced?: number | undefined,
	requireEquipment?: number | undefined,
	requireMillTamDem?: number | undefined,
	requireSOEspresso?: number | undefined,
	requireFilterCoffeeInMenu?: number | undefined,
	requireWaterMeterStatus?: number | undefined,
	requireServiceAction?: number | undefined,
	optionsEquipment?: string | undefined,
	optionsServiceAction?: string | undefined,
	requireInvoiceIdentifier?: number | undefined,
	requireSupervisor?: number | undefined,
	requireContact?: number | undefined,
	requirePhone?: number | undefined,
	requireEmail?: number | undefined,
	requireGrindingStonesCondition?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessServiceUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessServiceUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BusinessServiceUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_BusinessServiceLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	labelNote?: string | undefined,
	locale?: GraphQLTypes["Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput"] | undefined,
	labelNumberOfPeople?: string | undefined,
	labelInvoiced?: string | undefined,
	labelEquipment?: string | undefined,
	labelMillTamDem?: string | undefined,
	labelSOEspresso?: string | undefined,
	labelFilterCoffeeInMenu?: string | undefined,
	labelWaterMeterStatus?: string | undefined,
	labelServiceAction?: string | undefined,
	labelInvoiceIdentifier?: string | undefined,
	labelSupervisor?: string | undefined,
	labelContact?: string | undefined,
	labelPhone?: string | undefined,
	labelEmail?: string | undefined,
	labelGrindingStonesCondition?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutBusinessServicesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutBusinessServicesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessServiceLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutBusinessServicesUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutBusinessServicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutBusinessServicesCreateInput"] | undefined
};
	["Contember_BusinessServiceUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessServiceLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_BusinessServiceEventUpsertBusinessServiceRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessServiceUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceCreateInput"] | undefined
};
	["Contember_BusinessServiceEventUpdateFilledSupervisorEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_StaffUpdateInput"]: {
		bartender?: GraphQLTypes["Contember_StaffUpdateBartenderEntityRelationInput"] | undefined,
	courier?: GraphQLTypes["Contember_StaffUpdateCourierEntityRelationInput"] | undefined,
	cleaner?: GraphQLTypes["Contember_StaffUpdateCleanerEntityRelationInput"] | undefined,
	shiftsManager?: GraphQLTypes["Contember_StaffUpdateShiftsManagerEntityRelationInput"] | undefined,
	businessToBusinessManager?: GraphQLTypes["Contember_StaffUpdateBusinessToBusinessManagerEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_StaffUpdateUserEntityRelationInput"] | undefined,
	shiftsProfile?: GraphQLTypes["Contember_StaffUpdateShiftsProfileEntityRelationInput"] | undefined,
	stocksManager?: GraphQLTypes["Contember_StaffUpdateStocksManagerEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_StaffUpdatePhotoEntityRelationInput"] | undefined,
	courierManager?: GraphQLTypes["Contember_StaffUpdateCourierManagerEntityRelationInput"] | undefined,
	picker?: GraphQLTypes["Contember_StaffUpdatePickerEntityRelationInput"] | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	remunerationProfile?: GraphQLTypes["Contember_StaffUpdateRemunerationProfileEntityRelationInput"] | undefined,
	cashier?: GraphQLTypes["Contember_StaffUpdateCashierEntityRelationInput"] | undefined,
	venues?: Array<GraphQLTypes["Contember_StaffUpdateVenuesEntityRelationInput"]> | undefined,
	internalBuyer?: GraphQLTypes["Contember_StaffUpdateInternalBuyerEntityRelationInput"] | undefined,
	salesManager?: GraphQLTypes["Contember_StaffUpdateSalesManagerEntityRelationInput"] | undefined,
	cashierManager?: GraphQLTypes["Contember_StaffUpdateCashierManagerEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessServiceEventUpsertFilledSupervisorRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffCreateInput"] | undefined
};
	["Contember_BusinessServiceEventUpdateCreatedByEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_StaffCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_StaffUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessServiceEventUpsertCreatedByRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_StaffUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessServiceEventUpsertCreatedByRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpsertBusinessServiceEventsRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessServiceEventUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessServiceEventWithoutBusinessCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessServiceEventWithoutBusinessCustomerCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpdateDoubleshotCoffeeEquipmentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_DoubleshotCoffeeEquipmentUpdateInput"]: {
		espressoMachine?: string | undefined,
	espressoGrinder?: string | undefined,
	batchBrew?: string | undefined,
	batchGrinder?: string | undefined,
	waterFilter?: string | undefined,
	mechanicalFilter?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BusinessCustomerUpsertDoubleshotCoffeeEquipmentRelationInput"]: {
		update?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DoubleshotCoffeeEquipmentCreateInput"] | undefined
};
	["Contember_CustomerUpsertOwnerOfBusinessesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerWithoutOwnersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutOwnersCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpsertMembersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutMemberOfBusinessesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutMemberOfBusinessesCreateInput"] | undefined
};
	["Contember_BusinessToBusinessManagerUpsertManagedBusinessesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerWithoutAccountManagerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutAccountManagerCreateInput"] | undefined
};
	["Contember_StaffUpsertBusinessToBusinessManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessToBusinessManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_ShiftsProfileUpsertStaffRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffWithoutShiftsProfileUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutShiftsProfileCreateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleUpsertShiftsProfilesRelationInput"]: {
		by?: GraphQLTypes["Contember_ShiftsProfileUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ShiftsProfileWithoutRolesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutRolesCreateInput"] | undefined
};
	["Contember_TimetableDayNoteUpsertRoleRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableEmployeeRoleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleCreateInput"] | undefined
};
	["Contember_TimetableDayUpsertNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableDayNoteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableDayNoteWithoutDayUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableDayNoteWithoutDayCreateInput"] | undefined
};
	["Contember_TimetableDayUpdateVenueEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VenueWithoutTimetableDaysCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VenueWithoutTimetableDaysUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_TimetableDayUpsertVenueRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VenueUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_VenueWithoutTimetableDaysUpdateInput"]: {
		name?: string | undefined,
	deadlineTemplate?: GraphQLTypes["Contember_VenueUpdateDeadlineTemplateEntityRelationInput"] | undefined,
	timetableTemplates?: Array<GraphQLTypes["Contember_VenueUpdateTimetableTemplatesEntityRelationInput"]> | undefined,
	staffMembers?: Array<GraphQLTypes["Contember_VenueUpdateStaffMembersEntityRelationInput"]> | undefined,
	employeeRoles?: Array<GraphQLTypes["Contember_VenueUpdateEmployeeRolesEntityRelationInput"]> | undefined,
	pointOfSaleApplicationDevices?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleApplicationDevicesEntityRelationInput"]> | undefined,
	pointOfSaleTerminals?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSaleTerminalsEntityRelationInput"]> | undefined,
	pointOfSalePrinters?: Array<GraphQLTypes["Contember_VenueUpdatePointOfSalePrintersEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_VenueUpdateOrdersEntityRelationInput"]> | undefined,
	fulfillmentNotes?: Array<GraphQLTypes["Contember_VenueUpdateFulfillmentNotesEntityRelationInput"]> | undefined,
	areas?: Array<GraphQLTypes["Contember_VenueUpdateAreasEntityRelationInput"]> | undefined,
	enabledUnassignReasons?: Array<GraphQLTypes["Contember_VenueUpdateEnabledUnassignReasonsEntityRelationInput"]> | undefined,
	categories?: Array<GraphQLTypes["Contember_VenueUpdateCategoriesEntityRelationInput"]> | undefined,
	channels?: Array<GraphQLTypes["Contember_VenueUpdateChannelsEntityRelationInput"]> | undefined,
	address?: string | undefined,
	pointOfSalePngBase64ReceiptLogo?: string | undefined,
	pointOfSaleInitialView?: GraphQLTypes["Contember_PointOfSaleInitialView"] | undefined,
	pointOfSalePreferPrintReceipt?: boolean | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_TimetableDayUpsertVenueRelationInput"]: {
		update?: GraphQLTypes["Contember_VenueWithoutTimetableDaysUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VenueWithoutTimetableDaysCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupUpsertDayRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableDayWithoutShiftGroupsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableDayWithoutShiftGroupsCreateInput"] | undefined
};
	["Contember_EmployeeQueueUpsertShiftGroupRelationInput"]: {
		update?: GraphQLTypes["Contember_TimetableShiftGroupWithoutQueueUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutQueueCreateInput"] | undefined
};
	["Contember_EmployeeQueueItemUpsertQueueRelationInput"]: {
		update?: GraphQLTypes["Contember_EmployeeQueueWithoutItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueWithoutItemsCreateInput"] | undefined
};
	["Contember_ShiftsProfileUpsertQueueItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_EmployeeQueueItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftsProfileUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_EmployeeQueueItemWithoutShiftsProfileCreateInput"] | undefined
};
	["Contember_TimetableShiftUpsertShiftsProfileRelationInput"]: {
		update?: GraphQLTypes["Contember_ShiftsProfileWithoutShiftsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsProfileWithoutShiftsCreateInput"] | undefined
};
	["Contember_TimetableShiftGroupUpsertShiftsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftWithoutGroupUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftWithoutGroupCreateInput"] | undefined
};
	["Contember_TimetableEmployeeRoleUpsertShiftGroupsRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableShiftGroupUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableShiftGroupWithoutTypeUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableShiftGroupWithoutTypeCreateInput"] | undefined
};
	["Contember_ShiftsManagerUpsertManagedRolesRelationInput"]: {
		by?: GraphQLTypes["Contember_TimetableEmployeeRoleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TimetableEmployeeRoleWithoutShiftsManagersCreateInput"] | undefined
};
	["Contember_StaffUpsertShiftsManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_ShiftsManagerWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ShiftsManagerWithoutStaffCreateInput"] | undefined
};
	["Contember_CourierUpsertStaffRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffWithoutCourierUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutCourierCreateInput"] | undefined
};
	["Contember_DeliveryMethodUpsertCourierHandlersRelationInput"]: {
		by?: GraphQLTypes["Contember_CourierUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CourierWithoutDeliveryMethodsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CourierWithoutDeliveryMethodsCreateInput"] | undefined
};
	["Contember_OrderDeliveryUpsertMethodRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryMethodUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodCreateInput"] | undefined
};
	["Contember_DeliveryWaypointUpsertDeliveryRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderDeliveryWithoutDeliveryWaypointUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderDeliveryWithoutDeliveryWaypointCreateInput"] | undefined
};
	["Contember_DeliveryRouteUpsertWaypointsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryWaypointUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryWaypointWithoutRouteUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryWaypointWithoutRouteCreateInput"] | undefined
};
	["Contember_CourierUpsertRoutesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryRouteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryRouteWithoutCourierUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRouteWithoutCourierCreateInput"] | undefined
};
	["Contember_StaffUpsertCourierRelationInput"]: {
		update?: GraphQLTypes["Contember_CourierWithoutStaffUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CourierWithoutStaffCreateInput"] | undefined
};
	["Contember_StocksManagerUpsertStaffRelationInput"]: {
		update?: GraphQLTypes["Contember_StaffWithoutStocksManagerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StaffWithoutStocksManagerCreateInput"] | undefined
};
	["Contember_ProductUpsertManagerRelationInput"]: {
		update?: GraphQLTypes["Contember_StocksManagerWithoutManagedProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_StocksManagerWithoutManagedProductsCreateInput"] | undefined
};
	["Contember_VirtualProductUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductWithoutVirtualProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutVirtualProductCreateInput"] | undefined
};
	["Contember_WorkspaceUpsertCreditsProductRelationInput"]: {
		update?: GraphQLTypes["Contember_VirtualProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VirtualProductCreateInput"] | undefined
};
	["Contember_WorkspaceUpdateDiscountVatRateEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WorkspaceUpsertDiscountVatRateRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_VatRateUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WorkspaceUpsertDiscountVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_FreshingContainerUpsertWorkspaceRelationInput"]: {
		update?: GraphQLTypes["Contember_WorkspaceWithoutFreshingContainersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_WorkspaceWithoutFreshingContainersCreateInput"] | undefined
};
	["Contember_ProductIngredientUpsertFreshingContainerRelationInput"]: {
		update?: GraphQLTypes["Contember_FreshingContainerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FreshingContainerCreateInput"] | undefined
};
	["Contember_ProductIngredientUpdateImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientUpsertImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductIngredientUpdateSuppliersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IngredientSupplierListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientUpsertSuppliersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IngredientSupplierListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_IngredientSupplierListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_IngredientSupplierListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IngredientSupplierItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IngredientSupplierListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_IngredientSupplierListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_IngredientSupplierItemWithoutListUpdateInput"] | undefined
};
	["Contember_IngredientSupplierItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	supplier?: GraphQLTypes["Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierItemUpdateSupplierEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IngredientSupplierCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IngredientSupplierItemUpsertSupplierRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IngredientSupplierUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_IngredientSupplierUpdateInput"]: {
		internalName?: string | undefined,
	internalNote?: string | undefined,
	locales?: Array<GraphQLTypes["Contember_IngredientSupplierUpdateLocalesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IngredientSupplierUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_IngredientSupplierUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutIngredientSuppliersCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutIngredientSuppliersUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_IngredientSupplierLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutIngredientSuppliersUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productCategoryLists?: Array<GraphQLTypes["Contember_LocaleUpdateProductCategoryListsEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_IngredientSupplierLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutIngredientSuppliersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutIngredientSuppliersCreateInput"] | undefined
};
	["Contember_IngredientSupplierUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_IngredientSupplierLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_IngredientSupplierItemUpsertSupplierRelationInput"]: {
		update?: GraphQLTypes["Contember_IngredientSupplierUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierCreateInput"] | undefined
};
	["Contember_IngredientSupplierListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_IngredientSupplierItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_IngredientSupplierItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierItemWithoutListCreateInput"] | undefined
};
	["Contember_ProductIngredientUpsertSuppliersRelationInput"]: {
		update?: GraphQLTypes["Contember_IngredientSupplierListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_IngredientSupplierListCreateInput"] | undefined
};
	["Contember_AllergenUpsertIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientWithoutAllergensUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutAllergensCreateInput"] | undefined
};
	["Contember_AllergenLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_AllergenWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AllergenWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertAllergensRelationInput"]: {
		by?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_AllergenLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AllergenLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_OrderRecurrenceLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutOrderRecurrencesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutOrderRecurrencesCreateInput"] | undefined
};
	["Contember_OrderRecurrenceUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpsertRecurrencesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderRecurrenceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderRecurrenceWithoutBusinessCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceWithoutBusinessCategoryCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductListWithoutBusinessCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductListWithoutBusinessCategoryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryUpsertSpecificProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductListWithoutBusinessCategoryUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ProductListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductListItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductListItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductListItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductListItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductListItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductListItemWithoutListUpdateInput"] | undefined
};
	["Contember_ProductListItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	product?: GraphQLTypes["Contember_ProductListItemUpdateProductEntityRelationInput"] | undefined,
	defaultQuantityForOrder?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductListItemUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductListItemUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductListItemUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_ProductListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductListItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductListItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductListItemWithoutListCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpsertSpecificProductsRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductListWithoutBusinessCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductListWithoutBusinessCategoryCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"]> | undefined,
	specificProductsProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListUpdateLocalesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryListUpsertLocalesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductCategoryListUpdateLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"] | undefined
};
	["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"]: {
		name?: string | undefined,
	locale?: GraphQLTypes["Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListLocaleUpdateLocaleEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LocaleWithoutProductCategoryListsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LocaleWithoutProductCategoryListsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryListLocaleUpsertLocaleRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LocaleUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_LocaleWithoutProductCategoryListsUpdateInput"]: {
		businessCategories?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessCategoriesEntityRelationInput"]> | undefined,
	productIngredients?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientsEntityRelationInput"]> | undefined,
	orderRecurrences?: Array<GraphQLTypes["Contember_LocaleUpdateOrderRecurrencesEntityRelationInput"]> | undefined,
	blogs?: Array<GraphQLTypes["Contember_LocaleUpdateBlogsEntityRelationInput"]> | undefined,
	blogPosts?: Array<GraphQLTypes["Contember_LocaleUpdateBlogPostsEntityRelationInput"]> | undefined,
	identifier?: GraphQLTypes["Contember_LocaleUpdateIdentifierEntityRelationInput"] | undefined,
	menus?: Array<GraphQLTypes["Contember_LocaleUpdateMenusEntityRelationInput"]> | undefined,
	pages?: Array<GraphQLTypes["Contember_LocaleUpdatePagesEntityRelationInput"]> | undefined,
	productIngredientCategories?: Array<GraphQLTypes["Contember_LocaleUpdateProductIngredientCategoriesEntityRelationInput"]> | undefined,
	allergens?: Array<GraphQLTypes["Contember_LocaleUpdateAllergensEntityRelationInput"]> | undefined,
	productPackings?: Array<GraphQLTypes["Contember_LocaleUpdateProductPackingsEntityRelationInput"]> | undefined,
	products?: Array<GraphQLTypes["Contember_LocaleUpdateProductsEntityRelationInput"]> | undefined,
	productRecipes?: Array<GraphQLTypes["Contember_LocaleUpdateProductRecipesEntityRelationInput"]> | undefined,
	pointOfSaleCategories?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoriesEntityRelationInput"]> | undefined,
	pointOfSaleCategoryProducts?: Array<GraphQLTypes["Contember_LocaleUpdatePointOfSaleCategoryProductsEntityRelationInput"]> | undefined,
	deliveryMethods?: Array<GraphQLTypes["Contember_LocaleUpdateDeliveryMethodsEntityRelationInput"]> | undefined,
	ingredientSuppliers?: Array<GraphQLTypes["Contember_LocaleUpdateIngredientSuppliersEntityRelationInput"]> | undefined,
	identificationCodes?: Array<GraphQLTypes["Contember_LocaleUpdateIdentificationCodesEntityRelationInput"]> | undefined,
	pipelineStages?: Array<GraphQLTypes["Contember_LocaleUpdatePipelineStagesEntityRelationInput"]> | undefined,
	subscriptionBoxes?: Array<GraphQLTypes["Contember_LocaleUpdateSubscriptionBoxesEntityRelationInput"]> | undefined,
	businessServices?: Array<GraphQLTypes["Contember_LocaleUpdateBusinessServicesEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutProductCategoryListsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductCategoryListsCreateInput"] | undefined
};
	["Contember_ProductCategoryListUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_ProductCategoryListUpdateSpecificProductsProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"]: {
		order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListUpsertSpecificProductsProductCategoriesOfBusinessCategoryRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsProductCategoriesCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpsertSeasonalProductCategoriesRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListWithoutSeasonalProductCategoriesOfBusinessCategoryCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductCategoryListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ProductCategoryListUpdateItemsEntityRelationInput"]> | undefined,
	locales?: Array<GraphQLTypes["Contember_ProductCategoryListUpdateLocalesEntityRelationInput"]> | undefined,
	seasonalProductCategoriesOfBusinessCategory?: GraphQLTypes["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListUpdateSeasonalProductCategoriesOfBusinessCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"]: {
		order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined,
	specificProducts?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductCategoryListUpsertSeasonalProductCategoriesOfBusinessCategoryRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutSeasonalProductCategoriesCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpsertSpecificProductsProductCategoriesRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListWithoutSpecificProductsProductCategoriesOfBusinessCategoryCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpsertCategoryRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessCategoryWithoutBusinessCustomersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutBusinessCustomersCreateInput"] | undefined
};
	["Contember_CustomerUpsertMemberOfBusinessesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerWithoutMembersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutMembersCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpsertOwnersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutOwnerOfBusinessesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutOwnerOfBusinessesCreateInput"] | undefined
};
	["Contember_CustomerUpsertBusinessRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessCustomerWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerIngredientRatingUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutIngredientRatingsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutIngredientRatingsCreateInput"] | undefined
};
	["Contember_ProductIngredientUpsertCustomerRatingsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutIngredientUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutIngredientCreateInput"] | undefined
};
	["Contember_ProductIngredientCategoryUpsertIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientWithoutCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutCategoriesCreateInput"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertProductIngredientCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_PageLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutPagesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutPagesCreateInput"] | undefined
};
	["Contember_LinkableUpsertPageRelationInput"]: {
		update?: GraphQLTypes["Contember_PageLocaleWithoutLinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PageLocaleWithoutLinkCreateInput"] | undefined
};
	["Contember_LinkableUpdateRedirectEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_RedirectWithoutLinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_RedirectWithoutLinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_LinkableUpsertRedirectRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_RedirectUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_RedirectWithoutLinkUpdateInput"]: {
		target?: GraphQLTypes["Contember_RedirectUpdateTargetEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_RedirectUpdateTargetEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_RedirectUpsertTargetRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_RedirectUpsertTargetRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_LinkableUpsertRedirectRelationInput"]: {
		update?: GraphQLTypes["Contember_RedirectWithoutLinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_RedirectWithoutLinkCreateInput"] | undefined
};
	["Contember_WikiPageUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkableWithoutWikiPageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkableWithoutWikiPageCreateInput"] | undefined
};
	["Contember_WikiPageUpdateContentEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ContentUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WikiPageUpsertContentRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ContentUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_WikiPageUpsertContentRelationInput"]: {
		update?: GraphQLTypes["Contember_ContentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined
};
	["Contember_WikiPageUpsertParentRelationInput"]: {
		update?: GraphQLTypes["Contember_WikiPageWithoutChildrenUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_WikiPageWithoutChildrenCreateInput"] | undefined
};
	["Contember_WikiPageUpdateChildrenEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_WikiPageWithoutParentCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_WikiPageUpdateChildrenRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_WikiPageUpsertChildrenRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_WikiPageUpdateChildrenRelationInput"]: {
		by?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_WikiPageWithoutParentUpdateInput"] | undefined
};
	["Contember_WikiPageWithoutParentUpdateInput"]: {
		order?: number | undefined,
	title?: string | undefined,
	emoji?: string | undefined,
	link?: GraphQLTypes["Contember_WikiPageUpdateLinkEntityRelationInput"] | undefined,
	children?: Array<GraphQLTypes["Contember_WikiPageUpdateChildrenEntityRelationInput"]> | undefined,
	content?: GraphQLTypes["Contember_WikiPageUpdateContentEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_WikiPageUpsertChildrenRelationInput"]: {
		by?: GraphQLTypes["Contember_WikiPageUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_WikiPageWithoutParentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_WikiPageWithoutParentCreateInput"] | undefined
};
	["Contember_LinkableUpsertWikiPageRelationInput"]: {
		update?: GraphQLTypes["Contember_WikiPageWithoutLinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_WikiPageWithoutLinkCreateInput"] | undefined
};
	["Contember_PageLocaleUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkableWithoutPageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkableWithoutPageCreateInput"] | undefined
};
	["Contember_LocaleUpsertPagesRelationInput"]: {
		by?: GraphQLTypes["Contember_PageLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PageLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PageLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_BlogPostLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutBlogPostsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutBlogPostsCreateInput"] | undefined
};
	["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SeoCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SeoUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostLocaleUpsertSeoRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SeoUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BlogPostLocaleUpsertSeoRelationInput"]: {
		update?: GraphQLTypes["Contember_SeoUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SeoCreateInput"] | undefined
};
	["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostLocaleUpdateProductsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostLocaleUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BlogPostLocaleUpdateProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined
};
	["Contember_BlogPostLocaleUpsertProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_LinkableUpsertBlogPostRelationInput"]: {
		update?: GraphQLTypes["Contember_BlogPostLocaleWithoutLinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostLocaleWithoutLinkCreateInput"] | undefined
};
	["Contember_LinkUpsertInternalLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkableUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkableCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateBlogPostsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogPostListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertBlogPostsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogPostListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BlogPostListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_BlogPostListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogPostListItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_BlogPostListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_BlogPostListItemWithoutListUpdateInput"] | undefined
};
	["Contember_BlogPostListItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	caption?: string | undefined,
	blogPost?: GraphQLTypes["Contember_BlogPostListItemUpdateBlogPostEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostListItemUpdateBlogPostEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BlogPostLocaleCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostLocaleUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostListItemUpsertBlogPostRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BlogPostLocaleUpdateInput"]: {
		title?: string | undefined,
	availableForWeb?: boolean | undefined,
	availableForMobile?: boolean | undefined,
	root?: GraphQLTypes["Contember_BlogPostLocaleUpdateRootEntityRelationInput"] | undefined,
	locale?: GraphQLTypes["Contember_BlogPostLocaleUpdateLocaleEntityRelationInput"] | undefined,
	cover?: GraphQLTypes["Contember_BlogPostLocaleUpdateCoverEntityRelationInput"] | undefined,
	content?: GraphQLTypes["Contember_BlogPostLocaleUpdateContentEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"] | undefined,
	seo?: GraphQLTypes["Contember_BlogPostLocaleUpdateSeoEntityRelationInput"] | undefined,
	products?: Array<GraphQLTypes["Contember_BlogPostLocaleUpdateProductsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkableWithoutBlogPostCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkableWithoutBlogPostUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_BlogPostLocaleUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkableUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_LinkableWithoutBlogPostUpdateInput"]: {
		url?: string | undefined,
	page?: GraphQLTypes["Contember_LinkableUpdatePageEntityRelationInput"] | undefined,
	wikiPage?: GraphQLTypes["Contember_LinkableUpdateWikiPageEntityRelationInput"] | undefined,
	redirect?: GraphQLTypes["Contember_LinkableUpdateRedirectEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_BlogPostLocaleUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkableWithoutBlogPostUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkableWithoutBlogPostCreateInput"] | undefined
};
	["Contember_BlogPostListItemUpsertBlogPostRelationInput"]: {
		update?: GraphQLTypes["Contember_BlogPostLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostLocaleCreateInput"] | undefined
};
	["Contember_BlogPostListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_BlogPostListItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostListItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostListItemWithoutListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertBlogPostsRelationInput"]: {
		update?: GraphQLTypes["Contember_BlogPostListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateLinksEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertLinksRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ContentReferenceUpsertLinksRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateProductsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertProductsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ProductListUpdateItemsEntityRelationInput"]> | undefined,
	businessCategory?: GraphQLTypes["Contember_ProductListUpdateBusinessCategoryEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductListUpdateBusinessCategoryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductListUpsertBusinessCategoryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"]: {
		order?: number | undefined,
	visibleToNewCustomers?: boolean | undefined,
	locales?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateLocalesEntityRelationInput"]> | undefined,
	internalName?: string | undefined,
	businessCustomers?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateBusinessCustomersEntityRelationInput"]> | undefined,
	recurrences?: Array<GraphQLTypes["Contember_BusinessCategoryUpdateRecurrencesEntityRelationInput"]> | undefined,
	seasonalProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSeasonalProductCategoriesEntityRelationInput"] | undefined,
	specificProductsProductCategories?: GraphQLTypes["Contember_BusinessCategoryUpdateSpecificProductsProductCategoriesEntityRelationInput"] | undefined,
	slug?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductListUpsertBusinessCategoryRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutSpecificProductsCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertProductsRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateHeroEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_HeroCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_HeroUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertHeroRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_HeroUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_HeroUpdateInput"]: {
		showLogo?: boolean | undefined,
	middleContent?: string | undefined,
	medium?: GraphQLTypes["Contember_HeroUpdateMediumEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_HeroUpdateMediumEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_HeroUpsertMediumRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_HeroUpsertMediumRelationInput"]: {
		update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertHeroRelationInput"]: {
		update?: GraphQLTypes["Contember_HeroUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_HeroCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateGalleryEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_GalleryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_GalleryUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertGalleryRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_GalleryUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_GalleryUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_GalleryUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GalleryUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_GalleryItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_GalleryUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_GalleryUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_GalleryItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_GalleryItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_GalleryItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_GalleryUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_GalleryItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_GalleryItemWithoutListUpdateInput"] | undefined
};
	["Contember_GalleryItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	caption?: string | undefined,
	medium?: GraphQLTypes["Contember_GalleryItemUpdateMediumEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GalleryItemUpdateMediumEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_GalleryItemUpsertMediumRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_GalleryItemUpsertMediumRelationInput"]: {
		update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_GalleryUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_GalleryItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_GalleryItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_GalleryItemWithoutListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertGalleryRelationInput"]: {
		update?: GraphQLTypes["Contember_GalleryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_GalleryCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateSocialsAndAppsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_SocialsAndAppsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_SocialsAndAppsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertSocialsAndAppsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_SocialsAndAppsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ContentReferenceUpsertSocialsAndAppsRelationInput"]: {
		update?: GraphQLTypes["Contember_SocialsAndAppsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_SocialsAndAppsCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateProductGridEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductGridCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductGridUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertProductGridRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductGridUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductGridUpdateInput"]: {
		isCarousel?: boolean | undefined,
	categories?: Array<GraphQLTypes["Contember_ProductGridUpdateCategoriesEntityRelationInput"]> | undefined,
	type?: GraphQLTypes["Contember_ProductGridType"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGridUpdateCategoriesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductGridCategoryWithoutRootCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductGridUpdateCategoriesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGridUpsertCategoriesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductGridUpdateCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductGridCategoryWithoutRootUpdateInput"] | undefined
};
	["Contember_ProductGridCategoryWithoutRootUpdateInput"]: {
		order?: number | undefined,
	title?: string | undefined,
	items?: Array<GraphQLTypes["Contember_ProductGridCategoryUpdateItemsEntityRelationInput"]> | undefined,
	images?: GraphQLTypes["Contember_ProductGridCategoryUpdateImagesEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGridCategoryUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductGridItemWithoutCategoryCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductGridCategoryUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGridCategoryUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductGridCategoryUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductGridItemWithoutCategoryUpdateInput"] | undefined
};
	["Contember_ProductGridItemWithoutCategoryUpdateInput"]: {
		order?: number | undefined,
	title?: string | undefined,
	lead?: string | undefined,
	product?: GraphQLTypes["Contember_ProductGridItemUpdateProductEntityRelationInput"] | undefined,
	medium?: GraphQLTypes["Contember_ProductGridItemUpdateMediumEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_ProductGridItemUpdateLinkEntityRelationInput"] | undefined,
	description?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductGridItemUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGridItemUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductGridItemUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_ProductGridItemUpdateMediumEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGridItemUpsertMediumRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductGridItemUpsertMediumRelationInput"]: {
		update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_ProductGridItemUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGridItemUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductGridItemUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_ProductGridCategoryUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGridItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductGridItemWithoutCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductGridItemWithoutCategoryCreateInput"] | undefined
};
	["Contember_ProductGridCategoryUpdateImagesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductGridCategoryUpsertImagesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductGridCategoryUpsertImagesRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageListCreateInput"] | undefined
};
	["Contember_ProductGridUpsertCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGridCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductGridCategoryWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductGridCategoryWithoutRootCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertProductGridRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductGridUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductGridCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateProductBannersEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductBannerListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductBannerListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertProductBannersRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductBannerListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductBannerListUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_ProductBannerListUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductBannerListUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductBannerItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductBannerListUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductBannerListUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_ProductBannerListUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_ProductBannerItemWithoutListUpdateInput"] | undefined
};
	["Contember_ProductBannerItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	banner?: GraphQLTypes["Contember_ProductBannerItemUpdateBannerEntityRelationInput"] | undefined,
	size?: GraphQLTypes["Contember_ProductBannerItemSize"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductBannerItemUpdateBannerEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductBannerCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductBannerUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductBannerItemUpsertBannerRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductBannerUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductBannerUpdateInput"]: {
		beforeTitle?: string | undefined,
	title?: string | undefined,
	label?: string | undefined,
	button?: GraphQLTypes["Contember_ProductBannerUpdateButtonEntityRelationInput"] | undefined,
	backgroundImage?: GraphQLTypes["Contember_ProductBannerUpdateBackgroundImageEntityRelationInput"] | undefined,
	productImage?: GraphQLTypes["Contember_ProductBannerUpdateProductImageEntityRelationInput"] | undefined,
	product?: GraphQLTypes["Contember_ProductBannerUpdateProductEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductBannerUpdateButtonEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductBannerUpsertButtonRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductBannerUpsertButtonRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_ProductBannerUpdateBackgroundImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductBannerUpsertBackgroundImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductBannerUpsertBackgroundImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductBannerUpdateProductImageEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductBannerUpsertProductImageRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ImageUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductBannerUpsertProductImageRelationInput"]: {
		update?: GraphQLTypes["Contember_ImageUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ImageCreateInput"] | undefined
};
	["Contember_ProductBannerUpdateProductEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductBannerUpsertProductRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductBannerUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCreateInput"] | undefined
};
	["Contember_ProductBannerItemUpsertBannerRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductBannerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductBannerCreateInput"] | undefined
};
	["Contember_ProductBannerListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductBannerItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductBannerItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductBannerItemWithoutListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertProductBannersRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductBannerListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductBannerListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateDeliveryRegionsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_DeliveryRegionsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryRegionsUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertDeliveryRegionsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_DeliveryRegionsUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ContentReferenceUpsertDeliveryRegionsRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryRegionsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryRegionsCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateTextListEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertTextListRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_TextListUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ContentReferenceUpsertTextListRelationInput"]: {
		update?: GraphQLTypes["Contember_TextListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TextListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpdateGridTilesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_GridTilesCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_GridTilesUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ContentReferenceUpsertGridTilesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_GridTilesUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_GridTilesUpdateInput"]: {
		items?: Array<GraphQLTypes["Contember_GridTilesUpdateItemsEntityRelationInput"]> | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GridTilesUpdateItemsEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_GridTileItemWithoutListCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_GridTilesUpdateItemsRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_GridTilesUpsertItemsRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_GridTileItemUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_GridTileItemUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_GridTileItemUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_GridTilesUpdateItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_GridTileItemUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_GridTileItemWithoutListUpdateInput"] | undefined
};
	["Contember_GridTileItemWithoutListUpdateInput"]: {
		order?: number | undefined,
	text?: string | undefined,
	medium?: GraphQLTypes["Contember_GridTileItemUpdateMediumEntityRelationInput"] | undefined,
	link?: GraphQLTypes["Contember_GridTileItemUpdateLinkEntityRelationInput"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_GridTileItemUpdateMediumEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_GridTileItemUpsertMediumRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_MediumUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_GridTileItemUpsertMediumRelationInput"]: {
		update?: GraphQLTypes["Contember_MediumUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_MediumCreateInput"] | undefined
};
	["Contember_GridTileItemUpdateLinkEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_GridTileItemUpsertLinkRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_LinkUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_GridTileItemUpsertLinkRelationInput"]: {
		update?: GraphQLTypes["Contember_LinkUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LinkCreateInput"] | undefined
};
	["Contember_GridTilesUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_GridTileItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_GridTileItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_GridTileItemWithoutListCreateInput"] | undefined
};
	["Contember_ContentReferenceUpsertGridTilesRelationInput"]: {
		update?: GraphQLTypes["Contember_GridTilesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_GridTilesCreateInput"] | undefined
};
	["Contember_ContentBlockUpsertReferencesRelationInput"]: {
		by?: GraphQLTypes["Contember_ContentReferenceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ContentReferenceWithoutBlockUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ContentReferenceWithoutBlockCreateInput"] | undefined
};
	["Contember_ContentUpsertBlocksRelationInput"]: {
		by?: GraphQLTypes["Contember_ContentBlockUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ContentBlockWithoutContentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ContentBlockWithoutContentCreateInput"] | undefined
};
	["Contember_BlogPostLocaleUpsertContentRelationInput"]: {
		update?: GraphQLTypes["Contember_ContentUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ContentCreateInput"] | undefined
};
	["Contember_LocaleUpsertBlogPostsRelationInput"]: {
		by?: GraphQLTypes["Contember_BlogPostLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BlogPostLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BlogPostLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_BusinessCategoryLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutBusinessCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutBusinessCategoriesCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_OrderRecurrenceUpsertBusinessCategoryRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryWithoutRecurrencesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutRecurrencesCreateInput"] | undefined
};
	["Contember_OrderRecurrenceLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_OrderRecurrenceWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertOrderRecurrencesRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderRecurrenceLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderRecurrenceLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_AllergenLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutAllergensUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutAllergensCreateInput"] | undefined
};
	["Contember_AllergenUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_AllergenLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_AllergenLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AllergenLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_ProductIngredientUpsertAllergensRelationInput"]: {
		by?: GraphQLTypes["Contember_AllergenUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_AllergenWithoutIngredientsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AllergenWithoutIngredientsCreateInput"] | undefined
};
	["Contember_ProductIngredientLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertProductIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_ProductIngredientCategoryLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutProductIngredientCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductIngredientCategoriesCreateInput"] | undefined
};
	["Contember_ProductIngredientCategoryUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_ProductIngredientUpsertCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutIngredientsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCategoryWithoutIngredientsCreateInput"] | undefined
};
	["Contember_CustomerIngredientRatingUpsertIngredientRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientWithoutCustomerRatingsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientWithoutCustomerRatingsCreateInput"] | undefined
};
	["Contember_CustomerUpsertIngredientRatingsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerIngredientRatingUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerIngredientRatingWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerGroupUpsertCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutGroupUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutGroupCreateInput"] | undefined
};
	["Contember_ProductGroupPriceUpsertGroupRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerGroupWithoutProductPricesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerGroupWithoutProductPricesCreateInput"] | undefined
};
	["Contember_ProductUpsertGroupPricesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductGroupPriceWithoutProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductGroupPriceWithoutProductCreateInput"] | undefined
};
	["Contember_DeliveryZoneUpsertDeliveryZonesOfProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutDeliveryZonesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutDeliveryZonesCreateInput"] | undefined
};
	["Contember_TagUpsertDeliveryZonesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutDeliveryZonesOfTagsCreateInput"] | undefined
};
	["Contember_ChannelUpsertAddTagsOnRegistrationRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutAddTagsOnRegistrationUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutAddTagsOnRegistrationCreateInput"] | undefined
};
	["Contember_CartUpsertChannelRelationInput"]: {
		update?: GraphQLTypes["Contember_ChannelWithoutCartsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutCartsCreateInput"] | undefined
};
	["Contember_CartItemUpsertCartRelationInput"]: {
		update?: GraphQLTypes["Contember_CartWithoutItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutItemsCreateInput"] | undefined
};
	["Contember_CartItemUpdateFulfillmentNotesEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_FulfillmentNoteWithoutCartItemsCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_CartItemUpdateFulfillmentNotesRelationInput"] | undefined,
	upsert?: GraphQLTypes["Contember_CartItemUpsertFulfillmentNotesRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	disconnect?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	delete?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	alias?: string | undefined
};
	["Contember_CartItemUpdateFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	data?: GraphQLTypes["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"] | undefined
};
	["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"]: {
		note?: string | undefined,
	products?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateProductsEntityRelationInput"]> | undefined,
	tags?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateTagsEntityRelationInput"]> | undefined,
	venues?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateVenuesEntityRelationInput"]> | undefined,
	orderItems?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrderItemsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_FulfillmentNoteUpdateOrdersEntityRelationInput"]> | undefined,
	highlight?: boolean | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	emoji?: string | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_CartItemUpsertFulfillmentNotesRelationInput"]: {
		by?: GraphQLTypes["Contember_FulfillmentNoteUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_FulfillmentNoteWithoutCartItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_FulfillmentNoteWithoutCartItemsCreateInput"] | undefined
};
	["Contember_ProductUpsertCartItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CartItemWithoutProductUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CartItemWithoutProductCreateInput"] | undefined
};
	["Contember_ProductGroupPriceUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductWithoutGroupPricesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutGroupPricesCreateInput"] | undefined
};
	["Contember_CustomerGroupUpsertProductPricesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductGroupPriceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductGroupPriceWithoutGroupUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductGroupPriceWithoutGroupCreateInput"] | undefined
};
	["Contember_CustomerUpsertGroupRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerGroupWithoutCustomersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerGroupWithoutCustomersCreateInput"] | undefined
};
	["Contember_ChannelUpsertCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutChannelsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutChannelsCreateInput"] | undefined
};
	["Contember_TagUpsertAddTagsOnRegistrationRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelWithoutAddTagsOnRegistrationUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutAddTagsOnRegistrationCreateInput"] | undefined
};
	["Contember_ProductCategoryUpsertTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutCategoriesCreateInput"] | undefined
};
	["Contember_ProductCategoryUpsertParentRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductCategoryWithoutChildrenUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutChildrenCreateInput"] | undefined
};
	["Contember_TagUpsertCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryWithoutTagsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryWithoutTagsCreateInput"] | undefined
};
	["Contember_ProductUpsertTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutProductsCreateInput"] | undefined
};
	["Contember_ProductCategoryUpsertProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutCategoriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutCategoriesCreateInput"] | undefined
};
	["Contember_ProductCategoryListItemUpsertProductCategoryRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryCreateInput"] | undefined
};
	["Contember_ProductCategoryListUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryListItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListItemWithoutListUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListItemWithoutListCreateInput"] | undefined
};
	["Contember_ProductCategoryListLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductCategoryListWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertProductCategoryListsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductCategoryListLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductCategoryListLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_ProductIngredientLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutProductIngredientsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductIngredientsCreateInput"] | undefined
};
	["Contember_ProductIngredientUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_ProductIngredientItemUpsertIngredientRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientCreateInput"] | undefined
};
	["Contember_ProductIngredientItemUpdateQuantityEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientItemQuantityCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientItemQuantityUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientItemUpsertQuantityRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientItemQuantityUniqueWhere"] | undefined,
	disconnect?: boolean | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientItemQuantityUpdateInput"]: {
		unit?: GraphQLTypes["Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput"] | undefined,
	value?: number | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_ProductIngredientItemQuantityUpdateUnitEntityRelationInput"]: {
		create?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined,
	upsert?: GraphQLTypes["Contember_ProductIngredientItemQuantityUpsertUnitRelationInput"] | undefined,
	connect?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUniqueWhere"] | undefined,
	delete?: boolean | undefined
};
	["Contember_ProductIngredientItemQuantityUpsertUnitRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemQuantityUnitCreateInput"] | undefined
};
	["Contember_ProductIngredientItemUpsertQuantityRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductIngredientItemQuantityUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemQuantityCreateInput"] | undefined
};
	["Contember_ProductRecipeUpsertIngredientsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductIngredientItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductIngredientItemWithoutRecipeUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductIngredientItemWithoutRecipeCreateInput"] | undefined
};
	["Contember_ProductUpsertRecipeRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductRecipeWithoutProductsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductRecipeWithoutProductsCreateInput"] | undefined
};
	["Contember_CartItemUpsertProductRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductWithoutCartItemsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutCartItemsCreateInput"] | undefined
};
	["Contember_CartUpsertItemsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartItemUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CartItemWithoutCartUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CartItemWithoutCartCreateInput"] | undefined
};
	["Contember_ChannelUpsertCartsRelationInput"]: {
		by?: GraphQLTypes["Contember_CartUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CartWithoutChannelUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CartWithoutChannelCreateInput"] | undefined
};
	["Contember_OrderUpsertChannelRelationInput"]: {
		update?: GraphQLTypes["Contember_ChannelUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelCreateInput"] | undefined
};
	["Contember_CustomerUpsertOrdersRelationInput"]: {
		by?: GraphQLTypes["Contember_OrderUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_OrderWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_OrderWithoutCustomerCreateInput"] | undefined
};
	["Contember_TagUpsertCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerWithoutTagsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutTagsCreateInput"] | undefined
};
	["Contember_ChannelPaymentUpsertAllowOnlyForTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutAllowChannelPaymentsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutAllowChannelPaymentsCreateInput"] | undefined
};
	["Contember_VatRateUpsertChannelPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelPaymentWithoutVatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutVatRateCreateInput"] | undefined
};
	["Contember_ChannelDeliveryUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateWithoutChannelDeliveriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateWithoutChannelDeliveriesCreateInput"] | undefined
};
	["Contember_DeliveryMethodUpsertChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelDeliveryWithoutMethodUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryWithoutMethodCreateInput"] | undefined
};
	["Contember_PaymentMethodUpsertDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodWithoutPaymentMethodsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutPaymentMethodsCreateInput"] | undefined
};
	["Contember_ChannelPaymentUpsertMethodRelationInput"]: {
		update?: GraphQLTypes["Contember_PaymentMethodWithoutChannelsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodWithoutChannelsCreateInput"] | undefined
};
	["Contember_ChannelUpsertPaymentsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelPaymentWithoutChannelUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutChannelCreateInput"] | undefined
};
	["Contember_ChannelDeliveryUpsertChannelRelationInput"]: {
		update?: GraphQLTypes["Contember_ChannelWithoutDeliveriesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutDeliveriesCreateInput"] | undefined
};
	["Contember_VatRateUpsertChannelDeliveriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelDeliveryWithoutVatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryWithoutVatRateCreateInput"] | undefined
};
	["Contember_CustomerCreditUpsertVatRateRelationInput"]: {
		update?: GraphQLTypes["Contember_VatRateUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_VatRateCreateInput"] | undefined
};
	["Contember_CustomerUpsertCreditsRelationInput"]: {
		by?: GraphQLTypes["Contember_CustomerCreditUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_CustomerCreditWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerCreditWithoutCustomerCreateInput"] | undefined
};
	["Contember_BusinessCustomerUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutBusinessUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutBusinessCreateInput"] | undefined
};
	["Contember_BusinessCategoryUpsertBusinessCustomersRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCustomerUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCustomerWithoutCategoryUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCustomerWithoutCategoryCreateInput"] | undefined
};
	["Contember_BusinessCategoryLocaleUpsertRootRelationInput"]: {
		update?: GraphQLTypes["Contember_BusinessCategoryWithoutLocalesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryWithoutLocalesCreateInput"] | undefined
};
	["Contember_LocaleUpsertBusinessCategoriesRelationInput"]: {
		by?: GraphQLTypes["Contember_BusinessCategoryLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutLocaleUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_BusinessCategoryLocaleWithoutLocaleCreateInput"] | undefined
};
	["Contember_ProductPackingLocaleUpsertLocaleRelationInput"]: {
		update?: GraphQLTypes["Contember_LocaleWithoutProductPackingsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_LocaleWithoutProductPackingsCreateInput"] | undefined
};
	["Contember_ProductPackingUpsertLocalesRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductPackingLocaleUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductPackingLocaleWithoutRootUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingLocaleWithoutRootCreateInput"] | undefined
};
	["Contember_ProductUpsertPackingRelationInput"]: {
		update?: GraphQLTypes["Contember_ProductPackingUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductPackingCreateInput"] | undefined
};
	["Contember_TagUpsertProductsRelationInput"]: {
		by?: GraphQLTypes["Contember_ProductUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ProductWithoutTagsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ProductWithoutTagsCreateInput"] | undefined
};
	["Contember_CustomerUpsertTagsRelationInput"]: {
		by?: GraphQLTypes["Contember_TagUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_TagWithoutCustomersUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_TagWithoutCustomersCreateInput"] | undefined
};
	["Contember_InvoiceUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutInvoicesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutInvoicesCreateInput"] | undefined
};
	["Contember_AddressUpsertInvoicesRelationInput"]: {
		by?: GraphQLTypes["Contember_InvoiceUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_InvoiceWithoutAddressUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_InvoiceWithoutAddressCreateInput"] | undefined
};
	["Contember_DeliveryZoneUpsertAddressesRelationInput"]: {
		by?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_AddressWithoutDeliveryZoneUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutDeliveryZoneCreateInput"] | undefined
};
	["Contember_DeliveryMethodUpsertSuitableForDeliveryZonesRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryZoneUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutSuitableDeliveryMethodsCreateInput"] | undefined
};
	["Contember_ChannelDeliveryUpsertMethodRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryMethodWithoutChannelsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutChannelsCreateInput"] | undefined
};
	["Contember_ChannelUpsertDeliveriesRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelDeliveryUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelDeliveryWithoutChannelUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelDeliveryWithoutChannelCreateInput"] | undefined
};
	["Contember_ChannelPaymentUpsertChannelRelationInput"]: {
		update?: GraphQLTypes["Contember_ChannelWithoutPaymentsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelWithoutPaymentsCreateInput"] | undefined
};
	["Contember_PaymentMethodUpsertChannelsRelationInput"]: {
		by?: GraphQLTypes["Contember_ChannelPaymentUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_ChannelPaymentWithoutMethodUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_ChannelPaymentWithoutMethodCreateInput"] | undefined
};
	["Contember_DeliveryMethodUpsertPaymentMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_PaymentMethodUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_PaymentMethodWithoutDeliveryMethodsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_PaymentMethodWithoutDeliveryMethodsCreateInput"] | undefined
};
	["Contember_DeliveryZoneUpsertSuitableDeliveryMethodsRelationInput"]: {
		by?: GraphQLTypes["Contember_DeliveryMethodUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryMethodWithoutSuitableForDeliveryZonesCreateInput"] | undefined
};
	["Contember_AddressUpsertDeliveryZoneRelationInput"]: {
		update?: GraphQLTypes["Contember_DeliveryZoneWithoutAddressesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_DeliveryZoneWithoutAddressesCreateInput"] | undefined
};
	["Contember_CustomerUpsertAddressesRelationInput"]: {
		by?: GraphQLTypes["Contember_AddressUniqueWhere"] | undefined,
	update?: GraphQLTypes["Contember_AddressWithoutCustomerUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_AddressWithoutCustomerCreateInput"] | undefined
};
	["Contember_CustomerPaymentMethodUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutPaymentMethodsUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutPaymentMethodsCreateInput"] | undefined
};
	["Contember_CustomerUpsertDefaultPaymentMethodRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerPaymentMethodUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerPaymentMethodCreateInput"] | undefined
};
	["Contember_AddressUpsertCustomerRelationInput"]: {
		update?: GraphQLTypes["Contember_CustomerWithoutAddressesUpdateInput"] | undefined,
	create?: GraphQLTypes["Contember_CustomerWithoutAddressesCreateInput"] | undefined
};
	["Contember_CustomerUpdateInput"]: {
		addresses?: Array<GraphQLTypes["Contember_CustomerUpdateAddressesEntityRelationInput"]> | undefined,
	defaultBillingAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingAddressEntityRelationInput"] | undefined,
	defaultPaymentMethod?: GraphQLTypes["Contember_CustomerUpdateDefaultPaymentMethodEntityRelationInput"] | undefined,
	tags?: Array<GraphQLTypes["Contember_CustomerUpdateTagsEntityRelationInput"]> | undefined,
	credits?: Array<GraphQLTypes["Contember_CustomerUpdateCreditsEntityRelationInput"]> | undefined,
	paymentMethods?: Array<GraphQLTypes["Contember_CustomerUpdatePaymentMethodsEntityRelationInput"]> | undefined,
	orders?: Array<GraphQLTypes["Contember_CustomerUpdateOrdersEntityRelationInput"]> | undefined,
	permissions?: GraphQLTypes["Contember_CustomerUpdatePermissionsEntityRelationInput"] | undefined,
	group?: GraphQLTypes["Contember_CustomerUpdateGroupEntityRelationInput"] | undefined,
	photo?: GraphQLTypes["Contember_CustomerUpdatePhotoEntityRelationInput"] | undefined,
	phone?: string | undefined,
	name?: GraphQLTypes["Contember_CustomerUpdateNameEntityRelationInput"] | undefined,
	internalNote?: string | undefined,
	welcomeMessage?: string | undefined,
	familiarity?: GraphQLTypes["Contember_CustomerFamiliarity"] | undefined,
	ingredientRatings?: Array<GraphQLTypes["Contember_CustomerUpdateIngredientRatingsEntityRelationInput"]> | undefined,
	business?: GraphQLTypes["Contember_CustomerUpdateBusinessEntityRelationInput"] | undefined,
	ownerOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateOwnerOfBusinessesEntityRelationInput"]> | undefined,
	memberOfBusinesses?: Array<GraphQLTypes["Contember_CustomerUpdateMemberOfBusinessesEntityRelationInput"]> | undefined,
	carts?: Array<GraphQLTypes["Contember_CustomerUpdateCartsEntityRelationInput"]> | undefined,
	shortcutList?: GraphQLTypes["Contember_CustomerUpdateShortcutListEntityRelationInput"] | undefined,
	user?: GraphQLTypes["Contember_CustomerUpdateUserEntityRelationInput"] | undefined,
	channels?: Array<GraphQLTypes["Contember_CustomerUpdateChannelsEntityRelationInput"]> | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	invoices?: Array<GraphQLTypes["Contember_CustomerUpdateInvoicesEntityRelationInput"]> | undefined,
	invoicingPlan?: GraphQLTypes["Contember_CustomerUpdateInvoicingPlanEntityRelationInput"] | undefined,
	email?: string | undefined,
	createdAt?: GraphQLTypes["DateTime"] | undefined,
	billingSubjects?: Array<GraphQLTypes["Contember_CustomerUpdateBillingSubjectsEntityRelationInput"]> | undefined,
	offeredProducts?: Array<GraphQLTypes["Contember_CustomerUpdateOfferedProductsEntityRelationInput"]> | undefined,
	defaultDeliveryAddress?: GraphQLTypes["Contember_CustomerUpdateDefaultDeliveryAddressEntityRelationInput"] | undefined,
	defaultBillingSubject?: GraphQLTypes["Contember_CustomerUpdateDefaultBillingSubjectEntityRelationInput"] | undefined,
	prefersPackingsWithoutCardboard?: boolean | undefined,
	anonymousSession?: GraphQLTypes["Contember_CustomerUpdateAnonymousSessionEntityRelationInput"] | undefined,
	boughtVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateBoughtVouchersEntityRelationInput"]> | undefined,
	ownsVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateOwnsVouchersEntityRelationInput"]> | undefined,
	redeemedVouchers?: Array<GraphQLTypes["Contember_CustomerUpdateRedeemedVouchersEntityRelationInput"]> | undefined,
	spending?: GraphQLTypes["Contember_CustomerUpdateSpendingEntityRelationInput"] | undefined,
	customerTabs?: Array<GraphQLTypes["Contember_CustomerUpdateCustomerTabsEntityRelationInput"]> | undefined,
	lead?: Array<GraphQLTypes["Contember_CustomerUpdateLeadEntityRelationInput"]> | undefined,
	doubleshotLegacyId?: string | undefined,
	meta?: GraphQLTypes["Contember_CustomerUpdateMetaEntityRelationInput"] | undefined,
	doubleshotLastUpdatedAt?: GraphQLTypes["DateTime"] | undefined,
	_dummy_field_?: boolean | undefined
};
	["Contember_QueryTransaction"]: {
	__typename: "Contember_QueryTransaction",
	getCustomer?: GraphQLTypes["Contember_Customer"] | undefined
};
	["Contember_Info"]: {
	__typename: "Contember_Info",
	description?: string | undefined
};
	["Contember_MutationResult"]: {
	__typename:"Contember_CustomerUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["Contember__MutationError"]>
	['...on Contember_CustomerUpdateResult']: '__union' & GraphQLTypes["Contember_CustomerUpdateResult"];
};
	["Contember__MutationError"]: {
	__typename: "Contember__MutationError",
	path: Array<GraphQLTypes["Contember__PathFragment"]>,
	paths: Array<Array<GraphQLTypes["Contember__PathFragment"]>>,
	type: GraphQLTypes["Contember__MutationErrorType"],
	message?: string | undefined
};
	["Contember__MutationErrorType"]: Contember__MutationErrorType;
	["Contember_CustomerUpdateResult"]: {
	__typename: "Contember_CustomerUpdateResult",
	ok: boolean,
	errorMessage?: string | undefined,
	errors: Array<GraphQLTypes["Contember__MutationError"]>,
	node?: GraphQLTypes["Contember_Customer"] | undefined,
	validation: GraphQLTypes["Contember__ValidationResult"]
};
	["Contember_MutationTransaction"]: {
	__typename: "Contember_MutationTransaction",
	updateCustomer: GraphQLTypes["Contember_CustomerUpdateResult"]
};
	["Contember_MutationTransactionOptions"]: {
		deferForeignKeyConstraints?: boolean | undefined
}
    }
export type DeliveryTimelineType = "asap" | "day" | "halfAnHour";

export type ChannelCode = "b2b" | "eshop" | "app" | "pos" | "web" | "all" | "legacy";

export type ToastRatingValue = "positive" | "negative";

export type Contember_DeliveryZoneType = "Shop" | "Prague" | "CZ" | "Abroad" | "Virtual";

export type Contember_TimetableDayType = "open" | "closed";

export type Contember_UnassignedInfoReason = "sickness" | "vacation" | "other";

export type Contember_TemplateType = "inInterval" | "selectedDays";

export type Contember_PointOfSaleTerminalType = "manual" | "switchio" | "globalpayments";

export type Contember_OrderState = "created" | "waitingForPayment" | "processing" | "dispatched" | "fulfilled" | "canceled";

export type Contember_OrderDiscountType = "Manual" | "Credits" | "Voucher";

export type Contember_VoucherType = "Credits";

export type Contember_OrderPaymentState = "created" | "approved" | "failed";

export type Contember_OrderPriceLineType = "discount" | "item" | "delivery";

export type Contember_TableShape = "rectangle" | "ellipse";

export type Contember_PointOfSaleInitialView = "categories" | "areas";

export type Contember_CartState = "created" | "confirmed" | "deleted";

export type Contember_CartPriceLineType = "item";

export type Contember_ChannelCode = "b2b" | "eshop" | "app" | "pos" | "web" | "all" | "legacy";

export type Contember_VatRatePreset = "zero" | "default";

export type Contember_OrderDeliveryState = "processing" | "picked" | "delivering" | "delivered" | "failed";

export type Contember_EmploymentType = "full_time" | "part_time";

export type Contember_One = "One";

export type Contember_VirtualProductType = "Credits" | "Voucher";

export type Contember_VirtualProductPhysicalRepresentationType = "None" | "Optional" | "Required";

export type Contember_MediumType = "image" | "video";

export type Contember_ColorTheme = "light" | "dark";

export type Contember_ContentReferenceType = "button" | "medium" | "products" | "productBanners" | "links" | "link" | "blogPosts" | "content" | "gallery" | "hero" | "hr" | "marquee" | "productGrid" | "relatedBlogPosts" | "socialsAndApps" | "titleWithImage" | "deliveryRegions" | "collapsibleTexts" | "gridTiles";

export type Contember_LinkType = "internal" | "external" | "download";

export type Contember_PageTheme = "goodlok" | "childish";

export type Contember_ContentSize = "small" | "medium" | "large";

export type Contember_ProductGridType = "default" | "carousel" | "smallGrid";

export type Contember_ProductBannerItemSize = "small" | "medium" | "large" | "fullWidth";

export type Contember_TranslationContentType = "textPlain" | "textHtml";

export type Contember_MenuSubitemType = "links" | "products" | "delivery" | "contact";

export type Contember_PipelineStageType = "successful" | "failed" | "ordered" | "unassigned" | "regular";

export type Contember_ProductAvailability = "stockDependent" | "available" | "unavailable";

export type Contember_DeliveryTimelineType = "asap" | "day" | "halfAnHour";

export type Contember_PaymentMethodType = "credit" | "stripe" | "cash" | "invoice" | "import";

export type Contember_CustomerFamiliarity = "cizi" | "pratelskeVykani" | "slusneTykani" | "tykani" | "sikana";

export type Contember_InvoicingPresetType = "AdHoc" | "WhenFulfilled" | "FirstDayOfMonth" | "IntervalOfDays";

export type Contember_OrderDirection = "asc" | "desc" | "ascNullsFirst" | "descNullsLast";

export type Contember__MutationErrorType = "NotNullConstraintViolation" | "UniqueConstraintViolation" | "ForeignKeyConstraintViolation" | "NotFoundOrDenied" | "NonUniqueWhereInput" | "InvalidDataInput" | "SqlError";

export const createZeusVariables = useZeusVariables

export type FragmentResult<K extends keyof GraphQLTypes, Q extends unknown | ((...args: any[]) => unknown)> = InputType<
	GraphQLTypes[K],
	Q extends (...args: any[]) => unknown ? ReturnType<Q> : Q
>