import clsx from 'clsx'
import Image from 'next/image'
import { Fragment, FunctionComponent, useMemo, useState } from 'react'
import { Button as AriaButton, Dialog, DialogTrigger, Popover, Separator } from 'react-aria-components'
import { Icon, IconName } from '../icons/Icon'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import styles from './FilterSelect.module.sass'
import { ScrollBox } from './ScrollBox'
import { SearchBar } from './SearchBar'

export type FilterSectionValue = { [section: string]: string[] }

export type FilterGroup = { title: string; sections: FilterSection[] }

export type FilterSection = {
	key: string
	title: string
	icon: IconName
} & ({ items: FilterSectionItem[] } | { content: React.ReactNode })

export type FilterSectionItem = {
	label: string
	value: string
	imageUrl?: string
}

export type FilterSelectProps = {
	groups: FilterGroup[]
	value: FilterSectionValue
	onValueChange: (value: FilterSectionValue) => void
} & (
	| { searchQuery: string; onSearchQueryChange: (value: string) => void }
	| { searchQuery?: undefined; onSearchQueryChange?: undefined }
)

export const FilterSelect: FunctionComponent<FilterSelectProps> = ({
	groups,
	value,
	onValueChange,
	searchQuery,
	onSearchQueryChange,
}) => {
	const [activeSectionKey, setActiveSectionKey] = useState<string | null>(groups.at(0)?.sections.at(0)?.key ?? null)

	const activeSection = useMemo(() => {
		return groups.flatMap(group => group.sections).find(section => section.key === activeSectionKey)
	}, [activeSectionKey, groups])

	if (!activeSection || !activeSectionKey) {
		return null
	}

	return (
		<DialogTrigger>
			<AriaButton className={styles.button}>
				<div className={styles.buttonIcon}>
					<Icon name="ellipsis" />
				</div>
			</AriaButton>
			<Popover className={styles.wrapper} placement="bottom right">
				<Dialog className={styles.groups}>
					<div>
						{groups.map((group, index) => (
							<Fragment key={index}>
								<div className={styles.section}>
									<div className={styles.title}>{group.title}</div>
									{group.sections.map(section => (
										<AriaButton
											key={section.key}
											className={clsx(styles.sectionButton, activeSectionKey === section.key && styles.is_active)}
											onPressChange={() => setActiveSectionKey(section.key)}
											id={section.key}
										>
											<div className={styles.sectionTitle}>
												<div className={styles.sectionTitleIcon}>
													<Icon name={section.icon} />
												</div>
												{section.title}
												{value[section.key] && value[section.key].length > 0 && (
													<div className={styles.badge}>{value[section.key].length}</div>
												)}
											</div>
											<div className={styles.sectionArrow}>
												<Icon name="arrowDown" />
											</div>
										</AriaButton>
									))}
								</div>
								<Separator />
							</Fragment>
						))}
						<Button type="button" variant="seamless" uppercase={false} onClick={() => onValueChange({})}>
							<div className={styles.resetButton}>Zrušit všechny filtry</div>
						</Button>
					</div>
				</Dialog>

				<div className={styles.itemWrapper}>
					<div className={styles.header}>
						<div>
							{onSearchQueryChange && <SearchBar label="Hledat" value={searchQuery} onChange={onSearchQueryChange} />}
						</div>

						<Button
							type="button"
							variant="light"
							size="small"
							onClick={() => {
								const newValue = { ...value }
								delete newValue[activeSectionKey]
								onValueChange(newValue)
							}}
						>
							Zrušit
						</Button>
					</div>
					<ScrollBox type="high">
						<div className={styles.options}>
							{'items' in activeSection
								? activeSection.items.map(item => (
										<Checkbox
											key={item.value}
											label={
												<div className={styles.checkboxLabelWrapper}>
													{item.imageUrl && (
														<div className={styles.image}>
															<Image src={item.imageUrl} alt="" fill />
														</div>
													)}
													<div>{item.label}</div>
												</div>
											}
											onChange={checked => {
												if (checked) {
													if (value[activeSectionKey]) {
														const newValue = { ...value }
														newValue[activeSectionKey].push(item.value)
														onValueChange(newValue)
													} else {
														onValueChange({ ...value, [activeSectionKey]: [item.value] })
													}
												} else {
													if (value[activeSectionKey].length === 1) {
														const newValue = { ...value }
														delete newValue[activeSectionKey]
														onValueChange(newValue)
													} else {
														onValueChange({
															...value,
															[activeSectionKey]: value[activeSectionKey].filter(id => id !== item.value),
														})
													}
												}
											}}
											checkedValue={item.value}
											checked={value[activeSectionKey]?.includes(item.value) ?? false}
										/>
								  ))
								: 'content' in activeSection
								  ? activeSection.content
								  : null}
						</div>
					</ScrollBox>
				</div>
			</Popover>
		</DialogTrigger>
	)
}
