import clsx from 'clsx'
import { FunctionComponent, useMemo, useState } from 'react'
import { Button as AriaButton, Dialog, DialogTrigger, I18nProvider, Popover } from 'react-aria-components'
import { Icon } from '../icons/Icon'
import { Button } from './Button'
import { DatePicker, DatePickerProps, DateRangeValue } from './DatePicker'
import styles from './DatePickerInput.module.sass'

const format = (date: Date) => {
	return Intl.DateTimeFormat('cs', { day: '2-digit', month: '2-digit' }).format(date)
}

export const DatePickerInput: FunctionComponent<DatePickerProps> = props => {
	const selectedDate =
		props.mode === 'single' ? (
			props.value ? (
				<div>{format(props.value)}</div>
			) : (
				'Vyberte datum'
			)
		) : props.mode === 'range' ? (
			props.value?.start && props.value.end ? (
				<>
					<div>{format(props.value.start)}</div>-<div>{format(props.value.end)}</div>
				</>
			) : null
		) : null

	const [rangeValue, setRangeValue] = useState<DateRangeValue | null>(props.mode === 'range' ? props.value : null)
	const [singleValue, setSingleValue] = useState<Date | null>(props.mode === 'single' ? props.value : null)

	const datePickerHead = useMemo(() => {
		if (props.mode === 'range') {
			return (
				<div className={clsx(styles.head, styles.rangeHead)}>
					<div>{rangeValue?.start && format(rangeValue.start)}</div>
					<div>{rangeValue?.end && format(rangeValue.end)}</div>
				</div>
			)
		} else if (props.mode === 'single') {
			return (
				<div className={clsx(styles.head, styles.singleHead)}>
					<div>{singleValue && format(singleValue)}</div>
				</div>
			)
		} else {
			return 'Vyberte datum'
		}
	}, [props.mode, rangeValue?.end, rangeValue?.start, singleValue])

	return (
		<I18nProvider locale="cs-CZ">
			<DialogTrigger>
				<AriaButton className={styles.editButton}>
					{selectedDate}
					<div className={styles.calendarIcon}>
						<Icon name="calendar" />
					</div>
				</AriaButton>
				<Dialog>
					{({ close }) => (
						<Popover className={styles.contentWrapper} placement="bottom right">
							<div className={styles.content}>
								<div className={styles.head}>{datePickerHead}</div>
								{props.mode === 'range' ? (
									<DatePicker {...props} onChange={setRangeValue} value={rangeValue} />
								) : (
									<DatePicker {...props} onChange={setSingleValue} value={singleValue} />
								)}
								<div className={styles.buttons}>
									<Button
										type="button"
										variant="seamless"
										onClick={() => {
											if (props.mode === 'range') {
												setRangeValue(props.value)
											} else {
												setSingleValue(props.value)
											}
										}}
										size="small"
									>
										Zrušit
									</Button>
									<Button
										type="button"
										variant="primary"
										onClick={() => {
											close()
											if (props.mode === 'range') {
												props.onChange(rangeValue)
											} else {
												props.onChange(singleValue)
											}
										}}
										size="small"
									>
										Potvrdit
									</Button>
								</div>
							</div>
						</Popover>
					)}
				</Dialog>
			</DialogTrigger>
		</I18nProvider>
	)
}
