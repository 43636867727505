import { FragmentResult, ValueTypes } from '@goodlok/sdk/generated/content_role_admin'
import { BillingSubjectFragment } from './BillingSubjectFragment'
import { ImageFragment } from './ImageFragment'
import { OrderFragment } from './OrderFragment'

export const BusinessCustomerFragment = (locale: string | undefined) =>
	({
		id: true,
		name: true,
		logo: [{}, ImageFragment()],
		accountManager: [{}, { id: true, staff: [{}, { photo: [{}, ImageFragment()], user: [{}, { name: true }] }] }],
		owners: [{}, { id: true, name: [{}, { vocative: true }], email: true, phone: true }],
		category: [{}, { id: true }],
		customer: [
			{},
			{
				id: true,
				meta: [{}, { name: true, fallbackName: true, ordersTotalCents: true }],
				defaultBillingSubject: [{}, BillingSubjectFragment()],
				lead: [
					{ filter: { stageItem: { list: { pipeline: { unique: { eq: 'One' } } } } } },
					{ contactPerson: true, cafeType: true, offerType: true, multiroasterType: true, ownMachine: true },
				],
				orders: [{}, OrderFragment()],
				paginateOrders: [
					{ orderBy: [{ fulfilledAt: 'desc' }], first: 5, filter: { state: { eq: 'fulfilled' } } },
					{ pageInfo: { totalCount: true }, edges: { node: OrderFragment() } },
				],
				tags: [{}, { id: true }],
				group: [{}, { id: true }],
			},
		],
		note: true,
		businessServiceEvents: [
			{ orderBy: [{ fulfilledAt: 'desc' }] },
			{
				id: true,
				createdAt: true,
				createdBy: [
					{},
					{ id: true, user: [{}, { staff: [{}, { photo: [{}, ImageFragment()] }], name: true, email: true }] },
				],
				businessService: [
					{},
					{ id: true, localesByLocale: [{ by: { locale: { identifier: { code: locale } } } }, { name: true }] },
				],
				fulfilledAt: true,
				filledContact: true,
				filledEmail: true,
				filledPhone: true,
				filledNumberOfPeople: true,
				filledSupervisor: [{}, { id: true }],
				filledInvoiced: true,
				filledInvoiceIdentifier: true,
				filledEquipment: true,
				filledMillTamDem: true,
				filledSOEspresso: true,
				filledFilterCoffeeInMenu: true,
				filledWaterMeterStatus: true,
				filledGrindingStonesCondition: true,
				filledServiceAction: true,
				filledNote: true,
			},
		],
		doubleshotCoffeeEquipment: [
			{},
			{
				espressoMachine: true,
				espressoGrinder: true,
				batchBrew: true,
				batchGrinder: true,
				waterFilter: true,
				mechanicalFilter: true,
			},
		],
	}) satisfies ValueTypes['BusinessCustomer']

export type BusinessCustomerResult = FragmentResult<'BusinessCustomer', typeof BusinessCustomerFragment>
export type BusinessCustomerAccountManagerResult = BusinessCustomerResult['accountManager']
