import type { IconNameOrVariants } from '../components'

export type App = {
	name: string
	short: string
	handle: string
	port: number
	icon?: IconNameOrVariants
	pathname?: string
	hideInProduction?: boolean
}
export const appsConfiguration: App[] = [
	{
		name: 'Produkty a sklad',
		short: 'Pro',
		handle: 'products',
		port: 3107,
		icon: { on: 'productsOn', off: 'productsOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Objednávky',
		short: 'Obj',
		handle: 'orders',
		port: 3117,
		icon: { on: 'ordersOn', off: 'ordersOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Pokladna',
		short: 'Reg',
		handle: 'pos',
		port: 3115,
		icon: { on: 'posOn', off: 'posOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Výroba',
		short: 'Fre',
		handle: 'freshing',
		port: 3109,
		icon: { on: 'blenderOn', off: 'blenderOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Delivery',
		short: 'Del',
		handle: 'delivery',
		port: 3105,
		icon: { on: 'deliveryOn', off: 'deliveryOff' } satisfies IconNameOrVariants,
		pathname: '/admin',
	},
	{
		name: 'Nákupy',
		short: 'Gro',
		handle: 'groceries',
		port: 3113,
		icon: { on: 'cartOn', off: 'cartOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Sales Pipeline',
		short: 'Sal',
		handle: 'sales',
		port: 3119,
		icon: { on: 'salesOn', off: 'salesOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Směny',
		short: 'Shi',
		handle: 'timetables',
		port: 3116,
		icon: { on: 'shiftsOn', off: 'shiftsOff' } satisfies IconNameOrVariants,
		pathname: '/admin',
	},
	// {
	// 	name: 'Friends',
	// 	short: 'Fri',
	// 	handle: 'friends',
	// 	port: 0,
	// 	icon: { on: 'friendsOn', off: 'friendsOff' } satisfies IconNameOrVariants,
	// },
	{
		name: 'Statistiky',
		short: 'Sta',
		handle: 'stats',
		port: 3108,
		icon: { on: 'statsOn', off: 'statsOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Finance',
		short: 'fin',
		handle: 'finances',
		port: 3120,
		icon: { on: 'statsOn', off: 'statsOff' } satisfies IconNameOrVariants,
	},
	{
		name: 'Wiki',
		short: 'Wik',
		handle: 'wiki',
		port: 3110,
		icon: { on: 'wikiOn', off: 'wikiOff' } satisfies IconNameOrVariants,
	},
	// hide in production:
	{
		name: 'B2B',
		short: 'B2B',
		handle: 'b2b',
		port: 3102,
		hideInProduction: true,
	},
	{
		name: 'Buy',
		short: 'Buy',
		handle: 'buy',
		port: 3112,
		hideInProduction: true,
	},
	{
		name: 'Populate',
		short: 'Pop',
		handle: 'populate',
		port: 3106,
		hideInProduction: true,
	},
	{
		name: 'Styleguide',
		short: 'UI',
		handle: 'https://ui.goodlok.cz',
		port: 3104,
		hideInProduction: true,
	},
	{
		name: 'Nastavení',
		short: 'Nas',
		handle: 'panel',
		port: 3111,
		icon: { on: 'settings', off: 'settings' } satisfies IconNameOrVariants,
	},
]
