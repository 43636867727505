import { Input, LoadingSpinner, RadioSelect } from '@goodlok/ui'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Collapsible } from 'collapsible-react-component'
import Link from 'next/link'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { z } from 'zod'
import {
	Button,
	CartIdentifier,
	GoodlokAuthForm,
	GoodlokCartRoot,
	Icon,
	Modal,
	OnSuccessAuthAction,
	useDebouncedValue,
	useGoodlokAuth,
	useGoodlokShopping,
	useIsDebug,
	useMobileAppSignal,
} from '..'
import { useGoodlokCartIdentifier } from '../cart/useGoodlokShopping'
import { getEnabledApps } from '../misc/getEnabledApps'
import style from './CartCheckout.module.sass'
import { CheckoutLines } from './CheckoutLines'
import {
	AddAddressForm,
	DeliveryBasePicker,
	DeliveryMethodPicker,
	DeliveryOptionPicker,
	useDeliveryOptionPicker,
} from './DeliveryOptionPicker'
import { DumpCustomer } from './DumpCustomer'
import {
	CheckoutActions,
	CheckoutSummary,
	OrderCreatedHandler,
	PaymentOptionPicker,
	useCartPaymentOptions,
} from './PaymentOptionPicker'

export const CartCheckout: FunctionComponent<{ identifier: CartIdentifier }> = props => {
	return (
		<>
			{props.identifier && (
				<GoodlokCartRoot identifier={props.identifier}>
					<Checkout identifier={props.identifier} />
				</GoodlokCartRoot>
			)}
		</>
	)
}

export function useGoodlokCheckout(cartIdentifier?: CartIdentifier, onSuccess?: OrderCreatedHandler) {
	const [showSignIn, setShowSignIn] = useState(false)

	const identifier = useGoodlokCartIdentifier(cartIdentifier)
	const c = useGoodlokShopping(identifier)

	const cartId = c.cart.data?.id ?? null

	const [showAddAddressForm, setShowAddAddressForm] = useState(false)

	const [paymentOptionId, setPaymentOptionId] = useState<string | null>(null)

	const paymentOptions = useCartPaymentOptions()
	const activePaymentOption = paymentOptions.data?.options?.find(option => option.id === paymentOptionId)
	const debug = useIsDebug()

	const auth = useGoodlokAuth()
	const isAdmin = auth.hasTenantRole('admin')
	const [time, setTime] = useState('')

	const zeus = auth.zeus

	const deliveryOptionPicker = useDeliveryOptionPicker({ time })

	const selectedBase = deliveryOptionPicker.bases.find(base => base.selected)

	const methods = selectedBase && 'methods' in selectedBase ? selectedBase.methods : null

	const selectedMethod = methods?.find(method => method.selected) ?? selectedBase

	const deliveryOptions = selectedMethod && 'options' in selectedMethod ? selectedMethod.options : null

	const isEmptyCart = c.cart.data?.itemsCount === 0

	const { send } = useMobileAppSignal()

	const copyCart = (signedInCustomerId: string) => {
		if (identifier && typeof identifier === 'string') {
			if (signedInCustomerId) {
				c.copyCartItems({ sourceCartId: identifier, customerId: signedInCustomerId }).then(cart => {
					if (cart?.checkoutUrl) {
						window.location.href = cart.checkoutUrl
					}
				})
			}
		}
	}

	useEffect(() => {
		if (isEmptyCart) {
			send({ type: 'close' })
		}
	}, [send, isEmptyCart])

	const customerId = c.cart.data?.customerId ?? null

	const [hasEmail, setHasEmail] = useState<null | boolean>(null)

	const customerInfo = useQuery(
		['checkout', 'customerInfo', c.customerId],
		async () => {
			const info = customerId
				? await zeus.roleCustomer('query')({
						getCustomer: [
							{ by: { id: customerId } },
							{
								id: true,
								email: true,
								phone: true,
								name: [{}, { nominative: true }],
								user: [
									{},
									{
										id: true,
										email: true,
									},
								],
								defaultDeliveryAddress: [{}, { id: true, raw: true, geocodeValid: true, geocodeResponse: true }],
								anonymousSession: [{}, { id: true }],
								paginateOrders: [{}, { pageInfo: { totalCount: true } }],
								paginateAddresses: [{}, { pageInfo: { totalCount: true } }],
							},
						],
				  })
				: null

			if (info) {
				const hasEmail = z
					.string()
					.email()
					.safeParse(info.getCustomer?.email).success

				setHasEmail(old => {
					if (old === null) {
						return hasEmail
					}
					return old
				})
			}

			return info
		},
		{ enabled: !!customerId },
	)

	const cartIssues = useQuery(
		['cart', 'cartIssues', c.cart.data?.id],
		async () => {
			return c.cart.data?.id
				? await zeus.orders('query')({
						getCartIssues: [
							{ cartId: c.cart.data.id },
							{
								ok: true,
								issues: {
									scope: true,
									code: true,
									message: true,
								},
							},
						],
				  })
				: null
		},
		{
			enabled: !!c.cart.data?.id,
		},
	)

	const [deliveryType, setDeliveryType] = useState<'address' | 'pickup'>('address')

	const missingEmail = !hasEmail
	const userEmail = customerInfo.data?.getCustomer?.user?.email ?? ''

	const hasNoAddress = (customerInfo.data?.getCustomer?.paginateAddresses.pageInfo.totalCount ?? 0) === 0

	const [email, setEmail] = useState(userEmail)
	const [fullName, setFullName] = useState(customerInfo.data?.getCustomer?.name?.nominative ?? '')
	const [phone, setPhone] = useState(customerInfo.data?.getCustomer?.phone ?? '')
	const [addressInput, setAddress] = useState('')

	const missingPhone = !customerInfo.data?.getCustomer?.phone
	const missingName = !customerInfo.data?.getCustomer?.name?.nominative

	const debouncedEmail = useDebouncedValue(email)

	const validEmail = z.string().email().safeParse(debouncedEmail)

	const check = useExistingUserByEmailCheck(debouncedEmail)

	const queryClient = useQueryClient()

	const updateCustomerInfo = useMutation(
		async (params: {
			email: string
			fullName: string
			phone: string
			address: string
			deliveryType: 'address' | 'pickup'
		}) => {
			const { email, fullName, phone, address, deliveryType } = params
			if (customerId) {
				return zeus.orders('mutation')({
					updateCustomerInfo: [
						{ customerId, email, fullName, phone, defaultAddress: address, relevantCartId: cartId, deliveryType },
						{ ok: true, errorMessage: true },
					],
				})
			}
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['cart'])
				queryClient.invalidateQueries(['checkout'])
			},
		},
	)

	const defaultDeliveryAddressRaw = customerInfo.data?.getCustomer?.defaultDeliveryAddress?.raw

	const customerEmail = customerInfo.data?.getCustomer?.email
	const customerPhone = customerInfo.data?.getCustomer?.phone
	const customerFullName = customerInfo.data?.getCustomer?.name?.nominative

	useEffect(() => {
		if (customerEmail) {
			setEmail(old => old || customerEmail)
		}
	}, [customerEmail])

	useEffect(() => {
		if (customerPhone) {
			setPhone(old => old || customerPhone)
		}
	}, [customerPhone])

	useEffect(() => {
		if (customerFullName) {
			setFullName(old => old || customerFullName)
		}
	}, [customerFullName])

	useEffect(() => {
		if (defaultDeliveryAddressRaw) {
			setAddress(old => old || defaultDeliveryAddressRaw)
		}
	}, [defaultDeliveryAddressRaw])

	const [showAddNote, setShowAddNote] = useState(false)
	const [note, setNote] = useState('')

	const noteInputRef: React.Ref<HTMLInputElement | HTMLTextAreaElement> = useRef(null)

	useEffect(() => {
		if (showAddNote) {
			setTimeout(() => {
				noteInputRef.current?.focus()
			}, 100)
		}
	}, [showAddNote])

	const debouncedAddress = useDebouncedValue(addressInput, 2000)

	const realValue = [email, validEmail.success ? validEmail.data : null, phone, fullName, deliveryType].join('/')
	const debouncedCustomerInfoValues = useDebouncedValue(realValue, 300)

	const isDirty = realValue !== debouncedCustomerInfoValues || addressInput !== debouncedAddress

	const [submitCount, setSubmitCount] = useState(0)

	useEffect(() => {
		updateCustomerInfo.mutate({ email, address: debouncedAddress, fullName, phone, deliveryType })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedCustomerInfoValues, debouncedAddress])

	const cart = (
		<>
			{identifier && (
				<GoodlokCartRoot identifier={identifier}>
					<div className={style.lines}>{c.cart.data?.items && <CheckoutLines items={c.cart.data.items} />}</div>

					{debug && (
						<div>
							<Button
								type="button"
								variant="seamless"
								uppercase={false}
								round
								onClick={() => {
									c.resetCart()
								}}
							>
								Zrušit košík
							</Button>
							{!!c.cart.data?.customerId && <DumpCustomer customerId={c.cart.data.customerId} />}
						</div>
					)}
				</GoodlokCartRoot>
			)}
		</>
	)

	const address = (
		<>
			{c.cart.data?.customerId && (
				<Modal
					isOpen={!!showAddAddressForm}
					align="bottomOnMobile"
					transparent
					onRequestClose={() => setShowAddAddressForm(false)}
				>
					<AddAddressForm
						cartId={c.cart.data.id}
						customerId={c.cart.data.customerId}
						onCancel={() => setShowAddAddressForm(false)}
						onSuccess={() => setShowAddAddressForm(false)}
					/>
				</Modal>
			)}
		</>
	)

	const isAnonymous = Boolean(
		customerInfo.data?.getCustomer?.anonymousSession?.id && !customerInfo.data?.getCustomer.user?.id,
	)

	const isAnythingLoading = [c.cart.isFetching, check.isFetching, updateCustomerInfo.isLoading, isDirty]

	const importantCartIssues = cartIssues.data?.getCartIssues?.issues.filter(issue => issue.scope !== 'Payment') ?? []

	const [showDatePicker, setShowDatePicker] = useLocalStorageState('checkout.showDatePicker', { defaultValue: false })

	const checkingAddress = !updateCustomerInfo.error && addressInput && addressInput !== defaultDeliveryAddressRaw

	const validAddress =
		addressInput &&
		addressInput === defaultDeliveryAddressRaw &&
		customerInfo.data?.getCustomer?.defaultDeliveryAddress?.geocodeValid

	const options = (
		<>
			{identifier && (
				<GoodlokCartRoot identifier={identifier}>
					<div className={style.optionsWrapper}>
						{(missingEmail || hasNoAddress || (isAnonymous && (missingName || missingPhone))) && (
							<div className={style.delivery}>
								<div className={style.header}>
									<div className={style.parts}>
										<div className={style.part}>
											<strong>Kontaktní údaje</strong> <LoadingSpinner visible={updateCustomerInfo.isLoading} />
										</div>
										{isAnonymous && (
											<div className={style.part}>
												<div className={style.inlineBlock}>
													{'Již máte svůj účet? '}
													<Button
														type="button"
														variant="dark"
														round
														outline
														size="small"
														uppercase={false}
														onClick={() => setShowSignIn(old => !old)}
													>
														Přihlásit se
													</Button>
													<Modal
														isOpen={showSignIn}
														align="bottomOnMobile"
														bordered
														noPadding
														onRequestClose={() => {
															setShowSignIn(false)
														}}
													>
														{c.cart.data?.id && (
															<SignIn
																onSuccessAction={{
																	type: 'cloneAndCheckoutAnonymousCart',
																	cloneAndCheckoutAnonymousCartId: c.cart.data.id,
																}}
																onSuccess={customer => {
																	copyCart(customer.id)
																}}
															/>
														)}
													</Modal>
												</div>
											</div>
										)}
									</div>
								</div>
								{customerId && (
									<div className={style.content}>
										<form
											action=""
											onSubmit={e => {
												e.preventDefault()
											}}
										>
											<fieldset>
												{(isAnonymous || !hasEmail) && (
													<div style={{ margin: '.5em 0' }}>
														<Input
															autoComplete="email"
															label="Váš e-mail"
															labelPosition="inside"
															size="compact"
															value={email}
															onChange={e => setEmail(e.currentTarget.value)}
															error={
																check.data?.signStatus.signedUp ? (
																	<>S tímto e-mailem už u nás máte účet. Tak se radši přihlaste.</>
																) : undefined
															}
															required={isAnonymous}
														/>
													</div>
												)}
												<div style={{ margin: '.5em 0' }}>
													<Input
														autoComplete="name"
														label="Jméno"
														labelPosition="inside"
														size="compact"
														value={fullName}
														onChange={e => setFullName(e.currentTarget.value)}
														required={isAnonymous}
													/>
												</div>
												<div style={{ margin: '.5em 0' }}>
													<Input
														autoComplete="tel"
														label="Telefon"
														labelPosition="inside"
														size="compact"
														value={phone}
														onChange={e => setPhone(e.currentTarget.value)}
														required={isAnonymous}
													/>
												</div>
											</fieldset>
										</form>
									</div>
								)}
							</div>
						)}
						<div className={style.delivery}>
							{hasNoAddress && (
								<>
									<div className={style.header}>
										<div className={style.parts}>
											<div className={style.part}>
												<div style={{ marginBottom: '.75em' }}>
													<strong>Doprava</strong>
												</div>
												<RadioSelect
													name="deliveryType"
													radioGroup="deliveryType"
													value={deliveryType}
													options={[
														{ value: 'address', label: <>Doručení na adresu</> },
														{ value: 'pickup', label: <>Osobní vyzvednutí</> },
													]}
													onChange={setDeliveryType}
												/>
											</div>
											<div className={style.part}></div>
										</div>
									</div>
									{deliveryType === 'address' ? (
										<div className={style.content} style={{ marginBottom: '1em' }}>
											{customerId && (
												<Input
													labelPosition="inside"
													size="compact"
													textarea
													label="Adresa doručení"
													name="raw"
													placeholder="Napříkladová 123, Praha"
													value={addressInput}
													onChange={e => setAddress(e.currentTarget.value)}
													keepNoteSpace
													note={
														!updateCustomerInfo.error &&
														(checkingAddress ? (
															<div>
																<LoadingSpinner visible /> Kontrolujeme adresu
															</div>
														) : validAddress ? (
															<div style={{ color: 'var(--global-colors-green-900)' }}>
																<Icon name="circleCheckMark" /> Adresa je OK
															</div>
														) : null)
													}
													error={
														'error' in updateCustomerInfo && (
															<div>Adresu jsme nerozpoznali, zkuste ji lépe upřesnit.</div>
														)
													}
												/>
											)}
										</div>
									) : deliveryType === 'pickup' ? (
										<DeliveryBasePicker time={time} filter="pickup" />
									) : null}
								</>
							)}

							{!hasNoAddress && (
								<div className={style.header}>
									<div className={style.parts}>
										<div className={style.part}>
											<strong>Kam?</strong>
										</div>
									</div>
									{address}
								</div>
							)}

							<>
								{!hasNoAddress && (
									<DeliveryBasePicker
										autoPick
										time={time}
										onAddAddressClick={() => setShowAddAddressForm(old => !old)}
									/>
								)}
								{methods && (
									<>
										{!hasNoAddress && (
											<div className={style.header}>
												<strong>Jak?</strong>
											</div>
										)}
										<DeliveryMethodPicker methods={methods} time={time} />
									</>
								)}
							</>

							{deliveryOptions && (
								<>
									<div className={style.header}>
										<div className={style.parts}>
											<div
												className={style.part}
												onClick={
													isAdmin
														? () => {
																setShowDatePicker(o => !o)
														  }
														: undefined
												}
											>
												<strong>Kdy?</strong>
											</div>
											<div className={style.part}>
												{showDatePicker && (
													<div className={style.inlineBlock}>
														<Input
															size="compact"
															type="date"
															value={time}
															onChange={e => setTime(e.currentTarget.value)}
														/>
													</div>
												)}{' '}
											</div>
										</div>
									</div>
									<DeliveryOptionPicker options={deliveryOptions} time={time} />
								</>
							)}
						</div>

						{!!paymentOptions.data?.options.length && (
							<div className={style.payment}>
								<div className={style.header}>
									<strong>Placení</strong>
								</div>
								<PaymentOptionPicker
									paymentOptionId={paymentOptionId}
									setPaymentOptionId={setPaymentOptionId}
									creditsRestHeader={
										<div className={style.header}>
											<strong>A ještě zbývá něco doplatit…</strong>
										</div>
									}
								/>
							</div>
						)}
					</div>

					<div className={style.verticalSpace}>
						<div className={style.content}>
							<Collapsible revealType="topFirst" open={!showAddNote}>
								<button
									type="button"
									onClick={() => {
										setShowAddNote(old => !old)
									}}
									className={style.fakeLink}
								>
									Přidat poznámku
								</button>
							</Collapsible>
							<Collapsible revealType="topFirst" open={showAddNote}>
								<Input
									ref={noteInputRef}
									type="text"
									textarea
									label="Poznámka"
									size="compact"
									labelPosition="inside"
									value={note}
									onChange={e => setNote(e.currentTarget.value)}
								/>
							</Collapsible>
						</div>
					</div>

					<div className={style.floater}>
						<div className={style.floaterCard}>
							{submitCount > 0 && importantCartIssues.length ? (
								<div className={style.parts}>
									<div className={style.part}>
										<ul className={style.errorList}>
											{importantCartIssues.map((m, i) => (
												<li key={i} title={JSON.stringify(m)}>
													{m.message}
												</li>
											))}
										</ul>
									</div>
								</div>
							) : null}
							<div className={style.parts}>
								<div className={style.part}>
									<div className={style.summary}>
										<CheckoutSummary
											itemsCount={c.cart.data?.itemsCount}
											totalPriceCents={activePaymentOption?.priceCents ?? c.cart.data?.totalPriceCents ?? null}
											creditsCents={activePaymentOption?.creditsCents ?? null}
										/>
									</div>
								</div>
								<div className={style.part}>
									<div className={style.actions}>
										<CheckoutActions
											note={note}
											disabled={importantCartIssues.length > 0}
											loading={isAnythingLoading.some(item => item)}
											paymentOptionId={paymentOptionId}
											onSuccess={onSuccess}
											onSubmit={() => setSubmitCount(c => c + 1)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={style.floaterPlaceholder}></div>
				</GoodlokCartRoot>
			)}
		</>
	)

	return {
		cart: c,
		slots: {
			cart,
			options,
		},
	}
}

export const Checkout: FunctionComponent<{ identifier: CartIdentifier }> = props => {
	const { cart: c, slots } = useGoodlokCheckout(props.identifier)

	if ((c.cart.data?.items?.length ?? 0) === 0) {
		if (c.order.data) {
			return <LatestOrderNote identifier={props.identifier} />
		} else {
			return (
				<div
					style={{
						position: 'fixed',
						top: '25%',
						left: 0,
						right: 0,
						textAlign: 'center',
						fontSize: '10vw',
						opacity: 0.5,
					}}
				>
					<LoadingSpinner />
				</div>
			)
		}
	}

	return (
		<div className={style.wrapper}>
			<div className={style.card}>
				<div className={style.sidebar}>{slots.cart}</div>
				<div className={style.options}>{slots.options}</div>
			</div>
		</div>
	)
}

export const LatestOrderNote: FunctionComponent<{ identifier?: CartIdentifier }> = ({ identifier }) => {
	const { cart: c } = useGoodlokCheckout(identifier)
	const apps = getEnabledApps()
	const buyApp = apps.find(app => app.name === 'buy')
	return c.order.data && buyApp ? (
		<p style={{ textAlign: 'center' }}>
			Poslední objednávka je{' '}
			<Link href={`${buyApp.url}/order/${c.order.data?.id}`} legacyBehavior>
				<a>
					<code>#{c.order.data?.seq}</code>
				</a>
			</Link>{' '}
			<Button variant="dark" round outline size="small" type="button" uppercase={false} onClick={() => c.resetOrder()}>
				OK
			</Button>
		</p>
	) : null
}

export const CheckoutOptions: FunctionComponent<{
	identifier: CartIdentifier
	onSuccess?: OrderCreatedHandler
	showLatestOrderNote?: boolean
}> = props => {
	const { cart: c, slots } = useGoodlokCheckout(props.identifier, props.onSuccess)

	if ((c.cart.data?.items?.length ?? 0) === 0) {
		if (c.order.data && props.showLatestOrderNote) {
			return <LatestOrderNote identifier={props.identifier} />
		} else {
			return null
		}
	}

	return <div className={style.options}>{slots.options}</div>
}

export const SignIn: FunctionComponent<{
	onSuccessAction?: OnSuccessAuthAction
	onSuccess?: (customer: { id: string }) => void
}> = props => {
	const [successUrl, setSuccessUrl] = useState('/')
	useEffect(() => {
		setSuccessUrl(window.location.href)
	}, [])
	return (
		<GoodlokAuthForm
			successUrl={successUrl}
			onSuccess={props.onSuccess}
			onSuccessAction={props.onSuccessAction}
			minimal
		/>
	)
}

function useExistingUserByEmailCheck(email: string | null) {
	const { zeus } = useGoodlokAuth()

	const debouncedEmail = useDebouncedValue(email)

	const validEmail = z.string().email().safeParse(debouncedEmail)

	return useQuery(
		['checkExistingUserWithEmail', validEmail.success ? validEmail.data : null],
		async () => {
			if (validEmail.success)
				return zeus.auth('query')({
					signStatus: [
						{ username: validEmail.data },
						{ verified: true, hasCustomerProfile: true, signedUp: true, authenticatedWith: true },
					],
				})
		},
		{
			enabled: validEmail.success,
		},
	)
}
