import clsx from 'clsx'
import type { LinkProps } from 'next/link'
import type { FunctionComponent } from 'react'
import { Chip, type ChipProps } from './Chip'
import styles from './ChipNavigation.module.sass'
import { HorizontallyScrollableContent } from './HorizontallyScrollableContent'

type Item = {
	readonly label: string
	readonly value: string
	readonly icon?: ChipProps['icon']
	readonly link?: LinkProps
}

export type ChipNavigationProps = {
	items: readonly Item[]
	value: string | undefined
	onChange?: (value: string) => void
	horizontalScroll?: boolean
}

export const ChipNavigation: FunctionComponent<ChipNavigationProps> = ({
	items,
	value,
	onChange,
	horizontalScroll = false,
}) => {
	return horizontalScroll ? (
		<div className={clsx(styles.wrapper, horizontalScroll && styles.is_horizontal_scroll)}>
			<HorizontallyScrollableContent hideArrowsOnMobile>
				{items.map((item, i) => {
					const commonProps = { active: value === item.value, icon: item.icon }
					const props: ChipProps =
						item.link === undefined
							? {
									...commonProps,
									type: 'button',
									onClick:
										onChange === undefined
											? undefined
											: () => {
													onChange(item.value)
											  },
							  }
							: {
									...commonProps,
									...item.link,
									type: 'link',
							  }
					return (
						<div key={i} className={styles.chip}>
							<Chip {...props}>{item.label}</Chip>
						</div>
					)
				})}
			</HorizontallyScrollableContent>
		</div>
	) : (
		<div className={clsx(styles.wrapper, styles.is_wrap)}>
			{items.map((item, i) => {
				const commonProps = { active: value === item.value, icon: item.icon }
				const props: ChipProps =
					item.link === undefined
						? {
								...commonProps,
								type: 'button',
								onClick:
									onChange === undefined
										? undefined
										: () => {
												onChange(item.value)
										  },
						  }
						: {
								...commonProps,
								...item.link,
								type: 'link',
						  }
				return (
					<Chip key={i} {...props}>
						{item.label}
					</Chip>
				)
			})}
		</div>
	)
}
