import { content_role_adminZeus } from '@goodlok/sdk/browser/content_role_admin'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { BusinessServiceEventFragment } from '../fragments/BusinessServiceEventFragment'

export function useBusinessServiceEvents(props: {
	queryString: string
	selectedCountries: string[] | null
	selectedCustomerGroups: string[] | null
	page: number
}) {
	const { queryString, selectedCountries, selectedCustomerGroups, page } = props
	const perPage = 20
	const { locale } = useRouter()

	const searchQueryString = queryString.length > 2 ? queryString : ''

	return useQuery(
		['businessServiceEvents', queryString, selectedCountries, selectedCustomerGroups, page] as const,
		async () => {
			const data = await content_role_adminZeus('query')({
				paginateBusinessServiceEvent: [
					{
						orderBy: [{ fulfilledAt: 'desc' }],
						first: perPage,
						skip: (page - 1) * perPage,
						filter: {
							and: [
								searchQueryString
									? {
											or: [
												{ businessCustomer: { name: { containsCI: searchQueryString } } },
												{ businessCustomer: { customer: { email: { containsCI: searchQueryString } } } },
												{ businessCustomer: { customer: { name: { nominative: { containsCI: queryString } } } } },
												{ businessCustomer: { customer: { name: { vocative: { containsCI: queryString } } } } },
												{ businessCustomer: { customer: { user: { email: { containsCI: queryString } } } } },
												{
													businessCustomer: {
														customer: { defaultBillingSubject: { email: { containsCI: queryString } } },
													},
												},
											],
									  }
									: {},
								selectedCountries && selectedCountries.length > 0
									? {
											businessCustomer: {
												customer: { defaultBillingSubject: { country: { code: { in: selectedCountries } } } },
											},
									  }
									: {},
								selectedCustomerGroups && selectedCustomerGroups.length > 0
									? { businessCustomer: { customer: { group: { id: { in: selectedCustomerGroups } } } } }
									: {},
							],
						},
					},
					{ pageInfo: { totalCount: true }, edges: { node: BusinessServiceEventFragment(locale) } },
				],
			})

			const businessServiceEvents = {
				perPage,
				page,
				totalCount: data.paginateBusinessServiceEvent.pageInfo.totalCount,
				items: data.paginateBusinessServiceEvent.edges.map(edge => edge.node),
			}
			return businessServiceEvents
		},
		{ refetchOnWindowFocus: false, keepPreviousData: true },
	)
}
