import React, { forwardRef } from 'react'
import style from './Select.module.sass'

type Option = {
	readonly label: string
	readonly value?: string
	readonly disabled?: boolean
}

export type SelectProps = Pick<
	React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
	'autoComplete' | 'value' | 'name' | 'onChange' | 'disabled'
> & {
	label?: string
	options: readonly Option[]
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
	({ autoComplete, value, name, disabled, onChange, label, options }, ref) => {
		return (
			<label className={style.wrapper}>
				{label && <span className={style.label}>{label}</span>}
				<div className={style.inputWrapper}>
					<select
						className={style.input}
						name={name}
						autoComplete={autoComplete}
						value={value}
						onChange={onChange}
						disabled={disabled}
						ref={ref}
					>
						{options.map(({ label, value = label, disabled }) => (
							<option key={value} value={value} disabled={disabled}>
								{label}
							</option>
						))}
					</select>
				</div>
			</label>
		)
	},
)
Select.displayName = 'Select'
