import clsx from 'clsx'
import { ButtonBase, DistinctBaseButtonProps, SharedBaseButtonProps } from 'nextjs-button-base'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import styles from './Chip.module.sass'
import { Ripple } from './Ripple'

export type ChipProps = {
	active?: boolean
	icon?: ReactNode
} & Omit<SharedBaseButtonProps, 'className' | 'children'> &
	DistinctBaseButtonProps

export const Chip: FunctionComponent<PropsWithChildren<ChipProps>> = ({ active, icon, children, ...otherProps }) => {
	const compactInNarrowSpaces = icon !== undefined

	return (
		<ButtonBase
			{...otherProps}
			className={clsx(
				styles.wrapper,
				active && styles.is_active,
				compactInNarrowSpaces && styles.is_compactInNarrowSpaces,
			)}
		>
			<Ripple />
			<span className={styles.text}>{children}</span>
			{icon && <span className={styles.icon}>{icon}</span>}
		</ButtonBase>
	)
}
